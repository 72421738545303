import React, { createContext, useState } from 'react';

import OrgGroupData from './OrgGroupData';

export const OrgGroupStateContext = createContext();

const OrgGroupContextProvider = ({ children }) => {
  const stateHook = useState(OrgGroupData.INITIAL_STATE);

  return (
    <OrgGroupStateContext.Provider value={stateHook}>
      {children}
    </OrgGroupStateContext.Provider>
  );
};

export default OrgGroupContextProvider;