import React, { createContext, useState } from 'react';

import OmeMeetDateValidationData from './OmeMeetDateValidationData';

export const OmeMeetDateValidationStateContext = createContext();

const OmeMeetDateValidationContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetDateValidationData.INITIAL_STATE);

  return (
    <OmeMeetDateValidationStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetDateValidationStateContext.Provider>
  );
};

export default OmeMeetDateValidationContextProvider;