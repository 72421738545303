import useEventMultiSelect from './UseEventMultiSelect';

import MultiSelect from '../MultiSelect';

import global from '../../GlobalStyle.module.css';

const EventMultiSelect = ({ label, name, value, error, message, onChange }) => {
  const { eventState, options } = useEventMultiSelect();

  return eventState.message
    ? <span className={global.LoadingMsg}>{eventState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={options}
        error={error}
        message={message}
        isLoading={eventState.isLoading}
        onChange={onChange} />
    );
};

export default EventMultiSelect;