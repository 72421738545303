import React from 'react';

import OrganizationContextProvider from '../common/state/organization/OrganizationContextProvider';
import CourseContextProvider from '../common/state/course/CourseContextProvider';
import OrgUnitContextProvider from '../common/state/orgUnit/OrgUnitContextProvider';
import LscContextProvider from '../common/state/lsc/LscContextProvider';
import MeetClassificationContextProvider from '../common/state/meet/meetClassification/MeetClassificationContextProvider';
import MeetClassificationTypeContextProvider from '../common/state/meet/meetClassificationType/MeetClassificationTypeContextProvider';
import MeetSoftwareContextProvider from '../common/state/meet/meetSoftware/MeetSoftwareContextProvider';
import EventContextProvider from '../common/state/event/EventContextProvider';
import FacilityContextProvider from '../common/state/facility/FacilityContextProvider';

const AppStateContextProviders = ({ children }) => {

  return (
    <OrganizationContextProvider>
      <OrgUnitContextProvider>
        <CourseContextProvider>
          <LscContextProvider>
            <MeetClassificationContextProvider>
              <MeetClassificationTypeContextProvider>
                <MeetSoftwareContextProvider>
                  <EventContextProvider>
                    <FacilityContextProvider>
                      {children}
                    </FacilityContextProvider>
                  </EventContextProvider>
                </MeetSoftwareContextProvider>
              </MeetClassificationTypeContextProvider>
            </MeetClassificationContextProvider>
          </LscContextProvider>
        </CourseContextProvider>
      </OrgUnitContextProvider>
    </OrganizationContextProvider>
  );
};
export default AppStateContextProviders;