import { useEffect, useState } from 'react';

import useMeetData from '../../../../common/state/meet/UseMeetData';

const INITIAL_VIEW_STATE = {
  reportParameters: { meetId: '' },
  contextId: '',
  routeName: ''
};

const useMeetReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { meetState, getMeet } = useMeetData();

  useEffect(() => {
    if (meetState.objData.meetId > 0 && meetState.isObjLoading !== true && meetState.isObjLoaded !== true) {
      getMeet({ meetId: meetState.objData.meetId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.objData.meetId, meetState]);

  useEffect(() => {
    if (meetState.objData.meetId) {
      setViewState({
        ...viewState,
        reportParameters: { meetId: meetState.objData.meetId },
        contextId: meetState.objData.meetId,
        routeName: 'MEET_REPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.objData]);

  return { ...viewState };
};

export default useMeetReporting;