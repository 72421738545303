import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LaneSheetRightSideStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const LaneSheetRightSideContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LaneSheetRightSideStateContext.Provider value={stateHook}>
      {children}
    </LaneSheetRightSideStateContext.Provider>
  );
};

export default LaneSheetRightSideContextProvider;