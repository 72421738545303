import React from 'react';

import useMeetTimeIndividualCompetitorCombobox from './UseAddMeetTimeIndividualCompetitorCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';

const AddMeetTimeIndividualCompetitorCombobox = ({ label, name, valueToMatch, error, message, onChange }) => {
  const {
    optionsState,
  } = useMeetTimeIndividualCompetitorCombobox()

  return (
    <Combobox
      label={label}
      name={name}
      isLoading={false}
      items={optionsState}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default AddMeetTimeIndividualCompetitorCombobox;