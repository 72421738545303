import { useContext } from 'react';

import LaneSheetTopHeaderData from './LaneSheetTopHeaderData';

import { LaneSheetTopHeaderStateContext } from './LaneSheetTopHeaderContextProvider';

const useLaneSheetTopHeaderData = () => {
  const [laneSheetTopHeaderState, setLaneSheetTopHeaderState] = useContext(LaneSheetTopHeaderStateContext);

  const postLaneSheetTopHeaderInfo = (laneSheetTopHeaderObj, state, setState) => {
    return LaneSheetTopHeaderData.postLaneSheetTopHeaderInfoData(laneSheetTopHeaderObj, state, setState);
  };

  const getLaneSheetTopHeader = (laneSheetMeetId) => {
    return LaneSheetTopHeaderData.getLaneSheetTopHeaderData(laneSheetMeetId, laneSheetTopHeaderState, setLaneSheetTopHeaderState);
  };

  const deleteLaneSheetTopHeaderInfo = (laneSheetTopInfoId, state, setState) => {
    return LaneSheetTopHeaderData.deleteLaneSheetTopHeaderInfoData(laneSheetTopInfoId, state, setState);
  };

  return {
    laneSheetTopHeaderState,
    postLaneSheetTopHeaderInfo,
    getLaneSheetTopHeader,
    deleteLaneSheetTopHeaderInfo
  };
};

export default useLaneSheetTopHeaderData;