/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate, formatDateTimeToTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

const LargeGrid = ({ showOptionalFields, showOtsField, sortedMeetSessionArray,
  meetDatesState, formState, errorState, onValueTextPairChanged }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Session Number</th>
          <th>Session Type</th>
          <th>Current Session Date</th>
          <th>New Session Date</th>
          {showOptionalFields === true &&
            <Fragment>
              <th>Warm Up Time</th>
              <th>Start Time</th>
            </Fragment>
          }
          {showOtsField === true &&
            <Fragment>
              <th>OQM Session?</th>
            </Fragment>}
        </tr>
      </thead>
      <tbody>
        {sortedMeetSessionArray?.length > 0 ?
          sortedMeetSessionArray.map((session, i) => {
            let name = `sessionDateId${session.meetSessionId}`
            let value = `sessionDate${session.meetSessionId}`
            return (
              < tr key={session.meetSessionId || i} >
                <td>{session.sessionOrderId >= 0 ? session.sessionOrderId : ''}</td>
                <td>{session.sessionType?.typeName ? session.sessionType?.typeName : ''}</td>
                <td>{session.sessionDate ? formatDate(session.sessionDate) : ''}</td>
                <td>
                  <Dropdown
                    label=""
                    name={name}
                    options={meetDatesState}
                    value={formState[name]}
                    error={errorState[name]}
                    message={errorState[name]}
                    isLoading={false}
                    onChange={(newValue, newValueLabel, e) => {
                      onValueTextPairChanged(
                        newValue, name,
                        newValueLabel, value, e);
                    }} />
                </td>
                {showOptionalFields === true &&
                  <Fragment>
                    <td>{session.warmUpTime ? formatDateTimeToTimeTwelveHourClock(session.warmUpTime) : ''}</td>
                    <td>{session.startTime ? formatDateTimeToTimeTwelveHourClock(session.startTime) : ''}</td>
                  </Fragment>
                }
                {showOtsField === true &&
                  <Fragment>
                    <td>{session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : ''}</td>
                  </Fragment>
                }
              </tr>)
          }) : (
            <tr>
              {showOptionalFields === true && showOtsField === true ?
                <td colSpan="7">No Sessions</td> :
                showOptionalFields === true && showOtsField !== true ?
                  <td colSpan="6">No Sessions</td> :
                  showOptionalFields !== true && showOtsField === true ?
                    <td colSpan="5">No Sessions</td> :
                    <td colSpan="4">No Sessions</td>}
            </tr>
          )
        }
      </tbody>
    </table >
  );
}

const SmallGrid = ({ showOptionalFields, showOtsField, sortedMeetSessionArray, meetDatesState, formState, errorState, onValueTextPairChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {sortedMeetSessionArray?.length > 0 ?
      sortedMeetSessionArray.map((session, i) => {
        let name = `sessionDateId${session.meetSessionId}`
        let value = `sessionDate${session.meetSessionId}`
        return (
          <div className={global.SmallTableRow} key={session.meetSessionId || i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>Session Number {session.sessionOrderId}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Session #</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{session.sessionOrderId >= 0 ? session.sessionOrderId : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Session Type </div>
                <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{session.sessionType?.typeName ? session.sessionType?.typeName : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Current Date</div>
                <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{session.sessionDate ? formatDate(session.sessionDate) : <span>&nbsp;</span>}</div>
                <div className={['col-xs-12 col-sm-6', global.SmallTableRowLabels].join(' ')}>New Date</div>
                <div className={['col-xs-12 col-sm-6', global.SmallTableRowData].join(' ')}>
                  <Dropdown
                    label=""
                    name={name}
                    options={meetDatesState}
                    value={formState[name]}
                    error={errorState[name]}
                    message={errorState[name]}
                    isLoading={false}
                    onChange={(newValue, newValueLabel, e) => {
                      onValueTextPairChanged(
                        newValue, name,
                        newValueLabel, value, e);
                    }} />
                </div>
                {showOptionalFields === true &&
                  <Fragment>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Warm Up Time</div>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.warmUpTime ? formatDateTimeToTimeTwelveHourClock(session.warmUpTime) : <span>&nbsp;</span>}</div>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Start Time</div>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.startTime ? formatDateTimeToTimeTwelveHourClock(session.startTime) : <span>&nbsp;</span>}</div>
                  </Fragment>}
                {showOtsField === true &&
                  <Fragment>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>OQM Session?</div>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : <span>&nbsp;</span>}</div>
                  </Fragment>}
              </div>
            </div>
          </div>
        )
      })
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Sessions</div>
          </div>
        </div>
      )}
  </div >
);

const SessionsGrid = ({ showOptionalFields, showOtsField, sortedMeetSessionArray, meetDatesState, formState, errorState, onValueTextPairChanged }) => (
  <Fragment>
    <LargeGrid showOptionalFields={showOptionalFields} showOtsField={showOtsField} sortedMeetSessionArray={sortedMeetSessionArray} meetDatesState={meetDatesState} formState={formState} errorState={errorState} onValueTextPairChanged={onValueTextPairChanged} />
    <SmallGrid showOptionalFields={showOptionalFields} showOtsField={showOtsField} sortedMeetSessionArray={sortedMeetSessionArray} meetDatesState={meetDatesState} formState={formState} errorState={errorState} onValueTextPairChanged={onValueTextPairChanged} />
  </Fragment>
);

export default SessionsGrid;