import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getTimeStandardTypesData from './GetTimeStandardTypeData';
import getTimeStandardTypeByOrgUnitIdData from './GetTimeStandardTypeByOrgUnitIdData';
import getTimeStandardTypeHierarchyData from './GetTimeStandardTypeHierarchyData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  treeData: [],
  options: [],
  isOptionsLoaded: false
};

const TimeStandardTypeData = {
  INITIAL_STATE, getTimeStandardTypesData,
  getTimeStandardTypeByOrgUnitIdData, getTimeStandardTypeHierarchyData
};

export default TimeStandardTypeData;