import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetRelayAthleteEntryTimeData = (personId, meetEventId, legNumber, qualifyingEventId, state, setState) => {
  if (state.isObjLoading !== true) {
    const newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      personId,
      meetEventId,
      legNumber,
      message: 'Loading...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const meetEventIdForUrl = meetEventId ? encodeURIComponent(meetEventId) : 'NaN';
    const legNumberForUrl = legNumber ? encodeURIComponent(legNumber) : 'NaN';
    const qualifyingEventIdForUrl = qualifyingEventId ? encodeURIComponent(qualifyingEventId) : 'NaN';
    const url = `/OrgUnitAthleteEntry/relayAthlete/entryTime?personId=${personIdForUrl}&meetEventId=${meetEventIdForUrl}&legNumber=${legNumberForUrl}&qualifyingEventId=${qualifyingEventIdForUrl}`;
    MeetHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isObjLoaded: true,
            isObjLoading: false,
            objData,
            personId,
            meetEventId,
            legNumber,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoaded: false,
          isObjLoading: false,
          message: message
        });
      });
  }
};

export default GetRelayAthleteEntryTimeData;