import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOrgUnitAthleteEntryPurchaseItemData from '../../../../state/orgUnitAthleteEntryPayment/UseOrgUnitAthleteEntryPurchaseItemData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import EntryNavLinks from '../../EntryNavLinks';
import NavLinks from '../../../meet/NavLinks';

const INITIAL_VIEW_STATE = {
  items: [],
  instructionText: 'Pay for OME Athlete Entry Items'
};

const useAthleteUserPayments = () => {
  const navigate = useNavigate();
  const { orgUnitAthleteEntryPurchaseItemsPromise } = useOrgUnitAthleteEntryPurchaseItemData();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const redirectToPurchases = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_PURCHASES);
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_PURCHASES);
    }
  };

  const redirectToEntries = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_ENTRIES, { state: { tryGet: true } });
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_ENTRIES, { state: { tryGet: true } });
    }
  }

  // useEffect(() => {
  //   if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId) {
  //     const purchaseItemsPromise = orgUnitAthleteEntryPurchaseItemsPromise(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId);
  //     if (purchaseItemsPromise) {
  //       purchaseItemsPromise.then((purchaseItemData) => {
  //         const items = Array.isArray(purchaseItemData?.arrayData) && purchaseItemData.arrayData.length > 0
  //           ? [...purchaseItemData.arrayData] : [];
  //         const sortedItems = items.sort(function (a, b) {
  //           return (a.collectionDescription < b.collectionDescription) ? -1 : (a.collectionDescription > b.collectionDescription) ? 1 : 0;
  //         });
  //         setState({
  //           ...state,
  //           items: sortedItems
  //         });
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId]);

  return {
    //omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    //items: state.items,
    instructionText: state.instructionText,
    redirectToPurchases,
    redirectToEntries,
    orgUnitAthleteEntryId: omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId
  };
};

export default useAthleteUserPayments;