import React, { Fragment } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ matchItem, onQuickAddCheckbox, onDeleteCheckbox, onMatchWithMemberIdButtonClick }) => {
  return (
    <Fragment>
      <tr>
        <td style={(matchItem.isRepeat === true) ? { backgroundColor: 'blue' } : {}}></td>
        <td>
          {matchItem.lscCode + '/' + matchItem.clubCode}
        </td>
        <td>
          {matchItem.firstName || ''}
        </td>
        <td>
          {matchItem.lastName || ''}
        </td>
        <td>
          {formatDate(matchItem.birthDate)|| ''}
        </td>
        <td>
          {matchItem.isFina === true ? 'Yes' : 'No'}
        </td>
        <td>
          {matchItem.isRelay === true ? 'Yes' : 'No'}
        </td>
        <td>
          {matchItem.isFina === true ?
            <Checkbox
              label=""
              name="addPerson"
              onChange={(e) => { onQuickAddCheckbox(e, matchItem.rowId) }}
              checked={matchItem.addPerson}
            />
            : 'N/A'
          }
        </td>
        <td>
          <Checkbox
            label=""
            name="deletePerson"
            onChange={(e) => { onDeleteCheckbox(e, matchItem.rowId) }}
            checked={matchItem.deletePerson}
          />
        </td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => { onMatchWithMemberIdButtonClick(e, matchItem) }}>
            <FontAwesomeIcon icon={faUser} />
          </button>
        </td>
      </tr>
    </Fragment >
  );
};

const MeetUploadErrorTankQuickAddLargeGrid = ({ gridData, isLoading, onQuickAddCheckbox, onDeleteCheckbox, onMatchWithMemberIdButtonClick }) => {
  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <th></th>
            <th>LSC / Club Code</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Birth Date</th>
            <th>FINA?</th>
            <th>Relay?</th>
            <th>Quick Add</th>
            <th>Delete</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading === true ?
            <tr><td colSpan="9">Loading...</td></tr>
            : Array.isArray(gridData) && gridData.length > 0 ?
              gridData.map((match, i) => <GridRow key={i} matchItem={match} onQuickAddCheckbox={onQuickAddCheckbox} onDeleteCheckbox={onDeleteCheckbox} onMatchWithMemberIdButtonClick={onMatchWithMemberIdButtonClick} />)
              :
              <tr><td colSpan="9">No Results</td></tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
};

export default MeetUploadErrorTankQuickAddLargeGrid;