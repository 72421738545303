import React, { Fragment } from 'react';

import useOmeEventsGrid from './UseOmeEventsGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import Constants from '../../../../../common/utils/Constants';

const LargeDetailTableRow = ({ event, time, showNoSlowerThan, showNoFasterThan, showBonus }) => {
  return (
    <Fragment>
      {showNoSlowerThan === true && formatTimeForDisplay(time.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING &&
        <tr key={time.meetEventQualifyingTimeId + 'NoSlowerTS'}>
          <td>{time.course?.courseCode || ''} No Slower Than</td>
          <td>{time.qualifyingTime ? formatTimeForDisplay(time.qualifyingTime) : 'Not Used By Event'}</td>
          <td>{time.event?.eventName || ''}</td>
          <td>{time.event?.relatedScyEventId === event.event?.relatedScyEventId || time.event?.relatedScmEventId === event.event?.relatedScmEventId || time.event?.relatedLcmEventId === event.event?.relatedLcmEventId ? 'No' : 'Yes'}</td>
        </tr>}
      {showNoFasterThan === true && formatTimeForFilterObject(time.limitTime) !== Constants.MIN_SWIM_TIME_STRING &&
        <tr key={time.meetEventQualificationLimitId + 'NoFasterTS'}>
          <td>{time.course?.courseCode || ''} No Faster Than</td>
          <td>{time.limitTime ? formatTimeForDisplay(time.limitTime) : 'Not Used By Event'}</td>
          <td>{time.event?.eventName || ''}</td>
          <td>{time.event?.relatedScyEventId === event.event?.relatedScyEventId || time.event?.relatedScmEventId === event.event?.relatedScmEventId || time.event?.relatedLcmEventId === event.event?.relatedLcmEventId ? 'No' : 'Yes'}</td>
        </tr>}
      {showBonus === true && formatTimeForDisplay(time.bonusTime) !== Constants.MAX_SWIM_TIME_STRING &&
        <tr key={time.meetEventBonusTimeId + 'BonusTS'}>
          <td>{time.course?.courseCode || ''} Bonus</td>
          <td>{time.bonusTime ? formatTimeForDisplay(time.bonusTime) : 'Not Used By Event'}</td>
          <td>{time.event?.eventName || ''}</td>
          <td>{time.event?.relatedScyEventId === event.event?.relatedScyEventId || time.event?.relatedScmEventId === event.event?.relatedScmEventId || time.event?.relatedLcmEventId === event.event?.relatedLcmEventId ? 'No' : 'Yes'}</td>
        </tr>}
    </Fragment>
  )
}

const LargeGridDetailTable = ({ event, meetEventQualifyingTime, meetEventQualificationLimit, meetEventBonusTime, showNoSlowerThan, showNoFasterThan, showBonus }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th style={{ minWidth: '150px' }}>Time Standard Type</th>
        <th>Time</th>
        <th>Time Standard Event</th>
        <th>Alternate Event?</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(meetEventQualifyingTime) && meetEventQualifyingTime.length > 0 ? meetEventQualifyingTime.map((time, i) =>
        <LargeDetailTableRow key={i} event={event} time={time} showNoSlowerThan={showNoSlowerThan} />) : <Fragment />}
      {Array.isArray(meetEventQualificationLimit) && meetEventQualificationLimit.length > 0 ? meetEventQualificationLimit.map((time, i) =>
        <LargeDetailTableRow key={i} event={event} time={time} showNoFasterThan={showNoFasterThan} />) : <Fragment />}
      {Array.isArray(meetEventBonusTime) && meetEventBonusTime.length > 0 ? meetEventBonusTime.map((time, i) =>
        <LargeDetailTableRow key={i} event={event} time={time} showBonus={showBonus} />) : <Fragment />}
      {Array.isArray(meetEventQualifyingTime) && (meetEventQualifyingTime.length === 0 || meetEventQualifyingTime.filter(x => formatTimeForDisplay(x.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING).length === 0) &&
        Array.isArray(meetEventQualificationLimit) && (meetEventQualificationLimit.length === 0 || meetEventQualificationLimit.filter(x => formatTimeForFilterObject(x.limitTime) !== Constants.MIN_SWIM_TIME_STRING).length === 0) &&
        Array.isArray(meetEventBonusTime) && (meetEventBonusTime.length === 0 || meetEventBonusTime.filter(x => formatTimeForDisplay(x.bonusTime) !== Constants.MAX_SWIM_TIME_STRING).length === 0) ?
        <tr key={'NotUsedByEvent'}>
          <td colSpan={4}>Time Standards Cuts Not Used by Event</td>
        </tr> : <Fragment />}
    </tbody>
  </table>
);

const LargeGrid = ({ state, filteredGridState, onEdit, onDelete, showToggle, showNoSlowerThan, showNoFasterThan, showBonus, expandedId, onClick }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>#</th>
        <th>Event</th>
        <th>Comp. Category</th>
        <th>Age Group</th>
        <th>Session</th>
        <th style={{ minWidth: '130px' }}></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true ?
              <td colSpan="6">Loading...</td>
              : <td colSpan="6">No Events</td>
            }
          </tr>
        ) : Array.isArray(filteredGridState) && filteredGridState.length > 0
          ? filteredGridState.map((event, i) => {
            return (
              <Fragment key={event?.meetEventId + 'Fragment'} >
                <tr key={event?.meetEventId || i}>
                  <td>{event?.eventNumber || ''}</td>
                  <td>{event?.event?.eventName || ''}</td>
                  <td>{event?.eventCompetitionGenderType?.typeName || ''}</td>
                  <td>{event?.ageGroupName || ''} ({event?.minAge || ''} - {event?.maxAge || ''})</td>
                  <td>{event?.sessionOrderId || ''} ({event?.sessionType} {event?.sessionDate ? formatDate(event?.sessionDate) : ''})</td>
                  <td>
                    {onEdit &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, event)}>
                        <EditIcon />
                      </button>}
                    {onDelete &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, event)}>
                        <DeleteIcon />
                      </button>}
                    {showToggle === true &&
                      <button
                        className={global.IconButtonMargin}
                        type="button"
                        onClick={(e) => onClick(e, event?.meetEventId)}>
                        {expandedId === event?.meetEventId ? <HideIcon /> : <ShowIcon />}
                      </button>}
                  </td>
                </tr>
                {Array.isArray(event?.meetAgeGroupTimeStandard) && event?.meetAgeGroupTimeStandard.length > 0 && expandedId === event?.meetEventId &&
                  <tr className={global.Expanded}>
                    <td colSpan="6">
                      <LargeGridDetailTable event={event} meetEventQualifyingTime={event?.meetEventQualifyingTime} meetEventQualificationLimit={event?.meetEventQualificationLimit} meetEventBonusTime={event?.meetEventBonusTime}
                        showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus} />
                    </td>
                  </tr>}
              </Fragment>
            )
          }) : (
            <tr>
              <td colSpan="6">No Events</td>
            </tr>
          )
      }
    </tbody>
  </table >
);


const SmallDetailTableRow = ({ event, time, showNoSlowerThan, showNoFasterThan, showBonus }) => (
  <Fragment>
    {showNoSlowerThan === true && formatTimeForDisplay(time.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING &&
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>{time.course?.courseCode || ''} No Slower Than</span>&nbsp;
        </div>
        <div className={global.SmallTableDetailRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Standard Type</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}> {time.course?.courseCode || ''} No Slower Than</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Time</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.qualifyingTime ? formatTimeForDisplay(time.qualifyingTime) : <span>Not Used By Event</span>}</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Standard Event</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.event?.eventName || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Alternate Event?</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.event?.relatedScyEventId === event.event?.relatedScyEventId || time.event?.relatedScmEventId === event.event?.relatedScmEventId || time.event?.relatedLcmEventId === event.event?.relatedLcmEventId ? 'No' : 'Yes'}</div>
          </div>
        </div>
      </div>
    }
    {showNoFasterThan === true && formatTimeForFilterObject(time.limitTime) !== Constants.MIN_SWIM_TIME_STRING &&
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>No Faster Than</span>&nbsp;
        </div>
        <div className={global.SmallTableDetailRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Standard Type</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{time.course?.courseCode || ''} No Faster Than</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Time</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.limitTime ? formatTimeForDisplay(time.limitTime) : <span>Not Used By Event</span>}</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Standard Event</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.event?.eventName || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Alternate Event?</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.event?.relatedScyEventId === event.event?.relatedScyEventId || time.event?.relatedScmEventId === event.event?.relatedScmEventId || time.event?.relatedLcmEventId === event.event?.relatedLcmEventId ? 'No' : 'Yes'}</div>
          </div>
        </div>
      </div>}
    {showBonus === true && formatTimeForDisplay(time.bonusTime) !== Constants.MAX_SWIM_TIME_STRING &&
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>Bonus</span>&nbsp;
        </div>
        <div className={global.SmallTableDetailRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Standard Type</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{time.course?.courseCode || ''} Bonus</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Time</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.bonusTime ? formatTimeForDisplay(time.bonusTime) : <span>Not Used By Event</span>}</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Standard Event</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.event?.eventName || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Alternate Event?</div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{time.event?.relatedScyEventId === event.event?.relatedScyEventId || time.event?.relatedScmEventId === event.event?.relatedScmEventId || time.event?.relatedLcmEventId === event.event?.relatedLcmEventId ? 'No' : 'Yes'}</div>
          </div>
        </div>
      </div>}
  </Fragment >
);

const SmallGridDetailTable = ({ event, meetEventQualifyingTime, meetEventQualificationLimit, meetEventBonusTime, showNoSlowerThan, showNoFasterThan, showBonus }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(meetEventQualifyingTime) && meetEventQualifyingTime.length > 0 ? meetEventQualifyingTime.map((time, i) =>
      <SmallDetailTableRow key={i} event={event} time={time} showNoSlowerThan={showNoSlowerThan} />) : <Fragment />}
    {Array.isArray(meetEventQualificationLimit) && meetEventQualificationLimit.length > 0 ? meetEventQualificationLimit.map((time, i) =>
      <SmallDetailTableRow key={i} event={event} time={time} showNoFasterThan={showNoFasterThan} />) : <Fragment />}
    {Array.isArray(meetEventBonusTime) && meetEventBonusTime.length > 0 ? meetEventBonusTime.map((time, i) =>
      <SmallDetailTableRow key={i} event={event} time={time} showBonus={showBonus} />) : <Fragment />}
    {Array.isArray(meetEventQualifyingTime) && (meetEventQualifyingTime.length === 0 || meetEventQualifyingTime.filter(x => formatTimeForDisplay(x.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING).length === 0) &&
      Array.isArray(meetEventQualificationLimit) && (meetEventQualificationLimit.length === 0 || meetEventQualificationLimit.filter(x => formatTimeForFilterObject(x.limitTime) !== Constants.MIN_SWIM_TIME_STRING).length === 0) &&
      Array.isArray(meetEventBonusTime) && (meetEventBonusTime.length === 0 || meetEventBonusTime.filter(x => formatTimeForDisplay(x.bonusTime) !== Constants.MAX_SWIM_TIME_STRING).length === 0) ?
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>Time Standard Cuts</span>&nbsp;
        </div>
        <div className={global.SmallTableDetailRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 hidden-xs', global.SmallTableRowLabels].join(' ')}>Not Used By Event</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Time Standard Cuts</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>Not Used By Event</div>
          </div>
        </div>
      </div> : <Fragment />}
  </div>
);

const SmallGrid = ({ state, filteredGridState, onEdit, onDelete, showToggle, showNoSlowerThan, showNoFasterThan, showBonus, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Events</div>
            }
          </div>
        </div>
      ) : Array.isArray(filteredGridState) && filteredGridState.length > 0
        ? filteredGridState.map((event, i) => (
          <Fragment key={event?.meetEventId + 'Fragment'}>
            <div className={global.SmallTableRow} key={event?.meetEventId || i}>
              <div className={global.SmallTableRowHead}>
                <span className='hidden-xs'>{event?.eventNumber || ''}</span>&nbsp;
                {showToggle === true &&
                  <button className={global.IconButton}
                    type="button"
                    onClick={(e) => onClick(e, event?.meetEventId)}>
                    {expandedId === event?.meetEventId ? <HideIcon /> : <ShowIcon />}
                  </button>}
                {onDelete &&
                  <button className={showToggle === true ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onDelete(e, event)}>
                    <DeleteIcon />
                  </button>}
                {onEdit &&
                  <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, event)}>
                    <EditIcon />
                  </button>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Event Number</div>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{event?.eventNumber || <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Event</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{event?.event?.eventName || <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{event?.eventCompetitionGenderType?.typeName || <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Age Group</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{event.ageGroupName || ''} ({event?.minAge || <span>&nbsp;</span>} - {event?.maxAge || <span>&nbsp;</span>})</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Session</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{event?.sessionOrderId || <span>&nbsp;</span>} ({event?.sessionType} {event?.sessionDate ? formatDate(event?.sessionDate) : <span>&nbsp;</span>})</div>
                </div>
              </div>
            </div>
            {Array.isArray(event?.meetAgeGroupTimeStandard) && event?.meetAgeGroupTimeStandard.length > 0 && expandedId === event?.meetEventId
              && <SmallGridDetailTable event={event} meetEventQualifyingTime={event?.meetEventQualifyingTime} meetEventQualificationLimit={event?.meetEventQualificationLimit} meetEventBonusTime={event?.meetEventBonusTime}
                showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus} />}
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Events</div>
            </div>
          </div>
        )
    }
  </div >
);

const OmeEventsGrid = ({ state, gridState, gridFilters, onEdit, onDelete, showToggle, showNoSlowerThan, showNoFasterThan, showBonus }) => {
  const { expandedId, onClick } = useExpandedRow();
  const { filteredGridState } = useOmeEventsGrid(gridState, gridFilters);

  return (
    <Fragment>
      <LargeGrid state={state} filteredGridState={filteredGridState} onEdit={onEdit} onDelete={onDelete} showToggle={showToggle}
        showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus}
        expandedId={expandedId} onClick={onClick} />
      <SmallGrid state={state} filteredGridState={filteredGridState} onEdit={onEdit} onDelete={onDelete} showToggle={showToggle}
        showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus}
        expandedId={expandedId} onClick={onClick} />
    </Fragment>
  )
}

export default OmeEventsGrid;