import React, { Fragment } from 'react';

import useOmeRestrictionsTypeCompetitionCategory from './UseOmeRestrictionsTypeCompetitionCategory';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import CompetitionCategoryRadiobuttonList from '../../../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OmeRestrictionsTypeCompetitionCategory = () => {
  const {
    omeMeetState,
    competitionGenderState,
    errorState,
    formState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  } = useOmeRestrictionsTypeCompetitionCategory();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_RESTRICTIONS_TYPE_COMPETITION_CATEGORY}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className="col-xs-12">
              <CompetitionCategoryRadiobuttonList
                label={"Competition Category*"}
                name="competitionGenderTypeId"
                isVertical={true}
                value={formState.competitionGenderTypeId}
                error={errorState.competitionGenderTypeId}
                message={errorState.competitionGenderTypeId}
                onChange={(value) => { onFormValueChanged('competitionGenderTypeId', Number(value)) }}
              />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={competitionGenderState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeRestrictionsTypeCompetitionCategory;