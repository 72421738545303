import { useEffect, useState } from 'react';
import { useNavigate, useParams } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from "../utils/NavLinks";

import useSelectionsData from "../../../state/selections/UseSelectionsData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";

import ToIntIfInt from '../../../../common/utils/ToIntIfInt';

const TAXONOMIES = [''];
const SCOPE = 'Selections';

const useSelectionsById = () => {
  const navigate = useNavigate();
  const { selectionMeetId } = useParams();
  const { contextSecurityState, getContextSecurity, login } = useSecurityData();
  const { selectionsState, getSelectionConfiguration, resetSelectionsState } = useSelectionsData();
  const [tryGetByIdState, setTryGetByIdState] = useState(false);
  const targetSelectionMeetId = ToIntIfInt(selectionMeetId);

  useEffect(() => {
    resetSelectionsState();
    setTryGetByIdState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (targetSelectionMeetId > 0 && tryGetByIdState === true) {
      const getSelectionConfigurationPromise = getSelectionConfiguration(targetSelectionMeetId);

      if (getSelectionConfigurationPromise !== null) {
        getSelectionConfigurationPromise.then((newState) => {
          if (newState !== null) {
            if (contextSecurityState.isObjLoaded !== true || contextSecurityState.contextId !== targetSelectionMeetId) {
              getContextSecurity(targetSelectionMeetId, TAXONOMIES, SCOPE);
            }
          }
        }).catch((e) => {
          // assume they timed out, send to login
          login();
        });
      }
    } else if (!(targetSelectionMeetId > 0)) {
      // if no target id, send back to search
      navigate(NavLinks.SELECTIONS_NEW_SELECTION_SEARCH, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetSelectionMeetId, tryGetByIdState]);

  useEffect(() => {
    if (tryGetByIdState === true) {
      if (selectionsState.isObjLoaded === true && selectionsState.objData.selectionMeetId === targetSelectionMeetId
        && contextSecurityState.isObjLoaded === true && contextSecurityState.isObjLoading !== true &&
        contextSecurityState.contextId === targetSelectionMeetId) {
        if (selectionsState.objData.isConfigComplete === true) {
          // skip to participant grid if config is done
          navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
        } else {
          navigate(NavLinks.SELECTIONS_CONFIGURATION);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState, selectionsState, tryGetByIdState]);

  return {};
};

export default useSelectionsById;
