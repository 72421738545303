import { useEffect } from 'react';

import validate from './ConfigurationFormValidation';

import { getCompetitionGenderName } from '../../utils/SelectionsUtils';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  meetHost: '',
  meetName: '',
  meetDates: '',
  competitionGenderTypeName: '',
  selectionDesc: '',
  maxSwimmers: 0,
  maxDivers: 0,
  minRowsToSeed: 0,
  minRelayRowsToAccept: 0,
  addRatioIndRows: 0,
  addRatioRelayRows: 0,
  includeEntriesNotCheckedOut: false
};

const useConfigurationForm = (onSubmitFormCallback, selectionsStateObj) => {
  const { formState, errorState, isDirty, setIsDirty, handleSubmit, updateFormState, setFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const getTotalParticipants = (maxSwimmers, maxDivers) => {
    const swimmers = parseInt(maxSwimmers);
    const divers = parseInt(maxDivers);

    if (!isNaN(swimmers) && !isNaN(divers)) {
      return `${swimmers + divers}`;
    } else {
      return '...';
    }
  };

  useEffect(() => {
    if (selectionsStateObj) {
      setFormState({
        ...formState,
        meetHost: selectionsStateObj.meetHost || '',
        meetName: selectionsStateObj.meetName || '',
        meetDates: `${selectionsStateObj.meetStartDate ? formatDate(selectionsStateObj.meetStartDate) : Constants.BLANK_DATE_STRING} - ${selectionsStateObj.meetEndDate ? formatDate(selectionsStateObj.meetEndDate) : Constants.BLANK_DATE_STRING}`,
        competitionGenderTypeName: getCompetitionGenderName(selectionsStateObj.selectMales, selectionsStateObj.selectFemales),
        selectionDesc: selectionsStateObj.selectionDesc || '',
        maxSwimmers: selectionsStateObj.maxSwimmers || 0,
        maxDivers: selectionsStateObj.maxDivers || 0,
        minRowsToSeed: selectionsStateObj.minRowsToSeed || 0,
        minRelayRowsToAccept: selectionsStateObj.minRelayRowsToAccept || 0,
        addRatioIndRows: selectionsStateObj.addRatioIndRows || 0,
        addRatioRelayRows: selectionsStateObj.addRatioRelayRows || 0,
        includeEntriesNotCheckedOut: selectionsStateObj.includeEntriesNotCheckedOut === true ? true : false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsStateObj]);

  //TODO better way to do this?
  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    getTotalParticipants
  };
};

export default useConfigurationForm;