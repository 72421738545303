import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../utils/NavLinks';

import UseLaneSheetMeetEventRelayTeamsData from '../../../../state/laneSheetMeetEventRelayTeams/UseLaneSheetMeetEventRelayTeamsData'

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_POST_RELAY_TEAM_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_RELAY_TEAM_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  relayTeam: {}
};

const useLaneSheetMeetEventsAddRelayTeams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { laneSheetMeetEventRelayTeamsState, postLaneSheetMeetEventRelayTeam, deleteLaneSheetMeetEventRelayTeam,
    getLaneSheetMeetEventRelayTeams } = UseLaneSheetMeetEventRelayTeamsData();
  const [postRelayTeamState, setPostRelayTeamState] = useState(INITIAL_POST_RELAY_TEAM_STATE);
  const [deleteRelayTeamState, setDeleteRelayTeamState] = useState(INITIAL_DELETE_RELAY_TEAM_STATE);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.LANE_SHEET_MEET_EVENTS);
  };

  const onDeleteRelayTeamClicked = (relayTeam) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      relayTeam
    });
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeleteModalDeleteClicked = () => {
    const laneSheetRelayTeamId = deleteModalState.relayTeam?.laneSheetRelayTeamId;

    if (laneSheetRelayTeamId > 0) {
      const deleteLaneSheetMeetEventRelayTeamPromise = deleteLaneSheetMeetEventRelayTeam(laneSheetRelayTeamId, deleteRelayTeamState, setDeleteRelayTeamState);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLaneSheetMeetEventRelayTeamPromise !== null) {
        deleteLaneSheetMeetEventRelayTeamPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

            if (laneSheetEventId > 0) {
              const getLaneSheetMeetEventRelayTeamsPromise = getLaneSheetMeetEventRelayTeams(laneSheetEventId);

              if (getLaneSheetMeetEventRelayTeamsPromise !== null) {
                getLaneSheetMeetEventRelayTeamsPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  const onSubmitFormCallback = (formState) => {
    const relayTeams = formState.orgUnits;
    const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

    if (Array.isArray(relayTeams) && relayTeams.length > 0 && laneSheetEventId > 0) {
      const laneSheetMeetEventRelayTeamObj = {
        laneSheetEventId,
        orgUnits: relayTeams.map((x) => { return { orgUnitId: x.id } })
      };

      const postLaneSheetMeetEventRelayTeamPromise = postLaneSheetMeetEventRelayTeam(laneSheetMeetEventRelayTeamObj, postRelayTeamState, setPostRelayTeamState);

      if (postLaneSheetMeetEventRelayTeamPromise !== null) {
        postLaneSheetMeetEventRelayTeamPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

            if (laneSheetEventId > 0) {
              const getLaneSheetMeetEventRelayTeamsPromise = getLaneSheetMeetEventRelayTeams(laneSheetEventId);

              if (getLaneSheetMeetEventRelayTeamsPromise !== null) {
                getLaneSheetMeetEventRelayTeamsPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  useEffect(() => {
    const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

    if (laneSheetEventId > 0) {
      const getLaneSheetMeetEventRelayTeamsPromise = getLaneSheetMeetEventRelayTeams(laneSheetEventId);

      if (getLaneSheetMeetEventRelayTeamsPromise !== null) {
        getLaneSheetMeetEventRelayTeamsPromise.catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return {
    isSaving: postRelayTeamState.isObjLoading || deleteRelayTeamState.isObjLoading,
    isLoading: laneSheetMeetEventRelayTeamsState.isArrayLoading,
    meetEvent: location.state?.meetEvent || {},
    gridData: laneSheetMeetEventRelayTeamsState.arrayData || [],
    deleteModalState,
    onBackClicked,
    onSubmitFormCallback,
    onDeleteRelayTeamClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  };
};

export default useLaneSheetMeetEventsAddRelayTeams;