/* eslint-disable jsx-a11y/anchor-is-valid */

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ relayTeam, onDeleteClicked }) => (
  <tr>
    <td>{relayTeam?.orgUnitName || ''}</td>
    <td>{relayTeam?.orgUnitCode || ''}</td>
    <td>
      {relayTeam.canBeDeleted === true &&
        <button
          type='button'
          className={global.IconButtonMargin}
          onClick={() => onDeleteClicked(relayTeam)}><DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const LaneSheetMeetEventAddRelayTeamsLargeGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Team Name</th>
        <th>Org Unit Code</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((relayTeam, i) =>
            <LargeGridRow
              key={i}
              relayTeam={relayTeam}
              onDeleteClicked={onDeleteClicked}
            />)
          : <tr><td colSpan="3">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetMeetEventAddRelayTeamsLargeGrid