import React, { Fragment } from 'react';

import useAddMeetTimeSelectOrgUnits from './UseAddMeetTimeSelectOrgUnits';

import Headings from '../../../../../common/components/headings/Headings';
import TreeView from '../../../../../common/components/tree/TreeView';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

const AddMeetTimeSelectOrgUnits = () => {
  const {
    formState,
    errorState,
    treeState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = useAddMeetTimeSelectOrgUnits();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Times Results-Add Athlete(s)</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Headings.H6>Select Organizations for Athlete Roster</Headings.H6>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className='col-xs-12'>
            <TreeView
              label="Organization(s)*"
              name="orgUnits"
              value={formState.orgUnits}
              options={treeState.treeData}
              selectableLeavesOnly={true}
              singleSelect={false}
              error={errorState.orgUnits}
              message={errorState.orgUnits}
              isLoading={treeState.isArrayLoading}
              onChange={(newValue) => { onFormValueChanged('orgUnits', newValue) }} />
          </div>
        </div>
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className='col-xs-12'>
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction ? errorState.errorOnSubmitAction : ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default AddMeetTimeSelectOrgUnits;