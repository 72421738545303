import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";

import useSelectionsData from "../../../state/selections/UseSelectionsData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";

import Constants from "../../../../common/utils/Constants";

const TAXONOMIES = [''];
const SCOPE = 'Selections';

const useSelectionsNewSelectionSearch = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { newSelectionSearchState, newSelectionSearchFilterState, searchNewSelection, toggleNewSelectionSearchFilters,
    postSelection, resetSelectionsState
  } = useSelectionsData();

  const onCreateSelectionClicked = (omeMeetId, selectMales, selectFemales) => {
    const selectionsObj = {
      omeMeetId,
      selectMales,
      selectFemales
    };

    getContextSecurity(1, TAXONOMIES, SCOPE);

    const postSelectionPromise = postSelection(selectionsObj);

    if (postSelectionPromise !== null) {
      postSelectionPromise.then((newState) => {
        if (newState !== null) {
          navigate(NavLinks.SELECTIONS_CONFIGURATION);
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  };

  const onSubmitFormCallback = (formState) => {
    const searchNewSelectionPromise = searchNewSelection(createFilterObj(formState));

    if (searchNewSelectionPromise !== null) {
      searchNewSelectionPromise.catch((e) => {
        console.log(e);
      });
    }
  };

  const onExistingSelectionsClicked = (omeMeetId, meetName) => {
    navigate(NavLinks.SELECTIONS_EXISTING_SELECTIONS, { state: { omeMeetId, meetName } });
  };

  useEffect(() => {
    resetSelectionsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createFilterObj(formState) {
    return {
      orgUnitId: formState.orgUnitId || undefined,
      startDate: formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate: formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : undefined,
    };
  };

  return {
    isLoading: newSelectionSearchState.isArrayLoading,
    gridData: newSelectionSearchState.arrayData || [],
    showFilters: newSelectionSearchFilterState.showFilters,
    filterObject: newSelectionSearchFilterState.filterObject || {},
    onCreateSelectionClicked,
    onSubmitFormCallback,
    onExistingSelectionsClicked,
    toggleNewSelectionSearchFilters
  };
};

export default useSelectionsNewSelectionSearch;