import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postBulkLaneSheetMeetEventsOmeData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/MeetEvent/Ome`;

    return api?.executeArray ? api.executeArray(url, 'POST') : null;
  }
};

const postLaneSheetMeetEventData = (laneSheetMeetId, postLaneSheetMeetEventObj, state, setState) => {
  if (laneSheetMeetId > 0 && postLaneSheetMeetEventObj) {
    let newState = {
      ...state,
      laneSheetMeetId
    };

    const api = initApi(API_NAMES.MEET, newState, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/MeetEvent/Event`;
    return api?.executeObject ? api.executeObject(url, 'POST', postLaneSheetMeetEventObj) : null;
  }
};

const getLaneSheetMeetEventsData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/MeetEvent`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const deleteLaneSheetMeetEventData = (laneSheetEventId, state, setState) => {
  if (laneSheetEventId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const laneSheetEventIdForUrl = encodeURIComponent(laneSheetEventId);
    const url = `/LaneSheetEvent/${laneSheetEventIdForUrl}`;
    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LaneSheetMeetEventsData = {
  postBulkLaneSheetMeetEventsOmeData,
  postLaneSheetMeetEventData,
  getLaneSheetMeetEventsData,
  deleteLaneSheetMeetEventData
};

export default LaneSheetMeetEventsData;