import { useEffect } from 'react';

import useSelectionsUnavailableReasonsData from '../../state/selectionsUnavailableReasons/UseSelectionsUnavailableReasonsData';

const useSelectionsUnavailableReasonsDropdown = (makeGetCall) => {
  const { selectionsUnavailableReasonsState, setSelectionsUnavailableReasonsState, getSelectionsUnavailableReasons
  } = useSelectionsUnavailableReasonsData();

  useEffect(() => {
    if (makeGetCall === true) {
      if (selectionsUnavailableReasonsState.isArrayLoading !== true && selectionsUnavailableReasonsState.isArrayLoaded !== true) {
        const getSelectionsUnavailableReasonsPromise = getSelectionsUnavailableReasons();

        if (getSelectionsUnavailableReasonsPromise !== null) {
          getSelectionsUnavailableReasonsPromise.then((newState) => {
            if (newState !== null) {
              setSelectionsUnavailableReasonsState({
                ...newState,
                items: newState.arrayData.map(x => { return { id: x.unavailableReasonId, name: x.unavailableReasonName }; }),
                areItemsLoaded: true
              });
            }
          }).catch((e) => {
            console.log(e);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsUnavailableReasonsState, makeGetCall]);

  return {
    selectionsUnavailableReasonsState
  };
};

export default useSelectionsUnavailableReasonsDropdown;