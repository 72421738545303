import React, { Fragment } from 'react';

import useOrgUnitAdminRosterEntriesBonusIndividualOverride from './UseOrgUnitAdminRosterEntriesBonusIndividualOverride';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import TimeInput from '../../../../../common/components/inputs/TimeInput';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import EventMeetEventBonusDropdown from '../../../../components/dropdowns/EventMeetEventBonusDropdown';
import Input from '../../../../../common/components/inputs/Input';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterEntriesBonusIndividualOverride = () => {
  const { eventMeetEventBonusState, state, orgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryState,
    onSaveClicked, onBackClicked, onValueTextPairChanged, onFormValueChanged, formState, errorState
  } = useOrgUnitAdminRosterEntriesBonusIndividualOverride();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.isEdit === true ? 'Edit' : 'Add'} Individual Bonus Event Entry Override Time</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <Headings.H4 className={global.HeaderText}>Athlete: {(state?.athlete?.firstName || state?.athlete?.middleName || state?.athlete?.lastName) ? `${state?.athlete?.firstName || ''} ${state?.athlete?.preferredName !== '' && state?.athlete?.preferredName !== state?.athlete?.firstName ? '"' + state?.athlete?.preferredName + '"' : ''} ${state?.athlete?.middleName || ''} ${state?.athlete?.lastName || ''}` : ''} ({state?.athlete?.competitionGenderTypeName || ''}, {state?.athlete?.ageAtMeet >= 0 ? state?.athlete?.ageAtMeet : ''})</Headings.H4>
          <p className={global.HeaderText}><b>Individual Bonus Event:</b> {state.event?.eventName || ''} ({state.event?.eventCompetitionGenderTypeName || ''}, {state.event?.ageGroupName || ''}), Session {state.event?.meetSessionName || ''}</p>
          <p className={global.HeaderText}><b>Qualifying Date Range:</b> {`${formState.qualifyingStartDate} - ${formState.qualifyingEndDate}`}</p>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimeInput
              label="Swim Time*"
              name="swimTime"
              value={formState.swimTime}
              error={errorState.swimTime}
              message={errorState.swimTime}
              onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'swimTime', value, 'swimTimeValue'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <EventMeetEventBonusDropdown
              label="Event*"
              name="eventId"
              value={formState.eventId}
              error={errorState.eventId}
              message={errorState.eventId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'eventId', newValueLabel, 'eventName')
              }}
              meetEventId={state.event?.meetEventId}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Swim Date*"
              name="swimDate"
              value={formState.swimDate}
              error={errorState.swimDate}
              message={errorState.swimDate}
              onChange={(value) => { onFormValueChanged('swimDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={formState.usasHostOrg === true ? formState.usasLsc === true ? "Meet Name* (Do not include Year or LSC)" : "Meet Name* (Do not include Year)" : "Meet Name*"}
              name="meetName"
              value={formState.meetName}
              error={errorState.meetName}
              message={errorState.meetName}
              onChange={(value) => { onFormValueChanged('meetName', value) }} />
          </div>
        </div>
      </form>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryState.isArrayLoading || eventMeetEventBonusState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitAthleteEntryIndividualRosterEntryState.isSaving} />
    </Fragment >
  );
};

export default OrgUnitAdminRosterEntriesBonusIndividualOverride;