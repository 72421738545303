import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const getRecordGroups = (state, setState) => {
  let newState = {
    ...state,
  };

  const api = initApi(API_NAMES.COMMON, newState, setState);

  const url = `/RecordGroup`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const RecordGroupsData = {
  getRecordGroups
};

export default RecordGroupsData;