import React, { createContext, useState } from 'react';

import EventData from './EventData';

export const EventStateContext = createContext();

const EventContextProvider = ({ children }) => {
  const stateHook = useState(EventData.INITIAL_STATE);

  return (
    <EventStateContext.Provider value={stateHook}>
      {children}
    </EventStateContext.Provider>
  );
};

export default EventContextProvider;