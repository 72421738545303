import { useEffect, useState } from 'react';

import useSelectionsData from '../../../../state/selections/UseSelectionsData';

const INITIAL_VIEW_STATE = {
  reportParameters: { selectionMeetId: '' },
  routeName: ''
};

const useSelectionsReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { selectionsState } = useSelectionsData();

  useEffect(() => {
    if (selectionsState.objData?.selectionMeetId) {
      setViewState({
        ...viewState,
        reportParameters: { selectionMeetId: selectionsState.objData.selectionMeetId },
        routeName: 'SELECTIONS_REPORTS'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState.objData]);

  return { ...viewState };
};

export default useSelectionsReporting;