import { useEffect, useState } from 'react';
import useDateTimeData from '../../state/datetime/UseDateTimeData';

const INITIAL_STATE = {
  month: '00',
  day: '00',
  year: '0000',
  time: '99:99',
  years: [],
  isLoaded: false
};

const useDateTimePicker = (value, countOfYears, startYearOffset, onChange) => {
  const { dateTimeState, breakUpDateTime, getYearsAsOptions } = useDateTimeData();
  const [state, setState] = useState(INITIAL_STATE);

  const onValueChanged = (newValue, e, datePart) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const month = datePart === 'month' ? newValue : state.month;
    const day = datePart === 'day' ? newValue : state.day;
    const year = datePart === 'year' ? newValue : state.year;
    const time = datePart === 'time' ? newValue : state.time;
    setState({ ...state, month, day, year, time });
  };

  useEffect(() => {
    setState({
      ...state,
      years: getYearsAsOptions(countOfYears, startYearOffset),
      isLoaded: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isLoaded === true) {
      const dateTime = breakUpDateTime(value);
      setState({
        ...state,
        month: dateTime.month,
        day: dateTime.day,
        year: dateTime.year,
        time: dateTime.time
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoaded]);

  useEffect(() => {
    const newDate = `${state.month}/${state.day}/${state.year}`;
    const newValue = `${newDate} ${state.time}`;
    if (state.isLoaded === true && value && newValue && value !== newValue) {
      onChange(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    ...state,
    months: dateTimeState?.objData?.months || [],
    days: dateTimeState?.objData?.days || [],
    years: state?.years || [],
    times: dateTimeState?.objData?.times || [],
    onMonthChanged: (newValue, l, e) => onValueChanged(newValue, e, 'month'),
    onDayChanged: (newValue, l, e) => onValueChanged(newValue, e, 'day'),
    onYearChanged: (newValue, l, e) => onValueChanged(newValue, e, 'year'),
    onTimeChanged: (newValue, l, e) => onValueChanged(newValue, e, 'time')
  };
};

export default useDateTimePicker;