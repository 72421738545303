
import { Fragment } from "react";

import useExportEntries from './UseExportEntries';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import Headings from "../../../../common/components/headings/Headings";
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import CountryCombobox from "../../../../common/components/comboboxes/countryCombobox/CountryCombobox";
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ExportEntries = () => {
  const {
    omeMeetState,
    simpleFormState,
    simpleErrorState,
    omeEntriesExportState,
    onEntriesExportClicked,
    onSimpleFormValueChanged,
    onSimpleValueTextPairChanged
  } = useExportEntries();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Export Entries</Headings.H3>
        </div>
      </div>
      {omeMeetState.objData?.omeMeetId ?
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <YesNoSwitch
                label="International Meet?"
                name="isInternationalMeet"
                checked={simpleFormState.isInternationalMeet}
                error={simpleErrorState.isInternationalMeet}
                message={simpleErrorState.isInternationalMeet}
                onChange={(value) => { onSimpleFormValueChanged('isInternationalMeet', value) }} />
            </div>
            {simpleFormState.isInternationalMeet === true &&
              <div className={"col-xs-12 col-sm-6 col-md-4"}>
                <CountryCombobox
                  label="Country*"
                  name="country"
                  valueToMatch={simpleFormState.countryName}
                  error={simpleErrorState.country}
                  message={simpleErrorState.country}
                  onChange={(newValue, newValueLabel) => { onSimpleValueTextPairChanged(newValue, 'country', newValueLabel, 'countryName'); }} />
              </div>}
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PrimaryButton onClick={onEntriesExportClicked}>Export Entries</PrimaryButton>
            </div>
          </div>
        </Fragment> :
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <p className={global.HeaderText}>The OME Meet has not been created yet. Exporting Entries is not allowed at this time.</p>
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeEntriesExportState.isObjLoading} />
    </Fragment>);
}

export default ExportEntries;