import useParticipantRowCell from "./UseParticipantRowCell";

import style from './SelectionParticipantsGrid.module.css';

const SelectedCell = ({ cell, showDetailPopupsInGrid, onParticipantClicked }) => {
  const {
    getFormattedName,
    onEnterCell,
    onLeaveCell
  } = useParticipantRowCell();

  if (showDetailPopupsInGrid === true) {
    return (
      <td className={style.SelectedCell} //check selectionMeetPersonId to exclude relays
        style={cell.selectionMeetPersonId > 0 && cell.willAttend === false //TODO temp grid styling for will attend
          ? { backgroundColor: '#000000', color: '#FFFFFF' }
          : { backgroundColor: `#${cell.displayColor}` }}
        onClick={() => onParticipantClicked(cell)}
        onMouseEnter={(e) => { onEnterCell(e, cell) }}
        onMouseLeave={onLeaveCell}>
        {getFormattedName(cell.firstName, cell.lastName, cell.relayTeamName)}
      </td>
    );
  } else {
    return (
      <td className={style.SelectedCell} //check selectionMeetPersonId to exclude relays
        style={cell.selectionMeetPersonId > 0 && cell.willAttend === false //TODO temp grid styling for will attend
          ? { backgroundColor: '#000000', color: '#FFFFFF' }
          : { backgroundColor: `#${cell.displayColor}` }}
        onClick={() => onParticipantClicked(cell)}>
        {getFormattedName(cell.firstName, cell.lastName, cell.relayTeamName)}
      </td>
    );
  }
};

const ParticipantRowCell = ({ cell, showDetailPopupsInGrid, onParticipantClicked }) => {
  if (cell?.isSelected === true) {
    return <SelectedCell cell={cell} showDetailPopupsInGrid={showDetailPopupsInGrid} onParticipantClicked={onParticipantClicked} />;
  } else {
    return (
      <td></td>
    );
  }
};

export default ParticipantRowCell;