import React, { Fragment } from 'react';

import useSelectionsParticipantSelections from './UseSelectionsParticipantSelections';

import SelectionsParticipantSelectionsPopup from './SelectionsParticipantSelectionsPopup';

import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import ParticipantSelectionsReadOnlyForm from '../components/forms/ParticipantSelectionsReadOnlyForm';
import SelectionsLoadingModal from '../components/dialogs/SelectionsLoadingModal';
import { PARTICIPANT_SELECTIONS_NAME } from '../components/leftNav/SelectionsLeftNavConstants';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const SelectionsParticipantSelections = () => {
  const {
    isLoading,
    state,
    selectionsState,
    selectionsParticipantsState,
    onShowSelectionGridClicked,
    onCompleteClicked,
    onBackClicked
  } = useSelectionsParticipantSelections();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={PARTICIPANT_SELECTIONS_NAME} viewTitle={'Participant Selections'}>
        <div className="row">
          <div className='col-xs-12'>
            <ActionIntraPageButton onClick={onShowSelectionGridClicked}>Show Selection Grid</ActionIntraPageButton>
          </div>
        </div>
        <ParticipantSelectionsReadOnlyForm
          selectionsStateObj={selectionsState.objData}
          selectionsParticipantsStateObj={selectionsParticipantsState.objData} />
        {state.displayCompletionError === true &&
          <div className='row'>
            <div className="col-xs-12 usas-extra-bottom-margin">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{state.completionErrorMessage}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onCompleteClicked}>Complete</PrimaryButton>
          </div>
        </div>
        <SelectionsParticipantSelectionsPopup />
        <SelectionsLoadingModal isLoading={isLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
};

export default SelectionsParticipantSelections;