import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import GetInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import {
  eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete, restrictionsIsComplete,
  sessionsIsComplete, ageGroupsIsComplete
} from './LeftNavValidation';

const getAgeGroups = (viewName, route, omeMeetStateObjData, omeMeetInvitationsStateArrayData, meetStateObjData, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = viewName === Views.OME_MEET_AGE_GROUPS;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);
  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);
  const isRestrictionsComplete = restrictionsIsComplete(omeMeetStateObjData, restrictionsRoutePermission);
  const isInvitationsComplete = invitationsIsComplete(omeMeetInvitationsStateArrayData);
  const isSessionsComplete = sessionsIsComplete(meetStateObjData);

  const isComplete = isMeetInfoComplete === true && isEligibilityComplete === true &&
    isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true &&
    ageGroupsIsComplete(omeMeetStateObjData);

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true
    && isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true;

  const item = route === '' ? GetInitialStateOmeRoute(Views.OME_MEET_AGE_GROUPS) : getInitialStateMeetRoute(Views.OME_MEET_AGE_GROUPS);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getAgeGroups;