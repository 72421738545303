import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDateTime } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const MeetTeamEntrySmallGrid = ({ gridData, isLoading, teamEntryCurrentlyAllowed, onView, onEdit, onDeleteTeamEntryClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((teamEntry, i) => {
          return (
            <div className={global.SmallTableRow} key={i}>
              <div className={global.SmallTableRowHead}>{teamEntry.isReadOnly === true ? <span /> : <span className='hidden-xs'>{teamEntry.orgUnitName || <span>&nbsp;</span>} {teamEntry.orgUnitName === 'Unattached' ? (teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '') : ''} {teamEntry?.parentOrgUnitCode ? '(' + teamEntry?.parentOrgUnitCode + ')' : ''}</span>}
                {(teamEntry.teamEntryDeleteCurrentlyAllowed === true || teamEntryCurrentlyAllowed === true || teamEntry.teamEntryChangesCurrentlyAllowed === true) && <span>&nbsp;</span>}
                {teamEntry.isReadOnly === true &&
                  <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onView(e, teamEntry)}>View</ActionIntraPageButton></div>}
                {teamEntry.teamEntryDeleteCurrentlyAllowed === true &&
                  <button className={global.IconButton}
                    type="button"
                    onClick={(e) => onDeleteTeamEntryClicked(e, teamEntry)}>
                    <DeleteIcon />
                  </button>}
                {(teamEntryCurrentlyAllowed === true || teamEntry.teamEntryChangesCurrentlyAllowed === true) &&
                  <Fragment>
                    {teamEntry.teamEntryDeleteCurrentlyAllowed === true ?
                      <button className={global.IconButtonMobileMargin}
                        type="button"
                        onClick={(e) => onEdit(e, teamEntry)}>
                        <EditIcon />
                      </button>
                      :
                      <button className={global.IconButton}
                        type="button"
                        onClick={(e) => onEdit(e, teamEntry)}>
                        <EditIcon />
                      </button>}
                  </Fragment>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  {teamEntryCurrentlyAllowed === false && teamEntry.teamEntryChangesCurrentlyAllowed === false ?
                    <span>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Team Name</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry.orgUnitName || <span>&nbsp;</span>} {teamEntry.orgUnitName === 'Unattached' ? (teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '') : ''} {teamEntry?.parentOrgUnitCode ? '(' + teamEntry?.parentOrgUnitCode + ')' : ''}</div>
                    </span> :
                    <span>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Team Name</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{teamEntry.orgUnitName || <span>&nbsp;</span>} {teamEntry.orgUnitName === 'Unattached' ? (teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '') : ''} {teamEntry?.parentOrgUnitCode ? '(' + teamEntry?.parentOrgUnitCode + ')' : ''}</div>
                    </span>
                  }
                  <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Team Entry Status</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry.status || 'Unknown'} {teamEntry.orgUnitSubmissionDatetime ? '- ' + formatDateTime(teamEntry.orgUnitSubmissionDatetime) + ' ' + teamEntry.timeZone : ''}</div>
                </div>
              </div>
            </div>
          )
        }) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Team Entries</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetTeamEntrySmallGrid;