import React, { createContext, useState } from 'react';

import MeetCompetitionSummaryData from './MeetCompetitionSummaryData';

export const MeetCompetitionSummaryStateContext = createContext();

const MeetCompetitionSummaryContextProvider = ({ children }) => {
  const stateHook = useState(MeetCompetitionSummaryData.INITIAL_STATE);

  return (
    <MeetCompetitionSummaryStateContext.Provider value={stateHook}>
      {children}
    </MeetCompetitionSummaryStateContext.Provider>
  );
};

export default MeetCompetitionSummaryContextProvider;