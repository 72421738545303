import { useEffect } from 'react';

import validate from './ParticipantEditFormValidation';

import useSelectionsUnavailableReasonsData from '../../../../state/selectionsUnavailableReasons/UseSelectionsUnavailableReasonsData';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const INITIAL_FORM_STATE = {
  selectionMeetPersonId: Constants.DEFAULT_ID,
  participantName: '',
  parentOrgUnitName: '',
  meetName: '',
  meetDates: '',
  eventCompetitionGenderTypeName: '',
  orgUnitName: '',
  eventCode: '',
  entryTime: '',
  qualifyingSwimDate: '',
  qualifyingMeet: '',
  isSelected: '',
  isAlternate: '',
  selectionReason: '',
  willAttend: true,
  unavailableReasonId: Constants.DEFAULT_ID,
  unavailableReasonName: ''
};

const useParticipantEditForm = (onSubmitFormCallback, participantObj, selectionsStateObj) => {
  const { selectionsUnavailableReasonsState } = useSelectionsUnavailableReasonsData();
  const { formState, errorState, setIsDirty, handleSubmit, onValueTextPairChanged, setFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onWillAttendChanged = (newValue) => {
    setIsDirty(true);

    if (newValue === false) {
      let unavailableReason = null;

      if (Array.isArray(selectionsUnavailableReasonsState.items)) {
        unavailableReason = selectionsUnavailableReasonsState.items[0];
      }

      setFormState({
        ...formState,
        willAttend: newValue,
        unavailableReasonId: unavailableReason?.id || Constants.DEFAULT_ID,
        unavailableReasonName: unavailableReason?.name || ''
      });
    } else {
      setFormState({
        ...formState,
        willAttend: newValue,
        unavailableReasonId: Constants.DEFAULT_ID,
        unavailableReasonName: ''
      });
    }
  };

  useEffect(() => {
    if (participantObj && selectionsStateObj && selectionsUnavailableReasonsState.areItemsLoaded === true) {
      let unavailableReason = null;

      if (participantObj.willAttend !== true && Array.isArray(selectionsUnavailableReasonsState.items)) {
        unavailableReason = selectionsUnavailableReasonsState.items.find(x => x.name === participantObj.unavailableReason);

        if (!unavailableReason) {
          unavailableReason = selectionsUnavailableReasonsState.items[0];
        }
      }

      setFormState({
        ...formState,
        selectionMeetPersonId: participantObj.selectionMeetPersonId > 0 ? participantObj.selectionMeetPersonId : Constants.DEFAULT_ID,
        participantName: `${participantObj.firstName || ''} ${participantObj.lastName || ''}`,
        parentOrgUnitName: participantObj.parentOrgUnitName || '',
        meetName: selectionsStateObj.meetName || '',
        meetDates: `${selectionsStateObj.meetStartDate ? formatDate(selectionsStateObj.meetStartDate) : Constants.BLANK_DATE_STRING} - ${selectionsStateObj.meetEndDate ? formatDate(selectionsStateObj.meetEndDate) : Constants.BLANK_DATE_STRING}`,
        eventCompetitionGenderTypeName: participantObj.eventCompetitionGenderTypeName || '',
        orgUnitName: participantObj.orgUnitName || '',
        eventCode: participantObj.eventCode || '',
        entryTime: participantObj.entryTime ? formatTimeForDisplay(participantObj.entryTime) : '',
        qualifyingSwimDate: participantObj.qualifyingSwimDate ? formatDate(participantObj.qualifyingSwimDate) : '',
        qualifyingMeet: participantObj.qualifyingMeet || '',
        isSelected: participantObj.isSelected === true || participantObj.hasBeenSelected === true ? 'Yes' : 'No',
        isAlternate: participantObj.isAlternate === true ? 'Yes' : 'No',
        selectionReason: participantObj.selectionReason || '',
        willAttend: participantObj.willAttend === true ? true : false,
        unavailableReasonId: unavailableReason?.id > 0 ? unavailableReason.id : Constants.DEFAULT_ID,
        unavailableReasonName: unavailableReason?.name || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantObj, selectionsStateObj, selectionsUnavailableReasonsState.areItemsLoaded]);

  return {
    isLoading: selectionsUnavailableReasonsState.isArrayLoading,
    formState,
    errorState,
    onValueTextPairChanged,
    onWillAttendChanged,
    handleSubmit
  };
};

export default useParticipantEditForm;