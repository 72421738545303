import { isValidDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  
  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  if (!formState.orgUnitId || formState.orgUnitId === Constants.DEFAULT_ID) {
    errors.orgUnitId = `Must select a Organization to search`
  }

  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Meet Start Date Range is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Meet Start Date Range must be a valid date';
  } else if (endDate < startDate) {
    errors.startDate = 'Meet Start Date Range must be before the End Date';
  }

  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'Meet End Date Range is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'Meet End Date Range must be a valid date';
  } else if (endDate < startDate) {
    errors.endDate = 'Meet End Date Range must be after the Start Date';
  }

  return errors;
};

const NewSelectionSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NewSelectionSearchFormValidation;