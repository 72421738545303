/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ rightSideInfo, onDeleteClicked }) => (
  <tr>
    <td>{rightSideInfo?.displayOrder || 0}</td>
    <td>{rightSideInfo?.recordListName || ''}</td>
    <td>{rightSideInfo?.nameToShow || ''}</td>
    <td>{rightSideInfo?.recordsToShow || 0}</td>
    <td>{rightSideInfo?.isRelayOnly === true ? 'Yes' : 'No'}</td>
    <td>
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onDeleteClicked(rightSideInfo)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const LaneSheetMeetRightSideNotesLargeGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Display Order</th>
        <th>Record list</th>
        <th>Name to Show</th>
        <th>Times to Show</th>
        <th>Relay Only?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((rightSideInfo, i) =>
            <LargeGridRow
              key={i}
              rightSideInfo={rightSideInfo}
              onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="6">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetMeetRightSideNotesLargeGrid