import { useEffect } from "react";

import validate from './LaneSheetLandingFormValidation';

import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';
import useMeetClassificationData from "../../../../../common/state/meet/meetClassification/UseMeetClassificationData";

import HierarchicalDataUtils from "../../../../../common/utils/HierarchicalDataUtils";
import useForm from "../../../../../common/utils/UseForm";

const sortByOptions = [
  { id: 1, name: "Meet Name (A-Z)", value: 'MeetName' },
  { id: 2, name: "Meet Date (Oldest to Most Recent)", value: 'StartDate' }];

const useLaneSheetLandingForm = (onSubmitFormCallback, searchFilterObject) => {
  const { meetClassificationState } = useMeetClassificationData();
  const { orgUnitState, getOrgUnitsTopTwoLevels } = useOrgUnitData();
  const {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormState,
    setIsDirty,
    setErrors
  } = useForm(getInitialFormState(), onSubmitFormCallback, validate);

  function getInitialFormState() {
    return {
      orgUnit: orgUnitState.treeData.length > 0
        ? HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [orgUnitState.treeData[0]?.id]) : [],
      meetName: '',
      meetClassification: [],
      hasLaneSheet: '',
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name
    };
  };

  const onOrgUnitChanged = (newValue, newValueLabel) => {
    setFormState({
      ...formState,
      orgUnit: newValue,
      meetClassification: []
    });

    setIsDirty(true);
  };


  const onClearFormClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState(getInitialFormState());
    setErrors({});
  };

  useEffect(() => {
    if (Object.keys(searchFilterObject).length > 0
      && orgUnitState.isArrayLoaded === true && meetClassificationState.isArrayLoaded === true
      && meetClassificationState.orgUnitId === searchFilterObject?.orgUnitId) {
      const oldSearchValues = JSON.parse(JSON.stringify(searchFilterObject));

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (searchFilterObject.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }
 
      const newFormState = {
        ...formState,
        ...oldSearchValues,
        orgUnitId: undefined,
        meetName: searchFilterObject.meetName || '',
        orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
        meetClassification: HierarchicalDataUtils.GetNameIdPairs(meetClassificationState.treeData, [oldSearchValues.meetClassificationId]) || [],
        hasLaneSheet: searchFilterObject.hasLaneSheet ?? '',
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      };

      const validatePromise = validate(newFormState);

      validatePromise.then((errors) => {
        if (Object.keys(errors).length === 0 && Object.keys(searchFilterObject).length > 0) {
          onSubmitFormCallback(newFormState);
        }

        setFormState({
          ...newFormState
        });
      }).catch((e) => {
        console.log(e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    getOrgUnitsTopTwoLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    orgUnitState,
    onOrgUnitChanged,
    onClearFormClicked,
    sortByOptions,
    searchFilterObject
  };
};


export default useLaneSheetLandingForm;