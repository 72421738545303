import { useEffect } from 'react';
import useMeetDistinctEventsData from '../../../common/state/meet/meetDistinctEvents/UseMeetDistinctEventsData';

const useMeetTimesEventCombobox = (meetId) => {
  const { meetDistinctEventsState, getMeetDistinctEvents } = useMeetDistinctEventsData();

  useEffect(() => {
    if (meetDistinctEventsState.isArrayLoaded !== true) {
      getMeetDistinctEvents(meetId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    optionsState: meetDistinctEventsState.arrayAsOptions
  };
};

export default useMeetTimesEventCombobox;