import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LaneSheetHeatRelayTeamsStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LaneSheetHeatRelayTeamsContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LaneSheetHeatRelayTeamsStateContext.Provider value={stateHook}>
      {children}
    </LaneSheetHeatRelayTeamsStateContext.Provider>
  );
};

export default LaneSheetHeatRelayTeamsContextProvider;