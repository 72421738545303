import React, { Fragment } from 'react';

import useSelectionsExistingSelections from './UseSelectionsExistingSelections';

import ExistingSelectionsGrid from '../components/grids/ExistingSelectionsGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const SelectionsExistingSelections = () => {
  const {
    isLoading,
    gridData,
    meetName,
    onEditSelectionClicked,
    onDeleteSelectionClicked,
    onBackToSearchClicked
  } = useSelectionsExistingSelections();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{meetName} Existing Selections</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackToSearchClicked}>
            <LeftArrowIcon />  {'Back To Meet Participant Selection Search'}
          </button>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ExistingSelectionsGrid
            gridData={gridData}
            isLoading={isLoading}
            onEditSelectionClicked={onEditSelectionClicked}
            onDeleteSelectionClicked={onDeleteSelectionClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SelectionsExistingSelections;