import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitAthleteEntryRosterSelectionData from './OmeMeetOrgUnitAthleteEntryRosterSelectionData';

export const OmeMeetOrgUnitAthleteEntryRosterSelectionStateContext = createContext();

const OmeMeetOrgUnitAthleteEntryContextRosterSelectionProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitAthleteEntryRosterSelectionData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitAthleteEntryRosterSelectionStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitAthleteEntryRosterSelectionStateContext.Provider>
  );
};

export default OmeMeetOrgUnitAthleteEntryContextRosterSelectionProvider;