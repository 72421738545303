import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData';
import useAthleteEntryContextData from '../../../../state/athleteEntryContextView/UseAthleteEntryContextData';

import Constants from '../../../../../common/utils/Constants';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Coach from Athlete Entry?',
  coachName: '',
  organization: '',
  isInGoodStanding: '',
  isInGoodStandingExpirationDate: '',
  orgUnitAthleteEntryOrgUnitNonAthleteEntryId: Constants.DEFAULT_ID
};

const useAthleteUserCoaches = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoaches, deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntry } = useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData();
  const { athleteEntryContextState } = useAthleteEntryContextData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [state, setState] = useState({ tryGet: false });

  const onAddCoach = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_COACHES_SELECTION);
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_COACHES_SELECTION);
    }
  };

  const onDeleteCoachClicked = (e, coach) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      coachName: (coach.firstName || coach.middleName || coach.lastName) ? `${coach.firstName || ''} ${coach.preferredName !== '' && coach.preferredName !== coach.firstName ? '"' + coach.preferredName + '"' : ''} ${coach.middleName || ''} ${coach.lastName || ''}` : '',
      organization: `${coach.orgUnitName || ''} ${coach.parentOrgUnitCode ? '(' + coach.parentOrgUnitCode + ')' : ''}`,
      isInGoodStanding: coach.isInGoodStanding === true ? 'Yes' : coach.isInGoodStanding === false ? 'No' : '',
      isInGoodStandingExpirationDate: coach.isInGoodStandingExpirationDate ? formatDate(coach.isInGoodStandingExpirationDate) : '',
      orgUnitAthleteEntryOrgUnitNonAthleteEntryId: coach.orgUnitAthleteEntryOrgUnitNonAthleteEntryId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteCoach = (e, orgUnitAthleteEntryOrgUnitNonAthleteEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntry(orgUnitAthleteEntryOrgUnitNonAthleteEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded === true && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId &&
      omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isSaving === false) {
      if ((omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoaded === false || state.tryGet === true)
        && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading === false) {
        getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoaches(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, state.tryGet])

  return {
    athleteEntryContextState,
    modalState,
    omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
    onAddCoach,
    onDeleteCoachClicked,
    onModalCanceled,
    onDeleteCoach
  };
}

export default useAthleteUserCoaches;