import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";

import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsParticipantsData from "../../../state/selectionsParticipants/UseSelectionsParticipantsData";
import useSelectionsUnavailableReasonsData from "../../../state/selectionsUnavailableReasons/UseSelectionsUnavailableReasonsData";
import useSelectionsLoadingModalData from "../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

// keys for getting relay leg values
const willAttendTrailingKey = 'WillAttend';
const unavailableReasonTrailingKey = 'UnavailableReason';
const unavailableReasonIdTrailingKey = 'UnavailableReasonId';
const unavailableReasonNameTrailingKey = 'UnavailableReasonName';
const selectionMeetPersonIdTrailingKey = 'SelectionMeetPersonId';

const INITIAL_STATE = {
  doLoopingPut: false,
  participantLegsToPut: []
};

const useSelectionsParticipantSelectionsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectionsState } = useSelectionsData();
  const { selectionsParticipantsState, putSelectionPersonAttendance } = useSelectionsParticipantsData();
  const { selectionsUnavailableReasonsState, setSelectionsUnavailableReasonsState, getSelectionsUnavailableReasons
  } = useSelectionsUnavailableReasonsData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
  };

  const onSubmitFormCallbackIndividual = (formState) => {
    const willAttend = formState.willAttend ? true : false;
    const unavailableReasonId = formState.unavailableReasonId;
    const unavailableReasonName = formState.unavailableReasonName;

    if (isWillAttendDataDifferent(willAttend, unavailableReasonName, 'willAttend', 'unavailableReason')) {
      const selectionMeetId = selectionsState.objData?.selectionMeetId;
      const selectionMeetPersonId = formState.selectionMeetPersonId;

      if (selectionMeetId > 0 && selectionMeetPersonId > 0) {
        const putSelectionPersonAttendancePromise = putSelectionPersonAttendance(selectionMeetId, selectionMeetPersonId, willAttend, unavailableReasonId);

        if (putSelectionPersonAttendancePromise !== null) {
          putSelectionPersonAttendancePromise.then((newState) => {
            if (newState !== null) {
              navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
            }
          }).catch((e) => {
            setContextStateError(true);
          });
        }
      }
    } else {
      navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
    }
  };

  const onSubmitFormCallbackRelay = (formState) => {
    const participantLegsToPut = createParticipantLegsToPut(formState, ['leg1', 'leg2', 'leg3', 'leg4'])

    if (participantLegsToPut.length > 0) {
      setState({
        ...state,
        participantLegsToPut,
        doLoopingPut: true
      });
    } else {
      navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
    }
  };

  useEffect(() => {
    if (selectionsUnavailableReasonsState.isArrayLoading !== true && selectionsUnavailableReasonsState.isArrayLoaded !== true) {
      const getSelectionsUnavailableReasonsPromise = getSelectionsUnavailableReasons();

      if (getSelectionsUnavailableReasonsPromise !== null) {
        getSelectionsUnavailableReasonsPromise.then((newState) => {
          if (newState !== null) {
            setSelectionsUnavailableReasonsState({
              ...newState,
              items: newState.arrayData.map(x => { return { id: x.unavailableReasonId, name: x.unavailableReasonName }; }),
              areItemsLoaded: true
            });
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsUnavailableReasonsState]);

  useEffect(() => {
    if (state.doLoopingPut === true && selectionsParticipantsState.isObjLoading !== true) {
      const selectionMeetId = selectionsState.objData?.selectionMeetId;
      const participantLegToPut = state.participantLegsToPut.find(x => x.hasBeenPut === false);

      if (selectionMeetId > 0 && participantLegToPut?.selectionMeetPersonId > 0) {
        const putSelectionPersonAttendancePromise = putSelectionPersonAttendance(selectionMeetId, participantLegToPut.selectionMeetPersonId, participantLegToPut.willAttend, participantLegToPut.unavailableReasonId);
        const participantLegsToPut = state.participantLegsToPut.filter(x => x.selectionMeetPersonId !== participantLegToPut.selectionMeetPersonId);

        participantLegsToPut.push({
          ...participantLegToPut,
          hasBeenPut: true
        });

        setState({
          ...state,
          participantLegsToPut
        });
        if (putSelectionPersonAttendancePromise !== null) {
          putSelectionPersonAttendancePromise.catch((e) => {
            setContextStateError(true);
          });
        }
      } else {
        navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, selectionsParticipantsState.isObjLoading]);

  function isWillAttendDataDifferent(willAttend, unavailableReason, previousWillAttendKey, previousUnavailableReasonKey) {
    const previousWillAttend = location.state?.participantObj?.[previousWillAttendKey];
    const previousUnavailableReason = location.state?.participantObj?.[previousUnavailableReasonKey];

    if (previousWillAttend !== willAttend) {
      return true;
    } else if (previousUnavailableReason !== unavailableReason) {
      return true;
    } else {
      return false;
    }
  };

  function createParticipantLegsToPut(formState, legLeadingKeys) {
    const participantLegsToPut = [];

    for (const legLeadingKey of legLeadingKeys) {
      const willAttend = formState?.[`${legLeadingKey}${willAttendTrailingKey}`];
      const unavailableReasonName = formState?.[`${legLeadingKey}${unavailableReasonNameTrailingKey}`];

      if (isWillAttendDataDifferent(willAttend, unavailableReasonName, `${legLeadingKey}${willAttendTrailingKey}`, `${legLeadingKey}${unavailableReasonTrailingKey}`)) {
        const selectionMeetPersonId = formState?.[`${legLeadingKey}${selectionMeetPersonIdTrailingKey}`];
        const unavailableReasonId = formState?.[`${legLeadingKey}${unavailableReasonIdTrailingKey}`];

        participantLegsToPut.push({
          selectionMeetPersonId,
          willAttend,
          unavailableReasonId,
          hasBeenPut: false
        });
      }
    };

    return participantLegsToPut;
  };

  return {
    isLoading: selectionsParticipantsState.isObjLoading || selectionsUnavailableReasonsState.isArrayLoading || state.doLoopingPut === true,
    participantObj: location.state?.participantObj || {},
    selectionsState,
    onBackClicked,
    onSubmitFormCallbackIndividual,
    onSubmitFormCallbackRelay
  };
};

export default useSelectionsParticipantSelectionsDetail;