import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LaneSheetTemplateStateContext = createContext();

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  areOptionsLoaded: false
};

const LaneSheetTemplateContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LaneSheetTemplateStateContext.Provider value={stateHook}>
      {children}
    </LaneSheetTemplateStateContext.Provider>
  );
};

export default LaneSheetTemplateContextProvider;