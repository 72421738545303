import React from 'react';

import useLaneSheetMedalDropdown from './UseLaneSheetMedalDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const LaneSheetMedalDropdown = ({ label, name, value, error, message, onChange }) => {
  const { laneSheetMedalsState } = useLaneSheetMedalDropdown();

  return laneSheetMedalsState.message
    ? <span className={global.LoadingMsg}>{laneSheetMedalsState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={laneSheetMedalsState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={laneSheetMedalsState.areOptionsLoaded !== true}
        notSelectedLabel={"--"}
      />
    );
};

export default LaneSheetMedalDropdown;