import { Fragment } from 'react';

import useLaneSheetMeetEventAddEvent from './UseLaneSheetMeetEventsAddEvent';

import LaneSheetAddMeetEventForm from '../../components/forms/LaneSheetAddMeetEventForm';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const LaneSheetMeetEventsAddEvent = () => {
  const {
    isSaving,
    onSubmitFormCallback,
    onBackClicked
  } = useLaneSheetMeetEventAddEvent();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Meet Events For Lane Sheet</Headings.H3>
        </div>
      </div>
      <LaneSheetAddMeetEventForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LaneSheetMeetEventsAddEvent;