import Constants from "../../../common/utils/Constants";
import { formatDate, formatDateTime } from "../../../common/utils/DateFunctions";

export const meetInfoIsComplete = (omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission) => {
    if (omeMeetStateObjData.timeZone !== null &&
        omeMeetStateObjData.publishDate !== null &&
        omeMeetStateObjData.athleteAgeAsOfDate !== null &&
        omeMeetStateObjData.meetEntryStartDate !== null &&
        omeMeetStateObjData.meetEntryEndDate !== null &&
        omeMeetStateObjData.meetEntryChangeDeadline !== null
        && meetDatesAreValid(omeMeetStateObjData, meetStateObjData) === true &&
        omeMeetStateObjData.omeMeetEntryCourse.length > 0) {

        if (meetInfoRoutePermission === 'edit') {
            if (omeMeetStateObjData.allowSecondaryOrgUnitRepresentation !== null) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    return false;
};

export const eligibilityIsComplete = (omeMeetStateObjData, eligibilityRoutePermission) => {
    if (eligibilityRoutePermission === 'edit' && omeMeetStateObjData.status !== Constants.WORKFLOW_STATUS_CREATED) {
        if ((omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === true ||
            omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === false) &&
            (
                (omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === true && (
                    omeMeetStateObjData.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime === false ||
                    omeMeetStateObjData.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime === true))
                ||
                (omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === false &&
                    omeMeetStateObjData.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime === null)
            ) &&
            (omeMeetStateObjData.omeMeetEligibility[0]?.useAdjustedSwimTime === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.useAdjustedSwimTime === false) &&
            (omeMeetStateObjData.omeMeetCoachPass?.length > 0) &&
            ((omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === true && (omeMeetStateObjData.omeMeetQualification[0] !== undefined &&
                omeMeetStateObjData.omeMeetQualification[0]?.omeQualificationBonus?.length > 0)) ||
                omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === false)) {
            return true
        }

        return false;
    }
    else {
        if ((omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === true ||
            omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === false) &&
            (omeMeetStateObjData.omeMeetEligibility[0]?.useAdjustedSwimTime === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.useAdjustedSwimTime === false) &&
            (omeMeetStateObjData.omeMeetCoachPass?.length > 0) &&
            ((omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === true && (omeMeetStateObjData.omeMeetQualification[0] !== undefined &&
                omeMeetStateObjData.omeMeetQualification[0]?.omeQualificationBonus?.length > 0)) ||
                omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === false)) {
            return true
        }

        return false;
    }
};

export const restrictionsIsComplete = (omeMeetStateObjData, restrictionsRoutePermission) => {
    if (restrictionsRoutePermission === 'edit' && omeMeetStateObjData.status !== Constants.WORKFLOW_STATUS_CREATED) {
        if ((omeMeetStateObjData.omeMeetEligibility[0]?.usCitizenOnly === true ||
            omeMeetStateObjData.omeMeetEligibility[0]?.usCitizenOnly === false) && (
                omeMeetStateObjData.omeMeetEligibility[0]?.nationalTeamOnly === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.nationalTeamOnly === false) && (
                omeMeetStateObjData.omeMeetEligibility[0]?.allowRepOtherCountry === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.allowRepOtherCountry === false) && (
                omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedRoster === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedRoster === false) && (
                omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedAthleteEntry === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedAthleteEntry === false)
            && omeMeetStateObjData.omeMeetEligibility[0].omeMeetEligibilityAge?.length > 0) {
            return true;
        }

        return false;
    }
    else {
        if ((omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedRoster === true ||
            omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedRoster === false) && (
                omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedAthleteEntry === true ||
                omeMeetStateObjData.omeMeetEligibility[0]?.allowUnattachedAthleteEntry === false)
            && omeMeetStateObjData.omeMeetEligibility[0].omeMeetEligibilityAge?.length > 0) {
            return true;
        }

        return false;
    }
}

export const invitationsIsComplete = (omeMeetInvitationsStateArrayData) => {
    if (omeMeetInvitationsStateArrayData?.length > 0) {
        return true;
    }

    return false;
}

export const sessionsIsComplete = (meetStateObjData) => {
    if (meetStateObjData.meetSession?.length > 0) {
        return true;
    }
    return false;
}

export const ageGroupsIsComplete = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.meetAgeGroup?.length > 0) {
        return true;
    }
    return false;
}

export const timeStandardsIsComplete = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.omeMeetQualification[0]?.hasTimeStandards === false ||
        (omeMeetStateObjData.omeMeetQualification[0]?.hasTimeStandards === true &&
            omeMeetStateObjData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards !== null &&
            omeMeetStateObjData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards !== null &&
            ((omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === true &&
                omeMeetStateObjData.omeMeetQualification[0]?.hasBonusTimeStandards !== null
            ) || (omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === false &&
                omeMeetStateObjData.omeMeetQualification[0]?.hasBonusTimeStandards === null
                )) &&
            ((omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === true &&
                omeMeetStateObjData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === true &&
                omeMeetStateObjData.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRelayRule !== null
            ) || ((omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents === false ||
                omeMeetStateObjData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === false) &&
                omeMeetStateObjData.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRelayRule === null
                )) &&
            ((omeMeetStateObjData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === true &&
                omeMeetStateObjData.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRule !== null) ||
                (omeMeetStateObjData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === false &&
                    omeMeetStateObjData.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRule === null))
            &&
            omeMeetStateObjData.omeMeetQualification[0]?.timeStandardsQualifyingStartDate !== null &&
            omeMeetStateObjData.omeMeetQualification[0]?.timeStandardsQualifyingEndDate !== null &&
            (new Date(omeMeetStateObjData.omeMeetQualification[0]?.timeStandardsQualifyingStartDate) <
                new Date(omeMeetStateObjData.omeMeetQualification[0]?.timeStandardsQualifyingEndDate)) &&
            (new Date(formatDate(omeMeetStateObjData.omeMeetQualification[0]?.timeStandardsQualifyingEndDate)) <=
                new Date(formatDateTime(omeMeetStateObjData.meetEntryEndDate)?.substring(0, 10)))
            && timeStandardsAgeGroupsAllHaveAMeetAgeGroupTimeStandardRecord(omeMeetStateObjData) === true &&
            timeStandardsAgeGroupsNoSlowerThanTimeStandardTypeRequirements(omeMeetStateObjData) === true &&
            timeStandardsAgeGroupsNoFasterThanTimeStandardTypeRequirements(omeMeetStateObjData) === true &&
            timeStandardsAgeGroupsFulfillBonusTimeStandardTypeRequirements(omeMeetStateObjData) === true)
    ) {
        return true;
    }
    return false;
}

export const timeStandardsAgeGroupsAllHaveAMeetAgeGroupTimeStandardRecord = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.meetAgeGroup.length > 0) {
        let missingMeetAgeGroupTimeStandardRecord = false;
        for (let i = 0; i < omeMeetStateObjData.meetAgeGroup.length; i++) {
            //Make sure that every age group has made a time standard selection
            if (omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.length === 0) {
                missingMeetAgeGroupTimeStandardRecord = true
            }
        }
        if (missingMeetAgeGroupTimeStandardRecord === true) {
            return false;
        }
        else if (missingMeetAgeGroupTimeStandardRecord === false) {
            return true;
        }
    }
    return false;
}

export const timeStandardsAgeGroupsNoSlowerThanTimeStandardTypeRequirements = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards === true) {
        if (omeMeetStateObjData.meetAgeGroup.length > 0) {
            let missingTimeStandardTypeRequirementCount = 0;
            for (let i = 0; i < omeMeetStateObjData.meetAgeGroup.length; i++) {
                if (omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.length > 0) {
                    const index = omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.findIndex(x => x.noSlowerThanTimeStandardAgeGroupId !== null);
                    if (index < 0) {
                        missingTimeStandardTypeRequirementCount += 1;
                    }
                }
            }
            if (missingTimeStandardTypeRequirementCount < omeMeetStateObjData.meetAgeGroup.length) {
                return true;
            }
            else if (missingTimeStandardTypeRequirementCount >= omeMeetStateObjData.meetAgeGroup.length) {
                return false;
            }
        }
        return false;
    }
    else {
        return true;
    }
}

export const timeStandardsAgeGroupsNoFasterThanTimeStandardTypeRequirements = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === true) {
        if (omeMeetStateObjData.meetAgeGroup.length > 0) {
            let missingTimeStandardTypeRequirementCount = 0;
            for (let i = 0; i < omeMeetStateObjData.meetAgeGroup.length; i++) {
                if (omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.length > 0) {
                    const index = omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.findIndex(x => x.noFasterThanTimeStandardAgeGroupId !== null);
                    if (index < 0) {
                        missingTimeStandardTypeRequirementCount += 1;
                    }
                }
            }
            if (missingTimeStandardTypeRequirementCount < omeMeetStateObjData.meetAgeGroup.length) {
                return true;
            }
            else if (missingTimeStandardTypeRequirementCount >= omeMeetStateObjData.meetAgeGroup.length) {
                return false;
            }
        }
        return false;
    }
    else {
        return true;
    }
}

export const timeStandardsAgeGroupsFulfillBonusTimeStandardTypeRequirements = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents === true && omeMeetStateObjData.omeMeetQualification[0]?.hasBonusTimeStandards === true) {
        if (omeMeetStateObjData.meetAgeGroup.length > 0) {
            let missingTimeStandardTypeRequirementCount = 0;
            for (let i = 0; i < omeMeetStateObjData.meetAgeGroup.length; i++) {
                if (omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.length > 0) {
                    const index = omeMeetStateObjData.meetAgeGroup[i].meetAgeGroupTimeStandard.findIndex(x => x.bonusTimeStandardAgeGroupId !== null);
                    if (index < 0) {
                        missingTimeStandardTypeRequirementCount += 1;
                    }
                }
            }
            if (missingTimeStandardTypeRequirementCount < omeMeetStateObjData.meetAgeGroup.length) {
                return true;
            }
            else if (missingTimeStandardTypeRequirementCount >= omeMeetStateObjData.meetAgeGroup.length) {
                return false;
            }
        }
        return false;
    }
    else {
        return true;
    }
}

export const eventsIsComplete = (omeMeetStateObjData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID) => {
    if (eventAgeGroupsAreComplete(omeMeetStateObjData) === true &&
        eventTimeCutsAreComplete(omeMeetStateObjData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID) === true) {
        return true;
    }
    return false;
}

export const eventAgeGroupsAreComplete = (omeMeetStateObjData) => {
    if (omeMeetStateObjData.meetAgeGroup?.length > 0) {
        for (let i = 0; i < omeMeetStateObjData.meetAgeGroup.length; i++) {
            if (omeMeetStateObjData.meetAgeGroup[i].meetEvent.length === 0) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export const eventTimeCutsAreComplete = (omeMeetStateObjData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID) => {
    if (omeMeetStateObjData.meetAgeGroup?.length > 0) {
        for (let i = 0; i < omeMeetStateObjData.meetAgeGroup.length; i++) {
            if (omeMeetStateObjData.meetAgeGroup[i].meetEvent.length > 0) {
                if (omeMeetStateObjData.omeMeetEntryCourse.length > 0) {
                    const scy = omeMeetStateObjData.omeMeetEntryCourse.findIndex(x => x.courseId === SCY_COURSE_ID) > -1 ? true : false;
                    const scm = omeMeetStateObjData.omeMeetEntryCourse.findIndex(x => x.courseId === SCM_COURSE_ID) > -1 ? true : false;
                    const lcm = omeMeetStateObjData.omeMeetEntryCourse.findIndex(x => x.courseId === LCM_COURSE_ID) > -1 ? true : false;
                    for (let j = 0; j < omeMeetStateObjData.meetAgeGroup[i].meetEvent.length; j++) {
                        // if (omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].event?.isRelay === false) {
                        //Error when: time cuts are missing a course priority, or if there is a time cut for a course that doesn't have a course priority
                        if (omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.length > 0) {
                            for (let k = 0; k < omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.length; k++) {
                                const scyEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime[k].event?.relatedScyEventId;
                                const scmEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime[k].event?.relatedScmEventId;
                                const lcmEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime[k].event?.relatedLcmEventId;
                                let scyPossibleForEvent = true;
                                let scmPossibleForEvent = true;
                                let lcmPossibleForEvent = true;
                                if (!scyEventId) {
                                    scyPossibleForEvent = false;
                                }
                                if (!scmEventId) {
                                    scmPossibleForEvent = false;
                                }
                                if (!lcmEventId) {
                                    lcmPossibleForEvent = false;
                                }

                                const scyTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId) > -1 ? true : false;
                                const scmTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId) > -1 ? true : false;
                                const lcmTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId) > -1 ? true : false;

                                if ((scy === true && scyPossibleForEvent === true && scyTimeMatch === false) || (scy === false && scyTimeMatch === true) ||
                                    (scm === true && scmPossibleForEvent === true && scmTimeMatch === false) || (scm === false && scmTimeMatch === true) ||
                                    (lcm === true && lcmPossibleForEvent === true && lcmTimeMatch === false) || (lcm === false && lcmTimeMatch === true)) {
                                    return false;
                                }
                            }
                        }
                        if (omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.length > 0) {
                            for (let k = 0; k < omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.length; k++) {
                                const scyEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit[k].event?.relatedScyEventId;
                                const scmEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit[k].event?.relatedScmEventId;
                                const lcmEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit[k].event?.relatedLcmEventId;
                                let scyPossibleForEvent = true;
                                let scmPossibleForEvent = true;
                                let lcmPossibleForEvent = true;
                                if (!scyEventId) {
                                    scyPossibleForEvent = false;
                                }
                                if (!scmEventId) {
                                    scmPossibleForEvent = false;
                                }
                                if (!lcmEventId) {
                                    lcmPossibleForEvent = false;
                                }

                                const scyTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId) > -1 ? true : false;
                                const scmTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId) > -1 ? true : false;
                                const lcmTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId) > -1 ? true : false;

                                if ((scy === true && scyPossibleForEvent === true && scyTimeMatch === false) || (scy === false && scyTimeMatch === true) ||
                                    (scm === true && scmPossibleForEvent === true && scmTimeMatch === false) || (scm === false && scmTimeMatch === true) ||
                                    (lcm === true && lcmPossibleForEvent === true && lcmTimeMatch === false) || (lcm === false && lcmTimeMatch === true)) {
                                    return false;
                                }
                            }
                        }
                        if (omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length > 0) {
                            for (let k = 0; k < omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length; k++) {
                                const scyEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime[k].event?.relatedScyEventId;
                                const scmEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime[k].event?.relatedScmEventId;
                                const lcmEventId = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime[k].event?.relatedLcmEventId;
                                let scyPossibleForEvent = true;
                                let scmPossibleForEvent = true;
                                let lcmPossibleForEvent = true;
                                if (!scyEventId) {
                                    scyPossibleForEvent = false;
                                }
                                if (!scmEventId) {
                                    scmPossibleForEvent = false;
                                }
                                if (!lcmEventId) {
                                    lcmPossibleForEvent = false;
                                }

                                const scyTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId) > -1 ? true : false;
                                const scmTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId) > -1 ? true : false;
                                const lcmTimeMatch = omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId) > -1 ? true : false;

                                if ((scy === true && scyPossibleForEvent === true && scyTimeMatch === false) || (scy === false && scyTimeMatch === true) ||
                                    (scm === true && scmPossibleForEvent === true && scmTimeMatch === false) || (scm === false && scmTimeMatch === true) ||
                                    (lcm === true && lcmPossibleForEvent === true && lcmTimeMatch === false) || (lcm === false && lcmTimeMatch === true)) {
                                    return false;
                                }
                            }
                        }
                        // }
                    }
                }
                else {
                    for (let j = 0; j < omeMeetStateObjData.meetAgeGroup[i].meetEvent.length; j++) {
                        // if (omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].event?.isRelay === false) {
                        if (omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.length > 0 ||
                            omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.length > 0 ||
                            omeMeetStateObjData.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length > 0) {
                            return false;
                        }
                        // }
                    }
                }
            }
        }
        return true;
    }
    return false;
}

export const pricingIsComplete = (omeMeetStateObjData, omeMeetInvitationsStateArrayData, orgUnitStateArrayData, TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID,
    INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
    ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID) => {

    let savedInvitationsContainFinaOrgUnit = false;
    const finaOrgUnits = orgUnitStateArrayData.find(x => x.id === FINA_ORG_UNIT_ID);

    //Check saved invitations for FINA
    for (let i = 0; i < omeMeetInvitationsStateArrayData?.length; i++) {
        //Check to see if FINA is a saved invitation
        if (omeMeetInvitationsStateArrayData[i]?.orgUnitId === finaOrgUnits?.id) {
            savedInvitationsContainFinaOrgUnit = true;
            break;
        }
        //Check to see if a FINA continent is a saved invitation
        for (let j = 0; j < finaOrgUnits.children?.length; j++) {
            if (omeMeetInvitationsStateArrayData[i]?.orgUnitId === finaOrgUnits.children[j].id) {
                savedInvitationsContainFinaOrgUnit = true;
                break;
            }
            //Check to see if a FINA country is a saved invitation
            for (let k = 0; k < finaOrgUnits.children[j]?.children?.length; k++) {
                if (omeMeetInvitationsStateArrayData[i]?.orgUnitId === finaOrgUnits.children[j]?.children[k]?.id) {
                    savedInvitationsContainFinaOrgUnit = true;
                    break;
                }
            }
        }
    }

    const hasFINAInvitation = savedInvitationsContainFinaOrgUnit;
    const showCostPerIndividualInternationalAthlete = hasFINAInvitation;
    const showCostPerInternationalStaff = hasFINAInvitation;
    const showCostPerAthleteIndividualBonusEventEntry = omeMeetStateObjData.omeMeetEligibility[0]?.bonusEvents ?? false;
    const hasRelayEvents = omeMeetStateObjData.omeMeetEligibility[0]?.relayEvents ?? false;
    const allowRelayOnlySwimmer = omeMeetStateObjData.omeMeetEligibility[0]?.allowRelayOnlySwimmer ?? false;
    const showCostPerRelayOnlySwimmer = hasRelayEvents === true && allowRelayOnlySwimmer === true ? true : false;
    const showCostPerRelayEventEntry = hasRelayEvents;

    if (omeMeetStateObjData.omeMeetPrice?.length > 0) {
        const hasCostPerTeam = omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === TEAM_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerCredential = omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === CREDENTIAL_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerIndividualAthlete = omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerIndividualInternationalAthlete = showCostPerIndividualInternationalAthlete === true ? (omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false) : true;
        const hasCostPerInternationalStaff = showCostPerInternationalStaff === true ? (omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false) : true;
        const hasCostPerRelayOnlySwimmer = showCostPerRelayOnlySwimmer === true ? (omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false) : true;
        const hasCostPerAthleteIndividualEventEntry = omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerAthleteIndividualBonusEventEntry = showCostPerAthleteIndividualBonusEventEntry === true ? (omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false) : true;
        const hasCostPerRelayEventEntry = showCostPerRelayEventEntry === true ? (omeMeetStateObjData.omeMeetPrice.find(x => x.omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false) : true;
        if (hasCostPerTeam === false || hasCostPerCredential === false || hasCostPerIndividualAthlete === false || hasCostPerIndividualInternationalAthlete === false || hasCostPerInternationalStaff === false || hasCostPerRelayOnlySwimmer === false ||
            hasCostPerAthleteIndividualEventEntry === false || hasCostPerAthleteIndividualBonusEventEntry === false || hasCostPerRelayEventEntry === false) {
            return false;
        }
        return true;
    }
    return false;
}

export const submitIsComplete = (omeMeetStateObjData, meetStateObjData, ENTRY_CONTACT_STAFF_TYPE_ID) => {
    let entryContactPersonId = '';
    if (meetStateObjData.personMeetStaff?.length > 0) {
        for (let i = 0; i < meetStateObjData.personMeetStaff.length; i++) {
            for (let j = 0; j < meetStateObjData.personMeetStaff[i].personMeetStaffType.length; j++) {
                if (meetStateObjData.personMeetStaff[i].personMeetStaffType[j]?.meetStaffTypeId === ENTRY_CONTACT_STAFF_TYPE_ID) {
                    entryContactPersonId = meetStateObjData.personMeetStaff[i].personId;
                }
            }
        }
    }

    if (entryContactPersonId !== '' && omeMeetStateObjData.omeMeetPayee?.length > 0 && omeMeetStateObjData.status === Constants.WORKFLOW_STATUS_CREATED) {
        return true;
    }
    return false;
}

export const meetDatesAreValid = (omeMeetStateObjData, meetStateObjData) => {
    let errors = {};
    const publishDate = new Date(omeMeetStateObjData.publishDate);
    const athleteAgeAsOfDate = new Date(formatDate(omeMeetStateObjData.athleteAgeAsOfDate));
    const meetEntryStartDate = new Date(omeMeetStateObjData.meetEntryStartDate);
    const meetEntryEndDate = new Date(omeMeetStateObjData.meetEntryEndDate);
    const meetEntryChangeDeadline = new Date(omeMeetStateObjData.meetEntryChangeDeadline);
    const resultsPublishDate = new Date(formatDate(omeMeetStateObjData.resultsPublishDate));
    const meetStartDate = new Date(formatDate(meetStateObjData.startDate));
    const meetStartDateYear = meetStartDate.getFullYear();
    const dec31MeetStartDateYear = new Date(`December 31, ${meetStartDateYear} 00:00:00`);
    const meetEndDate = new Date(formatDate(meetStateObjData.endDate));
    const meetEndDateYear = meetStartDate.getFullYear();
    const dec31MeetEndDateYear = new Date(`December 31, ${meetEndDateYear} 00:00:00`);

    //Meet Publish Date 
    //Must be on or before Meet Entry Start Date & before Meet Start Date
    if (publishDate >= meetStartDate) {
        errors.publishDate = 'Meet Publish Date must be before the Meet Start Date'
    } else if (publishDate > meetEntryStartDate) {
        errors.publishDate = 'Meet Publish Date must be on or before the Meet Entry Start Date'
    }

    //Athlete Age as of Date
    //Must be December 31st of the meet year or between Meet Start Date and Meet End Date 
    if ((athleteAgeAsOfDate < meetStartDate || athleteAgeAsOfDate > meetEndDate) &&
        ((athleteAgeAsOfDate - dec31MeetStartDateYear !== 0) && (athleteAgeAsOfDate - dec31MeetEndDateYear !== 0))) {
        errors.athleteAgeAsOfDate = 'Athlete Age as of Date must be between the Meet Start Date and Meet End Date or on December 31st of the year of the meet'
    }

    //Meet Entry Start Date
    //Must be before Meet Entry End Date and Meet Start Date
    if (publishDate > meetEntryStartDate) {
        errors.meetEntryStartDate = 'Meet Entry Start Date must be on or after the Meet Publish Date'
    } else if (meetEntryStartDate >= meetStartDate) {
        errors.meetEntryStartDate = 'Meet Entry Start Date must be before the Meet Start Date'
    } else if (meetEntryEndDate <= meetEntryStartDate) {
        errors.meetEntryStartDate = 'Meet Entry Start Date must be before the Meet Entry End Date'
    }

    //Meet Entry End Date
    //Must be after Meet Entry Start Date and before Meet Start Date
    if (meetEntryEndDate >= meetStartDate) {
        errors.meetEntryEndDate = 'Meet Entry End Date must be before the Meet Start Date'
    } else if (meetEntryEndDate <= meetEntryStartDate) {
        errors.meetEntryEndDate = 'Meet Entry End Date must be after the Meet Entry Start Date'
    } else if (meetEntryChangeDeadline < meetEntryEndDate) {
        errors.meetEntryEndDate = 'Meet Entry End Date must be on or before the Meet Entry Change Deadline'
    }

    //Meet Entry Change Deadline 
    // Must be before Meet Start Date and after the Meet Entry Start Date
    if (meetEntryChangeDeadline >= meetStartDate) {
        errors.meetEntryChangeDeadline = 'Meet Entry Change Deadline must be before the Meet Start Date'
    } else if (meetEntryChangeDeadline < meetEntryEndDate) {
        errors.meetEntryChangeDeadline = 'Meet Entry Change Deadline must be on or after the Meet Entry End Date'
    }

    //Meet Results Publish Deadline
    // Must be on or after end date of meet
    if (omeMeetStateObjData.resultsPublishDate !== null) {
        if (resultsPublishDate < meetEndDate) {
            errors.resultsPublishDate = 'Meet Results Publish Date must be on or after the Meet End Date'
        }
    }

    if (Object.keys(errors).length > 0) {
        return false;
    }
    else if (Object.keys(errors).length === 0) {
        return true;
    }
}