import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.timeTypeName === Constants.DEFAULT_ID) {
    errors.timeTypeName = 'Time Result Type is required'
  }

  return errors;
};

const AddMeetTimeSelectTimeTypeValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AddMeetTimeSelectTimeTypeValidation;