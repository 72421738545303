import { useState } from 'react';

import OmeEntriesExportData from './OmeEntriesExportData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useOmeEntriesExportData = () => {
  const [omeEntriesExportState, setOmeEntriesExportState] = useState(INITIAL_STATE);

  const getOmeEntriesExport = (meetId) => {
    OmeEntriesExportData.getOmeEntriesExportData(meetId, omeEntriesExportState, setOmeEntriesExportState);
  }

  return {
    omeEntriesExportState,
    getOmeEntriesExport
  };
};

export default useOmeEntriesExportData;