import React from "react";

import useOrgUnitAdminPayments from './UseOrgUnitAdminPayments';

import ShoppingCart from '../../../../../common/components/payments/ShoppingCart';

const OrgUnitAdminPayments = () => {
  const {
    instructionText,
    redirectToPurchases,
    redirectToEntryStatus,
    orgUnitId,
    omeMeetOrgUnitEntryId
  } = useOrgUnitAdminPayments();

  return (
    <ShoppingCart title="Payment" isBarOnBottom={false} instructionText={instructionText} orgUnitId={orgUnitId} itemSubsystemId={3} itemContract={5} referenceId={omeMeetOrgUnitEntryId} onPaymentCompletion={redirectToEntryStatus} onPaymentCancel={redirectToPurchases}></ShoppingCart>
  );
};

export default OrgUnitAdminPayments;
