import { Fragment } from 'react';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AthleteUserEntriesSmallGrid = ({ gridData, isLoading, onDeleteEventClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((event, i) => (
          <Fragment key={event.individualAthleteMeetEventId + 'Fragment'}>
            <div className={global.SmallTableRow} key={event.individualAthleteMeetEventId || i}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>{event.eventName || <span>&nbsp;</span>}</span>&nbsp;
                {onDeleteEventClicked && (event.hasPaid === false || event.canBeDeleted === true) && <button className={global.IconButton}
                  type="button"
                  onClick={(e) => onDeleteEventClicked(e, event)}>
                  <DeleteIcon />
                </button>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Event-Course</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{event.eventName || <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.eventCompetitionGenderTypeName || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age Group</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.ageGroupName || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Session</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.meetSessionName || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Time</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.entryTime ? formatTimeForDisplay(event.entryTime) : 'NT'} {event.entryTime ? event.qualifyingCourseCode || <span>&nbsp;</span> : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Time Event</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.isAlternateEvent === true ? `ALT: ${event.qualifyingEventName || ''}` : event.isNonConformingTime === true ? `NC: ${event.qualifyingEventName || ''}` : <span>{event.qualifyingEventName || <span>&nbsp;</span>}</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Bonus?</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.qualifiedAsBonus === true ? 'Yes' : event.qualifiedAsBonus === false ? 'No' : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Override?</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.swimTimeEntryOverride === true ? 'Yes' : event.swimTimeEntryOverride === false ? 'No' : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.status || <span>&nbsp;</span>}</div>
                </div>
              </div>
            </div>
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Individual Event Entries</div>
            </div>
          </div>
        )
    }
  </div >
);

export default AthleteUserEntriesSmallGrid;