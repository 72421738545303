import React, { Fragment } from 'react';

import useAthleteEntryContextView from './UseAthleteEntryContextView';

import NavLinks from '../../meet/NavLinks';

import MobileContextBasedNav from '../../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../../common/components/contextBasedNav/LargeContextBasedNav';
import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../../common/components/PageNotFound';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../Entry.module.css';
import { formatDate, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

const AthleteEntryContextView = ({ children }) => {
  const {
    state,
    meetState,
    omeMeetState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    athleteEntryContextState,
    backToLabel,
    onHomeClicked,
    onBackClicked
  } = useAthleteEntryContextView();

  if (!omeMeetOrgUnitAthleteEntryIndividualRosterEntryState || Object.keys(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData).length === 0) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.HeaderButton} onClick={onHomeClicked}>
              <Headings.H3
                className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                excludeBar={true}>
                View/Edit Athlete Entry
              </Headings.H3>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6">
            <p className={global.HeaderText}><b>Meet Name:</b> {meetState.objData.meetName || ''}</p>
            <p className={global.HeaderText}><b>Meet Host:</b> {meetState.objData.hostOrgUnit?.orgUnitName || ''}</p>
            <p className={global.HeaderText}><b>Meet Dates:</b> {meetState.isObjLoaded ? `${meetState.objData.startDate ? formatDate(meetState.objData.startDate) : ''} - ${meetState.objData.endDate ? formatDate(meetState.objData.endDate) : ''}` : ''}</p>
            <p className={global.HeaderText}><b>Qualifying Date Range:</b>
              &nbsp;{omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards === false ? 'N/A (Meet does not use Time Standards)' : omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate && omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate ? `${formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate)} - ${formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate)}` : ''}</p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <p className={[global.HeaderText, style.RemoveTopMarginMobile].join(' ')}><b>Athlete:</b> {(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.firstName || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.middleName || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.lastName) ? `${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.firstName || ''} ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.preferredName !== '' && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.preferredName !== omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.firstName ? '"' + omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.preferredName + '"' : ''} ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.middleName || ''} ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.lastName || ''}` : ''} ({omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.competitionGenderTypeName || ''}, {omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.ageAtMeet >= 0 ? omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.ageAtMeet : ''})</p>
            <p className={global.HeaderText}><b>Athlete Birth Date:</b> {omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.birthDate ? formatDate(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.birthDate) : ''}</p>
            <p className={global.HeaderText}><b>Athlete Entry Status:</b> {omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.status || ''}</p>
            <p className={global.HeaderText}><b>Team Name:</b> {state?.orgUnitName || ''}</p>
          </div>
          {state?.orgUnitRegistrationStartDate &&
            <div className='col-xs-12'>
              <p className={[global.HeaderText, style.RemoveTopMargin].join(' ')}><b>Athlete Entry Dates:</b>
                &nbsp;{state?.orgUnitRegistrationStartDate ? formatDateTimeTwelveHourClock(state?.orgUnitRegistrationStartDate) : ''} - {state?.orgUnitRegistrationEndDate ? formatDateTimeTwelveHourClock(state?.orgUnitRegistrationEndDate) : ''}
                &nbsp;{omeMeetState?.objData?.timeZone ? omeMeetState?.objData?.timeZone : ''}</p></div>}
        </div>
        {athleteEntryContextState.showNav === true &&
        <div className="row">
          <div className="visible-xs col-xs-12">
            <MobileContextBasedNav taxonomyName={omeMeetState.route === '' ? "AthleteEntryDetail" : "AthleteMeetDetail"} renderPillsOnly={true} />
          </div>
          <div className="hidden-xs col-xs-12">
            <LargeContextBasedNav taxonomyName={omeMeetState.route === '' ? "AthleteEntryDetail" : "AthleteMeetDetail"} homeRoute={NavLinks.ATHLETE_ENTRIES} renderPillsOnly={true} />
          </div>
        </div>}
      </div>
      {children}
    </Fragment >
  );
};

export default AthleteEntryContextView;