import { isValidDate, isValidAddressLine } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

export const localValidate = (formState) => {
  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  let errors = {};
  let filterCount = 0;

  // Host Organization
  if (formState.orgUnit.length === 0 || formState.orgUnit[0].id < 1) {
    errors.orgUnit = `Must select a Host Organization to search`
  } else {
    filterCount += 1;
  }

  //Meet Name
  if (formState.meetName.trim() !== '') {
    if ((formState.meetName.trim()).length > 200) {
      errors.meetName = 'Meet Name cannot exceed 200 characters';
    } else {
      filterCount += 1;
    }
  }

  //City
  if (formState.city.trim() !== '') {
    if (!isValidAddressLine(formState.city.trim())) {
      errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    } else {
      filterCount += 1;
    }
  }

  //Country
  if (formState.country !== -1 && formState.country.trim() !== '') {
    if ((formState.country.trim()).length > 30) {
      errors.country = 'Country cannot exceed 30 characters';
    } else {
      filterCount += 1;
    }
  }

  //Start Date 
  if (formState.startDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.startDate)) {
      errors.startDate = 'Start Date must be a valid date';
    } else if (endDate < startDate) {
      errors.startDate = 'Start Date must be before the End Date';
    } else {
      filterCount += 1;
    }
  }

  //End Date must be after the Start Date
  if (formState.endDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.endDate)) {
      errors.endDate = 'End Date must be a valid date';
    } else if (endDate < startDate) {
      errors.endDate = 'End Date must be after the Start Date';
    } else {
      filterCount += 1;
    }
  }

  if (formState.meetClassification.length > 0) {
    filterCount += 1;
  }

  if (formState.courseId > 0) {
    filterCount += 1;
  }

  if (formState.stateName !== '') {
    if (formState.stateId === Constants.DEFAULT_ID || formState.stateId === '') {
      errors.stateId = 'Must be a valid State';
    }
    else {
      filterCount += 1;
    }
  }

  if (filterCount < 2) {
    errors.filter = 'Select a Host Organization and at least one additional filter to search';
  }

  return errors;
};

const SearchMeetsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchMeetsValidation;