import React, { createContext, useState } from 'react';

export const MeetFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  backToSystem: 'Meet'
};

const MeetFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <MeetFiltersStateContext.Provider value={stateHook}>
      {children}
    </MeetFiltersStateContext.Provider>
  );
};

export default MeetFiltersContextProvider;