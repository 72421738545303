import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import getMeetRaceStatsVideosData from './GetMeetRaceStatsVideosData';
import postMeetRaceStatsVideoData from './PostMeetRaceStatsVideoData';
import putMeetRaceStatsVideoData from './PutMeetRaceStatsVideoData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetRaceStatsVideosData = {
  INITIAL_STATE, getMeetRaceStatsVideosData, postMeetRaceStatsVideoData, putMeetRaceStatsVideoData
};

export default MeetRaceStatsVideosData;