import React from 'react';

import { formatPercentageForDisplay } from '../../utils/SelectionsUtils';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './SelectionAlternatesGrid.module.css';

const GridRow = ({ alternate, onEditClicked, onSelectClicked }) => (
  <tr>
    <td>{alternate.rowNumber || ''}</td>
    <td>{alternate.eventNumber || ''}</td>
    <td>{alternate.eventCode || ''}</td>
    <td>{alternate.relayTeamName || `${alternate.firstName || ''} ${alternate.lastName || ''}`}</td>
    <td>{alternate.orgUnitName || ''}</td>
    <td>{alternate.eventCompetitionGenderTypeName || ''}</td>
    <td>{alternate.entryTime ? formatTimeForDisplay(alternate.entryTime) : ''}</td>
    <td>{alternate.baseTime ? formatTimeForDisplay(alternate.baseTime) : ''}</td>
    <td>{alternate.percentOfBaseTime ? formatPercentageForDisplay(alternate.percentOfBaseTime) : ''}</td>
    <td>{alternate.standardName || ''}</td>
    <td>{alternate.hasBeenSelected === true ? 'Yes' : 'No'}</td>
    <td>
      <button className={[global.AnchorButton, style.ActionTypeButton].join(' ')} onClick={() => onEditClicked(alternate)}>
        <span>Edit</span>
      </button>
      <button className={[global.AnchorButton, style.ActionTypeButton].join(' ')} onClick={() => onSelectClicked(alternate)}>
        <span>Select</span>
      </button>
    </td>
  </tr>
);

const SelectionAlternatesGridLarge = ({ gridData, isLoading, onEditClicked, onSelectClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Row Number</th>
        <th>Event Number</th>
        <th>Event</th>
        <th>Athlete/Relay Name</th>
        <th>Team</th>
        <th>Gender</th>
        <th>Entry Time</th>
        <th>Base Time</th>
        <th>Time Rank %</th>
        <th>Standard</th>
        <th>Selected Swimmer(s)?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="12">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((alternate, i) => <GridRow key={i} alternate={alternate} onEditClicked={onEditClicked} onSelectClicked={onSelectClicked} />)
          : <tr><td colSpan="12">No Alternates</td></tr>
      }
    </tbody>
  </table>
);

export default SelectionAlternatesGridLarge;