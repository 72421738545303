import useLaneSheetData from "../../../state/laneSheet/UseLaneSheetData";

import Constants from "../../../../common/utils/Constants";
import ToIntIfInt from "../../../../common/utils/ToIntIfInt";

const useLaneSheetMeetInfo = () => {
  const { laneSheetState, setLaneSheetState, putLaneSheetMeetInfo } = useLaneSheetData();

  const onSubmitFormCallback = (formState) => {
    const meetId = laneSheetState.objData?.meetId;
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;
    const hostOrgUnitId = laneSheetState.objData?.hostOrgUnitId;

    if (meetId > 0 && laneSheetMeetId > 0 && hostOrgUnitId) {
      const putLaneSheetMeetInfoPromise = putLaneSheetMeetInfo(laneSheetMeetId, createLaneSheetObj(formState, meetId, laneSheetMeetId, hostOrgUnitId));

      if (putLaneSheetMeetInfoPromise !== null) {
        putLaneSheetMeetInfoPromise.then((newState) => {
          if (newState !== null) {
            setLaneSheetState({
              ...newState,
              isSaving: false
            });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  function createLaneSheetObj(formState, meetId, laneSheetMeetId, hostOrgUnitId) {
    let careerStatArrayOfIds = []

    for (const courseStats of formState.careerStats) {
      if (courseStats.id > 0) {
        careerStatArrayOfIds.push({ courseId: courseStats.id });
      }
    }

    let medalCountArray = []
    if (formState.medalCount1Id > 0) {
      const medalCount1Obj = {
        laneSheetMedalCountId: formState.laneSheetMedalCount1Id === Constants.DEFAULT_ID ? null : formState.laneSheetMedalCount1Id,
        meetClassificationId: formState.medalCount1Id,
        displayOrder: 1
      }

      medalCountArray.push(medalCount1Obj);
    }

    if (formState.medalCount2Id > 0) {
      const medalCount2Obj = {
        laneSheetMedalCountId: formState.laneSheetMedalCount2Id === Constants.DEFAULT_ID ? null : formState.laneSheetMedalCount2Id,
        meetClassificationId: formState.medalCount2Id,
        displayOrder: 2
      }

      medalCountArray.push(medalCount2Obj);
    }

    return {
      meetId,
      laneSheetMeetId,
      hostOrgUnitId,
      careerStats: careerStatArrayOfIds,
      laneSheetTemplateId: formState.laneSheetTemplateId,
      medalCount: medalCountArray,
      numberOfLanes: ToIntIfInt(formState.numberOfLanes),
      recordGroupWithinSheetId: formState.recordGroupWithinSheetId,
      recordWithinSheetName: formState.recordWithinSheetName,
      startingLaneNumber: formState.reverseLaneOrder === 'true' ? ToIntIfInt(formState.numberOfLanes) : 1,
      useAmericanDates: formState.useAmericanDates,
    };
  };

  return {
    isSaving: laneSheetState.isSaving,
    isLoading: laneSheetState.isObjLoading,
    laneSheetState,
    onSubmitFormCallback
  }
};

export default useLaneSheetMeetInfo;