import { useContext } from 'react';

import { OmeFeeTypeStateContext } from './OmeFeeTypeContextProvider';

import OmeFeeTypeData from './OmeFeeTypeData';

const useOmeFeeTypeData = () => {
  const [omeFeeTypeState, setOmeFeeTypeState] = useContext(OmeFeeTypeStateContext);

  const getOmeFeeTypes = () => {
    OmeFeeTypeData.getOmeFeeTypeData(omeFeeTypeState, setOmeFeeTypeState);
  };

  return {
    omeFeeTypeState,
    setOmeFeeTypeState,
    getOmeFeeTypes
  };
};

export default useOmeFeeTypeData;