import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ contact, onEditContact, onDeleteContactClicked }) => {
  return (
    <Fragment>
      <tr>
        <td>{(contact.firstName || contact.middleName || contact.lastName) ? `${contact.firstName || ''}  ${contact.preferredName !== '' && contact.preferredName !== contact.firstName ? '"' + contact.preferredName + '"' : ''} ${contact.middleName || ''} ${contact.lastName || ''}` : ''}</td>
        <td>{contact.emailAddress || ''}</td>
        <td>
          {onEditContact &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onEditContact(e, contact)}>
              <EditIcon />
            </button>}
          {onDeleteContactClicked &&
            < button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onDeleteContactClicked(e, contact)}>
              <DeleteIcon />
            </button>}
        </td>
      </tr>
    </Fragment >
  );
};

const OrgUnitAdminContactsLargeGrid = ({ gridData, isLoading, onEditContact, onDeleteContactClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Entry Contact Name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="3">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((contact) =>
              <GridRow
                key={contact.personId}
                contact={contact}
                onEditContact={onEditContact}
                onDeleteContactClicked={onDeleteContactClicked} />)
            :
            <tr>
              <td colSpan={3}>No Entry Contacts</td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default OrgUnitAdminContactsLargeGrid;