import Checkbox from "../../../../common/components/checkboxes/Checkbox";

import { formatDate } from "../../../../common/utils/DateFunctions";

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetUploadErrorTankSmallGrid = ({ gridData, isLoading, onMatchCheckbox }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((matchItem, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className="hidden-xs">{matchItem.lscCode + '/' + matchItem.clubCode}</span>&nbsp;
              <div className={global.IconButton}>
                <Checkbox
                  label=""
                  name="matchedPerson"
                  onChange={(e) => onMatchCheckbox(e, matchItem.personId)}
                  checked={matchItem.matchedPerson} />
              </div>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className="row">
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>LSC / Club Code</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{matchItem.errLscCode + '/' + matchItem.errClubCode}</div>
                <div style={(matchItem.firstName !== matchItem.errFirstName) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}} className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>First Name (File)</div>
                <div style={(matchItem.firstName !== matchItem.errFirstName) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}} className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{matchItem.errFirstName || <span>&nbsp;</span>}</div>
                <div style={(matchItem.lastName !== matchItem.errLastName) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}} className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Last Name (File)</div>
                <div style={(matchItem.lastName !== matchItem.errLastName) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}} className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{matchItem.errLastName || <span>&nbsp;</span>}</div>
                <div style={(matchItem.birthDate !== matchItem.errBirthDate) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}} className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date (File)</div>
                <div style={(matchItem.birthDate !== matchItem.errBirthDate) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}} className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(matchItem.errBirthDate) || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>First Name - Middle Name (Match)</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{matchItem.firstName || <span>&nbsp;</span>}&nbsp;{matchItem.middleName || ''}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Last Name (match)</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{matchItem.lastName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date (match)</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(matchItem.birthDate) || <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div >
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetUploadErrorTankSmallGrid;