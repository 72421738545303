import React, { Fragment } from 'react';

import useOmeRestrictionsNonHQ from './UseOmeRestrictionsNonHQ';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import OmeRestrictionsGrid from './OmeRestrictionsGrid';

import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../../common/utils/Constants';
import global from '../../../../../common/components/GlobalStyle.module.css';

const OmeRestrictionsNonHQ = () => {
  const {
    RESTRICTION_TYPE_ROUTES,
    omeMeetState,
    modalState,
    errorState,
    formState,
    onFormValueChanged,
    handleSubmit,
    onModalCanceled,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddRestrictionClicked,
    onEditRestrictionClicked,
    onDeleteRestrictionClicked,
    onDeleteRestriction,
    allowAllEdits,
  } = useOmeRestrictionsNonHQ();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_RESTRICTIONS_NON_HQ}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}>
        {allowAllEdits() === true ?
          <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row usas-extra-bottom-margin'>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Allow unattached swimmer entry to meet?*'
                    name='allowUnattachedAthleteEntry'
                    checked={formState.allowUnattachedAthleteEntry}
                    error={errorState.allowUnattachedAthleteEntry}
                    message={errorState.allowUnattachedAthleteEntry}
                    onChange={(value) => { onFormValueChanged('allowUnattachedAthleteEntry', value); }} />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Allow adding unattached swimmer to roster?*'
                    name='allowUnattachedRoster'
                    checked={formState.allowUnattachedRoster}
                    error={errorState.allowUnattachedRoster}
                    message={errorState.allowUnattachedRoster}
                    onChange={(value) => { onFormValueChanged('allowUnattachedRoster', value); }} />
                </div>
              </div>
            </form>
            <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
              <div className='col-xs-12'>
                <ActionIntraPageButton type="button" onClick={(e) => onAddRestrictionClicked(e)}>Add Restriction</ActionIntraPageButton>
              </div>
              <div className='col-xs-12'>
                <OmeRestrictionsGrid RESTRICTION_TYPE_ROUTES={RESTRICTION_TYPE_ROUTES} omeMeetState={omeMeetState} onEdit={onEditRestrictionClicked} onDelete={onDeleteRestrictionClicked} />
              </div>
            </div>
          </Fragment>
          : <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Allow unattached swimmer entry to meet?*'
                    name='allowUnattachedAthleteEntry'
                    checked={formState.allowUnattachedAthleteEntry}
                    error={errorState.allowUnattachedAthleteEntry}
                    message={errorState.allowUnattachedAthleteEntry}
                    onChange={(value) => { onFormValueChanged('allowUnattachedAthleteEntry', value); }}
                    disabled={true} />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Allow adding unattached swimmer to roster?*'
                    name='allowUnattachedRoster'
                    checked={formState.allowUnattachedRoster}
                    error={errorState.allowUnattachedRoster}
                    message={errorState.allowUnattachedRoster}
                    onChange={(value) => { onFormValueChanged('allowUnattachedRoster', value); }}
                    disabled={true} />
                </div>
              </div>
            </form>
            <div className='row usas-extra-bottom-margin'>
              <div className='col-xs-12'>
                <OmeRestrictionsGrid RESTRICTION_TYPE_ROUTES={RESTRICTION_TYPE_ROUTES} omeMeetState={omeMeetState} />
              </div>
            </div>
          </Fragment>}
        {errorState.general &&
          <div className="row">
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
            </div>
          </div>
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Restriction Type:</b>&nbsp;{modalState.restrictionType}</p>
              <p><b>Restriction:</b>&nbsp;{modalState.restriction}</p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onDeleteRestriction(e, modalState.restrictionTypeRoute)}>Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={omeMeetState.isObjLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate >
    </Fragment >
  );
};

export default OmeRestrictionsNonHQ;