import React, { Fragment } from 'react';

import useOUAdminEntryContextView from './UseOUAdminEntryContextView';

import NavLinks from '../../meet/NavLinks';

import MobileContextBasedNav from '../../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../../common/components/contextBasedNav/LargeContextBasedNav';
import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PageNotFound from '../../../../common/components/PageNotFound';

import Constants from '../../../../common/utils/Constants';
import { formatDate, formatDateTime, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../Entry.module.css';

const OUAdminEntryContextView = ({ children }) => {
  const {
    oUAdminEntryContextState,
    meetState,
    omeMeetState,
    omeMeetOrgUnitEntryState,
    backToLabel,
    onHomeClicked,
    onBackClicked
  } = useOUAdminEntryContextView();

  if (!omeMeetOrgUnitEntryState || Object.keys(omeMeetOrgUnitEntryState.objData).length === 0) {
    return omeMeetOrgUnitEntryState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.HeaderButton} onClick={onHomeClicked}>
              <Headings.H3
                className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                excludeBar={true}>
                View/Edit Team Entry
              </Headings.H3>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6">
            <p className={global.HeaderText}><b>Meet Name:</b> {meetState.objData.meetName || ''}</p>
            <p className={global.HeaderText}><b>Meet Host:</b> {meetState.objData.hostOrgUnit?.orgUnitName || ''}</p>
            <p className={global.HeaderText}><b>Meet Dates:</b> {meetState.isObjLoaded ? `${meetState.objData.startDate ? formatDate(meetState.objData.startDate) : ''} - ${meetState.objData.endDate ? formatDate(meetState.objData.endDate) : ''}` : ''}</p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <p className={[global.HeaderText, style.RemoveTopMarginMobile].join(' ')}><b>Team Name:</b> {omeMeetOrgUnitEntryState.objData.orgUnit?.orgUnitName || ''} {omeMeetOrgUnitEntryState.objData.orgUnit?.orgUnitName === 'Unattached' ? ' - ' + omeMeetOrgUnitEntryState.objData.orgUnit?.parentOrgUnitName : ''} {omeMeetOrgUnitEntryState.objData.orgUnit?.parentOrgUnitCode ? '(' + omeMeetOrgUnitEntryState.objData.orgUnit?.parentOrgUnitCode + ')' : ''}</p>
            <p className={global.HeaderText}><b>Team Entry Status:</b> {omeMeetOrgUnitEntryState.objData.status || ''} {omeMeetOrgUnitEntryState.objData.orgUnitSubmissionDatetime ? '- ' + formatDateTime(omeMeetOrgUnitEntryState.objData.orgUnitSubmissionDatetime) + ' ' + omeMeetOrgUnitEntryState.objData.timeZone : ''}</p>
          </div>
          <div className='col-xs-12'>
            <p className={[global.HeaderText, style.RemoveTopMargin].join(' ')}><b>Entry Dates:</b> {omeMeetState?.objData?.meetEntryStartDate ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.meetEntryStartDate) : ''} - {omeMeetState?.objData?.meetEntryEndDate ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.meetEntryEndDate) : ''} {omeMeetState?.objData?.timeZone || ''}</p>
            <p className={global.HeaderText}><b>Entry Change Deadline:</b>
              &nbsp;{omeMeetState?.objData?.meetEntryChangeDeadline ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.meetEntryChangeDeadline) : ''}
              &nbsp;{omeMeetState?.objData?.timeZone || ''}</p>
            <p className={global.HeaderText}><b>Qualifying Date Range:</b>
              &nbsp;{omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards === false ? 'N/A (Meet does not use Time Standards)' : omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate && omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate ? `${formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate)} - ${formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate)}` : ''}</p>
          </div>
        </div>
        {oUAdminEntryContextState.showNav === true &&
          <div className="row">
            <div className="visible-xs col-xs-12">
              <MobileContextBasedNav taxonomyName={omeMeetState.route === '' ? "OUEntryDetail" : "OUMeetDetail"} renderPillsOnly={true} />
            </div>
            <div className="hidden-xs col-xs-12">
              <LargeContextBasedNav taxonomyName={omeMeetState.route === '' ? "OUEntryDetail" : "OUMeetDetail"} homeRoute={NavLinks.OU_ADMIN_LANDING} renderPillsOnly={true} />
            </div>
          </div>}
      </div>
      {children}
    </Fragment >
  );
};

export default OUAdminEntryContextView;