import { useContext } from 'react';

import { TimeStandardTypeStateContext } from './TimeStandardTypeContextProvider';
import TimeStandardTypeData from './TimeStandardTypeData';

const useTimeStandardTypeData = () => {
  const [timeStandardTypeState, setTimeStandardTypeState] = useContext(TimeStandardTypeStateContext);

  const getTimeStandardTypes = () => {
    TimeStandardTypeData.getTimeStandardTypesData(timeStandardTypeState, setTimeStandardTypeState);
  };

  const getTimeStandardTypesByOrgUnitId = (orgUnitId) => {
    TimeStandardTypeData.getTimeStandardTypeByOrgUnitIdData(orgUnitId, timeStandardTypeState, setTimeStandardTypeState);
  };

  const getTimeStandardTypesHierarchy = () => {
    TimeStandardTypeData.getTimeStandardTypeHierarchyData(timeStandardTypeState, setTimeStandardTypeState);
  };

  return {
    timeStandardTypeState, setTimeStandardTypeState, getTimeStandardTypes,
    getTimeStandardTypesByOrgUnitId, getTimeStandardTypesHierarchy
  };
};

export default useTimeStandardTypeData;