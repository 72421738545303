/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';

import { formatDate, formatDateTime } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const LargeGrid = ({ teamEntry, onAdd, onEdit, onDeleteClicked, onView }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <th style={{ minWidth: '125px' }}>Meet Host</th>
        <th style={{ minWidth: '180px' }}>Meet Start - End Dates</th>
        <th style={{ minWidth: '125px' }}>Team Name</th>
        <th style={{ minWidth: '150px' }}>Team Entry Status</th>
        <th style={{ minWidth: '100px' }}></th>
      </tr>
    </thead>
    <tbody>
      {teamEntry.map((teamEntry, i) => {
        return (
          <tr key={i}>
            <td>{teamEntry.isReadOnly === true ? <a href='' onClick={(e) => onView(e, teamEntry)}>{teamEntry.meetName || ''}</a> : <span>{teamEntry.meetName || ''}</span>}</td>
            <td>{teamEntry.meetHost || ''}</td>
            <td>{teamEntry?.startDate ? formatDate(teamEntry?.startDate) : '?'} - {teamEntry?.endDate ? formatDate(teamEntry?.endDate) : '?'}</td>
            <td>{teamEntry?.orgUnitName || ''} {teamEntry?.orgUnitName === 'Unattached' ? (teamEntry?.parentOrgUnitName ? '- ' + teamEntry?.parentOrgUnitName : '') : ''} {teamEntry?.parentOrgUnitCode ? '(' + teamEntry?.parentOrgUnitCode + ')' : ''}</td>
            <td>{teamEntry?.teamEntryStatus || ''} {teamEntry.orgUnitSubmissionDateTime ? '- ' + formatDateTime(teamEntry.orgUnitSubmissionDateTime) + ' ' + teamEntry.timeZone : ''}</td>
            <td>
              {teamEntry?.omeMeetOrgUnitEntryId > 0 ?
                <span>
                  {(teamEntry.teamEntryCurrentlyAllowed === true || teamEntry.teamEntryChangesCurrentlyAllowed === true) &&
                    <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, teamEntry)}><EditIcon /></button>}
                  {teamEntry.teamEntryDeleteCurrentlyAllowed === true &&
                    <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, teamEntry)}><DeleteIcon /></button>}
                </span>
                :
                <Fragment>
                  {teamEntry.teamEntryCurrentlyAllowed === true &&
                    <button className={global.IconButtonMargin} type="button" onClick={(e) => onAdd(e, teamEntry)}><PlusIcon toolTipText='Enter OME Meet' /></button>}
                </Fragment>}
            </td>
          </tr>
        )
      })}
    </tbody>
  </table>
);

const SmallGrid = ({ teamEntry, onAdd, onEdit, onDeleteClicked, onView }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {teamEntry.map((teamEntry, i) => {
      return (
        <div className={global.SmallTableRow} key={i}>
          <div className={global.SmallTableRowHead} key={i}>{teamEntry.isReadOnly === true ? <span /> : <span className='hidden-xs'>{teamEntry.meetName || ''}</span>}{(teamEntry.teamEntryDeleteCurrentlyAllowed === true || teamEntry.teamEntryCurrentlyAllowed === true || teamEntry.teamEntryChangesCurrentlyAllowed === true) && <span>&nbsp;</span>}
            {teamEntry?.omeMeetOrgUnitEntryId > 0
              ? <span>
                {teamEntry.isReadOnly === true &&
                  <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onView(e, teamEntry)}>View</ActionIntraPageButton></div>}
                {teamEntry.teamEntryDeleteCurrentlyAllowed === true &&
                  <button className={global.IconButton} type="button" onClick={(e) => onDeleteClicked(e, teamEntry)}><DeleteIcon /></button>}
                {(teamEntry.teamEntryCurrentlyAllowed === true || teamEntry.teamEntryChangesCurrentlyAllowed === true) &&
                  <Fragment>
                    {teamEntry.teamEntryDeleteCurrentlyAllowed === true ?
                      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, teamEntry)}><EditIcon /></button> :
                      <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, teamEntry)}><EditIcon /></button>}
                  </Fragment>}
              </span>
              :
              <Fragment>
                {teamEntry.teamEntryCurrentlyAllowed === true &&
                  <button className={global.IconButton} type="button" onClick={(e) => onAdd(e, teamEntry)}> <PlusIcon toolTipText='Enter OME Meet' /> </button>}
              </Fragment>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              {teamEntry.isReadOnly === true ?
                <span>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry.meetName || <span>&nbsp;</span>}</div>
                </span> :
                <span>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Meet Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{teamEntry.meetName || <span>&nbsp;</span>}</div>
                </span>
              }
              <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Meet Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{teamEntry.meetName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet Host</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry.meetHost || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet Start Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry?.startDate ? formatDate(teamEntry?.startDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet End Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry?.endDate ? formatDate(teamEntry?.endDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Team Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry?.orgUnitName || <span>&nbsp;</span>} {teamEntry?.orgUnitName === 'Unattached' ? (teamEntry?.parentOrgUnitName ? '- ' + teamEntry?.parentOrgUnitName : '') : ''} {teamEntry?.parentOrgUnitCode ? '(' + teamEntry?.parentOrgUnitCode + ')' : ''}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Team Entry Status</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{teamEntry?.teamEntryStatus || <span>&nbsp;</span>} {teamEntry?.orgUnitSubmissionDateTime ? '- ' + formatDateTime(teamEntry?.orgUnitSubmissionDateTime) + ' ' + teamEntry?.timeZone : ''}</div>
            </div>
          </div>
        </div>)
    }
    )}
  </div>
);

const TeamEntryGrid = ({ state, onAdd, onEdit, onDeleteClicked, onView }) => {
  if (Array.isArray(state.arrayData) === false || state.arrayData?.length === 0) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <p>0 Search Results</p>
          </div>
        </div>
        <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
          <thead>
            <tr>
              <th>Meet Name</th>
              <th>Meet Host</th>
              <th>Meet Start - End Dates</th>
              <th>Team Entry Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="5">No Results</td>
            </tr>
          </tbody>
        </table>
        <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          {state.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{state.arrayData.length === 1 ? `${state.arrayData.length} Search Result` : `${state.arrayData.length} Search Results`}</p>}
        </div>
      </div>
      <LargeGrid teamEntry={state.arrayData} onAdd={onAdd} onEdit={onEdit} onDeleteClicked={onDeleteClicked} onView={onView} />
      <SmallGrid teamEntry={state.arrayData} onAdd={onAdd} onEdit={onEdit} onDeleteClicked={onDeleteClicked} onView={onView} />
    </Fragment>
  );
}
export default TeamEntryGrid;