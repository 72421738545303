import React, { Fragment } from 'react';

import useAddMeetTimeSelectTimeType from './UseAddMeetTimeSelectTimeType';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import RadioButtonList from '../../../../../common/components/radiobuttonlists/RadiobuttonList';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

const AddMeetTimeSelectTimeType = () => {
  const {
    meetTimeSelectTimeState,
    onSelectMeetTimeType,
    formState,
    errorState,
    onFormValueChanged,
    onCancelClicked,
  } = useAddMeetTimeSelectTimeType();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Meet Time Result</Headings.H3>
        </div>
      </div>
      <form onSubmit={onSelectMeetTimeType} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <RadioButtonList
              label="Select Time Result Type"
              name={"timeTypeName"}
              items={meetTimeSelectTimeState}
              value={formState.timeTypeName}
              error={errorState.timeTypeName}
              message={errorState.timeTypeName}
              isVertical={true}
              onChange={(value) => { onFormValueChanged("timeTypeName", value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default AddMeetTimeSelectTimeType;