import { useEffect } from 'react';

import useEventMeetEventBonusData from '../../state/eventMeetEventBonus/UseEventMeetEventBonusData';

const useEventMeetEventBonusDropdown = (meetEventId) => {
  const { eventMeetEventBonusState, getEventMeetEventBonus } = useEventMeetEventBonusData();

  useEffect(() => {
    if (eventMeetEventBonusState.isArrayLoading !== true && meetEventId !== eventMeetEventBonusState.meetEventId
      && meetEventId > 0) {
      getEventMeetEventBonus(meetEventId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMeetEventBonusState, meetEventId])

  return {
    eventMeetEventBonusState
  };
};

export default useEventMeetEventBonusDropdown;