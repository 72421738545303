import React from 'react';

import useMeetReporting from './UseMeetReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const MeetReporting = () => {
  const { reportParameters, contextId, routeName } = useMeetReporting();

  return (
    <ReportList listTitle="Meet Reports" 
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default MeetReporting;