import { useContext } from 'react';

import { EventMeetEventStateContext } from './EventMeetEventContextProvider';

import EventMeetEventData from './EventMeetEventData';

const useEventMeetEventData = () => {
  const [eventMeetEventState, setEventMeetEventState] = useContext(EventMeetEventStateContext);

  const getEventMeetEvent = (meetEventId) => {
    EventMeetEventData.getEventMeetEventData(meetEventId, eventMeetEventState, setEventMeetEventState);
  };

  return {
    eventMeetEventState,
    setEventMeetEventState,
    getEventMeetEvent
  };
};

export default useEventMeetEventData;