import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmePricingAdditionalPurchasesValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmePricing from './UseOmePricing';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeFeeTypeData from '../../../../state/omeFeeType/UseOmeFeeTypeData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../../common/utils/UseForm';

import Constants from '../../../../../common/utils/Constants';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const INITIAL_VIEW_STATE = {
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  pricingFormState: {},
  omeMeetPriceId: Constants.DEFAULT_ID,
  omeFeeTypeId: Constants.DEFAULT_ID,
  omeFeeTypeName: '',
  amount: '',
  description: ''
};

const useOmePricingAdditionalPurchases = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeFeeTypeState } = useOmeFeeTypeData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formStateHasBeenSaved } = useOmePricing();
  const { TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID, INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID,
    INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID,
    ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID } = useEnvironmentVariableData();
  const { formState, errorState, onFormValueChanged, isSubmitting, handleSubmit, onValueTextPairChanged, setIsDirty,
    isDirty, setFormData, setErrors } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_PRICING, { state: { pricingFormState: formState.pricingFormState } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_PRICING, { state: { pricingFormState: formState.pricingFormState } });
    }
  };

  function submitFormCallback(formState) {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    const updatedOmeMeetCopy = JSON.parse(JSON.stringify(updatedOmeMeet));
    if (updatedOmeMeet.omeMeetPrice.length > 0) {
      updatedOmeMeet.omeMeetPrice = updatedOmeMeet.omeMeetPrice.filter(x => x.omeFeeType?.isAdditionalPurchase === true);
    }

    updatedOmeMeet.omeMeetPrice = [
      ...updatedOmeMeet.omeMeetPrice,
      ...(formState.pricingFormState?.costPerTeam !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === TEAM_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: TEAM_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerTeam }] : []),
      ...(formState.pricingFormState?.costPerCredential !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === CREDENTIAL_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: CREDENTIAL_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerCredential }] : []),
      ...(formState.pricingFormState?.costPerIndividualAthlete !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerIndividualAthlete }] : []),
      ...(formState.pricingFormState?.costPerIndividualInternationalAthlete !== '' && formState.pricingFormState?.showCostPerIndividualInternationalAthlete === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerIndividualInternationalAthlete }] : []),
      ...(formState.pricingFormState?.costPerInternationalStaff !== '' && formState.pricingFormState?.showCostPerInternationalStaff === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: INTL_STAFF_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerInternationalStaff }] : []),
      ...(formState.pricingFormState?.costPerRelayOnlySwimmer !== '' && formState.pricingFormState?.showCostPerRelayOnlySwimmer === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerRelayOnlySwimmer }] : []),
      ...(formState.pricingFormState?.costPerAthleteIndividualEventEntry !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerAthleteIndividualEventEntry }] : []),
      ...(formState.pricingFormState?.costPerAthleteIndividualBonusEventEntry !== '' && formState.pricingFormState?.showCostPerAthleteIndividualBonusEventEntry === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerAthleteIndividualBonusEventEntry }] : []),
      ...(formState.pricingFormState?.costPerRelayEventEntry !== '' && formState.pricingFormState?.showCostPerRelayEventEntry === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, amount: formState.pricingFormState?.costPerRelayEventEntry }] : []),
    ]

    //Add
    if (formState.omeMeetPriceId < 0) {
      updatedOmeMeet.omeMeetPrice.push({ omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: formState.omeFeeTypeId, amount: formState.amount, description: formState.description.trim() });
    }
    else {
      //Edit
      const selectedOmeFeeTypeIndex = updatedOmeMeet.omeMeetPrice.findIndex(x => x.omeMeetPriceId === formState.omeMeetPriceId);
      if (selectedOmeFeeTypeIndex > -1) {
        updatedOmeMeet.omeMeetPrice[selectedOmeFeeTypeIndex].amount = formState.amount;
        updatedOmeMeet.omeMeetPrice[selectedOmeFeeTypeIndex].description = formState.description;
      }
    }
    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });
  };

  function additionalPurchaseFormStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    //Edit Additional Purchase 
    if (formState?.omeMeetPriceId !== Constants.DEFAULT_ID) {
      const selectedOmeFeeType = omeMeetState.objData.omeMeetPrice?.find(x => x.omeMeetPriceId === formState?.omeMeetPriceId);
      if ((ToIntIfInt(formState?.omeFeeTypeId) !== selectedOmeFeeType?.omeFeeTypeId)
        && (formState?.omeFeeTypeId !== Constants.DEFAULT_ID || selectedOmeFeeType?.omeFeeTypeId !== null)) {
        return false;
      }
      if ((formState?.amount !== selectedOmeFeeType?.amount)
        && (formState?.amount !== '' || selectedOmeFeeType?.amount !== null)) {
        return false;
      }
      if ((formState?.description !== selectedOmeFeeType?.description)
        && (formState?.description !== '' || selectedOmeFeeType?.description !== null)) {
        return false;
      }
    }
    //Add Additional Purchase
    else {
      if (isDirty === true) {
        return false;
      }

      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && omeFeeTypeState.isArrayLoaded === true
      && omeFeeTypeState.isArrayLoading === false) {
      if (location.state) {
        if (location.state.omeMeetPriceId) {
          //Edit
          const selectedOmeFeeType = omeMeetState.objData.omeMeetPrice.find(x => x.omeMeetPriceId === location.state.omeMeetPriceId);
          setFormData({
            ...formState,
            pricingFormState: location.state.pricingFormState || {},
            omeMeetPriceId: location.state.omeMeetPriceId || Constants.DEFAULT_ID,
            omeFeeTypeId: selectedOmeFeeType?.omeFeeTypeId || Constants.DEFAULT_ID,
            omeFeeTypeName: selectedOmeFeeType?.omeFeeType?.typeName || '',
            amount: selectedOmeFeeType?.amount || '',
            description: selectedOmeFeeType?.description || ''
          });
          if (formStateHasBeenSaved(location.state?.pricingFormState) === false) {
            setIsDirty(true);
          }
        }
        else {
          //Add
          setFormData({
            ...formState,
            pricingFormState: location.state.pricingFormState || {}
          });
        }

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, omeFeeTypeState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.pricingFormState);
      const isAdditionalPurchaseSaved = additionalPurchaseFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isAdditionalPurchaseSaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_PRICING);
      }
      else {
        navigate(HostNavLinks.OME_MEET_PRICING);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isSaving, isSubmitting, state.tryRedirect]);

  return {
    omeMeetState,
    omeFeeTypeState,
    formState,
    errorState,
    handleSubmit,
    onNextButtonClicked,
    onBackButtonClicked,
    onFormValueChanged,
    onValueTextPairChanged
  };
};

export default useOmePricingAdditionalPurchases;