import { useEffect, useState } from "react";

const useOmeEventsGrid = (gridState, gridFilters) => {
  const [filteredGridState, setFilteredGridState] = useState([]);

  useEffect(() => {
    if (gridFilters.eventId > 0) {
      setFilteredGridState(gridState.sortedGridData.filter(x => x.eventId === gridFilters.eventId));
    } else {
      setFilteredGridState(gridState.sortedGridData);
    }
  }, [gridState, gridFilters]);

  return {
    filteredGridState
  };
};

export default useOmeEventsGrid;