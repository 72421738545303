//import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  const sortPriority = parseInt(formState.sortPriority);

  if (!(formState.timeStandardId > 0)) {
    errors.timeStandardId = 'Time Standard is required';
  }

  if (isNaN(sortPriority)) {
    errors.sortPriority = 'Priority must be a valid number';
  } else if (!(sortPriority > 0)) {
    errors.sortPriority = 'Priority must be a positive number';
  }
    
  return errors;
};

const TimeStandardFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TimeStandardFormValidation;