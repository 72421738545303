import React, { createContext, useState } from 'react';

import MeetStaffData from './MeetStaffData';

export const MeetStaffStateContext = createContext();

const MeetStaffContextProvider = ({ children }) => {
  const stateHook = useState(MeetStaffData.INITIAL_STATE);

  return (
    <MeetStaffStateContext.Provider value={stateHook}>
      {children}
    </MeetStaffStateContext.Provider>
  );
};

export default MeetStaffContextProvider;