import { useState, useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { CONFIGURATION_NAME } from "../components/leftNav/SelectionsLeftNavConstants";

import NavLinks from "../utils/NavLinks";

import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsLeftNavData from "../../../state/selectionsLeftNav/UseSelectionsLeftNavData";
import useSelectionsLoadingModalData from "../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

import Constants from "../../../../common/utils/Constants";
import ToIntIfInt from "../../../../common/utils/ToIntIfInt";
import { compareNumerical } from "../../../../common/utils/SortingUtils";

const INITIAL_STATE = {
  showConfigurationForm: true,
  showTimeStandardForm: false,
  selectionMeetTimeStandard: [],
  showGridError: false, //TODO error does not show when form has an error
  gridErrorMessage: 'At least one Base Time Standard (Individual or Relay) is required',
  editTimeStandardId: Constants.DEFAULT_ID
};

const useSelectionsConfiguration = () => {
  const navigate = useNavigate();
  const { updateCurrentLeftNavData } = useSelectionsLeftNavData();
  const { selectionsState, putSelectionConfiguration } = useSelectionsData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [state, setState] = useState(INITIAL_STATE);

  // used for readonly continue
  const onContinueClicked = () => {
    navigate(NavLinks.SELECTIONS_MEET_EVENTS);
  };

  // used for edit save & continue
  const onConfigurationSubmitFormCallback = (formState) => {
    if (isSelectionMeetTimeStandardValid(state.selectionMeetTimeStandard)) {
      const selectionsObj = createSelectionsObj(formState, state.selectionMeetTimeStandard, selectionsState.objData);

      updateCurrentLeftNavData(CONFIGURATION_NAME, true);

      const putSelectionConfigurationPromise = putSelectionConfiguration(selectionsObj.selectionMeetId, selectionsObj);

      if (putSelectionConfigurationPromise !== null) {
        putSelectionConfigurationPromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.SELECTIONS_MEET_EVENTS);
          }
        }).catch((e) => {
          updateCurrentLeftNavData(CONFIGURATION_NAME, false);

          setContextStateError(true);
        });
      }
    } else {
      setState({
        ...state,
        showGridError: true
      });
    }
  };

  const onTimeStandardSubmitFormCallback = (formState) => {
    const timeStandardId = formState.timeStandardId;
    const timeStandardName = formState.timeStandardName;
    const isIndividualBaseStandard = formState.isIndividualBaseStandard;
    const isRelayBaseStandard = formState.isRelayBaseStandard;
    const sortPriority = parseInt(formState.sortPriority);

    const selectionMeetTimeStandard = state.selectionMeetTimeStandard.filter((x) => isNotDuplicateTimeStandardOrPriority(timeStandardId, sortPriority, isIndividualBaseStandard, isRelayBaseStandard, x));

    selectionMeetTimeStandard.push({
      timeStandardId,
      timeStandardName,
      sortPriority,
      isIndividualBaseStandard,
      isRelayBaseStandard
    });

    setState({
      ...state,
      showConfigurationForm: true,
      showTimeStandardForm: false,
      editTimeStandardId: Constants.DEFAULT_ID,
      selectionMeetTimeStandard: sortSelectionMeetTimeStandard(selectionMeetTimeStandard)
    });
  };

  const onExitClicked = () => {
    navigate(NavLinks.SELECTIONS_NEW_SELECTION_SEARCH);
  };

  const onTimeStandardFormCancelClicked = () => {
    setState({
      ...state,
      showConfigurationForm: true,
      showTimeStandardForm: false,
      editTimeStandardId: Constants.DEFAULT_ID
    });
  };

  const onAddTimeStandardClicked = () => {
    setState({
      ...state,
      showConfigurationForm: false,
      showTimeStandardForm: true,
      editTimeStandardId: Constants.DEFAULT_ID,
      showGridError: false
    });
  };

  const onEditTimeStandardClicked = (timeStandardId) => {
    setState({
      ...state,
      showConfigurationForm: false,
      showTimeStandardForm: true,
      editTimeStandardId: timeStandardId,
      showGridError: false
    });
  };

  const onDeleteTimeStandardClicked = (timeStandardId) => {
    const selectionMeetTimeStandard = state.selectionMeetTimeStandard.filter(x => x.timeStandardId !== timeStandardId);

    setState({
      ...state,
      selectionMeetTimeStandard
    });
  };

  useEffect(() => {
    if (selectionsState.isObjLoaded === true) {
      const selectionMeetTimeStandard = Array.isArray(selectionsState.objData.selectionMeetTimeStandard)
        ? selectionsState.objData.selectionMeetTimeStandard
        : [];

      setState({
        ...state,
        selectionMeetTimeStandard: sortSelectionMeetTimeStandard(selectionMeetTimeStandard)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState.isObjLoaded]);

  function sortSelectionMeetTimeStandard(selectionMeetTimeStandard) {
    const dataToSort = JSON.parse(JSON.stringify(selectionMeetTimeStandard));

    return dataToSort.sort((a, b) => compareNumerical(a.sortPriority, b.sortPriority) * -1);
  };

  function isNotDuplicateTimeStandardOrPriority(timeStandardId, sortPriority, isIndividualBaseStandard, isRelayBaseStandard,
    existingTimeStandard) {
    if (existingTimeStandard.timeStandardId === timeStandardId) {
      return false;
    } else if (existingTimeStandard.sortPriority === sortPriority) {
      return false;
    } else if (isIndividualBaseStandard === true && existingTimeStandard.isIndividualBaseStandard === isIndividualBaseStandard) {
      return false;
    } else if (isRelayBaseStandard === true && existingTimeStandard.isRelayBaseStandard === isRelayBaseStandard) {
      return false;
    } else {
      return true;
    }
  };

  function createSelectionsObj(formState, selectionMeetTimeStandard, selectionsStateObj) {
    return {
      ...selectionsStateObj,
      selectionDesc: formState.selectionDesc,
      maxSwimmers: ToIntIfInt(formState.maxSwimmers),
      maxDivers: ToIntIfInt(formState.maxDivers),
      minRowsToSeed: ToIntIfInt(formState.minRowsToSeed),
      actualRelayRowsToSeed: ToIntIfInt(formState.minRowsToSeed), // done for a d3 rule
      minRelayRowsToAccept: ToIntIfInt(formState.minRelayRowsToAccept),
      addRatioIndRows: ToIntIfInt(formState.addRatioIndRows),
      addRatioRelayRows: ToIntIfInt(formState.addRatioRelayRows),
      includeEntriesNotCheckedOut: formState.includeEntriesNotCheckedOut,
      selectionMeetTimeStandard
    };
  };

  function isSelectionMeetTimeStandardValid(selectionMeetTimeStandard) {
    const individualBaseTimeStandard = selectionMeetTimeStandard.find(x => x.isIndividualBaseStandard === true);
    const relayBaseTimeStandard = selectionMeetTimeStandard.find(x => x.isRelayBaseStandard === true);

    if (individualBaseTimeStandard || relayBaseTimeStandard) {
      return true;
    } else {
      return false;
    }
  };

  return {
    isLoading: selectionsState.isObjLoading,
    isReadOnly: selectionsState.objData?.isConfigComplete === true,
    state,
    selectionsState,
    onContinueClicked,
    onConfigurationSubmitFormCallback,
    onTimeStandardSubmitFormCallback,
    onExitClicked,
    onTimeStandardFormCancelClicked,
    onAddTimeStandardClicked,
    onEditTimeStandardClicked,
    onDeleteTimeStandardClicked
  };
};

export default useSelectionsConfiguration;