/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Fee Type</th>
        <th>Cost</th>
        <th>Description</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (<Fragment>
          {state.isObjLoading === true ?
            <tr>
              <td colSpan="4">Loading...</td>
            </tr> :
            <tr>
              <td colSpan="4">No Additional Purchase Items</td>
            </tr>
          }
        </Fragment>
        ) : state.objData.omeMeetPrice.length > 0 && gridState.gridData.length > 0 ?
          gridState.gridData.map((purchaseItem, i) => (
            < tr key={purchaseItem.omeMeetPriceId || i} >
              <td>{purchaseItem.omeFeeType?.typeName || ''}</td>
              <td>{purchaseItem.amount >= 0 ? '$' + purchaseItem.amount.toFixed(2) : ''}</td>
              <td>{purchaseItem.description?.length > 50 ? purchaseItem.description.substring(0, 50) + '...' : purchaseItem.description || ''}</td>
              <td>
                {onEdit &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, purchaseItem.omeMeetPriceId)}>
                    <EditIcon />
                  </button>}
                {onDelete &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, purchaseItem)}>
                    <DeleteIcon />
                  </button>}
              </td>
            </tr>)
          ) : (
            <tr>
              <td colSpan="4">No Additional Purchase Items</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ state, gridState, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Additional Purchase Items</div>
            }
          </div>
        </div>
      )
      : state.objData.omeMeetPrice.length > 0 && gridState.gridData.length > 0 ?
        gridState.gridData.map((purchaseItem, i) => (
          <div className={global.SmallTableRow} key={purchaseItem.omeMeetPriceId || i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{purchaseItem.omeFeeType?.typeName || ''}</span>&nbsp;
              {onDelete &&
                <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, purchaseItem)}>
                  <DeleteIcon />
                </button>}
              {onEdit &&
                <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, purchaseItem.omeMeetPriceId)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Fee Type</div>
                <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem.omeFeeType?.typeName || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Cost</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem?.amount >= 0 ? '$' + purchaseItem.amount.toFixed(2) : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Description</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem.description?.length > 50 ? purchaseItem.description.substring(0, 50) + '...' : purchaseItem.description || <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )
        )
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Additional Purchase Items</div>
            </div>
          </div>
        )}
  </div >
);

const OmePricingAdditionalPurchasesGrid = ({ omeMeetState, gridState, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={omeMeetState} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={omeMeetState} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default OmePricingAdditionalPurchasesGrid;