import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const searchLaneSheetLandingData = (filterObject, sortBy, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/Meet/search?filters=${encodeURIComponent(filterObject)}&sortBy=${sortBy}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const postLaneSheetMeetInfoData = (laneSheetMeetInfoObj, state, setState) => {
  if (laneSheetMeetInfoObj) {
    let newState = {
      ...state,
      isSaving: true
    };

    const api = initApi(API_NAMES.MEET, newState, setState);
    const url = '/LaneSheetMeet';
    return api?.executeObject ? api.executeObject(url, 'POST', laneSheetMeetInfoObj) : null;
  }
};

const getLaneSheetMeetInfoData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/MeetInfo`;
    
    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putLaneSheetMeetInfoData = (laneSheetMeetId, laneSheetMeetInfoObj, state, setState) => {
  if (laneSheetMeetId > 0 && laneSheetMeetInfoObj) {
    let newState = {
      ...state,
      isSaving: true
    };

    const api = initApi(API_NAMES.MEET, newState, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/MeetInfo`;
    return api?.executeObject ? api.executeObject(url, 'PUT', laneSheetMeetInfoObj) : null;
  }
};

const LaneSheetData = {
  searchLaneSheetLandingData,
  postLaneSheetMeetInfoData,
  getLaneSheetMeetInfoData,
  putLaneSheetMeetInfoData
};

export default LaneSheetData;