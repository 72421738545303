import { useContext } from 'react';

import { RelayAthleteMeetEventStateContext } from './RelayAthleteMeetEventContextProvider';

import RelayAthleteMeetEventData from './RelayAthleteMeetEventData';

const useRelayAthleteMeetEventData = () => {
  const [relayAthleteMeetEventState, setRelayAthleteMeetEventState] =
    useContext(RelayAthleteMeetEventStateContext);

  const getOrgUnitAthleteEntryRelayAthleteByMeetEventId = (meetEventId, omeMeetOrgUnitEntryId) =>
    RelayAthleteMeetEventData.getOrgUnitAthleteEntryRelayAthleteByMeetEventIdData(meetEventId, omeMeetOrgUnitEntryId, relayAthleteMeetEventState, setRelayAthleteMeetEventState);


  const clearRelayAthleteMeetEventArrayData = () => {
    setRelayAthleteMeetEventState({
      ...relayAthleteMeetEventState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    relayAthleteMeetEventState,
    setRelayAthleteMeetEventState,
    clearRelayAthleteMeetEventArrayData,
    getOrgUnitAthleteEntryRelayAthleteByMeetEventId
  };
};

export default useRelayAthleteMeetEventData;