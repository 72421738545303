import { useEffect } from 'react';
import useMeetDistinctAthletesData from '../../../common/state/meet/meetDistinctAthletes/UseMeetDistinctAthletesData';

const useMeetTimesCompetitorCombobox = (meetId) => {
  const { meetDistinctAthletesState, getMeetDistinctAthletes } = useMeetDistinctAthletesData();

  useEffect(() => {
    if (meetDistinctAthletesState.isArrayLoaded !== true) {
      getMeetDistinctAthletes(meetId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    optionsState: meetDistinctAthletesState.arrayAsOptions
  };
};

export default useMeetTimesCompetitorCombobox;
