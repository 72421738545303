import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeMeetDateValidationData  from './GetOmeMeetDateValidationData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetDateValidationData = {
  getOmeMeetDateValidationData,
  INITIAL_STATE
};

export default OmeMeetDateValidationData;