import { useEffect } from 'react';

import useOrgUnitOmeMeetData from '../../../state/orgUnitOmeMeet/UseOrgUnitOmeMeetData';

const useOrgUnitOmeMeetTreeView = (omeMeetId) => {
  const { orgUnitOmeMeetState, getOrgUnitsByOmeMeetId } = useOrgUnitOmeMeetData();

  useEffect(() => {
    if (omeMeetId > 0) {
      getOrgUnitsByOmeMeetId(omeMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetId]);

  return {
    orgUnitOmeMeetState
  };
};

export default useOrgUnitOmeMeetTreeView;