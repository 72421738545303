import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import useOmeEventsGrid from './UseOmeEventsGrid';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useEventData from '../../../../../common/state/event/UseEventData';
import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { eventAgeGroupsAreComplete, eventTimeCutsAreComplete } from '../../../../components/leftNavigation/LeftNavValidation';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  sortedGridData: [],
  hasTimeStandards: '',
  hasNoSlowerThanTimeStandards: '',
  hasNoFasterThanTimeStandards: '',
  hasBonusTimeStandards: '',
  bonusEvents: '',
  error: '',
  prevHighestEventNumber: 0
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Event?',
  meetAgeGroupId: Constants.DEFAULT_ID,
  meetEventId: Constants.DEFAULT_ID,
  eventNumber: '',
  event: '',
  competitionCategory: '',
  ageRange: '',
  session: ''
};

const useOmeEvents = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [gridFilterState, setGridFilterState] = useState({ eventId: Constants.DEFAULT_ID });
  const { filteredGridState } = useOmeEventsGrid(state, gridFilterState);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { meetState } = useMeetData();
  const { eventState } = useEventData();
  const { SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID } = useEnvironmentVariableData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();

  const onGridFilter = (eventId) => {
    if (gridFilterState.eventId !== eventId) {
      setGridFilterState({
        ...gridFilterState,
        eventId: eventId
      });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE, tryRedirect: true });
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT, tryRedirect: true });
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.BACK, tryRedirect: true });
  };

  const onAddEventClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const prevHighestEventNumber = state.sortedGridData?.length > 0 ? Math.max(...state.sortedGridData?.map(o => o.eventNumber)) : 0;
    setState({ ...state, submitButton: HostRegistrationConstants.ADD, tryRedirect: true, prevHighestEventNumber: prevHighestEventNumber });
  };

  const onEditEvent = (e, event) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_EVENTS_DETAIL, { state: { meetAgeGroupId: event.meetAgeGroupId, meetEventId: event.meetEventId } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_EVENTS_DETAIL, { state: { meetAgeGroupId: event.meetAgeGroupId, meetEventId: event.meetEventId } });
    }
  };

  const onDeleteEventClicked = (e, event) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      displayPopUp: true,
      meetAgeGroupId: event.meetAgeGroupId || Constants.DEFAULT_ID,
      meetEventId: event.meetEventId || Constants.DEFAULT_ID,
      eventNumber: event?.eventNumber || '',
      event: event?.event?.eventName || '',
      competitionCategory: event.eventCompetitionGenderType?.typeName || '',
      ageRange: `${event.minAge || ''} - ${event.maxAge}`,
      session: `${event.sessionOrderId || ''} (${event.sessionType || ''} ${event.sessionDate ? formatDate(event.sessionDate) : ''})`
    });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteEvent = (e, meetAgeGroupId, meetEventId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let updatedOmeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    const selectedAgeGroupIndex = updatedOmeMeetCopy.meetAgeGroup.findIndex(x => x.meetAgeGroupId === meetAgeGroupId);
    if (selectedAgeGroupIndex > -1) {
      const selectedEventIndex = updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent.findIndex(x => x.meetEventId === meetEventId);
      if (selectedEventIndex > -1) {
        updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent.splice(selectedEventIndex, 1);
        onModalCanceled();
        updatedOmeMeetCopy.meet = undefined;
        putOmeMeet(omeMeetState.objData.omeMeetId, updatedOmeMeetCopy);
      }
    }
  }

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && Object.keys(meetState.objData).length > 0) {
      //Format grid data and display in ascending event order
      let meetAgeGroupCopy = []
      if (omeMeetState.objData.meetAgeGroup.length > 0) {
        meetAgeGroupCopy = JSON.parse(JSON.stringify(omeMeetState.objData.meetAgeGroup));
        meetAgeGroupCopy.sort(function (a, b) { return a.minAge - b.minAge });
      }
      let meetEventCopy = [];
      for (let i = 0; i < meetAgeGroupCopy.length; i++) {
        for (let j = 0; j < meetAgeGroupCopy[i].meetEvent.length; j++) {
          const meetSession = meetState.objData.meetSession.find(x => x.meetSessionId === meetAgeGroupCopy[i].meetEvent[j].meetSessionId);
          if (Object.keys(meetSession).length > 0) {
            let meetEventObject = {
              ...meetAgeGroupCopy[i].meetEvent[j],
              sessionOrderId: meetSession.sessionOrderId,
              sessionType: meetSession.sessionType?.typeName || '',
              sessionDate: meetSession.sessionDate,
              meetAgeGroupId: meetAgeGroupCopy[i].meetAgeGroupId,
              ageGroupName: meetAgeGroupCopy[i].ageGroupName,
              minAge: meetAgeGroupCopy[i].minAge,
              maxAge: meetAgeGroupCopy[i].maxAge,
              meetAgeGroupTimeStandard: meetAgeGroupCopy[i].meetAgeGroupTimeStandard
            }
            meetEventCopy.push(meetEventObject);
          }
        }
      }

      if (meetEventCopy.length > 0) {
        meetEventCopy.sort(function (a, b) { return a.eventNumber - b.eventNumber });
      }
      setState({
        ...state,
        sortedGridData: meetEventCopy,
        hasTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasTimeStandards ?? '',
        hasNoSlowerThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards ?? '',
        hasNoFasterThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards ?? '',
        hasBonusTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasBonusTimeStandards ?? '',
        bonusEvents: omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, meetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (eventAgeGroupsAreComplete(omeMeetState.objData) === false) {
          let errorMsg = "Events must be complete before continuing to Pricing. At least one Age Group does not have an Event.";
          setState({
            ...state, error: errorMsg
          });
        }
        else if (eventTimeCutsAreComplete(omeMeetState.objData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID) === false) {
          //Figure out which course inputs are allowed
          let scy = false;
          let scm = false;
          let lcm = false;
          if (omeMeetState.objData.omeMeetEntryCourse.length > 0) {
            for (let i = 0; i < omeMeetState.objData.omeMeetEntryCourse.length; i++) {
              if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCY_COURSE_ID) {
                scy = true;
              } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCM_COURSE_ID) {
                scm = true;
              } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === LCM_COURSE_ID) {
                lcm = true;
              }
            }
          }
          let allowedCourses = [];
          if (scy === true) {
            allowedCourses.push('SCY');
          }
          if (scm === true) {
            allowedCourses.push('SCM');
          }
          if (lcm === true) {
            allowedCourses.push('LCM (where LCM is possible)');
          }
          let errorMsg = `Events must be complete before continuing to Pricing. At least one Event or Alternate Event is missing one or more Course Time Standard Cuts, as determined by each Course Priority set on Meet Info. Either edit Events and Alternate Events to not use Time Standards Cuts, or provide ${allowedCourses.join(', ')} Time Standard Cuts for each Time Standard Type used by each Event and Alternate Event.`;
          setState({
            ...state, error: errorMsg
          });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_PRICING);
          }
          else {
            navigate(HostNavLinks.OME_MEET_PRICING);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_TIME_STANDARDS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_TIME_STANDARDS);
        }
      }
      if (state.submitButton === HostRegistrationConstants.ADD) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS_DETAIL, { state: { prevHighestEventNumber: state.prevHighestEventNumber } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS_DETAIL, { state: { prevHighestEventNumber: state.prevHighestEventNumber } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isSaving, state.tryRedirect, state.submitButton, state.prevHighestEventNumber]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryChangeDeadline === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    state,
    omeMeetState,
    modalState,
    gridFilterState,
    eventState,
    filteredGridState,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddEventClicked,
    onEditEvent,
    onDeleteEventClicked,
    onDeleteEvent,
    onModalCanceled,
    onGridFilter,
    allowEdits
  };
};

export default useOmeEvents;