import { Fragment } from "react";
import useAthleteRaceStats from "./UseAthleteRaceStats";
import AthleteRaceStatsGrid from "./AthleteRaceStatsGrid";

import Headings from "../../../common/components/headings/Headings";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import Constants from "../../../common/utils/Constants";
import ViewReport from "../../../common/components/embeddedReports/ViewReport";

const AthletesRaceStats = () => {
  const { 
    athleteRaceStatsState, 
    viewState,
    setViewState,
    reportListState,
    onViewDashboard,
    INITIAL_VIEW_STATE
  } = useAthleteRaceStats();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>My Athlete's Race Stats</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <AthleteRaceStatsGrid
            data={athleteRaceStatsState.arrayData}
            isLoading={athleteRaceStatsState.isArrayLoading}
            onView={onViewDashboard} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={athleteRaceStatsState.isArrayLoading || reportListState?.isArrayLoading} />
      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />
          </div>
        </div>
      }
    </Fragment>);
}

export default AthletesRaceStats;