import React from 'react';

import useSelectionsTimeStandardDropdown from './UseSelectionsTimeStandardDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const SelectionsTimeStandardDropdown = ({ label, name, value, error, message, onChange, orgUnitId, omeMeetId }) => {
  const { selectionsTimeStandardState } = useSelectionsTimeStandardDropdown(orgUnitId, omeMeetId);

  return selectionsTimeStandardState.message
    ? <span className={global.LoadingMsg}>{selectionsTimeStandardState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={selectionsTimeStandardState.items}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={selectionsTimeStandardState.areItemsLoaded !== true}
      />
    );
};

export default SelectionsTimeStandardDropdown;