import { useState, useEffect } from 'react';

import useMeetRecognitionData from '../../../../common/state/meet/meetRecognition/UseMeetRecognitionData';
import useMeetData from '../../../../common/state/meet/UseMeetData';

const useMeetRecognition = () => {
  const { getMeetRecognition, putMeetRecognition } = useMeetRecognitionData();
  const { meetState } = useMeetData();
  const [gridState, setGridState] = useState(getInitialGridState);

  function handleCheckboxAction(objProp, id) {
    const newList = gridState.arrayData;
    const selectedIndex = newList.findIndex(x => x.organizationId === id);
    newList[selectedIndex][objProp] = !newList[selectedIndex][objProp];

    setGridState({
      ...gridState,
      arrayData: newList
    });
  }

  const onOrgCheckbox = (e, id) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    handleCheckboxAction("isEligibleForOrganization", id);
  }

  const onRecordsCheckbox = (e, id) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    handleCheckboxAction("isEligibleForRecord", id);
  }

  const onTopTimesCheckbox = (e, id) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    handleCheckboxAction("isEligibleForTopTimes", id);
  }

  const onMeetEntryCheckbox = (e, id) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    handleCheckboxAction("isEligibleForMeetEntry", id);
  }

  const onSaveButtonClick = () => {
    putMeetRecognition(meetState.objData.meetId, gridState.arrayData, gridState, setGridState);
  }

  const onCancelButtonClick = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (meetState.isObjLoaded === true) {
      getMeetRecognition(meetState.objData.meetId, gridState, setGridState);
    }
  }

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      getMeetRecognition(meetState.objData.meetId, gridState, setGridState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded]);

  return {
    gridState,
    onOrgCheckbox,
    onRecordsCheckbox,
    onTopTimesCheckbox,
    onMeetEntryCheckbox,
    onSaveButtonClick,
    onCancelButtonClick
  }
}

export default useMeetRecognition;

function getInitialGridState() {
  return {
    arrayData: [],
    isSaving: false,
    isSaved: false,
    isArrayLoading: false,
    isArrayLoaded: false
  };
};