import { useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import EntryNavLinks from '../../EntryNavLinks';
import NavLinks from '../../../meet/NavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryRelayRosterEntry/UseOmeMeetOrgUnitAthleteEntryRelayRosterEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';

import Constants from '../../../../../common/utils/Constants';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

const useOrgUnitAdminRelayEntries = () => {
    const navigate = useNavigate();
    const { omeMeetState } = useOmeMeetData();
    const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
    const { omeMeetOrgUnitAthleteEntryRelayRosterEntryState, getOrgUnitAthleteEntryRelayRosterEntry }
        = useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData();
    const { oUAdminEntryContextState } = useOUAdminEntryContextData();

    const onEditRelayClicked = (e, event) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        //Remove time cuts if they are the min/max default time cuts
        const eventCopy = JSON.parse(JSON.stringify(event));
        for (let i = 0; i < eventCopy.timeCuts?.length; i++) {
            if (eventCopy.timeCuts[i]?.noSlowerThanTimeCut &&
                formatTimeForDisplay(eventCopy.timeCuts[i]?.noSlowerThanTimeCut) === Constants.MAX_SWIM_TIME_STRING) {
                eventCopy.timeCuts[i].noSlowerThanTimeCut = null;
            }
            if (eventCopy.timeCuts[i]?.noFasterThanTimeCut &&
                formatTimeForFilterObject(eventCopy.timeCuts[i]?.noFasterThanTimeCut) === Constants.MIN_SWIM_TIME_STRING) {
                eventCopy.timeCuts[i].noFasterThanTimeCut = null;
            }
        };
        const updatedTimeCuts = []
        for (let i = 0; i < eventCopy.timeCuts?.length; i++) {
            if ((eventCopy.timeCuts[i]?.noFasterThanTimeCut && eventCopy.timeCuts[i]?.noFasterThanTimeCut !== null) ||
                (eventCopy.timeCuts[i]?.noSlowerThanTimeCut && eventCopy.timeCuts[i]?.noSlowerThanTimeCut !== null)) {
                updatedTimeCuts.push(eventCopy.timeCuts[i]);
            }
        };
        eventCopy.timeCuts = updatedTimeCuts;

        if (omeMeetState.route === '') {
            //OME Route
            navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES_TEAMS, { state: { event: eventCopy } });
        }
        else {
            //Meet Route
            navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES_TEAMS, { state: { event: eventCopy } });
        }
    };

    useEffect(() => {
        if (omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoaded === false) {
            getOrgUnitAthleteEntryRelayRosterEntry(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState])

    return {
        oUAdminEntryContextState,
        omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
        onEditRelayClicked
    };
}

export default useOrgUnitAdminRelayEntries;