import { useContext } from 'react';

import { OrgUnitAthleteEntryIndividualRosterEntryStateContext } from './OrgUnitAthleteEntryIndividualRosterEntryContextProvider';

import OrgUnitAthleteEntryIndividualRosterEntryData from './OrgUnitAthleteEntryIndividualRosterEntryData';

const useOrgUnitAthleteEntryIndividualRosterEntryData = () => {
  const [orgUnitAthleteEntryIndividualRosterEntryState, setOrgUnitAthleteEntryIndividualRosterEntryState] =
    useContext(OrgUnitAthleteEntryIndividualRosterEntryStateContext);

  const getOrgUnitAthleteEntryIndividualRosterEntry = (orgUnitAthleteEntryId) =>
    OrgUnitAthleteEntryIndividualRosterEntryData.getOrgUnitAthleteEntryIndividualRosterEntryData(orgUnitAthleteEntryId,
      orgUnitAthleteEntryIndividualRosterEntryState, setOrgUnitAthleteEntryIndividualRosterEntryState);

  const getOrgUnitAthleteEntryBonusIndividualRosterEntry = (orgUnitAthleteEntryId) =>
    OrgUnitAthleteEntryIndividualRosterEntryData.getOrgUnitAthleteEntryBonusIndividualRosterEntryData(orgUnitAthleteEntryId,
      orgUnitAthleteEntryIndividualRosterEntryState, setOrgUnitAthleteEntryIndividualRosterEntryState);

  const putOrgUnitAthleteEntryIndividualRosterEntry = (orgUnitAthleteEntryId, orgUnitAthleteEntryIndividualRosterEntryArray) =>
    OrgUnitAthleteEntryIndividualRosterEntryData.putOrgUnitAthleteEntryIndividualRosterEntryData(orgUnitAthleteEntryId,
      orgUnitAthleteEntryIndividualRosterEntryArray,
      orgUnitAthleteEntryIndividualRosterEntryState,
      setOrgUnitAthleteEntryIndividualRosterEntryState);

  const putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntry = (orgUnitAthleteEntryId, orgUnitAthleteEntryIndividualRosterEntryArray) =>
    OrgUnitAthleteEntryIndividualRosterEntryData.putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntryData(orgUnitAthleteEntryId,
      orgUnitAthleteEntryIndividualRosterEntryArray,
      orgUnitAthleteEntryIndividualRosterEntryState,
      setOrgUnitAthleteEntryIndividualRosterEntryState);

  const putOrgUnitAthleteEntryApproveIndividualEvents = (orgUnitAthleteEntryId, orgUnitAthleteEntryApproveEventsArray) =>
    OrgUnitAthleteEntryIndividualRosterEntryData.putOrgUnitAthleteEntryApproveIndividualEventsData(orgUnitAthleteEntryId,
      orgUnitAthleteEntryApproveEventsArray,
      orgUnitAthleteEntryIndividualRosterEntryState,
      setOrgUnitAthleteEntryIndividualRosterEntryState);

  const clearOrgUnitAthleteEntryIndividualRosterEntryArrayData = () => {
    setOrgUnitAthleteEntryIndividualRosterEntryState({
      ...orgUnitAthleteEntryIndividualRosterEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    orgUnitAthleteEntryIndividualRosterEntryState,
    setOrgUnitAthleteEntryIndividualRosterEntryState,
    getOrgUnitAthleteEntryIndividualRosterEntry,
    getOrgUnitAthleteEntryBonusIndividualRosterEntry,
    putOrgUnitAthleteEntryIndividualRosterEntry,
    putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntry,
    putOrgUnitAthleteEntryApproveIndividualEvents,
    clearOrgUnitAthleteEntryIndividualRosterEntryArrayData
  };
};

export default useOrgUnitAthleteEntryIndividualRosterEntryData;