import React, { Fragment } from 'react';

import useOmeEligibilityCoachPasses from './UseOmeEligibilityCoachPasses';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../../common/components/inputs/Input';

import Constants from '../../../../../common/utils/Constants';

const OmeEligibilityCoachPasses = () => {
  const {
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
  } = useOmeEligibilityCoachPasses();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_ELIGIBILITY_COACH_PASSES}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Min Number of Athletes*'
                name='minSwimmers'
                type='number'
                min='0'
                value={formState.minSwimmers}
                error={errorState.minSwimmers}
                message={errorState.minSwimmers}
                onChange={(value) => { onFormValueChanged('minSwimmers', value); }} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Max Number of Athletes*'
                name='maxSwimmers'
                type='number'
                min='0'
                value={formState.maxSwimmers}
                error={errorState.maxSwimmers}
                message={errorState.maxSwimmers}
                onChange={(value) => { onFormValueChanged('maxSwimmers', value); }} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Allowed Number of Coach Passes*'
                name='coachPasses'
                type='number'
                min='0'
                value={formState.coachPasses}
                error={errorState.coachPasses}
                message={errorState.coachPasses}
                onChange={(value) => { onFormValueChanged('coachPasses', value); }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeEligibilityCoachPasses;