import React, { createContext, useState } from 'react';

import OUAdminEntryContextData from './OUAdminEntryContextData';

export const OUAdminEntryContextStateContext = createContext();

const OUAdminEntryContextContextProvider = ({ children }) => {
  const stateHook = useState(OUAdminEntryContextData.INITIAL_STATE);

  return (
    <OUAdminEntryContextStateContext.Provider value={stateHook}>
      {children}
    </OUAdminEntryContextStateContext.Provider>
  );
};

export default OUAdminEntryContextContextProvider;