import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLaneSheetTopHeaderInfoData = (laneSheetTopHeaderObj, state, setState) => {
  if (laneSheetTopHeaderObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = '/LaneSheetTopInfo';

    return api?.executeObject ? api.executeObject(url, 'POST', laneSheetTopHeaderObj) : null;
  }
};

const getLaneSheetTopHeaderData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/TopHeader`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const deleteLaneSheetTopHeaderInfoData = (laneSheetTopInfoId, state, setState) => {
  if (laneSheetTopInfoId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetTopInfoIdForUrl = encodeURIComponent(laneSheetTopInfoId);
    const url = `/LaneSheetTopInfo/${laneSheetTopInfoIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LaneSheetTopHeaderData = {
  postLaneSheetTopHeaderInfoData,
  getLaneSheetTopHeaderData,
  deleteLaneSheetTopHeaderInfoData
};

export default LaneSheetTopHeaderData;