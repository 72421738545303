import { useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../utils/NavLinks';

import useLaneSheetData from '../../../../state/laneSheet/UseLaneSheetData';
import useLaneSheetRightSideData from '../../../../state/laneSheetRightSide/UseLaneSheetRightSideData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_POST_RIGHT_SIDE_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetRightSideNotesDetail = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetRightSideState, postLaneSheetRightSideInfo } = useLaneSheetRightSideData();
  const [postRightSideInfoState, setPostRightSideInfoState] = useState(INITIAL_POST_RIGHT_SIDE_INFO_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.LANE_SHEET_RIGHT_SIDE_INFO);
  };

  const onSubmitFormCallback = (formState) => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postLaneSheetRightSideInfoPromise = postLaneSheetRightSideInfo(createLaneSheetRightSideInfoObj(formState, laneSheetMeetId), postRightSideInfoState, setPostRightSideInfoState);

      if (postLaneSheetRightSideInfoPromise !== null) {
        postLaneSheetRightSideInfoPromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.LANE_SHEET_RIGHT_SIDE_INFO);
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  function createLaneSheetRightSideInfoObj(formState, laneSheetMeetId) {
    return {
      laneSheetMeetId,
      displayOrder: formState?.displayOrder,
      nameToShow: formState?.nameToShow?.trim(),
      recordListId: formState?.recordListId,
      recordsToShow: formState?.recordsToShow,
      isRelayOnly: formState?.isRelayOnly
    };
  }

  return {
    isSaving: postRightSideInfoState.isObjLoading,
    rightSideDataLength: laneSheetRightSideState.arrayData?.length || 0,
    onSubmitFormCallback,
    onBackClicked
  }
};

export default useLaneSheetRightSideNotesDetail;