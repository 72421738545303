import { Route, Routes } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import LaneSheetContextView from './LaneSheetContextView';
import LaneSheetLanding from './laneSheetLanding/LaneSheetLanding';
import LaneSheetAddMeetInfo from './laneSheetAddMeetInfo/LaneSheetAddMeetInfo';
import LaneSheetMeetInfo from './laneSheetMeetInfo/LaneSheetMeetInfo';
import LaneSheetMeetEvents from './laneSheetMeetEvents/LaneSheetMeetEvents';
import LaneSheetMeetEventsAddParticipants from './laneSheetMeetEvents/laneSheetMeetEventsAddParticipants/LaneSheetMeetEventsAddParticipants';
import LaneSheetMeetEventsAddEvent from './laneSheetMeetEvents/laneSheetMeetEventsAddEvent/LaneSheetMeetEventsAddEvent';
import LaneSheetTopHeaderNotes from './laneSheetTopHeaderNotes/LaneSheetTopHeaderNotes';
import LaneSheetTopHeaderNotesDetail from './laneSheetTopHeaderNotes/laneSheetTopHeaderNotesDetail/LaneSheetTopHeaderNotesDetail';
import LaneSheetRightSideNotes from './laneSheetRightSideNotes/LaneSheetRightSideNotes';
import LaneSheetRightSideNotesDetail from './laneSheetRightSideNotes/laneSheetRightSideNotesDetail/LaneSheetRightSideNotesDetail';
import LaneSheetHeats from './laneSheetHeats/LaneSheetHeats';
import LaneSheetMeetEventsAddRelayTeams from './laneSheetMeetEvents/laneSheetMeetEventsAddRelayTeams/LaneSheetMeetEventsAddRelayTeams';

import LaneSheetContextProvider from '../../state/laneSheet/LaneSheetContextProvider';
import LaneSheetLandingFilterContextProvider from '../../state/laneSheet/LaneSheetLandingFilterContextProvider';
import LaneSheetTemplateContextProvider from '../../state/laneSheetTemplate/LaneSheetTemplateContextProvider';
import RecordGroupsContextProvider from '../../state/recordGroups/RecordGroupsContextProvider';
import LaneSheetMeetEventsContextProvider from '../../state/laneSheetMeetEvents/LaneSheetMeetEventsContextProvider';
import LaneSheetMeetEventAthletesContextProvider from '../../state/laneSheetMeetEventAthletes/LaneSheetMeetEventAthletesContextProvider';
import RecordListContextProvider from '../../state/recordList/RecordListContextProvider';
import LaneSheetTopHeaderContextProvider from '../../state/laneSheetTopHeader/LaneSheetTopHeaderContextProvider';
import LaneSheetRightSideContextProvider from '../../state/laneSheetRightSide/LaneSheetRightSideContextProvider';
import LaneSheetHeatContextProvider from '../../state/laneSheetHeat/LaneSheetHeatContextProvider';
import LaneSheetHeatAthletesContextProvider from '../../state/laneSheetHeatAthletes/LaneSheetHeatAthletesContextProvider';
import LaneSheetMedalsContextProvider from '../../state/laneSheetMedals/LaneSheetMedalsContextProvider';
import LaneSheetMeetEventRelayTeamsContextProvider from '../../state/laneSheetMeetEventRelayTeams/LaneSheetMeetEventRelayTeamsContextProvider';
import LaneSheetHeatRelayTeamsContextProvider from '../../state/laneSheetHeatRelayTeams/LaneSheetHeatRelayTeamsContextProvider';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import TimeContextProvider from '../../../common/state/time/TimeContextProvider';
import SessionContextProvider from '../../../common/state/time/SessionContextProvider';
import TimeFiltersContextProvider from '../../../common/state/time/TimeFiltersContextProvider';
import TimesOrganizationContextProvider from '../../../common/state/time/TimesOrganizationContextProvider';
import EventCompetitonGenderTypeContextProvider from '../../../common/state/eventCompetitionGender/EventCompetitionGenderTypeContextProvider';

const LaneSheetContextRoutesContextProviders = ({ children }) => (
  <LaneSheetMeetEventsContextProvider>
    <LaneSheetTopHeaderContextProvider>
      <LaneSheetRightSideContextProvider>
        <LaneSheetMeetEventAthletesContextProvider>
          <LaneSheetMeetEventRelayTeamsContextProvider>
            <LaneSheetHeatContextProvider>
              <LaneSheetHeatAthletesContextProvider>
                <LaneSheetHeatRelayTeamsContextProvider>
                  <RecordListContextProvider>
                    {children}
                  </RecordListContextProvider>
                </LaneSheetHeatRelayTeamsContextProvider>
              </LaneSheetHeatAthletesContextProvider>
            </LaneSheetHeatContextProvider>
          </LaneSheetMeetEventRelayTeamsContextProvider>
        </LaneSheetMeetEventAthletesContextProvider>
      </LaneSheetRightSideContextProvider>
    </LaneSheetTopHeaderContextProvider>
  </LaneSheetMeetEventsContextProvider>
);

const LaneSheetBasicRoutesContextProviders = ({ children }) => (
  <TimeContextProvider>
    <TimeFiltersContextProvider>
      <TimesOrganizationContextProvider>
        <SessionContextProvider>
          <EventCompetitonGenderTypeContextProvider>
            <LaneSheetMedalsContextProvider>
              <RecordGroupsContextProvider>
                <LaneSheetTemplateContextProvider>
                  <LaneSheetLandingFilterContextProvider>
                    <LaneSheetContextProvider>
                      {children}
                    </LaneSheetContextProvider>
                  </LaneSheetLandingFilterContextProvider>
                </LaneSheetTemplateContextProvider>
              </RecordGroupsContextProvider>
            </LaneSheetMedalsContextProvider>
          </EventCompetitonGenderTypeContextProvider>
        </SessionContextProvider>
      </TimesOrganizationContextProvider>
    </TimeFiltersContextProvider>
  </TimeContextProvider>
);

const LaneSheetContextRoutes = ({ navRoutes }) => (
  <LaneSheetContextRoutesContextProviders>
    <LaneSheetContextView>
      <Routes>
        <Route path={navRoutes.LANE_SHEET_MEET_INFO?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_MEET_INFO}
              editElement={<LaneSheetMeetInfo />} />
          } />
        <Route path={navRoutes.LANE_SHEET_MEET_EVENTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_MEET_EVENTS}
              editElement={<LaneSheetMeetEvents />} />
          } />
        <Route path={navRoutes.LANE_SHEET_MEET_EVENTS_ADD_EVENTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_MEET_EVENTS_ADD_EVENTS}
              editElement={<LaneSheetMeetEventsAddEvent />} />
          } />
        <Route path={navRoutes.LANE_SHEET_MEET_EVENTS_ADD_PARTICIPANTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_MEET_EVENTS_ADD_PARTICIPANTS}
              editElement={<LaneSheetMeetEventsAddParticipants />} />
          } />
        <Route path={navRoutes.LANE_SHEET_MEET_EVENTS_ADD_RELAY_TEAMS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_MEET_EVENTS_ADD_RELAY_TEAMS}
              editElement={<LaneSheetMeetEventsAddRelayTeams />} />
          } />
        <Route path={navRoutes.LANE_SHEET_TOP_HEADER_INFO?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_TOP_HEADER_INFO}
              editElement={<LaneSheetTopHeaderNotes />} />
          } />
        <Route path={navRoutes.LANE_SHEET_TOP_HEADER_INFO_DETAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_TOP_HEADER_INFO_DETAIL}
              editElement={<LaneSheetTopHeaderNotesDetail />} />
          } />
        <Route path={navRoutes.LANE_SHEET_RIGHT_SIDE_INFO?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_RIGHT_SIDE_INFO}
              editElement={<LaneSheetRightSideNotes />} />
          } />
        <Route path={navRoutes.LANE_SHEET_RIGHT_SIDE_INFO_DETAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_RIGHT_SIDE_INFO_DETAIL}
              editElement={<LaneSheetRightSideNotesDetail />} />
          } />
        <Route path={navRoutes.LANE_SHEET_LANE_SHEETS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.LANE_SHEET_LANE_SHEETS}
              editElement={<LaneSheetHeats />} />
          } />
      </Routes>
    </LaneSheetContextView>
  </LaneSheetContextRoutesContextProviders>
);

const LaneSheetBasicRoutes = ({ navRoutes }) => (
  <LaneSheetBasicRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.LANE_SHEET_LANDING?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.LANE_SHEET_LANDING}
            editElement={<LaneSheetLanding />} />
        } />
      <Route path={navRoutes.LANE_SHEET_ADD?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.LANE_SHEET_ADD}
            editElement={<LaneSheetAddMeetInfo />} />
        } />

      <Route path={'/*'} element={<LaneSheetContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.LANE_SHEET_LANDING?.path} replace />} />
    </Routes>
  </LaneSheetBasicRoutesContextProviders>
);

const LaneSheet = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <LaneSheetBasicRoutes navRoutes={navRoutes} />
  );
};

export default LaneSheet;