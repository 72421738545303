import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom'

import validate from './AddMeetTimeSelectTimeTypeValidation';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import NavLinks from '../../NavLinks';

const MEET_TIME_TYPES = {
  Individual: "Individual",
  Relay: "Relay"
}

const MEET_TIME_TYPE_ROUTES = {
  Individual: NavLinks.MEET_TIMES_ADD_MEET_TIME_INDIVIDUAL,
  Relay: NavLinks.MEET_TIMES_ADD_MEET_TIME_RELAY
}

const useAddMeetTimeSelectTimeType = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [meetTimeSelectTimeState] = useState([
    { id: 1, value: "Individual", label: "Individual" },
    { id: 2, value: "Relay", label: "Relay" },
  ]);

  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    setIsDirty
  } = useForm(initialState, submitFormCallback, validate);

  function submitFormCallback() {
    if (state.meetTimesTypeRoute)
      navigate(state.meetTimesTypeRoute);
  }

  const onSelectMeetTimeType = (e) => {
    setIsDirty(true);
    handleSubmit(e);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME);
  };

  useEffect(() => {
    let meetTimesTypeRoute = state.meetTimesTypeRoute;
    const meetType = formState.timeTypeName;
    if (meetType === MEET_TIME_TYPES.Individual) {
      meetTimesTypeRoute = MEET_TIME_TYPE_ROUTES.Individual
    } else if (meetType === MEET_TIME_TYPES.Relay) {
      meetTimesTypeRoute = MEET_TIME_TYPE_ROUTES.Relay
    }
    setState({
      ...state,
      meetTimesTypeRoute
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.timeTypeName]);

  return {
    meetTimeSelectTimeState,
    onSelectMeetTimeType,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onCancelClicked
  };
}

export default useAddMeetTimeSelectTimeType;

function initialState() {
  return {
    meetTimesTypeRoute: '',
    timeTypeName: Constants.DEFAULT_ID,
    isButtonClicked: false,
  };
};