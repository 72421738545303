import { useContext } from 'react';

import { OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionStateContext } from './OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider';

import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData from './OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData';

const useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData = () => {
  const [omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState, setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState] = useContext(OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionStateContext);

  const getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection = (orgUnitAthleteEntryId, filterObj) =>
    OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData.getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData(orgUnitAthleteEntryId, JSON.stringify(filterObj), omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState, setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState);

  const postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection = (orgUnitAthleteEntryId, orgUnitNonAthleteEntryArray) =>
    OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData.postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData(orgUnitAthleteEntryId, orgUnitNonAthleteEntryArray, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState, setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState);

  const clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionArrayData = () => {
    setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState({
      ...omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState,
    setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState,
    getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection,
    postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection,
    clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionArrayData
  };
};

export default useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData;