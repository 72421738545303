import { useState } from 'react';

import AthleteSecondaryRepresentationData from './AthleteSecondaryRepresentationData';

const useAthleteSecondaryRepresentationData = () => {
  const [athleteSecondaryRepresentationState, setAthleteSecondaryRepresentationState] =
    useState(AthleteSecondaryRepresentationData.INITIAL_STATE);

  const postAthleteSecondaryRepresentation = (athleteSecondaryRepresentationObj) =>
    AthleteSecondaryRepresentationData.postAthleteSecondaryRepresentationData(athleteSecondaryRepresentationObj,
      athleteSecondaryRepresentationState, setAthleteSecondaryRepresentationState);

  const deleteAthleteSecondaryRepresentation = (athleteSecondaryRepresentationId) =>
    AthleteSecondaryRepresentationData.deleteAthleteSecondaryRepresentationData(athleteSecondaryRepresentationId,
      athleteSecondaryRepresentationState, setAthleteSecondaryRepresentationState);

  return {
    athleteSecondaryRepresentationState,
    postAthleteSecondaryRepresentation,
    deleteAthleteSecondaryRepresentation
  };
};

export default useAthleteSecondaryRepresentationData;