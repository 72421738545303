import { useEffect } from "react";

import useLaneSheetHeatAthletesData from "../../../state/laneSheetHeatAthletes/UseLaneSheetHeatAthletesData";
import useLaneSheetMeetEventAthletesData from "../../../state/laneSheetMeetEventAthletes/UseLaneSheetMeetEventAthletesData";
import useLaneSheetData from "../../../state/laneSheet/UseLaneSheetData";

import useMemberPersistentStorage from "../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

import Constants from "../../../../common/utils/Constants";
import formatCrossUiRoute from "../../../../common/utils/FormatCrossUiRoute";

const useLaneSheetHeatAthletesPopup = (laneSheetHeat, onClosePopup) => {
  const { getGlobalRoute } = useGlobalRoutesData();
  const { setPersistentStorage } = useMemberPersistentStorage();
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetMeetEventAthletesState, getLaneSheetMeetEventAthletes, resetLaneSheetMeetEventAthletesState
  } = useLaneSheetMeetEventAthletesData();
  const { laneSheetHeatAthletesState, getLaneSheetHeatAthletes, putLaneSheetHeatAthletes, resetLaneSheetHeatAthletesState
  } = useLaneSheetHeatAthletesData();

  const onSubmitFormCallback = (formState) => {
    const laneSheetHeatId = laneSheetHeat.laneSheetHeatId;
    const numberOfLanes = laneSheetState.objData?.numberOfLanes;
    const meetEventAthletes = laneSheetMeetEventAthletesState.arrayData;

    if (laneSheetHeatId > 0 && numberOfLanes > 0 && Array.isArray(meetEventAthletes)) {
      const laneSheetHeatAthletesArray = createLaneSheetHeatAthletesArray(formState, numberOfLanes, meetEventAthletes);
      const putLaneSheetHeatAthletesPromise = putLaneSheetHeatAthletes(laneSheetHeatId, laneSheetHeatAthletesArray);

      if (putLaneSheetHeatAthletesPromise !== null) {
        putLaneSheetHeatAthletesPromise.then((newState) => {
          if (newState !== null) {
            onClosePopup();
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  const onEditAthlete = (personId) => {
    if (personId) {
      setPersistentStorage(
        personId,
        Constants.UI_PROJECT_NAMES.MEMBER,
        '/',
        'Back to Member Search'
      );

      const routeObj = getGlobalRoute('MEMBER_INFO');
      const crossUiRoute = formatCrossUiRoute(routeObj?.uIProjectName, routeObj?.route, true);

      window.open(crossUiRoute.route);
    }
  };

  useEffect(() => {
    const laneSheetHeatId = laneSheetHeat.laneSheetHeatId;

    if (laneSheetHeatId > 0) {
      const getLaneSheetHeatAthletesPromise = getLaneSheetHeatAthletes(laneSheetHeatId);

      if (getLaneSheetHeatAthletesPromise !== null) {
        getLaneSheetHeatAthletesPromise.catch((e) => {
          //TODO context error
        });
      }
    } else {
      resetLaneSheetHeatAthletesState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetHeat]);

  useEffect(() => {
    const laneSheetEventId = laneSheetHeat.laneSheetEventId;

    if (laneSheetEventId > 0) {
      const getLaneSheetMeetEventAthletesPromise = getLaneSheetMeetEventAthletes(laneSheetEventId);

      if (getLaneSheetMeetEventAthletesPromise !== null) {
        getLaneSheetMeetEventAthletesPromise.catch((e) => {
          //TODO context error
        });
      }
    } else {
      resetLaneSheetMeetEventAthletesState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetHeat]);

  function createLaneSheetHeatAthletesArray(formState, numberOfLanes, meetEventAthletes) {
    const newArrayData = [];

    for (let i = 1; i <= numberOfLanes; i++) {
      const personId = formState[`personId${i}`];

      if (personId) {
        const targetAthlete = meetEventAthletes.find(x => x.personId === personId);

        if (targetAthlete) {
          newArrayData.push({
            laneNumber: i,
            personId,
            firstName: targetAthlete.firstName,
            middleName: targetAthlete.middleName,
            preferredName: targetAthlete.preferredName,
            lastName: targetAthlete.lastName
          });
        }
      }
    }

    return newArrayData;
  };

  return {
    isLoading: laneSheetHeatAthletesState.isArrayLoading || laneSheetMeetEventAthletesState.isArrayLoading,
    numberOfLanes: laneSheetState.objData?.numberOfLanes || 0,
    startingLaneNumber: laneSheetState.objData?.startingLaneNumber || 1,
    meetEventAthletes: laneSheetMeetEventAthletesState.arrayData || [],
    heatAthletes: laneSheetHeatAthletesState.arrayData || [],
    onSubmitFormCallback,
    onEditAthlete
  };
};

export default useLaneSheetHeatAthletesPopup;