import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitEntryPurchaseData from './OmeMeetOrgUnitEntryPurchaseData';

export const OmeMeetOrgUnitEntryPurchaseStateContext = createContext();

const OmeMeetOrgUnitEntryPurchaseContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitEntryPurchaseData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitEntryPurchaseStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitEntryPurchaseStateContext.Provider>
  );
};

export default OmeMeetOrgUnitEntryPurchaseContextProvider;