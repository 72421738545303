import { useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import EntryNavLinks from '../../EntryNavLinks';
import NavLinks from '../../../meet/NavLinks';

const INITIAL_VIEW_STATE = {
  items: [],
  instructionText: 'Pay for OME Team Entry Items'
};

const useOrgUnitAdminPayments = () => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const [state] = useState(INITIAL_VIEW_STATE);

  const redirectToPurchases = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_PURCHASES);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_PURCHASES);
    }
  };

  const redirectToEntryStatus = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_LANDING, { state: { tryGet: true } });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_LANDING, { state: { tryGet: true } });
    }
  }

  return {
    instructionText: state.instructionText,
    redirectToPurchases,
    redirectToEntryStatus,
    orgUnitId: omeMeetOrgUnitEntryState?.objData?.orgUnitId,
    omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId
  };
};

export default useOrgUnitAdminPayments;