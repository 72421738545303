import { formatDate } from "../../../../common/utils/DateFunctions";

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetUploadErrorTankPotentialMatchesSmallGrid = ({ gridData, onRadioChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridData?.isArrayLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData?.arrayData?.length > 0
        ? gridData?.arrayData?.map((person, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className="hidden-xs">{person.firstName}&nbsp;{person?.middleName}&nbsp;{person?.lastName}</span>&nbsp;
              <div className={global.IconButton}>
                <input
                  type="radio"
                  onChange={(e) => { onRadioChanged(e, person) }}
                  id={`memberId-${person.memberId}`}
                  name="memberId"
                  value={person.memberId} />
              </div>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className="row">
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>First Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.firstName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Middle Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.middleName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Last Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.lastName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.memberId || <span>&nbsp;</span>}</div>

                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Date of Birth</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(person.birthDate) || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Gender</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person?.competitionGenderTypeName || <span>&nbsp;</span>} {person?.competitionGenderTypeName === null ? person?.genderIdentityTypeName : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.lscCode || <span>&nbsp;</span>} {person?.lscCode !== null ? "/" : ''} {person.clubCode || <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div >
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetUploadErrorTankPotentialMatchesSmallGrid;