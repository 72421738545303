import React from 'react';

import useMeetTimesEventCombobox from './UseMeetTimesEventCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';

const MeetTimesEventCombobox = ({ label, name, valueToMatch, error, message, onChange, meetId }) => {
  const { optionsState } = useMeetTimesEventCombobox(meetId);

  return (
    <Combobox
      label={label}
      name={name}
      isLoading={false}
      items={optionsState}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default MeetTimesEventCombobox;