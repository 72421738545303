import { Fragment } from "react";

import useSelectionParticipantsGrid from "./UseSelectionParticipantsGrid";

import SelectionParticipantsGridLarge from "./SelectionParticipantsGridLarge";

import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";

const SelectionParticipantsGrid = ({ selectionsMeetEventsStateArray, selectionParticipants, showDetailPopupsInGrid,
  onToggleShowDetailPopups, onParticipantClicked }) => {
  const {
    state
  } = useSelectionParticipantsGrid(selectionsMeetEventsStateArray, selectionParticipants);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-4">
          <YesNoSwitch
            label='Allow Detail Pop-ups in Grid?'
            name='showDetailPopupsInGrid'
            checked={showDetailPopupsInGrid}
            onChange={onToggleShowDetailPopups} />
        </div>
      </div>
      <SelectionParticipantsGridLarge columnData={state.columnData} rowData={state.rowData} columnCount={state.columnCount} showDetailPopupsInGrid={showDetailPopupsInGrid} onParticipantClicked={onParticipantClicked} />
    </Fragment>
  );
};

export default SelectionParticipantsGrid;