import { useState } from 'react';

import SelectionsMeetEntriesData from './SelectionsMeetEntriesData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useSelectionsMeetEntriesData = () => {
  const [selectionsMeetEntriesState, setSelectionsMeetEntriesState] = useState(INITIAL_STATE);

  const getSelectionMeetEntries = (selectionMeetId) => {
    return SelectionsMeetEntriesData.getSelectionMeetEntriesData(selectionMeetId, selectionsMeetEntriesState, setSelectionsMeetEntriesState);
  };

  return {
    selectionsMeetEntriesState,
    getSelectionMeetEntries
  };
};

export default useSelectionsMeetEntriesData;