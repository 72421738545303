import useLaneSheetLandingForm from "./UseLaneSheetLandingForm";

import TreeView from "../../../../../common/components/tree/TreeView";
import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import MeetClassificationTreeView from "../../../../../common/components/tree/meetClassificationTree/MeetClassificationTreeView";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import Dropdown from "../../../../../common/components/dropdowns/Dropdown";

const LaneSheetLandingForm = ({ onSubmitFormCallback, searchFilterObject }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    orgUnitState,
    onOrgUnitChanged,
    onClearFormClicked,
    sortByOptions,
  } = useLaneSheetLandingForm(onSubmitFormCallback, searchFilterObject);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <TreeView
            label="Host Organization*"
            name="orgUnit"
            value={formState.orgUnit}
            options={orgUnitState.treeData}
            selectableLeavesOnly={false}
            singleSelect={true}
            error={errorState.orgUnit}
            message={errorState.orgUnit}
            isLoading={orgUnitState.isArrayLoading}
            onChange={(newValue) => { onOrgUnitChanged(newValue, 'orgUnit') }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Meet Name*"
            name="meetName"
            value={formState.meetName}
            error={errorState.meetName}
            message={errorState.meetName}
            onChange={(value) => { onFormValueChanged('meetName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <MeetClassificationTreeView
            label="Meet Classification"
            name="meetClassification"
            value={formState.meetClassification}
            error={errorState.meetClassification}
            message={errorState.meetClassification}
            selectableLeavesOnly={true}
            singleSelect={true}
            onChange={(newValue) => { onFormValueChanged('meetClassification', newValue) }}
            orgUnitId={formState.orgUnit[0]?.id || undefined}
            filterId={-1}
            filterMode={"exclude_filter_id"}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label="Meet Has Lane Sheet?"
            name="hasLaneSheet"
            checked={formState.hasLaneSheet}
            error={errorState.hasLaneSheet}
            message={errorState.hasLaneSheet}
            onChange={(value) => { onFormValueChanged('hasLaneSheet', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Sort Results By"
            name="sortById"
            options={sortByOptions}
            value={formState.sortById}
            error={errorState.sortById}
            message={errorState.sortById}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
            }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PrimaryButton type="submit">Search For Meet To Link</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onClearFormClicked()}>Clear Form</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetLandingForm;