import React, { Fragment } from 'react';

import LaneSheetMeetEventAddParticipantsLargeGrid from './LaneSheetMeetEventAddParticipantsLargeGrid';
import LaneSheetMeetEventAddParticipantsSmallGrid from './LaneSheetMeetEventAddParticipantsSmallGrid';

const LaneSheetMeetEventAddParticipantsGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            <p>{gridData?.length === 1 ? `${gridData?.length} Athlete` : `${gridData?.length} Athletes`}</p>
          </div>
        </div>
      )
      : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <LaneSheetMeetEventAddParticipantsLargeGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
    <LaneSheetMeetEventAddParticipantsSmallGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default LaneSheetMeetEventAddParticipantsGrid;