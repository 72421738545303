import { Fragment } from 'react';

import LaneSheetHeatLargeGrid from './LaneSheetHeatLargeGrid';
import LaneSheetHeatSmallGrid from './LaneSheetHeatSmallGrid';

const LaneSheetHeatGrid = ({ showArchived, isLoading, gridData, onUpdateDisplayOrder, onUpdateIsSelected, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <LaneSheetHeatLargeGrid
      showArchived={showArchived}
      isLoading={isLoading}
      gridData={gridData}
      onUpdateDisplayOrder={onUpdateDisplayOrder}
      onUpdateIsSelected={onUpdateIsSelected}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <LaneSheetHeatSmallGrid
      showArchived={showArchived}
      isLoading={isLoading}
      gridData={gridData}
      onUpdateDisplayOrder={onUpdateDisplayOrder}
      onUpdateIsSelected={onUpdateIsSelected}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default LaneSheetHeatGrid;