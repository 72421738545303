/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../common/utils/DateFunctions';

const LargeGrid = ({ sortedMeetSessionArray, meetSessionState, formState, errorState, onValueTextPairChanged }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Template Meet Session</th>
          <th>New Meet Session</th>
        </tr>
      </thead>
      <tbody>
        {sortedMeetSessionArray?.length > 0 ?
          sortedMeetSessionArray.map((session, i) => {
            let name = `meetSessionId${session.meetSessionId}`
            let value = `meetSession${session.meetSessionId}`
            return (
              < tr key={session.meetSessionId || i} >
                <td>{session.sessionOrderId >= 0 ? session.sessionOrderId : ''} {session.sessionType?.typeName ? session.sessionType?.typeName : ''} {session.sessionDate ? formatDate(session.sessionDate) : ''}</td>
                <td>
                  <Dropdown
                    label=""
                    name={name}
                    options={meetSessionState.dataAsOptionsWithNames}
                    value={formState[name]}
                    error={errorState[name]}
                    message={errorState[name]}
                    isLoading={false}
                    onChange={(newValue, newValueLabel, e) => {
                      onValueTextPairChanged(
                        newValue, name,
                        newValueLabel, value, e);
                    }} />
                </td>
              </tr>)
          }) : (
            <tr>
              <td colSpan="2">No Template Meet Sessions</td>
            </tr>
          )
        }
      </tbody>
    </table >
  );
}

const SmallGrid = ({ sortedMeetSessionArray, meetSessionState, formState, errorState, onValueTextPairChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {sortedMeetSessionArray?.length > 0 ?
      sortedMeetSessionArray.map((session, i) => {
        let name = `meetSessionId${session.meetSessionId}`
        let value = `meetSession${session.meetSessionId}`
        return (
          <div className={global.SmallTableRow} key={session.meetSessionId || i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>Session Number {session.sessionOrderId}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Template Session</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{session.sessionOrderId >= 0 ? session.sessionOrderId : <span>&nbsp;</span>}({session.sessionType?.typeName ? session.sessionType?.typeName : <span>&nbsp;</span>} {session.sessionDate ? formatDate(session.sessionDate) : <span>&nbsp;</span>})</div>
                <div className={['col-xs-12 col-sm-6', global.SmallTableRowLabels].join(' ')}>New Meet Session</div>
                <div className={['col-xs-12 col-sm-6', global.SmallTableRowData].join(' ')}>
                  <Dropdown
                    label=""
                    name={name}
                    options={meetSessionState.dataAsOptionsWithNames}
                    value={formState[name]}
                    error={errorState[name]}
                    message={errorState[name]}
                    isLoading={false}
                    onChange={(newValue, newValueLabel, e) => {
                      onValueTextPairChanged(
                        newValue, name,
                        newValueLabel, value, e);
                    }} />
                </div>
              </div>
            </div>
          </div>
        )
      })
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Sessions</div>
          </div>
        </div>
      )}
  </div >
);

const SessionsGrid = ({ sortedMeetSessionArray, meetSessionState, formState, errorState, onValueTextPairChanged }) => (
  <Fragment>
    <LargeGrid sortedMeetSessionArray={sortedMeetSessionArray} meetSessionState={meetSessionState} formState={formState} errorState={errorState} onValueTextPairChanged={onValueTextPairChanged} />
    <SmallGrid sortedMeetSessionArray={sortedMeetSessionArray} meetSessionState={meetSessionState} formState={formState} errorState={errorState} onValueTextPairChanged={onValueTextPairChanged} />
  </Fragment>
);

export default SessionsGrid;