const getCompetitionGenderName = (selectMales, selectFemales) => {
  if (selectMales === true && selectFemales === true) {
    return 'Both';
  } else if (selectMales === true) {
    return 'Men';
  } else if (selectFemales === true) {
    return 'Women';
  } else {
    return 'Unknown';
  }
};

const formatPercentageForDisplay = (percentage) => {
  if (percentage) {
    return `${(percentage * 100).toFixed(2)}%`;
  } else {
    return '';
  }
};

const isParticipantSelectionsValid = (selectedSwimmers, maxSwimmers) => {
  return selectedSwimmers > 0 && selectedSwimmers <= maxSwimmers;
};

export { getCompetitionGenderName, formatPercentageForDisplay, isParticipantSelectionsValid };