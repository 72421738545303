import { useState } from 'react';

import RaceStatsCoachesData from './RaceStatsCoachesData';

const useRaceStatsCoachesData = () => {
  const [raceStatsCoachesState, setRaceStatsCoachesState] = useState(RaceStatsCoachesData.INITIAL_STATE);

  const getRaceStatsCoaches = () => {
    RaceStatsCoachesData.getRaceStatsCoachesData(raceStatsCoachesState, setRaceStatsCoachesState);
  };

  return {
    raceStatsCoachesState,
    setRaceStatsCoachesState,
    getRaceStatsCoaches
  };
};

export default useRaceStatsCoachesData;