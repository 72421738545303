import React, { Fragment } from 'react';

import LaneSheetMeetRightSideNotesLargeGrid from './LaneSheetMeetRightSideNotesLargeGrid';
import LaneSheetMeetRightSideNotesSmallGrid from './LaneSheetMeetRightSideNotesSmallGrid';

const LaneSheetMeetRightSideNotesGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <Fragment>
    <LaneSheetMeetRightSideNotesLargeGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
    <LaneSheetMeetRightSideNotesSmallGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default LaneSheetMeetRightSideNotesGrid;