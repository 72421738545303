import React, { Fragment } from 'react';

import OrgUnitAdminRelayEntrySelectionLargeGrid from './OrgUnitAdminRelayEntrySelectionLargeGrid';
import OrgUnitAdminRelayEntrySelectionSmallGrid from './OrgUnitAdminRelayEntrySelectionSmallGrid';

const OrgUnitAdminRelayEntrySelectionGrid = ({ state, gridData, isLoading, meetEventId, omeMeetOrgUnitEntryId,  formState,
  errorState, onValueTextPairChanged, isLarge }) => {

  return (
    <Fragment>
      <form>
        {isLarge === true ?
          <OrgUnitAdminRelayEntrySelectionLargeGrid
            isEdit={Object.keys(state.relayTeam).length > 0 ? true : false}
            gridData={gridData}
            isLoading={isLoading}
            meetEventId={meetEventId}
            omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
            formState={formState}
            errorState={errorState}
            onValueTextPairChanged={onValueTextPairChanged}
            event={state.event}
          /> :
          <OrgUnitAdminRelayEntrySelectionSmallGrid
            isEdit={Object.keys(state.relayTeam).length > 0 ? true : false}
            gridData={gridData}
            isLoading={isLoading}
            meetEventId={meetEventId}
            omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
            formState={formState}
            errorState={errorState}
            onValueTextPairChanged={onValueTextPairChanged}
            event={state.event}
          />}
      </form>
    </Fragment>
  )
};

export default OrgUnitAdminRelayEntrySelectionGrid;