import HostRegistrationViews from '../../views/host/hostRegistration/HostRegistrationViews';

import NavLinks from '../../views/meet/NavLinks';

const INITIAL_STATE = [
  {
    name: HostRegistrationViews.OME_MEET_INFO,
    displayName: 'Meet Info',
    path: NavLinks.MEET_HOST_INFO,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: HostRegistrationViews.OME_MEET_ELIGIBILITY,
    displayName: 'Eligibility',
    path: NavLinks.MEET_HOST_ELIGIBILITY,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_RESTRICTIONS,
    displayName: 'Restrictions',
    path: NavLinks.MEET_HOST_RESTRICTIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_INVITATIONS,
    displayName: 'Invitations',
    path: NavLinks.MEET_HOST_INVITATIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_SESSIONS,
    displayName: 'Sessions',
    path: NavLinks.MEET_HOST_SESSIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_AGE_GROUPS,
    displayName: 'Age Groups',
    path: NavLinks.MEET_HOST_AGE_GROUPS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_TIME_STANDARDS,
    displayName: 'Time Standards',
    path: NavLinks.MEET_HOST_TIME_STANDARDS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_EVENTS,
    displayName: 'Events',
    path: NavLinks.MEET_HOST_EVENTS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_PRICING,
    displayName: 'Pricing',
    path: NavLinks.MEET_HOST_PRICING,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_SUBMIT,
    displayName: 'Submit',
    path: NavLinks.MEET_HOST_SUBMIT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  }];

const GetInitialStateMeetRoute = (viewName) => {
  const state = INITIAL_STATE.find(x => x.name === viewName);
  return JSON.parse(JSON.stringify(state || {}));
};

export default GetInitialStateMeetRoute;