import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeTimeStandardsValidation';

import NavLinks from '../../../meet/NavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useTimeStandardTypeData from '../../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate, formatDateTime } from '../../../../../common/utils/DateFunctions';
import {
  timeStandardsIsComplete,
  timeStandardsAgeGroupsAllHaveAMeetAgeGroupTimeStandardRecord,
  timeStandardsAgeGroupsNoSlowerThanTimeStandardTypeRequirements,
  timeStandardsAgeGroupsNoFasterThanTimeStandardTypeRequirements,
  timeStandardsAgeGroupsFulfillBonusTimeStandardTypeRequirements
} from '../../../../components/leftNavigation/LeftNavValidation';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  sortedGridData: [],
  meetAgeGroupId: Constants.DEFAULT_ID,
  meetAgeGroupTimeStandardId: Constants.DEFAULT_ID,
  error: ''
};

const INITIAL_FORM_STATE = {
  hasTimeStandards: '',
  hasNoSlowerThanTimeStandards: '',
  hasNoFasterThanTimeStandards: '',
  hasBonusTimeStandards: '',
  hasAthleteExceedsTimeStandardsRule: '',
  hasAthleteExceedsTimeStandardsRelayRule: '',
  hasQualifyingWindow: '',
  timeStandardsQualifyingStartDate: Constants.BLANK_DATE_STRING,
  timeStandardsQualifyingEndDate: Constants.BLANK_DATE_STRING,
  meetEntryStartDate: Constants.BLANK_DATETIME_STRING,
  meetEntryEndDate: Constants.BLANK_DATETIME_STRING,
  bonusEvents: '',
  relayEvents: ''
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
  deleteAllEventTimeStandardCuts: false,
  deleteAllEventNoSlowerThanTimeStandardCuts: false,
  deleteAllEventNoFasterThanTimeStandardCuts: false,
  deleteAllEventBonusTimeStandardCuts: false,
};

const useOmeTimeStandards = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { formState, errorState, onValueTextPairChanged, handleSubmit, isDirty, setIsDirty,
    onFormValueChanged, setErrors, isSubmitting, setFormData
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const tryValidateBeforeRedirect = async (submitButton, meetAgeGroupId, meetAgeGroupTimeStandardId) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({
        ...state, tryRedirect: true, submitButton: submitButton,
        meetAgeGroupId: meetAgeGroupId ? meetAgeGroupId : Constants.DEFAULT_ID,
        meetAgeGroupTimeStandardId: meetAgeGroupTimeStandardId ? meetAgeGroupTimeStandardId : Constants.DEFAULT_ID
      });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.BACK);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.BACK });
    }
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT });
    }
  };

  const onEditAgeGroupTimeStandard = (e, meetAgeGroupId, meetAgeGroupTimeStandardId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    tryValidateBeforeRedirect(HostRegistrationConstants.EDIT, meetAgeGroupId, meetAgeGroupTimeStandardId);
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setModalState(INITIAL_MODAL_STATE);
  };

  const onSaveAndDeleteEventTimeStandardCut = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const omeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    const updatedOmeMeet = editOmeMeetObj(omeMeetCopy);

    for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
      for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetEvent.length; j++) {
        if (modalState.deleteAllEventTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime = [];
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit = [];
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime = [];
        }
        if (modalState.deleteAllEventNoSlowerThanTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime = [];
        }
        if (modalState.deleteAllEventNoFasterThanTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit = [];
        }
        if (modalState.deleteAllEventBonusTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime = [];
        }
      }
    }

    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });

    setModalState(INITIAL_MODAL_STATE);
  };

  const editOmeMeetObj = (updatedOmeMeet) => {
    // If omeMeetQualification object has not been created yet
    if (updatedOmeMeet.omeMeetQualification.length === 0) {
      updatedOmeMeet.omeMeetQualification = [{
        omeMeetId: updatedOmeMeet.omeMeetId,
        hasTimeStandards: formState.hasTimeStandards,
        hasNoSlowerThanTimeStandards: formState.hasTimeStandards === true ? (formState.hasNoSlowerThanTimeStandards ?? null) : null,
        hasNoFasterThanTimeStandards: formState.hasTimeStandards === true ? (formState.hasNoFasterThanTimeStandards ?? null) : null,
        hasAthleteExceedsTimeStandardsRule: formState.hasTimeStandards === true && formState.hasNoFasterThanTimeStandards === true ? (formState.hasAthleteExceedsTimeStandardsRule ?? null) : null,
        hasAthleteExceedsTimeStandardsRelayRule: formState.hasTimeStandards === true && formState.hasNoFasterThanTimeStandards === true && formState.relayEvents === true ? (formState.hasAthleteExceedsTimeStandardsRelayRule ?? null) : null,
        hasBonusTimeStandards: formState.hasTimeStandards === true && formState.bonusEvents === true ? (formState.hasBonusTimeStandards ?? null) : null,
        timeStandardsQualifyingStartDate: formState.hasTimeStandards === true ? (formState.hasQualifyingWindow === true ?
          ((formState.timeStandardsQualifyingStartDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsQualifyingStartDate : null)) : Constants.DEFAULT_MIN_DATE)
          : null,
        timeStandardsQualifyingEndDate: formState.hasTimeStandards === true ? (formState.hasQualifyingWindow === true ?
          ((formState.timeStandardsQualifyingEndDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsQualifyingEndDate : null)) : formatDate(updatedOmeMeet.meetEntryEndDate))
          : null,
        omeQualificationBonus: []
      }];
    }
    // If omeMeetQualification object exists
    else if (updatedOmeMeet.omeMeetQualification.length > 0) {
      updatedOmeMeet.omeMeetQualification = [{
        ...updatedOmeMeet.omeMeetQualification[0],
        hasTimeStandards: formState.hasTimeStandards,
        hasNoSlowerThanTimeStandards: formState.hasTimeStandards === true ? (formState.hasNoSlowerThanTimeStandards ?? null) : null,
        hasNoFasterThanTimeStandards: formState.hasTimeStandards === true ? (formState.hasNoFasterThanTimeStandards ?? null) : null,
        hasAthleteExceedsTimeStandardsRule: formState.hasTimeStandards === true && formState.hasNoFasterThanTimeStandards === true ? (formState.hasAthleteExceedsTimeStandardsRule ?? null) : null,
        hasAthleteExceedsTimeStandardsRelayRule: formState.hasTimeStandards === true && formState.hasNoFasterThanTimeStandards === true && formState.relayEvents === true ? (formState.hasAthleteExceedsTimeStandardsRelayRule ?? null) : null,
        hasBonusTimeStandards: formState.hasTimeStandards === true && formState.bonusEvents === true ? (formState.hasBonusTimeStandards ?? null) : null,
        timeStandardsQualifyingStartDate: formState.hasTimeStandards === true ? (formState.hasQualifyingWindow === true ?
          ((formState.timeStandardsQualifyingStartDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsQualifyingStartDate : null)) : '01/01/1900')
          : null,
        timeStandardsQualifyingEndDate: formState.hasTimeStandards === true ? (formState.hasQualifyingWindow === true ?
          ((formState.timeStandardsQualifyingEndDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsQualifyingEndDate : null)) : updatedOmeMeet.meetEntryEndDate)
          : null,
      }];
    }

    if (updatedOmeMeet.meetAgeGroup.length > 0) {
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
        if (formState.hasTimeStandards === false) {
          //Remove all age group time standards if the meet does not have time standards
          updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard = [];
        }
        if (updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard.length > 0) {
          for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard.length; j++) {
            if (formState.hasTimeStandards === true && formState.hasNoSlowerThanTimeStandards === false) {
              //Remove any no slower than time standards if the meet does not have them
              updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
                ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
                noSlowerThanTimeStandardAgeGroupId: null,
                noSlowerThanTimeStandardAgeGroup: undefined
              }
            }
            if (formState.hasTimeStandards === true && formState.hasNoFasterThanTimeStandards === false) {
              //Remove any no faster than time standards if the meet does not have them
              updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
                ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
                noFasterThanTimeStandardAgeGroupId: null,
                noFasterThanTimeStandardAgeGroup: undefined
              }
            }
            if (formState.hasTimeStandards === true && (formState.bonusEvents === false || formState.hasBonusTimeStandards === false)) {
              //Remove any bonus time standards if the meet does not have them
              updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
                ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
                bonusTimeStandardAgeGroupId: null,
                bonusTimeStandardAgeGroup: undefined
              }
            }
          }
        }
      }
    }

    return updatedOmeMeet;
  }

  function submitFormCallback(formState) {
    //Check to see if any event changes need to be handled
    if (showDeleteWarningMessage(formState) === false) {

      const omeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
      const updatedOmeMeet = editOmeMeetObj(omeMeetCopy);

      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
      setState({ ...state, tryRedirect: true });
    }
  };

  function formStateHasBeenSaved(timeStandardsFormState) {
    //compare formState to omeMeetState to see if formState has been saved
    const hasQualifyingWindow = timeStandardsFormState?.hasTimeStandards === true ? ((timeStandardsFormState.timeStandardsQualifyingStartDate && formatDate(timeStandardsFormState.timeStandardsQualifyingStartDate) !== Constants.DEFAULT_MIN_DATE) ||
      (timeStandardsFormState.timeStandardsQualifyingEndDate && formatDate(timeStandardsFormState.timeStandardsQualifyingEndDate) !== formatDate(omeMeetState.objData?.meetEntryEndDate)) ?
      true : false) : null;

    if (timeStandardsFormState?.hasTimeStandards !== omeMeetState?.objData?.omeMeetQualification[0]?.hasTimeStandards
      && (timeStandardsFormState?.hasTimeStandards !== '' || omeMeetState?.objData?.omeMeetQualification[0]?.hasTimeStandards !== null)) {
      return false;
    }
    if (timeStandardsFormState?.hasNoSlowerThanTimeStandards !== omeMeetState?.objData?.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards
      && (timeStandardsFormState?.hasNoSlowerThanTimeStandards !== '' || omeMeetState?.objData?.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards !== null)) {
      return false;
    }
    if (timeStandardsFormState?.hasNoFasterThanTimeStandards !== omeMeetState?.objData?.omeMeetQualification[0]?.hasNoFasterThanTimeStandards
      && (timeStandardsFormState?.hasNoFasterThanTimeStandards !== '' || omeMeetState?.objData?.omeMeetQualification[0]?.hasNoFasterThanTimeStandards !== null)) {
      return false;
    }
    if (timeStandardsFormState?.hasBonusTimeStandards !== omeMeetState?.objData?.omeMeetQualification[0]?.hasBonusTimeStandards
      && (timeStandardsFormState?.hasBonusTimeStandards !== '' || omeMeetState?.objData?.omeMeetQualification[0]?.hasBonusTimeStandards !== null)) {
      return false;
    }
    if (timeStandardsFormState?.hasAthleteExceedsTimeStandardsRule !== omeMeetState?.objData?.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRule
      && (timeStandardsFormState?.hasAthleteExceedsTimeStandardsRule !== '' || omeMeetState?.objData?.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRule !== null)) {
      return false;
    }
    if (timeStandardsFormState?.hasAthleteExceedsTimeStandardsRelayRule !== omeMeetState?.objData?.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRelayRule
      && (timeStandardsFormState?.hasAthleteExceedsTimeStandardsRelayRule !== '' || omeMeetState?.objData?.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRelayRule !== null)) {
      return false;
    }
    if (timeStandardsFormState?.hasQualifyingWindow !== hasQualifyingWindow
      && (timeStandardsFormState?.hasQualifyingWindow !== '' || hasQualifyingWindow !== null)) {
      return false;
    }
    if (timeStandardsFormState?.timeStandardsQualifyingStartDate !== formatDate(omeMeetState?.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate)
      && (timeStandardsFormState?.timeStandardsQualifyingStartDate !== Constants.BLANK_DATE_STRING || omeMeetState?.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate !== null)) {
      return false;
    }
    if (timeStandardsFormState?.timeStandardsQualifyingEndDate !== formatDate(omeMeetState?.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate)
      && (timeStandardsFormState?.timeStandardsQualifyingEndDate !== Constants.BLANK_DATE_STRING || omeMeetState?.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate !== null)) {
      return false;
    }
    if (timeStandardsFormState?.meetEntryStartDate !== formatDateTime(omeMeetState.objData?.meetEntryStartDate)
      && (timeStandardsFormState?.meetEntryStartDate !== Constants.BLANK_DATETIME_STRING || omeMeetState.objData?.meetEntryStartDate !== null)) {
      return false;
    }
    if (timeStandardsFormState?.bonusEvents !== omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents
      && (timeStandardsFormState?.bonusEvents !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents !== null)) {
      return false;
    }

    return true;
  };

  function showDeleteWarningMessage(formState) {
    let numMeetEvents = 0;
    let hasMeetEventQualifyingTime = false;
    let hasMeetEventQualificationLimit = false
    let hasMeetEventBonusTime = false;

    for (let i = 0; i < omeMeetState?.objData?.meetAgeGroup.length; i++) {
      for (let j = 0; j < omeMeetState?.objData?.meetAgeGroup[i].meetEvent.length; j++) {
        numMeetEvents += 1;
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.length > 0) {
          hasMeetEventQualifyingTime = true;
        }
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.length > 0) {
          hasMeetEventQualificationLimit = true;
        }
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length > 0) {
          hasMeetEventBonusTime = true;
        }
      }
    }

    //If no events exist, don't show warning message
    if (numMeetEvents === 0) {
      return false;
    }
    //If events exist, but none of the events have time standard cuts, don't show warning message
    if (numMeetEvents > 0 && hasMeetEventQualifyingTime === false && hasMeetEventQualificationLimit === false &&
      hasMeetEventBonusTime === false) {
      return false;
    }
    else {
      let deleteAllEventTimeStandardCuts = false;
      let deleteAllEventNoSlowerThanTimeStandardCuts = false;
      let deleteAllEventNoFasterThanTimeStandardCuts = false;
      let deleteAllEventBonusTimeStandardCuts = false;

      //compare formState to omeMeetState to see if changes will affect any events using time standard cuts
      if (formState?.hasTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasTimeStandards === true) {
        deleteAllEventTimeStandardCuts = true;
      }

      if (formState?.hasNoSlowerThanTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards === true) {
        deleteAllEventNoSlowerThanTimeStandardCuts = true;
      }

      if (formState?.hasNoFasterThanTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === true) {
        deleteAllEventNoFasterThanTimeStandardCuts = true;
      }

      if (formState?.hasBonusTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasBonusTimeStandards === true) {
        deleteAllEventBonusTimeStandardCuts = true;
      }

      if (deleteAllEventTimeStandardCuts === true || deleteAllEventNoSlowerThanTimeStandardCuts === true ||
        deleteAllEventNoFasterThanTimeStandardCuts === true || deleteAllEventBonusTimeStandardCuts === true) {
        setModalState({
          ...modalState,
          displayPopUp: true,
          deleteAllEventTimeStandardCuts: deleteAllEventTimeStandardCuts,
          deleteAllEventNoSlowerThanTimeStandardCuts: deleteAllEventNoSlowerThanTimeStandardCuts,
          deleteAllEventNoFasterThanTimeStandardCuts: deleteAllEventNoFasterThanTimeStandardCuts,
          deleteAllEventBonusTimeStandardCuts: deleteAllEventBonusTimeStandardCuts
        });
        return true;
      }

      else {
        //If the formState changes are not related to time standards, don't show warning message
        return false;
      }
    }
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      //Sort grid data to display in ascending order
      let meetAgeGroupCopy = [];
      if (omeMeetState.objData.meetAgeGroup.length > 0) {
        meetAgeGroupCopy = JSON.parse(JSON.stringify(omeMeetState.objData.meetAgeGroup));
        meetAgeGroupCopy.sort(function (a, b) { return a.minAge - b.minAge });
      }
      setState({
        ...state,
        sortedGridData: meetAgeGroupCopy
      });
      //Set formState from location.state if 'Back' button was clicked on time standard detail page
      if (location.state && location.state?.timeStandardsFormState) {
        setFormData({
          ...formState,
          hasTimeStandards: location.state?.timeStandardsFormState?.hasTimeStandards ?? '',
          hasNoSlowerThanTimeStandards: location.state?.timeStandardsFormState?.hasNoSlowerThanTimeStandards ?? '',
          hasNoFasterThanTimeStandards: location.state?.timeStandardsFormState?.hasNoFasterThanTimeStandards ?? '',
          hasBonusTimeStandards: location.state?.timeStandardsFormState?.hasBonusTimeStandards ?? '',
          hasAthleteExceedsTimeStandardsRule: location.state?.timeStandardsFormState?.hasAthleteExceedsTimeStandardsRule ?? '',
          hasAthleteExceedsTimeStandardsRelayRule: location.state?.timeStandardsFormState?.hasAthleteExceedsTimeStandardsRelayRule ?? '',
          hasQualifyingWindow: location.state?.timeStandardsFormState?.hasQualifyingWindow ?? '',
          timeStandardsQualifyingStartDate: location.state?.timeStandardsFormState?.timeStandardsQualifyingStartDate ?
            formatDate(location.state?.timeStandardsFormState?.timeStandardsQualifyingStartDate) : Constants.BLANK_DATE_STRING,
          timeStandardsQualifyingEndDate: location.state?.timeStandardsFormState?.timeStandardsQualifyingEndDate ?
            formatDate(location.state?.timeStandardsFormState?.timeStandardsQualifyingEndDate) : Constants.BLANK_DATE_STRING,
          meetEntryStartDate: omeMeetState.objData?.meetEntryStartDate ? formatDateTime(omeMeetState.objData?.meetEntryStartDate) : Constants.BLANK_DATETIME_STRING,
          meetEntryEndDate: omeMeetState.objData?.meetEntryEndDate ? formatDateTime(omeMeetState.objData?.meetEntryEndDate) : Constants.BLANK_DATETIME_STRING,
          bonusEvents: omeMeetState.objData?.omeMeetEligibility[0]?.bonusEvents ?? '',
          relayEvents: omeMeetState.objData?.omeMeetEligibility[0]?.relayEvents ?? ''
        });
        if (formStateHasBeenSaved(location.state?.timeStandardsFormState) === false) {
          setIsDirty(true);
        }
      }
      else {
        //Set formState from omeMeetState
        setFormData({
          ...formState,
          hasTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards ?? '',
          hasNoSlowerThanTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards ?? '',
          hasNoFasterThanTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasNoFasterThanTimeStandards ?? '',
          hasBonusTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasBonusTimeStandards ?? '',
          hasAthleteExceedsTimeStandardsRule: omeMeetState.objData?.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRule ?? '',
          hasAthleteExceedsTimeStandardsRelayRule: omeMeetState.objData?.omeMeetQualification[0]?.hasAthleteExceedsTimeStandardsRelayRule ?? '',
          hasQualifyingWindow: omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards === true ? ((omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate && formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate) !== Constants.DEFAULT_MIN_DATE) ||
            (omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate && formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate) !== formatDate(omeMeetState.objData?.meetEntryEndDate)) ?
            true : false) : '',
          timeStandardsQualifyingStartDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate ?
            formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate) : Constants.BLANK_DATE_STRING,
          timeStandardsQualifyingEndDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate ?
            formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate) : Constants.BLANK_DATE_STRING,
          meetEntryStartDate: omeMeetState.objData?.meetEntryStartDate ? formatDateTime(omeMeetState.objData?.meetEntryStartDate) : Constants.BLANK_DATETIME_STRING,
          meetEntryEndDate: omeMeetState.objData?.meetEntryEndDate ? formatDateTime(omeMeetState.objData?.meetEntryEndDate) : Constants.BLANK_DATETIME_STRING,
          bonusEvents: omeMeetState.objData?.omeMeetEligibility[0]?.bonusEvents ?? '',
          relayEvents: omeMeetState.objData?.omeMeetEligibility[0]?.relayEvents ?? ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && state.tryRedirect === true && isSubmitting === false) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (timeStandardsIsComplete(omeMeetState.objData) === false) {
          let errorMsg = "Time Standards must be complete before continuing to Events.";
          if (timeStandardsAgeGroupsAllHaveAMeetAgeGroupTimeStandardRecord(omeMeetState.objData) === false) {
            errorMsg = errorMsg + "\nAt least one Age Group does not have Time Standards selected. Select Time Standards by clicking on the edit icon(s) in the table above.";
          }
          if (timeStandardsAgeGroupsNoSlowerThanTimeStandardTypeRequirements(omeMeetState.objData) === false) {
            errorMsg = errorMsg + "\nSince 'Meet has No Slower Than Time Standards?' is set to 'Yes', at least one Age Group must have a valid No Slower Than Time Standard.\nIf you do not want to use No Slower Than Time Standards for any Age Groups, please set that field to 'No' above."
          }
          if (timeStandardsAgeGroupsNoFasterThanTimeStandardTypeRequirements(omeMeetState.objData) === false) {
            errorMsg = errorMsg + "\nSince 'Meet has No Faster Than Time Standards?' is set to 'Yes', at least one Age Group must have a valid No Faster Than Time Standard.\nIf you do not want to use No Faster Than Time Standards for any Age Groups, please set that field to 'No' above."
          }
          if (timeStandardsAgeGroupsFulfillBonusTimeStandardTypeRequirements(omeMeetState.objData) === false) {
            errorMsg = errorMsg + "\nSince 'Meet has Bonus Time Standards?' is set to 'Yes', at least one Age Group must have a valid Bonus Time Standard.\nIf you do not want to use Bonus Time Standards for any Age Groups, please set that field to 'No' above."
          }
          setState({
            ...state, error: errorMsg
          });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_EVENTS);
          }
          else {
            navigate(HostNavLinks.OME_MEET_EVENTS);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_AGE_GROUPS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_AGE_GROUPS);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_TIME_STANDARDS_DETAIL, {
            state: {
              timeStandardsFormState: formState,
              meetAgeGroupId: state.meetAgeGroupId,
              meetAgeGroupTimeStandardId: state.meetAgeGroupTimeStandardId
            }
          });
        }
        else {
          navigate(HostNavLinks.OME_MEET_TIME_STANDARDS_DETAIL, {
            state: {
              timeStandardsFormState: formState,
              meetAgeGroupId: state.meetAgeGroupId,
              meetAgeGroupTimeStandardId: state.meetAgeGroupTimeStandardId
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isSaving, state.tryRedirect, state.submitButton, isSubmitting]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryChangeDeadline === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    state,
    omeMeetState,
    timeStandardTypeState,
    formState,
    errorState,
    modalState,
    onSaveAndDeleteEventTimeStandardCut,
    onModalCanceled,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onEditAgeGroupTimeStandard,
    formStateHasBeenSaved,
    allowEdits
  };
};

export default useOmeTimeStandards;