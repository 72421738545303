import { useEffect } from 'react';

import useLaneSheetTemplateData from '../../state/laneSheetTemplate/UseLaneSheetTemplateData';

const useLaneSheetTemplateDropdown = (makeGetCall) => {
  const { laneSheetTemplateState, setLaneSheetTemplateState, getLaneSheetTemplates
  } = useLaneSheetTemplateData();

  useEffect(() => {
    if (makeGetCall === true) {
      if (laneSheetTemplateState.isArrayLoading !== true
        && laneSheetTemplateState.isArrayLoaded !== true) {
        const getLaneSheetPromise = getLaneSheetTemplates();

        if (getLaneSheetPromise !== null) {
          getLaneSheetPromise.then((newState) => {
            if (newState !== null) {

              setLaneSheetTemplateState({
                ...newState,
                options: newState.arrayData.map((x) => { return { id: x.laneSheetTemplateId, name: x.templateName } }),
                areOptionsLoaded: true
              });
            }
          }).catch((e) => {
            // console.log(e);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetTemplateState, makeGetCall]);

  return {
    laneSheetTemplateState
  };
};

export default useLaneSheetTemplateDropdown;