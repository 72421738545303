import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeMeetOrgUnitEntryContactsData from './GetOmeMeetOrgUnitEntryContactsData';
import postOmeMeetOrgUnitEntryContactData from './PostOmeMeetOrgUnitEntryContactData';
import putOmeMeetOrgUnitEntryContactData from './PutOmeMeetOrgUnitEntryContactData';
import deleteOmeMeetOrgUnitEntryContactData from './DeleteOmeMeetOrgUnitEntryContactData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitEntryContactData = {
  INITIAL_STATE, getOmeMeetOrgUnitEntryContactsData, postOmeMeetOrgUnitEntryContactData,
  putOmeMeetOrgUnitEntryContactData, deleteOmeMeetOrgUnitEntryContactData
};

export default OmeMeetOrgUnitEntryContactData;