import { useState, useEffect } from 'react';
import useAthleteRaceStatsData from "../../state/athleteRaceStats/UseAthleteRaceStatsData";

import useEmbeddedReportData from '../../../common/state/embeddedReport/UseEmbeddedReportData';

const INITIAL_VIEW_STATE = {
  reportParameters: { personId: '' },
  orgUnitId: '',
  routeName: '',
  isLoading: false
};

const useAthleteRaceStats = () => {
  const { athleteRaceStatsState, getAthleteRaceStats } = useAthleteRaceStatsData();
  const [ viewState, setViewState ] = useState(INITIAL_VIEW_STATE);
  const { reportListState, getEmbeddedReportListForRoute } = useEmbeddedReportData();

  const onViewDashboard = (personId) => {
    setViewState({
      ...viewState,
      reportParameters: { personId: personId },
      routeName: 'MEMBER_RACE_STATS',
      isLoading: true,
      reportViewerType: 'Sisense'
    });
  }

  useEffect(() => {
    if (athleteRaceStatsState.isArrayLoaded !== true && athleteRaceStatsState.isArrayLoading !==true) {
      getAthleteRaceStats();
    }
  }, []);

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => x.reportViewerType === viewState.reportViewerType);

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0, 
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType]);
  
  return {
    athleteRaceStatsState,
    viewState,
    setViewState,
    reportListState,
    onViewDashboard,
    INITIAL_VIEW_STATE
  }  
}

export default useAthleteRaceStats;