import useLaneSheetAddMeetInfoForm from "./UseLaneSheetAddMeetInfoForm";

import RecordGroupDropdown from "../../../../components/dropdowns/RecordGroupDropdown";
import LaneSheetTemplateDropdown from "../../../../components/dropdowns/LaneSheetTemplateDropdown";
import LaneSheetMedalDropdown from "../../../../components/dropdowns/LaneSheetMedalDropdown";

import RadiobuttonList from "../../../../../common/components/radiobuttonlists/RadiobuttonList";
import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import MultiSelect from "../../../../../common/components/multiSelect/MultiSelect";

const LaneSheetAddMeetInfoForm = ({ onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    laneOrderState,
    careerStatCourseOptions,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFormClicked
  } = useLaneSheetAddMeetInfoForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <Input
            label="Number of Lanes*"
            name="numberOfLanes"
            type='number'
            min='6'
            max='10'
            value={formState.numberOfLanes}
            error={errorState.numberOfLanes}
            message={errorState.numberOfLanes}
            onChange={(value) => { onFormValueChanged('numberOfLanes', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <RadiobuttonList
            label="Print Lanes*"
            name={"reverseLaneOrder"}
            items={laneOrderState}
            value={formState.reverseLaneOrder}
            error={errorState.reverseLaneOrder}
            message={errorState.reverseLaneOrder}
            isVertical={true}
            onChange={(value) => { onFormValueChanged("reverseLaneOrder", value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <LaneSheetMedalDropdown
            label="Medal Count 1"
            name="medalCount1Id"
            value={formState.medalCount1Id}
            error={errorState.medalCount1Id}
            message={errorState.medalCount1Id}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'medalCount1Id', newValueLabel, 'medalCountName1', e); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <LaneSheetMedalDropdown
            label="Medal Count 2"
            name="medalCount2Id"
            value={formState.medalCount2Id}
            error={errorState.medalCount2Id}
            message={errorState.medalCount2Id}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'medalCount2Id', newValueLabel, 'medalCountName2', e); }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-3">
          <MultiSelect
            label="Show Career Stats For Course(s)*"
            name="careerStats"
            value={formState.careerStats}
            error={errorState.careerStats}
            message={errorState.careerStats}
            options={careerStatCourseOptions}
            isLoading={careerStatCourseOptions.length === 0}
            onChange={(value) => { onFormValueChanged('careerStats', value) }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <YesNoSwitch
            label="Format Date as MM/DD/YYYY?*"
            name="useAmericanDates"
            checked={formState.useAmericanDates}
            error={errorState.useAmericanDates}
            message={errorState.useAmericanDates}
            onChange={(value) => { onFormValueChanged('useAmericanDates', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <LaneSheetTemplateDropdown
            label="Lane Sheet Template*"
            name="laneSheetTemplateId"
            value={formState.laneSheetTemplateId}
            error={errorState.laneSheetTemplateId}
            message={errorState.laneSheetTemplateId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'laneSheetTemplateId', newValueLabel, 'laneSheetTemplateName', e); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <RecordGroupDropdown
            label="Record Within Lane Sheet*"
            name="recordGroupWithinSheetId"
            value={formState.recordGroupWithinSheetId}
            error={errorState.recordGroupWithinSheetId}
            message={errorState.recordGroupWithinSheetId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'recordGroupWithinSheetId', newValueLabel, 'recordWithinSheetName', e); }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onClearFormClicked()}>Cancel</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetAddMeetInfoForm;