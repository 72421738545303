export const localValidate = (formState) => {
  let errors = {};

  if (formState.heatNumber.trim() === '') {
    errors.heatNumber = 'Please enter a Heat';
  } else if (formState.heatNumber.trim()?.length > 50) {
    errors.heatNumber = 'Heat cannot exceed 50 characters';
  }

  if (formState.eventNameAndDistanceId.length === 0) {
    errors.eventNameAndDistanceId = 'Must select a Event & Competition Category';
  }

  if (!(formState.sessionTypeId > 0)) {
    errors.sessionTypeId = 'Must select a Session Type';
  }

  if (!(formState.heatDateId > 0)) {
    errors.heatDateId = 'Must select a Heat Date';
  }

  return errors;
};

const LaneSheetHeatAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetHeatAddFormValidation;