import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import getApprovedClubsForSecondaryRepData from './GetApprovedClubsForSecondaryRepData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  lscId: ''
};


const ApprovedClubsForSecondaryRepData = {
  INITIAL_STATE,
  getApprovedClubsForSecondaryRepData
};

export default ApprovedClubsForSecondaryRepData;