import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const getSelectionsOrgLevels = (organizationCode, levelCode, state, setState) => {
  if (organizationCode && levelCode) {
    let newState = {
      ...state,
      organizationCode,
      levelCode
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const organizationCodeForUrl = encodeURIComponent(organizationCode);
    const levelCodeForUrl = encodeURIComponent(levelCode);
    const url = `/OrgUnit/Organization/${organizationCodeForUrl}/OrgLevel/${levelCodeForUrl}/OrgUnit`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const SelectionsOrgLevelData = {
  getSelectionsOrgLevels
};

export default SelectionsOrgLevelData;