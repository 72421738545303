import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../utils/NavLinks';

import useLaneSheetMeetEventAthletesData from '../../../../state/laneSheetMeetEventAthletes/UseLaneSheetMeetEventAthletesData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_POST_ATHLETE_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_ATHLETE_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  athlete: {}
};

const useLaneSheetMeetEventsAddParticipants = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { laneSheetMeetEventAthletesState, getLaneSheetMeetEventAthletes, postLaneSheetMeetEventAthlete,
    deleteLaneSheetMeetEventAthlete
  } = useLaneSheetMeetEventAthletesData();
  const [postAthleteState, setPostAthleteState] = useState(INITIAL_POST_ATHLETE_STATE);
  const [deleteAthleteState, setDeleteAthleteState] = useState(INITIAL_DELETE_ATHLETE_STATE);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.LANE_SHEET_MEET_EVENTS);
  };

  const onDeleteAthleteClicked = (athlete) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      athlete
    });
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeleteModalDeleteClicked = () => {
    const laneSheetPersonId = deleteModalState.athlete?.laneSheetPersonId;

    if (laneSheetPersonId > 0) {
      const deleteLaneSheetMeetEventAthletePromise = deleteLaneSheetMeetEventAthlete(laneSheetPersonId, deleteAthleteState, setDeleteAthleteState);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLaneSheetMeetEventAthletePromise !== null) {
        deleteLaneSheetMeetEventAthletePromise.then((newState) => {
          if (newState !== null) {
            const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

            if (laneSheetEventId > 0) {
              const getLaneSheetMeetEventAthletesPromise = getLaneSheetMeetEventAthletes(laneSheetEventId);

              if (getLaneSheetMeetEventAthletesPromise !== null) {
                getLaneSheetMeetEventAthletesPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  const onSubmitFormCallback = (formState) => {
    const personId = formState.matchedMember?.personId;
    const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

    if (personId && laneSheetEventId > 0) {
      const laneSheetMeetEventAthleteObj = {
        personId,
        laneSheetEventId
      };

      const postLaneSheetMeetEventAthletePromise = postLaneSheetMeetEventAthlete(laneSheetMeetEventAthleteObj, postAthleteState, setPostAthleteState);

      if (postLaneSheetMeetEventAthletePromise !== null) {
        postLaneSheetMeetEventAthletePromise.then((newState) => {
          if (newState !== null) {
            const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

            if (laneSheetEventId > 0) {
              const getLaneSheetMeetEventAthletesPromise = getLaneSheetMeetEventAthletes(laneSheetEventId);

              if (getLaneSheetMeetEventAthletesPromise !== null) {
                getLaneSheetMeetEventAthletesPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  useEffect(() => {
    const laneSheetEventId = location.state?.meetEvent?.laneSheetEventId;

    if (laneSheetEventId > 0) {
      const getLaneSheetMeetEventAthletesPromise = getLaneSheetMeetEventAthletes(laneSheetEventId);

      if (getLaneSheetMeetEventAthletesPromise !== null) {
        getLaneSheetMeetEventAthletesPromise.catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return {
    isSaving: postAthleteState.isObjLoading || deleteAthleteState.isObjLoading,
    isLoading: laneSheetMeetEventAthletesState.isArrayLoading,
    meetEvent: location.state?.meetEvent || {},
    gridData: laneSheetMeetEventAthletesState.arrayData || [],
    deleteModalState,
    onBackClicked,
    onSubmitFormCallback,
    onDeleteAthleteClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  };
};

export default useLaneSheetMeetEventsAddParticipants;