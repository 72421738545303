import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeEligibilityBonusEventsValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeEligibility from './UseOmeEligibility';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const BONUS_EVENT_RULE_OVERLAP_ERROR = 'The provided number of qualifying events range overlaps with the range of an existing bonus event rule. Overlaps are not allowed.'

const INITIAL_VIEW_STATE = {
  tryRedirect: false
}

const INITIAL_FORM_STATE = {
  eligibilityFormState: {},
  omeMeetQualificationId: Constants.DEFAULT_ID,
  qualificationBonusId: Constants.DEFAULT_ID,
  minQualifyingEvents: '',
  maxQualifyingEvents: '',
  bonusStandardCount: ''
};

const useOmeEligibilityBonusEvents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { formStateHasBeenSaved } = useOmeEligibility();
  const { RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
    RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID } = useEnvironmentVariableData();
  const { formState, errorState, onFormValueChanged, handleSubmit, isDirty, isSubmitting, setFormData, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_ELIGIBILITY, { state: { eligibilityFormState: formState.eligibilityFormState } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_ELIGIBILITY, { state: { eligibilityFormState: formState.eligibilityFormState } });
    }
  };

  const tryAddEditBonusRule = (updatedOmeMeet) => {
    if (validateBonusEventRule(updatedOmeMeet) === true) {
      return true;
    }
    else {
      return null;
    }
  };

  function submitFormCallback() {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    let tryPut = true;

    //Check to see if the provided numbers work with the Max Number of Qualifying Events set on the main eligibility page 
    const maxQualifyingEventsNum = parseInt(formState.maxQualifyingEvents);
    const bonusStandardCountNum = parseInt(formState.bonusStandardCount);
    if (formState.eligibilityFormState?.maxEventsPerIndividualPerMeet) {
      if ((maxQualifyingEventsNum + bonusStandardCountNum) > formState.eligibilityFormState?.maxEventsPerIndividualPerMeet) {
        setErrors({ ...errorState, error: `The sum of the Max Number of Qualifying Events and the Allowed Number of Bonus Events (${maxQualifyingEventsNum + bonusStandardCountNum}) cannot exceed the Max Events Per Individual Per Meet (${formState.eligibilityFormState?.maxEventsPerIndividualPerMeet}).` });
        tryPut = false;
      }
    }

    //Delete the Cost Per Relay Only Swimmer & Cost Per Relay Event Entry records if relay events are not allowed for the meet
    if (formState.eligibilityFormState?.relayEvents === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const relayOnlySwimmerOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID);
      if (relayOnlySwimmerOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayOnlySwimmerOmeFeeTypeIdIndex, 1);
      }
      const relayEventEntryOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID);
      if (relayEventEntryOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayEventEntryOmeFeeTypeIdIndex, 1);
      }
    }

    //Delete the Cost Per Relay Only Swimmer if relay events are allowed for the meet but Relay Only Swimmers are not allowed
    if (formState.eligibilityFormState?.allowRelayOnlySwimmer === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const relayOnlySwimmerOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID);
      if (relayOnlySwimmerOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayOnlySwimmerOmeFeeTypeIdIndex, 1);
      }
    }

    //Save any changes to omeMeetEligibility made on previous page, make sure bonusEvents is set to true
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      omeMeetId: updatedOmeMeet.omeMeetId,
      maxNumberOfAthletesForMeet: formState.eligibilityFormState?.maxNumberOfAthletesForMeet.toString().replace(',', '') || null,
      maxNumberOfCapExemptAthletesForMeet: formState.eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet.toString().replace(',', '') || null,
      includeRelayOnlySwimmersInMaxNumber: formState.eligibilityFormState?.relayEvents === true && formState.eligibilityFormState?.allowRelayOnlySwimmer === true ? (formState.eligibilityFormState?.includeRelayOnlySwimmersInMaxNumber ?? null) : null,
      maxEventsPerIndividualPerMeet: formState.eligibilityFormState?.maxEventsPerIndividualPerMeet || null,
      maxEventsPerIndividualPerSession: formState.eligibilityFormState?.maxEventsPerIndividualPerSession || null,
      relayEvents: formState.eligibilityFormState?.relayEvents,
      allowRelayOnlySwimmer: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.allowRelayOnlySwimmer ?? null) : null,
      relayTeamFromRosterOnly: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.relayTeamFromRosterOnly ?? null) : null,
      forceAggregateIfFasterThanTeamTime: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.forceAggregateIfFasterThanTeamTime ?? null) : null,
      maxRelayTeamEntriesPerEvent: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.maxRelayTeamEntriesPerEvent || null) : null,
      maxRelayEventsPerIndividualPerMeet: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.maxRelayEventsPerIndividualPerMeet || null) : null,
      bonusEvents: true,
      usCitizenOnly: updatedOmeMeet.omeMeetEligibility[0]?.usCitizenOnly ?? null,
      nationalTeamOnly: updatedOmeMeet.omeMeetEligibility[0]?.nationalTeamOnly ?? null,
      allowRepOtherCountry: updatedOmeMeet.omeMeetEligibility[0]?.allowRepOtherCountry ?? null,
      useAdjustedSwimTime: formState.eligibilityFormState?.useAdjustedSwimTime
    }

    updatedOmeMeet.includeRelaySwimmerForCoachPasses = formState.eligibilityFormState?.relayEvents === true && formState.eligibilityFormState?.allowRelayOnlySwimmer === true ? (formState.eligibilityFormState?.includeRelaySwimmerForCoachPasses ?? null) : null;

    //set has hasAthleteExceedsTimeStandardsRelayRule to null if relay events are not allowed
    if (updatedOmeMeet.omeMeetQualification[0] && formState.eligibilityFormState?.relayEvents === false) {
      updatedOmeMeet.omeMeetQualification[0] = {
        ...updatedOmeMeet.omeMeetQualification[0],
        hasAthleteExceedsTimeStandardsRelayRule: null
      }
    }

    //Edit
    if (formState.omeMeetQualificationId > 0 && formState.qualificationBonusId > 0) {
      const selectedOmeMeetQualificationIndex = updatedOmeMeet.omeMeetQualification?.findIndex(x => x.omeMeetQualificationId === formState.omeMeetQualificationId);
      if (selectedOmeMeetQualificationIndex > -1) {
        const selectedQualificationBonusIndex = updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus?.findIndex(x => x.qualificationBonusId === formState.qualificationBonusId);
        if (selectedQualificationBonusIndex > -1) {
          if (updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus.length === 1 &&
            updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus[0].qualificationBonusId === formState.qualificationBonusId) {
            updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus[selectedQualificationBonusIndex] = {
              ...updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus[selectedQualificationBonusIndex],
              minQualifyingEvents: formState.minQualifyingEvents,
              maxQualifyingEvents: formState.maxQualifyingEvents,
              bonusStandardCount: formState.bonusStandardCount
            };
          }
          else if (updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus.length > 1) {
            if (tryAddEditBonusRule(updatedOmeMeet) === true) {
              updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus[selectedQualificationBonusIndex] = {
                ...updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex].omeQualificationBonus[selectedQualificationBonusIndex],
                minQualifyingEvents: formState.minQualifyingEvents,
                maxQualifyingEvents: formState.maxQualifyingEvents,
                bonusStandardCount: formState.bonusStandardCount
              };
            }
            else {
              tryPut = false;
            }
          }
        }
      }
    }
    //Add
    else {
      // If omeMeetQualification object has not been created yet
      if (updatedOmeMeet.omeMeetQualification.length === 0) {
        //add 0,0,0 rule if user provided rule does not cover 0 swimmers
        updatedOmeMeet.omeMeetQualification = [{
          omeMeetId: updatedOmeMeet.omeMeetId,
          hasTimeStandards: null,
          hasNoSlowerThanTimeStandards: null,
          hasNoFasterThanTimeStandards: null,
          hasAthleteExceedsTimeStandardsRule: null,
          hasBonusTimeStandards: null,
          timeStandardsQualifyingStartDate: null,
          timeStandardsQualifyingEndDate: null,
          omeQualificationBonus: formState.minQualifyingEvents > 0 ? [{
            minQualifyingEvents: formState.minQualifyingEvents,
            maxQualifyingEvents: formState.maxQualifyingEvents,
            bonusStandardCount: formState.bonusStandardCount
          }, {
            minQualifyingEvents: 0,
            maxQualifyingEvents: 0,
            bonusStandardCount: 0
          }] :
            [{
              minQualifyingEvents: formState.minQualifyingEvents,
              maxQualifyingEvents: formState.maxQualifyingEvents,
              bonusStandardCount: formState.bonusStandardCount
            }]
        }];
      }
      // If omeMeetQualification object exists
      else if (updatedOmeMeet.omeMeetQualification.length > 0) {
        //if existing bonus event rules, check for overlap
        if (updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.length > 0) {
          if (tryAddEditBonusRule(updatedOmeMeet) === true) {
            updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.push({
              omeMeetQualificationId: updatedOmeMeet.omeMeetQualification[0].omeMeetQualificationId,
              minQualifyingEvents: formState.minQualifyingEvents,
              maxQualifyingEvents: formState.maxQualifyingEvents,
              bonusStandardCount: formState.bonusStandardCount
            });
          }
          else {
            tryPut = false;
          }
        }
        //if no existing bonus event rules, add without checking overlap
        else {
          updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.push({
            omeMeetQualificationId: updatedOmeMeet.omeMeetQualification[0].omeMeetQualificationId,
            minQualifyingEvents: formState.minQualifyingEvents,
            maxQualifyingEvents: formState.maxQualifyingEvents,
            bonusStandardCount: formState.bonusStandardCount
          });
          //add 0,0,0 rule if user provided rule does not cover 0 swimmers
          if (formState.minQualifyingEvents > 0) {
            updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.push({
              omeMeetQualificationId: updatedOmeMeet.omeMeetQualification[0].omeMeetQualificationId,
              minQualifyingEvents: 0,
              maxQualifyingEvents: 0,
              bonusStandardCount: 0
            });
          }
        }
      }
    }

    if (tryPut === true) {
      //set has bonusTimeStandards to null if bonus events are not allowed
      if (updatedOmeMeet.omeMeetQualification[0] && formState.eligibilityFormState?.bonusEvents === false) {
        updatedOmeMeet.omeMeetQualification[0] = {
          ...updatedOmeMeet.omeMeetQualification[0],
          hasBonusTimeStandards: null
        }
      }
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
      setState({ ...state, tryRedirect: true });
    }
  };

  function getArrayMinNumberOfQualifyingEvents(qualificationBonusId) {
    const array = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus));
    if (qualificationBonusId > 0) {
      const index = array.findIndex(x => x.qualificationBonusId === qualificationBonusId)
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    return array.reduce((min, bonus) => bonus.minQualifyingEvents < min ? bonus.minQualifyingEvents : min, array[0].minQualifyingEvents);
  };

  function getArrayMaxNumberOfQualifyingEvents(qualificationBonusId) {
    const array = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus));
    if (qualificationBonusId > 0) {
      const index = array.findIndex(x => x.qualificationBonusId === qualificationBonusId)
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    return array.reduce((max, bonus) => bonus.maxQualifyingEvents > max ? bonus.maxQualifyingEvents : max, array[0].maxQualifyingEvents);
  };

  function validateBonusEventRule(omeMeetCopy) {
    //Check for overlapping qualifying events
    let overlappingBonusEventRulesCount = 0;
    const minQualifyingEventsNum = parseInt(formState.minQualifyingEvents);
    const maxQualifyingEventsNum = parseInt(formState.maxQualifyingEvents);
    const arrayMinQualifyingEvents = getArrayMinNumberOfQualifyingEvents(formState.qualificationBonusId);
    const arrayMaxQualifyingEvents = getArrayMaxNumberOfQualifyingEvents(formState.qualificationBonusId);

    if (minQualifyingEventsNum < arrayMinQualifyingEvents && maxQualifyingEventsNum > arrayMaxQualifyingEvents) {
      overlappingBonusEventRulesCount += 1;
    }

    for (let i = 0; i < omeMeetCopy.omeMeetQualification[0]?.omeQualificationBonus.length; i++) {

      if (omeMeetCopy.omeMeetQualification[0]?.omeQualificationBonus[i].qualificationBonusId !== formState.qualificationBonusId) {
        if ((minQualifyingEventsNum >= omeMeetCopy.omeMeetQualification[0]?.omeQualificationBonus[i].minQualifyingEvents &&
          minQualifyingEventsNum <= omeMeetCopy.omeMeetQualification[0]?.omeQualificationBonus[i].maxQualifyingEvents) ||
          (maxQualifyingEventsNum >= omeMeetCopy.omeMeetQualification[0]?.omeQualificationBonus[i]?.minQualifyingEvents &&
            maxQualifyingEventsNum <= omeMeetCopy.omeMeetQualification[0]?.omeQualificationBonus[i]?.maxQualifyingEvents)) {
          overlappingBonusEventRulesCount += 1;
        }
      }
    }

    if (overlappingBonusEventRulesCount > 0) {
      setErrors({ ...errorState, error: BONUS_EVENT_RULE_OVERLAP_ERROR });
      return false;
    }
    return true;
  };

  function bonusEventFormStateHasBeenSaved(formState) {
    //compare bonus event formState to omeMeetState to see if formState has been saved
    //Edit bonus event
    if (formState?.omeMeetQualificationId !== Constants.DEFAULT_ID && formState?.qualificationBonusId !== Constants.DEFAULT_ID) {
      const selectedOmeMeetQualification = omeMeetState.objData.omeMeetQualification?.find(x =>
        x.omeMeetQualificationId === formState?.omeMeetQualificationId);
      const selectedQualificationBonus = selectedOmeMeetQualification?.omeQualificationBonus?.find(x =>
        x.qualificationBonusId === formState?.qualificationBonusId);

      if ((ToIntIfInt(formState?.minQualifyingEvents) !== selectedQualificationBonus?.minQualifyingEvents)
        && (formState?.minQualifyingEvents !== '' || selectedQualificationBonus?.minQualifyingEvents !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.maxQualifyingEvents) !== selectedQualificationBonus?.maxQualifyingEvents)
        && (formState?.maxQualifyingEvents !== '' || selectedQualificationBonus?.maxQualifyingEvents !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.bonusStandardCount) !== selectedQualificationBonus?.bonusStandardCount)
        && (formState?.bonusStandardCount !== '' || selectedQualificationBonus?.bonusStandardCount !== null)) {
        return false;
      }
    }
    //Add bonus event
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && location.state && location.state?.eligibilityFormState) {
      //Edit
      if (omeMeetState.objData.omeMeetQualification.length > 0 && location.state?.omeMeetQualificationId
        && location.state?.qualificationBonusId) {
        const selectedOmeMeetQualification = omeMeetState.objData.omeMeetQualification?.find(x =>
          x.omeMeetQualificationId === location?.state?.omeMeetQualificationId);
        if (Object.keys(selectedOmeMeetQualification).length > 0) {
          const selectedQualificationBonus = selectedOmeMeetQualification.omeQualificationBonus?.find(x =>
            x.qualificationBonusId === location?.state?.qualificationBonusId);
          if (Object.keys(selectedQualificationBonus).length > 0) {
            setFormData({
              ...formState,
              eligibilityFormState: location.state?.eligibilityFormState || {},
              omeMeetQualificationId: location.state?.omeMeetQualificationId || Constants.DEFAULT_ID,
              qualificationBonusId: location.state?.qualificationBonusId || Constants.DEFAULT_ID,
              minQualifyingEvents: selectedQualificationBonus.minQualifyingEvents >= 0 ? selectedQualificationBonus.minQualifyingEvents : '',
              maxQualifyingEvents: selectedQualificationBonus.maxQualifyingEvents >= 0 ? selectedQualificationBonus.maxQualifyingEvents : '',
              bonusStandardCount: selectedQualificationBonus.bonusStandardCount >= 0 ? selectedQualificationBonus.bonusStandardCount : ''
            });
            if (formStateHasBeenSaved(location.state?.eligibilityFormState) === false) {
              setIsDirty(true);
            }
          }
        }
      }
      //Add 
      else {
        setFormData({
          ...formState,
          eligibilityFormState: location.state?.eligibilityFormState || {}
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.eligibilityFormState);
      const isBonusEventSaved = bonusEventFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isBonusEventSaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_ELIGIBILITY);
      }
      else {
        navigate(HostNavLinks.OME_MEET_ELIGIBILITY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    state,
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeEligibilityBonusEvents;