import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData from './OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData';

export const OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryStateContext = createContext();

const OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryStateContext.Provider>
  );
};

export default OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider;