import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const SelectionsStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const SelectionsContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsStateContext.Provider value={stateHook}>
      {children}
    </SelectionsStateContext.Provider>
  );
};

export default SelectionsContextProvider;