import getMeetInfo from './GetMeetInfo';
import getEligibility from './GetEligibility';
import getRestrictions from './GetRestrictions';
import getInvitations from './GetInvitations';
import getSessions from './GetSessions';
import getAgeGroups from './GetAgeGroups';
import getTimeStandards from './GetTimeStandards';
import getEvents from './GetEvents';
import getPricing from './GetPricing';
import getSubmit from './GetSubmit';

const getLeftNavigationState = (viewName, route, hostState, invitationsState, orgUnitState, meetState, ENTRY_CONTACT_STAFF_TYPE_ID, PAYMENT_CONTACT_STAFF_TYPE_ID,
  TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID, INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
  ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID,
  SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {

  const items = [
    getMeetInfo(viewName, route, hostState, meetState, meetInfoRoutePermission),
    getEligibility(viewName, route, hostState, meetState, eligibilityRoutePermission, meetInfoRoutePermission),
    getRestrictions(viewName, route, hostState, meetState, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission),
    getInvitations(viewName, route, hostState, invitationsState, meetState, eligibilityRoutePermission, meetInfoRoutePermission),
    getSessions(viewName, route, hostState, invitationsState, meetState, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission),
    getAgeGroups(viewName, route, hostState, invitationsState, meetState, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission),
    getTimeStandards(viewName, route, hostState, invitationsState, meetState, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission),
    getEvents(viewName, route, hostState, invitationsState, meetState, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission),
    getPricing(viewName, route, hostState, invitationsState, orgUnitState, meetState, TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID,
      INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
      ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID,
      SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission),
    getSubmit(viewName, route, hostState, invitationsState, orgUnitState, meetState, TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID,
      INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
      ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID,
      ENTRY_CONTACT_STAFF_TYPE_ID, PAYMENT_CONTACT_STAFF_TYPE_ID, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission)
  ];

  return items;
};

export default getLeftNavigationState;