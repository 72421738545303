/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { formatDateTime, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ host, onView, onEdit, onDeleteOmeMeetClicked }) => {
  return (
    <Fragment>
      <tr>
        <td>{host?.orgUnitName || ''}</td>
        <td>{host?.meetEntryStartDate ? formatDateTimeTwelveHourClock(host?.meetEntryStartDate) : ''} {host?.meetEntryStartDate ? "-" : ''} {host?.meetEntryEndDate ? formatDateTimeTwelveHourClock(host?.meetEntryEndDate) : ''}</td>
        <td>{host?.timeZone || ''}</td>
        <td>{host?.omeMeetId > 0 ? (host?.omeMeetStatus || '') : 'Not Created'}</td>
        <td>
          {host?.omeMeetId > 0 &&
            <span>
              {host?.isReadOnly === true &&
                <ActionIntraPageButton onClick={(e) => onView(e)}>View</ActionIntraPageButton>}
              {host?.hostChangesCurrentlyAllowed === true &&
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e)}><EditIcon /></button>}
              {host?.hostDeletesCurrentlyAllowed === true &&
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteOmeMeetClicked(e, host)}><DeleteIcon /></button>}
            </span>}
        </td>
      </tr>
    </Fragment>
  );
};

const MeetHostLargeGrid = ({ gridData, isLoading, meetState, onEnterOmeHostClicked, onView, onEdit, onDeleteOmeMeetClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Meet Host</th>
          <th>Entry Start - End Dates</th>
          <th>Time Zone</th>
          <th>OME Meet Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="5">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((host, i) =>
              <GridRow
                key={i}
                host={host}
                onView={onView}
                onEdit={onEdit}
                onDeleteOmeMeetClicked={onDeleteOmeMeetClicked}
              />)
            :
            <tr>
              <td>{meetState.objData?.hostOrgUnit?.orgUnitName || ''}</td>
              <td></td>
              <td></td>
              <td>Not Created</td>
              <td>
                {meetState?.objData.startDate && (new Date(formatDateTime(meetState?.objData?.startDate)) > new Date()) ?
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEnterOmeHostClicked(e)}> <PlusIcon toolTipText='Create OME Meet' /> </button> : <Fragment />}
              </td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetHostLargeGrid;