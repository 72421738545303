import React from 'react';

import useOrgUnitAdminReportingMyMeets from './UseOrgUnitAdminReportingMyMeets';

import ReportList from '../../../../../common/components/embeddedReports/ReportList';

const OrgUnitAdminReportingMyMeets = () => {
  const { reportParameters, contextId, routeName } = useOrgUnitAdminReportingMyMeets();

  return (
    <ReportList listTitle="OME Team Entry Reports" 
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default OrgUnitAdminReportingMyMeets;