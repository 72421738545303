import React from 'react';

import useSelectionsUnavailableReasonsDropdown from './UseSelectionsUnavailableReasonsDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const SelectionsUnavailableReasonsDropdown = ({ label, name, value, error, message, onChange, makeGetCall = true }) => {
  const { selectionsUnavailableReasonsState } = useSelectionsUnavailableReasonsDropdown(makeGetCall);

  return selectionsUnavailableReasonsState.message
    ? <span className={global.LoadingMsg}>{selectionsUnavailableReasonsState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={selectionsUnavailableReasonsState.items}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={selectionsUnavailableReasonsState.areItemsLoaded !== true}
      />
    );
};

export default SelectionsUnavailableReasonsDropdown;