/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ timeStandard, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{timeStandard.timeStandardName || ''}</td>
    <td>{timeStandard.sortPriority || ''}</td>
    <td>{timeStandard.isIndividualBaseStandard === true ? 'Yes' : 'No'}</td>
    <td>{timeStandard.isRelayBaseStandard === true ? 'Yes' : 'No'}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={() => onEditClicked(timeStandard.timeStandardId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={() => onDeleteClicked(timeStandard.timeStandardId)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const SelectionMeetTimeStandardGridLarge = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Time Standard</th>
        <th>Priority</th>
        <th>Is Individual Base Time Standard?</th>
        <th>Is Relay Base Time Standard?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((timeStandard, i) => <GridRow key={i} timeStandard={timeStandard} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="5">No Time Standards</td></tr>
      }
    </tbody>
  </table>
);

export default SelectionMeetTimeStandardGridLarge;
