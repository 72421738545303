import { useContext } from 'react';

import { MeetSessionStateContext } from './MeetSessionContextProvider';

import MeetSessionData from './MeetSessionData';

const useMeetSessionData = () => {
  const [meetSessionState, setMeetSessionState] = useContext(MeetSessionStateContext);

  const getMeetSessionsByMeetId = (meetId) => {
    MeetSessionData.getMeetSessionByMeetIdData(meetId, meetSessionState, setMeetSessionState);
  };

  return {
    meetSessionState,
    setMeetSessionState,
    getMeetSessionsByMeetId
  };
};

export default useMeetSessionData;