import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useMeetCompetitionSummaryData from '../../../state/meetCompetitionSummary/UseMeeetCompetitionSummaryData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../../common/state/searchMeet/UseSearchMeetData';

import NavLinks from '../NavLinks';
import useOmeMeetInvitationsData from '../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

const INITIAL_VIEW_STATE = {
  text: 'Search',
  isItemSelected: false,
  tryRedirect: false
};

const TAXONOMIES = ['MeetDetail', 'OUMeetDetail', 'AthleteMeetDetail'];
const SCOPE = 'Meet';

const useMeetSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState, getMeet, setMeetFiltersState, clearObjData } = useMeetData();
  const { meetCompetitionSummaryState, getMeetCompetitionSummary } = useMeetCompetitionSummaryData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { omeMeetState, getOmeMeet, clearOmeMeetObjData, setOmeMeetState } = useOmeMeetData();
  const { omeMeetInvitationsState, getOmeMeetInvitations, clearOmeMeetInvitationsArrayData } = useOmeMeetInvitationsData();
  const { getContextSecurity, contextSecurityState } = useSecurityData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onEditMeet = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const selectedMeetId = meet.meetId;
    const selectedOmeMeetId = meet.omeMeetId;

    getMeet(selectedMeetId);
    getMeetCompetitionSummary(selectedMeetId);
    if (selectedOmeMeetId) {
      getOmeMeet(selectedOmeMeetId);
      getOmeMeetInvitations(selectedOmeMeetId);
    } else {
      clearOmeMeetObjData();
      clearOmeMeetInvitationsArrayData();
    }

    getContextSecurity(selectedMeetId, TAXONOMIES, SCOPE);
    setState({ ...state, isItemSelected: true });
  };

  const onAddMeet = (e) => {
    //add a meet
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearObjData();
    navigate(NavLinks.MEET_ADD);
  }

  useEffect(() => {
    //Handle navigating back to meet host if exit is clicked on meet template page
    if (location.state && location.state?.meetId && location.state?.route && location.state?.meetFiltersState) {
      setMeetFiltersState(location.state?.meetFiltersState);
      getMeet(location.state?.meetId);
      getMeetCompetitionSummary(location.state?.meetId);
      if (location.state?.omeMeetId) {
        getOmeMeet(location.state?.omeMeetId);
        getOmeMeetInvitations(location.state?.omeMeetId);
      }
      else {
        clearOmeMeetObjData();
        clearOmeMeetInvitationsArrayData();
      }

      getContextSecurity(location.state?.meetId, TAXONOMIES, SCOPE);
      setState({ ...state, isItemSelected: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isItemSelected === true && contextSecurityState.isObjLoading === false && contextSecurityState.contextId === meetState.objData?.meetId) {
      if (meetState.isObjLoading !== true && meetState.isObjLoaded === true &&
        meetCompetitionSummaryState.isObjLoading !== true && meetCompetitionSummaryState.isObjLoaded === true
        && omeMeetState.isObjLoading !== true && omeMeetInvitationsState.isArrayLoading !== true) {
        if (meetState.objData.meetId > 0) {
          if (location.state && location.state.route) {
            //Created a new OME Meet
            if (location.state.omeMeetId) {
              const selectedMeet = {
                meetId: meetState.objData.meetId,
                meetName: meetState.objData.meetName,
                meetHost: meetState.objData.hostOrgUnit.orgUnitName,
                hostOrgUnitId: meetState.objData.hostOrgUnit.orgUnitId,
                meetClassificationName: meetState.objData.meetClassification?.typeName,
                startDate: meetState.objData.startDate,
                endDate: meetState.objData.endDate
              }
              setSearchMeetState({ ...searchMeetState, showMeetHeader: false });
              setOmeMeetState({ ...omeMeetState, route: 'meet', selectedMeet: selectedMeet });
              setState({ ...state, tryRedirect: true });
            }
            //Did not create a new OME Meet
            else {
              navigate(NavLinks.MEET_HOST);
            }
          }
          else {
            setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
              navigate(NavLinks.MEET_INFO);
          }
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, meetState, omeMeetState, omeMeetInvitationsState, meetCompetitionSummaryState, contextSecurityState]);

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetState.route === 'meet' &&
      Object.keys(omeMeetState.selectedMeet).length > 0 && searchMeetState.showMeetHeader === false) {
      navigate(NavLinks.MEET_HOST_INFO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, omeMeetState, searchMeetState]);

  return {
    meetState,
    searchMeetState,
    contextSecurityState,
    onEditMeet,
    onAddMeet
  };
};

export default useMeetSearch;