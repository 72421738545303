import Constants from '../../../common/utils/Constants';
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetEventMeetEventData = (meetEventId, state, setState) => {
  if (!state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      meetEventId,
      message: 'Loading...'
    };
    setState(newState);

    const meetEventIdForUrl = meetEventId ? encodeURIComponent(meetEventId) : 'NaN';
    const url = `/Event/MeetEvent/${meetEventIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ eventId: Constants.DEFAULT_ID, eventName: '--' });
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            meetEventId,
            items: arrayData.map(x => { return { id: x.eventId, name: `${x.eventName}` }; }),
            message: ''
          })
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetEventMeetEventData;