import React, { Fragment } from 'react';

import useMyMeetsContextView from './UseMyMeetsContextView';
import NavLinks from './MyMeetsNavLinks';

import Headings from '../../../common/components/headings/Headings';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';

import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Constants from '../../../common/utils/Constants';

const MyMeetsContextView = ({ children }) => {
  const { location, onHomeClicked, selectOrgUnitState, contextSecurityState, onBackToSelectionClicked } = useMyMeetsContextView();

  if (selectOrgUnitState.orgUnitId === '' && !location?.state?.selectOrgUnitState) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              My Meets
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {selectOrgUnitState.showBackToSelection &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>}
      <div className="row usas-extra-bottom-margin">
        <div className={["col-xs-12", global.HeaderText].join(' ')}>
          <p className={global.HeaderText}><strong>Editing:</strong> {selectOrgUnitState.orgUnitName}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="MyMeetsDetail" />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="MyMeetsDetail" homeRoute={NavLinks.MEET_HOST} selectTab={true} />
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={selectOrgUnitState.orgUnitId === '' || contextSecurityState.isObjLoading} />
      {children}
    </Fragment>
  );
};

export default MyMeetsContextView;