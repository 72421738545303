import React, { Fragment } from 'react';

import MeetUploadErrorTankPotentialMatchesLargeGrid from './MeetUploadErrorTankPotentialMatchesLargeGrid';
import MeetUploadErrorTankPotentialMatchesSmallGrid from './MeetUploadErrorTankPotentialMatchesSmallGrid';

const MeetTimesPotentialMatchesGrid = ({ gridData, onRadioChanged }) => {
  return (
    <Fragment>
      <MeetUploadErrorTankPotentialMatchesLargeGrid
        gridData={gridData}
        onRadioChanged={onRadioChanged} />
      <MeetUploadErrorTankPotentialMatchesSmallGrid
        gridData={gridData}
        onRadioChanged={onRadioChanged} />
    </Fragment>
  )
};

export default MeetTimesPotentialMatchesGrid;