/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import SelectionsUnavailableReasonsDropdown from '../../../../components/dropdowns/SelectionsUnavailableReasonsDropdown';

import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SelectionMeetTimeStandardGridLarge = ({ formState, onWillAttendChanged, onValueTextPairChanged }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Leg</th>
        <th>Participant Name</th>
        <th>Will Attend?</th>
        <th>Unavailable Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>{formState.leg1Name || ''}</td>
        <td>
          <YesNoSwitch
            name="leg1WillAttend"
            checked={formState.leg1WillAttend}
            onChange={(value) => { onWillAttendChanged(value, 'leg1WillAttend', 'leg1UnavailableReasonId', 'leg1UnavailableReasonName') }} />
        </td>
        <td>
          <div className={formState.leg1WillAttend === false ? global.DisplayComponent : global.HideComponent}>
            <SelectionsUnavailableReasonsDropdown
              name='leg1UnavailableReasonId'
              value={formState.leg1UnavailableReasonId}
              makeGetCall={false}
              onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'leg1UnavailableReasonId', newValueLabel, 'leg1UnavailableReasonName')} />
          </div>
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td>{formState.leg2Name || ''}</td>
        <td>
          <YesNoSwitch
            name="leg2WillAttend"
            checked={formState.leg2WillAttend}
            onChange={(value) => { onWillAttendChanged(value, 'leg2WillAttend', 'leg2UnavailableReasonId', 'leg2UnavailableReasonName') }} />
        </td>
        <td>
          <div className={formState.leg2WillAttend === false ? global.DisplayComponent : global.HideComponent}>
            <SelectionsUnavailableReasonsDropdown
              name='leg2UnavailableReasonId'
              value={formState.leg2UnavailableReasonId}
              makeGetCall={false}
              onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'leg2UnavailableReasonId', newValueLabel, 'leg2UnavailableReasonName')} />
          </div>
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>{formState.leg3Name || ''}</td>
        <td>
          <YesNoSwitch
            name="leg3WillAttend"
            checked={formState.leg3WillAttend}
            onChange={(value) => { onWillAttendChanged(value, 'leg3WillAttend', 'leg3UnavailableReasonId', 'leg3UnavailableReasonName') }} />
        </td>
        <td>
          <div className={formState.leg3WillAttend === false ? global.DisplayComponent : global.HideComponent}>
            <SelectionsUnavailableReasonsDropdown
              name='leg3UnavailableReasonId'
              value={formState.leg3UnavailableReasonId}
              makeGetCall={false}
              onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'leg3UnavailableReasonId', newValueLabel, 'leg3UnavailableReasonName')} />
          </div>
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td>{formState.leg4Name || ''}</td>
        <td>
          <YesNoSwitch
            name="leg4WillAttend"
            checked={formState.leg4WillAttend}
            onChange={(value) => { onWillAttendChanged(value, 'leg4WillAttend', 'leg4UnavailableReasonId', 'leg4UnavailableReasonName') }} />
        </td>
        <td>
          <div className={formState.leg4WillAttend === false ? global.DisplayComponent : global.HideComponent}>
            <SelectionsUnavailableReasonsDropdown
              name='leg4UnavailableReasonId'
              value={formState.leg4UnavailableReasonId}
              makeGetCall={false}
              onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'leg4UnavailableReasonId', newValueLabel, 'leg4UnavailableReasonName')} />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

export default SelectionMeetTimeStandardGridLarge;
