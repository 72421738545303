import { useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../utils/NavLinks';

import useLaneSheetData from '../../../../state/laneSheet/UseLaneSheetData';
import useLaneSheetTopHeaderData from '../../../../state/laneSheetTopHeader/UseLaneSheetTopHeaderData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_POST_TOP_HEADER_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetTopHeaderNotesDetail = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetTopHeaderState, postLaneSheetTopHeaderInfo } = useLaneSheetTopHeaderData();
  const [postTopHeaderInfoState, setPostTopHeaderInfoState] = useState(INITIAL_POST_TOP_HEADER_INFO_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.LANE_SHEET_TOP_HEADER_INFO);
  };

  const onSubmitFormCallback = (formState) => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postLaneSheetTopHeaderInfoPromise = postLaneSheetTopHeaderInfo(createLaneSheetTopHeaderObj(formState, laneSheetMeetId), postTopHeaderInfoState, setPostTopHeaderInfoState);

      if (postLaneSheetTopHeaderInfoPromise !== null) {
        postLaneSheetTopHeaderInfoPromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.LANE_SHEET_TOP_HEADER_INFO);
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  function createLaneSheetTopHeaderObj(formState, laneSheetMeetId) {
    return {
      laneSheetMeetId,
      displayOrder: formState.displayOrder,
      nameToShow: formState.nameToShow?.trim(),
      recordListId: formState.recordListId
    };
  }

  return {
    isSaving: postTopHeaderInfoState.isObjLoading,
    topHeaderDataLength: laneSheetTopHeaderState.arrayData?.length || 0,
    onSubmitFormCallback,
    onBackClicked
  };
};

export default useLaneSheetTopHeaderNotesDetail;