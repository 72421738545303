export const localValidate = (formState) => {
  let errors = {};

  if (formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards === true && formState.noSlowerThanTimeStandardAgeGroup.length === 0) {
    errors.noSlowerThanTimeStandardAgeGroup = 'A selection is required';
  }

  if (formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true && formState.noFasterThanTimeStandardAgeGroup.length === 0) {
    errors.noFasterThanTimeStandardAgeGroup = 'A selection is required';
  }

  if (formState.timeStandardsFormState?.hasBonusTimeStandards === true && formState.bonusTimeStandardAgeGroup.length === 0) {
    errors.bonusTimeStandardAgeGroup = 'A selection is required';
  }

  if (formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards === true && formState.noSlowerThanTimeStandardAgeGroup.length > 0 &&
    formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true && formState.noFasterThanTimeStandardAgeGroup.length > 0) {
    if (formState.noSlowerThanTimeStandardAgeGroup[0]?.id !== '' || formState.noFasterThanTimeStandardAgeGroup[0]?.id !== '') {
      if (formState.noSlowerThanTimeStandardAgeGroup[0]?.id === formState.noFasterThanTimeStandardAgeGroup[0]?.id) {
        errors.noSlowerThanTimeStandardAgeGroup = 'The No Slower Than Time Standard cannot be the same as the No Faster Than Time Standard';
        errors.noFasterThanTimeStandardAgeGroup = 'The No Faster Than Time Standard cannot be the same as the No Slower Than Time Standard';
      }
    }
  }

  // if (formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards === true && formState.noSlowerThanTimeStandardAgeGroup.length > 0 &&
  //   formState.timeStandardsFormState?.hasBonusTimeStandards === true && formState.bonusTimeStandardAgeGroup.length > 0) {
  //   if (formState.noSlowerThanTimeStandardAgeGroup[0]?.id !== '' || formState.bonusTimeStandardAgeGroup[0]?.id !== '') {
  //     if (formState.noSlowerThanTimeStandardAgeGroup[0]?.id === formState.bonusTimeStandardAgeGroup[0]?.id) {
  //       errors.noSlowerThanTimeStandardAgeGroup = errors.noSlowerThanTimeStandardAgeGroup ? errors.noSlowerThanTimeStandardAgeGroup + ' and the Bonus Time Standard' : 'The No Slower Than Time Standard cannot be the same as the Bonus Time Standard';
  //       errors.bonusTimeStandardAgeGroup = 'The Bonus Time Standard cannot be the same as the No Slower Than Time Standard';
  //     }
  //   }
  // }

  if (formState.timeStandardsFormState?.hasBonusTimeStandards === true && formState.bonusTimeStandardAgeGroup.length > 0 &&
    formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true && formState.noFasterThanTimeStandardAgeGroup.length > 0) {
    if (formState.bonusTimeStandardAgeGroup[0]?.id !== '' || formState.noFasterThanTimeStandardAgeGroup[0]?.id !== '') {
      if (formState.bonusTimeStandardAgeGroup[0]?.id === formState.noFasterThanTimeStandardAgeGroup[0]?.id) {
        errors.bonusTimeStandardAgeGroup = /*errors.bonusTimeStandardAgeGroup ? errors.bonusTimeStandardAgeGroup + ' and the No Faster Than Time Standard' :*/
          'The Bonus Time Standard cannot be the same as the No Faster Than Time Standard';
        errors.noFasterThanTimeStandardAgeGroup = /*errors.noFasterThanTimeStandardAgeGroup ? errors.noFasterThanTimeStandardAgeGroup + ' and the Bonus Time Standard' :*/
          'The No Faster Than Time Standard cannot be the same as the Bonus Time Standard';
      }
    }
  }

  return errors;
};

const OmeTimeStandardsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeTimeStandardsDetailValidation;