import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeAgeGroupsDetailValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const DUPLICATE_AGE_GROUP_NAME_ERROR = 'The provided Age Group Name matches the name of an existing age group. Duplicate age group names are not allowed.'

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  meetAgeGroupId: Constants.DEFAULT_ID,
  ageGroupName: '',
  minAge: '',
  maxAge: ''
};

const useOmeAgeGroupsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { formState, errorState, isSubmitting, isDirty, handleSubmit, setErrors, onFormValueChanged, setFormData
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async (submitButton) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true, submitButton: submitButton });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };


  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, submitButton: HostRegistrationConstants.BACK, tryRedirect: true });
  };

  function submitFormCallback(formState) {
    const updatedOmeMeetCopy = editOmeMeetObj();
    if (updatedOmeMeetCopy !== null) {
      putOmeMeet(omeMeetState.objData.omeMeetId, updatedOmeMeetCopy);
      setState({ ...state, tryRedirect: true });
    }
  };

  function formStateHasBeenSaved(formState) {
    //compare age group detail formState to omeMeetState to see if formState has been saved
    //Edit age group
    if (formState?.meetAgeGroupId !== Constants.DEFAULT_ID) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState?.meetAgeGroupId);

      if ((formState?.ageGroupName !== selectedMeetAgeGroup?.ageGroupName)
        && (formState?.ageGroupName !== '' || selectedMeetAgeGroup?.ageGroupName !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.maxAge) !== selectedMeetAgeGroup?.maxAge)
        && (formState?.maxAge !== '' || selectedMeetAgeGroup?.maxAge !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.minAge) !== selectedMeetAgeGroup?.minAge)
        && (formState?.minAge !== '' || selectedMeetAgeGroup?.minAge !== null)) {
        return false;
      }
    }
    //Add age group
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  const validateAgeGroup = (omeMeetCopy) => {
    //Check for dupes
    let duplicateAgeGroupNames = false;
    for (let i = 0; i < omeMeetCopy.meetAgeGroup.length; i++) {
      if (omeMeetCopy.meetAgeGroup[i].meetAgeGroupId !== formState.meetAgeGroupId) {
        if (omeMeetCopy.meetAgeGroup[i].ageGroupName === formState.ageGroupName.trim()) {
          duplicateAgeGroupNames = true;
        }
      }
    }
    if (duplicateAgeGroupNames === true) {
      setErrors({ ...errorState, error: DUPLICATE_AGE_GROUP_NAME_ERROR });
      return false;
    }

    return true;
  }

  const editOmeMeetObj = () => {
    let updatedOmeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    updatedOmeMeetCopy.meet = undefined;
    //Edit
    if (formState.meetAgeGroupId > 0) {
      const selectedIndex = updatedOmeMeetCopy.meetAgeGroup.findIndex(x => x.meetAgeGroupId === formState.meetAgeGroupId);
      if (selectedIndex > -1) {
        if (updatedOmeMeetCopy.meetAgeGroup.length === 1 && updatedOmeMeetCopy.meetAgeGroup[0].meetAgeGroupId === formState.meetAgeGroupId) {
          updatedOmeMeetCopy.meetAgeGroup[selectedIndex] = {
            ...updatedOmeMeetCopy.meetAgeGroup[selectedIndex],
            ageGroupName: formState.ageGroupName.trim(),
            minAge: parseInt(formState.minAge),
            maxAge: parseInt(formState.maxAge),
          };

          return updatedOmeMeetCopy;
        }
        else if (updatedOmeMeetCopy.meetAgeGroup.length > 1) {
          if (validateAgeGroup(updatedOmeMeetCopy) === true) {
            updatedOmeMeetCopy.meetAgeGroup[selectedIndex] = {
              ...updatedOmeMeetCopy.meetAgeGroup[selectedIndex],
              ageGroupName: formState.ageGroupName.trim(),
              minAge: parseInt(formState.minAge),
              maxAge: parseInt(formState.maxAge),
            };

            return updatedOmeMeetCopy;
          }
          else {
            return null;
          }
        }
      }
      else {
        return null;
      }
    }
    //Add
    else {
      if (updatedOmeMeetCopy.meetAgeGroup.length === 0) {
        updatedOmeMeetCopy.meetAgeGroup.push({
          omeMeetId: updatedOmeMeetCopy.omeMeetId,
          ageGroupName: formState.ageGroupName.trim(),
          minAge: parseInt(formState.minAge),
          maxAge: parseInt(formState.maxAge)
        });
        return updatedOmeMeetCopy;
      }
      else if (updatedOmeMeetCopy.meetAgeGroup.length > 0) {
        if (validateAgeGroup(updatedOmeMeetCopy) === true) {
          updatedOmeMeetCopy.meetAgeGroup.push({
            omeMeetId: updatedOmeMeetCopy.omeMeetId,
            ageGroupName: formState.ageGroupName.trim(),
            minAge: parseInt(formState.minAge),
            maxAge: parseInt(formState.maxAge)
          });
          return updatedOmeMeetCopy;
        }
        else {
          return null;
        }
      }
    }
  }

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && location.state) {
      //Edit
      if (omeMeetState.objData.meetAgeGroup.length > 0 && location.state?.meetAgeGroupId) {
        const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
          x.meetAgeGroupId === location?.state?.meetAgeGroupId);
        if (Object.keys(selectedMeetAgeGroup).length > 0) {
          setFormData({
            ...formState,
            meetAgeGroupId: location.state?.meetAgeGroupId || Constants.DEFAULT_ID,
            ageGroupName: selectedMeetAgeGroup.ageGroupName || '',
            minAge: selectedMeetAgeGroup.minAge >= 0 ? selectedMeetAgeGroup.minAge : '',
            maxAge: selectedMeetAgeGroup.maxAge >= 0 ? selectedMeetAgeGroup.maxAge : '',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_AGE_GROUPS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_AGE_GROUPS);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_AGE_GROUPS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_AGE_GROUPS);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    omeMeetState,
    formState,
    errorState,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeAgeGroupsDetail;
