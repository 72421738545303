import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  // Record Id
  if (formState.recordListId === Constants?.DEFAULT_ID) {
    errors.recordListId = `Please select a Record List`
  }

  // Display Order
  if (formState.displayOrder === '') {
    errors.displayOrder = 'Please enter a Display Order';
  }

  // Name to Show
  if (formState.nameToShow?.trim() === '') {
    errors.nameToShow = 'Please enter a Name to Show';
  } else if (formState.nameToShow?.trim()?.length > 100) {
    errors.nameToShow = 'Name to Show cannot exceed 100 characters';
  }

  return errors;
};

const LaneSheetTopHeaderNotesDetailFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetTopHeaderNotesDetailFormValidation;