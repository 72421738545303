import useCompetitionCategoryRadiobuttonList from './UseCompetitionCategoryRadiobuttonList';

import RadiobuttonList from '../RadiobuttonList';

const CompetitionCategoryRadiobuttonList = ({ label, name, isVertical, value, error, message, onChange }) => {
  const { state } = useCompetitionCategoryRadiobuttonList();

  return (
    <RadiobuttonList
      label={label}
      name={name}
      isVertical={isVertical}
      items={state.radiobuttonlist}
      value={value}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default CompetitionCategoryRadiobuttonList;