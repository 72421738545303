import React, { Fragment } from 'react';

import useOrgUnitAdminRoster from './UseOrgUnitAdminRoster';

import OrgUnitAdminRosterGrid from './OrgUnitAdminRosterGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterReadOnly = () => {
  const { omeMeetState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitEntryState
  } = useOrgUnitAdminRoster();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster</Headings.H3>
        </div>
        <div className="col-xs-12">
          <OrgUnitAdminRosterGrid
            gridData={omeMeetOrgUnitAthleteEntryState.arrayData}
            isLoading={omeMeetOrgUnitAthleteEntryState.isArrayLoading}
            allowRelayOnly={omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer ?? false}
            showSecondaryRep={omeMeetState.objData.allowSecondaryOrgUnitRepresentation ?? false}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitEntryState.isArrayLoading} />
    </Fragment>
  );
};

export default OrgUnitAdminRosterReadOnly;