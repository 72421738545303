import React, { createContext, useState } from 'react';

import CompetitionGenderTypeData from './CompetitionGenderTypeData';

export const CompetitionGenderTypeStateContext = createContext();

const CompetitonGenderTypeContextProvider = ({ children }) => {
  const stateHook = useState(CompetitionGenderTypeData.INITIAL_STATE);

  return (
    <CompetitionGenderTypeStateContext.Provider value={stateHook}>
      {children}
    </CompetitionGenderTypeStateContext.Provider>
  );
};

export default CompetitonGenderTypeContextProvider;