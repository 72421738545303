import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  const minQualifyingEvents = parseInt(formState.minQualifyingEvents);
  const maxQualifyingEvents = parseInt(formState.maxQualifyingEvents);
  let errors = {};

  if (formState.minQualifyingEvents === '') {
    errors.minQualifyingEvents = 'Min Number of Qualifying Events is required';
  } else if (!isValidNumber(formState.minQualifyingEvents) || ToIntIfInt(formState.minQualifyingEvents) > 14) {
    errors.minQualifyingEvents = 'Min Number of Qualifying Events must be between 0 and 14';
  } else if (minQualifyingEvents > maxQualifyingEvents) {
    errors.minQualifyingEvents = 'Min Number of Qualifying Events cannot be greater than Max Number of Qualifying Events';
  }

  if (formState.maxQualifyingEvents === '') {
    errors.maxQualifyingEvents = 'Max Number of Qualifying Events is required';
  } else if (!isValidNumber(formState.maxQualifyingEvents) || ToIntIfInt(formState.maxQualifyingEvents) > 14) {
    errors.maxQualifyingEvents = 'Max Number of Qualifying Events must be between 0 and 14';
  } else if (maxQualifyingEvents < minQualifyingEvents) {
    errors.maxQualifyingEvents = 'Max Number of Qualifying Events cannot be less than Min Number of Qualifying Events';
  }

  if (formState.bonusStandardCount === '') {
    errors.bonusStandardCount = 'Allowed Number of Bonus Events is required';
  } else if (!isValidNumber(formState.bonusStandardCount) || ToIntIfInt(formState.bonusStandardCount) > 14) {
    errors.bonusStandardCount = 'Allowed Number of Bonus Events must be between 0 and 14';
  }

  return errors;
};

const OmeEligibilityBonusEventsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OmeEligibilityBonusEventsValidation;