import React, { Fragment } from 'react';

import useSelectionsMeetEntries from './UseSelectionsMeetEntries';

import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import SelectionMeetEntriesGrid from '../components/grids/SelectionMeetEntriesGrid';
import SelectionsLoadingModal from '../components/dialogs/SelectionsLoadingModal';
import { MEET_ENTRIES_NAME } from '../components/leftNav/SelectionsLeftNavConstants';

import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

const SelectionsMeetEntries = () => {
  const {
    isLoading,
    gridData,
    isReadOnly,
    onContinueClicked,
    onSaveAndContinueClicked,
    onBackClicked
  } = useSelectionsMeetEntries();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={MEET_ENTRIES_NAME} viewTitle={'Meet Entries'}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            {isReadOnly === true
              ? <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
              : <PrimaryButton type="button" onClick={onSaveAndContinueClicked}>Save & Continue</PrimaryButton>
            }
          </div>
        </div>
        <SelectionMeetEntriesGrid
          gridData={gridData}
          isLoading={isLoading} />
        <SelectionsLoadingModal isLoading={isLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
};

export default SelectionsMeetEntries;