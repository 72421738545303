import React, { useState, useEffect } from 'react';
import useMeetData from '../../../../common/state/meet/UseMeetData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useMeetRaceStatsData from '../../../state/meetRaceStats/UseMeetRaceStatsData';
import useMeetRaceStatsVideosData from '../../../state/meetRaceStatsVideos/UseMeetRaceStatsVideosData';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import { formatTimeForSqlTime } from '../../../../common/utils/TimesUtils';

const useMeetRecon = () => {
  const { meetState } = useMeetData();
  const { meetRaceStatsState, postMeetRaceStats } = useMeetRaceStatsData();
  const { meetRaceStatsVideosState, getMeetRaceStatsVideos, postMeetRaceStatsVideos, putMeetRaceStatsVideo } = useMeetRaceStatsVideosData();

  const [jsonFileState, setJsonFileState] = useState(getInitialJSONFileState);
  const { MALE_COMPETITION_GENDER_ID, FEMALE_COMPETITION_GENDER_ID } = useEnvironmentVariableData();

  const FileUpload = () => {
    const hiddenFileInput = React.useRef(null);
    let fileReader;
    let fileType;

    const handleSelectFileClicked = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      hiddenFileInput.current.click();
    };

    const handleFileRead = (event) => {
      const fileContent = fileReader.result;
      processFile(fileContent, fileType);
    };

    const handleFileChosen = (file) => {
      fileType = file.name.substring(file.name.length - 3);

      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    };

    return (
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton onClick={handleSelectFileClicked}>Select Race Stats File (*.XML)</PrimaryButton>
        </div>
        <input
          type='file'
          id='file'
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={e => handleFileChosen(e.target.files[0])} />
      </div>
    );
  };

  const sessionTypeMap = {
    'PR': { id: '1' },
    'SO': { id: '2' },
    'F': { id: '3' },
    'SF': { id: '4' },
    'TT': { id: '6' }
  };

  //TODO populate from db - add RS events to event table, complicated by some RS events having 2 event ids (WTF??)
  const eventIdMap = {
    '1': { id: '1' },
    '2': { id: '2' },
    '3': { id: '3' },
    '4': { id: '4' },
    '7': { id: '11' },
    '8': { id: '12' },
    '9': { id: '13' },
    '10': { id: '14' },
    '11': { id: '15' },
    '12': { id: '16' },
    '13': { id: '17' },
    '14': { id: '18' },
    '15': { id: '19' },
    '16': { id: '20' },
    '17': { id: '21' },
    '18': { id: '22' },
    '24': { id: '28' },
    '25': { id: '29' },
    '26': { id: '30' },
    '27': { id: '31' },
    '28': { id: '32' },
    '29': { id: '33' },
    '30': { id: '38' },
    '31': { id: '39' },
    '32': { id: '40' },
    '33': { id: '41' },
    '34': { id: '42' },
    '35': { id: '43' },
    '36': { id: '44' },
    '37': { id: '45' },
    '38': { id: '46' },
    '39': { id: '47' },
    '40': { id: '48' },
    '41': { id: '49' },
    '47': { id: '55' },
    '48': { id: '56' },
    '49': { id: '57' },
    '50': { id: '58' },
    '51': { id: '59' },
    '52': { id: '60' },
    '53': { id: '65' },
    '54': { id: '66' },
    '55': { id: '67' },
    '56': { id: '68' },
    '57': { id: '69' },
    '58': { id: '70' },
    '59': { id: '71' },
    '60': { id: '72' },
    '61': { id: '73' },
    '62': { id: '74' },
    '63': { id: '75' },
    '115': { id: '55' },
    '116': { id: '56' },
    '117': { id: '57' },
    '119': { id: '58' },
    '120': { id: '59' },
    '121': { id: '60' },
    '122': { id: '65' },
    '123': { id: '66' },
    '124': { id: '67' },
    '125': { id: '68' },
    '126': { id: '69' },
    '127': { id: '70' },
    '128': { id: '71' },
    '129': { id: '72' },
    '130': { id: '73' },
    '131': { id: '74' },
    '132': { id: '75' }
  }

  useEffect(() => {
    if (meetRaceStatsState.isSaved === true) {
      setJsonFileState({
        ...jsonFileState,
        raceStatsContent: ""
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetRaceStatsState.isSaved]);

  function makeSwimmersArray(swimmers) {
    let swimmersAry = [];
    let swimmerObj = {};
    const swimmerCnt = swimmers[0].childElementCount * 2;

    for (var swimmerIndex = 0; swimmerIndex < swimmerCnt; swimmerIndex++) {
      const swimmerNodeName = swimmers[0].childNodes[swimmerIndex].nodeName;

      if (swimmerNodeName === "Swimmer") {
        const swimmerNode = swimmers[0].childNodes[swimmerIndex];
        swimmerObj = {
          swimmerId: swimmerNode.attributes["Id"].nodeValue,
          firstName: swimmerNode.attributes["FirstName"].nodeValue,
          middleName: swimmerNode.attributes["MiddleName"].nodeValue,
          lastName: swimmerNode.attributes["LastName"].nodeValue,
          birthDate: swimmerNode.attributes["Birthdate"].nodeValue,
          genderId: swimmerNode.attributes["Gender"].nodeValue === "M" ? MALE_COMPETITION_GENDER_ID : FEMALE_COMPETITION_GENDER_ID,
          orgUnitCode: swimmerNode.attributes["LscCode"].nodeValue
        }

        swimmersAry.push(swimmerObj);
      }
    }

    return swimmersAry;
  }

  function makeRacesArray(races, swimmersArray) {
    let racesAry = [];
    let raceObj = {};
    let lengthObj = {};
    let raceMetricObj = {};
    let supMetricObj = {};

    const raceCnt = races[0].childElementCount * 2;

    for (let raceIndex = 0; raceIndex < raceCnt; raceIndex++) {
      const raceNodeName = races[0].childNodes[raceIndex].nodeName;

      if (raceNodeName === "Race") {
        const raceNode = races[0].childNodes[raceIndex];

        const swimmerId = raceNode.attributes["SwimmerId"].nodeValue;
        const swimmerObj = swimmersArray.find(x => x.swimmerId === swimmerId);

        raceObj = {
          raceId: raceNode.attributes["Id"].nodeValue,
          eventId: eventIdMap[raceNode.attributes["EventId"].nodeValue].id,
          sessionTypeId: sessionTypeMap[raceNode.attributes["RaceType"].nodeValue].id,
          swimmerId: swimmerId,
          swimmerLastName: swimmerObj.lastName,
          swimmerFirstName: swimmerObj.firstName,
          swimmerMiddleName: swimmerObj.middleName,
          swimmerBirthdate: swimmerObj.birthDate,
          swimmerGenderId: swimmerObj.genderId,
          swimmerOrgUnitCode: swimmerObj.orgUnitCode,
          reactionTime: raceNode.attributes["ReactionTime"].nodeValue === "NaN" ? "0.00" : raceNode.attributes["ReactionTime"].nodeValue,
          relayEventId: raceNode.attributes["RelayEventId"].nodeValue,
          relayGroupId: raceNode.attributes["RelayGroupId"].nodeValue,
          legNumber: raceNode.attributes["RelayLegId"].nodeValue,
          lengths: []
        }

        const lengthsCnt = raceNode.childElementCount * 2;
        for (let lengthsIndex = 0; lengthsIndex < lengthsCnt; lengthsIndex++) {
          const lengthsNodeName = raceNode.childNodes[lengthsIndex].nodeName;

          if (lengthsNodeName === "Lengths") {
            const lengthsNode = raceNode.childNodes[lengthsIndex];

            const lengthCnt = lengthsNode.childElementCount * 2;
            for (let lengthIndex = 0; lengthIndex < lengthCnt; lengthIndex++) {
              const lengthNodeName = lengthsNode.childNodes[lengthIndex].nodeName;

              if (lengthNodeName === "Length") {
                const lengthNode = lengthsNode.childNodes[lengthIndex];

                const raceMetricsNode = lengthNode.childNodes[1];
                const splitMetricsNode = lengthNode.childNodes[3];
                const supplementalMetricsNodes = lengthNode.childNodes[5];

                lengthObj = {
                  lengthId: lengthNode.attributes["Id"].nodeValue,
                  breakoutTime: lengthNode.attributes["BreakoutTime"].nodeValue === "-" ? "0.00" : lengthNode.attributes["BreakoutTime"].nodeValue,
                  kickCount: lengthNode.attributes["KickCount"].nodeValue === "-" ? "0" : lengthNode.attributes["KickCount"].nodeValue,
                  raceMetricStartTime: raceMetricsNode.attributes["StartTime"].nodeValue,
                  raceMetricEndTime: raceMetricsNode.attributes["EndTime"].nodeValue,
                  splitStartTime: splitMetricsNode.attributes["StartTime"].nodeValue,
                  splitEndTime: splitMetricsNode.attributes["EndTime"].nodeValue,
                  strokeRates: [],
                  supplementalMetrics: []
                }

                const raceMetricCnt = raceMetricsNode.childElementCount * 2;
                for (let raceMetricIndex = 0; raceMetricIndex < raceMetricCnt; raceMetricIndex++) {
                  const raceMetricNodeName = raceMetricsNode.childNodes[raceMetricIndex].nodeName;

                  if (raceMetricNodeName === "RM") {
                    const raceMetricNode = raceMetricsNode.childNodes[raceMetricIndex];

                    raceMetricObj = {
                      raceMetricTypeId: raceMetricNode.attributes["type"].nodeValue === "0" ? "1" : "2",
                      cycleNumber: raceMetricNode.attributes["Id"].nodeValue,
                      strokeRate: raceMetricNode.textContent === "-" ? "0.00" : raceMetricNode.textContent
                    }

                    lengthObj.strokeRates.push(raceMetricObj);
                  }
                }

                const supMetricCnt = supplementalMetricsNodes.childElementCount * 2;
                for (let supMetricIndex = 0; supMetricIndex < supMetricCnt; supMetricIndex++) {
                  const supMetricNodeName = supplementalMetricsNodes.childNodes[supMetricIndex].nodeName;

                  if (supMetricNodeName === "RM") {
                    const supMetricNode = supplementalMetricsNodes.childNodes[supMetricIndex];
                    const distance = supMetricNode.childNodes[3].textContent;

                    supMetricObj = {
                      raceMetricTypeId: distance === "15" ? "3" : "4",
                      time: formatTimeForSqlTime(supMetricNode.childNodes[1].textContent)
                    }

                    lengthObj.supplementalMetrics.push(supMetricObj);
                  }
                }

                if (Object.keys(lengthObj).length > 0) {
                  raceObj.lengths.push(lengthObj);
                }
              }
            }
          }
        }
        racesAry.push(raceObj);
      }
    }

    return racesAry;
  }

  const processFile = (fileContent, fileType) => {
    let meetObj = {};
    const isValidFileType = fileType.toUpperCase() === "XML";

    if (isValidFileType) {
      let racesArray = [];
      let swimmersArray = [];
      let parser = new DOMParser();
      const xmlDoc = parser.parseFromString(fileContent, "text/xml");

      meetObj = {
        meetId: meetState.objData.meetId
      }

      const races = xmlDoc.getElementsByTagName("Races");
      const swimmers = xmlDoc.getElementsByTagName("Swimmers");

      swimmersArray = makeSwimmersArray(swimmers);
      racesArray = makeRacesArray(races, swimmersArray);

      meetObj.races = racesArray;

      setJsonFileState({
        ...jsonFileState,
        raceStatsContent: JSON.stringify(meetObj, null, 4),
        errorsContent: {},
        raceCount: racesArray.length,
        errors: 0, //lefErrorArray.length,
        isValidFileType: isValidFileType
      });
    } else {
      setJsonFileState({
        ...jsonFileState,
        raceStatsContent: '',
        errorsContent: {},
        raceCount: 0,
        errors: 0,
        isValidFileType: isValidFileType
      });
    };
  };

  const onSubmitRaceStatsRaces = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let raceStatsObj = JSON.parse(jsonFileState.raceStatsContent);
    if (meetRaceStatsState.isSaving !== true) {
      postMeetRaceStats(raceStatsObj);
    }
  };

  const onGetRaceStatsVideos = () => {
    //getMeetRaceStatsVideos(meetState.objData.meetId);
    putMeetRaceStatsVideo(meetState.objData.meetId);
  }

  // const onSaveVideoLinks = () => {
  //   let vidFileName = "";
  //   let link = "";
  //   let races = meetRaceStatsVideosState.objData.data;

  //   let raceStatsVideos = [];

  //   for (let index = 0; index < races.length; index++) {
  //     const race = races[index];

  //     if (race.files.length > 0) {
  //       let fileIndex = race.files.findIndex(x => x.rendition === '1080p');
  //       if (fileIndex === -1) {
  //         fileIndex = race.files.findIndex(x => x.rendition = '720p');
  //       }

  //       link = race.files[fileIndex].link;
  //       vidFileName = race.name;

  //       raceStatsVideos.push({ videoName: vidFileName, videoLink: link });
  //     }
  //   }

  //   let vidObj = {
  //     meetId: meetState.objData.meetId,
  //     raceStatsVideos: raceStatsVideos
  //   }

  //   if (meetRaceStatsVideosState.isSaving === false) {
  //     postMeetRaceStatsVideos(vidObj);
  //   }
  // }

  function getInitialJSONFileState() {
    return {
      raceStatsContent: '',
      errorsContent: '',
      fileDownloadUrl: '',
      raceCount: 0,
      errors: 0,
      isValidFileType: true
    };
  };

  return {
    meetState,
    jsonFileState,
    meetRaceStatsState,
    meetRaceStatsVideosState,
    processFile,
    FileUpload,
    onSubmitRaceStatsRaces,
    onGetRaceStatsVideos
    //onSaveVideoLinks
  };
};

export default useMeetRecon;