import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryIndividualRosterEntryData from './GetOrgUnitAthleteEntryIndividualRosterEntryData';
import getOrgUnitAthleteEntryBonusIndividualRosterEntryData from './GetOrgUnitAthleteEntryBonusIndividualRosterEntryData';
import putOrgUnitAthleteEntryIndividualRosterEntryData from './PutOrgUnitAthleteEntryIndividualRosterEntryData';
import putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntryData from './PutOrgUnitAthleteEntryAthleteEntryIndividualRosterEntryData';
import putOrgUnitAthleteEntryApproveIndividualEventsData from './PutOrgUnitAthleteEntryApproveIndividualEventsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OrgUnitAthleteEntryIndividualRosterEntryData = {
  INITIAL_STATE,
  getOrgUnitAthleteEntryIndividualRosterEntryData,
  getOrgUnitAthleteEntryBonusIndividualRosterEntryData,
  putOrgUnitAthleteEntryIndividualRosterEntryData,
  putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntryData,
  putOrgUnitAthleteEntryApproveIndividualEventsData
}

export default OrgUnitAthleteEntryIndividualRosterEntryData;