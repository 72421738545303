import React, { createContext, useState } from 'react';

import MeetSessionData from './MeetSessionData';

export const MeetSessionStateContext = createContext();

const MeetSessionContextProvider = ({ children }) => {
  const stateHook = useState(MeetSessionData.INITIAL_STATE);

  return (
    <MeetSessionStateContext.Provider value={stateHook}>
      {children}
    </MeetSessionStateContext.Provider>
  );
};

export default MeetSessionContextProvider;