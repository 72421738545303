import useNewSelectionSearchForm from "./UseNewSelectionSearchForm";

import SelectionsOrgLevelDropdown from "../../../../components/dropdowns/SelectionsOrgLevelDropdown";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";

const NewSelectionSearchForm = ({ onSubmitFormCallback, filterObject }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFormClicked
  } = useNewSelectionSearchForm(onSubmitFormCallback, filterObject);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <SelectionsOrgLevelDropdown
            label="Organization*"
            name="orgUnitId"
            value={formState.orgUnitId}
            error={errorState.orgUnitId}
            message={errorState.orgUnitId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName', e); }}
            organizationCode={'NCAA'}
            levelCode={'DIV'} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Meet Start Date Range*"
            name="startDate"
            value={formState.startDate}
            error={errorState.startDate}
            message={errorState.startDate}
            onChange={(value) => { onFormValueChanged('startDate', value); }}
            countOfYears={150}
            startYearOffset={-148} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Meet End Date Range*"
            name="endDate"
            value={formState.endDate}
            error={errorState.endDate}
            message={errorState.endDate}
            onChange={(value) => { onFormValueChanged('endDate', value); }}
            countOfYears={150}
            startYearOffset={-148} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Search for Meet</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={(e) => onClearFormClicked(e)}>Clear Form</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default NewSelectionSearchForm;