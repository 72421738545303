
import Checkbox from "../../../../common/components/checkboxes/Checkbox";

import { formatDate } from "../../../../common/utils/DateFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetUploadErrorTankQuickAddSmallGrid = ({ gridData, isLoading, onQuickAddCheckbox, onDeleteCheckbox, onMatchWithMemberIdButtonClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((quickAddItem, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className="hidden-xs">{quickAddItem.lscCode + '/' + quickAddItem.clubCode}</span>&nbsp;
              <button
                className={global.IconButton}
                type="button"
                onClick={(e) => { onMatchWithMemberIdButtonClick(e, quickAddItem) }}>
                <FontAwesomeIcon icon={faUser} />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className="row">
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>LSC / Club Code</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{quickAddItem.lscCode + '/' + quickAddItem.clubCode}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>First Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{quickAddItem.firstName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Last Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{quickAddItem.lastName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(quickAddItem.birthDate) || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>FINA?</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{quickAddItem.isFina ? 'Yes' : 'No'}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Relay?</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{quickAddItem.isRelay ? 'Yes' : 'No'}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Quick Add</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{quickAddItem.isFina === true ?
                  <Checkbox
                    label=""
                    name="addPerson"
                    onChange={(e) => { onQuickAddCheckbox(e, quickAddItem.rowId) }}
                    checked={quickAddItem.addPerson}
                  />
                  : 'N/A'
                }</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Delete</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
                  <Checkbox
                    label=""
                    name="deletePerson"
                    onChange={(e) => { onDeleteCheckbox(e, quickAddItem.rowId) }}
                    checked={quickAddItem.deletePerson}
                  />
                </div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>
                No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetUploadErrorTankQuickAddSmallGrid;