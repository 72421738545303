import React, { Fragment } from 'react';

import LaneSheetHeatRelayTeamFormGridLarge from './LaneSheetHeatRelayTeamFormGridLarge';

const LaneSheetHeatRelayTeamFormGrid = ({ numberOfLanes, startingLaneNumber, formState, options, onValueTextPairChanged }) => (
  <Fragment>
    <LaneSheetHeatRelayTeamFormGridLarge
      numberOfLanes={numberOfLanes}
      startingLaneNumber={startingLaneNumber}
      formState={formState}
      options={options}
      onValueTextPairChanged={onValueTextPairChanged} />
  </Fragment>
);

export default LaneSheetHeatRelayTeamFormGrid;