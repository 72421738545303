import React, { Fragment } from 'react';

import OrgUnitAdminRosterWrite from './OrgUnitAdminRosterWrite';
import OrgUnitAdminRosterReadOnly from './OrgUnitAdminRosterReadOnly';

import useOrgUnitAdminRoster from './UseOrgUnitAdminRoster';

const OrgUnitAdminRoster = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminRoster();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminRosterWrite /> :
        <OrgUnitAdminRosterReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminRoster;