import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import GetInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import {
  eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete, restrictionsIsComplete,
  sessionsIsComplete, ageGroupsIsComplete, timeStandardsIsComplete, eventsIsComplete
} from './LeftNavValidation';

const getEvents = (viewName, route, omeMeetStateObjData, omeMeetInvitationsStateArrayData, meetStateObjData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID,
  restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = viewName === Views.OME_MEET_EVENTS;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);
  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);
  const isRestrictionsComplete = restrictionsIsComplete(omeMeetStateObjData, restrictionsRoutePermission);
  const isInvitationsComplete = invitationsIsComplete(omeMeetInvitationsStateArrayData);
  const isSessionsComplete = sessionsIsComplete(meetStateObjData);
  const isAgeGroupsComplete = ageGroupsIsComplete(omeMeetStateObjData);
  const isTimeStandardsComplete = timeStandardsIsComplete(omeMeetStateObjData);

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true
    && isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true
    && isAgeGroupsComplete === true && isTimeStandardsComplete === true;

  const isComplete = isMeetInfoComplete === true && isEligibilityComplete === true && isTimeStandardsComplete === true
    && isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true
    && isAgeGroupsComplete === true && eventsIsComplete(omeMeetStateObjData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID);

  const item = route === '' ? GetInitialStateOmeRoute(Views.OME_MEET_EVENTS) : getInitialStateMeetRoute(Views.OME_MEET_EVENTS);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getEvents;