import React, { Fragment } from 'react';

import useMeetStaff from './UseMeetStaff';

import MeetStaffGrid from './MeetStaffGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const MeetStaffWrite = () => {
  const {
    meetStaffState,
    isLoading,
    Constants,
    onEditStaff
  } = useMeetStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Staff</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <MeetStaffGrid
            gridData={meetStaffState.arrayData}
            isLoading={meetStaffState.isArrayLoading}
            onEditStaff={onEditStaff}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  )
}

export default MeetStaffWrite;