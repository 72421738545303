import { useContext } from 'react';
import { CONFIGURATION_NAME, MEET_ENTRIES_NAME, MEET_EVENTS_NAME, PARTICIPANT_SELECTIONS_NAME, REPORTS_NAME, EXPORT_NAME } from '../../views/selections/components/leftNav/SelectionsLeftNavConstants';
import { ConfigurationIsComplete, ExportIsComplete, MeetEntriesIsComplete, MeetEventsIsComplete, ParticipantSelectionsIsComplete, ReportsIsComplete } from '../../views/selections/components/leftNav/SelectionsLeftNavValidation';
import { getCompetitionGenderName } from '../../views/selections/utils/SelectionsUtils';

import { SelectionsLeftNavStateContext } from './SelectionsLeftNavContextProvider';

const useSelectionsLeftNavData = () => {
  const [selectionsLeftNavState, setSelectionsLeftNavState] = useContext(SelectionsLeftNavStateContext);

  // refreshes all left nav objects and left nav title fields
  const refreshLeftNavState = (currentViewName, selectionsObj) => {
    const newArrayData = JSON.parse(JSON.stringify(selectionsLeftNavState.arrayData));

    // check isComplete, isCurrent & isVisited
    for (const leftNavObj of newArrayData) {
      const isComplete = getIsComplete(leftNavObj.name, selectionsObj);

      leftNavObj.isComplete = isComplete;

      if (leftNavObj.name === currentViewName) {
        leftNavObj.isCurrent = true;
        leftNavObj.isVisited = true;
      } else {
        leftNavObj.isCurrent = false;
      }
    }

    // check arePrerequisitesDone
    for (const leftNavObj of newArrayData) {
      if (Array.isArray(leftNavObj.prerequisites) && leftNavObj.prerequisites.length > 0) {
        let arePrerequisitesDone = true;

        for (const prerequisiteName of leftNavObj.prerequisites) {
          const prerequisiteObj = newArrayData.find(x => x.name === prerequisiteName);

          if (prerequisiteObj?.isComplete !== true) {
            arePrerequisitesDone = false;
            break;
          }
        }

        leftNavObj.arePrerequisitesDone = arePrerequisitesDone;
      } else {
        leftNavObj.arePrerequisitesDone = true;
      }
    }

    const newObjData = {
      meetHost: selectionsObj.meetHost,
      meetName: selectionsObj.meetName,
      meetStartDate: selectionsObj.meetStartDate,
      meetEndDate: selectionsObj.meetEndDate,
      competitionGenderTypeName: getCompetitionGenderName(selectionsObj.selectMales, selectionsObj.selectFemales)
    };

    setSelectionsLeftNavState({
      ...selectionsLeftNavState,
      arrayData: newArrayData,
      objData: newObjData,
      isLoaded: true
    });
  };

  const updateCurrentLeftNavData = (currentViewName, isComplete = undefined) => {
    if (selectionsLeftNavState.isLoaded === true) {
      const newArrayData = JSON.parse(JSON.stringify(selectionsLeftNavState.arrayData));

      // check isCurrent & isVisited
      for (const leftNavObj of newArrayData) {
        if (leftNavObj.name === currentViewName) {
          leftNavObj.isCurrent = true;
          leftNavObj.isVisited = true;

          if (isComplete !== undefined) {
            leftNavObj.isComplete = isComplete;
          }
        } else {
          leftNavObj.isCurrent = false;
        }
      }

      // check arePrerequisitesDone if isComplete was passed
      if (isComplete !== undefined) {
        for (const leftNavObj of newArrayData) {
          if (Array.isArray(leftNavObj.prerequisites) && leftNavObj.prerequisites.length > 0) {
            let arePrerequisitesDone = true;

            for (const prerequisiteName of leftNavObj.prerequisites) {
              const prerequisiteObj = newArrayData.find(x => x.name === prerequisiteName);

              if (prerequisiteObj?.isComplete !== true) {
                arePrerequisitesDone = false;
                break;
              }
            }

            leftNavObj.arePrerequisitesDone = arePrerequisitesDone;
          } else {
            leftNavObj.arePrerequisitesDone = true;
          }
        }
      }

      setSelectionsLeftNavState({
        ...selectionsLeftNavState,
        arrayData: newArrayData
      });
    }
  };

  function getIsComplete(leftNavObjName, selectionsObj) {
    switch (leftNavObjName) {
      case CONFIGURATION_NAME:
        return ConfigurationIsComplete(selectionsObj);
      case MEET_EVENTS_NAME:
        return MeetEventsIsComplete(selectionsObj);
      case MEET_ENTRIES_NAME:
        return MeetEntriesIsComplete(selectionsObj);
      case PARTICIPANT_SELECTIONS_NAME:
        return ParticipantSelectionsIsComplete(selectionsObj);
      case REPORTS_NAME:
        return ReportsIsComplete(selectionsObj);
      case EXPORT_NAME:
        return ExportIsComplete(selectionsObj);
      default:
        return false;
    }
  };

  return {
    selectionsLeftNavState,
    refreshLeftNavState,
    updateCurrentLeftNavData
  };
};

export default useSelectionsLeftNavData;