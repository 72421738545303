import React, { Fragment } from 'react';

import useOmeLoading from './UseOmeLoading';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';

const OmeLoading = () => {
  const {
    isLoading
  } = useOmeLoading();

  return (
    <Fragment>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default OmeLoading;