import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLaneSheetData from '../../../state/laneSheet/UseLaneSheetData';
import useLaneSheetMeetEventsData from '../../../state/laneSheetMeetEvents/UseLaneSheetMeetEventsData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  meetEvent: {}
};

const INITIAL_DELETE_MEET_EVENT_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetMeetEvents = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetMeetEventsState, getLaneSheetMeetEvents, postBulkLaneSheetMeetEventsOme, deleteLaneSheetMeetEvent
  } = useLaneSheetMeetEventsData();
  const [deleteMeetEventState, setDeleteMeetEventState] = useState(INITIAL_DELETE_MEET_EVENT_STATE);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);

  const onBulkAddFromOmeClicked = () => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postBulkLaneSheetMeetEventsOmePromise = postBulkLaneSheetMeetEventsOme(laneSheetMeetId);

      if (postBulkLaneSheetMeetEventsOmePromise !== null) {
        postBulkLaneSheetMeetEventsOmePromise.catch((e) => {
          //TODO context error
        });
      }
    }
  };

  const onAddMeetEventClicked = () => {
    navigate(NavLinks.LANE_SHEET_MEET_EVENTS_ADD_EVENTS);
  };

  const onAddParticipantsClicked = (meetEvent) => {
    if (meetEvent.isRelay === true) {
      navigate(NavLinks.LANE_SHEET_MEET_EVENTS_ADD_RELAY_TEAMS, { state: { meetEvent } });
    } else {
      navigate(NavLinks.LANE_SHEET_MEET_EVENTS_ADD_PARTICIPANTS, { state: { meetEvent } });
    }
  };

  const onDeleteMeetEventClicked = (meetEvent) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      meetEvent
    });
  };

  const onDeleteModalDeleteClicked = () => {
    const laneSheetEventId = deleteModalState.meetEvent?.laneSheetEventId;

    if (laneSheetEventId > 0) {
      const deleteLaneSheetMeetEventPromise = deleteLaneSheetMeetEvent(laneSheetEventId, deleteMeetEventState, setDeleteMeetEventState);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLaneSheetMeetEventPromise !== null) {
        deleteLaneSheetMeetEventPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

            if (laneSheetMeetId > 0) {
              const getLaneSheetMeetEventsPromise = getLaneSheetMeetEvents(laneSheetMeetId);

              if (getLaneSheetMeetEventsPromise !== null) {
                getLaneSheetMeetEventsPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  useEffect(() => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const getLaneSheetMeetEventsPromise = getLaneSheetMeetEvents(laneSheetMeetId);

      if (getLaneSheetMeetEventsPromise !== null) {
        getLaneSheetMeetEventsPromise.catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetState]);

  return {
    isSaving: deleteMeetEventState.isObjLoading,
    isLoading: laneSheetMeetEventsState.isArrayLoading,
    gridData: laneSheetMeetEventsState.arrayData || [],
    omeMeetId: laneSheetState.objData?.omeMeetId || null,
    deleteModalState,
    onBulkAddFromOmeClicked,
    onAddMeetEventClicked,
    onAddParticipantsClicked,
    onDeleteMeetEventClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  };
};

export default useLaneSheetMeetEvents;