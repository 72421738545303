import getMeetUploadErrorTank from './GetMeetUploadErrorTankData';
import postMeetUploadErrorTankMatch from './PostMeetUploadErrorTankMatchData';
import postMeetUploadErrorTankQuickAdd from './PostMeetUploadErrorTankQuickAddData';
import deleteMeetUploadErrorTankSwimmers from './DeleteMeetUploadErrorTankSwimmersData';
import postMeetUploadErrorTankMatchByMemberId from './PostMeetUploadErrorTankMatchByMemberIdData';
import getBasicMember from './GetBasicMemberData';
import getMeetPotentialMatchedPersons from './GetMeetPotentialMatchedPersonData';

import Constants from '../../../utils/Constants';

const INITIAL_STATE = {
  meetId: Constants.DEFAULT_ID,
  isObjLoading: false,
  isObjLoaded: false,
  objData: {},
  isArrayLoading: false,
  isArrayLoaded: false,
  arrayData: [],
  message: ''
};

const MeetUploadErrorTankData = {
  INITIAL_STATE,
  getMeetUploadErrorTank,
  postMeetUploadErrorTankMatch,
  postMeetUploadErrorTankQuickAdd,
  deleteMeetUploadErrorTankSwimmers,
  postMeetUploadErrorTankMatchByMemberId,
  getBasicMember,
  getMeetPotentialMatchedPersons
};

export default MeetUploadErrorTankData;
