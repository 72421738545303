import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MeetAddStaffValidation';

import NavLinks from '../NavLinks';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import UseForm from "../../../../common/utils/UseForm";
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const TAXONOMIES = ['MeetDetail', 'OUMeetDetail', 'AthleteMeetDetail'];
const SCOPE = 'Meet';

const useMeetAddStaff = () => {
  const navigate = useNavigate();
  const { meetState, putMeet } = useMeetData();
  const { resetOmeMeetState } = useOmeMeetData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const [state, setState] = useState({ tryRedirect: false });
  const { OFFICIAL_ORG_ROLE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID, MEET_DIRECTOR_STAFF_TYPE_ID,
    MEET_REFEREE_STAFF_TYPE_ID, MEET_CONTACT_STAFF_TYPE_ID } = useEnvironmentVariableData();
  const { formState, errorState, isSubmitting, handleSubmit, onFormValueChanged, setFormData
  } = UseForm(getInitialFormState, submitFormCallback, validate);

  const editMeetCopy = () => {
    const meetCopy = JSON.parse(JSON.stringify(meetState.objData));
    meetCopy.personMeetStaff = [
      {
        meetId: meetCopy.meetId,
        personId: formState.meetDirectorPersonId,
        personMeetStaffType: [
          { meetStaffTypeId: MEET_DIRECTOR_STAFF_TYPE_ID }]
      },
      {
        meetId: meetCopy.meetId,
        personId: formState.meetRefereePersonId,
        personMeetStaffType: [
          { meetStaffTypeId: MEET_REFEREE_STAFF_TYPE_ID }]
      },
      {
        meetId: meetCopy.meetId,
        personId: formState.meetContactPersonId,
        personMeetStaffType: [
          { meetStaffTypeId: MEET_CONTACT_STAFF_TYPE_ID }]
      }]
    return meetCopy;
  };

  const onBackClicked = () => {
    navigate(NavLinks.MEET_ADD_SESSIONS);
  };

  useEffect(() => {
    setFormData({
      ...formState,
      meetStartDate: meetState.objData.startDate ? formatDate(meetState.objData.startDate) : Constants.BLANK_DATETIME_STRING,
      meetEndDate: meetState.objData.endDate ? formatDate(meetState.objData.endDate) : Constants.BLANK_DATETIME_STRING
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (meetState.objData.meetId && meetState.objData.meetId > 0 && meetState.isSaving === false
      && contextSecurityState.isObjLoading === false && state.tryRedirect === true) {
      resetOmeMeetState();
      navigate(NavLinks.MEET_INFO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, contextSecurityState, state]);

  function submitFormCallback() {
    putMeet(meetState.objData?.meetId, editMeetCopy());
    getContextSecurity(meetState.objData.meetId, TAXONOMIES, SCOPE);
    setState({ ...state, tryRedirect: true });
  };

  function getInitialFormState() {
    return {
      meetDirectorMemberId: '',
      meetRefereeMemberId: '',
      meetContactMemberId: '',
      meetDirectorPersonId: '',
      meetRefereePersonId: '',
      meetContactPersonId: '',
      OFFICIAL_ORG_ROLE_ID: OFFICIAL_ORG_ROLE_ID,
      OFFICIALS_ROLES_ROLE_GROUP_ID: OFFICIALS_ROLES_ROLE_GROUP_ID,
      meetStartDate: Constants.BLANK_DATE_STRING,
      meetEndDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    meetState,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onBackClicked,
    isSaving: meetState.isSaving,
    isLoading: contextSecurityState.isObjLoading || isSubmitting
  };
};

export default useMeetAddStaff;