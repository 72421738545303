import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeAlternateEventDetailValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useTimeStandardAgeGroupData from '../../../../state/timeStandardAgeGroup/UseTimeStandardAgeGroupData';

import useEventData from '../../../../../common/state/event/UseEventData';
import useEventCompetitionGenderTypeData from '../../../../../common/state/eventCompetitionGender/UseEventCompetitionGenderTypeData';
import useTimeStandardTypeData from '../../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useMeetData from '../../../../../common/state/meet/UseMeetData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  isLoaded: false,
  ageGroups: [],
  sessions: [],
  updateTimeCuts: false
};

const INITIAL_FORM_STATE = {
  showScy: false,
  showScm: false,
  showLcm: false,
  scyPossibleForAlternateEvent: true,
  scmPossibleForAlternateEvent: true,
  lcmPossibleForAlternateEvent: true,

  selectedEvent: {},
  meetAgeGroupId: Constants.DEFAULT_ID,
  meetAgeGroupName: '',
  meetEventId: Constants.DEFAULT_ID,
  eventCompetitionGenderTypeId: '',
  meetSessionId: Constants.DEFAULT_ID,
  meetSessionName: '',
  eventId: Constants.DEFAULT_ID,
  scyEventId: Constants.DEFAULT_ID,
  scyEventName: '',
  scmEventId: Constants.DEFAULT_ID,
  scmEventName: '',
  lcmEventId: Constants.DEFAULT_ID,
  lcmEventName: '',
  isRelay: '',
  offeredAsBonusEvent: true,

  alternateMeetEventId: Constants.DEFAULT_ID,
  alternateEventId: Constants.DEFAULT_ID,
  alternateEventName: '',
  scyAlternateEventId: Constants.DEFAULT_ID,
  scyAlternateEventName: '',
  scmAlternateEventId: Constants.DEFAULT_ID,
  scmAlternateEventName: '',
  lcmAlternateEventId: Constants.DEFAULT_ID,
  lcmAlternateEventName: '',
  isAlternateRelay: '',

  hasNoSlowerThanTimeStandards: '',
  hasNoFasterThanTimeStandards: '',
  hasBonusTimeStandards: '',
  noSlowerThanTimeStandardName: '',
  noSlowerThanTimeStandardSwimTime: '',
  scyQualifyingTime: '',
  scyQualifyingTimeValue: '',
  scmQualifyingTime: '',
  scmQualifyingTimeValue: '',
  lcmQualifyingTime: '',
  lcmQualifyingTimeValue: '',
  noFasterThanTimeStandardName: '',
  noFasterThanTimeStandardSwimTime: '',
  scyLimitTime: '',
  scyLimitTimeValue: '',
  scmLimitTime: '',
  scmLimitTimeValue: '',
  lcmLimitTime: '',
  lcmLimitTimeValue: '',
  bonusTimeStandardName: '',
  bonusTimeStandardSwimTime: '',
  scyBonusTime: '',
  scyBonusTimeValue: '',
  scmBonusTime: '',
  scmBonusTimeValue: '',
  lcmBonusTime: '',
  lcmBonusTimeValue: '',
  alternateEventHasNoFasterThanTimeStandard: true,
  alternateEventHasNoSlowerThanTimeStandard: true,
  alternateEventHasBonusTimeStandard: true,
  handleRelayEvents: false
};

const useOmeAlternateEventDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { eventState, setEventState } = useEventData();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { meetState } = useMeetData();
  const { eventCompetitionGenderState } = useEventCompetitionGenderTypeData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { timeStandardAgeGroupState, getTimeStandardAgeGroupEventStandard } = useTimeStandardAgeGroupData();
  const { environmentVariableState, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formState, errorState, isSubmitting, isDirty, handleSubmit, setErrors, onFormValueChanged, setFormData, setFormState,
    onValueTextPairChanged, setIsDirty } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async (submitButton) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true, submitButton: submitButton });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, submitButton: HostRegistrationConstants.BACK, tryRedirect: true });
  };

  function submitFormCallback(formState) {
    //Prevent user from adding a relay event if they set relayEvents to false on the eligibility page
    let relayError = false;
    if (omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents === false) {
      if (formState.isAlternateRelay === true) {
        relayError = true;
      }
    }
    if (relayError === true) {
      setErrors({ ...errorState, error: `The selected relay event cannot be added because the 'Meet has Relay Events?' field is set to 'No' on the Eligibility page. In order to add a relay event, please navigate to the Eligibility page and set the 'Meet has Relay Events?' field to 'Yes'.` });
    }
    else if (relayError === false) {
      const updatedOmeMeetCopy = editOmeMeetObj();
      if (updatedOmeMeetCopy !== null) {
        updatedOmeMeetCopy.meet = undefined;
        putOmeMeet(omeMeetState.objData.omeMeetId, updatedOmeMeetCopy);
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const editOmeMeetObj = () => {
    let updatedOmeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    const selectedAgeGroupIndex = updatedOmeMeetCopy.meetAgeGroup.findIndex(x => x.meetAgeGroupId === formState.meetAgeGroupId);
    if (selectedAgeGroupIndex > -1) {
      const selectedMeetEventIndex = updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent.findIndex(x => x.meetEventId === formState.meetEventId);
      //Age group is the same
      if (selectedMeetEventIndex > -1) {
        //Remove any previous time cuts for alternate event
        updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime =
          updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime.filter(x => x.eventId !== formState.scyAlternateEventId && x.eventId !== formState.scmAlternateEventId && x.eventId !== formState.lcmAlternateEventId);
        updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit =
          updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit.filter(x => x.eventId !== formState.scyAlternateEventId && x.eventId !== formState.scmAlternateEventId && x.eventId !== formState.lcmAlternateEventId);
        updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime =
          updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime.filter(x => x.eventId !== formState.scyAlternateEventId && x.eventId !== formState.scmAlternateEventId && x.eventId !== formState.lcmAlternateEventId);

        //add any new time cuts for alternate event
        updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex] = {
          ...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex],
          meetEventQualifyingTime: formState.hasNoSlowerThanTimeStandards === true ?
            (formState.alternateEventHasNoSlowerThanTimeStandard === true ?
              [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime,
              ...(formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyAlternateEventId, qualifyingTime: formState.scyQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmAlternateEventId, qualifyingTime: formState.scmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmAlternateEventId, qualifyingTime: formState.lcmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmAlternateEventId)?.courseId, course: undefined, event: undefined }] : [])]
              :
              [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime,
              ...(formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyAlternateEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmAlternateEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmAlternateEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmAlternateEventId)?.courseId, course: undefined, event: undefined }] : [])])
            : [],
          meetEventQualificationLimit: formState.hasNoFasterThanTimeStandards === true ?
            (formState.alternateEventHasNoFasterThanTimeStandard === true ?
              [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit,
              ...(formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyAlternateEventId, limitTime: formState.scyLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmAlternateEventId, limitTime: formState.scmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmAlternateEventId, limitTime: formState.lcmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmAlternateEventId)?.courseId, course: undefined, event: undefined }] : [])]
              :
              [
                ...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit,
                ...(formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyAlternateEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmAlternateEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmAlternateEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmAlternateEventId)?.courseId, course: undefined, event: undefined }] : [])])
            : [],
          meetEventBonusTime: formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true ?
            (formState.alternateEventHasBonusTimeStandard === true ?
              [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime,
              ...(formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyAlternateEventId, bonusTime: formState.scyBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmAlternateEventId, bonusTime: formState.scmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmAlternateEventId, bonusTime: formState.lcmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmAlternateEventId)?.courseId, course: undefined, event: undefined }] : [])]
              :
              [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime,
              ...(formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyAlternateEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmAlternateEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmAlternateEventId)?.courseId, course: undefined, event: undefined }] : []),
              ...(formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmAlternateEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmAlternateEventId)?.courseId, course: undefined, event: undefined }] : [])])
            : []
        };
        return updatedOmeMeetCopy;
      }
      else {
        return null
      }
    }
    else {
      return null
    }
  };

  const onChangeEvent = (newValue, newValueLabel) => {
    const alternateEventObject = eventState.arrayData.find(x => x.eventId === newValue);
    const isAlternateRelay = alternateEventObject ? alternateEventObject.isRelay : '';
    const scyAlternateEventId = alternateEventObject ? alternateEventObject.relatedScyEventId : Constants.DEFAULT_ID;
    const scyAlternateEventName = alternateEventObject ? eventState.arrayData.find(x => x.eventId === alternateEventObject.relatedScyEventId)?.eventName || '' : '';
    const scmAlternateEventId = alternateEventObject ? alternateEventObject.relatedScmEventId : Constants.DEFAULT_ID;
    const scmAlternateEventName = alternateEventObject ? eventState.arrayData.find(x => x.eventId === alternateEventObject.relatedScmEventId)?.eventName || '' : '';
    const lcmAlternateEventId = alternateEventObject ? alternateEventObject.relatedLcmEventId : Constants.DEFAULT_ID;
    const lcmAlternateEventName = alternateEventObject ? eventState.arrayData.find(x => x.eventId === alternateEventObject.relatedLcmEventId)?.eventName || '' : '';
    let scyPossibleForAlternateEvent = true;
    let scmPossibleForAlternateEvent = true;
    let lcmPossibleForAlternateEvent = true;
    if (!scyAlternateEventId) {
      scyPossibleForAlternateEvent = false;
    }
    if (!scmAlternateEventId) {
      scmPossibleForAlternateEvent = false;
    }
    if (!lcmAlternateEventId) {
      lcmPossibleForAlternateEvent = false;
    }
    let showScy = false;
    let showScm = false;
    let showLcm = false;

    // if (isAlternateRelay === true) {
    //   if (alternateEventObject.courseId === SCY_COURSE_ID) {
    //     showScy = true;
    //     showScm = false;
    //     showLcm = false;
    //   }
    //   else if (alternateEventObject.courseId === SCM_COURSE_ID) {
    //     showScy = false;
    //     showScm = true;
    //     showLcm = false;
    //   }
    //   else if (alternateEventObject.courseId === LCM_COURSE_ID) {
    //     showScy = false;
    //     showScm = false;
    //     showLcm = true;
    //   }
    // }
    // else {
      //Figure out which course inputs to display for individual events  
      if (omeMeetState.objData.omeMeetEntryCourse.length > 0) {
        for (let i = 0; i < omeMeetState.objData.omeMeetEntryCourse.length; i++) {
          if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCY_COURSE_ID) {
            showScy = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCM_COURSE_ID) {
            showScm = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === LCM_COURSE_ID) {
            showLcm = true;
          }
        }
      }
    // }
    if (formState.alternateEventId !== newValue
      || formState.alternateEventName !== newValueLabel) {
      setFormState(formState => ({
        ...formState,
        showScy,
        showScm,
        showLcm,
        scyPossibleForAlternateEvent,
        scmPossibleForAlternateEvent,
        lcmPossibleForAlternateEvent,
        alternateEventId: newValue,
        alternateEventName: newValueLabel,
        isAlternateRelay: isAlternateRelay,
        scyAlternateEventId: scyAlternateEventId,
        scyAlternateEventName: scyAlternateEventName,
        scmAlternateEventId: scmAlternateEventId,
        scmAlternateEventName: scmAlternateEventName,
        lcmAlternateEventId: lcmAlternateEventId,
        lcmAlternateEventName: lcmAlternateEventName
      }));
      setIsDirty(true);
    }
  };

  function formStateHasBeenSaved(formState) {
    //compare event detail formState to omeMeetState to see if formState has been saved
    //Edit alternate event
    if (formState?.meetAgeGroupId !== Constants.DEFAULT_ID && formState?.meetEventId !== Constants.DEFAULT_ID &&
      formState.alternateEventId !== Constants.DEFAULT_ID && formState.alternateMeetEventId !== Constants.DEFAULT_ID) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState?.meetAgeGroupId);
      const selectedMeetEvent = selectedMeetAgeGroup?.meetEvent?.find(x =>
        x.meetEventId === formState?.meetEventId);

      if (formState?.alternateEventHasNoSlowerThanTimeStandard !== (selectedMeetEvent.meetEventQualifyingTime?.length > 0 &&
        selectedMeetEvent.meetEventQualifyingTime?.filter(x => (x.eventId === formState.scyAlternateEventId || x.eventId === formState.scmAlternateEventId || x.eventId === formState.lcmAlternateEventId) && formatTimeForDisplay(x.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0) ? true : false) {
        return false;
      }
      if ((formState?.scyQualifyingTimeValue !== selectedMeetEvent?.meetEventQualifyingTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyAlternateEventId)?.qualifyingTime)
        && (formState?.scyQualifyingTimeValue !== '' || selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyAlternateEventId)?.qualifyingTime !== undefined)) {
        return false;
      }
      if ((formState?.scmQualifyingTimeValue !== selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmAlternateEventId)?.qualifyingTime)
        && (formState?.scmQualifyingTimeValue !== '' || selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmAlternateEventId)?.qualifyingTime !== undefined)) {
        return false;
      }
      if ((formState?.lcmQualifyingTimeValue !== selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmAlternateEventId)?.qualifyingTime)
        && (formState?.lcmQualifyingTimeValue !== '' || selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmAlternateEventId)?.qualifyingTime !== undefined)) {
        return false;
      }

      if (formState?.alternateEventHasNoFasterThanTimeStandard !== (selectedMeetEvent.meetEventQualificationLimit?.length > 0 &&
        selectedMeetEvent.meetEventQualificationLimit?.filter(x => (x.eventId === formState.scyAlternateEventId || x.eventId === formState.scmAlternateEventId || x.eventId === formState.lcmAlternateEventId) && formatTimeForFilterObject(x.limitTime) !== Constants.MIN_SWIM_TIME_STRING).length > 0) ? true : false) {
        return false;
      }
      if ((formState?.scyLimitTimeValue !== selectedMeetEvent?.meetEventQualificationLimit?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyAlternateEventId)?.limitTime)
        && (formState?.scyLimitTimeValue !== '' || selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyAlternateEventId)?.limitTime !== undefined)) {
        return false;
      }
      if ((formState?.scmLimitTimeValue !== selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmAlternateEventId)?.limitTime)
        && (formState?.scmLimitTimeValue !== '' || selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmAlternateEventId)?.limitTime !== undefined)) {
        return false;
      }
      if ((formState?.lcmLimitTimeValue !== selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmAlternateEventId)?.limitTime)
        && (formState?.lcmLimitTimeValue !== '' || selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmAlternateEventId)?.limitTime !== undefined)) {
        return false;
      }

      if (formState?.alternateEventHasBonusTimeStandard !== (selectedMeetEvent.meetEventBonusTime?.length > 0 &&
        selectedMeetEvent.meetEventBonusTime?.filter(x => (x.eventId === formState.scyAlternateEventId || x.eventId === formState.scmAlternateEventId || x.eventId === formState.lcmAlternateEventId) && formatTimeForDisplay(x.bonusTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0) ? true : false) {
        return false;
      }

      if (formState.offeredAsBonusEvent === true) {
        if ((formState?.scyBonusTimeValue !== selectedMeetEvent?.meetEventBonusTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyAlternateEventId)?.bonusTime)
          && (formState?.scyBonusTimeValue !== '' || selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyAlternateEventId)?.bonusTime !== undefined)) {
          return false;
        }
        if ((formState?.scmBonusTimeValue !== selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmAlternateEventId)?.bonusTime)
          && (formState?.scmBonusTimeValue !== '' || selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmAlternateEventId)?.bonusTime !== undefined)) {
          return false;
        }
        if ((formState?.lcmBonusTimeValue !== selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmAlternateEventId)?.bonusTime)
          && (formState?.lcmBonusTimeValue !== '' || selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmAlternateEventId)?.bonusTime !== undefined)) {
          return false;
        }
      }
    }
    //Add alternate event
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && Object.keys(meetState.objData).length > 0) {
      //Sort age group data to display in ascending order in dropdown
      let meetAgeGroupCopy = [];
      if (omeMeetState.objData.meetAgeGroup.length > 0) {
        meetAgeGroupCopy = JSON.parse(JSON.stringify(omeMeetState.objData.meetAgeGroup));
        meetAgeGroupCopy.sort(function (a, b) { return a.minAge - b.minAge });
      }
      const meetAgeGroupCopyOptions = meetAgeGroupCopy.map((ageGroup) => ({ id: ageGroup.meetAgeGroupId, name: `${ageGroup.ageGroupName || ''} (${ageGroup.minAge || ''} - ${ageGroup.maxAge || ''})` }));
      meetAgeGroupCopyOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' });
      //Sort session data to display in ascending order in dropdown
      let meetSessionCopy = [];
      if (meetState.objData.meetSession.length > 0) {
        meetSessionCopy = JSON.parse(JSON.stringify(meetState.objData?.meetSession));
        meetSessionCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
      }
      const meetSessionCopyOptions = meetSessionCopy.map((session) => ({ id: session.meetSessionId, name: `${session.sessionOrderId || ''} (${session.sessionType?.typeName || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})` }));
      meetSessionCopyOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' });
      setState({
        ...state,
        isLoaded: true,
        ageGroups: meetAgeGroupCopyOptions,
        sessions: meetSessionCopyOptions
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, meetState.objData]);


  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && eventState.isArrayLoaded === true) {
      const allowedCourseIds = omeMeetState.objData?.omeMeetEntryCourse.map(x => { return x.courseId });
      const filteredArrayData = JSON.parse(JSON.stringify(eventState.arrayData)).filter(x => allowedCourseIds.some(courseId => courseId === x.courseId));
      const filteredArrayDataFormatted = filteredArrayData.map(x => { return { id: x.eventId, name: x.eventName }; });
      setEventState({ ...eventState, filteredItems: filteredArrayDataFormatted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, eventState.isArrayLoaded])

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && eventCompetitionGenderState.isArrayLoaded === true && state.isLoaded === true &&
      environmentVariableState.isLoaded === true && eventState.isArrayLoaded === true && eventState.filteredItems) {
      //Figure out which course inputs to display     
      let showScy = false;
      let showScm = false;
      let showLcm = false;
      if (omeMeetState.objData.omeMeetEntryCourse.length > 0) {
        for (let i = 0; i < omeMeetState.objData.omeMeetEntryCourse.length; i++) {
          if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCY_COURSE_ID) {
            showScy = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCM_COURSE_ID) {
            showScm = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === LCM_COURSE_ID) {
            showLcm = true;
          }
        }
      }
      //Edit
      if (location.state && omeMeetState.objData.meetAgeGroup?.length > 0 && location.state?.meetAgeGroupId && location.state?.meetEventId) {
        const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
          x.meetAgeGroupId === location?.state?.meetAgeGroupId);
        if (selectedMeetAgeGroup && Object.keys(selectedMeetAgeGroup).length > 0) {
          const selectedMeetEvent = selectedMeetAgeGroup.meetEvent?.find(x =>
            x.meetEventId === location?.state?.meetEventId);
          if (selectedMeetEvent && Object.keys(selectedMeetEvent).length > 0) {
            const eventObject = eventState.arrayData.find(x => x.eventId === selectedMeetEvent.eventId);
            const isRelay = eventObject ? eventObject.isRelay : '';
            const scyEventId = eventObject ? eventObject.relatedScyEventId : Constants.DEFAULT_ID;
            const scyEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedScyEventId)?.eventName || '' : '';
            const scmEventId = eventObject ? eventObject.relatedScmEventId : Constants.DEFAULT_ID;
            const scmEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedScmEventId)?.eventName || '' : '';
            const lcmEventId = eventObject ? eventObject.relatedLcmEventId : Constants.DEFAULT_ID;
            const lcmEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedLcmEventId)?.eventName || '' : '';
            //Edit
            if (location.state?.alternateMeetEventId && location.state?.alternateEventId) {
              const alternateEventObject = eventState.arrayData.find(x => x.eventId === location.state?.alternateEventId);
              const isAlternateRelay = alternateEventObject ? alternateEventObject.isRelay : '';
              //  if (isAlternateRelay === true) {
              //   if (alternateEventObject.courseId === SCY_COURSE_ID) {
              //     showScy = true;
              //     showScm = false;
              //     showLcm = false;
              //   }
              //   else if (alternateEventObject.courseId === SCM_COURSE_ID) {
              //     showScy = false;
              //     showScm = true;
              //     showLcm = false;
              //   }
              //   else if (alternateEventObject.courseId === LCM_COURSE_ID) {
              //     showScy = false;
              //     showScm = false;
              //     showLcm = true;
              //   }
              // }

              const scyAlternateEventId = alternateEventObject ? alternateEventObject.relatedScyEventId : Constants.DEFAULT_ID;
              const scyAlternateEventName = alternateEventObject ? eventState.arrayData.find(x => x.eventId === alternateEventObject.relatedScyEventId)?.eventName || '' : '';
              const scmAlternateEventId = alternateEventObject ? alternateEventObject.relatedScmEventId : Constants.DEFAULT_ID;
              const scmAlternateEventName = alternateEventObject ? eventState.arrayData.find(x => x.eventId === alternateEventObject.relatedScmEventId)?.eventName || '' : '';
              const lcmAlternateEventId = alternateEventObject ? alternateEventObject.relatedLcmEventId : Constants.DEFAULT_ID;
              const lcmAlternateEventName = alternateEventObject ? eventState.arrayData.find(x => x.eventId === alternateEventObject.relatedLcmEventId)?.eventName || '' : '';
              let scyPossibleForAlternateEvent = true;
              let scmPossibleForAlternateEvent = true;
              let lcmPossibleForAlternateEvent = true;
              if (!scyAlternateEventId) {
                scyPossibleForAlternateEvent = false;
              }
              if (!scmAlternateEventId) {
                scmPossibleForAlternateEvent = false;
              }
              if (!lcmAlternateEventId) {
                lcmPossibleForAlternateEvent = false;
              }
              setFormData({
                ...formState,
                showScy: showScy,
                showScm: showScm,
                showLcm: showLcm,
                eventId: selectedMeetEvent.eventId || Constants.DEFAULT_ID,
                meetEventId: selectedMeetEvent.meetEventId || Constants.DEFAULT_ID,
                meetAgeGroupId: selectedMeetAgeGroup.meetAgeGroupId || Constants.DEFAULT_ID,
                meetAgeGroupName: state.ageGroups.find(x => x.id === selectedMeetEvent.meetAgeGroupId)?.name || '',
                meetSessionId: selectedMeetEvent.meetSessionId || Constants.DEFAULT_ID,
                meetSessionName: state.sessions.find(x => x.id === selectedMeetEvent.meetSessionId)?.name || '',
                eventCompetitionGenderTypeId: selectedMeetEvent.eventCompetitionGenderTypeId || Constants.DEFAULT_ID,
                isRelay: isRelay ?? '',
                scyEventId: scyEventId,
                scyEventName: scyEventName,
                scmEventId: scmEventId,
                scmEventName: scmEventName,
                lcmEventId: lcmEventId,
                lcmEventName: lcmEventName,
                selectedEvent: selectedMeetEvent,
                offeredAsBonusEvent: selectedMeetEvent.offeredAsBonusEvent,

                alternateEventId: location.state?.alternateEventId || Constants.DEFAULT_ID,
                alternateEventName: eventState.filteredItems?.find(x => x.id === location.state?.alternateEventId)?.name || '',

                alternateMeetEventId: location.state?.alternateMeetEventId || Constants.DEFAULT_ID,
                isAlternateRelay: isAlternateRelay ?? '',
                scyPossibleForAlternateEvent: scyPossibleForAlternateEvent,
                scmPossibleForAlternateEvent: scmPossibleForAlternateEvent,
                lcmPossibleForAlternateEvent: lcmPossibleForAlternateEvent,
                scyAlternateEventId: scyAlternateEventId,
                scyAlternateEventName: scyAlternateEventName,
                scmAlternateEventId: scmAlternateEventId,
                scmAlternateEventName: scmAlternateEventName,
                lcmAlternateEventId: lcmAlternateEventId,
                lcmAlternateEventName: lcmAlternateEventName,

                hasNoSlowerThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards ?? '',
                hasNoFasterThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards ?? '',
                hasBonusTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasBonusTimeStandards ?? '',

                noSlowerThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
                noFasterThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
                bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',

                alternateEventHasNoSlowerThanTimeStandard: selectedMeetEvent.meetEventQualifyingTime?.length > 0 && selectedMeetEvent.meetEventQualifyingTime.filter(x => (x.eventId === scyAlternateEventId || x.eventId === scmAlternateEventId || x.eventId === lcmAlternateEventId) && formatTimeForDisplay(x.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0 ? true : false,
                alternateEventHasNoFasterThanTimeStandard: selectedMeetEvent.meetEventQualificationLimit?.length > 0 && selectedMeetEvent.meetEventQualificationLimit.filter(x => (x.eventId === scyAlternateEventId || x.eventId === scmAlternateEventId || x.eventId === lcmAlternateEventId) && formatTimeForFilterObject(x.limitTime) !== Constants.MIN_SWIM_TIME_STRING).length > 0 ? true : false,
                alternateEventHasBonusTimeStandard: selectedMeetEvent.meetEventBonusTime?.length > 0 && selectedMeetEvent.meetEventBonusTime.filter(x => (x.eventId === scyAlternateEventId || x.eventId === scmAlternateEventId || x.eventId === lcmAlternateEventId) && formatTimeForDisplay(x.bonusTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0 ? true : false,

                scyQualifyingTime: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.qualifyingTime) : '',
                scyQualifyingTimeValue: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.qualifyingTime : '',

                scyLimitTime: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.limitTime) : '',
                scyLimitTimeValue: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.limitTime : '',

                scyBonusTime: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.bonusTime) : '',
                scyBonusTimeValue: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyAlternateEventId)?.bonusTime : '',

                scmQualifyingTime: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.qualifyingTime) : '',
                scmQualifyingTimeValue: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.qualifyingTime : '',

                scmLimitTime: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.limitTime) : '',
                scmLimitTimeValue: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.limitTime : '',

                scmBonusTime: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.bonusTime) : '',
                scmBonusTimeValue: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmAlternateEventId)?.bonusTime : '',

                lcmQualifyingTime: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.qualifyingTime) : '',
                lcmQualifyingTimeValue: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.qualifyingTime : '',

                lcmLimitTime: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.limitTime) : '',
                lcmLimitTimeValue: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.limitTime : '',

                lcmBonusTime: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.bonusTime) : '',
                lcmBonusTimeValue: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmAlternateEventId)?.bonusTime : ''
              });
            }
            //Add
            else {
              setFormData({
                ...formState,
                showScy: showScy,
                showScm: showScm,
                showLcm: showLcm,
                eventId: selectedMeetEvent.eventId || Constants.DEFAULT_ID,
                meetEventId: selectedMeetEvent.meetEventId || Constants.DEFAULT_ID,
                meetAgeGroupId: selectedMeetAgeGroup.meetAgeGroupId || Constants.DEFAULT_ID,
                meetAgeGroupName: state.ageGroups.find(x => x.id === selectedMeetEvent.meetAgeGroupId)?.name || '',
                meetSessionId: selectedMeetEvent.meetSessionId || Constants.DEFAULT_ID,
                meetSessionName: state.sessions.find(x => x.id === selectedMeetEvent.meetSessionId)?.name || '',
                eventCompetitionGenderTypeId: selectedMeetEvent.eventCompetitionGenderTypeId || Constants.DEFAULT_ID,
                isRelay: isRelay ?? '',
                scyEventId: scyEventId,
                scyEventName: scyEventName,
                scmEventId: scmEventId,
                scmEventName: scmEventName,
                lcmEventId: lcmEventId,
                lcmEventName: lcmEventName,
                selectedEvent: selectedMeetEvent,
                offeredAsBonusEvent: selectedMeetEvent.offeredAsBonusEvent,

                hasNoSlowerThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards ?? '',
                hasNoFasterThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards ?? '',
                hasBonusTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasBonusTimeStandards ?? ''
              });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, state.isLoaded, eventState]);

  useEffect(() => {
    if (formState.meetAgeGroupId > 0) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState.meetAgeGroupId);
      if (Object.keys(selectedMeetAgeGroup).length > 0) {
        setFormState({
          ...formState,
          noSlowerThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          noFasterThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard'
        });
        let timeStandardAgeGroupIds = [];
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          timeStandardAgeGroupIds.push(selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup.timeStandardAgeGroupId);
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          timeStandardAgeGroupIds.push(selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup.timeStandardAgeGroupId);
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          timeStandardAgeGroupIds.push(selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup.timeStandardAgeGroupId);
        }
        if (timeStandardAgeGroupIds.length > 0) {
          let timeStandardAgeGroupIdsString = timeStandardAgeGroupIds.toString();
          getTimeStandardAgeGroupEventStandard(timeStandardAgeGroupIdsString);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.meetAgeGroupId]);

  useEffect(() => {
    if (timeStandardAgeGroupState.isArrayLoading === false && timeStandardAgeGroupState.isArrayLoaded === true &&
      formState.meetAgeGroupId > 0 && formState.alternateEventId > 0 && formState.eventCompetitionGenderTypeId > 0) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState.meetAgeGroupId);
      if (Object.keys(selectedMeetAgeGroup).length > 0) {
        let scyNoSlowerThanTimeStandardSwimTime = '';
        let scyNoFasterThanTimeStandardSwimTime = '';
        let scyBonusTimeStandardSwimTime = '';
        let scmNoSlowerThanTimeStandardSwimTime = '';
        let scmNoFasterThanTimeStandardSwimTime = '';
        let scmBonusTimeStandardSwimTime = '';
        let lcmNoSlowerThanTimeStandardSwimTime = '';
        let lcmNoFasterThanTimeStandardSwimTime = '';
        let lcmBonusTimeStandardSwimTime = '';
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
            if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
              selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId) {
              for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scyNoSlowerThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scmNoSlowerThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  lcmNoSlowerThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
              }
            }
          }
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
            if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
              selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId) {
              for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scyNoFasterThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scmNoFasterThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  lcmNoFasterThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
              }
            }
          }
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
            if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
              selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId) {
              for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scyBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmAlternateEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  lcmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
              }
            }
          }
        }

        setFormState({
          ...formState,
          noSlowerThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          noFasterThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',

          scyNoSlowerThanTimeStandardSwimTime: scyNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoSlowerThanTimeStandardSwimTime) : '',
          scyNoFasterThanTimeStandardSwimTime: scyNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoFasterThanTimeStandardSwimTime) : '',
          scyBonusTimeStandardSwimTime: scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '',

          scmNoSlowerThanTimeStandardSwimTime: scmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoSlowerThanTimeStandardSwimTime) : '',
          scmNoFasterThanTimeStandardSwimTime: scmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoFasterThanTimeStandardSwimTime) : '',
          scmBonusTimeStandardSwimTime: scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '',

          lcmNoSlowerThanTimeStandardSwimTime: lcmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoSlowerThanTimeStandardSwimTime) : '',
          lcmNoFasterThanTimeStandardSwimTime: lcmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoFasterThanTimeStandardSwimTime) : '',
          lcmBonusTimeStandardSwimTime: lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '',

          alternateEventHasNoSlowerThanTimeStandard: formState.alternateEventHasNoSlowerThanTimeStandard === '' ? (((formState.alternateMeetEventId < 0 && (scyNoSlowerThanTimeStandardSwimTime !== '' || scmNoSlowerThanTimeStandardSwimTime !== '' || lcmNoSlowerThanTimeStandardSwimTime !== '')) || (formState.scyQualifyingTime !== '' || formState.scmQualifyingTime !== '' || formState.lcmQualifyingTime !== '')) ? true : false) : formState.alternateEventHasNoSlowerThanTimeStandard,
          alternateEventHasNoFasterThanTimeStandard: formState.alternateEventHasNoFasterThanTimeStandard === '' ? (((formState.alternateMeetEventId < 0 && (scyNoFasterThanTimeStandardSwimTime !== '' || scmNoFasterThanTimeStandardSwimTime !== '' || lcmNoFasterThanTimeStandardSwimTime !== '')) || (formState.scyLimitTime !== '' || formState.scmLimitTime !== '' || formState.lcmLimitTime !== '')) ? true : false) : formState.alternateEventHasNoFasterThanTimeStandard,
          alternateEventHasBonusTimeStandard: formState.alternateEventHasBonusTimeStandard === '' ? (((formState.alternateMeetEventId < 0 && (scyBonusTimeStandardSwimTime !== '' || scmBonusTimeStandardSwimTime !== '' || lcmBonusTimeStandardSwimTime !== '')) || (formState.scyBonusTime !== '' || formState.scmBonusTime !== '' || formState.lcmBonusTime !== '')) ? true : false) : formState.alternateEventHasBonusTimeStandard,

          scyQualifyingTime: (formState.scyQualifyingTime === '' || state.updateTimeCuts === true) ? (scyNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoSlowerThanTimeStandardSwimTime) : '') : formState.scyQualifyingTime,
          scyQualifyingTimeValue: (formState.scyQualifyingTimeValue === '' || state.updateTimeCuts === true) ? scyNoSlowerThanTimeStandardSwimTime : formState.scyQualifyingTimeValue,
          scyLimitTime: (formState.scyLimitTime === '' || state.updateTimeCuts === true) ? (scyNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoFasterThanTimeStandardSwimTime) : '') : formState.scyLimitTime,
          scyLimitTimeValue: (formState.scyLimitTimeValue === '' || state.updateTimeCuts === true) ? scyNoFasterThanTimeStandardSwimTime : formState.scyLimitTimeValue,
          scyBonusTime: (formState.scyBonusTime === '' || state.updateTimeCuts === true) ? (scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '') : formState.scyBonusTime,
          scyBonusTimeValue: (formState.scyBonusTimeValue === '' || state.updateTimeCuts === true) ? scyBonusTimeStandardSwimTime : formState.scyBonusTimeValue,

          scmQualifyingTime: (formState.scmQualifyingTime === '' || state.updateTimeCuts === true) ? (scmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoSlowerThanTimeStandardSwimTime) : '') : formState.scmQualifyingTime,
          scmQualifyingTimeValue: (formState.scmQualifyingTimeValue === '' || state.updateTimeCuts === true) ? scmNoSlowerThanTimeStandardSwimTime : formState.scmQualifyingTimeValue,
          scmLimitTime: (formState.scmLimitTime === '' || state.updateTimeCuts === true) ? (scmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoFasterThanTimeStandardSwimTime) : '') : formState.scmLimitTime,
          scmLimitTimeValue: (formState.scmLimitTimeValue === '' || state.updateTimeCuts === true) ? scmNoFasterThanTimeStandardSwimTime : formState.scmLimitTimeValue,
          scmBonusTime: (formState.scmBonusTime === '' || state.updateTimeCuts === true) ? (scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '') : formState.scmBonusTime,
          scmBonusTimeValue: (formState.scmBonusTimeValue === '' || state.updateTimeCuts === true) ? scmBonusTimeStandardSwimTime : formState.scmBonusTimeValue,

          lcmQualifyingTime: (formState.lcmQualifyingTime === '' || state.updateTimeCuts === true) ? (lcmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoSlowerThanTimeStandardSwimTime) : '') : formState.lcmQualifyingTime,
          lcmQualifyingTimeValue: (formState.lcmQualifyingTimeValue === '' || state.updateTimeCuts === true) ? lcmNoSlowerThanTimeStandardSwimTime : formState.lcmQualifyingTimeValue,
          lcmLimitTime: (formState.lcmLimitTime === '' || state.updateTimeCuts === true) ? (lcmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoFasterThanTimeStandardSwimTime) : '') : formState.lcmLimitTime,
          lcmLimitTimeValue: (formState.lcmLimitTimeValue === '' || state.updateTimeCuts === true) ? lcmNoFasterThanTimeStandardSwimTime : formState.lcmLimitTimeValue,
          lcmBonusTime: (formState.lcmBonusTime === '' || state.updateTimeCuts === true) ? (lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '') : formState.lcmBonusTime,
          lcmBonusTimeValue: (formState.lcmBonusTimeValue === '' || state.updateTimeCuts === true) ? lcmBonusTimeStandardSwimTime : formState.lcmBonusTimeValue,

          handleRelayEvents: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardAgeGroupState, formState.meetAgeGroupId, formState.alternateEventId, formState.eventCompetitionGenderTypeId]);

  useEffect(() => {
    //Handle removing bonus time standard info for relay events
    if (formState.handleRelayEvents === true) {
      if (formState.alternateEventId !== Constants.DEFAULT_ID && formState.isAlternateRelay === true) {
        setFormState({
          ...formState,
          alternateEventHasBonusTimeStandard: '',
          scyBonusTime: '',
          scyBonusTimeValue: '',
          scmBonusTime: '',
          scmBonusTimeValue: '',
          lcmBonusTime: '',
          lcmBonusTimeValue: '',
          handleRelayEvents: false
        });

        if (state.updateTimeCuts === true) {
          setState({ ...state, updateTimeCuts: false });
        }
      }
      else if (formState.alternateEventId !== Constants.DEFAULT_ID && formState.isAlternateRelay === false) {
        const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
          x.meetAgeGroupId === formState.meetAgeGroupId);
        if (selectedMeetAgeGroup && Object.keys(selectedMeetAgeGroup).length > 0) {
          let scyBonusTimeStandardSwimTime = '';
          let scmBonusTimeStandardSwimTime = '';
          let lcmBonusTimeStandardSwimTime = '';
          if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId &&
            selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
            for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
              if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
                selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId) {
                for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                  if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyAlternateEventId &&
                    timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                    scyBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                  }
                  if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmAlternateEventId &&
                    timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                    scmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                  }
                  if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmAlternateEventId &&
                    timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                    lcmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                  }
                }
              }
            }
          }
          setFormState({
            ...formState,
            bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
            scyBonusTimeStandardSwimTime: scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '',
            scmBonusTimeStandardSwimTime: scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '',
            lcmBonusTimeStandardSwimTime: lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '',
            alternateEventHasBonusTimeStandard: formState.alternateEventHasBonusTimeStandard === '' ? (((formState.alternateMeetEventId < 0 && (scyBonusTimeStandardSwimTime !== '' || scmBonusTimeStandardSwimTime !== '' || lcmBonusTimeStandardSwimTime !== '')) || (formState.scyBonusTime !== '' || formState.scmBonusTime !== '' || formState.lcmBonusTime !== '')) ? true : false) : formState.alternateEventHasBonusTimeStandard,
            scyBonusTime: (formState.scyBonusTime === '' || state.updateTimeCuts === true) ? (scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '') : formState.scyBonusTime,
            scyBonusTimeValue: (formState.scyBonusTimeValue === '' || state.updateTimeCuts === true) ? scyBonusTimeStandardSwimTime : formState.scyBonusTimeValue,
            scmBonusTime: (formState.scmBonusTime === '' || state.updateTimeCuts === true) ? (scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '') : formState.scmBonusTime,
            scmBonusTimeValue: (formState.scmBonusTimeValue === '' || state.updateTimeCuts === true) ? scmBonusTimeStandardSwimTime : formState.scmBonusTimeValue,
            lcmBonusTime: (formState.lcmBonusTime === '' || state.updateTimeCuts === true) ? (lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '') : formState.lcmBonusTime,
            lcmBonusTimeValue: (formState.lcmBonusTimeValue === '' || state.updateTimeCuts === true) ? lcmBonusTimeStandardSwimTime : formState.lcmBonusTimeValue,
            handleRelayEvents: false
          });

          if (state.updateTimeCuts === true) {
            setState({ ...state, updateTimeCuts: false });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.handleRelayEvents, formState.alternateEventId])

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS_DETAIL, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: formState.meetEventId } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS_DETAIL, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: formState.meetEventId } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS_DETAIL, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: formState.meetEventId } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS_DETAIL, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: formState.meetEventId } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state.tryRedirect, state.submitButton]);

  return {
    omeMeetState,
    eventCompetitionGenderState,
    timeStandardTypeState,
    timeStandardAgeGroupState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onChangeEvent,
    state,
    setState
  };
};

export default useOmeAlternateEventDetail;