import { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { formatDateTime, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetHostSmallGrid = ({ gridData, isLoading, meetState, onEnterOmeHostClicked, onView, onEdit, onDeleteOmeMeetClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((host, i) => {
          return (
            <div className={global.SmallTableRow} key={i}>
              <div className={global.SmallTableRowHead}>{host?.isReadOnly === true ? <span /> : <span className='hidden-xs'>{host?.orgUnitName || <span>&nbsp;</span>}</span>}
                {host?.omeMeetId > 0 &&
                  <span>
                    {host?.isReadOnly === true &&
                      <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onView(e, host)}>View</ActionIntraPageButton></div>}
                    {host?.hostDeletesCurrentlyAllowed === true &&
                      <button className={global.IconButton} type="button" onClick={(e) => onDeleteOmeMeetClicked(e, host)}><DeleteIcon /></button>}
                    {host?.hostChangesCurrentlyAllowed === true &&
                      <Fragment>
                        {host?.hostDeletesCurrentlyAllowed === true ?
                          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e)}><EditIcon /></button>
                          :
                          <button className={global.IconButton} type="button" onClick={(e) => onEdit(e)}><EditIcon /></button>}
                      </Fragment>}
                  </span>}{(host?.hostChangesCurrentlyAllowed === true || host?.hostDeletesCurrentlyAllowed === true) && <span>&nbsp;</span>}</div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  {host?.isReadOnly === true ?
                    <span>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Host</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.orgUnitName || ''}</div>
                    </span> :
                    <span>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Meet Host</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{host?.orgUnitName || ''}</div>
                    </span>
                  }
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Start Date</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.meetEntryStartDate ? formatDateTimeTwelveHourClock(host?.meetEntryStartDate) : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry End Date</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.meetEntryEndDate ? formatDateTimeTwelveHourClock(host?.meetEntryEndDate) : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Time Zone</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.timeZone || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>OME Meet Status</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.omeMeetId > 0 ? (host?.omeMeetStatus || '') : 'Not Created'}</div>
                </div>
              </div>
            </div>
          )
        }) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{meetState.objData?.hostOrgUnit?.orgUnitName || <span>&nbsp;</span>}</span>
              {meetState?.objData.startDate && (new Date(formatDateTime(meetState?.objData?.startDate)) > new Date()) ?
                <button className={global.IconButton} type="button" onClick={(e) => onEnterOmeHostClicked(e)}> <PlusIcon toolTipText='Create OME Meet' /> </button> : <Fragment />}&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Meet Host</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{meetState.objData?.hostOrgUnit?.orgUnitName || ''}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Start Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}><span>&nbsp;</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry End Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}><span>&nbsp;</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Time Zone</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}><span>&nbsp;</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>OME Meet Status</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>Not Created</div>
              </div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetHostSmallGrid;