import React, { Fragment } from 'react';

import useAthleteUserCoachesSelection from './UseAthleteUserCoachesSelection';

import AthleteUserCoachesSelectionGrid from './AthleteUserCoachesSelectionGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../../common/utils/Constants';

const AthleteUserCoachesSelection = () => {
  const { gridState, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState,
    onSaveClicked, onBackClicked, onCheckboxChange, onFilterClicked, onClearFilterClicked,
    onFormValueChanged, formState, errorState
  } = useAthleteUserCoachesSelection();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Coach Selection</Headings.H3>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
            <Headings.H5>Please provide a First or Preferred Name and a Last Name to search for a Coach</Headings.H5>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="First or Preferred Name*"
              name="firstOrPreferredName"
              value={formState.firstOrPreferredName}
              error={errorState.firstOrPreferredName}
              message={errorState.firstOrPreferredName}
              onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Last Name*"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Search</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </div>
      </form>
      {gridState.gridData.length > 15 && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoading === false &&
        <div className='visible-md visible-lg'>
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div>}
      {gridState.gridData.length > 0 && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoading === false &&
        <div className='hidden-md hidden-lg'>
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div>}
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <AthleteUserCoachesSelectionGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoading}
            onCheckboxChange={onCheckboxChange}
          />
        </div>
      </div>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isSaving} />
    </Fragment >
  );
};

export default AthleteUserCoachesSelection;