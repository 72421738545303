import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OrgUnitAdminContactsDetailValidation'

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryContactData from '../../../../state/omeMeetOrgUnitEntryContact/UseOmeMeetOrgUnitEntryContactData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';

import UseForm from "../../../../../common/utils/UseForm";

const useOrgUnitAdminContactsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState, getOmeMeetOrgUnitEntryById } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitEntryContactState, postOmeMeetOrgUnitEntryContact, putOmeMeetOrgUnitEntryContact, confirmSaveOmeMeetOrgUnitEntryContact } = useOmeMeetOrgUnitEntryContactData();
  const { formState, errorState, setFormData, handleSubmit, onFormValueChanged, setIsDirty
  } = UseForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState({ tryGetOmeMeet: false, tryRedirect: false });

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    handleSubmit();
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_CONTACTS);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_CONTACTS);
    }
  };

  useEffect(() => {
    if (location.state) {
      setFormData({
        ...formState,
        omeMeetOrgUnitEntryContactId: location.state?.omeMeetOrgUnitEntryContactId || '',
        personId: location.state?.personId || '',
        memberId: location.state?.memberId || '',
        emailAddress: location.state?.emailAddress || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.tryGetOmeMeet === true && omeMeetOrgUnitEntryContactState.isSaving === false &&
      omeMeetOrgUnitEntryContactState.isSaved === true) {
      confirmSaveOmeMeetOrgUnitEntryContact();
      getOmeMeetOrgUnitEntryById(omeMeetOrgUnitEntryState.objData?.omeMeetId, omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId);
      setState({ ...state, tryGetOmeMeet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGetOmeMeet, omeMeetOrgUnitEntryContactState])

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetOrgUnitEntryState.isObjLoading === false &&
      omeMeetOrgUnitEntryState.isObjLoaded === true) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.OU_ADMIN_CONTACTS, { state: { tryGet: true } });
      }
      else {
        //Meet Route
        navigate(NavLinks.OU_ADMIN_CONTACTS, { state: { tryGet: true } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, omeMeetOrgUnitEntryState])

  function submitFormCallback(formState) {
    if (formState.omeMeetOrgUnitEntryContactId) {
      const omeMeetOrgUnitEntryContactObj = {
        omeMeetOrgUnitEntryContactId: formState.omeMeetOrgUnitEntryContactId,
        omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId,
        personId: formState.personId,
        emailAddress: formState.emailAddress.trim(),
      };
      putOmeMeetOrgUnitEntryContact(formState.omeMeetOrgUnitEntryContactId, omeMeetOrgUnitEntryContactObj);
    }
    else {
      const omeMeetOrgUnitEntryContactObj = {
        omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId,
        personId: formState.personId,
        emailAddress: formState.emailAddress.trim(),
      };
      postOmeMeetOrgUnitEntryContact(omeMeetOrgUnitEntryContactObj);
    }
    setState({ ...state, tryGetOmeMeet: true });
  };

  function getInitialFormState() {
    return ({
      omeMeetOrgUnitEntryContactId: '',
      personId: '',
      memberId: '',
      emailAddress: ''
    })
  };

  return {
    omeMeetOrgUnitEntryState,
    omeMeetOrgUnitEntryContactState,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onSaveButtonClicked,
    onBackButtonClicked
  };
};

export default useOrgUnitAdminContactsDetail;