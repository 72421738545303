import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  const minSwimmers = parseInt(formState.minSwimmers);
  const maxSwimmers = parseInt(formState.maxSwimmers);
  let errors = {};

  if (formState.minSwimmers === '') {
    errors.minSwimmers = 'Min Number of Athletes is required';
  } else if (!isValidNumber(formState.minSwimmers) || ToIntIfInt(formState.minSwimmers) > 2000) {
    errors.minSwimmers = 'Min Number of Athletes must be between 0 and 2,000';
  } else if (minSwimmers > maxSwimmers) {
    errors.minSwimmers = 'Min Number of Athletes cannot be greater than Max Number of Athletes';
  }

  if (formState.maxSwimmers === '') {
    errors.maxSwimmers = 'Max Number of Athletes is required';
  } else if (!isValidNumber(formState.maxSwimmers) || ToIntIfInt(formState.maxSwimmers) > 2000) {
    errors.maxSwimmers = 'Max Number of Athletes must be between 0 and 2,000';
  } else if (maxSwimmers < minSwimmers) {
    errors.maxSwimmers = 'Max Number of Athletes cannot be less than Min Number of Athletes';
  }

  if (formState.coachPasses === '') {
    errors.coachPasses = 'Allowed Number of Coach Passes is required';
  } else if (!isValidNumber(formState.coachPasses) || ToIntIfInt(formState.coachPasses) > 20) {
    errors.coachPasses = 'Allowed Number of Coach Passes must be between 0 and 20';
  }

  return errors;
};

const OmeEligibilityCoachPassesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OmeEligibilityCoachPassesValidation;