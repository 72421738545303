import React, { Fragment } from 'react';

import LaneSheetMeetTopHeaderNotesLargeGrid from './LaneSheetMeetTopHeaderNotesLargeGrid';
import LaneSheetMeetTopHeaderNotesSmallGrid from './LaneSheetMeetTopHeaderNotesSmallGrid';

const LaneSheetMeetTopHeaderNotesGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <Fragment>
    <LaneSheetMeetTopHeaderNotesLargeGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
    <LaneSheetMeetTopHeaderNotesSmallGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default LaneSheetMeetTopHeaderNotesGrid;