import React, { Fragment } from 'react';

import OrgUnitAdminPurchasesLargeGrid from './OrgUnitAdminPurchasesLargeGrid';
import OrgUnitAdminPurchasesSmallGrid from './OrgUnitAdminPurchasesSmallGrid';

const OrgUnitAdminPurchasesGrid = ({ gridData, isLoading }) => {
  return (
    <Fragment>
      <OrgUnitAdminPurchasesLargeGrid
        gridData={gridData}
        isLoading={isLoading}
      />
      <OrgUnitAdminPurchasesSmallGrid
        gridData={gridData}
        isLoading={isLoading}
      />
    </Fragment>
  )
};

export default OrgUnitAdminPurchasesGrid;