import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeDetailTableRow = ({ relayTeam }) => {
  return (
    <tr>
      <td>{relayTeam.relayTeamName || ''}</td>
      <td>{relayTeam.entryTime ? formatTimeForDisplay(relayTeam.entryTime) : 'NT'} {relayTeam.entryTime && relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : ''}</td>
      <td>{relayTeam.alternateEvent === true ? `ALT: ${relayTeam.qualifyingEventName || ''}` : relayTeam.nonConformingTime === true ? `NC: ${relayTeam.qualifyingEventName || ''}` : `${relayTeam.qualifyingEventName || ''}`}</td>
      <td>{relayTeam.isAggregate === true ? 'Yes' : 'No'}</td>
      <td>{relayTeam?.swimTimeEntryOverride === true ? 'Yes' : 'No'}</td>
      <td>{relayTeam.status || ''}</td>
      <td></td>
    </tr>
  )
}

const LargeDetailTable = ({ event }) => {
  return (
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th>Team</th>
          <th>Entry Time</th>
          <th>Time Event</th>
          <th>Aggregate?</th>
          <th>Override?</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(event.relayTeams) && event.relayTeams.length > 0 &&
          event.relayTeams.map((relayTeam, i) =>
            <LargeDetailTableRow
              relayTeam={relayTeam}
              key={i}
            />)}
      </tbody>
    </table>
  );
};

const GridRow = ({ event, onEditEventClicked, expandedId, onClick }) => {
  return (
    <Fragment key={event.meetEventId + 'Fragment'}>
      <tr key={event.meetEventId}>
        <td>{event.eventName || ''}</td>
        <td>{event.eventCompetitionGenderTypeName || ''}</td>
        <td>{event.ageGroupName || ''}</td>
        <td>{event.meetSessionName || ''}</td>
        <td>{onEditEventClicked && <button className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditEventClicked(e, event)}>
          <EditIcon />
        </button>}
          {Array.isArray(event.relayTeams) && event.relayTeams.length > 0 &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onClick(e, event.meetEventId)}>
              {expandedId === event.meetEventId ? <HideIcon /> : <ShowIcon />}
            </button>}
        </td>
      </tr>
      {expandedId === event.meetEventId && Array.isArray(event.relayTeams) && event.relayTeams.length > 0 &&
        <tr className={global.Expanded}>
          <td colSpan="5">
            <LargeDetailTable event={event} />
          </td>
        </tr>}
    </Fragment>
  );
};

const OrgUnitAdminRelayEntriesLargeGrid = ({ gridData, isLoading, onEditEventClicked, onClick, expandedId }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event - Course</th>
        <th>Competition Category</th>
        <th>Age Group</th>
        <th>Session</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((event, i) =>
            <GridRow
              key={i}
              event={event}
              onEditEventClicked={onEditEventClicked}
              onClick={onClick}
              expandedId={expandedId}
            />)
          :
          <tr>
            <td colSpan={5}>No Relay Events</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminRelayEntriesLargeGrid;