import { useContext } from 'react';

import { MyAthleteMeetEntriesFiltersStateContext } from './MyAthleteMeetEntriesFiltersContextProvider';

const useMyAthleteMeetEntriesFiltersData = () => {
  const [myAthleteMeetEntriesFiltersState, setMyAthleteMeetEntriesFiltersState] = useContext(MyAthleteMeetEntriesFiltersStateContext);

  const resetMyAthleteMeetEntriesFiltersState = () => {
    setMyAthleteMeetEntriesFiltersState({
      omeMeetAthleteEntryFiltersState: {}
    });
  };

  return {
    myAthleteMeetEntriesFiltersState,
    setMyAthleteMeetEntriesFiltersState,
    resetMyAthleteMeetEntriesFiltersState
  };
};

export default useMyAthleteMeetEntriesFiltersData;