import React, { Fragment } from 'react';

import useSelectionsLeftNav from './UseSelectionsLeftNav';

import LeftNav from '../../../../../common/components/leftNav/LeftNav';
import Headings from '../../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../../common/components/icons/LeftArrowIcon';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './SelectionsLeftNav.module.css';

const LeftNavigationTitle = ({ objData }) => (
  <div className={style.RegFor}>
    <Headings.H5>Organization:</Headings.H5>
    <Headings.H6>{objData.meetHost || ''}</Headings.H6>
    <Headings.H5>Meet Name:</Headings.H5>
    <Headings.H6>{objData.meetName || ''}</Headings.H6>
    <Headings.H5>Meet Dates:</Headings.H5>
    <Headings.H6>{objData.meetStartDate ? formatDate(objData.meetStartDate) : ''} - {objData.meetEndDate ? formatDate(objData.meetEndDate) : ''}</Headings.H6>
    <Headings.H5>Gender:</Headings.H5>
    <Headings.H6>{objData.competitionGenderTypeName || ''}</Headings.H6>
  </div>
);

const SelectionsLeftNav = ({ viewName, viewTitle, children }) => {
  const {
    selectionsLeftNavState,
    onBackToSearchClicked
  } = useSelectionsLeftNav(viewName);

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>Meet Participant Selection</Headings.H2>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {selectionsLeftNavState.isLoaded === true ? (
        <LeftNav
          formattedTitle={<LeftNavigationTitle objData={selectionsLeftNavState.objData || {}} />}
          state={selectionsLeftNavState.arrayData || []}
          validateBeforeRedirect={false}>
          <div className='row usas-extra-top-margin'>
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackToSearchClicked}>
                <LeftArrowIcon />  {'Back To Meet Participant Selection Search'}
              </button>
            </div>
          </div>
          {viewTitle &&
            <div className='row usas-extra-top-margin'>
              <div className='col-xs-12'>
                <Headings.H3 excludeBar={true}>{viewTitle}</Headings.H3>
              </div>
            </div>}
          <div className='row usas-extra-top-margin'>
            <div className='col-xs-12'>
              {children}
            </div>
          </div>
        </LeftNav>
      ) : (
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={true} />
      )}
    </Fragment>
  );
};

export default SelectionsLeftNav;