import { useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../utils/NavLinks";

import useSelectionsData from "../../../../state/selections/UseSelectionsData";
import useSelectionsLeftNavData from "../../../../state/selectionsLeftNav/UseSelectionsLeftNavData";
import useSelectionsParticipantsData from "../../../../state/selectionsParticipants/UseSelectionsParticipantsData";
import useSelectionsLoadingModalData from "../../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

const useSelectionsLeftNav = (viewName) => {
  const navigate = useNavigate();
  const { selectionsLeftNavState, refreshLeftNavState, updateCurrentLeftNavData } = useSelectionsLeftNavData();
  const { selectionsState } = useSelectionsData();
  const { getSelectionParticipants } = useSelectionsParticipantsData();
  const { setContextStateError } = useSelectionsLoadingModalData();

  const onBackToSearchClicked = () => {
    navigate(NavLinks.SELECTIONS_NEW_SELECTION_SEARCH);
  };

  useEffect(() => {
    if (selectionsState.isObjLoaded === true) {
      if (selectionsLeftNavState.isLoaded !== true) {
        if (selectionsState.objData.isConfigComplete === true) {
          const selectionMeetId = selectionsState.objData.selectionMeetId;

          const getSelectionParticipantsPromise = getSelectionParticipants(selectionMeetId);

          if (getSelectionParticipantsPromise !== null) {
            getSelectionParticipantsPromise.then((newState) => {
              if (newState !== null) {
                const selectionsObj = {
                  ...selectionsState.objData,
                  ...newState.objData,
                };
                refreshLeftNavState(viewName, selectionsObj);
              }
            }).catch((e) => {
              const selectionsObj = {
                ...selectionsState.objData,
              };

              refreshLeftNavState(viewName, selectionsObj);

              setContextStateError(true);
            });
          }
        } else {
          const selectionsObj = {
            ...selectionsState.objData,
          };

          refreshLeftNavState(viewName, selectionsObj);
        }
      } else {
        updateCurrentLeftNavData(viewName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsLeftNavState.isLoaded, selectionsState.isObjLoaded]);

  return {
    selectionsLeftNavState,
    onBackToSearchClicked
  };
};

export default useSelectionsLeftNav;