import { useEffect, useState } from 'react';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';

const INITIAL_VIEW_STATE = {
  reportParameters: { omeMeetOrgUnitEntryId: '' },
  contextId: '',
  routeName: ''
};

const useOrgUnitAdminReporting = () => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId && omeMeetState.objData.meetId) {
      setViewState({
        ...viewState,
        reportParameters: { omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId },
        contextId: omeMeetState.objData.meetId,
        routeName: 'MEET_ENTRY_TEAM_OUA_TEAMENTRYREPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetState]);

  return { ...viewState };
};

export default useOrgUnitAdminReporting;