import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useOmeMeetData from '../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useMeetCompetitionSummaryData from '../../state/meetCompetitionSummary/UseMeeetCompetitionSummaryData';
import useOmeMeetInvitationsData from '../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

import useMeetData from '../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../common/state/searchMeet/UseSearchMeetData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useOmeMeetAthleteEntryData from '../../state/omeMeetAthleteEntry/UseOmeMeetAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetTeamEntryData from '../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useMeetStaffData from '../../../common/state/meet/meetStaff/UseMeetStaffData';

const BACK_TO_LABELS = {
  meetBackToLabel: 'Back to Meet Search',
};

const useMeetContextView = () => {
  const navigate = useNavigate();
  const { meetState, meetFiltersState, clearObjData } = useMeetData();
  const { meetCompetitionSummaryState, clearMeetCompetitionSummaryObjData } = useMeetCompetitionSummaryData();
  const { clearOmeMeetObjData, clearOmeMeetArrayData } = useOmeMeetData();
  const { clearOmeMeetInvitationsArrayData } = useOmeMeetInvitationsData();
  const { searchMeetState } = useSearchMeetData();
  const { clearOmeMeetOrgUnitEntryObjData, clearOmeMeetOrgUnitEntryArrayData } = useOmeMeetOrgUnitEntryData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { clearOmeMeetAthleteEntryArrayData } = useOmeMeetAthleteEntryData();
  const { resetOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
  const { resetMeetStaffState } = useMeetStaffData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_INFO);
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    switch ((meetFiltersState.backToSystem || '').toLowerCase()) {
      default:
        goToMeetSearch();
        break;
    }
  };

  function goToMeetSearch() {
    clearObjData();
    clearOmeMeetObjData();
    clearOmeMeetInvitationsArrayData();
    clearOmeMeetOrgUnitEntryObjData();
    clearOmeMeetOrgUnitEntryArrayData();
    clearOmeMeetArrayData();
    clearOmeMeetTeamEntryArrayData();
    clearArrayData();
    clearOmeMeetAthleteEntryArrayData();
    resetOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
    resetMeetStaffState();
    clearMeetCompetitionSummaryObjData();

      navigate(NavLinks.MEET_SEARCH);
  }

  function getBackToLabel() {
    return BACK_TO_LABELS[`${(meetFiltersState.backToSystem || '').toLowerCase()}BackToLabel`] || BACK_TO_LABELS.meetBackToLabel;
  }

  return {
    meetState,
    searchMeetState,
    meetCompetitionSummaryState,
    backToLabel: getBackToLabel(),
    onBackClicked,
    onHomeClicked
  };
};

export default useMeetContextView;