
import React, { Fragment } from 'react';

import useLaneSheetMeetEventsAddParticipants from './UseLaneSheetMeetEventsAddParticipants';

import LaneSheetMeetEventAddParticipantsGrid from '../../components/grids/LaneSheetMeetEventAddParticipantsGrid';
import LaneSheetAddAthleteForm from '../../components/forms/LaneSheetAddAthleteForm';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

//TODO previous viewed athletes briefly displaying on revisit
const LaneSheetMeetEventsAddParticipants = () => {
  const {
    isSaving,
    isLoading,
    meetEvent,
    gridData,
    deleteModalState,
    onBackClicked,
    onSubmitFormCallback,
    onDeleteAthleteClicked,
    onDeleteModalCancelClicked,
    onDeleteModalDeleteClicked
  } = useLaneSheetMeetEventsAddParticipants();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Participants To Lane Sheet Meet</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <label className={global.UsasLabel}><b>Event Name:</b> {meetEvent.eventName || ''}</label>
          <label className={global.UsasLabel}><b>Competition Category:</b> {meetEvent.eventCompetitionGenderTypeName || ''}</label>
        </div>
      </div>
      <LaneSheetAddAthleteForm
        onSubmitFormCallback={onSubmitFormCallback}
        existingAthletes={gridData} />
      <LaneSheetMeetEventAddParticipantsGrid
        isLoading={isLoading}
        gridData={gridData}
        onDeleteClicked={onDeleteAthleteClicked} />
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Athlete?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className='col-xs-12'><strong>Full Name: </strong>{deleteModalState.athlete?.firstName} {deleteModalState.athlete?.middleName || ''} {deleteModalState.athlete?.lastName || ''}</p>
            <p className='col-xs-12'><strong>Member Id: </strong>{deleteModalState.athlete?.memberId || ''}</p>
            <p className='col-xs-12'><strong>Birth Date: </strong>{deleteModalState.athlete?.birthDate ? formatDate(deleteModalState.athlete.birthDate) : ''}</p>
            <p className='col-xs-12'>Are you sure you want to delete this athlete?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LaneSheetMeetEventsAddParticipants;