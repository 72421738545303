import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisuallyHidden } from '@reach/visually-hidden';

import useSelectionActions from './UseSelectionActions';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './SelectionActions.module.css';

const Li = ({ action, onActionClicked }) => (
  <li tabIndex="-1" onClick={() => { onActionClicked(action.shortActionName) }}>
    <span className={style.Action}>
      {action.rowNumber}.
      <VisuallyHidden>{action.actionName}</VisuallyHidden>
      <FontAwesomeIcon icon={faSquare} style={{ color: `${action.displayColor}` }} />
      {` ${action.actionDescription}`}
    </span>
  </li>
);

const SelectionActions = ({ errorState, onCloseClicked, onCompleteClicked }) => {
  const {
    actions,
    onActionClicked
  } = useSelectionActions();

  return (
    <div className="row">
      <div className="col-xs-12">
        <ul tabIndex="0" className={style.ActionTable}>
          {actions.map((action, i) => <Li key={i} action={action} onActionClicked={onActionClicked} />)}
        </ul>
      </div>
      {errorState.displayCompletionError === true &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.completionErrorMessage}</p>
          </div>
        </div>
      }
      <div className="usas-extra-top-margin usas-extra-bottom-margin pull-right">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onCloseClicked}>Close</SecondaryButton>&nbsp;
          <PrimaryButton type="button" onClick={onCompleteClicked}>Complete</PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SelectionActions;