import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.relayTeamId < 0) {
    errors.relayTeamId = 'Relay Team Name is required';
  }
  if (formState.isAggregate === true) {

    if (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '') {
      errors.eventId = 'Event is required';
    }

    if (formState.athlete1PersonId === Constants.DEFAULT_ID) {
      errors.athlete1PersonId = 'A valid selection is required';
    }

    if (formState.athlete1PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete1PersonId === formState.athlete2PersonId) {
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete1PersonId === formState.athlete3PersonId) {
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete1PersonId === formState.athlete4PersonId) {
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete2PersonId === Constants.DEFAULT_ID) {
      errors.athlete2PersonId = 'A valid selection is required';
    }

    if (formState.athlete2PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete2PersonId === formState.athlete1PersonId) {
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete2PersonId === formState.athlete3PersonId) {
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete2PersonId === formState.athlete4PersonId) {
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete3PersonId === Constants.DEFAULT_ID) {
      errors.athlete3PersonId = 'A valid selection is required';
    }

    if (formState.athlete3PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete3PersonId === formState.athlete1PersonId) {
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete3PersonId === formState.athlete2PersonId) {
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete3PersonId === formState.athlete4PersonId) {
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete4PersonId === Constants.DEFAULT_ID) {
      errors.athlete4PersonId = 'A valid selection is required';
    }

    if (formState.athlete3PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete4PersonId === formState.athlete1PersonId) {
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete4PersonId === formState.athlete2PersonId) {
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete4PersonId === formState.athlete3PersonId) {
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
      }
    }

  }

  return errors;
};

const OrgUnitAdminRelayEntriesDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRelayEntriesDetailValidation;