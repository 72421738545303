import { useContext } from 'react';

import { OrgUnitOmeMeetStateContext } from './OrgUnitOmeMeetContextProvider';
import OrgUnitOmeMeetData from './OrgUnitOmeMeetData';

const useOrgUnitOmeMeetData = () => {
  const [orgUnitOmeMeetState, setOrgUnitOmeMeetState] = useContext(OrgUnitOmeMeetStateContext);

  const getOrgUnitsByOmeMeetId = (omeMeetId) => OrgUnitOmeMeetData.getOrgUnitsByOmeMeetIdData(omeMeetId, orgUnitOmeMeetState, setOrgUnitOmeMeetState);

  return {
    orgUnitOmeMeetState,
    getOrgUnitsByOmeMeetId
  };
};

export default useOrgUnitOmeMeetData;