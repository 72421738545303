import useLaneSheetHeatAddForm from "./UseLaneSheetHeatAddForm";

import LaneSheetEventMultiSelect from "../../../../components/multiSelect/laneSheetEventMultiSelect/LaneSheetEventMultiSelect";
import DateRangeDropdown from "../../../../components/dropdowns/DateRangeDropdown";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SessionDropdown from "../../../../../common/components/dropdowns/sessionDropdown/SessionDropdown";
import Input from "../../../../../common/components/inputs/Input";

const LaneSheetHeatAddForm = ({ onSubmitFormCallback, laneSheetMeetId, meetStartDate, meetEndDate }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged
  } = useLaneSheetHeatAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row'>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <Input
            label="Heat*"
            name="heatNumber"
            value={formState.heatNumber}
            error={errorState.heatNumber}
            message={errorState.heatNumber}
            onChange={(value) => { onFormValueChanged('heatNumber', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <LaneSheetEventMultiSelect
            label="Event & Competition Category*"
            name="eventNameAndDistanceId"
            value={formState.eventNameAndDistanceId}
            error={errorState.eventNameAndDistanceId}
            message={errorState.eventNameAndDistanceId}
            laneSheetMeetId={laneSheetMeetId}
            onChange={(value) => { onFormValueChanged('eventNameAndDistanceId', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <SessionDropdown
            label="Session Type*"
            name="sessionTypeId"
            value={formState.sessionTypeId}
            error={errorState.sessionTypeId}
            message={errorState.sessionTypeId}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'sessionTypeId', newValueLabel, 'sessionTypeName') }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <DateRangeDropdown
            label="Heat Date*"
            name="heatDateId"
            value={formState.heatDateId}
            error={errorState.heatDateId}
            message={errorState.heatDateId}
            startDate={meetStartDate}
            endDate={meetEndDate}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'heatDateId', newValueLabel, 'heatDate'); }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Add</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetHeatAddForm;