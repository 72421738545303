import React, { createContext, useState } from 'react';

export const OmeMeetHostFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: ''
};

const OmeMeetHostFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <OmeMeetHostFiltersStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetHostFiltersStateContext.Provider>
  );
};

export default OmeMeetHostFiltersContextProvider;