import React, { createContext, useState } from 'react';

import SearchMeetData from './SearchMeetData';

export const SearchMeetStateContext = createContext();

const SearchMeetContextProvider = ({ children }) => {
  const stateHook = useState(SearchMeetData.INITIAL_STATE);

  return (
    <SearchMeetStateContext.Provider value={stateHook}>
      {children}
    </SearchMeetStateContext.Provider>
  );
};

export default SearchMeetContextProvider;