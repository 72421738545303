import React, { createContext, useState } from 'react';

import EventMeetEventBonusData from './EventMeetEventBonusData';

export const EventMeetEventBonusStateContext = createContext();

const EventMeetEventBonusContextProvider = ({ children }) => {
  const stateHook = useState(EventMeetEventBonusData.INITIAL_STATE);

  return (
    <EventMeetEventBonusStateContext.Provider value={stateHook}>
      {children}
    </EventMeetEventBonusStateContext.Provider>
  );
};

export default EventMeetEventBonusContextProvider;