import { useContext } from 'react';

import { OmeMeetTeamEntryStateContext } from './OmeMeetTeamEntryContextProvider';
import { OmeMeetTeamEntryFiltersStateContext } from './OmeMeetTeamEntryFiltersContextProvider';

import OmeMeetTeamEntryData from './OmeMeetTeamEntryData';

import Constants from '../../../common/utils/Constants';

const useOmeMeetTeamEntryData = () => {
  const [omeMeetTeamEntryState, setOmeMeetTeamEntryState] = useContext(OmeMeetTeamEntryStateContext);
  const [omeMeetTeamEntryFiltersState, setOmeMeetTeamEntryFiltersState] = useContext(OmeMeetTeamEntryFiltersStateContext);

  const searchMeetTeamEntry = (filterObject, sortBy) => {
    setOmeMeetTeamEntryFiltersState({ ...omeMeetTeamEntryFiltersState, filterObject, sortBy });
    OmeMeetTeamEntryData.searchMeetTeamEntryData(JSON.stringify(filterObject), sortBy, omeMeetTeamEntryState, setOmeMeetTeamEntryState);
  };

  const getOmeMeetTeamEntry = (omeMeetId) => {
    OmeMeetTeamEntryData.getOmeMeetTeamEntryData(omeMeetId, omeMeetTeamEntryState, setOmeMeetTeamEntryState);
  };

  const clearOmeMeetTeamEntryArrayData = () => {
    setOmeMeetTeamEntryState({
      ...omeMeetTeamEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      omeMeetId: Constants.DEFAULT_ID
    });
  };

  const resetOmeMeetTeamEntryFiltersState = () => {
    setOmeMeetTeamEntryFiltersState({
      filterObject: {},
      sortBy: ''
    });
  };

  return {
    omeMeetTeamEntryState,
    setOmeMeetTeamEntryState,
    omeMeetTeamEntryFiltersState,
    setOmeMeetTeamEntryFiltersState,
    searchMeetTeamEntry,
    getOmeMeetTeamEntry,
    clearOmeMeetTeamEntryArrayData,
    resetOmeMeetTeamEntryFiltersState
  };
};

export default useOmeMeetTeamEntryData;