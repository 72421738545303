import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmePricingValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';
import useOmeMeetInvitationsData from '../../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  omeMeetPriceId: Constants.DEFAULT_ID,
  gridData: []
};

const INITIAL_FORM_STATE = {
  costPerTeam: '',
  costPerCredential: '',
  costPerIndividualAthlete: '',
  costPerIndividualInternationalAthlete: '',
  costPerInternationalStaff: '',
  costPerRelayOnlySwimmer: '',
  costPerAthleteIndividualEventEntry: '',
  costPerAthleteIndividualBonusEventEntry: '',
  costPerRelayEventEntry: '',
  showCostPerIndividualInternationalAthlete: false,
  showCostPerInternationalStaff: false,
  showCostPerAthleteIndividualBonusEventEntry: false,
  showCostPerRelayOnlySwimmer: false,
  showCostPerRelayEventEntry: false
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Additional Purchase Item?',
  omeFeeTypeName: '',
  amount: '',
  description: '',
  omeMeetPriceId: Constants.DEFAULT_ID
};

const useOmePricing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetInvitationsState } = useOmeMeetInvitationsData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { orgUnitState, getOrgUnits } = useOrgUnitData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { FINA_ORG_UNIT_ID, TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID, INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID,
    INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID,
    ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID } = useEnvironmentVariableData();
  const { formState, errorState, onFormValueChanged, isSubmitting, handleSubmit, setIsDirty,
    isDirty, setFormData, setErrors } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async (submitButton, omeMeetPriceId) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({
        ...state, tryRedirect: true, submitButton: submitButton,
        omeMeetPriceId: omeMeetPriceId ? omeMeetPriceId : Constants.DEFAULT_ID
      });
    }
  };

  const tryValidateBeforeShowingDeletePopUp = async (purchaseItem) => {
    const errors = await validate(formState) || {};
    setErrors(errors);

    //Show popup if there are no form validation errors
    if (Object.keys(errors).length === 0) {
      setModalState({
        ...modalState,
        displayPopUp: true,
        omeFeeTypeName: purchaseItem.omeFeeType?.typeName || '',
        amount: '$' + purchaseItem.amount || '',
        description: purchaseItem.description || '',
        omeMeetPriceId: purchaseItem.omeMeetPriceId || Constants.DEFAULT_ID
      });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.BACK);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.BACK });
    }
  };

  const onAddAdditionalPurchaseItem = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.ADD);
  }

  const onEditAdditionalPurchaseItem = (e, omeMeetPriceId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.EDIT, omeMeetPriceId);
  }

  const onDeleteAdditionalPurchaseItemClicked = (e, purchaseItem) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeShowingDeletePopUp(purchaseItem);
  };

  const onDeleteAdditionalPurchaseItem = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    if (isDirty === true) {
      updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    }

    const selectedOmeMeetPriceIndex = updatedOmeMeet.omeMeetPrice.findIndex(x =>
      x.omeMeetPriceId === modalState.omeMeetPriceId);

    onModalCanceled();
    if (selectedOmeMeetPriceIndex > -1) {
      updatedOmeMeet.omeMeetPrice.splice(selectedOmeMeetPriceIndex, 1);
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const editOmeMeetCopy = (updatedOmeMeet) => {
    const updatedOmeMeetCopy = JSON.parse(JSON.stringify(updatedOmeMeet));
    if (updatedOmeMeet.omeMeetPrice.length > 0) {
      updatedOmeMeet.omeMeetPrice = updatedOmeMeet.omeMeetPrice.filter(x => x.omeFeeType?.isAdditionalPurchase === true);
    }

    updatedOmeMeet.omeMeetPrice = [
      ...updatedOmeMeet.omeMeetPrice,
      ...(formState.costPerTeam !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === TEAM_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: TEAM_OME_FEE_TYPE_ID, amount: formState.costPerTeam }] : []),
      ...(formState.costPerCredential !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === CREDENTIAL_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: CREDENTIAL_OME_FEE_TYPE_ID, amount: formState.costPerCredential }] : []),
      ...(formState.costPerIndividualAthlete !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, amount: formState.costPerIndividualAthlete }] : []),
      ...(formState.costPerIndividualInternationalAthlete !== '' && formState.showCostPerIndividualInternationalAthlete === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, amount: formState.costPerIndividualInternationalAthlete }] : []),
      ...(formState.costPerInternationalStaff !== '' && formState.showCostPerInternationalStaff === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: INTL_STAFF_OME_FEE_TYPE_ID, amount: formState.costPerInternationalStaff }] : []),
      ...(formState.costPerRelayOnlySwimmer !== '' && formState.showCostPerRelayOnlySwimmer === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID, amount: formState.costPerRelayOnlySwimmer }] : []),
      ...(formState.costPerAthleteIndividualEventEntry !== '' ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, amount: formState.costPerAthleteIndividualEventEntry }] : []),
      ...(formState.costPerAthleteIndividualBonusEventEntry !== '' && formState.showCostPerAthleteIndividualBonusEventEntry === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, amount: formState.costPerAthleteIndividualBonusEventEntry }] : []),
      ...(formState.costPerRelayEventEntry !== '' && formState.showCostPerRelayEventEntry === true ? [{ omeMeetPriceId: updatedOmeMeetCopy?.omeMeetPrice?.find(x => x.omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID)?.omeMeetPriceId || undefined, omeMeetId: omeMeetState.objData.omeMeetId, omeFeeTypeId: RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, amount: formState.costPerRelayEventEntry }] : []),
    ]

    return updatedOmeMeet;
  }

  function submitFormCallback(formState) {
    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });
  }

  function formStateHasBeenSaved(pricingFormState) {
    //compare formState to omeMeetState to see if formState has been saved
    let costPerTeam = '';
    let costPerCredential = '';
    let costPerIndividualAthlete = '';
    let costPerIndividualInternationalAthlete = '';
    let costPerInternationalStaff = '';
    let costPerRelayOnlySwimmer = '';
    let costPerAthleteIndividualEventEntry = '';
    let costPerAthleteIndividualBonusEventEntry = '';
    let costPerRelayEventEntry = '';

    //If the fee type exists in omeMeetState, check to see if the pricingFormState matches
    for (let i = 0; i < omeMeetState.objData.omeMeetPrice?.length; i++) {
      if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === TEAM_OME_FEE_TYPE_ID) {
        costPerTeam = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerTeam !== pricingFormState?.costPerTeam
          && (pricingFormState?.costPerTeam !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === CREDENTIAL_OME_FEE_TYPE_ID) {
        costPerCredential = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerCredential !== pricingFormState?.costPerCredential
          && (pricingFormState?.costPerCredential !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID) {
        costPerIndividualAthlete = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerIndividualAthlete !== pricingFormState?.costPerIndividualAthlete
          && (pricingFormState?.costPerIndividualAthlete !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID) {
        costPerIndividualInternationalAthlete = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerIndividualInternationalAthlete !== pricingFormState?.costPerIndividualInternationalAthlete
          && (pricingFormState?.costPerIndividualInternationalAthlete !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      }
      else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID) {
        costPerInternationalStaff = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerInternationalStaff !== pricingFormState?.costPerInternationalStaff
          && (pricingFormState?.costPerInternationalStaff !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID) {
        costPerRelayOnlySwimmer = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerRelayOnlySwimmer !== pricingFormState?.costPerRelayOnlySwimmer
          && (pricingFormState?.costPerRelayOnlySwimmer !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID) {
        costPerAthleteIndividualEventEntry = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerAthleteIndividualEventEntry !== pricingFormState?.costPerAthleteIndividualEventEntry
          && (pricingFormState?.costPerAthleteIndividualEventEntry !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID) {
        costPerAthleteIndividualBonusEventEntry = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerAthleteIndividualBonusEventEntry !== pricingFormState?.costPerAthleteIndividualBonusEventEntry
          && (pricingFormState?.costPerAthleteIndividualBonusEventEntry !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID) {
        costPerRelayEventEntry = omeMeetState.objData.omeMeetPrice[i].amount;
        if (costPerRelayEventEntry !== pricingFormState?.costPerRelayEventEntry
          && (pricingFormState?.costPerRelayEventEntry !== '' || omeMeetState.objData.omeMeetPrice[i].amount !== null)) {
          return false;
        }
      }
    }

    //If the fee type doesn't exist in omeMeetState, check to see if the pricingFormState matches
    if (costPerTeam === '' && pricingFormState?.costPerTeam !== '') {
      return false;
    }
    if (costPerCredential === '' && pricingFormState?.costPerCredential !== '') {
      return false;
    }
    if (costPerIndividualAthlete === '' && pricingFormState?.costPerIndividualAthlete !== '') {
      return false;
    }
    if (costPerIndividualInternationalAthlete === '' && pricingFormState?.costPerIndividualInternationalAthlete !== '') {
      return false;
    }
    if (costPerInternationalStaff === '' && pricingFormState?.costPerInternationalStaff !== '') {
      return false;
    }
    if (costPerRelayOnlySwimmer === '' && pricingFormState?.costPerRelayOnlySwimmer !== '') {
      return false;
    }
    if (costPerAthleteIndividualEventEntry === '' && pricingFormState?.costPerAthleteIndividualEventEntry !== '') {
      return false;
    }
    if (costPerAthleteIndividualBonusEventEntry === '' && pricingFormState?.costPerAthleteIndividualBonusEventEntry !== '') {
      return false;
    }
    if (costPerRelayEventEntry === '' && pricingFormState?.costPerRelayEventEntry !== '') {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === false && orgUnitState.isArrayLoading === false) {
      getOrgUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && orgUnitState.isArrayLoaded === true) {
      if (omeMeetState.objData.omeMeetEligibility[0]) {
        const additionalPurchaseOnlyArrayData = omeMeetState.objData.omeMeetPrice.filter(x => x.omeFeeType?.isAdditionalPurchase === true);
        setState({ ...state, gridData: additionalPurchaseOnlyArrayData });

        let savedInvitationsContainFinaOrgUnit = false;
        const finaOrgUnits = orgUnitState.arrayData.find(x => x.id === FINA_ORG_UNIT_ID);

        //Check saved invitations for FINA
        for (let i = 0; i < omeMeetInvitationsState.arrayData?.length; i++) {
          //Check to see if FINA is a saved invitation
          if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits?.id) {
            savedInvitationsContainFinaOrgUnit = true;
            break;
          }
          //Check to see if a FINA continent is a saved invitation
          for (let j = 0; j < finaOrgUnits.children?.length; j++) {
            if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits.children[j].id) {
              savedInvitationsContainFinaOrgUnit = true;
              break;
            }
            //Check to see if a FINA country is a saved invitation
            for (let k = 0; k < finaOrgUnits.children[j]?.children?.length; k++) {
              if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits.children[j]?.children[k]?.id) {
                savedInvitationsContainFinaOrgUnit = true;
                break;
              }
            }
          }
        }

        const hasFINAInvitation = savedInvitationsContainFinaOrgUnit;
        const hasBonusEvents = omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? '';
        const hasRelayEvents = omeMeetState.objData.omeMeetEligibility[0]?.relayEvents ?? '';
        const allowRelayOnlySwimmer = omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer ?? '';

        //Set formState from location.state if 'Back' button was clicked on additional purchases page
        if (location.state && location.state?.pricingFormState) {
          setFormData({
            ...formState,
            costPerTeam: location.state?.pricingFormState?.costPerTeam !== null && location.state?.pricingFormState?.costPerTeam !== undefined ?
              location.state?.pricingFormState?.costPerTeam : '',
            costPerCredential: location.state?.pricingFormState?.costPerCredential !== null && location.state?.pricingFormState?.costPerCredential !== undefined ?
              location.state?.pricingFormState?.costPerCredential : '',
            costPerIndividualAthlete: location.state?.pricingFormState?.costPerIndividualAthlete !== null && location.state?.pricingFormState?.costPerIndividualAthlete !== undefined ?
              location.state?.pricingFormState?.costPerIndividualAthlete : '',
            costPerIndividualInternationalAthlete: location.state?.pricingFormState?.costPerIndividualInternationalAthlete !== null && location.state?.pricingFormState?.costPerIndividualInternationalAthlete !== undefined ?
              location.state?.pricingFormState?.costPerIndividualInternationalAthlete : '',
            costPerInternationalStaff: location.state?.pricingFormState?.costPerInternationalStaff !== null && location.state?.pricingFormState?.costPerInternationalStaff !== undefined ?
              location.state?.pricingFormState?.costPerInternationalStaff : '',
            costPerRelayOnlySwimmer: location.state?.pricingFormState?.costPerRelayOnlySwimmer !== null && location.state?.pricingFormState?.costPerRelayOnlySwimmer !== undefined ?
              location.state?.pricingFormState?.costPerRelayOnlySwimmer : '',
            costPerAthleteIndividualEventEntry: location.state?.pricingFormState?.costPerAthleteIndividualEventEntry !== null && location.state?.pricingFormState?.costPerAthleteIndividualEventEntry !== undefined ?
              location.state?.pricingFormState?.costPerAthleteIndividualEventEntry : '',
            costPerAthleteIndividualBonusEventEntry: location.state?.pricingFormState?.costPerAthleteIndividualBonusEventEntry !== null && location.state?.pricingFormState?.costPerAthleteIndividualBonusEventEntry !== undefined ?
              location.state?.pricingFormState?.costPerAthleteIndividualBonusEventEntry : '',
            costPerRelayEventEntry: location.state?.pricingFormState?.costPerRelayEventEntry !== null && location.state?.pricingFormState?.costPerRelayEventEntry !== undefined ?
              location.state?.pricingFormState?.costPerRelayEventEntry : '',
            showCostPerIndividualInternationalAthlete: hasFINAInvitation,
            showCostPerInternationalStaff: hasFINAInvitation,
            showCostPerAthleteIndividualBonusEventEntry: hasBonusEvents,
            showCostPerRelayOnlySwimmer: hasRelayEvents === true && allowRelayOnlySwimmer === true ? true : false,
            showCostPerRelayEventEntry: hasRelayEvents
          });
          if (formStateHasBeenSaved(location.state?.pricingFormState) === false) {
            setIsDirty(true);
          }
        }
        //If no location.state exists, look to use omeMeetState data
        else {
          //Edit
          if (omeMeetState.objData.omeMeetPrice?.length > 0) {
            let costPerTeam = '';
            let costPerCredential = '';
            let costPerIndividualAthlete = '';
            let costPerIndividualInternationalAthlete = '';
            let costPerInternationalStaff = '';
            let costPerRelayOnlySwimmer = '';
            let costPerAthleteIndividualEventEntry = '';
            let costPerAthleteIndividualBonusEventEntry = '';
            let costPerRelayEventEntry = '';
            for (let i = 0; i < omeMeetState.objData.omeMeetPrice?.length; i++) {
              if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === TEAM_OME_FEE_TYPE_ID) {
                costPerTeam = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === CREDENTIAL_OME_FEE_TYPE_ID) {
                costPerCredential = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID) {
                costPerIndividualAthlete = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID) {
                costPerIndividualInternationalAthlete = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID) {
                costPerInternationalStaff = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID) {
                costPerRelayOnlySwimmer = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID) {
                costPerAthleteIndividualEventEntry = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID) {
                costPerAthleteIndividualBonusEventEntry = omeMeetState.objData.omeMeetPrice[i].amount;
              } else if (omeMeetState.objData.omeMeetPrice[i].omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID) {
                costPerRelayEventEntry = omeMeetState.objData.omeMeetPrice[i].amount;
              }
            }
            setFormData({
              ...formState,
              costPerTeam,
              costPerCredential,
              costPerIndividualAthlete,
              costPerIndividualInternationalAthlete,
              costPerInternationalStaff,
              costPerRelayOnlySwimmer,
              costPerAthleteIndividualEventEntry,
              costPerAthleteIndividualBonusEventEntry,
              costPerRelayEventEntry,
              showCostPerIndividualInternationalAthlete: hasFINAInvitation,
              showCostPerInternationalStaff: hasFINAInvitation,
              showCostPerAthleteIndividualBonusEventEntry: hasBonusEvents,
              showCostPerRelayOnlySwimmer: hasRelayEvents === true && allowRelayOnlySwimmer === true ? true : false,
              showCostPerRelayEventEntry: hasRelayEvents
            });
          } else {
            //No Pricing Info
            setFormData({
              ...formState,
              showCostPerIndividualInternationalAthlete: hasFINAInvitation,
              showCostPerInternationalStaff: hasFINAInvitation,
              showCostPerAthleteIndividualBonusEventEntry: hasBonusEvents,
              showCostPerRelayOnlySwimmer: hasRelayEvents === true && allowRelayOnlySwimmer === true ? true : false,
              showCostPerRelayEventEntry: hasRelayEvents
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, orgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_SUBMIT);
        }
        else {
          navigate(HostNavLinks.OME_MEET_SUBMIT);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.ADD) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_PRICING_ADDITIONAL_PURCHASES, { state: { pricingFormState: formState } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_PRICING_ADDITIONAL_PURCHASES, { state: { pricingFormState: formState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_PRICING_ADDITIONAL_PURCHASES, { state: { pricingFormState: formState, omeMeetPriceId: state.omeMeetPriceId } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_PRICING_ADDITIONAL_PURCHASES, { state: { pricingFormState: formState, omeMeetPriceId: state.omeMeetPriceId } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryStartDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    state,
    omeMeetState,
    modalState,
    formState,
    errorState,
    handleSubmit,
    onNextButtonClicked,
    onSaveAndExitButtonClicked,
    onBackButtonClicked,
    onFormValueChanged,
    onAddAdditionalPurchaseItem,
    onEditAdditionalPurchaseItem,
    onDeleteAdditionalPurchaseItem,
    onDeleteAdditionalPurchaseItemClicked,
    onModalCanceled,
    formStateHasBeenSaved,
    allowEdits
  };
};

export default useOmePricing;