import { Fragment } from 'react';

import RelayAthleteMeetEventCombobox from '../../../../components/comboboxes/relayAthleteMeetEventCombobox/RelayAthleteMeetEventCombobox';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './RelayEntries.module.css';
import Constants from '../../../../../common/utils/Constants';

const GridRow = ({ legNumber, isEdit, athlete, name, value, meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, event }) => {
  return (
    <Fragment key={value + 'Fragment'}>
      <tr key={value}>
        <td>{legNumber}</td>
        {isEdit === true ?
          <td>
            {(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''}
          </td> :
          <td className={style.OverrideTableStyling}>
            <RelayAthleteMeetEventCombobox
              label={""}
              name={name}
              valueToMatch={formState[name]}
              error={errorState[value]}
              message={errorState[value]}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, value, newValueLabel, name, e); }}
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}

            />
          </td>
        }
        <td>{athlete.competitionGenderTypeName || ''}</td>
        <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
        <td>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : ''}</td>
        {isEdit === true ?
          <Fragment>
            <td>{athlete.entryTime ? formatTimeForDisplay(athlete.entryTime) : 'NT'} {athlete.entryTime && formState.courseCode ? formState.courseCode : ''}</td>
            <td>{athlete.swimTimeEntryOverride === true ? 'Yes' : 'No'}</td>
            <td>{athlete.swimTimeEntryOverride === true ? athlete.entrySwimDate ? formatDate(athlete.entrySwimDate) : '' : ''}</td>
            <td>{athlete.swimTimeEntryOverride === true ? athlete.entryTimeMeetName || '' : ''}</td>
          </Fragment> :
          <td>{athlete.entryTime ? formatTimeForDisplay(athlete.entryTime) : formState[value] !== Constants.DEFAULT_ID ? 'NT' : ''} {athlete.entryTime && formState.courseCode ? formState.courseCode : ''}</td>}
      </tr>
    </Fragment>
  );
};

const OrgUnitAdminRelayEntrySelectionLargeGrid = ({ isEdit, gridData, isLoading, meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, event }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Leg</th>
          <th>Name</th>
          <th>Comp. Category</th>
          <th>Birth Date</th>
          <th>Age at Meet</th>
          {isEdit === true ?
            <Fragment>
              <th>Entry Time</th>
              <th>Override?</th>
              <th>Swim Date</th>
              <th>Meet Name</th>
            </Fragment> :
            <th>Entry Time</th>}
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan={isEdit === true ? "6" : "9"}>Loading...</td></tr>
          : <Fragment>
            <GridRow
              key={1}
              legNumber={isEdit === true ? gridData.athletes[0].legNumber : "1"}
              isEdit={isEdit}
              athlete={gridData.athletes[0]}
              name="athlete1Name"
              value="athlete1PersonId"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              event={event}
            />
            <GridRow
              key={2}
              legNumber={isEdit === true ? gridData.athletes[1].legNumber : "2"}
              isEdit={isEdit}
              athlete={gridData.athletes[1]}
              name="athlete2Name"
              value="athlete2PersonId"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              event={event}
            />
            <GridRow
              key={3}
              legNumber={isEdit === true ? gridData.athletes[2].legNumber : "3"}
              isEdit={isEdit}
              athlete={gridData.athletes[2]}
              name="athlete3Name"
              value="athlete3PersonId"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              event={event}
            />
            <GridRow
              key={4}
              legNumber={isEdit === true ? gridData.athletes[3].legNumber : "4"}
              isEdit={isEdit}
              athlete={gridData.athletes[3]}
              name="athlete4Name"
              value="athlete4PersonId"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              event={event}
            />
          </Fragment>
        }
      </tbody>
    </table>

  );
}

export default OrgUnitAdminRelayEntrySelectionLargeGrid;