import React, { Fragment } from 'react';

import useMeetStaffDetail from './UseMeetStaffDetail';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Constants from '../../../../common/utils/Constants';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import SearchMemberWithLscPopup from '../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetStaffDetail = () => {
  const { meetStaffState, meetState, formState, errorState, setFormData, handleSubmit, onFormValueChanged,
    onSaveButtonClicked, onBackButtonClicked } = useMeetStaffDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personId !== '' ? 'Edit' : 'Add'} Meet Staff</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row'>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <ReadOnly
              label={'Position'}
              name={'meetStaffTypeName'}
              value={formState.meetStaffTypeName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup
              label={'Member Id*'}
              memberIdName={'memberId'}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {errorState?.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState?.error}</p>}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveButtonClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackButtonClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetStaffState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={meetState.isObjLoading} />
    </Fragment>
  );
};

export default MeetStaffDetail;