import useLaneSheetRightSideNotesDetailForm from "./UseLaneSheetRightSideNotesDetailForm";

import LaneSheetRecordListCombobox from "../../../../components/comboboxes/laneSheetRecordListCombobox/LaneSheetRecordListCombobox";

import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Dropdown from "../../../../../common/components/dropdowns/Dropdown";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";

const LaneSheetRightSideNotesDetailForm = ({ primaryButtonText = 'Save', secondaryButtonText = 'Back',
  rightSideDataLength, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onValueTextPairChanged,
    displayOrderOptionsState
  } = useLaneSheetRightSideNotesDetailForm(onSubmitFormCallback, rightSideDataLength);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row  usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <LaneSheetRecordListCombobox
            label={"Record List*"}
            name={"recordListId"}
            valueToMatch={formState.listName}
            error={errorState.recordListId}
            message={errorState.recordListId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'recordListId', newValueLabel, 'listName', e); }}
            isTopInfoLaneSheet={false}
            isRightInfoLaneSheet={true} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <Dropdown
            label="Display Order*"
            error={errorState.displayOrder}
            message={errorState.displayOrder}
            options={displayOrderOptionsState}
            name="displayOrder"
            onChange={(value) => { onFormValueChanged('displayOrder', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <Input
            label="Name to Show*"
            name="nameToShow"
            value={formState.nameToShow}
            error={errorState.nameToShow}
            message={errorState.nameToShow}
            onChange={(value) => { onFormValueChanged('nameToShow', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <Input
            label="Times to Show*"
            name="recordsToShow"
            type="number"
            min='1'
            max='20'
            value={formState.recordsToShow}
            error={errorState.recordsToShow}
            message={errorState.recordsToShow}
            onChange={(value) => { onFormValueChanged('recordsToShow', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <YesNoSwitch
            label="Relay Only?*"
            name="isRelayOnly"
            checked={formState.isRelayOnly}
            error={errorState.isRelayOnly}
            message={errorState.isRelayOnly}
            onChange={(value) => { onFormValueChanged('isRelayOnly', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetRightSideNotesDetailForm;