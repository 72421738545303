import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';
import postMeetTime from './meetTimesAdd/PostMeetTimeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetTimesAddPostData = {
  INITIAL_STATE,
  postMeetTime
};

export default MeetTimesAddPostData;