import { Fragment } from 'react';

import useSessionsDetail from './UseSessionsDetail';

import Input from '../../components/inputs/Input';
import ReadOnly from '../../components/readOnly/ReadOnly';
import Dropdown from '../dropdowns/Dropdown';
import TimePicker from '../datepickers/TimePicker';
import SessionDropdown from '../dropdowns/sessionDropdown/SessionDropdown';
import PopUpModal from '../dialogs/PopUpModal';

import Constants from '../../utils/Constants';
import { formatTimeToTimeTwelveHourClockForDisplay } from '../../utils/DateFunctions';

import global from '../GlobalStyle.module.css';

const RequiredFieldsEditableOptionalFieldsShownForm = ({ formState, errorState, onFormValueChangedSetMeetSessionsState,
  onValueTextPairChangedSetMeetSessionsState, meetDatesState }) => (
  <div className='row'>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <Input
        label='Session Number*'
        name='sessionOrderId'
        type='number'
        min='1'
        value={formState.sessionOrderId}
        error={errorState.sessionOrderId}
        message={errorState.sessionOrderId}
        onChange={(value) => { onFormValueChangedSetMeetSessionsState('sessionOrderId', value); }} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <SessionDropdown
        label="Session Type*"
        name="sessionTypeId"
        value={formState.sessionTypeId}
        error={errorState.sessionTypeId}
        message={errorState.sessionTypeId}
        onChange={(id, name) => { onValueTextPairChangedSetMeetSessionsState(id, 'sessionTypeId', name, 'sessionTypeName') }}
      />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <Dropdown
        label="Session Date*"
        name="sessionDateId"
        options={meetDatesState}
        value={formState.sessionDateId}
        error={errorState.sessionDateId}
        message={errorState.sessionDateId}
        isLoading={false}
        onChange={(newValue, newValueLabel, e) => {
          onValueTextPairChangedSetMeetSessionsState(
            newValue, 'sessionDateId',
            newValueLabel, 'sessionDate', e);
        }} />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <TimePicker
        label='Warm Up Time'
        name='warmUpTime'
        value={formState.warmUpTime}
        error={errorState.warmUpTime}
        message={errorState.warmUpTime}
        onChange={(value) => {
          onValueTextPairChangedSetMeetSessionsState(value, 'warmUpTime', formatTimeToTimeTwelveHourClockForDisplay(value), 'warmUpTimeTwelveHourClock');
        }}
      />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <ReadOnly
        label='Warm Up Time (12-hour clock)'
        name='warmUpTimeTwelveHourClock'
        value={formState.warmUpTimeTwelveHourClock}
      />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <TimePicker
        label='Start Time'
        name='startTime'
        value={formState.startTime}
        error={errorState.startTime}
        message={errorState.startTime}
        onChange={(value) => {
          onValueTextPairChangedSetMeetSessionsState(value, 'startTime', formatTimeToTimeTwelveHourClockForDisplay(value), 'startTimeTwelveHourClock');
        }} />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <ReadOnly
        label='Start Time (12-hour clock)'
        name='startTimeTwelveHourClock'
        value={formState.startTimeTwelveHourClock}
      />
    </div>
  </div>
)

const RequiredFieldsReadOnlyOptionalFieldsShownForm = ({ formState, errorState, onValueTextPairChangedSetMeetSessionsState }) => (
  <div className='row'>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label="Session Number"
        name="sessionOrderId"
        value={formState.sessionOrderId} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label="Session Type"
        name="sessionTypeName"
        value={formState.sessionTypeName} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <ReadOnly
        label="Session Date"
        name="sessionDate"
        value={formState.sessionDate} />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <TimePicker
        label='Warm Up Time'
        name='warmUpTime'
        value={formState.warmUpTime}
        error={errorState.warmUpTime}
        message={errorState.warmUpTime}
        onChange={(value) => {
          onValueTextPairChangedSetMeetSessionsState(value, 'warmUpTime', formatTimeToTimeTwelveHourClockForDisplay(value), 'warmUpTimeTwelveHourClock');
        }}
      />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <ReadOnly
        label='Warm Up Time (12-hour clock)'
        name='warmUpTimeTwelveHourClock'
        value={formState.warmUpTimeTwelveHourClock}
      />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <TimePicker
        label='Start Time'
        name='startTime'
        value={formState.startTime}
        error={errorState.startTime}
        message={errorState.startTime}
        onChange={(value) => {
          onValueTextPairChangedSetMeetSessionsState(value, 'startTime', formatTimeToTimeTwelveHourClockForDisplay(value), 'startTimeTwelveHourClock');
        }} />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <ReadOnly
        label='Start Time (12-hour clock)'
        name='startTimeTwelveHourClock'
        value={formState.startTimeTwelveHourClock}
      />
    </div>
  </div>
);

const RequiredFieldsEditableOptionalFieldsNotShownForm = ({ formState, errorState, onFormValueChangedSetMeetSessionsState,
  onValueTextPairChangedSetMeetSessionsState, meetDatesState }) => (
  <div className='row'>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <Input
        label='Session Number*'
        name='sessionOrderId'
        type='number'
        min='1'
        value={formState.sessionOrderId}
        error={errorState.sessionOrderId}
        message={errorState.sessionOrderId}
        onChange={(value) => { onFormValueChangedSetMeetSessionsState('sessionOrderId', value); }} />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <SessionDropdown
        label="Session Type*"
        name="sessionTypeId"
        value={formState.sessionTypeId}
        error={errorState.sessionTypeId}
        message={errorState.sessionTypeId}
        onChange={(id, name) => { onValueTextPairChangedSetMeetSessionsState(id, 'sessionTypeId', name, 'sessionTypeName') }}
      />
    </div>
    <div className='col-xs-12 col-sm-6 col-md-4'>
      <Dropdown
        label="Session Date*"
        name="sessionDateId"
        options={meetDatesState}
        value={formState.sessionDateId}
        error={errorState.sessionDateId}
        message={errorState.sessionDateId}
        isLoading={false}
        onChange={(newValue, newValueLabel, e) => {
          onValueTextPairChangedSetMeetSessionsState(
            newValue, 'sessionDateId',
            newValueLabel, 'sessionDate', e);
        }} />
    </div>
  </div>
)

const SessionsDetail = () => {
  const {
    meetSessionsState,
    sessionState,
    meetDatesState,
    onFormValueChangedSetMeetSessionsState,
    onValueTextPairChangedSetMeetSessionsState
  } = useSessionsDetail();

  return (
    <Fragment>
      <form>
        <div className={meetSessionsState?.showOptionalFields === true ? global.DisplayComponent : global.HideComponent}>
          <div className={meetSessionsState?.requiredFieldsReadOnly === false ? global.DisplayComponent : global.HideComponent}>
            <RequiredFieldsEditableOptionalFieldsShownForm
              formState={meetSessionsState?.formState}
              errorState={meetSessionsState?.errorState}
              onFormValueChangedSetMeetSessionsState={onFormValueChangedSetMeetSessionsState}
              onValueTextPairChangedSetMeetSessionsState={onValueTextPairChangedSetMeetSessionsState}
              meetDatesState={meetDatesState}
            />
          </div>
          <div className={meetSessionsState?.requiredFieldsReadOnly === true ? global.DisplayComponent : global.HideComponent}>
            <RequiredFieldsReadOnlyOptionalFieldsShownForm
              formState={meetSessionsState?.formState}
              errorState={meetSessionsState?.errorState}
              onValueTextPairChangedSetMeetSessionsState={onValueTextPairChangedSetMeetSessionsState}
            />
          </div>
        </div>
        <div className={meetSessionsState?.showOptionalFields === false ? global.DisplayComponent : global.HideComponent}>
          <RequiredFieldsEditableOptionalFieldsNotShownForm
            formState={meetSessionsState?.formState}
            errorState={meetSessionsState?.errorState}
            onFormValueChangedSetMeetSessionsState={onFormValueChangedSetMeetSessionsState}
            onValueTextPairChangedSetMeetSessionsState={onValueTextPairChangedSetMeetSessionsState}
            meetDatesState={meetDatesState}
          />
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={sessionState.isArrayLoading} />
    </Fragment >
  );
};

export default SessionsDetail;