import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import GetInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import { eligibilityIsComplete, meetInfoIsComplete } from './LeftNavValidation';

const getEligibility = (viewName, route, omeMeetStateObjData, meetStateObjData, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = ((viewName === Views.OME_MEET_ELIGIBILITY) || (viewName === Views.OME_MEET_ELIGIBILITY_NON_HQ)) === true ? true : false;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);

  const arePrerequisitesDone = isMeetInfoComplete === true;

  const isComplete = isMeetInfoComplete === true && eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);

  const item = route === '' ? GetInitialStateOmeRoute(Views.OME_MEET_ELIGIBILITY) : getInitialStateMeetRoute(Views.OME_MEET_ELIGIBILITY);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getEligibility;