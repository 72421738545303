import { useContext } from 'react';

import { SelectionsOrgLevelStateContext } from './SelectionsOrgLevelContextProvider';

import SelectionsOrgLevelData from './SelectionsOrgLevelData';

const useSelectionsOrgLevelData = () => {
  const [selectionsOrgLevelState, setSelectionsOrgLevelState] = useContext(SelectionsOrgLevelStateContext);

  const getOrgLevelByOrganizationCodeAndLevelCode = (organizationCode, levelCode) => {
    return SelectionsOrgLevelData.getSelectionsOrgLevels(organizationCode, levelCode, selectionsOrgLevelState, setSelectionsOrgLevelState);
  };

  return {
    selectionsOrgLevelState,
    setSelectionsOrgLevelState,
    getOrgLevelByOrganizationCodeAndLevelCode
  };
};

export default useSelectionsOrgLevelData;