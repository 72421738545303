export const localValidate = (formState) => {

  const errors = {};
  if (formState.orgUnit.length === 0) {
    errors.orgUnit = 'Selection is required';
  }

  return errors;
};

const TeamEntryHQSelectOrgUnitValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default TeamEntryHQSelectOrgUnitValidation;