import React, { Fragment } from 'react';

import useOmeEventsDetail from './UseOmeEventsDetail';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import OmeMeetAlternateEventsGrid from './OmeMeetAlternateEventsGrid';

import TimeInput from '../../../../../common/components/inputs/TimeInput';
import Input from '../../../../../common/components/inputs/Input';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import EventCombobox from '../../../../../common/components/comboboxes/eventCombobox/EventCombobox';
import EventCompetitionCategoryRadiobuttonList from '../../../../../common/components/radiobuttonlists/eventCompetitionCategoryRadiobuttonList/EventCompetitionCategoryRadiobuttonList';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OmeEventsDetail = () => {
  const {
    state,
    setState,
    modalState,
    alternateEventGridState,
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    eventCompetitionGenderState,
    timeStandardTypeState,
    timeStandardAgeGroupState,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onChangeEvent,
    onAddAlternateEventClicked,
    onEditAlternateEventClicked,
    onDeleteAlternateEventClicked,
    onDeleteAlternateEvent,
    onModalCanceled
  } = useOmeEventsDetail();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_EVENTS_DETAIL}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Input
                label="Event Number*"
                name="eventNumber"
                type="number"
                min="1"
                max="199"
                value={formState.eventNumber}
                error={errorState.eventNumber}
                message={errorState.eventNumber}
                onChange={(value) => { onFormValueChanged('eventNumber', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <EventCombobox
                label={"Event*"}
                name={"eventId"}
                valueToMatch={formState.eventName}
                error={errorState.eventId}
                message={errorState.eventId}
                onChange={(newValue, newValueLabel, e) => { setState({ ...state, updateTimeCuts: true }); onChangeEvent(newValue, newValueLabel, e) }}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <EventCompetitionCategoryRadiobuttonList
                label={"Competition Category*"}
                name="eventCompetitionGenderTypeId"
                isVertical={false}
                value={Number(formState.eventCompetitionGenderTypeId)}
                error={errorState.eventCompetitionGenderTypeId}
                message={errorState.eventCompetitionGenderTypeId}
                onChange={(value) => { setState({ ...state, updateTimeCuts: true }); onFormValueChanged('eventCompetitionGenderTypeId', Number(value)) }}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Dropdown
                label="Age Group*"
                name="meetAgeGroupId"
                options={state.ageGroups}
                value={formState.meetAgeGroupId}
                error={errorState.meetAgeGroupId}
                message={errorState.meetAgeGroupId}
                isLoading={false}
                onChange={(value) => { setState({ ...state, updateTimeCuts: true }); onFormValueChanged('meetAgeGroupId', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Dropdown
                label="Session*"
                name="meetSessionId"
                options={state.sessions}
                value={formState.meetSessionId}
                error={errorState.meetSessionId}
                message={errorState.meetSessionId}
                isLoading={false}
                onChange={(value) => { onFormValueChanged('meetSessionId', value) }} />
            </div>
            <div className={formState.meetAgeGroupId > 0 && formState.eventId > 0 && formState.eventCompetitionGenderTypeId > 0 ?
              global.DisplayCompoment : global.HideComponent}>
              <div className={formState.hasNoSlowerThanTimeStandards === true &&
                (formState.scyPossibleForEvent === true || formState.scmPossibleForEvent === true || formState.lcmPossibleForEvent === true) ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12'>
                  <ReadOnly
                    label="No Slower Than Time Standard Name (Time Standard Cut for Selected Event and Competition Category)"
                    name="noSlowerThanTimeStandardName"
                    value={formState.noSlowerThanTimeStandardName + (formState.showScy === true && formState.scyPossibleForEvent === true ? (` (SCY: ${formState.scyNoSlowerThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showScm === true && formState.scmPossibleForEvent === true ? (` (SCM: ${formState.scmNoSlowerThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showLcm === true && formState.lcmPossibleForEvent === true ? (` (LCM: ${formState.lcmNoSlowerThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '')}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Event uses No Slower Than Time?*'
                    name='eventHasNoSlowerThanTimeStandard'
                    checked={formState.eventHasNoSlowerThanTimeStandard}
                    error={errorState.eventHasNoSlowerThanTimeStandard}
                    message={errorState.eventHasNoSlowerThanTimeStandard}
                    onChange={(value) => { onFormValueChanged('eventHasNoSlowerThanTimeStandard', value); }} />
                </div>
                <div className={formState.eventHasNoSlowerThanTimeStandard === true ? global.DisplayCompoment : global.HideComponent}>
                  <div className={formState.showScy === true && formState.scyPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCY No Slower Than Time*"
                        name="scyQualifyingTime"
                        value={formState.scyQualifyingTime}
                        error={errorState.scyQualifyingTime}
                        message={errorState.scyQualifyingTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scyQualifyingTime', value, 'scyQualifyingTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showScm === true && formState.scmPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCM No Slower Than Time*"
                        name="scmQualifyingTime"
                        value={formState.scmQualifyingTime}
                        error={errorState.scmQualifyingTime}
                        message={errorState.scmQualifyingTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scmQualifyingTime', value, 'scmQualifyingTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showLcm === true && formState.lcmPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="LCM No Slower Than Time*"
                        name="lcmQualifyingTime"
                        value={formState.lcmQualifyingTime}
                        error={errorState.lcmQualifyingTime}
                        message={errorState.lcmQualifyingTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'lcmQualifyingTime', value, 'lcmQualifyingTimeValue'); }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={formState.hasNoFasterThanTimeStandards === true &&
                (formState.scyPossibleForEvent === true || formState.scmPossibleForEvent === true || formState.lcmPossibleForEvent === true) ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12'>
                  <ReadOnly
                    label="No Faster Than Time Standard Name (Time Standard Cut for Selected Event and Competition Category)"
                    name="noFasterThanTimeStandardName"
                    value={formState.noFasterThanTimeStandardName + (formState.showScy === true && formState.scyPossibleForEvent === true ? (` (SCY: ${formState.scyNoFasterThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showScm === true && formState.scmPossibleForEvent === true ? (` (SCM: ${formState.scmNoFasterThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showLcm === true && formState.lcmPossibleForEvent === true ? (` (LCM: ${formState.lcmNoFasterThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '')}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Event uses No Faster Than Time?*'
                    name='eventHasNoFasterThanTimeStandard'
                    checked={formState.eventHasNoFasterThanTimeStandard}
                    error={errorState.eventHasNoFasterThanTimeStandard}
                    message={errorState.eventHasNoFasterThanTimeStandard}
                    onChange={(value) => { onFormValueChanged('eventHasNoFasterThanTimeStandard', value); }} />
                </div>
                <div className={formState.eventHasNoFasterThanTimeStandard === true ? global.DisplayCompoment : global.HideComponent}>
                  <div className={formState.showScy === true && formState.scyPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCY No Faster Than Time*"
                        name="scyLimitTime"
                        value={formState.scyLimitTime}
                        error={errorState.scyLimitTime}
                        message={errorState.scyLimitTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scyLimitTime', value, 'scyLimitTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showScm === true && formState.scmPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCM No Faster Than Time*"
                        name="scmLimitTime"
                        value={formState.scmLimitTime}
                        error={errorState.scmLimitTime}
                        message={errorState.scmLimitTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scmLimitTime', value, 'scmLimitTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showLcm === true && formState.lcmPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="LCM No Faster Than Time*"
                        name="lcmLimitTime"
                        value={formState.lcmLimitTime}
                        error={errorState.lcmLimitTime}
                        message={errorState.lcmLimitTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'lcmLimitTime', value, 'lcmLimitTimeValue'); }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={formState.hasBonusEvents === true && formState.hasBonusTimeStandards === false && formState.isRelay === false
                ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Event offers Bonus Event?*'
                    name='offeredAsBonusEvent'
                    checked={formState.offeredAsBonusEvent}
                    error={errorState.offeredAsBonusEvent}
                    message={errorState.offeredAsBonusEvent}
                    onChange={(value) => { onFormValueChanged('offeredAsBonusEvent', value); }} />
                </div>
              </div>
              <div className={formState.hasBonusTimeStandards === true && formState.isRelay === false &&
                (formState.scyPossibleForEvent === false && formState.scmPossibleForEvent === false && formState.lcmPossibleForEvent === false)
                ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Event offers Bonus Event?*'
                    name='offeredAsBonusEvent'
                    checked={formState.offeredAsBonusEvent}
                    error={errorState.offeredAsBonusEvent}
                    message={errorState.offeredAsBonusEvent}
                    onChange={(value) => { onFormValueChanged('offeredAsBonusEvent', value); }} />
                </div>
              </div>
              <div className={formState.hasBonusTimeStandards === true && formState.isRelay === false &&
                (formState.scyPossibleForEvent === true || formState.scmPossibleForEvent === true || formState.lcmPossibleForEvent === true)
                ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12'>
                  <ReadOnly
                    label="Bonus Time Standard Name (Time Standard Cut for Selected Event and Competition Category)"
                    name="bonusTimeStandardName"
                    value={formState.bonusTimeStandardName + (formState.showScy === true && formState.scyPossibleForEvent === true ? (` (SCY: ${formState.scyBonusTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showScy === true && formState.scyPossibleForEvent === true ? (` (SCM: ${formState.scmBonusTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showLcm === true && formState.lcmPossibleForEvent === true ? (` (LCM: ${formState.lcmBonusTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '')}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Event offers Bonus Event?*'
                    name='offeredAsBonusEvent'
                    checked={formState.offeredAsBonusEvent}
                    error={errorState.offeredAsBonusEvent}
                    message={errorState.offeredAsBonusEvent}
                    onChange={(value) => { onFormValueChanged('offeredAsBonusEvent', value); }} />
                </div>
                <div className={formState.offeredAsBonusEvent === true ? global.DisplayCompoment : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Event uses Bonus Time?*'
                      name='eventHasBonusTimeStandard'
                      checked={formState.eventHasBonusTimeStandard}
                      error={errorState.eventHasBonusTimeStandard}
                      message={errorState.eventHasBonusTimeStandard}
                      onChange={(value) => { onFormValueChanged('eventHasBonusTimeStandard', value); }} />
                  </div>
                  <div className={formState.eventHasBonusTimeStandard === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className={formState.showScy === true && formState.scyPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                      <div className='col-xs-12 col-sm-6'>
                        <TimeInput
                          label="SCY Bonus Time*"
                          name="scyBonusTime"
                          value={formState.scyBonusTime}
                          error={errorState.scyBonusTime}
                          message={errorState.scyBonusTime}
                          onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scyBonusTime', value, 'scyBonusTimeValue'); }} />
                      </div>
                    </div>
                    <div className={formState.showScm === true && formState.scmPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                      <div className='col-xs-12 col-sm-6'>
                        <TimeInput
                          label="SCM Bonus Time*"
                          name="scmBonusTime"
                          value={formState.scmBonusTime}
                          error={errorState.scmBonusTime}
                          message={errorState.scmBonusTime}
                          onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scmBonusTime', value, 'scmBonusTimeValue'); }} />
                      </div>
                    </div>
                    <div className={formState.showLcm === true && formState.lcmPossibleForEvent === true ? global.DisplayCompoment : global.HideComponent}>
                      <div className='col-xs-12 col-sm-6'>
                        <TimeInput
                          label="LCM Bonus Time*"
                          name="lcmBonusTime"
                          value={formState.lcmBonusTime}
                          error={errorState.lcmBonusTime}
                          message={errorState.lcmBonusTime}
                          onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'lcmBonusTime', value, 'lcmBonusTimeValue'); }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={formState.hasNoSlowerThanTimeStandards === true || formState.hasNoSlowerThanTimeStandards === true || formState.hasBonusTimeStandards === true ?
              global.DisplayCompoment : global.HideComponent}>
              <div className='col-xs-12'></div>
              <div className='col-xs-12 col-md-3 usas-extra-top-margin'>
                <ActionIntraPageButton onClick={(e) => onAddAlternateEventClicked(e)}>Add Alternate Event</ActionIntraPageButton>
              </div>
              <div className='col-xs-12 col-md-9'>
                <p>**Please provide all required information on this page before clicking 'Add Alternate Event'. Clicking this button will save any changes you have made on this page.</p>
              </div>
              <div className='col-xs-12'>
                <OmeMeetAlternateEventsGrid
                  gridState={alternateEventGridState}
                  omeMeetState={omeMeetState}
                  onEdit={onEditAlternateEventClicked}
                  onDelete={onDeleteAlternateEventClicked}
                />
              </div>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Alternate Event:</b>&nbsp;{modalState.event}</p>
              {modalState.showAdditionalMsg === true && <span>
                <p><b>Equivalent Event(s) on Different Courses:</b>&nbsp;{modalState.additionalMsg}</p>
                <p><b>The Alternate Event and all Equivalent Event(s) on Different Courses will be deleted.</b></p>
                <p><b>Are you sure you want to save any changes you have made on this page and make the associated deletes?</b></p>
              </span>}
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onDeleteAlternateEvent(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={eventCompetitionGenderState.isArrayLoading === true || timeStandardTypeState.isArrayLoading === true
            || timeStandardAgeGroupState.isArrayLoading === true} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment >
  );
};

export default OmeEventsDetail;