import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeEligibilityCoachPassesValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeEligibility from './UseOmeEligibility';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const COACH_PASS_RULE_OVERLAP_ERROR = 'The provided number of qualifying athletes range overlaps with the range of an existing coach pass rule. Overlaps are not allowed.'

const INITIAL_VIEW_STATE = {
  tryRedirect: false
}

const INITIAL_FORM_STATE = {
  eligibilityFormState: {},
  omeMeetCoachPassId: Constants.DEFAULT_ID,
  minSwimmers: '',
  maxSwimmers: '',
  coachPasses: ''
};

const useOmeEligibilityCoachPasses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { formStateHasBeenSaved } = useOmeEligibility();
  const { RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
    RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID } = useEnvironmentVariableData();
  const { formState, errorState, onFormValueChanged, handleSubmit, isDirty, isSubmitting, setFormData, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_ELIGIBILITY, { state: { eligibilityFormState: formState.eligibilityFormState } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_ELIGIBILITY, { state: { eligibilityFormState: formState.eligibilityFormState } });
    }
  };

  const tryAddEditCoachRule = (updatedOmeMeet) => {
    if (validateCoachPassRule(updatedOmeMeet) === true) {
      return true;
    }
    else {
      return null;
    }
  };

  function submitFormCallback() {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    let tryPut = true;

    //Check to see if the provided numbers work with the Max Number of Athletes For Meet set on the main eligibility page 
    const maxSwimmersNum = parseInt(formState.maxSwimmers);
    if (formState.eligibilityFormState?.maxNumberOfAthletesForMeet) {
      if (maxSwimmersNum > ToIntIfInt(formState.eligibilityFormState?.maxNumberOfAthletesForMeet)) {
        setErrors({ ...errorState, error: `The Max Number of Athletes (${maxSwimmersNum}) cannot exceed the Max Number of Athletes For Meet (${formState.eligibilityFormState?.maxNumberOfAthletesForMeet}).` });
        tryPut = false;
      }
    }

    //Delete the Cost Per Relay Only Swimmer & Cost Per Relay Event Entry records if relay events are not allowed for the meet
    if (formState.eligibilityFormState?.relayEvents === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const relayOnlySwimmerOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID);
      if (relayOnlySwimmerOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayOnlySwimmerOmeFeeTypeIdIndex, 1);
      }
      const relayEventEntryOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID);
      if (relayEventEntryOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayEventEntryOmeFeeTypeIdIndex, 1);
      }
    }

    //Delete the Cost Per Relay Only Swimmer if relay events are allowed for the meet but Relay Only Swimmers are not allowed
    if (formState.eligibilityFormState?.allowRelayOnlySwimmer === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const relayOnlySwimmerOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID);
      if (relayOnlySwimmerOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayOnlySwimmerOmeFeeTypeIdIndex, 1);
      }
    }

    //Save any changes to omeMeetEligibility made on previous page
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      omeMeetId: updatedOmeMeet.omeMeetId,
      maxNumberOfAthletesForMeet: formState.eligibilityFormState?.maxNumberOfAthletesForMeet.toString().replace(',', '') || null,
      maxNumberOfCapExemptAthletesForMeet: formState.eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet.toString().replace(',', '') || null,
      includeRelayOnlySwimmersInMaxNumber: formState.eligibilityFormState?.relayEvents === true && formState.eligibilityFormState?.allowRelayOnlySwimmer === true ? (formState.eligibilityFormState?.includeRelayOnlySwimmersInMaxNumber ?? null) : null,
      maxEventsPerIndividualPerMeet: formState.eligibilityFormState?.maxEventsPerIndividualPerMeet || null,
      maxEventsPerIndividualPerSession: formState.eligibilityFormState?.maxEventsPerIndividualPerSession || null,
      relayEvents: formState.eligibilityFormState?.relayEvents,
      allowRelayOnlySwimmer: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.allowRelayOnlySwimmer ?? null) : null,
      relayTeamFromRosterOnly: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.relayTeamFromRosterOnly ?? null) : null,
      forceAggregateIfFasterThanTeamTime: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.forceAggregateIfFasterThanTeamTime ?? null) : null,
      maxRelayTeamEntriesPerEvent: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.maxRelayTeamEntriesPerEvent || null) : null,
      maxRelayEventsPerIndividualPerMeet: formState.eligibilityFormState?.relayEvents === true ? (formState.eligibilityFormState?.maxRelayEventsPerIndividualPerMeet || null) : null,
      bonusEvents: formState.eligibilityFormState?.bonusEvents ?? null,
      usCitizenOnly: updatedOmeMeet.omeMeetEligibility[0]?.usCitizenOnly ?? null,
      nationalTeamOnly: updatedOmeMeet.omeMeetEligibility[0]?.nationalTeamOnly ?? null,
      allowRepOtherCountry: updatedOmeMeet.omeMeetEligibility[0]?.allowRepOtherCountry ?? null,
      useAdjustedSwimTime: formState.eligibilityFormState?.useAdjustedSwimTime
    }

    updatedOmeMeet.includeRelaySwimmerForCoachPasses = formState.eligibilityFormState?.relayEvents === true && formState.eligibilityFormState?.allowRelayOnlySwimmer === true ? (formState.eligibilityFormState?.includeRelaySwimmerForCoachPasses ?? null) : null;

    //set has hasAthleteExceedsTimeStandardsRelayRule to null if relay events are not allowed
    if (updatedOmeMeet.omeMeetQualification[0] && formState.eligibilityFormState?.relayEvents === false) {
      updatedOmeMeet.omeMeetQualification[0] = {
        ...updatedOmeMeet.omeMeetQualification[0],
        hasAthleteExceedsTimeStandardsRelayRule: null
      }
    }

    //Delete bonus rules if bonus events are not allowed for the meet
    if (formState.eligibilityFormState?.bonusEvents === false && updatedOmeMeet.omeMeetQualification?.length > 0) {
      if (updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.length > 0)
        updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus = [];
    }

    //Edit
    if (formState.omeMeetCoachPassId > 0) {
      const selectedOmeMeetCoachPassIndex = updatedOmeMeet.omeMeetCoachPass?.findIndex(x => x.omeMeetCoachPassId === formState.omeMeetCoachPassId);
      if (selectedOmeMeetCoachPassIndex > -1) {
        if (updatedOmeMeet.omeMeetCoachPass.length === 1 &&
          updatedOmeMeet.omeMeetCoachPass[selectedOmeMeetCoachPassIndex].omeMeetCoachPassId === formState.omeMeetCoachPassId) {
          updatedOmeMeet.omeMeetCoachPass[selectedOmeMeetCoachPassIndex] = {
            ...updatedOmeMeet.omeMeetCoachPass[selectedOmeMeetCoachPassIndex],
            minSwimmers: formState.minSwimmers,
            maxSwimmers: formState.maxSwimmers,
            coachPasses: formState.coachPasses
          };
        }
        else if (updatedOmeMeet.omeMeetCoachPass.length > 1) {
          if (tryAddEditCoachRule(updatedOmeMeet) === true) {
            updatedOmeMeet.omeMeetCoachPass[selectedOmeMeetCoachPassIndex] = {
              ...updatedOmeMeet.omeMeetCoachPass[selectedOmeMeetCoachPassIndex],
              minSwimmers: formState.minSwimmers,
              maxSwimmers: formState.maxSwimmers,
              coachPasses: formState.coachPasses
            };
          }
          else {
            tryPut = false;
          }
        }
      }
    }

    //Add
    else {
      // If omeMeetCoachPass object has not been created yet
      if (updatedOmeMeet.omeMeetCoachPass.length === 0) {
        //Add 0,0,0 coach pass rule if no coach pass rules exist
        updatedOmeMeet.omeMeetCoachPass = formState.minSwimmers > 0 ? [{
          omeMeetId: updatedOmeMeet.omeMeetId,
          minSwimmers: formState.minSwimmers,
          maxSwimmers: formState.maxSwimmers,
          coachPasses: formState.coachPasses
        },
        {
          omeMeetId: omeMeetState.objData.omeMeetId,
          minSwimmers: 0,
          maxSwimmers: 0,
          coachPasses: 0
        }] :
          [{
            omeMeetId: updatedOmeMeet.omeMeetId,
            minSwimmers: formState.minSwimmers,
            maxSwimmers: formState.maxSwimmers,
            coachPasses: formState.coachPasses
          }];
      }
      // If omeMeetCoachPass object exists
      else if (updatedOmeMeet.omeMeetCoachPass.length > 0) {
        //if existing coach pass rules, check for overlap
        if (updatedOmeMeet.omeMeetCoachPass.length > 0) {
          if (tryAddEditCoachRule(updatedOmeMeet) === true) {
            updatedOmeMeet.omeMeetCoachPass.push({
              omeMeetId: updatedOmeMeet.omeMeetId,
              minSwimmers: formState.minSwimmers,
              maxSwimmers: formState.maxSwimmers,
              coachPasses: formState.coachPasses
            });
          }
          else {
            tryPut = false;
          }
        }
        //if no existing coach pass rules, add without checking overlap
        else {
          updatedOmeMeet.omeMeetCoachPass.push({
            omeMeetId: updatedOmeMeet.omeMeetId,
            minSwimmers: formState.minSwimmers,
            maxSwimmers: formState.maxSwimmers,
            coachPasses: formState.coachPasses
          });
          //Add 0,0,0 coach pass rule if no coach pass rules exist
          if (formState.minSwimmers > 0) {
            updatedOmeMeet.omeMeetCoachPass.push({
              omeMeetId: updatedOmeMeet.omeMeetId,
              minSwimmers: 0,
              maxSwimmers: 0,
              coachPasses: 0
            });
          }
        }
      }
    }

    if (tryPut === true) {
      //set has bonusTimeStandards to null if bonus events are not allowed
      if (updatedOmeMeet.omeMeetQualification[0] && formState.eligibilityFormState?.bonusEvents === false) {
        updatedOmeMeet.omeMeetQualification[0] = {
          ...updatedOmeMeet.omeMeetQualification[0],
          hasCoachTimeStandards: null
        }
      }
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
      setState({ ...state, tryRedirect: true });
    }
  };

  function getArrayMinNumberOfAthletes(omeMeetCoachPassId) {
    const array = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetCoachPass));
    if (omeMeetCoachPassId > 0) {
      const index = array.findIndex(x => x.omeMeetCoachPassId === omeMeetCoachPassId)
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    return array.reduce((min, coach) => coach.minSwimmers < min ? coach.minSwimmers : min, array[0].minSwimmers);
  };

  function getArrayMaxNumberOfAthletes(omeMeetCoachPassId) {
    const array = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetCoachPass));
    if (omeMeetCoachPassId > 0) {
      const index = array.findIndex(x => x.omeMeetCoachPassId === omeMeetCoachPassId)
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    return array.reduce((max, coach) => coach.maxSwimmers > max ? coach.maxSwimmers : max, array[0].maxSwimmers);
  };

  function validateCoachPassRule(omeMeetCopy) {
    //Check for overlapping qualifying events
    let overlappingCoachPassRulesCount = 0;
    const minSwimmersNum = parseInt(formState.minSwimmers);
    const maxSwimmersNum = parseInt(formState.maxSwimmers);
    const arrayMinAthletes = getArrayMinNumberOfAthletes(formState.omeMeetCoachPassId);
    const arrayMaxAthletes = getArrayMaxNumberOfAthletes(formState.omeMeetCoachPassId);

    if (minSwimmersNum < arrayMinAthletes && maxSwimmersNum > arrayMaxAthletes) {
      overlappingCoachPassRulesCount += 1;
    }

    for (let i = 0; i < omeMeetCopy.omeMeetCoachPass.length; i++) {
      if (omeMeetCopy.omeMeetCoachPass[i].omeMeetCoachPassId !== formState.omeMeetCoachPassId) {
        if ((minSwimmersNum >= omeMeetCopy.omeMeetCoachPass[i].minSwimmers &&
          minSwimmersNum <= omeMeetCopy.omeMeetCoachPass[i].maxSwimmers) ||
          (maxSwimmersNum >= omeMeetCopy.omeMeetCoachPass[i]?.minSwimmers &&
            maxSwimmersNum <= omeMeetCopy.omeMeetCoachPass[i]?.maxSwimmers)) {
          overlappingCoachPassRulesCount += 1;
        }
      }
    }

    if (overlappingCoachPassRulesCount > 0) {
      setErrors({ ...errorState, error: COACH_PASS_RULE_OVERLAP_ERROR });
      return false;
    }
    return true;
  };

  function coachPassFormStateHasBeenSaved(formState) {
    //compare coach pass formState to omeMeetState to see if formState has been saved
    //Edit coach pass
    if (formState?.omeMeetCoachPassId !== Constants.DEFAULT_ID && formState?.omeMeetCoachPassId !== Constants.DEFAULT_ID) {
      const selectedOmeMeetCoachPass = omeMeetState.objData.omeMeetCoachPass?.find(x =>
        x.omeMeetCoachPassId === formState?.omeMeetCoachPassId);

      if ((ToIntIfInt(formState?.minSwimmers) !== selectedOmeMeetCoachPass?.minSwimmers)
        && (formState?.minSwimmers !== '' || selectedOmeMeetCoachPass?.minSwimmers !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.maxSwimmers) !== selectedOmeMeetCoachPass?.maxSwimmers)
        && (formState?.maxSwimmers !== '' || selectedOmeMeetCoachPass?.maxSwimmers !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.coachPasses) !== selectedOmeMeetCoachPass?.coachPasses)
        && (formState?.coachPasses !== '' || selectedOmeMeetCoachPass?.coachPasses !== null)) {
        return false;
      }
    }
    //Add coach pass
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && location.state && location.state?.eligibilityFormState) {
      //Edit
      if (omeMeetState.objData.omeMeetCoachPass.length > 0 && location.state?.omeMeetCoachPassId) {
        const selectedOmeMeetCoachPass = omeMeetState.objData.omeMeetCoachPass?.find(x =>
          x.omeMeetCoachPassId === location?.state?.omeMeetCoachPassId);
        if (Object.keys(selectedOmeMeetCoachPass).length > 0) {
          setFormData({
            ...formState,
            eligibilityFormState: location.state?.eligibilityFormState || {},
            omeMeetCoachPassId: location.state?.omeMeetCoachPassId || Constants.DEFAULT_ID,
            minSwimmers: selectedOmeMeetCoachPass.minSwimmers >= 0 ? selectedOmeMeetCoachPass.minSwimmers : '',
            maxSwimmers: selectedOmeMeetCoachPass.maxSwimmers >= 0 ? selectedOmeMeetCoachPass.maxSwimmers : '',
            coachPasses: selectedOmeMeetCoachPass.coachPasses >= 0 ? selectedOmeMeetCoachPass.coachPasses : ''
          });
          if (formStateHasBeenSaved(location.state?.eligibilityFormState) === false) {
            setIsDirty(true);
          }
        }
      }
      //Add 
      else {
        setFormData({
          ...formState,
          eligibilityFormState: location.state?.eligibilityFormState || {}
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.eligibilityFormState);
      const isCoachPassSaved = coachPassFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isCoachPassSaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_ELIGIBILITY);
      }
      else {
        navigate(HostNavLinks.OME_MEET_ELIGIBILITY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    state,
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeEligibilityCoachPasses;