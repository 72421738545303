import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeMeetInvitationsData from './GetOmeMeetInvitationsData';
import postOmeMeetInvitationsData from './PostOmeMeetInvitationsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const OmeMeetInvitationsData = {
  INITIAL_STATE,
  getOmeMeetInvitationsData,
  postOmeMeetInvitationsData
};

export default OmeMeetInvitationsData;