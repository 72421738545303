import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetStaffSmallGrid = ({ gridData, isLoading, onEditStaff }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ?
        gridData.map((staff) =>
        (<div className={global.SmallTableRow} key={staff.meetStaffTypeId}>
          <div className={global.SmallTableRowHead}><span className='hidden-xs'>{staff.meetStaffTypeName || <span>&nbsp;</span>}</span>&nbsp;
            {onEditStaff &&
              <button className={global.IconButton}
                type="button"
                onClick={(e) => onEditStaff(e, staff)}>
                <EditIcon />
              </button>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Position</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{staff.meetStaffTypeName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Staff Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{staff.firstName || <span>&nbsp;</span>} {staff.lastName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Email</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
            </div>
          </div>
        </div>)
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meet Staff</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetStaffSmallGrid;