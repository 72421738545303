const NavLinks = {
  MEET_ROOT: '/meet',
  MEET_BY_ID: '/meet/byid',
  MEET_SEARCH: '/meet/search',
  MEET_INFO: '/meet/info',
  BLOCK_PARTY_MEET_INFO: '/meet/blockpartymeetinfo',
  MEET_SESSIONS: '/meet/sessions',
  MEET_SESSIONS_DETAIL: '/meet/sessions/detail',
  MEET_STAFF: '/meet/staff',
  MEET_STAFF_DETAIL: '/meet/staff/detail',
  MEET_ADD: '/meet/add',
  MEET_ADD_SESSIONS: '/meet/add/sessions',
  MEET_ADD_SESSIONS_DETAIL: '/meet/add/sessions/detail',
  MEET_ADD_STAFF: '/meet/add/staff',
  MEET_MERGE: '/meet/merge',
  MEET_TIMES: '/meet/times',
  MEET_TIMES_SELECT_ORGS: '/meet/times/addathlete/selectorganizations',
  MEET_TIMES_SELECT_ATHLETES: '/meet/times/addathlete/selectathletes',
  MEET_TIMES_ADD_MEET_TIME: '/meet/times/addathlete/addmeettime',
  MEET_TIMES_ADD_MEET_TIME_SELECT_TIME_TYPE: '/meet/times/addathlete/addmeettime/selecttimetype',
  MEET_TIMES_ADD_MEET_TIME_INDIVIDUAL: '/meet/times/addathlete/addmeettime/individual',
  MEET_TIMES_ADD_MEET_TIME_RELAY: '/meet/times/addathlete/addmeettime/relay',
  MEET_UPLOAD: '/meet/upload',
  MEET_ERROR_TANK: '/meet/errortank',
  MEET_RECOGNITION: '/meet/recognition',
  MEET_REPORTING: '/meet/meetReporting',
  MEET_AFFILIATION: '/meet/affiliations',
  MEET_RECON: '/meet/recon',
  MEET_UPLOAD_LOCK: '/meet/meetuploadlock',
  MEET_RACE_STATS_UPLOAD: '/meet/racestatsupload',

  MEET_HOST: '/meet/host',
  MEET_HOST_MANAGEMENT: '/meet/host/management',
  MEET_HOST_EXPORT_ENTRIES: '/meet/exportentries',
  MEET_HOST_REPORTING: '/meet/host/reporting',
  MEET_HOST_SELECTION: '/meet/host/selection',
  MEET_HOST_LOADING: '/meet/host/loading',
  MEET_TEMPLATE_LANDING: '/meet/host/meettemplate/landing',
  MEET_TEMPLATE_SEARCH: '/meet/host/meettemplate/search',
  MEET_HOST_INFO: '/meet/host/meetinfo',
  MEET_HOST_INFO_COURSE_PRIORITY: '/meet/host/meetinfo/coursepriority',
  MEET_HOST_ELIGIBILITY: '/meet/host/eligibility',
  MEET_HOST_ELIGIBILITY_BONUS_EVENTS: '/meet/host/eligibility/bonusevents',
  MEET_HOST_ELIGIBILITY_COACH_PASSES: '/meet/host/eligibility/coachpasses',
  MEET_HOST_INVITATIONS: '/meet/host/invitations',
  MEET_HOST_RESTRICTIONS: '/meet/host/restrictions',
  MEET_HOST_RESTRICTIONS_TYPE: '/meet/host/restrictions/type',
  MEET_HOST_RESTRICTIONS_TYPE_MEMBERSHIP: '/meet/host/restrictions/type/membership',
  MEET_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY: '/meet/host/restrictions/type/competitioncategory',
  MEET_HOST_RESTRICTIONS_TYPE_AGE: '/meet/host/restrictions/type/age',
  MEET_HOST_SESSIONS: '/meet/host/sessions',
  MEET_HOST_SESSIONS_DETAIL: '/meet/host/sessions/detail',
  MEET_HOST_AGE_GROUPS: '/meet/host/agegroups',
  MEET_HOST_AGE_GROUPS_DETAIL: '/meet/host/agegroups/detail',
  MEET_HOST_TIME_STANDARDS: '/meet/host/timestandards',
  MEET_HOST_TIME_STANDARDS_DETAIL: '/meet/host/timestandards/detail',
  MEET_HOST_EVENTS: '/meet/host/events',
  MEET_HOST_EVENTS_DETAIL: '/meet/host/events/detail',
  MEET_HOST_EVENTS_DETAIL_ALTERNATE: '/meet/host/events/detail/alternate',
  MEET_HOST_PRICING: '/meet/host/pricing',
  MEET_HOST_PRICING_ADDITIONAL_PURCHASES: '/meet/host/pricing/additionalpurchases',
  MEET_HOST_SUBMIT: '/meet/host/submit',

  MEET_ENTRY_TEAM: '/meet/entry/team',
  MEET_ENTRY_TEAM_SELECTION: '/meet/entry/team/selection',
  MEET_ENTRY_TEAM_HQ_SELECTION: '/meet/entry/team/hqselection',
  OU_ADMIN: '/meet/entry/team/oua',
  OU_ADMIN_LANDING: '/meet/entry/team/oua/landing',
  OU_ADMIN_CONTACTS: '/meet/entry/team/oua/contacts',
  OU_ADMIN_CONTACTS_DETAIL: '/meet/entry/team/oua/contacts/detail',
  OU_ADMIN_ROSTER_PERMISSIONS: '/meet/entry/team/oua/rosterpermissions',
  OU_ADMIN_ROSTER: '/meet/entry/team/oua/roster',
  OU_ADMIN_ROSTER_SELECTION: '/meet/entry/team/oua/roster/selection',
  OU_ADMIN_ROSTER_ENTRIES: '/meet/entry/team/oua/rosterentries',
  OU_ADMIN_ROSTER_ENTRIES_DETAIL: '/meet/entry/team/oua/rosterentries/detail',
  OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_SELECTION: '/meet/entry/team/oua/rosterentries/individual/selection',
  OU_ADMIN_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION: '/meet/entry/team/oua/rosterentries/bonusindividual/selection',
  OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE: '/meet/entry/team/oua/rosterentries/individual/override',
  OU_ADMIN_ROSTER_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE: '/meet/entry/team/oua/rosterentries/bonusindividual/override',
  OU_ADMIN_RELAY_ENTRIES: '/meet/entry/team/oua/relayentries',
  OU_ADMIN_RELAY_ENTRIES_TEAMS: '/meet/entry/team/oua/relayentries/teams',
  OU_ADMIN_RELAY_ENTRIES_DETAIL: '/meet/entry/team/oua/relayentries/detail',
  OU_ADMIN_RELAY_ENTRIES_DETAIL_OVERRIDE: '/meet/entry/team/oua/relayentries/detail/override',
  OU_ADMIN_COACHES: '/meet/entry/team/oua/coaches',
  OU_ADMIN_COACHES_SELECTION: '/meet/entry/team/oua/coaches/selection',
  OU_ADMIN_PURCHASES: '/meet/entry/team/oua/purchases',
  OU_ADMIN_PAYMENTS: '/meet/entry/team/oua/payments',
  OU_ADMIN_REPORTING: '/meet/entry/team/oua/teamentryreporting',

  MEET_ENTRY_ATHLETE: '/meet/entry/athlete',
  ATHLETE_ENTRIES: '/meet/entry/athlete/user/entries',
  ATHLETE_ENTRIES_INDIVIDUAL_SELECTION: '/meet/entry/athlete/user/entries/individual/selection',
  ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION: '/meet/entry/athlete/user/entries/bonusindividual/selection',
  ATHLETE_ENTRIES_INDIVIDUAL_OVERRIDE: '/meet/entry/athlete/user/entries/individual/override',
  ATHLETE_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE: '/meet/entry/athlete/user/entries/bonusindividual/override',
  ATHLETE_COACHES: '/meet/entry/athlete/user/coaches',
  ATHLETE_COACHES_SELECTION: '/meet/entry/athlete/user/coaches/selection',
  ATHLETE_PURCHASES: '/meet/entry/athlete/user/purchases',
  ATHLETE_PAYMENTS: '/meet/entry/athlete/user/payments',
  OME_LOADING: '/ome/loading'
};

export default NavLinks;