import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './utils/NavLinks';

import useLaneSheetData from '../../state/laneSheet/UseLaneSheetData';

const useLaneSheetContextView = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.LANE_SHEET_MEET_INFO);
  };

  const onBackToLandingClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.LANE_SHEET_LANDING);
  };

  return {
    laneSheetState,
    onBackToLandingClicked,
    onHomeClicked
  };
};

export default useLaneSheetContextView;