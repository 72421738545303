import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetOrgUnitAthleteEntryRelayAthleteByMeetEventIdData = (meetEventId, omeMeetOrgUnitEntryId, state, setState) => {
  if (state.isArrayLoading !== true && meetEventId && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      meetEventId,
      message: 'Loading...'
    };
    setState(newState);

    const meetEventIdForUrl = meetEventId ? encodeURIComponent(meetEventId) : 'NaN';
    const omeMeetOrgUnitEntryIdForUrl = omeMeetOrgUnitEntryId ? encodeURIComponent(omeMeetOrgUnitEntryId) : 'NaN';
    const url = `/OrgUnitAthleteEntry/omeMeetOrgUnitEntry/${omeMeetOrgUnitEntryIdForUrl}/relayAthlete/meetEvent/${meetEventIdForUrl}`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            meetEventId,
            message: '',
            items: arrayData.map(x => { return { id: x.personId, name: `${x.firstName + ' ' || ''}${x.preferredName !== '' && x.preferredName !== x.firstName ? '"' + x.preferredName + '" ' : ''}${x.middleName + ' ' || ''}${x.lastName || ''}` }; }),
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOrgUnitAthleteEntryRelayAthleteByMeetEventIdData;