import React, { Fragment } from 'react';

import useOrgUnitAdminRosterPermissions from './UseOrgUnitAdminRosterPermissions';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminRosterPermissionsReadOnly = () => {
  const { formState } = useOrgUnitAdminRosterPermissions();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster Permissions</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label="Allow athletes (and their guardians) to enter themselves into the meet?"
            name="rosterEntryAllowed"
            checked={formState.orgUnitAthleteRegistrationAllowed}
            disabled={true} />
        </div>
      </div>
      <div className={formState.orgUnitAthleteRegistrationAllowed === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Athlete Entry Start Date"
              name="orgUnitRegistrationStartDate"
              value={formState.orgUnitRegistrationStartDate} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Athlete Entry End Date"
              name="orgUnitRegistrationEndDate"
              value={formState.orgUnitRegistrationEndDate} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Time Zone"
              name="timeZone"
              value={formState.timeZone} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrgUnitAdminRosterPermissionsReadOnly;