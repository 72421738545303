import React, { Fragment } from 'react';

import MeetUploadErrorTankQuickAddLargeGrid from './MeetUploadErrorTankQuickAddLargeGrid';
import MeetUploadErrorTankQuickAddSmallGrid from './MeetUploadErrorTankQuickAddSmallGrid';

const MeetUploadErrorTankQuickAddGrid = ({ gridData, isLoading, onQuickAddCheckbox, onDeleteCheckbox, onMatchWithMemberIdButtonClick }) => {
  return (
    <Fragment>
      <MeetUploadErrorTankQuickAddLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onQuickAddCheckbox={onQuickAddCheckbox}
        onDeleteCheckbox={onDeleteCheckbox}
        onMatchWithMemberIdButtonClick={onMatchWithMemberIdButtonClick}
        />
      <MeetUploadErrorTankQuickAddSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onQuickAddCheckbox={onQuickAddCheckbox}
        onDeleteCheckbox={onDeleteCheckbox}
        onMatchWithMemberIdButtonClick={onMatchWithMemberIdButtonClick} 
        /> 
    </Fragment>
  )
};

export default MeetUploadErrorTankQuickAddGrid;