import React, { createContext, useState } from 'react';

import OmeFeeTypeData from './OmeFeeTypeData';

export const OmeFeeTypeStateContext = createContext();

const OmeFeeTypeContextProvider = ({ children }) => {
  const stateHook = useState(OmeFeeTypeData.INITIAL_STATE);

  return (
    <OmeFeeTypeStateContext.Provider value={stateHook}>
      {children}
    </OmeFeeTypeStateContext.Provider>
  );
};

export default OmeFeeTypeContextProvider;