import React, { Fragment } from 'react';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import OrgUnitAdminRelayEntriesTeamsLargeGrid from './OrgUnitAdminRelayEntriesTeamsLargeGrid';
import OrgUnitAdminRelayEntriesTeamsSmallGrid from './OrgUnitAdminRelayEntriesTeamsSmallGrid';

const OrgUnitAdminRelayEntriesTeamsGrid = ({ gridData, isLoading, onEditRelayTeamClicked, onDeleteRelayTeamClicked, state }) => {
  const { onClick, expandedId } = useExpandedRow();
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            <p>{Array.isArray(gridData?.relayTeams) && gridData?.relayTeams?.length === 1 ? `${gridData?.relayTeams?.length} Relay Team` : `${gridData.relayTeams?.length || '0'} Relay Teams`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <OrgUnitAdminRelayEntriesTeamsLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditRelayTeamClicked={onEditRelayTeamClicked}
        onDeleteRelayTeamClicked={onDeleteRelayTeamClicked}
        onClick={onClick}
        expandedId={expandedId}
        event={state.event}
      />
      <OrgUnitAdminRelayEntriesTeamsSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditRelayTeamClicked={onEditRelayTeamClicked}
        onDeleteRelayTeamClicked={onDeleteRelayTeamClicked}
        onClick={onClick}
        expandedId={expandedId}
        event={state.event}
      />
    </Fragment>
  )
};

export default OrgUnitAdminRelayEntriesTeamsGrid;