import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import validate from './TeamEntryHQSelectOrgUnitValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetTeamEntryData from '../../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useOrgUnitOmeMeetData from '../../../../state/orgUnitOmeMeet/UseOrgUnitOmeMeetData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import usePersonOrgRoleData from '../../../../../common/state/personOrgRole/UsePersonOrgRoleData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useTeamEntryHQOrgUnitSelection = () => {
    const navigate = useNavigate();
    const { omeMeetState, clearOmeMeetArrayData, setOmeMeetState } = useOmeMeetData();
    const { omeMeetOrgUnitEntryState, postOmeMeetOrgUnitEntry, clearOmeMeetOrgUnitEntryObjData,
        clearOmeMeetOrgUnitEntryArrayData } = useOmeMeetOrgUnitEntryData();
    const { clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
    const { clearArrayData } = usePersonOrgRoleData();
    const { orgUnitOmeMeetState } = useOrgUnitOmeMeetData();
    const { searchMeetState, setSearchMeetState } = useSearchMeetData();
    const [state, setState] = useState({ radioButtonList: [], showRadioButtonList: false, tryRedirect: false });
    const { handleSubmit, formState, errorState, onFormValueChanged
    } = UseForm(getInitialFormState, submitFormCallback, validate);

    const onBackClicked = (e) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }
        clearOmeMeetOrgUnitEntryObjData();
        clearOmeMeetOrgUnitEntryArrayData();
        clearOmeMeetArrayData();
        clearOmeMeetTeamEntryArrayData();
        clearArrayData();

        navigate(NavLinks.MEET_ENTRY_TEAM);
    }

    function submitFormCallback() {
        handlePost();
    };

    function handlePost() {
        postOmeMeetOrgUnitEntry(omeMeetState.objData.omeMeetId, createOmeMeetOrgUnitEntryObject());
        setOmeMeetState({ ...omeMeetState, route: 'meet' });
        setState({ ...state, tryRedirect: true });
    }

    function createOmeMeetOrgUnitEntryObject() {
        const todaysDateTime = new Date();
        return {
            omeMeetId: omeMeetState.objData.omeMeetId,
            orgUnitId: formState.orgUnit[0]?.id,
            orgUnitEntryDatetime: todaysDateTime,
            orgUnitSubmissionDatetime: null,
            orgUnitRegistrationStartDate: null,
            orgUnitRegistrationEndDate: null,
            status: formState.orgUnit[0]?.name === 'Unattached' ? Constants.WORKFLOW_STATUS_UNATTACHED : Constants.WORKFLOW_STATUS_IN_PROGRESS,
            orgUnitAthleteRegistrationAllowed: formState.orgUnit[0]?.name === 'Unattached' ? true : null
        }
    }

    useEffect(() => {
        if (state.tryRedirect === true && omeMeetOrgUnitEntryState.isSaving === false &&
            omeMeetOrgUnitEntryState.isSaved === true && omeMeetState.route === 'meet') {
            setSearchMeetState({ ...searchMeetState, showMeetHeader: false });
            navigate(NavLinks.OU_ADMIN_LANDING, { state: { isReadOnly: false } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [omeMeetOrgUnitEntryState, state.tryRedirect, omeMeetState.route])

    function getInitialFormState() {
        return { orgUnit: [] };
    };

    return {
        state,
        handleSubmit,
        formState,
        errorState,
        onFormValueChanged,
        omeMeetState,
        omeMeetOrgUnitEntryState,
        orgUnitOmeMeetState,
        onBackClicked
    };
};

export default useTeamEntryHQOrgUnitSelection;