import React, { Fragment } from 'react';

import useOrgUnitAdminRoster from './UseOrgUnitAdminRoster';

import OrgUnitAdminRosterGrid from './OrgUnitAdminRosterGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import Combobox from '../../../../../common/components/comboboxes/Combobox';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterWrite = () => {
  const { omeMeetState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitEntryState, modalState, onAddAthlete, onDeleteAthleteClicked, onModalCanceled, onDeleteAthlete,
    secondaryRepModalState, athleteSecondaryRepresentationState, onAddSecondaryRepToAthleteClicked, handleSubmit, onSecondaryRepModalCanceled,
    formState, errorState, onValueTextPairChanged, approvedClubsForSecondaryRepState
  } = useOrgUnitAdminRoster();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddAthlete}>Select Athletes</ActionIntraPageButton>
        </div>
        <div className="col-xs-12">
          <OrgUnitAdminRosterGrid
            gridData={omeMeetOrgUnitAthleteEntryState.arrayData}
            isLoading={omeMeetOrgUnitAthleteEntryState.isArrayLoading}
            onAddSecondaryRepToAthleteClicked={onAddSecondaryRepToAthleteClicked}
            onDeleteAthleteClicked={onDeleteAthleteClicked}
            allowRelayOnly={omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer ?? false}
            showSecondaryRep={omeMeetState.objData.allowSecondaryOrgUnitRepresentation ?? false}
          />
        </div>
        {omeMeetOrgUnitAthleteEntryState.arrayData?.length > 15 && omeMeetOrgUnitAthleteEntryState.isArrayLoading === false &&
          <div className='visible-md visible-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton className="usas-extra-top-margin" type="button" onClick={onAddAthlete}>Select Athletes</ActionIntraPageButton>
            </div>
          </div>
        }
        {omeMeetOrgUnitAthleteEntryState.arrayData?.length > 0 && omeMeetOrgUnitAthleteEntryState.isArrayLoading === false &&
          <div className='hidden-md hidden-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton className="usas-extra-top-margin" type="button" onClick={onAddAthlete}>Select Athletes</ActionIntraPageButton>
            </div>
          </div>
        }
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Name:</strong> {modalState?.athleteName}</p>
            <p><strong>Competition Category:</strong> {modalState?.competitionCategory}</p>
            <p><strong>Birth Date:</strong> {modalState?.birthDate}</p>
            <p><strong>Age at Meet:</strong> {modalState?.ageAtMeet}</p>
            <p><strong>Status:</strong> {modalState?.status}</p>
            {omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true &&
              <p><strong>Relay Only Swimmer?</strong> {modalState?.isRelayOnlyAthlete}</p>}
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteAthlete(e, modalState.orgUnitAthleteEntryId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={secondaryRepModalState.modalTitle}
        displayPopUp={secondaryRepModalState.displayPopUp}
        onModalCanceled={onSecondaryRepModalCanceled}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xs-12">
              <p><strong>Athlete:</strong> {secondaryRepModalState?.athleteName} ({secondaryRepModalState?.competitionCategory}, {secondaryRepModalState?.ageAtMeet})</p>
            </div>
            <div className="col-xs-12 usas-extra-bottom-margin">
              <Combobox
                label={`Secondary Representation (${Constants.USAS_ORG_UNIT_LABEL_LSC})`}
                name="secondaryOrgUnitName"
                isLoading={approvedClubsForSecondaryRepState.isArrayLoading}
                items={approvedClubsForSecondaryRepState.items || []}
                valueToMatch={formState.secondaryOrgUnitName}
                error={errorState.secondaryOrgUnitId}
                message={errorState.secondaryOrgUnitId}
                onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'secondaryOrgUnitId', newValueLabel, 'secondaryOrgUnitName', e); }}
              />
            </div>
            {approvedClubsForSecondaryRepState.isArrayLoading === false &&
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onSecondaryRepModalCanceled}>Cancel</SecondaryButton>
              </div>}
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryState.isSaving || athleteSecondaryRepresentationState.isSaving} />
    </Fragment >
  );
};

export default OrgUnitAdminRosterWrite;