import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import putOmeMeetOrgUnitEntryTimesData from './PutOmeMeetOrgUnitEntryTimesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitEntryTimesData = {
  INITIAL_STATE, putOmeMeetOrgUnitEntryTimesData
};

export default OmeMeetOrgUnitEntryTimesData;