/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import useMeetContextView from './UseMeetContextView';

import NavLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import PageNotFound from '../../../common/components/PageNotFound';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

import global from '../../../common/components/GlobalStyle.module.css';

const MeetContextView = ({ children }) => {
  const {
    meetState,
    meetCompetitionSummaryState,
    searchMeetState,
    backToLabel,
    onBackClicked,
    onHomeClicked
  }
    = useMeetContextView();

  if (!meetState || Object.keys(meetState.objData).length === 0) {
    return meetState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }
  return (
    <Fragment>
      <div className={searchMeetState.showMeetHeader === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.HeaderButton} onClick={onHomeClicked}>
              <Headings.H3
                className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                excludeBar={true}>
                View/Edit Meet
              </Headings.H3>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className={"row"}>
          <div className="col-xs-12 col-sm-12">
            <p className={global.HeaderText}><b>Meet Name:</b> {meetCompetitionSummaryState?.objData?.meetName || ''} </p>
            <p className={global.HeaderText}><b>Meet Dates:</b> {meetCompetitionSummaryState?.isObjLoaded ? `${meetCompetitionSummaryState?.objData?.startDate ? formatDate(meetCompetitionSummaryState.objData.startDate) : ''} - ${meetCompetitionSummaryState.objData.endDate ? formatDate(meetCompetitionSummaryState.objData.endDate) : ''}` : ''}</p>
            {meetCompetitionSummaryState?.objData?.meetPacketUrl && <p className={global.HeaderText}><b>Meet Packet:</b> <a href={meetCompetitionSummaryState?.objData?.meetPacketUrl} target="_blank" rel="noreferrer">Click Here to View</a></p>}
          </div>
        </div>
        <div className="row">
          <div className="visible-xs col-xs-12">
            <MobileContextBasedNav taxonomyName="MeetDetail" />
          </div>
          <div className="hidden-xs col-xs-12">
            <LargeContextBasedNav taxonomyName="MeetDetail" homeRoute={NavLinks.MEET_INFO} />
          </div>
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default MeetContextView;