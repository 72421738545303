import { useContext } from 'react';

import { ApprovedClubsForSecondaryRepStateContext } from './ApprovedClubsForSecondaryRepContextProvider';

import ApprovedClubsForSecondaryRepData from './ApprovedClubsForSecondaryRepData';

const useApprovedClubsForSecondaryRepData = () => {
  const [approvedClubsForSecondaryRepState, setApprovedClubsForSecondaryRepState] = useContext(ApprovedClubsForSecondaryRepStateContext);

  const getApprovedClubsForSecondaryRep = (lscId) => ApprovedClubsForSecondaryRepData.getApprovedClubsForSecondaryRepData(lscId, approvedClubsForSecondaryRepState, setApprovedClubsForSecondaryRepState);

  return {
    approvedClubsForSecondaryRepState,
    getApprovedClubsForSecondaryRep
  };
};

export default useApprovedClubsForSecondaryRepData;