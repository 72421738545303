import getMeetRecognition from './GetMeetRecognitionData';
import putMeetRecognition from './PutMeetRecognitionData';

import Constants from '../../../utils/Constants';

const INITIAL_STATE = {
  meetId: Constants.DEFAULT_ID,
  isArrayLoaded: false,
  arrayData: [],
  message: ''
};

const MeetRecognitionData = {
  INITIAL_STATE,
  getMeetRecognition,
  putMeetRecognition
};

export default MeetRecognitionData;
