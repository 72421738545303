import React, { Fragment } from 'react';

import OrgUnitAdminRelayEntriesTeamsWrite from './OrgUnitAdminRelayEntriesTeamsWrite';
import OrgUnitAdminRelayEntriesTeamsReadOnly from './OrgUnitAdminRelayEntriesTeamsReadOnly';

import useOrgUnitAdminRelayEntriesTeams from './UseOrgUnitAdminRelayEntriesTeams';

const OrgUnitAdminRelayEntriesTeams = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminRelayEntriesTeams();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminRelayEntriesTeamsWrite /> :
        <OrgUnitAdminRelayEntriesTeamsReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminRelayEntriesTeams;