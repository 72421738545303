import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLaneSheetRightSideInfoData = (laneSheetRightSideInfoObj, state, setState) => {
  if (laneSheetRightSideInfoObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = '/LaneSheetRightInfo';

    return api?.executeObject ? api.executeObject(url, 'POST', laneSheetRightSideInfoObj) : null;
  }
};


const getLaneSheetRightSideData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/RightHeader`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const deleteLaneSheetRightSideInfoData = (laneSheetRightInfoId, state, setState) => {
  if (laneSheetRightInfoId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetRightInfoIdForUrl = encodeURIComponent(laneSheetRightInfoId);
    const url = `/LaneSheetRightInfo/${laneSheetRightInfoIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LaneSheetRightSideData = {
  postLaneSheetRightSideInfoData,
  getLaneSheetRightSideData,
  deleteLaneSheetRightSideInfoData
};

export default LaneSheetRightSideData;