import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesData from './GetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesData';
import deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntryData from './DeleteOrgUnitAthleteEntryOrgUnitNonAthleteEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData = {
  INITIAL_STATE, getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesData, deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntryData
};

export default OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData;