import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitNonAthleteEntryData from './OmeMeetOrgUnitNonAthleteEntryData';

export const OmeMeetOrgUnitNonAthleteEntryStateContext = createContext();

const OmeMeetOrgUnitNonAthleteEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitNonAthleteEntryData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitNonAthleteEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitNonAthleteEntryStateContext.Provider>
  );
};

export default OmeMeetOrgUnitNonAthleteEntryContextProvider;