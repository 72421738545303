import { useContext } from 'react';

import { MeetStateContext } from './MeetContextProvider';
import { MeetFiltersStateContext } from './MeetFiltersContextProvider';

import MeetData from './MeetData';

const useMeetData = () => {
  const [meetState, setMeetState] = useContext(MeetStateContext);
  const [meetFiltersState, setMeetFiltersState] = useContext(MeetFiltersStateContext);

  const searchMeets = (filterObject, sortBy) => {
    setMeetFiltersState({ ...meetFiltersState, filterObject, sortBy });
    MeetData.searchMeets(JSON.stringify(filterObject), sortBy, meetState, setMeetState);
  };

  const getMeet = (meetId) => MeetData.getMeetData(meetId, meetState, setMeetState);
  const postMeet = (meetObj) => MeetData.postMeetData(meetObj, meetState, setMeetState);
  const putMeet = (meetId, meetObj) => MeetData.putMeetData(meetId, meetObj, meetState, setMeetState);
  const deleteMeet = (meetId) => MeetData.deleteMeetData(meetId, meetState, setMeetState);

  const clearObjData = () => {
    setMeetState({
      ...meetState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setMeetState({
      ...meetState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSave = () => {
    setMeetState({
      ...meetState,
      isSaved: false
    });
  };

  return {
    meetState,
    meetFiltersState,
    setMeetFiltersState,
    searchMeets,
    getMeet,
    postMeet,
    putMeet,
    deleteMeet,
    clearObjData,
    clearArrayData,
    confirmSave
  };
};

export default useMeetData;