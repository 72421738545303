import { Fragment } from 'react';

import useLaneSheetContextView from './UseLaneSheetContextView';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import PageNotFound from '../../../common/components/PageNotFound';

import { formatDate } from '../../../common/utils/DateFunctions';
import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const LaneSheetContextView = ({ children }) => {
  const {
    laneSheetState,
    onBackToLandingClicked,
    onHomeClicked
  } = useLaneSheetContextView();

  if (!laneSheetState || Object.keys(laneSheetState.objData).length === 0) {
    return laneSheetState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackToLandingClicked}>
                <LeftArrowIcon />  {'Back to Lane Sheet Landing'}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.HeaderButton} onClick={onHomeClicked}>
              <Headings.H3
                className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                excludeBar={true}>
                View/Edit Lane Sheet
              </Headings.H3>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToLandingClicked}>
              <LeftArrowIcon /> &nbsp;
              Back to Lane Sheet Landing
            </button>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-12 col-sm-12">
            <p className={global.HeaderText}><b>Meet Name:</b> {laneSheetState.objData?.meetName || ''} </p>
            <p className={global.HeaderText}><b>Host Organization:</b> {laneSheetState.objData?.organization || ''} </p>
            <p className={global.HeaderText}><b>Meet Dates:</b> {laneSheetState.objData?.meetStartDate ? formatDate(laneSheetState.objData.meetStartDate) : ''} - {laneSheetState.objData?.meetEndDate ? formatDate(laneSheetState.objData.meetEndDate) : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="visible-xs col-xs-12">
            <MobileContextBasedNav taxonomyName="LaneSheetDetail" renderPillsOnly={true} />
          </div>
          <div className="hidden-xs col-xs-12">
            <LargeContextBasedNav taxonomyName="LaneSheetDetail" renderPillsOnly={true} />
          </div>
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default LaneSheetContextView;