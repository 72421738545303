import React, { Fragment } from 'react';

import useLaneSheetRightSideNotesDetail from './UseLaneSheetRightSideNotesDetail';

import LaneSheetRightSideNotesDetailForm from '../../components/forms/LaneSheetRightSideNotesDetailForm';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const LaneSheetRightSideNotesDetail = () => {
  const {
    isSaving,
    rightSideDataLength,
    onSubmitFormCallback,
    onBackClicked
  } = useLaneSheetRightSideNotesDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Lane Sheet Right Side Notes Detail</Headings.H3>
        </div>
      </div>
      <LaneSheetRightSideNotesDetailForm
        rightSideDataLength={rightSideDataLength}
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LaneSheetRightSideNotesDetail;