import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../meet/NavLinks';
import MyMeetsNavLinks from '../../myMeets/MyMeetsNavLinks';
import MyAthleteEntryNavLinks from '../../myAthleteEntry/MyAthleteEntryNavLinks';
import EntryNavLinks from '../EntryNavLinks';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitEntryData from '../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useAthleteEntryContextData from '../../../state/athleteEntryContextView/UseAthleteEntryContextData';
import useMyAthleteMeetEntriesFiltersData from '../../../state/myAthleteMeetEntriesFilters/UseMyAthleteMeetEntriesFiltersData';

import useSearchMeetData from '../../../../common/state/searchMeet/UseSearchMeetData';
import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const useAthleteEntryContextView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { omeMeetState } = useOmeMeetData();
  const { clearOmeMeetOrgUnitEntryObjData } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, clearOmeMeetOrgUnitAthleteEntryIndividualRosterEntryObjData }
    = UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { athleteEntryContextState, setAthleteEntryContextState } = useAthleteEntryContextData();
  const { myAthleteMeetEntriesFiltersState } = useMyAthleteMeetEntriesFiltersData();
  const [state, setState] = useState({ orgUnitRegistrationStartDate: '', orgUnitRegistrationEndDate: '', orgUnitName: '' });
  const BACK_TO_LABELS = {
    entryBackToLabel: selectOrgUnitState.orgUnitId === '' ? 'Back to My Athlete Meet Entries' : omeMeetState.route === '' ? 'Back to My Meets' : 'Back to Athlete Entry Management',
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearOmeMeetOrgUnitEntryObjData();
    clearOmeMeetOrgUnitAthleteEntryIndividualRosterEntryObjData();

    if (omeMeetState.route === '') {
      if (selectOrgUnitState.orgUnitId === '') {
        //My Athlete Meet Entries Route
        navigate(MyAthleteEntryNavLinks.MY_ATHLETE_ENTRY, { state: { myAthleteMeetEntriesFiltersState: myAthleteMeetEntriesFiltersState } });
      }
      else {
        //OME Route
        navigate(MyMeetsNavLinks.MEET_ENTRY_ATHLETE, { state: { selectOrgUnitState: selectOrgUnitState } });
      }
    }
    else {
      //Meet Route
      setSearchMeetState({ ...searchMeetState, showMeetHeader: true });

      navigate(NavLinks.MEET_ENTRY_ATHLETE, { state: { goToMeet: true } });
    }
  };

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_ENTRIES);
    }
    else {
      navigate(NavLinks.ATHLETE_ENTRIES);
    }
  };

  useEffect(() => {
    setAthleteEntryContextState({
      ...athleteEntryContextState, isReadOnly: location.state?.isReadOnly ?? true,
      isUnattached: location?.state?.orgUnitName?.substring(0, 10) === 'Unattached' ? true : false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state) {
      setState({
        ...state,
        orgUnitRegistrationStartDate: location.state?.orgUnitRegistrationStartDate || '',
        orgUnitRegistrationEndDate: location.state?.orgUnitRegistrationEndDate || '',
        orgUnitName: location.state?.orgUnitName || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getBackToLabel() {
    return BACK_TO_LABELS.entryBackToLabel;
  };

  return {
    state,
    meetState,
    omeMeetState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    athleteEntryContextState,
    backToLabel: getBackToLabel(),
    onHomeClicked,
    onBackClicked
  };
};

export default useAthleteEntryContextView;