import Dropdown from '../Dropdown';
import useTimeZoneDropdown from './UseTimeZoneDropdown';

const TimeZoneDropdown = ({ label, name, value, error, message, onChange }) => {
  const { options } = useTimeZoneDropdown();

  return (
    <Dropdown
      error={error}
      label={label}
      message={message}
      options={options}
      name={name}
      value={value}
      onChange={onChange}
      isLoading={false}
    />
  );
};

export default TimeZoneDropdown;