import React, { Fragment } from 'react';

import useOrgUnitAdminRosterEntriesBonusSelection from './UseOrgUnitAdminRosterEntriesBonusIndividualSelection';

import OrgUnitAdminRosterEntriesSelectionGrid from './OrgUnitAdminRosterEntriesSelectionGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import EventCombobox from '../../../../../common/components/comboboxes/eventCombobox/EventCombobox';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterEntriesBonusIndividualSelection = () => {
  const { state, gridState, orgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    onAddEditOverrideEntryClicked, onSelectAll, onUnselectAll, onSaveClicked, onBackClicked, onCheckboxChange, onFilterClicked, onClearFilterClicked,
    onValueTextPairChanged, formState, errorState, omeMeetOrgUnitAthleteEntryState, modalState, onOpenModalClicked, onModalCanceled
  } = useOrgUnitAdminRosterEntriesBonusSelection();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Individual Bonus Event Selection</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <Headings.H4 className={global.HeaderText}>Athlete: {(state?.athlete?.firstName || state?.athlete?.middleName || state?.athlete?.lastName) ? `${state?.athlete?.firstName || ''} ${state?.athlete?.preferredName !== '' && state?.athlete?.preferredName !== state?.athlete?.firstName ? '"' + state?.athlete?.preferredName + '"' : ''} ${state?.athlete?.middleName || ''} ${state?.athlete?.lastName || ''}` : ''} ({state?.athlete?.competitionGenderTypeName || ''}, {state?.athlete?.ageAtMeet >= 0 ? state?.athlete?.ageAtMeet : ''})</Headings.H4>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <EventCombobox
              label={"Event"}
              name={"eventId"}
              valueToMatch={formState.eventName}
              error={errorState.eventId}
              message={errorState.eventId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName', e); }}
            />
          </div>
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </div>
      </form>
      {gridState.gridData.length > 15 && orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
        <div className='visible-md visible-lg'>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Checkboxes</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Checkboxes</SecondaryButton>
            </div>
          </div>
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div>}
      {gridState.gridData.length > 0 && orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
        <div className='hidden-md hidden-lg'>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Checkboxes</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Checkboxes</SecondaryButton>
            </div>
          </div>
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div>}
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <OrgUnitAdminRosterEntriesSelectionGrid
            gridData={gridState.gridData}
            isLoading={orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading}
            onCheckboxChange={onCheckboxChange}
            bonus={true}
            onAddOverrideEntryClicked={onOpenModalClicked}
            onEditOverrideEntryClicked={onOpenModalClicked}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSelectAll}>Select All Checkboxes</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Checkboxes</SecondaryButton>
        </div>
      </div>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p className={['text-center', global.Bold].join(' ')}>You are about to navigate away from this page without saving...</p>
            <p className='usas-extra-top-marign usas-extra-bottom-margin'></p>
            <p className='text-center'>Are you sure you want to continue? Any selection changes and new override times will not be saved!</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 text-center usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
            <PrimaryButton type="button" onClick={(e) => onAddEditOverrideEntryClicked(e)}>Continue</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitAthleteEntryIndividualRosterEntryState.isSaving} />
    </Fragment >
  );
};

export default OrgUnitAdminRosterEntriesBonusIndividualSelection;