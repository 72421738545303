import React, { createContext, useState } from 'react';

import EventMeetEventData from './EventMeetEventData';

export const EventMeetEventStateContext = createContext();

const EventMeetEventContextProvider = ({ children }) => {
  const stateHook = useState(EventMeetEventData.INITIAL_STATE);

  return (
    <EventMeetEventStateContext.Provider value={stateHook}>
      {children}
    </EventMeetEventStateContext.Provider>
  );
};

export default EventMeetEventContextProvider;