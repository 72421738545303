import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import PlusIcon from '../../../../../common/components/icons/PlusIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ meet, onAddClicked, onEditClicked }) => (
  <Fragment>
    {meet?.laneSheetMeetId === null
      ? <button
        className={global.IconButton}
        type="button"
        onClick={() => onAddClicked(meet)}>
        <PlusIcon />
      </button>
      : <button
        className={global.IconButton}
        type="button"
        onClick={() => onEditClicked(meet.laneSheetMeetId)}>
        <EditIcon />
      </button>
    }
  </Fragment>
);

const SmallGridRow = ({ meet, onAddClicked, onEditClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span>{meet?.meetName || ''} </span>&nbsp;
      <SmallGridRowIcons
        meet={meet}
        onAddClicked={onAddClicked}
        onEditClicked={onEditClicked} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet Name
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {meet?.meetName || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Host Organization
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {meet?.organization || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet Start Date - Meet End Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {meet?.dateRange || ''}
        </div>
      </div>
    </div>
  </div>
);

const LaneSheetLandingSmallGrid = ({ isLoading, gridData, onAddClicked, onEditClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((meet, i) =>
          <SmallGridRow
            key={i}
            meet={meet}
            onAddClicked={onAddClicked}
            onEditClicked={onEditClicked} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
          </div>
        )
    }
  </div>
);

export default LaneSheetLandingSmallGrid;