import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const SelectionsOrgLevelStateContext = createContext();

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  organizationCode: '',
  levelCode: '',
  options: [],
  areOptionsLoaded: false
};

const SelectionsOrgLevelContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsOrgLevelStateContext.Provider value={stateHook}>
      {children}
    </SelectionsOrgLevelStateContext.Provider>
  );
};

export default SelectionsOrgLevelContextProvider;