import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  let maxNumberOfAthletesForMeet = formState.maxNumberOfAthletesForMeet ? formState.maxNumberOfAthletesForMeet.toString().replace(',', '') : '';
  if (formState.maxNumberOfAthletesForMeet !== '' && (!isValidNumber(ToIntIfInt(maxNumberOfAthletesForMeet))
    || ToIntIfInt(maxNumberOfAthletesForMeet) > 2000 || ToIntIfInt(maxNumberOfAthletesForMeet) === 0)) {
    errors.maxNumberOfAthletesForMeet = 'Number must be between 1 and 2,000';
  }

  if (formState.maxEventsPerIndividualPerMeet !== '' && (!isValidNumber(formState.maxEventsPerIndividualPerMeet)
    || ToIntIfInt(formState.maxEventsPerIndividualPerMeet) > 20 || ToIntIfInt(formState.maxEventsPerIndividualPerMeet) === 0)) {
    errors.maxEventsPerIndividualPerMeet = 'Number must be between 1 and 20';
  }

  if (formState.maxEventsPerIndividualPerSession !== '' && (!isValidNumber(formState.maxEventsPerIndividualPerSession)
    || ToIntIfInt(formState.maxEventsPerIndividualPerSession) > 20 || ToIntIfInt(formState.maxEventsPerIndividualPerSession) === 0)) {
    errors.maxEventsPerIndividualPerSession = 'Number must be between 1 and 20';
  }

  //Max events per invidual per session must be less than or equal to max events per individual per meet
  if (formState.maxEventsPerIndividualPerSession !== '' && formState.maxEventsPerIndividualPerMeet !== '') {
    if (ToIntIfInt(formState.maxEventsPerIndividualPerSession) > ToIntIfInt(formState.maxEventsPerIndividualPerMeet)) {
      errors.maxEventsPerIndividualPerSession = 'Number cannot be greater than the Max Events Per Individual Per Meet';
      errors.maxEventsPerIndividualPerMeet = 'Number cannot be less than the Max Events Per Individual Per Session';
    }
  }

  if (formState.useAdjustedSwimTime !== true && formState.useAdjustedSwimTime !== false) {
    errors.useAdjustedSwimTime = 'A selection is required';
  }

  if (formState.relayEvents !== true && formState.relayEvents !== false) {
    errors.relayEvents = 'A selection is required';
  }

  if (formState.relayEvents === true && formState.allowRelayOnlySwimmer === true && formState.maxNumberOfAthletesForMeet !== '' && formState.includeRelayOnlySwimmersInMaxNumber !== false &&
    formState.includeRelayOnlySwimmersInMaxNumber !== true) {
    errors.includeRelayOnlySwimmersInMaxNumber = 'A selection is required when a Max Number of Athletes For Meet is provided and the Meet has Relay Events and Allows Relay Only Swimmers';
  }

  if (formState.relayEvents === true && formState.allowRelayOnlySwimmer === true && formState.includeRelaySwimmerForCoachPasses !== false
    && formState.includeRelaySwimmerForCoachPasses !== true) {
    errors.includeRelaySwimmerForCoachPasses = 'A selection is required when the Meet has Relay Events and Allows Relay Only Swimmers';
  }

  if (formState.relayEvents === true && formState.relayTeamFromRosterOnly !== false &&
    formState.relayTeamFromRosterOnly !== true) {
    errors.relayTeamFromRosterOnly = 'A selection is required when the Meet has Relay Events';
  }

  if (formState.relayEvents === true && formState.allowRelayOnlySwimmer !== false &&
    formState.allowRelayOnlySwimmer !== true) {
    errors.allowRelayOnlySwimmer = 'A selection is required when the Meet has Relay Events';
  }

  if (formState.relayEvents === true && formState.maxRelayTeamEntriesPerEvent === '') {
    errors.maxRelayTeamEntriesPerEvent = 'A number is required when the Meet has Relay Events';
  } else if (!isValidNumber(formState.maxRelayTeamEntriesPerEvent)
    || ToIntIfInt(formState.maxRelayTeamEntriesPerEvent) > ToIntIfInt(formState.MAX_RELAY_TEAM_ENTRIES_PER_EVENT) || ToIntIfInt(formState.maxRelayTeamEntriesPerEvent) === 0) {
    errors.maxRelayTeamEntriesPerEvent = `Number must be between 1 and ${formState.MAX_RELAY_TEAM_ENTRIES_PER_EVENT}`;
  }

  if (formState.maxRelayEventsPerIndividualPerMeet !== '' && (!isValidNumber(formState.maxRelayEventsPerIndividualPerMeet)
    || ToIntIfInt(formState.maxRelayEventsPerIndividualPerMeet) > 10 || ToIntIfInt(formState.maxRelayEventsPerIndividualPerMeet) === 0)) {
    errors.maxRelayEventsPerIndividualPerMeet = 'Number must be between 1 and 10';
  }

  if (formState.bonusEvents !== true && formState.bonusEvents !== false) {
    errors.bonusEvents = 'A selection is required';
  }

  return errors;
};

const OmeEligibilityNonHQValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeEligibilityNonHQValidation;