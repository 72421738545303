import React, { Fragment } from 'react';

import useLaneSheetTopHeaderNotesDetail from './UseLaneSheetTopHeaderNotesDetail';

import LaneSheetTopHeaderNotesDetailForm from '../../components/forms/LaneSheetTopHeaderNotesDetailForm';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const LaneSheetTopHeaderNotesDetail = () => {
  const {
    isSaving,
    topHeaderDataLength,
    onSubmitFormCallback,
    onBackClicked
  } = useLaneSheetTopHeaderNotesDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Lane Sheet Top Header Notes Detail</Headings.H3>
        </div>
      </div>
      <LaneSheetTopHeaderNotesDetailForm
        topHeaderDataLength={topHeaderDataLength}
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LaneSheetTopHeaderNotesDetail;