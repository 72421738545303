import EditIcon from '../../../../../common/components/icons/EditIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

const OrgUnitAdminContactsSmallGrid = ({ gridData, isLoading, onEditContact, onDeleteContactClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ?
        gridData.map((contact) =>
        (<div className={global.SmallTableRow} key={contact.personId}>
          <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(contact.firstName || contact.middleName || contact.lastName) ? `${contact.firstName || ''}  ${contact.preferredName !== '' && contact.preferredName !== contact.firstName ? '"' + contact.preferredName + '"' : ''} ${contact.middleName || ''} ${contact.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
            {onDeleteContactClicked &&
              < button className={global.IconButton}
                type="button"
                onClick={(e) => onDeleteContactClicked(e, contact)}>
                <DeleteIcon />
              </button>}
            {onEditContact &&
              <button className={gridData.length > 1 ? global.IconButtonMobileMargin : global.IconButton}
                type="button"
                onClick={(e) => onEditContact(e, contact)}>
                <EditIcon />
              </button>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{(contact.firstName || contact.middleName || contact.lastName) ? `${contact.firstName || ''}  ${contact.preferredName !== '' && contact.preferredName !== contact.firstName ? '"' + contact.preferredName + '"' : ''} ${contact.middleName || ''} ${contact.lastName || ''}` : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Email</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{contact.emailAddress || <span>&nbsp;</span>}</div>
            </div>
          </div>
        </div>)
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Entry Contacts</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminContactsSmallGrid;