import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import validate from './TeamEntrySelectOrgUnitValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetTeamEntryData from '../../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import usePersonOrgRoleData from '../../../../../common/state/personOrgRole/UsePersonOrgRoleData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useTeamEntryOrgUnitSelection = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { omeMeetState, clearOmeMeetArrayData, setOmeMeetState } = useOmeMeetData();
    const { omeMeetOrgUnitEntryState, postOmeMeetOrgUnitEntry, clearOmeMeetOrgUnitEntryObjData,
        clearOmeMeetOrgUnitEntryArrayData } = useOmeMeetOrgUnitEntryData();
    const { clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
    const { personOrgRoleState, clearArrayData } = usePersonOrgRoleData();
    const { searchMeetState, setSearchMeetState } = useSearchMeetData();
    const [state, setState] = useState({ radioButtonList: [], showRadioButtonList: false, tryRedirect: false });
    const { handleSubmit, formState, errorState, onFormValueChanged
    } = UseForm(getInitialFormState, submitFormCallback, validate);

    const onBackClicked = (e) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }
        clearOmeMeetOrgUnitEntryObjData();
        clearOmeMeetOrgUnitEntryArrayData();
        clearOmeMeetArrayData();
        clearOmeMeetTeamEntryArrayData();
        clearArrayData();

        navigate(NavLinks.MEET_ENTRY_TEAM);
    };

    function submitFormCallback() {
        handlePost();
    };

    function handlePost() {
        postOmeMeetOrgUnitEntry(omeMeetState.objData.omeMeetId, createOmeMeetOrgUnitEntryObject());
        setOmeMeetState({ ...omeMeetState, route: 'meet' });
        setState({ ...state, tryRedirect: true });
    };

    function createOmeMeetOrgUnitEntryObject() {
        const todaysDateTime = new Date();
        return {
            omeMeetId: omeMeetState.objData.omeMeetId,
            orgUnitId: formState.orgUnitId ? formState.orgUnitId : state.radioButtonList[0]?.value,
            orgUnitEntryDatetime: todaysDateTime,
            orgUnitSubmissionDatetime: null,
            orgUnitRegistrationStartDate: null,
            orgUnitRegistrationEndDate: null,
            status: formState.orgUnitId ? (state.radioButtonList && state.radioButtonList?.find(x => x.orgUnitId === formState.orgUnitId)?.label === 'Unattached' ? Constants.WORKFLOW_STATUS_UNATTACHED : Constants.WORKFLOW_STATUS_IN_PROGRESS) :
                (state.radioButtonList[0]?.label === 'Unattached' ? Constants.WORKFLOW_STATUS_UNATTACHED : Constants.WORKFLOW_STATUS_IN_PROGRESS),
            orgUnitAthleteRegistrationAllowed: formState.orgUnitId ? (state.radioButtonList && state.radioButtonList?.find(x => x.orgUnitId === formState.orgUnitId)?.label === 'Unattached' ? true : null) :
                (state.radioButtonList[0]?.label === 'Unattached' ? true : null),
        }
    };

    useEffect(() => {
        if (location.state.availableOrgUnits?.length > 0) {
            setState({ ...state, radioButtonList: location.state.availableOrgUnits, showRadioButtonList: location.state.availableOrgUnits.length > 1 ? true : false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (personOrgRoleState.isArrayLoading === false && personOrgRoleState.isArrayLoaded === true &&
            state.radioButtonList.length === 1 && state.radioButtonList[0].value &&
            omeMeetState.isArrayLoaded === true && omeMeetState.arrayData.length > 0) {
            handlePost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personOrgRoleState.isArrayLoading, personOrgRoleState.isArrayLoaded, state.radioButtonList, omeMeetState.arrayData, omeMeetState.isArrayLoaded])

    useEffect(() => {
        if (state.tryRedirect === true && omeMeetOrgUnitEntryState.isSaving === false &&
            omeMeetOrgUnitEntryState.isSaved === true && omeMeetState.route === 'meet') {
            setSearchMeetState({ ...searchMeetState, showMeetHeader: false });
            navigate(NavLinks.OU_ADMIN_LANDING, { state: { isReadOnly: false } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [omeMeetOrgUnitEntryState, state.tryRedirect, omeMeetState.route])

    function getInitialFormState() {
        return { orgUnitId: '' };
    };

    return {
        state,
        handleSubmit,
        formState,
        errorState,
        onFormValueChanged,
        personOrgRoleState,
        omeMeetState,
        omeMeetOrgUnitEntryState,
        onBackClicked
    };
};

export default useTeamEntryOrgUnitSelection;