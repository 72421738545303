import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const LaneSheetTopHeaderStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const LaneSheetTopHeaderContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LaneSheetTopHeaderStateContext.Provider value={stateHook}>
      {children}
    </LaneSheetTopHeaderStateContext.Provider>
  );
};

export default LaneSheetTopHeaderContextProvider;