import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLaneSheetMeetEventRelayTeamOrgUnitData = (laneSheetMeetEventRelayTeamObj, state, setState) => {
  if (laneSheetMeetEventRelayTeamObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/LaneSheetRelayTeam/OrgUnit`;

    return api?.executeObject ? api.executeObject(url, 'POST', laneSheetMeetEventRelayTeamObj) : null;
  }
};

const getLaneSheetMeetEventRelayTeamsData = (laneSheetEventId, state, setState) => {
  if (laneSheetEventId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetEventIdForUrl = encodeURIComponent(laneSheetEventId);
    const url = `/LaneSheetEvent/${laneSheetEventIdForUrl}/RelayTeam`
   
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const deleteLaneSheetMeetEventRelayTeamData = (laneSheetRelayTeamId, state, setState) => {
  if (laneSheetRelayTeamId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetRelayTeamIdForUrl = encodeURIComponent(laneSheetRelayTeamId);
    const url = `/LaneSheetRelayTeam/${laneSheetRelayTeamIdForUrl}`;
    
    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LaneSheetMeetEventRelayTeamsData = {
  postLaneSheetMeetEventRelayTeamOrgUnitData,
  getLaneSheetMeetEventRelayTeamsData,
  deleteLaneSheetMeetEventRelayTeamData
};

export default LaneSheetMeetEventRelayTeamsData;