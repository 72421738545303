import React, { Fragment } from 'react';

import useOrgUnitAdminRosterEntries from './UseOrgUnitAdminRosterEntries';

import OrgUnitAdminRosterEntriesGrid from './OrgUnitAdminRosterEntriesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterEntriesReadOnly = () => {
  const { gridState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryState,
    formState, errorState, onFormValueChanged } = useOrgUnitAdminRosterEntries();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster Entries</Headings.H3>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-xs-12">
            <YesNoSwitch
              label="Only Show Athletes Requiring Event Approval?"
              name="onlyShowAthletesRequiringEventApproval"
              checked={formState.onlyShowAthletesRequiringEventApproval}
              error={errorState.onlyShowAthletesRequiringEventApproval}
              message={errorState.onlyShowAthletesRequiringEventApproval}
              onChange={(value) => { onFormValueChanged('onlyShowAthletesRequiringEventApproval', value) }} />
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-xs-12">
          <OrgUnitAdminRosterEntriesGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading} />
    </Fragment>
  );
};

export default OrgUnitAdminRosterEntriesReadOnly;