import { Fragment } from 'react';

import useLaneSheetAddMeetInfo from './UseLaneSheetAddMeetInfo';

import LaneSheetAddMeetInfoForm from '../components/forms/LaneSheetAddMeetInfoForm';

import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LaneSheetAddMeetInfo = () => {
  const {
    isSaving,
    meet,
    onSubmitFormCallback,
    onBackClicked
  } = useLaneSheetAddMeetInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackClicked}>
            <LeftArrowIcon /> &nbsp;
            Back to Lane Sheet Landing
          </button>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Name:</b> {meet.meetName || ''} </p>
          <p className={global.HeaderText}><b>Host Organization:</b> {meet.organization || ''} </p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {meet.dateRange || ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Meet Info For Lane Sheet</Headings.H3>
        </div>
      </div>
      {meet.meetId > 0 &&
        <LaneSheetAddMeetInfoForm onSubmitFormCallback={onSubmitFormCallback} />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LaneSheetAddMeetInfo;