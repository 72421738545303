import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import EntryNavLinks from './EntryNavLinks';

import OmeMeetContextProvider from '../../state/omeMeet/OmeMeetContextProvider';
import OmeMeetOrgUnitEntryContextProvider from '../../state/omeMeetOrgUnitEntry/OmeMeetOrgUnitEntryContextProvider';
import OmeMeetTeamEntryContextProvider from '../../state/omeMeetTeamEntry/OmeMeetTeamEntryContextProvider';
import OmeMeetTeamEntryFiltersContextProvider from '../../state/omeMeetTeamEntry/OmeMeetTeamEntryFiltersContextProvider';
import OmeMeetOrgUnitEntryPurchaseContextProvider from '../../state/omeMeetOrgUnitEntryPurchase/OmeMeetOrgUnitEntryPurchaseContextProvider';
import OrgUnitAthleteEntryPurchaseContextProvider from '../../state/orgUnitAthleteEntryPurchase/OrgUnitAthleteEntryPurchaseContextProvider';
import OmeMeetOrgUnitAthleteEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntry/OmeMeetOrgUnitAthleteEntryContextProvider';
import OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider from '../../state/omeMeetOrgUnitAthleteEntryRosterSelection/OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider';
import OmeMeetOrgUnitNonAthleteEntryContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntry/OmeMeetOrgUnitNonAthleteEntryContextProvider';
import OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntryCoachesSelection/OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider';
import OrgGroupContextProvider from '../../state/orgGroup/OrgGroupContextProvider';
import OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider';
import OrgUnitAthleteEntryContextIndividualRosterEntryProvider from '../../state/orgUnitAthleteEntry/OrgUnitAthleteEntryIndividualRosterEntryContextProvider';
import OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntryRelayRosterEntry/OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider';
import RelayAthleteMeetEventContextProvider from '../../state/relayAthleteMeetEvent/RelayAthleteMeetEventContextProvider';
import RelayAthleteEntryTimeContextProvider from '../../state/relayAthleteEntryTime/RelayAthleteEntryTimeContextProvider';
import OUAdminEntryContextContextProvider from '../../state/ouAdminEntryContextView/OUAdminEntryContextContextProvider';
import EventMeetEventBonusContextProvider from '../../state/eventMeetEventBonus/EventMeetEventBonusContextProvider';
import EventMeetEventContextProvider from '../../state/eventMeetEvent/EventMeetEventContextProvider';
import AthleteEntryContextContextProvider from '../../state/athleteEntryContextView/AthleteEntryContextContextProvider';
import OmeMeetOrgUnitEntryTimesContextProvider from '../../state/omeMeetOrgUnitEntryTimes/OmeMeetOrgUnitEntryTimesContextProvider';
import OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider from '../../state/omeMeetOrgUnitAthleteEntryAggregateRelayAthlete/OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider';
import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry/OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider';
import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider from '../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection/OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider';
import ApprovedClubsForSecondaryRepContextProvider from '../../state/approvedClubsForSecondaryRep/ApprovedClubsForSecondaryRepContextProvider';
import OmeMeetInvitationsContextProvider from '../../state/omeMeetInvitations/OmeMeetInvitationsContextProvider';
import OmeMeetOrgUnitEntryContactContextProvider from '../../state/omeMeetOrgUnitEntryContact/OmeMeetOrgUnitEntryContactContextProvider';

import MeetContextProvider from '../../../common/state/meet/MeetContextProvider';
import MeetFiltersContextProvider from '../../../common/state/meet/MeetFiltersContextProvider';
import SearchMeetContextProvider from '../../../common/state/searchMeet/SearchMeetContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

import OmeEntryLoading from './omeEntryLoading/OmeEntryLoading';
import OrgUnitAdminLanding from './orgUnitAdminUser/orgUnitAdminLanding/OrgUnitAdminLanding';
import OrgUnitAdminLandingReadOnly from './orgUnitAdminUser/orgUnitAdminLanding/OrgUnitAdminLandingReadOnly';
import OrgUnitAdminContacts from './orgUnitAdminUser/orgUnitAdminContacts/OrgUnitAdminContacts';
import OrgUnitAdminContactsReadOnly from './orgUnitAdminUser/orgUnitAdminContacts/OrgUnitAdminContactsReadOnly';
import OrgUnitAdminContactsDetail from './orgUnitAdminUser/orgUnitAdminContacts/OrgUnitAdminContactsDetail';
import OrgUnitAdminRosterPermissions from './orgUnitAdminUser/orgUnitAdminRosterPermissions/OrgUnitAdminRosterPermissions';
import OrgUnitAdminRosterPermissionsReadOnly from './orgUnitAdminUser/orgUnitAdminRosterPermissions/OrgUnitAdminRosterPermissionsReadOnly';
import OrgUnitAdminRoster from './orgUnitAdminUser/orgUnitAdminRoster/OrgUnitAdminRoster';
import OrgUnitAdminRosterReadOnly from './orgUnitAdminUser/orgUnitAdminRoster/OrgUnitAdminRosterReadOnly';
import OrgUnitAdminRosterSelection from './orgUnitAdminUser/orgUnitAdminRoster/OrgUnitAdminRosterSelection';
import OrgUnitAdminRosterEntries from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntries';
import OrgUnitAdminRosterEntriesReadOnly from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesReadOnly';
import OrgUnitAdminRosterEntriesDetail from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesDetail';
import OrgUnitAdminRelayEntries from './orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntries';
import OrgUnitAdminRelayEntriesReadOnly from './orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesReadOnly';
import OrgUnitAdminRelayEntriesTeams from './orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesTeams';
import OrgUnitAdminRelayEntriesTeamsReadOnly from './orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesTeamsReadOnly';
import OrgUnitAdminRelayEntriesDetail from './orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesDetail';
import OrgUnitAdminRelayEntriesDetailOverride from './orgUnitAdminUser/orgUnitAdminRelayEntries/OrgUnitAdminRelayEntriesDetailOverride';
import OrgUnitAdminRosterEntriesIndividualSelection from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesIndividualSelection';
import OrgUnitAdminRosterEntriesBonusIndividualSelection from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesBonusIndividualSelection';
import OrgUnitAdminRosterEntriesIndividualOverride from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesIndividualOverride';
import OrgUnitAdminRosterEntriesBonusIndividualOverride from './orgUnitAdminUser/orgUnitAdminRosterEntries/OrgUnitAdminRosterEntriesBonusIndividualOverride';
import OrgUnitAdminCoaches from './orgUnitAdminUser/orgUnitAdminCoaches/OrgUnitAdminCoaches';
import OrgUnitAdminCoachesReadOnly from './orgUnitAdminUser/orgUnitAdminCoaches/OrgUnitAdminCoachesReadOnly';
import OrgUnitAdminCoachesSelection from './orgUnitAdminUser/orgUnitAdminCoaches/OrgUnitAdminCoachesSelection';
import OrgUnitAdminPurchases from './orgUnitAdminUser/orgUnitAdminPurchases/OrgUnitAdminPurchases';
import OrgUnitAdminPurchasesReadOnly from './orgUnitAdminUser/orgUnitAdminPurchases/OrgUnitAdminPurchasesReadOnly';
import OrgUnitAdminPayments from './orgUnitAdminUser/orgUnitAdminPayments/OrgUnitAdminPayments';
import OrgUnitAdminPaymentsHQ from './orgUnitAdminUser/orgUnitAdminPayments/OrgUnitAdminPaymentsHQ';
import OrgUnitAdminOfficialsWrite from './orgUnitAdminUser/orgUnitAdminOfficials/OrgUnitAdminOfficialsWrite';
import OrgUnitAdminReportingMyMeets from './orgUnitAdminUser/orgUnitAdminReportingMyMeets/OrgUnitAdminReportingMyMeets';

import AthleteUserEntriesDetail from './athleteUser/athleteUserEntries/AthleteUserEntriesDetail';
import AthleteUserEntriesDetailReadOnly from './athleteUser/athleteUserEntries/AthleteUserEntriesDetailReadOnly';
import AthleteUserEntriesIndividualSelection from './athleteUser/athleteUserEntries/AthleteUserEntriesIndividualSelection';
import AthleteUserEntriesBonusIndividualSelection from './athleteUser/athleteUserEntries/AthleteUserEntriesBonusIndividualSelection';
import AthleteUserEntriesIndividualOverride from './athleteUser/athleteUserEntries/AthleteUserEntriesIndividualOverride';
import AthleteUserEntriesBonusIndividualOverride from './athleteUser/athleteUserEntries/AthleteUserEntriesBonusIndividualOverride';
import AthleteUserCoaches from './athleteUser/athleteUserCoaches/AthleteUserCoaches';
import AthleteUserCoachesReadOnly from './athleteUser/athleteUserCoaches/AthleteUserCoachesReadOnly';
import AthleteUserCoachesSelection from './athleteUser/athleteUserCoaches/AthleteUserCoachesSelection';
import AthleteUserPurchases from './athleteUser/athleteUserPurchases/AthleteUserPurchases';
import AthleteUserPurchasesReadOnly from './athleteUser/athleteUserPurchases/AthleteUserPurchasesReadOnly';
import AthleteUserPayments from './athleteUser/athleteUserPayments/AthleteUserPayments';
import OUAdminEntryContextView from './orgUnitAdminUser/OUAdminEntryContextView';
import AthleteEntryContextView from './athleteUser/AthleteEntryContextView';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const OUAdminEntryContextRoutes = ({ navRoutes }) => {
  return (
    <OUAdminEntryContextView>
      <Routes>
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_LANDING']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_LANDING']}
              editElement={<OrgUnitAdminLanding />}
              readOnlyElement={<OrgUnitAdminLandingReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_CONTACTS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_CONTACTS']}
              editElement={<OrgUnitAdminContacts />}
              readOnlyElement={<OrgUnitAdminContactsReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_CONTACTS_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_CONTACTS_DETAIL']}
              editElement={<OrgUnitAdminContactsDetail />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTERPERMISSIONS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTERPERMISSIONS']}
              editElement={<OrgUnitAdminRosterPermissions />}
              readOnlyElement={<OrgUnitAdminRosterPermissionsReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER']}
              editElement={<OrgUnitAdminRoster />}
              readOnlyElement={<OrgUnitAdminRosterReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_SELECTION']}
              editElement={<OrgUnitAdminRosterSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES']}
              editElement={<OrgUnitAdminRosterEntries />}
              readOnlyElement={<OrgUnitAdminRosterEntriesReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_DETAIL']}
              editElement={<OrgUnitAdminRosterEntriesDetail />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_SELECTION']}
              editElement={<OrgUnitAdminRosterEntriesIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION']}
              editElement={<OrgUnitAdminRosterEntriesBonusIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE']}
              editElement={<OrgUnitAdminRosterEntriesIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']}
              editElement={<OrgUnitAdminRosterEntriesBonusIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES']}
              editElement={<OrgUnitAdminRelayEntries />}
              readOnlyElement={<OrgUnitAdminRelayEntriesReadOnly />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES_TEAMS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES_TEAMS']}
              editElement={<OrgUnitAdminRelayEntriesTeams />}
              readOnlyElement={<OrgUnitAdminRelayEntriesTeamsReadOnly />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL']}
              editElement={<OrgUnitAdminRelayEntriesDetail />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_RELAY_ENTRIES_DETAIL_OVERRIDE']}
              editElement={<OrgUnitAdminRelayEntriesDetailOverride />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_COACHES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_COACHES']}
              editElement={<OrgUnitAdminCoaches />}
              readOnlyElement={<OrgUnitAdminCoachesReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_COACHES_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_COACHES_SELECTION']}
              editElement={<OrgUnitAdminCoachesSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_PURCHASES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_PURCHASES']}
              editElement={<OrgUnitAdminPurchases />}
              readOnlyElement={<OrgUnitAdminPurchasesReadOnly />} />
          }
        />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_PAYMENTS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_PAYMENTS']}
              editElement={<OrgUnitAdminPaymentsHQ />}
              readOnlyElement={<OrgUnitAdminPayments />} />
          } />
        <Route path={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_OFFICIALS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_TEAM_OUA_ROSTER_OFFICIALS']}
              editElement={<OrgUnitAdminOfficialsWrite />} />
          }
        />
        <Route path={navRoutes['OME_TEAMENTRY_REPORTING']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_TEAMENTRY_REPORTING']}
              editElement={<OrgUnitAdminReportingMyMeets />} />
          }
        />
        <Route path={'/'} element={<Navigate to={navRoutes['OME_ENTRY_TEAM_OUA_LANDING']?.route} replace />} />
      </Routes>
    </OUAdminEntryContextView>
  )
}

const AthleteEntryContextRoutes = ({ navRoutes }) => {
  return (
    <AthleteEntryContextView>
      <Routes>
        <Route path={navRoutes['OME_ENTRY_ATHLETE_ENTRIES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_ENTRIES']}
              editElement={<AthleteUserEntriesDetail />}
              readOnlyElement={<AthleteUserEntriesDetailReadOnly />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_SELECTION']}
              editElement={<AthleteUserEntriesIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION']}
              editElement={<AthleteUserEntriesBonusIndividualSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_INDIVIDUAL_OVERRIDE']}
              editElement={<AthleteUserEntriesIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE']}
              editElement={<AthleteUserEntriesBonusIndividualOverride />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_COACHES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_COACHES']}
              editElement={<AthleteUserCoaches />}
              readOnlyElement={<AthleteUserCoachesReadOnly />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_COACHES_SELECTION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_COACHES_SELECTION']}
              editElement={<AthleteUserCoachesSelection />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_PURCHASES']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_PURCHASES']}
              editElement={<AthleteUserPurchases />}
              readOnlyElement={<AthleteUserPurchasesReadOnly />}
            />
          }
        />
        <Route path={navRoutes['OME_ENTRY_ATHLETE_PAYMENTS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OME_ENTRY_ATHLETE_PAYMENTS']}
              editElement={<AthleteUserPayments />}
            />
          }
        />
        <Route path={'/'} element={<Navigate to={navRoutes['OME_ENTRY_ATHLETE_ENTRIES']?.route} replace />} />
      </Routes>
    </AthleteEntryContextView>
  )
}

const EntryRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OME_ENTRY_LOADING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_ENTRY_LOADING']}
            editElement={<OmeEntryLoading teamRedirectNavLink={EntryNavLinks.OU_ADMIN_LANDING} athleteRedirectNavLink={EntryNavLinks.ATHLETE_ENTRIES} />} />
        }
      />
      <Route path={navRoutes['OME_ENTRY_TEAM_OUA']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_ENTRY_TEAM_OUA']}
            editElement={<OUAdminEntryContextRoutes navRoutes={navRoutes} />} />
        }
      />
      <Route path={navRoutes['OME_ENTRY_ATHLETE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_ENTRY_ATHLETE']}
            editElement={<AthleteEntryContextRoutes navRoutes={navRoutes} />} />
        }
      />
    </Routes>
  );
};

const EntryContextProviders = ({ children }) => (
  <MeetContextProvider>
    <MeetFiltersContextProvider>
      <SearchMeetContextProvider>
        <OmeMeetContextProvider>
          <OmeMeetInvitationsContextProvider>
            <OmeMeetOrgUnitEntryContextProvider>
              <OmeMeetTeamEntryContextProvider>
                <OmeMeetTeamEntryFiltersContextProvider>
                  <OrgUnitAthleteEntryPurchaseContextProvider>
                    <OmeMeetOrgUnitEntryPurchaseContextProvider>
                      <OmeMeetOrgUnitAthleteEntryContextProvider>
                        <OmeMeetOrgUnitNonAthleteEntryContextProvider>
                          <OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider>
                            <PersonOrgRoleContextProvider>
                              <OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider>
                                <OmeMeetOrgUnitEntryContactContextProvider>
                                  <OrgGroupContextProvider>
                                    <OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider>
                                      <OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider>
                                        <OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider>
                                          <OrgUnitAthleteEntryContextIndividualRosterEntryProvider>
                                            <OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider>
                                              <OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider>
                                                <RelayAthleteMeetEventContextProvider>
                                                  <RelayAthleteEntryTimeContextProvider>
                                                    <EventMeetEventContextProvider>
                                                      <EventMeetEventBonusContextProvider>
                                                        <AthleteEntryContextContextProvider>
                                                          <OUAdminEntryContextContextProvider>
                                                            <OmeMeetOrgUnitEntryTimesContextProvider>
                                                              <ApprovedClubsForSecondaryRepContextProvider>
                                                                {children}
                                                              </ApprovedClubsForSecondaryRepContextProvider>
                                                            </OmeMeetOrgUnitEntryTimesContextProvider>
                                                          </OUAdminEntryContextContextProvider>
                                                        </AthleteEntryContextContextProvider>
                                                      </EventMeetEventBonusContextProvider>
                                                    </EventMeetEventContextProvider>
                                                  </RelayAthleteEntryTimeContextProvider>
                                                </RelayAthleteMeetEventContextProvider>
                                              </OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider>
                                            </OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider>
                                          </OrgUnitAthleteEntryContextIndividualRosterEntryProvider>
                                        </OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider>
                                      </OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider>
                                    </OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider>
                                  </OrgGroupContextProvider>
                                </OmeMeetOrgUnitEntryContactContextProvider>
                              </OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider>
                            </PersonOrgRoleContextProvider>
                          </OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider>
                        </OmeMeetOrgUnitNonAthleteEntryContextProvider>
                      </OmeMeetOrgUnitAthleteEntryContextProvider>
                    </OmeMeetOrgUnitEntryPurchaseContextProvider>
                  </OrgUnitAthleteEntryPurchaseContextProvider>
                </OmeMeetTeamEntryFiltersContextProvider>
              </OmeMeetTeamEntryContextProvider>
            </OmeMeetOrgUnitEntryContextProvider>
          </OmeMeetInvitationsContextProvider>
        </OmeMeetContextProvider>
      </SearchMeetContextProvider>
    </MeetFiltersContextProvider>
  </MeetContextProvider>
);

const EntryContextStateAndRoutes = () => (
  <EntryContextProviders>
    <EntryRoutes />
  </EntryContextProviders>
);

export default EntryContextStateAndRoutes;