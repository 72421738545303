import { useContext } from 'react';

import { LaneSheetTemplateStateContext } from './LaneSheetTemplateContextProvider';

import LaneSheetTemplateData from './LaneSheetTemplateData';

const useLaneSheetTemplateData = () => {
  const [laneSheetTemplateState, setLaneSheetTemplateState] = useContext(LaneSheetTemplateStateContext);

  const getLaneSheetTemplates = () => {
    return LaneSheetTemplateData.getLaneSheetTemplates(laneSheetTemplateState, setLaneSheetTemplateState);
  };

  return {
    laneSheetTemplateState,
    setLaneSheetTemplateState,
    getLaneSheetTemplates
  };
};

export default useLaneSheetTemplateData;