import React from 'react';

import useOrgGroupCombobox from './UseOrgGroupCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';

import global from '../../../../common/components/GlobalStyle.module.css';

const OrgGroupCombobox = ({ label, name, valueToMatch, error, message, onChange, orgUnitId, groupTypeId }) => {
  const { orgGroupState } = useOrgGroupCombobox(orgUnitId, groupTypeId);

  return orgGroupState.message
    ? <span className={global.LoadingMsg}>{orgGroupState.message}</span>
    :
    <Combobox
      label={label}
      name={name}
      isLoading={orgGroupState.isArrayLoading}
      items={orgGroupState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default OrgGroupCombobox;