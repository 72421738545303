import { formatDate } from '../../../../../common/utils/DateFunctions';

const CRLF = "\r\n";
const padding = "";
const HEADER_MAX_LENGTH = 190;

const sdifStrokeMap = {
  'FR': { value: '1' },
  'BK': { value: '2' },
  'BR': { value: '3' },
  'FL': { value: '4' },
  'IM': { value: '5' },
  'FR-R': { value: '6' },
  'MED-R': { value: '7' }
}

const sdifCourseMap = {
  'SCM': { value: 'S' },
  'SCY': { value: 'Y' },
  'LCM': { value: 'L' }
}

const convertTimeToSeconds = (time) => {
  if (!time) return '';

  const trailingZeros = time.substring(time.length - 5, time.length);
  if (trailingZeros === "00000") {
    time = time.slice(0, 11);
  }

  const [hr, min, sec] = time.split(":");
  const hoursToSeconds = parseInt(hr) * 60 * 60;
  const minsToSeconds = parseInt(min) * 60;
  const seconds = parseInt(sec.split(".")[0]);
  const milliseconds = sec.split(".")[1] || "00";

  let timeString = (hoursToSeconds + minsToSeconds + seconds).toString() + "." + milliseconds;

  return timeString = milliseconds.length === 1 ? timeString + "0" : timeString;
}

function getChecksumVal(line) {
  let sumOfCharacters = 0;

  for (let i = 1; i < HEADER_MAX_LENGTH; i += 2) {
    sumOfCharacters += line?.charCodeAt(i);
  }

  return sumOfCharacters;
}

function formatDateMMDD(dateStr) {
  if (dateStr.indexOf("/") === -1) return dateStr;

  const [m, d, y] = dateStr.split("/");
  const month = m.length === 1 ? `0${m}` : m;
  const day = d.length === 1 ? `0${d}` : d;
  return `${month}/${day}/${y}`;
}

// function testCalculateMeetExportChecksum() {
//   const headerLine = "CREATEDBY           USA Swimming                                                                    10/31/20221.0a      2022 Speedo Junior National Championships                   08/01/20228381                                                                                                                                                                                          ";
//   let line1 = "TEAM                MN                            FOXJ                          Foxjets Swim Team             Foxjets Swim Team             foxjet@comcast.net                                                                                  PO Box 46665                                                                                        Eden Prairie        MN                  55344-0665";
//   let line2 = "DECKPASSPERSON      041764LLORLARS                Larsen                        Lloyd                         Head Coach                    ";

//   const headerLine = "CREATEDBY           USA Swimming                                                                    07/28/20211.0a      Speedo Summer Championships - Greensboro                    08/03/2021";
//   let line1 = "TEAM                PV                            RMSC                          Rockville Montgomery Swim ClubRockville Montgomery Swim Clubmeldridg@verizon.net                              2407776830                                        18000 Central Park Cir                                                                              Boyds               MD                  20841-9707";
//   let line2 = "DECKPASSPERSON      111282MATSCOHE                Cohen                         Matthew                       Head Coach                    ";

//   line1 = line1.length > HEADER_MAX_LENGTH ? line1.substring(0, HEADER_MAX_LENGTH) : line1.padEnd(HEADER_MAX_LENGTH);
//   line2 = line2.length > HEADER_MAX_LENGTH ? line2.substring(0, HEADER_MAX_LENGTH) : line2.padEnd(HEADER_MAX_LENGTH);

//   const line1Chk = getChecksumVal(line1);
//   const line2Chk = getChecksumVal(line2);
//   const headerChk = getChecksumVal(headerLine);

//   const val = (line1Chk + line2Chk + headerChk) * 3.6;
//   const firstThree = parseInt(val.toString().substring(0, 3));
//   const checksum = parseInt(val) + firstThree;

//   return checksum.toString().split("").reverse().join("").substring(0, 4);

// }

function calculateMeetExportChecksum(headerLine, fileString) {
  const fileArray = fileString.toString().replace(/\r\n/g, '\n').split('\n');

  const filteredFileArray = [];
  let recsAdded = 1;
  for (let i = 0; i < fileArray.length; i += 1) {
    if (fileArray[i].length > 0) {
      filteredFileArray.push(fileArray[i]);
      recsAdded++;
    }
    if (recsAdded > 2) break;
  }

  let line1 = filteredFileArray[0];
  let line2 = filteredFileArray[1];

  line1 = line1?.length > HEADER_MAX_LENGTH ? line1.substring(0, HEADER_MAX_LENGTH) : line1?.padEnd(HEADER_MAX_LENGTH);
  line2 = line2?.length > HEADER_MAX_LENGTH ? line2.substring(0, HEADER_MAX_LENGTH) : line2?.padEnd(HEADER_MAX_LENGTH);

  const line1Chk = getChecksumVal(line1);
  const line2Chk = getChecksumVal(line2);
  const headerChk = getChecksumVal(headerLine);

  const val = (line1Chk + line2Chk + headerChk) * 3.6;
  const firstThree = parseInt(val.toString().substring(0, 3));
  const checksum = parseInt(val) + firstThree;

  return checksum.toString().split("").reverse().join("").substring(0, 4);
}

function buildTeamRec(team) {
  const email = team.clubContactEmail?.toString() || '';
  const phone = team.clubContactPhoneNumber?.toString() || '';
  const parentOrgUnitCode = team.parentOrgUnitCode?.toString() || 'US';
  const address1 = team.address1?.toString() || '';
  const address2 = team.address2?.toString() || '';
  const city = team.city?.toString() || '';
  const stateCode = team.stateCode?.toString() || '';
  const postalCode = team.postalCode?.toString() || '';

  const teamInfo = `TEAM                ${parentOrgUnitCode.padEnd(30)}${team.orgUnitCode.padEnd(30)}${team.orgUnitName.padEnd(30)}${team.orgUnitName.padEnd(30)}`;
  const teamContact1 = `${email.padEnd(50)}${phone.padEnd(50)}${address1.padEnd(50)}${address2.padEnd(50)}`;
  const teamContact2 = `${city.padEnd(20)}${stateCode.padEnd(20)}${postalCode.padEnd(10)}`;

  return teamInfo + teamContact1 + teamContact2 + CRLF;
}

function buildDeckPassRec(deckpass) {
  return `DECKPASSPERSON      ${deckpass.memberId.padEnd(30)}${deckpass.lastName.padEnd(30)}${deckpass.firstName.padEnd(30)}${deckpass.deckPassPosition.padEnd(30)}${CRLF}`;
}

function buildSelectionSwimmerRec(team, swimmer) {
  const isForeignTF = swimmer.isUsCitizen === true ? "F" : "T";
  const genderCode = swimmer.competitionGenderTypeId === 1 ? "M" : "F";
  const birthDate = formatDate(swimmer.birthDate);
  const hytekCode = swimmer.hyTekEquivalentCode?.toString() || '';
  const rosterStatusTF = "F"; //??
  const isRookie = "F";

  const part1 = `SWIMMER             ${swimmer.memberId.padEnd(30)}${swimmer.lastName.padEnd(30)}${swimmer.firstName.padEnd(30)}${birthDate.padEnd(30)}`;
  const part2 = `${genderCode.padEnd(50)}${isForeignTF.padEnd(50)}${rosterStatusTF.padEnd(50)}${team.padEnd(50)}`;
  const part3 = `${isRookie.padEnd(20)}${hytekCode.padEnd(30)}${CRLF}`;

  return part1 + part2 + part3;
}

function buildSwimmerRec(swimmer) {
  const isForeignTF = swimmer.isForeign === true ? "T" : "F";
  const rosterStatusTF = swimmer.rosterStatus === 1 ? "T" : "F";
  const birthDate = formatDate(swimmer.birthDate);
  const genderCode = swimmer.competitionGenderTypeCode;
  const secondaryRecognitionTeamName = swimmer.secondaryRecognitionTeamName?.toString() || '';
  const hytekCode = swimmer.hytekCode?.toString() || '';

  const part1 = `SWIMMER             ${swimmer.memberId.padEnd(30)}${swimmer.lastName.padEnd(30)}${swimmer.firstName.padEnd(30)}${birthDate.padEnd(30)}`;
  //const part2 = `${genderCode.padEnd(50)}${isForeignTF.padEnd(50)}${rosterStatusTF.padEnd(30)}${secondaryRecognitionTeamName.padEnd(30)}`;
  const part2 = `${genderCode.padEnd(50)}${isForeignTF.padEnd(50)}${rosterStatusTF.padEnd(50)}${secondaryRecognitionTeamName.padEnd(70)}`;
  //const part3 = `${hytekCode.padEnd(80)}${CRLF}`;
  const part3 = `${hytekCode.padEnd(30)}${CRLF}`;

  return part1 + part2 + part3;
}

function buildRelayOnlySwimmerRec(orgUnitName, relayOnlySwimmer) {
  const isForeignTF = relayOnlySwimmer.isForeign === true ? "T" : "F";
  const genderCode = relayOnlySwimmer.competitionGenderTypeId === 1 ? "M" : "F";
  const birthDate = formatDate(relayOnlySwimmer.birthDate);
  const hytekCode = relayOnlySwimmer.hyTekEquivalentCode?.toString() || '';

  const part1 = `SWIMMERRELAYONLY    ${relayOnlySwimmer.memberId.padEnd(30)}${relayOnlySwimmer.lastName.padEnd(30)}${relayOnlySwimmer.firstName.padEnd(30)}${birthDate.padEnd(30)}`;
  const part2 = `${genderCode.padEnd(50)}${isForeignTF.padEnd(50)}${orgUnitName.padEnd(120)}${hytekCode.padEnd(30)}${CRLF}`;

  return part1 + part2;
}

function formatEvent(event) {
  const [stroke, distance, course] = event.split(" ");

  return `${stroke} ${distance}  ${course}`;
}

function buildEntryRec(entry) {
  const isOverrideTF = entry.swimTimeEntryOverride === 1 ? "T" : "F";
  const [dist, stroke, course] = entry.eventCode.split(" ");
  const sdifStroke = sdifStrokeMap[stroke].value;
  const sdifCourse = sdifCourseMap[course].value;
  const sdifEventCode = `${sdifStroke} ${dist} ${sdifCourse}`;
  const sdifEntryCourseCode = sdifCourseMap[entry.courseCode].value;
  const isQualifiedAsBonusTF = entry.qualifiedAsBonus === true ? "T" : "F";
  const entryTime = convertTimeToSeconds(entry.entryTime);
  const ageGroupCode = `00${entry.minAge}0${entry.maxAge}`;

  return `EVENT               ${sdifEventCode.padEnd(30)}${entryTime.padEnd(30)}${isOverrideTF.padEnd(30)}${sdifEntryCourseCode.padEnd(30)}${isQualifiedAsBonusTF.padEnd(50)}${ageGroupCode.padEnd(200)}${CRLF}`;
}

function buildEventRec(event) {
  const isOverrideTF = event.swimTimeEntryOverride === true ? "T" : "F";
  const isQualifiedAsBonusTF = event.qualifiedAsBonus === true ? "T" : "F";
  const entryTime = event.entryTime === "NT" ? event.entryTime : convertTimeToSeconds(event.entryTime);
  const sdifEntryCourseCode = event.sdifEntryCourseCode === null ? "" : event.sdifEntryCourseCode

  return `EVENT               ${formatEvent(event.sdifEventCode).padEnd(30)}${entryTime.padEnd(30)}${isOverrideTF.padEnd(30)}${sdifEntryCourseCode.padEnd(30)}${isQualifiedAsBonusTF.padEnd(50)}${event.ageGroupCode.padEnd(50)}${CRLF}`;
}

function buildSelectionRelayRec(relay) {
  const [dist, stroke, course] = relay.eventCode.split(" ");
  const sdifStroke = sdifStrokeMap[stroke].value;
  const sdifCourse = sdifCourseMap[course].value;
  const sdifEventCode = `${sdifStroke} ${dist} ${sdifCourse}`;
  const sdifEntryCourseCode = sdifCourseMap[relay.courseCode].value;

  const entryTime = convertTimeToSeconds(relay.entryTime);
  const isOverrideTF = "F" //relay.swimTimeEntryOverride === 1 ? "T" : "F"; //how do we get this??
  const genderCode = relay.competitionGenderTypeId === 1 ? "M" : "F";
  const relayEntryType = relay.isAggregate = 1 ? "A" : "T";
  const relaySeedLowest = "N"; //the purpose of this in legacy is unkown - only 95 entries have this set to 'Y' and they are all recent
  const minNonConferenceTime = "3599.59"; //the purpose of this in legacy is unkown - legacy export files have 0.00
  const bonusTF = "F"; //relay.bonusTF 
  //TODO: redo ageGroupCode - this only works for 1-99
  const ageGroupCode = `00${relay.minAge}0${relay.maxAge}`;

  const part1 = `RELAY               ${sdifEventCode.padEnd(30)}${entryTime.toString().padEnd(30)}${sdifEntryCourseCode.padEnd(30)}${isOverrideTF.padEnd(30)}`;
  const part2 = `${relay.relayTeamName.padEnd(50)}${genderCode.padEnd(50)}${relayEntryType.padEnd(50)}${relaySeedLowest.padEnd(50)}`;
  const part3 = `${minNonConferenceTime.padEnd(20)}${bonusTF.padEnd(20)}${ageGroupCode.padEnd(10)}`;

  return part1 + part2 + part3 + CRLF;
}

function buildRelayRec(relay) {
  const entryTime = convertTimeToSeconds(relay.entryTime);
  const isOverrideTF = relay.swimTimeEntryOverride === true ? "T" : "F";
  const genderCode = relay.eventCompetitionGenderTypeCode;
  const relayEntryType = relay.isAggregate = 1 ? "A" : "T";
  const relaySeedLowest = "N"; //the purpose of this in legacy is unkown - only 95 entries have this set to 'Y' and they are all recent
  const minNonConferenceTime = "0.00"; //the purpose of this in legacy is unkown - legacy export files have 0.00
  const bonusTF = "F"; //relay.bonusTF 

  const part1 = `RELAY               ${formatEvent(relay.sdifEventCode).padEnd(30)}${entryTime.toString().padEnd(30)}${relay.sdifEntryCourseCode.padEnd(30)}${isOverrideTF.padEnd(30)}`;
  const part2 = `${relay.relayTeamName.padEnd(50)}${genderCode.padEnd(50)}${relayEntryType.padEnd(50)}${relaySeedLowest.padEnd(50)}`;
  const part3 = `${minNonConferenceTime.padEnd(20)}${bonusTF.padEnd(20)}${relay.ageGroupCode.padEnd(10)}`;

  return part1 + part2 + part3 + CRLF;
}

function buildRelayLegRec(isAggregate, leg, legNumber) {
  const relayType = isAggregate = 1 ? "AGGREGATE" : "TEAM";
  const legEntryTime = convertTimeToSeconds(leg.legEntryTime);

  return `NAMESRELAYLEG${legNumber}      ${relayType.toUpperCase().padEnd(30)}${leg.lastName.padEnd(30)}${leg.firstName.padEnd(30)}${legEntryTime.padEnd(30)}${leg.legAge.toString().padEnd(50)}${CRLF}`;
}

const buildCreatedByRec = (objDt, fileString, isSelection = false) => {
  const version = "1.0a";
  const today = new Date();
  //const today = new Date("07/07/2022"); -- for testing
  const fileDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
  const meetDate = formatDate(objDt.meetStartDate);
  let headerLine = "";

  if (isSelection === false) {
    headerLine = `CREATEDBY           USA Swimming${padding.padEnd(68)}${formatDateMMDD(fileDate)}${version}     ${objDt.meetName.padEnd(61)}${meetDate}`;
  } else {
    headerLine = `CREATEDBY           USA Swimming${padding.padEnd(68)}${formatDateMMDD(fileDate)}${version}      ${objDt.meetName.padEnd(60)}${meetDate}`;
  }
  const checksum = calculateMeetExportChecksum(headerLine.padEnd(190), fileString);
  //const checksumTest = testCalculateMeetExportChecksum(); // compare to legacy export checksum -> 2022 Speedo Junior National Championships
  //console.log("check sum => ", checksumTest);

  return headerLine + checksum.padEnd(190) + CRLF;
}

const createTeams = (teams, isSelection = false) => {
  let teamsString = "";

  teams?.forEach(team => {
    teamsString += CRLF + buildTeamRec(team);

    const deckpasses = team.deckPassPersons === undefined ? [] : team.deckPassPersons;
    deckpasses?.forEach(deckpass => {
      teamsString += buildDeckPassRec(deckpass);
    });

    const swimmers = team.swimmers === null ? [] : team.swimmers;
    swimmers?.forEach(swimmer => {
      if (isSelection) {
        teamsString += buildSelectionSwimmerRec(team.orgUnitName, swimmer);
      }
      else {
        teamsString += buildSwimmerRec(swimmer);
      }

      if (isSelection) {
        const entries = swimmer.entries === undefined ? [] : swimmer.entries;

        entries?.forEach(entry => {
          teamsString += buildEntryRec(entry);
        });

      } else {
        const events = swimmer.events === undefined ? [] : swimmer.events;
        events?.forEach(event => {
          teamsString += buildEventRec(event);
        });
      };
    });

    let relayOnlySwimmers = null;

    //looks like the json format coming from the selections API changed at some point. 
    if (isSelection) {
      relayOnlySwimmers = team.relayOnlySwimmers === null ? [] : team.relayOnlySwimmers;
    }
    else {
      relayOnlySwimmers = team.swimmerRelayOnly === null ? [] : team.swimmerRelayOnly;
    }

    relayOnlySwimmers?.forEach(relayOnlySwimmer => {
      teamsString += buildRelayOnlySwimmerRec(team.orgUnitName, relayOnlySwimmer);
    });

    const relays = team.relays === null ? [] : team.relays;
    relays?.forEach(relay => {
      if (isSelection === false) {
        teamsString += buildRelayRec(relay);
      } else {
        teamsString += buildSelectionRelayRec(relay);
      }

      if (isSelection === false) {
        const relayLeg = relay.legs === null ? [] : relay.legs;
        let legNum = 1;
        relayLeg?.forEach(leg => {
          teamsString += buildRelayLegRec(relay.isAggregate, leg, legNum++);
        });
      }
    });

  });

  return teamsString;
}

function buildUnattachedSwimmerRec(unattachedSwimmer) {
  const genderCode = unattachedSwimmer.competitionGenderTypeCode;
  const birthDate = formatDate(unattachedSwimmer.birthDate);
  const isForeignYN = unattachedSwimmer.isForeign === true ? "Y" : "N";

  const part1 = `UNATTACHEDSWIMMER   ${unattachedSwimmer.parentOrgUnitCode.padEnd(30)}${unattachedSwimmer.orgUnitCode.padEnd(30)}${unattachedSwimmer.memberId.padEnd(30)}`;
  const part2 = `${unattachedSwimmer.lastName.padEnd(30)}${unattachedSwimmer.firstName.padEnd(50)}${birthDate.padEnd(50)}${genderCode.padEnd(50)}${isForeignYN.padEnd(90)}`;

  return part1 + part2 + CRLF;
}

const createUnattachedSwimmers = (unattached) => {
  let unattachedString = "";

  unattached?.forEach(unattachedSwimmer => {
    unattachedString += buildUnattachedSwimmerRec(unattachedSwimmer);

    const unattachedEvents = unattachedSwimmer.events === undefined ? [] : unattachedSwimmer.events;

    unattachedEvents?.forEach(event => {
      unattachedString += buildEventRec(event);
    });

    const deckPassPersons = unattachedSwimmer.deckPassPersons === undefined ? [] : unattachedSwimmer.deckPassPersons;

    deckPassPersons?.forEach(deckPass => {
      unattachedString += buildDeckPassRec(deckPass);
    })

  });

  return unattachedString;
}

const HyTekExport = {
  createTeams,
  buildCreatedByRec,
  createUnattachedSwimmers
};

export default HyTekExport;