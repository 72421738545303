
export const localValidate = (formState) => {
  let errors = {};

  // Competition Category
  if (formState.competitionCategoryId.length === 0) {
    errors.competitionCategoryId = `Must select a competition category`
  }

  // Event and Distance
  if (formState.eventNameAndDistanceId.length === 0) {
    errors.eventNameAndDistanceId = `Must select an event and distance`
  }

  return errors;
};

const LaneSheetMeetEventsAddEventValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetMeetEventsAddEventValidation;