import { useContext } from 'react';

import { OmeMeetInvitationsStateContext } from './OmeMeetInvitationsContextProvider';

import OmeMeetInvitationsData from './OmeMeetInvitationsData';

const useOmeMeetInvitationsData = () => {
  const [omeMeetInvitationsState, setOmeMeetInvitationsState] = useContext(OmeMeetInvitationsStateContext);

  const getOmeMeetInvitations = (omeMeetId) => OmeMeetInvitationsData.getOmeMeetInvitationsData(omeMeetId, omeMeetInvitationsState, setOmeMeetInvitationsState);
  const postOmeMeetInvitations = (omeMeetId, omeMeetInvitationsArray) => OmeMeetInvitationsData.postOmeMeetInvitationsData(omeMeetId, omeMeetInvitationsArray, omeMeetInvitationsState, setOmeMeetInvitationsState);

  const clearOmeMeetInvitationsObjData = () => {
    setOmeMeetInvitationsState({
      ...omeMeetInvitationsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetInvitationsArrayData = () => {
    setOmeMeetInvitationsState({
      ...omeMeetInvitationsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeetInvitations = () => {
    setOmeMeetInvitationsState({
      ...omeMeetInvitationsState,
      isSaved: false
    });
  };

  const resetOmeMeetInvitationsState = () => {
    setOmeMeetInvitationsState(OmeMeetInvitationsData.INITIAL_STATE);
  }

  return {
    omeMeetInvitationsState,
    setOmeMeetInvitationsState,
    getOmeMeetInvitations,
    postOmeMeetInvitations,
    clearOmeMeetInvitationsObjData,
    clearOmeMeetInvitationsArrayData,
    confirmSaveOmeMeetInvitations,
    resetOmeMeetInvitationsState
  };
};

export default useOmeMeetInvitationsData;