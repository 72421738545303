import getOrganization from '../meetTimes/meetTimesAdd/GetOrganizationData';
import getOrgUnitAthletes from './meetTimesAdd/GetOrgUnitAthletesData';
import getMeetTimes from './GetMeetTimesData';

import Constants from '../../../utils/Constants';

const INITIAL_STATE = {
  meetId: Constants.DEFAULT_ID,
  isArrayLoaded: false,
  arrayData: [],
  orgTreeViewIds: [],
  athleteTreeViewIds: [],
  message: ''
};

const MeetTimesData = {
  INITIAL_STATE,
  getOrganization,
  getOrgUnitAthletes,
  getMeetTimes
};

export default MeetTimesData;