import { Fragment } from 'react';

import LaneSheetMeetEventsLargeGrid from './LaneSheetMeetEventsLargeGrid';
import LaneSheetMeetEventsSmallGrid from './LaneSheetMeetEventsSmallGrid';

const LaneSheetMeetEventsGrid = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <LaneSheetMeetEventsLargeGrid
      isLoading={isLoading}
      gridData={gridData}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <LaneSheetMeetEventsSmallGrid
      isLoading={isLoading}
      gridData={gridData}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default LaneSheetMeetEventsGrid;
