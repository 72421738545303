import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeMeetData from './GetOmeMeetData';
import postOmeMeetData from './PostOmeMeetData';
import putOmeMeetData from './PutOmeMeetData';
import getMeetSummaryData from './GetMeetSummaryData';
import getMeetSummaryForHostIdData from './GetMeetSummaryForHostIdData';
import getOmeMeetOrgUnitEntryPermissionData from './GetOmeMeetOrgUnitEntryPermissionData'
import deleteOmeMeetData from './DeleteOmeMeetData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  selectedMeet: {},
  route: ''
};

const OmeMeetData = {
  INITIAL_STATE,
  getOmeMeetData,
  postOmeMeetData,
  putOmeMeetData,
  getMeetSummaryData,
  getMeetSummaryForHostIdData,
  getOmeMeetOrgUnitEntryPermissionData,
  deleteOmeMeetData
};

export default OmeMeetData;