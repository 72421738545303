import React, { Fragment } from 'react';

import useOmeMeetInfoCoursePriority from './UseOmeMeetInfoCoursePriority';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import CourseDropdown from '../../../../../common/components/dropdowns/courseDropdown/CourseDropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../../common/utils/Constants';

const OmeMeetInfoCoursePriority = () => {
  const {
    omeMeetState,
    omeMeetDateValidationState,
    courseState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onValueTextPairChanged,
    secondaryRepDeleteModalState,
    onSecondaryRepDeleteModalCanceled,
    handleSave
  } = useOmeMeetInfoCoursePriority();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_INFO_COURSE_PRIORITY}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              {formState.omeMeetEntryCourseId > 0 ?
                <ReadOnly
                  label="Course"
                  name="courseId"
                  value={formState.courseName}
                />
                :
                <CourseDropdown
                  label="Course*"
                  name="courseId"
                  value={formState.courseId}
                  error={errorState.courseId}
                  message={errorState.courseId}
                  poolOnlyOptions={false}
                  onChange={(value, newValueLabel, e) => {
                    e && e.target && e.target.value &&
                      onValueTextPairChanged(value, 'courseId', newValueLabel, 'courseName')
                  }}
                />}
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Priority*'
                name='priority'
                type='number'
                min='1'
                value={formState.priority}
                error={errorState.priority}
                message={errorState.priority}
                onChange={(value) => { onFormValueChanged('priority', value); }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={secondaryRepDeleteModalState.modalTitle}
          displayPopUp={secondaryRepDeleteModalState.displayPopUp}
          onModalCanceled={onSecondaryRepDeleteModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p>Changing 'Allow Secondary Representation for Athletes?' from 'Yes' to 'No' on the prior page will delete all Secondary Representation information from any teams that have started their entries.</p>
              <p><b>Along with saving the course priority, are you sure you would like to save any meet time zone, date, meet packet URL, and secondary representation changes from the prior page and make the associated deletes?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onSecondaryRepDeleteModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => handleSave(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={courseState.isLoading || omeMeetDateValidationState.isObjLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeMeetInfoCoursePriority;