import React, { Fragment } from 'react';

import useOrgUnitAdminContactsDetail from './UseOrgUnitAdminContactsDetail';

import Headings from '../../../../../common/components/headings/Headings';
import SearchEntryContactPopup from '../../../../../common/components/searches/searchMember/SearchEntryContactPopup';
import Input from '../../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminContactsDetail = () => {
  const { omeMeetOrgUnitEntryContactState, omeMeetOrgUnitEntryState, formState, errorState, setFormData, handleSubmit, onFormValueChanged,
    onSaveButtonClicked, onBackButtonClicked } = useOrgUnitAdminContactsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.omeMeetOrgUnitEntryContactId !== '' ? 'Edit' : 'Add'} Entry Contact</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchEntryContactPopup
              label={'Member Id*'}
              memberIdName={'memberId'}
              orgUnitId={omeMeetOrgUnitEntryState.objData?.orgUnitId}
              organzationName={omeMeetOrgUnitEntryState.objData?.orgUnit?.organization?.organizationName}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Email*"
              name="emailAddress"
              value={formState.emailAddress}
              error={errorState.emailAddress}
              message={errorState.emailAddress}
              onChange={(value) => { onFormValueChanged('emailAddress', value); }}
            />
          </div>
        </div>
        {errorState?.error &&
          <div className='row'>
            <div className='col-xs-12'>
              {errorState?.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState?.error}</p>}
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveButtonClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackButtonClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitEntryContactState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitEntryState.isObjLoading} />
    </Fragment>
  );
};

export default OrgUnitAdminContactsDetail;