import useOrgUnitTreeView from './UseOrgUnitTreeView';

import TreeView from '../TreeView';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const OrgUnitTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, organizationId }) => {
  const { orgUnitState } = useOrgUnitTreeView(organizationId || Constants.DEFAULT_ID);

  return orgUnitState.message
    ? <span className={global.LoadingMsg}>{orgUnitState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={orgUnitState.isArrayLoading}
      options={orgUnitState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default OrgUnitTreeView;