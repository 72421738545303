import React, { Fragment } from 'react';

import LaneSheetMeetEventAddRelayTeamsLargeGrid from './LaneSheetMeetEventAddRelayTeamsLargeGrid';
import LaneSheetMeetEventAddRelayTeamsSmallGrid from './LaneSheetMeetEventAddRelayTeamsSmallGrid';

const LaneSheetMeetEventAddRelayTeamsGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            <p>{gridData?.length === 1 ? `${gridData?.length} Relay Team` : `${gridData?.length} Relay Teams`}</p>
          </div>
        </div>
      )
      : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <LaneSheetMeetEventAddRelayTeamsLargeGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
    <LaneSheetMeetEventAddRelayTeamsSmallGrid
      isLoading={isLoading}
      gridData={gridData}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default LaneSheetMeetEventAddRelayTeamsGrid;