import React, { Fragment } from 'react';

import OrgUnitAdminRosterEntriesWrite from './OrgUnitAdminRosterEntriesWrite';
import OrgUnitAdminRosterEntriesReadOnly from './OrgUnitAdminRosterEntriesReadOnly';

import useOrgUnitAdminRosterEntries from './UseOrgUnitAdminRosterEntries';

const OrgUnitAdminRosterEntries = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminRosterEntries();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminRosterEntriesWrite /> :
        <OrgUnitAdminRosterEntriesReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminRosterEntries;