import React, { Fragment } from 'react';

import AthleteUserCoachesWrite from './AthleteUserCoachesWrite';
import AthleteUserCoachesReadOnly from './AthleteUserCoachesReadOnly';

import useAthleteUserCoaches from './UseAthleteUserCoaches';

const AthleteUserCoaches = () => {
  const { athleteEntryContextState } = useAthleteUserCoaches();

  return (
    <Fragment>
      {athleteEntryContextState.isReadOnly === false ?
        <AthleteUserCoachesWrite /> :
        <AthleteUserCoachesReadOnly />
      }
    </Fragment>
  );
};

export default AthleteUserCoaches;