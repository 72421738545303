import React, { Fragment } from 'react';

import useSelectionsConfiguration from './UseSelectionsConfiguration';

import SelectionsLoadingModal from '../components/dialogs/SelectionsLoadingModal';
import ConfigurationForm from '../components/forms/ConfigurationForm';
import SelectionMeetTimeStandardGrid from '../components/grids/SelectionMeetTimeStandardGrid';
import TimeStandardForm from '../components/forms/TimeStandardForm';
import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import ConfigurationReadOnlyForm from '../components/forms/ConfigurationReadOnlyForm';
import SelectionMeetTimeStandardReadOnlyGrid from '../components/grids/SelectionMeetTimeStandardReadOnlyGrid';
import { CONFIGURATION_NAME } from '../components/leftNav/SelectionsLeftNavConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const SelectionsConfiguration = () => {
  const {
    isLoading,
    isReadOnly,
    state,
    selectionsState,
    onContinueClicked,
    onConfigurationSubmitFormCallback,
    onTimeStandardSubmitFormCallback,
    onExitClicked,
    onTimeStandardFormCancelClicked,
    onAddTimeStandardClicked,
    onEditTimeStandardClicked,
    onDeleteTimeStandardClicked
  } = useSelectionsConfiguration();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={CONFIGURATION_NAME} viewTitle={state.showConfigurationForm ? 'Configuration' : state.showTimeStandardForm ? 'Configuration Time Standard Detail' : 'Title'}>
        {isReadOnly === true ? (
          <Fragment>
            <ConfigurationReadOnlyForm
              onPrimaryButtonClicked={onContinueClicked}
              onSecondaryButtonClicked={onExitClicked}
              primaryButtonText={'Continue'}
              secondaryButtonText={'Exit'}
              selectionsStateObj={selectionsState.objData}>
              <SelectionMeetTimeStandardReadOnlyGrid
                gridData={state.selectionMeetTimeStandard}
                isLoading={isLoading} />
            </ConfigurationReadOnlyForm>
          </Fragment>
        ) : (
          <Fragment>
            <div className={state.showConfigurationForm === true ? global.DisplayComponent : global.HideComponent}>
              <ConfigurationForm
                onSubmitFormCallback={onConfigurationSubmitFormCallback}
                onSecondaryButtonClicked={onExitClicked}
                submitButtonText={'Save & Continue'}
                secondaryButtonText={'Exit'}
                selectionsStateObj={selectionsState.objData}>
                <SelectionMeetTimeStandardGrid
                  gridData={state.selectionMeetTimeStandard}
                  isLoading={isLoading}
                  showError={state.showGridError}
                  errorText={state.gridErrorMessage}
                  onAddClicked={onAddTimeStandardClicked}
                  onEditClicked={onEditTimeStandardClicked}
                  onDeleteClicked={onDeleteTimeStandardClicked} />
              </ConfigurationForm>
            </div>
            {state.showTimeStandardForm === true &&
              <TimeStandardForm
                onSubmitFormCallback={onTimeStandardSubmitFormCallback}
                onSecondaryButtonClicked={onTimeStandardFormCancelClicked}
                submitButtonText={'Save'}
                secondaryButtonText={'Cancel'}
                selectionMeetTimeStandard={state.selectionMeetTimeStandard}
                editTimeStandardId={state.editTimeStandardId}
                orgUnitId={selectionsState.objData?.orgUnitId || ''}
                omeMeetId={selectionsState.objData?.omeMeetId || ''} />
            }
          </Fragment>
        )}
        <SelectionsLoadingModal isLoading={isLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
};

export default SelectionsConfiguration;