import React from 'react';

import Input from './Input';

import { formatTimeForInput, formatTimeForFilterObject } from '../../utils/TimesUtils';

const TimeInput = ({ error, className, label, name, min, max, step, preText, postText, value, onChange }) => {

  return (
    <Input
      label={label}
      name={name}
      error={error}
      message={error}
      value={formatTimeForInput(value)}
      onChange={(value) => { onChange(value, formatTimeForFilterObject(value)) }}
      className={className}
      min={min}
      max={max}
      step={step}
      preText={preText}
      postText={postText}
    />
  );
};

export default TimeInput;