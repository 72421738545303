export const localValidate = (formState) => {

  const errors = {};
  if (formState.orgUnitId === '') {
    errors.orgUnitId = 'Selection is required';
  }

  return errors;
};

const TeamEntrySelectOrgUnitValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default TeamEntrySelectOrgUnitValidation;