import { useContext } from 'react';

import LaneSheetRightSideData from './LaneSheetRightSideData';

import { LaneSheetRightSideStateContext } from './LaneSheetRightSideContextProvider';

const useLaneSheetRightSideData = () => {
  const [laneSheetRightSideState, setLaneSheetRightSideState] = useContext(LaneSheetRightSideStateContext);

  const postLaneSheetRightSideInfo = (laneSheetRightSideInfoObj, state, setState) => {
    return LaneSheetRightSideData.postLaneSheetRightSideInfoData(laneSheetRightSideInfoObj, state, setState);
  };

  const getLaneSheetRightSide = (laneSheetMeetId) => {
    return LaneSheetRightSideData.getLaneSheetRightSideData(laneSheetMeetId, laneSheetRightSideState, setLaneSheetRightSideState);
  };

  const deleteLaneSheetRightSideInfo = (laneSheetRightInfoId, state, setState) => {
    return LaneSheetRightSideData.deleteLaneSheetRightSideInfoData(laneSheetRightInfoId, state, setState);
  };

  return {
    laneSheetRightSideState,
    postLaneSheetRightSideInfo,
    getLaneSheetRightSide,
    deleteLaneSheetRightSideInfo
  };
};

export default useLaneSheetRightSideData;