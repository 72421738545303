import { useContext } from 'react';

import { OmeMeetOrgUnitNonAthleteEntryStateContext } from './OmeMeetOrgUnitNonAthleteEntryContextProvider';

import OmeMeetOrgUnitNonAthleteEntryData from './OmeMeetOrgUnitNonAthleteEntryData';

const useOmeMeetOrgUnitNonAthleteEntryData = () => {
  const [omeMeetOrgUnitNonAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryState] = useContext(OmeMeetOrgUnitNonAthleteEntryStateContext);

  const getOrgUnitNonAthleteEntryCoaches = (omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitNonAthleteEntryData.getOrgUnitNonAthleteEntryCoachesData(omeMeetOrgUnitEntryId, omeMeetOrgUnitNonAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryState);

  const getOrgUnitNonAthleteEntry = (orgUnitNonAthleteEntryId) =>
    OmeMeetOrgUnitNonAthleteEntryData.getOrgUnitNonAthleteEntryData(orgUnitNonAthleteEntryId, omeMeetOrgUnitNonAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryState);

  const postOrgUnitNonAthleteEntry = (orgUnitNonAthleteEntryObj) =>
    OmeMeetOrgUnitNonAthleteEntryData.postOrgUnitNonAthleteEntryData(orgUnitNonAthleteEntryObj, omeMeetOrgUnitNonAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryState);

  const putOrgUnitNonAthleteEntry = (orgUnitNonAthleteEntryId, orgUnitNonAthleteEntryObj) =>
    OmeMeetOrgUnitNonAthleteEntryData.putOrgUnitNonAthleteEntryData(orgUnitNonAthleteEntryId, orgUnitNonAthleteEntryObj, omeMeetOrgUnitNonAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryState);

  const deleteOrgUnitNonAthleteEntry = (orgUnitNonAthleteEntryId) =>
    OmeMeetOrgUnitNonAthleteEntryData.deleteOrgUnitNonAthleteEntryData(orgUnitNonAthleteEntryId, omeMeetOrgUnitNonAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryState);

  const clearOmeMeetOrgUnitNonAthleteEntryObjData = () => {
    setOmeMeetOrgUnitNonAthleteEntryState({
      ...omeMeetOrgUnitNonAthleteEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetOrgUnitNonAthleteEntryArrayData = () => {
    setOmeMeetOrgUnitNonAthleteEntryState({
      ...omeMeetOrgUnitNonAthleteEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeetOrgUnitNonAthleteEntry = () => {
    setOmeMeetOrgUnitNonAthleteEntryState({
      ...omeMeetOrgUnitNonAthleteEntryState,
      isSaved: false
    });
  };

  return {
    omeMeetOrgUnitNonAthleteEntryState,
    getOrgUnitNonAthleteEntryCoaches,
    getOrgUnitNonAthleteEntry,
    postOrgUnitNonAthleteEntry,
    putOrgUnitNonAthleteEntry,
    deleteOrgUnitNonAthleteEntry,
    clearOmeMeetOrgUnitNonAthleteEntryObjData,
    clearOmeMeetOrgUnitNonAthleteEntryArrayData,
    confirmSaveOmeMeetOrgUnitNonAthleteEntry
  };
};

export default useOmeMeetOrgUnitNonAthleteEntryData;