import React, { Fragment } from 'react';

import useOmeRestrictionsType from './UseOmeRestrictionsType';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import RadioButtonList from '../../../../../common/components/radiobuttonlists/RadiobuttonList';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

const OmeRestrictionsType = () => {
  const {
    orgUnitState,
    selectRestrictionTypeState,
    onSelectRestrictionType,
    errorState,
    formState,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  } = useOmeRestrictionsType();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_RESTRICTIONS_TYPE}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}>
        <form onSubmit={onSelectRestrictionType}>
          <div className="row">
            <div className="col-xs-12">
              <RadioButtonList
                label="Restriction Type*"
                name={"restrictionTypeName"}
                items={selectRestrictionTypeState}
                value={formState.restrictionTypeName}
                error={errorState.restrictionTypeName}
                message={errorState.restrictionTypeName}
                isVertical={true}
                onChange={(value) => { onFormValueChanged("restrictionTypeName", value) }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={orgUnitState.isObjLoading} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeRestrictionsType;