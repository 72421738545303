import { useEffect } from 'react';

import useOmeMeetHostData from '../../../state/omeMeetHost/UseOmeMeetHostData';
import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useOmeMeetAthleteEntryData from '../../../state/omeMeetAthleteEntry/UseOmeMeetAthleteEntryData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

const useMyMeetsLandingPage = () => {
  const { contextSecurityState } = useSecurityData();
  const { resetOmeMeetHostFiltersState, clearOmeMeetHostArrayData } = useOmeMeetHostData();
  const { resetOmeMeetTeamEntryFiltersState, clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
  const { resetOmeMeetAthleteEntryFiltersState, clearOmeMeetAthleteEntryArrayData } = useOmeMeetAthleteEntryData();

  useEffect(() => {
      //clear out any filters used in my meets views
      resetOmeMeetHostFiltersState();
      resetOmeMeetTeamEntryFiltersState();
      resetOmeMeetAthleteEntryFiltersState();

      //clear out any prior search results
      clearOmeMeetHostArrayData();
      clearOmeMeetTeamEntryArrayData();
      clearOmeMeetAthleteEntryArrayData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    contextSecurityState
  };
};

export default useMyMeetsLandingPage;