export const localValidate = (formState) => {
  let errors = {};
  if (formState.meetName.trim().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters'
  }

  if (formState.isCurrent !== false && formState.isCurrent !== true) {
    errors.isCurrent = 'Must select yes or no'
  }

  return errors;
};

const MeetTeamEntryValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MeetTeamEntryValidation;