export const localValidate = (formState) => {
  let errors = {};
  
  //TODO

  return errors;
};

const LaneSheetHeatAthletesFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetHeatAthletesFormValidation;