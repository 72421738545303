import { Fragment } from 'react';

import PersonIcon from '../../../../../common/components/icons/PersonIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ meetEvent, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    {meetEvent.canBeDeleted === true &&
      <button
        className={global.IconButton}
        type="button"
        onClick={() => onDeleteClicked(meetEvent)}>
        <DeleteIcon />
      </button>
    }
    <button
      className={meetEvent.canBeDeleted === true ? global.IconButtonMobileMargin : global.IconButton}
      type="button"
      onClick={() => onEditClicked(meetEvent)}>
      <PersonIcon />
    </button>
  </Fragment>
);

//TODO table not displaying correctly
const SmallGridRow = ({ meetEvent, onEditClicked, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span></span>&nbsp;
      <SmallGridRowIcons
        meetEvent={meetEvent}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Event Name / Code
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {meetEvent?.eventName || ''} / {meetEvent?.eventCode || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Competition Category
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {meetEvent?.eventCompetitionGenderTypeName || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Number Of Participants
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {meetEvent?.numberOfParticipants || 0}
        </div>
      </div>
    </div>
  </div>
);

const LaneSheetMeetEventsSmallGrid = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((meetEvent, i) =>
          <SmallGridRow
            key={i}
            meetEvent={meetEvent}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
          </div>
        )
    }
  </div>
);

export default LaneSheetMeetEventsSmallGrid;