import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddMeetTimeSelectOrgUnitsValidation';

import { createTreeViewOptions } from '../utils/MeetUtils';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

import NavLinks from '../../NavLinks';

const useAddMeetTimeSelectOrgUnits = () => {
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { meetTimesAddState, getOrganization, refreshMeetTimesAddState, updateOrgTreeViewIds } = useMeetTimesData();
  const { errorState, formState, handleSubmit, updateFormState, setIsDirty, setFormState
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [treeState, setTreeState] = useState(getInitialTreeState());

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    refreshMeetTimesAddState(Constants.DEFAULT_ID);
    navigate(NavLinks.MEET_TIMES);
  };

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      refreshMeetTimesAddState(meetState.objData.meetId);

      if (treeState.isArrayLoading === false) {
        const organizationId = meetState.objData?.hostOrgUnit?.organizationId;

        getOrganization(organizationId, treeState, setTreeState);
      }
    }

    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (treeState.isArrayLoaded === true) {
      const treeViewOptions = createTreeViewOptions(treeState.arrayData, 'id', 'name', meetTimesAddState.orgTreeViewIds);

      setTreeState({
        ...treeState,
        treeData: treeViewOptions.newTreeData
      });
      setFormState({
        ...formState,
        orgUnits: treeViewOptions.idNamePairs
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeState.isArrayLoaded]);

  function submitFormCallback(formState) {
    updateOrgTreeViewIds(formState.orgUnits);

    navigate(NavLinks.MEET_TIMES_SELECT_ATHLETES);
  };

  function getInitialFormState() {
    return {
      orgUnits: []
    };
  };

  function getInitialTreeState() {
    return {
      arrayData: [],
      treeData: [],
      isArrayLoading: false,
      isArrayLoaded: false
    };
  };

  return {
    formState,
    errorState,
    treeState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked
  };
}

export default useAddMeetTimeSelectOrgUnits;