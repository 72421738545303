import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import OmeMeetHostContextProvider from '../../state/omeMeetHost/OmeMeetHostContextProvider';
import OmeMeetHostFiltersContextProvider from '../../state/omeMeetHost/OmeMeetHostFiltersContextProvider';
import OmeMeetTeamEntryContextProvider from '../../state/omeMeetTeamEntry/OmeMeetTeamEntryContextProvider';
import OmeMeetTeamEntryFiltersContextProvider from '../../state/omeMeetTeamEntry/OmeMeetTeamEntryFiltersContextProvider';
import OmeMeetAthleteEntryContextProvider from '../../state/omeMeetAthleteEntry/OmeMeetAthleteEntryContextProvider';
import OmeMeetAthleteEntryFiltersContextProvider from '../../state/omeMeetAthleteEntry/OmeMeetAthleteEntryFiltersContextProvider';
import OmeMeetOrgUnitEntryContextProvider from '../../state/omeMeetOrgUnitEntry/OmeMeetOrgUnitEntryContextProvider';
import OmeMeetOrgUnitAthleteEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntry/OmeMeetOrgUnitAthleteEntryContextProvider';

import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';

import MyMeetsContextView from './MyMeetsContextView';

import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import MyMeetsLandingPage from './myMeetLandingPage/MyMeetsLandingPage';
import MeetHost from './meetHost/MeetHost';
import TeamEntry from './teamEntry/TeamEntry';
import AthleteEntry from './athleteEntry/AthleteEntry';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

const ContextRoutes = ({ navRoutes }) => (
  <MyMeetsContextView>
    <Routes>
      <Route path={navRoutes['MY_MEETS_LANDING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_MEETS_LANDING']}
            editElement={<MyMeetsLandingPage />} />
        }
      />
      <Route path={navRoutes['MY_MEETS_HOST']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_MEETS_HOST']}
            editElement={<MeetHost />} />
        }
      />
      <Route path={navRoutes['MY_MEETS_TEAM_ENTRY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_MEETS_TEAM_ENTRY']}
            editElement={<TeamEntry />} />
        }
      />
      <Route path={navRoutes['MY_MEETS_ATHLETE_ENTRY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_MEETS_ATHLETE_ENTRY']}
            editElement={<AthleteEntry />} />
        }
      />
    </Routes>
  </MyMeetsContextView>
);

const MyMeetsRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['MY_MEETS_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_MEETS_SELECTION']}
            editElement={<OrgUnitSelection />} />
        }
      />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['MY_MEETS_SELECTION']?.route} replace />} />
    </Routes>
  );
};

const MyMeetsContextProviders = ({ children }) => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <OmeMeetHostContextProvider>
        <OmeMeetHostFiltersContextProvider>
          <OmeMeetTeamEntryContextProvider>
            <OmeMeetTeamEntryFiltersContextProvider>
              <OmeMeetAthleteEntryContextProvider>
                <OmeMeetAthleteEntryFiltersContextProvider>
                  <OmeMeetOrgUnitAthleteEntryContextProvider>
                    <OmeMeetOrgUnitEntryContextProvider>
                      <ReportPeriodSeasonContextProvider>
                        {children}
                      </ReportPeriodSeasonContextProvider>
                    </OmeMeetOrgUnitEntryContextProvider>
                  </OmeMeetOrgUnitAthleteEntryContextProvider>
                </OmeMeetAthleteEntryFiltersContextProvider>
              </OmeMeetAthleteEntryContextProvider>
            </OmeMeetTeamEntryFiltersContextProvider>
          </OmeMeetTeamEntryContextProvider>
        </OmeMeetHostFiltersContextProvider>
      </OmeMeetHostContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

const MyMeetsContextStateAndRoutes = () => (
  <MyMeetsContextProviders>
    <MyMeetsRoutes />
  </MyMeetsContextProviders>
);

export default MyMeetsContextStateAndRoutes;