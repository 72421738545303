import React, { Fragment } from 'react';

import OrgUnitAdminCoachesSelectionLargeGrid from './OrgUnitAdminCoachesSelectionLargeGrid';
import OrgUnitAdminCoachesSelectionSmallGrid from './OrgUnitAdminCoachesSelectionSmallGrid';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminCoachesSelectionGrid = ({ gridData, isLoading, onCheckboxChange }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            {gridData?.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p> :
              <p>{gridData?.length === 1 ? `${gridData?.length} Search Result` : `${gridData?.length ? gridData?.length : 0} Search Results`}</p>}
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <OrgUnitAdminCoachesSelectionLargeGrid
        gridData={gridData || []}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
      />
      <OrgUnitAdminCoachesSelectionSmallGrid
        gridData={gridData || []}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
      />
    </Fragment>
  )
};

export default OrgUnitAdminCoachesSelectionGrid;