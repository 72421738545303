import React, { Fragment } from 'react';

import PlusIcon from '../../../../common/components/icons/PlusIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const GridRow = ({ athlete, onCreate, onEdit, onDeleteClicked, meetInfo, teamEntry }) => {
  return (
    <Fragment>
      <tr>
        <td>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</td>
        <td>{athlete.competitionGenderTypeName}</td>
        <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
        <td>{athlete.status}</td>
        <td>{teamEntry.orgUnitName || ''} {teamEntry.orgUnitName === 'Unattached' ? teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '' : ''}</td>
        <td>
          {teamEntry.athleteEntryCurrentlyAllowed === true &&
            <Fragment>
              {athlete.orgUnitAthleteEntryId === 0 ?
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onCreate(e, athlete, meetInfo, teamEntry)}>
                  <PlusIcon toolTipText='Create Unattached Athlete Entry' />
                </button>
                :
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, athlete, meetInfo, teamEntry)}>
                  <EditIcon />
                </button>}
            </Fragment>}
          {teamEntry.athleteEntryDeleteCurrentlyAllowed === true &&
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, athlete, meetInfo, teamEntry)}>
              <DeleteIcon />
            </button>}
        </td>
      </tr>
    </Fragment>
  );
};

const MeetAthleteEntryLargeGrid = ({ gridData, isLoading, onCreate, onEdit, onDeleteClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Athlete Name (Age At Meet)</th>
          <th>Competition Category</th>
          <th>Birth Date</th>
          <th>Athlete Entry Status</th>
          <th>Team Name</th>
          <th style={{minWidth: '90px'}}></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="6">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((meetInfo) =>
              meetInfo?.teamEntry?.map((te) =>
              te?.athleteRoster?.map((athlete, i) =>
                <GridRow
                  key={i}
                  athlete={athlete}
                  onCreate={onCreate}
                  onEdit={onEdit}
                  onDeleteClicked={onDeleteClicked}
                  teamEntry={te}
                  meetInfo={meetInfo} />))
            )
            :
            <tr><td colSpan="6">No Athlete Entries</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetAthleteEntryLargeGrid;