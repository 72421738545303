import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OrgUnitAdminRosterSelectionValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryRosterSelectionData from '../../../../state/omeMeetOrgUnitAthleteEntryRosterSelection/UseOmeMeetOrgUnitAthleteEntryRosterSelectionData';
import useOrgGroupData from '../../../../state/orgGroup/UseOrgGroupData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../../common/utils/Constants';
import UseForm from '../../../../../common/utils/UseForm';

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }];

const INITIAL_FORM_STATE = {
  competitionGenderTypeId: Constants.DEFAULT_ID,
  competitionGenderTypeName: '',
  minAge: '',
  maxAge: '',
  orgGroupId: Constants.DEFAULT_ID,
  orgGroupName: '',
  firstOrPreferredName: '',
  lastName: '',
  individualEligibility: false,
  unattachedSwimmers: false,
  memberId: ''
};

const useOrgUnitAdminRosterSelection = () => {
  const navigate = useNavigate();
  const { environmentVariableState, USAS_ORGANIZATION_ID, PRACTICE_GROUP_TYPE_ID, CLUB_ORG_LEVEL_ID,
    NATIONAL_FEDERATION_ORG_LEVEL_ID, NCAA_TEAM_ORG_LEVEL_ID, ISL_TEAM_ORG_LEVEL_ID } = useEnvironmentVariableData();
  const { omeMeetState } = useOmeMeetData()
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryState } = useOmeMeetOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryRosterSelectionState,
    getOrgUnitAthleteEntryRosterSelection, postOrgUnitAthleteEntryRosterSelection } = useOmeMeetOrgUnitAthleteEntryRosterSelectionData();
  const { orgGroupState } = useOrgGroupData();
  const [state, setState] = useState({ tryRedirect: false, showEligibilityFilter: false, showUnattachedFilter: false });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const { handleSubmit, formState, errorState, setErrors, setFormState, onFormValueChanged, onValueTextPairChanged, setFormData
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    getOrgUnitAthleteEntryRosterSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, createFilterObj(INITIAL_FORM_STATE));
    setErrors({});
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let deleteAfterPayment = false;
    //checking for delete if the team is attached and has submitted, or if the team is unattached
    if (omeMeetOrgUnitEntryState.objData.status !== Constants.WORKFLOW_STATUS_IN_PROGRESS) {
      for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
        if (selectionChangesState.arrayData[i].orgUnitAthleteEntryId !== null && selectionChangesState.arrayData[i].isSelected === false
          && selectionChangesState.arrayData[i].hasPaid === true && selectionChangesState.arrayData[i].canBeDeleted === false) {
          deleteAfterPayment = true;
        }
      }
    }

    if (deleteAfterPayment === true) {
      setErrors({ ...errorState, general: `The current selections removed an athlete with a paid for entry. Removing an athlete after their entry has been purchased is not allowed.` });
    }
    else {
      let numberOfAthletesAllowed = false;
      if (omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet) {
        //Check to see if the selections exceed any host max swimmer entry limits
        let previousAthleteCount = omeMeetOrgUnitAthleteEntryState.arrayData?.length || 0;
        let currentAthleteNonRelayOnlySwimmerCount = 0;
        let currentAthleteRelayOnlySwimmerCount = 0;

        if (omeMeetOrgUnitAthleteEntryState.arrayData) {
          for (let i = 0; i < omeMeetOrgUnitAthleteEntryState.arrayData?.length; i++) {
            if (omeMeetOrgUnitAthleteEntryState.arrayData[i].isRelayOnlyAthlete === false) {
              currentAthleteNonRelayOnlySwimmerCount += 1;
            }
            else if (omeMeetOrgUnitAthleteEntryState.arrayData[i].isRelayOnlyAthlete === true) {
              currentAthleteRelayOnlySwimmerCount += 1;
            }
          }
        }

        // figure out athlete counts by type based on the selections
        let addedAthleteCount = 0;
        let removedAthleteCount = 0;
        let updatedAthleteCount = 0; //Non Relay Only and Relay Only Swimmer combined Count

        let addedAthleteNonRelayOnlySwimmerCount = 0;
        let removedAthleteNonRelayOnlySwimmerCount = 0;
        let updatedAthleteNonRelayOnlySwimmerCount = 0;  //Non Relay Only Swimmer Count

        let addedAthleteRelayOnlySwimmerCount = 0;
        let removedAthleteRelayOnlySwimmerCount = 0;
        let updatedAthleteRelayOnlySwimmerCount = 0;  //Relay Only Swimmer Count

        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          //athlete new to roster
          if (selectionChangesState.arrayData[i].isSelected === true && !selectionChangesState.arrayData[i].orgUnitAthleteEntryId) {
            addedAthleteCount += 1;
            if (selectionChangesState.arrayData[i].isRelayOnlyAthlete === true) {
              addedAthleteRelayOnlySwimmerCount += 1;
            }
            else if (selectionChangesState.arrayData[i].isRelayOnlyAthlete === false) {
              addedAthleteNonRelayOnlySwimmerCount += 1;
            }
          }
          //athlete on roster already, relay only swimmer status change
          else if (selectionChangesState.arrayData[i].isSelected === true && selectionChangesState.arrayData[i].orgUnitAthleteEntryId) {
            const existingAthlete = omeMeetOrgUnitAthleteEntryState.arrayData?.find(x => x.personId === selectionChangesState.arrayData[i].personId);
            if (existingAthlete) {
              if (existingAthlete.isRelayOnlyAthlete === true && selectionChangesState.arrayData[i].isRelayOnlyAthlete === false) {
                removedAthleteRelayOnlySwimmerCount += 1;
                addedAthleteNonRelayOnlySwimmerCount += 1;
              }
              else if (existingAthlete.isRelayOnlyAthlete === false && selectionChangesState.arrayData[i].isRelayOnlyAthlete === true) {
                removedAthleteNonRelayOnlySwimmerCount += 1;
                addedAthleteRelayOnlySwimmerCount += 1;
              }
            }
          }
          //athlete removed from roster
          else if (selectionChangesState.arrayData[i].isSelected === false && selectionChangesState.arrayData[i].orgUnitAthleteEntryId) {
            removedAthleteCount += 1;
            const existingAthlete = omeMeetOrgUnitAthleteEntryState.arrayData?.find(x => x.personId === selectionChangesState.arrayData[i].personId);
            if (existingAthlete) {
              if (existingAthlete.isRelayOnlyAthlete === true) {
                removedAthleteRelayOnlySwimmerCount += 1;
              }
              else if (existingAthlete.isRelayOnlyAthlete === false) {
                removedAthleteNonRelayOnlySwimmerCount += 1;
              }
            }
          }
        }

        updatedAthleteCount = previousAthleteCount + addedAthleteCount - removedAthleteCount;
        updatedAthleteNonRelayOnlySwimmerCount = currentAthleteNonRelayOnlySwimmerCount + addedAthleteNonRelayOnlySwimmerCount - removedAthleteNonRelayOnlySwimmerCount;
        updatedAthleteRelayOnlySwimmerCount = currentAthleteRelayOnlySwimmerCount + addedAthleteRelayOnlySwimmerCount - removedAthleteRelayOnlySwimmerCount;

        const includeRelaySwimmerForMaxNumber = omeMeetState.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber ?? false;
        const countOfSwimmersToUseForMaxNumber = includeRelaySwimmerForMaxNumber === true ? updatedAthleteCount : updatedAthleteNonRelayOnlySwimmerCount;

        if (countOfSwimmersToUseForMaxNumber > omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet) {
          setErrors({
            ...errorState, general: `The current selections (${omeMeetState.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber !== null ? (omeMeetState.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber === true ?
              `${updatedAthleteCount} ${updatedAthleteCount === 1 ? 'Athlete' : 'Athletes'}, includes ${updatedAthleteRelayOnlySwimmerCount} ${updatedAthleteRelayOnlySwimmerCount === 1 ? 'Relay Only Swimmer' : 'Relay Only Swimmers'}` :
              `${updatedAthleteNonRelayOnlySwimmerCount} ${updatedAthleteNonRelayOnlySwimmerCount === 1 ? 'Athlete' : 'Athletes'}, excludes ${updatedAthleteRelayOnlySwimmerCount} ${updatedAthleteRelayOnlySwimmerCount === 1 ? 'Relay Only Swimmer' : 'Relay Only Swimmers'}`) :
              `${updatedAthleteCount} ${updatedAthleteCount === 1 ? 'Athlete' : 'Athletes'}`}) exceed the max number of athletes allowed for the meet (${omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet}${omeMeetState.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber !== null ?
                omeMeetState.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber === true ?
                  (omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet === 1 ? ' Athlete, includes Relay Only Swimmers' : ' Athletes, includes Relay Only Swimmers') :
                  (omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet === 1 ? ' Athlete, excludes Relay Only Swimmers' : ' Athletes, excludes Relay Only Swimmers') :
                (omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet === 1 ? ' Athlete' : ' Athletes')}). This is not allowed.`
          });
        }
        else {
          numberOfAthletesAllowed = true;
        }
      }
      else {
        numberOfAthletesAllowed = true;
      }

      if (numberOfAthletesAllowed === true) {
        if (selectionChangesState.arrayData.length > 0) {
          postOrgUnitAthleteEntryRosterSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, selectionChangesState.arrayData);
        }
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER);
    }
  };

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasPaid === false || updatedGridData[i].canBeDeleted === true) {
        updatedGridData[i].isSelected = true;
      }
      const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedAthleteIndexArrayData > -1) {
        if (updatedArrayData[selectedAthleteIndexArrayData].hasPaid === false
          || updatedArrayData[selectedAthleteIndexArrayData].canBeDeleted === true) {
          updatedArrayData[selectedAthleteIndexArrayData].isSelected = true;
        }
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].isRelayAthlete === false || updatedGridData[i].canBeDeleted === true) {
        if (updatedGridData[i].hasPaid === false || updatedGridData[i].canBeDeleted === true) {
          updatedGridData[i].isSelected = false;
        }
        const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
        //Edit
        if (selectedAthleteIndexArrayData > -1) {
          if (updatedArrayData[selectedAthleteIndexArrayData].hasPaid === false ||
            updatedArrayData[selectedAthleteIndexArrayData].canBeDeleted === true) {
            updatedArrayData[selectedAthleteIndexArrayData].isSelected = false;
          }
        }
        else {
          updatedArrayData.push(updatedGridData[i]);
        }
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onSetAllRelayOnly = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasPaid === false || updatedGridData[i].canBeDeleted === true) {
        updatedGridData[i].isSelected = true;
        if (updatedGridData[i].isIndividualAthlete === false) {
          updatedGridData[i].isRelayOnlyAthlete = true;
          updatedGridData[i].isUpdated = true;
        }
      }
      const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedAthleteIndexArrayData > -1) {
        if (updatedArrayData[selectedAthleteIndexArrayData].hasPaid === false ||
          updatedArrayData[selectedAthleteIndexArrayData].canBeDeleted === true) {
          updatedArrayData[selectedAthleteIndexArrayData].isSelected = true;
          if (updatedArrayData[selectedAthleteIndexArrayData].isIndividualAthlete === false) {
            updatedArrayData[selectedAthleteIndexArrayData].isRelayOnlyAthlete = true;
            updatedArrayData[selectedAthleteIndexArrayData].isUpdated = true;
          }
        }
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUnsetAllRelayOnly = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasPaid === false || updatedGridData[i].canBeDeleted === true) {
        updatedGridData[i].isSelected = true;
        if (updatedGridData[i].isIndividualAthlete === false) {
          updatedGridData[i].isRelayOnlyAthlete = false;
          updatedGridData[i].isUpdated = true;
        }
      }
      const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedAthleteIndexArrayData > -1) {
        if (updatedArrayData[selectedAthleteIndexArrayData].hasPaid === false ||
          updatedArrayData[selectedAthleteIndexArrayData].canBeDeleted === true) {
          updatedArrayData[selectedAthleteIndexArrayData].isSelected = true;
          if (updatedArrayData[selectedAthleteIndexArrayData].isIndividualAthlete === false) {
            updatedArrayData[selectedAthleteIndexArrayData].isRelayOnlyAthlete = false;
            updatedArrayData[selectedAthleteIndexArrayData].isUpdated = true;
          }
        }
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onCheckboxChange = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === personId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.personId === personId);

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isSelected = !updatedGridData[selectedAthleteIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedAthleteIndexArrayData > -1) {
      updatedArrayData[selectedAthleteIndexArrayData].isSelected = !updatedArrayData[selectedAthleteIndexArrayData].isSelected;
      updatedGridData[selectedAthleteIndexGridData].isRelayOnlyAthlete = false;
      updatedGridData[selectedAthleteIndexGridData].isUpdated = false;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedAthleteIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedGridData[selectedAthleteIndexGridData].isRelayOnlyAthlete = false;
      updatedGridData[selectedAthleteIndexGridData].isUpdated = false;
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  const onYesNoSwitchChange = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === personId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.personId === personId);

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isRelayOnlyAthlete = !updatedGridData[selectedAthleteIndexGridData].isRelayOnlyAthlete;
      updatedGridData[selectedAthleteIndexGridData].isUpdated = true;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedAthleteIndexArrayData > -1) {
      updatedArrayData[selectedAthleteIndexArrayData].isRelayOnlyAthlete = !updatedArrayData[selectedAthleteIndexArrayData].isRelayOnlyAthlete;
      updatedGridData[selectedAthleteIndexGridData].isUpdated = true;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedAthleteIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  function submitFormCallback(formState) {
    getOrgUnitAthleteEntryRosterSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, createFilterObj(formState));
  };

  function createFilterObj(formState) {
    return {
      competitionGenderTypeId: formState.unattachedSwimmers !== true && formState.competitionGenderTypeId > 0 ? formState.competitionGenderTypeId : undefined,
      ageStart: formState.unattachedSwimmers !== true && formState.minAge.trim() !== '' ? formState.minAge.trim() : undefined,
      ageEnd: formState.unattachedSwimmers !== true && formState.maxAge.trim() !== '' ? formState.maxAge.trim() : undefined,
      firstName: formState.unattachedSwimmers !== true && formState.firstOrPreferredName.trim() !== '' ? formState.firstOrPreferredName.trim() : undefined,
      lastName: formState.unattachedSwimmers !== true && formState.lastName.trim() !== '' ? formState.lastName.trim() : undefined,
      orgGroupId: formState.unattachedSwimmers !== true && formState.orgGroupId > 0 ? formState.orgGroupId : undefined,
      individualEligibility: formState.individualEligibility,
      unattachedSwimmers: formState.unattachedSwimmers ?? undefined,
      memberId: formState.unattachedSwimmers === true ? formState.memberId.trim() : undefined
    }
  }

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      let showEligibilityFilter = false;
      if (omeMeetOrgUnitEntryState.objData.orgUnit?.orgLevelId === CLUB_ORG_LEVEL_ID ||
        omeMeetOrgUnitEntryState.objData.orgUnit?.orgLevelId === NATIONAL_FEDERATION_ORG_LEVEL_ID ||
        omeMeetOrgUnitEntryState.objData.orgUnit?.orgLevelId === NCAA_TEAM_ORG_LEVEL_ID ||
        omeMeetOrgUnitEntryState.objData.orgUnit?.orgLevelId === ISL_TEAM_ORG_LEVEL_ID) {
        showEligibilityFilter = true;
      }
      let showUnattachedFilter = false;
      if (omeMeetState.objData.omeMeetEligibility[0]?.allowUnattachedRoster === true) {
        showUnattachedFilter = true;
      }

      setState({ ...state, showEligibilityFilter: showEligibilityFilter, showUnattachedFilter: showUnattachedFilter });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, omeMeetOrgUnitEntryState, omeMeetState])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId) {
      getOrgUnitAthleteEntryRosterSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, createFilterObj(formState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoaded === true && omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoading === false) {
      const arrayDataCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitAthleteEntryRosterSelectionState.arrayData));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }
      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoaded, omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoading])

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetOrgUnitAthleteEntryRosterSelectionState.isSaving === false) {
      if (omeMeetOrgUnitAthleteEntryRosterSelectionState.objData.athletesOverMax === 0) {
        if (omeMeetState.route === '') {
          //OME Route
          navigate(EntryNavLinks.OU_ADMIN_ROSTER, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
        }
        else {
          //Meet Route
          navigate(NavLinks.OU_ADMIN_ROSTER, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
        }
      }
      else {
        setErrors({
          ...errorState, general: `Considering all existing team entries for this meet, the current selections exceed the max number of athletes allowed for the meet ${omeMeetState.objData?.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true ? omeMeetOrgUnitAthleteEntryRosterSelectionState.objData.includeRelayOnlySwimmersInMaxNumber === true ? ' (includes Relay Only Swimmers)' : ' (does not include Relay Only Swimmers)' : ''} by ${omeMeetOrgUnitAthleteEntryRosterSelectionState.objData.athletesOverMax}${omeMeetOrgUnitAthleteEntryRosterSelectionState.objData.athletesOverMax === 1 ? ' athlete' : ' athletes'}. This is not allowed.`
        });
        setState({ ...state, tryRedirect: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, omeMeetOrgUnitAthleteEntryRosterSelectionState])

  return {
    state,
    gridState,
    omeMeetState,
    omeMeetOrgUnitAthleteEntryRosterSelectionState,
    onSelectAll,
    onUnselectAll,
    onSetAllRelayOnly,
    onUnsetAllRelayOnly,
    onSaveClicked,
    onBackClicked,
    onCheckboxChange,
    onYesNoSwitchChange,
    onFilterClicked,
    onClearFilterClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    formState,
    errorState,
    competitionGenderOptions,
    omeMeetOrgUnitEntryState,
    orgGroupState,
    USAS_ORGANIZATION_ID,
    PRACTICE_GROUP_TYPE_ID
  };
}

export default useOrgUnitAdminRosterSelection;