import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';
import useOmeMeetInvitationsData from '../../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import { eligibilityIsComplete, meetInfoIsComplete, restrictionsIsComplete, invitationsIsComplete, sessionsIsComplete } from '../../../../components/leftNavigation/LeftNavValidation';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  error: ''
};

const useOmeSessions = () => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetInvitationsState } = useOmeMeetInvitationsData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { meetState } = useMeetData();
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_SESSIONS_DETAIL, { state: { meetSessionId: session.meetSessionId } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_SESSIONS_DETAIL, { state: { meetSessionId: session.meetSessionId } });
    }
  }

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE, tryRedirect: true });
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT, tryRedirect: true });
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.BACK, tryRedirect: true });
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (isReadyToRoute === true && meetState.isSaving === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        const meetInfoRoutePermission = navRoutes?.OME_HOST_MEET_INFO?.permission;
        const restrictionsRoutePermission = navRoutes?.OME_HOST_RESTRICTIONS?.permission;
        const eligibilityRoutePermission = navRoutes?.OME_HOST_ELIGIBILITY?.permission;
        if (meetInfoIsComplete(omeMeetState.objData, meetState.objData, meetInfoRoutePermission) === false || eligibilityIsComplete(omeMeetState.objData, eligibilityRoutePermission) === false ||
          restrictionsIsComplete(omeMeetState.objData, restrictionsRoutePermission) === false || invitationsIsComplete(omeMeetInvitationsState.arrayData) === false) {
          setState({ ...state, error: 'Meet Info, Eligibility, Restrictions, and Invitations must be complete before continuing to Age Groups.' });
        }
        else if (sessionsIsComplete(meetState.objData) === false) {
          setState({ ...state, error: 'At least one Session is required before continuing to Age Groups.' });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_AGE_GROUPS);
          }
          else {
            navigate(HostNavLinks.OME_MEET_AGE_GROUPS);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_INVITATIONS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_INVITATIONS);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isSaving, state, isReadyToRoute]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryStartDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    omeMeetState,
    state,
    HostNavLinks,
    meetState,
    onEditSession,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    allowEdits
  };
};

export default useOmeSessions;