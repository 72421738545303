import React from 'react';
import { Dialog } from '@reach/dialog';

import useLaneSheetHeatRelayTeamsPopup from './UseLaneSheetHeatRelayTeamsPopup';

import LaneSheetHeatRelayTeamsForm from '../components/forms/LaneSheetHeatRelayTeamsForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './LaneSheetHeatAthletesPopup.module.css';

const LaneSheetHeatRelayTeamsPopup = ({ laneSheetHeat, displayPopup, onClosePopup }) => {
  const {
    isLoading,
    numberOfLanes,
    startingLaneNumber,
    meetEventRelayTeams,
    heatRelayTeams,
    onSubmitFormCallback
  } = useLaneSheetHeatRelayTeamsPopup(laneSheetHeat, onClosePopup);

  return (
    <Dialog
      isOpen={displayPopup === true}
      allowPinchZoom={true}
      aria-label={'Lane Sheet Configuration'}
      className={style.LaneSheetConfig}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className='text-center'>Lane Sheet Configuration</Headings.H2>
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-xs-12'>
          <p className={global.HeaderText}><b>Event Name</b> {laneSheetHeat.eventName || ''}  </p>
        </div>
        <div className='col-xs-12'>
          <p className={global.HeaderText}><b>Heat - Heat Date - Session Type:</b> {laneSheetHeat.heatNumber || ''} - {laneSheetHeat.heatDate ? formatDate(laneSheetHeat.heatDate) : ''} - {laneSheetHeat.sessionTypeName || ''} </p>
        </div>
      </div>
      <LaneSheetHeatRelayTeamsForm
        numberOfLanes={numberOfLanes}
        startingLaneNumber={startingLaneNumber}
        meetEventRelayTeams={meetEventRelayTeams}
        heatRelayTeams={heatRelayTeams}
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onClosePopup} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Dialog>
  );
};

export default LaneSheetHeatRelayTeamsPopup;