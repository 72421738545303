import { useContext } from 'react';

import { ParticipantGridDetailPopupStateContext } from './ParticipantGridDetailPopupContextProvider';

const useParticipantGridDetailPopupData = () => {
  const [participantGridDetailPopupState, setParticipantGridDetailPopupState] = useContext(ParticipantGridDetailPopupStateContext);

  const onEnterCell = (event = undefined, participant) => {
    if (event) {
      setParticipantGridDetailPopupState({
        ...participantGridDetailPopupState,
        displayPopup: true,
        popupTitle: getFormattedTitle(participant.firstName, participant.lastName, participant.relayTeamName, participant.orgUnitName),
        pageX: event.pageX,
        pageY: event.pageY,
        participant
      });
    } else {
      setParticipantGridDetailPopupState({
        ...participantGridDetailPopupState,
        displayPopup: true,
        popupTitle: getFormattedTitle(participant.firstName, participant.lastName, participant.relayTeamName, participant.orgUnitName),
        participant
      });
    }
  };

  const onLeaveCell = () => {
    setParticipantGridDetailPopupState({
      ...participantGridDetailPopupState,
      displayPopup: false,
      popupTitle: '',
      pageX: 0,
      pageY: 0,
      participant: {}
    });
  };

  function getFormattedTitle(firstName, lastName, relayTeamName, orgUnitName) {
    if (relayTeamName) {
      return relayTeamName;
    } else {
      return `${firstName || ''} ${lastName || ''}, ${orgUnitName || ''}`;
    }
  };

  return {
    participantGridDetailPopupState,
    onEnterCell,
    onLeaveCell
  };
};

export default useParticipantGridDetailPopupData;