import { useState } from 'react';
import MeetRaceStatsVideosData from './MeetRaceStatsVideosData';

const useMeetRaceStatsVideosData = () => {
  const [meetRaceStatsVideosState, setMeetRaceStatsVideosState] = useState(MeetRaceStatsVideosData.INITIAL_STATE);

  const getMeetRaceStatsVideos = (meetId) => 
    MeetRaceStatsVideosData.getMeetRaceStatsVideosData(meetId, meetRaceStatsVideosState, setMeetRaceStatsVideosState);
  const postMeetRaceStatsVideos = (meetRaceStatsVideoObj) =>  
    MeetRaceStatsVideosData.postMeetRaceStatsVideoData(meetRaceStatsVideoObj, meetRaceStatsVideosState, setMeetRaceStatsVideosState);
  const putMeetRaceStatsVideo = (meetId) =>
    MeetRaceStatsVideosData.putMeetRaceStatsVideoData(meetId, meetRaceStatsVideosState, setMeetRaceStatsVideosState);

  const clearObjData = () => {
    setMeetRaceStatsVideosState({
      ...meetRaceStatsVideosState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    meetRaceStatsVideosState,
    getMeetRaceStatsVideos,
    postMeetRaceStatsVideos,
    putMeetRaceStatsVideo,
    clearObjData
  };
}

export default useMeetRaceStatsVideosData;