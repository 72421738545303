
import React, { Fragment } from 'react';

import useOrgUnitSelection from './UseTeamEntryOrgUnitSelection';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../../common/components/headings/Headings';
import RadiobuttonList from '../../../../../common/components/radiobuttonlists/RadiobuttonList';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const TeamEntryOrgUnitSelection = () => {
    const { state, handleSubmit, formState, errorState, onFormValueChanged,
        personOrgRoleState, omeMeetState, omeMeetOrgUnitEntryState, onBackClicked } = useOrgUnitSelection();
    return (
        <Fragment>
            <div className={state.radioButtonList.length > 0 && state.showRadioButtonList === true
                && personOrgRoleState.isArrayLoading !== true ? global.DisplayComponent : global.HideComponent} >
                <div className="row">
                    <div className="col-xs-12">
                        <Headings.H3>Enter Meet</Headings.H3>
                    </div>
                </div>
                <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                    <div className="col-xs-12">
                        <Headings.H6>Make a Selection</Headings.H6>
                    </div>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-xs-12">
                            <RadiobuttonList
                                isVertical={true}
                                items={state.radioButtonList}
                                label="Team Options*"
                                name="orgUnitId"
                                value={formState.orgUnitId}
                                error={errorState.orgUnitId}
                                message={errorState.orgUnitId}
                                onChange={(value) => { onFormValueChanged('orgUnitId', value); }} />
                        </div>
                    </div>
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
                            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
                        </div>
                    </div>
                </form>
            </div>
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={omeMeetOrgUnitEntryState.isSaving} />
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={(personOrgRoleState.isArrayLoading || omeMeetState.isArrayLoading) && omeMeetOrgUnitEntryState.isSaving === false} />
        </Fragment>
    );
};

export default TeamEntryOrgUnitSelection;