import { useContext } from 'react';

import { OmeMeetAthleteEntryStateContext } from './OmeMeetAthleteEntryContextProvider';
import { OmeMeetAthleteEntryFiltersStateContext } from './OmeMeetAthleteEntryFiltersContextProvider';

import OmeMeetAthleteEntryData from './OmeMeetAthleteEntryData';

const useOmeMeetAthleteEntryData = () => {
  const [omeMeetAthleteEntryState, setOmeMeetAthleteEntryState] = useContext(OmeMeetAthleteEntryStateContext);
  const [omeMeetAthleteEntryFiltersState, setOmeMeetAthleteEntryFiltersState] = useContext(OmeMeetAthleteEntryFiltersStateContext);

  const searchMeetAthleteEntry = (filterObject, sortBy) => {
    setOmeMeetAthleteEntryFiltersState({ ...omeMeetAthleteEntryFiltersState, filterObject, sortBy });
    OmeMeetAthleteEntryData.searchMeetAthleteEntryData(JSON.stringify(filterObject), sortBy, omeMeetAthleteEntryState, setOmeMeetAthleteEntryState);
  };

  const clearOmeMeetAthleteEntryArrayData = () => {
    setOmeMeetAthleteEntryState({
      ...omeMeetAthleteEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const resetOmeMeetAthleteEntryFiltersState = () => {
    setOmeMeetAthleteEntryFiltersState({
      filterObject: {},
      sortBy: ''
    });
  };

  return {
    omeMeetAthleteEntryState,
    setOmeMeetAthleteEntryState,
    omeMeetAthleteEntryFiltersState,
    setOmeMeetAthleteEntryFiltersState,
    searchMeetAthleteEntry,
    clearOmeMeetAthleteEntryArrayData,
    resetOmeMeetAthleteEntryFiltersState
  };
};

export default useOmeMeetAthleteEntryData;