import React, { createContext, useState } from 'react';

import OmeMeetAthleteEntryData from './OmeMeetAthleteEntryData';

export const OmeMeetAthleteEntryStateContext = createContext();

const OmeMeetAthleteEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetAthleteEntryData.INITIAL_STATE);

  return (
    <OmeMeetAthleteEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetAthleteEntryStateContext.Provider>
  );
};

export default OmeMeetAthleteEntryContextProvider;