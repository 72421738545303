import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.costPerTeam !== '') {
    if (!isValidNumber(formState.costPerTeam)) {
      errors.costPerTeam = 'Cost Per Team must be a valid number';
    } else if (formState.costPerTeam < 0 || formState.costPerTeam > 500) {
      errors.costPerTeam = 'Cost Per Team must be between $0 and $500';
    }
  } else if (formState.costPerTeam === '') {
    errors.costPerTeam = 'Cost Per Team must be a valid number';
  }

  if (formState.costPerCredential !== '') {
    if (!isValidNumber(formState.costPerCredential)) {
      errors.costPerCredential = 'Cost Per Credential must be a valid number';
    } else if (formState.costPerCredential < 0 || formState.costPerCredential > 500) {
      errors.costPerCredential = 'Cost Per Credential must be between $0 and $500';
    }
  } else if (formState.costPerCredential === '') {
    errors.costPerCredential = 'Cost Per Credential must be a valid number';
  }

  if (formState.costPerIndividualAthlete !== '') {
    if (!isValidNumber(formState.costPerIndividualAthlete)) {
      errors.costPerIndividualAthlete = 'Cost Per Individual Athlete must be a valid number';
    } else if (formState.costPerIndividualAthlete < 0 || formState.costPerIndividualAthlete > 500) {
      errors.costPerIndividualAthlete = 'Cost Per Individual Athlete must be between $0 and $500';
    }
  } else if (formState.costPerIndividualAthlete === '') {
    errors.costPerIndividualAthlete = 'Cost Per Individual Athlete must be a valid number';
  }

  if (formState.showCostPerRelayOnlySwimmer === true && formState.costPerRelayOnlySwimmer !== '') {
    if (!isValidNumber(formState.costPerRelayOnlySwimmer)) {
      errors.costPerRelayOnlySwimmer = 'Cost Per Relay Only Swimmer must be a valid number';
    } else if (formState.costPerRelayOnlySwimmer < 0 || formState.costPerRelayOnlySwimmer > 500) {
      errors.costPerRelayOnlySwimmer = 'Cost Per Relay Only Swimmer must be between $0 and $500';
    }
  } else if (formState.showCostPerRelayOnlySwimmer === true && formState.costPerRelayOnlySwimmer === '') {
    errors.costPerRelayOnlySwimmer = 'Cost Per Relay Only Swimmer must be a valid number';
  }

  if (formState.showCostPerIndividualInternationalAthlete === true && formState.costPerIndividualInternationalAthlete !== '') {
    if (!isValidNumber(formState.costPerIndividualInternationalAthlete)) {
      errors.costPerIndividualInternationalAthlete = 'Cost Per Individual International Athlete must be a valid number';
    } else if (formState.costPerIndividualInternationalAthlete < 0 || formState.costPerIndividualInternationalAthlete > 500) {
      errors.costPerIndividualInternationalAthlete = 'Cost Per Individual International Athlete must be between $0 and $500';
    }
  } else if (formState.showCostPerIndividualInternationalAthlete === true && formState.costPerIndividualInternationalAthlete === '') {
    errors.costPerIndividualInternationalAthlete = 'Cost Per Individual International Athlete must be a valid number';
  }

  if (formState.showCostPerInternationalStaff === true && formState.costPerInternationalStaff !== '') {
    if (!isValidNumber(formState.costPerInternationalStaff)) {
      errors.costPerInternationalStaff = 'Cost Per International Staff must be a valid number';
    } else if (formState.costPerInternationalStaff < 0 || formState.costPerInternationalStaff > 500) {
      errors.costPerInternationalStaff = 'Cost Per International Staff must be between $0 and $500';
    }
  } else if (formState.showCostPerInternationalStaff === true && formState.costPerInternationalStaff === '') {
    errors.costPerInternationalStaff = 'Cost Per International Staff must be a valid number';
  }

  if (formState.costPerAthleteIndividualEventEntry === '' || !isValidNumber(formState.costPerAthleteIndividualEventEntry)) {
    errors.costPerAthleteIndividualEventEntry = 'Cost Per Athlete Individual Event Entry must be a valid number';
  } else if (formState.costPerAthleteIndividualEventEntry < 0 || formState.costPerAthleteIndividualEventEntry > 500) {
    errors.costPerAthleteIndividualEventEntry = 'Cost Per Athlete Individual Event Entry must be between $0 and $500';
  }

  if (formState.showCostPerAthleteIndividualBonusEventEntry === true && formState.costPerAthleteIndividualBonusEventEntry !== '') {
    if (formState.costPerAthleteIndividualBonusEventEntry !== '') {
      if (!isValidNumber(formState.costPerAthleteIndividualBonusEventEntry)) {
        errors.costPerAthleteIndividualBonusEventEntry = 'Cost Per Athlete Individual Bonus Event Entry must be a valid number';
      } else if (formState.costPerAthleteIndividualBonusEventEntry < 0 || formState.costPerAthleteIndividualBonusEventEntry > 500) {
        errors.costPerAthleteIndividualBonusEventEntry = 'Cost Per Athlete Individual Bonus Event Entry must be between $0 and $500';
      }
    }
  } else if (formState.showCostPerAthleteIndividualBonusEventEntry === true && formState.costPerAthleteIndividualBonusEventEntry === '') {
    errors.costPerAthleteIndividualBonusEventEntry = 'Cost Per Athlete Individual Bonus Event Entry must be a valid number';
  }

  if (formState.showCostPerRelayEventEntry === true && formState.costPerRelayEventEntry !== '') {
    if (!isValidNumber(formState.costPerRelayEventEntry)) {
      errors.costPerRelayEventEntry = 'Cost Per Relay Event Entry must be a valid number';
    } else if (formState.costPerRelayEventEntry < 0 || formState.costPerRelayEventEntry > 500) {
      errors.costPerRelayEventEntry = 'Cost Per Relay Event Entry must be between $0 and $500';
    }
  } else if (formState.showCostPerRelayEventEntry === true && formState.costPerRelayEventEntry === '') {
    errors.costPerRelayEventEntry = 'Cost Per Relay Event Entry must be a valid number';
  }

  return errors;
};

const OmePricingValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmePricingValidation;