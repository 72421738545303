import { useState } from 'react';

import MeetStatusData from './MeetStatusData';

const useMeetStatusData = () => {
  const [meetStatusState, setMeetStatusState] = useState(MeetStatusData.INITIAL_STATE);
  const getMeetStatus = (meetId) => MeetStatusData.getMeetStatus(meetId, meetStatusState, setMeetStatusState);

  return { meetStatusState, getMeetStatus };
};

export default useMeetStatusData;