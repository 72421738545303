/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ topHeaderInfo, onDeleteClicked }) => (
  <tr>
    <td>{topHeaderInfo?.displayOrder || 0}</td>
    <td>{topHeaderInfo?.recordListName || ''}</td>
    <td>{topHeaderInfo?.nameToShow || ''}</td>
    <td>
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onDeleteClicked(topHeaderInfo)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const LaneSheetMeetTopHeaderNotesLargeGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Display Order</th>
        <th>Record list</th>
        <th>Name to Show</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData?.length > 0
          ? gridData?.map((topHeaderInfo, i) =>
            <LargeGridRow
              key={i}
              topHeaderInfo={topHeaderInfo}
              onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="4">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetMeetTopHeaderNotesLargeGrid;