import React, { Fragment } from 'react';

import useMeetHost from './UseMeetHost';

import MeetHostGrid from './MeetHostGrid';

import DeleteOmeModalInfo from '../../myMeets/meetHost/DeleteOmeModalInfo';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';
import { formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetHost = () => {
  const {
    omeMeetHostState,
    gridState,
    modalState,
    meetState,
    omeMeetState,
    onView,
    onEnterOmeHostClicked,
    onEdit,
    onDeleteOmeMeet,
    onDeleteOmeMeetClicked,
    onModalCanceled
  } = useMeetHost();
  
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Host Management</Headings.H3>
        </div>
        <div className="col-xs-12">
          {omeMeetState?.objData?.publishDate &&
            <p className={global.HeaderText}><b>Publish Date:</b>
              &nbsp;{omeMeetState?.objData?.publishDate && gridState.arrayData?.length > 0 ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.publishDate) : ''}
              &nbsp;{omeMeetState?.objData?.timeZone && gridState.arrayData?.length > 0 ? omeMeetState?.objData?.timeZone : ''}</p>}
          {gridState.arrayData?.length > 0 && omeMeetHostState?.objData?.hostChangesCurrentlyAllowed === false && omeMeetState.objData.omeMeetId && <p className={global.HeaderText}><b>The Meet Entry Change Deadline has passed. Editing Host Information is no longer allowed.</b></p>}
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <MeetHostGrid
            gridData={gridState.arrayData}
            isLoading={gridState.isArrayLoading}
            meetState={meetState}
            onView={onView}
            onEnterOmeHostClicked={onEnterOmeHostClicked}
            onEdit={onEdit}
            onDeleteOmeMeetClicked={onDeleteOmeMeetClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <DeleteOmeModalInfo modalState={modalState} />
            <p><b>Entry Start - End Dates:</b> {modalState?.entryDates}</p>
            <p><b>Time Zone:</b> {modalState?.timeZone}</p>
            <p><b>OME Meet Status:</b> {modalState?.status}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteOmeMeet(e, modalState.omeMeetId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetState.isObjLoading || gridState.isArrayLoading || omeMeetHostState.isObjLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={gridState.isSaving || omeMeetState.isSaving} />
    </Fragment >);
}

export default MeetHost;