import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getSelectionParticipantsData = (selectionMeetId, includeRelays, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/participants?includeRelays=${includeRelays || false}`;
    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const postSelectionRuleData = (selectionMeetId, selectionActionCode, includeRelaysInAlternates, state, setState) => {
  if (selectionMeetId > 0 && selectionActionCode) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsRules/${selectionMeetIdForUrl}`;
    return api?.executeObject ? api.executeObject(url, 'POST', { selectionActionCode, includeRelaysInAlternates }) : null;
  }
};

const postSelectionSelectAlternateRuleData = (selectionMeetId, selectionMeetEventEntryId, includeRelaysInAlternates, state, setState) => {
  if (selectionMeetId > 0 && selectionMeetEventEntryId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const selectionMeetEventEntryIdForUrl = encodeURIComponent(selectionMeetEventEntryId);
    const url = `/OmeSelectionsRules/SelectionRules/meetEventEntryFromAlternatesList/${selectionMeetIdForUrl}?selectionMeetEventEntryId=${selectionMeetEventEntryIdForUrl}&includeRelaysInAlternates=${includeRelaysInAlternates || false}`;
    return api?.executeObject ? api.executeObject(url, 'POST') : null;
  }
};

const putSelectionPersonAttendanceData = (selectionMeetId, selectionMeetPersonId, willAttend, unavailableReasonId, includeRelaysInAlternates, state, setState) => {
  if (selectionMeetId > 0 && selectionMeetPersonId > 0 && (willAttend === true || willAttend === false)) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const selectionMeetPersonIdForUrl = encodeURIComponent(selectionMeetPersonId);
    const unavailableReasonIdForUrl = encodeURIComponent(unavailableReasonId);
    const url = `/OmeSelectionsRules/SelectionRules/updateSelectionPersonAttendance/${selectionMeetIdForUrl}?selectionMeetPersonId=${selectionMeetPersonIdForUrl}&willAttend=${willAttend}${willAttend === false ? `&unavailableReasonId=${unavailableReasonIdForUrl}` : ''}&includeRelaysInAlternates=${includeRelaysInAlternates || false}`;
    return api?.executeObject ? api.executeObject(url, 'PUT') : null;
  }
};

const SelectionsParticipantsData = {
  getSelectionParticipantsData,
  postSelectionRuleData,
  postSelectionSelectAlternateRuleData,
  putSelectionPersonAttendanceData
};

export default SelectionsParticipantsData;