import { useEffect, useState } from 'react';
import useDateTimeData from '../../state/datetime/UseDateTimeData';

const INITIAL_STATE = {
  hour: '99',
  minute: '99'
};

const useDateTimePicker = (value, onChange) => {
  const { dateTimeState, breakUpTime } = useDateTimeData();
  const [state, setState] = useState(INITIAL_STATE);

  const onValueChanged = (val, e, datePart) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const hour = datePart === 'hour' ? val : state.hour;
    const minute = datePart === 'minute' ? val : state.minute;
    setState({ ...state, hour, minute });
  };

  useEffect(() => {
    const time = breakUpTime(value);
    if ((time.hour && time.hour !== state.hour) || (time.minute && time.minute !== state.minute)) {
      setState({
        ...state,
        hour: time.hour,
        minute: time.minute
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const newValue = `${state.hour}:${state.minute}`;
    if (newValue && value !== newValue) {
      onChange(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    ...state,
    hours: dateTimeState?.objData?.hours || [],
    minutes: dateTimeState?.objData?.minutes || [],
    onHourChanged: (newValue, l, e) => onValueChanged(newValue, e, 'hour'),
    onMinuteChanged: (newValue, l, e) => onValueChanged(newValue, e, 'minute')
  };
};

export default useDateTimePicker;