import { useContext } from 'react';

import { OrgUnitStateContext } from './OrgUnitContextProvider';
import OrgUnitData from './OrgUnitData';

const useOrgUnitData = () => {
  const [orgUnitState, setOrgUnitState] = useContext(OrgUnitStateContext);

  const getOrgUnits = () => OrgUnitData.getOrgUnitData(orgUnitState, setOrgUnitState);
  const getOrgUnitsByOrganizationId = (organizationId) => OrgUnitData.getOrgUnitByOrganizationIdData(organizationId, orgUnitState, setOrgUnitState);
  const getOrgUnitsTopTwoLevels = () => OrgUnitData.getOrgUnitTopTwoLevelsData(orgUnitState, setOrgUnitState);
  const getOrgUnitByOrgUnitId = (orgUnitId) => OrgUnitData.getOrgUnitByOrgUnitIdData(orgUnitId, orgUnitState, setOrgUnitState);
  const getBaseOrgUnitByOrgUnitId = (orgUnitId) => OrgUnitData.getBaseOrgUnitByOrgUnitIdData(orgUnitId, orgUnitState, setOrgUnitState )

  return {
    orgUnitState,
    getOrgUnits,
    getOrgUnitsByOrganizationId,
    getOrgUnitsTopTwoLevels,
    getOrgUnitByOrgUnitId,
    getBaseOrgUnitByOrgUnitId
  };
};

export default useOrgUnitData;