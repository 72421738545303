import React, { createContext, useState } from 'react';

import AthleteEntryContextData from './AthleteEntryContextData';

export const AthleteEntryContextStateContext = createContext();

const AthleteEntryContextContextProvider = ({ children }) => {
  const stateHook = useState(AthleteEntryContextData.INITIAL_STATE);

  return (
    <AthleteEntryContextStateContext.Provider value={stateHook}>
      {children}
    </AthleteEntryContextStateContext.Provider>
  );
};

export default AthleteEntryContextContextProvider;