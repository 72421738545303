import { useEffect } from 'react';

import useSelectionsOrgLevelData from '../../state/selectionsOrgLevel/UseSelectionsOrgLevelData';

const useSelectionsOrgLevelDropdown = (organizationCode, levelCode) => {
  const { selectionsOrgLevelState, setSelectionsOrgLevelState, getOrgLevelByOrganizationCodeAndLevelCode
  } = useSelectionsOrgLevelData(); 

  useEffect(() => {
    if (organizationCode && levelCode) {
      if (selectionsOrgLevelState.isArrayLoading !== true
        && (selectionsOrgLevelState.isArrayLoaded !== true || selectionsOrgLevelState.organizationCode !== organizationCode
          || selectionsOrgLevelState.levelCode !== levelCode)) {
        const getOrgLevelPromise = getOrgLevelByOrganizationCodeAndLevelCode(organizationCode, levelCode);

        if (getOrgLevelPromise !== null) {
          getOrgLevelPromise.then((newState) => {
            if (newState !== null) {
              setSelectionsOrgLevelState({
                ...newState,
                options: newState.arrayData.map((x) => { return { id: x.orgUnitId, name: x.orgUnitName } }),
                areOptionsLoaded: true
              });
            }
          }).catch((e) => {
            console.log(e);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsOrgLevelState, organizationCode, levelCode]);

  return {
    selectionsOrgLevelState
  };
};

export default useSelectionsOrgLevelDropdown;