import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeRestrictionsTypeMembershipValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeRestrictions from './UseOmeRestrictions';

import useOrgRoleData from '../../../../../common/state/orgRole/UseOrgRoleData';
import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  membershipOptions: [],
  tryRedirect: false
}

const INITIAL_FORM_STATE = {
  membership: [],
  restrictionFormState: {}
};

const useOmeRestrictionsTypeMembership = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgRoleState, getOrgRoleDataByRoleGroupId } = useOrgRoleData();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formStateHasBeenSaved } = useOmeRestrictions();
  const { OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { formState, errorState, onFormValueChanged, handleSubmit, isDirty, isSubmitting, setFormData, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (Object.keys(formState.restrictionFormState).length > 0) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS, { state: { restrictionFormState: formState.restrictionFormState } });
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS, { state: { restrictionFormState: formState.restrictionFormState } });
      }
    }
    else {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
  };

  function submitFormCallback() {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));

    //Save any changes to omeMeetEligibility made on main restriction page
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      omeMeetId: updatedOmeMeet.omeMeetId,
      usCitizenOnly: formState.restrictionFormState?.usCitizenOnly ?? null,
      nationalTeamOnly: formState.restrictionFormState?.nationalTeamOnly ?? null,
      allowRepOtherCountry: formState.restrictionFormState?.allowRepOtherCountry ?? null,
      allowUnattachedRoster: formState.restrictionFormState?.allowUnattachedRoster ?? null,
      allowUnattachedAthleteEntry: formState.restrictionFormState?.allowUnattachedAthleteEntry ?? null
    }

    updatedOmeMeet.omeMeetEligibility[0].omeMeetEligibilityOrgRole =
      formState.membership.map(x => {
        return (
          {
            omeMeetEligibilityId: updatedOmeMeet.omeMeetEligibility[0]?.omeMeetEligibilityId,
            orgRoleId: x.id
          }
        )
      });

    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });
  };

  function membershipFormStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    //Edit comp category restriction
    if (omeMeetState.objData.omeMeetEligibility[0] && omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole?.length > 0) {
      const orgRoleIdArray = omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.map(x => { return { id: x.orgRoleId } });
      const orgRoleIdArrayValues = [];
      for (let i = 0; i < orgRoleIdArray.length; i++) {
        orgRoleIdArrayValues.push({ id: orgRoleIdArray[i].id, name: state.membershipOptions.find(x => x.id === orgRoleIdArray[i].id)?.name || '' });
      }

      for (let i = 0; i < orgRoleIdArrayValues.length; i++) {
        let match = formState.membership?.find(x => x.id === orgRoleIdArrayValues[i].id);
        if (!match) {
          return false;
        }
      }

      for (let i = 0; i < formState.membership?.length; i++) {
        let match = orgRoleIdArrayValues?.find(x => x.id === formState.membership[i].id);
        if (!match) {
          return false;
        }
      }
    }
    //Add comp category restriction
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID !== orgRoleState.roleGroupId) {
      getOrgRoleDataByRoleGroupId(OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleState, OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID])

  useEffect(() => {
    if (orgRoleState.isArrayLoaded === true && OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID === orgRoleState.roleGroupId) {
      const membershipOptions = [];

      for (const orgRole of orgRoleState.arrayData) {
        if (orgRole.orgRoleId) {
          membershipOptions.push({ id: orgRole.orgRoleId, name: orgRole.orgRoleName });
        }
      }

      setState({ ...state, membershipOptions: membershipOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleState, OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID]);

  useEffect(() => {
    if (orgRoleState.isArrayLoaded === true && OME_ELIGIBLE_ATHLETE_ROLE_GROUP_ID === orgRoleState.roleGroupId && state.membershipOptions.length > 0
      && Object.keys(omeMeetState.objData).length > 0) {
      if (omeMeetState.objData.omeMeetEligibility[0]) {
        //Edit
        const orgRoleIdArray = omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.map(x => { return { id: x.orgRoleId } });
        const orgRoleIdArrayValues = [];
        for (let i = 0; i < orgRoleIdArray.length; i++) {
          orgRoleIdArrayValues.push({ id: orgRoleIdArray[i].id, name: state.membershipOptions.find(x => x.id === orgRoleIdArray[i].id)?.name || '' });
        }
        setFormData({
          ...formState,
          membership: orgRoleIdArrayValues || [],
          restrictionFormState: location.state && location.state?.restrictionFormState ? location.state?.restrictionFormState : {}
        });
        if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
          setIsDirty(true);
        }
      }
      else {
        //Add
        setFormData({
          ...formState,
          restrictionFormState: location.state && location.state?.restrictionFormState ? location.state?.restrictionFormState : {}
        });
        if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
          setIsDirty(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleState.isArrayLoaded, state, omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.restrictionFormState);
      const isMembershipSaved = membershipFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isMembershipSaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    state,
    omeMeetState,
    orgRoleState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeRestrictionsTypeMembership;
