import { useEffect } from 'react';

import validate from './LaneSheetAddAthleteFormValidation';

import useForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  memberId: '',
  matchedMember: {},
  existingAthletes: []
};

const useLaneSheetAddAthleteForm = (onSubmitFormCallback, existingAthletes) => {
  const { formState, errorState, isSubmitting, setFormData, updateFormState, handleSubmit
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    updateFormState('existingAthletes', existingAthletes || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAthletes]);

  return {
    isLoading: isSubmitting,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    setFormData,
    handleSubmit
  };
};

export default useLaneSheetAddAthleteForm;