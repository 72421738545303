import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postMeetResultsDocFilesData from './PostMeetResultsDocFilesData';

const BASE_UPLOAD_URL = 'https://meetdocs.blob.core.windows.net/meetresultdocs/';
const GET_MEET_FILES_PATH = '/MeetFiles/meetresultdocs?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetResultsDocFilesData = {
  BASE_UPLOAD_URL,
  GET_MEET_FILES_PATH,
  INITIAL_STATE,
  postMeetResultsDocFilesData
};

export default MeetResultsDocFilesData;