import { useEffect } from "react";

import useLaneSheetHeatRelayTeamsData from "../../../state/laneSheetHeatRelayTeams/UseLaneSheetHeatRelayTeamsData";
import useLaneSheetMeetEventRelayTeamsData from "../../../state/laneSheetMeetEventRelayTeams/UseLaneSheetMeetEventRelayTeamsData";
import useLaneSheetData from "../../../state/laneSheet/UseLaneSheetData";

const useLaneSheetHeatRelayTeamsPopup = (laneSheetHeat, onClosePopup) => {
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetMeetEventRelayTeamsState, getLaneSheetMeetEventRelayTeams, resetLaneSheetMeetEventRelayTeamsState
  } = useLaneSheetMeetEventRelayTeamsData();
  const { laneSheetHeatRelayTeamsState, getLaneSheetHeatRelayTeams, putLaneSheetHeatRelayTeams,
    resetLaneSheetHeatRelayTeamsState
  } = useLaneSheetHeatRelayTeamsData();

  const onSubmitFormCallback = (formState) => {
    const laneSheetHeatId = laneSheetHeat.laneSheetHeatId;
    const numberOfLanes = laneSheetState.objData?.numberOfLanes;
    const meetEventRelayTeams = laneSheetMeetEventRelayTeamsState.arrayData;

    if (laneSheetHeatId > 0 && numberOfLanes > 0 && Array.isArray(meetEventRelayTeams)) {
      const laneSheetHeatRelayTeamsArray = createLaneSheetHeatRelayTeamsArray(formState, numberOfLanes, meetEventRelayTeams);
      const putLaneSheetHeatRelayTeamsPromise = putLaneSheetHeatRelayTeams(laneSheetHeatId, laneSheetHeatRelayTeamsArray);

      if (putLaneSheetHeatRelayTeamsPromise !== null) {
        putLaneSheetHeatRelayTeamsPromise.then((newState) => {
          if (newState !== null) {
            onClosePopup();
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  useEffect(() => {
    const laneSheetHeatId = laneSheetHeat.laneSheetHeatId;

    if (laneSheetHeatId > 0) {
      const getLaneSheetHeatRelayTeamsPromise = getLaneSheetHeatRelayTeams(laneSheetHeatId);

      if (getLaneSheetHeatRelayTeamsPromise !== null) {
        getLaneSheetHeatRelayTeamsPromise.catch((e) => {
          //TODO context error
        });
      }
    } else {
      resetLaneSheetHeatRelayTeamsState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetHeat]);

  useEffect(() => {
    const laneSheetEventId = laneSheetHeat.laneSheetEventId;

    if (laneSheetEventId > 0) {
      const getLaneSheetMeetEventRelayTeamsPromise = getLaneSheetMeetEventRelayTeams(laneSheetEventId);

      if (getLaneSheetMeetEventRelayTeamsPromise !== null) {
        getLaneSheetMeetEventRelayTeamsPromise.catch((e) => {
          //TODO context error
        });
      }
    } else {
      resetLaneSheetMeetEventRelayTeamsState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetHeat]);

  function createLaneSheetHeatRelayTeamsArray(formState, numberOfLanes, meetEventRelayTeams) {
    const newArrayData = [];

    for (let i = 1; i <= numberOfLanes; i++) {
      const orgUnitId = formState[`orgUnitId${i}`];

      if (orgUnitId) {
        const targetRelayTeam = meetEventRelayTeams.find(x => x.orgUnitId === orgUnitId);

        if (targetRelayTeam) {
          newArrayData.push({
            laneNumber: i,
            orgUnitId,
            orgUnitName: targetRelayTeam.orgUnitName,
            orgUnitCode: targetRelayTeam.orgUnitCode
          });
        }
      }
    }

    return newArrayData;
  };

  return {
    isLoading: laneSheetHeatRelayTeamsState.isArrayLoading || laneSheetMeetEventRelayTeamsState.isArrayLoading,
    numberOfLanes: laneSheetState.objData?.numberOfLanes || 0,
    startingLaneNumber: laneSheetState.objData?.startingLaneNumber || 1,
    meetEventRelayTeams: laneSheetMeetEventRelayTeamsState.arrayData || [],
    heatRelayTeams: laneSheetHeatRelayTeamsState.arrayData || [],
    onSubmitFormCallback
  };
};

export default useLaneSheetHeatRelayTeamsPopup;