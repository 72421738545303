import React, { Fragment } from 'react';

import useAthleteUserPurchases from './UseAthleteUserPurchases';

import AthleteUserPurchasesGrid from './AthleteUserPurchasesGrid';
import AthleteUserAdditionalPurchasesGrid from './AthleteUserAdditionalPurchasesGrid';
import AthleteUserPreviousPurchasesGrid from './AthleteUserPurchasesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AthleteUserPurchasesWrite = () => {
  const { omeMeetState, omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, orgUnitAthleteEntryPurchaseState, handleSubmit, errorState, onAdditionalPurchaseValueChanged, onToggle, state,
    submitAthleteEntryAllowed, allAthleteEventsApproved } = useAthleteUserPurchases();

  return (
    <Fragment>
      <div className={omeMeetOrgUnitEntryState.objData.orgUnit?.orgUnitName === 'Unattached' ? global.DisplayComponent : global.HideComponent}>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Requirements to be able to Make Purchases and Submit Entry</Headings.H3>
          </div>
          <div className='col-xs-12 usas-extra-bottom-margin'>
            <li className='usas-extra-bottom-margin'>Athlete must have a status of 'Events Approved' or 'Submitted' {omeMeetState.objData.omeMeetEligibility[0].allowRelayOnlySwimmer === true ? "or 'Relay Only Swimmer'" : omeMeetState.objData.omeMeetEligibility[0].allowRelayOnlySwimmer === false ? "('Relay Only Swimmer' status is not allowed)" : ''} {allAthleteEventsApproved() === true ? <b>(Requirement Completed)</b> : <span style={{ color: 'red' }}>(Requirement Not Completed)</span>}</li>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className={state.showCurrent === true ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12">
                <Headings.H3>Required Purchases</Headings.H3>
              </div>
              <div className="col-xs-12">
                <AthleteUserPurchasesGrid
                  gridData={orgUnitAthleteEntryPurchaseState.objData?.currentPurchase?.omeMeetPurchase}
                  isLoading={orgUnitAthleteEntryPurchaseState.isObjLoading}
                />
              </div>
              {orgUnitAthleteEntryPurchaseState.objData?.currentPurchase?.additionalPurchase?.length > 0 &&
                <Fragment>
                  <div className="col-xs-12 usas-extra-top-margin">
                    <Headings.H3>Additional Purchases</Headings.H3>
                  </div>
                  <div className="col-xs-12">
                    <AthleteUserAdditionalPurchasesGrid
                      gridData={orgUnitAthleteEntryPurchaseState.objData?.currentPurchase?.additionalPurchase}
                      isLoading={orgUnitAthleteEntryPurchaseState.isObjLoading}
                      errorState={errorState}
                      onAdditionalPurchaseValueChanged={onAdditionalPurchaseValueChanged}
                    />
                  </div>
                </Fragment>}
            </div>
            <div className={state.showCurrent === false ? global.DisplayComponent : global.HideComponent}>
              {orgUnitAthleteEntryPurchaseState.objData?.previousPurchases?.length > 0 &&
                <Fragment>
                  <div className="col-xs-12">
                    <Headings.H3>Previous Purchases Summary</Headings.H3>
                  </div>
                  <div className="col-xs-12">
                    <AthleteUserPreviousPurchasesGrid
                      gridData={orgUnitAthleteEntryPurchaseState.objData?.previousPurchases}
                      isLoading={orgUnitAthleteEntryPurchaseState.isObjLoading}
                    />
                  </div>
                </Fragment>}
            </div>
            {orgUnitAthleteEntryPurchaseState.objData?.previousPurchases?.length > 0 &&
              <div className="col-xs-12 usas-extra-top-margin">
                <button className={global.ToggleButton} type="button" onClick={() => onToggle()}>{state.showCurrent === true ? 'Show Previous Purchases Summary' : 'Show Current Purchases'}</button>
              </div>}
            <div className={state.showCurrent === true && state.showPayButton === true && submitAthleteEntryAllowed() === true ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                <PrimaryButton type="submit">{omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.status === Constants.WORKFLOW_STATUS_SUBMITTED ? 'Pay' : 'Pay & Submit'}</PrimaryButton>
              </div>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={orgUnitAthleteEntryPurchaseState.isObjLoading || state.tryRedirect === true}
        />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={orgUnitAthleteEntryPurchaseState.isSaving}
        />
      </div>
      <div className={omeMeetOrgUnitEntryState.objData.orgUnit?.orgUnitName === 'Unattached' ? global.HideComponent : global.DisplayComponent}>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Purchases</Headings.H3>
          </div>
          <div className="col-xs-12">
            <p>Only Unattached Athletes can make purchases. Teams will complete any purchases associated with their athletes through the Team Entry process.</p>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default AthleteUserPurchasesWrite;