import { useContext } from 'react';

import { OmeMeetOrgUnitEntryPurchaseStateContext } from './OmeMeetOrgUnitEntryPurchaseContextProvider';

import OmeMeetOrgUnitEntryPurchaseData from './OmeMeetOrgUnitEntryPurchaseData';

const useOmeMeetOrgUnitEntryPurchaseData = () => {
  const [omeMeetOrgUnitEntryPurchaseState, setOmeMeetOrgUnitEntryPurchaseState] = useContext(OmeMeetOrgUnitEntryPurchaseStateContext);

  const getOmeMeetOrgUnitEntryPurchaseById = (omeMeetOrgUnitEntryPurchaseId) =>
    OmeMeetOrgUnitEntryPurchaseData.getOmeMeetOrgUnitEntryPurchaseByIdData(omeMeetOrgUnitEntryPurchaseId, omeMeetOrgUnitEntryPurchaseState, setOmeMeetOrgUnitEntryPurchaseState);

  const getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryId = (omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitEntryPurchaseData.getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryIdData(omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryPurchaseState, setOmeMeetOrgUnitEntryPurchaseState);

  const postOmeMeetOrgUnitEntryPurchase = (omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryPurchaseObj) =>
    OmeMeetOrgUnitEntryPurchaseData.postOmeMeetOrgUnitEntryPurchaseData(omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryPurchaseObj, omeMeetOrgUnitEntryPurchaseState, setOmeMeetOrgUnitEntryPurchaseState);

  const clearOmeMeetOrgUnitEntryPurchaseObjData = () => {
    setOmeMeetOrgUnitEntryPurchaseState({
      ...omeMeetOrgUnitEntryPurchaseState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetOrgUnitEntryPurchaseArrayData = () => {
    setOmeMeetOrgUnitEntryPurchaseState({
      ...omeMeetOrgUnitEntryPurchaseState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeetOrgUnitEntryPurchase = () => {
    setOmeMeetOrgUnitEntryPurchaseState({
      ...omeMeetOrgUnitEntryPurchaseState,
      isSaved: false
    });
  };

  return {
    omeMeetOrgUnitEntryPurchaseState,
    setOmeMeetOrgUnitEntryPurchaseState,
    getOmeMeetOrgUnitEntryPurchaseById,
    getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryId,
    postOmeMeetOrgUnitEntryPurchase,
    clearOmeMeetOrgUnitEntryPurchaseObjData,
    clearOmeMeetOrgUnitEntryPurchaseArrayData,
    confirmSaveOmeMeetOrgUnitEntryPurchase
  };
};

export default useOmeMeetOrgUnitEntryPurchaseData;