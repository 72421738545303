import { Fragment } from "react";

import useOrgUnitAdminRelayEntriesDetail from './UseOrgUnitAdminRelayEntriesDetail';

import OrgUnitAdminRelayEntrySelectionGrid from "./OrgUnitAdminRelayEntrySelectionGrid";

import EventMeetEventDropdown from "../../../../components/dropdowns/EventMeetEventDropdown";

import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../../common/components/headings/Headings";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from "../../../../../common/utils/Constants";
import { formatTimeForDisplay } from "../../../../../common/utils/TimesUtils";

const OrgUnitAdminRelayEntriesDetail = () => {
    const { state, omeMeetState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, gridState, onSaveClicked,
        onBackClicked, formState, errorState, onFormValueChanged, relayTeamOptionsState, onValueTextPairChanged,
        relayAthleteMeetEventState, relayAthleteEntryTimeState, omeMeetOrgUnitAthleteEntryState,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState,
        calculatedTeamEntryTimeState, isLarge, MSG, omeMeetOrgUnitEntryId, onAddOverrideRelayClicked, eventMeetEventState,
        setHandleEventChange
    } = useOrgUnitAdminRelayEntriesDetail();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <Headings.H3>Manage Relay Team</Headings.H3>
                </div>
                <div className="col-xs-12 usas-extra-bottom-margin">
                    <p className={global.HeaderText}><b>Relay Event:</b> {state.event?.eventName || ''} ({state.event?.eventCompetitionGenderTypeName || ''}, {state.event?.ageGroupName || ''}), Session {state.event?.meetSessionName || ''}</p>
                    {(state.event?.timeCuts && state.event?.timeCuts.length > 0) &&
                        <p className={global.HeaderText}><b>Qualifying Date Range:</b> {`${formState.qualifyingStartDate} - ${formState.qualifyingEndDate}`}</p>}
                </div>
                {Object.keys(state.relayTeam).length === 0 &&
                    <div className="col-xs-12 usas-extra-bottom-margin">
                        <ActionIntraPageButton onClick={onAddOverrideRelayClicked}>Add Override Time</ActionIntraPageButton>
                    </div>}
            </div>
            <form>
                <div className="row">
                    {Object.keys(state.relayTeam).length > 0 ?
                        <div className="col-xs-12"><p className={global.HeaderText}><b>Relay Team Name:</b> {formState.relayTeamName}</p></div>
                        : <Fragment />}
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        {Object.keys(state.relayTeam).length > 0 ?
                            <YesNoSwitch
                                label="Aggregate?"
                                name="isAggregate"
                                checked={formState.isAggregate}
                                error={errorState.isAggregate}
                                message={errorState.isAggregate}
                                onChange={(value) => { onFormValueChanged('isAggregate', value) }}
                                disabled={true} /> :
                            <YesNoSwitch
                                label="Add Relay Via Aggregate?"
                                name="isAggregate"
                                checked={formState.isAggregate}
                                error={errorState.isAggregate}
                                message={errorState.isAggregate}
                                onChange={(value) => { onFormValueChanged('isAggregate', value) }} />
                        }
                    </div>
                    {Object.keys(state.relayTeam).length > 0 &&
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <YesNoSwitch
                                label="Override?"
                                name="swimTimeEntryOverride"
                                checked={state.relayTeam?.swimTimeEntryOverride}
                                disabled={true} />
                        </div>}
                    {Object.keys(state.relayTeam).length > 0 &&
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <ReadOnly
                                label="Status"
                                name="status"
                                value={state.relayTeam?.status}
                            />
                        </div>}
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        {Object.keys(state.relayTeam).length > 0 ?
                            <Fragment /> :
                            <Dropdown
                                label="Relay Team Name*"
                                name="relayTeamId"
                                options={relayTeamOptionsState.options}
                                value={formState.relayTeamId}
                                error={errorState.relayTeamId}
                                message={errorState.relayTeamId}
                                isLoading={false}
                                onChange={(newValue, newValueLabel, e) => {
                                    e && e.target && e.target.value &&
                                        onValueTextPairChanged(newValue, 'relayTeamId', newValueLabel, 'relayTeamName');
                                }} />}
                    </div>
                    {formState.isAggregate === true && Object.keys(state.relayTeam).length === 0 &&
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <EventMeetEventDropdown
                                label="Event*"
                                name="eventId"
                                value={formState.eventId}
                                error={errorState.eventId}
                                message={errorState.eventId}
                                onChange={(value, newValueLabel, e) => {
                                    e && e.target && e.target.value &&
                                        setHandleEventChange(true);
                                    onValueTextPairChanged(value, 'eventId', newValueLabel, 'eventName')

                                }}
                                meetEventId={state.event?.meetEventId}
                            />
                        </div>}
                </div>
            </form>
            {formState.isAggregate === false &&
                <div className={formState.isAggregate === false ? global.DisplayComponent : global.HideComponent}>
                    <div className="row">
                        <div className="col-xs-12">
                            <p className={global.HeaderText}><b>Team Entry Time:</b> {state.relayTeam?.entryTime ? formatTimeForDisplay(state.relayTeam?.entryTime) : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime ? formatTimeForDisplay(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime) : `Unable to Find a Previous Qualifying Team Entry Time${omeMeetState?.objData.omeMeetEligibility[0]?.relayTeamFromRosterOnly === true ? ' with the Provided Roster' : ''}`} {state.relayTeam?.entryTime || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime ? formState.courseCode || '' : ''}</p>
                            {state.relayTeam?.entryTime ? <p className={global.HeaderText}><b>Time Event:</b> {state.relayTeam?.qualifyingEventName || ''}</p> : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.qualifyingEventName ? <p className={global.HeaderText}><b>Time Event:</b> {omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.qualifyingEventName || ''}</p> : <Fragment />}
                            {state.relayTeam?.entryTime && state.relayTeam.teamAthletes?.length > 0 &&
                                <Fragment>
                                    <p className={global.HeaderText}><b>Athletes:</b> {state.relayTeam?.teamAthletes && state.relayTeam?.teamAthletes[0] ? state.relayTeam?.teamAthletes[0]?.firstName + ' ' + state.relayTeam?.teamAthletes[0]?.lastName + ' (' + state.relayTeam?.teamAthletes[0]?.competitionGenderTypeName + ', ' + state.relayTeam?.teamAthletes[0]?.ageAtMeet + ')' : 'Unknown Leg 1 Athlete'},&nbsp;
                                        {state.relayTeam?.teamAthletes && state.relayTeam?.teamAthletes[1] ? state.relayTeam?.teamAthletes[1]?.firstName + ' ' + state.relayTeam?.teamAthletes[1]?.lastName + ' (' + state.relayTeam?.teamAthletes[1]?.competitionGenderTypeName + ', ' + state.relayTeam?.teamAthletes[1]?.ageAtMeet + ')' : 'Unknown Leg 2 Athlete'},&nbsp;
                                        {state.relayTeam?.teamAthletes && state.relayTeam?.teamAthletes[2] ? state.relayTeam?.teamAthletes[2]?.firstName + ' ' + state.relayTeam?.teamAthletes[2]?.lastName + ' (' + state.relayTeam?.teamAthletes[2]?.competitionGenderTypeName + ', ' + state.relayTeam?.teamAthletes[2]?.ageAtMeet + ')' : 'Unknown Leg 3 Athlete'},&nbsp;
                                        {state.relayTeam?.teamAthletes && state.relayTeam?.teamAthletes[3] ? state.relayTeam?.teamAthletes[3]?.firstName + ' ' + state.relayTeam?.teamAthletes[3]?.lastName + ' (' + state.relayTeam?.teamAthletes[3]?.competitionGenderTypeName + ', ' + state.relayTeam?.teamAthletes[3]?.ageAtMeet + ')' : 'Unknown Leg 4 Athlete'}</p>
                                </Fragment>}
                            {state.relayTeam?.entryTime === undefined && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime &&
                                <Fragment>
                                    <p className={global.HeaderText}><b>Athletes:</b> {omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0] ? omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.firstName + ' ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.lastName + ' (' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.competitionGenderTypeName + ', ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.ageAtMeet + ')' : 'Unknown Leg 1 Athlete'},&nbsp;
                                        {omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1] ? omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.firstName + ' ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.lastName + ' (' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.competitionGenderTypeName + ', ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.ageAtMeet + ')' : 'Unknown Leg 2 Athlete'},&nbsp;
                                        {omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2] ? omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.firstName + ' ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.lastName + ' (' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.competitionGenderTypeName + ', ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.ageAtMeet + ')' : 'Unknown Leg 3 Athlete'},&nbsp;
                                        {omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3] ? omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.firstName + ' ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.lastName + ' (' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.competitionGenderTypeName + ', ' + omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.ageAtMeet + ')' : 'Unknown Leg 4 Athlete'}</p>
                                </Fragment>}
                        </div>
                    </div>
                </div>}
            {formState.isAggregate === true &&
                <div className={formState.isAggregate === true ? global.DisplayComponent : global.HideComponent}>
                    <div className="row">
                        <div className="col-xs-12">
                            {(formState.eventId !== Constants.DEFAULT_ID || Object.keys(state.relayTeam).length > 0) &&
                                <p className={global.HeaderText}><b>Team Entry Time:</b> {calculatedTeamEntryTimeState.entryTime || 'NT'} {calculatedTeamEntryTimeState.entryTime !== MSG && calculatedTeamEntryTimeState.entryTime && formState?.courseCode ? formState?.courseCode : ''}</p>}
                            {formState.isAggregate === true && Object.keys(state.relayTeam).length > 0 &&
                                <p className={global.HeaderText}><b>Time Event:</b> {state.relayTeam?.qualifyingEventName || ''}</p>}
                        </div>
                        {Object.keys(state.relayTeam).length === 0 && formState.eventId !== Constants.DEFAULT_ID &&
                            <div className="col-xs-12">
                                <p><b>For their name to appear as a relay leg option, an athlete{omeMeetState?.objData.omeMeetEligibility[0]?.relayTeamFromRosterOnly === true ? ' must be on the roster and' : ''} cannot be associated with an exisiting relay team for the event</b></p>
                            </div>}
                    </div>
                    {(formState.eventId !== Constants.DEFAULT_ID || Object.keys(state.relayTeam).length > 0) &&
                        <div className={formState.eventId !== Constants.DEFAULT_ID || Object.keys(state.relayTeam).length > 0 ? global.DisplayComponent : global.HideComponent}>
                            <div className="row">
                                <div className="col-xs-12 usas-extra-bottom-margin">
                                    <OrgUnitAdminRelayEntrySelectionGrid
                                        state={state}
                                        gridData={gridState.gridData}
                                        isLoading={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading}
                                        meetEventId={state.event?.meetEventId}
                                        omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
                                        formState={formState}
                                        errorState={errorState}
                                        onValueTextPairChanged={onValueTextPairChanged}
                                        isLarge={isLarge}
                                    />
                                </div>
                            </div>
                        </div>}
                </div>}
            {errorState.general &&
                <div className="row">
                    <div className="col-xs-12">
                        <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
                    </div>
                </div>
            }
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    {((formState.isAggregate === false && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime) || (formState.isAggregate === true && Object.keys(state.relayTeam).length === 0)) &&
                        <Fragment><PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;</Fragment>}
                    <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
                </div>
            </div>
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isObjLoading ||
                    relayAthleteMeetEventState.isArrayLoading || relayAthleteEntryTimeState.isObjLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading ||
                    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
                    omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.isObjLoading || eventMeetEventState.isArrayLoading} />
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isSaving} />
        </Fragment >
    )
}
export default OrgUnitAdminRelayEntriesDetail;