import React, { createContext, useState } from 'react';

export const LaneSheetLandingFilterStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  showFilters: true,
};

const LaneSheetLandingFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <LaneSheetLandingFilterStateContext.Provider value={stateHook}>
      {children}
    </LaneSheetLandingFilterStateContext.Provider>
  );
};

export default LaneSheetLandingFilterContextProvider;