import React, { createContext, useState } from 'react';

export const SelectionsLoadingModalStateContext = createContext();

const INITIAL_STATE = {
  isContextError: false
};

const SelectionsLoadingModalContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsLoadingModalStateContext.Provider value={stateHook}>
      {children}
    </SelectionsLoadingModalStateContext.Provider>
  );
};

export default SelectionsLoadingModalContextProvider;