import React, { Fragment } from 'react';

import useOmePricingAdditionalPurchases from './UseOmePricingAdditionalPurchases';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';
import OmeFeeTypeDropdown from '../../../../components/dropdowns/OmeFeeTypeDropdown';

import Textarea from '../../../../../common/components/inputs/Textarea';
import CurrencyInput from '../../../../../common/components/inputs/CurrencyInput';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ToolTip from '../../../../../common/components/tooltips/ToolTip';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OmePricingAdditionalPurchases = () => {
  const {
    omeMeetState,
    omeFeeTypeState,
    errorState,
    formState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onBackButtonClicked,
  } = useOmePricingAdditionalPurchases();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_PRICING_ADDITIONAL_PURCHASES}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit}>
          <div className='row usas-extra-bottom-margin'>
            <div className='col-xs-12 col-sm-6'>
              <OmeFeeTypeDropdown
                label="Fee Type*"
                name="omeFeeTypeId"
                additionalPurchasesOnly={true}
                value={formState.omeFeeTypeId}
                error={errorState.omeFeeTypeId}
                message={errorState.omeFeeTypeId}
                onChange={(value, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(value, 'omeFeeTypeId', newValueLabel, 'omeFeeTypeName')
                }}
              />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <CurrencyInput
                label="Cost*"
                name="amount"
                value={formState.amount}
                error={errorState.amount}
                message={errorState.amount}
                onChange={(value) => { onFormValueChanged("amount", value); }}
              />
            </div>
            <div className='col-xs-12'>
              <Textarea
                className={global.Textarea}
                label={<Fragment>Description* <span><ToolTip label="The Description will be displayed to Team Entry users on their 'Purchases' page (e.g. 'Single Day Meal Ticket', 'Full Meet Meal Ticket')."></ToolTip></span></Fragment>}
                name="description"
                value={formState.description}
                error={errorState.description}
                message={errorState.description}
                onChange={(value) => { onFormValueChanged("description", value); }}
              />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={omeFeeTypeState.isArrayLoading}
        />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving}
        />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmePricingAdditionalPurchases;