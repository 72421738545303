import { Fragment } from 'react';

import RelayAthleteMeetEventCombobox from '../../../../components/comboboxes/relayAthleteMeetEventCombobox/RelayAthleteMeetEventCombobox';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './RelayEntries.module.css';
import TimeInput from '../../../../../common/components/inputs/TimeInput';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Input from '../../../../../common/components/inputs/Input';

const GridRow = ({ legNumber, athlete, name, value, name2, value2, name3, name4, athleteUsingOverrideTime,
  meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, onFormValueChanged }) => {
  return (
    <Fragment key={value + 'Fragment'}>
      <tr key={value}>
        <td>{legNumber}</td>
        <td className={style.OverrideTableStyling}>
          <RelayAthleteMeetEventCombobox
            label={""}
            name={name}
            valueToMatch={formState[name]}
            error={errorState[value]}
            message={errorState[value]}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, value, newValueLabel, name, e); }}
            meetEventId={meetEventId}
            omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId} />
        </td>
        <td>{athlete.competitionGenderTypeName || ''}</td>
        <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
        <td>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : ''}</td>
        <td className={style.OverrideTableStyling}>
          <TimeInput
            label={""}
            name={name2}
            value={formState[name2]}
            error={errorState[name2]}
            message={errorState[name2]}
            onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, name2, value, value2); }} />
        </td>
        <td className={style.OverrideTableStyling}>
          <span className={athleteUsingOverrideTime === true ? global.DisplayComponent : global.HideComponent}>
            <DatePicker
              label=""
              name={name3}
              value={formState[name3]}
              error={errorState[name3]}
              message={errorState[name3]}
              onChange={(value) => { onFormValueChanged(name3, value); }} />
          </span>
        </td>
        <td className={style.OverrideTableStyling}>
          <span className={athleteUsingOverrideTime === true ? global.DisplayComponent : global.HideComponent}>
            <Input
              label=''
              name={name4}
              value={formState[name4]}
              error={errorState[name4]}
              message={errorState[name4]}
              onChange={(value) => { onFormValueChanged(name4, value) }} />
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

const OrgUnitAdminRelayEntrySelectionOverrideLargeGrid = ({ gridData, isLoading, meetEventId, omeMeetOrgUnitEntryId, formState,
  errorState, onValueTextPairChanged, onFormValueChanged }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Leg</th>
          <th>Name</th>
          <th style={{ minWidth: '115px' }}>Comp. Category</th>
          <th style={{ minWidth: '90px' }}>Birth Date</th>
          <th style={{ minWidth: '90px' }}>Age at Meet</th>
          <th>Entry Time ({formState.courseCode || ''})</th>
          <th>Swim Date</th>
          <th>Meet Name</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan={"8"}>Loading...</td></tr>
          : <Fragment>
            <GridRow
              key={1}
              legNumber={"1"}
              athlete={gridData.athletes[0]}
              name="athlete1Name"
              value="athlete1PersonId"
              name2="athlete1SwimTime"
              value2="athlete1SwimTimeValue"
              name3="athlete1EntrySwimDate"
              name4="athlete1EntryTimeMeetName"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              onFormValueChanged={onFormValueChanged}
              athleteUsingOverrideTime={formState.athlete1UsingOverrideTime}
            />
            <GridRow
              key={2}
              legNumber={"2"}
              athlete={gridData.athletes[1]}
              name="athlete2Name"
              value="athlete2PersonId"
              name2="athlete2SwimTime"
              value2="athlete2SwimTimeValue"
              name3="athlete2EntrySwimDate"
              name4="athlete2EntryTimeMeetName"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              onFormValueChanged={onFormValueChanged}
              athleteUsingOverrideTime={formState.athlete2UsingOverrideTime}
            />
            <GridRow
              key={3}
              legNumber={"3"}
              athlete={gridData.athletes[2]}
              name="athlete3Name"
              value="athlete3PersonId"
              name2="athlete3SwimTime"
              value2="athlete3SwimTimeValue"
              name3="athlete3EntrySwimDate"
              name4="athlete3EntryTimeMeetName"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              onFormValueChanged={onFormValueChanged}
              athleteUsingOverrideTime={formState.athlete3UsingOverrideTime}
            />
            <GridRow
              key={4}
              legNumber={"4"}
              athlete={gridData.athletes[3]}
              name="athlete4Name"
              value="athlete4PersonId"
              name2="athlete4SwimTime"
              value2="athlete4SwimTimeValue"
              name3="athlete4EntrySwimDate"
              name4="athlete4EntryTimeMeetName"
              meetEventId={meetEventId}
              omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
              onFormValueChanged={onFormValueChanged}
              athleteUsingOverrideTime={formState.athlete4UsingOverrideTime}
            />
          </Fragment>
        }
      </tbody>
    </table>

  );
}

export default OrgUnitAdminRelayEntrySelectionOverrideLargeGrid;