import React, { Fragment } from 'react';

import OrgUnitAdminRosterPermissionsWrite from './OrgUnitAdminRosterPermissionsWrite';
import OrgUnitAdminRosterPermissionsReadOnly from './OrgUnitAdminRosterPermissionsReadOnly';

import useOrgUnitAdminRosterPermissions from './UseOrgUnitAdminRosterPermissions';

const OrgUnitAdminRosterPermissions = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminRosterPermissions();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminRosterPermissionsWrite /> :
        <OrgUnitAdminRosterPermissionsReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminRosterPermissions;