import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryRosterData from './GetOrgUnitAthleteEntryRosterData';
import getOrgUnitAthleteEntryData from './GetOrgUnitAthleteEntryData';
import postOrgUnitAthleteEntryData from './PostOrgUnitAthleteEntryData';
import putOrgUnitAthleteEntryData from './PutOrgUnitAthleteEntryData';
import deleteOrgUnitAthleteEntryData from './DeleteOrgUnitAthleteEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitAthleteEntryData = {
  INITIAL_STATE, getOrgUnitAthleteEntryRosterData, getOrgUnitAthleteEntryData, 
  postOrgUnitAthleteEntryData, putOrgUnitAthleteEntryData, deleteOrgUnitAthleteEntryData
};

export default OmeMeetOrgUnitAthleteEntryData;