import { useContext } from 'react';

import LaneSheetMeetEventsData from './LaneSheetMeetEventsData';

import { LaneSheetMeetEventsStateContext } from './LaneSheetMeetEventsContextProvider';

const useLaneSheetMeetEventsData = () => {
  const [laneSheetMeetEventsState, setLaneSheetMeetEventsState] = useContext(LaneSheetMeetEventsStateContext);

  const postBulkLaneSheetMeetEventsOme = (laneSheetMeetId) => {
    return LaneSheetMeetEventsData.postBulkLaneSheetMeetEventsOmeData(laneSheetMeetId, laneSheetMeetEventsState, setLaneSheetMeetEventsState);
  };

  const postLaneSheetMeetEvent = (laneSheetMeetId, laneSheetMeetEventObj, state, setState) => {
    return LaneSheetMeetEventsData.postLaneSheetMeetEventData(laneSheetMeetId, laneSheetMeetEventObj, state, setState);
  };

  const getLaneSheetMeetEvents = (laneSheetMeetId) => {
    return LaneSheetMeetEventsData.getLaneSheetMeetEventsData(laneSheetMeetId, laneSheetMeetEventsState, setLaneSheetMeetEventsState);
  };

  const deleteLaneSheetMeetEvent = (laneSheetEventId, state, setState) => {
    return LaneSheetMeetEventsData.deleteLaneSheetMeetEventData(laneSheetEventId, state, setState);
  };

  return {
    laneSheetMeetEventsState,
    postBulkLaneSheetMeetEventsOme,
    postLaneSheetMeetEvent,
    getLaneSheetMeetEvents,
    deleteLaneSheetMeetEvent
  };
};

export default useLaneSheetMeetEventsData;