const HostNavLinks = {
  HOME: '/ome/host',
  OME_HOST_LOADING: '/ome/host/loading',
  OME_MEET_TEMPLATE_LANDING: '/ome/meettemplate/landing',
  OME_MEET_TEMPLATE_SEARCH: '/ome/meettemplate/search',
  OME_MEET_INFO: '/ome/host/meetinfo',
  OME_MEET_INFO_COURSE_PRIORITY: '/ome/host/meetinfo/coursepriority',
  OME_MEET_ELIGIBILITY: '/ome/host/eligibility',
  OME_MEET_ELIGIBILITY_BONUS_EVENTS: '/ome/host/eligibility/bonusevents',
  OME_MEET_ELIGIBILITY_COACH_PASSES: '/ome/host/eligibility/coachpasses',
  OME_MEET_INVITATIONS: '/ome/host/invitations',
  OME_MEET_RESTRICTIONS: '/ome/host/restrictions',
  OME_MEET_RESTRICTIONS_TYPE: '/ome/host/restrictions/type',
  OME_MEET_RESTRICTIONS_TYPE_MEMBERSHIP: '/ome/host/restrictions/type/membership',
  OME_MEET_RESTRICTIONS_TYPE_COMPETITION_CATEGORY: '/ome/host/restrictions/type/competitioncategory',
  OME_MEET_RESTRICTIONS_TYPE_AGE: '/ome/host/restrictions/type/age',
  OME_MEET_SESSIONS: '/ome/host/sessions',
  OME_MEET_SESSIONS_DETAIL: '/ome/host/sessions/detail',
  OME_MEET_AGE_GROUPS: '/ome/host/agegroups',
  OME_MEET_AGE_GROUPS_DETAIL: '/ome/host/agegroups/detail',
  OME_MEET_TIME_STANDARDS: '/ome/host/timestandards',
  OME_MEET_TIME_STANDARDS_DETAIL: '/ome/host/timestandards/detail',
  OME_MEET_EVENTS: '/ome/host/events',
  OME_MEET_EVENTS_DETAIL: '/ome/host/events/detail',
  OME_MEET_EVENTS_DETAIL_ALTERNATE: '/ome/host/events/detail/alternate',
  OME_MEET_PRICING: '/ome/host/pricing',
  OME_MEET_PRICING_ADDITIONAL_PURCHASES: '/ome/host/pricing/additionalpurchases',
  OME_MEET_SUBMIT: '/ome/host/submit'
};

export default HostNavLinks;