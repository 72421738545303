import { useState, useEffect } from 'react';

import validate from './LaneSheetHeatRelayTeamsFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  options: []
};

const useLaneSheetHeatRelayTeamsForm = (onSubmitFormCallback, numberOfLanes, meetEventRelayTeams, heatRelayTeams) => {
  const { formState, errorState, setFormData, onValueTextPairChanged, handleSubmit
  } = UseForm({}, onSubmitFormCallback, validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    const options = [];

    meetEventRelayTeams.forEach((relayTeam) => {
      options.push({
        id: relayTeam.orgUnitId,
        name: relayTeam.orgUnitName
      });
    });

    setOptionsState({
      ...optionsState,
      areOptionsLoaded: true,
      options
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetEventRelayTeams]);

  useEffect(() => {
    if (numberOfLanes > 0 && optionsState.areOptionsLoaded === true) {
      let newFormState = {};

      for (let i = 1; i <= numberOfLanes; i++) {
        newFormState[`orgUnitId${i}`] = '';
        newFormState[`orgUnitName${i}`] = '';
      }

      for (const relayTeam of heatRelayTeams) {
        const i = relayTeam.laneNumber;
        const targetRelayTeam = optionsState.options.find(x => x.id === relayTeam.orgUnitId);

        if (i > 0 && targetRelayTeam) {
          newFormState[`orgUnitId${i}`] = targetRelayTeam.id;
          newFormState[`orgUnitName${i}`] = targetRelayTeam.name;
        }
      }

      setFormData({ ...newFormState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfLanes, optionsState, heatRelayTeams]);

  return {
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    options: optionsState.options || []
  };
};

export default useLaneSheetHeatRelayTeamsForm;