import React, { createContext, useState } from 'react';

import OmeMeetHostData from './OmeMeetHostData';

export const OmeMeetHostStateContext = createContext();

const OmeMeetHostContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetHostData.INITIAL_STATE);

  return (
    <OmeMeetHostStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetHostStateContext.Provider>
  );
};

export default OmeMeetHostContextProvider;