export const localValidate = (formState) => {
  let errors = {};

  if (formState.membership.length === 0) {
    errors.membership = 'A selection is required'
  }

  return errors;
};

const OmeRestrictionsTypeMembershipValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OmeRestrictionsTypeMembershipValidation;