import ToolTipIcon from '../icons/ToolTipIcon';

import style from './ToolTip.module.css';

const ToolTip = ({ label }) => {
    return (
        <span className={style.ToolTip}>
            <ToolTipIcon />
            <span className={style.ToolTipText}>{label}</span>
        </span>

    );
};

export default ToolTip;