import { Fragment } from "react";

import SelectionParticipantRelayFormGridLarge from "./SelectionParticipantRelayFormGridLarge";

import Headings from "../../../../../common/components/headings/Headings";

import global from '../../../../../common/components/GlobalStyle.module.css';

const SelectionParticipantRelayFormGrid = ({ formState, errorState, onWillAttendChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <Headings.H5>Relay Legs</Headings.H5>
      </div>
    </div>
    <SelectionParticipantRelayFormGridLarge formState={formState} onWillAttendChanged={onWillAttendChanged} onValueTextPairChanged={onValueTextPairChanged} />
    {errorState.gridError &&
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-10">
          <p className={global.ErrorMessage}>{errorState.gridError}</p>
        </div>
      </div>
    }
  </Fragment>
);

export default SelectionParticipantRelayFormGrid;