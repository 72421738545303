export const localValidate = (formState) => {
  let errors = {};
  
  if (formState.allowUnattachedRoster !== true && formState.allowUnattachedRoster !== false) {
    errors.allowUnattachedRoster = 'A selection is required';
  }

  if (formState.allowUnattachedAthleteEntry !== true && formState.allowUnattachedAthleteEntry !== false) {
    errors.allowUnattachedAthleteEntry = 'A selection is required';
  }

  return errors;
};

const OmeRestrictionsValidationNonHQ = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeRestrictionsValidationNonHQ;