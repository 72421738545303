import { Fragment } from "react";

import NewSelectionSearchGridLarge from "./NewSelectionSearchGridLarge";

import global from '../../../../../common/components/GlobalStyle.module.css';

const NewSelectionSearchGrid = ({ gridData, isLoading, showFilters, onFiltersToggle, onCreateSelectionClicked, onExistingSelectionsClicked }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>
          </div>
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={onFiltersToggle}>
              {showFilters === true ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>
        </div>
      )
      : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <NewSelectionSearchGridLarge gridData={gridData} isLoading={isLoading} onCreateSelectionClicked={onCreateSelectionClicked} onExistingSelectionsClicked={onExistingSelectionsClicked} />
  </Fragment>
);

export default NewSelectionSearchGrid;