import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.status !== Constants.WORKFLOW_STATUS_CREATED) {
    if (formState.usCitizenOnly !== true && formState.usCitizenOnly !== false) {
      errors.usCitizenOnly = 'A selection is required';
    }

    if (formState.nationalTeamOnly !== true && formState.nationalTeamOnly !== false) {
      errors.nationalTeamOnly = 'A selection is required';
    }

    if (formState.allowRepOtherCountry !== true && formState.allowRepOtherCountry !== false) {
      errors.allowRepOtherCountry = 'A selection is required';
    }
  }

  if (formState.allowUnattachedRoster !== true && formState.allowUnattachedRoster !== false) {
    errors.allowUnattachedRoster = 'A selection is required';
  }

  if (formState.allowUnattachedAthleteEntry !== true && formState.allowUnattachedAthleteEntry !== false) {
    errors.allowUnattachedAthleteEntry = 'A selection is required';
  }

  return errors;
};

const OmeRestrictionsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeRestrictionsValidation;