import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import getInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import { eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete } from './LeftNavValidation';

const getInvitations = (viewName, route, omeMeetStateObjData, omeMeetInvitationsStateArrayData, meetStateObjData, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = viewName === Views.OME_MEET_INVITATIONS;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);
  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);

  const isComplete = isMeetInfoComplete === true && isEligibilityComplete === true && invitationsIsComplete(omeMeetInvitationsStateArrayData);

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true;

  const item = route === '' ? getInitialStateOmeRoute(Views.OME_MEET_INVITATIONS) : getInitialStateMeetRoute(Views.OME_MEET_INVITATIONS);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getInvitations;