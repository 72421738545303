import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const SelectionsActionsStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const SelectionsActionsContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsActionsStateContext.Provider value={stateHook}>
      {children}
    </SelectionsActionsStateContext.Provider>
  );
};

export default SelectionsActionsContextProvider;