import Constants from "../../../../../common/utils/Constants";
import { formatDateTimeTwelveHourClock } from "../../../../../common/utils/DateFunctions";
import { isValidDateTime } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  const orgUnitRegistrationStartDate = new Date(formState.orgUnitRegistrationStartDate);
  const orgUnitRegistrationEndDate = new Date(formState.orgUnitRegistrationEndDate);
  const meetEntryStartDate = new Date(formState.meetEntryStartDate);
  const meetEntryEndDate = new Date(formState.meetEntryEndDate);

  if (formState.orgUnitAthleteRegistrationAllowed !== true && formState.orgUnitAthleteRegistrationAllowed !== false) {
    errors.orgUnitAthleteRegistrationAllowed = 'A selection is required';
  }

  if (formState.orgUnitAthleteRegistrationAllowed === true) {
    //Athlete Entry Start Date
    if (formState.orgUnitRegistrationStartDate === Constants.BLANK_DATETIME_STRING) {
      errors.orgUnitRegistrationStartDate = 'Athlete Entry Start Date is required';
    } else if (!isValidDateTime(formState.orgUnitRegistrationStartDate)) {
      errors.orgUnitRegistrationStartDate = 'Athlete Entry Start Date must be a valid date and time';
    } else if (orgUnitRegistrationEndDate <= orgUnitRegistrationStartDate) {
      errors.orgUnitRegistrationStartDate = 'Athlete Entry Start Date must be before the Athlete Entry End Date';
    } else if (orgUnitRegistrationStartDate < meetEntryStartDate) {
      errors.orgUnitRegistrationStartDate = `Athlete Entry Start Date must be on or after the Meet Entry Start Date ${formState.meetEntryStartDateDatabaseFormat ? '(' + formatDateTimeTwelveHourClock(formState.meetEntryStartDateDatabaseFormat) + ')' : ''}`
    } else if (orgUnitRegistrationStartDate >= meetEntryEndDate) {
      errors.orgUnitRegistrationStartDate = `Athlete Entry Start Date must be before the Meet Entry End Date ${formState.meetEntryEndDateDatabaseFormat ? '(' + formatDateTimeTwelveHourClock(formState.meetEntryEndDateDatabaseFormat) + ')' : ''}`
    }

    //Athlete Entry End Date
    if (formState.orgUnitRegistrationEndDate === Constants.BLANK_DATETIME_STRING) {
      errors.orgUnitRegistrationEndDate = 'Athlete Entry End Date is required';
    } else if (!isValidDateTime(formState.orgUnitRegistrationEndDate)) {
      errors.orgUnitRegistrationEndDate = 'Athlete Entry End Date must be a valid date and time';
    } else if (orgUnitRegistrationEndDate <= orgUnitRegistrationStartDate) {
      errors.orgUnitRegistrationEndDate = 'Athlete Entry End Date must be after the Athlete Entry Start Date';
    } else if (orgUnitRegistrationEndDate > meetEntryEndDate) {
      errors.orgUnitRegistrationEndDate = `Athlete Entry End Date must be on or before the Meet Entry End Date ${formState.meetEntryEndDateDatabaseFormat ? '(' + formatDateTimeTwelveHourClock(formState.meetEntryEndDateDatabaseFormat) + ')' : ''}`
    } else if (orgUnitRegistrationEndDate <= meetEntryStartDate) {
      errors.orgUnitRegistrationEndDate = `Athlete Entry End Date must be after the Meet Entry Start Date ${formState.meetEntryStartDateDatabaseFormat ? '(' + formatDateTimeTwelveHourClock(formState.meetEntryStartDateDatabaseFormat) + ')' : ''}`
    }
  }

  return errors;
};

const OrgUnitAdminRosterPermissions = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRosterPermissions;