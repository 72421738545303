import React, { Fragment } from 'react';

import NavLinks from '../../../meet/NavLinks';

import useOmeSessions from './UseOmeSessions';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import Sessions from '../../../../../common/components/meetSessions/Sessions';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

const OmeSessions = () => {
  const {
    omeMeetState,
    state,
    HostNavLinks,
    meetState,
    onEditSession,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    allowEdits
  } = useOmeSessions();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_SESSIONS}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={state.error}>
        {allowEdits() === true ?
          <Sessions
            showOptionalFields={true}
            requiredFieldsReadOnly={true}
            meetSessionArray={meetState?.objData?.meetSession}
            meetId={meetState?.objData?.meetId}
            meetStartDate={meetState?.objData?.startDate}
            meetEndDate={meetState?.objData?.endDate}
            onEdit={onEditSession}
            deleteButtonFirst={false}
            saveNavLink={omeMeetState.route === '' ? HostNavLinks.OME_MEET_SESSIONS : NavLinks.MEET_HOST_SESSIONS}
            backNavLink={omeMeetState.route === '' ? HostNavLinks.OME_MEET_SESSIONS : NavLinks.MEET_HOST_SESSIONS}
          /> :
          <Fragment>
            <div className='row'>
              <div className='col-xs-12 usas-extra-top-margin'>
                <Headings.H6>Sessions</Headings.H6>
              </div>
            </div>
            <Sessions
              showOptionalFields={true}
              requiredFieldsReadOnly={true}
              meetSessionArray={meetState?.objData?.meetSession}
              meetId={meetState?.objData?.meetId}
              meetStartDate={meetState?.objData?.startDate}
              meetEndDate={meetState?.objData?.endDate}
              deleteButtonFirst={false}
              saveNavLink={omeMeetState.route === '' ? HostNavLinks.OME_MEET_SESSIONS : NavLinks.MEET_HOST_SESSIONS}
              backNavLink={omeMeetState.route === '' ? HostNavLinks.OME_MEET_SESSIONS : NavLinks.MEET_HOST_SESSIONS}
            />
          </Fragment>
        }
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={meetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeSessions;