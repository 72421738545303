import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import searchMeetAthleteEntryData from './SearchMeetAthleteEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetAthleteEntryData = {
  INITIAL_STATE,
  searchMeetAthleteEntryData
};

export default OmeMeetAthleteEntryData;