import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Meet Director
  if (formState.meetDirectorMemberId.trim() === '') {
    errors.meetDirectorMemberId = 'Meet Director Member Id is required';
  } else if (!isValidMemberId(formState.meetDirectorMemberId.trim())) {
    errors.meetDirectorMemberId = 'Meet Director Member Id must be 14 alphanumeric characters';
  }

  //Meet Referee
  if (formState.meetRefereeMemberId.trim() === '') {
    errors.meetRefereeMemberId = 'Meet Referee Member Id is required';
  } else if (!isValidMemberId(formState.meetRefereeMemberId.trim())) {
    errors.meetRefereeMemberId = 'Meet Referee Member Id must be 14 alphanumeric characters';
  }

  //Meet Contact
  if (formState.meetContactMemberId.trim() === '') {
    errors.meetContactMemberId = 'Meet Contact Member Id is required';
  } else if (!isValidMemberId(formState.meetContactMemberId.trim())) {
    errors.meetContactMemberId = 'Meet Contact Member Id must be 14 alphanumeric characters';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};
  //Meet Director
  const meetDirectorMemberIdForUrl = formState.meetDirectorMemberId ? encodeURIComponent(formState.meetDirectorMemberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${meetDirectorMemberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.meetDirectorMemberId = "Meet Director Member Id does not have an active member registration";
        } else {
          formState.meetDirectorPersonId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.meetDirectorMemberId = 'Meet Director Member Id is not valid';
    });

  //Meet Referee
  const meetRefereeMemberIdForUrl = formState.meetRefereeMemberId ? encodeURIComponent(formState.meetRefereeMemberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${meetRefereeMemberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.meetRefereeMemberId = "Meet Referee Member Id does not have an active member registration";
        } else {
          formState.meetRefereePersonId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.meetRefereeMemberId = 'Meet Referee Member Id is not valid';
    });

  const meetRefereePersonIdForUrl = formState.meetRefereePersonId ? encodeURIComponent(formState.meetRefereePersonId) : 'NaN';
  const roleGroupIdForUrl = formState.OFFICIALS_ROLES_ROLE_GROUP_ID ? encodeURI(formState.OFFICIALS_ROLES_ROLE_GROUP_ID) : 'NaN';
  await PersonHttpHelper(`/Person/${meetRefereePersonIdForUrl}/hasActiveRegistration?roleGroupId=${roleGroupIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        if (objData.hasActiveRegistration === false) {
          errors.meetRefereeMemberId = "Meet Referee Member Id does not have an active official registration";
        }
      }
    })
    .catch(() => {
      errors.meetRefereeMemberId = "Meet Referee Member Id does not have an active official registration";
    });

  //Meet Contact
  const meetContactMemberIdForUrl = formState.meetContactMemberId ? encodeURIComponent(formState.meetContactMemberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${meetContactMemberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.meetContactMemberId = "Meet Contact Member Id does not have an active member registration";
        } else {
          formState.meetContactPersonId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.meetContactMemberId = 'Meet Contact Member Id is not valid';
    });

  return errors;
};

const MeetAddStaffValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default MeetAddStaffValidation;