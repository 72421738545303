import { useEffect } from 'react';

import useEventData from '../../../state/event/UseEventData';

const useEventCombobox = () => {
  const { eventState, getEvents } = useEventData();

  useEffect(() => {
    if (eventState.isArrayLoaded !== true && eventState.isArrayLoading !== true) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState]);

  return { eventState, getEvents };
};

export default useEventCombobox;