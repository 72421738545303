import React, { Fragment } from 'react';

import useOmeEvents from './UseOmeEvents';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import OmeEventsHeaderForm from './OmeEventsHeaderForm';
import OmeEventsGrid from './OmeEventsGrid';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

const OmeEvents = () => {
  const {
    state,
    omeMeetState,
    modalState,
    gridFilterState,
    eventState,
    filteredGridState,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddEventClicked,
    onEditEvent,
    onDeleteEventClicked,
    onDeleteEvent,
    onModalCanceled,
    onGridFilter,
    allowEdits
  } = useOmeEvents();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_EVENTS}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={state.error}>
        {allowEdits() === true ?
          <div className='row usas-extra-bottom-margin'>
            <div className="col-xs-12">
              <OmeEventsHeaderForm
                onAddEventClicked={onAddEventClicked}
                onFilterGrid={onGridFilter} />
            </div>
            <div className='col-xs-12'>
              <OmeEventsGrid
                state={omeMeetState}
                gridState={state}
                gridFilters={gridFilterState}
                onEdit={onEditEvent}
                onDelete={onDeleteEventClicked}
                showToggle={state.hasTimeStandards === true}
                showNoSlowerThan={state.hasNoSlowerThanTimeStandards === true}
                showNoFasterThan={state.hasNoFasterThanTimeStandards === true}
                showBonus={state.bonusEvents === true && state.hasBonusTimeStandards === true}
              />
            </div>
            {onAddEventClicked && filteredGridState?.length > 15 && omeMeetState.isSaving === false &&
              <div className="col-xs-12 col-sm-6 col-md-8 usas-extra-top-margin visible-md visible-lg">
                <div className='usas-extra-top-margin'>
                  <ActionIntraPageButton type="button" onClick={(e) => onAddEventClicked(e)}>Add Event</ActionIntraPageButton>
                </div>
              </div>}
            {onAddEventClicked && filteredGridState?.length > 0 && omeMeetState.isSaving === false &&
              <div className="col-xs-12 col-sm-6 col-md-8 usas-extra-top-margin hidden-md hidden-lg">
                <div className='usas-extra-top-margin'>
                  <ActionIntraPageButton type="button" onClick={(e) => onAddEventClicked(e)}>Add Event</ActionIntraPageButton>
                </div>
              </div>}
          </div>
          :
          <div className='row usas-extra-bottom-margin'>
            <div className="col-xs-12">
              <OmeEventsHeaderForm
                onFilterGrid={onGridFilter} />
            </div>
            <div className='col-xs-12'>
              <OmeEventsGrid
                state={omeMeetState}
                gridState={state}
                gridFilters={gridFilterState}
                showToggle={state.hasTimeStandards === true}
                showNoSlowerThan={state.hasNoSlowerThanTimeStandards === true}
                showNoFasterThan={state.hasNoFasterThanTimeStandards === true}
                showBonus={state.bonusEvents === true && state.hasBonusTimeStandards === true}
              />
            </div>
          </div>
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Event Number:</b>&nbsp;{modalState.eventNumber}</p>
              <p><b>Event:</b>&nbsp;{modalState.event}</p>
              <p><b>Competition Category:</b>&nbsp;{modalState.competitionCategory}</p>
              <p><b>Age Range:</b>&nbsp;{modalState.ageRange}</p>
              <p><b>Session:</b>&nbsp;{modalState.session}</p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onDeleteEvent(e, modalState.meetAgeGroupId, modalState.meetEventId)}>Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={eventState.isArrayLoading} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeEvents;