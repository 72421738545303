import React, { Fragment } from 'react';

import useOrgUnitAdminPurchases from './UseOrgUnitAdminPurchases';

import OrgUnitAdminPurchasesGrid from './OrgUnitAdminPurchasesGrid';
import OrgUnitAdminAdditionalPurchasesGrid from './OrgUnitAdminAdditionalPurchasesGrid';
import OrgUnitAdminPreviousPurchasesGrid from './OrgUnitAdminPurchasesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminPurchasesReadOnly = () => {
  const { omeMeetOrgUnitEntryPurchaseState, errorState, onAdditionalPurchaseValueChanged, state, onToggle
  } = useOrgUnitAdminPurchases();

  return (
    <Fragment>
      <div className="row">
        <div className={state.showCurrent === true ? global.DisplayComponent : global.HideComponent}>
          <div className="col-xs-12">
            <Headings.H3>Required Purchases</Headings.H3>
          </div>
          <div className="col-xs-12">
            <OrgUnitAdminPurchasesGrid
              gridData={omeMeetOrgUnitEntryPurchaseState.objData?.currentPurchase?.omeMeetPurchase}
              isLoading={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
            />
          </div>
          {omeMeetOrgUnitEntryPurchaseState.objData?.currentPurchase?.additionalPurchase?.length > 0 &&
            <Fragment>
              <div className="col-xs-12 usas-extra-top-margin">
                <Headings.H3>Additional Purchases</Headings.H3>
              </div>
              <div className="col-xs-12">
                <OrgUnitAdminAdditionalPurchasesGrid
                  gridData={omeMeetOrgUnitEntryPurchaseState.objData?.currentPurchase?.additionalPurchase}
                  isLoading={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
                  errorState={errorState}
                  onAdditionalPurchaseValueChanged={onAdditionalPurchaseValueChanged}
                />
              </div>
            </Fragment>}
        </div>
        <div className={state.showCurrent === false ? global.DisplayComponent : global.HideComponent}>
          {omeMeetOrgUnitEntryPurchaseState.objData?.previousPurchases?.length > 0 &&
            <Fragment>
              <div className="col-xs-12">
                <Headings.H3>Previous Purchases Summary</Headings.H3>
              </div>
              <div className="col-xs-12">
                <OrgUnitAdminPreviousPurchasesGrid
                  gridData={omeMeetOrgUnitEntryPurchaseState.objData?.previousPurchases}
                  isLoading={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
                />
              </div>
            </Fragment>}
        </div>
        {omeMeetOrgUnitEntryPurchaseState.objData?.previousPurchases?.length > 0 &&
          <div className="col-xs-12 usas-extra-top-margin">
            <button className={global.ToggleButton} type="button" onClick={() => onToggle()}>{state.showCurrent === true ? 'Show Previous Purchases Summary' : 'Show Current Purchases'}</button>
          </div>}
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
      />
    </Fragment>
  );
};

export default OrgUnitAdminPurchasesReadOnly;