import { useEffect } from 'react';

import useOmeFeeTypeData from '../../state/omeFeeType/UseOmeFeeTypeData';

const useOmeFeeTypeDropdown = () => {
  const { omeFeeTypeState, getOmeFeeTypes } = useOmeFeeTypeData();

  useEffect(() => {
    if (omeFeeTypeState.isArrayLoading !== true && omeFeeTypeState.isArrayLoaded !== true) {
      getOmeFeeTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeFeeTypeState])

  return {
    omeFeeTypeState
  };
};

export default useOmeFeeTypeDropdown;