import { useState, useEffect } from 'react';
import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsEntriesExportData from '../../../state/selectionsEntriesExport/UseSelectionsEntriesExportData';
import hyTekExport from '../../meet/meetHost/lib/HyTekExport';

const useSelectionsExport = () => {
  const { selectionsState } = useSelectionsData();
  const { selectionsEntriesExportState, getSelectionsEntriesExport } = useSelectionsEntriesExportData();
  const [uiState, setUiState] = useState({ exportFileCreated: false, fileContent: "" });

  let fileString = "";

  const onExportSubmitFormCallback = (formState) => {
    let standardId = -1;
    let includeAllIndEventEntries = formState.includeAllIndEventEntries === true ? 1 : 0;
    
    let numberIndividualEventSwimmersSelected = -1
    if (formState.relayAthsIncludeAllIndEventEntries === false) {
      numberIndividualEventSwimmersSelected = 4;
      standardId = formState.timeStandardId;
    }
     
    getSelectionsEntriesExport(selectionsState.objData.selectionMeetId, includeAllIndEventEntries, 0, standardId, false, numberIndividualEventSwimmersSelected);
  }

  function createHyTekEntriesExportFile() {
    const teams = selectionsEntriesExportState.objData.teams === undefined ? [] : selectionsEntriesExportState.objData.teams;
    fileString += hyTekExport.createTeams(teams, true);

    // const unattached = omeEntriesExportState.objData.unattachedSwimmers === undefined ? [] : omeEntriesExportState.objData.unattachedSwimmers;
    // fileString += hyTekExport.createUnattachedSwimmers(unattached);

    fileString = hyTekExport.buildCreatedByRec(selectionsState.objData, fileString, true) + fileString;

  }

  function provideFile() {
    const file = new Blob([uiState.fileContent], { type: 'application/text' });
    const a = document.createElement('a');

    a.href = URL.createObjectURL(file);

    let meetName = selectionsState.objData.meetName.replace("&", "").replace("'", "").replace("\\", "").replace("/", "").replace(".", "").replace("#", "");
    const selectionMeetId = selectionsState.objData.selectionMeetId;

    let fileName = `${selectionMeetId}_${meetName}_export.txt`;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
  }

  useEffect(() => {
    if (selectionsEntriesExportState.isObjLoaded === true) {
      createHyTekEntriesExportFile();
      setUiState({ ...uiState, exportFileCreated: true, fileContent: fileString });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsEntriesExportState]);

  useEffect(() => {
    if (uiState.exportFileCreated === true && uiState.fileContent.length > 0) {
      provideFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState]);


  return {
    selectionsState,
    selectionsEntriesExportState,
    onExportSubmitFormCallback
  };
};

export default useSelectionsExport;