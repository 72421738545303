import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeTimeStandardsDetailValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeTimeStandards from './UseOmeTimeStandards';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useTimeStandardTypeData from '../../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useGlobalRoutesData from '../../../../../common/state/security/UseGlobalRoutesData';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';

import { navigateToCrossUiRoute } from '../../../../../common/utils/FormatCrossUiRoute';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_VIEW_STATE = {
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  timeStandardsFormState: {},
  ageGroupName: '',
  minAge: '',
  maxAge: '',
  meetAgeGroupId: Constants.DEFAULT_ID,
  meetAgeGroupTimeStandardId: Constants.DEFAULT_ID,
  noSlowerThanTimeStandardAgeGroup: [],
  noFasterThanTimeStandardAgeGroup: [],
  bonusTimeStandardAgeGroup: []
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
  deleteAllEventTimeStandardCuts: false,
  deleteAllEventNoSlowerThanTimeStandardCuts: false,
  deleteAllEventNoFasterThanTimeStandardCuts: false,
  deleteAllEventBonusTimeStandardCuts: false,
};

const INITIAL_NAV_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'The information on this page has not been saved',
};

const useOmeTimeStandardsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formStateHasBeenSaved } = useOmeTimeStandards();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { userInfo } = useSecurityData();
  const menuItems = Array.isArray(userInfo?.taxonomies)
    ? userInfo?.taxonomies.find(t => t.taxonomyName === 'MainMenu')?.taxonomyLinks || []
    : [];
  const { formState, errorState, onValueTextPairChanged, handleSubmit, isDirty, setIsDirty,
    onFormValueChanged, setErrors, isSubmitting, setFormData
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [navModalState, setNavModalState] = useState(INITIAL_NAV_MODAL_STATE);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_TIME_STANDARDS, { state: { timeStandardsFormState: formState.timeStandardsFormState } });
    } else {
      navigate(HostNavLinks.OME_MEET_TIME_STANDARDS, { state: { timeStandardsFormState: formState.timeStandardsFormState } });
    }
  };

  const onCreateNewTimeStandardsClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (timeStandardFormStateHasBeenSaved(formState) === false ||
      formStateHasBeenSaved(location.state?.timeStandardsFormState) === false) {
      setNavModalState({
        ...navModalState,
        displayPopUp: true
      });
    }
    else {
      onNavigateToTimeStandards();
    }
  };

  const onNavModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setNavModalState(INITIAL_NAV_MODAL_STATE);
  };

  const onNavigateToTimeStandards = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    //Handle navigating to time standards search or my time standards depending on user permissions
    if (menuItems) {
      const competitionMenuItems = menuItems.find(x => x.displayName === 'Competition')
      if (competitionMenuItems && competitionMenuItems.children) {
        const timeStandardsRoute = competitionMenuItems.children.find(x => x.displayName === 'Time Standards');
        const myTimeStandardsRoute = competitionMenuItems.children.find(x => x.displayName === 'My Time Standards');
        if (timeStandardsRoute) {
          const timeStandardsGlobalRoute = getGlobalRoute('TIME_STANDARDS_SEARCH');
          if (timeStandardsGlobalRoute && timeStandardsGlobalRoute.route) {
            navigateToCrossUiRoute(timeStandardsGlobalRoute.uIProjectName, timeStandardsGlobalRoute.route, navigate);
          }
        }
        else if (myTimeStandardsRoute) {
          const timeStandardsGlobalRoute = getGlobalRoute('MY_TIME_STANDARDS');
          if (timeStandardsGlobalRoute && timeStandardsGlobalRoute.route) {
            navigateToCrossUiRoute(timeStandardsGlobalRoute.uIProjectName, timeStandardsGlobalRoute.route, navigate);
          }
        }
      }
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setModalState(INITIAL_MODAL_STATE);
  };

  const onSaveAndDeleteEventTimeStandardCut = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const omeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    const updatedOmeMeet = editOmeMeetObj(omeMeetCopy);

    for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
      for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetEvent.length; j++) {
        if (modalState.deleteAllEventTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime = [];
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit = [];
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime = [];
        }
        if (modalState.deleteAllEventNoSlowerThanTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime = [];
        }
        if (modalState.deleteAllEventNoFasterThanTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit = [];
        }
        if (modalState.deleteAllEventBonusTimeStandardCuts === true) {
          updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime = [];
        }
      }
    }

    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });

    setModalState(INITIAL_MODAL_STATE);
  };

  const editOmeMeetObj = (updatedOmeMeet) => {
    // If omeMeetQualification object has not been created yet
    if (updatedOmeMeet.omeMeetQualification.length === 0) {
      updatedOmeMeet.omeMeetQualification = [{
        omeMeetId: updatedOmeMeet.omeMeetId,
        hasTimeStandards: formState.timeStandardsFormState?.hasTimeStandards,
        hasNoSlowerThanTimeStandards: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards ?? null) : null,
        hasNoFasterThanTimeStandards: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasNoFasterThanTimeStandards ?? null) : null,
        hasAthleteExceedsTimeStandardsRule: formState.timeStandardsFormState?.hasTimeStandards === true && formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true ? (formState.timeStandardsFormState?.hasAthleteExceedsTimeStandardsRule ?? null) : null,
        hasAthleteExceedsTimeStandardsRelayRule: formState.timeStandardsFormState?.hasTimeStandards === true && formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true && formState.timeStandardsFormState?.relayEvents === true ? (formState.timeStandardsFormState?.hasAthleteExceedsTimeStandardsRelayRule ?? null) : null,
        hasBonusTimeStandards: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasBonusTimeStandards ?? null) : null,
        timeStandardsQualifyingStartDate: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasQualifyingWindow === true ?
          ((formState.timeStandardsFormState?.timeStandardsQualifyingStartDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsFormState?.timeStandardsQualifyingStartDate : null)) : Constants.DEFAULT_MIN_DATE)
          : null,
        timeStandardsQualifyingEndDate: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasQualifyingWindow === true ?
          ((formState.timeStandardsFormState?.timeStandardsQualifyingEndDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsFormState?.timeStandardsQualifyingEndDate : null)) : formatDate(updatedOmeMeet.meetEntryEndDate))
          : null,
        omeQualificationBonus: []
      }];
    }
    // If omeMeetQualification object exists
    else if (updatedOmeMeet.omeMeetQualification.length > 0) {
      updatedOmeMeet.omeMeetQualification = [{
        ...updatedOmeMeet.omeMeetQualification[0],
        hasTimeStandards: formState.timeStandardsFormState?.hasTimeStandards,
        hasNoSlowerThanTimeStandards: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards ?? null) : null,
        hasNoFasterThanTimeStandards: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasNoFasterThanTimeStandards ?? null) : null,
        hasAthleteExceedsTimeStandardsRule: formState.timeStandardsFormState?.hasTimeStandards === true && formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true ? (formState.timeStandardsFormState?.hasAthleteExceedsTimeStandardsRule ?? null) : null,
        hasAthleteExceedsTimeStandardsRelayRule: formState.timeStandardsFormState?.hasTimeStandards === true && formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true && formState.timeStandardsFormState?.relayEvents === true ? (formState.timeStandardsFormState?.hasAthleteExceedsTimeStandardsRelayRule ?? null) : null,
        hasBonusTimeStandards: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasBonusTimeStandards ?? null) : null,
        timeStandardsQualifyingStartDate: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasQualifyingWindow === true ?
          ((formState.timeStandardsFormState?.timeStandardsQualifyingStartDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsFormState?.timeStandardsQualifyingStartDate : null)) : Constants.DEFAULT_MIN_DATE)
          : null,
        timeStandardsQualifyingEndDate: formState.timeStandardsFormState?.hasTimeStandards === true ? (formState.timeStandardsFormState?.hasQualifyingWindow === true ?
          ((formState.timeStandardsFormState?.timeStandardsQualifyingEndDate !== Constants.BLANK_DATE_STRING ? formState.timeStandardsFormState?.timeStandardsQualifyingEndDate : null)) : formatDate(updatedOmeMeet.meetEntryEndDate))
          : null
      }];
    }

    if (updatedOmeMeet.meetAgeGroup.length > 0) {
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
        if (updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard.length > 0) {
          for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard.length; j++) {
            if (formState.timeStandardsFormState?.hasTimeStandards === true && formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards === false) {
              //Remove any no slower than time standards if the meet does not have them
              updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
                ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
                noSlowerThanTimeStandardAgeGroupId: null,
                noSlowerThanTimeStandardAgeGroup: undefined
              }
            }
            if (formState.timeStandardsFormState?.hasTimeStandards === true && formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === false) {
              //Remove any no faster than time standards if the meet does not have them
              updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
                ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
                noFasterThanTimeStandardAgeGroupId: null,
                noFasterThanTimeStandardAgeGroup: undefined
              }
            }
            if (formState.timeStandardsFormState?.hasTimeStandards === true && (formState.timeStandardsFormState?.bonusEvents === false ||
              formState.timeStandardsFormState?.hasBonusTimeStandards === false)) {
              //Remove any bonus time standards if the meet does not have them
              updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
                ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
                bonusTimeStandardAgeGroupId: null,
                bonusTimeStandardAgeGroup: undefined
              }
            }
          }
        }
      }
    }

    if (updatedOmeMeet.meetAgeGroup.length > 0) {
      const selectedMeetAgeGroupIndex = updatedOmeMeet.meetAgeGroup?.findIndex(x =>
        x.meetAgeGroupId === formState.meetAgeGroupId);
      if (selectedMeetAgeGroupIndex > -1) {
        //Edit
        if (formState.meetAgeGroupTimeStandardId > 0) {
          const selectedMeetAgeGroupTimeStandardIndex = updatedOmeMeet.meetAgeGroup[selectedMeetAgeGroupIndex].meetAgeGroupTimeStandard.findIndex(x =>
            x.meetAgeGroupTimeStandardId === formState.meetAgeGroupTimeStandardId);

          updatedOmeMeet.meetAgeGroup[selectedMeetAgeGroupIndex].meetAgeGroupTimeStandard[selectedMeetAgeGroupTimeStandardIndex] = {
            ...updatedOmeMeet.meetAgeGroup[selectedMeetAgeGroupIndex].meetAgeGroupTimeStandard[selectedMeetAgeGroupTimeStandardIndex],
            noSlowerThanTimeStandardAgeGroupId: formState.noSlowerThanTimeStandardAgeGroup[0]?.id || null,
            noSlowerThanTimeStandardAgeGroup: undefined,
            noFasterThanTimeStandardAgeGroupId: formState.noFasterThanTimeStandardAgeGroup[0]?.id || null,
            noFasterThanTimeStandardAgeGroup: undefined,
            bonusTimeStandardAgeGroupId: formState.bonusTimeStandardAgeGroup[0]?.id || null,
            bonusTimeStandardAgeGroup: undefined
          }
        }
        //Add
        else {
          updatedOmeMeet.meetAgeGroup[selectedMeetAgeGroupIndex].meetAgeGroupTimeStandard.push({
            meetAgeGroupId: formState.meetAgeGroupId,
            noSlowerThanTimeStandardAgeGroupId: formState.noSlowerThanTimeStandardAgeGroup[0]?.id || null,
            noFasterThanTimeStandardAgeGroupId: formState.noFasterThanTimeStandardAgeGroup[0]?.id || null,
            bonusTimeStandardAgeGroupId: formState.bonusTimeStandardAgeGroup[0]?.id || null
          });
        }
      }
    }

    return updatedOmeMeet;
  }

  function submitFormCallback(formState) {
    //Check to see if any event changes need to be handled
    if (showDeleteWarningMessage(formState) === false) {

      const omeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
      const updatedOmeMeet = editOmeMeetObj(omeMeetCopy);

      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
      setState({ ...state, tryRedirect: true });
    }
  };

  function showDeleteWarningMessage(formState) {
    let numMeetEvents = 0;
    let hasMeetEventQualifyingTime = false;
    let hasMeetEventQualificationLimit = false
    let hasMeetEventBonusTime = false;

    for (let i = 0; i < omeMeetState?.objData?.meetAgeGroup.length; i++) {
      for (let j = 0; j < omeMeetState?.objData?.meetAgeGroup[i].meetEvent.length; j++) {
        numMeetEvents += 1;
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.length > 0) {
          hasMeetEventQualifyingTime = true;
        }
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.length > 0) {
          hasMeetEventQualificationLimit = true;
        }
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length > 0) {
          hasMeetEventBonusTime = true;
        }
      }
    }

    //If no events exist, don't show warning message
    if (numMeetEvents === 0) {
      return false;
    }
    //If events exist, but none of the events have time standard cuts, don't show warning message
    if (numMeetEvents > 0 && hasMeetEventQualifyingTime === false && hasMeetEventQualificationLimit === false &&
      hasMeetEventBonusTime === false) {
      return false;
    }
    else {
      let deleteAllEventTimeStandardCuts = false;
      let deleteAllEventNoSlowerThanTimeStandardCuts = false;
      let deleteAllEventNoFasterThanTimeStandardCuts = false;
      let deleteAllEventBonusTimeStandardCuts = false;

      //compare formState to omeMeetState to see if changes will affect any events using time standard cuts
      if (formState.timeStandardsFormState?.hasTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasTimeStandards === true) {
        deleteAllEventTimeStandardCuts = true;
      }

      if (formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards === true) {
        deleteAllEventNoSlowerThanTimeStandardCuts = true;
      }

      if (formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasNoFasterThanTimeStandards === true) {
        deleteAllEventNoFasterThanTimeStandardCuts = true;
      }

      if (formState.timeStandardsFormState?.hasBonusTimeStandards === false && omeMeetState?.objData?.omeMeetQualification[0]?.hasBonusTimeStandards === true) {
        deleteAllEventBonusTimeStandardCuts = true;
      }

      if (deleteAllEventTimeStandardCuts === true || deleteAllEventNoSlowerThanTimeStandardCuts === true ||
        deleteAllEventNoFasterThanTimeStandardCuts === true || deleteAllEventBonusTimeStandardCuts === true) {
        setModalState({
          ...modalState,
          displayPopUp: true,
          deleteAllEventTimeStandardCuts: deleteAllEventTimeStandardCuts,
          deleteAllEventNoSlowerThanTimeStandardCuts: deleteAllEventNoSlowerThanTimeStandardCuts,
          deleteAllEventNoFasterThanTimeStandardCuts: deleteAllEventNoFasterThanTimeStandardCuts,
          deleteAllEventBonusTimeStandardCuts: deleteAllEventBonusTimeStandardCuts
        });
        return true;
      }

      else {
        //If the formState changes are not related to time standards, don't show warning message
        return false;
      }
    }
  };

  function timeStandardFormStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    //Edit time standard
    if (formState?.meetAgeGroupId !== Constants.DEFAULT_ID && formState?.meetAgeGroupTimeStandardId !== Constants.DEFAULT_ID) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState?.meetAgeGroupId);
      const selectedMeetAgeGroupTimeStandard = selectedMeetAgeGroup?.meetAgeGroupTimeStandard?.find(x =>
        x.meetAgeGroupTimeStandardId === formState?.meetAgeGroupTimeStandardId);

      if (ToIntIfInt(formState?.noSlowerThanTimeStandardAgeGroup[0]?.id) !== selectedMeetAgeGroupTimeStandard?.noSlowerThanTimeStandardAgeGroupId
        && (ToIntIfInt(formState?.noSlowerThanTimeStandardAgeGroup[0]?.id) !== Constants.DEFAULT_ID || selectedMeetAgeGroupTimeStandard?.noSlowerThanTimeStandardAgeGroupId !== null)) {
        return false;
      }
      if (ToIntIfInt(formState?.noFasterThanTimeStandardAgeGroup[0]?.id) !== selectedMeetAgeGroupTimeStandard?.noFasterThanTimeStandardAgeGroupId
        && (ToIntIfInt(formState?.noFasterThanTimeStandardAgeGroup[0]?.id) !== Constants.DEFAULT_ID || selectedMeetAgeGroupTimeStandard?.noFasterThanTimeStandardAgeGroupId !== null)) {
        return false;
      }
      if (ToIntIfInt(formState?.bonusTimeStandardAgeGroup[0]?.id) !== selectedMeetAgeGroupTimeStandard?.bonusTimeStandardAgeGroupId
        && (ToIntIfInt(formState?.bonusTimeStandardAgeGroup[0]?.id) !== Constants.DEFAULT_ID || selectedMeetAgeGroupTimeStandard?.bonusTimeStandardAgeGroupId !== null)) {
        return false;
      }
    }
    //Add time standard
    else {
      if (isDirty === true) {
        return false;
      }

      return true;
    }


    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && omeMeetState.objData?.meetAgeGroup?.length > 0
      && location.state && location.state?.timeStandardsFormState && location.state?.meetAgeGroupId > 0 &&
      timeStandardTypeState.isArrayLoading === false && timeStandardTypeState.isArrayLoaded === true) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === location?.state?.meetAgeGroupId);
      if (Object.keys(selectedMeetAgeGroup).length > 0) {
        //Edit Exisiting Age Group Time Standard
        if (location.state?.meetAgeGroupTimeStandardId > 0) {
          const selectedMeetAgeGroupTimeStandard = selectedMeetAgeGroup.meetAgeGroupTimeStandard?.find(x =>
            x.meetAgeGroupTimeStandardId === location?.state?.meetAgeGroupTimeStandardId);
          if (Object.keys(selectedMeetAgeGroupTimeStandard).length > 0) {
            setFormData({
              ...formState,
              timeStandardsFormState: location.state?.timeStandardsFormState || {},
              meetAgeGroupId: location.state?.meetAgeGroupId || Constants.DEFAULT_ID,
              meetAgeGroupTimeStandardId: location.state?.meetAgeGroupTimeStandardId || Constants.DEFAULT_ID,
              ageGroupName: selectedMeetAgeGroup.ageGroupName || '',
              minAge: selectedMeetAgeGroup.minAge >= 0 ? selectedMeetAgeGroup.minAge : '',
              maxAge: selectedMeetAgeGroup.maxAge >= 0 ? selectedMeetAgeGroup.maxAge : '',
              noSlowerThanTimeStandardAgeGroup: selectedMeetAgeGroupTimeStandard.noSlowerThanTimeStandardAgeGroupId >= 0 ? (HierarchicalDataUtils.GetNameIdPairs(timeStandardTypeState.treeData, [selectedMeetAgeGroupTimeStandard.noSlowerThanTimeStandardAgeGroupId !== null ?
                selectedMeetAgeGroupTimeStandard.noSlowerThanTimeStandardAgeGroupId.toString() : '']) || []) : [],
              noFasterThanTimeStandardAgeGroup: selectedMeetAgeGroupTimeStandard.noFasterThanTimeStandardAgeGroupId >= 0 ? (HierarchicalDataUtils.GetNameIdPairs(timeStandardTypeState.treeData, [selectedMeetAgeGroupTimeStandard.noFasterThanTimeStandardAgeGroupId !== null ?
                selectedMeetAgeGroupTimeStandard.noFasterThanTimeStandardAgeGroupId.toString() : '']) || []) : [],
              bonusTimeStandardAgeGroup: selectedMeetAgeGroupTimeStandard.bonusTimeStandardAgeGroupId >= 0 ? (HierarchicalDataUtils.GetNameIdPairs(timeStandardTypeState.treeData, [selectedMeetAgeGroupTimeStandard.bonusTimeStandardAgeGroupId !== null ?
                selectedMeetAgeGroupTimeStandard.bonusTimeStandardAgeGroupId.toString() : '']) || []) : []
            });
            if (formStateHasBeenSaved(location.state?.timeStandardsFormState) === false) {
              setIsDirty(true);
            }
          }
        }
        //Add New Age Group Time Standard
        else if (location.state?.meetAgeGroupTimeStandardId === Constants.DEFAULT_ID) {
          setFormData({
            ...formState,
            timeStandardsFormState: location.state?.timeStandardsFormState || {},
            meetAgeGroupId: location.state?.meetAgeGroupId || Constants.DEFAULT_ID,
            ageGroupName: selectedMeetAgeGroup.ageGroupName || '',
            minAge: selectedMeetAgeGroup.minAge >= 0 ? selectedMeetAgeGroup.minAge : '',
            maxAge: selectedMeetAgeGroup.maxAge >= 0 ? selectedMeetAgeGroup.maxAge : '',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, timeStandardTypeState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.timeStandardsFormState);
      const isTimeStandardSaved = timeStandardFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isTimeStandardSaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && state.tryRedirect === true && isSubmitting === false) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_TIME_STANDARDS);
      } else {
        navigate(HostNavLinks.OME_MEET_TIME_STANDARDS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isSaving, state, isSubmitting]);

  return {
    omeMeetState,
    timeStandardTypeState,
    formState,
    errorState,
    modalState,
    onSaveAndDeleteEventTimeStandardCut,
    onModalCanceled,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onCreateNewTimeStandardsClicked,
    navModalState,
    onNavModalCanceled,
    onNavigateToTimeStandards
  };
};

export default useOmeTimeStandardsDetail;