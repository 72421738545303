import React, { createContext, useState } from 'react';

export const MyAthleteMeetEntriesFiltersStateContext = createContext();

const INITIAL_STATE = {
  omeMeetAthleteEntryFiltersState: {}
};

const MyAthleteMeetEntriesFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <MyAthleteMeetEntriesFiltersStateContext.Provider value={stateHook}>
      {children}
    </MyAthleteMeetEntriesFiltersStateContext.Provider>
  );
};

export default MyAthleteMeetEntriesFiltersContextProvider;