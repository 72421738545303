import React, { Fragment } from 'react';

import MeetStaffLargeGrid from './MeetStaffLargeGrid';
import MeetStaffSmallGrid from './MeetStaffSmallGrid';

const MeetStaffGrid = ({ gridData, isLoading, onEditStaff }) => {
  return (
    <Fragment>
      <MeetStaffLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditStaff={onEditStaff}
      />
      <MeetStaffSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditStaff={onEditStaff}
      />
    </Fragment>
  )
};

export default MeetStaffGrid;