import { useContext } from 'react';

import { SelectionsTimeStandardStateContext } from './SelectionsTimeStandardContextProvider';

import SelectionsTimeStandardData from './SelectionsTimeStandardData';

const useSelectionsTimeStandardData = () => {
  const [selectionsTimeStandardState, setSelectionsTimeStandardState] = useContext(SelectionsTimeStandardStateContext);

  const getSelectionsTimeStandards = (orgUnitId, omeMeetId) => {
    return SelectionsTimeStandardData.getSelectionTimeStandardsData(orgUnitId, omeMeetId, selectionsTimeStandardState, setSelectionsTimeStandardState);
  };

  return {
    selectionsTimeStandardState,
    setSelectionsTimeStandardState,
    getSelectionsTimeStandards
  };
};

export default useSelectionsTimeStandardData;