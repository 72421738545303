import { useEffect } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import useOmeMeetData from "../../../state/omeMeet/UseOmeMeetData";
import useOmeMeetDateValidationData from '../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useOmeMeetInvitationsData from "../../../state/omeMeetInvitations/UseOmeMeetInvitationsData";
import useMyMeetsFiltersData from '../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSelectOrgUnitData from "../../../../common/state/selectOrgUnit/UseSelectOrgUnitData";
import useSecurityData from "../../../../common/state/security/UseSecurityData";
import useMeetData from "../../../../common/state/meet/UseMeetData";

const TAXONOMIES = ['']; //TODO none in database?
const SCOPE = 'OnlineMeetEntry';

const useOmeHostLoading = (redirectNavLink) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState, getMeet } = useMeetData();
  const { omeMeetState, setOmeMeetState, getOmeMeet } = useOmeMeetData();
  const { omeMeetInvitationsState, getOmeMeetInvitations } = useOmeMeetInvitationsData();
  const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { omeMeetDateValidationState, getOmeMeetDateValidation } = useOmeMeetDateValidationData();
  const { myMeetsFiltersState, setMyMeetsFiltersState } = useMyMeetsFiltersData();

  useEffect(() => {
    if (location.state?.selectOrgUnitState) {
      setSelectOrgUnitState({
        ...selectOrgUnitState,
        orgUnitId: location.state?.selectOrgUnitState.orgUnitId,
        orgUnitName: location.state?.selectOrgUnitState.orgUnitName,
        showBackToSelection: location.state?.selectOrgUnitState.showBackToSelection
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.omeMeetHostFiltersState || location.state?.omeMeetTeamEntryFiltersState) {
      setMyMeetsFiltersState({
        ...myMeetsFiltersState,
        omeMeetHostFiltersState: location.state?.omeMeetHostFiltersState,
        omeMeetTeamEntryFiltersState: location.state?.omeMeetTeamEntryFiltersState
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.omeMeet) {
      setOmeMeetState({
        ...omeMeetState,
        selectedMeet: location.state.omeMeet
      });
      if (Object.keys(omeMeetState.selectedMeet).length > 0) {
        if (location.state.omeMeet.omeMeetId > 0 && omeMeetState.isObjLoaded !== true) {
          getOmeMeet(location.state.omeMeet.omeMeetId);
          getOmeMeetDateValidation(location.state.omeMeet.omeMeetId);
          getOmeMeetInvitations(location.state.omeMeet.omeMeetId);
        }
        if (location.state.omeMeet.omeMeetId > 0 && location.state.omeMeet.meetId > 0 && meetState.isObjLoaded !== true) {
          getMeet(location.state.omeMeet.meetId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, omeMeetState.isObjLoaded, omeMeetState.selectedMeet]);

  useEffect(() => {
    if (contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE) {
      getContextSecurity(location.state.omeMeet?.omeMeetId, TAXONOMIES, SCOPE);
    }
    if (contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE) {
      //edit omeMeet - skip template
      if (meetState.isObjLoaded === true && meetState.objData.meetId === location.state.omeMeet.meetId &&
        location.state.omeMeet.omeMeetId > 0 && omeMeetState.objData?.omeMeetId === location.state.omeMeet.omeMeetId &&
        omeMeetDateValidationState.isObjLoaded === true && omeMeetInvitationsState.isArrayLoaded === true) {
        navigate(redirectNavLink);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState, meetState.objData, omeMeetState.objData, omeMeetInvitationsState.isArrayLoaded, omeMeetDateValidationState.isObjLoaded]);

  return {
    isLoading: true
  };
};

export default useOmeHostLoading;