import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitEntryData from './OmeMeetOrgUnitEntryData';

export const OmeMeetOrgUnitEntryStateContext = createContext();

const OmeMeetOrgUnitEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitEntryData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitEntryStateContext.Provider>
  );
};

export default OmeMeetOrgUnitEntryContextProvider;