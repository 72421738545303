import React, { Fragment } from 'react';

import useSelectionsNewSelectionSearch from './UseSelectionsNewSelectionSearch';

import NewSelectionSearchForm from '../components/forms/NewSelectionSearchForm';
import NewSelectionSearchGrid from '../components/grids/NewSelectionSearchGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const SelectionsNewSelectionSearch = () => {
  const {
    isLoading,
    gridData,
    showFilters,
    filterObject,
    onCreateSelectionClicked,
    onSubmitFormCallback,
    onExistingSelectionsClicked,
    toggleNewSelectionSearchFilters
  } = useSelectionsNewSelectionSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Participant Selection Search</Headings.H3>
        </div>
      </div>
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <NewSelectionSearchForm
          onSubmitFormCallback={onSubmitFormCallback}
          filterObject={filterObject} />
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NewSelectionSearchGrid
            gridData={gridData}
            isLoading={isLoading}
            showFilters={showFilters}
            onFiltersToggle={toggleNewSelectionSearchFilters}
            onCreateSelectionClicked={onCreateSelectionClicked}
            onExistingSelectionsClicked={onExistingSelectionsClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SelectionsNewSelectionSearch;