const EntryNavLinks = {
  HOME: '/ome/entry',
  OME_ENTRY_LOADING: '/ome/entry/loading',

  OU_ADMIN_LANDING: '/ome/entry/team/oua/landing',
  OU_ADMIN_CONTACTS: '/ome/entry/team/oua/contacts',
  OU_ADMIN_CONTACTS_DETAIL: '/ome/entry/team/oua/contacts/detail',
  OU_ADMIN_ROSTER_PERMISSIONS: '/ome/entry/team/oua/rosterpermissions',
  OU_ADMIN_ROSTER: '/ome/entry/team/oua/roster',
  OU_ADMIN_ROSTER_SELECTION: '/ome/entry/team/oua/roster/selection',
  OU_ADMIN_ROSTER_ENTRIES: '/ome/entry/team/oua/rosterentries',
  OU_ADMIN_ROSTER_ENTRIES_DETAIL: '/ome/entry/team/oua/rosterentries/detail',
  OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_SELECTION: '/ome/entry/team/oua/rosterentries/individual/selection',
  OU_ADMIN_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION: '/ome/entry/team/oua/rosterentries/bonusindividual/selection',
  OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE: '/ome/entry/team/oua/rosterentries/individual/override',
  OU_ADMIN_ROSTER_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE: '/ome/entry/team/oua/rosterentries/bonusindividual/override',
  OU_ADMIN_RELAY_ENTRIES: '/ome/entry/team/oua/relayentries',
  OU_ADMIN_RELAY_ENTRIES_TEAMS: '/ome/entry/team/oua/relayentries/teams',
  OU_ADMIN_RELAY_ENTRIES_DETAIL: '/ome/entry/team/oua/relayentries/detail',
  OU_ADMIN_RELAY_ENTRIES_DETAIL_OVERRIDE: '/ome/entry/team/oua/relayentries/detail/override',
  OU_ADMIN_COACHES: '/ome/entry/team/oua/coaches',
  OU_ADMIN_COACHES_SELECTION: '/ome/entry/team/oua/coaches/selection',
  OU_ADMIN_PURCHASES: '/ome/entry/team/oua/purchases',
  OU_ADMIN_PAYMENTS: '/ome/entry/team/oua/payments',
  OU_ADMIN_REPORTING: '/meet/entry/team/oua/teamentryreporting',

  ATHLETE_ENTRIES: '/ome/entry/athlete/entries',
  ATHLETE_ENTRIES_INDIVIDUAL_SELECTION: '/ome/entry/athlete/entries/individual/selection',
  ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION: '/ome/entry/athlete/entries/bonusindividual/selection',
  ATHLETE_ENTRIES_INDIVIDUAL_OVERRIDE: '/ome/entry/athlete/entries/individual/override',
  ATHLETE_ENTRIES_BONUS_INDIVIDUAL_OVERRIDE: '/ome/entry/athlete/entries/bonusindividual/override',
  ATHLETE_COACHES: '/ome/entry/athlete/coaches',
  ATHLETE_COACHES_SELECTION: '/ome/entry/athlete/coaches/selection',
  ATHLETE_PURCHASES: '/ome/entry/athlete/purchases',
  ATHLETE_PAYMENTS: '/ome/entry/athlete/payments',
};

export default EntryNavLinks;