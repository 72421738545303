import React, { Fragment } from 'react';

import useMeetTeamEntry from './UseMeetTeamEntry';

import MeetTeamEntryGrid from './MeetTeamEntryGrid';

import DeleteOmeModalInfo from '../../myMeets/meetHost/DeleteOmeModalInfo';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';
import { formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetTeamEntryNonHQ = () => {
  const { state, omeMeetState, omeMeetOrgUnitEntryState, omeMeetTeamEntryState, personOrgRoleState, onEditTeamEntry, onEnterMeetClicked,
    onDeleteTeamEntryClicked, onDeleteTeamEntry, onViewTeamEntry, onModalCanceled, modalState } = useMeetTeamEntry();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Entry Management</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12'>
          {omeMeetState?.objData?.omeMeetId ?
            <Fragment>
              <p className={global.HeaderText}><b>Entry Start - End Dates:</b>
                &nbsp;{omeMeetState?.objData?.meetEntryStartDate ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.meetEntryStartDate) : ''}
                &nbsp;{omeMeetState?.objData?.meetEntryStartDate ? "-" : ''} {omeMeetState?.objData?.meetEntryEndDate ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.meetEntryEndDate) : ''}
                &nbsp;{omeMeetState?.objData?.timeZone || ''}</p>
              <p className={global.HeaderText}><b>Entry Change Deadline:</b>
                &nbsp;{omeMeetState?.objData?.meetEntryChangeDeadline ? formatDateTimeTwelveHourClock(omeMeetState?.objData?.meetEntryChangeDeadline) : ''}
                &nbsp;{omeMeetState?.objData?.timeZone || ''}</p>
              {omeMeetTeamEntryState?.arrayData?.teamEntryCurrentlyAllowed === false && omeMeetState?.objData?.status === Constants.WORKFLOW_STATUS_IN_PROGRESS && <p className={global.HeaderText}><b>Online Meet Entry is not fully set up for this meet. Starting a new Team Entry is currently not allowed.</b></p>}
              {omeMeetTeamEntryState?.arrayData?.teamEntryCurrentlyAllowed === false && omeMeetState?.objData?.status !== Constants.WORKFLOW_STATUS_IN_PROGRESS && <p className={global.HeaderText}><b>Online Meet Entry is not open at this time. Starting a new Team Entry is currently not allowed.</b></p>}
            </Fragment> :
            <p className={global.HeaderText}><b>Online Meet Entry is not set up for this meet. Starting a new Team Entry is currently not allowed.</b></p>}
          {omeMeetState?.objData?.omeMeetId && omeMeetState?.objData?.status === Constants.WORKFLOW_STATUS_CREATED && personOrgRoleState.isArrayLoading === false && omeMeetOrgUnitEntryState.isArrayLoading === false &&
            omeMeetTeamEntryState?.arrayData?.teamEntryCurrentlyAllowed === true &&
            ((omeMeetTeamEntryState.arrayData?.teamEntry?.length === 0 || omeMeetTeamEntryState.arrayData?.teamEntry === null) && state.availableOrgUnits.length === 0) &&
            <p className={global.HeaderText}><b>The Logged In User is Not Associated with any Organizations that are Invited to the Meet.</b></p>}
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        {omeMeetState?.objData?.omeMeetId && omeMeetState?.objData?.status === Constants.WORKFLOW_STATUS_CREATED &&
          omeMeetTeamEntryState?.arrayData?.teamEntryCurrentlyAllowed === true && state.availableOrgUnits.length > 0 &&
          <div className='col-xs-12 usas-extra-top-margin'>
            <ActionIntraPageButton onClick={(e) => onEnterMeetClicked(e)}>Enter Meet</ActionIntraPageButton>
          </div>}
        <div className='col-xs-12'>
          <MeetTeamEntryGrid
            gridData={omeMeetTeamEntryState.arrayData?.teamEntry || []}
            isLoading={omeMeetTeamEntryState.isArrayLoading}
            teamEntryCurrentlyAllowed={omeMeetTeamEntryState.arrayData?.teamEntryCurrentlyAllowed ?? false}
            onView={onViewTeamEntry}
            onEdit={onEditTeamEntry}
            onDeleteTeamEntryClicked={onDeleteTeamEntryClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className='col-xs-12'>
            <DeleteOmeModalInfo modalState={modalState} />
            <p><strong>Team Name:</strong> {modalState?.teamName}</p>
            <p><strong>Team Entry Status:</strong> {modalState?.teamEntryStatus}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteTeamEntry(e, modalState.omeMeetId, modalState.omeMeetOrgUnitEntryId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetState.isArrayLoading ||
          personOrgRoleState.isArrayLoading || omeMeetTeamEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitEntryState.isSaving} />
    </Fragment>);
}

export default MeetTeamEntryNonHQ;