import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeFeeTypeData from './GetOmeFeeTypeData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  dataAsOptionsWithNames: [],
  additionalPurchaseOnlyArrayData: [],
  additionalPurchaseOnlyDataAsOptionsWithNames: []
};

const OmeFeeTypeData = {
  getOmeFeeTypeData,
  INITIAL_STATE
};

export default OmeFeeTypeData;