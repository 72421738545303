import { useContext } from 'react';

import { OmeMeetOrgUnitEntryStateContext } from './OmeMeetOrgUnitEntryContextProvider';

import OmeMeetOrgUnitEntryData from './OmeMeetOrgUnitEntryData';

const useOmeMeetOrgUnitEntryData = () => {
  const [omeMeetOrgUnitEntryState, setOmeMeetOrgUnitEntryState] = useContext(OmeMeetOrgUnitEntryStateContext);

  const getOmeMeetOrgUnitEntry = (omeMeetId) =>
    OmeMeetOrgUnitEntryData.getOmeMeetOrgUnitEntryData(omeMeetId, omeMeetOrgUnitEntryState, setOmeMeetOrgUnitEntryState);

  const getOmeMeetOrgUnitEntryById = (omeMeetId, omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitEntryData.getOmeMeetOrgUnitEntryByIdData(omeMeetId, omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryState, setOmeMeetOrgUnitEntryState);

  const postOmeMeetOrgUnitEntry = (omeMeetId, omeMeetOrgUnitEntryObj) =>
    OmeMeetOrgUnitEntryData.postOmeMeetOrgUnitEntryData(omeMeetId, omeMeetOrgUnitEntryObj, omeMeetOrgUnitEntryState, setOmeMeetOrgUnitEntryState);

  const putOmeMeetOrgUnitEntry = (omeMeetId, omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryObj) =>
    OmeMeetOrgUnitEntryData.putOmeMeetOrgUnitEntryData(omeMeetId, omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryObj, omeMeetOrgUnitEntryState, setOmeMeetOrgUnitEntryState);

  const deleteOmeMeetOrgUnitEntry = (omeMeetId, omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitEntryData.deleteOmeMeetOrgUnitEntryData(omeMeetId, omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryState, setOmeMeetOrgUnitEntryState);

  const clearOmeMeetOrgUnitEntryObjData = () => {
    setOmeMeetOrgUnitEntryState({
      ...omeMeetOrgUnitEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetOrgUnitEntryArrayData = () => {
    setOmeMeetOrgUnitEntryState({
      ...omeMeetOrgUnitEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeetOrgUnitEntry = () => {
    setOmeMeetOrgUnitEntryState({
      ...omeMeetOrgUnitEntryState,
      isSaved: false
    });
  };

  return {
    omeMeetOrgUnitEntryState,
    getOmeMeetOrgUnitEntry,
    getOmeMeetOrgUnitEntryById,
    postOmeMeetOrgUnitEntry,
    putOmeMeetOrgUnitEntry,
    deleteOmeMeetOrgUnitEntry,
    clearOmeMeetOrgUnitEntryObjData,
    clearOmeMeetOrgUnitEntryArrayData,
    confirmSaveOmeMeetOrgUnitEntry
  };
};

export default useOmeMeetOrgUnitEntryData;