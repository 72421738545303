import { isValidDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  const timeStandardsQualifyingStartDate = new Date(formState.timeStandardsQualifyingStartDate);
  const timeStandardsQualifyingEndDate = new Date(formState.timeStandardsQualifyingEndDate);
  const meetEntryEndDate = new Date(formState.meetEntryEndDate.substring(0, 10));

  if (formState.hasTimeStandards !== true && formState.hasTimeStandards !== false) {
    errors.hasTimeStandards = 'A selection is required';
  }

  else if (formState.hasTimeStandards === true) {

    if (formState.hasQualifyingWindow !== true && formState.hasQualifyingWindow !== false) {
      errors.hasQualifyingWindow = 'A selection is required';
    }

    if (formState.hasQualifyingWindow === true) {
      //Qualifying Start Date
      if (formState.timeStandardsQualifyingStartDate === Constants.BLANK_DATE_STRING) {
        errors.timeStandardsQualifyingStartDate = 'Qualifying Start Date is required';
      } else if (timeStandardsQualifyingStartDate >= timeStandardsQualifyingEndDate) {
        errors.timeStandardsQualifyingStartDate = 'Qualifying Start Date must be before the Qualifying End Date'
      } else if (!isValidDate(formState.timeStandardsQualifyingStartDate)) {
        errors.timeStandardsQualifyingStartDate = 'Qualifying Start Date must be a valid date';
      }
      //Qualifying End Date
      if (formState.timeStandardsQualifyingEndDate === Constants.BLANK_DATE_STRING) {
        errors.timeStandardsQualifyingEndDate = 'Qualifying End Date is required';
      } else if (timeStandardsQualifyingStartDate >= timeStandardsQualifyingEndDate) {
        errors.timeStandardsQualifyingEndDate = 'Qualifying End Date must be after the Qualifying Start Date'
      } else if (timeStandardsQualifyingEndDate > meetEntryEndDate) {
        errors.timeStandardsQualifyingEndDate = `Qualifying End Date must be on or before the Meet Entry End Date (${formState.meetEntryEndDate.substring(0, 10)})`
      } else if (!isValidDate(formState.timeStandardsQualifyingEndDate)) {
        errors.timeStandardsQualifyingEndDate = 'Qualifying End Date must be a valid date';
      }
    }

    if (formState.hasNoSlowerThanTimeStandards !== true && formState.hasNoSlowerThanTimeStandards !== false) {
      errors.hasNoSlowerThanTimeStandards = 'A selection is required';
    }

    if (formState.hasNoFasterThanTimeStandards !== true && formState.hasNoFasterThanTimeStandards !== false) {
      errors.hasNoFasterThanTimeStandards = 'A selection is required';
    }

    if (formState.bonusEvents === true && formState.hasBonusTimeStandards !== true && formState.hasBonusTimeStandards !== false) {
      errors.hasBonusTimeStandards = 'A selection is required';
    }


    if (formState.bonusEvents === true && formState.hasBonusTimeStandards !== true && formState.hasNoSlowerThanTimeStandards !== true && formState.hasNoFasterThanTimeStandards !== true) {
      errors.error = "'Meet uses Time Standards' is set to 'Yes'. At least one of the 'No Slower Than', 'No Faster Than', or 'Bonus' fields must be set to 'Yes'.";
    }

    if (formState.bonusEvents === false && formState.hasNoSlowerThanTimeStandards !== true && formState.hasNoFasterThanTimeStandards !== true) {
      errors.error = "'Meet uses Time Standards' is set to 'Yes'. At least one of the 'No Slower Than' or 'No Faster Than' fields must be set to 'Yes'.";
    }

    if (formState.hasNoFasterThanTimeStandards === true) {
      if (formState.hasAthleteExceedsTimeStandardsRule !== true && formState.hasAthleteExceedsTimeStandardsRule !== false) {
        errors.hasAthleteExceedsTimeStandardsRule = 'A selection is required';
      }

      if (formState.relayEvents === true && formState.hasAthleteExceedsTimeStandardsRelayRule !== true && formState.hasAthleteExceedsTimeStandardsRelayRule !== false) {
        errors.hasAthleteExceedsTimeStandardsRelayRule = 'A selection is required';
      }
    }
  }

  return errors;
};

const OmeTimeStandardsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeTimeStandardsValidation;