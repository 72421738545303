/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ selection, onEditSelectionClicked, onDeleteSelectionClicked }) => (
  <tr>
    <td>{selection.selectionStartDate ? formatDate(selection.selectionStartDate) : ''}</td>
    <td>{selection.selectionStartedBy || ''}</td>
    <td>{selection.competitionGenderTypeName || ''}</td>
    <td>{selection.meetStartDate ? formatDate(selection.meetStartDate) : ''} - {selection.meetEndDate ? formatDate(selection.meetEndDate) : ''}</td>
    <td>{selection.meetCity || ''}{selection.meetCity && selection.meetStateCode ? '/' : ''}{selection.meetStateCode || ''}</td>
    <td>{selection.selectionDesc || ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={() => onEditSelectionClicked(selection.selectionMeetId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={() => onDeleteSelectionClicked(selection.selectionMeetId)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const ExistingSelectionsGridLarge = ({ gridData, isLoading, onEditSelectionClicked, onDeleteSelectionClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Selection Started'} columnField={'selectionStartDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Started By'} columnField={'selectionStartedBy'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Gender'} columnField={'competitionGenderTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Start Date - End Date</th>
          <th>Location</th>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Description'} columnField={'selectionDesc'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="8">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((selection, i) => <GridRow key={i} selection={selection} onEditSelectionClicked={onEditSelectionClicked} onDeleteSelectionClicked={onDeleteSelectionClicked} />)
            : <tr><td colSpan="8">No Selections</td></tr>
        }
      </tbody>
    </table>
  );
};

export default ExistingSelectionsGridLarge;