import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLaneSheetData from '../../../state/laneSheet/UseLaneSheetData';

import ToIntIfInt from '../../../../common/utils/ToIntIfInt';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

const TAXONOMIES = ['LaneSheetDetail'];
const SCOPE = 'LaneSheet';

const useLaneSheetAddMeetInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getContextSecurity } = useSecurityData();
  const { laneSheetState, setLaneSheetState, postLaneSheetMeetInfo } = useLaneSheetData();

  const onBackClicked = () => {
    navigate(NavLinks.LANE_SHEET_LANDING);
  };

  const onSubmitFormCallback = (formState) => {
    const meetId = location.state?.meet?.meetId;
    const hostOrgUnitId = location.state?.meet?.hostOrgUnitId;

    if (meetId && hostOrgUnitId) {
      const postLaneSheetMeetInfoPromise = postLaneSheetMeetInfo(createLaneSheetObj(formState, meetId, hostOrgUnitId));

      if (postLaneSheetMeetInfoPromise !== null) {
        postLaneSheetMeetInfoPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetMeetId = newState.objData?.laneSheetMeetId || 1;

            setLaneSheetState({
              ...newState,
              isSaving: false
            });
            getContextSecurity(laneSheetMeetId, TAXONOMIES, SCOPE);
            navigate(NavLinks.LANE_SHEET_MEET_INFO);
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  function createLaneSheetObj(formState, meetId, hostOrgUnitId) {
    let careerStatArrayOfIds = []

    for (const courseStats of formState.careerStats) {
      if (courseStats.id > 0) {
        careerStatArrayOfIds.push({ courseId: courseStats.id });
      }
    }

    let medalCountArray = []
    if (formState.medalCount1Id > 0) {
      const medalCount1Obj = {
        meetClassificationId: formState.medalCount1Id,
        displayOrder: 1
      };

      medalCountArray.push(medalCount1Obj);
    }

    if (formState.medalCount2Id > 0) {
      const medalCount2Obj = {
        meetClassificationId: formState.medalCount2Id,
        displayOrder: 2
      };

      medalCountArray.push(medalCount2Obj);
    }

    return {
      meetId,
      hostOrgUnitId,
      careerStats: careerStatArrayOfIds,
      laneSheetTemplateId: formState.laneSheetTemplateId,
      medalCount: medalCountArray,
      numberOfLanes: ToIntIfInt(formState.numberOfLanes),
      recordGroupWithinSheetId: formState.recordGroupWithinSheetId,
      recordWithinSheetName: formState.recordWithinSheetName,
      startingLaneNumber: formState.reverseLaneOrder === 'true' ? ToIntIfInt(formState.numberOfLanes) : 1,
      useAmericanDates: formState.useAmericanDates,
    };
  };

  return {
    isSaving: laneSheetState.isSaving,
    meet: location.state?.meet || {},
    onSubmitFormCallback,
    onBackClicked
  }
};

export default useLaneSheetAddMeetInfo;