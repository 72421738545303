import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';
import { Fragment } from 'react';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

const AthleteUserEntriesSelectionSmallGrid = ({ gridData, isLoading, onCheckboxChange, bonus, onAddOverrideEntryClicked, onEditOverrideEntryClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((event, i) => (
          <div className={global.SmallTableRow} key={event.meetEventId || i}>
            <div className={global.SmallTableRowHead}>
              {(event.hasQualifyingTime === true || event.swimTimeEntryOverride === true) ?
                <Fragment>
                  <Fragment><span>{event.swimTimeEntryOverride === true ?
                    <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onEditOverrideEntryClicked(e, event)}>Edit Override Time</ActionIntraPageButton></div> :
                    <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onAddOverrideEntryClicked(e, event)}>Add Override Time</ActionIntraPageButton></div>}</span>&nbsp;</Fragment>
                  <div className={global.CheckboxMobile}>
                    {(event.hasPaid === false || event.canBeDeleted === true) ?
                      <Checkbox
                        className={global.IconButton}
                        label=""
                        name="isSelected"
                        onChange={(e) => { onCheckboxChange(e, event.meetEventId) }}
                        checked={event.isSelected === true && event.qualifiedAsBonus === bonus} /> :
                      <Checkbox
                        className={global.IconButton}
                        label=""
                        name="isSelected"
                        onChange={(e) => { onCheckboxChange(e, event.meetEventId) }}
                        checked={event.isSelected === true && event.qualifiedAsBonus === bonus}
                        disabled={true} />}
                  </div>
                </Fragment>
                :
                <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onAddOverrideEntryClicked(e, event)}>Add Override Time</ActionIntraPageButton></div>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Event-Course</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{event.eventName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-xs'].join(' ')}>Competition Category</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-2'].join(' ')}>{event.eventCompetitionGenderTypeName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Age Group</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{event.ageGroupName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Session</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.meetSessionName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Entry Time</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{event.entryTime ? formatTimeForDisplay(event.entryTime) : 'NT'} {event.entryTime ? event.qualifyingCourseCode || <span>&nbsp;</span> : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Time Event</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.isAlternateEvent === true ? `ALT: ${event.qualifyingEventName || ''}` : event.isNonConformingTime === true ? `NC: ${event.qualifyingEventName || ''}` : <span>{event.qualifyingEventName || <span>&nbsp;</span>}</span>}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default AthleteUserEntriesSelectionSmallGrid;