import React, { Fragment } from 'react';

import useOmeTimeStandards from './UseOmeTimeStandards';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import OmeAgeGroupTimeStandardsGrid from './OmeAgeGroupTimeStandardsGrid';

import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ToolTip from '../../../../../common/components/tooltips/ToolTip';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OmeTimeStandards = () => {
  const {
    state,
    omeMeetState,
    timeStandardTypeState,
    errorState,
    formState,
    modalState,
    onModalCanceled,
    onSaveAndDeleteEventTimeStandardCut,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onEditAgeGroupTimeStandard,
    allowEdits
  } = useOmeTimeStandards();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_TIME_STANDARDS}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={errorState.error || state.error}>
        {allowEdits() === true ?
          <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row usas-extra-bottom-margin'>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label="Meet uses Time Standards?*"
                    name="hasTimeStandards"
                    checked={formState.hasTimeStandards}
                    error={errorState.hasTimeStandards}
                    message={errorState.hasTimeStandards}
                    onChange={(value) => { onFormValueChanged('hasTimeStandards', value); }}
                  />
                </div>
                <div className={formState.hasTimeStandards === true ? global.DisplayComponent : global.HideComponent} >
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label="Meet has No Slower Than Time Standards?*"
                      name="hasNoSlowerThanTimeStandards"
                      checked={formState.hasNoSlowerThanTimeStandards}
                      error={errorState.hasNoSlowerThanTimeStandards}
                      message={errorState.hasNoSlowerThanTimeStandards}
                      onChange={(value) => { onFormValueChanged('hasNoSlowerThanTimeStandards', value); }}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label="Meet has No Faster Than Time Standards?*"
                      name="hasNoFasterThanTimeStandards"
                      checked={formState.hasNoFasterThanTimeStandards}
                      error={errorState.hasNoFasterThanTimeStandards}
                      message={errorState.hasNoFasterThanTimeStandards}
                      onChange={(value) => { onFormValueChanged('hasNoFasterThanTimeStandards', value); }}
                    />
                  </div>
                  {formState.bonusEvents === true &&
                    <div className='col-xs-12 col-sm-6'>
                      <YesNoSwitch
                        label="Meet has Bonus Time Standards?*"
                        name="hasBonusTimeStandards"
                        checked={formState.hasBonusTimeStandards}
                        error={errorState.hasBonusTimeStandards}
                        message={errorState.hasBonusTimeStandards}
                        onChange={(value) => { onFormValueChanged('hasBonusTimeStandards', value); }}
                      />
                    </div>}
                  {formState.hasNoFasterThanTimeStandards === true &&
                    <div className='col-xs-12'>
                      <YesNoSwitch
                        label="Meet Excludes Athletes from All Events if they have a Swim Time Faster than a No Faster Than Time Standard Cut?*"
                        name="hasAthleteExceedsTimeStandardsRule"
                        checked={formState.hasAthleteExceedsTimeStandardsRule}
                        error={errorState.hasAthleteExceedsTimeStandardsRule}
                        message={errorState.hasAthleteExceedsTimeStandardsRule}
                        onChange={(value) => { onFormValueChanged('hasAthleteExceedsTimeStandardsRule', value); }}
                      />
                    </div>}
                  {formState.hasNoFasterThanTimeStandards === true && formState.relayEvents === true &&
                    <div className='col-xs-12'>
                      <YesNoSwitch
                        label="Meet Excludes Athletes from a Relay Event if they have a Swim Time Faster than a No Faster Than Time Standard Cut for the Relay Leg's Stroke and Distance?*"
                        name="hasAthleteExceedsTimeStandardsRelayRule"
                        checked={formState.hasAthleteExceedsTimeStandardsRelayRule}
                        error={errorState.hasAthleteExceedsTimeStandardsRelayRule}
                        message={errorState.hasAthleteExceedsTimeStandardsRelayRule}
                        onChange={(value) => { onFormValueChanged('hasAthleteExceedsTimeStandardsRelayRule', value); }}
                      />
                    </div>}
                  <div className='col-xs-12'>
                    <YesNoSwitch
                      label="Time Standards use a Qualifying Window?*"
                      name="hasQualifyingWindow"
                      checked={formState.hasQualifyingWindow}
                      error={errorState.hasQualifyingWindow}
                      message={errorState.hasQualifyingWindow}
                      onChange={(value) => {
                        onFormValueChanged('hasQualifyingWindow', value);
                        if (value === true) {
                          onFormValueChanged('timeStandardsQualifyingStartDate', Constants.BLANK_DATE_STRING);
                          onFormValueChanged('timeStandardsQualifyingEndDate', Constants.BLANK_DATE_STRING);
                        }
                      }}
                    />
                  </div>
                  <div className={formState.hasQualifyingWindow === true ? global.DisplayComponent : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <DatePicker
                        label={<Fragment>Qualifying Start Date* <span><ToolTip label="Qualifying swim times before this date are not accepted for the meet"></ToolTip></span></Fragment>}
                        name="timeStandardsQualifyingStartDate"
                        value={formState.timeStandardsQualifyingStartDate}
                        error={errorState.timeStandardsQualifyingStartDate}
                        message={errorState.timeStandardsQualifyingStartDate}
                        onChange={(value) => { onFormValueChanged('timeStandardsQualifyingStartDate', value); }}
                        countOfYears={155}
                        startYearOffset={-150} />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <DatePicker
                        label={<Fragment>Qualifying End Date* <span><ToolTip label="Qualifying swim times after this date are not accepted for the meet"></ToolTip></span></Fragment>}
                        name="timeStandardsQualifyingEndDate"
                        value={formState.timeStandardsQualifyingEndDate}
                        error={errorState.timeStandardsQualifyingEndDate}
                        message={errorState.timeStandardsQualifyingEndDate}
                        onChange={(value) => { onFormValueChanged('timeStandardsQualifyingEndDate', value); }}
                        countOfYears={155}
                        startYearOffset={-150} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={formState.hasTimeStandards === true ? global.DisplayComponent : global.HideComponent} >
              <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
                <div className='col-xs-12 usas-extra-top-margin'>
                  <p>Time Standard information is required for each Age Group. Select Time Standards for an Age Group by clicking the edit icon. If an Age Group does not use a Time Standard Type, select the 'No Time Standard' option on the detail page.</p>
                </div>
                <div className='col-xs-12 usas-extra-top-margin'>
                  <Headings.H6>Age Group Time Standards</Headings.H6>
                </div>
                <div className='col-xs-12'>
                  <OmeAgeGroupTimeStandardsGrid
                    state={omeMeetState}
                    gridState={state}
                    onEdit={onEditAgeGroupTimeStandard}
                    showNoSlowerThan={formState.hasNoSlowerThanTimeStandards === true}
                    showNoFasterThan={formState.hasNoFasterThanTimeStandards === true}
                    showBonus={formState.bonusEvents === true && formState.hasBonusTimeStandards === true}
                  />
                </div>
              </div>
            </div>
          </Fragment>
          :
          <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row usas-extra-bottom-margin'>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label="Meet uses Time Standards?*"
                    name="hasTimeStandards"
                    checked={formState.hasTimeStandards}
                    error={errorState.hasTimeStandards}
                    message={errorState.hasTimeStandards}
                    onChange={(value) => { onFormValueChanged('hasTimeStandards', value); }}
                    disabled={true}
                  />
                </div>
                <div className={formState.hasTimeStandards === true ? global.DisplayComponent : global.HideComponent} >
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label="Meet has No Slower Than Time Standards?*"
                      name="hasNoSlowerThanTimeStandards"
                      checked={formState.hasNoSlowerThanTimeStandards}
                      error={errorState.hasNoSlowerThanTimeStandards}
                      message={errorState.hasNoSlowerThanTimeStandards}
                      onChange={(value) => { onFormValueChanged('hasNoSlowerThanTimeStandards', value); }}
                      disabled={true}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label="Meet has No Faster Than Time Standards?*"
                      name="hasNoFasterThanTimeStandards"
                      checked={formState.hasNoFasterThanTimeStandards}
                      error={errorState.hasNoFasterThanTimeStandards}
                      message={errorState.hasNoFasterThanTimeStandards}
                      onChange={(value) => { onFormValueChanged('hasNoFasterThanTimeStandards', value); }}
                      disabled={true}
                    />
                  </div>
                  {formState.bonusEvents === true &&
                    <div className='col-xs-12 col-sm-6'>
                      <YesNoSwitch
                        label="Meet has Bonus Time Standards?*"
                        name="hasBonusTimeStandards"
                        checked={formState.hasBonusTimeStandards}
                        error={errorState.hasBonusTimeStandards}
                        message={errorState.hasBonusTimeStandards}
                        onChange={(value) => { onFormValueChanged('hasBonusTimeStandards', value); }}
                        disabled={true}
                      />
                    </div>}
                  {formState.hasNoFasterThanTimeStandards === true &&
                    <div className='col-xs-12'>
                      <YesNoSwitch
                        label="Meet Excludes Athletes from All Events if they have a Swim Time Faster than a No Faster Than Time Standard Cut?*"
                        name="hasAthleteExceedsTimeStandardsRule"
                        checked={formState.hasAthleteExceedsTimeStandardsRule}
                        error={errorState.hasAthleteExceedsTimeStandardsRule}
                        message={errorState.hasAthleteExceedsTimeStandardsRule}
                        onChange={(value) => { onFormValueChanged('hasAthleteExceedsTimeStandardsRule', value); }}
                        disabled={true}
                      />
                    </div>}
                  {formState.hasNoFasterThanTimeStandards === true && formState.relayEvents === true &&
                    <div className='col-xs-12'>
                      <YesNoSwitch
                        label="Meet Excludes Athletes from a Relay Event if they have a Swim Time Faster than a No Faster Than Time Standard Cut for the Relay Leg's Stroke and Distance?*"
                        name="hasAthleteExceedsTimeStandardsRelayRule"
                        checked={formState.hasAthleteExceedsTimeStandardsRelayRule}
                        error={errorState.hasAthleteExceedsTimeStandardsRelayRule}
                        message={errorState.hasAthleteExceedsTimeStandardsRelayRule}
                        onChange={(value) => { onFormValueChanged('hasAthleteExceedsTimeStandardsRelayRule', value); }}
                        disabled={true}
                      />
                    </div>}
                  <div className='col-xs-12'>
                    <YesNoSwitch
                      label="Time Standards use a Qualifying Window?*"
                      name="hasQualifyingWindow"
                      checked={formState.hasQualifyingWindow}
                      error={errorState.hasQualifyingWindow}
                      message={errorState.hasQualifyingWindow}
                      onChange={(value) => { onFormValueChanged('hasQualifyingWindow', value); }}
                      disabled={true}
                    />
                  </div>
                  <div className={formState.hasQualifyingWindow === true ? global.DisplayComponent : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Qualifying Start Date* <span><ToolTip label="Qualifying swim times before this date are not accepted for the meet"></ToolTip></span></Fragment>}
                        name="timeStandardsQualifyingStartDate"
                        value={formatDate(formState.timeStandardsQualifyingStartDate)} />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Qualifying End Date* <span><ToolTip label="Qualifying swim times after this date are not accepted for the meet"></ToolTip></span></Fragment>}
                        name="timeStandardsQualifyingEndDate"
                        value={formatDate(formState.timeStandardsQualifyingEndDate)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={formState.hasTimeStandards === true ? global.DisplayComponent : global.HideComponent} >
              <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
                <div className='col-xs-12 usas-extra-top-margin'>
                  <p>Time Standard information is required for each Age Group. Select Time Standards for an Age Group by clicking the edit icon. If an Age Group does not use a Time Standard Type, select the 'No Time Standard' option on the detail page.</p>
                </div>
                <div className='col-xs-12 usas-extra-top-margin'>
                  <Headings.H6>Age Group Time Standards</Headings.H6>
                </div>
                <div className='col-xs-12'>
                  <OmeAgeGroupTimeStandardsGrid
                    state={omeMeetState}
                    gridState={state}
                    showNoSlowerThan={formState.hasNoSlowerThanTimeStandards === true}
                    showNoFasterThan={formState.hasNoFasterThanTimeStandards === true}
                    showBonus={formState.bonusEvents === true && formState.hasBonusTimeStandards === true}
                  />
                </div>
              </div>
            </div>
          </Fragment>}
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              {modalState.deleteAllEventTimeStandardCuts === true ? <p>Changing 'Meet Uses Time Standards?' from 'Yes' to 'No' will delete all Event Time Standard Cuts.</p> : ''}
              {modalState.deleteAllEventNoSlowerThanTimeStandardCuts === true ? <p>Changing 'Meet has No Slower Than Time Standards?' from 'Yes' to 'No' will delete all Event No Slower Than Time Standard Cuts.</p> : ''}
              {modalState.deleteAllEventNoFasterThanTimeStandardCuts === true ? <p>Changing 'Meet has No Faster Than Time Standards?' from 'Yes' to 'No' will delete all Event No Faster Than Time Standard Cuts.</p> : ''}
              {modalState.deleteAllEventBonusTimeStandardCuts === true ? <p>Changing 'Meet has Bonus Time Standards?' from 'Yes' to 'No' will delete all Event Bonus Time Standard Cuts.</p> : ''}
              <p><b>Are you sure you would like to save these changes and make the associated deletes?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onSaveAndDeleteEventTimeStandardCut(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={timeStandardTypeState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate >
    </Fragment >
  );
};

export default OmeTimeStandards;