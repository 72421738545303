export const localValidate = (formState) => {
  let errors = {};

  if (formState.orgUnit.length === 0) {
    errors.orgUnit = 'At least one selection is required'
  }

  return errors;
};

const OmeInvitationsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeInvitationsValidation;