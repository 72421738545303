import React, { Fragment } from 'react';

import useOmeMeetTemplate from './UseOmeMeetTemplate';

import SessionsGrid from './SessionsGrid';

import SelectedMeetTemplateGrid from './SelectedMeetTemplateGrid';

import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../common/components/headings/Headings';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import global from '../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../common/utils/DateFunctions';
import Constants from '../../../common/utils/Constants';

const OmeMeetTemplate = () => {
  const {
    omeMeetState,
    meetState,
    contextSecurityState,
    onNextButtonClicked,
    onExitButtonClicked,
    onDeleteClicked,
    goToMeetTemplateSearch,
    formState,
    errorState,
    onValueTextPairChanged,
    meetSessionChangesModalState,
    meetSessionState,
    onModalSaveButtonClicked,
    onMeetSessionChangesModalCanceled
  } = useOmeMeetTemplate();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3
            className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
            excludeBar={true}>Online Meet Entry - Host</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className={"row"}>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Host:</b> {omeMeetState.selectedMeet?.meetHost} </p>
          <p className={global.HeaderText}><b>Meet Name:</b> {omeMeetState.selectedMeet?.meetName}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {formatDate(omeMeetState.selectedMeet?.startDate)} - {formatDate(omeMeetState.selectedMeet?.endDate)} </p>
          <p className={global.HeaderText}><b>Meet Classification:</b> {omeMeetState.selectedMeet?.meetClassificationName || ''}</p>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Meet Template (Optional)</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <p>Selecting a meet template is an optional step that can be used to speed up the Online Meet Entry (OME) Host process.</p>
          <p>Choosing a meet template will pre-populate your new OME meet with information from an exisiting OME meet.</p>
          <p>If you wish to use a meet template, please use the search below. If you do not wish to use a meet template, simply click 'Continue'.</p>
          <p><b>After clicking 'Continue', you will not be able to select a different template. Please select carefully.</b></p>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <ActionIntraPageButton onClick={goToMeetTemplateSearch}>Search For Meet Template</ActionIntraPageButton>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <SelectedMeetTemplateGrid meetState={meetState} onDelete={onDeleteClicked} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <PrimaryButton onClick={onNextButtonClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton onClick={onExitButtonClicked}>Exit</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={meetState.isObjLoading || contextSecurityState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={meetSessionChangesModalState.modalTitle}
        displayPopUp={meetSessionChangesModalState.displayPopUp}
        onModalCanceled={onMeetSessionChangesModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Template Meet:</b> {meetSessionChangesModalState.templateMeetInfo}</p>
            <p><b>New Meet:</b> {meetSessionChangesModalState.meetInfo}</p>
            <p><b>In order to properly copy events from the template meet into the new meet, for each session in the template meet please select a session from the new meet to use for events instead.</b></p>
            <SessionsGrid
              showOptionalFields={false}
              showOtsField={false}
              sortedMeetSessionArray={meetState.objData?.meetSession || []}
              meetSessionState={meetSessionState}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalSaveButtonClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onMeetSessionChangesModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default OmeMeetTemplate;