import { isValidDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  //Meet Name
  if (formState.meetName.trim() === '') {
    errors.meetName = 'Meet Name is required'
  } else if ((formState.usasHostOrg === true && formState.usasLsc === true && (formState.autogeneratedMeetYear + ' ' + formState.autogeneratedMeetLsc + ' ' + formState.meetName.trim()).toString().length > 200) ||
    (formState.usasHostOrg === true && formState.usasLsc === false && (formState.autogeneratedMeetYear + ' ' + formState.meetName.trim()).toString().length > 200) ||
    (formState.usasHostOrg === false && formState.meetName.trim().length > 200)) {
    errors.meetName = 'Meet Name cannot exceed 200 characters'
  }

  //Meet Abbreviation
  if (formState.meetAbbreviation.trim().length > 50) {
    errors.meetAbbreviation = 'Meet Abbreviation cannot exceed 50 characters'
  }

  //Host Org Unit
  if (formState.hostOrgUnit.length === 0 || formState.hostOrgUnit[0].id < 1) {
    errors.hostOrgUnit = 'Host Organization is required'
  } else if (formState.hostOrgUnit[0].id === formState.NCAA_ORG_UNIT_ID) {
    errors.hostOrgUnit = 'Please select an NCAA Division, Conference, or Team'
  }

  //Meet Classification
  if (formState.meetClassification.length === 0) {
    errors.meetClassification = 'Meet Classification is required'
  }

  // Meet Course
  if (formState.meetCourse.length === 0) {
    errors.meetCourse = "Must pick at least one Course"
  }

  //Start Date
  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  } else if (endDate < startDate) {
    errors.startDate = 'Start Date must be before or on the End Date';
  }

  //End Date
  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  } else if (endDate < startDate) {
    errors.endDate = 'End Date must be on or after the Start Date';
  }

  //Facility
  if (errors.hostOrgUnit === undefined && (formState.facilityId < 0 || formState.facilityName === '') &&
    formState.meetCourse.find(x => x.id !== 4)
  ) {
    if (formState.facilityId !== null) {
      errors.facilityId = 'Facility is required';
    }
  }

  return errors;
};

const MeetAddValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MeetAddValidation;