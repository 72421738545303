export const localValidate = (formState) => {
  const newRelayTeams = formState.orgUnits || [];
  const existingRelayTeams = formState.existingRelayTeams || [];
  let errors = {};

  if (newRelayTeams.length === 0) {
    errors.orgUnits = 'Must select a Relay Team';
  } else {
    for (const relayTeam of newRelayTeams) {
      const existingRelayTeam = existingRelayTeams.find(x => x.orgUnitId === relayTeam.id);

      if (existingRelayTeam) {
        errors.orgUnits = `${existingRelayTeam.orgUnitName} is already assigned to this event`;
        break;
      }
    }
  }

  return errors;
};

const LaneSheetAddRelayTeamsFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetAddRelayTeamsFormValidation;