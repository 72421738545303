import { useState, useEffect } from 'react';

import validate from './LaneSheetHeatAthletesFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  options: []
};

const useLaneSheetHeatAthletesForm = (onSubmitFormCallback, numberOfLanes, meetEventAthletes, heatAthletes) => {
  const { formState, errorState, setFormData, onValueTextPairChanged, handleSubmit
  } = UseForm({}, onSubmitFormCallback, validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    const options = [];

    meetEventAthletes.forEach((athlete) => {
      options.push({
        id: athlete.personId,
        name: `${athlete.firstName || ''} ${athlete.lastName || ''}`
      });
    });

    setOptionsState({
      ...optionsState,
      areOptionsLoaded: true,
      options
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetEventAthletes]);

  useEffect(() => {
    if (numberOfLanes > 0 && optionsState.areOptionsLoaded === true) {
      let newFormState = {};

      for (let i = 1; i <= numberOfLanes; i++) {
        newFormState[`personId${i}`] = '';
        newFormState[`personName${i}`] = '';
      }

      for (const athlete of heatAthletes) {
        const i = athlete.laneNumber;
        const targetAthlete = optionsState.options.find(x => x.id === athlete.personId);

        if (i > 0 && targetAthlete) {
          newFormState[`personId${i}`] = targetAthlete.id;
          newFormState[`personName${i}`] = targetAthlete.name;
        }
      }

      setFormData({ ...newFormState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfLanes, optionsState, heatAthletes]);

  return {
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    options: optionsState.options || []
  };
};

export default useLaneSheetHeatAthletesForm;