import React from 'react';

import useSelectionsOrgLevelDropdown from './UseSelectionsOrgLevelDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const SelectionsOrgLevelDropdown = ({ label, name, value, error, message, onChange, organizationCode, levelCode }) => {
  const { selectionsOrgLevelState } = useSelectionsOrgLevelDropdown(organizationCode, levelCode);

  return selectionsOrgLevelState.message
    ? <span className={global.LoadingMsg}>{selectionsOrgLevelState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={selectionsOrgLevelState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={selectionsOrgLevelState.areOptionsLoaded !== true}
      />
    );
};

export default SelectionsOrgLevelDropdown;