import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const GetOrgRoleDataByOrganizationIdData = (organizationId, state, setState) => {
  if (state.isArrayLoading !== true && organizationId > 0 && organizationId !== state.organizationId && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      organizationId,
      message: 'Loading...'
    };
    setState(newState);

    const organizationIdForUrl = organizationId ? encodeURIComponent(organizationId) : 'NaN';
    const url = `/OrgRole/Organization/${organizationIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          data.unshift({ orgRoleId: '', orgRoleName: '--' });
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            organizationId,
            arrayData: data,
            dataAsOptionsWithNames: data.map((orgRole) => { return { id: orgRole.orgRoleId, name: orgRole.orgRoleName } }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOrgRoleDataByOrganizationIdData;