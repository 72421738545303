import useLaneSheetHeatRelayTeamsForm from "./UseLaneSheetHeatRelayTeamsForm";

import LaneSheetHeatRelayTeamFormGrid from "../grids/LaneSheetHeatRelayTeamFormGrid";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const LaneSheetHeatRelayTeamsForm = ({ primaryButtonText = 'Save', secondaryButtonText = 'Cancel', onSubmitFormCallback,
  onSecondaryButtonClicked, numberOfLanes, startingLaneNumber, meetEventRelayTeams, heatRelayTeams }) => {
  const {
    formState,
    //errorState,
    handleSubmit,
    onValueTextPairChanged,
    options
  } = useLaneSheetHeatRelayTeamsForm(onSubmitFormCallback, numberOfLanes, meetEventRelayTeams, heatRelayTeams);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <LaneSheetHeatRelayTeamFormGrid
        numberOfLanes={numberOfLanes}
        startingLaneNumber={startingLaneNumber}
        formState={formState}
        options={options}
        onValueTextPairChanged={onValueTextPairChanged} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetHeatRelayTeamsForm;