import React from 'react';

import useOrgUnitOmeMeetTreeView from './UseOrgUnitOmeMeetTreeView';

import TreeView from '../../../../common/components/tree/TreeView';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const OrgUnitOmeMeetTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, omeMeetId }) => {
  const { orgUnitOmeMeetState } = useOrgUnitOmeMeetTreeView(omeMeetId || Constants.DEFAULT_ID);

  return orgUnitOmeMeetState.message
    ? <span className={global.LoadingMsg}>{orgUnitOmeMeetState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={orgUnitOmeMeetState.isArrayLoading}
      options={orgUnitOmeMeetState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default OrgUnitOmeMeetTreeView;