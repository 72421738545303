import { isValidCompetitorGender } from '../utils/MeetUtils';
import { isValidNumber, isValidBirthDate, isValidSwimTime } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';
import { convertTimeToMilliseconds, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

const PERSON_ID_KEY = 'personId';
const PERSON_GENDER_KEY = 'personGender';

const eventGenders = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Mixed" }
];

export const localValidate = (formState) => {
  const competitors = [
    { id: formState.personId1, number: 1, gender: formState.personGender1 },
    { id: formState.personId2, number: 2, gender: formState.personGender2 },
    { id: formState.personId3, number: 3, gender: formState.personGender3 },
    { id: formState.personId4, number: 4, gender: formState.personGender4 },
  ];
  let errors = {};

  //Athlete 1
  if (formState.personId1 === Constants.DEFAULT_ID) {
    errors.personId1 = 'Leg 1 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.personId1, 1, competitors);

    if (dupResultString) {
      errors.personId1 = dupResultString;
    }
  }

  if (formState.time1 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time1))) {
    errors.time1 = 'Leg 1 Time must be valid';
  }

  //Athlete 2
  if (formState.personId2 === Constants.DEFAULT_ID) {
    errors.personId2 = 'Leg 2 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.personId2, 2, competitors);

    if (dupResultString) {
      errors.personId2 = dupResultString;
    }
  }

  if (formState.time2 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time2))) {
    errors.time2 = 'Leg 2 Time must be valid';
  }

  //Athlete 3
  if (formState.personId3 === Constants.DEFAULT_ID) {
    errors.personId3 = 'Leg 3 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.personId3, 3, competitors);

    if (dupResultString) {
      errors.personId3 = dupResultString;
    }
  }

  if (formState.time3 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time3))) {
    errors.time3 = 'Leg 3 Time must be valid';
  }

  //Athlete 4
  if (formState.personId4 === Constants.DEFAULT_ID) {
    errors.personId4 = 'Leg 4 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.personId4, 4, competitors);

    if (dupResultString) {
      errors.personId4 = dupResultString;
    }
  }

  if (formState.time4 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time4))) {
    errors.time4 = 'Leg 4 Time must be valid';
  }

  //Event
  if (formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = 'Event is required';
  }

  //Event Gender
  if (formState.eventCompetitionGenderTypeId === Constants.DEFAULT_ID) {
    errors.eventCompetitionGenderTypeId = 'Event Gender is required';
  }

  //Time 
  if (formState.time === '') {
    errors.time = 'Relay Team Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formState.time))) {
    errors.time = 'Relay Team Time must be valid';
  } else if (formState.time1 || formState.time2 || formState.time3 || formState.time4) {
    const time1Milliseconds = formState.time1 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time1)) : 0;
    const time2Milliseconds = formState.time2 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time2)) : 0;
    const time3Milliseconds = formState.time3 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time3)) : 0;
    const time4Milliseconds = formState.time4 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time4)) : 0;
    const totalTimeMilliseconds = time1Milliseconds + time2Milliseconds + time3Milliseconds + time4Milliseconds;
    const timeMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formState.time));

    if (totalTimeMilliseconds > timeMilliseconds) {
      errors.time = 'The sum of the Relay Leg Times cannot be greater than the Relay Team Time';
    }
  }

  //Place
  if (formState.finishPosition !== '' && (!isValidNumber(formState.finishPosition.trim()) || formState.finishPosition.trim() === '0')) {
    errors.finishPosition = 'Place (finish position) must be a number greater than zero if entered';
  }

  //Team Name
  if (formState.teamName.trim() !== '' && formState.teamName.trim().length > 200) {
    errors.teamName = 'Team Name cannot exceed 200 characters';
  }

  //Swim Date
  if (formState.swimDate === '' || formState.swimDateId === Constants.DEFAULT_ID) {
    errors.swimDateId = 'Swim Date is required';
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDateId = 'Swim Date cannot be in the future'
  }

  if (errors.eventCompetitionGenderTypeId === undefined) {
    const eventGender = eventGenders.find(genderOption => genderOption.id === formState.eventCompetitionGenderTypeId)?.name;

    if (eventGender) {
      if (eventGender === eventGenders[2].name) {
        let maleCount = 0;
        let femaleCount = 0;

        for (const competitor of competitors) {
          if (competitor.gender === eventGenders[0].name) {
            maleCount += 1;
          }
          else if (competitor.gender === eventGenders[1].name) {
            femaleCount += 1;
          }
        }

        if (maleCount !== 2 && femaleCount !== 2) {
          errors.eventCompetitionGenderTypeId = `Must contain 2 Males & 2 Females. Males selected: ${maleCount} | Females selected: ${femaleCount}`;
        }
      } else {
        for (const competitor of competitors) {
          if (errors[`${PERSON_ID_KEY}${competitor.number}`] === undefined) {
            const compGenderResults = isValidCompetitorGender(formState[`${PERSON_GENDER_KEY}${competitor.number}`], eventGender);

            if (compGenderResults) {
              errors[`${PERSON_ID_KEY}${competitor.number}`] = compGenderResults;
            }
          }
        }
      }
    }
  }

  return errors;
};

const AddMeetTimeRelayValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AddMeetTimeRelayValidation;

function isDuplicateCompetitor(targetPersonId, targetPersonNumber, competitors) {
  let matchNumbers = [];

  for (const competitor of competitors) {
    if (competitor.id === targetPersonId && competitor.number !== targetPersonNumber) {
      matchNumbers.push(competitor.number);
    }
  }

  if (matchNumbers.length > 0) {
    let errorMessage = `Leg ${targetPersonNumber} Athlete cannot be the same as`;

    if (matchNumbers.length === 1) {
      errorMessage += ` Leg ${matchNumbers[0]} Athlete`
    }
    else {
      errorMessage += ' Leg Athletes';
      for (let i = 0; i < matchNumbers.length; i++) {
        if (i === matchNumbers.length - 1) {
          errorMessage += ` and ${matchNumbers[i]}`;
        } else {
          errorMessage += ` ${matchNumbers[i]}`
        }
      }
    }

    return errorMessage;
  } else {
    return undefined;
  }
}