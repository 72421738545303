import { useState, useEffect } from 'react';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useMeetUploadErrorTankData from '../../../../common/state/meet/meetUploadErrorTank/UseMeetUploadErrorTankData';
import useForm from '../../../../common/utils/UseForm';

const INITIAL_STATE = {
  errorMessage: '',
  searchingForMemberByMemberPopup: false,
};

const useMeetUploadErrorTank = () => {
  const {
    getMeetUploadErrorTank,
    postMeetUploadErrorTankMatch,
    postMeetUploadErrorTankQuickAdd,
    deleteMeetUploadErrorTankSwimmers,
    postMeetUploadErrorTankMatchByMemberId,
    getBasicMember,
    getMeetPotentialMatchedPersons
  } = useMeetUploadErrorTankData();


  const { meetState } = useMeetData();
  const [gridState, setGridState] = useState(getInitialGridState);
  const [popupState, setPopupState] = useState(getInitialPopupState);
  const [basicMatchedMemberState, setBasicMatchedMemberState] = useState(getInitialBasicMemberState);
  const [potentialMatchMemberState, setPotentialMatchMemberState] = useState(getInitialPotentialMatchMemberState);
  const [state, setState] = useState(INITIAL_STATE);

  const [uiState, setUiState] = useState({
    isMatchedButtonClicked: false,
    isQuickAddButtonClicked: false,
    isMatchByIdButtonClicked: false,
    isUsingPersonSearchToIdentifyMember: false,
  });
  const [selectedSwimmerState, setSelectedSwimmerState] = useState({
    clubCode: '',
    lscCode: '',
    memberId: '',
    firstName: '',
    lastName: '',
    birthDate: ''
  })

  const {
    formState,
    handleSubmit,
    setFormState,
    setFormData,
    errorState,
    onFormValueChanged
  } = useForm(getFormInitialState);


  const validate = () => {
    if (formState.memberId.trim().length === 0) {
      return 'Please select a member to match with in order to proceed'
    }

    return '';
  };

  const onMatchSelectedMember = () => {
    const errorMessage = validate();
    if (errorMessage) {

      setState({ ...state, errorMessage });
    } else {

      setState({ ...INITIAL_STATE });
      getBasicMember(formState.memberId, basicMatchedMemberState, setBasicMatchedMemberState);
    };
  }


  const onMatchSelectedMemberUsingPersonSearch = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    getBasicMember(formState.memberId, basicMatchedMemberState, setBasicMatchedMemberState);
    setState({ ...state, searchingForMemberByMemberPopup: false });
    setUiState({
      ...uiState,
      isMatchByIdButtonClicked: true,
      isMatchedButtonClicked: true,
      isQuickAddButtonClicked: false,
      isUsingPersonSearchToIdentifyMember: true
    });
  };


  const onMatchCheckbox = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newList = gridState.arrayData;
    const selectedIndex = newList.findIndex(x => x.personId === personId);
    newList[selectedIndex].matchedPerson = !newList[selectedIndex].matchedPerson;

    setGridState({
      ...gridState,
      arrayData: newList
    });
  };

  const onRadioChanged = (e, person) => {

    setFormData({
      ...formState,
      person: person,
      memberId: person?.memberId
    });
  };

  const onQuickAddCheckbox = (e, rowId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newList = gridState.arrayData;
    const selectedIndex = newList.findIndex(x => x.rowId === rowId);
    newList[selectedIndex].addPerson = !newList[selectedIndex].addPerson;

    setGridState({
      ...gridState,
      arrayData: newList
    });
  }

  const onDeleteCheckbox = (e, rowId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newList = gridState.arrayData;
    const selectedIndex = newList.findIndex(x => x.rowId === rowId);
    newList[selectedIndex].deletePerson = !newList[selectedIndex].deletePerson;

    setGridState({
      ...gridState,
      arrayData: newList
    });
  }

  const onSelectAllButtonClick = () => {
    if (meetState.isObjLoaded === true) {
      let deletePersonArray = gridState.arrayData;

      deletePersonArray.forEach((arrayItem) => {
        arrayItem.deletePerson = true;
      });

      setGridState({
        ...gridState,
        arrayData: deletePersonArray
      });
    }
  }

  const onUnselectAllButtonClick = () => {
    if (meetState.isObjLoaded === true) {
      //let addPersonArray = gridState.arrayData;
      let deletePersonArray = gridState.arrayData;

      deletePersonArray.forEach((arrayItem) => {
        arrayItem.deletePerson = false;
      });

      setGridState({
        ...gridState,
        arrayData: deletePersonArray
      });
    }
  }

  const submitMatchSelections = () => {
    setUiState({ ...uiState, isMatchedButtonClicked: true, isQuickAddButtonClicked: false });

    postMeetUploadErrorTankMatch(meetState.objData.meetId, gridState.arrayData, gridState, setGridState);
  }

  const onQuickAddButtonClick = () => {
    setUiState({ ...uiState, isMatchedButtonClicked: true, isQuickAddButtonClicked: true });

    let quickAddPersonArray = [];

    gridState.arrayData.forEach((arrayItem) => {
      if (arrayItem.addPerson === true) {
        quickAddPersonArray.push(arrayItem);
      }
    });


    postMeetUploadErrorTankQuickAdd(meetState.objData.meetId, quickAddPersonArray, gridState, setGridState);
  }

  const onBackToMatchingButtonClick = () => {
    if (meetState.isObjLoaded === true) {
      setUiState({ ...uiState, isMatchedButtonClicked: false, isQuickAddButtonClicked: false });

      getMeetUploadErrorTank(meetState.objData.meetId, gridState, setGridState);
    }
  }

  const onDeleteSwimmerFromErrorTank = () => {
    deleteMeetUploadErrorTankSwimmers(meetState.objData.meetId, gridState.arrayData, gridState, setGridState);

    setPopupState({
      ...popupState,
      displayModal: false,
      displayMessage: ''
    });
  }

  const onDeleteButtonClick = () => {
    if (meetState.isObjLoaded === true) {
      setUiState({ ...uiState, isMatchedButtonClicked: true, isQuickAddButtonClicked: true });

      let deleteItem = gridState.arrayData.find(({ deletePerson, isRelay }) => deletePerson === true && isRelay === true)

      //person belonging to a relay is being deleted
      let message = "";
      if (deleteItem !== undefined) {
        message = "One or more swimmers who are member of a relay have been selected for deletion. If one or more members of a relay are deleted, all the relay members will be deleted."
      } else {
        message = "Are you sure you want to delete the selected swimmers from the error tank?"
      }

      setPopupState({
        ...popupState,
        displayModal: true,
        displayMessage: message
      });
    }
  }

  const clearSelections = () => {
    if (meetState.isObjLoaded === true) {
      setUiState({ ...uiState, isMatchedButtonClicked: false });
      getMeetUploadErrorTank(meetState.objData.meetId, gridState, setGridState);
    }
  }

  const onConfirmMatchMemberByIdButtonClick = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }


    const selectedSwimmerObj = {
      ...selectedSwimmerState,
      memberId: formState.memberId
    };

    postMeetUploadErrorTankMatchByMemberId(meetState.objData.meetId, selectedSwimmerObj, gridState, setGridState);

    setBasicMatchedMemberState({
      ...basicMatchedMemberState,
      isObjLoaded: false
    });

    setUiState({
      ...uiState,
      isMatchByIdButtonClicked: false
    });

    resetPotentialMatchState();
  }

  const onCancelMatchWithMemberIdButtonClick = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setBasicMatchedMemberState({
      ...basicMatchedMemberState,
      ...getInitialBasicMemberState()
    });

    setUiState({
      ...uiState,
      isMatchByIdButtonClicked: false
    });

    resetPotentialMatchState();
  }


  const onMatchWithMemberIdButtonClick = (e, matchItem) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setSelectedSwimmerState({
      ...selectedSwimmerState,
      clubCode: matchItem.clubCode,
      lscCode: matchItem.lscCode,
      memberId: '',
      firstName: matchItem.firstName,
      lastName: matchItem.lastName,
      birthDate: matchItem.birthDate
    });

    setFormState({
      ...formState,
      memberId: ''
    });

    setUiState({
      ...uiState,
      isMatchByIdButtonClicked: true
    });
  };

  const resetPotentialMatchState = (e,) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPotentialMatchMemberState({
      ...potentialMatchMemberState,
      ...getInitialPotentialMatchMemberState()
    })

    setFormState({
      ...formState,
      ...getFormInitialState()
    })
  };


  const onSearchForMemberByMemberPopup = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, searchingForMemberByMemberPopup: !state.searchingForMemberByMemberPopup });
  };

  const setStateForSearchPersonByMemberId = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({...state, searchingForMemberByMemberPopup: true});
  }


  const onCancelSearchByMemberIdPopup = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setUiState({...uiState, isMatchByIdButtonClicked: true});
    setStateForSearchPersonByMemberId();
  }
  

  useEffect(() => {
    if (formState?.memberId === '' && uiState?.isMatchByIdButtonClicked === true
      && potentialMatchMemberState?.isArrayLoaded !== true) {

      getMeetPotentialMatchedPersons(meetState.objData.meetId, selectedSwimmerState?.firstName, selectedSwimmerState?.lastName, selectedSwimmerState?.birthDate, selectedSwimmerState?.lscCode, selectedSwimmerState?.clubCode, potentialMatchMemberState, setPotentialMatchMemberState)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.memberId, uiState?.isMatchByIdButtonClicked, potentialMatchMemberState, selectedSwimmerState]);

  const onPopupCancel = () => {
    setPopupState(getInitialPopupState);
  };

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      getMeetUploadErrorTank(meetState.objData.meetId, gridState, setGridState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded]);

  useEffect(() => {
    if (gridState.isArrayLoaded === true && gridState.arrayData.length > 0) {
      const gridStateArrayData = gridState.arrayData;

      gridStateArrayData.sort((a, b) => {
        const result = a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase());

        return result !== 0 ? result : a.firstName.toUpperCase().localeCompare(b.firstName.toUpperCase());
      });

      let lastName = gridStateArrayData[0].lastName;
      let firstName = gridStateArrayData[0].firstName;

      gridStateArrayData.forEach((arrayItem) => {
        if (arrayItem.lastName === lastName && arrayItem.firstName === firstName) {
          arrayItem.isRepeat = true;
        } else {
          arrayItem.isRepeat = false;
        }
        lastName = arrayItem.lastName;
        firstName = arrayItem.findName;
      });

      if (gridStateArrayData.length > 1 && gridStateArrayData[1].isRepeat === false) {
        gridStateArrayData[0].isRepeat = false;
      } else if (gridStateArrayData.length === 1) {
        gridStateArrayData[0].isRepeat = false;
      }

      let newState = {
        ...gridState,
        arrayData: gridStateArrayData
      };
      setGridState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.isArrayLoaded]);



  return {
    gridState,
    uiState,
    popupState,
    selectedSwimmerState,
    basicMatchedMemberState,
    handleSubmit,
    onMatchCheckbox,
    submitMatchSelections,
    clearSelections,
    onBackToMatchingButtonClick,
    onQuickAddButtonClick,
    onQuickAddCheckbox,
    onSelectAllButtonClick,
    onUnselectAllButtonClick,
    onDeleteButtonClick,
    onDeleteCheckbox,
    onMatchWithMemberIdButtonClick,
    onCancelMatchWithMemberIdButtonClick,
    onPopupCancel,
    onDeleteSwimmerFromErrorTank,
    onConfirmMatchMemberByIdButtonClick,
    setFormData,
    potentialMatchMemberState,
    onRadioChanged,
    onMatchSelectedMember,
    errorMessage: state?.errorMessage,
    formState,
    errorState,
    onFormValueChanged,
    onSearchForMemberByMemberPopup,
    searchingForMemberByMemberPopUp: state.searchingForMemberByMemberPopup,
    onMatchSelectedMemberUsingPersonSearch,
    onCancelSearchByMemberIdPopup
  };
};

export default useMeetUploadErrorTank;

function getInitialGridState() {
  return {
    arrayData: [],
    isArrayLoading: false,
    isArrayLoaded: false
  };
};

function getFormInitialState() {
  return {
    memberId: '',
    person: {}
  }
};

function getInitialPopupState() {
  return {
    displayModal: false,
    confirmationMessage: ''
  }
};

function getInitialBasicMemberState() {
  return {
    objData: {},
    isObjLoading: false,
    isObjLoaded: false
  }
};


function getInitialPotentialMatchMemberState() {
  return {
    arrayData: [],
    isArrayLoading: false,
    isArrayLoaded: false
  }
};