import React, { createContext, useState } from 'react';

export const MyMeetsFiltersStateContext = createContext();

const INITIAL_STATE = {
  omeMeetHostFiltersState: {},
  omeMeetTeamEntryFiltersState: {}
};

const MyMeetsFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <MyMeetsFiltersStateContext.Provider value={stateHook}>
      {children}
    </MyMeetsFiltersStateContext.Provider>
  );
};

export default MyMeetsFiltersContextProvider;