import React, { Fragment } from 'react';

import useMeetRecon from './UseMeetRecon';

import Headings from '../../../../common/components/headings/Headings';
import MeetReconUpload from '../../../../common/components/fileUpload/meetReconUpload/MeetReconUpload';

const MeetRecon = () => {
  const {
    meetId
  } = useMeetRecon();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Recon</Headings.H3>
        </div>
      </div>
      {meetId > 0 &&
        <MeetReconUpload meetId={meetId} />
      }
    </Fragment>
  );
};

export default MeetRecon;