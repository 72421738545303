import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.omeFeeTypeId < 0) {
    errors.omeFeeTypeId = 'A valid Fee Type is required';
  }

  if (formState.amount === '' || !isValidNumber(formState.amount)) {
    errors.amount = 'Cost must be a valid number';
  } else if (formState.amount < 0 || formState.amount > 500) {
    errors.amount = 'Cost must be between $0 and $500';
  }

  if (formState.description.trim() === '') {
    errors.description = 'Description is required';
  } else if (formState.description.trim().length > 500) {
    errors.description = 'Description cannot exceed 500 characters';
  }

  return errors;
};

const OmePricingAdditionalPurchasesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmePricingAdditionalPurchasesValidation;