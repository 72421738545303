export const INITIAL_STATE = {
  showNav: true,
  isReadOnly: true,
  isUnattached: true
};

const AthleteEntryContextData = {
  INITIAL_STATE
};

export default AthleteEntryContextData;