import { Fragment } from "react";

import useParticipantSelectionsReadOnlyForm from "./UseParticipantSelectionsReadOnlyForm";

import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import Headings from "../../../../../common/components/headings/Headings";

const ParticipantSelectionsReadOnlyForm = ({ selectionsStateObj, selectionsParticipantsStateObj }) => {
  const {
    state
  } = useParticipantSelectionsReadOnlyForm(selectionsStateObj, selectionsParticipantsStateObj);

  return (
    <Fragment>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Participant Caps</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label='Swimmers'
            name="maxSwimmers"
            value={state.maxSwimmers} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label='Divers'
            name="maxDivers"
            value={state.maxDivers} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label='Total'
            name="totalParticipants"
            value={state.totalParticipants} />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Total Number of Competitors Selected</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Selected Swimmers'
            name="selectedSwimmers"
            value={state.selectedSwimmers} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Selected Divers'
            name="selectedDivers"
            value={state.selectedDivers} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Total Selected'
            name="totalSelected"
            value={state.totalSelected} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Number of Individual Event Swimmers Selected'
            name="indEventSwimmersSelected"
            value={state.indEventSwimmersSelected} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Last Filled Individual Row Number'
            name="lastFilledIndRowNumber"
            value={state.lastFilledIndRowNumber} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Last Filled Relay Row Number'
            name="lastFilledRelayRowNumber"
            value={state.lastFilledRelayRowNumber} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Relay Rows to Place/Seed'
            name="actualRelayRowsToSeed"
            value={state.actualRelayRowsToSeed} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Minimum Number of Relays to Accept'
            name="minRelayRowsToAccept"
            value={state.minRelayRowsToAccept} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Relay Swimmers Selected Based on Relay Only Relay Assignment'
            name="relaySelectedBasedOnRelayOnlyRelayAssignment"
            value={state.relaySelectedBasedOnRelayOnlyRelayAssignment} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Relay Swimmers Selected Based on Individual Entry Relay Assignment'
            name="relaySelectedBasedOnIndEntryRelayAssignment"
            value={state.relaySelectedBasedOnIndEntryRelayAssignment} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Relay Swimmers Selected Based on Relay Only Roster Designation'
            name="relaySelectedBasedOnRelayOnlyRosterDesignation"
            value={state.relaySelectedBasedOnRelayOnlyRosterDesignation} />
        </div>
      </div>
    </Fragment>
  );
};

export default ParticipantSelectionsReadOnlyForm;