import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const SmallDetailTableRow = ({ athlete, relayTeam }) => {
  return (
    <Fragment>
      <div className={global.SmallDetailTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>{`Leg ${athlete.legNumber >= 0 ? athlete.legNumber : ''}`} - {(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>{`Leg ${athlete.legNumber >= 0 ? athlete.legNumber : ''} Name`}</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age at Meet</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Time</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entryTime ? formatTimeForDisplay(athlete.entryTime) : 'NT'} {athlete.entryTime && relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Override?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.swimTimeEntryOverride === true ? 'Yes' : 'No'}</div>
            <span className={athlete.swimTimeEntryOverride === true ? global.DisplayComponent : global.HideComponent}>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Swim Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entrySwimDate ? formatDate(athlete.entrySwimDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entryTimeMeetName || <span>&nbsp;</span>}</div>
            </span>
          </div>
        </div>
      </div >
    </Fragment >
  )
}

const SmallDetailTable = ({ teamAthletes, relayTeam }) => {
  return (
    <div className={global.SmallDetailTable}>
      {Array.isArray(teamAthletes) && teamAthletes.length > 0 &&
        teamAthletes.map((athlete, i) =>
          <SmallDetailTableRow
            athlete={athlete}
            relayTeam={relayTeam}
            key={i}
            index={i}
          />)}
    </div>
  );
};

const OrgUnitAdminRelayEntriesTeamsSmallGrid = ({ gridData, isLoading, onEditRelayTeamClicked, onDeleteRelayTeamClicked, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(gridData.relayTeams) && gridData?.relayTeams?.length > 0
        ? gridData.relayTeams.map((relayTeam, i) => (
          <Fragment key={relayTeam.relayTeamName + 'Fragment'}>
            <div className={global.SmallTableRow} key={relayTeam.relayTeamName || i}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>{relayTeam.relayTeamName || <span>&nbsp;</span>}</span>&nbsp;
                {Array.isArray(relayTeam.teamAthletes) && relayTeam.teamAthletes.length > 0 &&
                  <button className={global.IconButton}
                    type="button"
                    onClick={(e) => onClick(e, relayTeam.relayTeamName)}>
                    {expandedId === relayTeam.relayTeamName ? <HideIcon /> : <ShowIcon />}
                  </button>}
                {onDeleteRelayTeamClicked && relayTeam.hasPaid === false && <button className={Array.isArray(relayTeam.teamAthletes) && relayTeam.teamAthletes.length > 0 ?
                  global.IconButtonMobileMargin : global.IconButton}
                  type="button"
                  onClick={(e) => onDeleteRelayTeamClicked(e, relayTeam)}>
                  <DeleteIcon />
                </button>}
                {onEditRelayTeamClicked && <button className={
                  global.IconButtonMobileMargin}
                  type="button"
                  onClick={(e) => onEditRelayTeamClicked(e, relayTeam)}>
                  <EditIcon />
                </button>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Team</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{relayTeam.relayTeamName || <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Entry Time</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.entryTime ? formatTimeForDisplay(relayTeam.entryTime) : 'NT'} {relayTeam.entryTime && relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Time Event</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.alternateEvent === true ? `ALT: ${relayTeam.qualifyingEventName || <span>&nbsp;</span>}` : relayTeam.nonConformingTime === true ? `NC: ${relayTeam.qualifyingEventName || <span>&nbsp;</span>}` : `${relayTeam.qualifyingEventName || <span>&nbsp;</span>}`}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Aggregate?</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.isAggregate === true ? 'Yes' : 'No'}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Override?</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam?.swimTimeEntryOverride === true ? 'Yes' : 'No'}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.status || <span>&nbsp;</span>}</div>
                </div>
              </div>
            </div>
            {expandedId === relayTeam.relayTeamName &&
              <SmallDetailTable teamAthletes={relayTeam.teamAthletes} relayTeam={relayTeam} />
            }
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Relay Teams</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminRelayEntriesTeamsSmallGrid;