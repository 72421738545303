import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { formatDateTime } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ teamEntry, teamEntryCurrentlyAllowed, onEdit, onDeleteTeamEntryClicked, onView }) => {
  return (
    <Fragment>
      <tr>
        <td>{teamEntry.orgUnitName || ''} {teamEntry.orgUnitName === 'Unattached' ? (teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '') : ''} {teamEntry?.parentOrgUnitCode ? '(' + teamEntry?.parentOrgUnitCode + ')' : ''}</td>
        <td>{teamEntry.status || 'Unknown'} {teamEntry.orgUnitSubmissionDatetime ? '- ' + formatDateTime(teamEntry.orgUnitSubmissionDatetime) + ' ' + teamEntry.timeZone : ''}</td>
        <td>
          {teamEntry.isReadOnly === true &&
            <ActionIntraPageButton onClick={(e) => onView(e, teamEntry)}>View</ActionIntraPageButton>}
          {(teamEntryCurrentlyAllowed === true || teamEntry.teamEntryChangesCurrentlyAllowed === true) &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onEdit(e, teamEntry)}>
              <EditIcon />
            </button>}
          {teamEntry.teamEntryDeleteCurrentlyAllowed === true &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onDeleteTeamEntryClicked(e, teamEntry)}>
              <DeleteIcon />
            </button>}
        </td>
      </tr>
    </Fragment >
  );
};

const MeetTeamEntryLargeGrid = ({ gridData, teamEntryCurrentlyAllowed, isLoading, onEdit, onDeleteTeamEntryClicked, onView }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Team Name</th>
          <th>Team Entry Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="3">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((teamEntry, i) =>
              <GridRow
                key={i}
                teamEntry={teamEntry}
                teamEntryCurrentlyAllowed={teamEntryCurrentlyAllowed}
                onEdit={onEdit}
                onDeleteTeamEntryClicked={onDeleteTeamEntryClicked}
                onView={onView} />)
            :
            <tr>
              <td colSpan={3}>No Team Entries</td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetTeamEntryLargeGrid;