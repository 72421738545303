import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminPurchasesSmallGrid = ({ gridData, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData?.length > 0
        ? gridData?.map((omeMeetPurchase, i) => (
          <div className={global.SmallTableRow} key={omeMeetPurchase.omeMeetPurchaseId || i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{omeMeetPurchase.omeFeeTypeName || <span>&nbsp;</span>}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Line Item</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{omeMeetPurchase.omeFeeTypeName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Line Item Cost</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{omeMeetPurchase.amount !== null && omeMeetPurchase.amount > 0 ? '$' + omeMeetPurchase.amount.toFixed(2) : <span>$0.00</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Quantity</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{omeMeetPurchase.quantity !== null && omeMeetPurchase.quantity >= 0 ? omeMeetPurchase.quantity : <span>0</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Total Cost</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{omeMeetPurchase.totalAmount !== null && omeMeetPurchase.totalAmount > 0 ? '$' + omeMeetPurchase.totalAmount.toFixed(2) : <span>$0.00</span>}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Purchases</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminPurchasesSmallGrid;