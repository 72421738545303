import MeetRecognitionData from './MeetRecognitionData';

const useMeetRecognitionData = () => {
  const getMeetRecognition = (meetId, viewState, setViewState) => {
    MeetRecognitionData.getMeetRecognition(meetId, viewState, setViewState);
  };

  const putMeetRecognition = (meetId, meetRecognitionArray, viewState, setViewState) => {
    MeetRecognitionData.putMeetRecognition(meetId, meetRecognitionArray, viewState, setViewState);
  };

  return {
    getMeetRecognition,
    putMeetRecognition
  }
}

export default useMeetRecognitionData;