import React, { createContext, useState } from 'react';

import OrgUnitAthleteEntryIndividualRosterEntryData from './OrgUnitAthleteEntryIndividualRosterEntryData';

export const OrgUnitAthleteEntryIndividualRosterEntryStateContext = createContext();

const OrgUnitAthleteEntryIndividualRosterEntryContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitAthleteEntryIndividualRosterEntryData.INITIAL_STATE);

  return (
    <OrgUnitAthleteEntryIndividualRosterEntryStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitAthleteEntryIndividualRosterEntryStateContext.Provider>
  );
};

export default OrgUnitAthleteEntryIndividualRosterEntryContextProvider;