import { useEffect } from 'react';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useSessionsDetail from '../../../../../common/components/meetSessions/UseSessionsDetail';

import { formatDateTimeToTime } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

const useOmeSessionsDetail = () => {
  const { meetState } = useMeetData();
  const { meetSessionsState } = useSessionsDetail();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();

  function meetSessionStateHasBeenSaved(meetSessionsState) {
    const meetCopy = JSON.parse(JSON.stringify(meetState.objData));
    const selectedMeetSession = meetCopy?.meetSession?.find(x => x.meetSessionId === meetSessionsState?.formState?.meetSessionId);

    if (meetSessionsState?.formState?.warmUpTime !== formatDateTimeToTime(selectedMeetSession?.warmUpTime)
      && (meetSessionsState?.formState?.warmUpTime !== Constants.BLANK_TIME_STRING || selectedMeetSession?.warmUpTime !== null)) {
      return false;
    }
    if (meetSessionsState?.formState?.startTime !== formatDateTimeToTime(selectedMeetSession?.startTime)
      && (meetSessionsState?.formState?.startTime !== Constants.BLANK_TIME_STRING || selectedMeetSession?.startTime !== null)) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (Object.keys(meetState.objData).length > 0) {
      const isSaved = meetSessionStateHasBeenSaved(meetSessionsState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetSessionsState, meetState.objData]);

  return {
    meetState
  };
};

export default useOmeSessionsDetail;