import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallDetailTableRow = ({ relayTeam }) => {
  return (
    <Fragment>
      <div className={global.SmallDetailTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>{relayTeam.relayTeamName || <span>&nbsp;</span>}</span>&nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Team</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{relayTeam.relayTeamName || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Entry Time</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.entryTime ? formatTimeForDisplay(relayTeam.entryTime) : 'NT'} {relayTeam.entryTime && relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Time Event</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.alternateEvent === true ? `ALT: ${relayTeam.qualifyingEventName || <span>&nbsp;</span>}` : relayTeam.nonConformingTime === true ? `NC: ${relayTeam.qualifyingEventName || <span>&nbsp;</span>}` : `${relayTeam.qualifyingEventName || <span>&nbsp;</span>}`}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Aggregate?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.isAggregate === true ? 'Yes' : 'No'}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Override?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam?.swimTimeEntryOverride === true ? 'Yes' : 'No'}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{relayTeam.status || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const SmallDetailTable = ({ event }) => {
  return (
    <div className={global.SmallDetailTable}>
      {Array.isArray(event.relayTeams) && event?.relayTeams.length > 0 &&
        event.relayTeams.map((relayTeam, i) =>
          <SmallDetailTableRow
            relayTeam={relayTeam}
            event={event}
            key={i}
          />)}
    </div>
  );
};

const OrgUnitAdminRelayEntriesSmallGrid = ({ gridData, isLoading, onEditEventClicked, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((event, i) => (
          <Fragment key={event.meetEventId + 'Fragment'}>
            <div className={global.SmallTableRow} key={event.meetEventId || i}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>{event.eventName || <span>&nbsp;</span>}</span>&nbsp;
                {Array.isArray(event.relayTeams) && event.relayTeams.length > 0 &&
                  <button className={global.IconButton}
                    type="button"
                    onClick={(e) => onClick(e, event.meetEventId)}>
                    {expandedId === event.meetEventId ? <HideIcon /> : <ShowIcon />}
                  </button>}
                {onEditEventClicked && <button className={Array.isArray(event.relayTeams) && event.relayTeams.length > 0 ? global.IconButtonMobileMargin : global.IconButton}
                  type="button"
                  onClick={(e) => onEditEventClicked(e, event)}>
                  <EditIcon />
                </button>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Event-Course</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{event.eventName || <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.eventCompetitionGenderTypeName || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age Group</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.ageGroupName || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Session</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.meetSessionName || <span>&nbsp;</span>}</div>
                </div>
              </div>
            </div>
            {expandedId === event.meetEventId &&
              <SmallDetailTable event={event} />
            }
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Relay Events</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminRelayEntriesSmallGrid;