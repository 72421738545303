import React, { Fragment } from 'react';

import AthleteUserPurchasesLargeGrid from './AthleteUserPurchasesLargeGrid';
import AthleteUserPurchasesSmallGrid from './AthleteUserPurchasesSmallGrid';

const AthleteUserPurchasesGrid = ({ gridData, isLoading }) => {
  return (
    <Fragment>
      <AthleteUserPurchasesLargeGrid
        gridData={gridData}
        isLoading={isLoading}
      />
      <AthleteUserPurchasesSmallGrid
        gridData={gridData}
        isLoading={isLoading}
      />
    </Fragment>
  )
};

export default AthleteUserPurchasesGrid;