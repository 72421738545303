import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import getInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import { eligibilityIsComplete, meetInfoIsComplete, restrictionsIsComplete } from './LeftNavValidation';

const getRestrictions = (viewName, route, omeMeetStateObjData, meetStateObjData, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = ((viewName === Views.OME_MEET_RESTRICTIONS) || (viewName === Views.OME_MEET_RESTRICTIONS_NON_HQ)) === true ? true : false;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission) === true;

  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission) === true;

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true;

  const isComplete = isMeetInfoComplete === true && isEligibilityComplete === true && restrictionsIsComplete(omeMeetStateObjData, restrictionsRoutePermission) === true;

  const item = route === '' ? getInitialStateOmeRoute(Views.OME_MEET_RESTRICTIONS) : getInitialStateMeetRoute(Views.OME_MEET_RESTRICTIONS);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getRestrictions;