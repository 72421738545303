const INITIAL_STATE = {
  tryRedirect: false,
  showFilters: true,
  showMeetHeader: true
};

const SearchMeetData = {
  INITIAL_STATE,
}

export default SearchMeetData;