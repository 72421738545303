/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import global from '../../../../../common/components/GlobalStyle.module.css';
import Input from '../../../../../common/components/inputs/Input';

const LargeGrid = ({ isLoading, gridData, errorState, onAdditionalPurchaseValueChanged }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Line Item</th>
        <th>Description</th>
        <th>Line Item Cost</th>
        <th>Quantity</th>
        <th>Total Cost</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (<Fragment>
          <tr>
            <td colSpan="5">Loading...</td>
          </tr>
        </Fragment>
        ) : Array.isArray(gridData) && gridData?.length > 0 ?
          gridData.map((purchaseItem, i) => (
            < tr key={purchaseItem.omeMeetPriceId || i} >
              <td>{purchaseItem.omeFeeTypeName || ''}</td>
              <td>{purchaseItem.omeMeetPriceDescription?.length > 50 ? purchaseItem.omeMeetPriceDescription.substring(0, 50) + '...' : purchaseItem.omeMeetPriceDescription || ''}</td>
              <td>{purchaseItem.amount !== null && purchaseItem.amount > 0 ? '$' + purchaseItem.amount.toFixed(2) : '$0.00'}</td>
              <td><Input
                label=""
                name={"quantity" + purchaseItem.omeMeetPriceId}
                type="number"
                min="0"
                max="1000000"
                step="1"
                value={purchaseItem.quantity || ''}
                error={errorState.quantity}
                message={errorState.quantity}
                onChange={(value) => { onAdditionalPurchaseValueChanged(purchaseItem, value) }} />
              </td>
              <td>{purchaseItem.totalAmount !== null && purchaseItem.totalAmount > 0 ? '$' + purchaseItem.totalAmount : '$0.00'}</td>
            </tr>)
          ) : (
            <tr>
              <td colSpan="5">No Additional Purchase Items</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ isLoading, gridData, errorState, onAdditionalPurchaseValueChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData?.length > 0 ?
        gridData.map((purchaseItem, i) => (
          <div className={global.SmallTableRow} key={purchaseItem.omeMeetPriceId || i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{purchaseItem.omeFeeTypeName || ''}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Line Item</div>
                <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem.omeFeeTypeName || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Description</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem.omeMeetPriceDescription?.length > 50 ? purchaseItem.omeMeetPriceDescription.substring(0, 50) + '...' : purchaseItem.omeMeetPriceDescription || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Line Item Cost</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem?.amount !== null && purchaseItem?.amount > 0 ? '$' + purchaseItem.amount.toFixed(2) : <span>$0.00</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Quantity</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  <Input
                    label=""
                    name={"quantity" + purchaseItem.omeMeetPriceId}
                    type="number"
                    min="0"
                    max="1000000"
                    step="1"
                    value={purchaseItem.quantity || ''}
                    error={errorState.quantity}
                    message={errorState.quantity}
                    onChange={(value) => { onAdditionalPurchaseValueChanged(purchaseItem, value) }} /></div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Total Cost</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{purchaseItem?.totalAmount !== null && purchaseItem?.totalAmount > 0 ? '$' + purchaseItem.totalAmount : '$0.00'}</div>
              </div>
            </div>
          </div>
        )
        )
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Additional Purchase Items</div>
            </div>
          </div>
        )}
  </div >
);

const OrgUnitAdminAdditionalPurchasesGrid = ({ gridData, isLoading, errorState, onAdditionalPurchaseValueChanged }) => (
  <Fragment>
    <LargeGrid gridData={gridData} isLoading={isLoading} errorState={errorState} onAdditionalPurchaseValueChanged={onAdditionalPurchaseValueChanged} />
    <SmallGrid gridData={gridData} isLoading={isLoading} errorState={errorState} onAdditionalPurchaseValueChanged={onAdditionalPurchaseValueChanged} />
  </Fragment>
);

export default OrgUnitAdminAdditionalPurchasesGrid;