import React, { Fragment } from 'react';

import useOrgUnitAdminLanding from './UseOrgUnitAdminLanding';

import MeetTeamEntryGrid from './MeetTeamEntryGrid'

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminLandingReadOnly = () => {
  const { omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryRelayRosterEntryState, omeMeetOrgUnitNonAthleteEntryState } = useOrgUnitAdminLanding();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Entry Status</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <MeetTeamEntryGrid
            gridData={omeMeetOrgUnitEntryState?.objData}
            isLoading={omeMeetOrgUnitEntryState?.isObjLoading}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitEntryState.isObjLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading
          || omeMeetOrgUnitNonAthleteEntryState.isArrayLoading} />
    </Fragment>
  );
};

export default OrgUnitAdminLandingReadOnly;