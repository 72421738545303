import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getSelectionMeetEventsData = (selectionMeetId, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/meetevents`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putSelectionMeetEventsData = (selectionMeetId, meetEventsArray, state, setState) => {
  if (selectionMeetId > 0 && meetEventsArray) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/meetevents`;
    return api?.executeArray ? api.executeArray(url, 'PUT', meetEventsArray) : null;
  }
};

const SelectionsMeetEventsData = {
  getSelectionMeetEventsData,
  putSelectionMeetEventsData
};

export default SelectionsMeetEventsData;