import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitEntryTimesData from './OmeMeetOrgUnitEntryTimesData';

export const OmeMeetOrgUnitEntryTimesStateContext = createContext();

const OmeMeetOrgUnitEntryTimesContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitEntryTimesData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitEntryTimesStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitEntryTimesStateContext.Provider>
  );
};

export default OmeMeetOrgUnitEntryTimesContextProvider;