import React from 'react';

import useAddMeetTimeEventCombobox from './UseAddMeetTimeEventCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';

const AddMeetTimeEventCombobox = ({ label, name, valueToMatch, error, message, onChange, isRelay }) => {
  const {
    optionsState
  } = useAddMeetTimeEventCombobox(isRelay)

  return (
    <Combobox
      label={label}
      name={name}
      isLoading={false}
      items={optionsState}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default AddMeetTimeEventCombobox;