import React, { createContext, useState } from 'react';

import SelectionsLeftNavData from './SelectionsLeftNavData';

export const SelectionsLeftNavStateContext = createContext();

const INITIAL_STATE = {
  isLoaded: false,
  arrayData: SelectionsLeftNavData.INITIAL_ARRAY_DATA,
  objData: SelectionsLeftNavData.INITIAL_OBJ_DATA
};

const SelectionsLeftNavContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsLeftNavStateContext.Provider value={stateHook}>
      {children}
    </SelectionsLeftNavStateContext.Provider>
  );
};

export default SelectionsLeftNavContextProvider;