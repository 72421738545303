import { useState, useEffect } from "react";

import validate from './LaneSheetTopHeaderNotesDetailFormValidation';

import useForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  nameToShow: '',
  recordListId: Constants.DEFAULT_ID,
  listName: '',
  displayOrder: Constants.DEFAULT_ID
};

const useLaneSheetTopHeaderNotesDetailForm = (onSubmitFormCallback, topHeaderDataLength) => {
  const { formState, errorState, handleSubmit, onFormValueChanged, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [displayOrderOptionsState, setDisplayOrderOptionsState] = useState([]);

  useEffect(() => {
    const displayOrderOptions = [];

    for (let i = 0; i < topHeaderDataLength + 1; i++) {
      displayOrderOptions.push({
        id: i + 1,
        value: i + 1,
        name: i + 1
      });
    }

    setDisplayOrderOptionsState(displayOrderOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topHeaderDataLength]);

  return {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    displayOrderOptionsState
  };
};

export default useLaneSheetTopHeaderNotesDetailForm;