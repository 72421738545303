import { useState, useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";
import { isParticipantSelectionsValid } from "../utils/SelectionsUtils";

import useSelectionsParticipantsData from "../../../state/selectionsParticipants/UseSelectionsParticipantsData";
import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsMeetEventsData from "../../../state/selectionsMeetEvents/UseSelectionsMeetEventsData";
import useParticipantGridDetailPopupData from "../../../state/selectionsParticipants/participantGridDetailPopup/UseParticipantGridDetailPopupData";
import useSelectionsLoadingModalData from "../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

import Constants from "../../../../common/utils/Constants";

const INITIAL_ALTERNATE_MODAL_STATE = {
  displayModal: false,
  selectionMeetPersonId: Constants.DEFAULT_ID,
  selectionMeetEventEntryId: Constants.DEFAULT_ID,
  firstName: '',
  lastName: '',
  relayTeamName: '',
  orgUnitName: ''
};

const INITIAL_STATE = {
  displayCompletionError: false,
  completionErrorMessage: 'Selected Swimmers cannot be 0 or greater than Max Swimmers'
};

const useSelectionsParticipantSelectionsPopup = () => {
  const navigate = useNavigate();
  const { selectionsState } = useSelectionsData();
  const { selectionsMeetEventsState } = useSelectionsMeetEventsData();
  const { selectionsParticipantsState, selectionGridOptionsState, postSelectionSelectAlternateRule,
    toggleShowSelectionGridPopup, toggleShowRelaysInAlternatesGrid, toggleShowDetailPopupsInGrid
  } = useSelectionsParticipantsData();
  const { onLeaveCell } = useParticipantGridDetailPopupData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [alternateModalState, setAlternateModalState] = useState(INITIAL_ALTERNATE_MODAL_STATE);
  const [state, setState] = useState(INITIAL_STATE);

  const onCompleteClicked = () => {
    const maxSwimmers = selectionsState.objData?.maxSwimmers;
    const selectedSwimmers = selectionsParticipantsState.objData?.selectedSwimmers;

    if (isParticipantSelectionsValid(selectedSwimmers, maxSwimmers)) {
      navigate(NavLinks.SELECTIONS_REPORTS);
    } else {
      setState({
        ...state,
        displayCompletionError: true
      });
    }
  };

  const onSelectAlternateClicked = (alternate) => {
    setAlternateModalState({
      ...alternateModalState,
      displayModal: true,
      selectionMeetPersonId: alternate.selectionMeetPersonId > 0 ? alternate.selectionMeetPersonId : Constants.DEFAULT_ID,
      selectionMeetEventEntryId: alternate.selectionMeetEventEntryId > 0 ? alternate.selectionMeetEventEntryId : Constants.DEFAULT_ID,
      firstName: alternate.firstName || '',
      lastName: alternate.lastName || '',
      relayTeamName: alternate.relayTeamName || '',
      orgUnitName: alternate.orgUnitName || ''
    });
  };

  const onAlternateModalConfirmClicked = () => {
    const selectionMeetId = selectionsState.objData?.selectionMeetId;
    const selectionMeetEventEntryId = alternateModalState.selectionMeetEventEntryId;

    if (selectionMeetId > 0 && selectionMeetEventEntryId > 0) {
      const postSelectionSelectAlternateRulePromise = postSelectionSelectAlternateRule(selectionMeetId, selectionMeetEventEntryId);

      if (postSelectionSelectAlternateRulePromise !== null) {
        postSelectionSelectAlternateRulePromise.catch((e) => {
          setContextStateError(true);
        });
      }
    }

    setAlternateModalState(INITIAL_ALTERNATE_MODAL_STATE);
  };

  const onAlternateModalCancelClicked = () => {
    setAlternateModalState(INITIAL_ALTERNATE_MODAL_STATE);
  };

  const onParticipantClicked = (participantObj) => {
    onLeaveCell();

    navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS_DETAIL, { state: { participantObj } });
  };

  const onCloseClicked = () => {
    toggleShowSelectionGridPopup(false);
  };

  const onToggleShowRelays = () => {
    const selectionMeetId = selectionsState.objData?.selectionMeetId;

    if (selectionMeetId > 0) {
      toggleShowRelaysInAlternatesGrid(selectionMeetId);
    }
  };

  useEffect(() => {
    // grid detail popup vertical position is based on page scroll, reset scroll here to keep positioning consistent
    if (selectionGridOptionsState.showSelectionGridPopup === true) {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [selectionGridOptionsState.showSelectionGridPopup]);

  return {
    state,
    selectionsState,
    selectionsMeetEventsState,
    selectionsParticipantsState,
    selectionGridOptionsState,
    alternateModalState,
    onToggleShowRelays,
    toggleShowDetailPopupsInGrid,
    onCompleteClicked,
    onCloseClicked,
    onSelectAlternateClicked,
    onAlternateModalConfirmClicked,
    onAlternateModalCancelClicked,
    onParticipantClicked
  };
};

export default useSelectionsParticipantSelectionsPopup;