import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const getLaneSheetTemplates = (state, setState) => {
  let newState = {
    ...state,
  };

  const api = initApi(API_NAMES.MEET, newState, setState);

  const url = `/LaneSheetTemplate`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const LaneSheetTemplateData = {
  getLaneSheetTemplates
};

export default LaneSheetTemplateData;