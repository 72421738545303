import { useContext } from 'react';

import { TimeStandardAgeGroupStateContext } from './TimeStandardAgeGroupContextProvider';

import TimeStandardAgeGroupData from './TimeStandardAgeGroupData';

const useTimeStandardAgeGroupData = () => {
  const [timeStandardAgeGroupState, setTimeStandardAgeGroupState] = useContext(TimeStandardAgeGroupStateContext);

  const getTimeStandardAgeGroupEventStandard = (timeStandardAgeGroupIds) => TimeStandardAgeGroupData.getTimeStandardAgeGroupEventStandardData(
    timeStandardAgeGroupIds, timeStandardAgeGroupState, setTimeStandardAgeGroupState);

  const clearTimeStandardAgeGroupArrayData = () => {
    setTimeStandardAgeGroupState({
      ...timeStandardAgeGroupState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  }

  return {
    timeStandardAgeGroupState,
    setTimeStandardAgeGroupState,
    getTimeStandardAgeGroupEventStandard,
    clearTimeStandardAgeGroupArrayData
  };
};

export default useTimeStandardAgeGroupData;