import React, { createContext, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const SelectionsTimeStandardStateContext = createContext();

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  items: [],
  areItemsLoaded: false,
  orgUnitId: '',
  omeMeetId: ''
};

const SelectionsTimeStandardContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsTimeStandardStateContext.Provider value={stateHook}>
      {children}
    </SelectionsTimeStandardStateContext.Provider>
  );
};

export default SelectionsTimeStandardContextProvider;