import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeMeetOrgUnitEntryData from './GetOmeMeetOrgUnitEntryData';
import getOmeMeetOrgUnitEntryByIdData from './GetOmeMeetOrgUnitEntryByIdData';
import postOmeMeetOrgUnitEntryData from './PostOmeMeetOrgUnitEntryData';
import putOmeMeetOrgUnitEntryData from './PutOmeMeetOrgUnitEntryData';
import deleteOmeMeetOrgUnitEntryData from './DeleteOmeMeetOrgUnitEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitEntryData = {
  INITIAL_STATE, getOmeMeetOrgUnitEntryData, getOmeMeetOrgUnitEntryByIdData, 
  postOmeMeetOrgUnitEntryData, putOmeMeetOrgUnitEntryData, deleteOmeMeetOrgUnitEntryData
};

export default OmeMeetOrgUnitEntryData;