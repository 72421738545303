import useEventCombobox from './UseEventCombobox';

import Combobox from '../Combobox';

import global from '../../GlobalStyle.module.css';

const EventCombobox = ({ label, name, valueToMatch, error, message, onChange }) => {
  const { eventState } = useEventCombobox();

  return eventState.message
    ? <span className={global.LoadingMsg}>{eventState.message}</span>
    :
    <Combobox
      label={label}
      name={name}
      isLoading={eventState.isArrayLoading}
      items={eventState.filteredItems ? eventState.filteredItems : eventState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default EventCombobox;