import React, { Fragment } from 'react';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeDetailTableRow = ({ timeStandard, showNoSlowerThan, showNoFasterThan, showBonus }) => {
  return (
    <Fragment>
      {showNoSlowerThan === true &&
        <tr key={timeStandard.meetAgeGroupTimeStandardId + 'NoSlowerTS'}>
          <td>No Slower Than</td>
          <td>{timeStandard.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard'}</td>
        </tr>}
      {showNoFasterThan === true &&
        <tr key={timeStandard.meetAgeGroupTimeStandardId + 'NoFasterTS'}>
          <td>No Faster Than</td>
          <td>{timeStandard.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard'}</td>
        </tr>}
      {showBonus === true &&
        <tr key={timeStandard.meetAgeGroupTimeStandardId + 'BonusTS'}>
          <td>Bonus</td>
          <td>{timeStandard.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard'}</td>
        </tr>}
    </Fragment>
  )
}

const LargeGridDetailTable = ({ meetAgeGroupTimeStandard, showNoSlowerThan, showNoFasterThan, showBonus }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th style={{ minWidth: '150px' }}>Time Standard Type</th>
        <th>Time Standard Name</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(meetAgeGroupTimeStandard) ? meetAgeGroupTimeStandard.map((timeStandard, i) =>
        <LargeDetailTableRow key={i} timeStandard={timeStandard} showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus} />) : <Fragment />}
    </tbody>
  </table>
);

const LargeGrid = ({ state, gridState, onEdit, showNoSlowerThan, showNoFasterThan, showBonus, expandedId, onClick }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Age Group Name</th>
        <th>Min Age</th>
        <th>Max Age</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true ?
              <td colSpan="4">Loading...</td>
              : <td colSpan="4">No Age Group Time Standards</td>
            }
          </tr>
        ) : Array.isArray(gridState.sortedGridData) && gridState.sortedGridData.length > 0
          ? gridState.sortedGridData.map(ageGroup => (
            <Fragment key={ageGroup.meetAgeGroupId + 'Fragment'} >
              <tr key={ageGroup.meetAgeGroupId}>
                <td>{ageGroup.ageGroupName}</td>
                <td>{ageGroup.minAge}</td>
                <td>{ageGroup.maxAge}</td>
                <td>{onEdit &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, ageGroup.meetAgeGroupId, ageGroup.meetAgeGroupTimeStandard.length > 0 ? ageGroup.meetAgeGroupTimeStandard[0].meetAgeGroupTimeStandardId : undefined)}>
                    <EditIcon />
                  </button>}
                  <button
                    className={global.IconButtonMargin}
                    type="button"
                    onClick={(e) => onClick(e, ageGroup.meetAgeGroupId)}>
                    {expandedId === ageGroup.meetAgeGroupId ? <HideIcon /> : <ShowIcon />}
                  </button>
                </td>
              </tr>
              {Array.isArray(ageGroup.meetAgeGroupTimeStandard) && ageGroup.meetAgeGroupTimeStandard.length > 0 && expandedId === ageGroup.meetAgeGroupId &&
                <tr className={global.Expanded}>
                  <td colSpan="4">
                    <LargeGridDetailTable meetAgeGroupTimeStandard={ageGroup.meetAgeGroupTimeStandard}
                      showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus} />
                  </td>
                </tr>}
            </Fragment>
          )) : (
            <tr>
              <td colSpan="4">No Age Group Time Standards</td>
            </tr>
          )
      }
    </tbody>
  </table >
);


const SmallDetailTableRow = ({ timeStandard, showNoSlowerThan, showNoFasterThan, showBonus }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>Time Standards</span>&nbsp;
    </div>
    <div className={global.SmallTableDetailRowInfo}>
      <div className='row'>
        {showNoSlowerThan === true &&
          <Fragment>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>No Slower Than</div>
            <div className={['col-xs-6 col-sm-9', global.SmallTableRowData].join(' ')}>{timeStandard.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || <span>No Time Standard</span>}</div>
          </Fragment>}
        {showNoFasterThan === true &&
          <Fragment>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>No Faster Than</div>
            <div className={['col-xs-6 col-sm-9', global.SmallTableRowData].join(' ')}>{timeStandard.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || <span>No Time Standard</span>}</div>
          </Fragment>}
        {showBonus === true &&
          <Fragment>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Bonus</div>
            <div className={['col-xs-6 col-sm-9', global.SmallTableRowData].join(' ')}>{timeStandard.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || <span>No Time Standard</span>}</div>
          </Fragment>}
      </div>
    </div>
  </div >
);

const SmallGridDetailTable = ({ meetAgeGroupTimeStandard, showNoSlowerThan, showNoFasterThan, showBonus }) => (
  <div className={global.SmallTableDetailTable}>
    {meetAgeGroupTimeStandard.map((timeStandard, i) =>
      <SmallDetailTableRow key={i} timeStandard={timeStandard} showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus} />)}
  </div>
);

const SmallGrid = ({ state, gridState, onEdit, showNoSlowerThan, showNoFasterThan, showBonus, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Age Group Time Standards</div>

            }
          </div>
        </div>
      ) : Array.isArray(gridState.sortedGridData) && gridState.sortedGridData.length > 0
        ? gridState.sortedGridData.map(ageGroup => (
          <Fragment key={ageGroup.meetAgeGroupId + 'Fragment'}>
            <div className={global.SmallTableRow} key={ageGroup.meetAgeGroupId}>
              <div className={global.SmallTableRowHead}>
                <span className='hidden-xs'>{ageGroup.ageGroupName}</span>&nbsp;
                <button className={global.IconButton}
                  type="button"
                  onClick={(e) => onClick(e, ageGroup.meetAgeGroupId)}>
                  {expandedId === ageGroup.meetAgeGroupId ? <HideIcon /> : <ShowIcon />}
                </button>
                {onEdit &&
                  <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, ageGroup.meetAgeGroupId, ageGroup.meetAgeGroupTimeStandard.length > 0 ? ageGroup.meetAgeGroupTimeStandard[0].meetAgeGroupTimeStandardId : undefined)}>
                    <EditIcon />
                  </button>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Age Group Name</div>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{ageGroup.ageGroupName}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Min Age</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.minAge}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Max Age</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.maxAge}</div>
                </div>
              </div>
            </div>
            {Array.isArray(ageGroup.meetAgeGroupTimeStandard) && ageGroup.meetAgeGroupTimeStandard.length > 0 && expandedId === ageGroup.meetAgeGroupId
              && <SmallGridDetailTable meetAgeGroupTimeStandard={ageGroup.meetAgeGroupTimeStandard}
                showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus} />}
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Age Group Time Standards</div>
            </div>
          </div>
        )
    }
  </div >
);

const OmeAgeGroupTimeStandardsGrid = ({ state, gridState, onEdit, showNoSlowerThan, showNoFasterThan, showBonus }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeGrid state={state} gridState={gridState} onEdit={onEdit} showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus}
        expandedId={expandedId} onClick={onClick} />
      <SmallGrid state={state} gridState={gridState} onEdit={onEdit} showNoSlowerThan={showNoSlowerThan} showNoFasterThan={showNoFasterThan} showBonus={showBonus}
        expandedId={expandedId} onClick={onClick} />
    </Fragment>
  )
}

export default OmeAgeGroupTimeStandardsGrid;