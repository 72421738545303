import { useState, useEffect } from 'react';
import useAthleteRaceStatsData from "../../state/athleteRaceStats/UseAthleteRaceStatsData";
import useRaceStatsCoachesData from "../../state/raceStatsCoaches/UseRaceStatsCoachesData";

import useEmbeddedReportData from '../../../common/state/embeddedReport/UseEmbeddedReportData';
import UseForm from '../../../common/utils/UseForm';
import Constants from '../../../common/utils/Constants';

import validate from './AthleteRaceStatsValidation';

const INITIAL_VIEW_STATE = {
  reportParameters: { personId: '' },
  orgUnitId: '',
  routeName: '',
  isLoading: false
};

const INITIAL_FORM_STATE = {
  personId: Constants.DEFAULT_ID,
  name: ''
}

const useAthleteRaceStats = () => {
  const { athleteRaceStatsState, getAthleteRaceStatsForCoach, clearArrayData } = useAthleteRaceStatsData();
  const { raceStatsCoachesState, getRaceStatsCoaches } = useRaceStatsCoachesData();
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { reportListState, getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const { formState, errorState, handleSubmit, onValueTextPairChanged } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback() {
    clearArrayData();
  }

  const onViewDashboard = (personId) => {
    setViewState({
      ...viewState,
      reportParameters: { personId: personId },
      routeName: 'MEMBER_RACE_STATS',
      isLoading: true,
      reportViewerType: 'Sisense'
    });
  }

  useEffect(() => {
    if (athleteRaceStatsState.isArrayLoaded !== true && athleteRaceStatsState.isArrayLoading !== true) {
      getAthleteRaceStatsForCoach(formState.personId);
    }
  }, [athleteRaceStatsState]);

  useEffect(() => {
    if (raceStatsCoachesState.isArrayLoaded !== true && raceStatsCoachesState.isArrayLoading !== true) {
      getRaceStatsCoaches();
    }
  }, []);

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => x.reportViewerType === viewState.reportViewerType);

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType]);

  return {
    raceStatsCoachesState,
    athleteRaceStatsState,
    viewState,
    setViewState,
    reportListState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onViewDashboard,
    INITIAL_VIEW_STATE
  }
}

export default useAthleteRaceStats;