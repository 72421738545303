import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postAthleteSecondaryRepresentationData from './PostAthleteSecondaryRepresentationData';
import deleteAthleteSecondaryRepresentationData from './DeleteAthleteSecondaryRepresentationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const AthleteSecondaryRepresentationData = {
  INITIAL_STATE, postAthleteSecondaryRepresentationData, deleteAthleteSecondaryRepresentationData
}

export default AthleteSecondaryRepresentationData;