import React from 'react';

import useTimeStandardTypeTreeView from './UseTimeStandardTypeTreeView';

import TreeView from '../../../../common/components/tree/TreeView';

import global from '../../../../common/components/GlobalStyle.module.css';

const TimeStandardTypeTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect, onChange }) => {
  const { timeStandardTypeState } = useTimeStandardTypeTreeView();

  return timeStandardTypeState.message
    ? <span className={global.LoadingMsg}>{timeStandardTypeState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={timeStandardTypeState.isArrayLoading}
      options={timeStandardTypeState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default TimeStandardTypeTreeView;