import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { MEET_ENTRIES_NAME } from "../components/leftNav/SelectionsLeftNavConstants";

import NavLinks from "../utils/NavLinks";

import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsLeftNavData from "../../../state/selectionsLeftNav/UseSelectionsLeftNavData";
import useSelectionsMeetEntriesData from "../../../state/selectionsMeetEntries/UseSelectionsMeetEntriesData";
import useSelectionsLoadingModalData from "../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useSelectionsMeetEntries = () => {
  const navigate = useNavigate();
  const { updateCurrentLeftNavData } = useSelectionsLeftNavData();
  const { selectionsState, putSelectionCompleteSetup, setIsConfigComplete } = useSelectionsData();
  const { selectionsMeetEntriesState, getSelectionMeetEntries } = useSelectionsMeetEntriesData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = () => {
    navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
  };

  const onSaveAndContinueClicked = () => {
    const selectionMeetId = selectionsState.objData?.selectionMeetId;

    if (selectionMeetId > 0) {
      updateCurrentLeftNavData(MEET_ENTRIES_NAME, true);

      const putSelectionCompleteSetupPromise = putSelectionCompleteSetup(selectionMeetId, state, setState)

      if (putSelectionCompleteSetupPromise !== null) {
        putSelectionCompleteSetupPromise.then((newState) => {
          if (newState !== null) {
            if (newState.objData?.result === true) {
              setIsConfigComplete(true);

              navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
            } else {
              setIsConfigComplete(false);

              updateCurrentLeftNavData(MEET_ENTRIES_NAME, false);
            }
          }
        }).catch((e) => {
          setIsConfigComplete(false);

          updateCurrentLeftNavData(MEET_ENTRIES_NAME, false);

          setContextStateError(true);
        });
      }
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.SELECTIONS_MEET_EVENTS);
  };

  useEffect(() => {
    const selectionMeetId = selectionsState.objData?.selectionMeetId;
    const isConfigComplete = selectionsState.objData?.isConfigComplete;

    if (selectionMeetId > 0) {
      const getSelectionMeetEntriesPromise = getSelectionMeetEntries(selectionMeetId);

      if (getSelectionMeetEntriesPromise !== null) {
        getSelectionMeetEntriesPromise.then((newState) => {
          if (newState !== null) {
            if (isConfigComplete === true) {
              updateCurrentLeftNavData(MEET_ENTRIES_NAME, true);
            }
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: selectionsMeetEntriesState.isArrayLoading || state.isObjLoading,
    gridData: selectionsMeetEntriesState.arrayData || [],
    isReadOnly: selectionsState.objData?.isConfigComplete,
    onContinueClicked,
    onSaveAndContinueClicked,
    onBackClicked
  };
};

export default useSelectionsMeetEntries