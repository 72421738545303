import { Fragment } from 'react';

import LaneSheetHeatAthleteFormGridLarge from './LaneSheetHeatAthleteFormGridLarge';

const LaneSheetHeatAthleteFormGrid = ({ numberOfLanes, startingLaneNumber, formState, options, onValueTextPairChanged, onEditAthlete={onEditAthlete} }) => (
  <Fragment>
    <LaneSheetHeatAthleteFormGridLarge
      numberOfLanes={numberOfLanes}
      startingLaneNumber={startingLaneNumber}
      formState={formState}
      options={options}
      onValueTextPairChanged={onValueTextPairChanged}
      onEditAthlete={onEditAthlete} />
  </Fragment>
);

export default LaneSheetHeatAthleteFormGrid;