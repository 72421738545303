import React, { Fragment } from 'react';

import useOmePricing from './UseOmePricing';

import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';
import Views from '../HostRegistrationViews';

import OmePricingAdditionalPurchasesGrid from './OmePricingAdditionalPurchasesGrid';

import CurrencyInput from '../../../../../common/components/inputs/CurrencyInput';
import ToolTip from '../../../../../common/components/tooltips/ToolTip';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OmePricing = () => {
  const {
    state,
    modalState,
    omeMeetState,
    errorState,
    formState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddAdditionalPurchaseItem,
    onEditAdditionalPurchaseItem,
    onDeleteAdditionalPurchaseItem,
    onDeleteAdditionalPurchaseItemClicked,
    onModalCanceled,
    allowEdits
  } = useOmePricing();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_PRICING}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={errorState.error}>
        {allowEdits() === true ?
          <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row usas-extra-bottom-margin'>
                <div className='col-xs-12 col-sm-6'>
                  <CurrencyInput
                    label="Cost Per Team*"
                    name="costPerTeam"
                    value={formState.costPerTeam}
                    error={errorState.costPerTeam}
                    message={errorState.costPerTeam}
                    onChange={(value) => { onFormValueChanged("costPerTeam", value); }}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <CurrencyInput
                    label={<Fragment>Cost Per Credential* <span><ToolTip label="Cost For Team Support Members: Coaches, Massage Therapists, Sports Medicine, etc."></ToolTip></span></Fragment>}
                    name="costPerCredential"
                    value={formState.costPerCredential}
                    error={errorState.costPerCredential}
                    message={errorState.costPerCredential}
                    onChange={(value) => { onFormValueChanged("costPerCredential", value); }}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <CurrencyInput
                    label="Cost Per Individual Athlete*"
                    name="costPerIndividualAthlete"
                    value={formState.costPerIndividualAthlete}
                    error={errorState.costPerIndividualAthlete}
                    message={errorState.costPerIndividualAthlete}
                    onChange={(value) => { onFormValueChanged("costPerIndividualAthlete", value); }}
                  />
                </div>
                <div className={formState.showCostPerIndividualInternationalAthlete === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <CurrencyInput
                      label="Cost Per Individual International Athlete*"
                      name="costPerIndividualInternationalAthlete"
                      value={formState.costPerIndividualInternationalAthlete}
                      error={errorState.costPerIndividualInternationalAthlete}
                      message={errorState.costPerIndividualInternationalAthlete}
                      onChange={(value) => { onFormValueChanged("costPerIndividualInternationalAthlete", value); }}
                    />
                  </div>
                </div>
                <div className={formState.showCostPerInternationalStaff === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <CurrencyInput
                      label="Cost Per International Staff*"
                      name="costPerInternationalStaff"
                      value={formState.costPerInternationalStaff}
                      error={errorState.costPerInternationalStaff}
                      message={errorState.costPerInternationalStaff}
                      onChange={(value) => { onFormValueChanged("costPerInternationalStaff", value); }}
                    />
                  </div>
                </div>
                <div className={formState.showCostPerRelayOnlySwimmer === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <CurrencyInput
                      label="Cost Per Relay Only Swimmer*"
                      name="costPerRelayOnlySwimmer"
                      value={formState.costPerRelayOnlySwimmer}
                      error={errorState.costPerRelayOnlySwimmer}
                      message={errorState.costPerRelayOnlySwimmer}
                      onChange={(value) => { onFormValueChanged("costPerRelayOnlySwimmer", value); }}
                    />
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <CurrencyInput
                    label="Cost Per Athlete Individual Event Entry*"
                    name="costPerAthleteIndividualEventEntry"
                    value={formState.costPerAthleteIndividualEventEntry}
                    error={errorState.costPerAthleteIndividualEventEntry}
                    message={errorState.costPerAthleteIndividualEventEntry}
                    onChange={(value) => { onFormValueChanged("costPerAthleteIndividualEventEntry", value); }}
                  />
                </div>
                <div className={formState.showCostPerAthleteIndividualBonusEventEntry === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <CurrencyInput
                      label="Cost Per Athlete Individual Bonus Event Entry*"
                      name="costPerAthleteIndividualBonusEventEntry"
                      value={formState.costPerAthleteIndividualBonusEventEntry}
                      error={errorState.costPerAthleteIndividualBonusEventEntry}
                      message={errorState.costPerAthleteIndividualBonusEventEntry}
                      onChange={(value) => { onFormValueChanged("costPerAthleteIndividualBonusEventEntry", value); }}
                    />
                  </div>
                </div>
                <div className={formState.showCostPerRelayEventEntry === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <CurrencyInput
                      label="Cost Per Relay Event Entry*"
                      name="costPerRelayEventEntry"
                      value={formState.costPerRelayEventEntry}
                      error={errorState.costPerRelayEventEntry}
                      message={errorState.costPerRelayEventEntry}
                      onChange={(value) => { onFormValueChanged("costPerRelayEventEntry", value); }}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className='row'>
              <div className="col-xs-12 usas-extra-top-margin">
                <ActionIntraPageButton type="button" onClick={onAddAdditionalPurchaseItem}>Add Additional Purchase Item</ActionIntraPageButton>
              </div>
              <div className="col-xs-12">
                <OmePricingAdditionalPurchasesGrid
                  omeMeetState={omeMeetState}
                  gridState={state}
                  onEdit={onEditAdditionalPurchaseItem}
                  onDelete={onDeleteAdditionalPurchaseItemClicked}
                />
              </div>
            </div>
          </Fragment>
          : <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <ReadOnly
                    label="Cost Per Team"
                    name="costPerTeam"
                    value={`$${formState.costPerTeam !== '' && formState.costPerTeam >= 0 ? formState.costPerTeam.toFixed(2) : ''}`}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <ReadOnly
                    label={<Fragment>Cost Per Credential <span><ToolTip label="Cost For Team Support Members: Coaches, Massage Therapists, Sports Medicine, etc."></ToolTip></span></Fragment>}
                    name="costPerCredential"
                    value={`$${formState.costPerCredential !== '' && formState.costPerCredential >= 0 ? formState.costPerCredential.toFixed(2) : ''}`}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <ReadOnly
                    label="Cost Per Individual Athlete"
                    name="costPerIndividualAthlete"
                    value={`$${formState.costPerIndividualAthlete !== '' && formState.costPerIndividualAthlete >= 0 ? formState.costPerIndividualAthlete.toFixed(2) : ''}`}
                  />
                </div>
                <div className={formState.showCostPerIndividualInternationalAthlete === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label="Cost Per Individual International Athlete"
                      name="costPerIndividualInternationalAthlete"
                      value={`$${formState.costPerIndividualInternationalAthlete !== '' && formState.costPerIndividualInternationalAthlete >= 0 ? formState.costPerIndividualInternationalAthlete.toFixed(2) : ''}`}
                    />
                  </div>
                </div>
                <div className={formState.showCostPerInternationalStaff === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label="Cost Per International Staff"
                      name="costPerInternationalStaff"
                      value={`$${formState.costPerInternationalStaff !== '' && formState.costPerInternationalStaff >= 0 ? formState.costPerInternationalStaff.toFixed(2) : ''}`}
                    />
                  </div>
                </div>
                <div className={formState.showCostPerRelayOnlySwimmer === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label="Cost Per Relay Only Swimmer"
                      name="costPerRelayOnlySwimmer"
                      value={`$${formState.costPerRelayOnlySwimmer !== '' && formState.costPerRelayOnlySwimmer >= 0 ? formState.costPerRelayOnlySwimmer.toFixed(2) : ''}`}
                    />
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <ReadOnly
                    label="Cost Per Athlete Individual Event Entry*"
                    name="costPerAthleteIndividualEventEntry"
                    value={`$${formState.costPerAthleteIndividualEventEntry !== '' && formState.costPerAthleteIndividualEventEntry >= 0 ? formState.costPerAthleteIndividualEventEntry.toFixed(2) : ''}`}
                  />
                </div>
                <div className={formState.showCostPerAthleteIndividualBonusEventEntry === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label="Cost Per Athlete Individual Bonus Event Entry"
                      name="costPerAthleteIndividualBonusEventEntry"
                      value={`$${formState.costPerAthleteIndividualBonusEventEntry !== '' && formState.costPerAthleteIndividualBonusEventEntry >= 0 ? formState.costPerAthleteIndividualBonusEventEntry.toFixed(2) : ''}`}
                    />
                  </div>
                </div>
                <div className={formState.showCostPerRelayEventEntry === true ? global.DisplayComponent : global.HideComponent}>
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label="Cost Per Relay Event Entry"
                      name="costPerRelayEventEntry"
                      value={`$${formState.costPerRelayEventEntry !== '' && formState.costPerRelayEventEntry >= 0 ? formState.costPerRelayEventEntry.toFixed(2) : ''}`}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className='row'>
              <div className='col-xs-12 usas-extra-top-margin'>
                <Headings.H6>Additional Purchase Items</Headings.H6>
              </div>
              <div className="col-xs-12">
                <OmePricingAdditionalPurchasesGrid
                  omeMeetState={omeMeetState}
                  gridState={state}
                />
              </div>
            </div>
          </Fragment>}
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Fee Type:</b>&nbsp;{modalState.omeFeeTypeName}</p>
              <p><b>Cost:</b>&nbsp;{modalState.amount}</p>
              <p><b>Description:</b>&nbsp;{modalState.description}</p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onDeleteAdditionalPurchaseItem(e)}>Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving}
        />
      </ViewTemplate >
    </Fragment >
  );
};

export default OmePricing;