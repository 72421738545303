
import React, { Fragment } from 'react';

import useHQOrgUnitSelection from './UseTeamEntryHQOrgUnitSelection';

import OrgUnitOmeMeetTreeView from '../../../../components/tree/orgUnitOmeMeetTree/OrgUnitOmeMeetTreeView';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

const TeamEntryHQOrgUnitSelection = () => {
    const { handleSubmit, formState, errorState, onFormValueChanged,
        omeMeetState, omeMeetOrgUnitEntryState, orgUnitOmeMeetState, onBackClicked
    } = useHQOrgUnitSelection();
    return (
        <Fragment>
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Headings.H3>Enter Meet</Headings.H3>
                    </div>
                </div>
                <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                    <div className="col-xs-12">
                        <Headings.H6>Make a Selection</Headings.H6>
                    </div>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <OrgUnitOmeMeetTreeView
                                label="Team Options*"
                                name="orgUnit"
                                value={formState.orgUnit}
                                error={errorState.orgUnit}
                                message={errorState.orgUnit}
                                selectableLeavesOnly={true}
                                singleSelect={true}
                                onChange={(newValue) => { onFormValueChanged('orgUnit', newValue) }}
                                omeMeetId={omeMeetState.objData?.omeMeetId}
                            />
                        </div>
                    </div>
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
                            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
                        </div>
                    </div>
                </form>
            </div>
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={omeMeetOrgUnitEntryState.isSaving} />
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={(omeMeetState.isArrayLoading && omeMeetOrgUnitEntryState.isSaving === false)
                    || orgUnitOmeMeetState.isArrayLoading} />
        </Fragment>
    );
};

export default TeamEntryHQOrgUnitSelection;