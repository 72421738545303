import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OrgUnitAdminRelayEntriesDetailValidation';

import EntryNavLinks from '../../EntryNavLinks';
import NavLinks from '../../../meet/NavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryRelayRosterEntry/UseOmeMeetOrgUnitAthleteEntryRelayRosterEntryData';
import useRelayAthleteMeetEventData from '../../../../state/relayAthleteMeetEvent/UseRelayAthleteMeetEventData';
import useRelayAthleteEntryTimeData from '../../../../state/relayAthleteEntryTime/UseRelayAthleteEntryTimeData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData from '../../../../state/omeMeetOrgUnitAthleteEntryAggregateRelayAthlete/UseOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData';
import useEventMeetEventData from '../../../../state/eventMeetEvent/UseEventMeetEventData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { convertTimeToMilliseconds, formatMillisecondsToTime, formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const MSG = 'Please select 4 athletes with entry times to calculate the Team Entry Time';

const INITIAL_FORM_STATE = {
    isAggregate: false,
    relayTeamId: Constants.DEFAULT_ID,
    relayTeamName: '',
    athlete1PersonId: Constants.DEFAULT_ID,
    athlete1Name: '',
    athlete2PersonId: Constants.DEFAULT_ID,
    athlete2Name: '',
    athlete3PersonId: Constants.DEFAULT_ID,
    athlete3Name: '',
    athlete4PersonId: Constants.DEFAULT_ID,
    athlete4Name: '',
    eventId: Constants.DEFAULT_ID,
    eventName: '',
    courseCode: '',
    qualifyingStartDate: Constants.BLANK_DATE_STRING,
    qualifyingEndDate: Constants.BLANK_DATE_STRING,
    timeCuts: [],
    hasTimeStandards: false
};

const relayTeamOptions = [
    { id: Constants.DEFAULT_ID, name: "--", value: '' },
    { id: 1, name: "A", value: 1 },
    { id: 2, name: "B", value: 2 },
    { id: 3, name: "C", value: 3 },
    { id: 4, name: "D", value: 4 },
    { id: 5, name: "E", value: 5 }];

const useOrgUnitAdminRelayEntriesDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { omeMeetState } = useOmeMeetData();
    const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
    const { omeMeetOrgUnitAthleteEntryRelayRosterEntryState, getOrgUnitAthleteEntryRelayQualifyingTeam, postRelayMeetEventEntry }
        = useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData();
    const { relayAthleteMeetEventState } = useRelayAthleteMeetEventData();
    const { relayAthleteEntryTimeState, getRelayAthleteEntryTime } = useRelayAthleteEntryTimeData();
    const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
    const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
        getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
        = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
    const { omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState, getOrgUnitAthleteEntryAggregateRelayAthlete } = useOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData();
    const { eventMeetEventState } = useEventMeetEventData();
    const { environmentVariableState, MAX_RELAY_TEAM_ENTRIES_PER_EVENT } = useEnvironmentVariableData();
    const { formState, errorState, onFormValueChanged, onValueTextPairChanged, handleSubmit, setIsDirty, setFormData, setErrors }
        = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);
    const [state, setState] = useState({
        tryGet: false,
        tryRedirect: false, tryForceAggregateValidate: false, event: {}, relayTeam: {}, selectedRelayRosterEntryObj: {},
        trySetAthlete1Info: false, trySetAthlete2Info: false, trySetAthlete3Info: false, trySetAthlete4Info: false,
        athlete1OrgUnitAthleteEntryId: '', athlete2OrgUnitAthleteEntryId: '', athlete3OrgUnitAthleteEntryId: '', athlete4OrgUnitAthleteEntryId: '',
        athlete1PersonId: '', athlete2PersonId: '', athlete3PersonId: '', athlete4PersonId: ''
    });
    const [handleEventChange, setHandleEventChange] = useState(false);
    const [gridState, setGridState] = useState({ gridData: { athletes: [{}, {}, {}, {}] } });
    const [relayTeamOptionsState, setRelayTeamOptionsState] = useState({ options: relayTeamOptions });
    const [calculatedTeamEntryTimeState, setCalculatedTeamEntryTimeState] = useState({ entryTime: MSG, entryTimeFormattedForDatabase: '' });
    const [isLarge, setIsLarge] = useState(window.innerWidth > 991);

    const onAddOverrideRelayClicked = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (omeMeetState.route === '') {
            //OME Route
            navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES_DETAIL_OVERRIDE, { state: { event: state.event } });
        }
        else {
            //Meet Route
            navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES_DETAIL_OVERRIDE, { state: { event: state.event } });
        }
    };

    const onSaveClicked = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        setIsDirty(true);
        handleSubmit();
    };

    const onBackClicked = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (omeMeetState.route === '') {
            //OME Route
            navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES_TEAMS, { state: { event: state.event } });
        }
        else {
            //Meet Route
            navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES_TEAMS, { state: { event: state.event } });
        }
    };

    const updateMedia = () => {
        setIsLarge(window.innerWidth > 991);
    };

    useEffect(() => {
        //deal with combobox option issue by setting a state to use on a
        // ternary for the large and small selection grids
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(() => {
        if (location.state && location.state.event && environmentVariableState.isLoaded === true &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoaded === true) {

            const selectedRelayRosterEntry = omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData.find(x =>
                x.meetEventId === location.state.event.meetEventId);
            setState({
                ...state,
                event: location.state.event,
                relayTeam: location.state.relayTeam || {},
                selectedRelayRosterEntryObj: selectedRelayRosterEntry || {}
            });
            setFormData({
                ...formState,
                courseCode: location.state.relayTeam ? location.state.relayTeam?.qualifyingEventName?.substring(location.state.relayTeam?.qualifyingEventName?.length - 3) || '' : formState.eventId !== Constants.DEFAULT_ID ? eventMeetEventState.arrayData?.find(x => x.eventId === formState.eventId)?.eventName?.substring(eventMeetEventState.arrayData?.find(x => x.eventId === formState.eventId)?.eventName?.length - 3) || '' : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.qualifyingCourseCode || '',
                isAggregate: (location.state.relayTeam && location.state.relayTeam.isAggregate) ?? false,
                relayTeamId: (location.state.relayTeam && location.state.relayTeam.relayTeamName) ? relayTeamOptions.find(x => x.name === location.state.relayTeam.relayTeamName)?.id || Constants.DEFAULT_ID : Constants.DEFAULT_ID,
                relayTeamName: (location.state.relayTeam && location.state.relayTeam.relayTeamName) ? location.state.relayTeam.relayTeamName : '',
                timeCuts: location.state.event?.timeCuts || [],
                hasTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards ?? false,
                qualifyingStartDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate ? formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate) : Constants.BLANK_DATE_STRING,
                qualifyingEndDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate ? formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate) : Constants.BLANK_DATE_STRING,
            });

            let filteredRelayTeamOptions = relayTeamOptions;
            if (selectedRelayRosterEntry?.relayTeams) {
                for (let i = 0; i < selectedRelayRosterEntry.relayTeams.length; i++) {
                    const selectedRelayTeamOptionIndex = relayTeamOptions.findIndex(x => x.name === selectedRelayRosterEntry.relayTeams[i].relayTeamName);
                    if (selectedRelayTeamOptionIndex !== -1) {
                        filteredRelayTeamOptions = filteredRelayTeamOptions.filter(x => x.name !== selectedRelayRosterEntry.relayTeams[i].relayTeamName);
                    }
                }
            }

            let filteredRelayTeamOptionsBasedOnMaxRelayTeamEntriesPerEvent = [];
            const numOfExistingTeamEntries = selectedRelayRosterEntry?.relayTeams?.length || 0;
            if (omeMeetState.objData.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent) {
                const endIndex = ToIntIfInt(omeMeetState.objData.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent) + 1 - numOfExistingTeamEntries;
                filteredRelayTeamOptionsBasedOnMaxRelayTeamEntriesPerEvent = filteredRelayTeamOptions.slice(0, endIndex);
            } else if (MAX_RELAY_TEAM_ENTRIES_PER_EVENT) {
                const endIndex = ToIntIfInt(MAX_RELAY_TEAM_ENTRIES_PER_EVENT) + 1 - numOfExistingTeamEntries;
                filteredRelayTeamOptionsBasedOnMaxRelayTeamEntriesPerEvent = filteredRelayTeamOptions.slice(0, endIndex);
            }

            setRelayTeamOptionsState({ ...relayTeamOptionsState, options: filteredRelayTeamOptionsBasedOnMaxRelayTeamEntriesPerEvent });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, environmentVariableState])

    useEffect(() => {
        //Add view only
        if (state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0 &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isObjLoading === false &&
            (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isObjLoaded === false
                && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.message === '')) {
            getOrgUnitAthleteEntryRelayQualifyingTeam(omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId, state.event.meetEventId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.event, state.relayTeam, omeMeetOrgUnitEntryState.objData, omeMeetOrgUnitAthleteEntryRelayRosterEntryState])

    useEffect(() => {
        //Edit view only
        if (state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length > 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
            if (state.relayTeam.teamAthletes?.length > 0) {
                updatedGridData.athletes[0] = state.relayTeam?.teamAthletes[0] || {};
                updatedGridData.athletes[1] = state.relayTeam?.teamAthletes[1] || {};
                updatedGridData.athletes[2] = state.relayTeam?.teamAthletes[2] || {};
                updatedGridData.athletes[3] = state.relayTeam?.teamAthletes[3] || {};
            }
            setGridState({ ...gridState, gridData: updatedGridData });
            setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: state.relayTeam.entryTime ? formatTimeForDisplay(state.relayTeam.entryTime) : '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.event, state.relayTeam, relayAthleteMeetEventState])

    useEffect(() => {
        //Add view only
        //Clear out any athlete info if the user changes the event
        if (handleEventChange === true && location.state && location.state.event &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoaded === true) {
            const selectedRelayRosterEntry = omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData.find(x =>
                x.meetEventId === location.state.event.meetEventId);
            setState({
                tryGet: false,
                tryRedirect: false, tryForceAggregateValidate: false,
                event: location.state.event,
                relayTeam: location.state.relayTeam || {},
                selectedRelayRosterEntryObj: selectedRelayRosterEntry || {},
                trySetAthlete1Info: false, trySetAthlete2Info: false, trySetAthlete3Info: false, trySetAthlete4Info: false,
                athlete1OrgUnitAthleteEntryId: '', athlete2OrgUnitAthleteEntryId: '', athlete3OrgUnitAthleteEntryId: '', athlete4OrgUnitAthleteEntryId: '',
                athlete1PersonId: '', athlete2PersonId: '', athlete3PersonId: '', athlete4PersonId: ''
            });
            setFormData({
                ...formState,
                courseCode: eventMeetEventState.arrayData?.find(x => x.eventId === formState.eventId)?.eventName?.substring(eventMeetEventState.arrayData?.find(x => x.eventId === formState.eventId)?.eventName?.length - 3) || '',
                athlete1PersonId: Constants.DEFAULT_ID,
                athlete1Name: '',
                athlete2PersonId: Constants.DEFAULT_ID,
                athlete2Name: '',
                athlete3PersonId: Constants.DEFAULT_ID,
                athlete3Name: '',
                athlete4PersonId: Constants.DEFAULT_ID,
                athlete4Name: '',
            });
            setGridState({ gridData: { athletes: [{}, {}, {}, {}] } });
            setHandleEventChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleEventChange, omeMeetOrgUnitAthleteEntryRelayRosterEntryState])

    useEffect(() => {
        //Add view only, get Entry Time for leg 1
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            const selectedPersonId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete1PersonId)?.personId || Constants.DEFAULT_ID;
            const selectedOrgUnitAthleteEntryId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete1PersonId)?.orgUnitAthleteEntryId || Constants.DEFAULT_ID;
            if (selectedPersonId !== Constants.DEFAULT_ID) {
                getRelayAthleteEntryTime(selectedPersonId, state.event.meetEventId, 1, formState.eventId);
                setState({ ...state, trySetAthlete1Info: true, athlete1OrgUnitAthleteEntryId: selectedOrgUnitAthleteEntryId, athlete1PersonId: selectedPersonId, trySetAthlete2Info: false, trySetAthlete3Info: false, trySetAthlete4Info: false });
            }
            else {
                updatedGridData.athletes[0] = {};
                setGridState({ ...gridState, gridData: updatedGridData });
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete1PersonId, relayAthleteMeetEventState])

    useEffect(() => {
        //Add view only, update Grid State for leg 1
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true
            && state.trySetAthlete1Info === true && relayAthleteEntryTimeState.isObjLoaded === true && relayAthleteEntryTimeState.legNumber === 1 &&
            relayAthleteEntryTimeState.personId === state.athlete1PersonId &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            if (formState.athlete1PersonId !== Constants.DEFAULT_ID) {
                updatedGridData.athletes[0] = {
                    ...relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete1PersonId),
                    entryTime: relayAthleteEntryTimeState.objData.entryTime,
                    swimTimeId: relayAthleteEntryTimeState.objData.swimTimeId,
                    hasAthleteExceedsTimeStandardsRelayRule: relayAthleteEntryTimeState.objData.hasAthleteExceedsTimeStandardsRelayRule
                };
                tryToCalculateTeamEntryTime(updatedGridData.athletes);
            }
            else {
                updatedGridData.athletes[0] = {};
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
            setGridState({ ...gridState, gridData: updatedGridData });
            setState({ ...state, trySetAthlete1Info: false, athlete1PersonId: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete1PersonId, relayAthleteMeetEventState, relayAthleteEntryTimeState, state.trySetAthlete1Info, state.athlete1PersonId])

    useEffect(() => {
        //Add view only, get Entry Time for leg 2
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            const selectedPersonId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete2PersonId)?.personId || Constants.DEFAULT_ID;
            const selectedOrgUnitAthleteEntryId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete2PersonId)?.orgUnitAthleteEntryId || Constants.DEFAULT_ID;
            if (selectedPersonId !== Constants.DEFAULT_ID) {
                getRelayAthleteEntryTime(selectedPersonId, state.event.meetEventId, 2, formState.eventId);
                setState({ ...state, trySetAthlete1Info: false, trySetAthlete2Info: true, athlete2OrgUnitAthleteEntryId: selectedOrgUnitAthleteEntryId, athlete2PersonId: selectedPersonId, trySetAthlete3Info: false, trySetAthlete4Info: false });
            }
            else {
                updatedGridData.athletes[1] = {};
                setGridState({ ...gridState, gridData: updatedGridData });
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete2PersonId, relayAthleteMeetEventState])

    useEffect(() => {
        //Add view only, update Grid State for leg 2
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true
            && state.trySetAthlete2Info === true && relayAthleteEntryTimeState.isObjLoaded === true && relayAthleteEntryTimeState.legNumber === 2 &&
            relayAthleteEntryTimeState.personId === state.athlete2PersonId &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            if (formState.athlete2PersonId !== Constants.DEFAULT_ID) {
                updatedGridData.athletes[1] = {
                    ...relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete2PersonId),
                    entryTime: relayAthleteEntryTimeState.objData.entryTime,
                    swimTimeId: relayAthleteEntryTimeState.objData.swimTimeId,
                    hasAthleteExceedsTimeStandardsRelayRule: relayAthleteEntryTimeState.objData.hasAthleteExceedsTimeStandardsRelayRule
                };
                tryToCalculateTeamEntryTime(updatedGridData.athletes);
            }
            else {
                updatedGridData.athletes[1] = {};
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
            setGridState({ ...gridState, gridData: updatedGridData });
            setState({ ...state, trySetAthlete2Info: false, athlete2PersonId: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete2PersonId, relayAthleteMeetEventState, relayAthleteEntryTimeState, state.trySetAthlete2Info, state.athlete2PersonId])

    useEffect(() => {
        //Add view only, get Entry Time for leg 3
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            const selectedPersonId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete3PersonId)?.personId || Constants.DEFAULT_ID;
            const selectedOrgUnitAthleteEntryId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete3PersonId)?.personId || Constants.DEFAULT_ID;
            if (selectedPersonId !== Constants.DEFAULT_ID) {
                getRelayAthleteEntryTime(selectedPersonId, state.event.meetEventId, 3, formState.eventId);
                setState({ ...state, trySetAthlete1Info: false, trySetAthlete2Info: false, trySetAthlete3Info: true, athlete3OrgUnitAthleteEntryId: selectedOrgUnitAthleteEntryId, athlete3PersonId: selectedPersonId, trySetAthlete4Info: false });
            }
            else {
                updatedGridData.athletes[2] = {};
                setGridState({ ...gridState, gridData: updatedGridData });
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete3PersonId, relayAthleteMeetEventState])

    useEffect(() => {
        //Add view only, update Grid State for leg 3
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true
            && state.trySetAthlete3Info === true && relayAthleteEntryTimeState.isObjLoaded === true && relayAthleteEntryTimeState.legNumber === 3 &&
            relayAthleteEntryTimeState.personId === state.athlete3PersonId &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            if (formState.athlete3PersonId !== Constants.DEFAULT_ID) {
                updatedGridData.athletes[2] = {
                    ...relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete3PersonId),
                    entryTime: relayAthleteEntryTimeState.objData.entryTime,
                    swimTimeId: relayAthleteEntryTimeState.objData.swimTimeId,
                    hasAthleteExceedsTimeStandardsRelayRule: relayAthleteEntryTimeState.objData.hasAthleteExceedsTimeStandardsRelayRule
                };
                tryToCalculateTeamEntryTime(updatedGridData.athletes);
            }
            else {
                updatedGridData.athletes[2] = {};
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
            setGridState({ ...gridState, gridData: updatedGridData });
            setState({ ...state, trySetAthlete3Info: false, athlete3PersonId: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete3PersonId, relayAthleteMeetEventState, relayAthleteEntryTimeState, state.trySetAthlete3Info, state.athlete3PersonId])

    useEffect(() => {
        //Add view only, get Entry Time for leg 4
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            const selectedPersonId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete4PersonId)?.personId || Constants.DEFAULT_ID;
            const selectedOrgUnitAthleteEntryId = relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete4PersonId)?.orgUnitAthleteEntryId || Constants.DEFAULT_ID;
            if (selectedPersonId !== Constants.DEFAULT_ID) {
                getRelayAthleteEntryTime(selectedPersonId, state.event.meetEventId, 4, formState.eventId);
                setState({ ...state, trySetAthlete1Info: false, trySetAthlete2Info: false, trySetAthlete3Info: false, trySetAthlete4Info: true, athlete4OrgUnitAthleteEntryId: selectedOrgUnitAthleteEntryId, athlete4PersonId: selectedPersonId });
            }
            else {
                updatedGridData.athletes[3] = {};
                setGridState({ ...gridState, gridData: updatedGridData });
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete4PersonId, relayAthleteMeetEventState])

    useEffect(() => {
        //Add view only, update Grid State for leg 4
        if (relayAthleteMeetEventState.isArrayLoading === false && relayAthleteMeetEventState.isArrayLoaded === true
            && state.trySetAthlete4Info === true && relayAthleteEntryTimeState.isObjLoaded === true && relayAthleteEntryTimeState.legNumber === 4 &&
            relayAthleteEntryTimeState.personId === state.athlete4PersonId &&
            state.event && Object.keys(state.event).length > 0 && state.relayTeam && Object.keys(state.relayTeam).length === 0) {
            const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));

            if (formState.athlete4PersonId !== Constants.DEFAULT_ID) {
                updatedGridData.athletes[3] = {
                    ...relayAthleteMeetEventState.arrayData.find(x => x.personId === formState.athlete4PersonId),
                    entryTime: relayAthleteEntryTimeState.objData.entryTime,
                    swimTimeId: relayAthleteEntryTimeState.objData.swimTimeId,
                    hasAthleteExceedsTimeStandardsRelayRule: relayAthleteEntryTimeState.objData.hasAthleteExceedsTimeStandardsRelayRule
                };
                tryToCalculateTeamEntryTime(updatedGridData.athletes);
            }
            else {
                updatedGridData.athletes[3] = {};
                setCalculatedTeamEntryTimeState({ ...calculatedTeamEntryTimeState, entryTime: MSG, entryTimeFormattedForDatabase: '' });
            }
            setGridState({ ...gridState, gridData: updatedGridData });
            setState({ ...state, trySetAthlete4Info: false, athlete4PersonId: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.athlete4PersonId, relayAthleteMeetEventState, relayAthleteEntryTimeState, state.trySetAthlete4Info, state.athlete4PersonId])

    useEffect(() => {
        if (state.tryForceAggregateValidate === true && omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.isObjLoading === false &&
            omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.isObjLoaded === true) {
            const fastestAggregateTimeInMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.objData?.swimTime)));
            const teamTimeInMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime)));
            if (omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.objData?.relayAthletes != null) {
                if (fastestAggregateTimeInMilliseconds < teamTimeInMilliseconds) {
                    setErrors({ ...errorState, general: `A faster aggregate time exists (${formatTimeForDisplay(omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.objData?.swimTime)}). Please use the faster aggregate time instead of a team entry time.` });
                }
                else {
                    saveRelay();
                }
            }
            else {
                saveRelay();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tryForceAggregateValidate, omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState])

    useEffect(() => {
        if (state.tryGet === true && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading === false &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isSaving === false && relayAthleteEntryTimeState.isObjLoading === false) {
            //get Roster to refresh any relay only statuses
            getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
            //get Roster Entries to refresh any relay only statuses
            getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
            setState({ ...state, tryGet: false, tryRedirect: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tryGet, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, relayAthleteEntryTimeState])

    useEffect(() => {
        if (state.tryRedirect === true && omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading === false &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isSaving === false && relayAthleteEntryTimeState.isObjLoading === false &&
            omeMeetOrgUnitAthleteEntryState.isArrayLoading === false && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false) {
            if (omeMeetState.route === '') {
                //OME Route
                navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES_TEAMS, { state: { event: state.event } });
            }
            else {
                //Meet Route
                navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES_TEAMS, { state: { event: state.event } });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tryRedirect, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, relayAthleteEntryTimeState, omeMeetOrgUnitAthleteEntryState,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState])

    function tryToCalculateTeamEntryTime(updatedGridDataAthletes) {
        let countOfAthleteEntryTimes = 0;
        for (let i = 0; i < updatedGridDataAthletes.length; i++) {
            if (updatedGridDataAthletes[i].entryTime) {
                countOfAthleteEntryTimes += 1;
            }
        }
        if (countOfAthleteEntryTimes === 4) {
            const athlete1EntryTimeMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(updatedGridDataAthletes[0].entryTime)));
            const athlete2EntryTimeMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(updatedGridDataAthletes[1].entryTime)));
            const athlete3EntryTimeMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(updatedGridDataAthletes[2].entryTime)));
            const athlete4EntryTimeMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(updatedGridDataAthletes[3].entryTime)));
            const teamEntryTimeMilliseconds = athlete1EntryTimeMilliseconds + athlete2EntryTimeMilliseconds + athlete3EntryTimeMilliseconds + athlete4EntryTimeMilliseconds;
            const entryTimeFormattedForDatabase = formatMillisecondsToTime(teamEntryTimeMilliseconds);
            const teamEntryTimeFormattedForDisplay = formatTimeForDisplay(entryTimeFormattedForDatabase);
            setCalculatedTeamEntryTimeState({
                ...calculatedTeamEntryTimeState,
                entryTimeFormattedForDatabase: entryTimeFormattedForDatabase,
                entryTime: teamEntryTimeFormattedForDisplay
            });
        } else {
            setCalculatedTeamEntryTimeState({
                ...calculatedTeamEntryTimeState,
                entryTimeFormattedForDatabase: '',
                entryTime: MSG
            });
        }
    }

    function submitFormCallback(formState) {
        let error = false;
        let relayLimitCount = omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet || 0;
        let leg1AthleteRelayEntryCount = 0;
        let leg2AthleteRelayEntryCount = 0;
        let leg3AthleteRelayEntryCount = 0;
        let leg4AthleteRelayEntryCount = 0;
        if (omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet) {
            for (let i = 0; i < omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData.length; i++) {
                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams) {
                    for (let j = 0; j < omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams.length; j++) {
                        for (let k = 0; k < omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes?.length; k++) {
                            if (formState.isAggregate === false) {
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.personId) {
                                    leg1AthleteRelayEntryCount += 1;
                                }
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.personId) {
                                    leg2AthleteRelayEntryCount += 1;
                                }
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.personId) {
                                    leg3AthleteRelayEntryCount += 1;
                                }
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.personId) {
                                    leg4AthleteRelayEntryCount += 1;
                                }
                            }
                            else if (formState.isAggregate === true) {
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    gridState.gridData.athletes[0]?.personId) {
                                    leg1AthleteRelayEntryCount += 1;
                                }
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    gridState.gridData.athletes[1]?.personId) {
                                    leg2AthleteRelayEntryCount += 1;
                                }
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    gridState.gridData.athletes[2]?.personId) {
                                    leg3AthleteRelayEntryCount += 1;
                                }
                                if (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData[i].relayTeams[j].teamAthletes[k].personId ===
                                    gridState.gridData.athletes[3]?.personId) {
                                    leg4AthleteRelayEntryCount += 1;
                                }
                            }
                        }
                    }
                }
            }

            if (leg1AthleteRelayEntryCount >= relayLimitCount || leg2AthleteRelayEntryCount >= relayLimitCount ||
                leg3AthleteRelayEntryCount >= relayLimitCount || leg4AthleteRelayEntryCount >= relayLimitCount) {
                error = true;
                setErrors({ ...errorState, general: `The Max Relay Events Per Individual Per Meet is ${omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet}.${leg1AthleteRelayEntryCount >= relayLimitCount ? `\n The Leg 1 Athlete is already entered into ${leg1AthleteRelayEntryCount} ${leg1AthleteRelayEntryCount === 1 ? 'relay' : 'relays'}. Please select a different Leg 1 Athlete.` : ''}${leg2AthleteRelayEntryCount >= relayLimitCount ? `\n The Leg 2 Athlete is already entered into ${leg2AthleteRelayEntryCount} ${leg2AthleteRelayEntryCount === 1 ? 'relay' : 'relays'}. Please select a different Leg 2 Athlete.` : ''}${leg3AthleteRelayEntryCount >= relayLimitCount ? `\n The Leg 3 Athlete is already entered into ${leg3AthleteRelayEntryCount} ${leg3AthleteRelayEntryCount === 1 ? 'relay' : 'relays'}. Please select a different Leg 3 Athlete.` : ''}${leg4AthleteRelayEntryCount >= relayLimitCount ? `\n The Leg 4 Athlete is already entered into ${leg4AthleteRelayEntryCount} ${leg4AthleteRelayEntryCount === 1 ? 'relay' : 'relays'}. Please select a different Leg 4 Athlete.` : ''}` });
            }
        }

        //Check if athletes violate no faster than time standard cut rule
        if (formState.isAggregate === true && (gridState.gridData.athletes[0]?.hasAthleteExceedsTimeStandardsRelayRule === true || gridState.gridData.athletes[1]?.hasAthleteExceedsTimeStandardsRelayRule === true ||
            gridState.gridData.athletes[2]?.hasAthleteExceedsTimeStandardsRelayRule === true || gridState.gridData.athletes[3]?.hasAthleteExceedsTimeStandardsRelayRule === true)) {
            error = true;
            let athlete1PersonIdError = gridState.gridData.athletes[0]?.hasAthleteExceedsTimeStandardsRelayRule === true ? 'Ineligible to swim selected relay leg' : '';
            let athlete2PersonIdError = gridState.gridData.athletes[1]?.hasAthleteExceedsTimeStandardsRelayRule === true ? 'Ineligible to swim selected relay leg' : '';
            let athlete3PersonIdError = gridState.gridData.athletes[2]?.hasAthleteExceedsTimeStandardsRelayRule === true ? 'Ineligible to swim selected relay leg' : '';
            let athlete4PersonIdError = gridState.gridData.athletes[3]?.hasAthleteExceedsTimeStandardsRelayRule === true ? 'Ineligible to swim selected relay leg' : '';
            setErrors({
                ...errorState, athlete1PersonId: errorState.athlete1PersonId ? errorState.athlete1PersonId : athlete1PersonIdError ? athlete1PersonIdError : undefined,
                athlete2PersonId: errorState.athlete2PersonId ? errorState.athlete2PersonId : athlete2PersonIdError ? athlete2PersonIdError : undefined,
                athlete3PersonId: errorState.athlete3PersonId ? errorState.athlete3PersonId : athlete3PersonIdError ? athlete3PersonIdError : undefined,
                athlete4PersonId: errorState.athlete4PersonId ? errorState.athlete4PersonId : athlete4PersonIdError ? athlete4PersonIdError : undefined,
                general: "At least one of the selected athletes is ineligible to swim their selected relay leg because they have a swim time faster than a No Faster Than Time Standard Cut for the relay leg's stroke and distance. Please choose 4 eligible athletes in order to create an aggregate relay team."
            });
        }

        if (error === false) {
            if (formState.isAggregate === true && (state.event?.timeCuts && state.event?.timeCuts.length > 0) && (!gridState.gridData.athletes[0]?.entryTime || !gridState.gridData.athletes[1]?.entryTime ||
                !gridState.gridData.athletes[2]?.entryTime || !gridState.gridData.athletes[3]?.entryTime)) {
                setErrors({ ...errorState, general: 'At least one of the selected athletes is missing an entry time. Please choose 4 athletes with entry times in order to create an aggregate relay team.' });
            } else if (formState.isAggregate === true && (state.event?.timeCuts && state.event?.timeCuts.length > 0) && (gridState.gridData.athletes[0]?.entryTime && gridState.gridData.athletes[1]?.entryTime &&
                gridState.gridData.athletes[2]?.entryTime && gridState.gridData.athletes[3]?.entryTime && calculatedTeamEntryTimeState.entryTimeFormattedForDatabase)) {
                const teamEntryTimeMilliseconds = convertTimeToMilliseconds(calculatedTeamEntryTimeState.entryTimeFormattedForDatabase);
                for (let i = 0; i < state.event?.timeCuts.length; i++) {
                    if (state.event?.timeCuts[i]?.qualifyingEventId === formState.eventId) {
                        const noFasterThanTimeCutMilliseconds = state.event?.timeCuts[i]?.noFasterThanTimeCut ? convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(state.event?.timeCuts[i]?.noFasterThanTimeCut))) : '';
                        const noSlowerThanTimeCutMilliseconds = state.event?.timeCuts[i]?.noSlowerThanTimeCut ? convertTimeToMilliseconds(formatTimeForFilterObject(formatTimeForDisplay(state.event?.timeCuts[i]?.noSlowerThanTimeCut))) : '';
                        if (teamEntryTimeMilliseconds < noFasterThanTimeCutMilliseconds) {
                            error = true;
                            setErrors({ ...errorState, general: `The Team Entry Time cannot be faster than the ${state.event?.eventName} No Faster Than Time Standard Cut (${formatTimeForDisplay(state.event?.timeCuts[i]?.noFasterThanTimeCut)}).` });
                        }
                        else if (teamEntryTimeMilliseconds > noSlowerThanTimeCutMilliseconds) {
                            error = true;
                            setErrors({ ...errorState, general: `The Team Entry Time cannot be slower than the ${state.event?.eventName} No Slower Than Time Standard Cut (${formatTimeForDisplay(state.event?.timeCuts[i]?.noSlowerThanTimeCut)}).` });
                        }
                    }
                }
                if (error === false) {
                    saveRelay();
                }
            } else if (formState.isAggregate === false && omeMeetState.objData.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime === true) {
                //do forceAggregateIfFasterThanTeamTime validation API Call
                getOrgUnitAthleteEntryAggregateRelayAthlete(omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId, state.event.meetEventId);
                setState({ ...state, tryForceAggregateValidate: true });
            }
            else {
                if (error === false) {
                    saveRelay();
                }
            }
        }
    };

    function saveRelay() {
        const relayAthletes = formState.isAggregate === false ?
            (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes?.length > 0 ?
                [
                    ...(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.orgUnitAthleteEntryId > 0 || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.personId ? [{ orgUnitAthleteEntryId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.orgUnitAthleteEntryId, personId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.personId, legNumber: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.legNumber, entryTime: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.entryTime, entryTimeMeetName: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[0]?.entryTimeMeetName, swimTimeEntryOverride: false }] : []),
                    ...(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.orgUnitAthleteEntryId > 0 || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.personId ? [{ orgUnitAthleteEntryId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.orgUnitAthleteEntryId, personId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.personId, legNumber: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.legNumber, entryTime: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.entryTime, entryTimeMeetName: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[1]?.entryTimeMeetName, swimTimeEntryOverride: false }] : []),
                    ...(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.orgUnitAthleteEntryId > 0 || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.personId ? [{ orgUnitAthleteEntryId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.orgUnitAthleteEntryId, personId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.personId, legNumber: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.legNumber, entryTime: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.entryTime, entryTimeMeetName: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[2]?.entryTimeMeetName, swimTimeEntryOverride: false }] : []),
                    ...(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.orgUnitAthleteEntryId > 0 || omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.personId ? [{ orgUnitAthleteEntryId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.orgUnitAthleteEntryId, personId: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.personId, legNumber: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.legNumber, entryTime: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.entryTime, entryTimeMeetName: omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.relayAthletes[3]?.entryTimeMeetName, swimTimeEntryOverride: false }] : [])
                ] :
                [])
            :
            [
                { personId: gridState.gridData.athletes[0]?.personId, orgUnitAthleteEntryId: gridState.gridData.athletes[0]?.orgUnitAthleteEntryId, legNumber: 1, entryTime: gridState.gridData.athletes[0]?.entryTime, swimTimeId: gridState.gridData.athletes[0]?.swimTimeId, swimTimeEntryOverride: false },
                { personId: gridState.gridData.athletes[1]?.personId, orgUnitAthleteEntryId: gridState.gridData.athletes[1]?.orgUnitAthleteEntryId, legNumber: 2, entryTime: gridState.gridData.athletes[1]?.entryTime, swimTimeId: gridState.gridData.athletes[1]?.swimTimeId, swimTimeEntryOverride: false },
                { personId: gridState.gridData.athletes[2]?.personId, orgUnitAthleteEntryId: gridState.gridData.athletes[2]?.orgUnitAthleteEntryId, legNumber: 3, entryTime: gridState.gridData.athletes[2]?.entryTime, swimTimeId: gridState.gridData.athletes[2]?.swimTimeId, swimTimeEntryOverride: false },
                { personId: gridState.gridData.athletes[3]?.personId, orgUnitAthleteEntryId: gridState.gridData.athletes[3]?.orgUnitAthleteEntryId, legNumber: 4, entryTime: gridState.gridData.athletes[3]?.entryTime, swimTimeId: gridState.gridData.athletes[3]?.swimTimeId, swimTimeEntryOverride: false }
            ];

        const relayAthleteObj = {
            meetEventId: state.event.meetEventId,
            omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId,
            meetId: omeMeetState.objData.meetId,
            entryMeetName: formState.isAggregate === true ? null : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryMeetName,
            entryTime: formState.isAggregate === true ? calculatedTeamEntryTimeState.entryTimeFormattedForDatabase || null : formatTimeForFilterObject(formatTimeForDisplay(omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.entryTime)),
            swimTimeId: formState.isAggregate === true ? null : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.swimTimeId,
            swimDate: formState.isAggregate === true ? formatDate(getTodaysDate()) : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.swimDate,
            relayTeamName: formState.relayTeamName,
            isAggregate: formState.isAggregate,
            swimTimeEntryOverride: false,
            qualifyingCourseId: formState.isAggregate === true ? eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.courseId : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData?.qualifyingCourseId,
            nonConforming: formState.isAggregate === true ? eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.isNonConforming : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData.nonconforming,
            alternateEvent: formState.isAggregate === true ? eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.isAlternateEvent : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData.alternateEvent,
            qualifyingEventId: formState.isAggregate === true ? formState.eventId : omeMeetOrgUnitAthleteEntryRelayRosterEntryState.objData.qualifyingEventId,
            relayAthletes: relayAthletes
        }
        postRelayMeetEventEntry(relayAthleteObj);
        setState({ ...state, tryGet: true });
    };

    return {
        state,
        omeMeetState,
        gridState,
        calculatedTeamEntryTimeState,
        relayAthleteEntryTimeState,
        omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
        omeMeetOrgUnitAthleteEntryState,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
        omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState,
        eventMeetEventState,
        onSaveClicked,
        onBackClicked,
        formState,
        errorState,
        onFormValueChanged,
        onValueTextPairChanged,
        relayTeamOptionsState,
        relayAthleteMeetEventState,
        isLarge,
        MSG,
        omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId,
        onAddOverrideRelayClicked,
        setHandleEventChange
    };
}

export default useOrgUnitAdminRelayEntriesDetail;