import { useContext } from 'react';

import { SelectionsLoadingModalStateContext } from './SelectionsLoadingModalContextProvider';

const useSelectionsLoadingModalData = () => {
  const [contextErrorState, setContextErrorState] = useContext(SelectionsLoadingModalStateContext);

  const setContextStateError = (isContextError) => {
    setContextErrorState({
      ...contextErrorState,
      isContextError
    });
  };

  return {
    isContextError: contextErrorState.isContextError === true,
    setContextStateError
  };
};

export default useSelectionsLoadingModalData;