import React, { createContext, useState } from 'react';

import TimeStandardTypeData from './TimeStandardTypeData';

export const TimeStandardTypeStateContext = createContext();

const TimeStandardTypeContextProvider = ({ children }) => {
  const stateHook = useState(TimeStandardTypeData.INITIAL_STATE);

  return (
    <TimeStandardTypeStateContext.Provider value={stateHook}>
      {children}
    </TimeStandardTypeStateContext.Provider>
  );
};

export default TimeStandardTypeContextProvider;