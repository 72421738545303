import { useContext } from 'react';

import { OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteStateContext } from './OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider';

import OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData from './OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData';

const useOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData = () => {
  const [omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState, setOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteState] =
    useContext(OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteStateContext);

  const getOrgUnitAthleteEntryAggregateRelayAthlete = (omeMeetOrgUnitEntryId, meetEventId) =>
  OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData.getOrgUnitAthleteEntryAggregateRelayAthleteData(omeMeetOrgUnitEntryId, meetEventId,
      omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState, setOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteState);

  const clearOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteObjData = () => {
    setOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteState({
      ...omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState,
    setOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteState,
    getOrgUnitAthleteEntryAggregateRelayAthlete,
    clearOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteObjData
  };
};

export default useOmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData;