import Constants from '../../../../../common/utils/Constants';
import { convertTimeToMilliseconds, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { isValidSwimTime } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {

  const scyQualifyingTimeFormatted = formatTimeForFilterObject(formState.scyQualifyingTime);
  const scyQualifyingTimeInMilliseconds = convertTimeToMilliseconds(scyQualifyingTimeFormatted);
  const scmQualifyingTimeFormatted = formatTimeForFilterObject(formState.scmQualifyingTime);
  const scmQualifyingTimeInMilliseconds = convertTimeToMilliseconds(scmQualifyingTimeFormatted);
  const lcmQualifyingTimeFormatted = formatTimeForFilterObject(formState.lcmQualifyingTime);
  const lcmQualifyingTimeInMilliseconds = convertTimeToMilliseconds(lcmQualifyingTimeFormatted);

  const scyLimitTimeFormatted = formatTimeForFilterObject(formState.scyLimitTime);
  const scyLimitTimeInMilliseconds = convertTimeToMilliseconds(scyLimitTimeFormatted);
  const scmLimitTimeFormatted = formatTimeForFilterObject(formState.scmLimitTime);
  const scmLimitTimeInMilliseconds = convertTimeToMilliseconds(scmLimitTimeFormatted);
  const lcmLimitTimeFormatted = formatTimeForFilterObject(formState.lcmLimitTime);
  const lcmLimitTimeInMilliseconds = convertTimeToMilliseconds(lcmLimitTimeFormatted);

  const scyBonusTimeFormatted = formatTimeForFilterObject(formState.scyBonusTime);
  const scyBonusTimeInMilliseconds = convertTimeToMilliseconds(scyBonusTimeFormatted);
  const scmBonusTimeFormatted = formatTimeForFilterObject(formState.scmBonusTime);
  const scmBonusTimeInMilliseconds = convertTimeToMilliseconds(scmBonusTimeFormatted);
  const lcmBonusTimeFormatted = formatTimeForFilterObject(formState.lcmBonusTime);
  const lcmBonusTimeInMilliseconds = convertTimeToMilliseconds(lcmBonusTimeFormatted);

  let errors = {};

  if (formState.alternateEventName === ''
    || formState.alternateEventId === Constants.DEFAULT_ID) {
    errors.alternateEventId = 'A valid Alternate Event is required';
  }

  if (formState.scyPossibleForAlternateEvent === false && formState.scmPossibleForAlternateEvent === false && formState.lcmPossibleForAlternateEvent === false) {
    errors.alternateEventId = "The Alternate Event cannot be an Open Water event";
  }

  if (formState.alternateEventId === formState.eventId) {
    errors.alternateEventId = "The Alternate Event and Event cannot be the same";
  }
  else if (formState.alternateEventId === formState.scyEventId) {
    errors.alternateEventId = "The Alternate Event and Event cannot be equivalent events on different courses";
  }
  else if (formState.alternateEventId === formState.scmEventId) {
    errors.alternateEventId = "The Alternate Event and Event cannot be equivalent events on different courses";
  }
  else if (formState.alternateEventId === formState.lcmEventId) {
    errors.alternateEventId = "The Alternate Event and Event cannot be equivalent events on different courses";
  };

  if (formState.isRelay === true && formState.isAlternateRelay === false) {
    errors.eventId = "The Alternate Event must be a relay event, like the Selected Event";
  }
  if (formState.isRelay === false && formState.isAlternateRelay === true) {
    errors.alternateEventId = "The Alternate Event must be an individual event, like the Selected Event";
  }

  if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard !== true && formState.alternateEventHasNoSlowerThanTimeStandard !== false) {
    errors.alternateEventHasNoSlowerThanTimeStandard = 'A selection is required';
  }

  if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true) {
    if (formState.showScy === true && formState.scyPossibleForAlternateEvent === true) {
      if (formState.scyQualifyingTime.trim() === '') {
        errors.scyQualifyingTime = 'SCY No Slower Than Time is required';
      }
      else if (!isValidSwimTime(scyQualifyingTimeFormatted)) {
        errors.scyQualifyingTime = 'SCY No Slower Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true &&
        scyQualifyingTimeInMilliseconds <= scyLimitTimeInMilliseconds) {
        errors.scyQualifyingTime = 'SCY No Slower Than Time must be slower than SCY No Faster Than Time';
      }
      // Bonus time should be slower than or equal to No Slower Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true &&
        scyQualifyingTimeInMilliseconds > scyBonusTimeInMilliseconds) {
        errors.scyQualifyingTime = 'SCY No Slower Than Time must be faster than or equal to SCY Bonus Time';
      }
    }

    if (formState.showScm === true && formState.scmPossibleForAlternateEvent === true) {
      if (formState.scmQualifyingTime.trim() === '') {
        errors.scmQualifyingTime = 'SCM No Slower Than Time is required';
      }
      else if (!isValidSwimTime(scmQualifyingTimeFormatted)) {
        errors.scmQualifyingTime = 'SCM No Slower Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true &&
        scmQualifyingTimeInMilliseconds <= scmLimitTimeInMilliseconds) {
        errors.scmQualifyingTime = 'SCM No Slower Than Time must be slower than SCM No Faster Than Time';
      }
      // Bonus time should be slower than or equal to No Slower Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true &&
        scmQualifyingTimeInMilliseconds > scmBonusTimeInMilliseconds) {
        errors.scmQualifyingTime = 'SCM No Slower Than Time must be faster than or equal to SCM Bonus Time';
      }
    }

    if (formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true) {
      if (formState.lcmQualifyingTime.trim() === '') {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time is required';
      }
      else if (!isValidSwimTime(lcmQualifyingTimeFormatted)) {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true &&
        lcmQualifyingTimeInMilliseconds <= lcmLimitTimeInMilliseconds) {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time must be slower than LCM No Faster Than Time';
      }
      // Bonus time should be slower than or equal to No Slower Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true &&
        lcmQualifyingTimeInMilliseconds > lcmBonusTimeInMilliseconds) {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time must be faster than or equal to LCM Bonus Time';
      }
    }
  }

  if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard !== true && formState.alternateEventHasNoFasterThanTimeStandard !== false) {
    errors.alternateEventHasNoFasterThanTimeStandard = 'A selection is required';
  }

  if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true) {
    if (formState.showScy === true && formState.scyPossibleForAlternateEvent === true) {
      if (formState.scyLimitTime.trim() === '') {
        errors.scyLimitTime = 'SCY No Faster Than Time is required';
      }
      else if (!isValidSwimTime(scyLimitTimeFormatted)) {
        errors.scyLimitTime = 'SCY No Faster Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true &&
        scyLimitTimeInMilliseconds >= scyQualifyingTimeInMilliseconds) {
        errors.scyLimitTime = 'SCY No Faster Than Time must be faster than SCY No Slower Than Time';
      }
      // Bonus time should be slower than No Faster Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true &&
        scyLimitTimeInMilliseconds >= scyBonusTimeInMilliseconds) {
        errors.scyLimitTime = 'SCY No Faster Than Time must be faster than SCY Bonus Time';
      }
    }

    if (formState.showScm === true && formState.scmPossibleForAlternateEvent === true) {
      if (formState.scmLimitTime.trim() === '') {
        errors.scmLimitTime = 'SCM No Faster Than Time is required';
      }
      else if (!isValidSwimTime(scmLimitTimeFormatted)) {
        errors.scmLimitTime = 'SCM No Faster Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true &&
        scmLimitTimeInMilliseconds >= scmQualifyingTimeInMilliseconds) {
        errors.scmLimitTime = 'SCM No Faster Than Time must be faster than SCM No Slower Than Time';
      }
      // Bonus time should be slower than No Faster Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true &&
        scmLimitTimeInMilliseconds >= scmBonusTimeInMilliseconds) {
        errors.scmLimitTime = 'SCM No Faster Than Time must be faster than SCM Bonus Time';
      }
    }

    if (formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true) {
      if (formState.lcmLimitTime.trim() === '') {
        errors.lcmLimitTime = 'LCM No Faster Than Time is required';
      }
      else if (!isValidSwimTime(lcmLimitTimeFormatted)) {
        errors.lcmLimitTime = 'LCM No Faster Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true &&
        lcmLimitTimeInMilliseconds >= lcmQualifyingTimeInMilliseconds) {
        errors.lcmLimitTime = 'LCM No Faster Than Time must be faster than LCM No Slower Than Time';
      }
      // Bonus time should be slower than No Faster Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true &&
        lcmLimitTimeInMilliseconds >= lcmBonusTimeInMilliseconds) {
        errors.lcmLimitTime = 'LCM No Faster Than Time must be faster than LCM Bonus Time';
      }
    }
  }

  if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard !== true && formState.alternateEventHasBonusTimeStandard !== false && formState.isRelay === false) {
    errors.alternateEventHasBonusTimeStandard = 'A selection is required';
  }

  if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.alternateEventHasBonusTimeStandard === true) {
    if (formState.showScy === true && formState.scyPossibleForAlternateEvent === true) {
      if (formState.scyBonusTime.trim() === '') {
        errors.scyBonusTime = 'SCY Bonus Time is required';
      }
      else if (!isValidSwimTime(scyBonusTimeFormatted)) {
        errors.scyBonusTime = 'SCY Bonus Time must be a valid time';
      }
      // Bonus time should be slower than No Faster Than and slower than or equal to No Slower Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true &&
        scyBonusTimeInMilliseconds < scyQualifyingTimeInMilliseconds) {
        errors.scyBonusTime = 'SCY Bonus Time must be slower than or equal to SCY No Slower Than Time';
      }
      else if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true &&
        scyBonusTimeInMilliseconds <= scyLimitTimeInMilliseconds) {
        errors.scyBonusTime = 'SCY Bonus Time must be slower than SCY No Faster Than Time';
      }
    }

    if (formState.showScm === true && formState.scmPossibleForAlternateEvent === true) {
      if (formState.scmBonusTime.trim() === '') {
        errors.scmBonusTime = 'SCM Bonus Time is required';
      }
      else if (!isValidSwimTime(scmBonusTimeFormatted)) {
        errors.scmBonusTime = 'SCM Bonus Time must be a valid time';
      }
      // Bonus time should be slower than No Faster Than and slower than or equal to No Slower Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true &&
        scmBonusTimeInMilliseconds < scmQualifyingTimeInMilliseconds) {
        errors.scmBonusTime = 'SCM Bonus Time must be slower than or equal to SCM No Slower Than Time';
      }
      else if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true &&
        scmBonusTimeInMilliseconds <= scmLimitTimeInMilliseconds) {
        errors.scmBonusTime = 'SCM Bonus Time must be slower than SCM No Faster Than Time';
      }
    }

    if (formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true) {
      if (formState.lcmBonusTime.trim() === '') {
        errors.lcmBonusTime = 'LCM Bonus Time is required';
      }
      else if (!isValidSwimTime(lcmBonusTimeFormatted)) {
        errors.lcmBonusTime = 'LCM Bonus Time must be a valid time';
      }
      // Bonus time should be slower than No Faster Than and slower than or equal to No Slower Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.alternateEventHasNoSlowerThanTimeStandard === true &&
        lcmBonusTimeInMilliseconds < lcmQualifyingTimeInMilliseconds) {
        errors.lcmBonusTime = 'LCM Bonus Time must be slower than or equal to LCM No Slower Than Time';
      }
      else if (formState.hasNoFasterThanTimeStandards === true && formState.alternateEventHasNoFasterThanTimeStandard === true &&
        lcmBonusTimeInMilliseconds <= lcmLimitTimeInMilliseconds) {
        errors.lcmBonusTime = 'LCM Bonus Time must be slower than LCM No Faster Than Time';
      }
    }
  }

  return errors;
};


const OmeAlternateEventDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeAlternateEventDetailValidation;