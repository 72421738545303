import { Fragment } from 'react';

import useMeetTimes from './UseMeetTimes';
import MeetTimesGrid from './MeetTimesGrid';

import MeetTimesEventCombobox from '../../../../components/comboboxes/MeetTimesEventCombobox';
import MeetTimesCompetitorCombobox from '../../../../components/comboboxes/MeetTimesCompetitorCombobox';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import Input from '../../../../../common/components/inputs/Input';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';

import global from '../../../../../common/components/GlobalStyle.module.css';

const MeetTimesReadOnly = () => {
  const {
    meetId,
    competitionGenderTypes,
    sessionTypes,
    formState,
    errorState,
    gridState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onAddAthletesClicked
  } = useMeetTimes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Times Results</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row'>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <MeetTimesEventCombobox
              label="Event Course"
              name="eventCourseId"
              valueToMatch={formState.eventCourseName}
              error={errorState.eventCourseId}
              message={errorState.eventCourseId}
              meetId={meetId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'eventCourseId', newValueLabel, 'eventCourseName', e); }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <MeetTimesCompetitorCombobox
              label="Competitor"
              name="competitorId"
              valueToMatch={formState.competitorName}
              error={errorState.competitorId}
              message={errorState.competitorId}
              meetId={meetId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'competitorId', newValueLabel, 'competitorName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Event Gender"
              name="eventGenderId"
              options={competitionGenderTypes}
              value={formState.eventGenderId}
              error={errorState.eventGenderId}
              message={errorState.eventGenderId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => { e && e.target && e.target.value && onValueTextPairChanged(newValue, 'eventGenderId', newValueLabel, 'eventGenderName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Session"
              name="sessionId"
              options={sessionTypes}
              value={formState.sessionId}
              error={errorState.sessionId}
              message={errorState.sessionId}
              isloading={false}
              onChange={(newValue, newValueLabel, e) => { e && e.target && e.target.value && onValueTextPairChanged(newValue, 'sessionId', newValueLabel, 'sessionName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2">
            <Input
              label="Start Age"
              name="startAge"
              value={formState.startAge}
              error={errorState.startAge}
              message={errorState.startAge}
              onChange={(value) => { onFormValueChanged('startAge', value) }} />
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2">
            <Input
              label="End Age"
              name="endAge"
              value={formState.endAge}
              error={errorState.endAge}
              message={errorState.endAge}
              onChange={(value) => { onFormValueChanged('endAge', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <p style={{ marginTop: "30px" }}><b>Total Times Results:</b> {Array.isArray(gridState.arrayData) ? gridState.arrayData.length : 0}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <p>{errorState.filter ? '' : '*Must filter by an Event Course or Competitor to view times results'}</p>
            <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="submit">Search for Times Results</PrimaryButton>&nbsp;
            <ActionIntraPageButton onClick={onAddAthletesClicked}>Add Athlete(s)</ActionIntraPageButton>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-row-extra-top-margin">
            <p>{gridState.displayArray.length === 1 ? `${gridState.displayArray.length} Time Result` : `${gridState.displayArray.length} Times Results`}</p>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className='col-xs-12'>
            <MeetTimesGrid
              gridData={gridState.displayArray}
              isLoading={gridState.isArrayLoading}
            />
          </div>
        </div>
      </form>
      {gridState.isArrayLoading === true && <LoadingModal />}
    </Fragment>
  );
};

export default MeetTimesReadOnly;