import React, { Fragment } from 'react';

import LaneSheetLandingLargeGrid from './LaneSheetLandingLargeGrid';
import LaneSheetLandingSmallGrid from './LaneSheetLandingSmallGrid';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LaneSheetLandingGrid = ({ isLoading, gridData, onAddClicked, onEditClicked, onFiltersToggle, showFilters }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            {gridData.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
              : <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>}
          </div>
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={onFiltersToggle}>
              {showFilters === true ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>
        </div>
      )
      : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <LaneSheetLandingLargeGrid
      isLoading={isLoading}
      gridData={gridData}
      onAddClicked={onAddClicked}
      onEditClicked={onEditClicked} />
    <LaneSheetLandingSmallGrid
      isLoading={isLoading}
      gridData={gridData}
      onAddClicked={onAddClicked}
      onEditClicked={onEditClicked} />
  </Fragment>
);

export default LaneSheetLandingGrid;
