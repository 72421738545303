import { useState } from "react";
import { useEffect } from "react";

import useParticipantGridDetailPopupData from "../../../../state/selectionsParticipants/participantGridDetailPopup/UseParticipantGridDetailPopupData";

const X_LEFT_OFFSET = 50;
const X_RIGHT_OFFSET = -450;
const Y_TOP_OFFSET = -50;
const Y_BOTTOM_OFFSET = -250;

const useParticipantGridDetailPopup = () => {
  const { participantGridDetailPopupState, onEnterCell, onLeaveCell } = useParticipantGridDetailPopupData();
  const [windowSizeState, setWindowSizeState] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });

  const getRelayLegName = (firstName, lastName) => {
    if (firstName || lastName) {
      return `${firstName || ''} ${lastName || ''}`;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSizeState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  function getLeftPx() {
    const pageX = participantGridDetailPopupState.pageX;

    // on right side
    if (pageX > (windowSizeState.innerWidth / 2)) {
      return `${pageX + X_RIGHT_OFFSET}px`;
    } else {
      return `${pageX + X_LEFT_OFFSET}px`;
    }
  };

  function getTopPx() {
    const pageY = participantGridDetailPopupState.pageY;

    // on bottom
    if (pageY > (windowSizeState.innerHeight / 1.5)) {
      return `${pageY + Y_BOTTOM_OFFSET}px`;
    } else {
      return `${pageY + Y_TOP_OFFSET}px`;
    }
  };

  return {
    participantGridDetailPopupState,
    windowSizeState,
    onEnterCell,
    onLeaveCell,
    getLeftPx,
    getTopPx,
    getRelayLegName
  };
};

export default useParticipantGridDetailPopup;