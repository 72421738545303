import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryRosterSelectionData from './GetOrgUnitAthleteEntryRosterSelectionData';
import postOrgUnitAthleteEntryRosterSelectionData from './PostOrgUnitAthleteEntryRosterSelectionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitAthleteEntryRosterSelectionData = {
  INITIAL_STATE, getOrgUnitAthleteEntryRosterSelectionData, postOrgUnitAthleteEntryRosterSelectionData
}

export default OmeMeetOrgUnitAthleteEntryRosterSelectionData;