import React, { Fragment } from 'react';

import useOrgUnitAdminCoaches from './UseOrgUnitAdminCoaches';

import OrgUnitAdminCoachesGrid from './OrgUnitAdminCoachesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminCoachesWrite = () => {
  const { omeMeetOrgUnitNonAthleteEntryState,
    modalState, onAddCoach, onDeleteCoachClicked, onModalCanceled, onDeleteCoach
  } = useOrgUnitAdminCoaches();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Coaches</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddCoach}>Select Coaches</ActionIntraPageButton>
        </div>
        <div className="col-xs-12">
          <OrgUnitAdminCoachesGrid
            gridData={omeMeetOrgUnitNonAthleteEntryState.arrayData}
            isLoading={omeMeetOrgUnitNonAthleteEntryState.isArrayLoading}
            onDeleteCoachClicked={onDeleteCoachClicked}
          />
        </div>
        {omeMeetOrgUnitNonAthleteEntryState.arrayData?.length > 15 && omeMeetOrgUnitNonAthleteEntryState.isArrayLoading === false &&
          <div className='visible-md visible-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton className="usas-extra-top-margin" type="button" onClick={onAddCoach}>Select Coaches</ActionIntraPageButton>
            </div>
          </div>}
        {omeMeetOrgUnitNonAthleteEntryState.arrayData?.length > 0 && omeMeetOrgUnitNonAthleteEntryState.isArrayLoading === false &&
          <div className='hidden-md hidden-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton className="usas-extra-top-margin" type="button" onClick={onAddCoach}>Select Coaches</ActionIntraPageButton>
            </div>
          </div>}
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Name:</strong> {modalState?.coachName}</p>
            <p><strong>Phone Number:</strong> {modalState?.phoneNumber}</p>
            <p><strong>Email:</strong> {modalState?.email}</p>
            <p><strong>Is In Good Standing?:</strong> {modalState?.isInGoodStanding}</p>
            <p><strong>Good Standing Expiration Date:</strong> {modalState?.isInGoodStandingExpirationDate}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteCoach(e, modalState.orgUnitNonAthleteEntryId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryState.isSaving} />
    </Fragment>
  );
};

export default OrgUnitAdminCoachesWrite;