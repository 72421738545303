import { useContext } from 'react';

import LaneSheetHeatAthletesData from './LaneSheetHeatAthletesData';

import { LaneSheetHeatAthletesStateContext } from './LaneSheetHeatAthletesContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const useLaneSheetHeatAthletesData = () => {
  const [laneSheetHeatAthletesState, setLaneSheetHeatAthletesState] = useContext(LaneSheetHeatAthletesStateContext);

  const getLaneSheetHeatAthletes = (laneSheetHeatId) => {
    return LaneSheetHeatAthletesData.getLaneSheetHeatAthletesData(laneSheetHeatId, laneSheetHeatAthletesState, setLaneSheetHeatAthletesState);
  };

  const putLaneSheetHeatAthletes = (laneSheetHeatId, laneSheetHeatAthletesArray) => {
    return LaneSheetHeatAthletesData.putLaneSheetHeatAthletesData(laneSheetHeatId, laneSheetHeatAthletesArray, laneSheetHeatAthletesState, setLaneSheetHeatAthletesState);
  };

  const resetLaneSheetHeatAthletesState = () => {
    setLaneSheetHeatAthletesState({
      ...BASIC_INITIAL_STATE
    });
  };

  return {
    laneSheetHeatAthletesState,
    resetLaneSheetHeatAthletesState,
    getLaneSheetHeatAthletes,
    putLaneSheetHeatAthletes
  };
};

export default useLaneSheetHeatAthletesData;