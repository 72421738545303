import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import MyMeetsNavLinks from '../MyMeetsNavLinks';
import MeetNavLinks from '../../meet/NavLinks';

import validate from './MeetTeamEntryValidation';

import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useOmeMeetOrgUnitEntryData from '../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetHostData from '../../../state/omeMeetHost/UseOmeMeetHostData';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useReportPeriodSeasonData from '../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import UseForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate, formatDateTime } from '../../../../common/utils/DateFunctions';

const TAXONOMIES = ['OUEntryDetail'];
const SCOPE = 'OnlineMeetEntry';

const INITIAL_FORM_STATE = {
  meetName: '',
  teamEntryStatusId: Constants.DEFAULT_ID,
  reportPeriodId: Constants.DEFAULT_ID,
  reportPeriodName: '',
  sortBy: 'MeetName',
  isCurrent: true
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Team Entry?',
  meetHost: '',
  meetName: '',
  meetDates: '',
  teamName: '',
  teamEntryStatus: '',
  omeMeetId: Constants.DEFAULT_ID,
  omeMeetOrgUnitEntryId: Constants.DEFAULT_ID
};

const useTeamEntry = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ isInitialPageLoadComplete: false, tryGet: false, teamEntry: {} });
  const [omeMeetTeamEntryStatusState] = useState([
    { id: Constants.DEFAULT_ID, name: "--" },
    { id: 1, name: Constants.WORKFLOW_STATUS_IN_PROGRESS },
    { id: 2, name: Constants.WORKFLOW_STATUS_SUBMITTED },
    { id: 3, name: Constants.WORKFLOW_STATUS_UNATTACHED }
  ]);
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { formState, setFormState, errorState, setErrors, onFormValueChanged, onValueTextPairChanged, handleSubmit, setFormData } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { omeMeetTeamEntryState, omeMeetTeamEntryFiltersState, searchMeetTeamEntry } = useOmeMeetTeamEntryData();
  const { omeMeetHostFiltersState } = useOmeMeetHostData();
  const { omeMeetOrgUnitEntryState, deleteOmeMeetOrgUnitEntry } = useOmeMeetOrgUnitEntryData();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();

  const onEnterANewMeetButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(MeetNavLinks.MEET_SEARCH);
  };

  const onAddEntryClicked = () => {
    console.log('TODO');
  };

  const onEditEntryClicked = (e, teamEntry) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    getContextSecurity(teamEntry?.omeMeetId, TAXONOMIES, SCOPE);
    setState({ ...state, tryRedirect: true, teamEntry: teamEntry });
  };

  const onViewEntryClicked = (e, teamEntry) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    getContextSecurity(teamEntry?.omeMeetId, TAXONOMIES, SCOPE);
    setState({ ...state, tryRedirect: true, teamEntry: teamEntry });
  };

  const onDeleteEntryClicked = (e, omeMeetOrgUnitEntry) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      displayPopUp: true,
      meetHost: omeMeetOrgUnitEntry?.meetHost || '',
      meetName: omeMeetOrgUnitEntry?.meetName || '',
      meetDates: `${omeMeetOrgUnitEntry?.startDate ? formatDate(omeMeetOrgUnitEntry?.startDate) : ''} - ${omeMeetOrgUnitEntry?.endDate ? formatDate(omeMeetOrgUnitEntry?.endDate) : ''}`,
      teamName: `${omeMeetOrgUnitEntry?.orgUnitName || ''} ${omeMeetOrgUnitEntry?.orgUnitName === 'Unattached' ? ' - ' + omeMeetOrgUnitEntry?.parentOrgUnitName : ''} ${omeMeetOrgUnitEntry?.parentOrgUnitCode ? '(' + omeMeetOrgUnitEntry?.parentOrgUnitCode + ')' : ''}`,
      teamEntryStatus: `${omeMeetOrgUnitEntry.teamEntryStatus || ''} ${omeMeetOrgUnitEntry.orgUnitSubmissionDateTime ? '- ' + formatDateTime(omeMeetOrgUnitEntry.orgUnitSubmissionDateTime) + ' ' + omeMeetOrgUnitEntry.timeZone : ''}`,
      omeMeetId: omeMeetOrgUnitEntry?.omeMeetId || Constants.DEFAULT_ID,
      omeMeetOrgUnitEntryId: omeMeetOrgUnitEntry?.omeMeetOrgUnitEntryId || Constants.DEFAULT_ID
    });
  };

  const onDeleteTeamEntry = (e, omeMeetId, omeMeetOrgUnitEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOmeMeetOrgUnitEntry(omeMeetId, omeMeetOrgUnitEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    searchMeetTeamEntry({ orgUnitId: selectOrgUnitState.orgUnitId, isCurrent: true }, formState.sortBy || '');
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  };

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoaded === true) {
      if (omeMeetTeamEntryFiltersState.filterObject &&
        Object.keys(omeMeetTeamEntryFiltersState.filterObject).length > 0) {
        setFormData({
          ...formState,
          meetName: omeMeetTeamEntryFiltersState.filterObject?.meetName || '',
          teamEntryStatusId: omeMeetTeamEntryFiltersState.filterObject?.teamEntryStatus ? (omeMeetTeamEntryStatusState.find(x => x.name === omeMeetTeamEntryFiltersState.filterObject?.teamEntryStatus)?.id || Constants.DEFAULT_ID) : Constants.DEFAULT_ID,
          reportPeriodId: omeMeetTeamEntryFiltersState.filterObject?.reportPeriodId || Constants.DEFAULT_ID,
          reportPeriodName: omeMeetTeamEntryFiltersState.filterObject?.reportPeriodId ? reportPeriodSeasonState.arrayData.find(x => x.reportPeriodId === omeMeetTeamEntryFiltersState.filterObject?.reportPeriodId)?.reportPeriodName || '' : '',
          isCurrent: omeMeetTeamEntryFiltersState.filterObject?.isCurrent ?? true,
          sortBy: omeMeetTeamEntryFiltersState.sortBy || 'MeetName'
        });
        setState({ ...state, isInitialPageLoadComplete: true });
      }
      else {
        setState({ ...state, isInitialPageLoadComplete: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState]);

  useEffect(() => {
    if (state.isInitialPageLoadComplete === true) {
      if ((omeMeetTeamEntryState.isArrayLoaded === false || state.tryGet === true) && selectOrgUnitState?.orgUnitId && omeMeetOrgUnitEntryState.isSaving === false &&
        reportPeriodSeasonState.isArrayLoaded === true) {
        filter(formState);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetTeamEntryState, omeMeetOrgUnitEntryState, state.isInitialPageLoadComplete, state.tryGet, selectOrgUnitState.orgUnitId, reportPeriodSeasonState]);

  useEffect(() => {
    if (state.tryRedirect === true && Object.keys(state.teamEntry).length > 0 && omeMeetTeamEntryState.isSaving === false) {
      navigate(MyMeetsNavLinks.OME_LOADING, { state: { teamEntry: state.teamEntry, selectOrgUnitState, omeMeetHostFiltersState: omeMeetHostFiltersState, omeMeetTeamEntryFiltersState: omeMeetTeamEntryFiltersState } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetTeamEntryState, state.tryRedirect, state.teamEntry]);

  function submitFormCallback(formState) {
    filter(formState);
  };

  function filter(formState) {
    searchMeetTeamEntry({
      orgUnitId: selectOrgUnitState.orgUnitId,
      meetName: formState.meetName.trim().length > 0 ? formState.meetName.trim() : undefined,
      reportPeriodId: formState.reportPeriodId > 0 ? formState.reportPeriodId : undefined,
      teamEntryStatus: formState.teamEntryStatusId > 0 ? (omeMeetTeamEntryStatusState.find(x => x.id === formState.teamEntryStatusId)?.name || undefined) : undefined,
      isCurrent: formState.isCurrent
    }, formState.sortBy || '');
  };

  return {
    formState,
    errorState,
    modalState,
    omeMeetOrgUnitEntryState,
    onFormValueChanged,
    onValueTextPairChanged,
    omeMeetTeamEntryState,
    USAS_ORGANIZATION_ID,
    onAddEntryClicked,
    onEditEntryClicked,
    onDeleteEntryClicked,
    onDeleteTeamEntry,
    onFilterClicked,
    onClearFilterClicked,
    onModalCanceled,
    displayLoadingPopUp: omeMeetTeamEntryState?.isArrayLoading === true || reportPeriodSeasonState.isArrayLoading ||
      contextSecurityState.isObjLoading,
    omeMeetTeamEntryStatusState,
    onEnterANewMeetButtonClicked,
    onViewEntryClicked
  };
};

export default useTeamEntry;