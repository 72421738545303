import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLaneSheetData from '../../../state/laneSheet/UseLaneSheetData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

const TAXONOMIES = ['LaneSheetDetail'];
const SCOPE = 'LaneSheet';

const sortByOptions = [
  { id: 1, name: "Meet Name (A-Z)", value: 'meetName' },
  { id: 2, name: "Meet Date (Oldest to Most Recent)", value: 'dateRange' }];

const useLaneSheetLanding = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { laneSheetState, laneSheetLandingFilterState, laneSheetLandingSearchState, searchLaneSheetLanding,
    toggleLaneSheetLandingFilters, resetLaneSheetState, getLaneSheetMeetInfo
  } = useLaneSheetData();

  const onSubmitFormCallback = (formState) => {
    let sortBy = undefined;

    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    const searchLaneSheetLandingPromise = searchLaneSheetLanding(createSearchFilterObj(formState), sortBy);

    if (searchLaneSheetLandingPromise !== null) {
      searchLaneSheetLandingPromise.catch((e) => {
        //TODO state error
      });
    }
  };

  const onEditLaneSheetMeetClicked = (laneSheetMeetId) => {
    if (laneSheetMeetId > 0) {
      getContextSecurity(laneSheetMeetId, TAXONOMIES, SCOPE);

      const getLaneSheetMeetInfoPromise = getLaneSheetMeetInfo(laneSheetMeetId);

      if (getLaneSheetMeetInfoPromise !== null) {
        getLaneSheetMeetInfoPromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.LANE_SHEET_MEET_INFO);
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  const onAddLaneSheetMeetClicked = (meet) => {
    navigate(NavLinks.LANE_SHEET_ADD, { state: { meet } });
  };

  useEffect(() => {
    resetLaneSheetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: formState.orgUnit[0].id,
      meetName: formState.meetName.trim() || undefined,
      meetClassificationId: (formState.meetClassification !== undefined && formState.meetClassification.length > 0) ? formState.meetClassification[0].id : undefined,
      hasLaneSheet: formState.hasLaneSheet !== '' ? formState.hasLaneSheet : undefined,
    };
  }

  return {
    isLoading: laneSheetLandingSearchState.isArrayLoading || laneSheetState.isObjLoading,
    searchFilterObject: laneSheetLandingFilterState.filterObject || {},
    showFilters: laneSheetLandingFilterState?.showFilters,
    gridData: laneSheetLandingSearchState.arrayData || [],
    onSubmitFormCallback,
    toggleLaneSheetLandingFilters,
    onEditLaneSheetMeetClicked,
    onAddLaneSheetMeetClicked
  };
};

export default useLaneSheetLanding;