import Constants from '../../utils/Constants';
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const GetOrgRoleDataByRoleGroupIdOrgLevelIdOrgUnitIdData = (roleGroupId, orgLevelId, orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && roleGroupId > 0 && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      roleGroupId,
      orgLevelId: orgLevelId || Constants.DEFAULT_ID,
      orgUnitId: orgUnitId || '',
      message: 'Loading...'
    };
    setState(newState);

    const orgLevelIdForUrl = orgLevelId ? encodeURIComponent(orgLevelId) : '';
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const url = `/OrgRole/OrgRoleGroup/${roleGroupIdForUrl}${orgLevelIdForUrl ? `?orgLevelId=${orgLevelIdForUrl}` : orgUnitIdForUrl ? `?orgUnitId=${orgUnitIdForUrl}` : ''}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            roleGroupId,
            arrayData: data,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOrgRoleDataByRoleGroupIdOrgLevelIdOrgUnitIdData;