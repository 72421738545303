import { Fragment } from 'react';

import { formatPlaceString } from '../utils/MeetUtils';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableSingleRow = ({ org }) => (
  <tr>
    <td>{org.qualifiedOrgUnitCode}</td>
  </tr>
);

const GridRowDetailTableRelay = ({ relayOrganization, relayTeam }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Organization</th>
        <th>1st Leg</th>
        <th>2nd Leg</th>
        <th>3rd Leg</th>
        <th>4th Leg</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{relayOrganization}</td>
        {Array.isArray(relayTeam) && relayTeam.map((swimmer, i) =>
          <td key={i}>{swimmer.firstName} {swimmer.lastName} ({swimmer.age})</td>)}
      </tr>
    </tbody>
  </table>
);

const GridRowDetailTableSingle = ({ individualOrganization }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Organization</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(individualOrganization) && individualOrganization.map((org, i) =>
        <DetailTableSingleRow key={i} org={org} />)}
    </tbody>
  </table>
);

const GridRow = ({ time, onEdit, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr key={time.swimTimeId}>
        {onEdit ?
          <td><a onClick={() => onEdit(time.swimTimeId)}>{time.time ? formatTimeForDisplay(time.time) : '--'}</a></td> :
          <td>{time.time ? formatTimeForDisplay(time.time) : '--'}</td>
        }
        <td>{time.competitor || ''}</td>
        <td>{time.age || ''}</td>
        <td>{time.eventGender || ''}</td>
        <td>{formatDate(time.swimDate)}</td>
        <td>{time.eventCourse || ''}</td>
        <td>{time.session || ''}</td>
        <td>{formatPlaceString(time.finishPosition) || ''}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, time.swimTimeId)}>
            {expandedId === time.swimTimeId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(time.individualOrganization) && time.individualOrganization.length > 0 && expandedId === time.swimTimeId &&
        <tr className={global.Expanded}>
          <td colSpan="9">
            <GridRowDetailTableSingle individualOrganization={time.individualOrganization} />
          </td>
        </tr>}
      {Array.isArray(time.relayTeam) && time.relayTeam.length > 0 && expandedId === time.swimTimeId &&
        <tr className={global.Expanded}>
          <td colSpan="9">
            <GridRowDetailTableRelay
              relayOrganization={time.relayOrganization}
              relayTeam={time.relayTeam} />
          </td>
        </tr>}
    </Fragment>
  );
};

const MeetTimesLargeGrid = ({ gridData, isLoading, onEdit, expandedId, onClick }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Time'} columnField={'time'}
            sortType={SGConstants.SORT_TYPE_TIME} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competitor'} columnField={'competitor'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Age'} columnField={'age'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event Gender'} columnField={'eventGender'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Date'} columnField={'swimDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event-Course'} columnField={'eventCourse'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Session'} columnField={'session'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Place'} columnField={'finishPosition'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="9">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0 ?
            sortableGridState.sortedGridData.map((time, i) =>
              <GridRow key={i} time={time} onEdit={onEdit} expandedId={expandedId} onClick={onClick} />)
            :
            <tr><td colSpan="9">No Times Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default MeetTimesLargeGrid;