import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOmeMeetOrgUnitEntryPurchaseByIdData from './GetOmeMeetOrgUnitEntryPurchaseByIdData';
import getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryIdData from './GetOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryIdData';
import postOmeMeetOrgUnitEntryPurchaseData from './PostOmeMeetOrgUnitEntryPurchaseData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitEntryPurchaseData = {
  INITIAL_STATE, getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryIdData, getOmeMeetOrgUnitEntryPurchaseByIdData, 
  postOmeMeetOrgUnitEntryPurchaseData
};

export default OmeMeetOrgUnitEntryPurchaseData;