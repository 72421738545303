export const CONTINUE = 'Continue';
export const EXIT = 'Exit';
export const SAVE_AND_EXIT = 'Save & Exit';
export const SUBMIT_AND_EXIT = 'Submit & Exit';
export const BACK = 'Back';
export const ADD = 'Add';
export const EDIT = 'Edit';
export const EXIT_CONFIRMATION = 'Exit Confirmation';
export const IN_PROGRESS_STATUS = 'In Progress';
export const MISSING_REQUIRED_INFORMATION = 'Missing Required Information';
export const UNSAVED_CHANGES = 'Any unsaved changes you have made on this page will not be saved.';

const ClubRegistrationConstants = {
  CONTINUE,
  EXIT,
  SAVE_AND_EXIT,
  SUBMIT_AND_EXIT,
  BACK,
  ADD,
  EDIT,
  EXIT_CONFIRMATION,
  IN_PROGRESS_STATUS,
  MISSING_REQUIRED_INFORMATION,
  UNSAVED_CHANGES
};

export default ClubRegistrationConstants;