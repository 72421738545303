import React, { Fragment } from 'react';
import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import OrgUnitAdminRosterEntriesLargeGrid from './OrgUnitAdminRosterEntriesLargeGrid';
import OrgUnitAdminRosterEntriesSmallGrid from './OrgUnitAdminRosterEntriesSmallGrid';

const OrgUnitAdminRosterEntriesGrid = ({ gridData, isLoading, onEditAthleteClicked, onUpdateAthleteTimes }) => {
  const { onClick, expandedId } = useExpandedRow();
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            <p>{gridData.length === 1 ? `${gridData.length} Athlete` : `${gridData.length} Athletes`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <OrgUnitAdminRosterEntriesLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditAthleteClicked={onEditAthleteClicked}
        onClick={onClick}
        expandedId={expandedId}
        onUpdateAthleteTimes={onUpdateAthleteTimes}
      />
      <OrgUnitAdminRosterEntriesSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditAthleteClicked={onEditAthleteClicked}
        onClick={onClick}
        expandedId={expandedId}
        onUpdateAthleteTimes={onUpdateAthleteTimes}
      />
    </Fragment>
  )
};

export default OrgUnitAdminRosterEntriesGrid;