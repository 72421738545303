import React, { Fragment } from 'react';

import useOmeAgeGroups from './UseOmeAgeGroups';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import OmeAgeGroupsGrid from './OmeAgeGroupsGrid';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

const OmeAgeGroups = () => {
  const {
    state,
    modalState,
    omeMeetState,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddAgeGroup,
    onEditAgeGroup,
    onDeleteAgeGroupClicked,
    onDeleteAgeGroup,
    onModalCanceled,
    allowEdits
  } = useOmeAgeGroups();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_AGE_GROUPS}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={state.error}>
        {allowEdits() === true ?
          <div className="row">
            <div className="col-xs-12">
              <ActionIntraPageButton type="button" onClick={onAddAgeGroup}>Add Age Group</ActionIntraPageButton>
            </div>
            <div className="col-xs-12">
              <OmeAgeGroupsGrid
                state={omeMeetState}
                gridState={state}
                onEdit={onEditAgeGroup}
                onDelete={onDeleteAgeGroupClicked}
              />
            </div>
          </div> :
          <div className="row">
            <div className='col-xs-12 usas-extra-top-margin'>
              <Headings.H6>Age Groups</Headings.H6>
            </div>
            <div className="col-xs-12">
              <OmeAgeGroupsGrid
                state={omeMeetState}
                gridState={state}
              />
            </div>
          </div>}
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Age Group Name:</b>&nbsp;{modalState.ageGroupName}</p>
              <p><b>Age Range:</b>&nbsp;{modalState.minAge} - {modalState.maxAge}</p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onDeleteAgeGroup(e, modalState.meetAgeGroupId)}>Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeAgeGroups;