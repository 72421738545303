import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../../common/state/searchMeet/UseSearchMeetData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';
import { formatDate, formatDateTime } from '../../../../common/utils/DateFunctions';
import useOmeMeetInvitationsData from '../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Team Entry?',
  meetHost: '',
  meetName: '',
  meetDates: '',
  teamName: '',
  teamEntryStatus: '',
  omeMeetId: Constants.DEFAULT_ID,
  omeMeetOrgUnitEntryId: Constants.DEFAULT_ID
};

const useMeetTeamEntry = () => {
  const { environmentVariableState, MY_MEETS_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const navigate = useNavigate();
  const [state, setState] = useState({ tryGet: false, availableOrgUnits: [] });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { meetState } = useMeetData();
  const { omeMeetState, setOmeMeetState, clearOmeMeetObjData, clearOmeMeetArrayData, getOmeMeetOrgUnitEntryPermission } = useOmeMeetData();
  const { clearOmeMeetInvitationsArrayData } = useOmeMeetInvitationsData();
  const { omeMeetOrgUnitEntryState, getOmeMeetOrgUnitEntryById, deleteOmeMeetOrgUnitEntry } = useOmeMeetOrgUnitEntryData();
  const { omeMeetTeamEntryState, getOmeMeetTeamEntry, clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
  const { personOrgRoleState, getPersonOrgRoleByRoleGroup } = usePersonOrgRoleData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();

  const onEnterMeetClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_ENTRY_TEAM_SELECTION, { state: { availableOrgUnits: state.availableOrgUnits } });
  };

  const onStartEntryForATeamClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_ENTRY_TEAM_HQ_SELECTION);
  }

  const onViewTeamEntry = (e, teamEntry) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setOmeMeetState({ ...omeMeetState, route: 'meet' });
    getOmeMeetOrgUnitEntryById(omeMeetState.objData.omeMeetId, teamEntry.omeMeetOrgUnitEntryId);
    setSearchMeetState({ ...searchMeetState, showMeetHeader: false });

    navigate(NavLinks.OU_ADMIN_LANDING, { state: { isReadOnly: teamEntry?.isReadOnly } });
  };

  const onEditTeamEntry = (e, teamEntry) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setOmeMeetState({ ...omeMeetState, route: 'meet' });
    getOmeMeetOrgUnitEntryById(omeMeetState.objData.omeMeetId, teamEntry.omeMeetOrgUnitEntryId);
    setSearchMeetState({ ...searchMeetState, showMeetHeader: false });

    navigate(NavLinks.OU_ADMIN_LANDING, { state: { isReadOnly: teamEntry?.isReadOnly } });
  };

  const onDeleteTeamEntryClicked = (e, teamEntry) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      displayPopUp: true,
      meetHost: meetState.objData.hostOrgUnit?.orgUnitName || '',
      meetName: meetState.objData.meetName || '',
      meetDates: `${meetState.objData?.startDate ? formatDate(meetState.objData?.startDate) : ''} - ${meetState.objData?.endDate ? formatDate(meetState.objData?.endDate) : ''}`,
      teamName: `${teamEntry.orgUnitName || ''} ${teamEntry?.orgUnitName === 'Unattached' ? ' - ' + teamEntry?.parentOrgUnitName : ''} ${teamEntry.parentOrgUnitCode ? '(' + teamEntry.parentOrgUnitCode + ')' : ''}`,
      teamEntryStatus: `${teamEntry.status || ''} ${teamEntry.orgUnitSubmissionDatetime ? '- ' + formatDateTime(teamEntry.orgUnitSubmissionDatetime) + ' ' + teamEntry.timeZone : ''}`,
      omeMeetId: omeMeetState.objData.omeMeetId || Constants.DEFAULT_ID,
      omeMeetOrgUnitEntryId: teamEntry.omeMeetOrgUnitEntryId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteTeamEntry = (e, omeMeetId, omeMeetOrgUnitEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteOmeMeetOrgUnitEntry(omeMeetId, omeMeetOrgUnitEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  }

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      getPersonOrgRoleByRoleGroup(0, MY_MEETS_ROLE_GROUP_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState])

  useEffect(() => {
    if (meetState.isObjLoaded === true && omeMeetState.isObjLoaded === true &&
      omeMeetState.objData?.omeMeetId) {
      if (omeMeetTeamEntryState.isArrayLoading === false && (omeMeetTeamEntryState.isArrayLoaded === false ||
        omeMeetTeamEntryState.omeMeetId !== omeMeetState.objData.omeMeetId)) {
        getOmeMeetTeamEntry(omeMeetState.objData.omeMeetId);
      }
      if (omeMeetState.isArrayLoaded === false && omeMeetState.isArrayLoading === false &&
        personOrgRoleState.isArrayLoaded === true) {
        getOmeMeetOrgUnitEntryPermission(omeMeetState.objData.omeMeetId, getUserOrgUnits());
      }
    }
    else if ((meetState.isObjLoaded === true && omeMeetState.isObjLoaded === true &&
      omeMeetState.objData.meetId !== meetState.objData.meetId) || (meetState.isObjLoaded === true &&
        !meetState.omeMeetId)) {
      clearOmeMeetObjData();
      clearOmeMeetArrayData();
      clearOmeMeetTeamEntryArrayData();
      clearOmeMeetInvitationsArrayData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, omeMeetState.isObjLoaded, personOrgRoleState.isArrayLoaded]);

  useEffect(() => {
    if (state.tryGet === true && omeMeetState.isObjLoaded === true &&
      omeMeetState.objData?.omeMeetId && omeMeetOrgUnitEntryState.isSaving === false
      && personOrgRoleState.isArrayLoaded === true) {
      getOmeMeetTeamEntry(omeMeetState.objData.omeMeetId);
      getOmeMeetOrgUnitEntryPermission(omeMeetState.objData.omeMeetId, getUserOrgUnits());
      setState({ ...state, tryGet: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, omeMeetState.isObjLoaded, omeMeetOrgUnitEntryState.isSaving, personOrgRoleState.isArrayLoaded]);

  useEffect(() => {
    if (omeMeetState.isArrayLoaded === true && personOrgRoleState.isArrayLoaded === true && omeMeetTeamEntryState.isArrayLoaded === true) {
      const availableOrgUnits = unusedOrgUnitsAvailableToUser();
      setState({ ...state, availableOrgUnits: availableOrgUnits })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isArrayLoaded, personOrgRoleState.isArrayLoaded, omeMeetTeamEntryState.isArrayLoaded])

  function unusedOrgUnitsAvailableToUser() {
    const unusedOrgUnitsAvailableToUser = [];
    if (omeMeetTeamEntryState.arrayData?.teamEntry?.length > 0) {
      for (let i = 0; i < omeMeetState.arrayData.length; i++) {
        const orgUnitEntryExists = omeMeetTeamEntryState.arrayData?.teamEntry?.find(x => x.orgUnitId === omeMeetState.arrayData[i]?.orgUnitId);
        if (orgUnitEntryExists === undefined && omeMeetState.arrayData[i]?.isAllowedToEnterMeet === true) {
          const personOrgRole = personOrgRoleState.arrayData.find(x => x.orgUnitId === omeMeetState.arrayData[i]?.orgUnitId);
          if (personOrgRole) {
            unusedOrgUnitsAvailableToUser.push({ label: personOrgRole.orgUnitName, value: personOrgRole.orgUnitId });
          }
        }
      }
    }
    else {
      for (let i = 0; i < omeMeetState.arrayData.length; i++) {
        if (omeMeetState.arrayData[i]?.isAllowedToEnterMeet === true) {
          const personOrgRole = personOrgRoleState.arrayData.find(x => x.orgUnitId === omeMeetState.arrayData[i]?.orgUnitId);
          if (personOrgRole) {
            unusedOrgUnitsAvailableToUser.push({ label: personOrgRole.orgUnitName, value: personOrgRole.orgUnitId });
          }
        }
      }
    }

    return unusedOrgUnitsAvailableToUser;
  }

  function getUserOrgUnits() {
    const userOrgUnitOptions = personOrgRoleState.arrayData.map((personOrgRole) => {
      return {
        orgUnitId: personOrgRole.orgUnitId || '',
      }
    })
    //remove any duplicate options
    const uniqueUserOrgUnitOptions = userOrgUnitOptions.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.orgUnitId === value.orgUnitId
      ))
    )

    return uniqueUserOrgUnitOptions;
  }

  return {
    state,
    omeMeetState,
    omeMeetOrgUnitEntryState,
    omeMeetTeamEntryState,
    personOrgRoleState,
    onViewTeamEntry,
    onEditTeamEntry,
    onEnterMeetClicked,
    onStartEntryForATeamClicked,
    onDeleteTeamEntryClicked,
    onDeleteTeamEntry,
    onModalCanceled,
    modalState
  }
}

export default useMeetTeamEntry;