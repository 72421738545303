import React, { Fragment } from 'react';

import useMeetRecognition from './UseMeetRecognition';

import MeetRecognitionGrid from './MeetRecognitionGrid';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';

const MeetRecognition = () => {
  const {
    gridState,
    onOrgCheckbox,
    onRecordsCheckbox,
    onTopTimesCheckbox,
    onMeetEntryCheckbox,
    onSaveButtonClick,
    onCancelButtonClick
  } = useMeetRecognition();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Recognition</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12'>
          <MeetRecognitionGrid
            gridData={gridState.arrayData}
            isLoading={gridState.isArrayLoading}
            onOrgCheckbox={onOrgCheckbox}
            onRecordsCheckbox={onRecordsCheckbox}
            onTopTimesCheckbox={onTopTimesCheckbox}
            onMeetEntryCheckbox={onMeetEntryCheckbox}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className='col-xs-12 usas-extra-top-margin'>
          <PrimaryButton type="button" onClick={onSaveButtonClick}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onCancelButtonClick}>Cancel</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={gridState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={gridState.isSaving} />
    </Fragment>);
}

export default MeetRecognition;