import React, { createContext, useState } from 'react';

import EventCompetitionGenderTypeData from './EventCompetitionGenderTypeData';

export const EventCompetitionGenderTypeStateContext = createContext();

const EventCompetitonGenderTypeContextProvider = ({ children }) => {
  const stateHook = useState(EventCompetitionGenderTypeData.INITIAL_STATE);

  return (
    <EventCompetitionGenderTypeStateContext.Provider value={stateHook}>
      {children}
    </EventCompetitionGenderTypeStateContext.Provider>
  );
};

export default EventCompetitonGenderTypeContextProvider;