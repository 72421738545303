import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetSessionByMeetIdData from './GetMeetSessionByMeetIdData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  dataAsOptionsWithNames: []
};

const MeetSessionData = {
  getMeetSessionByMeetIdData,
  INITIAL_STATE
};

export default MeetSessionData;