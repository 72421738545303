import { Fragment } from "react";

import useSearchEntryContactPopup from './UseSearchEntryContactPopup';

import SearchMemberPopupGrid from "./SearchMemberPopupGrid";

import Input from "../../inputs/Input";
import LookupIcon from "../../icons/LookupIcon";
import PopUpModal from "../../dialogs/PopUpModal";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";

const SearchEntryContactPopup = ({ label = "Member ID", memberIdName = "memberId", orgUnitId, organzationName,
  formState, errorState, setFormData, onFormValueChanged }) => {
  const {
    state,
    memberLookupState,
    popupFormState,
    popupErrorState,
    updatePopupFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectPerson
  } = useSearchEntryContactPopup(formState, setFormData, memberIdName, orgUnitId, organzationName);

  return (
    <Fragment>
      <div className="usas-extra-bottom-margin">
        <Input
          label={label}
          name="memberId"
          value={formState[memberIdName]}
          error={errorState[memberIdName]}
          message={errorState[memberIdName]}
          onChange={(value) => { onFormValueChanged(memberIdName, value); }}
        />
        <a onClick={() => onDisplayPopupClicked()}><LookupIcon /></a>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={400}
        title={'Entry Contact Member ID Lookup'}
        displayPopUp={state.displayPopup === true}
        onModalCanceled={onHidePopupClicked} >
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <SearchMemberPopupGrid
              data={memberLookupState.arrayData}
              isLoading={memberLookupState.isArrayLoading}
              onSelectPerson={onSelectPerson} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Input
              label="First Name*"
              name="firstName"
              value={popupFormState.firstName}
              error={popupErrorState.firstName}
              message={popupErrorState.firstName}
              onChange={(value) => { updatePopupFormState('firstName', value) }} />
            <Input
              label="Last Name*"
              name="lastName"
              value={popupFormState.lastName}
              error={popupErrorState.lastName}
              message={popupErrorState.lastName}
              onChange={(value) => { updatePopupFormState('lastName', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={handleSubmit}>Search</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onHidePopupClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default SearchEntryContactPopup;