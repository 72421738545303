import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getSelectionMeetEntriesData = (selectionMeetId, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const filterObject = JSON.stringify({ selectionMeetId, isBasedOn: 1, limitToSelectionStandard: 0 });
    //const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsRules/searchevententries?filter=${encodeURIComponent(filterObject)}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const SelectionsMeetEntriesData = {
  getSelectionMeetEntriesData
};

export default SelectionsMeetEntriesData;