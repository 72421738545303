import { useContext } from 'react';

import { OmeMeetOrgUnitNonAthleteEntryCoachesSelectionStateContext } from './OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider';

import OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData from './OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData';

const useOmeMeetOrgUnitNonAthleteEntryCoachesSelectionData = () => {
  const [omeMeetOrgUnitNonAthleteEntryCoachesSelectionState, setOmeMeetOrgUnitNonAthleteEntryCoachesSelectionState] = useContext(OmeMeetOrgUnitNonAthleteEntryCoachesSelectionStateContext);

  const getOrgUnitNonAthleteEntryCoachesSelection = (omeMeetOrgUnitEntryId, filterObj) =>
    OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData.getOrgUnitNonAthleteEntryCoachesSelectionData(omeMeetOrgUnitEntryId, JSON.stringify(filterObj), omeMeetOrgUnitNonAthleteEntryCoachesSelectionState, setOmeMeetOrgUnitNonAthleteEntryCoachesSelectionState);

  const postOrgUnitNonAthleteEntryCoachesSelection = (omeMeetOrgUnitEntryId, orgUnitNonAthleteEntryArray) =>
    OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData.postOrgUnitNonAthleteEntryCoachesSelectionData(omeMeetOrgUnitEntryId, orgUnitNonAthleteEntryArray, omeMeetOrgUnitNonAthleteEntryCoachesSelectionState, setOmeMeetOrgUnitNonAthleteEntryCoachesSelectionState);

  const clearOmeMeetOrgUnitNonAthleteEntryCoachesSelectionArrayData = () => {
    setOmeMeetOrgUnitNonAthleteEntryCoachesSelectionState({
      ...omeMeetOrgUnitNonAthleteEntryCoachesSelectionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    omeMeetOrgUnitNonAthleteEntryCoachesSelectionState,
    setOmeMeetOrgUnitNonAthleteEntryCoachesSelectionState,
    getOrgUnitNonAthleteEntryCoachesSelection,
    postOrgUnitNonAthleteEntryCoachesSelection,
    clearOmeMeetOrgUnitNonAthleteEntryCoachesSelectionArrayData
  };
};

export default useOmeMeetOrgUnitNonAthleteEntryCoachesSelectionData;