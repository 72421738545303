/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Alternate Event</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (<Fragment>
          {state.isObjLoading === true ?
            <tr>
              <td colSpan="2">Loading...</td>
            </tr> :
            <tr>
              <td colSpan="2">No Alternate Events</td>
            </tr>
          }
        </Fragment>
        ) : gridState.gridData?.length > 0 ?
          gridState.gridData?.map((alternateEvent, i) => (
            < tr key={alternateEvent.eventId || i} >
              <td>{alternateEvent.event?.eventName || ''}</td>
              <td>
                {onDelete &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, alternateEvent)}>
                    <EditIcon />
                  </button>}
                {onEdit &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, alternateEvent)}>
                    <DeleteIcon />
                  </button>}
              </td>
            </tr>)
          ) : (
            <tr>
              <td colSpan="2">No Alternate Events</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ state, gridState, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Alternate Events</div>
            }
          </div>
        </div>
      )
      : gridState.gridData.length > 0 ?
        gridState.gridData.map((alternateEvent, i) => (
          <div className={global.SmallTableRow} key={alternateEvent.eventId || i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{alternateEvent.event?.eventName || <span>&nbsp;</span>}</span>&nbsp;
              {onDelete &&
                <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, alternateEvent)}>
                  <DeleteIcon />
                </button>}
              {onEdit &&
                <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, alternateEvent)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Alternate Event</div>
                <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{alternateEvent.event?.eventName || <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )
        )
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Alternate Events</div>
            </div>
          </div>
        )}
  </div >
);

const OmeMeetAlternateEventsGrid = ({ omeMeetState, gridState, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={omeMeetState} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={omeMeetState} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default OmeMeetAlternateEventsGrid;