import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const searchNewSelectionData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/OmeSelectionsMeet/search?filters=${encodeURIComponent(filterObject)}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const postSelectionData = (selectionsObj, state, setState) => {
  if (selectionsObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = '/OmeSelectionsMeet';
    return api?.executeObject ? api.executeObject(url, 'POST', selectionsObj) : null;
  }
};

const getExistingSelectionsData = (omeMeetId, meetName, state, setState) => {
  if (omeMeetId > 0) {
    let newState = {
      ...state,
      omeMeetId,
      meetName,
      objData: {}, // objData holds delete result, clear on new get
      isObjLoaded: false,
      isObjLoading: false
    };

    const api = initApi(API_NAMES.MEET, newState, setState);

    const omeMeetIdForUrl = encodeURIComponent(omeMeetId);
    const url = `/OmeSelectionsMeet/OmeMeet/${omeMeetIdForUrl}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getSelectionConfigurationData = (selectionMeetId, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/configuration`;
    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putSelectionConfigurationData = (selectionMeetId, configurationObj, state, setState) => {
  if (selectionMeetId > 0 && configurationObj) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/configuration`;
    return api?.executeObject ? api.executeObject(url, 'PUT', configurationObj) : null;
  }
};

const putSelectionCompleteSetupData = (selectionMeetId, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/completesetup`;
    return api?.executeObject ? api.executeObject(url, 'PUT') : null;
  }
};

const deleteSelectionData = (selectionMeetId, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}`;
    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const SelectionsData = {
  searchNewSelectionData,
  postSelectionData,
  getExistingSelectionsData,
  getSelectionConfigurationData,
  putSelectionConfigurationData,
  putSelectionCompleteSetupData,
  deleteSelectionData
};

export default SelectionsData;