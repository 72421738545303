import { useState } from 'react';

import SelectionsEntriesExportData from './SelectionsEntriesExportData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useSelectionsEntriesExportData = () => {
  const [selectionsEntriesExportState, setSelectionsEntriesExportState] = useState(INITIAL_STATE);

  const getSelectionsEntriesExport = (
    selectionsMeetId, includeAllIndEventEntries, relayAthsIncludeAllIndEventEntries, timeStandardId, 
    includeRelayEntriesForATeamThatHasIndEntrySelections, numberIndividualEventSwimmersSelected) => {
    SelectionsEntriesExportData.getSelectionsEntriesExportData(
      selectionsMeetId, includeAllIndEventEntries, relayAthsIncludeAllIndEventEntries, timeStandardId, 
      includeRelayEntriesForATeamThatHasIndEntrySelections, numberIndividualEventSwimmersSelected,
      selectionsEntriesExportState, setSelectionsEntriesExportState);
  }

  return {
    selectionsEntriesExportState,
    getSelectionsEntriesExport
  };
};

export default useSelectionsEntriesExportData;