import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLaneSheetHeatRelayTeamsData = (laneSheetHeatId, state, setState) => {
  if (laneSheetHeatId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetHeatIdForUrl = encodeURIComponent(laneSheetHeatId);
    const url = `/LaneSheetHeat/${laneSheetHeatIdForUrl}/RelayTeam`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putLaneSheetHeatRelayTeamsData = (laneSheetHeatId, laneSheetHeatRelayTeamsArray, state, setState) => {
  if (laneSheetHeatId > 0 && laneSheetHeatRelayTeamsArray) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetHeatIdForUrl = encodeURIComponent(laneSheetHeatId);
    const url = `/LaneSheetHeat/${laneSheetHeatIdForUrl}/RelayTeam`;

    return api?.executeArray ? api.executeArray(url, 'PUT', laneSheetHeatRelayTeamsArray) : null;
  }
};

const LaneSheetHeatRelayTeamsData = {
  getLaneSheetHeatRelayTeamsData,
  putLaneSheetHeatRelayTeamsData
};

export default LaneSheetHeatRelayTeamsData;