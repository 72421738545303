import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ omeMeetPurchase }) => {
  return (
    <tr key={omeMeetPurchase.omeMeetPurchaseId}>
      <td>{omeMeetPurchase.omeFeeTypeName || ''}</td>
      <td>{omeMeetPurchase.amount !== null && omeMeetPurchase.amount > 0 ? '$' + omeMeetPurchase.amount.toFixed(2) : '$0.00'}</td>
      <td>{omeMeetPurchase.quantity !== null && omeMeetPurchase.quantity >= 0 ? omeMeetPurchase.quantity : '0'}</td>
      <td>{omeMeetPurchase.totalAmount !== null && omeMeetPurchase.totalAmount > 0 ? '$' + omeMeetPurchase.totalAmount.toFixed(2) : '$0.00'}</td>
    </tr>
  );
};

const AthleteUserPurchasesLargeGrid = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Line Item</th>
        <th>Line Item Cost</th>
        <th>Quantity</th>
        <th>Total Cost</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData?.length > 0 ?
          gridData?.map((omeMeetPurchase, i) =>
            <GridRow
              key={i}
              omeMeetPurchase={omeMeetPurchase}
            />)
          :
          <tr>
            <td colSpan={4}>No Purchases</td>
          </tr>
      }
    </tbody>
  </table>
);

export default AthleteUserPurchasesLargeGrid;