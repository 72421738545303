import { useContext, useState } from 'react';

import LaneSheetData from './LaneSheetData';

import { LaneSheetStateContext } from './LaneSheetContextProvider';
import { LaneSheetLandingFilterStateContext } from './LaneSheetLandingFilterContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_LANE_SHEET_LANDING_SEARCH_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetData = () => {
  const [laneSheetState, setLaneSheetState] = useContext(LaneSheetStateContext);
  const [laneSheetLandingFilterState, setLaneSheetLandingFilterState] = useContext(LaneSheetLandingFilterStateContext);
  const [laneSheetLandingSearchState, setLaneSheetLandingSearchState] = useState(INITIAL_LANE_SHEET_LANDING_SEARCH_STATE);

  const searchLaneSheetLanding = (filterObject, sortBy) => {
    setLaneSheetLandingFilterState({ ...laneSheetLandingFilterState, filterObject });
    return LaneSheetData.searchLaneSheetLandingData(JSON.stringify(filterObject), sortBy, laneSheetLandingSearchState, setLaneSheetLandingSearchState);
  };

  const postLaneSheetMeetInfo = (laneSheetMeetInfoObj) => {
    return LaneSheetData.postLaneSheetMeetInfoData(laneSheetMeetInfoObj, laneSheetState, setLaneSheetState);
  };

  const getLaneSheetMeetInfo = (laneSheetMeetId) => {
    return LaneSheetData.getLaneSheetMeetInfoData(laneSheetMeetId, laneSheetState, setLaneSheetState);
  };

  const putLaneSheetMeetInfo = (laneSheetMeetId, laneSheetMeetInfoObj) => {
    return LaneSheetData.putLaneSheetMeetInfoData(laneSheetMeetId, laneSheetMeetInfoObj, laneSheetState, setLaneSheetState);
  };

  const toggleLaneSheetLandingFilters = () => {
    setLaneSheetLandingFilterState({
      ...laneSheetLandingFilterState,
      showFilters: !laneSheetLandingFilterState.showFilters
    });
  };

  const resetLaneSheetState = () => {
    setLaneSheetState({
      ...BASIC_INITIAL_STATE
    });
  };

  return {
    laneSheetState,
    laneSheetLandingFilterState,
    laneSheetLandingSearchState,
    setLaneSheetState,
    toggleLaneSheetLandingFilters,
    resetLaneSheetState,
    searchLaneSheetLanding,
    postLaneSheetMeetInfo,
    getLaneSheetMeetInfo,
    putLaneSheetMeetInfo
  };
};

export default useLaneSheetData;