/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ athlete, onDeleteClicked }) => (
  <tr>
    <td>{athlete?.firstName || ''} {athlete?.middleName || ''} {athlete?.lastName || ''}</td>
    <td>{athlete?.birthDate ? formatDate(athlete.birthDate) : ''}</td>
    <td>{athlete?.memberId || ''}</td>
    <td>
      {athlete.canBeDeleted === true &&
        <button
          type='button'
          className={global.IconButtonMargin}
          onClick={() => onDeleteClicked(athlete)}><DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const LaneSheetMeetEventAddParticipantsLargeGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Full Name</th>
        <th>Birth Date</th>
        <th>Member Id</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((athlete, i) =>
            <LargeGridRow
              key={i}
              athlete={athlete}
              onDeleteClicked={onDeleteClicked}
            />)
          : <tr><td colSpan="4">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetMeetEventAddParticipantsLargeGrid