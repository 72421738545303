import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from './OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';

export const OmeMeetOrgUnitAthleteEntryIndividualRosterEntryStateContext = createContext();

const OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitAthleteEntryIndividualRosterEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitAthleteEntryIndividualRosterEntryStateContext.Provider>
  );
};

export default OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider;