import { useEffect } from 'react';

import validate from './ParticipantRelayEditFormValidation';

import useSelectionsUnavailableReasonsData from '../../../../state/selectionsUnavailableReasons/UseSelectionsUnavailableReasonsData';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const INITIAL_FORM_STATE = {
  relayTeamName: '',
  parentOrgUnitName: '',
  meetName: '',
  meetDates: '',
  eventCompetitionGenderTypeName: '',
  orgUnitName: '',
  eventCode: '',
  entryTime: '',
  qualifyingSwimDate: '',
  qualifyingMeet: '',
  isSelected: '',
  isAlternate: '',
  selectionReason: '',
  leg1SelectionMeetPersonId: Constants.DEFAULT_ID,
  leg1Name: '',
  leg1WillAttend: true,
  leg1UnavailableReasonId: Constants.DEFAULT_ID,
  leg1UnavailableReasonName: '',
  leg2SelectionMeetPersonId: Constants.DEFAULT_ID,
  leg2Name: '',
  leg2WillAttend: true,
  leg2UnavailableReasonId: Constants.DEFAULT_ID,
  leg2UnavailableReasonName: '',
  leg3SelectionMeetPersonId: Constants.DEFAULT_ID,
  leg3Name: '',
  leg3WillAttend: true,
  leg3UnavailableReasonId: Constants.DEFAULT_ID,
  leg3UnavailableReasonName: '',
  leg4SelectionMeetPersonId: Constants.DEFAULT_ID,
  leg4Name: '',
  leg4WillAttend: true,
  leg4UnavailableReasonId: Constants.DEFAULT_ID,
  leg4UnavailableReasonName: '',
};

const useParticipantRelayEditForm = (onSubmitFormCallback, participantObj, selectionsStateObj) => {
  const { selectionsUnavailableReasonsState } = useSelectionsUnavailableReasonsData();
  const { formState, errorState, setIsDirty, handleSubmit, onValueTextPairChanged, setFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onWillAttendChanged = (newValue, willAttendKey, unavailableReasonIdKey, unavailableReasonNameKey) => {
    setIsDirty(true);

    if (newValue === false) {
      let unavailableReason = null;

      if (Array.isArray(selectionsUnavailableReasonsState.items)) {
        unavailableReason = selectionsUnavailableReasonsState.items[0];
      }

      setFormState({
        ...formState,
        [willAttendKey]: newValue,
        [unavailableReasonIdKey]: unavailableReason?.id || Constants.DEFAULT_ID,
        [unavailableReasonNameKey]: unavailableReason?.name || ''
      });
    } else {
      setFormState({
        ...formState,
        [willAttendKey]: newValue,
        [unavailableReasonIdKey]: Constants.DEFAULT_ID,
        [unavailableReasonNameKey]: ''
      });
    }
  };

  useEffect(() => {
    if (participantObj && selectionsStateObj && selectionsUnavailableReasonsState.areItemsLoaded === true) {
      const leg1UnavailableReason = getUnavailableReasonObj(participantObj.leg1WillAttend, participantObj.leg1UnavailableReason);
      const leg2UnavailableReason = getUnavailableReasonObj(participantObj.leg2WillAttend, participantObj.leg2UnavailableReason);
      const leg3UnavailableReason = getUnavailableReasonObj(participantObj.leg3WillAttend, participantObj.leg3UnavailableReason);
      const leg4UnavailableReason = getUnavailableReasonObj(participantObj.leg4WillAttend, participantObj.leg4UnavailableReason);

      setFormState({
        ...formState,
        relayTeamName: participantObj.relayTeamName || '',
        parentOrgUnitName: participantObj.parentOrgUnitName || '',
        meetName: selectionsStateObj.meetName || '',
        meetDates: `${selectionsStateObj.meetStartDate ? formatDate(selectionsStateObj.meetStartDate) : Constants.BLANK_DATE_STRING} - ${selectionsStateObj.meetEndDate ? formatDate(selectionsStateObj.meetEndDate) : Constants.BLANK_DATE_STRING}`,
        eventCompetitionGenderTypeName: participantObj.eventCompetitionGenderTypeName || '',
        orgUnitName: participantObj.orgUnitName || '',
        eventCode: participantObj.eventCode || '',
        entryTime: participantObj.entryTime ? formatTimeForDisplay(participantObj.entryTime) : '',
        qualifyingSwimDate: participantObj.qualifyingSwimDate ? formatDate(participantObj.qualifyingSwimDate) : '',
        qualifyingMeet: participantObj.qualifyingMeet || '',
        isSelected: participantObj.isSelected === true || participantObj.hasBeenSelected === true ? 'Yes' : 'No',
        isAlternate: participantObj.isAlternate === true ? 'Yes' : 'No',
        selectionReason: participantObj.selectionReason || '',
        leg1SelectionMeetPersonId: participantObj.leg1SelectionMeetPersonId > 0 ? participantObj.leg1SelectionMeetPersonId : Constants.DEFAULT_ID,
        leg1Name: `${participantObj.leg1FirstName || ''} ${participantObj.leg1LastName || ''}`,
        leg1WillAttend: participantObj.leg1WillAttend === true ? true : false,
        leg1UnavailableReasonId: leg1UnavailableReason?.id > 0 ? leg1UnavailableReason?.id : Constants.DEFAULT_ID,
        leg1UnavailableReasonName: leg1UnavailableReason?.name || '',
        leg2SelectionMeetPersonId: participantObj.leg2SelectionMeetPersonId > 0 ? participantObj.leg2SelectionMeetPersonId : Constants.DEFAULT_ID,
        leg2Name: `${participantObj.leg2FirstName || ''} ${participantObj.leg2LastName || ''}`,
        leg2WillAttend: participantObj.leg2WillAttend === true ? true : false,
        leg2UnavailableReasonId: leg2UnavailableReason?.id > 0 ? leg2UnavailableReason?.id : Constants.DEFAULT_ID,
        leg2UnavailableReasonName: leg2UnavailableReason?.name || '',
        leg3SelectionMeetPersonId: participantObj.leg3SelectionMeetPersonId > 0 ? participantObj.leg3SelectionMeetPersonId : Constants.DEFAULT_ID,
        leg3Name: `${participantObj.leg3FirstName || ''} ${participantObj.leg3LastName || ''}`,
        leg3WillAttend: participantObj.leg3WillAttend === true ? true : false,
        leg3UnavailableReasonId: leg3UnavailableReason?.id > 0 ? leg3UnavailableReason?.id : Constants.DEFAULT_ID,
        leg3UnavailableReasonName: leg3UnavailableReason?.name || '',
        leg4SelectionMeetPersonId: participantObj.leg4SelectionMeetPersonId > 0 ? participantObj.leg4SelectionMeetPersonId : Constants.DEFAULT_ID,
        leg4Name: `${participantObj.leg4FirstName || ''} ${participantObj.leg4LastName || ''}`,
        leg4WillAttend: participantObj.leg4WillAttend === true ? true : false,
        leg4UnavailableReasonId: leg4UnavailableReason?.id > 0 ? leg4UnavailableReason?.id : Constants.DEFAULT_ID,
        leg4UnavailableReasonName: leg4UnavailableReason?.name || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantObj, selectionsStateObj, selectionsUnavailableReasonsState.areItemsLoaded]);

  function getUnavailableReasonObj(willAttend, unavailableReason) {
    if (willAttend !== true && Array.isArray(selectionsUnavailableReasonsState.items)) {
      const unavailableReasonObj = selectionsUnavailableReasonsState.items.find(x => x.name === unavailableReason);

      if (!unavailableReasonObj) {
        return selectionsUnavailableReasonsState.items[0];
      } else {
        return unavailableReasonObj;
      }
    } else {
      return null;
    }
  };

  return {
    isLoading: selectionsUnavailableReasonsState.isArrayLoading,
    formState,
    errorState,
    onValueTextPairChanged,
    onWillAttendChanged,
    handleSubmit
  };
};

export default useParticipantRelayEditForm;