export const localValidate = (formState) => {
  let errors = {};
  if (formState.meetName.trim().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters'
  }

  return errors;
};

const MeetAthleteEntryValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MeetAthleteEntryValidation;