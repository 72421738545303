import { useState } from 'react';
import MeetRaceStatsData from './MeetRaceStatsData';

const useMeetRaceStatsData = () => {
  const [meetRaceStatsState, setMeetRaceStatsState] = useState(MeetRaceStatsData.INITIAL_STATE);

  const postMeetRaceStats = (meetRaceStatsObj) => MeetRaceStatsData.postMeetRaceStatsData(meetRaceStatsObj, meetRaceStatsState, setMeetRaceStatsState);

  const clearObjData = () => {
    setMeetRaceStatsState({
      ...meetRaceStatsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    meetRaceStatsState,
    postMeetRaceStats,
    clearObjData
  };
}

export default useMeetRaceStatsData;