import { useContext } from 'react';

import { LaneSheetMedalsStateContext } from './LaneSheetMedalsContextProvider';

import LaneSheetMedalsData from './LaneSheetMedalsData';

const useLaneSheetMedalsData = () => {
  const [laneSheetMedalsState, setLaneSheetMedalsState] = useContext(LaneSheetMedalsStateContext);

  const getLaneSheetMedals = () => {
    return LaneSheetMedalsData.getLaneSheetMedals(laneSheetMedalsState, setLaneSheetMedalsState);
  };

  return {
    laneSheetMedalsState,
    setLaneSheetMedalsState,
    getLaneSheetMedals
  };
};

export default useLaneSheetMedalsData;