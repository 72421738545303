import { useContext } from 'react';

import { MeetStaffStateContext } from './MeetStaffContextProvider';

import MeetStaffData from './MeetStaffData';

const useMeetStaffData = () => {
  const [meetStaffState, setMeetStaffState] = useContext(MeetStaffStateContext);

  const getMeetStaff = (meetId) => {
    MeetStaffData.getMeetStaffData(meetId, meetStaffState, setMeetStaffState);
  };

  const putMeetStaffData = (meetId, meetStaffObj) => {
    MeetStaffData.putMeetStaffData(meetId, meetStaffObj, meetStaffState, setMeetStaffState);
  };

  const confirmSaveMeetStaff = () => {
    setMeetStaffState({
      ...meetStaffState,
      isSaved: false
    });
  };

  const resetMeetStaffState = () => {
    setMeetStaffState(MeetStaffData.INITIAL_STATE);
  }

  return {
    meetStaffState,
    setMeetStaffState,
    getMeetStaff,
    putMeetStaffData,
    confirmSaveMeetStaff,
    resetMeetStaffState
  };
};

export default useMeetStaffData;