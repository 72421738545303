import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from '../meet/NavLinks';
import MyMeetsNavLinks from '../myMeets/MyMeetsNavLinks';
import HostNavLinks from '../host/HostNavLinks';
import HostRegistrationConstants from '../host/hostRegistration/HostRegistrationConstants';

import useOmeMeetData from '../../state/omeMeet/UseOmeMeetData';
import useOmeMeetInvitationsData from '../../state/omeMeetInvitations/UseOmeMeetInvitationsData';
import useMeetSessionData from '../../state/meetSession/UseMeetSessionData';
import useMyMeetsFiltersData from '../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useMeetData from '../../../common/state/meet/UseMeetData';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import { formatDate, getTodaysDate } from '../../../common/utils/DateFunctions';
import UseForm from '../../../common/utils/UseForm';
import Constants from '../../../common/utils/Constants';

const TAXONOMIES_OME = ['']; //TODO none in database?
const SCOPE_OME = 'OnlineMeetEntry';

const useOmeMeetTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, setOmeMeetState, postOmeMeet, clearOmeMeetObjData } = useOmeMeetData();
  const { omeMeetInvitationsState, postOmeMeetInvitations } = useOmeMeetInvitationsData();
  const { clearOmeMeetInvitationsArrayData } = useOmeMeetInvitationsData();
  const { meetState, clearObjData, getMeet } = useMeetData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState, setMyMeetsFiltersState } = useMyMeetsFiltersData();
  const { meetSessionState, getMeetSessionsByMeetId } = useMeetSessionData();
  const [state, setState] = useState({ tryRedirect: false, tryGetMeet: false });
  const [meetSessionChangesModalState, setMeetSessionChangesModalState] = useState(getInitialMeetSessionChangesModalState());
  const { formState, errorState, onValueTextPairChanged, setErrors } = UseForm(getInitialFormState, () => { }, () => { });

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (meetState.objData && Object.keys(meetState.objData).length > 0 && meetState.objData?.meetSession?.length > 0) {
      //template meet selected with at least one meet session
      onOpenMeetSessionChangesModalClicked();
    }
    else {
      //no template selected, or template meet does not have any sessions
      onCreateOmeMeet();
    }
  };

  const onModalSaveButtonClicked = (e) => {
    let errors = {};
    for (let i = 0; i < meetState.objData?.meetSession?.length; i++) {
      let objProp1 = `meetSessionId${meetState.objData?.meetSession[i].meetSessionId}`;
      if (formState[objProp1] === Constants.DEFAULT_ID) {
        errors[objProp1] = 'Required';
      }
    }
    if (Object.keys(errors).length === 0) {
      onCreateOmeMeet();
    }
    else {
      setErrors({ ...errors });
    }
  }

  const onCreateOmeMeet = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const todaysDate = getTodaysDate();
    let omeMeetEligibilityCopy = omeMeetState.objData?.omeMeetEligibility && omeMeetState.objData?.omeMeetEligibility[0] ? JSON.parse(JSON.stringify(omeMeetState.objData?.omeMeetEligibility[0])) : {};
    if (Object.keys(omeMeetEligibilityCopy).length > 0) {
      delete omeMeetEligibilityCopy.omeMeetEligibilityId;
      delete omeMeetEligibilityCopy.omeMeetId;
      omeMeetEligibilityCopy.forceAggregateIfFasterThanTeamTime = null;
      omeMeetEligibilityCopy.maxNumberOfCapExemptAthletesForMeet = null;
      omeMeetEligibilityCopy.usCitizenOnly = null;
      omeMeetEligibilityCopy.nationalTeamOnly = null;
      omeMeetEligibilityCopy.allowRepOtherCountry = null;

      omeMeetEligibilityCopy.omeMeetEligibilityOrgRole = omeMeetEligibilityCopy.omeMeetEligibilityOrgRole.map((x) => {
        return ({
          omeMeetEligibilityOrgRoleId: undefined,
          omeMeetId: undefined,
          orgRoleId: x.orgRoleId
        })
      });
      omeMeetEligibilityCopy.omeMeetEligibilityAge = omeMeetEligibilityCopy.omeMeetEligibilityAge.map((x) => {
        return ({
          omeMeetEligibilityAgeId: undefined,
          omeMeetId: undefined,
          minAge: x.minAge,
          maxAge: x.maxAge
        })
      });
      omeMeetEligibilityCopy.omeMeetEligibilityCompetitionGenderType = omeMeetEligibilityCopy.omeMeetEligibilityCompetitionGenderType.map((x) => {
        return ({
          omeMeetEligibilityCompetitionGenderTypeId: undefined,
          omeMeetId: undefined,
          competitionGenderTypeId: x.competitionGenderTypeId
        })
      });
    }

    let omeMeetQualificationCopy = omeMeetState.objData?.omeMeetQualification && omeMeetState.objData?.omeMeetQualification[0] ? JSON.parse(JSON.stringify(omeMeetState.objData?.omeMeetQualification[0])) : {};
    if (Object.keys(omeMeetQualificationCopy).length > 0) {
      delete omeMeetQualificationCopy.omeMeetQualificationId;
      delete omeMeetQualificationCopy.omeMeetId;

      omeMeetQualificationCopy.timeStandardsQualifyingStartDate = null;
      omeMeetQualificationCopy.timeStandardsQualifyingEndDate = null

      omeMeetQualificationCopy.omeQualificationBonus = omeMeetQualificationCopy.omeQualificationBonus.map((x) => {
        return ({
          omeQualificationBonusId: undefined,
          omeMeetId: undefined,
          minQualifyingEvents: x.minQualifyingEvents,
          maxQualifyingEvents: x.maxQualifyingEvents,
          bonusStandardCount: x.bonusStandardCount
        })
      });
    }

    let meetAgeGroupCopy = omeMeetState.objData?.meetAgeGroup ? JSON.parse(JSON.stringify(omeMeetState.objData?.meetAgeGroup)) : [];
    if (meetAgeGroupCopy.length > 0) {
      meetAgeGroupCopy = meetAgeGroupCopy.map((x) => {
        //handle changing session for events
        let meetAgeGroupMeetEventCopy = x.meetEvent ? JSON.parse(JSON.stringify(x.meetEvent)) : [];
        if (meetAgeGroupMeetEventCopy.length > 0) {
          meetAgeGroupMeetEventCopy = meetAgeGroupMeetEventCopy.map((y) => {
            let newValue = `meetSessionId${y.meetSessionId}`
            //handle time standards cuts
            let meetEventBonusTimeCopy = y?.meetEventBonusTime ? JSON.parse(JSON.stringify(y?.meetEventBonusTime)) : [];
            if (meetEventBonusTimeCopy.length > 0) {
              meetEventBonusTimeCopy = meetEventBonusTimeCopy.map((z1) => {
                return (
                  {
                    ...z1,
                    meetEventId: undefined,
                    meetAgeGroupId: undefined,
                    meetEventBonusTimeId: undefined,
                    modifiedDatetime: undefined,
                    course: undefined,
                    event: undefined
                  }
                )
              })
            }

            let meetEventQualificationLimitCopy = y?.meetEventQualificationLimit ? JSON.parse(JSON.stringify(y?.meetEventQualificationLimit)) : [];
            if (meetEventQualificationLimitCopy.length > 0) {
              meetEventQualificationLimitCopy = meetEventQualificationLimitCopy.map((z2) => {
                return (
                  {
                    ...z2,
                    meetEventId: undefined,
                    meetAgeGroupId: undefined,
                    meetEventQualificationLimitId: undefined,
                    modifiedDatetime: undefined,
                    course: undefined,
                    event: undefined
                  }
                )
              })
            }

            let meetEventQualifyingTimeCopy = y?.meetEventQualifyingTime ? JSON.parse(JSON.stringify(y?.meetEventQualifyingTime)) : [];
            if (meetEventQualifyingTimeCopy.length > 0) {
              meetEventQualifyingTimeCopy = meetEventQualifyingTimeCopy.map((z3) => {
                return (
                  {
                    ...z3,
                    meetEventId: undefined,
                    meetAgeGroupId: undefined,
                    meetEventQualifyingTimeId: undefined,
                    modifiedDatetime: undefined,
                    course: undefined,
                    event: undefined
                  }
                )
              })
            }

            return ({
              ...y,
              meetEventId: undefined,
              meetAgeGroupId: undefined,
              meetSessionId: formState[newValue],
              meetEventBonusTime: meetEventBonusTimeCopy,
              meetEventQualificationLimit: meetEventQualificationLimitCopy,
              meetEventQualifyingTime: meetEventQualifyingTimeCopy,
              event: undefined,
              eventCompetitionGenderType: undefined,
              modifiedDatetime: undefined,
              nonConformingEvent: undefined
            })
          })
        };

        let meetAgeGroupTimeStandardCopy = x.meetAgeGroupTimeStandard ? JSON.parse(JSON.stringify(x.meetAgeGroupTimeStandard)) : [];
        if (meetAgeGroupTimeStandardCopy.length > 0) {
          meetAgeGroupTimeStandardCopy = meetAgeGroupTimeStandardCopy.map((a) => {
            return (
              {
                ...a,
                meetAgeGroupTimeStandardId: undefined,
                meetAgeGroupId: undefined,
                modifiedDatetime: undefined,
                noSlowerThanTimeStandardAgeGroup: undefined,
                noFasterThanTimeStandardAgeGroup: undefined,
                bonusTimeStandardAgeGroup: undefined
              }
            )
          })
        }

        return ({
          meetAgeGroupId: undefined,
          omeMeetId: undefined,
          minAge: x.minAge,
          maxAge: x.maxAge,
          ageGroupName: x.ageGroupName,
          meetAgeGroupTimeStandard: meetAgeGroupTimeStandardCopy,
          meetEvent: meetAgeGroupMeetEventCopy
        })
      })
    }

    let omeMeetCoachPassCopy = omeMeetState.objData?.omeMeetCoachPass ? JSON.parse(JSON.stringify(omeMeetState.objData?.omeMeetCoachPass)) : [];
    if (omeMeetCoachPassCopy.length > 0) {
      omeMeetCoachPassCopy = omeMeetCoachPassCopy.map((x) => {
        return ({
          omeMeetCoachPassId: undefined,
          omeMeetId: undefined,
          minSwimmers: x.minSwimmers,
          maxSwimmers: x.maxSwimmers,
          coachPasses: x.coachPasses
        })
      });
    }

    let omeMeetPriceCopy = omeMeetState.objData?.omeMeetPrice ? JSON.parse(JSON.stringify(omeMeetState.objData?.omeMeetPrice)) : [];
    if (omeMeetPriceCopy.length > 0) {
      omeMeetPriceCopy = omeMeetPriceCopy.map((x) => {
        return ({
          omeMeetPriceId: undefined,
          omeMeetId: undefined,
          omeFeeTypeId: x.omeFeeTypeId,
          amount: x.amount,
          description: x.description,
          omeMeetOrgUnitEntryPurchaseDetail: []
        })
      });
    }

    let omeMeetEntryCourseCopy = omeMeetState.objData?.omeMeetEntryCourse ? JSON.parse(JSON.stringify(omeMeetState.objData?.omeMeetEntryCourse)) : [];
    if (omeMeetEntryCourseCopy.length > 0) {
      omeMeetEntryCourseCopy = omeMeetEntryCourseCopy.map((x) => {
        return ({
          omeMeetEntryCourseId: undefined,
          omeMeetId: undefined,
          courseId: x.courseId, priority: x.priority
        })
      });
    }

    const newOmeMeet = {
      meetId: omeMeetState?.selectedMeet?.meetId,
      meetCreationDate: todaysDate,
      timeZone: Object.keys(omeMeetState.objData).length > 0 ? omeMeetState.objData.timeZone : null,
      status: HostRegistrationConstants.IN_PROGRESS_STATUS,
      omeMeetEligibility: Object.keys(omeMeetState.objData).length > 0 ? (Object.keys(omeMeetEligibilityCopy).length > 0 ? [omeMeetEligibilityCopy] : []) : [],
      omeMeetQualification: Object.keys(omeMeetState.objData).length > 0 ? (Object.keys(omeMeetQualificationCopy).length > 0 ? [omeMeetQualificationCopy] : []) : [],
      omeMeetOrgUnitEntry: null,
      meetAgeGroup: Object.keys(omeMeetState.objData).length > 0 ? meetAgeGroupCopy : [],
      includeRelaySwimmerForCoachPasses: Object.keys(omeMeetState.objData).length > 0 ? omeMeetState.objData.includeRelaySwimmerForCoachPasses : null,
      omeMeetCoachPass: Object.keys(omeMeetState.objData).length > 0 ? omeMeetCoachPassCopy : [],
      omeMeetPrice: Object.keys(omeMeetState.objData).length > 0 ? omeMeetPriceCopy : [],
      omeMeetEntryCourse: Object.keys(omeMeetState.objData).length > 0 ? omeMeetEntryCourseCopy : [],
      allowSecondaryOrgUnitRepresentation: false,
      omeMeetPayee: []
    }

    onMeetSessionChangesModalCanceled();

    postOmeMeet(newOmeMeet);
    setState({ ...state, tryPostInvitations: true });
  };

  const onExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_SEARCH, {
        state: {
          meetId: location.state?.omeMeet?.meetId ? location.state?.omeMeet?.meetId : location.state?.meetId || undefined, meetFiltersState: location.state?.meetFiltersState, route: location.state?.route
        }
      });
    }
    else {
      navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
    }
  };

  const onDeleteClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    clearOmeMeetObjData();
    clearOmeMeetInvitationsArrayData();
    clearObjData();
  };

  const goToMeetTemplateSearch = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(HostNavLinks.OME_MEET_TEMPLATE_SEARCH, {
      state: {
        meetId: location.state?.omeMeet?.meetId || location.state?.meetId, meetFiltersState: location.state?.meetFiltersState, route: location.state?.route
      }
    });
  };

  const onOpenMeetSessionChangesModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const templateMeetInfo = `${meetState.objData?.meetName || ''} (Host: ${meetState.objData?.hostOrgUnit?.qualifiedOrgUnitCode || ''}, Meet Dates: ${meetState.objData?.startDate ? formatDate(meetState.objData?.startDate) : ''} - ${meetState.objData?.endDate ? formatDate(meetState.objData?.endDate) : ''}, Meet Classification: ${meetState.objData?.meetClassification?.typeName || ''})`;
    const meetInfo = `${omeMeetState.selectedMeet?.meetName || ''} (Host: ${omeMeetState.selectedMeet?.meetHost || ''}, Meet Dates: ${omeMeetState.selectedMeet?.startDate ? formatDate(omeMeetState.selectedMeet?.startDate) : ''} - ${omeMeetState.selectedMeet?.endDate ? formatDate(omeMeetState.selectedMeet?.endDate) : ''}, Meet Classification: ${omeMeetState.selectedMeet?.meetClassificationName || ''})`;
    setMeetSessionChangesModalState({
      ...meetSessionChangesModalState,
      templateMeetInfo: templateMeetInfo,
      meetInfo: meetInfo,
      displayPopUp: true
    });
  };

  const onMeetSessionChangesModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setMeetSessionChangesModalState(getInitialMeetSessionChangesModalState());
    setErrors({});
  };

  useEffect(() => {
    if (location.state?.selectOrgUnitState) {
      setSelectOrgUnitState({
        ...selectOrgUnitState,
        orgUnitId: location.state?.selectOrgUnitState.orgUnitId,
        orgUnitName: location.state?.selectOrgUnitState.orgUnitName,
        showBackToSelection: location.state?.selectOrgUnitState.showBackToSelection
      });
    }
    if (location.state?.omeMeetHostFiltersState || location.state?.omeMeetTeamEntryFiltersState) {
      setMyMeetsFiltersState({
        ...myMeetsFiltersState,
        omeMeetHostFiltersState: location.state?.omeMeetHostFiltersState,
        omeMeetTeamEntryFiltersState: location.state?.omeMeetTeamEntryFiltersState
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.omeMeet) {
      setOmeMeetState({
        ...omeMeetState,
        selectedMeet: location.state?.omeMeet,
        route: location.state?.route || omeMeetState.route,
        meetFiltersState: location.state.meetFiltersState || {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetState.selectedMeet && Object.keys(omeMeetState.selectedMeet).length > 0) {
      if (omeMeetState.selectedMeet?.meetId) {
        getMeetSessionsByMeetId(omeMeetState.selectedMeet?.meetId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.selectedMeet]);

  useEffect(() => {
    if (omeMeetState.isObjLoaded === true && omeMeetState.isSaving === false &&
      omeMeetState.isSaved === true && omeMeetState?.selectedMeet?.meetId && meetState.isObjLoading === false
      && omeMeetInvitationsState.isArrayLoading === false && state.tryPostInvitations === true) {
      let omeMeetInvitationsCopy = omeMeetInvitationsState.arrayData ? JSON.parse(JSON.stringify(omeMeetInvitationsState.arrayData)) : [];
      if (omeMeetInvitationsCopy.length > 0) {
        postOmeMeetInvitations(omeMeetState?.objData.omeMeetId, omeMeetInvitationsCopy);
      }
      setState({ ...state, tryPostInvitations: false, tryGetMeet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, omeMeetInvitationsState, meetState.isObjLoading, state.tryPostInvitations])

  useEffect(() => {
    if (omeMeetState.isObjLoaded === true && omeMeetState.isSaving === false &&
      omeMeetState.isSaved === true && omeMeetState?.selectedMeet?.meetId && meetState.isObjLoading === false
      && omeMeetInvitationsState.isSaving === false && state.tryGetMeet === true) {
      getMeet(omeMeetState?.selectedMeet?.meetId);
      setState({ ...state, tryGetMeet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, omeMeetInvitationsState, meetState.isObjLoading, state.tryGetMeet])

  useEffect(() => {
    //OME Route Only
    if (omeMeetState.route === '') {
      if (omeMeetState.isObjLoaded === true && omeMeetState.isSaving === false &&
        omeMeetState.isSaved === true && omeMeetInvitationsState.isSaving === false &&
        meetState.isObjLoading === false && state.tryRedirect === true &&
        contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE_OME) {
        getContextSecurity(omeMeetState.objData.omeMeetId, TAXONOMIES_OME, SCOPE_OME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, omeMeetInvitationsState, meetState.isObjLoading, state.tryRedirect, contextSecurityState])

  useEffect(() => {
    //OME Route
    if (omeMeetState.route === '') {
      if (omeMeetState.isObjLoaded === true && omeMeetState.isSaving === false &&
        omeMeetState.isSaved === true && omeMeetInvitationsState.isSaving === false && omeMeetState.objData.omeMeetId &&
        meetState.isObjLoading === false && state.tryRedirect === true
        && contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE_OME) {
        const updatedSelectedMeet = {
          ...omeMeetState.selectedMeet,
          omeMeetId: omeMeetState.objData.omeMeetId,
          omeMeetStatus: omeMeetState.objData.status
        };
        navigate(HostNavLinks.OME_HOST_LOADING, { state: { omeMeet: updatedSelectedMeet } });
      }
    }
    //Meet Route
    else if (omeMeetState.route !== '') {
      if (omeMeetState.isObjLoaded === true && omeMeetState.isSaving === false &&
        omeMeetState.isSaved === true && omeMeetInvitationsState.isSaving === false && omeMeetState.objData.omeMeetId &&
        meetState.isObjLoading === false && state.tryRedirect === true) {
        navigate(NavLinks.MEET_SEARCH, {
          state: {
            meetId: omeMeetState.objData.meetId,
            omeMeetId: omeMeetState.objData.omeMeetId,
            meetFiltersState: omeMeetState.meetFiltersState,
            route: omeMeetState.route
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, omeMeetInvitationsState, meetState.isObjLoading, state.tryRedirect, contextSecurityState])

  function getInitialMeetSessionChangesModalState() {
    return {
      displayPopUp: false,
      templateMeetInfo: '',
      meetInfo: '',
      modalTitle: 'Handle Session Differences between Template Meet and New Meet in Order to Copy Events'
    };
  };

  function getInitialFormState() {
    return {};
  };

  return {
    omeMeetState,
    meetState,
    contextSecurityState,
    onNextButtonClicked,
    onExitButtonClicked,
    onDeleteClicked,
    goToMeetTemplateSearch,
    meetSessionChangesModalState,
    onModalSaveButtonClicked,
    onOpenMeetSessionChangesModalClicked,
    onMeetSessionChangesModalCanceled,
    formState,
    errorState,
    onValueTextPairChanged,
    meetSessionState
  };
};

export default useOmeMeetTemplate;