import React, { Fragment } from 'react';

import useSelectionsMeetEvents from './UseSelectionsMeetEvents';

import SelectionMeetEventsGrid from '../components/grids/SelectionMeetEventsGrid';
import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import SelectionMeetEventsReadOnlyGrid from '../components/grids/SelectionMeetEventsReadOnlyGrid';
import SelectionsLoadingModal from '../components/dialogs/SelectionsLoadingModal';
import { MEET_EVENTS_NAME } from '../components/leftNav/SelectionsLeftNavConstants';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const SelectionsMeetEvents = () => {
  const {
    isLoading,
    state,
    isReadOnly,
    onContinueClicked,
    onBaseTimeChanged,
    onSaveAndContinueClicked,
    onBackClicked
  } = useSelectionsMeetEvents();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={MEET_EVENTS_NAME} viewTitle={'Meet Events'}>
        {isReadOnly === true ? (
          <Fragment>
            <SelectionMeetEventsReadOnlyGrid
              gridData={state.gridData}
              isLoading={isLoading} />
            <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <SelectionMeetEventsGrid
              gridData={state.gridData}
              isLoading={isLoading}
              errorText={state.gridErrorMessage}
              showError={state.showGridError}
              onRowChange={onBaseTimeChanged} />
            <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={onSaveAndContinueClicked}>Save & Continue</PrimaryButton>
              </div>
            </div>
          </Fragment>
        )}
        <SelectionsLoadingModal isLoading={isLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
};

export default SelectionsMeetEvents;