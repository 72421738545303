import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddMeetTimeIndividualValidation';

import { getMeetDates } from '../utils/MeetUtils';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';
import useEventData from '../../../../../common/state/event/UseEventData';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

import NavLinks from '../../NavLinks';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useAddMeetTimeIndividual = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(getInitialState);
  const { eventState, getEvents } = useEventData();
  const { meetState } = useMeetData();
  const { postMeetTime, meetTimeAddPostDataState, setMeetTimeAddPostDataState, meetTimesAddState } = useMeetTimesData();
  const {
    formState,
    errorState,
    handleSubmit,
    setFormState,
    updateFormState,
    onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const [meetDatesState, setMeetDatesState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select a Swim Date" }
  ]);

  const [eventCompetitionGenderTypeState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select an Event Gender" },
    { id: 1, name: "Male" },
    { id: 2, name: "Female" }
  ]);

  const [sessionTypeState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select a Session Type" },
    { id: 1, name: "Prelim" },
    { id: 2, name: "SwimOff" },
    { id: 3, name: "Final" },
    { id: 4, name: "SemiFinal" },
    { id: 5, name: "QuarterFinal" },
    { id: 6, name: "TimedFinal" }
  ]);

  function submitFormCallback(formState) {
    postMeetTime(createMeetTimeIndividualObject(formState))
    setState({
      ...state,
      tryPageRedirect: true
    })
  };

  const createMeetTimeIndividualObject = (formState) => {
    return {
      personId: formState.personId,
      personName: formState.personName,
      eventId: formState.eventId,
      meetId: formState.meetId,
      eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
      sessionId: formState.sessionId,
      time: formatTimeForFilterObject(formState.time),
      swimDate: formState.swimDate,
      finishPosition: parseInt(formState.finishPosition.trim()),
      isLeadOffTime: formState.isLeadOffTime
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME_SELECT_TIME_TYPE);
  };

  useEffect(() => {
    if (meetTimeAddPostDataState.isSaved === true
      && state.tryPageRedirect === true) {
      const newArrayData = meetTimeAddPostDataState.arrayData;
      newArrayData.push(meetTimeAddPostDataState.objData);
      setMeetTimeAddPostDataState({
        ...meetTimeAddPostDataState,
        arrayData: newArrayData,
        isSaved: false
      })
      navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetTimeAddPostDataState, state.tryPageRedirect]);

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      const startDate = formatDate(meetState.objData.startDate);
      const endDate = formatDate(meetState.objData.endDate);
      const meetDatesRange = getMeetDates(startDate, endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "Select a Swim Date" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState]);

  useEffect(() => {
    if (eventState.isArrayLoaded === false) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formState.personId !== '') {
      const personGender = meetTimesAddState.arrayData.find(data => data.id === formState.personId)?.gender;

      setFormState({
        ...formState,
        personGender: personGender
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.personId]);

  return {
    formState,
    errorState,
    eventState,
    meetDatesState,
    meetTimesAddState,
    meetTimeAddPostDataState,
    sessionTypeState,
    eventCompetitionGenderTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked
  };

  function getInitialFormState() {
    return {
      personId: '',
      personName: '',
      personGender: '',
      eventId: Constants.DEFAULT_ID,
      meetId: meetState.objData.meetId,
      eventCompetitionGenderTypeId: Constants.DEFAULT_ID,
      sessionId: Constants.DEFAULT_ID,
      time: '',
      swimDate: '',
      swimDateId: Constants.DEFAULT_ID,
      finishPosition: '',
      isLeadOffTime: false,
      eventName: '',
      competitorName: ''
    };
  }

  function getInitialState() {
    return {
      tryPageRedirect: false
    };
  };
}

export default useAddMeetTimeIndividual;