import React from 'react';

import { formatPercentageForDisplay } from '../../utils/SelectionsUtils';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ meetEntry }) => (
  <tr>
    <td>{meetEntry.eventNumber || ''}</td>
    <td>{meetEntry.eventCode || ''}</td>
    <td>{meetEntry.eventCompetitionGenderTypeName || ''}</td>
    <td>{meetEntry.isRelay === true ? `${meetEntry.relayTeamName || ''}` : `${meetEntry.individualFirstName || ''} ${meetEntry.individualLastName || ''}`}</td>
    <td>{meetEntry.entryTime ? formatTimeForDisplay(meetEntry.entryTime) : ''}</td>
    <td>{meetEntry.baseTime ? formatTimeForDisplay(meetEntry.baseTime) : ''}</td>
    <td>{meetEntry.percentOfBaseTime ? formatPercentageForDisplay(meetEntry.percentOfBaseTime) : ''}</td>
    <td>{meetEntry.standardName || ''}</td>
  </tr>
);

const SelectionMeetEntriesGridLarge = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event Number</th>
        <th>Event</th>
        <th>Gender</th>
        <th>Swimmer/Team</th>
        <th>Entry Time</th>
        <th>Base Time</th>
        <th>Time Rank %</th>
        <th>Standard</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="8">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((meetEntry, i) => <GridRow key={i} meetEntry={meetEntry} />)
          : <tr><td colSpan="8">No Meet Entries</td></tr>
      }
    </tbody>
  </table>
);

export default SelectionMeetEntriesGridLarge;