import { useContext } from 'react';

import SelectionsParticipantsData from './SelectionsParticipantsData';

import { SelectionsParticipantsStateContext } from './SelectionsParticipantsContextProvider';
import { SelectionGridOptionsStateContext } from './SelectionGridOptionsContextProvider';

const useSelectionsParticipantsData = () => {
  const [selectionsParticipantsState, setSelectionsParticipantsState] = useContext(SelectionsParticipantsStateContext);
  const [selectionGridOptionsState, setSelectionGridOptionsState] = useContext(SelectionGridOptionsStateContext);

  const getSelectionParticipants = (selectionMeetId) => {
    const includeRelays = selectionGridOptionsState.showRelaysInAlternatesGrid === true;

    return SelectionsParticipantsData.getSelectionParticipantsData(selectionMeetId, includeRelays, selectionsParticipantsState, setSelectionsParticipantsState);
  };

  const postSelectionRule = (selectionMeetId, shortActionName) => {
    return SelectionsParticipantsData.postSelectionRuleData(
      selectionMeetId,
      shortActionName,
      selectionGridOptionsState.showRelaysInAlternatesGrid === true,
      selectionsParticipantsState,
      setSelectionsParticipantsState);
  };

  const postSelectionSelectAlternateRule = (selectionMeetId, selectionMeetEventEntryId) => {
    return SelectionsParticipantsData.postSelectionSelectAlternateRuleData(
      selectionMeetId,
      selectionMeetEventEntryId,
      selectionGridOptionsState.showRelaysInAlternatesGrid === true,
      selectionsParticipantsState,
      setSelectionsParticipantsState);
  };

  const putSelectionPersonAttendance = (selectionMeetId, selectionMeetPersonId, willAttend, unavailableReasonId) => {
    return SelectionsParticipantsData.putSelectionPersonAttendanceData(
      selectionMeetId,
      selectionMeetPersonId,
      willAttend,
      unavailableReasonId,
      selectionGridOptionsState.showRelaysInAlternatesGrid === true,
      selectionsParticipantsState,
      setSelectionsParticipantsState);
  };

  const toggleShowSelectionGridPopup = (setBool = undefined) => {
    if (setBool === true || setBool === false) {
      setSelectionGridOptionsState({
        ...selectionGridOptionsState,
        showSelectionGridPopup: setBool
      });
    } else {
      setSelectionGridOptionsState({
        ...selectionGridOptionsState,
        showSelectionGridPopup: !selectionGridOptionsState.showSelectionGridPopup
      });
    }
  };

  const toggleShowDetailPopupsInGrid = (setBool = undefined) => {
    if (setBool === true || setBool === false) {
      setSelectionGridOptionsState({
        ...selectionGridOptionsState,
        showDetailPopupsInGrid: setBool
      });
    } else {
      setSelectionGridOptionsState({
        ...selectionGridOptionsState,
        showDetailPopupsInGrid: !selectionGridOptionsState.showDetailPopupsInGrid
      });
    }
  };

  const toggleShowRelaysInAlternatesGrid = (selectionMeetId, setBool = undefined) => {
    if (setBool === true || setBool === false) {
      setSelectionGridOptionsState({
        ...selectionGridOptionsState,
        showRelaysInAlternatesGrid: setBool
      });
      const includeRelays = setBool;

      SelectionsParticipantsData.getSelectionParticipantsData(selectionMeetId, includeRelays, selectionsParticipantsState, setSelectionsParticipantsState);
    } else {
      setSelectionGridOptionsState({
        ...selectionGridOptionsState,
        showRelaysInAlternatesGrid: !selectionGridOptionsState.showRelaysInAlternatesGrid
      });
      const includeRelays = !selectionGridOptionsState.showRelaysInAlternatesGrid;

      SelectionsParticipantsData.getSelectionParticipantsData(selectionMeetId, includeRelays, selectionsParticipantsState, setSelectionsParticipantsState);
    }
  };

  return {
    selectionsParticipantsState,
    selectionGridOptionsState,
    getSelectionParticipants,
    postSelectionRule,
    postSelectionSelectAlternateRule,
    putSelectionPersonAttendance,
    toggleShowSelectionGridPopup,
    toggleShowDetailPopupsInGrid,
    toggleShowRelaysInAlternatesGrid
  };
};

export default useSelectionsParticipantsData;