import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const LargeDetailTableRow = ({ athlete, meetInfo, teamEntry, onCreate, onEdit, onDeleteClicked, onView }) => {
  return (
    <tr>
      {teamEntry.isReadOnly === true && athlete.orgUnitAthleteEntryId > 0 ?
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <td><a href='' onClick={(e) => onView(e, athlete, meetInfo, teamEntry)}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</a></td> :
        <td>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</td>}
      <td>{athlete.competitionGenderTypeName || ''}</td>
      <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
      <td>{athlete.status || ''}</td>
      <td>{teamEntry.orgUnitName || ''} {teamEntry.orgUnitName === 'Unattached' ? teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '' : ''} {teamEntry.parentOrgUnitCode ? '(' + teamEntry.parentOrgUnitCode + ')' : ''}</td>
      <td>
        {teamEntry.athleteEntryCurrentlyAllowed === true &&
          <Fragment>
            {athlete.orgUnitAthleteEntryId === 0 ?
              <button className={global.IconButtonMargin} type="button" onClick={(e) => onCreate(e, athlete, meetInfo, teamEntry)}>
                <PlusIcon toolTipText='Create Unattached Athlete Entry' />
              </button> :
              <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, athlete, meetInfo, teamEntry)}>
                <EditIcon />
              </button>}
          </Fragment>}
        {teamEntry.athleteEntryDeleteCurrentlyAllowed === true && athlete.canBeDeleted === true &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, athlete, meetInfo, teamEntry)}>
            <DeleteIcon />
          </button>}
      </td>
    </tr>
  )
}

const LargeDetailTable = ({ meetInfo, teamEntries, onCreate, onEdit, onDeleteClicked, onView }) => {
  return (
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th>Athlete Name (Age At Meet)</th>
          <th>Competition Category</th>
          <th>Birth Date</th>
          <th>Athlete Entry Status</th>
          <th>Team Name</th>
          <th style={{ minWidth: '90px' }}></th>
        </tr>
      </thead>
      <tbody>
        {teamEntries?.map((teamEntry) =>
          teamEntry?.athleteRoster?.map((athlete, i) =>
            <LargeDetailTableRow
              athlete={athlete}
              teamEntry={teamEntry}
              meetInfo={meetInfo}
              key={i}
              onCreate={onCreate}
              onEdit={onEdit}
              onDeleteClicked={onDeleteClicked}
              onView={onView}
            />))}
      </tbody>
    </table>
  );
};

const LargeMeetRow = ({ meetInfo, onCreate, onEdit, onDeleteClicked, expandedId, onClick, onView }) => {
  return (
    <Fragment>
      <tr>
        <td>{meetInfo.meetName}</td>
        <td>{meetInfo.meetHost}</td>
        <td>{meetInfo.startDate ? formatDate(meetInfo.startDate) : ''} - {meetInfo.endDate ? formatDate(meetInfo.endDate) : ''}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, meetInfo.meetId)}>
            {expandedId === meetInfo.meetId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {expandedId === meetInfo.meetId &&
        <tr className={global.Expanded}>
          <td colSpan="4">
            <LargeDetailTable meetInfo={meetInfo} teamEntries={meetInfo.teamEntry} onCreate={onCreate} onEdit={onEdit} onDeleteClicked={onDeleteClicked} onView={onView} />
          </td>
        </tr>}
    </Fragment>
  );
};

const LargeGrid = ({ state, onCreate, onEdit, onDeleteClicked, expandedId, onClick, onView }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <th>Meet Host</th>
        <th>Meet Start - End Dates</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.arrayData.map((meetInfo, i) => (
        <LargeMeetRow
          key={i}
          meetInfo={meetInfo}
          onCreate={onCreate}
          onEdit={onEdit}
          onDeleteClicked={onDeleteClicked}
          expandedId={expandedId}
          onClick={onClick}
          onView={onView}
        />
      ))}
    </tbody>
  </table>
)

const SmallDetailTableRow = ({ athlete, teamEntry, meetInfo, onCreate, onEdit, onDeleteClicked, onView }) => {
  return (
    <Fragment>
      <div className={global.SmallDetailTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          {teamEntry.isReadOnly === true && athlete.orgUnitAthleteEntryId > 0 ? <span><ActionIntraPageButton onClick={(e) => onView(e, athlete, meetInfo, teamEntry)}>View</ActionIntraPageButton> </span> :
            <span><span className='hidden-xs'>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</span>&nbsp;</span>}
          {teamEntry.athleteEntryDeleteCurrentlyAllowed === true && athlete.canBeDeleted === true &&
            <button className={global.IconButton} type="button" onClick={(e) => onDeleteClicked(e, athlete, meetInfo, teamEntry)}>
              <DeleteIcon />
            </button>}
          {teamEntry.athleteEntryCurrentlyAllowed === true &&
            <Fragment>
              {teamEntry.athleteEntryDeleteCurrentlyAllowed === true && athlete.canBeDeleted === true ?
                <Fragment>
                  {athlete.orgUnitAthleteEntryId === 0 ?
                    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onCreate(e, athlete, meetInfo, teamEntry)}>
                      <PlusIcon toolTipText='Create Unattached Athlete Entry' />
                    </button> :
                    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, athlete, meetInfo, teamEntry)}>
                      <EditIcon />
                    </button>}
                </Fragment>
                :
                <Fragment>
                  {athlete.orgUnitAthleteEntryId === 0 ?
                    <button className={global.IconButton} type="button" onClick={(e) => onCreate(e, athlete, meetInfo, teamEntry)}>
                      <PlusIcon toolTipText='Create Unattached Athlete Entry' />
                    </button> :
                    <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, athlete, meetInfo, teamEntry)}>
                      <EditIcon />
                    </button>}
                </Fragment>}
            </Fragment>}
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            {teamEntry.isReadOnly === true && athlete.orgUnitAthleteEntryId > 0 ?
              <span>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Athlete Name (Age)</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</div>
              </span>
              :
              <span>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Athlete Name (Age)</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</div>
              </span>}
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-xs'].join(' ')}>Competition Category</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Comp. Category</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Status</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.status || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Team Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{teamEntry.orgUnitName || <span>&nbsp;</span>} {teamEntry.orgUnitName === 'Unattached' ? teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '' : ''} {teamEntry.parentOrgUnitCode ? '(' + teamEntry.parentOrgUnitCode + ')' : ''}</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const SmallDetailTable = ({ meetInfo, teamEntries, onCreate, onEdit, onDeleteClicked, onView }) => {
  return (
    <div className={global.SmallDetailTable}>
      {teamEntries?.map((teamEntry) =>
        teamEntry?.athleteRoster?.map((athlete, i) =>
          <SmallDetailTableRow
            athlete={athlete}
            key={i}
            teamEntry={teamEntry}
            meetInfo={meetInfo}
            onCreate={onCreate}
            onEdit={onEdit}
            onDeleteClicked={onDeleteClicked}
            onView={onView} />
        ))}
    </div>
  );
};

const SmallGrid = ({ state, onCreate, onEdit, onDeleteClicked, expandedId, onClick, onView }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.arrayData.map((meetInfo, i) => (
      <Fragment key={i}>
        <div className={global.SmallTableRow} key={i}>
          <div className={global.SmallTableRowHead} key={i}><span className='hidden-xs'>{meetInfo.meetName || <span>&nbsp;</span>}</span>&nbsp;
            <button className={global.IconButton}
              type="button"
              onClick={(e) => onClick(e, meetInfo.meetId)}>
              {expandedId === meetInfo.meetId ? <HideIcon /> : <ShowIcon />}
            </button>
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Meet Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{meetInfo.meetName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet Host</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{meetInfo.meetHost || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet Start Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{meetInfo?.startDate ? formatDate(meetInfo?.startDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Meet End Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{meetInfo?.endDate ? formatDate(meetInfo?.endDate) : <span>&nbsp;</span>}</div>
            </div>
          </div>
        </div>
        {expandedId === meetInfo.meetId &&
          <SmallDetailTable meetInfo={meetInfo} teamEntries={meetInfo.teamEntry} onCreate={onCreate} onEdit={onEdit} onDeleteClicked={onDeleteClicked} onView={onView} />
        }
      </Fragment>
    ))}
  </div>
);

const AthleteEntryGrid = ({ state, onCreate, onEdit, onDeleteClicked, onView }) => {
  const { onClick, expandedId } = useExpandedRow();
  if (Array.isArray(state.arrayData) === false || state.arrayData.length === 0) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <p>0 Search Results</p>
          </div>
        </div>
        <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
          <thead>
            <tr>
              <th>Meet Name</th>
              <th>Meet Host</th>
              <th>Meet Start - End Dates</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="4">No Results</td>
            </tr>
          </tbody>
        </table>
        <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          {state.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{state.arrayData.length === 1 ? `${state.arrayData.length} Search Result` : `${state.arrayData.length} Search Results`}</p>}
        </div>
      </div>
      <LargeGrid state={state} onCreate={onCreate} onEdit={onEdit} onDeleteClicked={onDeleteClicked} expandedId={expandedId} onClick={onClick} onView={onView} />
      <SmallGrid state={state} onCreate={onCreate} onEdit={onEdit} onDeleteClicked={onDeleteClicked} expandedId={expandedId} onClick={onClick} onView={onView} />
    </Fragment>
  );
};

export default AthleteEntryGrid;