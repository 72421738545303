import React, { Fragment } from 'react';

const DeleteOmeModalInfo = ({ modalState }) => {
  return (
    <Fragment>
      <p><strong>Meet Name:</strong> {modalState?.meetName}</p>
      <p><strong>Meet Host:</strong> {modalState?.meetHost}</p>
      <p><strong>Meet Dates: </strong> {modalState?.meetDates}</p>
    </Fragment>
  );
};

export default DeleteOmeModalInfo;