import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeInvitationsValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetInvitationsData from '../../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';
import { eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete, restrictionsIsComplete } from '../../../../components/leftNavigation/LeftNavValidation';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  organizationInvitations: []
};

const INITIAL_FORM_STATE = {
  orgUnit: []
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
  warningMsg: ''
};

const useOmeInvitations = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetInvitationsState, postOmeMeetInvitations } = useOmeMeetInvitationsData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { meetState } = useMeetData();
  const { orgUnitState, getOrgUnits } = useOrgUnitData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { formState, errorState, onFormValueChanged, isSubmitting, handleSubmit, setIsDirty,
    isDirty, setFormData, setErrors } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async (submitButton) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      let savedInvitationsContainFinaOrgUnit = false;
      let selectionsContainFinaOrgUnit = false;

      const finaOrgUnits = orgUnitState.arrayData.find(x => x.id === FINA_ORG_UNIT_ID);

      //Check saved invitations for FINA
      for (let i = 0; i < omeMeetInvitationsState.arrayData?.length; i++) {
        //Check to see if FINA is a saved invitation
        if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits?.id) {
          savedInvitationsContainFinaOrgUnit = true;
          break;
        }
        //Check to see if a FINA continent is a saved invitation
        for (let j = 0; j < finaOrgUnits.children?.length; j++) {
          if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits.children[j].id) {
            savedInvitationsContainFinaOrgUnit = true;
            break;
          }
          //Check to see if a FINA country is a saved invitation
          for (let k = 0; k < finaOrgUnits.children[j]?.children?.length; k++) {
            if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits.children[j]?.children[k]?.id) {
              savedInvitationsContainFinaOrgUnit = true;
              break;
            }
          }
        }
      }

      //Check selections for FINA
      for (let i = 0; i < formState.orgUnit.length; i++) {
        //Check to see if FINA is selected
        if (formState.orgUnit[i]?.id === finaOrgUnits?.id) {
          selectionsContainFinaOrgUnit = true;
          break;
        }
        //Check to see if a FINA continent is selected
        for (let j = 0; j < finaOrgUnits.children?.length; j++) {
          if (formState.orgUnit[i]?.id === finaOrgUnits.children[j].id) {
            selectionsContainFinaOrgUnit = true;
            break;
          }
          //Check to see if a FINA country is selected
          for (let k = 0; k < finaOrgUnits.children[j]?.children?.length; k++) {
            if (formState.orgUnit[i]?.id === finaOrgUnits.children[j]?.children[k]?.id) {
              selectionsContainFinaOrgUnit = true;
              break;
            }
          }
        }
      }

      let displayPopUp = false;
      let warningMsg = '';
      //Show warning popup if previous FINA selections are unselected and there was a International fee set on Pricing
      if (savedInvitationsContainFinaOrgUnit === true && selectionsContainFinaOrgUnit === false) {
        for (let i = 0; i < omeMeetState?.objData?.omeMeetPrice?.length; i++) {
          if (omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID ||
            omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID) {
            displayPopUp = true;
            warningMsg = "Unselecting all FINA organizations will also delete the 'Cost Per Individual International Athlete' and 'Cost Per International Staff' provided on the Pricing Page."
          }
        }
      }

      if (displayPopUp === true) {
        setState({ ...state, submitButton: submitButton });
        setModalState({
          ...modalState,
          displayPopUp,
          warningMsg
        });
      }
      else {
        setState({ ...state, tryRedirect: true, submitButton: submitButton });
      }
    }
  };

  const tryValidateBeforeShowingWarningPopUp = async (submitButton) => {
    setState({ ...state, submitButton: submitButton });
    let displayPopUp = false;
    let warningMsg = '';
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      let savedInvitationsContainFinaOrgUnit = false;
      let selectionsContainFinaOrgUnit = false;

      const finaOrgUnits = orgUnitState.arrayData.find(x => x.id === FINA_ORG_UNIT_ID);

      //Check saved invitations for FINA
      for (let i = 0; i < omeMeetInvitationsState.arrayData?.length; i++) {
        //Check to see if FINA is a saved invitation
        if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits?.id) {
          savedInvitationsContainFinaOrgUnit = true;
          break;
        }
        //Check to see if a FINA continent is a saved invitation
        for (let j = 0; j < finaOrgUnits.children?.length; j++) {
          if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits.children[j].id) {
            savedInvitationsContainFinaOrgUnit = true;
            break;
          }
          //Check to see if a FINA country is a saved invitation
          for (let k = 0; k < finaOrgUnits.children[j]?.children?.length; k++) {
            if (omeMeetInvitationsState.arrayData[i]?.orgUnitId === finaOrgUnits.children[j]?.children[k]?.id) {
              savedInvitationsContainFinaOrgUnit = true;
              break;
            }
          }
        }
      }

      //Check selections for FINA
      for (let i = 0; i < formState.orgUnit.length; i++) {
        //Check to see if FINA is selected
        if (formState.orgUnit[i]?.id === finaOrgUnits?.id) {
          selectionsContainFinaOrgUnit = true;
          break;
        }
        //Check to see if a FINA continent is selected
        for (let j = 0; j < finaOrgUnits.children?.length; j++) {
          if (formState.orgUnit[i]?.id === finaOrgUnits.children[j].id) {
            selectionsContainFinaOrgUnit = true;
            break;
          }
          //Check to see if a FINA country is selected
          for (let k = 0; k < finaOrgUnits.children[j]?.children?.length; k++) {
            if (formState.orgUnit[i]?.id === finaOrgUnits.children[j]?.children[k]?.id) {
              selectionsContainFinaOrgUnit = true;
              break;
            }
          }
        }
      }

      //Show warning popup if previous FINA selections are unselected and there was a International fee set on Pricing
      if (savedInvitationsContainFinaOrgUnit === true && selectionsContainFinaOrgUnit === false) {
        for (let i = 0; i < omeMeetState?.objData?.omeMeetPrice?.length; i++) {
          if (omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID ||
            omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID) {
            displayPopUp = true;
            warningMsg = "Unselecting all FINA organizations will also delete the 'Cost Per Individual International Athlete' and 'Cost Per International Staff' provided on the Pricing Page."
          }
        }
      }

      if (displayPopUp === true) {
        setModalState({
          ...modalState,
          displayPopUp,
          warningMsg
        });
      } else {
        setIsDirty(true);
        handleSubmit();
      }
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      tryValidateBeforeShowingWarningPopUp(HostRegistrationConstants.CONTINUE);
    }
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      tryValidateBeforeShowingWarningPopUp(HostRegistrationConstants.SAVE_AND_EXIT);
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.BACK);
    }
    else {
      tryValidateBeforeShowingWarningPopUp(HostRegistrationConstants.BACK);
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const getNameForIdRecurse = (id, node) => {
    let name = null;
    if (id === node.id) {
      name = { name: node.name, node: node };
    } else if (Array.isArray(node.children) && node.children.length > 0) {
      node.children.forEach(child => {
        if (!name) {
          name = getNameForIdRecurse(id, child);
        }
      });
    }

    return name;
  };

  const GetNameIdPairsRemoveAnyChildren = (treeData, idValues, lastSelectedOrgUnitId) => {
    //Remove any children selections if the lastSelectedOrgUnitId is a parent of those children selections
    const newValues = JSON.parse(JSON.stringify(idValues));
    let newObj = null;
    treeData.forEach(node => {
      if (!newObj) {
        const lastSelectedOrgUnitIdName = getNameForIdRecurse(lastSelectedOrgUnitId, node);
        if (lastSelectedOrgUnitIdName) {
          for (let i = 0; i < idValues.length; i++) {
            if (idValues[i] !== lastSelectedOrgUnitId) {
              const childSelected = getNameForIdRecurse(idValues[i], lastSelectedOrgUnitIdName.node);
              if (childSelected) {
                const childIndex = newValues.findIndex(x => x === idValues[i]);
                newValues.splice(childIndex, 1);
              }
            }
          }
        }
      }
    });

    return newValues;
  };

  const GetNameIdPairsRemoveAnyParents = (treeData, idValues) => {
    //Remove any parent selections if any children are selected
    const newValues = [];
    if (Array.isArray(idValues) && idValues.length > 0) {
      idValues.forEach(id => {
        let newObj = null;
        treeData.forEach(node => {
          if (!newObj) {
            const name = getNameForIdRecurse(id, node);

            if (name) {
              let addToNewValues = true;
              for (let i = 0; i < idValues.length; i++) {
                if (idValues[i] !== id) {
                  const childSelected = getNameForIdRecurse(idValues[i], name.node);
                  if (childSelected) {
                    addToNewValues = false;
                  }
                }
              }
              if (addToNewValues === true) {
                newObj = { id: id, name: name.name };
              }
            }
          }
        });

        if (newObj) {
          newValues.push(newObj);
        }
      });
    }

    return newValues;
  };

  const onOrgUnitFormValueChanged = (orgUnit, newValue) => {
    let allSelectedOrgUnitIds = [];
    let lastSelectedOrgUnitId = '';

    for (let i = 0; i < newValue.length; i++) {
      allSelectedOrgUnitIds.push(newValue[i].id);
      const currentlyInFormState = formState.orgUnit.find(x => x.id === newValue[i].id);
      if (!currentlyInFormState) {
        lastSelectedOrgUnitId = newValue[i].id;
      }
    }

    const updatedOrgUnitIdsWithoutChildren = GetNameIdPairsRemoveAnyChildren(orgUnitState.treeData, allSelectedOrgUnitIds, lastSelectedOrgUnitId);
    const updatedOrgUnitIdsWithoutChildrenAndParents = GetNameIdPairsRemoveAnyParents(orgUnitState.treeData, updatedOrgUnitIdsWithoutChildren);
    onFormValueChanged(orgUnit, updatedOrgUnitIdsWithoutChildrenAndParents);
  };

  function submitFormCallback(formState) {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));

    //Delete cost per individual international athlete and cost per international staff if all FINA organizations are unselected
    if (modalState.displayPopUp === true) {
      const selectedAthleteOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID);
      if (selectedAthleteOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(selectedAthleteOmeFeeTypeIdIndex, 1);
      }
      const selectedStaffOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === INTL_STAFF_OME_FEE_TYPE_ID);
      if (selectedStaffOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(selectedStaffOmeFeeTypeIdIndex, 1);
      }

      onModalCanceled();
    }
    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);

    //Handle invitation changes
    const updatedOmeMeetInvitationsArray =
      formState.orgUnit?.map(x => {
        return (
          {
            orgUnitId: x.id
          }
        )
      });
    postOmeMeetInvitations(omeMeetState?.objData?.omeMeetId, updatedOmeMeetInvitationsArray);

    setState({ ...state, tryRedirect: true });
  };

  function formStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    //Edit invitations
    if (omeMeetInvitationsState.arrayData && omeMeetInvitationsState.arrayData?.length > 0) {
      const orgUnitIdArray = omeMeetInvitationsState.arrayData.map(x => { return { id: x.orgUnitId } });
      const orgUnitIdArrayValues = [];
      for (let i = 0; i < orgUnitIdArray.length; i++) {
        orgUnitIdArrayValues.push(orgUnitIdArray[i].id);
      }

      for (let i = 0; i < orgUnitIdArrayValues.length; i++) {
        let match = formState.orgUnit?.find(x => x.id === orgUnitIdArrayValues[i]);
        if (!match) {
          return false;
        }
      }

      for (let i = 0; i < formState.orgUnit?.length; i++) {
        let match = orgUnitIdArrayValues?.find(x => x === formState.orgUnit[i].id);
        if (!match) {
          return false;
        }
      }
    }
    //Add invitations
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === false && orgUnitState.isArrayLoading === false) {
      getOrgUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState]);

  useEffect(() => {
    let selectedOrgUnitNames = [];
    if (Array.isArray(formState.orgUnit) === true && formState.orgUnit.length > 0) {
      formState.orgUnit.forEach(x => selectedOrgUnitNames.push(x.name));
    }
    const selectedOrgUnitNamesString = selectedOrgUnitNames.join(', ');
    setState({
      ...state,
      organizationInvitations: selectedOrgUnitNamesString
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.orgUnit]);

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === true && Object.keys(omeMeetState.objData).length > 0 &&
      omeMeetInvitationsState.isArrayLoaded === true && omeMeetInvitationsState.isArrayLoaded === true) {
      if (Array.isArray(omeMeetInvitationsState.arrayData)) {
        const orgUnitIdArray = omeMeetInvitationsState.arrayData.map(x => { return { id: x.orgUnitId } });
        const orgUnitIdArrayValues = [];
        for (let i = 0; i < orgUnitIdArray.length; i++) {
          orgUnitIdArrayValues.push(orgUnitIdArray[i].id);
        }
        if (orgUnitIdArrayValues?.length > 0) {
          setFormData({
            ...formState,
            orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, orgUnitIdArrayValues) || [],
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded, omeMeetState.objData, omeMeetInvitationsState.isArrayLoaded]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData, omeMeetInvitationsState.arrayData]);

  useEffect(() => {
    if (isReadyToRoute === true && omeMeetState.isSaving === false && omeMeetInvitationsState.isSaving === false
      && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        const meetInfoRoutePermission = navRoutes?.OME_HOST_MEET_INFO?.permission;
        const restrictionsRoutePermission = navRoutes?.OME_HOST_RESTRICTIONS?.permission;
        const eligibilityRoutePermission = navRoutes?.OME_HOST_ELIGIBILITY?.permission;
        if (meetInfoIsComplete(omeMeetState.objData, meetState.objData, meetInfoRoutePermission) === false || eligibilityIsComplete(omeMeetState.objData, eligibilityRoutePermission) === false ||
          restrictionsIsComplete


            (omeMeetState.objData, restrictionsRoutePermission) === false || invitationsIsComplete(omeMeetInvitationsState.arrayData) === false) {
          setErrors({ ...errorState, error: 'Meet Info, Eligibility, Restrictions, and Invitations must be complete before continuing to Sessions.' });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_SESSIONS);
          }
          else {
            navigate(HostNavLinks.OME_MEET_SESSIONS);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_RESTRICTIONS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, omeMeetInvitationsState.isSaving, state, isReadyToRoute]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryEndDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    omeMeetState,
    omeMeetInvitationsState,
    organizationInvitations: state?.organizationInvitations,
    orgUnitState,
    formState,
    errorState,
    handleSubmit,
    onNextButtonClicked,
    onSaveAndExitButtonClicked,
    onBackButtonClicked,
    onOrgUnitFormValueChanged,
    modalState,
    onModalCanceled,
    allowEdits
  };
};

export default useOmeInvitations;