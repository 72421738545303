import useDateTimeData from '../../../state/datetime/UseDateTimeData';

const useTimeZoneDropdown = () => {
  const {dateTimeState} = useDateTimeData();

  return {
    options: dateTimeState?.objData?.timeZones || []
  };
};

export default useTimeZoneDropdown;