/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ReadOnlyGridRow = ({ meetEvent }) => (
  <tr>
    <td>{meetEvent.eventNumber || ''}</td>
    <td>{meetEvent.eventName || ''}</td>
    <td>{meetEvent.eventCompetitionGenderTypeName || ''}</td>
    <td>{meetEvent.displayBaseTime || ''}</td>
  </tr>
);

const SelectionMeetEventsReadOnlyGridLarge = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event Number</th>
        <th>Event</th>
        <th>Gender</th>
        <th>Base Time</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((meetEvent, i) => <ReadOnlyGridRow key={i} meetEvent={meetEvent} />)
          : <tr><td colSpan="4">No Meet Events</td></tr>
      }
    </tbody>
  </table>
);

export default SelectionMeetEventsReadOnlyGridLarge;