import { useEffect, useState } from 'react';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useMeetData from '../../../../common/state/meet/UseMeetData';

const INITIAL_VIEW_STATE = {
  reportParameters: { omeMeetId: '' },
  contextId: '',
  routeName: ''
};

const useMeetHostReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { omeMeetState } = useOmeMeetData();
  const { meetState } = useMeetData();

  useEffect(() => {
    if (meetState.objData.meetId && omeMeetState.objData.omeMeetId) {
      setViewState({
        ...viewState,
        reportParameters: { omeMeetId: omeMeetState.objData.omeMeetId },
        contextId: meetState.objData.meetId,
        routeName: 'MEET_HOST_REPORTING'
      });
    }
    else if (meetState.objData.meetId && !omeMeetState.objData.omeMeetId) {
      setViewState({
        ...viewState,
        routeName: undefined
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, meetState]);

  return { ...viewState };
};

export default useMeetHostReporting;