import { Fragment } from 'react';

import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ event, onCheckboxChange, bonus, onAddOverrideEntryClicked, onEditOverrideEntryClicked }) => {
  return (
    <tr key={event.meetEventId}>
      <td>{event.eventName || ''}</td>
      <td>{event.eventCompetitionGenderTypeName || ''}</td>
      <td>{event.ageGroupName || ''}</td>
      <td>{event.meetSessionName || ''}</td>
      <td>{event.entryTime ? formatTimeForDisplay(event.entryTime) : 'NT'} {event.entryTime ? event.qualifyingCourseCode || '' : ''}</td>
      <td>{event.isAlternateEvent === true ? `ALT: ${event.qualifyingEventName || ''}` : event.isNonConformingTime === true ? `NC: ${event.qualifyingEventName || ''}` : `${event.qualifyingEventName || ''}`}</td>
      <td>
        {(event.hasQualifyingTime === true || event.swimTimeEntryOverride === true) ?
          <Fragment>
            <div className='pull-left'>
              {(event.hasPaid === false || event.canBeDeleted === true) ?
                <Checkbox
                  className={global.IconButton}
                  label=""
                  name="isSelected"
                  onChange={(e) => { onCheckboxChange(e, event.meetEventId) }}
                  checked={event.isSelected === true && event.qualifiedAsBonus === bonus} /> :
                <Checkbox
                  className={global.IconButton}
                  label=""
                  name="isSelected"
                  onChange={(e) => { onCheckboxChange(e, event.meetEventId) }}
                  checked={event.isSelected === true && event.qualifiedAsBonus === bonus}
                  disabled={true} />}
            </div>
            {event.swimTimeEntryOverride === true ?
              <Fragment>&nbsp;&nbsp;<ActionIntraPageButton onClick={(e) => onEditOverrideEntryClicked(e, event)}>Edit Override Time</ActionIntraPageButton></Fragment> :
              <Fragment>&nbsp;&nbsp;<ActionIntraPageButton onClick={(e) => onAddOverrideEntryClicked(e, event)}>Add Override Time</ActionIntraPageButton></Fragment>}
          </Fragment>
          :
          <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ActionIntraPageButton onClick={(e) => onAddOverrideEntryClicked(e, event)}>Add Override Time</ActionIntraPageButton></Fragment>
        }
      </td>
    </tr>
  );
};

const AthleteUserEntriesSelectionLargeGrid = ({ gridData, isLoading, onCheckboxChange, bonus, onAddOverrideEntryClicked, onEditOverrideEntryClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event - Course</th>
        <th style={{ minWidth: '120px' }}>Comp. Category</th>
        <th style={{ minWidth: '90px' }}>Age Group</th>
        <th>Session</th>
        <th style={{ minWidth: '100px' }}>Entry Time</th>
        <th style={{ minWidth: '100px' }}>Time Event</th>
        <th style={{ minWidth: '230px' }}>Select</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="7">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((event, i) =>
            <GridRow
              key={i}
              event={event}
              onCheckboxChange={onCheckboxChange}
              bonus={bonus}
              onAddOverrideEntryClicked={onAddOverrideEntryClicked}
              onEditOverrideEntryClicked={onEditOverrideEntryClicked}
            />)
          :
          <tr>
            <td colSpan={7}>No Results</td>
          </tr>
      }
    </tbody>
  </table>
);

export default AthleteUserEntriesSelectionLargeGrid;