import { useEffect, useState } from 'react';

import useTimesReconData from '../../../state/meet/timesRecon/UseTimesReconData';
import useEmbeddedReportData from '../../../state/embeddedReport/UseEmbeddedReportData';

import sdifConversion from '../meetResultsUpload/lib/SDIFConversion';
import shared from '../meetResultsUpload/lib/Shared';

const INITIAL_VIEW_STATE = {
  reportParameters: { meetId: '' },
  contextId: '',
  routeName: ''
};

const useTimesReconUpload = (meetId) => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { timesReconState, postTimesRecon } = useTimesReconData();
  const [jsonFileState, setJsonFileState] = useState(getInitialJSONFileState);
  const [uiState, setUiState] = useState({ canUpload: true, message: '' });
  const { getEmbeddedReportListForContext } = useEmbeddedReportData();

  const makeIndividualTimesArray = sdifConversion.makeIndividualTimesArray;
  const makeClubObject = sdifConversion.makeClubObject;
  const makeMeetRelaysObject = shared.makeMeetRelaysObject;
  const makeMeetObject = shared.makeMeetObject;
  const makeRelayArray = sdifConversion.makeRelayArray;
  const checkFileStructure = sdifConversion.checkFileStructure;
  const makeErrorsObject = sdifConversion.makeErrorsObject;

  const onSubmitTimesReconResults = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let resultsObj = JSON.parse(jsonFileState.individualTimesReconContent);
    if (timesReconState.isSaving !== true) {
      postTimesRecon(resultsObj);
    }

    if (Object.keys(jsonFileState.relayTimesReconContent).length > 0) {
      let relayResultObj = JSON.parse(jsonFileState.relayTimesReconContent);
      if (timesReconState.isSaving !== true && relayResultObj.meet.relayEvents.length > 0) {
        postTimesRecon(relayResultObj);
      }
    }
  };

  const onDownloadClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let output = jsonFileState.individualTimesReconContent;
    const file = new Blob([output], { type: 'application/json' });
    const a = document.createElement('a');

    a.href = URL.createObjectURL(file);
    let fileName = "TimesReconIndividual.json";
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);

    if (Object.keys(jsonFileState.relayTimesReconContent).length !== 0) {
      let output2 = jsonFileState.relayTimesReconContent;
      const file2 = new Blob([output2], { type: 'application/json' });
      const a2 = document.createElement('a');

      a2.href = URL.createObjectURL(file2);
      fileName = "TimesReconRelay.json";
      a2.download = fileName;
      a2.click();

      URL.revokeObjectURL(a2.href);
    }

    if (Object.keys(jsonFileState.errorsContent).length !== 0) {
      let output3 = jsonFileState.errorsContent;
      const file3 = new Blob([output3], { type: 'application/json' });
      const a3 = document.createElement('a');

      a3.href = URL.createObjectURL(file3);
      fileName = "Errors.json";
      a3.download = fileName;
      a3.click();

      URL.revokeObjectURL(a3.href);
    }
  };

  const processFile = (fileContent, fileType) => {
    setUiState({ ...uiState, canUpload: true, message: '' });

    const isValidFileType = fileType.toUpperCase() === "SD3" || fileType.toUpperCase() === "CL2" || fileType.toUpperCase() === "ZIP";
    const isZipFile = fileType.toUpperCase() === "ZIP";

    const fileArray = fileContent.toString().replace(/\r\n/g, '\n').split('\n');
    let clubObj = { lscId: '', clubCode: '', clubName: '' };
    let individualTimesArray = [];
    let relayTimesArray = [];
    let fileArrayIndex = 0;
    let errorArray = [];
    let memberIdObj = { id: "" };

    if (checkFileStructure(fileArray, errorArray, fileArrayIndex) && isValidFileType && !isZipFile) {
      const isTimesRecon = true;
      for (let fileLine of fileArray) {
        switch (fileLine.substring(0, 2)) {
          case 'C1':
            clubObj = makeClubObject(fileLine);
            break;
          case 'D0':
            makeIndividualTimesArray(fileLine, individualTimesArray, fileArray, fileArrayIndex + 1, clubObj, errorArray, memberIdObj, isTimesRecon);
            break;
          case 'E0':
            makeRelayArray(relayTimesArray, fileArray, fileArrayIndex, clubObj, errorArray, memberIdObj, isTimesRecon);
            break;
          default:
            break;
        }
        fileArrayIndex++;
      }

      let meetObject = makeMeetObject(individualTimesArray, meetId);
      let relayMeetObject = makeMeetRelaysObject(relayTimesArray, meetId);
      let errorsObject = makeErrorsObject(errorArray, meetId);

      setJsonFileState({
        ...jsonFileState,
        individualTimesReconContent: JSON.stringify(meetObject, null, 4),
        relayTimesReconContent: relayMeetObject.meet.relayEvents.length > 0 ? JSON.stringify(relayMeetObject, null, 4) : {},
        errorsContent: errorsObject.errors.length > 0 ? JSON.stringify(errorsObject, null, 4) : {},
        indTimesCount: individualTimesArray.length,
        relayTimesCount: relayTimesArray.length,
        errors: errorArray.length,
        isValidFileType: isValidFileType,
        isZipFile: isZipFile
      });
    } else {
      setJsonFileState({
        ...jsonFileState,
        indTimesCount: 0,
        relayTimesCount: 0,
        errors: errorArray.length,
        isValidFileType: isValidFileType,
        isZipFile: isZipFile
      });
    }
  }

  const onRunTimesReconReport = (meetId) => {
    setViewState({
      ...viewState,
      reportParameters: { meetId: meetId },
      contextId: meetId,
      routeName: 'TIMES_RECON'
    });
  };

  useEffect(() => {
    if (viewState.routeName && viewState.contextId) {
      const reportListPromise = getEmbeddedReportListForContext(viewState.routeName, viewState.contextId);

      if (reportListPromise) {

        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const listNames = [];
            stateObj.arrayData.forEach(x => {
              if (listNames.filter(a => a.id === x.listName).length === 0) {
                listNames.push({ id: x.listName, name: x.listDisplayName });
              }
            });

            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: stateObj.arrayData.length === 1,
              reportInfoId: stateObj.arrayData.length === 1 ? stateObj.arrayData[0].reportInfoId : -1,
              listNames,
              rawReportList: [...stateObj.arrayData],
              reportList: [...stateObj.arrayData]
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: false,
              reportInfoId: -1,
              listNames: [],
              rawReportList: [],
              reportList: []
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showGrid: false,
            showReport: false,
            reportInfoId: -1,
            listNames: [],
            rawReportList: [],
            reportList: []
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.contextId]);

  function getInitialJSONFileState() {
    return {
      individualTimesReconContent: '',
      relayTimesReconContent: '',
      errorsContent: '',
      fileDownloadUrl: '',
      clubCount: 0,
      swimmerCount: 0,
      errorCount: 0,
      processingStarted: false
    };
  };

  return {
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    timesReconState,
    jsonFileState,
    uiState,
    processFile,
    onDownloadClicked,
    onSubmitTimesReconResults,
    onRunTimesReconReport
  }
}

export default useTimesReconUpload;