/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './NewSelectionSearchGrid.module.css';

const GridRow = ({ omeMeet, onCreateSelectionClicked, onExistingSelectionsClicked }) => (
  <tr>
    <td>{omeMeet.meetName || ''}</td>
    <td>{omeMeet.startDate ? formatDate(omeMeet.startDate) : ''} - {omeMeet.endDate ? formatDate(omeMeet.endDate) : ''}</td>
    <td>{omeMeet.city || ''}{omeMeet.city && omeMeet.stateCode ? '/' : ''}{omeMeet.stateCode || ''}</td>
    <td>
      {omeMeet.countOfSelections > 0
        ? (
          <button className={[global.AnchorButton, style.SelectionTypeButton].join(' ')} onClick={() => onExistingSelectionsClicked(omeMeet.omeMeetId, omeMeet.meetName)}>
            <span>{omeMeet.countOfSelections}</span>
          </button>
        ) : (
          <span>0</span>
        )}
    </td>
    <td>
      {omeMeet.hasMaleEvents === true && <button className={[global.AnchorButton, style.SelectionTypeButton].join(' ')} onClick={() => onCreateSelectionClicked(omeMeet.omeMeetId, true, false)}>
        <span>Men</span>
      </button>}
      {omeMeet.hasFemaleEvents === true && <button className={[global.AnchorButton, style.SelectionTypeButton].join(' ')} onClick={() => onCreateSelectionClicked(omeMeet.omeMeetId, false, true)}>
        <span>Women</span>
      </button>}
    </td>
  </tr>
);

const NewSelectionSearchGridLarge = ({ gridData, isLoading, onCreateSelectionClicked, onExistingSelectionsClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <th>Start Date - End Date</th>
        <th>Location</th>
        <th>Number of Existing Selections</th>
        <th>Start Selection for</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((omeMeet, i) => <GridRow key={i} omeMeet={omeMeet} onCreateSelectionClicked={onCreateSelectionClicked} onExistingSelectionsClicked={onExistingSelectionsClicked} />)
          : <tr><td colSpan="5">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default NewSelectionSearchGridLarge;
