import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import getInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import { meetInfoIsComplete } from './LeftNavValidation';

const getMeetInfo = (viewName, route, omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission) => {
  const isCurrent = ((viewName === Views.OME_MEET_INFO) || (viewName === Views.OME_MEET_INFO_NON_HQ)) === true ? true : false;

  const isComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);

  const item = route === '' ? getInitialStateOmeRoute(Views.OME_MEET_INFO) : getInitialStateMeetRoute(Views.OME_MEET_INFO);

  return {
    ...item,
    isCurrent,
    isComplete
  };
};

export default getMeetInfo;