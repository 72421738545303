import React, { createContext, useState } from 'react';

import OrgUnitData from './OrgUnitData';

export const OrgUnitStateContext = createContext();

const OrgUnitContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitData.INITIAL_STATE);

  return (
    <OrgUnitStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitStateContext.Provider>
  );
};

export default OrgUnitContextProvider;