import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatPhoneNumber } from '../../../../../common/utils/validation';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const GridRow = ({ coach, onDeleteCoachClicked }) => {
  return (
    <tr key={coach.orgUnitNonAthleteEntryId}>
      <td>{(coach.firstName || coach.middleName || coach.lastName) ? `${coach.firstName || ''} ${coach.preferredName !== '' && coach.preferredName !== coach.firstName ? '"' + coach.preferredName + '"' : ''} ${coach.middleName || ''} ${coach.lastName || ''}` : ''}</td>
      <td>{coach.phoneNumber ? formatPhoneNumber(coach.phoneNumber) : ''}</td>
      <td>{coach.email || ''}</td>
      <td>{coach.isInGoodStanding === true ? 'Yes' : coach.isInGoodStanding === false ? 'No' : ''}</td>
      <td>{coach.isInGoodStandingExpirationDate ? formatDate(coach.isInGoodStandingExpirationDate) : ''}</td>
      <td>
        {onDeleteCoachClicked && coach.hasPaid === false &&
          <button className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onDeleteCoachClicked(e, coach)}>
            <DeleteIcon />
          </button>}
      </td>
    </tr>
  );
};

const OrgUnitAdminCoachesLargeGrid = ({ gridData, isLoading, onDeleteCoachClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th style={{ minWidth: '110px' }}>Phone Number</th>
        <th>Email</th>
        <th style={{ minWidth: '150px' }}>Is In Good Standing?</th>
        <th style={{ minWidth: '215px' }}>Good Standing Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="6">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((coach, i) =>
            <GridRow
              key={i}
              coach={coach}
              onDeleteCoachClicked={onDeleteCoachClicked} />)
          :
          <tr>
            <td colSpan={6}>No Coaches</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminCoachesLargeGrid;