/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import DeleteIcon from '../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../common/utils/DateFunctions';

import global from '../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <th>Classification</th>
        <th>Meet Start - End Dates</th>
        <th>Location</th>
        <th>Organization</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (<Fragment>
          {state.isObjLoading === true ?
            <tr>
              <td colSpan="6">Loading...</td>
            </tr> :
            <tr>
              <td colSpan="6">No Meet Template Selected</td>
            </tr>
          }
        </Fragment>
        ) : Object.keys(state.objData).length > 0 ?
          (
            <tr>
              <td>{state.objData.meetName ? state.objData.meetName : ''}</td>
              <td>{state.objData.meetClassification?.typeName ? state.objData.meetClassification.typeName : ''}</td>
              <td>{formatDate(state.objData.startDate)} - {formatDate(state.objData.endDate)}</td>
              <td>{state.objData.facility ? (state.objData.facility.city || '') + '/' + (state.objData.facility.stateCode || '') : ''}</td>
              <td>{state.objData.hostOrgUnit ? state.objData.hostOrgUnit?.qualifiedOrgUnitCode : ''}</td>
              <td>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e)}>
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan="6">No Meet Template Selected</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ state, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Meet Template Selected</div>
            }
          </div>
        </div>
      )
      : Object.keys(state.objData).length > 0 ?
        (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{state.objData.meetName ? state.objData.meetName : <span>&nbsp;</span>}</span>&nbsp;
              <button className={global.IconButton} type="button" onClick={(e) => onDelete(e)}>
                <DeleteIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{state.objData.meetName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Classification</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{state.objData.meetClassification?.typeName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Start Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{state.objData.startDate ? formatDate(state.objData.startDate) : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet End Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{state.objData.endDate ? formatDate(state.objData.endDate) : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Location</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{state.objData.facility ? (state.objData.facility.city || '') + '/' + (state.objData.facility.stateCode || '') : ''}&nbsp;</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Organization</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{state.objData.hostOrgUnit ? state.objData.hostOrgUnit?.qualifiedOrgUnitCode : ''}</div>
              </div>
            </div>
          </div>
        )
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meet Template Selected</div>
            </div>
          </div>
        )}
  </div >
);

const SelectedMeetTemplateGrid = ({ meetState, onDelete }) => (
  <Fragment>
    <LargeGrid state={meetState} onDelete={onDelete} />
    <SmallGrid state={meetState} onDelete={onDelete} />
  </Fragment>
);

export default SelectedMeetTemplateGrid;