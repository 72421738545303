import React, { createContext, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const RecordListStateContext = createContext();


export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const RecordListContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <RecordListStateContext.Provider value={stateHook}>
      {children}
    </RecordListStateContext.Provider>
  );
};

export default RecordListContextProvider;