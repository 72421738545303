import React, { Fragment } from 'react';

import useOrgUnitAdminRosterSelection from './UseOrgUnitAdminRosterSelection';

import OrgUnitAdminRosterSelectionGrid from './OrgUnitAdminRosterSelectionGrid';

import OrgGroupCombobox from '../../../../components/comboboxes/orgGroupCombobox/OrgGroupCombobox';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import Input from '../../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import SearchMemberPopup from '../../../../../common/components/searches/searchMember/SearchMemberPopup';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterSelection = () => {
  const { state, gridState, omeMeetState, omeMeetOrgUnitAthleteEntryRosterSelectionState, onSelectAll, onUnselectAll,
    onSetAllRelayOnly, onUnsetAllRelayOnly, onSaveClicked, onBackClicked, onCheckboxChange, onYesNoSwitchChange, onFilterClicked, onClearFilterClicked,
    onFormValueChanged, onValueTextPairChanged, formState, errorState, competitionGenderOptions,
    omeMeetOrgUnitEntryState, orgGroupState, USAS_ORGANIZATION_ID, PRACTICE_GROUP_TYPE_ID, setFormData,
  } = useOrgUnitAdminRosterSelection();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster Selection</Headings.H3>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className={formState.unattachedSwimmers !== true ? global.DisplayComponent : global.HideComponent}>
            <div className='col-xs-12 col-sm-6 col-md-4'>
              <Dropdown
                label="Competition Category"
                name="competitionGenderTypeId"
                options={competitionGenderOptions}
                value={formState.competitionGenderTypeId}
                error={errorState.competitionGenderTypeId}
                message={errorState.competitionGenderTypeId}
                isLoading={false}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(newValue, 'competitionGenderTypeId', newValueLabel, 'competitionGenderTypeName');
                }} />
            </div>
            <div className="col-xs-6 col-sm-3 col-md-2">
              <Input
                label="Min Age"
                name="minAge"
                type="number"
                min="1"
                max="99"
                value={formState.minAge}
                error={errorState.minAge}
                message={errorState.minAge}
                onChange={(value) => { onFormValueChanged('minAge', value) }} />
            </div>
            <div className="col-xs-6 col-sm-3 col-md-2">
              <Input
                label="Max Age"
                name="maxAge"
                type="number"
                min="1"
                max="99"
                value={formState.maxAge}
                error={errorState.maxAge}
                message={errorState.maxAge}
                onChange={(value) => { onFormValueChanged('maxAge', value) }} />
            </div>
            <div className={omeMeetOrgUnitEntryState?.objData?.orgUnit?.organizationId === USAS_ORGANIZATION_ID ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <OrgGroupCombobox
                  label={"Club Practice Group"}
                  name={"orgGroupId"}
                  valueToMatch={formState.orgGroupName}
                  error={errorState.orgGroupId}
                  message={errorState.orgGroupId}
                  onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgGroupId', newValueLabel, 'orgGroupName', e); }}
                  orgUnitId={omeMeetOrgUnitEntryState?.objData?.orgUnit?.orgUnitId}
                  groupTypeId={PRACTICE_GROUP_TYPE_ID}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="First or Preferred Name"
                name="firstOrPreferredName"
                value={formState.firstOrPreferredName}
                error={errorState.firstOrPreferredName}
                message={errorState.firstOrPreferredName}
                onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="Last Name"
                name="lastName"
                value={formState.lastName}
                error={errorState.lastName}
                message={errorState.lastName}
                onChange={(value) => { onFormValueChanged('lastName', value) }} />
            </div>
          </div>
          <div className={state.showEligibilityFilter === true ? global.DisplayComponent : global.HideComponent}>
            <div className='col-xs-12 col-sm-6 col-md-4'>
              <YesNoSwitch
                label='Only Show Athletes Eligible for Individual Events?*'
                name='individualEligibility'
                checked={formState.individualEligibility}
                error={errorState.individualEligibility}
                message={errorState.individualEligibility}
                onChange={(value) => { onFormValueChanged('individualEligibility', value); }} />
            </div>
          </div>
          <div className={state.showUnattachedFilter === true ? global.DisplayComponent : global.HideComponent}>
            <div className='col-xs-12 col-sm-6 col-md-4'>
              <YesNoSwitch
                label='Search for Unattached Athlete?*'
                name='unattachedSwimmers'
                checked={formState.unattachedSwimmers}
                error={errorState.unattachedSwimmers}
                message={errorState.unattachedSwimmers}
                onChange={(value) => { onFormValueChanged('unattachedSwimmers', value); }} />
            </div>
            <div className={formState.unattachedSwimmers === true ? global.DisplayComponent : global.HideComponent}>
              <div className='col-xs-12 col-sm-6 col-md-4'>
                <SearchMemberPopup
                  label={'Member Id*'}
                  memberIdName={'memberId'}
                  formState={formState}
                  errorState={errorState}
                  setFormData={setFormData}
                  onFormValueChanged={onFormValueChanged}
                />
              </div>
            </div>
          </div>
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </div>
      </form>
      {gridState.gridData.length > 15 && omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoading === false &&
        < div className='visible-md visible-lg' >
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            </div>
          </div>
          {omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true &&
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSetAllRelayOnly}>Select All Results and Set Relay Only Swimmer to 'Yes'</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onUnsetAllRelayOnly}>Select All Results and Set Relay Only Swimmer to 'No'</SecondaryButton>
              </div>
            </div>}
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div >}
      {gridState.gridData.length > 0 && omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoading === false &&
        < div className='hidden-md hidden-lg' >
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            </div>
          </div>
          {omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true &&
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSetAllRelayOnly}>Select All Results and Set Relay Only Swimmer to 'Yes'</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onUnsetAllRelayOnly}>Select All Results and Set Relay Only Swimmer to 'No'</SecondaryButton>
              </div>
            </div>}
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div >}
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <OrgUnitAdminRosterSelectionGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoading}
            onCheckboxChange={onCheckboxChange}
            onYesNoSwitchChange={onYesNoSwitchChange}
            allowRelayOnly={omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer ?? false}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
        </div>
      </div>
      {omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true &&
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSetAllRelayOnly}>Select All Results and Set Relay Only Swimmer to 'Yes'</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onUnsetAllRelayOnly}>Select All Results and Set Relay Only Swimmer to 'No'</SecondaryButton>
          </div>
        </div>}
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryRosterSelectionState.isArrayLoading || orgGroupState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryRosterSelectionState.isSaving} />
    </Fragment >
  );
};

export default OrgUnitAdminRosterSelection;