import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import postMeetRaceStatsData from './PostMeetRaceStatsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetRaceStatsData = {
  INITIAL_STATE, postMeetRaceStatsData
};

export default MeetRaceStatsData;