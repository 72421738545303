/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import PlusIcon from '../../../../../common/components/icons/PlusIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ meet, onAddClicked, onEditClicked }) => (
  <tr>
    <td>
      {meet?.laneSheetMeetId === null
        ? <span>{meet?.meetName || ''}</span>
        : <a onClick={() => onEditClicked(meet.laneSheetMeetId)}>{meet?.meetName || ''}</a>
      }
    </td>
    <td>{meet?.organization || ''}</td>
    <td>{meet?.dateRange || ''}</td>
    <td>
      {meet?.laneSheetMeetId === null && (
        <button
          className={[global.IconButtonMargin, 'pull-right'].join(' ')}
          type="button"
          onClick={() => onAddClicked(meet)}>
          <PlusIcon toolTipText='Add Lane Sheet' />
        </button>
      )}
    </td>
  </tr>
);

const LaneSheetLandingLargeGrid = ({ isLoading, gridData, onAddClicked, onEditClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <th>Host Organization</th>
        <th>Meet Start Date - Meet End Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((meet, i) =>
            <LargeGridRow
              key={i}
              meet={meet}
              onAddClicked={onAddClicked}
              onEditClicked={onEditClicked} />)
          : <tr><td colSpan="4">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetLandingLargeGrid