import { Fragment } from 'react';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ event, onDeleteEventClicked }) => {
  return (
    <Fragment key={event.individualAthleteMeetEventId + 'Fragment'}>
      <tr key={event.individualAthleteMeetEventId}>
        <td>{event.eventName || ''}</td>
        <td>{event.eventCompetitionGenderTypeName || ''}</td>
        <td>{event.ageGroupName || ''}</td>
        <td>{event.meetSessionName || ''}</td>
        <td>{event.entryTime ? formatTimeForDisplay(event.entryTime) : 'NT'} {event.entryTime ? event.qualifyingCourseCode || '' : ''}</td>
        <td>{event.isAlternateEvent === true ? `ALT: ${event.qualifyingEventName || ''}` : event.isNonConformingTime === true ? `NC: ${event.qualifyingEventName || ''}` : `${event.qualifyingEventName || ''}`}</td>
        <td>{event.qualifiedAsBonus === true ? 'Yes' : event.qualifiedAsBonus === false ? 'No' : ''}</td>
        <td>{event.swimTimeEntryOverride === true ? 'Yes' : event.swimTimeEntryOverride === false ? 'No' : ''}</td>
        <td>{event.status || ''}</td>
        <td>{onDeleteEventClicked && (event.hasPaid === false || event.canBeDeleted === true) && <button className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDeleteEventClicked(e, event)}>
          <DeleteIcon />
        </button>}
        </td>
      </tr>
    </Fragment>
  );
};

const OrgUnitAdminRosterEntriesDetailLargeGrid = ({ gridData, isLoading, onDeleteEventClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event - Course</th>
        <th style={{ minWidth: '120px' }}>Comp. Category</th>
        <th style={{ minWidth: '90px' }}>Age Group</th>
        <th>Session</th>
        <th style={{ minWidth: '100px' }}>Entry Time</th>
        <th style={{ minWidth: '100px' }}>Time Event</th>
        <th>Bonus?</th>
        <th>Override?</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="10">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((event, i) =>
            <GridRow
              key={i}
              event={event}
              onDeleteEventClicked={onDeleteEventClicked}
            />)
          :
          <tr>
            <td colSpan={10}>No Individual Event Entries</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminRosterEntriesDetailLargeGrid;