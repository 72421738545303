import { useContext } from 'react';

import LaneSheetMeetEventAthletesData from './LaneSheetMeetEventAthletesData';

import { LaneSheetMeetEventAthletesStateContext } from './LaneSheetMeetEventAthletesContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const useLaneSheetMeetEventAthletesData = () => {
  const [laneSheetMeetEventAthletesState, setLaneSheetMeetEventAthletesState] = useContext(LaneSheetMeetEventAthletesStateContext);

  const postLaneSheetMeetEventAthlete = (laneSheetMeetEventAthleteObj, state, setState) => {
    return LaneSheetMeetEventAthletesData.postLaneSheetMeetEventAthleteData(laneSheetMeetEventAthleteObj, state, setState);
  };

  const getLaneSheetMeetEventAthletes = (laneSheetEventId) => {
    return LaneSheetMeetEventAthletesData.getLaneSheetMeetEventAthletesData(laneSheetEventId, laneSheetMeetEventAthletesState, setLaneSheetMeetEventAthletesState);
  };

  const deleteLaneSheetMeetEventAthlete = (laneSheetPersonId, state, setState) => {
    return LaneSheetMeetEventAthletesData.deleteLaneSheetMeetEventAthleteData(laneSheetPersonId, state, setState);
  };

  const resetLaneSheetMeetEventAthletesState = () => {
    setLaneSheetMeetEventAthletesState({
      ...BASIC_INITIAL_STATE
    });
  };

  return {
    laneSheetMeetEventAthletesState,
    resetLaneSheetMeetEventAthletesState,
    postLaneSheetMeetEventAthlete,
    getLaneSheetMeetEventAthletes,
    deleteLaneSheetMeetEventAthlete
  };
};

export default useLaneSheetMeetEventAthletesData;