import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../views/meet/NavLinks';
import MyMeetsNavLinks from '../../views/myMeets/MyMeetsNavLinks';
import HostRegistrationViews from '../../views/host/hostRegistration/HostRegistrationViews';
import { getViewData } from '../../views/host/hostRegistration/HostRegistrationViews';

import useOmeMeetDateValidationData from '../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useOmeMeetData from '../../state/omeMeet/UseOmeMeetData';
import useSearchMeetData from '../../../common/state/searchMeet/UseSearchMeetData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  name: '',
  subHeaderText: '',
  instructionText: '',
  nextButtonText: '',
  backButtonText: '',
  saveAndExitButtonText: ''
};

const useViewTemplate = (viewName, onBackButtonClicked) => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const [view, setView] = useState(INITIAL_STATE);

  const handleMeetRouteSaveAndExit = () => {
    setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
    navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
  }

  const handleBackButtonClicked = (e) => {
    if (onBackButtonClicked instanceof Function) {
      onBackButtonClicked(e);
    } else {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      if (omeMeetState.route !== '') {
        handleMeetRouteSaveAndExit();
      }
      else {
        navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
      }
    }
  };

  useEffect(() => {
    const foundView = getViewData(viewName);
    if (foundView) {
      if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
        if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryChangeDeadline === false) {
          foundView.saveAndExitButtonText = 'Exit';
          if (foundView.name === HostRegistrationViews.OME_MEET_INFO) {
            foundView.backButtonText = '';
          }
          if (foundView.name === HostRegistrationViews.OME_MEET_SUBMITTED) {
            foundView.nextButtonText = '';
          }
        }
      }
      setView({ ...foundView });
    }
  }, [viewName, omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryChangeDeadline, omeMeetState.objData?.status]);

  return {
    ...view,
    handleBackButtonClicked
  };
};

export default useViewTemplate;
