import { useContext } from 'react';

import LaneSheetMeetEventRelayTeamsData from './LaneSheetMeetEventRelayTeamsData';

import { LaneSheetMeetEventRelayTeamsStateContext } from './LaneSheetMeetEventRelayTeamsContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const useLaneSheetMeetEventRelayTeamsData = () => {
  const [laneSheetMeetEventRelayTeamsState, setLaneSheetMeetEventRelayTeamsState] = useContext(LaneSheetMeetEventRelayTeamsStateContext);

  const postLaneSheetMeetEventRelayTeam = (laneSheetMeetEventRelayTeamObj, state, setState) => {
    return LaneSheetMeetEventRelayTeamsData.postLaneSheetMeetEventRelayTeamOrgUnitData(laneSheetMeetEventRelayTeamObj, state, setState);
  };

  const getLaneSheetMeetEventRelayTeams = (laneSheetEventId) => {
    return LaneSheetMeetEventRelayTeamsData.getLaneSheetMeetEventRelayTeamsData(laneSheetEventId, laneSheetMeetEventRelayTeamsState, setLaneSheetMeetEventRelayTeamsState);
  };

  const deleteLaneSheetMeetEventRelayTeam = (laneSheetRelayTeamId, state, setState) => {
    return LaneSheetMeetEventRelayTeamsData.deleteLaneSheetMeetEventRelayTeamData(laneSheetRelayTeamId, state, setState);
  };

  const resetLaneSheetMeetEventRelayTeamsState = () => {
    setLaneSheetMeetEventRelayTeamsState({
      ...BASIC_INITIAL_STATE
    });
  };

  return {
    laneSheetMeetEventRelayTeamsState,
    resetLaneSheetMeetEventRelayTeamsState,
    postLaneSheetMeetEventRelayTeam,
    getLaneSheetMeetEventRelayTeams,
    deleteLaneSheetMeetEventRelayTeam
  };
};

export default useLaneSheetMeetEventRelayTeamsData;