import { Fragment } from "react";

import ExistingSelectionsGridLarge from "./ExistingSelectionsGridLarge";

const ExistingSelectionsGrid = ({ gridData, isLoading, onEditSelectionClicked, onDeleteSelectionClicked }) => (
  <Fragment>
    <ExistingSelectionsGridLarge gridData={gridData} isLoading={isLoading} onEditSelectionClicked={onEditSelectionClicked} onDeleteSelectionClicked={onDeleteSelectionClicked} />
  </Fragment>
);

export default ExistingSelectionsGrid;