import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryIdData from './GetOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryIdData';
import getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryIdData from './GetOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  omeMeetOrgUnitEntryId: Constants.DEFAULT_ID
};

const OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData = {
  INITIAL_STATE,
  getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryIdData,
  getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryIdData
}

export default OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData;