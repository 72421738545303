import React, { createContext, useState } from 'react';

import ApprovedClubsForSecondaryRepData from './ApprovedClubsForSecondaryRepData';

export const ApprovedClubsForSecondaryRepStateContext = createContext();

const ApprovedClubsForSecondaryRepContextProvider = ({ children }) => {
  const stateHook = useState(ApprovedClubsForSecondaryRepData.INITIAL_STATE);

  return (
    <ApprovedClubsForSecondaryRepStateContext.Provider value={stateHook}>
      {children}
    </ApprovedClubsForSecondaryRepStateContext.Provider>
  );
};

export default ApprovedClubsForSecondaryRepContextProvider;