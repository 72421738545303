import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { MEET_EVENTS_NAME } from "../components/leftNav/SelectionsLeftNavConstants";

import NavLinks from "../utils/NavLinks";

import useSelectionsMeetEventsData from "../../../state/selectionsMeetEvents/UseSelectionsMeetEventsData";
import useSelectionsLeftNavData from "../../../state/selectionsLeftNav/UseSelectionsLeftNavData";
import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsLoadingModalData from "../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

import { formatTimeForDisplay, formatTimeForFilterObject } from "../../../../common/utils/TimesUtils";
import { isValidSwimTime } from "../../../../common/utils/validation";

const INITIAL_STATE = {
  gridData: [],
  showGridError: false,
  gridErrorMessage: 'Must provide a valid base time for every event',
  isDirty: false
};

const useSelectionsMeetEvents = () => {
  const navigate = useNavigate();
  const { selectionsLeftNavState, updateCurrentLeftNavData } = useSelectionsLeftNavData();
  const { selectionsState } = useSelectionsData();
  const { selectionsMeetEventsState, getSelectionMeetEvents, putSelectionMeetEvents } = useSelectionsMeetEventsData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [state, setState] = useState(INITIAL_STATE);

  // used for readonly continue
  const onContinueClicked = () => {
    navigate(NavLinks.SELECTIONS_MEET_ENTRIES);
  };

  // used for edit save & continue
  const onSaveAndContinueClicked = () => {
    if (isMeetEventsValid(state.gridData)) {
      if (state.isDirty === true) {
        const selectionMeetId = selectionsState.objData?.selectionMeetId;
        const selectionsArray = createSelectionsArray(state.gridData);

        updateCurrentLeftNavData(MEET_EVENTS_NAME, true);

        const putSelectionMeetEventsPromise = putSelectionMeetEvents(selectionMeetId, selectionsArray);

        if (putSelectionMeetEventsPromise !== null) {
          putSelectionMeetEventsPromise.then((newState) => {
            if (newState !== null) {
              navigate(NavLinks.SELECTIONS_MEET_ENTRIES);
            }
          }).catch((e) => {
            updateCurrentLeftNavData(MEET_EVENTS_NAME, false);

            setContextStateError(true);
          });
        }
      } else {
        navigate(NavLinks.SELECTIONS_MEET_ENTRIES);
      }
    } else {
      setState({
        ...state,
        showGridError: true,
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.SELECTIONS_CONFIGURATION);
  };

  const onBaseTimeChanged = (displayValue, value, meetEventId) => {
    const newGridData = JSON.parse(JSON.stringify(state.gridData));

    const targetMeetEvent = newGridData.find(x => x.meetEventId === meetEventId);

    if (targetMeetEvent) {
      targetMeetEvent.displayBaseTime = displayValue;
      targetMeetEvent.baseTime = value;
    }

    setState({
      ...state,
      gridData: newGridData,
      isDirty: true
    });
  };

  useEffect(() => {
    if (selectionsLeftNavState.isLoaded === true) {
      const selectionMeetId = selectionsState.objData?.selectionMeetId;

      if (selectionMeetId > 0) {
        const getSelectionMeetEventsPromise = getSelectionMeetEvents(selectionMeetId);

        if (getSelectionMeetEventsPromise !== null) {
          getSelectionMeetEventsPromise.then((newState) => {
            if (newState !== null) {
              const gridData = JSON.parse(JSON.stringify(newState.arrayData));

              for (const meetEvent of gridData) {
                meetEvent.displayBaseTime = meetEvent.baseTime ? formatTimeForDisplay(meetEvent.baseTime) : '';
              };

              setState({
                ...state,
                gridData
              });

              updateCurrentLeftNavData(MEET_EVENTS_NAME, true);
            }
          }).catch((e) => {
            updateCurrentLeftNavData(MEET_EVENTS_NAME, false);

            setContextStateError(true);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState, selectionsLeftNavState.isLoaded]);

  function isMeetEventsValid(gridData) {
    for (const meetEvent of gridData) {
      if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(meetEvent.baseTime)))) {
        return false;
      }
    }

    return true;
  };

  function createSelectionsArray(gridData) {
    const selectionMeetEvent = JSON.parse(JSON.stringify(gridData));

    for (const meetEvent of selectionMeetEvent) {
      meetEvent.displayBaseTime = undefined;
    };

    return selectionMeetEvent;
  };

  return {
    isLoading: selectionsMeetEventsState.isArrayLoading,
    isReadOnly: selectionsState.objData?.isConfigComplete === true,
    state,
    onContinueClicked,
    onBaseTimeChanged,
    onSaveAndContinueClicked,
    onBackClicked
  };
};

export default useSelectionsMeetEvents