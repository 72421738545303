import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import MyMeetsNavLinks from '../MyMeetsNavLinks';
import MeetNavLinks from '../../meet/NavLinks';

import validate from './MeetAthleteEntryValidation';

import useOmeMeetAthleteEntryData from '../../../state/omeMeetAthleteEntry/UseOmeMeetAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useReportPeriodSeasonData from '../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import UseForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const TAXONOMIES = ['AthleteEntryDetail'];
const SCOPE = 'OnlineMeetEntry';

const INITIAL_FORM_STATE = {
  meetName: '',
  athleteEntryStatusId: Constants.DEFAULT_ID,
  reportPeriodId: Constants.DEFAULT_ID,
  reportPeriodName: '',
  sortBy: 'MeetName'
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Athlete Entry?',
  meetHost: '',
  meetName: '',
  meetDates: '',
  athleteName: '',
  athleteCompetitionCategory: '',
  athleteAgeAtMeet: '',
  athleteBirthDate: '',
  athleteEntryStatus: '',
  orgUnitName: '',
  orgUnitAthleteEntryId: Constants.DEFAULT_ID
};

const useAthleteEntry = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ tryGet: false, tryGetAfterCreate: false, tryRedirect: false, athleteEntry: {}, meetInfo: {}, teamEntry: {} });
  const [omeMeetAthleteEntryStatusState] = useState([
    { id: Constants.DEFAULT_ID, name: "--" },
    { id: 1, name: Constants.WORKFLOW_STATUS_EVENT_ENTRY_REQUIRED },
    { id: 2, name: Constants.WORKFLOW_STATUS_EVENT_APPROVAL_REQUIRED },
    { id: 3, name: Constants.WORKFLOW_STATUS_EVENTS_APPROVED },
    { id: 4, name: Constants.WORKFLOW_STATUS_RELAY_ONLY_SWIMMER },
    { id: 5, name: Constants.WORKFLOW_STATUS_SUBMITTED },
  ]);
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { formState, setFormState, errorState, setErrors, onFormValueChanged, onValueTextPairChanged, handleSubmit,
    setFormData } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();
  const { omeMeetAthleteEntryState, omeMeetAthleteEntryFiltersState, searchMeetAthleteEntry } = useOmeMeetAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryState, postOrgUnitAthleteEntry, deleteOrgUnitAthleteEntry } = useOmeMeetOrgUnitAthleteEntryData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();

  const onEnterANewMeetButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(MeetNavLinks.MEET_SEARCH);
  };

  const onCreateEntryClicked = (e, athleteEntry, meetInfo, teamEntry) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    postOrgUnitAthleteEntry(teamEntry.omeMeetOrgUnitEntryId, athleteEntry.personId);
    setState({ ...state, tryGetAfterCreate: true, athleteEntry: athleteEntry, meetInfo: meetInfo, teamEntry: teamEntry });
  };

  const onEditEntryClicked = (e, athleteEntry, meetInfo, teamEntry) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    getContextSecurity(meetInfo?.omeMeetId, TAXONOMIES, SCOPE);
    setState({ ...state, tryRedirect: true, athleteEntry: athleteEntry, meetInfo: meetInfo, teamEntry: teamEntry });
  };

  const onDeleteEntryClicked = (e, athlete, meetInfo, teamEntry) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      meetHost: meetInfo?.meetHost || '',
      meetName: meetInfo?.meetName || '',
      meetDates: `${meetInfo?.startDate ? formatDate(meetInfo?.startDate) : ''} - ${meetInfo?.endDate ? formatDate(meetInfo?.endDate) : ''}`,
      athleteName: (athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : '',
      athleteCompetitionCategory: athlete.competitionGenderTypeName || '',
      athleteAgeAtMeet: athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : '',
      athleteBirthDate: athlete.birthDate ? formatDate(athlete.birthDate) : '',
      athleteEntryStatus: athlete?.status || '',
      orgUnitName: `${teamEntry.orgUnitName || ''} ${teamEntry.orgUnitName === 'Unattached' ? teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '' : ''} ${teamEntry.parentOrgUnitCode ? '(' + teamEntry.parentOrgUnitCode + ')' : ''}`,
      orgUnitAthleteEntryId: athlete?.orgUnitAthleteEntryId || Constants.DEFAULT_ID
    });
  };

  const onDeleteAthleteEntry = (e, orgUnitAthleteEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOrgUnitAthleteEntry(orgUnitAthleteEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    searchMeetAthleteEntry({ orgUnitId: selectOrgUnitState.orgUnitId }, formState.sortBy || '');
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  };

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoaded === true && omeMeetAthleteEntryFiltersState.filterObject &&
      Object.keys(omeMeetAthleteEntryFiltersState.filterObject).length > 0) {
      setFormData({
        ...formState,
        meetName: omeMeetAthleteEntryFiltersState.filterObject?.meetName || '',
        athleteEntryStatusId: omeMeetAthleteEntryFiltersState.filterObject?.athleteEntryStatus ? (omeMeetAthleteEntryStatusState.find(x => x.name === omeMeetAthleteEntryFiltersState.filterObject?.athleteEntryStatus)?.id || Constants.DEFAULT_ID) : Constants.DEFAULT_ID,
        reportPeriodId: omeMeetAthleteEntryFiltersState.filterObject?.reportPeriodId || Constants.DEFAULT_ID,
        reportPeriodName: omeMeetAthleteEntryFiltersState.filterObject?.reportPeriodId ? reportPeriodSeasonState.arrayData.find(x => x.reportPeriodId === omeMeetAthleteEntryFiltersState.filterObject?.reportPeriodId)?.reportPeriodName || '' : '',
        sortBy: omeMeetAthleteEntryFiltersState.sortBy || 'MeetName'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState]);

  useEffect(() => {
    if ((omeMeetAthleteEntryState.isArrayLoaded === false || state.tryGet === true) && selectOrgUnitState?.orgUnitId && omeMeetOrgUnitAthleteEntryState.isSaving === false &&
      reportPeriodSeasonState.isArrayLoaded === true) {
      filter(formState);
      if (state.tryGet === true) {
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryState, omeMeetAthleteEntryState, state.tryGet, selectOrgUnitState.orgUnitId, reportPeriodSeasonState]);

  useEffect(() => {
    if (state.tryGetAfterCreate === true && state.athleteEntry && Object.keys(state.athleteEntry).length > 0 &&
      state.meetInfo && state.teamEntry && Object.keys(state.teamEntry)?.length > 0 && Object.keys(state.meetInfo).length > 0 && omeMeetOrgUnitAthleteEntryState.isSaving === false) {
      getContextSecurity(state.meetInfo?.omeMeetId, TAXONOMIES, SCOPE);
      setState({ ...state, athleteEntry: omeMeetOrgUnitAthleteEntryState.objData, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryState, state.tryGetAfterCreate, state.athleteEntry, state.meetInfo, state.teamEntry]);

  useEffect(() => {
    if (state.tryRedirect === true && state.athleteEntry && Object.keys(state.athleteEntry).length > 0 &&
      state.meetInfo && Object.keys(state.meetInfo).length > 0 && state.teamEntry && Object.keys(state.teamEntry).length > 0 &&
      omeMeetOrgUnitAthleteEntryState.isSaving === false) {
      navigate(MyMeetsNavLinks.OME_LOADING, { state: { athleteEntry: state.athleteEntry, meetInfo: state.meetInfo, teamEntry: state.teamEntry, selectOrgUnitState } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryState, state.tryRedirect, state.athleteEntry, state.meetInfo, state.teamEntry]);

  function submitFormCallback(formState) {
    filter(formState);
  };

  function filter(formState) {
    searchMeetAthleteEntry({
      orgUnitId: selectOrgUnitState.orgUnitId,
      meetName: formState.meetName.trim().length > 0 ? formState.meetName.trim() : undefined,
      reportPeriodId: formState.reportPeriodId > 0 ? formState.reportPeriodId : undefined,
      athleteEntryStatus: formState.athleteEntryStatusId > 0 ? (omeMeetAthleteEntryStatusState.find(x => x.id === formState.athleteEntryStatusId)?.name || undefined) : undefined
    }, formState.sortBy || '');
  };

  return {
    formState,
    errorState,
    modalState,
    omeMeetAthleteEntryState,
    onFormValueChanged,
    onValueTextPairChanged,
    omeMeetAthleteEntryStatusState,
    USAS_ORGANIZATION_ID,
    onCreateEntryClicked,
    onEditEntryClicked,
    onDeleteEntryClicked,
    onDeleteAthleteEntry,
    onFilterClicked,
    onClearFilterClicked,
    onModalCanceled,
    displayLoadingPopUp: reportPeriodSeasonState.isArrayLoading || omeMeetAthleteEntryState?.isArrayLoading === true ||
      contextSecurityState.isObjLoading,
    onEnterANewMeetButtonClicked
  };
};

export default useAthleteEntry;