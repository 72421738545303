import React, { Fragment } from 'react';

import useOrgUnitAdminContacts from './UseOrgUnitAdminContacts';

import OrgUnitAdminContactsGrid from './OrgUnitAdminContactsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

const OrgUnitAdminContactsReadOnly = () => {
  const {
    omeMeetOrgUnitEntryContactState,
    isLoading,
    Constants
  } = useOrgUnitAdminContacts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Entry Contacts</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <OrgUnitAdminContactsGrid
            gridData={omeMeetOrgUnitEntryContactState.arrayData}
            isLoading={omeMeetOrgUnitEntryContactState.isArrayLoading}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default OrgUnitAdminContactsReadOnly;