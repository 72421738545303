import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitNonAthleteEntryCoachesData from './GetOrgUnitNonAthleteEntryCoachesData';
import getOrgUnitNonAthleteEntryData from './GetOrgUnitNonAthleteEntryData';
import postOrgUnitNonAthleteEntryData from './PostOrgUnitNonAthleteEntryData';
import putOrgUnitNonAthleteEntryData from './PutOrgUnitNonAthleteEntryData';
import deleteOrgUnitNonAthleteEntryData from './DeleteOrgUnitNonAthleteEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitNonAthleteEntryData = {
  INITIAL_STATE, getOrgUnitNonAthleteEntryCoachesData, getOrgUnitNonAthleteEntryData,
  postOrgUnitNonAthleteEntryData, putOrgUnitNonAthleteEntryData, deleteOrgUnitNonAthleteEntryData
};

export default OmeMeetOrgUnitNonAthleteEntryData;