import React, { Fragment } from 'react';

import OrgUnitAdminLandingWrite from './OrgUnitAdminLandingWrite';
import OrgUnitAdminLandingReadOnly from './OrgUnitAdminLandingReadOnly';

import useOrgUnitAdminLanding from './UseOrgUnitAdminLanding';

const OrgUnitAdminLanding = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminLanding();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminLandingWrite /> :
        <OrgUnitAdminLandingReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminLanding;