import { Fragment } from "react";

import SelectionMeetEntriesGridLarge from "./SelectionMeetEntriesGridLarge";

const SelectionMeetEntriesGrid = ({ gridData, isLoading }) => (
  <Fragment>
    <SelectionMeetEntriesGridLarge gridData={gridData} isLoading={isLoading} />
  </Fragment>
);

export default SelectionMeetEntriesGrid;