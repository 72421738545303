import React, { Fragment } from 'react';

import useOmeEligibilityBonusEvents from './UseOmeEligibilityBonusEvents';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../../common/components/inputs/Input';

import Constants from '../../../../../common/utils/Constants';

const OmeEligibilityBonusEvents = () => {
  const {
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
  } = useOmeEligibilityBonusEvents();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_ELIGIBILITY_BONUS_EVENTS}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Min Number of Qualifying Events*'
                name='minQualifyingEvents'
                type='number'
                min='0'
                value={formState.minQualifyingEvents}
                error={errorState.minQualifyingEvents}
                message={errorState.minQualifyingEvents}
                onChange={(value) => { onFormValueChanged('minQualifyingEvents', value); }} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Max Number of Qualifying Events*'
                name='maxQualifyingEvents'
                type='number'
                min='0'
                value={formState.maxQualifyingEvents}
                error={errorState.maxQualifyingEvents}
                message={errorState.maxQualifyingEvents}
                onChange={(value) => { onFormValueChanged('maxQualifyingEvents', value); }} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Allowed Number of Bonus Events*'
                name='bonusStandardCount'
                type='number'
                min='0'
                value={formState.bonusStandardCount}
                error={errorState.bonusStandardCount}
                message={errorState.bonusStandardCount}
                onChange={(value) => { onFormValueChanged('bonusStandardCount', value); }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeEligibilityBonusEvents;