import { useEffect } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../utils/NavLinks";
import { getCompetitionGenderName } from "../utils/SelectionsUtils";

import useSelectionsData from "../../../state/selections/UseSelectionsData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";

const TAXONOMIES = [''];
const SCOPE = 'Selections';

const useSelectionsExistingSelections = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getContextSecurity } = useSecurityData();
  const { existingSelectionsState, setExistingSelectionsState, getExistingSelections, getSelectionConfiguration,
    deleteSelection
  } = useSelectionsData();

  const onEditSelectionClicked = (selectionMeetId) => {
    if (selectionMeetId > 0) {
      getContextSecurity(selectionMeetId, TAXONOMIES, SCOPE);

      const getSelectionPromise = getSelectionConfiguration(selectionMeetId);

      if (getSelectionPromise !== null) {
        getSelectionPromise.then((newState) => {
          if (newState !== null) {
            if (newState.objData.isConfigComplete === true) {
              // skip to participant grid if config is done
              navigate(NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS);
            } else {
              navigate(NavLinks.SELECTIONS_CONFIGURATION);
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    }
  };

  const onDeleteSelectionClicked = (selectionMeetId) => {
    if (selectionMeetId > 0) {
      const deleteSelectionPromise = deleteSelection(selectionMeetId);

      if (deleteSelectionPromise !== null) {
        deleteSelectionPromise.then((newDeleteState) => {
          if (newDeleteState !== null) {
            const getExistingSelectionsPromise = getExistingSelections(existingSelectionsState.omeMeetId, existingSelectionsState.meetName);

            if (getExistingSelectionsPromise !== null) {
              getExistingSelectionsPromise.then((newGetState) => {
                if (newGetState !== null) {
                  const newArrayData = JSON.parse(JSON.stringify(newGetState.arrayData));

                  for (const selection of newArrayData) {
                    selection.competitionGenderTypeName = getCompetitionGenderName(selection.selectMales, selection.selectFemales);
                  }

                  setExistingSelectionsState({
                    ...newGetState,
                    arrayData: newArrayData
                  });
                }
              }).catch((e) => {
                console.log(e);
              });
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    }
  };

  const onBackToSearchClicked = () => {
    navigate(NavLinks.SELECTIONS_NEW_SELECTION_SEARCH);
  };

  useEffect(() => {
    if (location.state?.omeMeetId > 0) {
      const getExistingSelectionsPromise = getExistingSelections(location.state.omeMeetId, location.state.meetName);

      if (getExistingSelectionsPromise !== null) {
        getExistingSelectionsPromise.then((newState) => {
          if (newState !== null) {
            const newArrayData = JSON.parse(JSON.stringify(newState.arrayData));

            for (const selection of newArrayData) {
              selection.competitionGenderTypeName = getCompetitionGenderName(selection.selectMales, selection.selectFemales);
            }

            setExistingSelectionsState({
              ...newState,
              arrayData: newArrayData
            });
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return {
    isLoading: existingSelectionsState.isArrayLoading || existingSelectionsState.isObjLoading,
    gridData: existingSelectionsState.arrayData || [],
    meetName: existingSelectionsState.meetName || '',
    onEditSelectionClicked,
    onDeleteSelectionClicked,
    onBackToSearchClicked
  };
};

export default useSelectionsExistingSelections;