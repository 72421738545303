/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ RESTRICTION_TYPE_ROUTES, state, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th style={{ minWidth: '200px' }}>Restriction Type</th>
        <th>Restriction</th>
        <th style={{ minWidth: '100px' }}></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (<Fragment>
          {state.isObjLoading === true ?
            <tr>
              <td colSpan="3">Loading...</td>
            </tr> :
            <tr>
              <td colSpan="3">No Restrictions</td>
            </tr>
          }
        </Fragment>
        ) : (
          (state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole?.length > 0 ||
            state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType?.length > 0 ||
            state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityAge?.length > 0) ?
            <Fragment>
              {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.length > 0 &&
                <tr key={RESTRICTION_TYPE_ROUTES.Membership}>
                  <td>Membership</td>
                  <td>{state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.map((membershipRestriction, i) =>
                    <span key={i + RESTRICTION_TYPE_ROUTES.Membership + 'large'}>{membershipRestriction.orgRole?.orgRoleName || ''}{state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.length - 1 === i ? '' : ', '}</span>)
                  }</td>
                  <td>
                    {onEdit &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, RESTRICTION_TYPE_ROUTES.Membership)}>
                        <EditIcon />
                      </button>}
                    {onDelete &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, RESTRICTION_TYPE_ROUTES.Membership, 'Membership', state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole)}>
                        <DeleteIcon />
                      </button>}
                  </td>
                </tr>
              }
              {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType.map((genderRestriction, i) =>
                <tr key={genderRestriction.omeMeetEligibilityCompetitionGenderTypeId + RESTRICTION_TYPE_ROUTES.CompetitionCategory}>
                  <td>Competition Category</td>
                  <td>{genderRestriction.competitionGenderType?.typeName || ''}</td>
                  <td>
                    {onEdit &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, RESTRICTION_TYPE_ROUTES.CompetitionCategory)}>
                        <EditIcon />
                      </button>}
                    {onDelete &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, RESTRICTION_TYPE_ROUTES.CompetitionCategory, 'Competition Category', genderRestriction.competitionGenderType?.typeName)}>
                        <DeleteIcon />
                      </button>}
                  </td>
                </tr>
              )}
              {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityAge.map((ageRestriction, i) =>
                <tr key={ageRestriction.omeMeetEligibilityAgeId + RESTRICTION_TYPE_ROUTES.Age}>
                  <td>Age</td>
                  <td>{ageRestriction.minAge >= 0 ? ageRestriction.minAge : ''} - {ageRestriction.maxAge >= 0 ? ageRestriction.maxAge : ''}</td>
                  <td>
                    {onEdit &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, RESTRICTION_TYPE_ROUTES.Age)}>
                        <EditIcon />
                      </button>}
                    {onDelete &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, RESTRICTION_TYPE_ROUTES.Age, 'Age', ageRestriction)}>
                        <DeleteIcon />
                      </button>}
                  </td>
                </tr>
              )}
            </Fragment>
            : (
              <tr>
                <td colSpan="3">No Restrictions</td>
              </tr>
            )
        )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ RESTRICTION_TYPE_ROUTES, state, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Restrictions</div>
            }
          </div>
        </div>
      )
      : (state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole?.length > 0 ||
        state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType?.length > 0 ||
        state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityAge?.length > 0) ?
        (
          <Fragment key={'smallGrid'}>
            {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.length > 0 &&
              <div className={global.SmallTableRow} key={RESTRICTION_TYPE_ROUTES.Membership}>
                <div className={global.SmallTableRowHead}>
                  <span className='hidden-xs'>Membership</span>&nbsp;
                  {onDelete &&
                    <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, RESTRICTION_TYPE_ROUTES.Membership, 'Membership', state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole)}>
                      <DeleteIcon />
                    </button>}
                  {onEdit &&
                    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, RESTRICTION_TYPE_ROUTES.Membership)}>
                      <EditIcon />
                    </button>}
                </div>
                <div className={global.SmallTableRowInfo}>
                  <div className='row'>
                    <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Restriction Type</div>
                    <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>Membership</div>
                    <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Restriction</div>
                    <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                      {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.map((membershipRestriction, i) =>
                        <span key={i + RESTRICTION_TYPE_ROUTES.Membership + 'small'}>{membershipRestriction.orgRole?.orgRoleName || ''}{state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.length - 1 === i ? '' : ', '}</span>)}</div>
                  </div>
                </div>
              </div>
            }
            {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType.map((genderRestriction, i) =>
              <div className={global.SmallTableRow} key={genderRestriction.omeMeetEligibilityCompetitionGenderTypeId + RESTRICTION_TYPE_ROUTES.CompetitionCategory}>
                <div className={global.SmallTableRowHead}>
                  <span className='hidden-xs'>Competition Category</span>&nbsp;
                  {onDelete &&
                    <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, RESTRICTION_TYPE_ROUTES.CompetitionCategory, 'Competition Category', genderRestriction.competitionGenderType?.typeName)}>
                      <DeleteIcon />
                    </button>}
                  {onEdit &&
                    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, RESTRICTION_TYPE_ROUTES.CompetitionCategory)}>
                      <EditIcon />
                    </button>}
                </div>
                <div className={global.SmallTableRowInfo}>
                  <div className='row'>
                    <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Restriction Type</div>
                    <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>Competition Category</div>
                    <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Restriction</div>
                    <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{genderRestriction.competitionGenderType?.typeName || ''}</div>
                  </div>
                </div>
              </div>
            )}
            {state.objData?.omeMeetEligibility[0]?.omeMeetEligibilityAge.map((ageRestriction, i) =>
              <div className={global.SmallTableRow} key={ageRestriction.omeMeetEligibilityAgeId + RESTRICTION_TYPE_ROUTES.Age}>
                <div className={global.SmallTableRowHead}>
                  <span className='hidden-xs'>Age</span>&nbsp;
                  {onDelete &&
                    <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, RESTRICTION_TYPE_ROUTES.Age, 'Age', ageRestriction)}>
                      <DeleteIcon />
                    </button>}
                  {onEdit &&
                    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, RESTRICTION_TYPE_ROUTES.Age)}>
                      <EditIcon />
                    </button>}
                </div>
                <div className={global.SmallTableRowInfo}>
                  <div className='row'>
                    <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Restriction Type</div>
                    <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>Age</div>
                    <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Restriction</div>
                    <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{ageRestriction.minAge >= 0 ? ageRestriction.minAge : ''} - {ageRestriction.maxAge >= 0 ? ageRestriction.maxAge : ''}</div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Restrictions</div>
            </div>
          </div>
        )}
  </div >
);

const OmeRestrictionsGrid = ({ RESTRICTION_TYPE_ROUTES, omeMeetState, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid RESTRICTION_TYPE_ROUTES={RESTRICTION_TYPE_ROUTES} state={omeMeetState} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid RESTRICTION_TYPE_ROUTES={RESTRICTION_TYPE_ROUTES} state={omeMeetState} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default OmeRestrictionsGrid;