import { useEffect } from 'react';
import NavLinks from '../MyMeetsNavLinks';

import useOmeMeetHostData from '../../../state/omeMeetHost/UseOmeMeetHostData';
import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useOmeMeetAthleteEntryData from '../../../state/omeMeetAthleteEntry/UseOmeMeetAthleteEntryData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useOrgUnitSelection = () => {
    const { MY_MEETS_ROLE_GROUP_ID } = useEnvironmentVariableData();
    const { resetOmeMeetHostFiltersState, clearOmeMeetHostArrayData } = useOmeMeetHostData();
    const { resetOmeMeetTeamEntryFiltersState, clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
    const { resetOmeMeetAthleteEntryFiltersState, clearOmeMeetAthleteEntryArrayData } = useOmeMeetAthleteEntryData();

    useEffect(() => {
        //clear out any filters used in my meets views
        resetOmeMeetHostFiltersState();
        resetOmeMeetTeamEntryFiltersState();
        resetOmeMeetAthleteEntryFiltersState();

        //clear out any prior search results
        clearOmeMeetHostArrayData();
        clearOmeMeetTeamEntryArrayData();
        clearOmeMeetAthleteEntryArrayData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        NavLinks,
        MY_MEETS_ROLE_GROUP_ID
    };
};

export default useOrgUnitSelection;