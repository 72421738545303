import { useContext } from 'react';
import Constants from '../../../common/utils/Constants';

import { OmeMeetOrgUnitAthleteEntryIndividualRosterEntryStateContext } from './OmeMeetOrgUnitAthleteEntryIndividualRosterEntryContextProvider';

import OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from './OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';

const useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData = () => {
  const [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState] =
    useContext(OmeMeetOrgUnitAthleteEntryIndividualRosterEntryStateContext);

  const getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId = (omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData.getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryIdData(omeMeetOrgUnitEntryId,
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState);

  const getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId = (orgUnitAthleteEntryId, omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitAthleteEntryIndividualRosterEntryData.getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryIdData(orgUnitAthleteEntryId, omeMeetOrgUnitEntryId,
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState);

  const clearOmeMeetOrgUnitAthleteEntryIndividualRosterEntryObjData = () => {
    setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState({
      ...omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: '',
      omeMeetOrgUnitEntryId: Constants.DEFAULT_ID
    });
  }

  const clearOmeMeetOrgUnitAthleteEntryIndividualRosterEntryArrayData = () => {
    setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState({
      ...omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  }

  const resetOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData = () => {
    setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState({
      ...omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      arrayData: [],
      message: '',
      omeMeetOrgUnitEntryId: Constants.DEFAULT_ID
    });
  }

  return {
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    setOmeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId,
    getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId,
    clearOmeMeetOrgUnitAthleteEntryIndividualRosterEntryObjData,
    clearOmeMeetOrgUnitAthleteEntryIndividualRosterEntryArrayData,
    resetOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData
  };
};

export default useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData;