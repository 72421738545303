
import UseForm from "../../../../../common/utils/UseForm";
import validate from './ExportFormValidation';

const INITIAL_FORM_STATE = {
  includeAllIndEventEntries: true,
  relayAthsIncludeAllIndEventEntries: true,
  timeStandardId: -1,
  timeStandardName: '',
  includeRelayEntriesForATeamThatHasIndEntrySelections: false,
  numberIndividualEventSwimmersSelected: 0
};

const useExportForm = (onSubmitFormCallback, selectionsStateObj) => {
  const { formState, errorState, isDirty, setIsDirty, handleSubmit, updateFormState, setFormState, onValueTextPairChanged
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);


  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onValueTextPairChanged
  };
};

export default useExportForm;

