const CRLF = "\r\n";

function createNode(xmlDoc, parentNodeName, nodeName, attributes) {
  let parentNodes = xmlDoc.getElementsByTagName(parentNodeName);
  let node = xmlDoc.createElement(nodeName);

  attributes.forEach(attribute => {
    const docAttribute = xmlDoc.createAttribute(attribute.name);
    docAttribute.nodeValue = attribute.value;

    node.setAttributeNode(docAttribute);
  });

  parentNodes[parentNodes.length - 1].appendChild(node);
}

function createRelayNode(xmlDoc, ageMax, ageMin, ageTotalMax, ageTotalMin, gender, name, number) {
  let attributeArray = [
    { name: "agemax", value: ageMax },
    { name: "agemin", value: ageMin },
    { name: "agetotalmax", value: ageTotalMax },
    { name: "agetotalmin", value: ageTotalMin },
    { name: "gender", value: gender },
    { name: "name", value: name },
    { name: "number", value: number }
  ];

  createNode(xmlDoc, "RELAYS", "RELAY", attributeArray);
}

function createEntryNode(xmlDoc, entryTime, eventId) {
  let attributeArray = [{ name: "entrytime", value: entryTime }, { name: "eventid", value: eventId }];

  createNode(xmlDoc, "ENTRIES", "ENTRY", attributeArray);
}

function createAthleteNode(xmlDoc, athleteId, firstName, lastName, birthDate, gender) {
  let attributeArray = [
    { name: "athleteid", value: athleteId },
    { name: "firstname", value: firstName },
    { name: "lastname", value: lastName },
    { name: "birthdate", value: birthDate },
    { name: "gender", value: gender }
  ];

  createNode(xmlDoc, "ATHLETES", "ATHLETE", attributeArray);
}

function createClubNode(xmlDoc, clubCode, clubName) {
  let attributeArray = [{ name: "clubcode", value: clubCode }, { name: "clubname", value: clubName }];

  createNode(xmlDoc, "CLUBS", "CLUB", attributeArray);
}

function createClubsNode(xmlDoc, clubs) {
  createNode(xmlDoc, "MEET", "CLUBS", []);

  clubs?.forEach(club => {
    if (club.swimmers !== null) {
      createClubNode(xmlDoc, club.orgUnitCode, club.orgUnitName);
      createNode(xmlDoc, "CLUB", "ATHLETES", []);

      club.swimmers?.forEach(swimmer => {
        createAthleteNode(xmlDoc, swimmer.memberId, swimmer.firstName, swimmer.lastName, swimmer.birthDate, swimmer.competitionGenderTypeCode);
        createNode(xmlDoc, "ATHLETE", "ENTRIES", []);

        swimmer.events?.forEach(event => {
          createEntryNode(xmlDoc, event.entryTime, event.meetEventId);
        });
      });

      if (club.relays !== null) {
        createNode(xmlDoc, "CLUB", "RELAYS", []);

        let relayNum = 1;
        club.relays.forEach(relay => {
          const ageMax = relay.ageGroupCode.substring(0, 3).replace(/^0+/, '');
          const ageMin = relay.ageGroupCode.substring(3, 6).replace(/^0+/, '');
          createRelayNode(xmlDoc, ageMax, ageMin, "-1", "-1", relay.eventCompetitionGenderTypeCode, relay.relayTeamName, relayNum);
          relayNum++;

          createNode(xmlDoc, "RELAY", "ENTRIES", []);
          createNode(xmlDoc, "ENTRIES", "ENTRY", [{ name: "entrytime", value: relay.entryTime }, { name: "eventid", value: relay.meetEventId }]);
          createNode(xmlDoc, "ENTRY", "RELAYPOSITIONS", []);

          relay.legs?.forEach(leg => {
            createNode(xmlDoc, "RELAYPOSITIONS", "RELAYPOSITION", [
              { name: "athleteid", value: leg.memberId },{ name: "number", value: leg.legNumber }]);
          });
        });
      };
    };
  });
}

function createAgeGroupNode(xmlDoc, meetAgeGroupId, maxAge, minAge, ageGroupName) {
  let attributeArray = [
    { name: "agegroupid", value: meetAgeGroupId },
    { name: "agemax", value: maxAge },
    { name: "agemin", value: minAge },
    { name: "name", value: ageGroupName }];

  createNode(xmlDoc, "AGEGROUPS", "AGEGROUP", attributeArray);
}

function createSwimStyleNode(xmlDoc, distance, isRelay, stroke) {
  let attributeArray = [
    { name: "distance", value: distance },
    { name: "relaycount", value: isRelay === 1 ? 4 : 1 },
    { name: "stroke", value: stroke }
  ];

  createNode(xmlDoc, "EVENT", "SWIMSTYLE", attributeArray);
}

function createEventNode(xmlDoc, eventNumber, eventId, gender, order, round) {
  let attributeArray = [
    { name: "number", value: eventNumber },
    { name: "eventid", value: eventId },
    { name: "gender", value: gender },
    { name: "order", value: order },
    { name: "round", value: round }];

  createNode(xmlDoc, "EVENTS", "EVENT", attributeArray);
}

function createEventsNode(xmlDoc, events) {
  createNode(xmlDoc, "SESSION", "EVENTS", []);

  let eventNum = 0;
  events?.forEach(event => {
    createEventNode(xmlDoc, eventNum++, event.meetEventId, event.gender, eventNum++, "TIM");
    createSwimStyleNode(xmlDoc, event.distance, event.isRelay, event.stroke);
    createNode(xmlDoc, "EVENT", "AGEGROUPS", []);
    createAgeGroupNode(xmlDoc, event.meetAgeGroupId, event.maxAge, event.minAge, event.ageGroupName);
  });
}

function createSessionNode(xmlDoc, sessionDate, sessionNumber, sessionData) {
  let attributeArray = [{ name: "date", value: sessionDate }, { name: "number", value: sessionNumber }];

  createNode(xmlDoc, "SESSIONS", "SESSION", attributeArray);
}

function createSessionsNode(xmlDoc, sessions) {
  createNode(xmlDoc, "MEET", "SESSIONS", []);

  sessions?.forEach(session => {
    if (session.events !== null) {
      createSessionNode(xmlDoc, session.sessionDate, session.number);
      createEventsNode(xmlDoc, session.events);
    }
  });
}

function createMeetNode(xmlDoc, exportData) {
  let attributeArray = [
    { name: "city", value: exportData.meetCity },
    { name: "name", value: exportData.meetName },
    { name: "nation", value: exportData.meetCountry }];

  createNode(xmlDoc, "MEETS", "MEET", attributeArray);

  createSessionsNode(xmlDoc, exportData.sessions);
  createClubsNode(xmlDoc, exportData.teams);
}

function createMeetsNode(xmlDoc) {
  createNode(xmlDoc, "LENEX", "MEETS", []);
}

function createContactNode(xmlDoc, meetHostEmail) {
  let attributeArray = [{ name: "email", value: meetHostEmail }];

  createNode(xmlDoc, "CONSTRUCTOR", "CONTACT", attributeArray);
}

function createConstructorNode(xmlDoc) {
  ///TODO: get from constants
  const software = "SWIMS3.0";
  const softwareVersion = "1.0";
  let attributeArray = [{ name: "name", value: software }, { name: "version", value: softwareVersion }];

  createNode(xmlDoc, "LENEX", "CONSTRUCTOR", attributeArray);
}

function indent(level) {
  const indent = "";
  return indent.padEnd(level * 2);
}

const createOmegaOmeExportDoc = (exportData) => {
  const parser = new DOMParser();
  let xml = "<?xml version=\"1.0\" standalone=\"yes\" ?>";

  const version = "3.0";
  xml += `${CRLF}<LENEX version="${version}"></LENEX>`;

  let xmlDoc = parser.parseFromString(xml, "application/xml");

  createConstructorNode(xmlDoc);

  const meetHostEmail = "test@email.net"; //exportData.meetHostEmail;
  createContactNode(xmlDoc, meetHostEmail);
  createMeetsNode(xmlDoc);
  createMeetNode(xmlDoc, exportData);

  const s = new XMLSerializer();
  const str = s.serializeToString(xmlDoc);

  //XML serializer doesn't appear to have support for CRLF and indentation
  return str.replaceAll(">", ">" + CRLF).replace("<CONSTRUCTOR", `${indent(1)}<CONSTRUCTOR`).replace("</CONSTRUCTOR", "  </CONSTRUCTOR")
    .replace("<CONTACT", `${indent(2)}<CONTACT`).replace("<MEETS>", `${indent(1)}<MEETS>`).replace("<MEET ", `${indent(2)}<MEET `)
    .replace("<SESSIONS>", `${indent(3)}<SESSIONS>`).replaceAll("<SESSION ", `${indent(4)}<SESSION `).replace("<EVENTS>", `${indent(5)}<EVENTS>`)
    .replace("</EVENTS>", `${indent(5)}</EVENTS>`)
    .replaceAll("<EVENT ", `${indent(6)}<EVENT `).replaceAll("<SWIMSTYLE ", `${indent(7)}<SWIMSTYLE `).replaceAll("</EVENT>", `${indent(6)}</EVENT>`)
    .replaceAll("</SESSION>", `${indent(4)}</SESSION>`).replace("</SESSIONS>", `${indent(3)}</SESSIONS>`).replace("</MEET>", `${indent(2)}</MEET>`)
    .replace("</MEETS>", `${indent(1)}</MEETS>`).replaceAll("<AGEGROUPS>", `${indent(7)}<AGEGROUPS>`).replaceAll("<AGEGROUP ", `${indent(8)}<AGEGROUP `)
    .replaceAll("</AGEGROUPS>", `${indent(7)}</AGEGROUPS>`).replace("<CLUBS>", `${indent(3)}<CLUBS>`).replace("</CLUBS>", `${indent(3)}</CLUBS>`)
    .replaceAll("<CLUB ", `${indent(4)}<CLUB `).replaceAll("</CLUB>", `${indent(4)}</CLUB>`).replaceAll("<ATHLETES>", `${indent(5)}<ATHLETES>`)
    .replaceAll("</ATHLETES", `${indent(5)}</ATHLETES`).replaceAll("<ATHLETES/>", `${indent(5)}<ATHLETES/>`).replaceAll("<ATHLETE ", `${indent(6)}<ATHLETE `)
    .replaceAll("</ATHLETE>", `${indent(6)}</ATHLETE>`).replaceAll("<CLUB>", "</CLUB>").replaceAll("<ENTRIES>", `${indent(7)}<ENTRIES>`)
    .replaceAll("</ENTRIES>", `${indent(7)}</ENTRIES>`).replaceAll("<ENTRY ", `${indent(8)}<ENTRY `).replaceAll("</ENTRY>", `${indent(8)}</ENTRY>`)
    .replaceAll("<RELAYS>", `${indent(5)}<RELAYS>`)
    .replaceAll("</RELAYS>", `${indent(5)}</RELAYS>`).replaceAll("<RELAY ", `${indent(6)}<RELAY `).replaceAll("</RELAY>", `${indent(6)}</RELAY>`)
    .replaceAll("<RELAYPOSITIONS>", `${indent(9)}<RELAYPOSITIONS>`).replaceAll("</RELAYPOSITIONS>", `${indent(9)}</RELAYPOSITIONS>`)
    .replaceAll("<RELAYPOSITION ", `${indent(10)}<RELAYPOSITION `);
}

const OmegaExport = {
  createOmegaOmeExportDoc
};

export default OmegaExport;