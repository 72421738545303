import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Age Group Name</th>
        <th>Min Age</th>
        <th>Max Age</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            {state.isObjLoading === true ?
              <td colSpan="4">Loading...</td>
              : <td colSpan="4">No Age Groups</td>
            }
          </tr>
        ) : Array.isArray(gridState.sortedGridData) && gridState.sortedGridData.length > 0
          ? gridState.sortedGridData.map(ageGroup => (
            <tr key={ageGroup.meetAgeGroupId}>
              <td>{ageGroup.ageGroupName}</td>
              <td>{ageGroup.minAge}</td>
              <td>{ageGroup.maxAge}</td>
              <td>
                {onEdit &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, ageGroup.meetAgeGroupId)}>
                    <EditIcon />
                  </button>}
                {onDelete &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, ageGroup)}>
                    <DeleteIcon />
                  </button>}
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="4">No Age Groups</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state, gridState, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Age Groups</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState.sortedGridData) && gridState.sortedGridData.length > 0
        ? gridState.sortedGridData.map(ageGroup => (
          <div className={global.SmallTableRow} key={ageGroup.meetAgeGroupId}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{ageGroup.ageGroupName}</span>&nbsp;
              {onDelete &&
                <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, ageGroup)}>
                  <DeleteIcon />
                </button>}
              {onEdit &&
                <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, ageGroup.meetAgeGroupId)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Group Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{ageGroup.ageGroupName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Min Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.minAge}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Max Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.maxAge}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Age Groups</div>
            </div>
          </div>
        )
    }
  </div >
);

const OmeAgeGroupsGrid = ({ state, gridState, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={state} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={state} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
)

export default OmeAgeGroupsGrid;