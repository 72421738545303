import { useEffect } from "react";

import validate from './NewSelectionSearchFormValidation';

import useSelectionsOrgLevelData from "../../../../state/selectionsOrgLevel/UseSelectionsOrgLevelData";

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  orgUnitId: '',
  orgUnitName: '',
  startDate: Constants.BLANK_DATE_STRING,
  endDate: Constants.BLANK_DATE_STRING
};

const useNewSelectionSearchForm = (onSubmitFormCallback, filterObject) => {
  const { selectionsOrgLevelState } = useSelectionsOrgLevelData();
  const { formState, errorState, handleSubmit, updateFormState, onValueTextPairChanged, setFormState, setErrors
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFormClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (Array.isArray(selectionsOrgLevelState.options)) {
      const firstOrgLevel = selectionsOrgLevelState.options[0];

      setFormState({
        ...INITIAL_FORM_STATE,
        orgUnitId: firstOrgLevel?.id || '',
        orgUnitName: firstOrgLevel?.name || ''
      });
    } else {
      setFormState({ ...INITIAL_FORM_STATE });
    }

    setErrors({});
  };

  useEffect(() => {
    if (selectionsOrgLevelState.areOptionsLoaded === true && Array.isArray(selectionsOrgLevelState.options)) {
      const previousOrgLevel = selectionsOrgLevelState.options.find((x) => x.id === filterObject.orgUnitId);
      const firstOrgLevel = selectionsOrgLevelState.options[0];

      let newFormState = {
        orgUnitId: previousOrgLevel?.id || firstOrgLevel?.id || '',
        orgUnitName: previousOrgLevel?.name || firstOrgLevel?.name || '',
        startDate: filterObject.startDate || Constants.BLANK_DATE_STRING,
        endDate: filterObject.endDate || Constants.BLANK_DATE_STRING
      };

      const validatePromise = validate(newFormState);

      validatePromise.then((errors) => {
        // if no errors and filterObject had values, do search
        if (Object.keys(errors).length === 0 && Object.keys(filterObject).length > 0) {
          onSubmitFormCallback(newFormState);
        }

        setFormState({
          ...formState,
          ...newFormState
        });
      }).catch((e) => {
        console.log(e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsOrgLevelState.areOptionsLoaded]);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onClearFormClicked
  };
};

export default useNewSelectionSearchForm;