import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getOrgUnitData from './GetOrgUnitData';
import getOrgUnitByOrganizationIdData from './GetOrgUnitByOrganizationIdData';
import getOrgUnitTopTwoLevelsData from './GetOrgUnitTopTwoLevelsData';
import getOrgUnitByOrgUnitIdData from './GetOrgUnitByOrgUnitIdData';
import getBaseOrgUnitByOrgUnitIdData from './GetBaseOrgUnitByOrgUnitIdData';

import Constants from '../../utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  organizationId: Constants.DEFAULT_ID,
  treeData: []
};

const OrgUnitData = {
  INITIAL_STATE, getOrgUnitData, getOrgUnitByOrganizationIdData,
  getOrgUnitTopTwoLevelsData, getOrgUnitByOrgUnitIdData, getBaseOrgUnitByOrgUnitIdData
};

export default OrgUnitData;