import { useEffect } from 'react';

import useFacilityCombobox from './UseFacilityCombobox';

import Combobox from '../Combobox';
import ReadOnly from '../../readOnly/ReadOnly';

import global from '../../GlobalStyle.module.css';

const FacilityCombobox = ({ label, name, valueToMatch, error, message, onChange, orgUnitId, onFacilityComboboxStateChange }) => {
  const { facilityComboboxState } = useFacilityCombobox(orgUnitId);

  useEffect(() => {
    if (typeof onFacilityComboboxStateChange === 'function') {
      onFacilityComboboxStateChange(facilityComboboxState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityComboboxState.isLoaded]);

  return facilityComboboxState.message
    ? <span className={global.LoadingMsg}>{facilityComboboxState.message}</span>
    : (facilityComboboxState.data.length > 0 ?
      <Combobox
        label={label}
        name={name}
        isLoading={facilityComboboxState.isLoading}
        items={facilityComboboxState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
      :
      <ReadOnly
        label={label}
        name={name}
        value="No Associated Facilities" />
    );
};

export default FacilityCombobox;