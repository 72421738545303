import { useEffect } from 'react';

import useRecordListData from '../../../state/recordList/UseRecordListData';

const useLaneSheetRecordListCombobox = (isTopInfoLaneSheet, isRightInfoLaneSheet) => {
  const { recordListState, getRecordList } = useRecordListData();

  useEffect(() => {
    if (isTopInfoLaneSheet === true || isRightInfoLaneSheet === true)
      getRecordList(isTopInfoLaneSheet, isRightInfoLaneSheet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopInfoLaneSheet, isRightInfoLaneSheet]);

  return {
    recordListState
  };
};

export default useLaneSheetRecordListCombobox;