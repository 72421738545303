import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './MeetTimesFilterValidation';

import useMeetCompetitionSummaryData from '../../../../state/meetCompetitionSummary/UseMeeetCompetitionSummaryData';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';
import formatCrossUiRoute from '../../../../../common/utils/FormatCrossUiRoute';

import NavLinks from '../../NavLinks';

const EVENT_COURSE_KEY = 'eventCourse';
const COMPETITOR_KEY = 'competitor';
const EVENT_GENDER_KEY = 'eventGender';
const START_AGE_KEY = 'startAge';
const END_AGE_KEY = 'endAge';
const AGES_KEY = 'ages';
const SESSION_KEY = 'session';

const TAXONOMIES = ['MeetDetail', 'OUMeetDetail', 'AthleteMeetDetail'];
const SCOPE = 'Meet';

const COMPETITION_GENDER_TYPES = [
  { id: Constants.DEFAULT_ID, name: "All Genders" },
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Mixed" }
];

const SESSION_TYPES = [
  { id: Constants.DEFAULT_ID, name: "All Sessions" },
  { id: 1, name: "Prelim" },
  { id: 2, name: "SwimOff" },
  { id: 3, name: "Final" },
  { id: 4, name: "SemiFinal" },
  { id: 5, name: "QuarterFinal" },
  { id: 6, name: "TimedFinal" }
];

const INITIAL_DELETE_POPUP_STATE = {
  displayModal: false
};

const useMeetTimes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [competitionGenderTypes,] = useState([...COMPETITION_GENDER_TYPES]);
  const [sessionTypes,] = useState([...SESSION_TYPES]);
  const [deletePopupState, setDeletePopupState] = useState(INITIAL_DELETE_POPUP_STATE);
  const { meetState, getMeet } = useMeetData();
  const { meetCompetitionSummaryState, getMeetCompetitionSummary } = useMeetCompetitionSummaryData();
  const [gridState, setGridState] = useState(getInitialGridState);
  const { getContextSecurity } = useSecurityData();
  const { deleteMeetTimesState, deleteMeetTimes, getMeetTimes } = useMeetTimesData();
  const { errorState, formState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setErrors
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onAddAthletesClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES_SELECT_ORGS);
  };

  const onEditTimeClicked = (swimTimeId) => {
    const relativePath = `/times/byid/${swimTimeId}/meet`;
    const formattedUrlObject = formatCrossUiRoute(Constants.UI_PROJECT_NAMES.TIMES, relativePath, true);
    window.location.href = formattedUrlObject.route;
  };

  const onDeleteAllTimesClicked = () => {
    setDeletePopupState({
      ...deletePopupState,
      displayModal: true
    });
  };

  const onDeleteModalConfirmClicked = () => {
    const meetId = meetState.objData.meetId;

    if (meetId > 0) {
      const deleteMeetTimesPromise = deleteMeetTimes(meetId);

      if (deleteMeetTimesPromise ?? false) {
        deleteMeetTimesPromise.then((newState) => {
          if (newState ?? false) {
            getMeetTimes(meetState.objData.meetId, gridState, setGridState);
          }
        }).catch(() => {
        });
      }
    }

    setDeletePopupState(INITIAL_DELETE_POPUP_STATE);
  };

  const onDeleteModalCancelClicked = () => {
    setDeletePopupState(INITIAL_DELETE_POPUP_STATE);
  };

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      getMeetTimes(meetState.objData.meetId, gridState, setGridState);

      if (meetCompetitionSummaryState.isObjLoaded !== true) {
        getMeetCompetitionSummary(meetState.objData.meetId);
      }
    }
    else if (location.state && location.state.meetId) {
      if (meetState.isObjLoading === false) {
        const meetId = location.state.meetId;
        getMeet(meetId);
        getContextSecurity(meetId, TAXONOMIES, SCOPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded]);

  useEffect(() => {
    if (gridState.isArrayLoaded === true) {
      setGridState({
        ...gridState,
        displayArray: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.isArrayLoaded]);

  function submitFormCallback(formState) {
    if (gridState.isArrayLoaded === true) {
      const initialFilterObject = {
        eventCourse: formState.eventCourseId !== Constants.DEFAULT_ID ? formState.eventCourseName : undefined,
        competitor: formState.competitorId !== Constants.DEFAULT_ID ? formState.competitorName : undefined,
        eventGender: formState.eventGenderId !== Constants.DEFAULT_ID ? formState.eventGenderName : undefined,
        session: formState.sessionId !== Constants.DEFAULT_ID ? formState.sessionName : undefined
      };

      if (formState.endAge.trim() && formState.startAge.trim()) {
        initialFilterObject.ages = { startAge: parseInt(formState.startAge), endAge: parseInt(formState.endAge) }
      } else {
        initialFilterObject.startAge = formState.startAge.trim() !== '' ? parseInt(formState.startAge) : undefined;
        initialFilterObject.endAge = formState.endAge.trim() !== '' ? parseInt(formState.endAge) : undefined
      }

      const filterObject = JSON.parse(JSON.stringify(initialFilterObject));

      if (Object.keys(filterObject).length > 0) {
        let filteredTimeData = gridState.arrayData;
        for (const [key, value] of Object.entries(filterObject)) {
          filteredTimeData = filterTimeArrayByKeyValue(key, value, filteredTimeData);
        }
        setGridState({
          ...gridState,
          displayArray: filteredTimeData
        });
      }
      else {
        setGridState({
          ...gridState,
          displayArray: []
        });

        setErrors({ filter: 'Must filter by an Event Course or Competitor to view times results' });
      }
    } else {
      setErrors({ filter: 'No times data loaded' });
    }
  }

  function filterTimeArrayByKeyValue(key, value, timeArray) {
    const targetArray = JSON.parse(JSON.stringify(timeArray));

    if (key === EVENT_COURSE_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].eventCourse !== value) {
          targetArray[i] = null;
        }
      }
    } else if (key === COMPETITOR_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].competitor !== value) {
          if (Array.isArray(timeArray[i].relayTeam)) {
            let matchFound = false;
            for (let j = 0; j < timeArray[i].relayTeam.length; j++) {
              const member = timeArray[i].relayTeam[j];
              if (`${member.firstName} ${member.lastName}` === value) {
                matchFound = true;
                break;
              }
            }

            if (matchFound === true) {
              continue; // Match found do not set this relay time to null
            }
          }

          targetArray[i] = null;
        }
      }
    } else if (key === EVENT_GENDER_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].eventGender !== value) {
          targetArray[i] = null;
        }
      }
    } else if (key === SESSION_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].session !== value) {
          targetArray[i] = null;
        }
      }
    } else if (key === AGES_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].age >= value.startAge && timeArray[i].age <= value.endAge) {
          continue;
        }
        else if (Array.isArray(timeArray[i].relayTeam)) {
          let matchFound = false;
          for (let j = 0; j < timeArray[i].relayTeam.length; j++) {
            const member = timeArray[i].relayTeam[j];

            if (member.age >= value.startAge && member.age <= value.endAge) {
              matchFound = true;
              break;
            }
          }

          if (matchFound === false) {
            targetArray[i] = null;
          }
        }
        else {
          targetArray[i] = null;
        }
      }
    } else if (key === START_AGE_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].age < value) {
          targetArray[i] = null;
        }
      }
    } else if (key === END_AGE_KEY) {
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i].age > value) {
          targetArray[i] = null;
        }
      }
    }

    return targetArray.filter(time => time !== null);
  }

  function getInitialFormState() {
    return {
      eventCourseId: Constants.DEFAULT_ID,
      eventCourseName: '',
      competitorId: Constants.DEFAULT_ID,
      competitorName: '',
      eventGenderId: Constants.DEFAULT_ID,
      eventGenderName: 'All Genders',
      sessionId: Constants.DEFAULT_ID,
      sessionName: '',
      startAge: '',
      endAge: ''
    };
  }

  function getInitialGridState() {
    return {
      arrayData: [],
      isArrayLoading: false,
      isArrayLoaded: false,
      displayArray: []
    };
  }

  return {
    meetId: meetState.objData.meetId,
    competitionGenderTypes,
    sessionTypes,
    formState,
    errorState,
    gridState,
    deletePopupState,
    deleteMeetTimesState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onEditTimeClicked,
    onAddAthletesClicked,
    onDeleteAllTimesClicked,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked
  };
};

export default useMeetTimes;