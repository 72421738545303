
import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
    const { NavLinks, MY_MEETS_ROLE_GROUP_ID } = useOrgUnitSelection();

    return (
        <Fragment>
            <SelectOrgUnit
                pageTitle={'My Meets'}
                navLink={NavLinks.LANDING}
                roleGroupId={MY_MEETS_ROLE_GROUP_ID} />
        </Fragment>
    );
};

export default OrgUnitSelection;