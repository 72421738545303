import React, { Fragment } from 'react';

import OrgUnitAdminRosterEntriesDetailLargeGrid from './OrgUnitAdminRosterEntriesDetailLargeGrid';
import OrgUnitAdminRosterEntriesDetailSmallGrid from './OrgUnitAdminRosterEntriesDetailSmallGrid';

const OrgUnitAdminRosterEntriesDetailGrid = ({ gridData, isLoading, onDeleteEventClicked }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            <p>{gridData.length === 1 ? `${gridData.length} Individual Event Entry` : `${gridData.length} Individual Event Entries`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <OrgUnitAdminRosterEntriesDetailLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onDeleteEventClicked={onDeleteEventClicked}
      />
      <OrgUnitAdminRosterEntriesDetailSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onDeleteEventClicked={onDeleteEventClicked}
      />
    </Fragment>
  )
};

export default OrgUnitAdminRosterEntriesDetailGrid;