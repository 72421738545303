import React, { Fragment } from 'react';

import OrgUnitAdminRelayEntrySelectionOverrideLargeGrid from './OrgUnitAdminRelayEntrySelectionOverrideLargeGrid';
import OrgUnitAdminRelayEntrySelectionOverrideSmallGrid from './OrgUnitAdminRelayEntrySelectionOverrideSmallGrid';

const OrgUnitAdminRelayEntrySelectionOverrideGrid = ({ gridData, isLoading, meetEventId, omeMeetOrgUnitEntryId, formState,
  errorState, onValueTextPairChanged, isLarge, onFormValueChanged }) => {

  return (
    <Fragment>
      {isLarge === true ?
        <OrgUnitAdminRelayEntrySelectionOverrideLargeGrid
          gridData={gridData}
          isLoading={isLoading}
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
        /> :
        <OrgUnitAdminRelayEntrySelectionOverrideSmallGrid
          gridData={gridData}
          isLoading={isLoading}
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
        />}
    </Fragment>
  )
};

export default OrgUnitAdminRelayEntrySelectionOverrideGrid;