import Checkbox from "../../../../common/components/checkboxes/Checkbox";
import global from '../../../../common/components/GlobalStyle.module.css';

const MeetRecognitionSmallGrid = ({ gridData, isLoading, onOrgCheckbox, onRecordsCheckbox, onTopTimesCheckbox, onMeetEntryCheckbox }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((orgItem, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className="hidden-xs">{orgItem.organizationName}</span>&nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className="row">
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Organization</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{orgItem.organizationName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Org Eligible?</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  <Checkbox
                    label=""
                    name="isEligibleForOrganization"
                    onChange={(e) => { onOrgCheckbox(e, orgItem.organizationId) }}
                    checked={orgItem.isEligibleForOrganization} />
                </div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Records Eligible?</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  {orgItem.isEligibleForOrganization === true ?
                    <Checkbox
                      label=""
                      name="isEligibleForRecord"
                      onChange={(e) => { onRecordsCheckbox(e, orgItem.organizationId) }}
                      checked={orgItem.isEligibleForRecord} />
                    : 'Not Applicable'
                  }
                </div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Top Times Eligible?</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  {orgItem.isEligibleForOrganization === true ?
                    <Checkbox
                      label=""
                      name="isEligibleForTopTimes"
                      onChange={(e) => { onTopTimesCheckbox(e, orgItem.organizationId) }}
                      checked={orgItem.isEligibleForTopTimes} />
                    : 'Not Applicable'
                  }
                </div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Entry Eligible?</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  {orgItem.isEligibleForOrganization === true ?
                    <Checkbox
                      label=""
                      name="isEligibleForMeetEntry"
                      onChange={(e) => { onMeetEntryCheckbox(e, orgItem.organizationId) }}
                      checked={orgItem.isEligibleForMeetEntry} />
                    : 'Not Applicable'
                  }
                </div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetRecognitionSmallGrid;