import React, { Fragment } from 'react';

import useMeetAdd from './UseMeetAdd';

import OrgUnitTreeView from '../../../../common/components/tree/orgUnitTree/OrgUnitTreeView';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import MultiSelect from '../../../../common/components/multiSelect/MultiSelect';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import MeetClassificationTreeView from '../../../../common/components/tree/meetClassificationTree/MeetClassificationTreeView';
import MeetSoftwareDropdown from '../../../../common/components/dropdowns/meetSoftwareDropdown/MeetSoftwareDropdown';
import FacilityCombobox from '../../../../common/components/comboboxes/facilityCombobox/FacilityCombobox';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetAdd = () => {
  const {
    isLoading,
    isSaving,
    state,
    formState,
    errorState,
    courseOptionsState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onFacilityComboboxStateChange,
    onHostOrgUnitChanged,
    Constants,
    OW_COURSE_ID
  } = useMeetAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Meet</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <OrgUnitTreeView
              label="Host Organization*"
              name="hostOrgUnit"
              value={formState.hostOrgUnit}
              error={errorState.hostOrgUnit}
              message={errorState.hostOrgUnit}
              selectableLeavesOnly={false}
              singleSelect={true}
              onChange={(newValue) => { onHostOrgUnitChanged(newValue); }}
              organizationId={undefined} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Start Date*"
              name="startDate"
              value={formState.startDate}
              error={errorState.startDate}
              message={errorState.startDate}
              onChange={(value) => {
                onFormValueChanged('startDate', value);
              }}
              countOfYears={154}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="End Date*"
              name="endDate"
              value={formState.endDate}
              error={errorState.endDate}
              message={errorState.endDate}
              onChange={(value) => { onFormValueChanged('endDate', value); }}
              countOfYears={154}
              startYearOffset={-149} />
          </div>
          <div className={formState.usasHostOrg === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="Year for Meet Name"
                name="autogeneratedMeetYear"
                value={formState.autogeneratedMeetYear}
              />
            </div>
            <div className={formState.usasLsc === true ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="LSC for Meet Name"
                  name="autogeneratedMeetLsc"
                  value={formState.autogeneratedMeetLsc}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={formState.usasHostOrg === true ? formState.usasLsc === true ? "Meet Name* (Do not include Year or LSC)" : "Meet Name* (Do not include Year)" : "Meet Name*"}
              name="meetName"
              value={formState.meetName}
              error={errorState.meetName}
              message={errorState.meetName}
              onChange={(value) => { onFormValueChanged('meetName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4" >
            <Input
              label="Meet Abbreviation"
              name="meetAbbreviation"
              value={formState.meetAbbreviation}
              error={errorState.meetAbbreviation}
              message={errorState.meetAbbreviation}
              onChange={(value) => { onFormValueChanged('meetAbbreviation', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4" >
            <MultiSelect
              label="Meet Course(s)*"
              name="meetCourse"
              value={formState.meetCourse}
              error={errorState.meetCourse}
              message={errorState.meetCourse}
              options={courseOptionsState}
              isLoading={courseOptionsState.length === 0}
              onChange={(value) => { onFormValueChanged('meetCourse', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <MeetClassificationTreeView
              label="Meet Classification*"
              name="meetClassification"
              value={formState.meetClassification}
              error={errorState.meetClassification}
              message={errorState.meetClassification}
              selectableLeavesOnly={true}
              singleSelect={true}
              onChange={(newValue) => { onFormValueChanged('meetClassification', newValue) }}
              orgUnitId={formState.hostOrgUnit[0]?.id || undefined}
              filterId={-1}
              filterMode={"exclude_filter_id"}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <MeetSoftwareDropdown
              label="Meet Software"
              name="meetSoftwareId"
              value={formState.meetSoftwareId}
              error={errorState.meetSoftwareId}
              message={errorState.meetSoftwareId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'meetSoftwareId', newValueLabel, 'meetSoftwareName', e); }} />
          </div>
          {formState.meetCourse.find(x => x.id !== OW_COURSE_ID) ?
            <div className={formState.hostOrgUnit.length > 0 ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <FacilityCombobox
                  label="Facility*"
                  name="facilityId"
                  valueToMatch={formState.facilityName}
                  error={errorState.facilityId}
                  message={errorState.facilityId}
                  onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName', e); }}
                  orgUnitId={formState.hostOrgUnit[0]?.id || ''}
                  onFacilityComboboxStateChange={onFacilityComboboxStateChange} />
              </div>
            </div> : <Fragment />
          }
          <div className={formState.facilityId > 0 ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="City"
                name="facilityCity"
                value={state.facilityCity} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="State"
                name="facilityState"
                value={state.facilityState} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="Country"
                name="facilityCountry"
                value={state.facilityCountry} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="Altitude (ft)"
                name="facilityAltitude"
                value={state.facilityAltitude !== '' ? state.facilityAltitude.toString() : 'Unknown'} />
            </div>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default MeetAdd;