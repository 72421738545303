import React, { Fragment } from 'react';

import OrgUnitAdminRosterLargeGrid from './OrgUnitAdminRosterLargeGrid';
import OrgUnitAdminRosterSmallGrid from './OrgUnitAdminRosterSmallGrid';

const OrgUnitAdminRosterGrid = ({ gridData, isLoading, onAddSecondaryRepToAthleteClicked, onDeleteAthleteClicked, allowRelayOnly, showSecondaryRep }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            <p>{gridData.length === 1 ? `${gridData.length} Athlete` : `${gridData.length} Athletes`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <OrgUnitAdminRosterLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onAddSecondaryRepToAthleteClicked={onAddSecondaryRepToAthleteClicked}
        onDeleteAthleteClicked={onDeleteAthleteClicked}
        allowRelayOnly={allowRelayOnly}
        showSecondaryRep={showSecondaryRep}
      />
      <OrgUnitAdminRosterSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onAddSecondaryRepToAthleteClicked={onAddSecondaryRepToAthleteClicked}
        onDeleteAthleteClicked={onDeleteAthleteClicked}
        allowRelayOnly={allowRelayOnly}
        showSecondaryRep={showSecondaryRep}
      />
    </Fragment>
  )
};

export default OrgUnitAdminRosterGrid;