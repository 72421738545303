import { useState } from 'react';

import AthleteRaceStatsData from './AthleteRaceStatsData';

const useAthleteRaceStatsData = () => {
  const [athleteRaceStatsState, setAthleteRaceStatsState] = useState(AthleteRaceStatsData.INITIAL_STATE);

  const getAthleteRaceStats = () => {
    AthleteRaceStatsData.getAthleteRaceStatsData(athleteRaceStatsState, setAthleteRaceStatsState);
  };

  const getAthleteRaceStatsForCoach = (personId) => {
    AthleteRaceStatsData.getAthleteRaceStatsForCoachData(personId, athleteRaceStatsState, setAthleteRaceStatsState);
  }

  const clearArrayData = () => {
    setAthleteRaceStatsState({
      ...athleteRaceStatsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    athleteRaceStatsState,
    setAthleteRaceStatsState,
    getAthleteRaceStats,
    getAthleteRaceStatsForCoach,
    clearArrayData
  };
};

export default useAthleteRaceStatsData;