import { useContext } from 'react';

import { OmeMeetOrgUnitAthleteEntryStateContext } from './OmeMeetOrgUnitAthleteEntryContextProvider';

import OmeMeetOrgUnitAthleteEntryData from './OmeMeetOrgUnitAthleteEntryData';

const useOmeMeetOrgUnitAthleteEntryData = () => {
  const [omeMeetOrgUnitAthleteEntryState, setOmeMeetOrgUnitAthleteEntryState] = useContext(OmeMeetOrgUnitAthleteEntryStateContext);

  const getOrgUnitAthleteEntryRoster = (omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitAthleteEntryData.getOrgUnitAthleteEntryRosterData(omeMeetOrgUnitEntryId, omeMeetOrgUnitAthleteEntryState, setOmeMeetOrgUnitAthleteEntryState);

  const getOrgUnitAthleteEntry = (orgUnitAthleteEntryId) =>
    OmeMeetOrgUnitAthleteEntryData.getOrgUnitAthleteEntryData(orgUnitAthleteEntryId, omeMeetOrgUnitAthleteEntryState, setOmeMeetOrgUnitAthleteEntryState);

  const postOrgUnitAthleteEntry = (omeMeetOrgUnitEntryId, personId) =>
    OmeMeetOrgUnitAthleteEntryData.postOrgUnitAthleteEntryData(omeMeetOrgUnitEntryId, personId, omeMeetOrgUnitAthleteEntryState, setOmeMeetOrgUnitAthleteEntryState);

  const putOrgUnitAthleteEntry = (orgUnitAthleteEntryId, orgUnitAthleteEntryObj) =>
    OmeMeetOrgUnitAthleteEntryData.putOrgUnitAthleteEntryData(orgUnitAthleteEntryId, orgUnitAthleteEntryObj, omeMeetOrgUnitAthleteEntryState, setOmeMeetOrgUnitAthleteEntryState);

  const deleteOrgUnitAthleteEntry = (orgUnitAthleteEntryId) =>
    OmeMeetOrgUnitAthleteEntryData.deleteOrgUnitAthleteEntryData(orgUnitAthleteEntryId, omeMeetOrgUnitAthleteEntryState, setOmeMeetOrgUnitAthleteEntryState);

  const clearOmeMeetOrgUnitAthleteEntryObjData = () => {
    setOmeMeetOrgUnitAthleteEntryState({
      ...omeMeetOrgUnitAthleteEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetOrgUnitAthleteEntryArrayData = () => {
    setOmeMeetOrgUnitAthleteEntryState({
      ...omeMeetOrgUnitAthleteEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeetOrgUnitAthleteEntry = () => {
    setOmeMeetOrgUnitAthleteEntryState({
      ...omeMeetOrgUnitAthleteEntryState,
      isSaved: false
    });
  };

  return {
    omeMeetOrgUnitAthleteEntryState,
    getOrgUnitAthleteEntryRoster,
    getOrgUnitAthleteEntry,
    postOrgUnitAthleteEntry,
    putOrgUnitAthleteEntry,
    deleteOrgUnitAthleteEntry,
    clearOmeMeetOrgUnitAthleteEntryObjData,
    clearOmeMeetOrgUnitAthleteEntryArrayData,
    confirmSaveOmeMeetOrgUnitAthleteEntry
  };
};

export default useOmeMeetOrgUnitAthleteEntryData;