import React, { Fragment } from 'react';

import useOmeRestrictionsTypeMembership from './UseOmeRestrictionsTypeMembership';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import MultiSelect from '../../../../../common/components/multiSelect/MultiSelect';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

const OmeRestrictionsTypeMembership = () => {
  const {
    state,
    omeMeetState,
    orgRoleState,
    errorState,
    formState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
  } = useOmeRestrictionsTypeMembership();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_RESTRICTIONS_TYPE_MEMBERSHIP}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12'>
              <MultiSelect
                label="Membership(s)*"
                name="membership"
                value={formState.membership}
                error={errorState.membership}
                message={errorState.membership}
                options={state.membershipOptions}
                isLoading={orgRoleState.isArrayLoading || state.membershipOptions.length === 0}
                onChange={(value) => { onFormValueChanged('membership', value) }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={orgRoleState.isArrayLoading || state.membershipOptions.length === 0} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeRestrictionsTypeMembership;