import { Fragment } from "react";

import useOrgUnitAdminRelayEntriesTeams from "./UseOrgUnitAdminRelayEntriesTeams";

import OrgUnitAdminRelayEntriesTeamsGrid from "./OrgUnitAdminRelayEntriesTeamsGrid";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../../common/components/headings/Headings";
import ActionIntraPageButton from "../../../../../common/components/buttons/ActionIntraPageButton";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../common/utils/Constants";
import ToIntIfInt from "../../../../../common/utils/ToIntIfInt";

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminRelayEntriesTeamsWrite = () => {
    const { state, gridState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, athleteMeetEventState,
        omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
        onAddRelayTeamClicked, onEditRelayTeamClicked, onDeleteRelayTeamClicked, onDeleteRelayTeam,
        onBackClicked, modalState, onModalCanceled, omeMeetState, MAX_RELAY_TEAM_ENTRIES_PER_EVENT } = useOrgUnitAdminRelayEntriesTeams();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <Headings.H3>Manage Relay Teams</Headings.H3>
                </div>
                <div className="col-xs-12">
                    <p className={global.HeaderText}><b>Relay Event:</b> {state.event?.eventName || ''} ({state.event?.eventCompetitionGenderTypeName || ''}, {state.event?.ageGroupName || ''}), Session {state.event?.meetSessionName || ''}</p>
                    {/* {(state.event?.timeCuts && state.event?.timeCuts.length > 0) &&
                        <p className={global.HeaderText}><b>Qualifying Time:</b>
                            {state.event.timeCuts.map((timeCut) => {
                                return (
                                    <Fragment key={timeCut.qualifyingEventId}>&nbsp;<span>{`(${timeCut.eventName || ''}`}{timeCut?.noFasterThanTimeCut ? ' No Faster Than ' + formatTimeForDisplay(timeCut?.noFasterThanTimeCut) : ''}{timeCut?.noFasterThanTimeCut && timeCut?.noSlowerThanTimeCut ? ' &' : ''}{timeCut?.noSlowerThanTimeCut ? ' No Slower Than ' + formatTimeForDisplay(timeCut?.noSlowerThanTimeCut) : ''}{`)`}</span></Fragment>
                                );
                            })}
                        </p>} */}
                </div>
            </div>
            {((gridState.gridData?.relayTeams && gridState.gridData?.relayTeams?.length < (omeMeetState.objData.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent ? ToIntIfInt(omeMeetState.objData.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent) : MAX_RELAY_TEAM_ENTRIES_PER_EVENT)) || !gridState.gridData?.relayTeams) &&
                <div className="row usas-extra-top-margin">
                    <div className="col-xs-12">
                        <ActionIntraPageButton onClick={onAddRelayTeamClicked}>Add Relay Team</ActionIntraPageButton>
                    </div>
                </div>}
            <div className="row">
                <div className="col-xs-12">
                    <OrgUnitAdminRelayEntriesTeamsGrid
                        gridData={gridState.gridData}
                        isLoading={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading}
                        onEditRelayTeamClicked={onEditRelayTeamClicked}
                        onDeleteRelayTeamClicked={onDeleteRelayTeamClicked}
                        state={state}
                    />
                </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
                </div>
            </div>
            <PopUpModal
                widthPct={90}
                maxWidth={720}
                title={modalState.modalTitle}
                displayPopUp={modalState.displayPopUp}
                onModalCanceled={onModalCanceled}>
                <div className="row">
                    <div className='col-xs-12'>
                        <p><strong>Team:</strong> {modalState?.relayTeamName}</p>
                        <p><strong>Entry Time:</strong> {modalState?.entryTime} {modalState?.entryTime !== 'NT' ? modalState?.courseCode : ''}</p>
                        <p><strong>Time Event:</strong> {modalState.timeEvent || ''}</p>
                        <p><strong>Aggregate?</strong> {modalState?.isAggregate}</p>
                        <p><strong>Override?</strong> {modalState?.swimTimeEntryOverride}</p>
                        <p><strong>Status:</strong> {modalState?.status}</p>
                    </div>
                    <div className="col-xs-12 usas-extra-top-margin">
                        <PrimaryButton type="button" onClick={(e) => onDeleteRelayTeam(e, modalState.relayMeetEventEntryId)}>Delete</PrimaryButton>&nbsp;
                        <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
                    </div>
                </div>
            </PopUpModal>
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading ||
                    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading} />
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isSaving || athleteMeetEventState.isSaving} />
        </Fragment>
    )
}
export default OrgUnitAdminRelayEntriesTeamsWrite;