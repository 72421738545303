import useMeetData from '../../../../common/state/meet/UseMeetData';

const useMeetSessionsDetail = () => {
  const { meetState } = useMeetData();

  return {
    meetState
  };
};

export default useMeetSessionsDetail;