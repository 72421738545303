import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MeetAddValidation';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useCourseData from '../../../../common/state/course/UseCourseData';
import useOrgUnitData from '../../../../common/state/orgUnit/UseOrgUnitData';
import useLscData from '../../../../common/state/lsc/UseLscData';
import useMeetSoftwareData from '../../../../common/state/meet/meetSoftware/UseMeetSoftwareData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';

import NavLinks from '../NavLinks';

const useMeetAdd = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(getInitialState);
  const [courseOptionsState, setCourseOptionsState] = useState([]);
  const { meetState, postMeet, confirmSave } = useMeetData();
  const { orgUnitState } = useOrgUnitData();
  const { lscState, getLscs } = useLscData();
  const { meetSoftwareState } = useMeetSoftwareData();
  const { courseState } = useCourseData();
  const { environmentVariableState, OW_COURSE_ID, USAS_ORG_UNIT_ID, LSC_ORG_LEVEL_ID, CLUB_ORG_LEVEL_ID, NCAA_ORG_UNIT_ID
  } = useEnvironmentVariableData();
  const { errorState, formState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_SEARCH);
  };

  const onFacilityComboboxStateChange = (facilityComboboxState) => {
    if (facilityComboboxState.data.length === 0) {
      setFormState({
        ...formState,
        facilityId: null,
        facilityName: ''
      });
    } else {
      setFormState({
        ...formState,
        facilityId: Constants.DEFAULT_ID,
        facilityName: ''
      });
    }
    setState({
      ...state,
      facilityData: facilityComboboxState.data
    });
  };

  const onHostOrgUnitChanged = (newValue) => {
    setFormState({
      ...formState,
      hostOrgUnit: newValue,
      meetClassification: [],
      facilityId: Constants.DEFAULT_ID,
      facilityName: ''
    });
    setIsDirty(true);
  };

  const getSelfOrgLevelIdForIdRecurse = (id, node) => {
    let selfOrgLevelId = null;
    if (id === node.id) {
      selfOrgLevelId = node.selfOrgLevelId;
    } else if (Array.isArray(node.children) && node.children.length > 0) {
      node.children.forEach(child => {
        if (!selfOrgLevelId) {
          selfOrgLevelId = getSelfOrgLevelIdForIdRecurse(id, child);
        }
      });
    }

    return selfOrgLevelId;
  };

  const getParentOrgUnitIdForIdRecurse = (id, node) => {
    let parentOrgUnitId = null;
    if (id === node.id) {
      parentOrgUnitId = node.parentOrgUnitId;
    } else if (Array.isArray(node.children) && node.children.length > 0) {
      node.children.forEach(child => {
        if (!parentOrgUnitId) {
          parentOrgUnitId = getParentOrgUnitIdForIdRecurse(id, child);
        }
      });
    }

    return parentOrgUnitId;
  };

  useEffect(() => {
    if (lscState.isLoading !== true && lscState.isLoaded !== true) {
      getLscs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscState]);

  useEffect(() => {
    if (courseState.isLoaded === true) {
      const courseOptions = [];

      for (const course of courseState.data) {
        if (course.courseId > 0) {
          courseOptions.push({ id: course.courseId, name: course.courseCode });
        }
      }

      setCourseOptionsState(courseOptions);
    }
  }, [courseState]);

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === true && environmentVariableState.isLoaded === true && lscState.isLoaded === true) {
      const usasOrganizationsTreeData = JSON.parse(JSON.stringify(orgUnitState.treeData)).find(x => x.id === USAS_ORG_UNIT_ID);
      const usasOrganizationsArrayData = JSON.parse(JSON.stringify(orgUnitState.arrayData)).find(x => x.id === USAS_ORG_UNIT_ID);

      let usasHostOrg = [];
      let usasLsc = '';
      if (formState.hostOrgUnit.length > 0) {
        usasHostOrg = HierarchicalDataUtils.GetNameIdPairs([usasOrganizationsTreeData], [formState.hostOrgUnit[0].id]) || [];

        let newObj = null;
        let id = formState.hostOrgUnit[0].id;

        [usasOrganizationsArrayData].forEach(node => {
          if (!newObj) {
            const selfOrgLevelIdForIdRecurse = getSelfOrgLevelIdForIdRecurse(id, node);
            if (selfOrgLevelIdForIdRecurse) {
              newObj = { id, selfOrgLevelIdForIdRecurse }
            }
          }
        });
        if (newObj?.selfOrgLevelIdForIdRecurse === LSC_ORG_LEVEL_ID) {
          usasLsc = lscState.data.find(x => x.orgUnitId === formState.hostOrgUnit[0].id)?.orgUnitCode || '';
        }
        else if (newObj?.selfOrgLevelIdForIdRecurse === CLUB_ORG_LEVEL_ID) {
          let newObj2 = null;
          [usasOrganizationsArrayData].forEach(node => {
            if (!newObj2) {
              const parentOrgUnitIdForIdRecurse = getParentOrgUnitIdForIdRecurse(id, node);
              if (parentOrgUnitIdForIdRecurse) {
                newObj2 = { id, parentOrgUnitIdForIdRecurse }
              }
            }
          });
          usasLsc = lscState.data.find(x => x.orgUnitId === newObj2?.parentOrgUnitIdForIdRecurse)?.orgUnitCode || '';
        }
      }
      setFormState({
        ...formState,
        usasHostOrg: usasHostOrg.length > 0 ? true : false,
        usasLsc: usasLsc !== '' ? true : false,
        autogeneratedMeetLsc: usasLsc,
        NCAA_ORG_UNIT_ID: NCAA_ORG_UNIT_ID
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState, environmentVariableState, lscState, formState.hostOrgUnit]);

  useEffect(() => {
    let selectedFacility;
    if (formState.facilityId > 0) {
      selectedFacility = state.facilityData.find(x => x.facilityId === formState.facilityId);
    }
    setState({
      ...state,
      facilityCity: selectedFacility?.city || '',
      facilityState: selectedFacility?.stateCode || '',
      facilityCountry: selectedFacility?.country || '',
      facilityAltitude: selectedFacility?.altitude !== null && selectedFacility?.altitude >= 0 ? selectedFacility?.altitude : ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.facilityId]);

  useEffect(() => {
    if (formState.startDate !== Constants.BLANK_DATE_STRING) {
      setFormState({
        ...formState,
        autogeneratedMeetYear: formState.startDate?.substring(6, 10) !== '0000' ? formState.startDate?.substring(6, 10) : ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.startDate]);

  useEffect(() => {
    if (meetState.isSaving === false && meetState.isSaved === true) {
      confirmSave();
      setState({ ...state, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState]);

  useEffect(() => {
    if (meetState.objData.meetId && meetState.objData.meetId > 0 && state.tryRedirect === true) {
      navigate(NavLinks.MEET_ADD_SESSIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, state]);

  function submitFormCallback(formState) {
    if (meetState.isSaving !== true) {
      postMeet(createMeetObject());

      setState({
        ...state,
        tryRedirect: true
      });
    }
  };

  function createMeetObject() {
    return {
      meetClassificationId: formState.meetClassification[0].id,
      meetName: formState.usasHostOrg === true ? formState.usasLsc === true ? formState.autogeneratedMeetYear + ' ' + formState.autogeneratedMeetLsc + ' ' + formState.meetName.trim() : formState.autogeneratedMeetYear + ' ' + formState.meetName.trim() : formState.meetName.trim(),
      startDate: formState.startDate,
      endDate: formState.endDate,
      hostOrgUnitId: formState.hostOrgUnit[0].id,
      meetSoftwareId: formState.meetSoftwareId > 0 ? formState.meetSoftwareId : null,
      facilityId: formState.facilityId > 0 ? formState.facilityId : null,
      meetAbbreviation: formState.meetAbbreviation.trim(),
      meetCourse: formState.meetCourse.map(course => { return { courseId: course.id } }),
      isUsasMeet: false,
      isFinaMeet: false,
      isNcaaMeet: false,
      isInternationalSwimLeagueMeet: false,
      isUploadLocked: 0
    }
  };

  function getInitialState() {
    return {
      tryRedirect: false,
      facilityData: [],
      facilityCity: '',
      facilityState: '',
      facilityCountry: '',
      facilityAltitude: ''
    };
  };

  function getInitialFormState() {
    return {
      hostOrgUnit: [],
      meetClassification: [],
      meetName: '',
      meetAbbreviation: '',
      meetCourse: [],
      meetTypeName: '',
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      meetSoftwareId: Constants.DEFAULT_ID,
      meetSoftwareName: '',
      autogeneratedMeetYear: '',
      autogeneratedMeetLsc: '',
      usasHostOrg: false,
      usasLsc: '',
      NCAA_ORG_UNIT_ID: NCAA_ORG_UNIT_ID || Constants.DEFAULT_ID
    };
  };

  return {
    isLoading: orgUnitState.isArrayLoading || meetSoftwareState.isLoading
      || lscState.isLoading || courseOptionsState.length === 0,
    isSaving: meetState.isSaving,
    state,
    formState,
    errorState,
    courseOptionsState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onFacilityComboboxStateChange,
    onHostOrgUnitChanged,
    Constants,
    OW_COURSE_ID
  };
};

export default useMeetAdd;