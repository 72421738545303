import React, { Fragment } from 'react';

import AthleteUserCoachesLargeGrid from './AthleteUserCoachesLargeGrid';
import AthleteUserCoachesSmallGrid from './AthleteUserCoachesSmallGrid';

const AthleteUserCoachesGrid = ({ gridData, isLoading, onDeleteCoachClicked }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            <p>{gridData.length === 1 ? `${gridData.length} Coach` : `${gridData.length} Coaches`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <AthleteUserCoachesLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onDeleteCoachClicked={onDeleteCoachClicked}
      />
      <AthleteUserCoachesSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onDeleteCoachClicked={onDeleteCoachClicked}
      />
    </Fragment>
  )
};

export default AthleteUserCoachesGrid;