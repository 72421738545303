import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import getInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import {
  ageGroupsIsComplete, eligibilityIsComplete, eventsIsComplete, invitationsIsComplete,
  meetInfoIsComplete, pricingIsComplete, restrictionsIsComplete, sessionsIsComplete, submitIsComplete, timeStandardsIsComplete
}
  from './LeftNavValidation';

const getSubmit = (viewName, route, omeMeetStateObjData, omeMeetInvitationsStateArrayData,orgUnitStateArrayData, meetStateObjData, TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID,
  INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
  ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID,
  FINA_ORG_UNIT_ID, ENTRY_CONTACT_STAFF_TYPE_ID, PAYMENT_CONTACT_STAFF_TYPE_ID, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID,
  restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {

  const isCurrent = ((viewName === Views.OME_MEET_SUBMIT) || (viewName === Views.OME_MEET_SUBMITTED)) === true ? true : false;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);
  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);
  const isRestrictionsComplete = restrictionsIsComplete(omeMeetStateObjData, restrictionsRoutePermission);
  const isInvitationsComplete = invitationsIsComplete(omeMeetInvitationsStateArrayData);
  const isSessionsComplete = sessionsIsComplete(meetStateObjData);
  const isAgeGroupsComplete = ageGroupsIsComplete(omeMeetStateObjData);
  const isTimeStandardsComplete = timeStandardsIsComplete(omeMeetStateObjData);
  const isEventsComplete = eventsIsComplete(omeMeetStateObjData, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID);
  const isPricingComplete = pricingIsComplete(omeMeetStateObjData, omeMeetInvitationsStateArrayData,orgUnitStateArrayData, TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID,
    INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
    ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID);

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true
    && isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true
    && isAgeGroupsComplete === true && isTimeStandardsComplete === true && isEventsComplete === true && isPricingComplete === true;

  const isComplete = isMeetInfoComplete === true && isEligibilityComplete === true && isTimeStandardsComplete === true && isEventsComplete === true && isPricingComplete === true && submitIsComplete(omeMeetStateObjData, meetStateObjData, ENTRY_CONTACT_STAFF_TYPE_ID, PAYMENT_CONTACT_STAFF_TYPE_ID);

  const item = route === '' ? getInitialStateOmeRoute(Views.OME_MEET_SUBMIT) : getInitialStateMeetRoute(Views.OME_MEET_SUBMIT);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getSubmit;