import { Fragment } from 'react';

import useLaneSheetAddAthleteForm from './UseLaneSheetAddAthleteForm';

import SearchMemberPopup from '../../../../../common/components/searches/searchMember/SearchMemberPopup';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LaneSheetAddAthleteForm = ({ onSubmitFormCallback, existingAthletes }) => {
  const {
    isLoading,
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    handleSubmit
  } = useLaneSheetAddAthleteForm(onSubmitFormCallback, existingAthletes);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <SearchMemberPopup
              label={'Member Id*'}
              memberIdName={'memberId'}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} type='submit'>Add Member</button>
          </div>
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LaneSheetAddAthleteForm;