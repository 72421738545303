import React, { createContext, useState } from 'react';

import OmeMeetTeamEntryData from './OmeMeetTeamEntryData';

export const OmeMeetTeamEntryStateContext = createContext();

const OmeMeetTeamEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetTeamEntryData.INITIAL_STATE);

  return (
    <OmeMeetTeamEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetTeamEntryStateContext.Provider>
  );
};

export default OmeMeetTeamEntryContextProvider;