import React from 'react';

import useDateRangeDropdown from './UseDateRangeDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

const DateRangeDropdown = ({ label, name, value, error, message, onChange, startDate, endDate }) => {
  const { options } = useDateRangeDropdown(startDate, endDate);

  return (
    <Dropdown
      error={error}
      label={label}
      message={message}
      options={options}
      name={name}
      value={value}
      onChange={onChange}
      isLoading={false}
    />
  );
};

export default DateRangeDropdown;