import { useEffect, useState } from 'react';

import { getCompetitionGenderName } from '../../utils/SelectionsUtils';

import Constants from "../../../../../common/utils/Constants";
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_STATE = {
  meetHost: '',
  meetName: '',
  meetDates: '',
  competitionGenderTypeName: '',
  selectionDesc: '',
  maxSwimmers: '0',
  maxDivers: '0',
  minRowsToSeed: '0',
  minRelayRowsToAccept: '0',
  addRatioIndRows: '0',
  addRatioRelayRows: '0',
  includeEntriesNotCheckedOut: ''
};

const useConfigurationForm = (selectionsStateObj) => {
  const [state, setState] = useState(INITIAL_STATE);

  const getTotalParticipants = (maxSwimmers, maxDivers) => {
    const swimmers = parseInt(maxSwimmers);
    const divers = parseInt(maxDivers);

    if (!isNaN(swimmers) && !isNaN(divers)) {
      return `${swimmers + divers}`;
    } else {
      return '...';
    }
  };

  useEffect(() => {
    if (selectionsStateObj) {
      setState({
        ...state,
        meetHost: selectionsStateObj.meetHost || '',
        meetName: selectionsStateObj.meetName || '',
        meetDates: `${selectionsStateObj.meetStartDate ? formatDate(selectionsStateObj.meetStartDate) : Constants.BLANK_DATE_STRING} - ${selectionsStateObj.meetEndDate ? formatDate(selectionsStateObj.meetEndDate) : Constants.BLANK_DATE_STRING}`,
        competitionGenderTypeName: getCompetitionGenderName(selectionsStateObj.selectMales, selectionsStateObj.selectFemales),
        selectionDesc: selectionsStateObj.selectionDesc || '',
        maxSwimmers: selectionsStateObj.maxSwimmers || '0',
        maxDivers: selectionsStateObj.maxDivers || '0',
        minRowsToSeed: selectionsStateObj.minRowsToSeed || '0',
        minRelayRowsToAccept: selectionsStateObj.minRelayRowsToAccept || '0',
        addRatioIndRows: selectionsStateObj.addRatioIndRows || '0',
        addRatioRelayRows: selectionsStateObj.addRatioRelayRows || '0',
        includeEntriesNotCheckedOut: selectionsStateObj.includeEntriesNotCheckedOut === true ? 'Yes' : 'No'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsStateObj]);

  return {
    state,
    getTotalParticipants
  };
};

export default useConfigurationForm;