import React from 'react';

import ParticipantRowCell from './ParticipantRowCell';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import style from './SelectionParticipantsGrid.module.css';

const GridRow = ({ row, columnCount, showDetailPopupsInGrid, onParticipantClicked }) => (
  <tr>
    <td>{row.selectionRowNumber || ''}</td>
    {Array.isArray(row.selectionRowCell) && row.selectionRowCell.length > 0
      ? row.selectionRowCell.map((cell, i) => <ParticipantRowCell key={i} cell={cell} showDetailPopupsInGrid={showDetailPopupsInGrid} onParticipantClicked={onParticipantClicked} />)
      : <td colSpan={columnCount - 1}></td>}
  </tr>
);

const GridColumnHeader = ({ event }) => (
  <th>
    <span>{event.eventNumber || ''}.</span>
    <span>{event.eventCode || ''}</span>
    <span>{event.eventCompetitionGenderTypeName || ''}</span>
    <span>{event.baseTime ? formatTimeForDisplay(event.baseTime) : ''}</span>
  </th>
);

const SelectionParticipantsGridLarge = ({ columnData, rowData, columnCount, showDetailPopupsInGrid,
  onParticipantClicked }) => (
  <table className={[style.SelectionTable].join(' ')}>
    <thead>
      <tr>
        <th>
          <span>EVENT</span>
          <span>ROW</span>
        </th>
        {Array.isArray(columnData) && columnData.length > 0
          ? columnData.map((event, i) => <GridColumnHeader key={i} event={event} />)
          : <th colSpan={columnCount - 1}></th>}
      </tr>
    </thead>
    <tbody>
      {Array.isArray(rowData) && rowData.length > 0
        ? rowData.map((row, i) => <GridRow key={i} row={row} columnCount={columnCount} showDetailPopupsInGrid={showDetailPopupsInGrid} onParticipantClicked={onParticipantClicked} />)
        : <tr><td colSpan={columnCount}></td></tr>
      }
    </tbody>
  </table>
);

export default SelectionParticipantsGridLarge;