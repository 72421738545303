export const localValidate = (formState) => {
  let errors = {};

  const selectedAthletes = JSON.parse(JSON.stringify(formState.athletes));
  
  for (const invalidId of formState.invalidIds) {
    for (let i = 0; i < selectedAthletes.length; i++) {
      if (selectedAthletes[i]?.id === invalidId) {
        selectedAthletes[i] = null;
      }
    }
  }

  formState.athletes = selectedAthletes.filter(athlete => athlete !== null);

  if (formState.athletes.length === 0) {
    errors.athletes = 'Must select at least one Athlete to continue';
  }

  return errors;
};

const AddMeetTimeSelectAthletesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AddMeetTimeSelectAthletesValidation;