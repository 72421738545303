import { isValidCompetitorGender } from '../utils/MeetUtils';
import { formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { isValidNumber, isValidBirthDate, isValidSwimTime } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

const eventGenders = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Mixed" }
];

export const localValidate = (formState) => {
  let errors = {};

  //Athlete 
  if (formState.personId === '') {
    errors.personId = 'Athlete is required';
  }

  //Event
  if (formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = 'Event is required';
  }

  //Event Gender
  if (formState.eventCompetitionGenderTypeId === Constants.DEFAULT_ID) {
    errors.eventCompetitionGenderTypeId = 'Event Gender is required';
  }

  //Time 
  if (formState.time === '') {
    errors.time = 'Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formState.time))) {
    errors.time = 'Time must be valid';
  }

  //Place
  if (formState.finishPosition !== '' && (!isValidNumber(formState.finishPosition.trim()) || formState.finishPosition.trim() === '0')) {
    errors.finishPosition = 'Place (finish position) must be a number greater than zero if entered';
  }

  //Swim Date
  if (formState.swimDate === '' || formState.swimDateId === Constants.DEFAULT_ID) {
    errors.swimDateId = 'Swim Date is required';
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDateId = 'Swim Date cannot be in the future'
  }

  if (errors.eventCompetitionGenderTypeId === undefined && errors.personId === undefined) {
    const eventGender = eventGenders.find(genderOption => genderOption.id === formState.eventCompetitionGenderTypeId)?.name;
    const compGenderResults = isValidCompetitorGender(formState.personGender, eventGender);

    if (compGenderResults) {
      errors.personId = compGenderResults;
    }
  }

  return errors;
};

const AddMeetTimeIndividualValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AddMeetTimeIndividualValidation;