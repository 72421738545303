import React from 'react';

import useEventMeetEventBonusDropdown from './UseEventMeetEventBonusDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const EventMeetEventBonusDropdown = ({ label, name, value, error, message, onChange, meetEventId }) => {
  const { eventMeetEventBonusState } = useEventMeetEventBonusDropdown(meetEventId);

  return eventMeetEventBonusState.message
    ? <span className={global.LoadingMsg}>{eventMeetEventBonusState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={eventMeetEventBonusState.items}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={eventMeetEventBonusState.isArrayLoading}
        areValuesIntegers={true}
      />
    );
};

export default EventMeetEventBonusDropdown;