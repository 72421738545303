import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { PARTICIPANT_SELECTIONS_NAME } from "../components/leftNav/SelectionsLeftNavConstants";

import NavLinks from "../utils/NavLinks";
import { isParticipantSelectionsValid } from "../utils/SelectionsUtils";

import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsParticipantsData from "../../../state/selectionsParticipants/UseSelectionsParticipantsData";
import useSelectionsLeftNavData from "../../../state/selectionsLeftNav/UseSelectionsLeftNavData";
import useSelectionsMeetEventsData from "../../../state/selectionsMeetEvents/UseSelectionsMeetEventsData";
import useSelectionsLoadingModalData from "../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

const INITIAL_STATE = {
  displayCompletionError: false,
  completionErrorMessage: 'Selected Swimmers cannot be 0 or greater than Max Swimmers'
};

const useSelectionsParticipantSelections = () => {
  const navigate = useNavigate();
  const { selectionsLeftNavState, updateCurrentLeftNavData } = useSelectionsLeftNavData();
  const { selectionsState } = useSelectionsData();
  const { selectionsMeetEventsState, getSelectionMeetEvents } = useSelectionsMeetEventsData();
  const { selectionsParticipantsState, getSelectionParticipants, toggleShowSelectionGridPopup
  } = useSelectionsParticipantsData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCompleteClicked = () => {
    const maxSwimmers = selectionsState.objData?.maxSwimmers;
    const selectedSwimmers = selectionsParticipantsState.objData?.selectedSwimmers;

    if (isParticipantSelectionsValid(selectedSwimmers, maxSwimmers)) {
      navigate(NavLinks.SELECTIONS_REPORTS);
    } else {
      setState({
        ...state,
        displayCompletionError: true
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.SELECTIONS_MEET_ENTRIES);
  };

  const onShowSelectionGridClicked = () => {
    toggleShowSelectionGridPopup(true);
  };

  useEffect(() => {
    if (selectionsLeftNavState.isLoaded === true) {
      const selectionMeetId = selectionsState.objData?.selectionMeetId;

      if (selectionMeetId > 0) {
        const getSelectionParticipantsPromise = getSelectionParticipants(selectionMeetId);

        if (getSelectionParticipantsPromise !== null) {
          getSelectionParticipantsPromise.then((newState) => {
            if (newState !== null) {
              const maxSwimmers = selectionsState.objData?.maxSwimmers;
              const selectedSwimmers = newState.objData?.selectedSwimmers;

              if (isParticipantSelectionsValid(selectedSwimmers, maxSwimmers)) {
                updateCurrentLeftNavData(PARTICIPANT_SELECTIONS_NAME, true);
              } else {
                updateCurrentLeftNavData(PARTICIPANT_SELECTIONS_NAME, false);
              }
            }
          }).catch((e) => {
            setContextStateError(true);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState, selectionsLeftNavState.isLoaded]);

  useEffect(() => {
    if (selectionsLeftNavState.isLoaded === true) {
      if (selectionsMeetEventsState.isArrayLoaded !== true) {
        const selectionMeetId = selectionsState.objData?.selectionMeetId;

        if (selectionMeetId > 0) {
          const getSelectionMeetEventsPromise = getSelectionMeetEvents(selectionMeetId);

          if (getSelectionMeetEventsPromise !== null) {
            getSelectionMeetEventsPromise.catch((e) => {
              setContextStateError(true);
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState, selectionsMeetEventsState, selectionsLeftNavState.isLoaded]);

  return {
    isLoading: selectionsParticipantsState.isObjLoading,
    state,
    selectionsState,
    selectionsParticipantsState,
    onShowSelectionGridClicked,
    onBackClicked,
    onCompleteClicked
  };
};

export default useSelectionsParticipantSelections