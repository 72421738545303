import React from 'react';

import useLaneSheetEventMultiSelect from './UseLaneSheetEventMultiSelect';

import MultiSelect from '../../../../common/components/multiSelect/MultiSelect';

import global from '../../../../common/components/GlobalStyle.module.css';

const LaneSheetEventMultiSelect = ({ label, name, value, error, message, onChange, laneSheetMeetId }) => {
  const { laneSheetMeetEventsState, options } = useLaneSheetEventMultiSelect(laneSheetMeetId);

  return laneSheetMeetEventsState.message
    ? <span className={global.LoadingMsg}>{laneSheetMeetEventsState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={options}
        error={error}
        message={message}
        isLoading={laneSheetMeetEventsState.isArrayLoading}
        onChange={onChange} />
    );
};

export default LaneSheetEventMultiSelect;