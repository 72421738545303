import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getMeetStaffData from './GetMeetStaffData';
import putMeetStaffData from './PutMeetStaffData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetStaffData = {
  INITIAL_STATE, getMeetStaffData, putMeetStaffData
};

export default MeetStaffData;