export const localValidate = (formState) => {
  let errors = {};

  if (formState.willAttend === false) {
    if (!(formState.unavailableReasonId > 0)) {
      errors.unavailableReasonId = 'Unavailable Reason is required';
    }
  }

  return errors;
};

const ParticipantEditFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ParticipantEditFormValidation;