import { useEffect } from 'react';

import useEventMeetEventData from '../../state/eventMeetEvent/UseEventMeetEventData';

const useEventMeetEventDropdown = (meetEventId) => {
  const { eventMeetEventState, getEventMeetEvent } = useEventMeetEventData();

  useEffect(() => {
    if (eventMeetEventState.isArrayLoading !== true && meetEventId !== eventMeetEventState.meetEventId &&
      meetEventId > 0) {
      getEventMeetEvent(meetEventId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMeetEventState, meetEventId])

  return {
    eventMeetEventState
  };
};

export default useEventMeetEventDropdown;