import React from 'react';

import useMeetTimesCompetitorCombobox from './UseMeetTimesCompetitorCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';

const MeetTimesCompetitorCombobox = ({ label, name, valueToMatch, error, message, onChange, meetId }) => {
  const { optionsState } = useMeetTimesCompetitorCombobox(meetId);

  return (
    <Combobox
      label={label}
      name={name}
      isLoading={false}
      items={optionsState}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default MeetTimesCompetitorCombobox;