import { isValidEmail } from '../../../../../common/utils/validation';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Member Id
  if (formState.memberId.trim() === '') {
    errors.memberId = 'Member Id is required';
  } else if (!isValidMemberId(formState.memberId.trim())) {
    errors.memberId = 'Member Id must be 14 alphanumeric characters';
  }

  //Email
  if (formState.emailAddress.trim() === '') {
    errors.emailAddress = 'Email is required';
  } else if (!isValidEmail(formState.emailAddress.trim())) {
    errors.emailAddress = 'Email must be in a valid format';
  } else if (formState.emailAddress.trim().length > 512) {
    errors.emailAddress = 'Email cannot exceed 512 characters'
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  //Member Id
  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.memberId = "Member Id does not have an active member registration";
        } else {
          formState.personId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.memberId = 'Member Id is not valid';
    });

  return errors;
};

const OrgUnitAdminContactsDetailValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default OrgUnitAdminContactsDetailValidation;