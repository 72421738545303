import React, { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';
import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ orgItem, onOrgCheckbox, onRecordsCheckbox, onTopTimesCheckbox, onMeetEntryCheckbox }) => {
  return (
    <Fragment>
      <tr>
        <td>
          {orgItem.organizationName || ''}
        </td>
        <td>
          <Checkbox
            label=""
            name="isEligibleForOrganization"
            onChange={(e) => { onOrgCheckbox(e, orgItem.organizationId) }}
            checked={orgItem.isEligibleForOrganization} />
        </td>
        <td>
          {orgItem.isEligibleForOrganization === true ?
          <Checkbox
            label=""
            name="isEligibleForRecord"
            onChange={(e) => { onRecordsCheckbox(e, orgItem.organizationId) }}
            checked={orgItem.isEligibleForRecord} />
            : 'Not Applicable'
          }
        </td>
        <td>
          {orgItem.isEligibleForOrganization === true ?
          <Checkbox
            label=""
            name="isEligibleForTopTimes"
            onChange={(e) => { onTopTimesCheckbox(e, orgItem.organizationId) }}
            checked={orgItem.isEligibleForTopTimes} />
            : 'Not Applicable'
          }
        </td>
        <td>
          {orgItem.isEligibleForOrganization === true ?
          <Checkbox
            label=""
            name="isEligibleForMeetEntry"
            onChange={(e) => { onMeetEntryCheckbox(e, orgItem.organizationId) }}
            checked={orgItem.isEligibleForMeetEntry} />
            : 'Not Applicable'
          } 
        </td>
      </tr>
    </Fragment>
  );
};

const MeetRecognitionLargeGrid = ({ gridData, isLoading, onOrgCheckbox, onRecordsCheckbox, onTopTimesCheckbox, onMeetEntryCheckbox }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Organization</th>
          <th>Org Eligible?</th>
          <th>Records Eligible?</th>
          <th>Top Times Eligible?</th>
          <th>Meet Entry Eligible?</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="9">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((org, i) => 
            <GridRow 
              key={i} 
              orgItem={org} 
              onOrgCheckbox={onOrgCheckbox}
              onRecordsCheckbox={onRecordsCheckbox}
              onTopTimesCheckbox={onTopTimesCheckbox}
              onMeetEntryCheckbox={onMeetEntryCheckbox} />)
            :
            <tr><td colSpan="9">No Results</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetRecognitionLargeGrid;