import { useEffect } from 'react';

import useOrgGroupsData from '../../../state/orgGroup/UseOrgGroupData';

const useOrgGroupCombobox = (orgUnitId, groupTypeId) => {
  const { orgGroupState, getOrgGroupsByOrgUnitIdAndGroupTypeId } = useOrgGroupsData();

  useEffect(() => {
    if ((orgGroupState.isArrayLoaded !== true || orgUnitId !== orgGroupState.orgUnitId ||
      groupTypeId !== orgGroupState.groupTypeId) && orgGroupState.isArrayLoading !== true) {
      getOrgGroupsByOrgUnitIdAndGroupTypeId(orgUnitId, groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupState, orgUnitId, groupTypeId]);

  return { orgGroupState, getOrgGroupsByOrgUnitIdAndGroupTypeId };
};

export default useOrgGroupCombobox;