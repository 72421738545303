import { useContext } from 'react';

import { OrgRoleStateContext } from './OrgRoleContextProvider';
import OrgRoleData from './OrgRoleData';

const useOrgRoleData = () => {
  const [orgRoleState, setOrgRoleState] = useContext(OrgRoleStateContext);

  const getOrgRoleDataByRoleGroupId = (roleGroupId) => OrgRoleData.getOrgRoleDataByRoleGroupIdData(roleGroupId, orgRoleState, setOrgRoleState)
  const getOrgRoleDataByOrganizationId = (organizationId) => OrgRoleData.getOrgRoleDataByOrganizationIdData(organizationId, orgRoleState, setOrgRoleState);
  const getOrgRoleDataByRoleGroupIdWithOrgLevelIdOrOrgUnitId = (roleGroupId, orgLevelId = undefined, orgUnitId = undefined) => OrgRoleData.getOrgRoleDataByRoleGroupIdOrgLevelIdOrgUnitIdData(roleGroupId, orgLevelId, orgUnitId, orgRoleState, setOrgRoleState)

  const resetOrgRoleState = () => {

    setOrgRoleState({ ...OrgRoleData.INITIAL_STATE });
  };

  return {
    orgRoleState,
    setOrgRoleState,
    getOrgRoleDataByRoleGroupId,
    getOrgRoleDataByOrganizationId,
    getOrgRoleDataByRoleGroupIdWithOrgLevelIdOrOrgUnitId,
    resetOrgRoleState
  };
};

export default useOrgRoleData;