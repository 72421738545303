import React, { Fragment } from 'react';

import useSelectionsParticipantSelectionsDetail from './UseSelectionsParticipantSelectionsDetail';

import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import ParticipantEditForm from '../components/forms/ParticipantEditForm';
import ParticipantRelayEditForm from '../components/forms/ParticipantRelayEditForm';
import SelectionsLoadingModal from '../components/dialogs/SelectionsLoadingModal';
import { PARTICIPANT_SELECTIONS_NAME } from '../components/leftNav/SelectionsLeftNavConstants';

const SelectionsParticipantSelectionsDetail = () => {
  const {
    isLoading,
    participantObj,
    selectionsState,
    onSubmitFormCallbackIndividual,
    onSubmitFormCallbackRelay,
    onBackClicked
  } = useSelectionsParticipantSelectionsDetail();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={PARTICIPANT_SELECTIONS_NAME} viewTitle={'Participant Edit'}>
        {participantObj.selectionMeetPersonId > 0 ? (
          <ParticipantEditForm
            onSubmitFormCallback={onSubmitFormCallbackIndividual}
            onSecondaryButtonClicked={onBackClicked}
            submitButtonText={'Save'}
            secondaryButtonText={'Back'}
            participantObj={participantObj}
            selectionsStateObj={selectionsState.objData} />
        ) : (
          <ParticipantRelayEditForm
            onSubmitFormCallback={onSubmitFormCallbackRelay}
            onSecondaryButtonClicked={onBackClicked}
            submitButtonText={'Save'}
            secondaryButtonText={'Back'}
            participantObj={participantObj}
            selectionsStateObj={selectionsState.objData} />
        )}
        <SelectionsLoadingModal isLoading={isLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
};

export default SelectionsParticipantSelectionsDetail;