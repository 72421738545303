import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useOmeMeetHostData from '../../state/omeMeetHost/UseOmeMeetHostData';
import useOmeMeetTeamEntryData from '../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import usePersonOrgRoleData from '../../../common/state/personOrgRole/UsePersonOrgRoleData';

import MyMeetsNavLinks from './MyMeetsNavLinks';

const TAXONOMIES = ['MyMeetsDetail'];
const SCOPE = 'MyMeets';

const useMyMeetContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getContextSecurity, contextSecurityState } = useSecurityData();
  const { clearOmeMeetHostArrayData, omeMeetHostFiltersState, setOmeMeetHostFiltersState } = useOmeMeetHostData();
  const { clearOmeMeetTeamEntryArrayData, omeMeetTeamEntryFiltersState, setOmeMeetTeamEntryFiltersState } = useOmeMeetTeamEntryData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, setSelectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  };

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetSelectOrgUnitState();
    clearArrayData();
    clearOmeMeetHostArrayData();
    clearOmeMeetTeamEntryArrayData();
    navigate(MyMeetsNavLinks.SELECTION);
  };

  useEffect(() => {
    if (location.state?.selectOrgUnitState) {
      setSelectOrgUnitState({
        ...selectOrgUnitState,
        orgUnitId: location.state?.selectOrgUnitState.orgUnitId,
        orgUnitName: location.state?.selectOrgUnitState.orgUnitName,
        showBackToSelection: location.state?.selectOrgUnitState.showBackToSelection
      });
    }
    if (location.state?.myMeetsFiltersState) {
      setOmeMeetHostFiltersState({
        ...omeMeetHostFiltersState,
        filterObject: location.state?.myMeetsFiltersState?.omeMeetHostFiltersState?.filterObject,
        sortBy: location.state?.myMeetsFiltersState?.omeMeetHostFiltersState?.sortBy,
      });
      setOmeMeetTeamEntryFiltersState({
        ...omeMeetTeamEntryFiltersState,
        filterObject: location.state?.myMeetsFiltersState?.omeMeetTeamEntryFiltersState?.filterObject,
        sortBy: location.state?.myMeetsFiltersState?.omeMeetTeamEntryFiltersState?.sortBy,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getContextSecurity(selectOrgUnitState.orgUnitId, TAXONOMIES, SCOPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId]);

  return {
    location,
    onHomeClicked,
    selectOrgUnitState,
    contextSecurityState,
    onBackToSelectionClicked
  };
};

export default useMyMeetContextView;