import { useContext } from 'react';

import { OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryStateContext } from './OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryContextProvider';

import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData from './OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData';

const useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData = () => {
  const [omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState] = useContext(OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryStateContext);

  const getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoaches = (orgUnitAthleteEntryId) =>
    OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData.getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesData(orgUnitAthleteEntryId, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState);

  const deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntry = (orgUnitAthleteEntryOrgUnitNonAthleteEntryId) =>
    OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData.deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntryData(orgUnitAthleteEntryOrgUnitNonAthleteEntryId, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState);

  const clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryObjData = () => {
    setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState({
      ...omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryArrayData = () => {
    setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState({
      ...omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry = () => {
    setOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState({
      ...omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
      isSaved: false
    });
  };

  return {
    omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
    getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoaches,
    deleteOrgUnitAthleteEntryOrgUnitNonAthleteEntry,
    clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryObjData,
    clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryArrayData,
    confirmSaveOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry
  };
};

export default useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData;