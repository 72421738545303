import React, { Fragment } from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDateTime } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ teamEntry, onDeleteTeamEntryClicked }) => {
  return (
    <Fragment>
      <tr>
        <td>{teamEntry.orgUnit?.orgUnitName || ''} {teamEntry.orgUnit?.orgUnitName === 'Unattached' ? ' - ' + teamEntry.orgUnit?.parentOrgUnitName : ''} {teamEntry.orgUnit?.parentOrgUnitCode ? '(' + teamEntry.orgUnit?.parentOrgUnitCode + ')' : ''}</td>
        <td>{teamEntry.status || 'Unknown'} {teamEntry.orgUnitSubmissionDatetime ? '- ' + formatDateTime(teamEntry.orgUnitSubmissionDatetime) + ' ' + teamEntry.timeZone : ''}</td>
        <td>
          {onDeleteTeamEntryClicked &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onDeleteTeamEntryClicked(e, teamEntry)}>
              <DeleteIcon />
            </button>}
        </td>
      </tr>
    </Fragment>
  );
};

const MeetTeamEntryLargeGrid = ({ gridData, isLoading, onDeleteTeamEntryClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Team Name</th>
          <th>Team Entry Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="3">Loading...</td></tr>
          : gridData && Object.keys(gridData).length > 0 ?
            <GridRow
              key={gridData.omeMeetOrgUnitEntryId}
              teamEntry={gridData}
              onDeleteTeamEntryClicked={onDeleteTeamEntryClicked} />
            :
            <tr>
              <td colSpan={3}>No Team Entry</td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetTeamEntryLargeGrid;