import React, { createContext, useState } from 'react';

export const SelectionGridOptionsStateContext = createContext();

const INITIAL_STATE = {
  showSelectionGridPopup: false,
  showDetailPopupsInGrid: true,
  showRelaysInAlternatesGrid: false
};

const SelectionGridOptionsContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionGridOptionsStateContext.Provider value={stateHook}>
      {children}
    </SelectionGridOptionsStateContext.Provider>
  );
};

export default SelectionGridOptionsContextProvider;