
export const localValidate = (formState) => {
  let errors = {};

  // Host Organization
  if (formState.orgUnit.length === 0 || formState.orgUnit[0].id < 1) {
    errors.orgUnit = `Must select a Host Organization to search`
  }

  //Meet Name
  if (formState.meetName === '') {
    errors.meetName = 'Please enter a Meet Name to filter results';
  } else if (formState.meetName.trim() !== '' & formState.meetName.trim().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters';
  }

  return errors;
};

const LaneSheetLandingFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetLandingFormValidation;