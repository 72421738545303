import Constants from "../../../../../common/utils/Constants";
import { isValidNumber } from "../../../../../common/utils/validation";

const MAX_NUMBER_OF_LANES = 10;
const MIN_NUMBER_OF_LANES = 6;

export const localValidate = (formState) => {
  let errors = {};

  // Number of Lanes
  if (formState.numberOfLanes === '') {
    errors.numberOfLanes = `Number of Lanes is required`
  } else if (formState.numberOfLanes === 0) {
    errors.numberOfLanes = 'Number of lanes must be a number greater than 0';
  } else if (formState.numberOfLanes < MIN_NUMBER_OF_LANES || formState.numberOfLanes > MAX_NUMBER_OF_LANES) {
    errors.numberOfLanes = `Number of lanes must be a number between ${MIN_NUMBER_OF_LANES} and ${MAX_NUMBER_OF_LANES}`;
  } else if (!isValidNumber(formState.numberOfLanes)) {
    errors.numberOfLanes = 'Number of lanes must be a valid number';
  }

  //Print Lanes
  if (formState.reverseLaneOrder === '') {
    errors.reverseLaneOrder = 'Please select Print Lanes';
  }

  //Lane Sheet Course Id
  if (formState.careerStats.length === 0) {
    errors.careerStats = "Must pick at least one Course";
  }

  //Date Format
  if (formState.useAmericanDates !== true && formState.useAmericanDates !== false) {
    errors.useAmericanDates = 'Please select a date format';
  }

  //Lane Sheet Template
  if (formState.laneSheetTemplateId === Constants.DEFAULT_ID) {
    errors.laneSheetTemplateId = "Please select a lane sheet template";
  }

  //Record Group
  if (formState.recordGroupWithinSheetId === Constants.DEFAULT_ID) {
    errors.recordGroupWithinSheetId = "Please select a record group";
  }

  return errors;
};

const LaneSheetAddMeetInfoFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetAddMeetInfoFormValidation;