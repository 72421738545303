import { Fragment } from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import Icon from '../../../../../common/components/icons/Icon';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminRosterSmallGrid = ({ gridData, isLoading, onAddSecondaryRepToAthleteClicked, onDeleteAthleteClicked, allowRelayOnly, showSecondaryRep }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((athlete, i) => (
          <div className={global.SmallTableRow} key={athlete.orgUnitAthleteEntryId || i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''}  ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
              {onDeleteAthleteClicked && (athlete.canBeDeleted === true || (athlete.isRelayAthlete === false && athlete.hasPaid === false))
                && <button className={global.IconButton}
                  type="button"
                  onClick={(e) => onDeleteAthleteClicked(e, athlete)}>
                  <DeleteIcon />
                </button>}
              {onAddSecondaryRepToAthleteClicked && showSecondaryRep === true &&
                <button className={onDeleteAthleteClicked && (athlete.canBeDeleted === true || (athlete.isRelayAthlete === false && athlete.hasPaid === false)) ? global.IconButtonMobileMargin : global.IconButton}
                  type="button"
                  onClick={(e) => onAddSecondaryRepToAthleteClicked(e, athlete)}>
                  <Icon tooltip={"Edit Secondary Representation"} iconObj={faEdit} />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age at Meet</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.status || <span>&nbsp;</span>}</div>
                {allowRelayOnly === true &&
                  <Fragment>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-sm'].join(' ')}>Relay Only Swimmer?</div>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-sm'].join(' ')}>Relay Only?</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.isRelayOnlyAthlete === true ? 'Yes' : athlete.isRelayOnlyAthlete === false ? 'No' : <span>&nbsp;</span>}</div>
                  </Fragment>
                }
                {showSecondaryRep === true &&
                  <Fragment>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Secondary Representation</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.secondaryRepresentationOrgUnitId ? `${athlete.secondaryRepresentationOrgUnitName || ''} (${athlete.secondaryRepresentationParentOrgUnitCode || ''}/${athlete.secondaryRepresentationOrgUnitCode || ''})` : <span>&nbsp;</span>}</div>
                  </Fragment>
                }
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Athletes</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminRosterSmallGrid;