import { useContext, useState } from 'react';

import SelectionsData from './SelectionsData';

import { SelectionsStateContext } from './SelectionsContextProvider';
import { NewSelectionSearchFiltersStateContext } from './NewSelectionSearchFiltersContextProvider';

import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_NEW_SELECTION_SEARCH_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_EXISTING_SELECTIONS_STATE = {
  ...BASIC_INITIAL_STATE,
  omeMeetId: Constants.DEFAULT_ID,
  meetName: ''
};

const useSelectionsData = () => {
  const [selectionsState, setSelectionsState] = useContext(SelectionsStateContext);
  const [newSelectionSearchFilterState, setNewSelectionSearchFilterState] = useContext(NewSelectionSearchFiltersStateContext);
  const [newSelectionSearchState, setNewSelectionSearchState] = useState(INITIAL_NEW_SELECTION_SEARCH_STATE);
  const [existingSelectionsState, setExistingSelectionsState] = useState(INITIAL_EXISTING_SELECTIONS_STATE);

  const searchNewSelection = (filterObject) => {
    setNewSelectionSearchFilterState({ ...newSelectionSearchFilterState, filterObject });
    return SelectionsData.searchNewSelectionData(JSON.stringify(filterObject), newSelectionSearchState, setNewSelectionSearchState);
  };

  const postSelection = (selectionsObj) => {
    return SelectionsData.postSelectionData(selectionsObj, selectionsState, setSelectionsState);
  };

  const getExistingSelections = (omeMeetId, meetName) => {
    return SelectionsData.getExistingSelectionsData(omeMeetId, meetName, existingSelectionsState, setExistingSelectionsState);
  };

  const getSelectionConfiguration = (selectionMeetId) => {
    return SelectionsData.getSelectionConfigurationData(selectionMeetId, selectionsState, setSelectionsState);
  };

  const putSelectionConfiguration = (selectionMeetId, configurationObj) => {
    return SelectionsData.putSelectionConfigurationData(selectionMeetId, configurationObj, selectionsState, setSelectionsState);
  };

  const putSelectionCompleteSetup = (selectionMeetId, state, setState) => {
    return SelectionsData.putSelectionCompleteSetupData(selectionMeetId, state, setState);
  };

  const deleteSelection = (selectionMeetId) => {
    return SelectionsData.deleteSelectionData(selectionMeetId, existingSelectionsState, setExistingSelectionsState);
  };

  const toggleNewSelectionSearchFilters = () => {
    setNewSelectionSearchFilterState({
      ...newSelectionSearchFilterState,
      showFilters: !newSelectionSearchFilterState.showFilters
    });
  };

  const setIsConfigComplete = (isConfigComplete) => {
    const objData = selectionsState.objData;
    objData.isConfigComplete = isConfigComplete;

    setSelectionsState({
      ...selectionsState,
      objData
    });
  };

  const resetSelectionsState = () => {
    setSelectionsState({
      ...selectionsState,
      ...BASIC_INITIAL_STATE
    });
  };

  return {
    selectionsState,
    newSelectionSearchState,
    newSelectionSearchFilterState,
    existingSelectionsState,
    setExistingSelectionsState,
    searchNewSelection,
    getExistingSelections,
    postSelection,
    getSelectionConfiguration,
    putSelectionConfiguration,
    putSelectionCompleteSetup,
    deleteSelection,
    toggleNewSelectionSearchFilters,
    setIsConfigComplete,
    resetSelectionsState
  };
};

export default useSelectionsData;