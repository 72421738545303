import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryRelayAthleteByMeetEventIdData from './GetOrgUnitAthleteEntryRelayAthleteByMeetEventIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  meetEventId: Constants.DEFAULT_ID
};

const RelayAthleteMeetEventData = {
  INITIAL_STATE, getOrgUnitAthleteEntryRelayAthleteByMeetEventIdData
}

export default RelayAthleteMeetEventData;