import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getOrgRoleDataByRoleGroupIdData from './GetOrgRoleDataByRoleGroupIdData';
import getOrgRoleDataByOrganizationIdData from './GetOrgRoleDataByOrganizationIdData';
import getOrgRoleDataByRoleGroupIdOrgLevelIdOrgUnitIdData from './GetOrgRoleDataByRoleGroupIdOrgLevelIdOrgUnitIdData';

import Constants from '../../utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  roleGroupId: Constants.DEFAULT_ID,
  organizationId: Constants.DEFAULT_ID,
  orgLevelId: Constants.DEFAULT_ID,
  orgUnitId: '',
  dataAsOptionsWithNames: []
};

const OrgRoleData = {
  INITIAL_STATE, getOrgRoleDataByRoleGroupIdData, getOrgRoleDataByOrganizationIdData, getOrgRoleDataByRoleGroupIdOrgLevelIdOrgUnitIdData
};

export default OrgRoleData;