import { Fragment } from 'react';

import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const GridRow = ({ athlete, onCheckboxChange, onYesNoSwitchChange, allowRelayOnly }) => {
  return (
    <tr key={athlete.orgUnitAthleteEntryId}>
      <td>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''}  ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''}</td>
      <td>{athlete.competitionGenderTypeName || ''}</td>
      <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
      <td>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : ''}</td>
      <td>
      {(athlete.canBeDeleted === true || (athlete.isRelayAthlete === false && athlete.hasPaid === false)) ?
          <Checkbox
            label=""
            name="isSelected"
            onChange={(e) => { onCheckboxChange(e, athlete.personId) }}
            checked={athlete.isSelected} /> :
          <Checkbox
            label=""
            name="isSelected"
            onChange={(e) => { onCheckboxChange(e, athlete.personId) }}
            checked={athlete.isSelected}
            disabled={true} />
        }
      </td>
      {allowRelayOnly === true &&
        <td>
          {athlete.isSelected === true &&
            <Fragment>
              {athlete.isIndividualAthlete === true ?
                <YesNoSwitch
                  label=""
                  name={'isRelayOnlyAthlete' + athlete.personId}
                  onChange={(e) => { onYesNoSwitchChange(e, athlete.personId) }}
                  checked={athlete.isRelayOnlyAthlete}
                  disabled={true} /> :
                <YesNoSwitch
                  label=""
                  name={'isRelayOnlyAthlete' + athlete.personId}
                  onChange={(e) => { onYesNoSwitchChange(e, athlete.personId) }}
                  checked={athlete.isRelayOnlyAthlete} />}
            </Fragment>}
        </td>}
    </tr>
  );
};

const OrgUnitAdminRosterSelectionLargeGrid = ({ gridData, isLoading, onCheckboxChange, onYesNoSwitchChange, allowRelayOnly }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Competition Category</th>
        <th>Birth Date</th>
        <th>Age at Meet</th>
        <th>Select</th>
        {allowRelayOnly === true &&
          <th>Relay Only Swimmer?</th>}
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={allowRelayOnly === true ? "6" : "5"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((athlete, i) =>
            <GridRow
              key={i}
              athlete={athlete}
              onCheckboxChange={onCheckboxChange}
              onYesNoSwitchChange={onYesNoSwitchChange}
              allowRelayOnly={allowRelayOnly} />)
          :
          <tr>
            <td colSpan={allowRelayOnly === true ? "6" : "5"}>No Results</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminRosterSelectionLargeGrid;