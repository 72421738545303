import React, { Fragment } from 'react';

import useOmeTimeStandardsDetail from './UseOmeTimeStandardsDetail';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import TimeStandardTypeTreeView from '../../../../components/tree/timeStandardTypeTree/TimeStandardTypeTreeView';

import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';
import global from '../../../../../common/components/GlobalStyle.module.css';

const OmeTimeStandardsDetail = () => {
  const {
    omeMeetState,
    timeStandardTypeState,
    errorState,
    formState,
    modalState,
    onModalCanceled,
    onSaveAndDeleteEventTimeStandardCut,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onCreateNewTimeStandardsClicked,
    navModalState,
    onNavModalCanceled,
    onNavigateToTimeStandards
  } = useOmeTimeStandardsDetail();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_TIME_STANDARDS_DETAIL}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12'>
              <ActionIntraPageButton onClick={onCreateNewTimeStandardsClicked}>Create or Update Time Standards</ActionIntraPageButton>
            </div>
          </div>
          <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
            <div className='col-xs-12 col-sm-6'>
              <ReadOnly
                label="Age Group Name"
                name="ageGroupName"
                value={formState.ageGroupName}
              />
            </div>
            <div className='col-xs-12 col-sm-3'>
              <ReadOnly
                label="Min Age"
                name="minAge"
                value={formState.minAge}
              />
            </div>
            <div className='col-xs-12 col-sm-3'>
              <ReadOnly
                label="Max Age"
                name="maxAge"
                value={formState.maxAge}
              />
            </div>
            <div className={formState.timeStandardsFormState?.hasNoSlowerThanTimeStandards === true ? global.DisplayCompoment : global.HideComponent}>
              <div className='col-xs-12'>
                <TimeStandardTypeTreeView
                  label={'No Slower Than Time Standard*'}
                  name="noSlowerThanTimeStandardAgeGroup"
                  value={formState.noSlowerThanTimeStandardAgeGroup}
                  error={errorState.noSlowerThanTimeStandardAgeGroup}
                  message={errorState.noSlowerThanTimeStandardAgeGroup}
                  selectableLeavesOnly={true}
                  singleSelect={true}
                  onChange={(newValue) => {
                    onFormValueChanged('noSlowerThanTimeStandardAgeGroup', newValue)
                  }} />
              </div>
            </div>
            <div className={formState.timeStandardsFormState?.hasNoFasterThanTimeStandards === true ? global.DisplayCompoment : global.HideComponent}>
              <div className='col-xs-12'>
                <TimeStandardTypeTreeView
                  label={'No Faster Than Time Standard*'}
                  name="noFasterThanTimeStandardAgeGroup"
                  value={formState.noFasterThanTimeStandardAgeGroup}
                  error={errorState.noFasterThanTimeStandardAgeGroup}
                  message={errorState.noFasterThanTimeStandardAgeGroup}
                  selectableLeavesOnly={true}
                  singleSelect={true}
                  onChange={(newValue) => {
                    onFormValueChanged('noFasterThanTimeStandardAgeGroup', newValue)
                  }} />
              </div>
            </div>
            <div className={formState.timeStandardsFormState?.hasBonusTimeStandards === true ? global.DisplayCompoment : global.HideComponent}>
              <div className='col-xs-12'>
                <TimeStandardTypeTreeView
                  label={'Bonus Time Standard*'}
                  name="bonusTimeStandardAgeGroup"
                  value={formState.bonusTimeStandardAgeGroup}
                  error={errorState.bonusTimeStandardAgeGroup}
                  message={errorState.bonusTimeStandardAgeGroup}
                  selectableLeavesOnly={true}
                  singleSelect={true}
                  onChange={(newValue) => {
                    onFormValueChanged('bonusTimeStandardAgeGroup', newValue)
                  }} />
              </div>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={navModalState.modalTitle}
          displayPopUp={navModalState.displayPopUp}
          onModalCanceled={onNavModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p className={['text-center', global.Bold].join(' ')}>You are about to navigate away from this page without saving...</p>
              <p className='usas-extra-top-marign usas-extra-bottom-margin'></p>
              <p className='text-center'>Are you sure you want to continue? The information you entered has not been saved!</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onNavModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
              <PrimaryButton type="button" onClick={(e) => onNavigateToTimeStandards(e)}>Continue</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              {modalState.deleteAllEventTimeStandardCuts === true ? <p>Changing 'Meet Uses Time Standards?' from 'Yes' to 'No' will delete all Event Time Standard Cuts.</p> : ''}
              {modalState.deleteAllEventNoSlowerThanTimeStandardCuts === true ? <p>Changing 'Meet has No Slower Than Time Standards?' from 'Yes' to 'No' will delete all Event No Slower Than Time Standard Cuts.</p> : ''}
              {modalState.deleteAllEventNoFasterThanTimeStandardCuts === true ? <p>Changing 'Meet has No Faster Than Time Standards?' from 'Yes' to 'No' will delete all Event No Faster Than Time Standard Cuts.</p> : ''}
              {modalState.deleteAllEventBonusTimeStandardCuts === true ? <p>Changing 'Meet has Bonus Time Standards?' from 'Yes' to 'No' will delete all Event Bonus Time Standard Cuts.</p> : ''}
              <p><b>Are you sure you would like to save these changes and make the associated deletes?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onSaveAndDeleteEventTimeStandardCut(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={timeStandardTypeState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeTimeStandardsDetail;