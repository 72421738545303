import { useEffect, useState } from "react";

const INITIAL_STATE = {
  maxSwimmers: '0',
  maxDivers: '0',
  totalParticipants: '0',
  selectedSwimmers: '0',
  selectedDivers: '0',
  totalSelected: '0',
  indEventSwimmersSelected: '0',
  lastFilledIndRowNumber: '0',
  lastFilledRelayRowNumber: '0',
  actualRelayRowsToSeed: '0',
  minRelayRowsToAccept: '0',
  relaySelectedBasedOnRelayOnlyRelayAssignment: '0',
  relaySelectedBasedOnIndEntryRelayAssignment: '0',
  relaySelectedBasedOnRelayOnlyRosterDesignation: '0'
};

const useParticipantSelectionsReadOnlyForm = (selectionsStateObj, selectionsParticipantsStateObj) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (selectionsStateObj && selectionsParticipantsStateObj) {
      setState({
        ...state,
        maxSwimmers: selectionsStateObj.maxSwimmers || '0',
        maxDivers: selectionsStateObj.maxDivers || '0',
        totalParticipants: getTotalParticipants(selectionsStateObj.maxSwimmers, selectionsStateObj.maxDivers),
        actualRelayRowsToSeed: selectionsStateObj.actualRelayRowsToSeed || '0',
        minRelayRowsToAccept: selectionsStateObj.minRelayRowsToAccept || '0',
        selectedSwimmers: selectionsParticipantsStateObj.selectedSwimmers || '0',
        selectedDivers: selectionsParticipantsStateObj.selectedDivers || '0',
        totalSelected: selectionsParticipantsStateObj.totalSelected || '0',
        indEventSwimmersSelected: selectionsParticipantsStateObj.indEventSwimmersSelected || '0',
        lastFilledIndRowNumber: selectionsParticipantsStateObj.lastFilledIndRowNumber || '0',
        lastFilledRelayRowNumber: selectionsParticipantsStateObj.lastFilledRelayRowNumber || '0',
        relaySelectedBasedOnRelayOnlyRelayAssignment: selectionsParticipantsStateObj.relaySelectedBasedOnRelayOnlyRelayAssignment || '0',
        relaySelectedBasedOnIndEntryRelayAssignment: selectionsParticipantsStateObj.relaySelectedBasedOnIndEntryRelayAssignment || '0',
        relaySelectedBasedOnRelayOnlyRosterDesignation: selectionsParticipantsStateObj.relaySelectedBasedOnRelayOnlyRosterDesignation || '0'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsStateObj, selectionsParticipantsStateObj]);

  function getTotalParticipants(maxSwimmers, maxDivers) {
    const swimmers = parseInt(maxSwimmers);
    const divers = parseInt(maxDivers);

    if (!isNaN(swimmers) && !isNaN(divers)) {
      return `${swimmers + divers}`;
    } else {
      return '...';
    }
  };

  return {
    state
  };
};

export default useParticipantSelectionsReadOnlyForm;