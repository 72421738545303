import { useEffect } from 'react';


import useRecordGroupData from '../../state/recordGroups/UseRecordGroupsData';

const useRecordGroupDropdown = (makeGetCall) => {
  const { recordGroupState, setRecordGroupState, getRecordGroups
  } = useRecordGroupData();

  useEffect(() => {
    if (makeGetCall === true) {
      if (recordGroupState.isArrayLoading !== true
        && recordGroupState.isArrayLoaded !== true) {
        const getRecordGroupPromise = getRecordGroups();

        if (getRecordGroupPromise !== null) {
          getRecordGroupPromise.then((newState) => {
            if (newState !== null) {
              setRecordGroupState({
                ...newState,
                options: newState.arrayData.map((x) => { return { id: x.recordGroupId, name: x.groupName } }),
                areOptionsLoaded: true
              });
            }
          }).catch((e) => {
            // console.log(e);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordGroupState, makeGetCall]);

  return {
    recordGroupState
  };
};

export default useRecordGroupDropdown;