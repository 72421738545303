import { useState } from 'react';

import OrgUnitAthleteEntryPurchaseItemData from './OrgUnitAthleteEntryPurchaseItemData';

const useOrgUnitAthleteEntryPurchaseItemData = () => {
  const [orgUnitAthleteEntryPurchaseItemState, setOrgUnitAthleteEntryPurchaseItemState] = useState(OrgUnitAthleteEntryPurchaseItemData.INITIAL_STATE);

  const orgUnitAthleteEntryPurchaseItemsPromise = (orgUnitAthleteEntryId) => {
    return OrgUnitAthleteEntryPurchaseItemData.getOrgUnitAthleteEntryPurchaseItems(orgUnitAthleteEntryId, orgUnitAthleteEntryPurchaseItemState, setOrgUnitAthleteEntryPurchaseItemState);
  };

  return {
    orgUnitAthleteEntryPurchaseItemsPromise
  };
};

export default useOrgUnitAthleteEntryPurchaseItemData;