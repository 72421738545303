/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ meetHost, onView, onEnterOmeHostClicked, onEdit, onDeleteOmeMeetClicked }) => {
  return (
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <th>Meet Name</th>
          <th style={{ minWidth: '180px' }}>Meet Start - End Dates</th>
          <th style={{ minWidth: '305px' }}>Entry Start - End Dates</th>
          <th style={{ minWidth: '180px' }}>Time Zone</th>
          <th style={{ minWidth: '125px' }}>OME Meet Status</th>
          <th style={{ minWidth: '100px' }}></th>
        </tr>
      </thead>
      <tbody>
        {meetHost.map((host, i) => {
          return (
            <tr key={i}>
              <td>{host?.omeMeetId > 0 && host.isReadOnly === true ? <a href='' onClick={(e) => onView(e, host)}>{host.meetName || ''}</a> : <span>{host.meetName || ''}</span>}</td>
              <td>{host?.startDate ? formatDate(host?.startDate) : '?'} - {host?.endDate ? formatDate(host?.endDate) : '?'}</td>
              <td>{host?.entryOpenDate === null
                ? ''
                : formatDateTimeTwelveHourClock(host?.entryOpenDate)} {host?.entryOpenDate === null ? '' : '-'} {host?.entryClosedDate === null ? '' : formatDateTimeTwelveHourClock(host?.entryClosedDate)}</td>
              <td>{host?.timeZone || ''}</td>
              <td>{host?.omeMeetId > 0 ? (host.omeMeetStatus || '') : 'Not Created'}</td>
              <td>
                {host?.omeMeetId > 0
                  ? <span>
                    {host.hostChangesCurrentlyAllowed === true &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, host)}><EditIcon /></button>}
                    {host.hostDeletesCurrentlyAllowed === true &&
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteOmeMeetClicked(e, host)}><DeleteIcon /></button>}
                  </span>
                  :
                  <Fragment>
                    {host?.startDate && (new Date(formatDate(host?.startDate)) > new Date()) ?
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEnterOmeHostClicked(e, host)}> <PlusIcon toolTipText='Create OME Meet' /> </button> : <Fragment />}
                  </Fragment>}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
}

const SmallGrid = ({ meetHost, onEnterOmeHostClicked, onView, onEdit, onDeleteOmeMeetClicked }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {meetHost.map((host, i) => {
      return (
        <div className={global.SmallTableRow} key={i}>
          <div className={global.SmallTableRowHead} key={i}>{host.isReadOnly === true ? <span /> : <span className='hidden-xs'>{host.meetName || ''}</span>}{(host?.hostChangesCurrentlyAllowed === true || host?.hostDeletesCurrentlyAllowed === true) && <span>&nbsp;</span>}
            {host?.omeMeetId > 0
              ? <span>
                {host.isReadOnly === true &&
                  <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onView(e, host)}>View</ActionIntraPageButton></div>}
                {host.hostDeletesCurrentlyAllowed === true &&
                  <button className={global.IconButton} type="button" onClick={(e) => onDeleteOmeMeetClicked(e, host)}><DeleteIcon /></button>}
                {host.hostChangesCurrentlyAllowed === true &&
                  <Fragment>
                    {host.hostDeletesCurrentlyAllowed === true ?
                      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, host)}><EditIcon /></button>
                      :
                      <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, host)}><EditIcon /></button>}
                  </Fragment>}
              </span>
              :
              <Fragment>
                {host?.startDate && (new Date(formatDate(host?.startDate)) > new Date()) ?
                  <button className={global.IconButton} type="button" onClick={(e) => onEnterOmeHostClicked(e, host)}> <PlusIcon toolTipText='Create OME Meet' /> </button> : <Fragment />}
              </Fragment>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              {host.isReadOnly === true ?
                <span>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host.meetName || <span>&nbsp;</span>}</div>
                </span> :
                <span>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Meet Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{host.meetName || <span>&nbsp;</span>}</div>
                </span>
              }
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Start Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.startDate ? formatDate(host?.startDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet End Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.endDate ? formatDate(host?.endDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Start Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.entryOpenDate === null ? <span>&nbsp;</span> : formatDateTimeTwelveHourClock(host?.entryOpenDate)}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry End Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.entryClosedDate === null ? <span>&nbsp;</span> : formatDateTimeTwelveHourClock(host?.entryClosedDate)}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Time Zone</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.timeZone || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>OME Meet Status</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{host?.omeMeetId > 0 ? (host.omeMeetStatus || '') : 'Not Created'}</div>
            </div>
          </div>
        </div>)
    })}
  </div>
);

const MeetHostGrid = ({ state, onEnterOmeHostClicked, onView, onEdit, onDeleteOmeMeetClicked }) => {
  if (Array.isArray(state.arrayData) === false || state.arrayData.length === 0) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <p>0 Search Results</p>
          </div>
        </div>
        <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
          <thead>
            <tr>
              <th>Meet Name</th>
              <th>Meet Start - End Dates</th>
              <th>Entry Start - End Dates</th>
              <th>Time Zone</th>
              <th>OME Meet Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6">No Results</td>
            </tr>
          </tbody>
        </table>
        <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          {state.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{state.arrayData.length === 1 ? `${state.arrayData.length} Search Result` : `${state.arrayData.length} Search Results`}</p>}
        </div>
      </div>
      <LargeGrid meetHost={state.arrayData} onEnterOmeHostClicked={onEnterOmeHostClicked} onView={onView} onEdit={onEdit} onDeleteOmeMeetClicked={onDeleteOmeMeetClicked} />
      <SmallGrid meetHost={state.arrayData} onEnterOmeHostClicked={onEnterOmeHostClicked} onView={onView} onEdit={onEdit} onDeleteOmeMeetClicked={onDeleteOmeMeetClicked} />
    </Fragment>
  );
}
export default MeetHostGrid;