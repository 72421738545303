import { Fragment } from "react";

import SelectionMeetEventsGridLarge from "./SelectionMeetEventsGridLarge";

import global from '../../../../../common/components/GlobalStyle.module.css';

const SelectionMeetEventsGrid = ({ gridData, isLoading, showError, errorText, onRowChange }) => (
  <Fragment>
    <SelectionMeetEventsGridLarge gridData={gridData} isLoading={isLoading} onRowChange={onRowChange} />
    {showError === true &&
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-10">
          <p className={global.ErrorMessage}>{errorText}</p>
        </div>
      </div>
    }
  </Fragment>
);

export default SelectionMeetEventsGrid;