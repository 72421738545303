import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData from './GetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData';
import postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData from './PostOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData = {
  INITIAL_STATE, getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData, postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData
}

export default OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData;