import React, { Fragment } from 'react';

import OrgUnitAdminContactsWrite from './OrgUnitAdminContactsWrite';
import OrgUnitAdminContactsReadOnly from './OrgUnitAdminContactsReadOnly';

import useOrgUnitAdminContacts from './UseOrgUnitAdminContacts';

const OrgUnitAdminContacts = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminContacts();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminContactsWrite /> :
        <OrgUnitAdminContactsReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminContacts;