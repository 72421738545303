import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';

const GetMeetDistinctEvents = (meetId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const meetIdForUrl = meetId ? encodeURIComponent(meetId) : 'NaN';
    const url = `/meet/${meetIdForUrl}/event`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            arrayAsOptions: arrayData.map(x => { return { id: x.eventId, name: x.eventCourse } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetMeetDistinctEvents;

