import { useContext } from 'react';

import { RecordListStateContext } from './RecordListContextProvider';

import RecordListData from './RecordListData';

const useRecordListData = () => {
  const [recordListState, setRecordListState] = useContext(RecordListStateContext);

  const getRecordList = (isTopInfoLaneSheet, isRightInfoLaneSheet) => {
    RecordListData.getRecordList(isTopInfoLaneSheet, isRightInfoLaneSheet, recordListState, setRecordListState);
  };

  return {
    getRecordList,
    recordListState,
    setRecordListState
  };
};

export default useRecordListData;