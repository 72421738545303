import useLaneSheetHeatAthletesForm from "./UseLaneSheetHeatAthletesForm";

import LaneSheetHeatAthleteFormGrid from "../grids/LaneSheetHeatAthleteFormGrid";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const LaneSheetHeatAthletesForm = ({ primaryButtonText = 'Save', secondaryButtonText = 'Cancel', onSubmitFormCallback,
  onEditAthlete, onSecondaryButtonClicked, numberOfLanes, startingLaneNumber, meetEventAthletes, heatAthletes }) => {
  const {
    formState,
    //errorState,
    handleSubmit,
    onValueTextPairChanged,
    options
  } = useLaneSheetHeatAthletesForm(onSubmitFormCallback, numberOfLanes, meetEventAthletes, heatAthletes);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <LaneSheetHeatAthleteFormGrid
        numberOfLanes={numberOfLanes}
        startingLaneNumber={startingLaneNumber}
        formState={formState}
        options={options}
        onValueTextPairChanged={onValueTextPairChanged}
        onEditAthlete={onEditAthlete} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetHeatAthletesForm;