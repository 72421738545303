import React, { Fragment } from 'react';

import OrgUnitAdminPurchasesWrite from './OrgUnitAdminPurchasesWrite';
import OrgUnitAdminPurchasesReadOnly from './OrgUnitAdminPurchasesReadOnly';

import useOrgUnitAdminPurchases from './UseOrgUnitAdminPurchases';

const OrgUnitAdminPurchases = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminPurchases();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminPurchasesWrite /> :
        <OrgUnitAdminPurchasesReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminPurchases;