import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const getLaneSheetMedals = (state, setState) => {
  const api = initApi(API_NAMES.MEET, state, setState);
  const url = `/MeetClassification/Flat?trackMedals=${true}`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const LaneSheetMedalsData = {
  getLaneSheetMedals
};

export default LaneSheetMedalsData;