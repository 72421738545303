import React, { createContext, useState } from 'react';

import RelayAthleteEntryTimeData from './RelayAthleteEntryTimeData';

export const RelayAthleteEntryTimeStateContext = createContext();

const RelayAthleteEntryTimeContextProvider = ({ children }) => {
  const stateHook = useState(RelayAthleteEntryTimeData.INITIAL_STATE);

  return (
    <RelayAthleteEntryTimeStateContext.Provider value={stateHook}>
      {children}
    </RelayAthleteEntryTimeStateContext.Provider>
  );
};

export default RelayAthleteEntryTimeContextProvider;