import { useEffect, useState } from 'react';
import useEventCompetitionGenderTypeData from '../../../state/eventCompetitionGender/UseEventCompetitionGenderTypeData';

const useCompetitionCategoryMultiSelect = () => {
  const { eventCompetitionGenderState, getEventCompetitionGenderTypes } = useEventCompetitionGenderTypeData();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (eventCompetitionGenderState.isArrayLoading === false && eventCompetitionGenderState.isArrayLoaded === false) {
      getEventCompetitionGenderTypes();
    } else if (eventCompetitionGenderState.isArrayLoading === false && eventCompetitionGenderState.isArrayLoaded === true) {
      const competitionCategoryOptions = [];
      for (const competitionCategory of eventCompetitionGenderState.arrayData) {
        if (competitionCategory.eventCompetitionGenderTypeId > 0) {
          competitionCategoryOptions.push({ id: competitionCategory.eventCompetitionGenderTypeId, name: competitionCategory.typeName });
        }
      }

      setOptions(competitionCategoryOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCompetitionGenderState]);

  return {
    eventCompetitionGenderState,
    options
  };
};

export default useCompetitionCategoryMultiSelect;