import { useEffect, useState } from "react";

import useCompetitionGenderTypeData from "../../../state/competitionGender/UseCompetitionGenderTypeData";

const useCompetitionCategoryRadiobuttonList = () => {
  const [state, setState] = useState({ radiobuttonlist: [] });
  const { competitionGenderState, getCompetitionGenderTypes } = useCompetitionGenderTypeData();

  function getCompetitionGenderArray() {
    const createCompetitionGenderArray = competitionGenderState.arrayData.map((compGender) => {
      return {
        label: compGender.typeName,
        value: compGender.competitionGenderTypeId,
        id: compGender.competitionGenderTypeId
      };
    });
    const competitionCategoryArrayWithoutUnknown = createCompetitionGenderArray.filter(x => x.label !== 'Unknown')

    setState({
      ...state,
      radiobuttonlist: competitionCategoryArrayWithoutUnknown
    });
    return createCompetitionGenderArray;
  }

  useEffect(() => {
    if (competitionGenderState.isArrayLoaded !== true && competitionGenderState.isArrayLoading !== true) {
      getCompetitionGenderTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionGenderState]);

  useEffect(() => {
    if (competitionGenderState.arrayData.length > 0) {
      getCompetitionGenderArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionGenderState.arrayData]);

  return { state };
};

export default useCompetitionCategoryRadiobuttonList;