import React, { createContext, useState } from 'react';

import OrgRoleData from './OrgRoleData';

export const OrgRoleStateContext = createContext();

const OrgRoleContextProvider = ({ children }) => {
  const stateHook = useState(OrgRoleData.INITIAL_STATE);

  return (
    <OrgRoleStateContext.Provider value={stateHook}>
      {children}
    </OrgRoleStateContext.Provider>
  );
};

export default OrgRoleContextProvider;