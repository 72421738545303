import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetAthleteEntryData from '../../../state/omeMeetAthleteEntry/UseOmeMeetAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../../common/state/searchMeet/UseSearchMeetData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Athlete Entry?',
  meetHost: '',
  meetName: '',
  meetDates: '',
  athleteName: '',
  athleteCompetitionCategory: '',
  athleteAgeAtMeet: '',
  athleteBirthDate: '',
  athleteEntryStatus: '',
  orgUnitName: '',
  orgUnitAthleteEntryId: Constants.DEFAULT_ID
};

const useMeetAthleteEntry = () => {
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { omeMeetState, setOmeMeetState } = useOmeMeetData();
  const { omeMeetAthleteEntryState, searchMeetAthleteEntry, clearOmeMeetAthleteEntryArrayData } = useOmeMeetAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryState, postOrgUnitAthleteEntry, deleteOrgUnitAthleteEntry } = useOmeMeetOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const [state, setState] = useState({ tryGet: false, tryGetAfterCreate: false, tryRedirect: false, athleteEntry: {}, meetInfo: {}, teamEntry: {} });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [gridState, setGridState] = useState(getInitialGridState);

  const onCreateEntryClicked = (e, athleteEntry, meetInfo, teamEntry) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    postOrgUnitAthleteEntry(teamEntry.omeMeetOrgUnitEntryId, athleteEntry.personId);
    setState({ ...state, tryGetAfterCreate: true, athleteEntry: athleteEntry, meetInfo: meetInfo, teamEntry: teamEntry });
  };

  const onEditEntryClicked = (e, athleteEntry, meetInfo, teamEntry) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setOmeMeetState({ ...omeMeetState, route: 'meet' });
    getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(athleteEntry.orgUnitAthleteEntryId, teamEntry.omeMeetOrgUnitEntryId);

    setState({ ...state, tryRedirect: true, athleteEntry: athleteEntry, meetInfo: meetInfo, teamEntry: teamEntry });
  };

  const onDeleteEntryClicked = (e, athlete, meetInfo, teamEntry) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      displayPopUp: true,
      meetHost: meetInfo?.meetHost || '',
      meetName: meetInfo?.meetName || '',
      meetDates: `${meetInfo?.startDate ? formatDate(meetInfo?.startDate) : ''} - ${meetInfo?.endDate ? formatDate(meetInfo?.endDate) : ''}`,
      athleteName: (athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : '',
      athleteCompetitionCategory: athlete.competitionGenderTypeName || '',
      athleteAgeAtMeet: athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : '',
      athleteBirthDate: athlete.birthDate ? formatDate(athlete.birthDate) : '',
      athleteEntryStatus: athlete?.status || '',
      orgUnitName: `${teamEntry.orgUnitName || ''} ${teamEntry.orgUnitName === 'Unattached' ? teamEntry.parentOrgUnitName ? '- ' + teamEntry.parentOrgUnitName : '' : ''} ${teamEntry.parentOrgUnitCode ? '(' + teamEntry.parentOrgUnitCode + ')' : ''}`,
      orgUnitAthleteEntryId: athlete?.orgUnitAthleteEntryId || Constants.DEFAULT_ID
    });
  };

  const onDeleteAthleteEntry = (e, orgUnitAthleteEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOrgUnitAthleteEntry(orgUnitAthleteEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      searchMeetAthleteEntry({ meetId: meetState.objData.meetId }, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded]);

  useEffect(() => {
    if (omeMeetAthleteEntryState.isArrayLoaded === true) {
      setGridState({
        ...gridState,
        arrayData: omeMeetAthleteEntryState.arrayData,
        isSaving: false,
        isSaved: false,
        isArrayLoading: false,
        isArrayLoaded: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetAthleteEntryState.isArrayLoaded]);

  useEffect(() => {
    if (state.tryGet === true && omeMeetOrgUnitAthleteEntryState.isSaving === false) {
      setGridState({
        ...gridState,
        arrayData: [],
        isSaving: false,
        isSaved: false,
        isArrayLoading: false,
        isArrayLoaded: true
      });
      clearOmeMeetAthleteEntryArrayData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, omeMeetOrgUnitAthleteEntryState.isSaving]);

  useEffect(() => {
    if (state.tryGet === true && omeMeetAthleteEntryState.isArrayLoading === false &&
      omeMeetAthleteEntryState.isArrayLoaded === false) {
      setState({ ...state, tryGet: false });
      searchMeetAthleteEntry({ meetId: meetState.objData.meetId }, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, omeMeetAthleteEntryState]);

  useEffect(() => {
    if (state.tryGetAfterCreate === true && state.athleteEntry && Object.keys(state.athleteEntry).length > 0 && state.meetInfo &&
      Object.keys(state.meetInfo)?.length > 0 && state.teamEntry && Object.keys(state.teamEntry)?.length > 0 && omeMeetOrgUnitAthleteEntryState.isSaving === false) {
      setOmeMeetState({ ...omeMeetState, route: 'meet' });
      getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(omeMeetOrgUnitAthleteEntryState.objData.orgUnitAthleteEntryId, state.teamEntry.omeMeetOrgUnitEntryId);
      setState({ ...state, athleteEntry: omeMeetOrgUnitAthleteEntryState.objData, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryState, state.tryGetAfterCreate, state.athleteEntry, state.meetInfo, state.teamEntry]);

  useEffect(() => {
    if (state.tryRedirect === true && state.meetInfo && Object.keys(state.meetInfo).length > 0 &&
      state.teamEntry && Object.keys(state.teamEntry).length > 0 && omeMeetOrgUnitAthleteEntryState.isSaving === false
      && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoading === false) {
      setSearchMeetState({ ...searchMeetState, showMeetHeader: false });
      navigate(NavLinks.ATHLETE_ENTRIES, { state: { orgUnitRegistrationStartDate: state.teamEntry?.orgUnitRegistrationStartDate || '', orgUnitRegistrationEndDate: state.teamEntry?.orgUnitRegistrationEndDate || '', orgUnitName: `${state.teamEntry?.orgUnitName || ''} ${state.teamEntry?.orgUnitName === 'Unattached' ? state.teamEntry?.parentOrgUnitName ? '- ' + state.teamEntry?.parentOrgUnitName : '' : ''}`, } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, state.meetInfo, state.teamEntry, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState]);

  return {
    gridState,
    modalState,
    omeMeetAthleteEntryState,
    omeMeetOrgUnitAthleteEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    onCreateEntryClicked,
    onEditEntryClicked,
    onDeleteEntryClicked,
    onDeleteAthleteEntry,
    onModalCanceled
  }
}

export default useMeetAthleteEntry;

function getInitialGridState() {
  return {
    arrayData: [],
    isSaving: false,
    isSaved: false,
    isArrayLoading: false,
    isArrayLoaded: false
  };
};