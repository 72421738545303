import { useContext, useEffect } from 'react';

import { OrganizationStateContext } from './OrganizationContextProvider';
import OrganizationData, { defaultOrganizationState } from './OrganizationData';

const useOrganizationData = () => {
  const [organizationState, setOrganizationState] = useContext(OrganizationStateContext);
  const getOrganizations = () => { OrganizationData.getOrganizations(organizationState, setOrganizationState); }

  //TODO:  useEffects should not be used at the data hook level.  Move this down to the code behind hooks to prevent excessive calls.
  useEffect(() => {
    if (organizationState.isLoading !== true && organizationState.isLoaded !== true) {
      getOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    organizationState,
    getOrganizations,
    defaultOrganizationState: { ...defaultOrganizationState, orgUnitId: '', orgUnitName: '' }
  };
};

export default useOrganizationData;