import { useState, useEffect } from 'react';

import FacilitiesByOrgUnitData from './FacilitiesByOrgUnitData';

const useFacilitiesByOrgUnitData = (orgUnitId) => {
  const [facilityComboboxState, setFacilityComboboxState] = useState(FacilitiesByOrgUnitData.INITIAL_STATE);
  const getFacilitiesByOrgUnit = (orgUnitId) => FacilitiesByOrgUnitData.getFacilitiesByOrgUnitData(orgUnitId, facilityComboboxState, setFacilityComboboxState);

  useEffect(() => {
    if (orgUnitId && facilityComboboxState.orgUnitId !== orgUnitId) {
      getFacilitiesByOrgUnit(orgUnitId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, facilityComboboxState.orgUnitId, facilityComboboxState.isLoaded]);

  return {
    facilityComboboxState,
    getFacilitiesByOrgUnit
  };
};

export default useFacilitiesByOrgUnitData;