import { useState, useEffect } from "react";

import { PARTICIPANT_SELECTIONS_NAME } from "../leftNav/SelectionsLeftNavConstants";

import { isParticipantSelectionsValid } from "../../utils/SelectionsUtils";

import useSelectionsData from "../../../../state/selections/UseSelectionsData";
import useSelectionsActionsData from "../../../../state/selectionsActions/UseSelectionsActionsData";
import useSelectionsParticipantsData from "../../../../state/selectionsParticipants/UseSelectionsParticipantsData";
import useSelectionsLeftNavData from "../../../../state/selectionsLeftNav/UseSelectionsLeftNavData";
import useSelectionsLoadingModalData from "../../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";

import { compareNumerical } from "../../../../../common/utils/SortingUtils";

const useSelectionActions = () => {
  const { updateCurrentLeftNavData } = useSelectionsLeftNavData();
  const { selectionsState } = useSelectionsData();
  const { postSelectionRule } = useSelectionsParticipantsData();
  const { selectionsActionsState, getSelectionActions } = useSelectionsActionsData();
  const { setContextStateError } = useSelectionsLoadingModalData();
  const [actionsState, setActionsState] = useState([]);

  const onActionClicked = (shortActionName) => {
    const selectionMeetId = selectionsState.objData?.selectionMeetId;

    if (selectionMeetId > 0 && shortActionName) {
      const postSelectionRulePromise = postSelectionRule(selectionMeetId, shortActionName);

      if (postSelectionRulePromise !== null) {
        postSelectionRulePromise.then((newState) => {
          if (newState !== null) {
            const maxSwimmers = selectionsState.objData?.maxSwimmers;
            const selectedSwimmers = newState.objData?.selectedSwimmers;

            if (isParticipantSelectionsValid(selectedSwimmers, maxSwimmers)) {
              updateCurrentLeftNavData(PARTICIPANT_SELECTIONS_NAME, true);
            } else {
              updateCurrentLeftNavData(PARTICIPANT_SELECTIONS_NAME, false);
            }
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
    }
  };

  useEffect(() => {
    if (selectionsActionsState.isArrayLoaded !== true) {
      const selectionMeetId = selectionsState.objData?.selectionMeetId;

      if (selectionMeetId > 0) {
        const getSelectionActionsPromise = getSelectionActions(selectionMeetId);

        if (getSelectionActionsPromise !== null) {
          getSelectionActionsPromise.then((newState) => {
            if (newState !== null) {
              const actions = newState.arrayData;

              setActionsState(actions.sort((a, b) => compareNumerical(a.rowNumber, b.rowNumber) * -1));
            }
          }).catch((e) => {
            setContextStateError(true);
          });
        }
      }
    } else if (selectionsActionsState.isArrayLoaded === true) {
      const actions = selectionsActionsState.arrayData;

      setActionsState(actions.sort((a, b) => compareNumerical(a.rowNumber, b.rowNumber) * -1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState, selectionsActionsState.isArrayLoaded]);

  return {
    actions: actionsState,
    onActionClicked
  };
};

export default useSelectionActions;