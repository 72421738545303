/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ReadOnlyGridRow = ({ timeStandard }) => (
  <tr>
    <td>{timeStandard.timeStandardName || ''}</td>
    <td>{timeStandard.sortPriority || ''}</td>
    <td>{timeStandard.isIndividualBaseStandard === true ? 'Yes' : 'No'}</td>
    <td>{timeStandard.isRelayBaseStandard === true ? 'Yes' : 'No'}</td>
  </tr>
);

const SelectionMeetTimeStandardReadOnlyGridLarge = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Time Standard</th>
        <th>Priority</th>
        <th>Is Individual Base Time Standard?</th>
        <th>Is Relay Base Time Standard?</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((timeStandard, i) => <ReadOnlyGridRow key={i} timeStandard={timeStandard} />)
          : <tr><td colSpan="4">No Time Standards</td></tr>
      }
    </tbody>
  </table>
);

export default SelectionMeetTimeStandardReadOnlyGridLarge;
