import React from 'react';

import useSelectionsLoadingModal from './UseSelectionsLoadingModal';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../../common/utils/Constants';

const SelectionsLoadingModal = ({ isLoading }) => {
  const {
    selectionMeetId,
    isContextError,
    onReloadContextClicked,
    onBackToSearchClicked
  } = useSelectionsLoadingModal();

  if (isContextError === true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title='Error'
        displayPopUp={true}
        onModalCanceled={onBackToSearchClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>An error has occurred, would you like to reload the selection?</p>
            <p><b>SelectionMeetId:</b> {selectionMeetId}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onReloadContextClicked}>Yes, Reload Selection</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackToSearchClicked}>Exit Selection</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    );
  } else {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading === true} />
    );
  }
};

export default SelectionsLoadingModal;