import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import Meet from './views/meet/Meet';
import EntryContextStateAndRoutes from './views/entry/EntryContextStateAndRoutes';
import HostContextStateAndRoutes from './views/host/HostContextStateAndRoutes';
import MyMeetsContextStateAndRoutes from './views/myMeets/MyMeetsContextStateAndRoutes';
import MyAthleteEntryContextStateAndRoutes from './views/myAthleteEntry/MyAthleteEntryContextStateAndRoutes';
import OmeLoading from './views/omeLoading/OmeLoading';
import OmeMeetTemplate from './views/omeMeetTemplate/OmeMeetTemplate';
import OmeMeetTemplateSearch from './views/omeMeetTemplateSearch/OmeMeetTemplateSearch';
import Selections from './views/selections/Selections';
import LaneSheet from './views/laneSheet/LaneSheet';
import AthleteRaceStats from './views/myAthleteRaceStats/AthleteRaceStats';
import AthleteRaceStatsHQ from './views/athleteRaceStats/AthleteRaceStats';

import MeetSessionContextProvider from './state/meetSession/MeetSessionContextProvider';

import MyAthleteMeetEntriesFiltersContextProvider from './state/myAthleteMeetEntriesFilters/MyAthleteMeetEntriesFiltersContextProvider';
import MyMeetsFiltersContextProvider from './state/myMeetsFilters/MyMeetsFiltersContextProvider';
import SelectOrgUnitContextProvider from '../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import MeetContextProvider from '../common/state/meet/MeetContextProvider';
import MeetFiltersContextProvider from '../common/state/meet/MeetFiltersContextProvider';
import SearchMeetContextProvider from '../common/state/searchMeet/SearchMeetContextProvider';
import OmeMeetContextProvider from './state/omeMeet/OmeMeetContextProvider';
import OmeMeetInvitationsContextProvider from './state/omeMeetInvitations/OmeMeetInvitationsContextProvider';

import useNavRoutes from '../common/state/security/UseNavRoutes';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import LoadingModal from '../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';


const MeetTemplate = ({ navRoutes }) => (
  <MeetContextProvider>
    <MeetFiltersContextProvider>
      <SearchMeetContextProvider>
        <OmeMeetContextProvider>
          <OmeMeetInvitationsContextProvider>
            <MeetSessionContextProvider>
              <Routes>
                <Route path={navRoutes['OME_MEET_TEMPLATE_LANDING']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['OME_MEET_TEMPLATE_LANDING']}
                      editElement={<OmeMeetTemplate />} />
                  }
                />
                <Route path={navRoutes['OME_MEET_TEMPLATE_SEARCH']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['OME_MEET_TEMPLATE_SEARCH']}
                      editElement={<OmeMeetTemplateSearch />} />
                  }
                />
              </Routes>
            </MeetSessionContextProvider>
          </OmeMeetInvitationsContextProvider>
        </OmeMeetContextProvider>
      </SearchMeetContextProvider>
    </MeetFiltersContextProvider>
  </MeetContextProvider>
);

const Ome = ({ navRoutes }) => (
  <MyAthleteMeetEntriesFiltersContextProvider>
    <MyMeetsFiltersContextProvider>
      <SelectOrgUnitContextProvider>
        <Routes>
          <Route path={navRoutes['OME_ENTRY']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OME_ENTRY']}
                editElement={<EntryContextStateAndRoutes />} />
            }
          />
          <Route path={navRoutes['OME_HOST']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OME_HOST']}
                editElement={<HostContextStateAndRoutes />} />
            }
          />
          <Route path={navRoutes['OME_LOADING']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OME_LOADING']}
                editElement={<OmeLoading />} />
            }
          />
          <Route path={navRoutes['OME_MEET_TEMPLATE_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OME_MEET_TEMPLATE_ROOT']}
                editElement={<MeetTemplate navRoutes={navRoutes} />} />
            }
          />
        </Routes>
      </SelectOrgUnitContextProvider>
    </MyMeetsFiltersContextProvider>
  </MyAthleteMeetEntriesFiltersContextProvider>
);

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes['HOME']?.path} element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes['MEET_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEET_ROOT']}
                editElement={<Meet />} />
            }
          />
          <Route path={navRoutes['MY_MEETS_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MY_MEETS_ROOT']}
                editElement={<MyMeetsContextStateAndRoutes />} />
            }
          />
          <Route path={navRoutes['OME_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['OME_ROOT']}
                editElement={<Ome navRoutes={navRoutes} />} />
            }
          />
          <Route path={navRoutes['SELECTIONS_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['SELECTIONS_ROOT']}
                editElement={<Selections />} />
            }
          />
          <Route path={navRoutes['MY_ATHLETE_ENTRY']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MY_ATHLETE_ENTRY']}
                editElement={<MyAthleteEntryContextStateAndRoutes />} />
            }
          />
          <Route path={navRoutes['LANE_SHEET_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['LANE_SHEET_ROOT']}
                editElement={<LaneSheet />} />
            }
          />

          <Route path={navRoutes['MY_ATHLETE_RACE_STATS']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MY_ATHLETE_RACE_STATS']}
                editElement={<AthleteRaceStats />} />
            }
          />

          <Route path={navRoutes['ATHLETE_RACE_STATS']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['ATHLETE_RACE_STATS']}
                editElement={<AthleteRaceStatsHQ />} />
            }
          />

          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;