import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import getInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import {
  eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete, restrictionsIsComplete,
  sessionsIsComplete, ageGroupsIsComplete, timeStandardsIsComplete
} from './LeftNavValidation';

const getTimeStandards = (viewName, route, omeMeetStateObjData, omeMeetInvitationsStateArrayData, meetStateObjData, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = viewName === Views.OME_MEET_TIME_STANDARDS;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);
  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);
  const isRestrictionsComplete = restrictionsIsComplete(omeMeetStateObjData, restrictionsRoutePermission);
  const isInvitationsComplete = invitationsIsComplete(omeMeetInvitationsStateArrayData);
  const isSessionsComplete = sessionsIsComplete(meetStateObjData);
  const isAgeGroupsComplete = ageGroupsIsComplete(omeMeetStateObjData);

  const isComplete = timeStandardsIsComplete(omeMeetStateObjData) === true && isMeetInfoComplete === true && isEligibilityComplete === true && isAgeGroupsComplete === true &&
    isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true;

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true
    && isRestrictionsComplete === true && isInvitationsComplete === true && isSessionsComplete === true
    && isAgeGroupsComplete === true;

  const item = route === '' ? getInitialStateOmeRoute(Views.OME_MEET_TIME_STANDARDS) : getInitialStateMeetRoute(Views.OME_MEET_TIME_STANDARDS);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getTimeStandards;