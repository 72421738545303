import React, { createContext, useState } from 'react';

import OmeTimeStandardData from './OmeTimeStandardData';

export const OmeTimeStandardStateContext = createContext();

const OmeTimeStandardContextProvider = ({ children }) => {
  const stateHook = useState(OmeTimeStandardData.INITIAL_STATE);

  return (
    <OmeTimeStandardStateContext.Provider value={stateHook}>
      {children}
    </OmeTimeStandardStateContext.Provider>
  );
};

export default OmeTimeStandardContextProvider;