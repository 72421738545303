import { useContext } from 'react';

import { SelectionsUnavailableReasonsStateContext } from './SelectionsUnavailableReasonsContextProvider';

import SelectionsUnavailableReasonsData from './SelectionsUnavailableReasonsData';

const useSelectionsUnavailableReasonsData = () => {
  const [selectionsUnavailableReasonsState, setSelectionsUnavailableReasonsState] = useContext(SelectionsUnavailableReasonsStateContext);

  const getSelectionsUnavailableReasons = () => {
    return SelectionsUnavailableReasonsData.getSelectionsUnavailableReasonsData(selectionsUnavailableReasonsState, setSelectionsUnavailableReasonsState);
  };

  return {
    selectionsUnavailableReasonsState,
    setSelectionsUnavailableReasonsState,
    getSelectionsUnavailableReasons
  };
};

export default useSelectionsUnavailableReasonsData;