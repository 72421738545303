import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryIdData from './GetOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryIdData';
import postOrgUnitAthleteEntryPurchaseData from './PostOrgUnitAthleteEntryPurchaseData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OrgUnitAthleteEntryPurchaseData = {
  INITIAL_STATE, getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryIdData, 
  postOrgUnitAthleteEntryPurchaseData
};

export default OrgUnitAthleteEntryPurchaseData;