import { useState } from 'react';

import validate from './SearchEntryContactPopupValidation';

import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';
import useMemberLookupData from '../../../state/memberLookup/UseMemberLookupData';

import UseForm from '../../../utils/UseForm';

const INITIAL_STATE = {
  displayPopup: false
};

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: ''
};

const useSearchEntryContactPopup = (viewFormState, setViewFormData, memberIdName, orgUnitId, organizationName) => {
  const { USAS_ORGANIZATION_NAME, FINA_ORGANIZATION_NAME, NCAA_ORGANIZATION_NAME, ISL_ORGANIZATION_FULL_NAME,
    CLUB_STAFF_ROLE_GROUP_NAME, FINA_STAFF_ROLE_GROUP_NAME, NCAA_STAFF_ROLE_GROUP_NAME, ISL_STAFF_ROLE_GROUP_NAME
  } = useEnvironmentVariableData();
  const { memberLookupState, getMemberLookupByOrgUnit } = useMemberLookupData();
  const { formState, errorState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onDisplayPopupClicked = () => {
    setState({ ...state, displayPopup: true });
  };

  const onHidePopupClicked = () => {
    setState({ ...state, displayPopup: false });
  };

  const onSelectPerson = (e, memberId) => {
    e?.preventDefault();

    setViewFormData({ ...viewFormState, [memberIdName]: memberId }, true);
    setState({ ...state, displayPopup: false });
  };

  function onSubmitFormCallback(formState) {
    let roleGroupName = '';

    if (organizationName === USAS_ORGANIZATION_NAME) {
      roleGroupName = CLUB_STAFF_ROLE_GROUP_NAME;
    } else if (organizationName === FINA_ORGANIZATION_NAME) {
      roleGroupName = FINA_STAFF_ROLE_GROUP_NAME;
    } else if (organizationName === NCAA_ORGANIZATION_NAME) {
      roleGroupName = NCAA_STAFF_ROLE_GROUP_NAME;
    } else if (organizationName === ISL_ORGANIZATION_FULL_NAME) {
      roleGroupName = ISL_STAFF_ROLE_GROUP_NAME;
    }

    getMemberLookupByOrgUnit(formState.firstName, formState.lastName, orgUnitId, roleGroupName);
  }

  return {
    state,
    memberLookupState,
    popupFormState: formState,
    popupErrorState: errorState,
    updatePopupFormState: updateFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectPerson
  };
};

export default useSearchEntryContactPopup;