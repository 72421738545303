import React, { Fragment } from 'react';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import OrgUnitAdminRelayEntriesLargeGrid from './OrgUnitAdminRelayEntriesLargeGrid';
import OrgUnitAdminRelayEntriesSmallGrid from './OrgUnitAdminRelayEntriesSmallGrid';

const OrgUnitAdminRelayEntriesGrid = ({ gridData, isLoading, onEditEventClicked }) => {
  const { onClick, expandedId } = useExpandedRow();
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            <p>{gridData.length === 1 ? `${gridData.length} Relay Event` : `${gridData.length} Relay Events`}</p>
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <OrgUnitAdminRelayEntriesLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditEventClicked={onEditEventClicked}
        onClick={onClick}
        expandedId={expandedId}
      />
      <OrgUnitAdminRelayEntriesSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditEventClicked={onEditEventClicked}
        onClick={onClick}
        expandedId={expandedId}
      />
    </Fragment>
  )
};

export default OrgUnitAdminRelayEntriesGrid;