import React, { Fragment } from 'react';

import MeetUploadErrorTankLargeGrid from './MeetUploadErrorTankLargeGrid';
import MeetUploadErrorTankSmallGrid from './MeetUploadErrorTankSmallGrid';

const MeetTimesGrid = ({ gridData, isLoading, onMatchCheckbox }) => {
  return (
    <Fragment>
      <MeetUploadErrorTankLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onMatchCheckbox={onMatchCheckbox}
        />
      <MeetUploadErrorTankSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onMatchCheckbox={onMatchCheckbox} /> 
    </Fragment>
  )
};

export default MeetTimesGrid;