import Constants from "../../../../common/utils/Constants";
import { isValidDate } from "../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  //Meet Name
  if (formState.meetName.trim() === '') {
    errors.meetName = 'Meet Name is required'
  } else if (formState.meetName.trim().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters'
  }

  //Meet Abbreviation
  if (formState.meetAbbreviation.trim().length > 50) {
    errors.meetAbbreviation = 'Meet Abbreviation cannot exceed 50 characters'
  }

  //Host Org Unit
  if (formState.hostOrgUnit.length === 0 || formState.hostOrgUnit[0].id < 1) {
    errors.hostOrgUnit = `Host Organization is required`
  }

  // Meet Course
  if (formState.meetCourse.length === 0) {
    errors.meetCourse = "Must pick at least one Course"
  }

  //Facility
  if (errors.hostOrgUnit === undefined && (formState.facilityId < 0 || formState.facilityName === '')) {
    if (formState.facilityId !== null) {
      errors.facilityId = 'Facility is required';
    }
  }

  //Sanction Number
  if (formState.meetSanctionNumber.trim().length > 20) {
    errors.meetSanctionNumber = 'Sanction Number cannot exceed 20 characters';
  }

  //Start Date
  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  } else if (endDate < startDate) {
    errors.startDate = 'Start Date must be before or on the End Date';
  }

  //End Date
  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  } else if (endDate < startDate) {
    errors.endDate = 'End Date must be on or after the Start Date';
  }

  return errors;
};

const MeetInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MeetInfoValidation;