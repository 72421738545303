import useParticipantGridDetailPopupData from "../../../../state/selectionsParticipants/participantGridDetailPopup/UseParticipantGridDetailPopupData";

const MAX_LAST_NAME_LENGTH = 7;
const MAX_RELAY_TEAM_LENGTH = 10;

const useParticipantRowCell = () => {
  const { onEnterCell, onLeaveCell } = useParticipantGridDetailPopupData();

  const getFormattedName = (firstName, lastName, relayTeamName) => {
    try {
      if (relayTeamName) {
        if (relayTeamName.length > MAX_RELAY_TEAM_LENGTH) {
          const formattedName = `${relayTeamName.slice(0, MAX_RELAY_TEAM_LENGTH - 3)}...`;

          return formattedName;
        } else {
          return `${relayTeamName}`;
        }
      } else {
        const firstInitial = firstName.slice(0, 1);

        if (lastName.length > MAX_LAST_NAME_LENGTH) {
          const formattedLastName = `${lastName.slice(0, MAX_LAST_NAME_LENGTH - 3)}...`;

          return `${firstInitial}. ${formattedLastName}`;
        } else {
          return `${firstInitial}. ${lastName}`;
        }
      }
    } catch {
      return '...';
    }
  };

  return {
    getFormattedName,
    onEnterCell,
    onLeaveCell
  };
};

export default useParticipantRowCell;