import React from 'react';

import useRecordListCombobox from './UseLaneSheetRecordListCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';

import global from '../../../../common/components/GlobalStyle.module.css';

const LaneSheetRecordListCombobox = ({ label, name, valueToMatch, error, message, onChange, isTopInfoLaneSheet = false, isRightInfoLaneSheet = false }) => {
  const { recordListState } = useRecordListCombobox(isTopInfoLaneSheet, isRightInfoLaneSheet);

  return recordListState.message
    ? <span className={global.LoadingMsg}>{recordListState.message}</span>
    :
    <Combobox
      label={label}
      name={name}
      isLoading={recordListState.isArrayLoading}
      items={recordListState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default LaneSheetRecordListCombobox;