import { useEffect, useState } from 'react';

import validate from './SearchMeetValidation';

import useStatesCombobox from '../../comboboxes/statesCombobox/UseStatesCombobox';
import useCountryCombobox from '../../comboboxes/countryCombobox/UseCountryCombobox';

import useMeetData from '../../../state/meet/UseMeetData';
import useSearchMeetData from '../../../state/searchMeet/UseSearchMeetData';
import useOrgUnitData from '../../../state/orgUnit/UseOrgUnitData';
import useMeetClassificationData from '../../../state/meet/meetClassification/UseMeetClassificationData';
import useSecurityData from '../../../state/security/UseSecurityData';

import useForm from '../../../utils/UseForm';
import Constants from '../../../utils/Constants';
import HierarchicalDataUtils from '../../../utils/HierarchicalDataUtils';

const sortByOptions = [
  { id: 1, name: "Meet Name (A-Z)", value: 'MeetName' },
  { id: 2, name: "Meet Date (Oldest to Most Recent)", value: 'StartDate' }];

const useSearchMeet = ({ omeMeetResultsOnly, simplifiedView }) => {
  const { meetState, searchMeets, meetFiltersState } = useMeetData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { orgUnitState, getOrgUnitsTopTwoLevels } = useOrgUnitData();
  const { meetClassificationState } = useMeetClassificationData();
  const { getStateNameById } = useStatesCombobox();
  const { getCountryNameById } = useCountryCombobox();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const { contextSecurityState } = useSecurityData();
  const [tryPreviousSearch, setTryPreviousSearch] = useState(true);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchMeetState({ ...searchMeetState, showFilters: !searchMeetState.showFilters });
    setIsDirty(true);
  }

  const onOrgUnitChanged = (newValue, newValueLabel) => {
    setFormState({
      ...formState,
      orgUnit: newValue,
      meetClassification: []
    });

    setIsDirty(true);
  };

  useEffect(() => {
    getOrgUnitsTopTwoLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Sets initial form state once
  useEffect(() => {
    if (orgUnitState.isArrayLoaded === true && Object.keys(meetFiltersState.filterObject).length === 0) {
      setFormState(getInitialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (Object.keys(meetFiltersState.filterObject).length > 0 && tryPreviousSearch === true
      && orgUnitState.isArrayLoaded === true && meetClassificationState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(meetFiltersState.filterObject));

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (meetFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      if (meetClassificationState.orgUnitId === meetFiltersState.filterObject?.orgUnitId) {
        setIsDirty(true);
        setTryPreviousSearch(false);
        if (simplifiedView === true) {
          setFormState({
            ...formState,
            orgUnitId: undefined,
            orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
            meetName: oldSearchValues.meetName || '',
            startDate: oldSearchValues.startDate || Constants.BLANK_DATE_STRING,
            endDate: oldSearchValues.endDate || Constants.BLANK_DATE_STRING,
            sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
            sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
          });
        } else {
          setFormState({
            ...formState,
            ...oldSearchValues,
            orgUnitId: undefined,
            orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
            meetClassification: HierarchicalDataUtils.GetNameIdPairs(meetClassificationState.treeData, [oldSearchValues.meetClassificationId]) || [],
            stateName: oldSearchValues.stateCode ? getStateNameById(oldSearchValues.stateCode) : '',
            countryName: oldSearchValues.country ? getCountryNameById(oldSearchValues.country) : '',
            sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
            sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
          });
        }

        if (meetState.isArrayLoading === false) {
          if (simplifiedView === true) {
            const filterObject = {
              orgUnitId: oldSearchValues.orgUnitId || undefined,
              meetName: oldSearchValues.meetName || undefined,
              startDate: oldSearchValues.startDate || undefined,
              endDate: oldSearchValues.endDate || undefined,
            }
            searchMeets(filterObject, meetFiltersState.sortBy || '');
          }
          else {
            searchMeets(meetFiltersState.filterObject, meetFiltersState.sortBy || '');
          }
        }
      }
      else {
        setFormState({
          ...formState,
          orgUnitId: undefined,
          orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded, meetClassificationState.isArrayLoaded]);

  useEffect(() => {
    if (searchMeetState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMeetState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchMeetState({ ...searchMeetState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (meetState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchMeets(filterObject, sortBy || '');
    }
  };

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: formState.orgUnit[0].id,
      meetName: formState.meetName.trim() || undefined,
      meetClassificationId: (formState.meetClassification !== undefined && formState.meetClassification.length > 0) ? formState.meetClassification[0].id : undefined,
      courseId: formState.courseId > 0 ? formState.courseId : undefined,
      city: formState.city.trim() || undefined,
      stateCode: formState.stateName !== '' ? formState.stateId : undefined,
      country: formState.countryName !== '' ? formState.country : undefined,
      startDate: formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate: formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : undefined,
      hasOmeMeet: omeMeetResultsOnly === true ? true : undefined
    };
  };

  function getInitialFormState() {
    return {
      orgUnit: orgUnitState.treeData.length > 0
        ? HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [orgUnitState.treeData[0]?.id]) : [],
      meetClassification: [],
      meetName: '',
      courseId: Constants.DEFAULT_ID,
      courseName: '',
      city: '',
      stateId: '',
      stateName: '',
      country: '',
      countryName: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name
    };
  };

  return {
    orgUnitState,
    formState,
    errorState,
    meetState,
    searchMeetState,
    setSearchMeetState,
    contextSecurityState,
    clearForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onOrgUnitChanged,
    handleSubmit,
    setIsDirty,
    onFiltersToggle,
    sortByOptions
  }
}

export default useSearchMeet;