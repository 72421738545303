import MeetUploadErrorTankData from './MeetUploadErrorTankData';

const useMeetUploadErrorTankData = () => {

  const getMeetUploadErrorTank = (meetId, viewState, setViewState) => {
    MeetUploadErrorTankData.getMeetUploadErrorTank(meetId, viewState, setViewState);
  };

  const getBasicMember = (memberId, basicMemberState, setBasicMemberState) => {
    MeetUploadErrorTankData.getBasicMember(memberId, basicMemberState, setBasicMemberState);
  };

  const getMeetPotentialMatchedPersons = (meetId, firstName, lastName, birthDate, lscCode, clubCode, potentialMatchMemberState, setPotentialMatchMemberState) => {
    MeetUploadErrorTankData.getMeetPotentialMatchedPersons(meetId, firstName, lastName, birthDate, lscCode, clubCode, potentialMatchMemberState, setPotentialMatchMemberState)
  }

  const postMeetUploadErrorTankMatch = (meetId, matchingMembersArray, viewState, setViewState) => {
    MeetUploadErrorTankData.postMeetUploadErrorTankMatch(meetId, matchingMembersArray, viewState, setViewState);
  }

  const postMeetUploadErrorTankQuickAdd = (meetId, unmatchedMembersArray, viewState, setViewState) => {
    MeetUploadErrorTankData.postMeetUploadErrorTankQuickAdd(meetId, unmatchedMembersArray, viewState, setViewState);
  }

  const deleteMeetUploadErrorTankSwimmers = (meetId, unmatchedMembersArray, viewState, setViewState) => {
    MeetUploadErrorTankData.deleteMeetUploadErrorTankSwimmers(meetId, unmatchedMembersArray, viewState, setViewState);
  }

  const postMeetUploadErrorTankMatchByMemberId = (meetId, matchingMemberObj, viewState, setViewState) => {
    MeetUploadErrorTankData.postMeetUploadErrorTankMatchByMemberId(meetId, matchingMemberObj, viewState, setViewState);
  }

  return {
    getMeetUploadErrorTank,
    postMeetUploadErrorTankMatch,
    postMeetUploadErrorTankQuickAdd,
    deleteMeetUploadErrorTankSwimmers,
    postMeetUploadErrorTankMatchByMemberId,
    getBasicMember,
    getMeetPotentialMatchedPersons
  }
}

export default useMeetUploadErrorTankData;