import { useEffect, useState } from "react";

import useEventCompetitionGenderTypeData from "../../../state/eventCompetitionGender/UseEventCompetitionGenderTypeData";

const useEventCompetitionCategoryRadiobuttonList = () => {
  const [state, setState] = useState({ radiobuttonlist: [] });
  const { eventCompetitionGenderState, getEventCompetitionGenderTypes } = useEventCompetitionGenderTypeData();

  function getEventCompetitionGenderArray() {
    const createEventCompetitionGenderArray = eventCompetitionGenderState.arrayData.map((eventCompGender) => {
      return {
        label: eventCompGender.typeName,
        value: eventCompGender.eventCompetitionGenderTypeId,
        id: eventCompGender.eventCompetitionGenderTypeId
      };
    });
    setState({
      ...state,
      radiobuttonlist: createEventCompetitionGenderArray
    });
    return createEventCompetitionGenderArray;
  }

  useEffect(() => {
    if (eventCompetitionGenderState.isArrayLoaded !== true && eventCompetitionGenderState.isArrayLoading !== true) {
      getEventCompetitionGenderTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCompetitionGenderState]);

  useEffect(() => {
    if (eventCompetitionGenderState.arrayData.length > 0) {
      getEventCompetitionGenderArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCompetitionGenderState.arrayData]);

  return { state };
};

export default useEventCompetitionCategoryRadiobuttonList;