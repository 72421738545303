import { Fragment } from "react";

import SelectionMeetTimeStandardGridLarge from "./SelectionMeetTimeStandardGridLarge";

import Headings from "../../../../../common/components/headings/Headings";
import ActionIntraPageButton from "../../../../../common/components/buttons/ActionIntraPageButton";

import global from '../../../../../common/components/GlobalStyle.module.css';

const SelectionMeetTimeStandardGrid = ({ gridData, isLoading, showError, errorText, onAddClicked, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <Headings.H5>Time Standards to Achieve</Headings.H5>
      </div>
    </div>
    <div className="row">
      <div className='col-xs-12 usas-extra-top-margin'>
        <ActionIntraPageButton onClick={onAddClicked}>Add Time Standard</ActionIntraPageButton>
      </div>
    </div>
    <SelectionMeetTimeStandardGridLarge gridData={gridData} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    {showError === true &&
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-10">
          <p className={global.ErrorMessage}>{errorText}</p>
        </div>
      </div>
    }
  </Fragment>
);

export default SelectionMeetTimeStandardGrid;