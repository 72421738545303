import { useContext } from 'react';

import { OUAdminEntryContextStateContext } from './OUAdminEntryContextContextProvider';

const useOUAdminEntryContextData = () => {
  const [oUAdminEntryContextState, setOUAdminEntryContextState] = useContext(OUAdminEntryContextStateContext);

  return {
    oUAdminEntryContextState,
    setOUAdminEntryContextState
  };
};

export default useOUAdminEntryContextData;