import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLaneSheetData from '../../../state/laneSheet/UseLaneSheetData';
import useLaneSheetRightSideData from '../../../state/laneSheetRightSide/UseLaneSheetRightSideData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  rightSideInfo: {}
};

const INITIAL_DELETE_RIGHT_SIDE_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetRightSideNotes = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetRightSideState, getLaneSheetRightSide, deleteLaneSheetRightSideInfo } = useLaneSheetRightSideData();
  const [deleteRightSideInfoState, setDeleteRightSideInfoState] = useState(INITIAL_DELETE_RIGHT_SIDE_INFO_STATE);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);

  const onAddRightSideInfoClicked = () => {
    navigate(NavLinks.LANE_SHEET_RIGHT_SIDE_INFO_DETAIL);
  };

  const onDeleteRightSideInfoClicked = (rightSideInfo) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      rightSideInfo
    });
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeleteModalDeleteClicked = () => {
    const laneSheetRightInfoId = deleteModalState.rightSideInfo?.laneSheetRightInfoId;

    if (laneSheetRightInfoId > 0) {
      const deleteLaneSheetRightSideInfoPromise = deleteLaneSheetRightSideInfo(laneSheetRightInfoId, deleteRightSideInfoState, setDeleteRightSideInfoState);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLaneSheetRightSideInfoPromise !== null) {
        deleteLaneSheetRightSideInfoPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

            if (laneSheetMeetId > 0) {
              const getLaneSheetRightSidePromise = getLaneSheetRightSide(laneSheetMeetId);

              if (getLaneSheetRightSidePromise !== null) {
                getLaneSheetRightSidePromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  useEffect(() => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const getLaneSheetRightSidePromise = getLaneSheetRightSide(laneSheetMeetId);

      if (getLaneSheetRightSidePromise !== null) {
        getLaneSheetRightSidePromise.catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetState]);

  return {
    isSaving: deleteRightSideInfoState.isObjLoading,
    isLoading: laneSheetRightSideState.isArrayLoading,
    gridData: laneSheetRightSideState.arrayData || [],
    deleteModalState,
    onAddRightSideInfoClicked,
    onDeleteRightSideInfoClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  };
};

export default useLaneSheetRightSideNotes;