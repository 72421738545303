import React, { Fragment } from 'react';

import useOmeRestrictionsTypeAge from './UseOmeRestrictionsTypeAge';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../../common/components/inputs/Input';

import Constants from '../../../../../common/utils/Constants';

const OmeRestrictionsTypeAge = () => {
  const {
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
  } = useOmeRestrictionsTypeAge();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_RESTRICTIONS_TYPE_AGE}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Min Age*'
                name='minAge'
                type='number'
                min='1'
                value={formState.minAge}
                error={errorState.minAge}
                message={errorState.minAge}
                onChange={(value) => { onFormValueChanged('minAge', value); }} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input
                label='Max Age*'
                name='maxAge'
                type='number'
                min='1'
                value={formState.maxAge}
                error={errorState.maxAge}
                message={errorState.maxAge}
                onChange={(value) => { onFormValueChanged('maxAge', value); }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeRestrictionsTypeAge;