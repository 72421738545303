import { useEffect } from 'react';
import { useNavigate, useParams } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';

const TAXONOMIES = ['MeetDetail', 'OUMeetDetail', 'AthleteMeetDetail'];
const SCOPE = 'Meet';

const useMeetById = () => {
  const navigate = useNavigate();
  const { targetMeetId, pill } = useParams();
  const { meetState, getMeet } = useMeetData();
  const { getContextSecurity, contextSecurityState } = useSecurityData();
  const meetId = ToIntIfInt(targetMeetId);

  //load time
  useEffect(() => {
    if (meetId > 0 && meetState.isObjLoading !== true && meetState.isObjLoaded !== true) {
      getMeet(meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetId, meetState]);

  //load security context
  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      if (meetState.objData?.meetId > 0) {
        if (contextSecurityState.isObjLoading !== true && contextSecurityState.isObjLoaded !== true) {
          getContextSecurity(meetId, TAXONOMIES, SCOPE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState, meetState]);

  //navigate
  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      if (contextSecurityState.isObjLoaded === true) {
        if (contextSecurityState.contextId === meetId && contextSecurityState.scope === 'Meet') {
          if ((pill || '').toLowerCase() === 'times') {
            navigate(NavLinks.MEET_TIMES);
          } else {
            navigate(NavLinks.MEET_INFO);
          }
        } else {
          navigate(NavLinks.MEET_SEARCH);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, contextSecurityState]);

  return {};
};

export default useMeetById;
