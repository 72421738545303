import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import StopwatchIcon from '../../../../../common/components/icons/StopwatchIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const SmallDetailTableRow = ({ event }) => {
  return (
    <Fragment>
      <div className={global.SmallDetailTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>{event.eventName}</span>&nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Event-Course</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{event.eventName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-xs'].join(' ')}>Competition Category</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Comp. Category</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.eventCompetitionGenderTypeName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age Group</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.ageGroupName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Session</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.meetSessionName || <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Time</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.entryTime ? formatTimeForDisplay(event.entryTime) : 'NT'} {event.entryTime ? event.qualifyingCourseCode || <span>&nbsp;</span> : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Time Event</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.isAlternateEvent === true ? `ALT: ${event.qualifyingEventName || ''}` : event.isNonConformingTime === true ? `NC: ${event.qualifyingEventName || ''}` : <span>{event.qualifyingEventName || <span>&nbsp;</span>}</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Bonus?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.qualifiedAsBonus === true ? 'Yes' : event.qualifiedAsBonus === false ? 'No' : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Override?</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.swimTimeEntryOverride === true ? 'Yes' : event.swimTimeEntryOverride === false ? 'No' : <span>&nbsp;</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{event.status || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const SmallDetailTable = ({ athlete }) => {
  return (
    <div className={global.SmallDetailTable}>
      {Array.isArray(athlete.events) && athlete?.events.length > 0 &&
        athlete.events.map((event, i) =>
          <SmallDetailTableRow
            event={event}
            key={i}
          />)}
    </div>
  );
};

const OrgUnitAdminRosterEntriesSmallGrid = ({ gridData, isLoading, onEditAthleteClicked, expandedId, onClick, onUpdateAthleteTimes }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((athlete, i) => (
          <Fragment key={athlete.orgUnitAthleteEntryId || i + 'Fragment'}>
            <div className={global.SmallTableRow} key={athlete.orgUnitAthleteEntryId || i}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''}  ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
                {Array.isArray(athlete.events) && athlete.events.length > 0 &&
                  <Fragment>
                    <button className={global.IconButton}
                      type="button"
                      onClick={(e) => onClick(e, athlete.orgUnitAthleteEntryId)}>
                      {expandedId === athlete.orgUnitAthleteEntryId ? <HideIcon /> : <ShowIcon />}
                    </button>
                    {onUpdateAthleteTimes &&
                      <button className={global.IconButtonMobileMargin}
                        type="button"
                        onClick={(e) => onUpdateAthleteTimes(e, athlete)}>
                        <StopwatchIcon toolTipText='Update Individual Event Entry Times for Athlete' />
                      </button>}
                  </Fragment>}
                {onEditAthleteClicked && <button className={Array.isArray(athlete.events) && athlete.events.length > 0 ? global.IconButtonMobileMargin : global.IconButton}
                  type="button"
                  onClick={(e) => onEditAthleteClicked(e, athlete)}>
                  <EditIcon />
                </button>}
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                  <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age at Meet</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.status || <span>&nbsp;</span>}</div>
                </div>
              </div>
            </div>
            {expandedId === athlete.orgUnitAthleteEntryId &&
              <SmallDetailTable athlete={athlete} />
            }
          </Fragment>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Athletes</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminRosterEntriesSmallGrid;