import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeEligibilityNonHQValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  omeMeetQualificationId: Constants.DEFAULT_ID,
  qualificationBonusId: Constants.DEFAULT_ID,
  sortedBonusGridData: [],
  sortedCoachGridData: []
};

const INITIAL_FORM_STATE = {
  maxNumberOfAthletesForMeet: '',
  maxNumberOfCapExemptAthletesForMeet: '',
  includeRelayOnlySwimmersInMaxNumber: '',
  includeRelaySwimmerForCoachPasses: '',
  maxEventsPerIndividualPerMeet: '',
  maxEventsPerIndividualPerSession: '',
  relayEvents: '',
  allowRelayOnlySwimmer: '',
  relayTeamFromRosterOnly: '',
  forceAggregateIfFasterThanTeamTime: '',
  maxRelayTeamEntriesPerEvent: '',
  maxRelayEventsPerIndividualPerMeet: '',
  bonusEvents: '',
  useAdjustedSwimTime: ''
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: '',
  minQualifyingEvents: '',
  maxQualifyingEvents: '',
  bonusStandardCount: '',
  omeMeetQualificationId: Constants.DEFAULT_ID,
  qualificationBonusId: Constants.DEFAULT_ID,
  minSwimmers: '',
  maxSwimmers: '',
  coachPasses: '',
  omeMeetCoachPassId: Constants.DEFAULT_ID
};

const INITIAL_WARNING_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
  deleteAllEventBonusTimeStandardCuts: false,
  timeStandardWarningMsg: '',
  deleteCostPerAthleteIndividualBonusEventEntry: false,
  bonusPricingWarningMessage: '',
  deleteRelayRelatedCosts: false,
  relayOnlySwimmerPricingWarningMessage: '',
  relayEntryPricingWarningMessage: ''
};

const useOmeEligibilityNonHQ = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [warningModalState, setWarningModalState] = useState(INITIAL_WARNING_MODAL_STATE);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { environmentVariableState, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
    RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, MAX_RELAY_TEAM_ENTRIES_PER_EVENT } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formState, errorState, onFormValueChanged, isSubmitting, handleSubmit, setIsDirty,
    isDirty, setFormData, setErrors } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async (submitButton, omeMeetQualificationId, qualificationBonusId, omeMeetCoachPassId) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    //Prevent user from setting relayEvents to false if they have already added relay events
    let relayError = false;
    if (formState.relayEvents === false) {
      if (omeMeetState.objData.meetAgeGroup?.length > 0) {
        for (let i = 0; i < omeMeetState.objData.meetAgeGroup.length; i++) {
          for (let j = 0; j < omeMeetState.objData.meetAgeGroup[i].meetEvent.length; j++) {
            if (omeMeetState.objData.meetAgeGroup[i].meetEvent[j]?.event?.isRelay === true) {
              relayError = true;
            }
          }
        }
      }
    }
    if (relayError === true) {
      setErrors({ ...errorState, error: `Meet has Relay Events? cannot be set to 'No' because relay events have been added on the Events page. To be permitted to set this field to 'No', navigate to the Events page and delete all relay events.` });
    }
    else if (relayError === false) {
      let error = ''
      if (issueWithMaxEventsPerIndividualPerMeet() === true) {
        error = `Max Events Per Individual Per Meet (${formState.maxEventsPerIndividualPerMeet}) cannot be less than the sum of any Bonus Event Rule's Max Number of Qualifying Events and Allowed Number of Bonus Events. Please delete any Bonus Event Rule in conflict with this validation rule in order to save changes made on this page.`
        setErrors({ ...errorState, error: error });
      }
      if (issueWithMaxNumberOfAthletesForMeet() === true) {
        error = error ? error + `\n\n Max Number of Athletes For Meet (${formState.maxNumberOfAthletesForMeet}) cannot be less than any Coach Pass Rule's Max Number of Athletes. Please delete any Coach Pass Rule in conflict with this validation rule in order to save changes made on this page.` :
          `Max Number of Athletes For Meet (${formState.maxNumberOfAthletesForMeet}) cannot be less than any Coach Pass Rule's Max Number of Athletes. Please delete any Coach Pass Rule in conflict with this validation rule in order to save changes made on this page.`;
        setErrors({ ...errorState, error: error });
      }
      else if (Object.keys(errors).length === 0 && error === '') {
        setState({
          ...state, tryRedirect: true, submitButton: submitButton,
          omeMeetQualificationId: omeMeetQualificationId ? omeMeetQualificationId : Constants.DEFAULT_ID,
          qualificationBonusId: qualificationBonusId ? qualificationBonusId : Constants.DEFAULT_ID,
          omeMeetCoachPassId: omeMeetCoachPassId ? omeMeetCoachPassId : Constants.DEFAULT_ID
        });
      }
    }
  };

  const tryValidateBeforeShowingDeletePopUp = async (obj) => {
    const errors = await validate(formState) || {};
    setErrors(errors);

    //Don't show delete popup if maxEventsPerIndividualPerMeet has changed
    let bonusRuleError = false;
    if (omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet === null && formState?.maxEventsPerIndividualPerMeet.toString() !== '') {
      bonusRuleError = true;
    } else if (ToIntIfInt(formState?.maxEventsPerIndividualPerMeet) !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet
      && (formState?.maxEventsPerIndividualPerMeet !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet !== null)) {
      bonusRuleError = true;
    }

    if (bonusRuleError === true) {
      if (obj.omeMeetCoachPassId) {
        setErrors({ ...errorState, error: `Max Events Per Individual Per Meet (${formState.maxEventsPerIndividualPerMeet || 'blank'}) has changed. This field is used for Bonus Event Rule validation and cannot be updated when deleting a Coach Pass Rule. Please revert Max Events Per Individual Per Meet to its previous value (${omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet !== null ? omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet : 'blank'}) in order to delete a Coach Pass Rule.` });
      }
      else {
        setErrors({ ...errorState, error: `Max Events Per Individual Per Meet (${formState.maxEventsPerIndividualPerMeet || 'blank'}) has changed. This field is used for Bonus Event Rule validation and cannot be updated when deleting a Bonus Event Rule. Please revert Max Events Per Individual Per Meet to its previous value (${omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet !== null ? omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet : 'blank'}) in order to delete a Bonus Event Rule.` });
      }
    }

    //Don't show delete popup if maxEventsPerIndividualPerMeet has changed
    let coachRuleError = false;
    if (omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet === null && formState?.maxNumberOfAthletesForMeet.toString() !== '') {
      coachRuleError = true;
    } else if (ToIntIfInt(formState?.maxNumberOfAthletesForMeet) !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet
      && (formState?.maxNumberOfAthletesForMeet !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet !== null)) {
      coachRuleError = true;
    }

    if (coachRuleError === true) {
      if (obj.omeMeetCoachPassId) {
        setErrors({ ...errorState, error: `Max Number of Athletes For Meet (${formState.maxNumberOfAthletesForMeet || 'blank'}) has changed. This field is used for Coach Pass Rule validation and cannot be updated when deleting a Coach Pass Rule. Please revert Max Number of Athletes For Meet to its previous value (${omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet !== null ? omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet : 'blank'}) in order to delete a Coach Pass Rule.` });
      }
      else {
        setErrors({ ...errorState, error: `Max Number of Athletes For Meet (${formState.maxNumberOfAthletesForMeet || 'blank'}) has changed. This field is used for Coach Pass Rule validation and cannot be updated when deleting a Bonus Event Rule. Please revert Max Number of Athletes For Meet to its previous value (${omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet !== null ? omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet : 'blank'}) in order to delete a Bonus Event Rule.` });
      }
    }

    //Show popup if there are no form validation errors and maxEventsPerIndividualPerMeet and maxNumberOfAthletesForMeet have not changed
    if (Object.keys(errors).length === 0 && bonusRuleError === false && coachRuleError === false) {
      setModalState({
        ...modalState,
        displayPopUp: true,
        modalTitle: obj?.omeMeetCoachPassId ? 'Delete Coach Pass Rule?' : 'Delete Bonus Event Rule?',
        minQualifyingEvents: obj?.minQualifyingEvents >= 0 ? obj?.minQualifyingEvents : '',
        maxQualifyingEvents: obj?.maxQualifyingEvents >= 0 ? obj?.maxQualifyingEvents : '',
        bonusStandardCount: obj?.bonusStandardCount >= 0 ? obj?.bonusStandardCount : '',
        omeMeetQualificationId: obj?.omeMeetQualificationId || Constants.DEFAULT_ID,
        qualificationBonusId: obj?.qualificationBonusId || Constants.DEFAULT_ID,
        minSwimmers: obj?.minSwimmers >= 0 ? obj?.minSwimmers : '',
        maxSwimmers: obj?.maxSwimmers >= 0 ? obj?.maxSwimmers : '',
        coachPasses: obj?.coachPasses >= 0 ? obj?.coachPasses : '',
        omeMeetCoachPassId: obj?.omeMeetCoachPassId || Constants.DEFAULT_ID
      });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && (formState.bonusEvents === false ||
      (formState.bonusEvents === true && omeMeetState.objData?.omeMeetQualification[0]?.omeQualificationBonus.length > 0))
      && omeMeetState.objData?.omeMeetCoachPass.length > 0) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      setIsDirty(true);
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && (formState.bonusEvents === false ||
      (formState.bonusEvents === true && omeMeetState.objData?.omeMeetQualification[0]?.omeQualificationBonus.length > 0))
      && omeMeetState.objData?.omeMeetCoachPass.length > 0) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      setIsDirty(true);
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && (formState.bonusEvents === false ||
      (formState.bonusEvents === true && omeMeetState.objData?.omeMeetQualification[0]?.omeQualificationBonus.length > 0))
      && omeMeetState.objData?.omeMeetCoachPass.length > 0) {
      tryValidateBeforeRedirect(HostRegistrationConstants.BACK);
    }
    else {
      setIsDirty(true);
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.BACK });
    }
  };

  const onAddCoachPassRuleClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.ADD + 'Coach');
  };


  const onEditCoachPassRuleClicked = (e, omeMeetCoachPassId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.EDIT + 'Coach', null, null, omeMeetCoachPassId);
  }

  const onDeleteCoachPassRuleClicked = (e, coach) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeShowingDeletePopUp(coach);
  };

  const onDeleteCoachPassRule = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();

    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    if (isDirty === true) {
      updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    }

    const selectedOmeMeetCoachPassIndex = updatedOmeMeet.omeMeetCoachPass.findIndex(x =>
      x.omeMeetCoachPassId === modalState.omeMeetCoachPassId);

    if (selectedOmeMeetCoachPassIndex > -1) {
      updatedOmeMeet.omeMeetCoachPass?.splice(selectedOmeMeetCoachPassIndex, 1);
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    }
  };

  const onAddBonusEventRuleClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.ADD + 'Bonus');
  }

  const onEditBonusEventRuleClicked = (e, omeMeetQualificationId, qualificationBonusId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.EDIT + 'Bonus', omeMeetQualificationId, qualificationBonusId, null);
  }

  const onDeleteBonusEventRuleClicked = (e, bonus) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeShowingDeletePopUp(bonus);
  };

  const onDeleteBonusEventRule = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();

    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    if (isDirty === true) {
      updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    }

    const selectedOmeMeetQualificationIndex = updatedOmeMeet.omeMeetQualification.findIndex(x =>
      x.omeMeetQualificationId === modalState.omeMeetQualificationId);

    if (selectedOmeMeetQualificationIndex > -1) {
      const selectedOmeQualificationBonusIndex = updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex]?.omeQualificationBonus.findIndex(x =>
        x.qualificationBonusId === modalState.qualificationBonusId);

      if (selectedOmeQualificationBonusIndex > -1) {
        updatedOmeMeet.omeMeetQualification[selectedOmeMeetQualificationIndex]?.omeQualificationBonus.splice(selectedOmeQualificationBonusIndex, 1);
        updatedOmeMeet.meet = undefined;
        putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
      }
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onSaveAndDelete = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const omeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    const updatedOmeMeet = editOmeMeetCopy(omeMeetCopy);
    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });
    setWarningModalState(INITIAL_WARNING_MODAL_STATE);
  };

  const onWarningModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setWarningModalState(INITIAL_WARNING_MODAL_STATE);
  };

  const editOmeMeetCopy = (updatedOmeMeet) => {
    //Add 0,0,0 bonus event rule if bonus events are allowed for the meet,
    //and the omeMeetQualification is created
    if (formState.bonusEvents === true && updatedOmeMeet.omeMeetQualification?.length > 0) {
      if (updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.length === 0)
        updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus = [{
          omeMeetQualificationId: updatedOmeMeet.omeMeetQualification[0].omeMeetQualificationId,
          minQualifyingEvents: 0,
          maxQualifyingEvents: 0,
          bonusStandardCount: 0
        }];
    }

    //Add 0,0,0 bonus event rule if bonus events are allowed 
    //for the meet and the omeMeetQualification is not created yet
    if (updatedOmeMeet.omeMeetQualification.length === 0) {
      updatedOmeMeet.omeMeetQualification = [{
        omeMeetId: updatedOmeMeet.omeMeetId,
        hasTimeStandards: null,
        hasNoSlowerThanTimeStandards: null,
        hasNoFasterThanTimeStandards: null,
        hasAthleteExceedsTimeStandardsRule: null,
        hasBonusTimeStandards: null,
        timeStandardsQualifyingStartDate: null,
        timeStandardsQualifyingEndDate: null,
        omeQualificationBonus: formState.bonusEvents === true ? [{
          minQualifyingEvents: formState.minQualifyingEvents,
          maxQualifyingEvents: formState.maxQualifyingEvents,
          bonusStandardCount: formState.bonusStandardCount
        }] : []
      }];
    }

    //Add 0,0,0 coach pass rule, if there are no coach passes provided
    if (updatedOmeMeet.omeMeetCoachPass?.length === 0) {
      updatedOmeMeet.omeMeetCoachPass = [{
        omeMeetId: omeMeetState.objData.omeMeetId,
        minSwimmers: 0,
        maxSwimmers: 0,
        coachPasses: 0
      }];
    }

    //Delete any bonus event rules if bonus events are not allowed for the meet
    if (formState.bonusEvents === false && updatedOmeMeet.omeMeetQualification?.length > 0) {
      if (updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus.length > 0)
        updatedOmeMeet.omeMeetQualification[0].omeQualificationBonus = [];
    }

    //Delete the Cost Per Athlete Individual Bonus Event Entry record if bonus rules are not allowed for the meet
    if (formState.bonusEvents === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const omeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID);
      if (omeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(omeFeeTypeIdIndex, 1);
      }
    }

    //Delete the Cost Per Relay Only Swimmer & Cost Per Relay Event Entry records if relay events are not allowed for the meet
    if (formState.relayEvents === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const relayOnlySwimmerOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID);
      if (relayOnlySwimmerOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayOnlySwimmerOmeFeeTypeIdIndex, 1);
      }
      const relayEventEntryOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID);
      if (relayEventEntryOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayEventEntryOmeFeeTypeIdIndex, 1);
      }
    }

    //Delete the Cost Per Relay Only Swimmer if relay events are allowed for the meet but Relay Only Swimmers are not allowed
    if (formState.allowRelayOnlySwimmer === false && updatedOmeMeet.omeMeetPrice?.length > 0) {
      const relayOnlySwimmerOmeFeeTypeIdIndex = updatedOmeMeet.omeMeetPrice?.findIndex(x => x.omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID);
      if (relayOnlySwimmerOmeFeeTypeIdIndex > -1) {
        updatedOmeMeet.omeMeetPrice.splice(relayOnlySwimmerOmeFeeTypeIdIndex, 1);
      }
    }

    //Any relay event related info is cleared out below if relayEvents is set to false
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      omeMeetId: updatedOmeMeet.omeMeetId,
      maxNumberOfAthletesForMeet: formState.maxNumberOfAthletesForMeet.toString().replace(',', '') || null,
      maxNumberOfCapExemptAthletesForMeet: formState.maxNumberOfCapExemptAthletesForMeet.toString().replace(',', '') || null,
      allowRelayOnlySwimmer: formState.relayEvents === true ? (formState.allowRelayOnlySwimmer ?? null) : null,
      relayTeamFromRosterOnly: formState.relayEvents === true ? (formState.relayTeamFromRosterOnly ?? null) : null,
      forceAggregateIfFasterThanTeamTime: formState.relayEvents === true ? (formState.forceAggregateIfFasterThanTeamTime ?? null) : null,
      includeRelayOnlySwimmersInMaxNumber: formState.relayEvents === true && formState.allowRelayOnlySwimmer === true ? (formState.includeRelayOnlySwimmersInMaxNumber ?? null) : null,
      maxEventsPerIndividualPerMeet: formState.maxEventsPerIndividualPerMeet || null,
      maxEventsPerIndividualPerSession: formState.maxEventsPerIndividualPerSession || null,
      relayEvents: formState.relayEvents,
      maxRelayTeamEntriesPerEvent: formState.relayEvents === true ? (formState.maxRelayTeamEntriesPerEvent || null) : null,
      maxRelayEventsPerIndividualPerMeet: formState.relayEvents === true ? (formState.maxRelayEventsPerIndividualPerMeet || null) : null,
      bonusEvents: formState.bonusEvents,
      usCitizenOnly: updatedOmeMeet.omeMeetEligibility[0]?.usCitizenOnly ?? null,
      nationalTeamOnly: updatedOmeMeet.omeMeetEligibility[0]?.nationalTeamOnly ?? null,
      allowRepOtherCountry: updatedOmeMeet.omeMeetEligibility[0]?.allowRepOtherCountry ?? null,
      useAdjustedSwimTime: formState.useAdjustedSwimTime
    }

    updatedOmeMeet.includeRelaySwimmerForCoachPasses = formState.relayEvents === true && formState.allowRelayOnlySwimmer === true ? (formState.includeRelaySwimmerForCoachPasses ?? null) : null;
    updatedOmeMeet.meet = undefined;

    //set has hasAthleteExceedsTimeStandardsRelayRule to null if relay events are not allowed
    if (updatedOmeMeet.omeMeetQualification[0] && formState.relayEvents === false) {
      updatedOmeMeet.omeMeetQualification[0] = {
        ...updatedOmeMeet.omeMeetQualification[0],
        hasAthleteExceedsTimeStandardsRelayRule: null
      }
    }

    //set has bonusTimeStandards to null if bonus events are not allowed
    if (updatedOmeMeet.omeMeetQualification[0] && formState.bonusEvents === false) {
      updatedOmeMeet.omeMeetQualification[0] = {
        ...updatedOmeMeet.omeMeetQualification[0],
        hasBonusTimeStandards: null
      }
    }

    //Remove any bonus time standards if the meet does not have bonus events
    if (formState.bonusEvents === false && updatedOmeMeet.meetAgeGroup.length > 0) {
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
        if (updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard.length > 0) {
          for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard.length; j++) {
            updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j] = {
              ...updatedOmeMeet.meetAgeGroup[i].meetAgeGroupTimeStandard[j],
              bonusTimeStandardAgeGroupId: null,
              bonusTimeStandardAgeGroup: undefined
            }
          }
        }
      }
    }

    //Remove any bonus time standard cuts if the meet does not allow bonus events
    if (formState.bonusEvents === false && updatedOmeMeet.meetAgeGroup.length > 0) {
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
        if (updatedOmeMeet.meetAgeGroup[i].meetEvent.length > 0) {
          for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetEvent.length; j++) {
            updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime = [];
          }
        }
      }
    }

    return updatedOmeMeet;
  }

  function submitFormCallback(formState) {
    //Prevent user from setting relayEvents to false if they have already added relay events
    let relayError = false;
    if (formState.relayEvents === false) {
      if (omeMeetState.objData.meetAgeGroup?.length > 0) {
        for (let i = 0; i < omeMeetState.objData.meetAgeGroup.length; i++) {
          for (let j = 0; j < omeMeetState.objData.meetAgeGroup[i].meetEvent.length; j++) {
            if (omeMeetState.objData.meetAgeGroup[i].meetEvent[j]?.event?.isRelay === true) {
              relayError = true;
            }
          }
        }
      }
    }
    if (relayError === true) {
      setErrors({ ...errorState, error: `Meet has Relay Events? cannot be set to 'No' because relay events have been added on the Events page. To be permitted to set this field to 'No', navigate to the Events page and delete all relay events.` });
    }
    else if (relayError === false) {
      //Check to see if any bonus event rules conflict with the max number of events per individual per meet
      let error = ''
      if (issueWithMaxEventsPerIndividualPerMeet() === true) {
        error = `Max Events Per Individual Per Meet (${formState.maxEventsPerIndividualPerMeet}) cannot be less than the sum of any Bonus Event Rule's Max Number of Qualifying Events and Allowed Number of Bonus Events. Please delete any Bonus Event Rule in conflict with this validation rule in order to save changes made on this page.`
        setErrors({ ...errorState, error: error });
      }
      //Check to see if any coach pass rules conflict with the max number of athletes for meet
      if (issueWithMaxNumberOfAthletesForMeet() === true) {
        error = error ? error + `\n\n Max Number of Athletes For Meet (${formState.maxNumberOfAthletesForMeet}) cannot be less than any Coach Pass Rule's Max Number of Athletes. Please delete any Coach Pass Rule in conflict with this validation rule in order to save changes made on this page.` :
          `Max Number of Athletes For Meet (${formState.maxNumberOfAthletesForMeet}) cannot be less than any Coach Pass Rule's Max Number of Athletes. Please delete any Coach Pass Rule in conflict with this validation rule in order to save changes made on this page.`;
        setErrors({ ...errorState, error: error });
      }
      else if (error === '') {
        //Check to see if any relay / bonus time Standard / event changes need to be handled
        if (showDeleteWarningMessage(formState) === false) {
          const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
          updatedOmeMeet.meet = undefined;
          putOmeMeet(omeMeetState?.objData?.omeMeetId, editOmeMeetCopy(updatedOmeMeet));
          setState({ ...state, tryRedirect: true });
        }
      }
    }
  }

  function formStateHasBeenSaved(eligibilityFormState) {
    //compare formState to omeMeetState to see if formState has been saved
    if (eligibilityFormState?.includeRelayOnlySwimmersInMaxNumber !== omeMeetState?.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber
      && (eligibilityFormState?.includeRelayOnlySwimmersInMaxNumber !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber !== null)) {
      return false;
    }
    if (eligibilityFormState?.includeRelaySwimmerForCoachPasses !== omeMeetState?.objData?.includeRelaySwimmerForCoachPasses
      && (eligibilityFormState?.includeRelaySwimmerForCoachPasses !== '' || omeMeetState?.objData?.includeRelaySwimmerForCoachPasses !== null)) {
      return false;
    }
    if (eligibilityFormState?.relayEvents !== omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents
      && (eligibilityFormState?.relayEvents !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents !== null)) {
      return false;
    }
    if (eligibilityFormState?.allowRelayOnlySwimmer !== omeMeetState?.objData?.omeMeetEligibility[0]?.allowRelayOnlySwimmer
      && (eligibilityFormState?.allowRelayOnlySwimmer !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.allowRelayOnlySwimmer !== null)) {
      return false;
    }
    if (eligibilityFormState?.relayTeamFromRosterOnly !== omeMeetState?.objData?.omeMeetEligibility[0]?.relayTeamFromRosterOnly
      && (eligibilityFormState?.relayTeamFromRosterOnly !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.relayTeamFromRosterOnly !== null)) {
      return false;
    }
    if (eligibilityFormState?.forceAggregateIfFasterThanTeamTime !== omeMeetState?.objData?.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime
      && (eligibilityFormState?.forceAggregateIfFasterThanTeamTime !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime !== null)) {
      return false;
    }
    if (eligibilityFormState?.bonusEvents !== omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents
      && (eligibilityFormState?.bonusEvents !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents !== null)) {
      return false;
    }
    if (ToIntIfInt(eligibilityFormState?.maxNumberOfAthletesForMeet.toString().replace(',', '')) !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet
      && (ToIntIfInt(eligibilityFormState?.maxNumberOfAthletesForMeet.toString().replace(',', '')) !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet !== null)) {
      return false;
    }
    if (ToIntIfInt(eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet.toString().replace(',', '')) !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfCapExemptAthletesForMeet
      && (ToIntIfInt(eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet.toString().replace(',', '')) !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfCapExemptAthletesForMeet !== null)) {
      return false;
    }
    if (eligibilityFormState?.maxEventsPerIndividualPerMeet !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet
      && (eligibilityFormState?.maxEventsPerIndividualPerMeet !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet !== null)) {
      return false;
    }
    if (eligibilityFormState?.maxEventsPerIndividualPerSession !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerSession
      && (eligibilityFormState?.maxEventsPerIndividualPerSession !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerSession !== null)) {
      return false;
    }
    if (eligibilityFormState?.maxRelayTeamEntriesPerEvent !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent
      && (eligibilityFormState?.maxRelayTeamEntriesPerEvent !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent !== null)) {
      return false;
    }
    if (eligibilityFormState?.maxRelayEventsPerIndividualPerMeet !== omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet
      && (eligibilityFormState?.maxRelayEventsPerIndividualPerMeet !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet !== null)) {
      return false;
    }
    if (eligibilityFormState?.useAdjustedSwimTime !== omeMeetState?.objData?.omeMeetEligibility[0]?.useAdjustedSwimTime
      && (eligibilityFormState?.useAdjustedSwimTime !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.useAdjustedSwimTime !== null)) {
      return false;
    }

    return true;
  };

  function showDeleteWarningMessage(formState) {
    let numMeetEvents = 0;
    let hasMeetEventBonusTime = false;
    let deleteAllEventBonusTimeStandardCuts = false;
    let timeStandardWarningMsg = '';

    for (let i = 0; i < omeMeetState?.objData?.meetAgeGroup.length; i++) {
      for (let j = 0; j < omeMeetState?.objData?.meetAgeGroup[i].meetEvent.length; j++) {
        numMeetEvents += 1;
        if (omeMeetState?.objData?.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length > 0) {
          hasMeetEventBonusTime = true;
        }
      }
    }

    //If the meet has bonus time standards, show time standard warning
    if ((numMeetEvents === 0 || (numMeetEvents > 0 && hasMeetEventBonusTime === false)) && omeMeetState.objData?.omeMeetQualification[0]?.hasBonusTimeStandards === true) {
      timeStandardWarningMsg = "Changing 'Meet allows Bonus Events?' from 'Yes' to 'No' will delete all Age Group Bonus Time Standards."
    }

    //If the meet has bonus time standards and bonus event cuts, show time standard and bonus event cut warning
    if (numMeetEvents > 0 && hasMeetEventBonusTime === true && omeMeetState.objData?.omeMeetQualification[0]?.hasBonusTimeStandards === true) {
      timeStandardWarningMsg = "Changing 'Meet allows Bonus Events?' from 'Yes' to 'No' will delete all Age Group Bonus Time Standards and Event Bonus Time Standard Cuts."
    }

    //compare formState to omeMeetState to see if changes will affect any events using time standard cuts
    if (formState?.bonusEvents === false && omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents === true &&
      omeMeetState.objData?.omeMeetQualification[0]?.hasBonusTimeStandards === true) {
      deleteAllEventBonusTimeStandardCuts = true;
    }

    let deleteCostPerAthleteIndividualBonusEventEntry = false;
    let bonusPricingWarningMessage = '';

    if (formState.bonusEvents === false) {
      for (let i = 0; i < omeMeetState?.objData?.omeMeetPrice?.length; i++) {
        if (omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID) {
          deleteCostPerAthleteIndividualBonusEventEntry = true;
          bonusPricingWarningMessage = "Changing 'Meet allows Bonus Events?' from 'Yes' to 'No' will delete the 'Cost Per Athlete Individual Bonus Event Entry' provided on the Pricing page."
        }
      }
    }

    let deleteRelayRelatedCosts = false;
    let relayOnlySwimmerPricingWarningMessage = '';
    let relayEntryPricingWarningMessage = '';

    if (formState.relayEvents === false) {
      for (let i = 0; i < omeMeetState?.objData?.omeMeetPrice?.length; i++) {
        if (omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID) {
          deleteRelayRelatedCosts = true;
          relayOnlySwimmerPricingWarningMessage = "Changing 'Meet has Relay Events?' from 'Yes' to 'No' will delete the 'Cost Per Relay Only Swimmer' provided on the Pricing page."
        }
        if (omeMeetState?.objData?.omeMeetPrice[i].omeFeeTypeId === RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID) {
          deleteRelayRelatedCosts = true;
          relayEntryPricingWarningMessage = "Changing 'Meet has Relay Events?' from 'Yes' to 'No' will delete the 'Cost Per Relay Event Entry' provided on the Pricing page."
        }
      }
    }

    if (deleteAllEventBonusTimeStandardCuts === true || deleteCostPerAthleteIndividualBonusEventEntry === true ||
      deleteRelayRelatedCosts === true) {
      setWarningModalState({
        ...warningModalState,
        displayPopUp: true,
        deleteAllEventBonusTimeStandardCuts: deleteAllEventBonusTimeStandardCuts,
        timeStandardWarningMsg: timeStandardWarningMsg,
        deleteCostPerAthleteIndividualBonusEventEntry: deleteCostPerAthleteIndividualBonusEventEntry,
        bonusPricingWarningMessage: bonusPricingWarningMessage,
        deleteRelayRelatedCosts: deleteRelayRelatedCosts,
        relayOnlySwimmerPricingWarningMessage: relayOnlySwimmerPricingWarningMessage,
        relayEntryPricingWarningMessage: relayEntryPricingWarningMessage
      });
      return true;
    }

    else {
      //If the formState changes are not related to relay or bonus events, don't show warning message
      return false;
    }
  };

  function issueWithMaxEventsPerIndividualPerMeet() {
    if (formState.bonusEvents === false || formState.maxEventsPerIndividualPerMeet === '') {
      return false;
    }
    else if (omeMeetState.objData.omeMeetQualification[0] !== undefined &&
      omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus?.length > 0) {
      for (let i = 0; i < omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus?.length; i++) {
        let maxQualifyingEventsNum = parseInt(omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].maxQualifyingEvents);
        let bonusStandardCountNum = parseInt(omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount);
        if ((maxQualifyingEventsNum + bonusStandardCountNum) > parseInt(formState.maxEventsPerIndividualPerMeet)) {
          return true;
        }
      }
    }

    return false;
  };

  function issueWithMaxNumberOfAthletesForMeet() {
    if (formState.maxNumberOfAthletesForMeet === '') {
      return false;
    }
    else if (omeMeetState.objData.omeMeetCoachPass !== undefined &&
      omeMeetState.objData.omeMeetCoachPass?.length > 0) {
      for (let i = 0; i < omeMeetState.objData.omeMeetCoachPass?.length; i++) {
        let maxSwimmersNum = parseInt(omeMeetState.objData.omeMeetCoachPass[i].maxSwimmers);
        if (maxSwimmersNum > parseInt(formState.maxNumberOfAthletesForMeet)) {
          return true;
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && environmentVariableState.isLoaded === true) {
      //Sort grid data to display in ascending order
      let omeQualificationBonusCopy = [];
      if (omeMeetState.objData.omeMeetQualification[0]) {
        omeQualificationBonusCopy = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus));
        omeQualificationBonusCopy.sort(function (a, b) { return a.minQualifyingEvents - b.minQualifyingEvents });
      }
      let omeCoachPassCopy = [];
      if (omeMeetState.objData.omeMeetCoachPass) {
        omeCoachPassCopy = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetCoachPass));
        omeCoachPassCopy.sort(function (a, b) { return a.minSwimmers - b.minSwimmers });
      }
      setState({
        ...state,
        sortedBonusGridData: omeQualificationBonusCopy,
        sortedCoachGridData: omeCoachPassCopy
      });
      //Set formState from location.state if 'Back' button was clicked on bonus event detail page
      if (location.state && location.state?.eligibilityFormState) {
        setFormData({
          ...formState,
          maxNumberOfAthletesForMeet: location.state?.eligibilityFormState?.maxNumberOfAthletesForMeet !== null &&
            location.state?.eligibilityFormState?.maxNumberOfAthletesForMeet !== undefined ?
            location.state?.eligibilityFormState?.maxNumberOfAthletesForMeet : '',
          maxNumberOfCapExemptAthletesForMeet: location.state?.eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet !== null &&
            location.state?.eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet !== undefined ?
            location.state?.eligibilityFormState?.maxNumberOfCapExemptAthletesForMeet : '',
          includeRelayOnlySwimmersInMaxNumber: location.state?.eligibilityFormState?.includeRelayOnlySwimmersInMaxNumber ?? '',
          includeRelaySwimmerForCoachPasses: location.state?.eligibilityFormState?.includeRelaySwimmerForCoachPasses ?? '',
          maxEventsPerIndividualPerMeet: location.state?.eligibilityFormState?.maxEventsPerIndividualPerMeet !== null &&
            location.state?.eligibilityFormState?.maxEventsPerIndividualPerMeet !== undefined ?
            location.state?.eligibilityFormState?.maxEventsPerIndividualPerMeet : '',
          maxEventsPerIndividualPerSession: location.state?.eligibilityFormState?.maxEventsPerIndividualPerSession !== null &&
            location.state?.eligibilityFormState?.maxEventsPerIndividualPerSession !== undefined ?
            location.state?.eligibilityFormState?.maxEventsPerIndividualPerSession : '',
          relayEvents: location.state?.eligibilityFormState?.relayEvents ?? '',
          allowRelayOnlySwimmer: location.state?.eligibilityFormState?.allowRelayOnlySwimmer ?? '',
          relayTeamFromRosterOnly: location.state?.eligibilityFormState?.relayTeamFromRosterOnly ?? '',
          forceAggregateIfFasterThanTeamTime: location.state?.eligibilityFormState?.forceAggregateIfFasterThanTeamTime ?? '',
          maxRelayTeamEntriesPerEvent: location.state?.eligibilityFormState?.maxRelayTeamEntriesPerEvent !== null &&
            location.state?.eligibilityFormState?.maxRelayTeamEntriesPerEvent !== undefined ?
            location.state?.eligibilityFormState?.maxRelayTeamEntriesPerEvent : '',
          maxRelayEventsPerIndividualPerMeet: location.state?.eligibilityFormState?.maxRelayEventsPerIndividualPerMeet !== null &&
            location.state?.eligibilityFormState?.maxRelayEventsPerIndividualPerMeet !== undefined ?
            location.state?.eligibilityFormState?.maxRelayEventsPerIndividualPerMeet : '',
          bonusEvents: location.state?.eligibilityFormState?.bonusEvents ?? '',
          useAdjustedSwimTime: location.state?.eligibilityFormState?.useAdjustedSwimTime ?? '',
          MAX_RELAY_TEAM_ENTRIES_PER_EVENT: MAX_RELAY_TEAM_ENTRIES_PER_EVENT
        });
        if (formStateHasBeenSaved(location.state?.eligibilityFormState) === false) {
          setIsDirty(true);
        }
      }
      else {
        //Set formState from omeMeetState
        setFormData({
          ...formState,
          maxNumberOfAthletesForMeet: omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet !== null &&
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet !== undefined ?
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet : '',
          maxNumberOfCapExemptAthletesForMeet: omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfCapExemptAthletesForMeet !== null &&
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfCapExemptAthletesForMeet !== undefined ?
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxNumberOfCapExemptAthletesForMeet : '',
          includeRelayOnlySwimmersInMaxNumber: omeMeetState?.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber ?? '',
          includeRelaySwimmerForCoachPasses: omeMeetState?.objData?.includeRelaySwimmerForCoachPasses ?? '',
          maxEventsPerIndividualPerMeet: omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet !== null &&
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet !== undefined ?
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerMeet : '',
          maxEventsPerIndividualPerSession: omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerSession !== null &&
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerSession !== undefined ?
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxEventsPerIndividualPerSession : '',
          relayEvents: omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents ?? '',
          allowRelayOnlySwimmer: omeMeetState?.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer ?? '',
          relayTeamFromRosterOnly: omeMeetState?.objData.omeMeetEligibility[0]?.relayTeamFromRosterOnly ?? '',
          forceAggregateIfFasterThanTeamTime: omeMeetState?.objData.omeMeetEligibility[0]?.forceAggregateIfFasterThanTeamTime ?? '',
          maxRelayTeamEntriesPerEvent: omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent !== null &&
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent !== undefined ?
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayTeamEntriesPerEvent : '',
          maxRelayEventsPerIndividualPerMeet: omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet !== null &&
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet !== undefined ?
            omeMeetState?.objData?.omeMeetEligibility[0]?.maxRelayEventsPerIndividualPerMeet : '',
          bonusEvents: omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents ?? '',
          useAdjustedSwimTime: omeMeetState?.objData?.omeMeetEligibility[0]?.useAdjustedSwimTime ?? '',
          MAX_RELAY_TEAM_ENTRIES_PER_EVENT: MAX_RELAY_TEAM_ENTRIES_PER_EVENT
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, environmentVariableState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        let error = '';
        if (omeMeetState?.objData?.omeMeetCoachPass?.length === 0) {
          error = 'At least one Coach Pass Rule is required';
        }
        if (omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents === true && (omeMeetState?.objData?.omeMeetQualification[0] === undefined ||
          omeMeetState?.objData?.omeMeetQualification[0]?.omeQualificationBonus?.length === 0)) {
          error = error ? error + '\n At least one Bonus Event Rule is required' : 'At least one Bonus Event Rule is required';
        }
        if (error !== '') {
          setErrors({ ...errorState, error: error });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_RESTRICTIONS);
          }
          else {
            navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents === true && (omeMeetState?.objData?.omeMeetQualification[0] === undefined ||
          omeMeetState?.objData?.omeMeetQualification[0]?.omeQualificationBonus?.length === 0)) {
          setErrors({ ...errorState, error: 'At least one Bonus Event Rule is required' });
        }
        else {
          if (omeMeetState.route !== '') {
            setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
            navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
          }
          else {
            navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState?.objData?.omeMeetEligibility[0]?.bonusEvents === true && (omeMeetState?.objData?.omeMeetQualification[0] === undefined ||
          omeMeetState?.objData?.omeMeetQualification[0]?.omeQualificationBonus?.length === 0)) {
          setErrors({ ...errorState, error: 'At least one Bonus Event Rule is required' });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_INFO);
          }
          else {
            navigate(HostNavLinks.OME_MEET_INFO);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.ADD + 'Coach') {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_ELIGIBILITY_COACH_PASSES, { state: { eligibilityFormState: formState } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_ELIGIBILITY_COACH_PASSES, { state: { eligibilityFormState: formState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.ADD + 'Bonus') {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_ELIGIBILITY_BONUS_EVENTS, { state: { eligibilityFormState: formState } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_ELIGIBILITY_BONUS_EVENTS, { state: { eligibilityFormState: formState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT + 'Coach') {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_ELIGIBILITY_COACH_PASSES, {
            state: {
              eligibilityFormState: formState,
              omeMeetCoachPassId: state.omeMeetCoachPassId
            }
          });
        }
        else {
          navigate(HostNavLinks.OME_MEET_ELIGIBILITY_COACH_PASSES, {
            state: {
              eligibilityFormState: formState,
              omeMeetCoachPassId: state.omeMeetCoachPassId
            }
          });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT + 'Bonus') {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_ELIGIBILITY_BONUS_EVENTS, {
            state: {
              eligibilityFormState: formState,
              omeMeetQualificationId: state.omeMeetQualificationId,
              qualificationBonusId: state.qualificationBonusId
            }
          });
        }
        else {
          navigate(HostNavLinks.OME_MEET_ELIGIBILITY_BONUS_EVENTS, {
            state: {
              eligibilityFormState: formState,
              omeMeetQualificationId: state.omeMeetQualificationId,
              qualificationBonusId: state.qualificationBonusId
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryStartDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    state,
    modalState,
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onSaveAndExitButtonClicked,
    onBackButtonClicked,
    onAddCoachPassRuleClicked,
    onEditCoachPassRuleClicked,
    onDeleteCoachPassRuleClicked,
    onDeleteCoachPassRule,
    onAddBonusEventRuleClicked,
    onEditBonusEventRuleClicked,
    onDeleteBonusEventRuleClicked,
    onDeleteBonusEventRule,
    formStateHasBeenSaved,
    onModalCanceled,
    warningModalState,
    onWarningModalCanceled,
    onSaveAndDelete,
    allowEdits,
    MAX_RELAY_TEAM_ENTRIES_PER_EVENT
  };
};

export default useOmeEligibilityNonHQ;