import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getRecordList from './GetRecordListData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  items: [],
  isTopInfoLaneSheet: null,
  isRightInfoLaneSheet: null
};

const RecordListData = {
  getRecordList,
  INITIAL_STATE
};

export default RecordListData;