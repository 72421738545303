export const localValidate = (formState) => {
  let errors = {};

  if (formState.competitionGenderTypeId === '') {
    errors.competitionGenderTypeId = 'A selection is required'
  }

  return errors;
};

const OmeRestrictionsTypeCompetitionCategoryValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OmeRestrictionsTypeCompetitionCategoryValidation;