import { useContext } from 'react';

import { CompetitionGenderTypeStateContext } from './CompetitionGenderTypeContextProvider';
import CompetitionGenderTypeData from './CompetitionGenderTypeData';

const useCompetitionGenderTypeData = () => {
  const [competitionGenderState, setCompetitionGenderState] = useContext(CompetitionGenderTypeStateContext);

  const getCompetitionGenderTypes = () =>
  CompetitionGenderTypeData.getCompetitionGenderTypes(competitionGenderState, setCompetitionGenderState);

  return {
    competitionGenderState,
    setCompetitionGenderState,
    getCompetitionGenderTypes
  }
}


export default useCompetitionGenderTypeData;