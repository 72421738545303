import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetCompetitionSummaryData from './GetMeetCompetitionSummaryData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  meetId: Constants.DEFAULT_ID
};

const MeetCompetitionSummaryData = {
  getMeetCompetitionSummaryData,
  INITIAL_STATE
};

export default MeetCompetitionSummaryData;