import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import postTimesReconData from './PostTimesReconData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TimesReconData = {
  INITIAL_STATE, postTimesReconData
};

export default TimesReconData;