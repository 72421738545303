import { Fragment } from 'react';

import useLaneSheetHeats from './UseLaneSheetHeats';

import LaneSheetHeatAthletesPopup from './LaneSheetHeatAthletesPopup';
import LaneSheetHeatRelayTeamsPopup from './LaneSheetHeatRelayTeamsPopup';

import LaneSheetHeatGrid from '../components/grids/LaneSheetHeatGrid';
import LaneSheetHeatAddForm from '../components/forms/LaneSheetHeatAddForm';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const LaneSheetHeats = () => {
  const {
    isSaving,
    isLoading,
    gridData,
    reportsState,
    deleteModalState,
    heatPopupState,
    laneSheetState,
    onBulkAddFromOmeClicked,
    onSubmitFormCallback,
    onDeleteLaneSheetHeatClicked,
    onDeleteModalCancelClicked,
    onDeleteModalDeleteClicked,
    onUpdateDisplayOrder,
    onDisplayHeatPopupClicked,
    onHideHeatPopupClicked,
    onUpdateButtonClicked,
    onUpdateIsSelected,
    onGenerateLaneSheetsReport,
    onCloseLaneSheetsReport,
    onUnarchiveSelectedEventsClicked,
    onArchiveSelectedEventsClicked,
    state,
    setState,
  } = useLaneSheetHeats();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Lane Sheets</Headings.H3>
        </div>
      </div>
      <LaneSheetHeatAddForm
        onSubmitFormCallback={onSubmitFormCallback}
        laneSheetMeetId={laneSheetState.objData?.laneSheetMeetId}
        meetStartDate={laneSheetState.objData?.meetStartDate}
        meetEndDate={laneSheetState.objData?.meetEndDate} />
      <hr />
      {laneSheetState.objData?.omeMeetId ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-9">
            <ActionIntraPageButton type="button" onClick={onBulkAddFromOmeClicked}>Bulk Add Lane Sheet From Ome</ActionIntraPageButton>
          </div>
          <div className="col-xs-12 col-sm-3">
            <div className='pull-right'>
              <YesNoSwitch
                label="Show Archived?"
                name="showArchived"
                checked={state.showArchived}
                onChange={(value) => { setState({ ...state, 'showArchived': value }) }} />
            </div>
          </div>
        </div>
        :
        <div className="row usas-extra-bottom-margin pull-right">
          <div className="col-xs-12">
            <YesNoSwitch
              label="Show Archived?"
              name="showArchived"
              checked={state.showArchived}
              onChange={(value) => { setState({ ...state, 'showArchived': value }) }} />
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <LaneSheetHeatGrid
            showArchived={state.showArchived}
            isLoading={isLoading}
            gridData={gridData}
            onUpdateDisplayOrder={onUpdateDisplayOrder}
            onUpdateIsSelected={onUpdateIsSelected}
            onEditClicked={onDisplayHeatPopupClicked}
            onDeleteClicked={onDeleteLaneSheetHeatClicked} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-md-5">
          <PrimaryButton type='button' onClick={onUpdateButtonClicked}>Update</PrimaryButton>&nbsp;
          <ActionIntraPageButton type="button" onClick={onGenerateLaneSheetsReport}>Generate Report</ActionIntraPageButton>&nbsp;
        </div>
        <div className="col-xs-12 col-md-7">
          <SecondaryButton className="pull-right" type='button' onClick={onUnarchiveSelectedEventsClicked}>Unarchive Selected Events</SecondaryButton>&nbsp;
          <div className="pull-right">&nbsp;</div>
          <PrimaryButton className="pull-right" type='button' onClick={onArchiveSelectedEventsClicked}>Archive Selected Events</PrimaryButton>&nbsp;
        </div>
      </div>
      {heatPopupState.isRelay === true
        ? <LaneSheetHeatRelayTeamsPopup
          laneSheetHeat={heatPopupState.laneSheetHeat}
          displayPopup={heatPopupState.displayPopUp}
          onClosePopup={onHideHeatPopupClicked} />
        : <LaneSheetHeatAthletesPopup
          laneSheetHeat={heatPopupState.laneSheetHeat}
          displayPopup={heatPopupState.displayPopUp}
          onClosePopup={onHideHeatPopupClicked} />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Lane Sheet Heat?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className='col-xs-12'><strong>Event Name: </strong>{deleteModalState.laneSheetHeat?.eventName || ''}</p>
            <p className='col-xs-12'><strong>Competition Category: </strong>{deleteModalState.laneSheetHeat?.eventCompetitionGenderTypeName || ''}</p>
            <p className='col-xs-12'><strong>Session Type Name: </strong>{deleteModalState.laneSheetHeat?.sessionTypeName || ''}</p>
            <p className='col-xs-12'><strong>Heat: </strong>{deleteModalState.laneSheetHeat?.heatNumber || ''}</p>
            <p className='col-xs-12'><strong>Date: </strong>{deleteModalState.laneSheetHeat?.heatDate ? formatDate(deleteModalState.laneSheetHeat.heatDate) : ''}</p>
            <p className='col-xs-12'><strong>Display Order: </strong>{deleteModalState.laneSheetHeat?.displayOrder || ''}</p>
            <p className='col-xs-12'><strong>Archived? </strong>{deleteModalState.laneSheetHeat?.isArchived === true ? 'Yes' : 'No'}</p>
            <p className='col-xs-12'>Are you sure you want to delete this heat?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      {reportsState.showReport === true &&
        <ViewReport
          reportInfoId={reportsState.reportInfoId}
          reportParameters={reportsState.reportParameters}
          onBackToGrid={onCloseLaneSheetsReport} />
      }
    </Fragment>
  );
};

export default LaneSheetHeats;