import React from 'react';
import { Routes, Route } from 'react-router-dom';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import OmeMeetAthleteEntryContextProvider from '../../state/omeMeetAthleteEntry/OmeMeetAthleteEntryContextProvider';
import OmeMeetAthleteEntryFiltersContextProvider from '../../state/omeMeetAthleteEntry/OmeMeetAthleteEntryFiltersContextProvider';
import OmeMeetOrgUnitAthleteEntryContextProvider from '../../state/omeMeetOrgUnitAthleteEntry/OmeMeetOrgUnitAthleteEntryContextProvider';

import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';

import AthleteEntry from './athleteEntry/AthleteEntry';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

const MyAthleteEntryRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={'/'}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_ATHLETE_ENTRY']}
            editElement={<AthleteEntry />} />
        }
      />
    </Routes>
  );
};

const MyAthleteEntryContextProviders = ({ children }) => (
  <OmeMeetAthleteEntryContextProvider>
    <OmeMeetAthleteEntryFiltersContextProvider>
      <OmeMeetOrgUnitAthleteEntryContextProvider>
        <ReportPeriodSeasonContextProvider>
          {children}
        </ReportPeriodSeasonContextProvider>
      </OmeMeetOrgUnitAthleteEntryContextProvider>
    </OmeMeetAthleteEntryFiltersContextProvider>
  </OmeMeetAthleteEntryContextProvider>
);

const MyAthleteEntryContextStateAndRoutes = () => (
  <MyAthleteEntryContextProviders>
    <MyAthleteEntryRoutes />
  </MyAthleteEntryContextProviders>
);

export default MyAthleteEntryContextStateAndRoutes;