import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';
//import Constants from '../../../utils/Constants';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  orgUnitId: '',
  data: [],
  items: [],
  message: ''
};

const getFacilitiesByOrgUnitData = (orgUnitId, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    const newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      orgUnitId,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/Facility/OrgUnit/${orgUnitIdForUrl}`;
    ClubHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isLoading: false,
            isLoaded: true,
            orgUnitId,
            data: data,
            items: data.map((x) => { return { id: x.facilityId, name: x.facilityName ? `${x.facilityName} (${x.city}, ${x.stateCode})` : '' } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const FacilitiesByOrgUnitData = { INITIAL_STATE, getFacilitiesByOrgUnitData };

export default FacilitiesByOrgUnitData;