import { useContext } from 'react';

import { OmeMeetStateContext } from './OmeMeetContextProvider';

import OmeMeetData from './OmeMeetData';

const useOmeMeetData = () => {
  const [omeMeetState, setOmeMeetState] = useContext(OmeMeetStateContext);

  const getOmeMeet = (omeMeetId) => OmeMeetData.getOmeMeetData(omeMeetId, omeMeetState, setOmeMeetState);
  const postOmeMeet = (omeMeetObj) => OmeMeetData.postOmeMeetData(omeMeetObj, omeMeetState, setOmeMeetState);
  const putOmeMeet = (omeMeetId, omeMeetObj) => OmeMeetData.putOmeMeetData(omeMeetId, omeMeetObj, omeMeetState, setOmeMeetState);

  const getMeetSummary = (meetId) => OmeMeetData.getMeetSummaryData(meetId, omeMeetState, setOmeMeetState);
  const getMeetSummaryForHost = (hostOrgUnitId) => OmeMeetData.getMeetSummaryForHostIdData(hostOrgUnitId, omeMeetState, setOmeMeetState);
  const getOmeMeetOrgUnitEntryPermission = (omeMeetId, orgUnitIds) => OmeMeetData.getOmeMeetOrgUnitEntryPermissionData(omeMeetId, orgUnitIds, omeMeetState, setOmeMeetState);

  const deleteOmeMeet = (omeMeetId) => OmeMeetData.deleteOmeMeetData(omeMeetId, omeMeetState, setOmeMeetState);

  const clearOmeMeetObjData = () => {
    setOmeMeetState({
      ...omeMeetState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetArrayData = () => {
    setOmeMeetState({
      ...omeMeetState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOmeMeet = () => {
    setOmeMeetState({
      ...omeMeetState,
      isSaved: false
    });
  };

  const resetOmeMeetState = () => {
    setOmeMeetState(OmeMeetData.INITIAL_STATE);
  }

  return {
    omeMeetState,
    setOmeMeetState,
    getOmeMeet,
    postOmeMeet,
    putOmeMeet,
    getMeetSummary,
    getMeetSummaryForHost,
    getOmeMeetOrgUnitEntryPermission,
    clearOmeMeetObjData,
    clearOmeMeetArrayData,
    confirmSaveOmeMeet,
    resetOmeMeetState,
    deleteOmeMeet
  };
};

export default useOmeMeetData;