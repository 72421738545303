import { useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../utils/NavLinks';

import useLaneSheetMeetEventsData from '../../../../state/laneSheetMeetEvents/UseLaneSheetMeetEventsData';
import useLaneSheetData from '../../../../state/laneSheet/UseLaneSheetData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_POST_MEET_EVENT_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetMeetEventAddEvent = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();
  const { postLaneSheetMeetEvent } = useLaneSheetMeetEventsData();
  const [postMeetEventState, setPostMeetEventState] = useState(INITIAL_POST_MEET_EVENT_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.LANE_SHEET_MEET_EVENTS);
  };

  const onSubmitFormCallback = (formState) => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postLaneSheetMeetEventPromise = postLaneSheetMeetEvent(laneSheetMeetId, createLaneSheetMeetEventObj(formState), postMeetEventState, setPostMeetEventState);

      if (postLaneSheetMeetEventPromise !== null) {
        postLaneSheetMeetEventPromise.then((newState) => {
          if (newState !== null) {
            navigate(NavLinks.LANE_SHEET_MEET_EVENTS);
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  function createLaneSheetMeetEventObj(formState) {
    let compCategoryArrayOfIds = []
    for (const compCategory of formState.competitionCategoryId) {
      if (compCategory.id > 0) {
        compCategoryArrayOfIds.push(compCategory.id);
      }
    }

    let eventArrayOfIds = []
    for (const event of formState.eventNameAndDistanceId) {
      if (event.id > 0) {
        eventArrayOfIds.push(event.id);
      }
    }

    return {
      eventCompetitionGenderTypeIds: compCategoryArrayOfIds,
      eventIds: eventArrayOfIds
    };
  };

  return {
    isSaving: postMeetEventState.isObjLoading,
    onSubmitFormCallback,
    onBackClicked
  };
};

export default useLaneSheetMeetEventAddEvent;