import HostRegistrationViews from '../../views/host/hostRegistration/HostRegistrationViews';
import HostNavLinks from "../../views/host/HostNavLinks";

const INITIAL_STATE = [
  {
    name: HostRegistrationViews.OME_MEET_INFO,
    displayName: 'Meet Info',
    path: HostNavLinks.OME_MEET_INFO,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true
  },
  {
    name: HostRegistrationViews.OME_MEET_ELIGIBILITY,
    displayName: 'Eligibility',
    path: HostNavLinks.OME_MEET_ELIGIBILITY,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_RESTRICTIONS,
    displayName: 'Restrictions',
    path: HostNavLinks.OME_MEET_RESTRICTIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_INVITATIONS,
    displayName: 'Invitations',
    path: HostNavLinks.OME_MEET_INVITATIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_SESSIONS,
    displayName: 'Sessions',
    path: HostNavLinks.OME_MEET_SESSIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_AGE_GROUPS,
    displayName: 'Age Groups',
    path: HostNavLinks.OME_MEET_AGE_GROUPS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_TIME_STANDARDS,
    displayName: 'Time Standards',
    path: HostNavLinks.OME_MEET_TIME_STANDARDS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_EVENTS,
    displayName: 'Events',
    path: HostNavLinks.OME_MEET_EVENTS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_PRICING,
    displayName: 'Pricing',
    path: HostNavLinks.OME_MEET_PRICING,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  },
  {
    name: HostRegistrationViews.OME_MEET_SUBMIT,
    displayName: 'Submit',
    path: HostNavLinks.OME_MEET_SUBMIT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false
  }];

const GetInitialStateOmeRoute = (viewName) => {
  const state = INITIAL_STATE.find(x => x.name === viewName);
  return JSON.parse(JSON.stringify(state || {}));
};

export default GetInitialStateOmeRoute;