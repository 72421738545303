import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeDetailTableRow = ({ athlete, relayTeam }) => {
  return (
    <tr>
      <td>{athlete.legNumber >= 0 ? athlete.legNumber : ''}</td>
      <td>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''}</td>
      <td>{athlete.competitionGenderTypeName || ''}</td>
      <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
      <td>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : ''}</td>
      <td>{athlete.entryTime ? formatTimeForDisplay(athlete.entryTime) : 'NT'} {athlete.entryTime && relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : ''}</td>
      <td>{athlete.swimTimeEntryOverride === true ? 'Yes' : 'No'}</td>
      <td>{athlete.swimTimeEntryOverride === true ? athlete.entrySwimDate ? formatDate(athlete.entrySwimDate) : '' : ''}</td>
      <td>{athlete.swimTimeEntryOverride === true ? athlete.entryTimeMeetName || '' : ''}</td>
    </tr>
  )
}

const LargeDetailTable = ({ teamAthletes, relayTeam }) => {
  return (
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th>Leg</th>
          <th>Name</th>
          <th>Comp. Category</th>
          <th>Birth Date</th>
          <th>Age at Meet</th>
          <th>Entry Time</th>
          <th>Override?</th>
          <th>Swim Date</th>
          <th>Meet Name</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(teamAthletes) && teamAthletes.length > 0 &&
          teamAthletes.map((athlete, i) =>
            <LargeDetailTableRow
              athlete={athlete}
              relayTeam={relayTeam}
              key={i}
              index={i}
            />)}
      </tbody>
    </table>
  );
};

const GridRow = ({ relayTeam, onEditRelayTeamClicked, onDeleteRelayTeamClicked, expandedId, onClick }) => {
  return (
    <Fragment key={relayTeam.relayTeamName + 'Fragment'}>
      <tr key={relayTeam.relayTeamName}>
        <td>{relayTeam.relayTeamName || ''}</td>
        <td>{relayTeam.entryTime ? formatTimeForDisplay(relayTeam.entryTime) : 'NT'} {relayTeam.entryTime && relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : ''}</td>
        <td>{relayTeam.alternateEvent === true ? `ALT: ${relayTeam.qualifyingEventName || ''}` : relayTeam.nonConformingTime === true ? `NC: ${relayTeam.qualifyingEventName || ''}` : `${relayTeam.qualifyingEventName || ''}`}</td>
        <td>{relayTeam.isAggregate === true ? 'Yes' : 'No'}</td>
        <td>{relayTeam?.swimTimeEntryOverride === true ? 'Yes' : 'No'}</td>
        <td>{relayTeam.status || ''}</td>
        <td>{onEditRelayTeamClicked && <button className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditRelayTeamClicked(e, relayTeam)}>
          <EditIcon />
        </button>}
          {onDeleteRelayTeamClicked && relayTeam.hasPaid === false && <button className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onDeleteRelayTeamClicked(e, relayTeam)}>
            <DeleteIcon />
          </button>}
          {Array.isArray(relayTeam.teamAthletes) && relayTeam.teamAthletes.length > 0 &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onClick(e, relayTeam.relayTeamName)}>
              {expandedId === relayTeam.relayTeamName ? <HideIcon /> : <ShowIcon />}
            </button>}
        </td>
      </tr>
      {expandedId === relayTeam.relayTeamName && Array.isArray(relayTeam.teamAthletes) && relayTeam.teamAthletes.length > 0 &&
        <tr className={global.Expanded}>
          <td colSpan="7">
            <LargeDetailTable teamAthletes={relayTeam.teamAthletes} relayTeam={relayTeam} />
          </td>
        </tr>}
    </Fragment>
  );
};

const OrgUnitAdminRelayEntriesTeamsLargeGrid = ({ gridData, isLoading, onEditRelayTeamClicked,
  onDeleteRelayTeamClicked, onClick, expandedId }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Team</th>
        <th>Entry Time</th>
        <th>Time Event</th>
        <th>Aggregate?</th>
        <th>Override?</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="7">Loading...</td></tr>
        : Array.isArray(gridData.relayTeams) && gridData?.relayTeams?.length > 0 ?
          gridData.relayTeams.map((relayTeam, i) =>
            <GridRow
              key={i}
              relayTeam={relayTeam}
              onEditRelayTeamClicked={onEditRelayTeamClicked}
              onDeleteRelayTeamClicked={onDeleteRelayTeamClicked}
              onClick={onClick}
              expandedId={expandedId}
            />)
          :
          <tr>
            <td colSpan={7}>No Relay Teams</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminRelayEntriesTeamsLargeGrid;