import React, { createContext, useState } from 'react';

import MeetData from './MeetData';

export const MeetStateContext = createContext();

const MeetContextProvider = ({ children }) => {
  const stateHook = useState(MeetData.INITIAL_STATE);

  return (
    <MeetStateContext.Provider value={stateHook}>
      {children}
    </MeetStateContext.Provider>
  );
};

export default MeetContextProvider;