import React, { Fragment } from 'react';

import useMeetAthleteEntry from './UseMeetAthleteEntry';

import MeetAthleteEntryGrid from './MeetAthleteEntryGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';
import DeleteOmeModalInfo from '../../myMeets/meetHost/DeleteOmeModalInfo';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const MeetAthleteEntry = () => {
  const { gridState,
    modalState,
    omeMeetAthleteEntryState,
    omeMeetOrgUnitAthleteEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    onCreateEntryClicked,
    onEditEntryClicked,
    onDeleteEntryClicked,
    onDeleteAthleteEntry,
    onModalCanceled } = useMeetAthleteEntry();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Athlete Entry Management</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <MeetAthleteEntryGrid
            gridData={gridState.arrayData}
            isLoading={gridState.isArrayLoading}
            onCreateEntryClicked={onCreateEntryClicked}
            onEditEntryClicked={onEditEntryClicked}
            onDeleteEntryClicked={onDeleteEntryClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className='col-xs-12'>
            <DeleteOmeModalInfo modalState={modalState} />
            <p><strong>Athlete:</strong> {modalState?.athleteName} ({modalState?.athleteCompetitionCategory}, {modalState?.athleteAgeAtMeet})</p>
            <p><strong>Athlete Birth Date:</strong> {modalState?.athleteBirthDate}</p>
            <p><strong>Athlete Entry Status:</strong> {modalState?.athleteEntryStatus}</p>
            <p><strong>Team Name:</strong> {modalState?.orgUnitName}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteAthleteEntry(e, modalState.orgUnitAthleteEntryId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetAthleteEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryState.isSaving} />
    </Fragment>);
}

export default MeetAthleteEntry;