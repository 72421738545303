import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import validate from './MeetStaffDetailValidation'

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useMeetStaffData from "../../../../common/state/meet/meetStaff/UseMeetStaffData";
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import UseForm from "../../../../common/utils/UseForm";
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useMeetStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState, getMeet } = useMeetData();
  const { meetStaffState, putMeetStaffData, confirmSaveMeetStaff } = useMeetStaffData();
  const { formState, errorState, setFormData, handleSubmit, onFormValueChanged, setIsDirty
  } = UseForm(getInitialFormState(), submitFormCallback, validate);
  const { environmentVariableState, MEET_DIRECTOR_STAFF_TYPE_ID,
    MEET_REFEREE_STAFF_TYPE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const [state, setState] = useState({ tryGetMeet: false, tryRedirect: false });

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    handleSubmit();
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_STAFF);
  };

  useEffect(() => {
    if (location.state && environmentVariableState.isLoaded === true) {
      let meetDirectorMemberId = '';
      let meetRefereeMemberId = ''
      for (let i = 0; i < meetStaffState.arrayData.length; i++) {
        if (meetStaffState.arrayData[i].meetStaffTypeId === MEET_DIRECTOR_STAFF_TYPE_ID) {
          meetDirectorMemberId = meetStaffState.arrayData[i].memberId || '';
        }
        else if (meetStaffState.arrayData[i].meetStaffTypeId === MEET_REFEREE_STAFF_TYPE_ID) {
          meetRefereeMemberId = meetStaffState.arrayData[i].memberId || '';
        }
      }
      setFormData({
        ...formState,
        meetStaffTypeId: location.state?.meetStaffTypeId || '',
        meetStaffTypeName: location.state?.meetStaffTypeName || '',
        isMeetDirector: location.state?.meetStaffTypeId === MEET_DIRECTOR_STAFF_TYPE_ID ? true : false,
        isMeetReferee: location.state?.meetStaffTypeId === MEET_REFEREE_STAFF_TYPE_ID ? true : false,
        meetDirectorMemberId: meetDirectorMemberId,
        meetRefereeMemberId: meetRefereeMemberId,
        personId: location.state?.personId || '',
        memberId: location.state?.memberId || '',
        OFFICIALS_ROLES_ROLE_GROUP_ID: OFFICIALS_ROLES_ROLE_GROUP_ID || '',
        meetStartDate: meetState.objData.startDate ? formatDate(meetState.objData.startDate) : Constants.BLANK_DATETIME_STRING,
        meetEndDate: meetState.objData.endDate ? formatDate(meetState.objData.endDate) : Constants.BLANK_DATETIME_STRING
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState])

  useEffect(() => {
    if (state.tryGetMeet === true && meetStaffState.isSaving === false &&
      meetStaffState.isSaved === true) {
      confirmSaveMeetStaff();
      getMeet(meetState.objData?.meetId);
      setState({ ...state, tryGetMeet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGetMeet, meetStaffState])

  useEffect(() => {
    if (state.tryRedirect === true && meetState.isObjLoading === false &&
      meetState.isObjLoaded === true) {
      navigate(NavLinks.MEET_STAFF, { state: { tryGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, meetState])

  function submitFormCallback(formState) {
    const meetStaffObj = {
      personId: formState.personId,
      meetStaffTypeId: formState.meetStaffTypeId
    };
    putMeetStaffData(meetState.objData?.meetId, meetStaffObj);
    setState({ ...state, tryGetMeet: true });
  };

  function getInitialFormState() {
    return ({
      meetStaffTypeId: '',
      meetStaffTypeName: '',
      isMeetDirector: false,
      isMeetReferee: false,
      meetDirectorMemberId: '',
      meetRefereeMemberId: '',
      personId: '',
      memberId: '',
      OFFICIALS_ROLES_ROLE_GROUP_ID: '',
      meetStartDate: Constants.BLANK_DATE_STRING,
      meetEndDate: Constants.BLANK_DATE_STRING
    })
  };

  return {
    meetStaffState,
    meetState,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onSaveButtonClicked,
    onBackButtonClicked
  };
};

export default useMeetStaffDetail;