/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useEventData from '../../../common/state/event/UseEventData';
import useMeetData from '../../../common/state/meet/UseMeetData';

const useAddMeetTimeEventCombobox = (isRelay) => {
  const [optionsState, setOptionsState] = useState([])
  const { meetState } = useMeetData();
  const { eventState } = useEventData();
  const [state, setState] = useState({ eventCourseArray: [] })

  function getEventCourseTypeFromMeetInfo() {
    const createMeetEventCourseTypes = meetState.objData?.meetCourse?.map(x => x.course.courseCode)
    setState({
      ...state,
      eventCourseArray: createMeetEventCourseTypes
    })
    return createMeetEventCourseTypes;
  }

  const createUniqueOptions = (timesData) => {
    const course = JSON.parse(JSON.stringify(state.eventCourseArray));
    const uniqueOptions = timesData.map(e => {
      const courseConfiguration = e.eventCode;

      if (courseConfiguration) {
        const newEventName = courseConfiguration.substring(courseConfiguration.length - 3, courseConfiguration.length);
        if (course.some(c => c === newEventName.trim())) {
          return {
            id: e.eventId, name: e.eventName
          }
        }
      }
    });

    if (isRelay === true) {
      for (let i = 0; i < uniqueOptions.length; i++) {
        if (uniqueOptions[i] && !uniqueOptions[i].name.includes('Relay')) {
          uniqueOptions[i] = undefined;
        }
      }
    } else {
      for (let i = 0; i < uniqueOptions.length; i++) {
        if (uniqueOptions[i] && uniqueOptions[i].name.includes('Relay')) {
          uniqueOptions[i] = undefined;
        }
      }
    }
    setOptionsState(uniqueOptions.filter(x => x !== undefined))
  }

  useEffect(() => {
    if (eventState.isArrayLoaded === true &&
      state.eventCourseArray.length > 0) {
      createUniqueOptions(eventState.arrayData);
    }
  }, [eventState.isArrayLoaded, state.eventCourseArray]);


  useEffect(() => {
    if (meetState.objData?.meetCourse?.length > 0) {
      getEventCourseTypeFromMeetInfo()
    }
  }, [])

  return {
    optionsState
  };
};

export default useAddMeetTimeEventCombobox;
