import useMeetData from '../../../../common/state/meet/UseMeetData';

const useMeetRecon = () => {
  const { meetState } = useMeetData();

  return {
    meetId: meetState.objData?.meetId || -1
  };
};

export default useMeetRecon;