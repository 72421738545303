export const localValidate = (formState) => {
  let errors = {};

  return errors;
};

const AthleteUserPurchases = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AthleteUserPurchases;