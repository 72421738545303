import { API_NAMES, initApi } from "../../../../utils/HttpApiHelper";

const deleteMeetTimesData = (meetId, state, setState) => {
  if (meetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const meetIdForUrl = encodeURIComponent(meetId);
    const url = `/Meet/${meetIdForUrl}/Times`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const DeleteMeetTimesData = {
  deleteMeetTimesData
};

export default DeleteMeetTimesData;