import { Fragment } from 'react';

import useAthleteUserEntriesDetail from './UseAthleteUserEntriesDetail';

import AthleteUserEntriesDetailGrid from './AthleteUserEntriesDetailGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AthleteUserEntriesDetailWrite = () => {
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
    omeMeetOrgUnitEntryTimesState, state, gridState, athleteMeetEventState,
    onAddEventClicked, onAddBonusEventClicked, onDeleteEventClicked, onDeleteEvent, modalState, onModalCanceled, onUpdateAthleteTimes,
    errorState } = useAthleteUserEntriesDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H3>Manage Athlete Events</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddEventClicked}>Select Events</ActionIntraPageButton>
          {state.bonusEvents === true && <Fragment>&nbsp;<ActionIntraPageButton onClick={onAddBonusEventClicked}>Select Bonus Events</ActionIntraPageButton></Fragment>}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AthleteUserEntriesDetailGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading}
            onDeleteEventClicked={onDeleteEventClicked}
          />
        </div>
      </div>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>
      }
      {gridState.gridData.length > 0 && gridState.showUpdateButton === true &&
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton onClick={(e) => onUpdateAthleteTimes(e)}>Update Individual Event Entry Times</PrimaryButton>
          </div>
        </div>}
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Athlete:</strong> {modalState.athlete}</p>
            <p><strong>Event - Course:</strong> {modalState.eventName}</p>
            <p><strong>Competition Category:</strong> {modalState.eventCompetitionGenderTypeName}</p>
            <p><strong>Age Group:</strong> {modalState.ageGroupName}</p>
            <p><strong>Entry Time:</strong> {modalState.entryTime || 'NT'} {modalState?.qualifyingCourseCode}</p>
            <p><strong>Time Event:</strong> {modalState.timeEvent || ''}</p>
            <p><strong>Bonus Entry:</strong> {modalState.qualifiedAsBonus}</p>
            <p><strong>Override Entry:</strong> {modalState.override}</p>
            <p><strong>Status:</strong> {modalState.status}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteEvent(e, modalState.individualAthleteMeetEventId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoading || omeMeetOrgUnitEntryTimesState.isSaving ||
          omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={athleteMeetEventState.isSaving} />
    </Fragment>
  );
};

export default AthleteUserEntriesDetailWrite;