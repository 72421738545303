import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitNonAthleteEntryCoachesSelectionData from './GetOrgUnitNonAthleteEntryCoachesSelectionData';
import postOrgUnitNonAthleteEntryCoachesSelectionData from './PostOrgUnitNonAthleteEntryCoachesSelectionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData = {
  INITIAL_STATE, getOrgUnitNonAthleteEntryCoachesSelectionData, postOrgUnitNonAthleteEntryCoachesSelectionData
}

export default OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData;