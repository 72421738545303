
import React, { Fragment } from 'react';

import useLaneSheetMeetEventsAddRelayTeams from './UseLaneSheetMeetEventsAddRelayTeams';

import LaneSheetMeetEventAddRelayTeamsGrid from '../../components/grids/LaneSheetMeetEventAddRelayTeamsGrid';
import LaneSheetAddRelayTeamsForm from '../../components/forms/LaneSheetAddRelayTeamsForm';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LaneSheetMeetEventsAddRelayTeams = () => {
  const {
    isSaving,
    isLoading,
    meetEvent,
    gridData,
    deleteModalState,
    onBackClicked,
    onSubmitFormCallback,
    onDeleteRelayTeamClicked,
    onDeleteModalCancelClicked,
    onDeleteModalDeleteClicked
  } = useLaneSheetMeetEventsAddRelayTeams();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Relay Teams To Lane Sheet Meet</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <label className={global.UsasLabel}><b>Event Name:</b> {meetEvent.eventName || ''}</label>
          <label className={global.UsasLabel}><b>Competition Category:</b> {meetEvent.eventCompetitionGenderTypeName || ''}</label>
        </div>
      </div>
      <LaneSheetAddRelayTeamsForm
        onSubmitFormCallback={onSubmitFormCallback}
        existingRelayTeams={gridData} />
      <LaneSheetMeetEventAddRelayTeamsGrid
        isLoading={isLoading}
        gridData={gridData}
        onDeleteClicked={onDeleteRelayTeamClicked} />
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Relay Team?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className='col-xs-12'><strong>Relay Team Name: </strong>{deleteModalState.relayTeam?.orgUnitName}</p>
            <p className='col-xs-12'><strong>Org Unit Code: </strong>{deleteModalState.relayTeam?.orgUnitCode}</p>
            <p className='col-xs-12'>Are you sure you want to delete this relay team?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LaneSheetMeetEventsAddRelayTeams;