import { useEffect, useState } from 'react';
import useMeetData from '../../../../common/state/meet/UseMeetData';

const useMeetInfo = () => {
    const { meetState, putMeet } = useMeetData();
    const [simpleFormState, setSimpleFormState] = useState(getInitialFormState());

    function createPUTObject() {
        const copyMeet = JSON.parse(JSON.stringify(meetState.objData));
    
        return {
          ...copyMeet,
          isUploadLocked: simpleFormState.isUploadLocked
        };
      };

    const onSaveClicked = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (meetState.isObjLoaded === true) {
            putMeet(meetState.objData.meetId, createPUTObject());
        }
    }

    const onSimpleFormValueChanged = (component, value) => {
        setSimpleFormState({ ...simpleFormState, [component]: value })
    }

    useEffect(() => {
        if (meetState.isObjLoaded === true && meetState.isSaving === false) {
            setSimpleFormState({
                ...simpleFormState,
                isUploadLocked: meetState.objData.isUploadLocked
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetState]);

    function getInitialFormState() {
        return {
            isUploadLocked: false
        };
    };

    return {
        meetState,
        simpleFormState,
        onSimpleFormValueChanged,
        onSaveClicked,
        futureMeet: new Date(meetState.objData.startDate) > new Date() 
    }
}

export default useMeetInfo;