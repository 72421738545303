import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.secondaryOrgUnitId === Constants.DEFAULT_ID && formState.secondaryOrgUnitName?.trim() !== '') {
    errors.secondaryOrgUnitId = 'Please select a valid Secondary Representation or remove all text to indicate that the athlete does not have a Secondary Representation'
  }

  if (formState.secondaryOrgUnitId === formState.teamOrgUnitId) {
    errors.secondaryOrgUnitId = 'Secondary Representation cannot be the same as the team associated with this entry';
  }

  return errors;
};

const OrgUnitAdminRosterSecondaryRepValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRosterSecondaryRepValidation;