import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

import { formatDate } from '../../../../../common/utils/DateFunctions';

function formatPlaceString(finalPosition) {
  if (finalPosition !== null) {
    const position = finalPosition.toString();

    if (position) {
      if (position.length > 1) {
        const lastTwoDigits = position.slice(-2);

        if (lastTwoDigits === '11' || lastTwoDigits === '12' || lastTwoDigits === '13') {
          return `${position}th`
        }
      }
      const lastDigit = position.slice(-1);

      if (lastDigit === '1') {
        return `${position}st`;
      }
      else if (lastDigit === '2') {
        return `${position}nd`;
      }
      else if (lastDigit === '3') {
        return `${position}rd`;
      }
      else {
        return `${position}th`;
      }
    }
  }

  return finalPosition;
}


const getMeetDates = (startDate, endDate) => {
  let dates = []
  const theStartDate = new Date(startDate)
  const theEndDate = new Date(endDate)
  while (theStartDate <= theEndDate) {
    dates = [...dates, formatDate(theStartDate)]
    let newStartDate = theStartDate.getDate() + 1;
    theStartDate.setDate(newStartDate)
  }
  return dates
}

/**
 * @param {Array} treeArrayData 
 * @param {String} dataIdKey
 * @param {String} dataNameKey
 * @param {Array} selectedIdValues optional
 * @returns {Object} An object with two fields, newTreeData that is used to populate the tree component & idNamePairs that is
 * used to initially select options in the tree 
 */
const createTreeViewOptions = (treeArrayData, dataIdKey, dataNameKey, selectedIdValues) => {
  const newTreeData = HierarchicalDataUtils.MapArrayToTreeData(treeArrayData, dataIdKey, dataNameKey);
  const idNamePairs = Array.isArray(newTreeData) === true ? HierarchicalDataUtils.GetNameIdPairs(newTreeData, selectedIdValues || []) : [];

  return { newTreeData, idNamePairs };
};

const isValidCompetitorGender = (competitorGender, eventGender) => {
  let errorMessage = '';

  if (eventGender) {
    if (competitorGender !== eventGender) {
      errorMessage = `Athlete's gender (${competitorGender}) does not match Event Gender`;
    }
  }

  return errorMessage;
}

export { formatPlaceString, createTreeViewOptions, getMeetDates, isValidCompetitorGender }