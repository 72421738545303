import { Fragment } from 'react';

import useLaneSheetMeetEvents from './UseLaneSheetMeetEvents';
import LaneSheetMeetEventsGrid from '../components/grids/LaneSheetMeetEventsGrid';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const LaneSheetMeetEvents = () => {
  const {
    isSaving,
    isLoading,
    gridData,
    omeMeetId,
    deleteModalState,
    onBulkAddFromOmeClicked,
    onAddMeetEventClicked,
    onAddParticipantsClicked,
    onDeleteMeetEventClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  } = useLaneSheetMeetEvents();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Events For Lane Sheet</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          {omeMeetId &&
            <Fragment><ActionIntraPageButton onClick={onBulkAddFromOmeClicked}>Bulk Add From OME</ActionIntraPageButton>&nbsp;</Fragment>
          }
          <ActionIntraPageButton onClick={onAddMeetEventClicked}>Add Event</ActionIntraPageButton>
        </div>
      </div>
      <LaneSheetMeetEventsGrid
        isLoading={isLoading}
        gridData={gridData}
        onEditClicked={onAddParticipantsClicked}
        onDeleteClicked={onDeleteMeetEventClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Event?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className='col-xs-12'><strong>Event Name / Code: </strong>{deleteModalState.meetEvent?.eventName || ''} / {deleteModalState.meetEvent?.eventCode}</p>
            <p className='col-xs-12'><strong>Competition Category: </strong>{deleteModalState.meetEvent?.eventCompetitionGenderTypeName || ''}</p>
            <p className='col-xs-12'><strong>Number of Participants: </strong>{deleteModalState.meetEvent?.numberOfParticipants ?? ''}</p>
            <p className='col-xs-12'>Are you sure you want to delete this event?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LaneSheetMeetEvents;