import React, { Fragment } from 'react';

import NavLinks from '../NavLinks';

import useMeetSessions from './UseMeetSessions';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Sessions from '../../../../common/components/meetSessions/Sessions';

const MeetSessionsWrite = () => {
  const {
    meetState,
    isLoading,
    isSaving,
    Constants,
    onAddSession,
    onEditSession,
    onDeleteSession
  } = useMeetSessions();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Sessions</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <Sessions
            showOptionalFields={true}
            requiredFieldsReadOnly={false}
            meetSessionArray={meetState?.objData?.meetSession}
            meetId={meetState?.objData?.meetId}
            meetStartDate={meetState?.objData?.startDate}
            meetEndDate={meetState?.objData?.endDate}
            onAdd={onAddSession}
            onEdit={onEditSession}
            onDelete={onDeleteSession}
            deleteButtonFirst={true}
            saveNavLink={NavLinks.MEET_SESSIONS}
            backNavLink={NavLinks.MEET_SESSIONS}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default MeetSessionsWrite;