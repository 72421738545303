import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryRelayRosterEntryData from './GetOrgUnitAthleteEntryRelayRosterEntryData';
import getOrgUnitAthleteEntryRelayQualifyingTeamData from './GetOrgUnitAthleteEntryRelayQualifyingTeamData';
import postRelayMeetEventEntryData from './PostRelayMeetEventEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitAthleteEntryRelayRosterEntryData = {
  INITIAL_STATE, getOrgUnitAthleteEntryRelayRosterEntryData, getOrgUnitAthleteEntryRelayQualifyingTeamData, postRelayMeetEventEntryData
}

export default OmeMeetOrgUnitAthleteEntryRelayRosterEntryData;