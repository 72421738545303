import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AthleteUserCoachesSelectionValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData from '../../../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection/UseOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  isInGoodStanding: true,
  firstOrPreferredName: '',
  lastName: ''
};

const useAthleteUserCoachesSelection = () => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState } = useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState, clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionArrayData,
    getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection, postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection } = useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData();
  const [state, setState] = useState({ initialStateHandled: false, tryRedirect: false });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const { handleSubmit, formState, errorState, setErrors, setFormState, onFormValueChanged, onValueTextPairChanged } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  }

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  }

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let deleteAfterPayment = false;
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.status !== Constants.WORKFLOW_STATUS_SUBMITTED) {
      for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
        if (selectionChangesState.arrayData[i].orgUnitAthleteEntryOrgUnitNonAthleteEntryId !== null && selectionChangesState.arrayData[i].isSelected === false &&
          selectionChangesState.arrayData[i].hasPaid === true) {
          deleteAfterPayment = true;
        }
      }
    }

    if (deleteAfterPayment === true) {
      setErrors({ ...errorState, general: `The current selections removed a coach with a paid for credential. Removing a coach after their credential has been purchased is not allowed.` });
    }
    else {
      let coachPassRuleError = false;
      //Compare maxCoachNumber to number of selections
      if (omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber) {
        let existingCoachNum = omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length;
        let newSelectionsArray = selectionChangesState.arrayData?.filter(x => (x.isSelected === true && x.orgUnitAthleteEntryOrgUnitNonAthleteEntryId === null));
        let newSelectionsNum = newSelectionsArray ? newSelectionsArray.length : 0;
        let newUnselectionsArray = selectionChangesState.arrayData?.filter(x => (x.isSelected === false && x.orgUnitAthleteEntryOrgUnitNonAthleteEntryId !== null));
        let newUnselectionsNum = newUnselectionsArray ? newUnselectionsArray.length : 0;
        let newCoachNum = existingCoachNum + newSelectionsNum - newUnselectionsNum;
        if (newCoachNum > omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber) {
          coachPassRuleError = true;
        }
      }
      if (coachPassRuleError === true) {
        setErrors({ ...errorState, general: `The current selections exceed the number of allowed coaches according to the meet's coach pass rules. Only ${omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber || ''} coach ${omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber === 1 ? 'selection is' : 'selections are'} allowed.` });
      }
      else {
        if (selectionChangesState.arrayData.length > 0) {
          let allSelectionsInGoodStanding = true;
          for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
            if (selectionChangesState.arrayData[i].isInGoodStanding === false && selectionChangesState.arrayData[i].isSelected === true) {
              allSelectionsInGoodStanding = false
            }
          }
          if (allSelectionsInGoodStanding === true) {
            postOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId, {
              maxCoachNumber: omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber,
              coaches: selectionChangesState.arrayData
            });
            setState({ ...state, tryRedirect: true });
          }
          else {
            setErrors({ ...errorState, general: "All selections must be 'In Good Standing'. At least one selection is 'Not In Good Standing', which is not allowed." })
          }
        }
        else {
          setState({ ...state, tryRedirect: true });
        }
      }
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_COACHES);
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_COACHES);
    }
  }

  const onCheckboxChange = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === personId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.personId === personId);

    if (selectedAthleteIndexArrayData > -1) {
      updatedArrayData[selectedAthleteIndexArrayData].isSelected = !updatedArrayData[selectedAthleteIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isSelected = !updatedGridData[selectedAthleteIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }
    if (selectedAthleteIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  function submitFormCallback(formState) {
    getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelection(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId, createFilterObj(formState));
  };

  function createFilterObj(formState) {
    return {
      firstName: formState.firstOrPreferredName.trim() !== '' ? formState.firstOrPreferredName.trim() : undefined,
      lastName: formState.lastName.trim() !== '' ? formState.lastName.trim() : undefined,
      isInGoodStanding: formState.isInGoodStanding ?? undefined
    }
  }

  useEffect(() => {
    clearOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionArrayData();
    setState({ ...state, initialStateHandled: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.initialStateHandled === true && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoaded === true && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoading === false) {
      const arrayDataCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.arrayData?.coaches || []));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }
      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialStateHandled, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoaded, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isArrayLoading])

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState.isSaving === false) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.ATHLETE_COACHES, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
      }
      else {
        //Meet Route
        navigate(NavLinks.ATHLETE_COACHES, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState])

  return {
    gridState,
    omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionState,
    onSaveClicked,
    onBackClicked,
    onCheckboxChange,
    onFilterClicked,
    onClearFilterClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    formState,
    errorState
  };
}

export default useAthleteUserCoachesSelection;