import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postLaneSheetMeetEventAthleteData = (laneSheetMeetEventAthleteObj, state, setState) => {
  if (laneSheetMeetEventAthleteObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/LaneSheetPerson`;

    return api?.executeObject ? api.executeObject(url, 'POST', laneSheetMeetEventAthleteObj) : null;
  }
};

const getLaneSheetMeetEventAthletesData = (laneSheetEventId, state, setState) => {
  if (laneSheetEventId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetEventIdForUrl = encodeURIComponent(laneSheetEventId);
    const url = `/LaneSheetEvent/${laneSheetEventIdForUrl}/Athlete`
   
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const deleteLaneSheetMeetEventAthleteData = (laneSheetPersonId, state, setState) => {
  if (laneSheetPersonId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetPersonIdForUrl = encodeURIComponent(laneSheetPersonId);
    const url = `/LaneSheetPerson/${laneSheetPersonIdForUrl}`;
    
    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LaneSheetMeetEventAthletesData = {
  postLaneSheetMeetEventAthleteData,
  getLaneSheetMeetEventAthletesData,
  deleteLaneSheetMeetEventAthleteData
};

export default LaneSheetMeetEventAthletesData;