export const localValidate = (formState) => {
  let errors = {};

  if (formState.leg1WillAttend === false) {
    if (!(formState.leg1UnavailableReasonId > 0)) {
      if (errors.gridError) {
        errors.gridError += ' | Unavailable Reason for Leg 1 is required';
      } else {
        errors.gridError = 'Unavailable Reason for Leg 1 is required';
      }
    }
  }

  if (formState.leg2WillAttend === false) {
    if (!(formState.leg2UnavailableReasonId > 0)) {
      if (errors.gridError) {
        errors.gridError += ' | Unavailable Reason for Leg 2 is required';
      } else {
        errors.gridError = 'Unavailable Reason for Leg 2 is required';
      }
    }
  }

  if (formState.leg3WillAttend === false) {
    if (!(formState.leg3UnavailableReasonId > 0)) {
      if (errors.gridError) {
        errors.gridError += ' | Unavailable Reason for Leg 3 is required';
      } else {
        errors.gridError = 'Unavailable Reason for Leg 3 is required';
      }
    }
  }

  if (formState.leg4WillAttend === false) {
    if (!(formState.leg4UnavailableReasonId > 0)) {
      if (errors.gridError) {
        errors.gridError += ' | Unavailable Reason for Leg 4 is required';
      } else {
        errors.gridError = 'Unavailable Reason for Leg 4 is required';
      }
    }
  }

  return errors;
};

const ParticipantRelayEditFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ParticipantRelayEditFormValidation;