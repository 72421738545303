import useLaneSheetAddMeetEventForm from "./UseLaneSheetAddMeetEventForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import EventMultiSelect from "../../../../../common/components/multiSelect/eventMultiSelect/EventMultiSelect";
import CompetitionCategoryMultiSelect from "../../../../../common/components/multiSelect/competitionCategoryMultiSelect/CompetitionCategoryMultiSelect";

const LaneSheetAddMeetEventForm = ({ primaryButtonText = 'Save', secondaryButtonText = 'Back',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit
  } = useLaneSheetAddMeetEventForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <CompetitionCategoryMultiSelect
            label="Competition Category*"
            name="competitionCategoryId"
            value={formState.competitionCategoryId}
            error={errorState.competitionCategoryId}
            message={errorState.competitionCategoryId}
            onChange={(value) => { onFormValueChanged('competitionCategoryId', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <EventMultiSelect
            label="Event Name and Distance*"
            name="eventNameAndDistanceId"
            value={formState.eventNameAndDistanceId}
            error={errorState.eventNameAndDistanceId}
            message={errorState.eventNameAndDistanceId}
            onChange={(value) => { onFormValueChanged('eventNameAndDistanceId', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetAddMeetEventForm;