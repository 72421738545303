import useParticipantRelayEditForm from "./UseParticipantRelayEditForm";

import SelectionParticipantRelayFormGrid from "../grids/SelectionParticipantRelayFormGrid";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";

const ParticipantRelayEditForm = ({ onSubmitFormCallback, onSecondaryButtonClicked, submitButtonText, secondaryButtonText,
  participantObj, selectionsStateObj }) => {
  const {
    formState,
    errorState,
    onWillAttendChanged,
    onValueTextPairChanged,
    handleSubmit
  } = useParticipantRelayEditForm(onSubmitFormCallback, participantObj, selectionsStateObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Meet Name'
            name="meetName"
            value={formState.meetName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Meet Dates'
            name="meetDates"
            value={formState.meetDates} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Relay Team Name'
            name="relayTeamName"
            value={formState.relayTeamName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Organization'
            name="parentOrgUnitName"
            value={formState.parentOrgUnitName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Gender'
            name="eventCompetitionGenderTypeName"
            value={formState.eventCompetitionGenderTypeName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Team'
            name="orgUnitName"
            value={formState.orgUnitName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Event'
            name="eventCode"
            value={formState.eventCode} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Entry Time'
            name="entryTime"
            value={formState.entryTime} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Qualifying Swim Date'
            name="qualifyingSwimDate"
            value={formState.qualifyingSwimDate} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Qualifying Meet'
            name="qualifyingMeet"
            value={formState.qualifyingMeet} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Selected'
            name="isSelected"
            value={formState.isSelected} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Alternate'
            name="isAlternate"
            value={formState.isAlternate} />
        </div>
        {formState.selectionReason &&
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Selection Reason'
              name="selectionReason"
              value={formState.selectionReason} />
          </div>
        }
      </div>
      <SelectionParticipantRelayFormGrid
        formState={formState}
        errorState={errorState}
        onWillAttendChanged={onWillAttendChanged}
        onValueTextPairChanged={onValueTextPairChanged} />
      <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default ParticipantRelayEditForm;