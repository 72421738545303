import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeMeetInfoValidationNonHQ';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useDateTimeData from '../../../../../common/state/datetime/UseDateTimeData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';

import { eventTimeCutsAreComplete } from '../../../../components/leftNavigation/LeftNavValidation';

import { formatDate, formatDateTime } from '../../../../../common/utils/DateFunctions';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  tryGetDateValidation: false,
  omeMeetEntryCourseId: Constants.DEFAULT_ID
};

const INITIAL_FORM_STATE = {
  meetStartDate: Constants.BLANK_DATE_STRING,
  meetEndDate: Constants.BLANK_DATE_STRING,
  timeZoneId: '',
  timeZoneValue: 'Time Zone',
  meetCreationDate: Constants.BLANK_DATE_STRING,
  publishDate: Constants.BLANK_DATETIME_STRING,
  meetEntryStartDate: Constants.BLANK_DATETIME_STRING,
  meetEntryEndDate: Constants.BLANK_DATETIME_STRING,
  meetEntryChangeDeadline: Constants.BLANK_DATETIME_STRING,
  athleteAgeAsOfDate: Constants.BLANK_DATE_STRING,
  resultsPublishDate: Constants.BLANK_DATE_STRING,
  meetPacketUrl: ''
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
  courseId: Constants.DEFAULT_ID,
  courseCode: '',
  priority: '',
  omeMeetEntryCourseId: Constants.DEFAULT_ID,
  showAdditionalWarningMsg: false,
  additionalWarningMsg: ''
};

const useOmeMeetInfoNonHQ = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState, getOmeMeetDateValidation } = useOmeMeetDateValidationData();
  const { dateTimeState } = useDateTimeData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { orgUnitState } = useOrgUnitData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { formState, errorState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormData, isSubmitting, setErrors
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [gridState, setGridState] = useState({ sortedGridData: [] });

  const tryValidateBeforeRedirect = async (submitButton, omeMeetEntryCourseId) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({
        ...state, tryGetDateValidation: true, submitButton: submitButton,
        omeMeetEntryCourseId: omeMeetEntryCourseId ? omeMeetEntryCourseId : Constants.DEFAULT_ID
      });
    }
  };

  const tryValidateBeforeShowingDeletePopUp = async (coursePriority) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {

      let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
      const isSaved = formStateHasBeenSaved(formState);
      if (isSaved === false) {
        updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
      }

      const selectedOmeMeetEntryCourseIndex = updatedOmeMeet.omeMeetEntryCourse.findIndex(x =>
        x.omeMeetEntryCourseId === coursePriority.omeMeetEntryCourseId);

      if (selectedOmeMeetEntryCourseIndex > -1) {
        updatedOmeMeet.omeMeetEntryCourse.splice(selectedOmeMeetEntryCourseIndex, 1);
      }

      let showAdditionalWarningMsg = false;
      let additionalWarningMsg = '';

      let meetHasEventsInCourseCodeToBeDeleted = false;
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup?.length; i++) {
        for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i]?.meetEvent.length; j++) {
          if (updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].event?.courseId === coursePriority.courseId) {
            meetHasEventsInCourseCodeToBeDeleted = true;
          }
        }
      }

      if (meetHasEventsInCourseCodeToBeDeleted === true) {
        showAdditionalWarningMsg = true;
        additionalWarningMsg = `Deleting this Course Priority will also delete all ${coursePriority.course?.courseCode || ''} Events.`;
      }

      if (eventTimeCutsAreComplete(updatedOmeMeet, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID) === false) {
        showAdditionalWarningMsg = true;
        additionalWarningMsg = additionalWarningMsg !== '' ? additionalWarningMsg + `\n Deleting this Course Priority will also delete all Individual Event ${coursePriority.course?.courseCode || ''} Time Standard Cuts.` :
          `Deleting this Course Priority will also delete all Individual Event ${coursePriority.course?.courseCode || ''} Time Standard Cuts.`;
      }

      setModalState({
        ...modalState,
        displayPopUp: true,
        courseId: coursePriority.course?.courseId || Constants.DEFAULT_ID,
        courseCode: coursePriority.course?.courseCode || '',
        priority: coursePriority.priority >= 0 ? coursePriority.priority : '',
        omeMeetEntryCourseId: coursePriority.omeMeetEntryCourseId || Constants.DEFAULT_ID,
        showAdditionalWarningMsg,
        additionalWarningMsg
      });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const isSaved = formStateHasBeenSaved(formState);
    if (isSaved === true) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  }

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const isSaved = formStateHasBeenSaved(formState);
    if (isSaved === true) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT });
    }
  };

  const onAddCoursePriorityClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.ADD);
  }

  const onEditCoursePriorityClicked = (e, omeMeetEntryCourseId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.EDIT, omeMeetEntryCourseId);
  }

  const onDeleteCoursePriorityClicked = (e, coursePriority) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeShowingDeletePopUp(coursePriority);
  };

  const onDeleteCoursePriority = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();

    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    const isSaved = formStateHasBeenSaved(formState);
    if (isSaved === false) {
      updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    }

    const selectedOmeMeetEntryCourseIndex = updatedOmeMeet.omeMeetEntryCourse.findIndex(x =>
      x.omeMeetEntryCourseId === modalState.omeMeetEntryCourseId);

    if (selectedOmeMeetEntryCourseIndex > -1) {
      updatedOmeMeet.omeMeetEntryCourse.splice(selectedOmeMeetEntryCourseIndex, 1);
      updatedOmeMeet = removeAnyTimeStandardCuts(updatedOmeMeet);

      //Remove any events and time cuts associated with the course priority that is being deleted
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup?.length; i++) {
        let updatedMeetEvent = [];
        for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i]?.meetEvent?.length; j++) {
          if (updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].event?.courseId !== modalState.courseId) {
            const meetEventCopy = JSON.parse(JSON.stringify(updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j]));

            const meetEventQualifyingTimeUpdated = [];
            for (let k = 0; k < updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventQualifyingTime.length; k++) {
              if (updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventQualifyingTime[k].event?.courseId !== modalState.courseId) {
                meetEventQualifyingTimeUpdated.push(updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventQualifyingTime[k]);
              }
            }
            meetEventCopy.meetEventQualifyingTime = meetEventQualifyingTimeUpdated;

            const meetEventQualificationLimitUpdated = [];
            for (let k = 0; k < updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventQualificationLimit.length; k++) {
              if (updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventQualificationLimit[k].event?.courseId !== modalState.courseId) {
                meetEventQualificationLimitUpdated.push(updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventQualificationLimit[k]);
              }
            }
            meetEventCopy.meetEventQualificationLimit = meetEventQualificationLimitUpdated;


            const meetEventBonusTimeUpdated = [];
            for (let k = 0; k < updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventBonusTime.length; k++) {
              if (updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventBonusTime[k].event?.courseId !== modalState.courseId) {
                meetEventBonusTimeUpdated.push(updatedOmeMeet.meetAgeGroup[i]?.meetEvent[j].meetEventBonusTime[k]);
              }
            }
            meetEventCopy.meetEventBonusTime = meetEventBonusTimeUpdated;

            updatedMeetEvent.push(meetEventCopy);
          }
        }
        updatedOmeMeet.meetAgeGroup[i].meetEvent = updatedMeetEvent;
      }
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    }
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const editOmeMeetCopy = (updatedOmeMeet) => {
    updatedOmeMeet = {
      ...updatedOmeMeet,
      timeZone: formState.timeZoneValue,
      publishDate: formState.publishDate,
      meetEntryStartDate: formState.meetEntryStartDate,
      meetEntryEndDate: formState.meetEntryEndDate,
      meetEntryChangeDeadline: formState.meetEntryChangeDeadline,
      athleteAgeAsOfDate: formState.athleteAgeAsOfDate,
      resultsPublishDate: formState.resultsPublishDate !== Constants.BLANK_DATE_STRING ? formState.resultsPublishDate : null,
      meetPacketUrl: formState.meetPacketUrl.trim() || null
    }

    //handle changing meet qualifying end date automatically for meets with time standards that do not use a qualifying window
    if (updatedOmeMeet.omeMeetQualification.length > 0 && updatedOmeMeet.omeMeetQualification[0]?.hasTimeStandards === true) {
      const hasQualifyingWindow = (updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingStartDate && formatDate(updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingStartDate) !== Constants.DEFAULT_MIN_DATE) ||
        (updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingEndDate && formatDate(updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingEndDate) !== formatDate(omeMeetState.objData?.meetEntryEndDate)) ?
        true : false;
      if (hasQualifyingWindow === false) {
        updatedOmeMeet.omeMeetQualification = [{
          ...updatedOmeMeet.omeMeetQualification[0],
          timeStandardsQualifyingEndDate: formState.meetEntryEndDate
        }]
      }
    }

    return updatedOmeMeet;
  }

  const removeAnyTimeStandardCuts = (updatedOmeMeet) => {
    //Remove any time standard cuts associated with the course that is being deleted
    if (updatedOmeMeet.meetAgeGroup?.length > 0) {
      for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
        if (updatedOmeMeet.meetAgeGroup[i].meetEvent.length > 0) {
          if (updatedOmeMeet.omeMeetEntryCourse.length > 0) {
            const scy = updatedOmeMeet.omeMeetEntryCourse.findIndex(x => x.courseId === SCY_COURSE_ID) > -1 ? true : false;
            const scm = updatedOmeMeet.omeMeetEntryCourse.findIndex(x => x.courseId === SCM_COURSE_ID) > -1 ? true : false;
            const lcm = updatedOmeMeet.omeMeetEntryCourse.findIndex(x => x.courseId === LCM_COURSE_ID) > -1 ? true : false;
            for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetEvent.length; j++) {
              // if (updatedOmeMeet.meetAgeGroup[i].meetEvent[j].event?.isRelay === false) {
              let meetEventQualifyingTimeIndex = -1;
              let meetEventQualificationLimitIndex = -1;
              let meetEventBonusTimeIndex = -1;
              if (updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.length > 0) {
                const scyTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === SCY_COURSE_ID) > -1 ? true : false;
                const scmTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === SCM_COURSE_ID) > -1 ? true : false;
                const lcmTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === LCM_COURSE_ID) > -1 ? true : false;

                if (scy === false && scyTimeMatch === true) {
                  meetEventQualifyingTimeIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === SCY_COURSE_ID);
                } else if (scm === false && scmTimeMatch === true) {
                  meetEventQualifyingTimeIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === SCM_COURSE_ID);
                } else if (lcm === false && lcmTimeMatch === true) {
                  meetEventQualifyingTimeIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.findIndex(x => x.courseId === LCM_COURSE_ID);
                }
              }
              if (meetEventQualifyingTimeIndex > -1) {
                updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime.splice(meetEventQualifyingTimeIndex, 1);
              }

              if (updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.length > 0) {
                const scyTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === SCY_COURSE_ID) > -1 ? true : false;
                const scmTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === SCM_COURSE_ID) > -1 ? true : false;
                const lcmTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === LCM_COURSE_ID) > -1 ? true : false;

                if (scy === false && scyTimeMatch === true) {
                  meetEventQualificationLimitIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === SCY_COURSE_ID);
                } else if (scm === false && scmTimeMatch === true) {
                  meetEventQualificationLimitIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === SCM_COURSE_ID);
                } else if (lcm === false && lcmTimeMatch === true) {
                  meetEventQualificationLimitIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.findIndex(x => x.courseId === LCM_COURSE_ID);
                }
              }
              if (meetEventQualificationLimitIndex > -1) {
                updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit.splice(meetEventQualificationLimitIndex, 1);
              }

              if (updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.length > 0) {
                const scyTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === SCY_COURSE_ID) > -1 ? true : false;
                const scmTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === SCM_COURSE_ID) > -1 ? true : false;
                const lcmTimeMatch = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === LCM_COURSE_ID) > -1 ? true : false;

                if (scy === false && scyTimeMatch === true) {
                  meetEventBonusTimeIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === SCY_COURSE_ID);
                } else if (scm === false && scmTimeMatch === true) {
                  meetEventBonusTimeIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === SCM_COURSE_ID);
                } else if (lcm === false && lcmTimeMatch === true) {
                  meetEventBonusTimeIndex = updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.findIndex(x => x.courseId === LCM_COURSE_ID);
                }
              }
              if (meetEventBonusTimeIndex > -1) {
                updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime.splice(meetEventBonusTimeIndex, 1);
              }
              // }
            }
          }
          else {
            for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetEvent.length; j++) {
              // if (updatedOmeMeet.meetAgeGroup[i].meetEvent[j].event?.isRelay === false) {
              updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualifyingTime = [];
              updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventQualificationLimit = [];
              updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventBonusTime = [];
            }
            // }
          }
        }
      }
    }

    return updatedOmeMeet;
  }

  function submitFormCallback(formState) {
    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));

    updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryGetDateValidation: true });
  }

  function formStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    if (formState?.timeZoneValue !== omeMeetState.objData?.timeZone
      && (formState?.timeZoneValue !== 'Time Zone' || omeMeetState.objData?.timeZone !== null)) {
      return false;
    }
    if (formState?.publishDate !== formatDateTime(omeMeetState.objData?.publishDate)
      && (formState?.publishDate !== Constants.BLANK_DATETIME_STRING || omeMeetState.objData?.publishDate !== null)) {
      return false;
    }
    if (formState?.meetEntryStartDate !== formatDateTime(omeMeetState.objData?.meetEntryStartDate)
      && (formState?.meetEntryStartDate !== Constants.BLANK_DATETIME_STRING || omeMeetState.objData?.meetEntryStartDate !== null)) {
      return false;
    }
    if (formState?.meetEntryEndDate !== formatDateTime(omeMeetState.objData?.meetEntryEndDate)
      && (formState?.meetEntryEndDate !== Constants.BLANK_DATETIME_STRING || omeMeetState.objData?.meetEntryEndDate !== null)) {
      return false;
    }
    if (formState?.meetEntryChangeDeadline !== formatDateTime(omeMeetState.objData?.meetEntryChangeDeadline)
      && (formState?.meetEntryChangeDeadline !== Constants.BLANK_DATETIME_STRING || omeMeetState.objData?.meetEntryChangeDeadline !== null)) {
      return false;
    }
    if (formState?.athleteAgeAsOfDate !== formatDate(omeMeetState.objData?.athleteAgeAsOfDate)
      && (formState?.athleteAgeAsOfDate !== Constants.BLANK_DATE_STRING || omeMeetState.objData?.athleteAgeAsOfDate !== null)) {
      return false;
    }
    if (formState?.resultsPublishDate !== formatDate(omeMeetState.objData?.resultsPublishDate)
      && (formState?.resultsPublishDate !== Constants.BLANK_DATE_STRING || omeMeetState.objData?.resultsPublishDate !== null)) {
      return false;
    }

    if (formState?.meetPacketUrl !== omeMeetState.objData?.meetPacketUrl
      && (formState?.meetPacketUrl !== '' || omeMeetState.objData?.meetPacketUrl !== null)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && omeMeetDateValidationState.isObjLoaded === false &&
      omeMeetDateValidationState.isObjLoading === false) {
      getOmeMeetDateValidation(omeMeetState?.objData?.omeMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, omeMeetDateValidationState])

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && dateTimeState.isObjLoaded === true && dateTimeState?.objData?.timeZones?.length > 0) {
      //Sort course grid data to display in descending 1-3 order
      let omeMeetEntryCourseCopy = [];
      if (omeMeetState.objData.omeMeetEntryCourse && omeMeetState.objData.omeMeetEntryCourse.length > 0) {
        omeMeetEntryCourseCopy = JSON.parse(JSON.stringify(omeMeetState.objData.omeMeetEntryCourse));
        omeMeetEntryCourseCopy.sort(function (a, b) { return a.priority - b.priority });
      }
      setGridState({
        ...gridState,
        sortedGridData: omeMeetEntryCourseCopy
      });

      //Set formState from location.state if 'Back' button was clicked on bonus event detail page
      if (location.state && location.state?.meetInfoFormState) {
        setFormData({
          ...formState,
          meetStartDate: location.state?.meetInfoFormState?.meetStartDate ? formatDate(location.state?.meetInfoFormState?.meetStartDate) : Constants.BLANK_DATE_STRING,
          meetEndDate: location.state?.meetInfoFormState?.meetEndDate ? formatDate(location.state?.meetInfoFormState?.meetEndDate) : Constants.BLANK_DATE_STRING,
          timeZoneId: location.state?.meetInfoFormState?.timeZoneId || '',
          timeZoneValue: location.state?.meetInfoFormState?.timeZoneValue || 'Time Zone',
          publishDate: location.state?.meetInfoFormState?.publishDate || Constants.BLANK_DATETIME_STRING,
          meetEntryStartDate: location.state?.meetInfoFormState?.meetEntryStartDate || Constants.BLANK_DATETIME_STRING,
          meetEntryEndDate: location.state?.meetInfoFormState?.meetEntryEndDate || Constants.BLANK_DATETIME_STRING,
          meetEntryChangeDeadline: location.state?.meetInfoFormState?.meetEntryChangeDeadline || Constants.BLANK_DATETIME_STRING,
          athleteAgeAsOfDate: location.state?.meetInfoFormState?.athleteAgeAsOfDate ? formatDate(location.state?.meetInfoFormState?.athleteAgeAsOfDate) : Constants.BLANK_DATE_STRING,
          resultsPublishDate: location.state?.meetInfoFormState?.resultsPublishDate ? formatDate(location.state?.meetInfoFormState?.resultsPublishDate) : Constants.BLANK_DATE_STRING,
          meetPacketUrl: location.state?.meetInfoFormState?.meetPacketUrl || ''
        });
      }
      else {
        //Set formState from omeMeetState
        setFormData({
          ...formState,
          meetStartDate: omeMeetState.selectedMeet?.startDate ? formatDate(omeMeetState.selectedMeet?.startDate) : Constants.BLANK_DATE_STRING,
          meetEndDate: omeMeetState.selectedMeet?.endDate ? formatDate(omeMeetState.selectedMeet?.endDate) : Constants.BLANK_DATE_STRING,
          timeZoneId: omeMeetState.objData?.timeZone ? dateTimeState?.objData?.timeZones?.find(x => x.id === omeMeetState.objData?.timeZone)?.name || '' : '',
          timeZoneValue: omeMeetState.objData?.timeZone || 'Time Zone',
          publishDate: omeMeetState.objData?.publishDate ? formatDateTime(omeMeetState.objData?.publishDate) : Constants.BLANK_DATETIME_STRING,
          meetEntryStartDate: omeMeetState.objData?.meetEntryStartDate ? formatDateTime(omeMeetState.objData?.meetEntryStartDate) : Constants.BLANK_DATETIME_STRING,
          meetEntryEndDate: omeMeetState.objData?.meetEntryEndDate ? formatDateTime(omeMeetState.objData?.meetEntryEndDate) : Constants.BLANK_DATETIME_STRING,
          meetEntryChangeDeadline: omeMeetState.objData?.meetEntryChangeDeadline ? formatDateTime(omeMeetState.objData?.meetEntryChangeDeadline) : Constants.BLANK_DATETIME_STRING,
          athleteAgeAsOfDate: omeMeetState.objData?.athleteAgeAsOfDate ? formatDate(omeMeetState.objData?.athleteAgeAsOfDate) : Constants.BLANK_DATE_STRING,
          resultsPublishDate: omeMeetState.objData?.resultsPublishDate ? formatDate(omeMeetState.objData?.resultsPublishDate) : Constants.BLANK_DATE_STRING,
          meetPacketUrl: omeMeetState.objData?.meetPacketUrl || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, dateTimeState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && dateTimeState.isObjLoaded === true) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData, dateTimeState.isObjLoaded]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryGetDateValidation === true) {
      getOmeMeetDateValidation(omeMeetState.objData.omeMeetId);
      setState({ ...state, tryGetDateValidation: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state])

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState?.objData?.omeMeetEntryCourse && omeMeetState?.objData?.omeMeetEntryCourse?.length === 0) {
          setErrors({ ...errorState, error: 'At least one Course Priority is required' });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_ELIGIBILITY);
          }
          else {
            navigate(HostNavLinks.OME_MEET_ELIGIBILITY);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState?.objData?.omeMeetEntryCourse && omeMeetState?.objData?.omeMeetEntryCourse?.length === 0) {
          setErrors({ ...errorState, error: 'At least one Course Priority is required' });
        }
        else {
          if (omeMeetState.route !== '') {
            setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
            navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
          }
          else {
            navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.ADD) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_INFO_COURSE_PRIORITY, { state: { meetInfoFormState: formState } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_INFO_COURSE_PRIORITY, { state: { meetInfoFormState: formState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_INFO_COURSE_PRIORITY, {
            state: {
              meetInfoFormState: formState,
              omeMeetEntryCourseId: state.omeMeetEntryCourseId
            }
          });
        }
        else {
          navigate(HostNavLinks.OME_MEET_INFO_COURSE_PRIORITY, {
            state: {
              meetInfoFormState: formState,
              omeMeetEntryCourseId: state.omeMeetEntryCourseId
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  function allowAllEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryStartDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  function allowSomeEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryChangeDeadline === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    omeMeetState,
    omeMeetDateValidationState,
    dateTimeState,
    orgUnitState,
    formState,
    errorState,
    gridState,
    modalState,
    onModalCanceled,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onSaveAndExitButtonClicked,
    onAddCoursePriorityClicked,
    onEditCoursePriorityClicked,
    onDeleteCoursePriorityClicked,
    onDeleteCoursePriority,
    formStateHasBeenSaved,
    allowAllEdits,
    allowSomeEdits
  };
};

export default useOmeMeetInfoNonHQ;