import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData from './OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData';

export const OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteStateContext = createContext();

const OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteStateContext.Provider>
  );
};

export default OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteContextProvider;