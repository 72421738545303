import React, { createContext, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const SelectionsParticipantsStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const SelectionsParticipantsContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <SelectionsParticipantsStateContext.Provider value={stateHook}>
      {children}
    </SelectionsParticipantsStateContext.Provider>
  );
};

export default SelectionsParticipantsContextProvider;