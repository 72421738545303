import React, { createContext, useState } from 'react';

import MeetClassificationTypeData from './MeetClassificationTypeData';

export const MeetClassificationTypeStateContext = createContext();

const MeetClassificationTypeContextProvider = ({ children }) => {
  const stateHook = useState(MeetClassificationTypeData.INITIAL_STATE);

  return (
    <MeetClassificationTypeStateContext.Provider value={stateHook}>
      {children}
    </MeetClassificationTypeStateContext.Provider>
  );
};

export default MeetClassificationTypeContextProvider;