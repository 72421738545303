import { Fragment } from "react";

import SelectionMeetTimeStandardReadOnlyGridLarge from "./SelectionMeetTimeStandardReadOnlyGridLarge";

import Headings from "../../../../../common/components/headings/Headings";

const SelectionMeetTimeStandardReadOnlyGrid = ({ gridData, isLoading }) => (
  <Fragment>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <Headings.H5>Time Standards to Achieve</Headings.H5>
      </div>
    </div>
    <SelectionMeetTimeStandardReadOnlyGridLarge gridData={gridData} isLoading={isLoading} />
  </Fragment>
);

export default SelectionMeetTimeStandardReadOnlyGrid;