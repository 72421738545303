import React, { Fragment } from 'react';

import MeetRecognitionLargeGrid from './MeetRecognitionLargeGrid';
import MeetRecognitionSmallGrid from './MeetRecognitionSmallGrid';

const MeetRecognitionGrid = ({ gridData, isLoading, onOrgCheckbox, onRecordsCheckbox, onTopTimesCheckbox, onMeetEntryCheckbox }) => {
  return (
    <Fragment>
      <MeetRecognitionLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onOrgCheckbox={onOrgCheckbox}
        onRecordsCheckbox={onRecordsCheckbox}
        onTopTimesCheckbox={onTopTimesCheckbox}
        onMeetEntryCheckbox={onMeetEntryCheckbox}
      />
      <MeetRecognitionSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onOrgCheckbox={onOrgCheckbox}
        onRecordsCheckbox={onRecordsCheckbox}
        onTopTimesCheckbox={onTopTimesCheckbox}
        onMeetEntryCheckbox={onMeetEntryCheckbox}
      />  
    </Fragment>
  )
};

export default MeetRecognitionGrid;