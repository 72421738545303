import { Fragment } from "react"
import Headings from "../../../../common/components/headings/Headings"
import global from '../../../../common/components/GlobalStyle.module.css';
import Constants from "../../../../common/utils/Constants";

const MeetInfoReadContent = ({ formState, state }) => {
  return (
    <Fragment>
      <div className="col-xs-12 col-sm-6 usas-extra-top-margin" >
        <Headings.H4>Primary</Headings.H4>
        <div className={global.HeaderText}>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Meet Name:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray'}}>{formState.meetName || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Meet Abbreviation:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.meetAbbreviation || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Sanction Number:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.meetSanctionNumber || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Meet Classification:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.meetClassificationName || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Meet Courses:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.meetCourse.length > 0 ?
            formState.meetCourse.map(x => `${x.name} `) : <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Meet Software:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.meetSoftwareName || <span>No Info</span>}</div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
        <Headings.H4>Host</Headings.H4>
        <div className={global.HeaderText}>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Organization Host:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{state.organizationName || <span>No Info</span>}</div>
          {state.lscName !== undefined &&
            <>
              <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>LSC Host:</b></div>
              <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{state.lscName || <span>No Info</span>}</div>
            </>}
          {state.hostOrgUnitName !== undefined &&
            <>
              <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>{state.hostOrgUnitLabel}:</b></div>
              <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{state.hostOrgUnitName || <span>No Info</span>}</div>
            </>}
        </div>
      </div>
      <div className='hidden-xs row'></div>
      <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
        <Headings.H4>Facility</Headings.H4>
        <div className={global.HeaderText}>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Facility Name:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.facilityName || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>City:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}> {state.facilityCity || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>State:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{state.facilityState || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Country:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{state.facilityCountry || <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Altitude (ft):</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{state.facilityAltitude !== '' ?
            state.facilityAltitude.toString() : <span>Unknown</span>}</div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 usas-extra-top-margin"></div>
      <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
        <Headings.H4>Dates</Headings.H4>
        <div className={global.HeaderText}>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>Start Date:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : <span>No Info</span>}</div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px' }}><b>End Date:</b></div>
          <div className="col-xs-12 col-md-6" style={{ paddingBottom: '5px', color: 'darkslategray' }}>{formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : <span>No Info</span>}</div>
        </div>
      </div>
    </Fragment>
  )
}

export default MeetInfoReadContent