import React, { Fragment } from 'react';

import useAddMeetTime from './UseAddMeetTime';

import AddMeetTimeGrid from './AddMeetTimeGrid';

import Headings from '../../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import LeftArrowIcon from '../../../../../common/components/icons/LeftArrowIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AddMeetTime = () => {
  const {
    listState,
    onContinueToMeetTimeType,
    onBackToMeetAddAthleteClicked
  } = useAddMeetTime();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>Add Meet Time Result</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onContinueToMeetTimeType}>Add Time Result</ActionIntraPageButton>&nbsp;
          <button className={[global.BackToSearchButton, 'pull-right usas-extra-top-margin'].join(' ')} type="button" onClick={() => onBackToMeetAddAthleteClicked()}><LeftArrowIcon /> Back to Meet Times Results-Add Athlete(s)</button>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <AddMeetTimeGrid state={listState} />
        </div>
      </div>
    </Fragment>
  )
}

export default AddMeetTime;