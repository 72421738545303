import { useEffect, useState } from 'react';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';

const INITIAL_VIEW_STATE = {
  reportParameters: { omeMeetOrgUnitEntryId: '' },
  contextId: '',
  routeName: ''
};

const useOrgUnitAdminReportingMyMeets = () => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { meetState } = useMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId && meetState.objData.meetId) {
      setViewState({
        ...viewState,
        reportParameters: { omeMeetOrgUnitEntryId: omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId },
        contextId: meetState.objData.meetId,
        routeName: 'OME_TEAMENTRY_REPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, meetState]);

  return { ...viewState };
};

export default useOrgUnitAdminReportingMyMeets;