import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitEntryContactData from './OmeMeetOrgUnitEntryContactData';

export const OmeMeetOrgUnitEntryContactStateContext = createContext();

const OmeMeetOrgUnitEntryContactContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitEntryContactData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitEntryContactStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitEntryContactStateContext.Provider>
  );
};

export default OmeMeetOrgUnitEntryContactContextProvider;