import React, { Fragment } from 'react';

import useMeetAddSessions from './UseMeetAddSessions';

import NavLinks from '../NavLinks';

import Sessions from '../../../../common/components/meetSessions/Sessions';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const MeetAddSessions = () => {
  const {
    state,
    meetState,
    isLoading,
    isSaving,
    onContinueClicked,
    Constants,
    onAddSession,
    onEditSession,
    onDeleteSession
  } = useMeetAddSessions();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Sessions</Headings.H3>
        </div>
      </div>
      <div className={"row usas-extra-bottom-margin"}>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Name:</b> {meetState?.objData?.meetName || ''}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {meetState?.objData?.startDate ? formatDate(meetState?.objData?.startDate) : ''} - {meetState?.objData?.endDate ? formatDate(meetState?.objData?.endDate) : ''} </p>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <Sessions
            showOptionalFields={true}
            requiredFieldsReadOnly={false}
            meetSessionArray={meetState?.objData?.meetSession}
            meetId={meetState?.objData?.meetId}
            meetStartDate={meetState?.objData?.startDate}
            meetEndDate={meetState?.objData?.endDate}
            onAdd={onAddSession}
            onEdit={onEditSession}
            onDelete={onDeleteSession}
            deleteButtonFirst={true}
            saveNavLink={NavLinks.MEET_ADD_SESSIONS}
            backNavLink={NavLinks.MEET_ADD_SESSIONS}
          />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          {state.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{state?.error}</p>}
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default MeetAddSessions;