import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeRestrictionsTypeCompetitionCategoryValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeRestrictions from './UseOmeRestrictions';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useCompetitionGenderTypeData from '../../../../../common/state/competitionGender/UseCompetitionGenderTypeData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  tryRedirect: false
}

const INITIAL_FORM_STATE = {
  competitionGenderTypeId: '',
  restrictionFormState: {}
};

const useOmeRestrictionsTypeCompetitionCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formStateHasBeenSaved } = useOmeRestrictions();
  const { competitionGenderState } = useCompetitionGenderTypeData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { formState, errorState, onFormValueChanged, handleSubmit, isDirty, isSubmitting, setFormData, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (Object.keys(formState.restrictionFormState).length > 0) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS, { state: { restrictionFormState: formState.restrictionFormState } });
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS, { state: { restrictionFormState: formState.restrictionFormState } });
      }
    }
    else {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
  };

  function submitFormCallback() {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));

    //Save any changes to omeMeetEligibility made on main restriction page
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      omeMeetId: updatedOmeMeet.omeMeetId,
      usCitizenOnly: formState.restrictionFormState?.usCitizenOnly ?? null,
      nationalTeamOnly: formState.restrictionFormState?.nationalTeamOnly ?? null,
      allowRepOtherCountry: formState.restrictionFormState?.allowRepOtherCountry ?? null,
      allowUnattachedRoster: formState.restrictionFormState?.allowUnattachedRoster ?? null,
      allowUnattachedAthleteEntry: formState.restrictionFormState?.allowUnattachedAthleteEntry ?? null
    }

    updatedOmeMeet.omeMeetEligibility[0].omeMeetEligibilityCompetitionGenderType = [{
      omeMeetEligibilityId: updatedOmeMeet.omeMeetEligibility[0]?.omeMeetEligibilityId,
      competitionGenderTypeId: formState.competitionGenderTypeId
    }];

    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });
  };

  function competitionCategoryFormStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    //Edit comp category restriction
    if (omeMeetState.objData.omeMeetEligibility[0] && omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType[0]) {
      const selectedCompetitionCategory = omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType[0];
      if (formState?.competitionGenderTypeId !== selectedCompetitionCategory?.competitionGenderTypeId
        && (formState?.competitionGenderTypeId !== '' || selectedCompetitionCategory?.competitionGenderTypeId !== null)) {
        return false;
      }
    }
    //Add comp category restriction
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (competitionGenderState.isArrayLoaded === true && Object.keys(omeMeetState.objData).length > 0) {
      if (omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType[0]) {
        //Edit
        setFormData({
          ...formState,
          competitionGenderTypeId: omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityCompetitionGenderType[0]?.competitionGenderTypeId,
          restrictionFormState: location.state && location.state?.restrictionFormState ? location.state?.restrictionFormState : {}
        });
        if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
          setIsDirty(true);
        }
      }
      else {
        //Add
        setFormData({
          ...formState,
          restrictionFormState: location.state && location.state?.restrictionFormState ? location.state?.restrictionFormState : {}
        });
        if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
          setIsDirty(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionGenderState.isArrayLoaded, omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.restrictionFormState);
      const isCompetitionCategorySaved = competitionCategoryFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isCompetitionCategorySaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    omeMeetState,
    competitionGenderState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeRestrictionsTypeCompetitionCategory;