import Constants from '../../../common/utils/Constants';
import { TimesHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  dataAsOptionsWithNames: []
};

const getOmeTimeStandardsByOrgUnitId = (orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      orgUnitId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/TimeStandard/Ome/OrgUnit/${orgUnitIdForUrl}`;
    TimesHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          data.unshift({ timeStandardId: Constants.DEFAULT_ID, standardName: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            dataAsOptionsWithNames: data.map((o) => { return { id: o.timeStandardId, name: o.standardName } }),
            message: '',
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const OmeTimeStandardData = { INITIAL_STATE, getOmeTimeStandardsByOrgUnitId };

export default OmeTimeStandardData;