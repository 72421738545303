import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitNonAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';

import Constants from '../../../../../common/utils/Constants';

import { formatPhoneNumber } from '../../../../../common/utils/validation';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Coach from Team Entry?',
  coachName: '',
  phoneNumber: '',
  email: '',
  isInGoodStanding: '',
  isInGoodStandingExpirationDate: '',
  orgUnitNonAthleteEntryId: Constants.DEFAULT_ID
};

const useOrgUnitAdminCoaches = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitNonAthleteEntryState, getOrgUnitNonAthleteEntryCoaches,
    deleteOrgUnitNonAthleteEntry } = useOmeMeetOrgUnitNonAthleteEntryData();
  const { oUAdminEntryContextState } = useOUAdminEntryContextData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [state, setState] = useState({ tryGet: false });

  const onAddCoach = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_COACHES_SELECTION);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_COACHES_SELECTION);
    }
  };

  const onDeleteCoachClicked = (e, coach) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      coachName: (coach.firstName || coach.middleName || coach.lastName) ? `${coach.firstName || ''} ${coach.preferredName !== '' && coach.preferredName !== coach.firstName ? '"' + coach.preferredName + '"' : ''} ${coach.middleName || ''} ${coach.lastName || ''}` : '',
      phoneNumber: coach.phoneNumber ? formatPhoneNumber(coach.phoneNumber) : '',
      email: coach.email || '',
      isInGoodStanding: coach.isInGoodStanding === true ? 'Yes' : coach.isInGoodStanding === false ? 'No' : '',
      isInGoodStandingExpirationDate: coach.isInGoodStandingExpirationDate ? formatDate(coach.isInGoodStandingExpirationDate) : '',
      orgUnitNonAthleteEntryId: coach.orgUnitNonAthleteEntryId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteCoach = (e, orgUnitNonAthleteEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOrgUnitNonAthleteEntry(orgUnitNonAthleteEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId && omeMeetOrgUnitNonAthleteEntryState.isSaving === false) {
      if ((omeMeetOrgUnitNonAthleteEntryState.isArrayLoaded === false || state.tryGet === true)
        && omeMeetOrgUnitNonAthleteEntryState.isArrayLoading === false) {
        getOrgUnitNonAthleteEntryCoaches(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitNonAthleteEntryState, state.tryGet])

  return {
    oUAdminEntryContextState,
    modalState,
    omeMeetOrgUnitNonAthleteEntryState,
    onAddCoach,
    onDeleteCoachClicked,
    onModalCanceled,
    onDeleteCoach
  };
}

export default useOrgUnitAdminCoaches;