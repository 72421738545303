import React from 'react';

import useOrgUnitAdminReporting from './UseOrgUnitAdminReporting';

import ReportList from '../../../../../common/components/embeddedReports/ReportList';

const OrgUnitAdminReporting = () => {
  const { reportParameters, contextId, routeName } = useOrgUnitAdminReporting();

  return (
    <ReportList listTitle="OME Team Entry Reports" 
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default OrgUnitAdminReporting;