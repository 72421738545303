import { useEffect } from 'react';

import useTimeStandardTypeData from '../../../../common/state/timeStandardType/UseTimeStandardTypeData';

const useTimeStandardTypeTreeView = () => {
  const { timeStandardTypeState, getTimeStandardTypesHierarchy } = useTimeStandardTypeData();

  useEffect(() => {
    if (timeStandardTypeState.isArrayLoading !== true && timeStandardTypeState.isArrayLoaded !== true) {
      getTimeStandardTypesHierarchy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardTypeState]);

  return {
    timeStandardTypeState
  };
};

export default useTimeStandardTypeTreeView;