export const localValidate = (formState) => {
  let errors = {};

  if (formState.orgUnits.length === 0) {
    errors.orgUnits = 'Must select at least one Organization to continue';
  }

  return errors;
};

const AddMeetTimeSelectOrgUnitsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AddMeetTimeSelectOrgUnitsValidation;