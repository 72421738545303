import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { Fragment } from 'react';
import PlusIcon from '../../../../common/components/icons/PlusIcon';

const MeetAthleteEntrySmallGrid = ({ gridData, isLoading, onCreate, onEdit, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ?
        gridData.map((meetInfo) =>
          meetInfo?.teamEntry?.map((te) =>
            te?.athleteRoster?.map((athlete, i) => {
              return (
                <div className={global.SmallTableRow} key={i}>
                  <div className={global.SmallTableRowHead}>  <span className='hidden-xs'>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</span>&nbsp;
                    {te.athleteEntryDeleteCurrentlyAllowed === true &&
                      <button className={global.IconButton} type="button" onClick={(e) => onDeleteClicked(e, athlete, meetInfo, te)}>
                        <DeleteIcon />
                      </button>}
                    {te.athleteEntryCurrentlyAllowed === true &&
                      <Fragment>
                        {te.athleteEntryDeleteCurrentlyAllowed === true ?
                          <Fragment>
                            {athlete.orgUnitAthleteEntryId === 0 ?
                              <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onCreate(e, athlete, meetInfo, te)}>
                                <PlusIcon toolTipText='Create Unattached Athlete Entry' />
                              </button> :
                              <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, athlete, meetInfo, te)}>
                                <EditIcon />
                              </button>}
                          </Fragment>
                          :
                          <Fragment>
                            {athlete.orgUnitAthleteEntryId === 0 ?
                              <button className={global.IconButton} type="button" onClick={(e) => onCreate(e, athlete, meetInfo, te)}>
                                <PlusIcon toolTipText='Create Unattached Athlete Entry' />
                              </button> :
                              <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, athlete, meetInfo, te)}>
                                <EditIcon />
                              </button>}
                          </Fragment>}
                      </Fragment>}</div>
                  <div className={global.SmallTableRowInfo}>
                    <div className='row'>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Athlete Name (Age)</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''} ({athlete.ageAtMeet})</div>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-xs'].join(' ')}>Competition Category</div>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Comp. Category</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Status</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.status}</div>
                      <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Team Name</div>
                      <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{te.orgUnitName || <span>&nbsp;</span>} {te.orgUnitName === 'Unattached' ? te.parentOrgUnitName ? '- ' + te.parentOrgUnitName : '' : ''}</div>
                    </div>
                  </div>
                </div>
              )
            })
          )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>
                No Athlete Entries</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetAthleteEntrySmallGrid;