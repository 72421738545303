import { isValidName } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.firstOrPreferredName.trim() !== '' && !isValidName(formState.firstOrPreferredName.trim())) {
    errors.firstOrPreferredName = 'First or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.lastName.trim() !== '' && !isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  return errors;
};

const OrgUnitAdminCoachesSelectionValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminCoachesSelectionValidation;