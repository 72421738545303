import { Fragment } from "react";

import SelectionMeetEventsReadOnlyGridLarge from "./SelectionMeetEventsReadOnlyGridLarge";

const SelectionMeetEventsReadOnlyGrid = ({ gridData, isLoading }) => (
  <Fragment>
    <SelectionMeetEventsReadOnlyGridLarge gridData={gridData} isLoading={isLoading} />
  </Fragment>
);

export default SelectionMeetEventsReadOnlyGrid;