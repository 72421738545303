import React, {createContext, useState} from 'react';

import MeetTimesAddPostData from '../MeetTimesAddPostData';

export const MeetTimesAddPostStateContext = createContext();

const MeetTimesAddPostContextProvider = ({children}) => {
  const stateHook = useState(MeetTimesAddPostData.INITIAL_STATE);

  return (
    <MeetTimesAddPostStateContext.Provider value={stateHook}>
      {children}
    </MeetTimesAddPostStateContext.Provider>
  );
};

 export default MeetTimesAddPostContextProvider;