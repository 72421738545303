import React, { Fragment } from 'react';

import useViewTemplate from './UseViewTemplate';

import LeftNavigation from '../leftNavigation/LeftNavigation';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import Headings from '../../../common/components/headings/Headings';

import global from '../../../common/components/GlobalStyle.module.css';
import style from '../../views/host/Host.module.css';

const ViewTemplate = ({ viewName, errorMessage, onBackButtonClicked, onNextButtonClicked, onSaveAndExitButtonClicked, children }) => {
  const {
    subHeaderText,
    instructionText,
    nextButtonText,
    backButtonText,
    saveAndExitButtonText,
    handleBackButtonClicked,
  } = useViewTemplate(viewName, onBackButtonClicked);

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>Online Meet Entry - Host</Headings.H2>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <LeftNavigation viewName={viewName}>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            <Headings.H3 excludeBar={true}>{subHeaderText}</Headings.H3>
          </div>
        </div>
        {instructionText && (
          <div className="row usas-extra-top-margin">
            <div className='col-xs-12'>
              <p className={global.Multiline}>{instructionText}</p>
            </div>
          </div>
        )}
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {children}
          </div>
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {errorMessage && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorMessage}</p>}
          </div>
        </div>
        <div className='row usas-extra-top-margin pull-right hidden-xs'>
          <div className='col-xs-12'>
            {backButtonText && <span><SecondaryButton onClick={handleBackButtonClicked}>{backButtonText}</SecondaryButton> &nbsp;</span>}
            {onSaveAndExitButtonClicked && saveAndExitButtonText
              ? <Fragment><PrimaryButton onClick={onSaveAndExitButtonClicked}>{saveAndExitButtonText}</PrimaryButton> &nbsp;</Fragment>
              : <Fragment />}
            {onNextButtonClicked
              ? <span>{nextButtonText && <PrimaryButton onClick={onNextButtonClicked}>{nextButtonText}</PrimaryButton>}</span>
              : <span>{nextButtonText && <PrimaryButton type="submit">{nextButtonText}</PrimaryButton>}</span>}
          </div>
        </div>
        <div className='row usas-extra-top-margin visible-xs'>
          <div className='col-xs-12'>
            {backButtonText && <span><SecondaryButton onClick={handleBackButtonClicked}>{backButtonText}</SecondaryButton> &nbsp;</span>}
            {onSaveAndExitButtonClicked && saveAndExitButtonText
              ? <Fragment><PrimaryButton onClick={onSaveAndExitButtonClicked}>{saveAndExitButtonText}</PrimaryButton> &nbsp;</Fragment>
              : <Fragment />}
            {onNextButtonClicked
              ? <span>{nextButtonText && <PrimaryButton onClick={onNextButtonClicked}>{nextButtonText}</PrimaryButton>}</span>
              : <span>{nextButtonText && <PrimaryButton type="submit">{nextButtonText}</PrimaryButton>}</span>}
          </div>
        </div>
      </LeftNavigation>
    </Fragment>
  );
};

export default ViewTemplate;