import { useContext } from 'react';

import { SearchMeetStateContext } from './SearchMeetContextProvider';

const useSearchMeetData = () => {
  const [searchMeetState, setSearchMeetState] = useContext(SearchMeetStateContext);

  return {
    searchMeetState,
    setSearchMeetState
  };
};

export default useSearchMeetData;