import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';

import OmeTimeStandardContextProvider from '../../../state/omeTimeStandard/OmeTimeStandardContextProvider';
import TimeStandardAgeGroupContextProvider from '../../../state/timeStandardAgeGroup/TimeStandardAgeGroupContextProvider';
import OmeFeeTypeContextProvider from '../../../state/omeFeeType/OmeFeeTypeContextProvider';
import OmeMeetHostContextProvider from '../../../state/omeMeetHost/OmeMeetHostContextProvider';
import OmeMeetHostFiltersContextProvider from '../../../state/omeMeetHost/OmeMeetHostFiltersContextProvider';
import OmeMeetDateValidationContextProvider from '../../../state/omeMeetDateValidation/OmeMeetDateValidationContextProvider';

import OrgRoleContextProvider from '../../../../common/state/orgRole/OrgRoleContextProvider';
import CompetitonGenderTypeContextProvider from '../../../../common/state/competitionGender/CompetitionGenderTypeContextProvider';
import EventCompetitonGenderTypeContextProvider from '../../../../common/state/eventCompetitionGender/EventCompetitionGenderTypeContextProvider';
import TimeStandardTypeContextProvider from '../../../../common/state/timeStandardType/TimeStandardTypeContextProvider';
import MeetSessionsContextProvider from '../../../../common/state/meetSessions/MeetSessionsContextProvider';
import SessionContextProvider from '../../../../common/state/time/SessionContextProvider';
import TimeContextProvider from '../../../../common/state/time/TimeContextProvider';
import TimeFiltersContextProvider from '../../../../common/state/time/TimeFiltersContextProvider';
import TimesOrganizationContextProvider from '../../../../common/state/time/TimesOrganizationContextProvider';
import LeftNavModalContextProvider from '../../../../common/state/leftNavModal/LeftNavModalContextProvider';
import BasePersonFiltersContextProvider from '../../../../common/state/personBase/BasePersonFiltersContextProvider';
import BasePersonContextProvider from '../../../../common/state/personBase/BasePersonContextProvider';
import PersonGeneralContextProvider from '../../../../common/state/personGeneral/PersonGeneralContextProvider';

import MeetHost from './MeetHost';
import OmeMeetInfo from '../../host/hostRegistration/omeMeetInfo/OmeMeetInfo';
import OmeMeetInfoNonHQ from '../../host/hostRegistration/omeMeetInfo/OmeMeetInfoNonHQ';
import OmeMeetInfoCoursePriority from '../../host/hostRegistration/omeMeetInfo/OmeMeetInfoCoursePriority';
import OmeEligibility from '../../host/hostRegistration/omeEligibility/OmeEligibility';
import OmeEligibilityNonHQ from '../../host/hostRegistration/omeEligibility/OmeEligibilityNonHQ';
import OmeEligibilityBonusEvents from '../../host/hostRegistration/omeEligibility/OmeEligibilityBonusEvents';
import OmeEligibilityCoachPasses from '../../host/hostRegistration/omeEligibility/OmeEligibilityCoachPasses';
import OmeRestrictions from '../../host/hostRegistration/omeRestrictions/OmeRestrictions';
import OmeRestrictionsNonHQ from '../../host/hostRegistration/omeRestrictions/OmeRestrictionsNonHQ';
import OmeRestrictionsType from '../../host/hostRegistration/omeRestrictions/OmeRestrictionsType';
import OmeRestrictionsTypeMembership from '../../host/hostRegistration/omeRestrictions/OmeRestrictionsTypeMembership';
import OmeRestrictionsTypeCompetitionCategory from '../../host/hostRegistration/omeRestrictions/OmeRestrictionsTypeCompetitionCategory';
import OmeRestrictionsTypeAge from '../../host/hostRegistration/omeRestrictions/OmeRestrictionsTypeAge';
import OmeInvitations from '../../host/hostRegistration/omeInvitations/OmeInvitations';
import OmeSessions from '../../host/hostRegistration/omeSessions/OmeSessions';
import OmeSessionsDetail from '../../host/hostRegistration/omeSessions/OmeSessionsDetail';
import OmeAgeGroups from '../../host/hostRegistration/omeAgeGroups/OmeAgeGroups';
import OmeAgeGroupsDetail from '../../host/hostRegistration/omeAgeGroups/OmeAgeGroupsDetail';
import OmeTimeStandards from '../../host/hostRegistration/omeTimeStandards/OmeTimeStandards';
import OmeTimeStandardsDetail from '../../host/hostRegistration/omeTimeStandards/OmeTimeStandardsDetail';
import OmeEvents from '../../host/hostRegistration/omeEvents/OmeEvents';
import OmeEventsDetail from '../../host/hostRegistration/omeEvents/OmeEventsDetail';
import OmeAlternateEventDetail from '../../host/hostRegistration/omeEvents/OmeAlternateEventDetail';
import OmePricing from '../../host/hostRegistration/omePricing/OmePricing';
import OmePricingAdditionalPurchases from '../../host/hostRegistration/omePricing/OmePricingAdditionalPurchases';
import OmeSubmit from '../../host/hostRegistration/omeSubmit/OmeSubmit';
import MeetHostReporting from './MeetHostReporting';

import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';

const MeetHostRoutes = ({ navRoutes }) => {
  return (
    <Routes>
      <Route path={navRoutes['MEET_HOST_MANAGEMENT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_MANAGEMENT']}
            editElement={<MeetHost />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_MEET_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_MEET_INFO']}
            editElement={<OmeMeetInfo />}
            readOnlyElement={<OmeMeetInfoNonHQ />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_MEET_INFO_COURSE_PRIORITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_MEET_INFO_COURSE_PRIORITY']}
            editElement={<OmeMeetInfoCoursePriority />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_ELIGIBILITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_ELIGIBILITY']}
            editElement={<OmeEligibility />}
            readOnlyElement={<OmeEligibilityNonHQ />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_ELIGIBILITY_BONUS_EVENTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_ELIGIBILITY_BONUS_EVENTS']}
            editElement={<OmeEligibilityBonusEvents />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_ELIGIBILITY_COACH_PASSES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_ELIGIBILITY_COACH_PASSES']}
            editElement={<OmeEligibilityCoachPasses />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_RESTRICTIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_RESTRICTIONS']}
            editElement={<OmeRestrictions />}
            readOnlyElement={<OmeRestrictionsNonHQ />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_RESTRICTIONS_TYPE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_RESTRICTIONS_TYPE']}
            editElement={<OmeRestrictionsType />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_RESTRICTIONS_TYPE_MEMBERSHIP']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_RESTRICTIONS_TYPE_MEMBERSHIP']}
            editElement={<OmeRestrictionsTypeMembership />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY']}
            editElement={<OmeRestrictionsTypeCompetitionCategory />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_RESTRICTIONS_TYPE_AGE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_RESTRICTIONS_TYPE_AGE']}
            editElement={<OmeRestrictionsTypeAge />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_INVITATIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_INVITATIONS']}
            editElement={<OmeInvitations />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_SESSIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_SESSIONS']}
            editElement={<OmeSessions />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_SESSIONS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_SESSIONS_DETAIL']}
            editElement={<OmeSessionsDetail />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_AGE_GROUPS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_AGE_GROUPS']}
            editElement={<OmeAgeGroups />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_AGE_GROUPS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_AGE_GROUPS_DETAIL']}
            editElement={<OmeAgeGroupsDetail />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_TIME_STANDARDS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_TIME_STANDARDS']}
            editElement={<OmeTimeStandards />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_TIME_STANDARDS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_TIME_STANDARDS_DETAIL']}
            editElement={<OmeTimeStandardsDetail />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_EVENTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_EVENTS']}
            editElement={<OmeEvents />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_EVENTS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_EVENTS_DETAIL']}
            editElement={<OmeEventsDetail />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_EVENTS_DETAIL_ALTERNATE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_EVENTS_DETAIL_ALTERNATE']}
            editElement={<OmeAlternateEventDetail />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_PRICING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_PRICING']}
            editElement={<OmePricing />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_PRICING_ADDITIONAL_PURCHASES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_PRICING_ADDITIONAL_PURCHASES']}
            editElement={<OmePricingAdditionalPurchases />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_SUBMIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_SUBMIT']}
            editElement={<OmeSubmit />} />
        }
      />
      <Route path={navRoutes['MEET_HOST_REPORTING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEET_HOST_REPORTING']}
            editElement={<MeetHostReporting />} />
        }
      />
      <Route path={'/'} element={<Navigate to={navRoutes['MEET_HOST_MANAGEMENT']?.route} replace />} />
    </Routes>
  );
};

const MeetHostContextProviders = ({ children }) => (
  <LeftNavModalContextProvider>
    <TimeStandardTypeContextProvider>
      <OmeTimeStandardContextProvider>
        <OrgRoleContextProvider>
          <CompetitonGenderTypeContextProvider>
            <EventCompetitonGenderTypeContextProvider>
              <MeetSessionsContextProvider>
                <TimeContextProvider>
                  <TimeFiltersContextProvider>
                    <TimesOrganizationContextProvider>
                      <SessionContextProvider>
                        <TimeStandardAgeGroupContextProvider>
                          <BasePersonContextProvider>
                            <BasePersonFiltersContextProvider>
                              <PersonGeneralContextProvider>
                                <OmeFeeTypeContextProvider>
                                  <OmeMeetHostFiltersContextProvider>
                                    <OmeMeetHostContextProvider>
                                      <OmeMeetDateValidationContextProvider>
                                        {children}
                                      </OmeMeetDateValidationContextProvider>
                                    </OmeMeetHostContextProvider>
                                  </OmeMeetHostFiltersContextProvider>
                                </OmeFeeTypeContextProvider>
                              </PersonGeneralContextProvider>
                            </BasePersonFiltersContextProvider>
                          </BasePersonContextProvider>
                        </TimeStandardAgeGroupContextProvider>
                      </SessionContextProvider>
                    </TimesOrganizationContextProvider>
                  </TimeFiltersContextProvider>
                </TimeContextProvider>
              </MeetSessionsContextProvider>
            </EventCompetitonGenderTypeContextProvider>
          </CompetitonGenderTypeContextProvider>
        </OrgRoleContextProvider>
      </OmeTimeStandardContextProvider>
    </TimeStandardTypeContextProvider>
  </LeftNavModalContextProvider>
);

const MeetHostContextStateAndRoutes = ({ navRoutes }) => (
  <MeetHostContextProviders>
    <MeetHostRoutes navRoutes={navRoutes} />
  </MeetHostContextProviders>
);

export default MeetHostContextStateAndRoutes;