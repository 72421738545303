import React, { createContext, useState } from 'react';

import OrgUnitAthleteEntryPurchaseData from './OrgUnitAthleteEntryPurchaseData';

export const OrgUnitAthleteEntryPurchaseStateContext = createContext();

const OrgUnitAthleteEntryPurchaseContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitAthleteEntryPurchaseData.INITIAL_STATE);

  return (
    <OrgUnitAthleteEntryPurchaseStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitAthleteEntryPurchaseStateContext.Provider>
  );
};

export default OrgUnitAthleteEntryPurchaseContextProvider;