import React from 'react';

import useSelectionsReporting from './UseSelectionsReporting';

import ReportList from '../../../../../common/components/embeddedReports/ReportList';

const SelectionsReporting = () => {
  const { reportParameters, contextId, routeName } = useSelectionsReporting();

  return (
    <ReportList
      excludeBar={true}
      listTitle="Reports"
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default SelectionsReporting;