import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ staff, onEditStaff }) => {
  return (
    <Fragment>
      <tr>
        <td>{staff.meetStaffTypeName || ''}</td>
        <td>{staff.firstName || ''} {staff.lastName || ''}</td>
        <td>{staff.email || ''}</td>
        <td>
          {onEditStaff &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onEditStaff(e, staff)}>
              <EditIcon />
            </button>}
        </td>
      </tr>
    </Fragment>
  );
};

const MeetStaffLargeGrid = ({ gridData, isLoading, onEditStaff }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position</th>
          <th>Staff Name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="4">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((staff) =>
              <GridRow
                key={staff.meetStaffTypeId}
                staff={staff}
                onEditStaff={onEditStaff} />)
            :
            <tr>
              <td colSpan={4}>No Meet Staff</td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetStaffLargeGrid;