export const CONFIGURATION_NAME = 'CONFIGURATION';
export const MEET_EVENTS_NAME = 'MEET_EVENTS';
export const MEET_ENTRIES_NAME = 'MEET_ENTRIES';
export const PARTICIPANT_SELECTIONS_NAME = 'PARTICIPANT_SELECTIONS';
export const REPORTS_NAME = 'REPORTS';
export const EXPORT_NAME = 'EXPORT';

const SelectionsLeftNavConstants = {
  CONFIGURATION_NAME,
  MEET_EVENTS_NAME,
  MEET_ENTRIES_NAME,
  PARTICIPANT_SELECTIONS_NAME,
  REPORTS_NAME,
  EXPORT_NAME
};

export default SelectionsLeftNavConstants;