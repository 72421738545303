import React, { Fragment } from 'react';

import useOmeInvitations from './UseOmeInvitations';

import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';
import Views from '../HostRegistrationViews';

import OrgUnitTreeView from '../../../../../common/components/tree/orgUnitTree/OrgUnitTreeView';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../../common/utils/Constants';

const OmeInvitations = () => {
  const {
    omeMeetState,
    omeMeetInvitationsState,
    organizationInvitations,
    orgUnitState,
    errorState,
    formState,
    handleSubmit,
    onOrgUnitFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    modalState,
    onModalCanceled,
    allowEdits
  } = useOmeInvitations();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_INVITATIONS}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={errorState.error}>
        {allowEdits() === true ?
          <form onSubmit={handleSubmit}>
            <div className='row usas-extra-bottom-margin'>
              <div className='col-xs-12 col-sm-6'>
                <OrgUnitTreeView
                  label="Organization*"
                  name="orgUnit"
                  value={formState.orgUnit}
                  error={errorState.orgUnit}
                  message={errorState.orgUnit}
                  selectableLeavesOnly={false}
                  singleSelect={false}
                  onChange={(newValue) => { onOrgUnitFormValueChanged('orgUnit', newValue) }}
                  organizationId={undefined} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <ReadOnly
                  label="Selected Organizations/Teams"
                  value={organizationInvitations} />
              </div>
            </div>
          </form> :
          <form onSubmit={handleSubmit}>
            <div className='row usas-extra-bottom-margin'>
              <div className='col-xs-12 col-sm-6'>
                <ReadOnly
                  label="Selected Organizations/Teams"
                  value={organizationInvitations} />
              </div>
            </div>
          </form>
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p>{modalState.warningMsg}</p>
              <p><b>Are you sure you would like to save organization changes and make the associated deletes?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => handleSubmit(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={orgUnitState.isArrayLoading || omeMeetInvitationsState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving || omeMeetInvitationsState.isSaving}
        />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeInvitations;