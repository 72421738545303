import { Fragment } from 'react';

import useSearchMeet from './UseSearchMeet';

import SearchMeetGrid from './SearchMeetGrid';

import CourseDropdown from '../../dropdowns/courseDropdown/CourseDropdown';
import MeetClassificationTreeView from '../../tree/meetClassificationTree/MeetClassificationTreeView';
import Headings from '../../headings/Headings';
import Input from '../../inputs/Input';
import Dropdown from '../../dropdowns/Dropdown';
import DatePicker from '../../datepickers/DatePicker';
import TreeView from '../../tree/TreeView';
import CountryCombobox from '../../comboboxes/countryCombobox/CountryCombobox';
import StatesCombobox from '../../comboboxes/statesCombobox/StatesCombobox';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import ActionIntraPageButton from '../../buttons/ActionIntraPageButton';
import PopUpModal from '../../dialogs/PopUpModal';
import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const SimplifiedForm = ({ searchButtonText, handleSubmit, orgUnitState, formState, errorState, onFormValueChanged, onValueTextPairChanged,
  onOrgUnitChanged, clearForm, sortByOptions, onCancelClicked, onAdd }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className='row'>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <TreeView
          label="Host Organization"
          name="orgUnit"
          value={formState.orgUnit}
          options={orgUnitState.treeData}
          selectableLeavesOnly={false}
          singleSelect={true}
          error={errorState.orgUnit}
          message={errorState.orgUnit}
          isLoading={orgUnitState.isArrayLoading}
          onChange={(newValue) => { onOrgUnitChanged(newValue, 'orgUnit') }}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Meet Name"
          name="meetName"
          value={formState.meetName}
          error={errorState.meetName}
          message={errorState.meetName}
          onChange={(value) => { onFormValueChanged('meetName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Meet Start Date Range"
          name="startDate"
          value={formState.startDate}
          error={errorState.startDate}
          message={errorState.startDate}
          onChange={(value) => { onFormValueChanged('startDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Meet End Date Range"
          name="endDate"
          value={formState.endDate}
          error={errorState.endDate}
          message={errorState.endDate}
          onChange={(value) => { onFormValueChanged('endDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">{searchButtonText}</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onCancelClicked !== undefined ? <Fragment><SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton>&nbsp;</Fragment> : <Fragment />}
        {onAdd !== undefined ? <Fragment><ActionIntraPageButton type="button" onClick={() => onAdd()}>Add Meet</ActionIntraPageButton>&nbsp;</Fragment> : <Fragment />}
      </div>
    </div>
  </form >
)

const Form = ({ searchButtonText, handleSubmit, orgUnitState, formState, errorState, onFormValueChanged, onValueTextPairChanged, onOrgUnitChanged,
  clearForm, sortByOptions, onCancelClicked, onAdd }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className='row'>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <TreeView
          label="Host Organization"
          name="orgUnit"
          value={formState.orgUnit}
          options={orgUnitState.treeData}
          selectableLeavesOnly={false}
          singleSelect={true}
          error={errorState.orgUnit}
          message={errorState.orgUnit}
          isLoading={orgUnitState.isArrayLoading}
          onChange={(newValue) => { onOrgUnitChanged(newValue, 'orgUnit') }}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Meet Name"
          name="meetName"
          value={formState.meetName}
          error={errorState.meetName}
          message={errorState.meetName}
          onChange={(value) => { onFormValueChanged('meetName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <MeetClassificationTreeView
          label="Meet Classification"
          name="meetClassification"
          value={formState.meetClassification}
          error={errorState.meetClassification}
          message={errorState.meetClassification}
          selectableLeavesOnly={true}
          singleSelect={true}
          onChange={(newValue) => { onFormValueChanged('meetClassification', newValue) }}
          orgUnitId={formState.orgUnit[0]?.id || undefined}
          filterId={-1} 
          filterMode={"exclude_filter_id"}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <CourseDropdown
          label="Course"
          name="courseId"
          value={formState.courseId}
          error={errorState.courseId}
          message={errorState.courseId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'courseId', newValueLabel, 'courseName', e); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <StatesCombobox
          isNorthAmerica={true}
          label="State"
          name="stateId"
          valueToMatch={formState.stateName}
          error={errorState.stateId}
          message={errorState.stateId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateId', newValueLabel, 'stateName', e); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <CountryCombobox
          label="Country"
          name="country"
          valueToMatch={formState.countryName}
          error={errorState.country}
          message={errorState.country}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'country', newValueLabel, 'countryName', e); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Meet Start Date Range"
          name="startDate"
          value={formState.startDate}
          error={errorState.startDate}
          message={errorState.startDate}
          onChange={(value) => { onFormValueChanged('startDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Meet End Date Range"
          name="endDate"
          value={formState.endDate}
          error={errorState.endDate}
          message={errorState.endDate}
          onChange={(value) => { onFormValueChanged('endDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">{searchButtonText}</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onCancelClicked !== undefined ? <Fragment><SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton>&nbsp;</Fragment> : <Fragment />}
        {onAdd !== undefined ? <Fragment><ActionIntraPageButton type="button" onClick={() => onAdd()}>Add Meet</ActionIntraPageButton>&nbsp;</Fragment> : <Fragment />}
      </div>
    </div>
  </form >
)

const SearchMeet = ({ pageTitle, searchButtonText, onEdit, searchMeetState, onAdd, onCancelClicked, omeMeetResultsOnly, simplifiedView }) => {
  const {
    orgUnitState,
    formState,
    errorState,
    meetState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onOrgUnitChanged,
    sortByOptions,
    onFiltersToggle,
    contextSecurityState
  } = useSearchMeet({ omeMeetResultsOnly, simplifiedView });

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchMeetState !== undefined ?
        //toggles filters and results
        <Fragment>
          <div className={searchMeetState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            {simplifiedView === true ?
              <SimplifiedForm
                searchButtonText={searchButtonText}
                orgUnitState={orgUnitState}
                formState={formState}
                errorState={errorState}
                clearForm={clearForm}
                handleSubmit={handleSubmit}
                onFormValueChanged={onFormValueChanged}
                onValueTextPairChanged={onValueTextPairChanged}
                onOrgUnitChanged={onOrgUnitChanged}
                sortByOptions={sortByOptions}
                onCancelClicked={onCancelClicked}
                onAdd={onAdd}
              />
              :
              <Form
                searchButtonText={searchButtonText}
                orgUnitState={orgUnitState}
                formState={formState}
                errorState={errorState}
                clearForm={clearForm}
                handleSubmit={handleSubmit}
                onFormValueChanged={onFormValueChanged}
                onValueTextPairChanged={onValueTextPairChanged}
                onOrgUnitChanged={onOrgUnitChanged}
                sortByOptions={sortByOptions}
                onCancelClicked={onCancelClicked}
                onAdd={onAdd}
              />}
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchMeetGrid
                searchMeetState={searchMeetState}
                meetState={meetState}
                onEdit={onEdit}
                onFiltersToggle={onFiltersToggle}
                onCancelClicked={onCancelClicked}
              />
            </div>
          </div>
          {onCancelClicked && searchMeetState.showFilters === false ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          {simplifiedView === true ?
            <SimplifiedForm
              searchButtonText={searchButtonText}
              orgUnitState={orgUnitState}
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              onOrgUnitChanged={onOrgUnitChanged}
              sortByOptions={sortByOptions}
              onCancelClicked={onCancelClicked}
              onAdd={onAdd}
            />
            :
            <Form
              searchButtonText={searchButtonText}
              orgUnitState={orgUnitState}
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              onOrgUnitChanged={onOrgUnitChanged}
              sortByOptions={sortByOptions}
              onAdd={onAdd}
            />}
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchMeetGrid
                meetState={meetState}
                onEdit={onEdit}
              />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={meetState.isArrayLoading || meetState.isObjLoading || formState.orgUnit.length === 0 || contextSecurityState.isObjLoading} />
    </Fragment >
  );
};

export default SearchMeet;