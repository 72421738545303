import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getSelectionActions = (selectionMeetId, state, setState) => {
  if (selectionMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const selectionMeetIdForUrl = encodeURIComponent(selectionMeetId);
    const url = `/OmeSelectionsMeet/${selectionMeetIdForUrl}/actions`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const SelectionsActionsData = {
  getSelectionActions
};

export default SelectionsActionsData;