import { useContext } from 'react';

import LaneSheetHeatRelayTeamsData from './LaneSheetHeatRelayTeamsData';

import { LaneSheetHeatRelayTeamsStateContext } from './LaneSheetHeatRelayTeamsContextProvider';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const useLaneSheetHeatRelayTeamsData = () => {
  const [laneSheetHeatRelayTeamsState, setLaneSheetHeatRelayTeamsState] = useContext(LaneSheetHeatRelayTeamsStateContext);

  const getLaneSheetHeatRelayTeams = (laneSheetHeatId) => {
    return LaneSheetHeatRelayTeamsData.getLaneSheetHeatRelayTeamsData(laneSheetHeatId, laneSheetHeatRelayTeamsState, setLaneSheetHeatRelayTeamsState);
  };

  const putLaneSheetHeatRelayTeams = (laneSheetHeatId, laneSheetHeatRelayTeamsArray) => {
    return LaneSheetHeatRelayTeamsData.putLaneSheetHeatRelayTeamsData(laneSheetHeatId, laneSheetHeatRelayTeamsArray, laneSheetHeatRelayTeamsState, setLaneSheetHeatRelayTeamsState);
  };

  const resetLaneSheetHeatRelayTeamsState = () => {
    setLaneSheetHeatRelayTeamsState({
      ...BASIC_INITIAL_STATE
    });
  };

  return {
    laneSheetHeatRelayTeamsState,
    resetLaneSheetHeatRelayTeamsState,
    getLaneSheetHeatRelayTeams,
    putLaneSheetHeatRelayTeams
  };
};

export default useLaneSheetHeatRelayTeamsData;