import { useContext } from 'react';

import { RelayAthleteEntryTimeStateContext } from './RelayAthleteEntryTimeContextProvider';

import RelayAthleteEntryTimeData from './RelayAthleteEntryTimeData';

const useAthleteEntryTimeData = () => {
  const [relayAthleteEntryTimeState, setRelayAthleteEntryTimeState] =
    useContext(RelayAthleteEntryTimeStateContext);

  const getRelayAthleteEntryTime = (personId, meetEventId, legNumber, qualifyingEventId) =>
    RelayAthleteEntryTimeData.getRelayAthleteEntryTimeData(personId, meetEventId, legNumber, qualifyingEventId,
      relayAthleteEntryTimeState, setRelayAthleteEntryTimeState);

  return {
    relayAthleteEntryTimeState,
    getRelayAthleteEntryTime
  };
};

export default useAthleteEntryTimeData;