import React, { Fragment } from 'react';

import useOrgUnitAdminContacts from './UseOrgUnitAdminContacts';

import OrgUnitAdminContactsGrid from './OrgUnitAdminContactsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

const OrgUnitAdminContactsWrite = () => {
  const {
    omeMeetOrgUnitEntryContactState,
    isLoading,
    Constants,
    modalState,
    onModalCanceled,
    onAddContact,
    onEditContact,
    onDeleteContactClicked,
    onDeleteContact
  } = useOrgUnitAdminContacts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Entry Contacts</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddContact}>Add Entry Contact</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <OrgUnitAdminContactsGrid
            gridData={omeMeetOrgUnitEntryContactState.arrayData}
            isLoading={omeMeetOrgUnitEntryContactState.isArrayLoading}
            onEditContact={onEditContact}
            onDeleteContactClicked={onDeleteContactClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Entry Contact Name:</strong> {modalState?.contactName}</p>
            <p><strong>Email:</strong> {modalState?.emailAddress}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteContact(e, modalState.omeMeetOrgUnitEntryContactId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  )
}

export default OrgUnitAdminContactsWrite;