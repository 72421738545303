import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getRelayAthleteEntryTimeData from './GetRelayAthleteEntryTimeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  personId: Constants.DEFAULT_ID,
  meetEventId: Constants.DEFAULT_ID,
  legNumber: Constants.DEFAULT_ID
};

const OmeMeetOrgUnitAthleteEntryRosterSelectionData = {
  INITIAL_STATE, getRelayAthleteEntryTimeData
}

export default OmeMeetOrgUnitAthleteEntryRosterSelectionData;