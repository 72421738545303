import React, { Fragment } from 'react';

import useSessions from './UseSessions';

import SessionsGrid from './SessionsGrid';

import ActionIntraPageButton from '../buttons/ActionIntraPageButton';
import PopUpModal from '../dialogs/PopUpModal';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';

import { formatDate } from '../../utils/DateFunctions';

const Sessions = ({ showOptionalFields, showOtsField,  requiredFieldsReadOnly, meetSessionArray, meetId, meetStartDate, meetEndDate,
  onAdd, onEdit, onDelete, deleteButtonFirst, saveNavLink, backNavLink }) => {
  const { meetSessionsState, onDeleteClicked, onModalCanceled } =
    useSessions(showOptionalFields, showOtsField, requiredFieldsReadOnly, meetSessionArray, meetId, meetStartDate, meetEndDate, deleteButtonFirst, saveNavLink, backNavLink);

  return (
    <Fragment>
      <div className='row'>
        {onAdd &&
          <div className='col-xs-12'>
            <ActionIntraPageButton onClick={onAdd}>Add Session</ActionIntraPageButton>
          </div>}
        <div className='col-xs-12'>
          <SessionsGrid
            showOptionalFields={meetSessionsState.showOptionalFields}
            showOtsField={meetSessionsState.showOtsField}
            sortedMeetSessionArray={meetSessionsState.sortedMeetSessionArray}
            onEdit={onEdit}
            onDelete={onDelete}
            onDeleteClicked={onDeleteClicked}
          />
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={meetSessionsState?.modalState?.modalTitle}
          displayPopUp={meetSessionsState?.modalState?.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Session Number:</b>&nbsp;{meetSessionsState?.modalState?.sessionOrderId}</p>
              <p><b>Session Type:</b>&nbsp;{meetSessionsState?.modalState?.sessionTypeName}</p>
              <p><b>Session Date:</b>&nbsp;{meetSessionsState?.modalState?.sessionDate ? formatDate(meetSessionsState?.modalState?.sessionDate) : ''}</p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              {meetSessionsState?.modalState?.deleteButtonFirst === true &&
                <Fragment>
                  <PrimaryButton type="button" onClick={(e) => onDelete(e, meetSessionsState?.modalState?.meetSessionId)}>Delete</PrimaryButton>&nbsp;
                  <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
                </Fragment>}
              {meetSessionsState?.modalState?.deleteButtonFirst === false &&
                <Fragment>
                  <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
                  <PrimaryButton type="button" onClick={(e) => onDelete(e, meetSessionsState?.modalState?.meetSessionId)}>Delete</PrimaryButton>
                </Fragment>}
            </div>
          </div>
        </PopUpModal>
      </div>
    </Fragment>
  );
};

export default Sessions;