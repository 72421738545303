import React, { createContext, useState } from 'react';

export const OmeMeetTeamEntryFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: ''
};

const OmeMeetTeamEntryFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <OmeMeetTeamEntryFiltersStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetTeamEntryFiltersStateContext.Provider>
  );
};

export default OmeMeetTeamEntryFiltersContextProvider;