import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AthleteUserPurchasesValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOrgUnitAthleteEntryPurchaseData from '../../../../state/orgUnitAthleteEntryPurchase/UseOrgUnitAthleteEntryPurchaseData';
import useAthleteEntryContextData from '../../../../state/athleteEntryContextView/UseAthleteEntryContextData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';

import useForm from '../../../../../common/utils/UseForm';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
};

const useAthleteUserPurchases = () => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { orgUnitAthleteEntryPurchaseState, setOrgUnitAthleteEntryPurchaseState, getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryId, postOrgUnitAthleteEntryPurchase } = useOrgUnitAthleteEntryPurchaseData();
  const { athleteEntryContextState, setAthleteEntryContextState } = useAthleteEntryContextData();
  const { errorState, formState, handleSubmit, updateFormState, setFormData, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false, showCurrent: true, showPayButton: false });

  const onToggle = () => {
    setState({ ...state, showCurrent: !state.showCurrent });
  };

  function submitAthleteEntryAllowed() {
    //All athlete events must be approved
    if (allAthleteEventsApproved() === false) {
      return false;
    }

    return true;
  };


  function allAthleteEventsApproved() {
    //Must have at least one event if the athlete is not a relay only swimmer
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.status !== Constants.WORKFLOW_STATUS_RELAY_ONLY_SWIMMER &&
      (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.events === null ||
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.events?.length === 0)) {
      return false;
    }

    //If there is at least one event, check that the events have the right status to pay and submit
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.events?.length > 0) {
      if (omeMeetState.objData.omeMeetEligibility[0]?.relayEvents === true && omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true) {
        if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.status !== Constants.WORKFLOW_STATUS_EVENTS_APPROVED && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.status !== Constants.WORKFLOW_STATUS_RELAY_ONLY_SWIMMER &&
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.status !== Constants.WORKFLOW_STATUS_SUBMITTED) {
          return false;
        }
      }
      else if (omeMeetState.objData.omeMeetEligibility[0]?.relayEvents === false ||
        (omeMeetState.objData.omeMeetEligibility[0]?.relayEvents === true && omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === false)) {
        if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.status !== Constants.WORKFLOW_STATUS_EVENTS_APPROVED &&
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.status !== Constants.WORKFLOW_STATUS_SUBMITTED) {
          return false;
        }
      }
    }

    return true;
  };


  useEffect(() => {
    setAthleteEntryContextState({ ...athleteEntryContextState, showNav: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.omeMeetOrgUnitEntryId) {
      getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.omeMeetOrgUnitEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId,
  omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.omeMeetOrgUnitEntryId])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded === true && omeMeetState.isObjLoaded === true) {
      getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryId(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetState])

  useEffect(() => {
    if (orgUnitAthleteEntryPurchaseState.isObjLoaded === true && Object.keys(omeMeetState.objData).length > 0) {
      if (orgUnitAthleteEntryPurchaseState.objData.currentPurchase?.omeMeetPurchase) {
        setFormData({
          ...formState,
        });
        setIsDirty(true);

        const omeMeetPurchaseWithQuantityOtherThanZero = orgUnitAthleteEntryPurchaseState.objData.currentPurchase.omeMeetPurchase?.find(x => x.quantity > 0) ? true : false;
        const additionalPurchaseWithQuantityOtherThanZero = orgUnitAthleteEntryPurchaseState.objData.currentPurchase.additionalPurchase?.find(x => x.quantity > 0) ? true : false;
        if (omeMeetPurchaseWithQuantityOtherThanZero === true || additionalPurchaseWithQuantityOtherThanZero === true) {
          setState({ ...state, showPayButton: true });
        }
        else {
          setState({ ...state, showPayButton: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitAthleteEntryPurchaseState, omeMeetState.objData])

  useEffect(() => {
    if (state.tryRedirect === true && orgUnitAthleteEntryPurchaseState.isSaved === true) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.ATHLETE_PAYMENTS);
      }
      else {
        //Meet Route
        navigate(NavLinks.ATHLETE_PAYMENTS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, orgUnitAthleteEntryPurchaseState])

  function submitFormCallback(formState) {
    setAthleteEntryContextState({ ...athleteEntryContextState, showNav: false });
    postOrgUnitAthleteEntryPurchase(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId, createOrgUnitAthleteEntryPurchaseObject());
    setState({ ...state, tryRedirect: true });
  };

  function createOrgUnitAthleteEntryPurchaseObject() {
    const orgUnitAthleteEntryPurchaseStateCopy = JSON.parse(JSON.stringify(orgUnitAthleteEntryPurchaseState.objData));

    return {
      ...orgUnitAthleteEntryPurchaseStateCopy,
    }
  };

  function onAdditionalPurchaseValueChanged(purchaseItem, value) {
    const orgUnitAthleteEntryPurchaseStateCopy = JSON.parse(JSON.stringify(orgUnitAthleteEntryPurchaseState.objData));
    const selectedItemIndex = orgUnitAthleteEntryPurchaseStateCopy.currentPurchase?.additionalPurchase.findIndex(x => x.omeMeetPriceId === purchaseItem.omeMeetPriceId);
    if (selectedItemIndex > -1) {
      const valueInt = ToIntIfInt(value);
      const valueInteger = valueInt ? parseInt(valueInt).toFixed(0) : 0;
      if (valueInt >= 0 && valueInt <= 1000000) {
        const totalAmount = valueInt * orgUnitAthleteEntryPurchaseStateCopy.currentPurchase.additionalPurchase[selectedItemIndex].amount;
        const totalAmountRounded = totalAmount.toFixed(2);
        orgUnitAthleteEntryPurchaseStateCopy.currentPurchase.additionalPurchase[selectedItemIndex] = {
          ...orgUnitAthleteEntryPurchaseStateCopy.currentPurchase.additionalPurchase[selectedItemIndex],
          quantity: valueInteger,
          totalAmount: totalAmountRounded
        }
      }
    }
    setOrgUnitAthleteEntryPurchaseState({
      ...orgUnitAthleteEntryPurchaseState,
      objData: orgUnitAthleteEntryPurchaseStateCopy
    });
  }

  return {
    athleteEntryContextState,
    state,
    omeMeetState,
    omeMeetOrgUnitEntryState,
    orgUnitAthleteEntryPurchaseState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    errorState,
    formState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onAdditionalPurchaseValueChanged,
    onToggle,
    allAthleteEventsApproved,
    submitAthleteEntryAllowed
  };
}

export default useAthleteUserPurchases;