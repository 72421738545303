import { useState, useEffect } from "react";

import validate from './LaneSheetAddMeetInfoFormValidation';

import useCourseData from "../../../../../common/state/course/UseCourseData";
import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

import useForm from "../../../../../common/utils/UseForm";
import Constants from "../../../../../common/utils/Constants";

const INITIAL_FORM_STATE = {
  careerStats: [],
  laneSheetTemplateId: Constants.DEFAULT_ID,
  laneSheetTemplateName: '',
  numberOfLanes: 0,
  medalCount1Id: Constants.DEFAULT_ID,
  medalCountName1: '',
  medalCount2Id: Constants.DEFAULT_ID,
  medalCountName2: '',
  recordGroupWithinSheetId: Constants.DEFAULT_ID,
  recordWithinSheetName: '',
  reverseLaneOrder: 'false',
  useAmericanDates: '',
};

const INITIAL_LANE_ORDER_STATE = [
  { id: 1, value: 'false', label: "Lane 1 at top" },
  { id: 2, value: 'true', label: "Lane 1 at bottom" },
];

const useLaneSheetAddMeetInfoForm = (onSubmitFormCallback) => {
  const { environmentVariableState } = useEnvironmentVariableData();
  const { courseState, getCourses } = useCourseData();
  const { formState, errorState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormState,
    setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [careerStatCourseOptions, setCareerStatCourseOptions] = useState([]);
  const [laneOrderState] = useState(INITIAL_LANE_ORDER_STATE);

  const onClearFormClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      careerStats: [],
      numberOfLanes: 0,
      reverseLaneOrder: 'false',
      useAmericanDates: '',
    });
    setErrors({});
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && courseState.isLoading !== true && courseState.isLoaded !== true) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, courseState]);

  useEffect(() => {
    if (courseState.isLoaded === true) {
      const courseOptions = [];

      for (const course of courseState.data) {
        if (course.courseId > 0) {
          courseOptions.push({ id: course.courseId, name: course.courseCode });
        }
      }

      setCareerStatCourseOptions(courseOptions);
    }
  }, [courseState]);

  return {
    formState,
    errorState,
    laneOrderState,
    careerStatCourseOptions,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFormClicked
  };
};

export default useLaneSheetAddMeetInfoForm;