import Views from '../../views/host/hostRegistration/HostRegistrationViews';
import getInitialStateOmeRoute from './GetInitialStateOmeRoute';
import getInitialStateMeetRoute from './GetInitialStateMeetRoute';
import { eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete, restrictionsIsComplete, sessionsIsComplete } from './LeftNavValidation';

const getSessions = (viewName, route, omeMeetStateObjData, omeMeetInvitationsStateArrayData, meetStateObjData, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission) => {
  const isCurrent = viewName === Views.OME_MEET_SESSIONS;

  const isMeetInfoComplete = meetInfoIsComplete(omeMeetStateObjData, meetStateObjData, meetInfoRoutePermission);
  const isEligibilityComplete = eligibilityIsComplete(omeMeetStateObjData, eligibilityRoutePermission);
  const isRestrictionsComplete = restrictionsIsComplete(omeMeetStateObjData, restrictionsRoutePermission);
  const isInvitationsComplete = invitationsIsComplete(omeMeetInvitationsStateArrayData);

  const isComplete = isMeetInfoComplete === true && isEligibilityComplete === true && isRestrictionsComplete === true && isInvitationsComplete === true
    && sessionsIsComplete(meetStateObjData);

  const arePrerequisitesDone = isMeetInfoComplete === true && isEligibilityComplete === true
    && isRestrictionsComplete === true && isInvitationsComplete === true;

  const item = route === '' ? getInitialStateOmeRoute(Views.OME_MEET_SESSIONS) : getInitialStateMeetRoute(Views.OME_MEET_SESSIONS);

  return {
    ...item,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getSessions;