import React, { Fragment } from 'react';

import AthleteUserEntriesDetailWrite from './AthleteUserEntriesDetailWrite';
import AthleteUserEntriesDetailReadOnly from './AthleteUserEntriesDetailReadOnly';

import useAthleteUserEntriesDetail from './UseAthleteUserEntriesDetail';

const AthleteUserEntriesDetail = () => {
  const { athleteEntryContextState } = useAthleteUserEntriesDetail();

  return (
    <Fragment>
      {athleteEntryContextState.isReadOnly === false ?
        <AthleteUserEntriesDetailWrite /> :
        <AthleteUserEntriesDetailReadOnly />
      }
    </Fragment>
  );
};

export default AthleteUserEntriesDetail;