import { useContext } from 'react';

import { OmeMeetHostStateContext } from './OmeMeetHostContextProvider';
import { OmeMeetHostFiltersStateContext } from './OmeMeetHostFiltersContextProvider';

import OmeMeetHostData from './OmeMeetHostData';

const useOmeMeetHostData = () => {
  const [omeMeetHostState, setOmeMeetHostState] = useContext(OmeMeetHostStateContext);
  const [omeMeetHostFiltersState, setOmeMeetHostFiltersState] = useContext(OmeMeetHostFiltersStateContext);

  const getMeetHost = (meetId) => OmeMeetHostData.getMeetHostData(meetId, omeMeetHostState, setOmeMeetHostState);

  const searchMeetHost = (filterObject, sortBy) => {
    setOmeMeetHostFiltersState({ ...omeMeetHostFiltersState, filterObject, sortBy });
    OmeMeetHostData.searchMeetHostData(JSON.stringify(filterObject), sortBy, omeMeetHostState, setOmeMeetHostState);
  };

  const deleteOmeMeet = (omeMeetId) => OmeMeetHostData.deleteOmeMeetData(omeMeetId, omeMeetHostState, setOmeMeetHostState);

  const clearOmeMeetHostObjData = () => {
    setOmeMeetHostState({
      ...omeMeetHostState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOmeMeetHostArrayData = () => {
    setOmeMeetHostState({
      ...omeMeetHostState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const resetOmeMeetHostFiltersState = () => {
    setOmeMeetHostFiltersState({
      filterObject: {},
      sortBy: ''
    });
  };

  return {
    omeMeetHostState,
    setOmeMeetHostState,
    omeMeetHostFiltersState,
    setOmeMeetHostFiltersState,
    getMeetHost,
    searchMeetHost,
    clearOmeMeetHostObjData,
    clearOmeMeetHostArrayData,
    deleteOmeMeet,
    resetOmeMeetHostFiltersState
  };
};

export default useOmeMeetHostData;