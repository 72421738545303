import { isValidDate, isValidDateTime } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

const isValidLink = (link) => {
  try {
    const trimmedLink = link.trim();
    const http = trimmedLink.substring(0, 7);

    if (http === 'http://' && link.length > 7) {
      return true;
    } else {
      const https = trimmedLink.substring(0, 8);

      if (https === 'https://' && link.length > 8) {
        return true;
      } else {
        return false;
      }
    }
  } catch {
    return false;
  }
};

export const localValidate = (formState) => {
  let errors = {};

  const publishDate = new Date(formState.publishDate);
  const athleteAgeAsOfDate = new Date(formState.athleteAgeAsOfDate);
  const meetEntryStartDate = new Date(formState.meetEntryStartDate);
  const meetEntryEndDate = new Date(formState.meetEntryEndDate);
  const meetEntryChangeDeadline = new Date(formState.meetEntryChangeDeadline);
  const resultsPublishDate = new Date(formState.resultsPublishDate);
  const meetStartDate = new Date(formState.meetStartDate);
  const meetStartDateYear = meetStartDate.getFullYear();
  const dec31MeetStartDateYear = new Date(`December 31, ${meetStartDateYear} 00:00:00`);
  const meetEndDate = new Date(formState.meetEndDate);
  const meetEndDateYear = meetStartDate.getFullYear();
  const dec31MeetEndDateYear = new Date(`December 31, ${meetEndDateYear} 00:00:00`);

  //Time Zone
  if (formState.timeZoneId === '' || formState.timeZoneValue === 'Time Zone'
    || formState.timeZoneValue === undefined || formState.timeZoneValue === null) {
    errors.timeZoneValue = 'Meet Time Zone is required'
  }

  //Meet Publish Date 
  //Must be on or before Meet Entry Start Date & before Meet Start Date
  if (formState.publishDate.trim() === Constants.BLANK_DATETIME_STRING) {
    errors.publishDate = 'Meet Publish Date is required'
  } else if (!isValidDateTime(formState.publishDate)) {
    errors.publishDate = 'Meet Publish Date must be a valid date and time'
  } else if (publishDate >= meetStartDate) {
    errors.publishDate = 'Meet Publish Date must be before the Meet Start Date'
  } else if (publishDate > meetEntryStartDate) {
    errors.publishDate = 'Meet Publish Date must be on or before the Meet Entry Start Date'
  }

  //Athlete Age as of Date
  //Must be December 31st of the meet year or between Meet Start Date and Meet End Date 
  if (formState.athleteAgeAsOfDate.trim() === Constants.BLANK_DATE_STRING) {
    errors.athleteAgeAsOfDate = 'Athlete Age as of Date is required'
  } else if ((athleteAgeAsOfDate < meetStartDate || athleteAgeAsOfDate > meetEndDate) &&
    ((athleteAgeAsOfDate - dec31MeetStartDateYear !== 0) && (athleteAgeAsOfDate - dec31MeetEndDateYear !== 0))) {
    errors.athleteAgeAsOfDate = 'Athlete Age as of Date must be between the Meet Start Date and Meet End Date or on December 31st of the year of the meet'
  } else if ((formState.athleteAgeAsOfDate.trim() !== Constants.BLANK_DATE_STRING)
    && (!isValidDate(formState.athleteAgeAsOfDate))) {
    errors.athleteAgeAsOfDate = 'Athlete Age as of Date must be a valid date'
  }

  //Meet Entry Start Date
  //Must be before Meet Entry End Date and Meet Start Date
  if (formState.meetEntryStartDate.trim() === Constants.BLANK_DATETIME_STRING) {
    errors.meetEntryStartDate = 'Meet Entry Start Date is required'
  } else if (!isValidDateTime(formState.meetEntryStartDate)) {
    errors.meetEntryStartDate = 'Meet Entry Start Date must be a valid date and time'
  } else if (publishDate > meetEntryStartDate) {
    errors.meetEntryStartDate = 'Meet Entry Start Date must be on or after the Meet Publish Date'
  } else if (meetEntryStartDate >= meetStartDate) {
    errors.meetEntryStartDate = 'Meet Entry Start Date must be before the Meet Start Date'
  } else if (meetEntryEndDate <= meetEntryStartDate) {
    errors.meetEntryStartDate = 'Meet Entry Start Date must be before the Meet Entry End Date'
  }

  //Meet Entry End Date
  //Must be after Meet Entry Start Date and before Meet Start Date
  if (formState.meetEntryEndDate.trim() === Constants.BLANK_DATETIME_STRING) {
    errors.meetEntryEndDate = 'Meet Entry End Date is required'
  } else if (!isValidDateTime(formState.meetEntryEndDate)) {
    errors.meetEntryEndDate = 'Meet Entry End Date must be a valid date and time'
  } else if (meetEntryEndDate >= meetStartDate) {
    errors.meetEntryEndDate = 'Meet Entry End Date must be before the Meet Start Date'
  } else if (meetEntryEndDate <= meetEntryStartDate) {
    errors.meetEntryEndDate = 'Meet Entry End Date must be after the Meet Entry Start Date'
  } else if (meetEntryChangeDeadline < meetEntryEndDate) {
    errors.meetEntryEndDate = 'Meet Entry End Date must be on or before the Meet Entry Change Deadline'
  }

  //Meet Entry Change Deadline 
  // Must be before Meet Start Date and after the Meet Entry Start Date
  if (formState.meetEntryChangeDeadline.trim() === Constants.BLANK_DATETIME_STRING) {
    errors.meetEntryChangeDeadline = 'Meet Entry Change Deadline is required'
  } else if (!isValidDateTime(formState.meetEntryChangeDeadline)) {
    errors.meetEntryChangeDeadline = 'Meet Entry Change Deadline must be a valid date and time'
  } else if (meetEntryChangeDeadline >= meetStartDate) {
    errors.meetEntryChangeDeadline = 'Meet Entry Change Deadline must be before the Meet Start Date'
  } else if (meetEntryChangeDeadline < meetEntryEndDate) {
    errors.meetEntryChangeDeadline = 'Meet Entry Change Deadline must be on or after the Meet Entry End Date'
  }

  //Meet Results Publish Deadline
  // Must be on or after end date of meet
  if (formState.resultsPublishDate.trim() !== Constants.BLANK_DATE_STRING) {
    if (resultsPublishDate < meetEndDate) {
      errors.resultsPublishDate = 'Meet Results Publish Date must be on or after the Meet End Date'
    } else if (!isValidDate(formState.resultsPublishDate)) {
      errors.resultsPublishDate = 'Meet Results Publish Date must be a valid date'
    }
  }

  //Meet Packet URL
  if (formState.meetPacketUrl.trim() !== '') {
    if (!isValidLink(formState.meetPacketUrl)) {
      errors.meetPacketUrl = `Meet Packet URL must begin with 'http://' or 'https://'`;
    }
    if (formState.meetPacketUrl.trim().length > 200) {
      errors.meetPacketUrl = 'Meet Packet URL cannot exceed 200 characters';
    }
  }

  return errors;
};

const OmeMeetInfoValidationNonHQ = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeMeetInfoValidationNonHQ;