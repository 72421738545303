import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useAthleteMeetEventData from '../../../../state/athleteMeetEvent/UseAthleteMeetEventData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/orgUnitAthleteEntry/UseOrgUnitAthleteEntryIndividualRosterEntryData';

import Constants from '../../../../../common/utils/Constants';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Individual Event Entry?',
  eventName: '',
  eventCompetitionGenderTypeName: '',
  ageGroupName: '',
  entryTime: '',
  qualifyingCourseCode: '',
  qualifiedAsBonus: '',
  override: '',
  status: '',
  individualAthleteMeetEventId: Constants.DEFAULT_ID
};

const useOrgUnitAdminRosterEntries = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId } =
    useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { orgUnitAthleteEntryIndividualRosterEntryState, putOrgUnitAthleteEntryApproveIndividualEvents }
    = useOrgUnitAthleteEntryIndividualRosterEntryData();
  const { athleteMeetEventState, deleteIndividualAthleteMeetEvent } = useAthleteMeetEventData();
  const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
  const [state, setState] = useState({
    athlete: {}, tryGet: false, tryRedirect: false, tryGetAfterDelete: false,
    tryUpdateGridData: false, bonusEvents: ''
  });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [errorState, setErrorState] = useState({ general: '' });

  const onAddEventClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_SELECTION, { state: { athlete: state.athlete } });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_SELECTION, { state: { athlete: state.athlete } });
    }
  };

  const onAddBonusEventClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION, { state: { athlete: state.athlete } });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES_BONUS_INDIVIDUAL_SELECTION, { state: { athlete: state.athlete } });
    }
  };

  const onApprovePendingEventsClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedEvents = JSON.parse(JSON.stringify(state.athlete.events));
    for (let i = 0; i < updatedEvents.length; i++) {
      updatedEvents[i].status = Constants.WORKFLOW_STATUS_APPROVED;
    }

    putOrgUnitAthleteEntryApproveIndividualEvents(state.athlete?.orgUnitAthleteEntryId, updatedEvents);
    setState({ ...state, tryGet: true });
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES);
    }
  };

  const onDeleteEventClicked = (e, individualAthleteMeetEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let bonusRuleViolated = false;
    let bonusRuleViolatedErrorMsg = '';
    //if bonus events are allowed and the selected event is not a bonus event, check to see if the delete would violate any bonus rules
    if (state.bonusEvents === true && individualAthleteMeetEvent.qualifiedAsBonus === false) {
      const eventsAfterTheDelete = JSON.parse(JSON.stringify(state.athlete.events));
      const selectedIndex = eventsAfterTheDelete.findIndex(x => x.individualAthleteMeetEventId === individualAthleteMeetEvent.individualAthleteMeetEventId);
      if (selectedIndex > -1) {
        eventsAfterTheDelete.splice(selectedIndex, 1);
      }

      //figure out the number of individual and bonus entries after the potential delete
      let updatedAthleteIndividualEventCount = 0;
      let updatedAthleteBonusIndividualEventCount = 0;
      if (eventsAfterTheDelete) {
        for (let i = 0; i < eventsAfterTheDelete.length; i++) {
          if (eventsAfterTheDelete[i].qualifiedAsBonus === false) {
            updatedAthleteIndividualEventCount += 1;
          }
          else if (eventsAfterTheDelete[i].qualifiedAsBonus === true) {
            updatedAthleteBonusIndividualEventCount += 1;
          }
        }
      }

      //check to see if any bonus rules are violated by the potential delete
      let differenceBetweenBonusEntriesAndAllowedBonusEntries = 0;
      for (let i = 0; i < omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus.length; i++) {
        //find the bonus rule that would applies to the number of individual (non-bonus) event entries after the delete
        if (updatedAthleteIndividualEventCount <= omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].maxQualifyingEvents &&
          updatedAthleteIndividualEventCount >= omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].minQualifyingEvents) {
          if (updatedAthleteBonusIndividualEventCount > omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount) {
            bonusRuleViolated = true;
            differenceBetweenBonusEntriesAndAllowedBonusEntries = updatedAthleteBonusIndividualEventCount - omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount;
            bonusRuleViolatedErrorMsg = `Deleting this individual event entry would leave this athlete with ${updatedAthleteIndividualEventCount} individual event ${updatedAthleteIndividualEventCount === 1 ? 'entry' : 'entries'}. Based on the bonus event rules of the meet, an athlete with ${updatedAthleteIndividualEventCount} individual event ${updatedAthleteIndividualEventCount === 1 ? 'entry' : 'entries'} is allowed ${omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount} individual bonus ${omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount === 1 ? 'event entry' : 'event entries'}. This athlete has ${updatedAthleteBonusIndividualEventCount} individual bonus event ${updatedAthleteBonusIndividualEventCount === 1 ? 'entry' : 'entries'}, which would not be allowed.\n\nIn order to be able to delete this individual event entry, please delete ${differenceBetweenBonusEntriesAndAllowedBonusEntries} individual bonus event ${differenceBetweenBonusEntriesAndAllowedBonusEntries === 1 ? 'entry' : 'entries'}.`
          }
        }
      }
    }

    //if there is an error, don't show the delete pop-up
    if (bonusRuleViolated === true) {
      setErrorState({ ...errorState, general: bonusRuleViolatedErrorMsg });
    }
    else {
      setErrorState({ ...errorState, general: '' });
      setModalState({
        ...modalState,
        displayPopUp: true,
        eventName: individualAthleteMeetEvent.eventName || '',
        eventCompetitionGenderTypeName: individualAthleteMeetEvent.eventCompetitionGenderTypeName || '',
        ageGroupName: individualAthleteMeetEvent.ageGroupName || '',
        entryTime: individualAthleteMeetEvent.entryTime ? formatTimeForDisplay(individualAthleteMeetEvent.entryTime) : 'NT',
        qualifyingCourseCode: individualAthleteMeetEvent.qualifyingCourseCode || '',
        qualifiedAsBonus: individualAthleteMeetEvent.qualifiedAsBonus === true ? 'Yes' : individualAthleteMeetEvent.qualifiedAsBonus === false ? 'No' : '',
        override: individualAthleteMeetEvent.swimTimeEntryOverride === true ? 'Yes' : individualAthleteMeetEvent.swimTimeEntryOverride === false ? 'No' : '',
        status: individualAthleteMeetEvent.status || '',
        individualAthleteMeetEventId: individualAthleteMeetEvent.individualAthleteMeetEventId || Constants.DEFAULT_ID
      });
    }
  };

  const onDeleteEvent = (e, individualAthleteMeetEventId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteIndividualAthleteMeetEvent(individualAthleteMeetEventId);
    onModalCanceled();
    setState({ ...state, tryGetAfterDelete: true });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (location.state && location.state.athlete) {
      setState({ ...state, athlete: location.state.athlete, bonusEvents: omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? '' });
      setGridState({ ...gridState, gridData: location.state.athlete.events || [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId && state.tryGet === true &&
      orgUnitAthleteEntryIndividualRosterEntryState.isSaving === false) {
      getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      setState({ ...state, tryGet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId, state.tryGet, orgUnitAthleteEntryIndividualRosterEntryState.isSaving])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId && state.tryGetAfterDelete === true &&
      athleteMeetEventState.isSaving === false) {
      getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      setState({ ...state, tryGetAfterDelete: false, tryUpdateGridData: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId, state.tryGetAfterDelete, athleteMeetEventState.isSaving])

  useEffect(() => {
    if (state.tryUpdateGridData === true && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false) {
      const updatedAthlete = omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.arrayData.find(x => x.personId === state.athlete?.personId)
      setState({ ...state, tryUpdateGridData: false, athlete: updatedAthlete });
      setGridState({ ...gridState, gridData: updatedAthlete.events || [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryUpdateGridData, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState])

  useEffect(() => {
    if (state.tryRedirect === true && orgUnitAthleteEntryIndividualRosterEntryState.isSaving === false &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
      omeMeetOrgUnitAthleteEntryState.isArrayLoading === false) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES);
      }
      else {
        //Meet Route
        navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, orgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryState])

  return {
    state,
    gridState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    orgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryState,
    athleteMeetEventState,
    onAddEventClicked,
    onAddBonusEventClicked,
    onApprovePendingEventsClicked,
    onBackClicked,
    onDeleteEventClicked,
    onDeleteEvent,
    onModalCanceled,
    modalState,
    errorState
  };
}

export default useOrgUnitAdminRosterEntries;