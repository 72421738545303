import { useState } from 'react';

import AthleteMeetEventData from './AthleteMeetEventData';

const useAthleteMeetEventData = () => {
  const [athleteMeetEventState, setAthleteMeetEventState] =
    useState(AthleteMeetEventData.INITIAL_STATE);

  const deleteIndividualAthleteMeetEvent = (individualAthleteMeetEventId) =>
    AthleteMeetEventData.deleteIndividualAthleteMeetEventData(individualAthleteMeetEventId,
      athleteMeetEventState, setAthleteMeetEventState);

  const deleteRelayMeetEventEntry = (relayMeetEventEntryId) =>
    AthleteMeetEventData.deleteRelayMeetEventEntryData(relayMeetEventEntryId,
      athleteMeetEventState, setAthleteMeetEventState);

  return {
    athleteMeetEventState,
    deleteIndividualAthleteMeetEvent,
    deleteRelayMeetEventEntry
  };
};

export default useAthleteMeetEventData;