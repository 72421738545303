import { useState } from 'react';
import TimesReconData from './TimesReconData';

const useTimesReconData = () => {
  const [timesReconState, setTimesReconState] = useState(TimesReconData.INITIAL_STATE);

  const postTimesRecon = (timesReconObj) => TimesReconData.postTimesReconData(timesReconObj, timesReconState, setTimesReconState);

  return {
    timesReconState,
    postTimesRecon
  };
}

export default useTimesReconData;