import React, { Fragment } from 'react';

import useOmeSubmit from './UseOmeSubmit';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import SearchMemberPopup from '../../../../../common/components/searches/searchMember/SearchMemberPopup';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';
import AddressValidation from '../../../../../common/components/addressValidation/AddressValidation';
import Input from '../../../../../common/components/inputs/Input';
import StatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/StatesCombobox';
import CountryCombobox from '../../../../../common/components/comboboxes/countryCombobox/CountryCombobox';

const OmeSubmit = () => {
  const {
    omeMeetDateValidationState,
    isSubmitting,
    omeMeetState,
    meetState,
    basePersonState,
    personGeneralState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    allowEdits,
    LOCAL_CONSTANTS,
    handleSubmitProxy,
    onCountryChange,
    validateState
  } = useOmeSubmit();

  return (
    <Fragment>
      <ViewTemplate
        viewName={omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED ?
          Views.OME_MEET_SUBMITTED : Views.OME_MEET_SUBMIT}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}>
        {allowEdits() === true ?
          <form onSubmit={handleSubmitProxy} noValidate>
            <div className='row usas-extra-bottom-margin'>
              {formState.meetContactName !== '' &&
                <div className="col-xs-12 col-sm-6">
                  <ReadOnly
                    label="Meet Contact Name"
                    name="meetContactName"
                    value={formState.meetContactName}
                  />
                </div>}
              {formState.meetContactEmail !== '' &&
                <div className="col-xs-12 col-sm-6">
                  <ReadOnly
                    label="Meet Contact Email"
                    name="meetContactEmail"
                    value={formState.meetContactEmail}
                  />
                </div>}
              {formState.meetContactPhone !== '' &&
                <div className="col-xs-12">
                  <ReadOnly
                    label="Meet Contact Phone"
                    name="meetContactPhone"
                    value={formState.meetContactPhone}
                  />
                </div>}
            </div>
            <hr></hr>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 col-sm-6">
                <SearchMemberPopup
                  label={'Entry Contact Member Id*'}
                  memberIdName={'entryContactMemberId'}
                  formState={formState}
                  errorState={errorState}
                  setFormData={setFormData}
                  onFormValueChanged={onFormValueChanged}
                />
              </div>
              {formState.entryContactName !== '' &&
                <div className="col-xs-12 col-sm-6">
                  <ReadOnly
                    label="Entry Contact Name"
                    name="entryContactName"
                    value={formState.entryContactName}
                  />
                </div>}
              {formState.entryContactEmail !== '' &&
                <div className="col-xs-12">
                  <ReadOnly
                    label="Entry Contact Email"
                    name="entryContactEmail"
                    value={formState.entryContactEmail}
                  />
                </div>}
              {formState.entryContactPhone !== '' &&
                <div className="col-xs-12">
                  <ReadOnly
                    label="Entry Contact Phone"
                    name="entryContactPhone"
                    value={formState.entryContactPhone}
                  />
                </div>}
            </div>
            <hr></hr>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Payment Contact Name*"
                  name="paymentContactName"
                  value={formState.paymentContactName}
                  error={errorState.paymentContactName}
                  message={errorState.paymentContactName}
                  onChange={(value) => { onFormValueChanged('paymentContactName', value); }}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Payment Contact Email*"
                  name="emailAddress"
                  value={formState.emailAddress}
                  error={errorState.emailAddress}
                  message={errorState.emailAddress}
                  onChange={(value) => { onFormValueChanged('emailAddress', value); }}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="To Whom To Make Out Payment*"
                  name="paymentRecipient"
                  value={formState.paymentRecipient}
                  error={errorState.paymentRecipient}
                  message={errorState.paymentRecipient}
                  onChange={(value) => { onFormValueChanged('paymentRecipient', value); }}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <CountryCombobox
                  label="Country*"
                  name="country"
                  valueToMatch={formState.countryName}
                  error={errorState.country}
                  message={errorState.country}
                  onChange={(newValue, newValueLabel, e) => { onCountryChange(newValue, 'country', newValueLabel, 'countryName', e) }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Address Line 1*"
                  name="address1"
                  value={formState.address1}
                  error={errorState.address1}
                  message={errorState.address1}
                  onChange={(value) => { onFormValueChanged('address1', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Address Line 2"
                  name="address2"
                  value={formState.address2}
                  error={errorState.address2}
                  message={errorState.address2}
                  onChange={(value) => { onFormValueChanged('address2', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="City*"
                  name="city"
                  value={formState.city}
                  error={errorState.city}
                  message={errorState.city}
                  onChange={(value) => { onFormValueChanged('city', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                {formState.country === LOCAL_CONSTANTS.USA || formState.country === LOCAL_CONSTANTS.CANADA || formState.country === LOCAL_CONSTANTS.MEXICO ?
                  <StatesCombobox
                    label="State*"
                    name="state"
                    valueToMatch={formState.state}
                    error={errorState.state}
                    message={errorState.state}
                    onChange={(id, name) => { onValueTextPairChanged(id, 'stateCode', name, 'state'); }}
                    isUSA={formState.country === LOCAL_CONSTANTS.USA}
                    isCanada={formState.country === LOCAL_CONSTANTS.CANADA}
                    isMexico={formState.country === LOCAL_CONSTANTS.MEXICO} />
                  : <Fragment />
                }
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Zip Code*"
                  name="postalCode"
                  value={formState.postalCode}
                  error={errorState.postalCode}
                  message={errorState.postalCode}
                  onChange={(value) => { onFormValueChanged('postalCode', value); }} />
              </div>
            </div>
            <AddressValidation formState={formState} setFormData={setFormData} />
          </form>
          :
          <form onSubmit={handleSubmitProxy} noValidate>
            <div className='row usas-extra-bottom-margin'>
              {formState.meetContactName !== '' &&
                <div className="col-xs-12 col-sm-6">
                  <ReadOnly
                    label="Meet Contact Name"
                    name="meetContactName"
                    value={formState.meetContactName}
                  />
                </div>}
              {formState.meetContactEmail !== '' &&
                <div className="col-xs-12 col-sm-6">
                  <ReadOnly
                    label="Meet Contact Email"
                    name="meetContactEmail"
                    value={formState.meetContactEmail}
                  />
                </div>}
              {formState.meetContactPhone !== '' &&
                <div className="col-xs-12">
                  <ReadOnly
                    label="Meet Contact Phone"
                    name="meetContactPhone"
                    value={formState.meetContactPhone}
                  />
                </div>}
            </div>
            <hr></hr>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 col-sm-6">
                <ReadOnly
                  label="Entry Contact Member Id*"
                  name="entryContactMemberId"
                  value={formState.entryContactMemberId}
                />
              </div>
              {formState.entryContactName !== '' &&
                <div className="col-xs-12 col-sm-6">
                  <ReadOnly
                    label="Entry Contact Name"
                    name="entryContactName"
                    value={formState.entryContactName}
                  />
                </div>}
              {formState.entryContactEmail !== '' &&
                <div className="col-xs-12">
                  <ReadOnly
                    label="Entry Contact Email"
                    name="entryContactEmail"
                    value={formState.entryContactEmail}
                  />
                </div>}
              {formState.entryContactPhone !== '' &&
                <div className="col-xs-12">
                  <ReadOnly
                    label="Entry Contact Phone"
                    name="entryContactPhone"
                    value={formState.entryContactPhone}
                  />
                </div>}
            </div>
            <hr></hr>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Payment Contact Name*"
                  name="paymentContactName"
                  value={formState.paymentContactName}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Payment Contact Email*"
                  name="emailAddress"
                  value={formState.emailAddress}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="To Whom To Make Out Payment*"
                  name="paymentRecipient"
                  value={formState.paymentRecipient}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Country*"
                  name="country"
                  value={formState.countryName}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Address Line 1*"
                  name="address1"
                  value={formState.address1}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Address Line 2"
                  name="address2"
                  value={formState.address2}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="City*"
                  name="city"
                  value={formState.city}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="State*"
                  name="state"
                  value={formState.state}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Zip Code*"
                  name="postalCode"
                  value={formState.postalCode}
                />
              </div>
            </div>
            <AddressValidation formState={formState} setFormData={setFormData} />
          </form>}
        {omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED &&
          <div className='row'>
            <div className='col-xs-12'>
              {omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryChangeDeadline === true ?
                <p><b>This OME Meet is submitted & created. Feel free to review and/or make changes to the Host setup by using the left navigation, or by using the navigation buttons at the bottom of each page. You do not need to revisit this page to submit any changes made on other pages of the Host setup. On every page, any changes made will be submitted when you save the page.</b></p> :
                <p><b>This OME Meet is submitted & created. Feel free to review the Host setup by using the left navigation, or by using the navigation buttons at the bottom of each page. Since the current date is after the Meet Entry Change Deadline, no changes to the Host setup are currently allowed.</b></p>}
            </div>
          </div>
        }
        {validateState.isValidating === true && (
          <PopUpModal
            displayPopUp={true}
            maxWidth={250}
            title="Validating..."
            widthPct={50} />
        )}
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={basePersonState.isObjLoading || personGeneralState.isObjLoading || meetState.isObjLoading || isSubmitting} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving || meetState.isSaving} />
      </ViewTemplate>
    </Fragment >
  );
};

export default OmeSubmit;