import { useState } from 'react';

import MeetDistinctAthletesData from './MeetDistinctAthletesData';

const useMeetDistinctAthletesData = () => {
  const [meetDistinctAthletesState, setMeetDistinctAthletesState] = useState(MeetDistinctAthletesData.INITIAL_STATE);
  const getMeetDistinctAthletes = (meetId) => MeetDistinctAthletesData.getMeetDistinctAthletes(meetId, meetDistinctAthletesState, setMeetDistinctAthletesState);

  return { meetDistinctAthletesState, getMeetDistinctAthletes };
};

export default useMeetDistinctAthletesData;