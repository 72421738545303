import { Fragment } from "react";

import useOrgUnitAdminRelayEntriesTeams from "./UseOrgUnitAdminRelayEntriesTeams";

import OrgUnitAdminRelayEntriesTeamsGrid from "./OrgUnitAdminRelayEntriesTeamsGrid";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../../common/components/headings/Headings";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminRelayEntriesTeamsReadOnly = () => {
    const { state, gridState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, omeMeetOrgUnitAthleteEntryState,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, onEditRelayTeamClicked,
        onBackClicked } = useOrgUnitAdminRelayEntriesTeams();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <Headings.H3>Manage Relay Teams</Headings.H3>
                </div>
                <div className="col-xs-12">
                    <p className={global.HeaderText}><b>Relay Event:</b> {state.event?.eventName || ''} ({state.event?.eventCompetitionGenderTypeName || ''}, {state.event?.ageGroupName || ''}), Session {state.event?.meetSessionName || ''}</p>
                    {/* {(state.event?.timeCuts && state.event?.timeCuts.length > 0) &&
                        <p className={global.HeaderText}><b>Qualifying Time:</b>
                            {state.event.timeCuts.map((timeCut) => {
                                return (
                                    <Fragment key={timeCut.qualifyingEventId}>&nbsp;<span>{`(${timeCut.eventName || ''}`}{timeCut?.noFasterThanTimeCut ? ' No Faster Than ' + formatTimeForDisplay(timeCut?.noFasterThanTimeCut) : ''}{timeCut?.noFasterThanTimeCut && timeCut?.noSlowerThanTimeCut ? ' &' : ''}{timeCut?.noSlowerThanTimeCut ? ' No Slower Than ' + formatTimeForDisplay(timeCut?.noSlowerThanTimeCut) : ''}{`)`}</span></Fragment>
                                );
                            })}
                        </p>} */}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <OrgUnitAdminRelayEntriesTeamsGrid
                        gridData={gridState.gridData}
                        isLoading={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading}
                        onEditRelayTeamClicked={onEditRelayTeamClicked}
                        state={state}
                    />
                </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                    <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
                </div>
            </div>
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading ||
                    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading} />
        </Fragment>
    )
}
export default OrgUnitAdminRelayEntriesTeamsReadOnly;