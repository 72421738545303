import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData from './OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData';

export const OmeMeetOrgUnitNonAthleteEntryCoachesSelectionStateContext = createContext();

const OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitNonAthleteEntryCoachesSelectionData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitNonAthleteEntryCoachesSelectionStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitNonAthleteEntryCoachesSelectionStateContext.Provider>
  );
};

export default OmeMeetOrgUnitNonAthleteEntryCoachesSelectionContextProvider;