import { useContext } from 'react';

import { OmeMeetOrgUnitEntryContactStateContext } from './OmeMeetOrgUnitEntryContactContextProvider';

import OmeMeetOrgUnitEntryContactData from './OmeMeetOrgUnitEntryContactData';

const useOmeMeetOrgUnitEntryContactData = () => {
  const [omeMeetOrgUnitEntryContactState, setOmeMeetOrgUnitEntryContactState] = useContext(OmeMeetOrgUnitEntryContactStateContext);

  const getOmeMeetOrgUnitEntryContacts = (omeMeetOrgUnitEntryId) => {
    OmeMeetOrgUnitEntryContactData.getOmeMeetOrgUnitEntryContactsData(omeMeetOrgUnitEntryId, omeMeetOrgUnitEntryContactState, setOmeMeetOrgUnitEntryContactState);
  };

  const postOmeMeetOrgUnitEntryContact = (omeMeetOrgUnitEntryContactObj) => {
    OmeMeetOrgUnitEntryContactData.postOmeMeetOrgUnitEntryContactData(omeMeetOrgUnitEntryContactObj, omeMeetOrgUnitEntryContactState, setOmeMeetOrgUnitEntryContactState);
  };

  const putOmeMeetOrgUnitEntryContact = (omeMeetOrgUnitEntryContactId, omeMeetOrgUnitEntryContactObj) => {
    OmeMeetOrgUnitEntryContactData.putOmeMeetOrgUnitEntryContactData(omeMeetOrgUnitEntryContactId, omeMeetOrgUnitEntryContactObj, omeMeetOrgUnitEntryContactState, setOmeMeetOrgUnitEntryContactState);
  };

  const deleteOmeMeetOrgUnitEntryContact = (omeMeetOrgUnitEntryContactId) => {
    OmeMeetOrgUnitEntryContactData.deleteOmeMeetOrgUnitEntryContactData(omeMeetOrgUnitEntryContactId, omeMeetOrgUnitEntryContactState, setOmeMeetOrgUnitEntryContactState);
  };

  const confirmSaveOmeMeetOrgUnitEntryContact = () => {
    setOmeMeetOrgUnitEntryContactState({
      ...omeMeetOrgUnitEntryContactState,
      isSaved: false
    });
  };

  const resetOmeMeetOrgUnitEntryContactState = () => {
    setOmeMeetOrgUnitEntryContactState(OmeMeetOrgUnitEntryContactData.INITIAL_STATE);
  }

  return {
    omeMeetOrgUnitEntryContactState,
    setOmeMeetOrgUnitEntryContactState,
    getOmeMeetOrgUnitEntryContacts,
    postOmeMeetOrgUnitEntryContact,
    putOmeMeetOrgUnitEntryContact,
    deleteOmeMeetOrgUnitEntryContact,
    confirmSaveOmeMeetOrgUnitEntryContact,
    resetOmeMeetOrgUnitEntryContactState
  };
};

export default useOmeMeetOrgUnitEntryContactData;