import { useEffect, useState } from 'react';
import useEventData from '../../../state/event/UseEventData';

const useEventMultiSelect = () => {
  const { eventState, getEvents } = useEventData();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (eventState.isArrayLoading === false && eventState.isArrayLoaded === false) {
      getEvents();
    } else if (eventState.isArrayLoading === false && eventState.isArrayLoaded === true) {
      const eventOptions = [];
      for (const event of eventState.arrayData) {
        if (event.eventId > 0) {
          eventOptions.push({ id: event.eventId, name: event.eventName });
        }
      }

      setOptions(eventOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState]);

  return {
    eventState,
    options
  };
};

export default useEventMultiSelect;