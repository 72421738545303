import { Fragment } from 'react';

import RelayAthleteMeetEventCombobox from '../../../../components/comboboxes/relayAthleteMeetEventCombobox/RelayAthleteMeetEventCombobox';
import TimeInput from '../../../../../common/components/inputs/TimeInput';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Input from '../../../../../common/components/inputs/Input';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ legNumber, athlete, name, value, name2, value2, name3, name4, athleteUsingOverrideTime,
  meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, onFormValueChanged }) => {
  return (
    <Fragment key={value + 'Fragment'}>
      <div className={global.SmallTableRow} key={value}>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-12 col-sm-6'].join(' ')}>{`Leg ${legNumber} Name`}</div>
              <div className={[global.SmallTableRowData, 'col-xs-12 col-sm-6'].join(' ')}>
                <RelayAthleteMeetEventCombobox
                  label={""}
                  name={name}
                  valueToMatch={formState[name]}
                  error={errorState[value]}
                  message={errorState[value]}
                  onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, value, newValueLabel, name, e); }}
                  meetEventId={meetEventId}
                  omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
                />
              </div>
              <div className={['col-xs-6 col-sm-6 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-6'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Age at Meet</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-12 col-sm-6'].join(' ')}>Entry Time ({formState.courseCode || ''})</div>
              <div className={[global.SmallTableRowData, 'col-xs-12 col-sm-6'].join(' ')}>
                <TimeInput
                  label={""}
                  name={name2}
                  value={formState[name2]}
                  error={errorState[name2]}
                  message={errorState[name2]}
                  onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, name2, value, value2); }} />
              </div>
              <span className={athleteUsingOverrideTime === true ? global.DisplayComponent : global.HideComponent}>
                <div className={[global.SmallTableRowLabels, 'col-xs-12 col-sm-6'].join(' ')}>Swim Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-12 col-sm-6'].join(' ')}>
                  <DatePicker
                    label=""
                    name={name3}
                    value={formState[name3]}
                    error={errorState[name3]}
                    message={errorState[name3]}
                    onChange={(value) => { onFormValueChanged(name3, value); }} />
                </div>
              </span>
              <span className={athleteUsingOverrideTime === true ? global.DisplayComponent : global.HideComponent}>
                <div className={[global.SmallTableRowLabels, 'col-xs-12 col-sm-6'].join(' ')}>Meet Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-12 col-sm-6'].join(' ')}>
                  <Input
                    label=''
                    name={name4}
                    value={formState[name4]}
                    error={errorState[name4]}
                    message={errorState[name4]}
                    onChange={(value) => { onFormValueChanged(name4, value) }} />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const OrgUnitAdminRelayEntrySelectionOverrideSmallGrid = ({ isEdit, gridData, isLoading,
  meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, onFormValueChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) :
      <Fragment>
        <GridRow
          key={1}
          legNumber={"1"}
          isEdit={isEdit}
          athlete={gridData.athletes[0]}
          name="athlete1Name"
          value="athlete1PersonId"
          name2="athlete1SwimTime"
          value2="athlete1SwimTimeValue"
          name3="athlete1EntrySwimDate"
          name4="athlete1EntryTimeMeetName"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
          athleteUsingOverrideTime={formState.athlete1UsingOverrideTime}
        />
        <GridRow
          key={2}
          legNumber={"2"}
          athlete={gridData.athletes[1]}
          name="athlete2Name"
          value="athlete2PersonId"
          name2="athlete2SwimTime"
          value2="athlete2SwimTimeValue"
          name3="athlete2EntrySwimDate"
          name4="athlete2EntryTimeMeetName"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
          athleteUsingOverrideTime={formState.athlete2UsingOverrideTime}
        />
        <GridRow
          key={3}
          legNumber={"3"}
          athlete={gridData.athletes[2]}
          name="athlete3Name"
          value="athlete3PersonId"
          name2="athlete3SwimTime"
          value2="athlete3SwimTimeValue"
          name3="athlete3EntrySwimDate"
          name4="athlete3EntryTimeMeetName"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
          athleteUsingOverrideTime={formState.athlete3UsingOverrideTime}
        />
        <GridRow
          key={4}
          legNumber={"4"}
          athlete={gridData.athletes[3]}
          name="athlete4Name"
          value="athlete4PersonId"
          name2="athlete4SwimTime"
          value2="athlete4SwimTimeValue"
          name3="athlete4EntrySwimDate"
          name4="athlete4EntryTimeMeetName"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          onFormValueChanged={onFormValueChanged}
          athleteUsingOverrideTime={formState.athlete4UsingOverrideTime}
        />
      </Fragment>
    }
  </div >
);

export default OrgUnitAdminRelayEntrySelectionOverrideSmallGrid;