import { useContext } from 'react';

import { OmeMeetOrgUnitAthleteEntryRelayRosterEntryStateContext } from './OmeMeetOrgUnitAthleteEntryRelayRosterEntryContextProvider';

import OmeMeetOrgUnitAthleteEntryRelayRosterEntryData from './OmeMeetOrgUnitAthleteEntryRelayRosterEntryData';

const useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData = () => {
  const [omeMeetOrgUnitAthleteEntryRelayRosterEntryState, setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState] =
    useContext(OmeMeetOrgUnitAthleteEntryRelayRosterEntryStateContext);

  const getOrgUnitAthleteEntryRelayRosterEntry = (omeMeetOrgUnitEntryId) =>
    OmeMeetOrgUnitAthleteEntryRelayRosterEntryData.getOrgUnitAthleteEntryRelayRosterEntryData(omeMeetOrgUnitEntryId,
      omeMeetOrgUnitAthleteEntryRelayRosterEntryState, setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState);

  const getOrgUnitAthleteEntryRelayQualifyingTeam = (omeMeetOrgUnitEntryId, meetEventId) =>
    OmeMeetOrgUnitAthleteEntryRelayRosterEntryData.getOrgUnitAthleteEntryRelayQualifyingTeamData(omeMeetOrgUnitEntryId, meetEventId,
      omeMeetOrgUnitAthleteEntryRelayRosterEntryState, setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState);

  const postRelayMeetEventEntry = (relayAthleteObj) =>
    OmeMeetOrgUnitAthleteEntryRelayRosterEntryData.postRelayMeetEventEntryData(relayAthleteObj,
      omeMeetOrgUnitAthleteEntryRelayRosterEntryState, setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState);

  const clearOmeMeetOrgUnitAthleteEntryRelayRosterEntryArrayData = () => {
    setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState({
      ...omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const clearOmeMeetOrgUnitAthleteEntryRelayRosterEntryObjData = () => {
    setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState({
      ...omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
    setOmeMeetOrgUnitAthleteEntryRelayRosterEntryState,
    getOrgUnitAthleteEntryRelayRosterEntry,
    getOrgUnitAthleteEntryRelayQualifyingTeam,
    postRelayMeetEventEntry,
    clearOmeMeetOrgUnitAthleteEntryRelayRosterEntryArrayData,
    clearOmeMeetOrgUnitAthleteEntryRelayRosterEntryObjData
  };
};

export default useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData;