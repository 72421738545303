
export const localValidate = (formState) => {
  let errors = {};

  return errors;
};

const ExportFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ExportFormValidation;

