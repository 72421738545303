/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';

import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import Input from '../../../../../common/components/inputs/Input';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ showArchived, laneSheetHeat, onUpdateDisplayOrder, onUpdateIsSelected, onEditClicked, onDeleteClicked }) => {
  if ((showArchived === true && laneSheetHeat?.isArchived === true) || (showArchived === false && laneSheetHeat?.isArchived === false)) {
    return (
      <tr>
        <td><a onClick={() => onEditClicked(laneSheetHeat)}>{laneSheetHeat?.eventName || ''}</a></td>
        <td>{laneSheetHeat?.eventCompetitionGenderTypeName || ''}</td>
        <td>{laneSheetHeat?.sessionTypeName || ''}</td>
        <td>{laneSheetHeat?.heatNumber || ''}</td>
        <td>{laneSheetHeat?.heatDate ? formatDate(laneSheetHeat.heatDate) : ''}</td>
        <td style={{ width: "10%" }}>
          <Input
            name={"displayOrder" + laneSheetHeat.laneSheetHeatId}
            type="number"
            min="0"
            max="10000"
            value={laneSheetHeat.displayOrder}
            onChange={(value) => { onUpdateDisplayOrder(laneSheetHeat, value) }} />
        </td>
        <td>{laneSheetHeat?.isArchived === true ? 'Yes' : 'No'}</td>
        <td>
          <Checkbox
            label=""
            name={"isSelected" + laneSheetHeat.laneSheetHeatId}
            onChange={() => { onUpdateIsSelected(laneSheetHeat) }} />
        </td>
        <td>
          <button className={global.IconButtonMargin}
            type="button"
            onClick={() => onDeleteClicked(laneSheetHeat)}>
            <DeleteIcon />
          </button>
        </td>
      </tr>
    );
  }
  else {
    return (
      <Fragment />
    )
  }
}

const LaneSheetHeatLargeGrid = ({ showArchived, isLoading, gridData, onUpdateDisplayOrder, onUpdateIsSelected, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event Name</th>
        <th>Competition Category</th>
        <th>Session Type</th>
        <th>Heat</th>
        <th>Date</th>
        <th>Display Order</th>
        <th>Archived?</th>
        <th>Selected?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="8">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((laneSheetHeat, i) =>
            <LargeGridRow
              key={i}
              showArchived={showArchived}
              laneSheetHeat={laneSheetHeat}
              onUpdateDisplayOrder={onUpdateDisplayOrder}
              onUpdateIsSelected={onUpdateIsSelected}
              onEditClicked={onEditClicked}
              onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="8">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetHeatLargeGrid