import React, { Fragment } from 'react';

import MeetHostLargeGrid from './MeetHostLargeGrid';
import MeetHostSmallGrid from './MeetHostSmallGrid';

const MeetHostGrid = ({ gridData, isLoading, meetState, onEnterOmeHostClicked, onView, onEdit, onDeleteOmeMeetClicked }) => {
  return (
    <Fragment>
      <MeetHostLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        meetState={meetState}
        onView={onView}
        onEnterOmeHostClicked={onEnterOmeHostClicked}
        onEdit={onEdit}
        onDeleteOmeMeetClicked={onDeleteOmeMeetClicked}
      />
      <MeetHostSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        meetState={meetState}
        onView={onView}
        onEnterOmeHostClicked={onEnterOmeHostClicked}
        onEdit={onEdit}
        onDeleteOmeMeetClicked={onDeleteOmeMeetClicked}
      />
    </Fragment>
  )
};

export default MeetHostGrid;