import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitAthleteEntryData from './OmeMeetOrgUnitAthleteEntryData';

export const OmeMeetOrgUnitAthleteEntryStateContext = createContext();

const OmeMeetOrgUnitAthleteEntryContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitAthleteEntryData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitAthleteEntryStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitAthleteEntryStateContext.Provider>
  );
};

export default OmeMeetOrgUnitAthleteEntryContextProvider;