import { useState, useEffect } from 'react';
import useMeetTimesData from '../../../common/state/meet/meetTimes/UseMeetTimesData';

const useAddMeetTimeIndividualCompetitorCombobox = () => {
  const [optionsState, setOptionsState] = useState([])
  const { meetTimesAddState } = useMeetTimesData();

  const createUniqueOptions = (timesData) => {
    const uniqueOptions = timesData.map(e => {
      return {
        id: e.id, name: e.name
      }
    })
    setOptionsState(uniqueOptions)
  }

  useEffect(() => {
    if (meetTimesAddState.arrayData.length > 0) {
      createUniqueOptions(meetTimesAddState.arrayData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetTimesAddState.arrayData]);

  return {
    optionsState,
  };
};

export default useAddMeetTimeIndividualCompetitorCombobox;
