import React, { createContext, useState } from 'react';

import OrgUnitOmeMeetData from './OrgUnitOmeMeetData';

export const OrgUnitOmeMeetStateContext = createContext();

const OrgUnitOmeMeetContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitOmeMeetData.INITIAL_STATE);

  return (
    <OrgUnitOmeMeetStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitOmeMeetStateContext.Provider>
  );
};

export default OrgUnitOmeMeetContextProvider;