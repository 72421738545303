import React, { Fragment } from 'react';

import useMeetHost from './UseMeetHost';

import MeetHostGrid from './MeetHostGrid';
import DeleteOmeModalInfo from './DeleteOmeModalInfo';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReportPeriodDropdown from '../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetHost = () => {
  const {
    modalState,
    displayLoadingPopUp,
    omeMeetHostState,
    omeMeetStatusState,
    onModalCanceled,
    onView,
    onEnterOmeHostClicked,
    onHostANewMeetButtonClicked,
    onEdit,
    onDeleteOmeMeet,
    onDeleteOmeMeetClicked,
    onFilterClicked,
    onClearFilterClicked,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    USAS_ORGANIZATION_ID
  } = useMeetHost();

  return (
    <Fragment>
      <div className='row'>
        <div className="col-xs-12">
          <Headings.H3>Meet Host</Headings.H3>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <Input
              label="Meet Name"
              name="meetName"
              value={formState.meetName}
              error={errorState.meetName}
              message={errorState.meetName}
              onChange={(value) => { onFormValueChanged('meetName', value); }}
            />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <Dropdown
              label="OME Meet Status"
              name="omeMeetStatusId"
              options={omeMeetStatusState}
              value={formState.omeMeetStatusId}
              error={errorState.omeMeetStatusId}
              message={errorState.omeMeetStatusId}
              isLoading={false}
              onChange={(value) => { onFormValueChanged('omeMeetStatusId', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <ReportPeriodDropdown
              label="Registration Year"
              name="reportPeriodId"
              value={formState.reportPeriodId}
              error={errorState.reportPeriodId}
              message={errorState.reportPeriodId}
              onChange={(newValue, newValueLabel) =>
                onValueTextPairChanged(newValue, 'reportPeriodId', newValueLabel, 'reportPeriodName')}
              organizationId={USAS_ORGANIZATION_ID}
              defaultOption={true}
              currentAndFutureOptionsOnly={false} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <YesNoSwitch
              label="Future Meet?"
              name="isCurrent"
              checked={formState.isCurrent}
              error={errorState.isCurrent}
              message={errorState.isCurrent}
              onChange={(value) => { onFormValueChanged('isCurrent', value) }} />
          </div>
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </div>
      </form>
      <div className='row usas-extra-top-margin  usas-extra-bottom-margin'>
        {omeMeetHostState.arrayData?.length > 15 && omeMeetHostState.isArrayLoading === false &&
          <div className='visible-md visible-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton onClick={onHostANewMeetButtonClicked}>Search For a New Meet to Host</ActionIntraPageButton>
            </div>
          </div>
        }
        {omeMeetHostState.arrayData?.length > 0 && omeMeetHostState.isArrayLoading === false &&
          <div className='hidden-md hidden-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton onClick={onHostANewMeetButtonClicked}>Search For a New Meet to Host</ActionIntraPageButton>
            </div>
          </div>
        }
        <div className='col-xs-12'>
          <MeetHostGrid
            state={omeMeetHostState}
            onView={onView}
            onEnterOmeHostClicked={onEnterOmeHostClicked}
            onEdit={onEdit}
            onDeleteOmeMeetClicked={onDeleteOmeMeetClicked} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12  usas-extra-top-margin'>
          <ActionIntraPageButton onClick={onHostANewMeetButtonClicked}>Search For a New Meet to Host</ActionIntraPageButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className='col-xs-12'>
            <DeleteOmeModalInfo modalState={modalState} />
            <p><b>Entry Start - End Dates:</b> {modalState?.entryDates}</p>
            <p><b>Time Zone:</b> {modalState?.timeZone}</p>
            <p><b>OME Meet Status:</b> {modalState?.omeMeetStatus}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteOmeMeet(e, modalState.omeMeetId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={displayLoadingPopUp}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetHostState.isSaving}
      />
    </Fragment>
  );
};

export default MeetHost;