import { isParticipantSelectionsValid } from "../../utils/SelectionsUtils";

export const ConfigurationIsComplete = (selectionsObj) => {
  return selectionsObj.isConfigComplete === true;
};

export const MeetEventsIsComplete = (selectionsObj) => {
  return selectionsObj.isConfigComplete === true;
};

export const MeetEntriesIsComplete = (selectionsObj) => {
  return selectionsObj.isConfigComplete === true;
};

export const ParticipantSelectionsIsComplete = (selectionsObj) => {
  return isParticipantSelectionsValid(selectionsObj.selectedSwimmers, selectionsObj.maxSwimmers);
};

export const ReportsIsComplete = (selectionsObj) => {
  return false; //never complete?
};

export const ExportIsComplete = (selectionsObj) => {
  return false; //never complete
}