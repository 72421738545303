import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import StopwatchIcon from '../../../../../common/components/icons/StopwatchIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const LargeDetailTableRow = ({ event }) => {
  return (
    <tr>
      <td>{event.eventName || ''}</td>
      <td>{event.eventCompetitionGenderTypeName || ''}</td>
      <td>{event.ageGroupName || ''}</td>
      <td>{event.meetSessionName || ''}</td>
      <td>{event.entryTime ? formatTimeForDisplay(event.entryTime) : 'NT'} {event.entryTime ? event.qualifyingCourseCode || '' : ''}</td>
      <td>{event.isAlternateEvent === true ? `ALT: ${event.qualifyingEventName || ''}` : event.isNonConformingTime === true ? `NC: ${event.qualifyingEventName || ''}` : `${event.qualifyingEventName || ''}`}</td>
      <td>{event.qualifiedAsBonus === true ? 'Yes' : event.qualifiedAsBonus === false ? 'No' : ''}</td>
      <td>{event.swimTimeEntryOverride === true ? 'Yes' : event.swimTimeEntryOverride === false ? 'No' : ''}</td>
      <td>{event.status || ''}</td>
    </tr>
  )
}

const LargeDetailTable = ({ athlete }) => {
  return (
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th>Event - Course</th>
          <th style={{ minWidth: '120px' }}>Comp. Category</th>
          <th style={{ minWidth: '90px' }}>Age Group</th>
          <th>Session</th>
          <th style={{ minWidth: '100px' }}>Entry Time</th>
          <th style={{ minWidth: '100px' }}>Time Event</th>
          <th>Bonus?</th>
          <th>Override?</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(athlete.events) && athlete.events.length > 0 &&
          athlete.events.map((event, i) =>
            <LargeDetailTableRow
              event={event}
              key={i}
            />)}
      </tbody>
    </table>
  );
};

const GridRow = ({ index, athlete, onEditAthleteClicked, expandedId, onClick, onUpdateAthleteTimes }) => {
  return (
    <Fragment key={athlete.orgUnitAthleteEntryId || index + 'Fragment'}>
      <tr key={athlete.orgUnitAthleteEntryId || index}>
        <td>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''}</td>
        <td>{athlete.competitionGenderTypeName || ''}</td>
        <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
        <td>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : ''}</td>
        <td>{athlete.status || ''}</td>
        <td>
          {onEditAthleteClicked &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onEditAthleteClicked(e, athlete)}>
              <EditIcon />
            </button>}
          {Array.isArray(athlete.events) && athlete.events.length > 0 &&
            <Fragment>
              {onUpdateAthleteTimes &&
                <button className={global.IconButtonMargin}
                  type="button"
                  onClick={(e) => onUpdateAthleteTimes(e, athlete)}>
                  <StopwatchIcon toolTipText='Update Individual Event Entry Times for Athlete' />
                </button>}
              <button
                className={global.IconButtonMargin}
                type="button"
                onClick={(e) => onClick(e, athlete.orgUnitAthleteEntryId)}>
                {expandedId === athlete.orgUnitAthleteEntryId ? <HideIcon /> : <ShowIcon />}
              </button>
            </Fragment>
          }
        </td>
      </tr>
      {expandedId === athlete.orgUnitAthleteEntryId && Array.isArray(athlete.events) && athlete.events.length > 0 &&
        <tr className={global.Expanded}>
          <td colSpan="6">
            <LargeDetailTable athlete={athlete} />
          </td>
        </tr>}
    </Fragment>
  );
};

const OrgUnitAdminRosterEntriesLargeGrid = ({ gridData, isLoading, onEditAthleteClicked, expandedId, onClick, onUpdateAthleteTimes }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Competition Category</th>
        <th>Birth Date</th>
        <th>Age at Meet</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="6">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((athlete, i) =>
            <GridRow
              key={i}
              index={i}
              athlete={athlete}
              onEditAthleteClicked={onEditAthleteClicked}
              expandedId={expandedId}
              onClick={onClick}
              onUpdateAthleteTimes={onUpdateAthleteTimes}
            />)
          :
          <tr>
            <td colSpan={6}>No Athletes</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminRosterEntriesLargeGrid;