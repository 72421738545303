import React, { Fragment } from 'react';

import useAthleteUserEntriesDetail from './UseAthleteUserEntriesDetail';

import AthleteUserEntriesDetailGrid from './AthleteUserEntriesDetailGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const AthleteUserEntriesDetailReadOnly = () => {
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, gridState } = useAthleteUserEntriesDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H3>Manage Athlete Events</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AthleteUserEntriesDetailGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading ||
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoading ||
          omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading} />
    </Fragment>
  );
};

export default AthleteUserEntriesDetailReadOnly;