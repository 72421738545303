import useConfigurationForm from "./UseConfigurationForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import Headings from "../../../../../common/components/headings/Headings";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";

const ConfigurationForm = ({ onSubmitFormCallback, onSecondaryButtonClicked, submitButtonText, secondaryButtonText,
  selectionsStateObj, children }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    getTotalParticipants
  } = useConfigurationForm(onSubmitFormCallback, selectionsStateObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row'>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Organization'
            name="meetHost"
            value={formState.meetHost} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Meet Name'
            name="meetName"
            value={formState.meetName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Meet Dates'
            name="meetDates"
            value={formState.meetDates} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Gender'
            name="competitionGenderTypeName"
            value={formState.competitionGenderTypeName} />
        </div>
        <div className="col-xs-12">
          <Input
            label='Description*'
            name="selectionDesc"
            value={formState.selectionDesc}
            error={errorState.selectionDesc}
            message={errorState.selectionDesc}
            onChange={(value) => { onFormValueChanged('selectionDesc', value) }} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Maximum Number of Participants</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Swimmers*'
            name="maxSwimmers"
            value={formState.maxSwimmers}
            error={errorState.maxSwimmers}
            message={errorState.maxSwimmers}
            onChange={(value) => { onFormValueChanged('maxSwimmers', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Divers*'
            name="maxDivers"
            value={formState.maxDivers}
            error={errorState.maxDivers}
            message={errorState.maxDivers}
            onChange={(value) => { onFormValueChanged('maxDivers', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Total Participants'
            name="totalParticipants"
            value={getTotalParticipants(formState.maxSwimmers, formState.maxDivers)} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Initial Selection Configuration</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Minimum Number of Rows/Places to Seed*'
            name="minRowsToSeed"
            value={formState.minRowsToSeed}
            error={errorState.minRowsToSeed}
            message={errorState.minRowsToSeed}
            onChange={(value) => { onFormValueChanged('minRowsToSeed', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Minimum Number of Relays to Accept*'
            name="minRelayRowsToAccept"
            value={formState.minRelayRowsToAccept}
            error={errorState.minRelayRowsToAccept}
            message={errorState.minRelayRowsToAccept}
            onChange={(value) => { onFormValueChanged('minRelayRowsToAccept', value) }} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Individual/Relay Ratio</Headings.H5>
          <p>When adding rows based on individual to relay ratio, use the following ratio</p>
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Individual Row(s)*'
            name="addRatioIndRows"
            value={formState.addRatioIndRows}
            error={errorState.addRatioIndRows}
            message={errorState.addRatioIndRows}
            onChange={(value) => { onFormValueChanged('addRatioIndRows', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Relay Row(s)*'
            name="addRatioRelayRows"
            value={formState.addRatioRelayRows}
            error={errorState.addRatioRelayRows}
            message={errorState.addRatioRelayRows}
            onChange={(value) => { onFormValueChanged('addRatioRelayRows', value) }} />
        </div>
      </div>
      {children}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <YesNoSwitch
            label='Include entries that are not checked out? (only use this for trial runs)'
            name="includeEntriesNotCheckedOut"
            checked={formState.includeEntriesNotCheckedOut}
            error={errorState.includeEntriesNotCheckedOut}
            message={errorState.includeEntriesNotCheckedOut}
            onChange={(value) => { onFormValueChanged('includeEntriesNotCheckedOut', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default ConfigurationForm;