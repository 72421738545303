import React, { Fragment } from 'react';

import useLaneSheetLanding from './UseLaneSheetLanding';

import LaneSheetLandingGrid from '../components/grids/LaneSheetLandingGrid';
import LaneSheetLandingForm from '../components/forms/LaneSheetLandingForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LaneSheetLanding = () => {
  const {
    isLoading,
    searchFilterObject,
    showFilters,
    gridData,
    onSubmitFormCallback,
    toggleLaneSheetLandingFilters,
    onEditLaneSheetMeetClicked,
    onAddLaneSheetMeetClicked
  } = useLaneSheetLanding();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Lane Sheet Landing</Headings.H3>
        </div>
      </div>
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <LaneSheetLandingForm onSubmitFormCallback={onSubmitFormCallback} searchFilterObject={searchFilterObject} />
      </div>
      <LaneSheetLandingGrid
        isLoading={isLoading}
        gridData={gridData}
        onAddClicked={onAddLaneSheetMeetClicked}
        onEditClicked={onEditLaneSheetMeetClicked}
        onFiltersToggle={toggleLaneSheetLandingFilters}
        showFilters={showFilters} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LaneSheetLanding;