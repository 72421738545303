import React, { Fragment } from 'react';

import useMeetSearch from './UseMeetSearch';

import SearchMeet from '../../../../common/components/searches/searchMeet/SearchMeet';

const SimplifiedMeetSearch = () => {
  const simplifiedView = true;
  const { searchMeetState, onEditMeet } = useMeetSearch(simplifiedView);

  return (
    <Fragment>
      <SearchMeet
        pageTitle={"Meet Search"}
        searchButtonText={"Search for Meet"}
        onEdit={onEditMeet}
        searchMeetState={searchMeetState}
        simplifiedView={simplifiedView}
      />
    </Fragment>
  );
}

export default SimplifiedMeetSearch;