import React, { createContext, useState } from 'react';

export const ParticipantGridDetailPopupStateContext = createContext();

const INITIAL_STATE = {
  displayPopup: false,
  popupTitle: '',
  screenX: 0,
  screenY: 0,
  participant: {}
};

const ParticipantGridDetailPopupContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <ParticipantGridDetailPopupStateContext.Provider value={stateHook}>
      {children}
    </ParticipantGridDetailPopupStateContext.Provider>
  );
};

export default ParticipantGridDetailPopupContextProvider;