import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddMeetTimeSelectAthletesValidation';

import { createTreeViewOptions } from '../utils/MeetUtils';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';
import useForm from '../../../../../common/utils/UseForm';

import NavLinks from '../../NavLinks';

const useAddMeetTimeSelectAthletes = () => {
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { meetTimesAddState, getOrgUnitAthletes, updateAthleteTreeViewIds } = useMeetTimesData();
  const { errorState, formState, handleSubmit, updateFormState, setIsDirty, setFormState
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [treeState, setTreeState] = useState(getInitialTreeState());

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES_SELECT_ORGS);
  };

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      if (treeState.isArrayLoading === false) {
        if (meetTimesAddState.orgTreeViewIds.length > 0) {
          getOrgUnitAthletes(meetTimesAddState.orgTreeViewIds, treeState, setTreeState);
        }
      }
    }

    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (treeState.isArrayLoaded === true) {
      const treeViewOptions = createTreeViewOptions(treeState.arrayData, 'id', 'name', meetTimesAddState.athleteTreeViewIds);
      const invalidIds = []

      for (const club of treeViewOptions.newTreeData) {
        if (club.children === undefined || club.children.length === 0) {
          club.name = club.name + ' (No active athletes available)';
          invalidIds.push(club.id);
        }
      }

      setTreeState({
        ...treeState,
        treeData: treeViewOptions.newTreeData
      });
      setFormState({
        ...formState,
        athletes: treeViewOptions.idNamePairs,
        invalidIds
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeState.isArrayLoaded]);

  function submitFormCallback(formState) {
    updateAthleteTreeViewIds(createAthleteOptionsWithGender(formState.athletes));

    navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME);
  };

  function createAthleteOptionsWithGender(athletes) {
    const athleteOptions = [];

    if (Array.isArray(athletes) && athletes.length > 0) {
      athletes.forEach(athlete => {
        for (let i = 0; i < treeState.arrayData.length; i++) {
          if (Array.isArray(treeState.arrayData[i].children) && treeState.arrayData[i].children.length > 0) {
            const athleteData = treeState.arrayData[i].children.find(data => data.id === athlete.id);

            if (athleteData) {
              athleteOptions.push({
                id: athleteData.id,
                name: athleteData.name,
                gender: athleteData.competitionGenderTypeName
              });
            }
          }
        }
      });
    }

    return athleteOptions;
  }

  function getInitialFormState() {
    return {
      athletes: [],
      invalidIds: []
    };
  };

  function getInitialTreeState() {
    return {
      arrayData: [],
      treeData: [],
      isArrayLoading: false,
      isArrayLoaded: false
    };
  };

  return {
    formState,
    errorState,
    treeState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked
  };
}

export default useAddMeetTimeSelectAthletes;