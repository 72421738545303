import { useContext } from 'react';

import { OrgUnitAthleteEntryPurchaseStateContext } from './OrgUnitAthleteEntryPurchaseContextProvider';

import OrgUnitAthleteEntryPurchaseData from './OrgUnitAthleteEntryPurchaseData';

const useOrgUnitAthleteEntryPurchaseData = () => {
  const [orgUnitAthleteEntryPurchaseState, setOrgUnitAthleteEntryPurchaseState] = useContext(OrgUnitAthleteEntryPurchaseStateContext);

  const getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryId = (orgUnitAthleteEntryId) =>
    OrgUnitAthleteEntryPurchaseData.getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryIdData(orgUnitAthleteEntryId, orgUnitAthleteEntryPurchaseState, setOrgUnitAthleteEntryPurchaseState);

  const postOrgUnitAthleteEntryPurchase = (orgUnitAthleteEntryId, OrgUnitAthleteEntryPurchaseObj) =>
    OrgUnitAthleteEntryPurchaseData.postOrgUnitAthleteEntryPurchaseData(orgUnitAthleteEntryId, OrgUnitAthleteEntryPurchaseObj, orgUnitAthleteEntryPurchaseState, setOrgUnitAthleteEntryPurchaseState);

  const clearOrgUnitAthleteEntryPurchaseObjData = () => {
    setOrgUnitAthleteEntryPurchaseState({
      ...orgUnitAthleteEntryPurchaseState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOrgUnitAthleteEntryPurchaseArrayData = () => {
    setOrgUnitAthleteEntryPurchaseState({
      ...orgUnitAthleteEntryPurchaseState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSaveOrgUnitAthleteEntryPurchase = () => {
    setOrgUnitAthleteEntryPurchaseState({
      ...orgUnitAthleteEntryPurchaseState,
      isSaved: false
    });
  };

  return {
    orgUnitAthleteEntryPurchaseState,
    setOrgUnitAthleteEntryPurchaseState,
    getOrgUnitAthleteEntryPurchaseByOrgUnitAthleteEntryId,
    postOrgUnitAthleteEntryPurchase,
    clearOrgUnitAthleteEntryPurchaseObjData,
    clearOrgUnitAthleteEntryPurchaseArrayData,
    confirmSaveOrgUnitAthleteEntryPurchase
  };
};

export default useOrgUnitAthleteEntryPurchaseData;