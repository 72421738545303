import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import Icon from '../../../../../common/components/icons/Icon';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ athlete, onAddSecondaryRepToAthleteClicked, onDeleteAthleteClicked, allowRelayOnly, showSecondaryRep }) => {
  return (
    <tr key={athlete.orgUnitAthleteEntryId}>
      <td>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : ''}</td>
      <td style={{ minWidth: '120px' }}>{athlete.competitionGenderTypeName || ''}</td>
      <td style={{ minWidth: '100px' }}>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
      <td style={{ minWidth: '100px' }}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : ''}</td>
      <td>{athlete.status || ''}</td>
      {allowRelayOnly === true &&
        <td style={{ minWidth: '160px' }}>{athlete.isRelayOnlyAthlete === true ? 'Yes' : athlete.isRelayOnlyAthlete === false ? 'No' : ''}</td>}
      {showSecondaryRep === true &&
        <td style={{ minWidth: '195px' }}>{athlete.secondaryRepresentationOrgUnitId ? `${athlete.secondaryRepresentationOrgUnitName || ''} (${athlete.secondaryRepresentationParentOrgUnitCode || ''}/${athlete.secondaryRepresentationOrgUnitCode || ''})` : ''}</td>}
      <td style={{ minWidth: '90px' }}>
        {onAddSecondaryRepToAthleteClicked && showSecondaryRep === true &&
          <button className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onAddSecondaryRepToAthleteClicked(e, athlete)}>
            <Icon tooltip={"Edit Secondary Representation"} iconObj={faEdit} />
          </button>}
        {onDeleteAthleteClicked && (athlete.canBeDeleted === true || (athlete.isRelayAthlete === false && athlete.hasPaid === false)) &&
          <button className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onDeleteAthleteClicked(e, athlete)}>
            <DeleteIcon />
          </button>}
      </td>
    </tr>
  );
};

const OrgUnitAdminRosterLargeGrid = ({ gridData, isLoading, onAddSecondaryRepToAthleteClicked, onDeleteAthleteClicked, allowRelayOnly, showSecondaryRep }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Comp. Category</th>
        <th>Birth Date</th>
        <th>Age at Meet</th>
        <th>Status</th>
        {allowRelayOnly === true &&
          <th>Relay Only Swimmer?</th>}
        {showSecondaryRep === true &&
          <th>Secondary Representation</th>}
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={allowRelayOnly === true ? (showSecondaryRep === true ? '8' : '7') : (showSecondaryRep === true ? '7' : '6')}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((athlete, i) =>
            <GridRow
              key={i}
              athlete={athlete}
              onAddSecondaryRepToAthleteClicked={onAddSecondaryRepToAthleteClicked}
              onDeleteAthleteClicked={onDeleteAthleteClicked}
              allowRelayOnly={allowRelayOnly}
              showSecondaryRep={showSecondaryRep} />)
          :
          <tr>
            <td colSpan={allowRelayOnly === true ? (showSecondaryRep === true ? '8' : '7') : (showSecondaryRep === true ? '7' : '6')}>No Athletes</td>
          </tr>
      }
    </tbody>
  </table>
);

export default OrgUnitAdminRosterLargeGrid;