import React, { Fragment } from 'react';

import useOmeAgeGroupsDetail from './UseOmeAgeGroupsDetail';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import Input from '../../../../../common/components/inputs/Input';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OmeAgeGroupsDetail = () => {
  const {
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  } = useOmeAgeGroupsDetail();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_AGE_GROUPS_DETAIL}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Input
                label="Age Group Name*"
                name="ageGroupName"
                value={formState.ageGroupName}
                error={errorState.ageGroupName}
                message={errorState.ageGroupName}
                onChange={(value) => { onFormValueChanged('ageGroupName', value) }} />
            </div>
            <div className="col-xs-12 col-sm-3">
              <Input
                label="Min Age*"
                name="minAge"
                type="number"
                min="1"
                max="99"
                value={formState.minAge}
                error={errorState.minAge}
                message={errorState.minAge}
                onChange={(value) => { onFormValueChanged('minAge', value) }} />
            </div>
            <div className="col-xs-12 col-sm-3">
              <Input
                label="Max Age*"
                name="maxAge"
                type="number"
                min="1"
                max="99"
                value={formState.maxAge}
                error={errorState.maxAge}
                message={errorState.maxAge}
                onChange={(value) => { onFormValueChanged('maxAge', value) }} />
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeAgeGroupsDetail;