import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';
import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetMeetSessionByMeetIdData = (meetId, state, setState) => {
  if (state.isArrayLoaded !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const meetIdForUrl = meetId ? encodeURIComponent(meetId) : 'NaN';
    const url = `/MeetSession/Meet/${meetIdForUrl}`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ meetSessionId: Constants.DEFAULT_ID, sessionOrderId: '', sessionDate: '', sessionType: { typeName: '' } });
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            dataAsOptionsWithNames: arrayData.map(x => {
              return {
                id: x.meetSessionId, name: `${x.sessionOrderId} ${x.sessionOrderId ? `(${x.sessionType?.typeName || ''} ${x.sessionDate ? formatDate(x.sessionDate) : ''})` : '--'}`
              };
            }),
            message: ''
          })
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetMeetSessionByMeetIdData;