import { useEffect } from 'react';

import validate from './OrgUnitAdminRosterPermissionsValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useDateTimeData from '../../../../../common/state/datetime/UseDateTimeData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDateTime } from '../../../../../common/utils/DateFunctions';

const useOrgUnitAdminRosterPermissions = () => {
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState, putOmeMeetOrgUnitEntry } = useOmeMeetOrgUnitEntryData();
  const { dateTimeState } = useDateTimeData();
  const { oUAdminEntryContextState } = useOUAdminEntryContextData();
  const { errorState, formState, handleSubmit, updateFormState, setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetState.isObjLoaded === true && dateTimeState.isObjLoaded === true) {
      setFormData({
        ...formState,
        orgUnitAthleteRegistrationAllowed: omeMeetOrgUnitEntryState.objData?.orgUnitAthleteRegistrationAllowed ?? '',
        orgUnitRegistrationStartDate: omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationStartDate ? formatDateTime(omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationStartDate) : Constants.BLANK_DATETIME_STRING,
        orgUnitRegistrationEndDate: omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationEndDate ? formatDateTime(omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationEndDate) : Constants.BLANK_DATETIME_STRING,
        timeZone: omeMeetState.objData?.timeZone || '',
        meetEntryStartDate: omeMeetState.objData?.meetEntryStartDate ? formatDateTime(omeMeetState.objData?.meetEntryStartDate) : Constants.BLANK_DATETIME_STRING,
        meetEntryStartDateDatabaseFormat: omeMeetState.objData?.meetEntryStartDate || '',
        meetEntryEndDate: omeMeetState.objData?.meetEntryEndDate ? formatDateTime(omeMeetState.objData?.meetEntryEndDate) : Constants.BLANK_DATETIME_STRING,
        meetEntryEndDateDatabaseFormat: omeMeetState.objData?.meetEntryEndDate || ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetState, dateTimeState.isObjLoaded])

  function submitFormCallback(formState) {
    if (formStateHasBeenSaved(formState) === false) {
      putOmeMeetOrgUnitEntry(omeMeetState?.objData?.omeMeetId, omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId, updateOmeMeetOrgUnitEntryObject());
    }
  };

  function updateOmeMeetOrgUnitEntryObject() {
    const omeMeetOrgUnitEntryCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitEntryState.objData));
    return {
      ...omeMeetOrgUnitEntryCopy,
      orgUnitAthleteRegistrationAllowed: formState.orgUnitAthleteRegistrationAllowed,
      orgUnitRegistrationStartDate: formState.orgUnitAthleteRegistrationAllowed === true ? formState.orgUnitRegistrationStartDate : null,
      orgUnitRegistrationEndDate: formState.orgUnitAthleteRegistrationAllowed === true ? formState.orgUnitRegistrationEndDate : null
    }
  };

  function formStateHasBeenSaved(formState) {
    //compare formState to omeMeetOrgUnitEntryState to see if formState has been saved
    if (formState?.orgUnitAthleteRegistrationAllowed !== omeMeetOrgUnitEntryState.objData?.orgUnitAthleteRegistrationAllowed
      && (formState?.orgUnitAthleteRegistrationAllowed !== '' || omeMeetOrgUnitEntryState.objData?.orgUnitAthleteRegistrationAllowed !== null)) {
      return false;
    }
    if (formState?.orgUnitRegistrationStartDate !== formatDateTime(omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationStartDate)
      && (formState?.orgUnitRegistrationStartDate !== Constants.BLANK_DATETIME_STRING || omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationStartDate !== null)) {
      return false;
    }
    if (formState?.orgUnitRegistrationEndDate !== formatDateTime(omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationEndDate)
      && (formState?.orgUnitRegistrationEndDate !== Constants.BLANK_DATETIME_STRING || omeMeetOrgUnitEntryState.objData?.orgUnitRegistrationEndDate !== null)) {
      return false;
    }

    return true;
  }

  function getInitialFormState() {
    return {
      orgUnitAthleteRegistrationAllowed: '',
      orgUnitRegistrationStartDate: Constants.BLANK_DATETIME_STRING,
      orgUnitRegistrationEndDate: Constants.BLANK_DATETIME_STRING,
      timeZone: '',
      meetEntryStartDate: Constants.BLANK_DATETIME_STRING,
      meetEntryStartDateDatabaseFormat: '',
      meetEntryEndDate: Constants.BLANK_DATETIME_STRING,
      meetEntryEndDateDatabaseFormat: ''
    }
  };

  return {
    oUAdminEntryContextState,
    omeMeetOrgUnitEntryState,
    errorState,
    formState,
    onFormValueChanged: updateFormState,
    handleSubmit
  };
}

export default useOrgUnitAdminRosterPermissions;