import { useEffect } from 'react';

import useSelectionsTimeStandardData from '../../state/selectionsTimeStandard/UseSelectionsTimeStandardData';

const useSelectionsTimeStandardDropdown = (orgUnitId, omeMeetId) => {
  const { selectionsTimeStandardState, setSelectionsTimeStandardState, getSelectionsTimeStandards } = useSelectionsTimeStandardData();

  useEffect(() => {
    if (orgUnitId && omeMeetId) {
      if (selectionsTimeStandardState.isArrayLoading !== true
        && (selectionsTimeStandardState.isArrayLoaded !== true || selectionsTimeStandardState.orgUnitId !== orgUnitId
          || selectionsTimeStandardState.omeMeetId !== omeMeetId)) {
        const getSelectionsTimeStandardsPromise = getSelectionsTimeStandards(orgUnitId, omeMeetId);

        if (getSelectionsTimeStandardsPromise !== null) {
          getSelectionsTimeStandardsPromise.then((newState) => {
            if (newState !== null) {
              setSelectionsTimeStandardState({
                ...newState,
                items: newState.arrayData.map(x => { return { id: x.timeStandardId, name: x.standardName }; }),
                areItemsLoaded: true
              });
            }
          }).catch((e) => {
            console.log(e);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsTimeStandardState, orgUnitId, omeMeetId]);

  return {
    selectionsTimeStandardState
  };
};

export default useSelectionsTimeStandardDropdown;