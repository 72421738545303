import { useContext } from 'react';

import { EventCompetitionGenderTypeStateContext } from './EventCompetitionGenderTypeContextProvider';
import EventCompetitionGenderTypeData from './EventCompetitionGenderTypeData';

const useEventCompetitionGenderTypeData = () => {
  const [eventCompetitionGenderState, setEventCompetitionGenderState] = useContext(EventCompetitionGenderTypeStateContext);

  const getEventCompetitionGenderTypes = () =>
  EventCompetitionGenderTypeData.getEventCompetitionGenderTypes(eventCompetitionGenderState, setEventCompetitionGenderState);

  return {
    eventCompetitionGenderState,
    setEventCompetitionGenderState,
    getEventCompetitionGenderTypes
  }
}


export default useEventCompetitionGenderTypeData;