import React, { Fragment } from 'react';

import useOmeSessionsDetail from './UseOmeSessionsDetail';

import useSessionsDetail from '../../../../../common/components/meetSessions/UseSessionsDetail';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import SessionsDetail from '../../../../../common/components/meetSessions/SessionsDetail';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

const OmeSessionsDetail = () => {
  const { meetState } = useOmeSessionsDetail();
  const { onSaveButtonClicked, onBackButtonClicked, meetSessionsState } = useSessionsDetail();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_SESSIONS_DETAIL}
        onNextButtonClicked={onSaveButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={meetSessionsState?.errorState?.error}>
        <SessionsDetail />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={meetState.isSaving} />
      </ViewTemplate>
    </Fragment>
  );
};

export default OmeSessionsDetail;