import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getRaceStatsCoachesData from './GetRaceStatsCoachesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RaceStatsCoachesData = {
  INITIAL_STATE,
  getRaceStatsCoachesData
};

export default RaceStatsCoachesData;