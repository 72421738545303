import React from 'react';

import useSelectionsById from './UseSelectionsById';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const SelectionsById = () => {
  useSelectionsById();

  return (
    <PopUpModal
      widthPct={50}
      maxWidth={250}
      title={Constants.LOADING_MSG}
      displayPopUp={true} />
  );
};

export default SelectionsById;