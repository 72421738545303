import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../utils/NavLinks';

import useLaneSheetData from '../../../state/laneSheet/UseLaneSheetData';
import useLaneSheetTopHeaderData from '../../../state/laneSheetTopHeader/UseLaneSheetTopHeaderData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  topHeaderInfo: {}
};

const INITIAL_DELETE_TOP_HEADER_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const useLaneSheetTopHeaderNotes = () => {
  const navigate = useNavigate();
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetTopHeaderState, getLaneSheetTopHeader, deleteLaneSheetTopHeaderInfo } = useLaneSheetTopHeaderData();
  const [deleteTopHeaderInfoState, setDeleteTopHeaderInfoState] = useState(INITIAL_DELETE_TOP_HEADER_INFO_STATE);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);

  const onAddTopHeaderInfoClicked = () => {
    navigate(NavLinks.LANE_SHEET_TOP_HEADER_INFO_DETAIL);
  };

  const onDeleteTopHeaderInfoClicked = (topHeaderInfo) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      topHeaderInfo
    });
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeleteModalDeleteClicked = () => {
    const laneSheetTopInfoId = deleteModalState.topHeaderInfo?.laneSheetTopInfoId;

    if (laneSheetTopInfoId > 0) {
      const deleteLaneSheetTopHeaderPromise = deleteLaneSheetTopHeaderInfo(laneSheetTopInfoId, deleteTopHeaderInfoState, setDeleteTopHeaderInfoState);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLaneSheetTopHeaderPromise !== null) {
        deleteLaneSheetTopHeaderPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

            if (laneSheetMeetId > 0) {
              const getLaneSheetTopHeaderPromise = getLaneSheetTopHeader(laneSheetMeetId);

              if (getLaneSheetTopHeaderPromise !== null) {
                getLaneSheetTopHeaderPromise.catch((e) => {
                  //TODO context error
                });
              }
            }
          }
        }).catch((e) => {
          //TODO local error
        });
      }
    }
  };

  useEffect(() => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const getLaneSheetTopHeaderPromise = getLaneSheetTopHeader(laneSheetMeetId);

      if (getLaneSheetTopHeaderPromise !== null) {
        getLaneSheetTopHeaderPromise.catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetState]);

  return {
    isSaving: deleteTopHeaderInfoState.isObjLoading,
    isLoading: laneSheetTopHeaderState.isArrayLoading,
    gridData: laneSheetTopHeaderState.arrayData || [],
    deleteModalState,
    onAddTopHeaderInfoClicked,
    onDeleteTopHeaderInfoClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  }
};

export default useLaneSheetTopHeaderNotes;