import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import PersonIcon from '../../../../../common/components/icons/PersonIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ meetEvent, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{meetEvent?.eventName || ''} / {meetEvent?.eventCode || ''}</td>
    <td>{meetEvent?.eventCompetitionGenderTypeName || ''}</td>
    <td>{meetEvent?.numberOfParticipants || 0}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => onEditClicked(meetEvent)}>
        <PersonIcon />
      </button>&nbsp;
      {meetEvent.canBeDeleted === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDeleteClicked(meetEvent)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const LaneSheetMeetEventsLargeGrid = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event Name / Code</th>
        <th>Competition Category</th>
        <th>Number Of Participants</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((meetEvent, i) =>
            <LargeGridRow
              key={i}
              meetEvent={meetEvent}
              onEditClicked={onEditClicked}
              onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="4">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default LaneSheetMeetEventsLargeGrid