import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MeetHostValidation';

import MyMeetsNavLinks from '../MyMeetsNavLinks';
import MeetNavLinks from '../../meet/NavLinks';

import useOmeMeetHostData from '../../../state/omeMeetHost/UseOmeMeetHostData';
import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useReportPeriodSeasonData from '../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import UseForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';

const TAXONOMIES = ['']; //TODO none in database?
const SCOPE = 'OnlineMeetEntry';

const INITIAL_FORM_STATE = {
  meetName: '',
  omeMeetStatusId: Constants.DEFAULT_ID,
  reportPeriodId: Constants.DEFAULT_ID,
  reportPeriodName: '',
  sortBy: 'MeetName',
  isCurrent: true
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete OME Meet?',
  meetHost: '',
  meetName: '',
  meetDates: '',
  entryDates: '',
  timeZone: '',
  omeMeetStatus: '',
  omeMeetId: Constants.DEFAULT_ID
};

const useMeetHost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ isInititalPageLoadComplete: false, tryGet: false, tryRedirect: false, omeMeet: {} });
  const [omeMeetStatusState] = useState([
    { id: Constants.DEFAULT_ID, name: "--" },
    { id: 1, name: Constants.WORKFLOW_STATUS_NOT_CREATED },
    { id: 2, name: Constants.WORKFLOW_STATUS_IN_PROGRESS },
    { id: 3, name: Constants.WORKFLOW_STATUS_CREATED }
  ]);
  const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { formState, setFormState, errorState, setErrors, onFormValueChanged, onValueTextPairChanged, handleSubmit, setFormData } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { omeMeetHostState, omeMeetHostFiltersState, searchMeetHost, deleteOmeMeet } = useOmeMeetHostData();
  const { omeMeetTeamEntryFiltersState } = useOmeMeetTeamEntryData();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { reportPeriodSeasonState } = useReportPeriodSeasonData();

  const onHostANewMeetButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(MeetNavLinks.MEET_SEARCH);
  };

  const onEnterOmeHostClicked = (e, omeMeet) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, tryRedirect: true, omeMeet: omeMeet });
  };

  const onView = (e, omeMeet) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    getContextSecurity(omeMeet?.omeMeetId, TAXONOMIES, SCOPE);
    setState({ ...state, tryRedirect: true, omeMeet: omeMeet });
  };

  const onEdit = (e, omeMeet) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    getContextSecurity(omeMeet?.omeMeetId, TAXONOMIES, SCOPE);
    setState({ ...state, tryRedirect: true, omeMeet: omeMeet });
  };

  const onDeleteOmeMeetClicked = (e, omeMeet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      meetHost: omeMeet?.meetHost || '',
      meetName: omeMeet?.meetName || '',
      meetDates: `${omeMeet?.startDate ? formatDate(omeMeet?.startDate) : ''} - ${omeMeet?.endDate ? formatDate(omeMeet?.endDate) : ''}`,
      entryDates: `${omeMeet?.entryOpenDate ? formatDateTimeTwelveHourClock(omeMeet?.entryOpenDate) : ''} ${omeMeet?.entryOpenDate ? '-' : ''} ${omeMeet?.entryClosedDate ? formatDateTimeTwelveHourClock(omeMeet?.entryClosedDate) : ''}`,
      timeZone: omeMeet?.timeZone || '',
      omeMeetStatus: omeMeet?.omeMeetStatus || '',
      omeMeetId: omeMeet?.omeMeetId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteOmeMeet = (e, omeMeetId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOmeMeet(omeMeetId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  };

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    searchMeetHost({ hostOrgUnitId: selectOrgUnitState.orgUnitId, isCurrent: true }, formState.sortBy || '');
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  };

  useEffect(() => {
    if (location.state?.selectOrgUnitState) {
      setSelectOrgUnitState({
        ...selectOrgUnitState,
        orgUnitId: location.state?.selectOrgUnitState.orgUnitId,
        orgUnitName: location.state?.selectOrgUnitState.orgUnitName,
        showBackToSelection: location.state?.selectOrgUnitState.showBackToSelection
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoaded === true) {
      if (omeMeetHostFiltersState.filterObject &&
        Object.keys(omeMeetHostFiltersState.filterObject).length > 0) {
        setFormData({
          ...formState,
          meetName: omeMeetHostFiltersState.filterObject?.meetName || '',
          omeMeetStatusId: omeMeetHostFiltersState.filterObject?.omeMeetStatus ? (omeMeetStatusState.find(x => x.name === omeMeetHostFiltersState.filterObject?.omeMeetStatus)?.id || Constants.DEFAULT_ID) : Constants.DEFAULT_ID,
          reportPeriodId: omeMeetHostFiltersState.filterObject?.reportPeriodId || Constants.DEFAULT_ID,
          reportPeriodName: omeMeetHostFiltersState.filterObject?.reportPeriodId ? reportPeriodSeasonState.arrayData.find(x => x.reportPeriodId === omeMeetHostFiltersState.filterObject?.reportPeriodId)?.reportPeriodName || '' : '',
          isCurrent: omeMeetHostFiltersState.filterObject?.isCurrent ?? true,
          sortBy: omeMeetHostFiltersState.sortBy || 'MeetName'
        });
        setState({ ...state, isInititalPageLoadComplete: true });
      }
      else {
        setState({ ...state, isInititalPageLoadComplete: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState]);

  useEffect(() => {
    if (state.isInititalPageLoadComplete === true) {
      if ((omeMeetHostState.isArrayLoaded === false || state.tryGet === true) && selectOrgUnitState?.orgUnitId && omeMeetHostState.isSaving === false &&
        reportPeriodSeasonState.isArrayLoaded === true) {
        filter(formState);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetHostState, state.isInititalPageLoadComplete, state.tryGet, selectOrgUnitState.orgUnitId, reportPeriodSeasonState]);

  useEffect(() => {
    if (state.tryRedirect === true && Object.keys(state.omeMeet).length > 0 && omeMeetHostState.isSaving === false) {
      navigate(MyMeetsNavLinks.OME_LOADING, { state: { omeMeet: state.omeMeet, selectOrgUnitState, omeMeetHostFiltersState: omeMeetHostFiltersState, omeMeetTeamEntryFiltersState: omeMeetTeamEntryFiltersState } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetHostState, state.tryRedirect, state.omeMeet]);

  function submitFormCallback(formState) {
    filter(formState);
  };

  function filter(formState) {
    searchMeetHost({
      hostOrgUnitId: selectOrgUnitState.orgUnitId,
      meetName: formState.meetName.trim().length > 0 ? formState.meetName.trim() : undefined,
      reportPeriodId: formState.reportPeriodId > 0 ? formState.reportPeriodId : undefined,
      omeMeetStatus: formState.omeMeetStatusId > 0 ? (omeMeetStatusState.find(x => x.id === formState.omeMeetStatusId)?.name || undefined) : undefined,
      isCurrent: formState.isCurrent
    }, formState.sortBy || '');
  };

  return {
    modalState,
    onModalCanceled,
    onEnterOmeHostClicked,
    displayLoadingPopUp: omeMeetHostState?.isArrayLoading === true || reportPeriodSeasonState.isArrayLoading || contextSecurityState.isObjLoading === true,
    omeMeetHostState,
    onHostANewMeetButtonClicked,
    onView,
    onEdit,
    onDeleteOmeMeet,
    onDeleteOmeMeetClicked,
    onFilterClicked,
    onClearFilterClicked,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    omeMeetStatusState,
    USAS_ORGANIZATION_ID
  };
};

export default useMeetHost;