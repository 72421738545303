import React from 'react';

import useMeetHostReporting from './UseMeetHostReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const MeetHostReporting = () => {
  const { reportParameters, contextId, routeName } = useMeetHostReporting();

  return (
    <ReportList listTitle="Meet Host Reports" 
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default MeetHostReporting;