import { Fragment } from 'react';

import MeetTimesLargeGrid from './MeetTimesLargeGrid';
import MeetTimesSmallGrid from './MeetTimesSmallGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MeetTimesGrid = ({ gridData, isLoading, onEdit }) => {
  const { onClick, expandedId } = useExpandedRow();

  return (
    <Fragment>
      <MeetTimesLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEdit={onEdit}
        expandedId={expandedId}
        onClick={onClick} />
      <MeetTimesSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEdit={onEdit}
        expandedId={expandedId}
        onClick={onClick}
      />
    </Fragment>
  )
};

export default MeetTimesGrid;