import { useEffect } from 'react';

import validate from './LaneSheetAddRelayTeamsFormValidation';

import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';

import useForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  orgUnits: [],
  existingRelayTeams: []
};

const useLaneSheetAddRelayTeamsForm = (onSubmitFormCallback, existingRelayTeams) => {
  const { orgUnitState, getOrgUnitsTopTwoLevels } = useOrgUnitData();
  const { formState, errorState, isSubmitting, updateFormState, setFormState, handleSubmit
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    setFormState({
      ...INITIAL_FORM_STATE,
      orgUnits: [],
      existingRelayTeams: existingRelayTeams || []
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingRelayTeams]);

  useEffect(() => {
    getOrgUnitsTopTwoLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: isSubmitting,
    orgUnitState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit
  };
};

export default useLaneSheetAddRelayTeamsForm;