import useMeetData from '../../../../common/state/meet/UseMeetData';
import { getTodaysDate, formatDate } from '../../../../common/utils/DateFunctions';

const useMeetUpload = () => {
  const { meetState } = useMeetData();

  function canUploadMeetResults() {
    let canUpload = false;
    const today = Date.parse(formatDate(getTodaysDate()));
    const startDate = Date.parse(formatDate(meetState.objData.startDate));

    canUpload = startDate <= today;

    return canUpload;
  }

  return {
    meetId: meetState.objData?.meetId || -1,
    canUploadResults: canUploadMeetResults(),
    meetStartDate: meetState.objData.startDate,
    meetEndDate: meetState.objData.endDate
  };
};

export default useMeetUpload;