const MyMeetsNavLinks = {
  HOME: '/mymeets',        //redirects to LANDING
  SELECTION: '/mymeets/selection',
  OME_LOADING: '/ome/loading',
  LANDING: '/mymeets/landing',
  MEET_HOST: '/mymeets/host',
  MEET_ENTRY_TEAM: '/mymeets/teamentry',
  MEET_ENTRY_ATHLETE: '/mymeets/athleteentry'
};

export default MyMeetsNavLinks;