import { isValidNumber } from '../../../../../common/utils/validation';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let hasRequiredField = false;
  let errors = {};

  if (formState.eventCourseId === Constants.DEFAULT_ID) {
    if (formState.eventCourseName.trim() !== '') {
      errors.eventCourseId = 'Must be a valid Event Course';
    }
  } else {
    hasRequiredField = true;
  }

  if (formState.competitorId === Constants.DEFAULT_ID) {
    if (formState.competitorName.trim() !== '') {
      errors.competitorId = 'Must be a valid Competitor';
    }
  } else {
    hasRequiredField = true;
  }

  if (formState.startAge.trim() !== '') {
    if (!isValidNumber(formState.startAge.trim())) {
      errors.startAge = 'Must be a number';
    }
    else if (formState.endAge.trim() !== '' && isValidNumber(formState.endAge.trim())) {
      const endAge = ToIntIfInt(formState.endAge);
      const startAge = ToIntIfInt(formState.startAge);

      if (endAge < startAge) {
        errors.startAge = 'Must be less than End Age'
      }
    }
  }

  if (formState.endAge.trim() !== '') {
    if (!isValidNumber(formState.endAge.trim())) {
      errors.endAge = 'Must be a number';
    }
    else if (formState.startAge.trim() !== '' && isValidNumber(formState.startAge.trim())) {
      const endAge = ToIntIfInt(formState.endAge);
      const startAge = ToIntIfInt(formState.startAge);

      if (endAge < startAge) {
        errors.endAge = 'Must be greater than Start Age'
      }
    }
  }

  if (hasRequiredField === false) {
    errors.filter = 'Must filter by an Event Course or Competitor to view times results';
  }

  return errors;
};

const MeetTimesFilterValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MeetTimesFilterValidation;