import React, { Fragment } from 'react';

import AthleteUserPurchasesWrite from './AthleteUserPurchasesWrite';
import AthleteUserPurchasesReadOnly from './AthleteUserPurchasesReadOnly';

import useAthleteUserPurchases from './UseAthleteUserPurchases';

const AthleteUserPurchases = () => {
  const { athleteEntryContextState } = useAthleteUserPurchases();

  return (
    <Fragment>
      {athleteEntryContextState.isReadOnly === false ?
        <AthleteUserPurchasesWrite /> :
        <AthleteUserPurchasesReadOnly />
      }
    </Fragment>
  );
};

export default AthleteUserPurchases;