import React from 'react';

import useOmeFeeTypeDropdown from './UseOmeFeeTypeDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const OmeFeeTypeDropdown = ({ label, name, value, error, message, onChange, additionalPurchasesOnly }) => {
  const { omeFeeTypeState } = useOmeFeeTypeDropdown();

  return omeFeeTypeState.message
    ? <span className={global.LoadingMsg}>{omeFeeTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={additionalPurchasesOnly === true ? omeFeeTypeState.additionalPurchaseOnlyDataAsOptionsWithNames : omeFeeTypeState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={omeFeeTypeState.isArrayLoading}
      />
    );
};

export default OmeFeeTypeDropdown;