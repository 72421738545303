import React, { Fragment } from 'react';

import MeetTeamEntryLargeGrid from './MeetTeamEntryLargeGrid';
import MeetTeamEntrySmallGrid from './MeetTeamEntrySmallGrid';

const MeetTeamEntryGrid = ({ gridData, isLoading, teamEntryCurrentlyAllowed, onEdit, onDeleteTeamEntryClicked, onView }) => {
  return (
    <Fragment>
      <MeetTeamEntryLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        teamEntryCurrentlyAllowed={teamEntryCurrentlyAllowed}
        onEdit={onEdit}
        onDeleteTeamEntryClicked={onDeleteTeamEntryClicked}
        onView={onView}
      />
      <MeetTeamEntrySmallGrid
        gridData={gridData}
        isLoading={isLoading}
        teamEntryCurrentlyAllowed={teamEntryCurrentlyAllowed}
        onEdit={onEdit}
        onDeleteTeamEntryClicked={onDeleteTeamEntryClicked}
        onView={onView}
      />
    </Fragment>
  )
};

export default MeetTeamEntryGrid;