import React, { Fragment } from 'react';

import useLaneSheetRightSideNotes from './UseLaneSheetRightSideNotes';

import LaneSheetMeetRightSideNotesGrid from '../components/grids/LaneSheetMeetRightSideNotesGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LaneSheetRightSideNotes = () => {
  const {
    isSaving,
    isLoading,
    gridData,
    deleteModalState,
    onAddRightSideInfoClicked,
    onDeleteRightSideInfoClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  } = useLaneSheetRightSideNotes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Right Side Notes For Lane Sheet</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddRightSideInfoClicked}>Add New Note</ActionIntraPageButton>
        </div>
      </div>
      <LaneSheetMeetRightSideNotesGrid
        isLoading={isLoading}
        gridData={gridData}
        onDeleteClicked={onDeleteRightSideInfoClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Lane Sheet Right Side Info?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className='col-xs-12'><strong>Record List Name: </strong>{deleteModalState.rightSideInfo?.recordListName || ''}</p>
            <p className='col-xs-12'><strong>Name to Show: </strong>{deleteModalState.rightSideInfo?.nameToShow || ''}</p>
            <p className='col-xs-12'><strong>Times to Show: </strong>{deleteModalState.rightSideInfo?.recordsToShow || 0}</p>
            <p className='col-xs-12'><strong>Display Order: </strong>{deleteModalState.rightSideInfo?.displayOrder || 0}</p>
            <p className='col-xs-12'><strong>Relay Only? </strong>{deleteModalState.rightSideInfo?.isRelayOnly === true ? 'Yes' : 'No'}</p>
            <p className='col-xs-12'>Are you sure you want to delete this Lane Sheet Right Side Information?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LaneSheetRightSideNotes;