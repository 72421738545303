import { useContext } from 'react';

import SelectionsActionsData from './SelectionsActionsData';

import { SelectionsActionsStateContext } from './SelectionsActionsContextProvider';

const useSelectionsActionsData = () => {
  const [selectionsActionsState, setSelectionsActionsState] = useContext(SelectionsActionsStateContext);

  const getSelectionActions = (selectionMeetId) => {
    return SelectionsActionsData.getSelectionActions(selectionMeetId, selectionsActionsState, setSelectionsActionsState);
  };

  return {
    selectionsActionsState,
    getSelectionActions
  };
};

export default useSelectionsActionsData;