import { useContext } from 'react';

import { OmeMeetOrgUnitAthleteEntryRosterSelectionStateContext } from './OmeMeetOrgUnitAthleteEntryRosterSelectionContextProvider';

import OmeMeetOrgUnitAthleteEntryRosterSelectionData from './OmeMeetOrgUnitAthleteEntryRosterSelectionData';

const useOmeMeetOrgUnitAthleteEntryRosterSelectionData = () => {
  const [omeMeetOrgUnitAthleteEntryRosterSelectionState, setOmeMeetOrgUnitAthleteEntryRosterSelectionState] = useContext(OmeMeetOrgUnitAthleteEntryRosterSelectionStateContext);

  const getOrgUnitAthleteEntryRosterSelection = (omeMeetOrgUnitEntryId, filterObj) =>
    OmeMeetOrgUnitAthleteEntryRosterSelectionData.getOrgUnitAthleteEntryRosterSelectionData(omeMeetOrgUnitEntryId, JSON.stringify(filterObj), omeMeetOrgUnitAthleteEntryRosterSelectionState, setOmeMeetOrgUnitAthleteEntryRosterSelectionState);

  const postOrgUnitAthleteEntryRosterSelection = (omeMeetOrgUnitEntryId, orgUnitAthleteEntryArray) =>
    OmeMeetOrgUnitAthleteEntryRosterSelectionData.postOrgUnitAthleteEntryRosterSelectionData(omeMeetOrgUnitEntryId, orgUnitAthleteEntryArray, omeMeetOrgUnitAthleteEntryRosterSelectionState, setOmeMeetOrgUnitAthleteEntryRosterSelectionState);

  const clearOmeMeetOrgUnitAthleteEntryRosterSelectionArrayData = () => {
    setOmeMeetOrgUnitAthleteEntryRosterSelectionState({
      ...omeMeetOrgUnitAthleteEntryRosterSelectionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const clearOmeMeetOrgUnitAthleteEntryRosterSelectionObjData = () => {
    setOmeMeetOrgUnitAthleteEntryRosterSelectionState({
      ...omeMeetOrgUnitAthleteEntryRosterSelectionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    omeMeetOrgUnitAthleteEntryRosterSelectionState,
    setOmeMeetOrgUnitAthleteEntryRosterSelectionState,
    getOrgUnitAthleteEntryRosterSelection,
    postOrgUnitAthleteEntryRosterSelection,
    clearOmeMeetOrgUnitAthleteEntryRosterSelectionObjData,
    clearOmeMeetOrgUnitAthleteEntryRosterSelectionArrayData
  };
};

export default useOmeMeetOrgUnitAthleteEntryRosterSelectionData;