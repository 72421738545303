import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeMeetInfoCoursePriorityValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeMeetInfo from './UseOmeMeetInfo';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';

import useCourseData from '../../../../../common/state/course/UseCourseData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const COURSE_DUPLICATE_ERROR = 'The provided course is already associated with the meet. Duplicates are not allowed.'
const PRIORITY_DUPLICATE_ERROR = 'The provided priority is already associated with an existing course. Duplicates are not allowed.'

const INITIAL_VIEW_STATE = {
  tryGetDateValidation: false,
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  meetInfoFormState: {},
  omeMeetEntryCourseId: Constants.DEFAULT_ID,
  courseId: Constants.DEFAULT_ID,
  courseName: '',
  priority: ''
};

const INITIAL_SECONDARY_REP_DELETE_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
};

const useOmeMeetInfoCoursePriority = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState, getOmeMeetDateValidation } = useOmeMeetDateValidationData();
  const { courseState } = useCourseData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [secondaryRepDeleteModalState, setSecondaryRepDeleteModalState] = useState(INITIAL_SECONDARY_REP_DELETE_MODAL_STATE);
  const { formStateHasBeenSaved } = useOmeMeetInfo();
  const { formState, errorState, onFormValueChanged, handleSubmit, isDirty, isSubmitting,
    setFormData, setErrors, setIsDirty, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryGetDateValidation: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_INFO, { state: { meetInfoFormState: formState.meetInfoFormState } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_INFO, { state: { meetInfoFormState: formState.meetInfoFormState } });
    }
  };

  const tryAddEditCoursePriority = (updatedOmeMeet) => {
    if (validateCoursePriority(updatedOmeMeet) === true) {
      return true;
    }
    else {
      return null;
    }
  };

  const onSecondaryRepDeleteModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    setSecondaryRepDeleteModalState(INITIAL_SECONDARY_REP_DELETE_MODAL_STATE);
  };

  function handleSave() {
    if (secondaryRepDeleteModalState.displayPopUp === true) {
      onSecondaryRepDeleteModalCanceled();
    }
    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    let tryPut = true;

    //Save any unsaved changes made on previous page
    updatedOmeMeet = {
      ...updatedOmeMeet,
      timeZone: formState.meetInfoFormState?.timeZoneValue,
      publishDate: formState.meetInfoFormState?.publishDate,
      meetEntryStartDate: formState.meetInfoFormState?.meetEntryStartDate,
      meetEntryEndDate: formState.meetInfoFormState?.meetEntryEndDate,
      meetEntryChangeDeadline: formState.meetInfoFormState?.meetEntryChangeDeadline,
      athleteAgeAsOfDate: formState.meetInfoFormState?.athleteAgeAsOfDate,
      resultsPublishDate: formState.meetInfoFormState?.resultsPublishDate !== Constants.BLANK_DATE_STRING ? formState.meetInfoFormState?.resultsPublishDate : null,
      meetPacketUrl: formState.meetInfoFormState?.meetPacketUrl.trim() || null,
      allowSecondaryOrgUnitRepresentation: formState.meetInfoFormState?.allowSecondaryOrgUnitRepresentation ?? false
    }

    //handle changing meet qualifying end date automatically for meets with time standards that do not use a qualifying window
    if (updatedOmeMeet.omeMeetQualification.length > 0 && updatedOmeMeet.omeMeetQualification[0]?.hasTimeStandards === true) {
      const hasQualifyingWindow = (updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingStartDate && formatDate(updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingStartDate) !== Constants.DEFAULT_MIN_DATE) ||
        (updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingEndDate && formatDate(updatedOmeMeet.omeMeetQualification[0].timeStandardsQualifyingEndDate) !== formatDate(omeMeetState.objData?.meetEntryEndDate)) ?
        true : false;
      if (hasQualifyingWindow === false) {
        updatedOmeMeet.omeMeetQualification = [{
          ...updatedOmeMeet.omeMeetQualification[0],
          timeStandardsQualifyingStartDate: Constants.DEFAULT_MIN_DATE,
          timeStandardsQualifyingEndDate: formState.meetInfoFormState?.meetEntryEndDate
        }]
      }
    }

    //Edit
    if (formState.omeMeetEntryCourseId > 0) {
      const selectedOmeMeetEntryCourseIndex = updatedOmeMeet.omeMeetEntryCourse?.findIndex(x => x.omeMeetEntryCourseId === formState.omeMeetEntryCourseId);
      if (selectedOmeMeetEntryCourseIndex > -1) {
        if (updatedOmeMeet.omeMeetEntryCourse.length === 1 &&
          updatedOmeMeet.omeMeetEntryCourse[selectedOmeMeetEntryCourseIndex].omeMeetEntryCourseId === formState.omeMeetEntryCourseId) {
          updatedOmeMeet.omeMeetEntryCourse[selectedOmeMeetEntryCourseIndex] = {
            ...updatedOmeMeet.omeMeetEntryCourse[selectedOmeMeetEntryCourseIndex],
            courseId: formState.courseId,
            course: undefined,
            priority: formState.priority
          };
        }
        else if (updatedOmeMeet.omeMeetEntryCourse.length > 1) {
          if (tryAddEditCoursePriority(updatedOmeMeet) === true) {
            updatedOmeMeet.omeMeetEntryCourse[selectedOmeMeetEntryCourseIndex] = {
              ...updatedOmeMeet.omeMeetEntryCourse[selectedOmeMeetEntryCourseIndex],
              courseId: formState.courseId,
              course: undefined,
              priority: formState.priority
            };
          }
          else {
            tryPut = false;
          }
        }
      }
    }
    //Add
    else {
      // If omeMeetEntryCourse object has not been created yet
      if (updatedOmeMeet.omeMeetEntryCourse.length === 0) {
        updatedOmeMeet.omeMeetEntryCourse = [{
          omeMeetId: updatedOmeMeet.omeMeetId,
          courseId: formState.courseId,
          priority: formState.priority
        }];
      }
      // If omeMeetEntryCourse object exists
      else if (updatedOmeMeet.omeMeetEntryCourse.length > 0) {
        //if existing course priorities, check for duplicates
        if (updatedOmeMeet.omeMeetEntryCourse.length > 0) {
          if (tryAddEditCoursePriority(updatedOmeMeet) === true) {
            updatedOmeMeet.omeMeetEntryCourse.push({
              omeMeetId: updatedOmeMeet.omeMeetId,
              courseId: formState.courseId,
              priority: formState.priority
            });
          }
          else {
            tryPut = false;
          }
        }
        //if no existing course priorities, add without checking for duplicates
        else {
          updatedOmeMeet.omeMeetEntryCourse.push({
            omeMeetId: updatedOmeMeet.omeMeetId,
            courseId: formState.courseId,
            priority: formState.priority
          });
        }
      }
    }

    if (tryPut === true) {
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
      setState({ ...state, tryGetDateValidation: true });
    }
  };

  function submitFormCallback() {
    if (formState.meetInfoFormState?.allowSecondaryOrgUnitRepresentation === false && omeMeetState.objData?.allowSecondaryOrgUnitRepresentation === true) {
      setSecondaryRepDeleteModalState({
        ...secondaryRepDeleteModalState,
        displayPopUp: true
      });
    }
    else {
      handleSave();
    }
  };

  function validateCoursePriority(omeMeetCopy) {
    //Check for duplicate courses or priorities
    let duplicateCourse = false;
    let duplicatePriority = false;

    for (let i = 0; i < omeMeetCopy.omeMeetEntryCourse.length; i++) {
      if (omeMeetCopy.omeMeetEntryCourse[i].omeMeetEntryCourseId !== formState.omeMeetEntryCourseId) {
        if (omeMeetCopy.omeMeetEntryCourse[i].courseId === formState.courseId) {
          duplicateCourse = true;
        }
        if (ToIntIfInt(omeMeetCopy.omeMeetEntryCourse[i].priority) === ToIntIfInt(formState.priority)) {
          duplicatePriority = true;
        }
      }
    }

    if (duplicateCourse === true && duplicatePriority === true) {
      setErrors({ ...errorState, error: COURSE_DUPLICATE_ERROR + '\n' + PRIORITY_DUPLICATE_ERROR });
      return false;
    }
    if (duplicateCourse === true && duplicatePriority === false) {
      setErrors({ ...errorState, error: COURSE_DUPLICATE_ERROR });
      return false;
    }
    if (duplicateCourse === false && duplicatePriority === true) {
      setErrors({ ...errorState, error: PRIORITY_DUPLICATE_ERROR });
      return false;
    }
    return true;
  }

  function coursePriorityFormStateHasBeenSaved(formState) {
    //compare course priority formState to omeMeetState to see if formState has been saved
    //Edit course priority
    if (formState?.omeMeetEntryCourseId !== Constants.DEFAULT_ID) {
      const selectedOmeMeetEntryCourse = omeMeetState.objData.omeMeetEntryCourse?.find(x =>
        x.omeMeetEntryCourseId === formState?.omeMeetEntryCourseId);
      if (formState?.courseId !== selectedOmeMeetEntryCourse?.courseId
        && (formState?.courseId !== Constants.DEFAULT_ID || selectedOmeMeetEntryCourse?.courseId !== null)) {
        return false;
      }
      if (formState?.priority && (ToIntIfInt(formState?.priority) !== selectedOmeMeetEntryCourse?.priority)
        && (formState?.priority !== '' || selectedOmeMeetEntryCourse?.priority !== null)) {
        return false;
      }
    }
    //Add course priority
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && location.state && location.state?.meetInfoFormState &&
      courseState.isLoaded === true) {
      //Edit
      if (omeMeetState.objData.omeMeetEntryCourse.length > 0 && location.state?.omeMeetEntryCourseId) {
        const selectedOmeMeetEntryCourse = omeMeetState.objData.omeMeetEntryCourse?.find(x =>
          x.omeMeetEntryCourseId === location?.state?.omeMeetEntryCourseId);
        if (selectedOmeMeetEntryCourse && Object.keys(selectedOmeMeetEntryCourse).length > 0) {
          setFormData({
            ...formState,
            meetInfoFormState: location.state?.meetInfoFormState || {},
            omeMeetEntryCourseId: location.state?.omeMeetEntryCourseId || Constants.DEFAULT_ID,
            courseId: selectedOmeMeetEntryCourse.courseId || Constants.DEFAULT_ID,
            courseName: selectedOmeMeetEntryCourse.course?.courseCode || '',
            priority: selectedOmeMeetEntryCourse.priority >= 0 ? selectedOmeMeetEntryCourse.priority : ''
          });
          if (formStateHasBeenSaved(location.state?.meetInfoFormState) === false) {
            setIsDirty(true);
          }
        }
      }
      //Add 
      else {
        setFormData({
          ...formState,
          meetInfoFormState: location.state?.meetInfoFormState || {}
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, courseState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.meetInfoFormState);
      const isCoursePrioritySaved = coursePriorityFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isCoursePrioritySaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryGetDateValidation === true) {
      getOmeMeetDateValidation(omeMeetState.objData.omeMeetId);
      setState({ ...state, tryGetDateValidation: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state])

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_INFO);
      }
      else {
        navigate(HostNavLinks.OME_MEET_INFO);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    state,
    omeMeetState,
    omeMeetDateValidationState,
    courseState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    secondaryRepDeleteModalState,
    onSecondaryRepDeleteModalCanceled,
    handleSave
  };
};

export default useOmeMeetInfoCoursePriority;