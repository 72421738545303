import { useContext } from 'react';

import { RecordGroupsStateContext } from './RecordGroupsContextProvider';

import RecordGroupsData from './RecordGroupsData';

const useRecordGroupsData = () => {
  const [recordGroupState, setRecordGroupState] = useContext(RecordGroupsStateContext);

  const getRecordGroups = () => {
    return RecordGroupsData.getRecordGroups(recordGroupState, setRecordGroupState);
  };

  return {
    recordGroupState,
    setRecordGroupState,
    getRecordGroups
  };
};

export default useRecordGroupsData;