import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getEventMeetEventBonusData from './GetEventMeetEventBonusData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  meetEventId: Constants.DEFAULT_ID,
  items: []
};

const EventMeetEventBonusData = {
  getEventMeetEventBonusData,
  INITIAL_STATE
};

export default EventMeetEventBonusData;