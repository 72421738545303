import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useAthleteMeetEventData from '../../../../state/athleteMeetEvent/UseAthleteMeetEventData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useAthleteEntryContextData from '../../../../state/athleteEntryContextView/UseAthleteEntryContextData';
import useOmeMeetOrgUnitEntryTimesData from '../../../../state/omeMeetOrgUnitEntryTimes/UseOmeMeetOrgUnitEntryTimesData';
import useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../../common/utils/Constants';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Individual Event Entry?',
  athlete: '',
  eventName: '',
  eventCompetitionGenderTypeName: '',
  ageGroupName: '',
  entryTime: '',
  timeEvent: '',
  qualifyingCourseCode: '',
  qualifiedAsBonus: '',
  override: '',
  status: '',
  individualAthleteMeetEventId: Constants.DEFAULT_ID
};

const useAthleteUserEntries = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId } =
    useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { athleteMeetEventState, deleteIndividualAthleteMeetEvent } = useAthleteMeetEventData();
  const { omeMeetOrgUnitEntryTimesState, putOmeMeetOrgUnitEntryTimes, confirmSaveOmeMeetOrgUnitEntryTimes } = useOmeMeetOrgUnitEntryTimesData();
  const { omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoaches } = useOmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryData();
  const { ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID } = useEnvironmentVariableData();
  const [state, setState] = useState({ tryGetAfterUpdateOrDelete: false, tryUpdateGridData: false, bonusEvents: '' });
  const [gridState, setGridState] = useState({ gridData: [], showUpdateButton: false });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [errorState, setErrorState] = useState({ general: '' });
  const { athleteEntryContextState, setAthleteEntryContextState } = useAthleteEntryContextData();

  const onAddEventClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_ENTRIES_INDIVIDUAL_SELECTION);
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_ENTRIES_INDIVIDUAL_SELECTION);
    }
  }

  const onAddBonusEventClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION);
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_ENTRIES_BONUS_INDIVIDUAL_SELECTION);
    }
  }

  const onDeleteEventClicked = (e, individualAthleteMeetEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let bonusRuleViolated = false;
    let bonusRuleViolatedErrorMsg = '';
    //if bonus events are allowed and the selected event is not a bonus event, check to see if the delete would violate any bonus rules
    if (state.bonusEvents === true && individualAthleteMeetEvent.qualifiedAsBonus === false) {
      const eventsAfterTheDelete = JSON.parse(JSON.stringify(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.events));
      const selectedIndex = eventsAfterTheDelete.findIndex(x => x.individualAthleteMeetEventId === individualAthleteMeetEvent.individualAthleteMeetEventId);
      if (selectedIndex > -1) {
        eventsAfterTheDelete.splice(selectedIndex, 1);
      }

      //figure out the number of individual and bonus entries after the potential delete
      let updatedAthleteIndividualEventCount = 0;
      let updatedAthleteBonusIndividualEventCount = 0;
      if (eventsAfterTheDelete) {
        for (let i = 0; i < eventsAfterTheDelete.length; i++) {
          if (eventsAfterTheDelete[i].qualifiedAsBonus === false) {
            updatedAthleteIndividualEventCount += 1;
          }
          else if (eventsAfterTheDelete[i].qualifiedAsBonus === true) {
            updatedAthleteBonusIndividualEventCount += 1;
          }
        }
      }

      //check to see if any bonus rules are violated by the potential delete
      let differenceBetweenBonusEntriesAndAllowedBonusEntries = 0;
      for (let i = 0; i < omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus.length; i++) {
        //find the bonus rule that would applies to the number of individual (non-bonus) event entries after the delete
        if (updatedAthleteIndividualEventCount <= omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].maxQualifyingEvents &&
          updatedAthleteIndividualEventCount >= omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].minQualifyingEvents) {
          if (updatedAthleteBonusIndividualEventCount > omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount) {
            bonusRuleViolated = true;
            differenceBetweenBonusEntriesAndAllowedBonusEntries = updatedAthleteBonusIndividualEventCount - omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount;
            bonusRuleViolatedErrorMsg = `Deleting this individual event entry would leave this athlete with ${updatedAthleteIndividualEventCount} individual event ${updatedAthleteIndividualEventCount === 1 ? 'entry' : 'entries'}. Based on the bonus event rules of the meet, an athlete with ${updatedAthleteIndividualEventCount} individual event ${updatedAthleteIndividualEventCount === 1 ? 'entry' : 'entries'} is allowed ${omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount} individual bonus ${omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount === 1 ? 'event entry' : 'event entries'}. This athlete has ${updatedAthleteBonusIndividualEventCount} individual bonus event ${updatedAthleteBonusIndividualEventCount === 1 ? 'entry' : 'entries'}, which would not be allowed.\n\nIn order to be able to delete this individual event entry, please delete ${differenceBetweenBonusEntriesAndAllowedBonusEntries} individual bonus event ${differenceBetweenBonusEntriesAndAllowedBonusEntries === 1 ? 'entry' : 'entries'}.`
          }
        }
      }
    }

    //if there is an error, don't show the delete pop-up
    if (bonusRuleViolated === true) {
      setErrorState({ ...errorState, general: bonusRuleViolatedErrorMsg });
    }
    else {
      setErrorState({ ...errorState, general: '' });
      setModalState({
        ...modalState,
        displayPopUp: true,
        athlete: `${(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.firstName || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.middleName || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.lastName) ? `${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.firstName || ''} ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.preferredName !== '' && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.preferredName !== omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.firstName ? '"' + omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.preferredName + '"' : ''} ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.middleName || ''} ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.lastName || ''}` : ''} (${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.competitionGenderTypeName || ''}, ${omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.ageAtMeet >= 0 ? omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.ageAtMeet : ''})`,
        eventName: individualAthleteMeetEvent.eventName || '',
        eventCompetitionGenderTypeName: individualAthleteMeetEvent.eventCompetitionGenderTypeName || '',
        ageGroupName: individualAthleteMeetEvent.ageGroupName || '',
        entryTime: individualAthleteMeetEvent.entryTime ? formatTimeForDisplay(individualAthleteMeetEvent.entryTime) : 'NT',
        timeEvent: `${individualAthleteMeetEvent.isAlternateEvent === true ? `ALT: ${individualAthleteMeetEvent.qualifyingEventName || ''}` : individualAthleteMeetEvent.isNonConformingTime === true ? `NC: ${individualAthleteMeetEvent.qualifyingEventName || ''}` : `${individualAthleteMeetEvent.qualifyingEventName || ''}`}`,
        qualifyingCourseCode: individualAthleteMeetEvent.qualifyingCourseCode || '',
        qualifiedAsBonus: individualAthleteMeetEvent.qualifiedAsBonus === true ? 'Yes' : individualAthleteMeetEvent.qualifiedAsBonus === false ? 'No' : '',
        override: individualAthleteMeetEvent.swimTimeEntryOverride === true ? 'Yes' : individualAthleteMeetEvent.swimTimeEntryOverride === false ? 'No' : '',
        status: individualAthleteMeetEvent.status || '',
        individualAthleteMeetEventId: individualAthleteMeetEvent.individualAthleteMeetEventId || Constants.DEFAULT_ID
      });
    }
  };

  const onDeleteEvent = (e, individualAthleteMeetEventId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteIndividualAthleteMeetEvent(individualAthleteMeetEventId);
    onModalCanceled();
    setState({ ...state, tryGetAfterUpdateOrDelete: true });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onUpdateAthleteTimes = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    putOmeMeetOrgUnitEntryTimes(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId);
    setState({ ...state, tryGetAfterUpdateOrDelete: true });
  };

  useEffect(() => {
    setAthleteEntryContextState({ ...athleteEntryContextState, showNav: true });
    if (location.state && location.state.tryGet === true) {
      getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.orgUnitAthleteEntryId, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.omeMeetOrgUnitEntryId);
      getOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoaches(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isSaved === true) {
      confirmSaveOmeMeetOrgUnitEntryTimes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isSaved])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded === true &&
      Object.keys(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData).length > 0) {
      setState({ ...state, bonusEvents: omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? '' });

      let showUpdateButton = false;
      if (omeMeetState.objData?.omeMeetPrice?.length > 0) {
        const hasCostPerAthleteIndividualEventEntry = omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerAthleteIndividualBonusEventEntry = omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        if (hasCostPerAthleteIndividualEventEntry === true && hasCostPerAthleteIndividualBonusEventEntry === true) {
          //if Individual Event Cost equals Bonus Event Cost, then show the update times button
          if (omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount ===
            omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount) {
            showUpdateButton = true;
          }
        }
        //if we don't have both Individual and Bonus Event prices for the meet, then show the update times button
        else {
          showUpdateButton = true;
        }
      }
      //if we don't have pricing for the meet, then show the update times button
      else {
        showUpdateButton = true;
      }

      setGridState({ ...gridState, gridData: omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.events || [], showUpdateButton: showUpdateButton });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.omeMeetOrgUnitEntryId &&
      state.tryGetAfterUpdateOrDelete === true &&
      athleteMeetEventState.isSaving === false && omeMeetOrgUnitEntryTimesState.isSaving === false) {
      getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.omeMeetOrgUnitEntryId);
      setState({ ...state, tryGetAfterUpdateOrDelete: false, tryUpdateGridData: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.omeMeetOrgUnitEntryId, state.tryGetAfterUpdateOrDelete,
  athleteMeetEventState.isSaving, omeMeetOrgUnitEntryTimesState.isSaving])

  useEffect(() => {
    if (state.tryUpdateGridData === true && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoading === false) {
      const updatedAthlete = omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData;
      setState({ ...state, tryUpdateGridData: false });

      let showUpdateButton = false;
      if (omeMeetState.objData?.omeMeetPrice?.length > 0) {
        const hasCostPerAthleteIndividualEventEntry = omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerAthleteIndividualBonusEventEntry = omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        if (hasCostPerAthleteIndividualEventEntry === true && hasCostPerAthleteIndividualBonusEventEntry === true) {
          //if Individual Event Cost equals Bonus Event Cost, then show the update times button
          if (omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount ===
            omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount) {
            showUpdateButton = true;
          }
        }
        //if we don't have both Individual and Bonus Event prices for the meet, then show the update times button
        else {
          showUpdateButton = true;
        }
      }
      //if we don't have pricing for the meet, then show the update times button
      else {
        showUpdateButton = true;
      }

      setGridState({ ...gridState, gridData: updatedAthlete.events || [], showUpdateButton: showUpdateButton });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryUpdateGridData, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState])

  return {
    athleteEntryContextState,
    omeMeetOrgUnitEntryTimesState,
    state,
    gridState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState,
    athleteMeetEventState,
    onAddEventClicked,
    onAddBonusEventClicked,
    onDeleteEventClicked,
    onDeleteEvent,
    onModalCanceled,
    modalState,
    errorState,
    onUpdateAthleteTimes
  };
}

export default useAthleteUserEntries;