import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.eventName.trim() !== '') {
    if (formState.eventId === Constants.DEFAULT_ID || formState.eventId === '') {
      errors.eventId = 'Must be a valid Event';
    }
  }

  return errors;
};

const OrgUnitAdminRosterEntriesSelectionValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRosterEntriesSelectionValidation;