import { useContext } from 'react';

import SelectionsMeetEventsData from './SelectionsMeetEventsData';

import { SelectionsMeetEventsStateContext } from './SelectionsMeetEventsContextProvider';

const useSelectionsMeetEventsData = () => {
  const [selectionsMeetEventsState, setSelectionsMeetEventsState] = useContext(SelectionsMeetEventsStateContext);

  const getSelectionMeetEvents = (selectionMeetId) => {
    return SelectionsMeetEventsData.getSelectionMeetEventsData(selectionMeetId, selectionsMeetEventsState, setSelectionsMeetEventsState);
  };

  const putSelectionMeetEvents = (selectionMeetId, meetEventsArray) => {
    return SelectionsMeetEventsData.putSelectionMeetEventsData(selectionMeetId, meetEventsArray, selectionsMeetEventsState, setSelectionsMeetEventsState);
  };

  return {
    selectionsMeetEventsState,
    getSelectionMeetEvents,
    putSelectionMeetEvents
  };
};

export default useSelectionsMeetEventsData;