import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryContactData from '../../../../state/omeMeetOrgUnitEntryContact/UseOmeMeetOrgUnitEntryContactData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';

import Constants from '../../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Entry Contact?',
  contactName: '',
  emailAddress: '',
  omeMeetOrgUnitEntryContactId: Constants.DEFAULT_ID
};

const useOrgUnitAdminContacts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitEntryContactState, getOmeMeetOrgUnitEntryContacts, deleteOmeMeetOrgUnitEntryContact } = useOmeMeetOrgUnitEntryContactData();
  const { oUAdminEntryContextState } = useOUAdminEntryContextData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [state, setState] = useState({ tryGet: false });

  const onAddContact = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_CONTACTS_DETAIL);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_CONTACTS_DETAIL);
    }
  };

  const onEditContact = (e, contact) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_CONTACTS_DETAIL, {
        state: {
          omeMeetOrgUnitEntryContactId: contact?.omeMeetOrgUnitEntryContactId || '',
          memberId: contact?.memberId || '',
          personId: contact?.personId || '',
          emailAddress: contact?.emailAddress || ''
        }
      });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_CONTACTS_DETAIL, {
        state: {
          omeMeetOrgUnitEntryContactId: contact?.omeMeetOrgUnitEntryContactId || '',
          memberId: contact?.memberId || '',
          personId: contact?.personId || '',
          emailAddress: contact?.emailAddress || ''
        }
      });
    }

  };

  const onDeleteContactClicked = (e, contact) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      contactName: (contact.firstName || contact.middleName || contact.lastName) ? `${contact.firstName || ''} ${contact.preferredName !== '' && contact.preferredName !== contact.firstName ? '"' + contact.preferredName + '"' : ''} ${contact.middleName || ''} ${contact.lastName || ''}` : '',
      emailAddress: contact.emailAddress || '',
      omeMeetOrgUnitEntryContactId: contact.omeMeetOrgUnitEntryContactId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteContact = (e, omeMeetOrgUnitEntryContactId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOmeMeetOrgUnitEntryContact(omeMeetOrgUnitEntryContactId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId
      && omeMeetOrgUnitEntryContactState.isArrayLoading !== true && omeMeetOrgUnitEntryContactState.isSaving !== true
      && (omeMeetOrgUnitEntryContactState.isArrayLoaded !== true || state.tryGet === true)) {
      getOmeMeetOrgUnitEntryContacts(omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId);
      if (state.tryGet === true) {
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitEntryContactState, state.tryGet])

  return {
    oUAdminEntryContextState,
    omeMeetOrgUnitEntryContactState,
    isLoading: omeMeetOrgUnitEntryContactState.isArrayLoading,
    Constants,
    modalState,
    onModalCanceled,
    onAddContact,
    onEditContact,
    onDeleteContactClicked,
    onDeleteContact
  };
}

export default useOrgUnitAdminContacts;