import { Fragment } from 'react';

import RelayAthleteMeetEventCombobox from '../../../../components/comboboxes/relayAthleteMeetEventCombobox/RelayAthleteMeetEventCombobox';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import Constants from '../../../../../common/utils/Constants';

const GridRow = ({ legNumber, isEdit, athlete, name, value, meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, event }) => {
  return (
    <Fragment key={value + 'Fragment'}>
      <div className={global.SmallTableRow} key={value}>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            {isEdit === true && <span className='hidden-xs'>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `Leg ${athlete.legNumber} - ${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</span>}&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              {isEdit === true ?
                <Fragment>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>{`Leg ${legNumber} Name`}</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</div>
                </Fragment> :
                <Fragment>
                  <div className={[global.SmallTableRowLabels, 'col-xs-12 col-sm-6'].join(' ')}>{`Leg ${legNumber} Name`}</div>
                  <div className={[global.SmallTableRowData, 'col-xs-12 col-sm-6'].join(' ')}>
                    <RelayAthleteMeetEventCombobox
                      label={""}
                      name={name}
                      valueToMatch={formState[name]}
                      error={errorState[value]}
                      message={errorState[value]}
                      onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, value, newValueLabel, name, e); }}
                      meetEventId={meetEventId}
                      omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
                    />
                  </div>
                </Fragment>}
              <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age at Meet</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : <span>&nbsp;</span>}</div>
              {isEdit === true ?
                <Fragment>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Time</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entryTime ? formatTimeForDisplay(athlete.entryTime) : 'NT'} {athlete.entryTime && formState.courseCode ? formState.courseCode : ''}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Override?</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.swimTimeEntryOverride === true ? 'Yes' : 'No'}</div>
                  <span className={athlete.swimTimeEntryOverride === true ? global.DisplayComponent : global.HideComponent}>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Swim Date</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entrySwimDate ? formatDate(athlete.entrySwimDate) : <span>&nbsp;</span>}</div>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Meet Name</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entryTimeMeetName || <span>&nbsp;</span>}</div>
                  </span>
                </Fragment> :
                <Fragment>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Entry Time</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.entryTime ? formatTimeForDisplay(athlete.entryTime) : formState[value] !== Constants.DEFAULT_ID ? 'NT' : ''} {athlete.entryTime && formState.courseCode ? formState.courseCode : ''}</div>
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const OrgUnitAdminRelayEntrySelectionSmallGrid = ({ isEdit, gridData, isLoading, meetEventId, omeMeetOrgUnitEntryId, formState, errorState, onValueTextPairChanged, event }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) :
      <Fragment>
        <GridRow
          key={1}
          legNumber={isEdit === true ? gridData.athletes[0].legNumber : "1"}
          isEdit={isEdit}
          athlete={gridData.athletes[0]}
          name="athlete1Name"
          value="athlete1PersonId"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          event={event}
        />
        <GridRow
          key={2}
          legNumber={isEdit === true ? gridData.athletes[1].legNumber : "2"}
          isEdit={isEdit}
          athlete={gridData.athletes[1]}
          name="athlete2Name"
          value="athlete2PersonId"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          event={event}
        />
        <GridRow
          key={3}
          legNumber={isEdit === true ? gridData.athletes[2].legNumber : "3"}
          isEdit={isEdit}
          athlete={gridData.athletes[2]}
          name="athlete3Name"
          value="athlete3PersonId"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          event={event}
        />
        <GridRow
          key={4}
          legNumber={isEdit === true ? gridData.athletes[3].legNumber : "4"}
          isEdit={isEdit}
          athlete={gridData.athletes[3]}
          name="athlete4Name"
          value="athlete4PersonId"
          meetEventId={meetEventId}
          omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
          formState={formState}
          errorState={errorState}
          onValueTextPairChanged={onValueTextPairChanged}
          event={event}
        />
      </Fragment>
    }
  </div >
);

export default OrgUnitAdminRelayEntrySelectionSmallGrid;