import React, { Fragment } from 'react';

import useOmeEligibility from './UseOmeEligibility';

import OmeEligibilityBonusEventsGrid from './OmeEligibilityBonusEventsGrid';
import OmeEligibilityCoachPassesGrid from './OmeEligibilityCoachPassesGrid';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import Input from '../../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ToolTip from '../../../../../common/components/tooltips/ToolTip';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

const OmeEligibility = () => {
  const {
    state,
    modalState,
    omeMeetState,
    errorState,
    formState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddCoachPassRuleClicked,
    onEditCoachPassRuleClicked,
    onDeleteCoachPassRuleClicked,
    onDeleteCoachPassRule,
    onAddBonusEventRuleClicked,
    onEditBonusEventRuleClicked,
    onDeleteBonusEventRuleClicked,
    onDeleteBonusEventRule,
    onModalCanceled,
    warningModalState,
    onWarningModalCanceled,
    onSaveAndDelete,
    allowEdits,
    MAX_RELAY_TEAM_ENTRIES_PER_EVENT
  } = useOmeEligibility();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_ELIGIBILITY}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={errorState.error}>
        {allowEdits() === true ?
          <form onSubmit={handleSubmit}>
            <div className='row usas-extra-bottom-margin'>
              <div className='col-xs-12 col-sm-6'>
                <Input
                  label={<Fragment>Max Number of Athletes For Meet<span>&nbsp;<ToolTip label={`Limit on the number of athletes in individual events (including any bonus events) that can enter the meet.`}></ToolTip></span></Fragment>}
                  name='maxNumberOfAthletesForMeet'
                  value={formState.maxNumberOfAthletesForMeet}
                  error={errorState.maxNumberOfAthletesForMeet}
                  message={errorState.maxNumberOfAthletesForMeet}
                  onChange={(value) => { onFormValueChanged('maxNumberOfAthletesForMeet', value); }} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <Input
                  label={<Fragment>Max Number of Cap Exempt Athletes For Meet<span>&nbsp;<ToolTip label={`Limit on the number of cap exempt athletes in individual events (including any bonus events) that can enter the meet.`}></ToolTip></span></Fragment>}
                  name='maxNumberOfCapExemptAthletesForMeet'
                  value={formState.maxNumberOfCapExemptAthletesForMeet}
                  error={errorState.maxNumberOfCapExemptAthletesForMeet}
                  message={errorState.maxNumberOfCapExemptAthletesForMeet}
                  onChange={(value) => { onFormValueChanged('maxNumberOfCapExemptAthletesForMeet', value); }} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <Input
                  label={<Fragment>Max Events Per Individual Per Meet<span>&nbsp;<ToolTip label="Limit on the number of individual events (including any bonus events) an athlete can enter for the entire meet."></ToolTip></span></Fragment>}
                  name='maxEventsPerIndividualPerMeet'
                  type='number'
                  min='1'
                  value={formState.maxEventsPerIndividualPerMeet}
                  error={errorState.maxEventsPerIndividualPerMeet}
                  message={errorState.maxEventsPerIndividualPerMeet}
                  onChange={(value) => { onFormValueChanged('maxEventsPerIndividualPerMeet', value); }} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <Input
                  label={<Fragment>Max Events Per Individual Per Session<span>&nbsp;<ToolTip label="Limit on the number of individual events (including any bonus events) an athlete can enter per session."></ToolTip></span></Fragment>}
                  name='maxEventsPerIndividualPerSession'
                  type='number'
                  min='1'
                  value={formState.maxEventsPerIndividualPerSession}
                  error={errorState.maxEventsPerIndividualPerSession}
                  message={errorState.maxEventsPerIndividualPerSession}
                  onChange={(value) => { onFormValueChanged('maxEventsPerIndividualPerSession', value); }} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <YesNoSwitch
                  label='Meet uses Altitude Adjusted Times to Qualify for Events?*'
                  name='useAdjustedSwimTime'
                  checked={formState.useAdjustedSwimTime}
                  error={errorState.useAdjustedSwimTime}
                  message={errorState.useAdjustedSwimTime}
                  onChange={(value) => { onFormValueChanged('useAdjustedSwimTime', value); }} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <YesNoSwitch
                  label='Meet has Relay Events?*'
                  name='relayEvents'
                  checked={formState.relayEvents}
                  error={errorState.relayEvents}
                  message={errorState.relayEvents}
                  onChange={(value) => { onFormValueChanged('relayEvents', value); }} />
              </div>
              {formState.relayEvents === true &&
                <Fragment>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Relay Entry Times must be from Swimmers on Roster?*'
                      name='relayTeamFromRosterOnly'
                      checked={formState.relayTeamFromRosterOnly}
                      error={errorState.relayTeamFromRosterOnly}
                      message={errorState.relayTeamFromRosterOnly}
                      onChange={(value) => { onFormValueChanged('relayTeamFromRosterOnly', value); }}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Force Aggregate Relay if Faster Than Team Time?*'
                      name='forceAggregateIfFasterThanTeamTime'
                      checked={formState.forceAggregateIfFasterThanTeamTime}
                      error={errorState.forceAggregateIfFasterThanTeamTime}
                      message={errorState.forceAggregateIfFasterThanTeamTime}
                      onChange={(value) => { onFormValueChanged('forceAggregateIfFasterThanTeamTime', value); }}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Meet allows Relay Only Swimmers?*'
                      name='allowRelayOnlySwimmer'
                      checked={formState.allowRelayOnlySwimmer}
                      error={errorState.allowRelayOnlySwimmer}
                      message={errorState.allowRelayOnlySwimmer}
                      onChange={(value) => { onFormValueChanged('allowRelayOnlySwimmer', value); }}
                    />
                  </div>
                  {formState.allowRelayOnlySwimmer === true &&
                    <Fragment>
                      <div className='col-xs-12 col-sm-6'>
                        <YesNoSwitch
                          label='Include Relay Only Swimmers in Max Number of Athletes?'
                          name='includeRelayOnlySwimmersInMaxNumber'
                          checked={formState.includeRelayOnlySwimmersInMaxNumber}
                          error={errorState.includeRelayOnlySwimmersInMaxNumber}
                          message={errorState.includeRelayOnlySwimmersInMaxNumber}
                          onChange={(value) => { onFormValueChanged('includeRelayOnlySwimmersInMaxNumber', value); }} />
                      </div>
                      <div className='col-xs-12 col-sm-6'>
                        <YesNoSwitch
                          label='Include Relay Only Swimmers in Count for Coach Passes?*'
                          name='includeRelaySwimmerForCoachPasses'
                          checked={formState.includeRelaySwimmerForCoachPasses}
                          error={errorState.includeRelaySwimmerForCoachPasses}
                          message={errorState.includeRelaySwimmerForCoachPasses}
                          onChange={(value) => { onFormValueChanged('includeRelaySwimmerForCoachPasses', value); }} />
                      </div>
                    </Fragment>
                  }
                  <div className='col-xs-12 col-sm-6'>
                    <Input
                      label={<Fragment>Max Relay Entries Per Team Per Relay Event*<span>&nbsp;<ToolTip label="Limit on the number of relay entries allowed per team for each relay event."></ToolTip></span></Fragment>}
                      name='maxRelayTeamEntriesPerEvent'
                      type='number'
                      min='1'
                      max={MAX_RELAY_TEAM_ENTRIES_PER_EVENT}
                      value={formState.maxRelayTeamEntriesPerEvent}
                      error={errorState.maxRelayTeamEntriesPerEvent}
                      message={errorState.maxRelayTeamEntriesPerEvent}
                      onChange={(value) => { onFormValueChanged('maxRelayTeamEntriesPerEvent', value); }} />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <Input
                      label={<Fragment>Max Relay Events Per Individual Per Meet<span>&nbsp;<ToolTip label="Limit on the number of relay events an athlete can enter for the entire meet."></ToolTip></span></Fragment>}
                      name='maxRelayEventsPerIndividualPerMeet'
                      type='number'
                      min='1'
                      value={formState.maxRelayEventsPerIndividualPerMeet}
                      error={errorState.maxRelayEventsPerIndividualPerMeet}
                      message={errorState.maxRelayEventsPerIndividualPerMeet}
                      onChange={(value) => { onFormValueChanged('maxRelayEventsPerIndividualPerMeet', value); }} />
                  </div>
                </Fragment>}
              <div className='col-xs-12 col-sm-6'>
                <YesNoSwitch
                  label='Meet allows Bonus Events?*'
                  name='bonusEvents'
                  checked={formState.bonusEvents}
                  error={errorState.bonusEvents}
                  message={errorState.bonusEvents}
                  onChange={(value) => { onFormValueChanged('bonusEvents', value); }} />
              </div>
              <div className='col-xs-12'></div>
              <div className='col-xs-12 col-md-3 usas-extra-top-margin'>
                <ActionIntraPageButton type="button" onClick={(e) => onAddCoachPassRuleClicked(e)}>Add Coach Pass Rule</ActionIntraPageButton>
              </div>
              <div className='col-xs-12 col-md-9'>
                <p>**If a Coach Pass Rule is not provided, a Coach Pass Rule allowing 0 coach passes when there are 0 athletes will automatically be created upon saving the page.
                </p>
              </div>
              <div className='col-xs-12'>
                <OmeEligibilityCoachPassesGrid omeMeetState={omeMeetState} gridState={state} onEdit={onEditCoachPassRuleClicked} onDelete={onDeleteCoachPassRuleClicked} />
              </div>
              {formState.bonusEvents === true &&
                <Fragment>
                  <div className='col-xs-12 col-md-3 usas-extra-top-margin'>
                    <ActionIntraPageButton type="button" onClick={(e) => onAddBonusEventRuleClicked(e)}>Add Bonus Event Rule</ActionIntraPageButton>
                  </div>
                  <div className='col-xs-12 col-md-9'>
                    <p style={{ marginLeft: '5px' }}>**If a Bonus Event Rule is not provided, a Bonus Event Rule allowing 0 bonus events when an athlete has 0 qualifying events will automatically be created upon saving the page.
                    </p>
                  </div>
                  <div className='col-xs-12'>
                    <OmeEligibilityBonusEventsGrid omeMeetState={omeMeetState} gridState={state} onEdit={onEditBonusEventRuleClicked} onDelete={onDeleteBonusEventRuleClicked} />
                  </div>
                </Fragment>
              }
            </div>
          </form> :
          <form onSubmit={handleSubmit}>
            <div className='row usas-extra-bottom-margin'>
              <div className='col-xs-12 col-sm-6'>
                <ReadOnly
                  label={<Fragment>Max Number of Athletes For Meet<span>&nbsp;<ToolTip label={`Limit on the number of athletes in individual events (including any bonus events) that can enter the meet.`}></ToolTip></span></Fragment>}
                  name='maxNumberOfAthletesForMeet'
                  value={formState.maxNumberOfAthletesForMeet}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <ReadOnly
                  label={<Fragment>Max Number of Cap Exempt Athletes For Meet<span>&nbsp;<ToolTip label={`Limit on the number of cap exempt athletes in individual events (including any bonus events) that can enter the meet.`}></ToolTip></span></Fragment>}
                  name='maxNumberOfCapExemptAthletesForMeet'
                  value={formState.maxNumberOfCapExemptAthletesForMeet}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <ReadOnly
                  label={<Fragment>Max Events Per Individual Per Meet<span>&nbsp;<ToolTip label="Limit on the number of individual events (including any bonus events) an athlete can enter for the entire meet."></ToolTip></span></Fragment>}
                  name='maxEventsPerIndividualPerMeet'
                  value={formState.maxEventsPerIndividualPerMeet}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <ReadOnly
                  label={<Fragment>Max Events Per Individual Per Session<span>&nbsp;<ToolTip label="Limit on the number of individual events (including any bonus events) an athlete can enter per session."></ToolTip></span></Fragment>}
                  name='maxEventsPerIndividualPerSession'
                  value={formState.maxEventsPerIndividualPerSession}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <YesNoSwitch
                  label='Meet uses Altitude Adjusted Times to Qualify for Events?*'
                  name='useAdjustedSwimTime'
                  checked={formState.useAdjustedSwimTime}
                  error={errorState.useAdjustedSwimTime}
                  message={errorState.useAdjustedSwimTime}
                  onChange={(value) => { onFormValueChanged('useAdjustedSwimTime', value); }}
                  disabled={true} />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <YesNoSwitch
                  label='Meet has Relay Events?*'
                  name='relayEvents'
                  checked={formState.relayEvents}
                  error={errorState.relayEvents}
                  message={errorState.relayEvents}
                  onChange={(value) => { onFormValueChanged('relayEvents', value); }}
                  disabled={true} />
              </div>
              {formState.relayEvents === true &&
                <Fragment>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Relay Entry Times must be from Swimmers on Roster?*'
                      name='relayTeamFromRosterOnly'
                      checked={formState.relayTeamFromRosterOnly}
                      error={errorState.relayTeamFromRosterOnly}
                      message={errorState.relayTeamFromRosterOnly}
                      onChange={(value) => { onFormValueChanged('relayTeamFromRosterOnly', value); }}
                      disabled={true}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Force Aggregate Relay if Faster Than Team Time?*'
                      name='forceAggregateIfFasterThanTeamTime'
                      checked={formState.forceAggregateIfFasterThanTeamTime}
                      error={errorState.forceAggregateIfFasterThanTeamTime}
                      message={errorState.forceAggregateIfFasterThanTeamTime}
                      onChange={(value) => { onFormValueChanged('forceAggregateIfFasterThanTeamTime', value); }}
                      disabled={true}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <YesNoSwitch
                      label='Meet allows Relay Only Swimmers?*'
                      name='allowRelayOnlySwimmer'
                      checked={formState.allowRelayOnlySwimmer}
                      error={errorState.allowRelayOnlySwimmer}
                      message={errorState.allowRelayOnlySwimmer}
                      onChange={(value) => { onFormValueChanged('allowRelayOnlySwimmer', value); }}
                      disabled={true} />
                  </div>
                  {formState.allowRelayOnlySwimmer === true &&
                    <Fragment>
                      <div className='col-xs-12 col-sm-6'>
                        <YesNoSwitch
                          label='Include Relay Only Swimmers in Max Number of Athletes?'
                          name='includeRelayOnlySwimmersInMaxNumber'
                          checked={formState.includeRelayOnlySwimmersInMaxNumber}
                          error={errorState.includeRelayOnlySwimmersInMaxNumber}
                          message={errorState.includeRelayOnlySwimmersInMaxNumber}
                          onChange={(value) => { onFormValueChanged('includeRelayOnlySwimmersInMaxNumber', value); }}
                          disabled={true} />
                      </div>
                      <div className='col-xs-12 col-sm-6'>
                        <YesNoSwitch
                          label='Include Relay Only Swimmers in Count for Coach Passes?*'
                          name='includeRelaySwimmerForCoachPasses'
                          checked={formState.includeRelaySwimmerForCoachPasses}
                          error={errorState.includeRelaySwimmerForCoachPasses}
                          message={errorState.includeRelaySwimmerForCoachPasses}
                          onChange={(value) => { onFormValueChanged('includeRelaySwimmerForCoachPasses', value); }}
                          disabled={true} />
                      </div>
                    </Fragment>}
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label={<Fragment>Max Relay Entries Per Team Per Relay Event*<span>&nbsp;<ToolTip label="Limit on the number of relay entries allowed per team for each relay event."></ToolTip></span></Fragment>}
                      name='maxRelayTeamEntriesPerEvent'
                      value={formState.maxRelayTeamEntriesPerEvent}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                    <ReadOnly
                      label={<Fragment>Max Relay Events Per Individual Per Meet<span>&nbsp;<ToolTip label="Limit on the number of relay events an athlete can enter for the entire meet."></ToolTip></span></Fragment>}
                      name='maxRelayEventsPerIndividualPerMeet'
                      value={formState.maxRelayEventsPerIndividualPerMeet}
                    />
                  </div>
                </Fragment>}
              <div className='col-xs-12 col-sm-6'>
                <YesNoSwitch
                  label='Meet allows Bonus Events?*'
                  name='bonusEvents'
                  checked={formState.bonusEvents}
                  error={errorState.bonusEvents}
                  message={errorState.bonusEvents}
                  onChange={(value) => { onFormValueChanged('bonusEvents', value); }}
                  disabled={true} />
              </div>
              <div className='col-xs-12 usas-extra-top-margin'>
                <Headings.H6>Coach Pass Rules</Headings.H6>
              </div>
              <div className='col-xs-12'>
                <OmeEligibilityCoachPassesGrid omeMeetState={omeMeetState} gridState={state} />
              </div>
              {formState.bonusEvents === true &&
                <Fragment>
                  <div className='col-xs-12 usas-extra-top-margin'>
                    <Headings.H6>Bonus Event Rules</Headings.H6>
                  </div>
                  <div className='col-xs-12'>
                    <OmeEligibilityBonusEventsGrid omeMeetState={omeMeetState} gridState={state} />
                  </div>
                </Fragment>
              }
            </div>
          </form>
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              {modalState.minQualifyingEvents !== '' && <p><b>Min Number of Qualifying Events:</b>&nbsp;{modalState.minQualifyingEvents}</p>}
              {modalState.maxQualifyingEvents !== '' && <p><b>Max Number of Qualifying Events:</b>&nbsp;{modalState.maxQualifyingEvents}</p>}
              {modalState.bonusStandardCount !== '' && <p><b>Allowed Number of Bonus Events:</b>&nbsp;{modalState.bonusStandardCount}</p>}
              {modalState.minSwimmers !== '' && <p><b>Min Number of Athletes:</b>&nbsp;{modalState.minSwimmers}</p>}
              {modalState.maxSwimmers !== '' && <p><b>Max Number of Athletes:</b>&nbsp;{modalState.maxSwimmers}</p>}
              {modalState.coachPasses !== '' && <p><b>Allowed Number of Coach Passes:</b>&nbsp;{modalState.coachPasses}</p>}
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => modalState.omeMeetCoachPassId >= 0 ? onDeleteCoachPassRule(e) : onDeleteBonusEventRule(e)}>Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={warningModalState.modalTitle}
          displayPopUp={warningModalState.displayPopUp}
          onModalCanceled={onWarningModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              {warningModalState.deleteRelayRelatedCosts === true && warningModalState.relayOnlySwimmerPricingWarningMessage !== '' ? <p>{warningModalState.relayOnlySwimmerPricingWarningMessage}</p> : ''}
              {warningModalState.deleteRelayRelatedCosts === true && warningModalState.relayEntryPricingWarningMessage !== '' ? <p>{warningModalState.relayEntryPricingWarningMessage}</p> : ''}
              {warningModalState.deleteCostPerAthleteIndividualBonusEventEntry === true ? <p>{warningModalState.bonusPricingWarningMessage}</p> : ''}
              {warningModalState.deleteAllEventBonusTimeStandardCuts === true ? <p>{warningModalState.timeStandardWarningMsg}</p> : ''}
              <p><b>Are you sure you would like to save these changes and make the associated deletes?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onWarningModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onSaveAndDelete(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment >
  );
};

export default OmeEligibility;