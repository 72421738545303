import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getAthleteRaceStatsData from './GetAthleteRaceStatsData';
import getAthleteRaceStatsForCoachData from './GetAthleteRaceStatsForCoachData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const AthleteRaceStatsData = {
  INITIAL_STATE,
  getAthleteRaceStatsData,
  getAthleteRaceStatsForCoachData
};

export default AthleteRaceStatsData;