import { useEffect } from 'react';

import useRelayAthleteMeetEventData from '../../../state/relayAthleteMeetEvent/UseRelayAthleteMeetEventData';

const useRelayAthleteMeetEventCombobox = (meetEventId, omeMeetOrgUnitEntryId) => {
  const { relayAthleteMeetEventState, getOrgUnitAthleteEntryRelayAthleteByMeetEventId } = useRelayAthleteMeetEventData();

  useEffect(() => {
    if ((relayAthleteMeetEventState.isArrayLoaded !== true || (meetEventId && meetEventId !== relayAthleteMeetEventState.meetEventId))
      && relayAthleteMeetEventState.isArrayLoading !== true) {
      getOrgUnitAthleteEntryRelayAthleteByMeetEventId(meetEventId, omeMeetOrgUnitEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relayAthleteMeetEventState, meetEventId, omeMeetOrgUnitEntryId]);

  return { relayAthleteMeetEventState, getOrgUnitAthleteEntryRelayAthleteByMeetEventId };
};

export default useRelayAthleteMeetEventCombobox;