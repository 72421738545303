import React, { Fragment } from 'react';

import AthleteRaceStatsGridLarge from './AthleteRaceStatsGridLarge';

const AthleteRaceStatsGrid = ({ data, isLoading, onView }) => (
  <Fragment>
    <AthleteRaceStatsGridLarge data={data} isLoading={isLoading} onView={onView} />
  </Fragment>
);

export default AthleteRaceStatsGrid;