import React, { Fragment } from 'react';

import useTimesReconUpload from './UseTimesReconUpload';

import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import PopUpModal from '../../dialogs/PopUpModal';
import Constants from '../../../utils/Constants';
import ActionIntraPageButton from '../../buttons/ActionIntraPageButton';
import ViewReport from '../../embeddedReports/ViewReport';

import global from '../../../components/GlobalStyle.module.css';

const TimesReconUpload = ({ meetId }) => {
  const FileUpload = () => {
    const hiddenFileInput = React.useRef(null);
    let fileReader;
    let fileType;

    const handleSelectFileClicked = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      hiddenFileInput.current.click();
    };

    const handleFileRead = (event) => {
      const fileContent = fileReader.result;
      processFile(fileContent, fileType);
    };

    const handleFileChosen = (file) => {
      fileType = file.name.substring(file.name.length - 3);

      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    };

    return (
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton onClick={handleSelectFileClicked}>Select a File</PrimaryButton>&nbsp;
          Hy-Tek (*.cl2), SDIF (*.sd3), Lenex (*.lef)
        </div>
        <input
          type='file'
          id='file'
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={e => handleFileChosen(e.target.files[0])} />
      </div>
    );
  };

  const {
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    timesReconState,
    jsonFileState,
    uiState,
    processFile,
    onDownloadClicked,
    onSubmitTimesReconResults,
    onRunTimesReconReport
  } = useTimesReconUpload(meetId);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <FileUpload />
        </div>
      </div>
      {timesReconState.isSaved
        ? <Fragment>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <p className={global.Bold}>Times Recon data was uploaded. Please use the 'Run Times Recon Report' button to view the uploaded data.</p>
            </div>
          </div>
        </Fragment>
        : <Fragment />}
      {timesReconState.message.length > 0 && timesReconState.message !== Constants.SAVING_MSG
        ? <Fragment>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <p className={global.Bold}>Times Recon Save Error: {timesReconState.message}</p>
            </div>
          </div>
        </Fragment>
        : <Fragment />}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin" style={{ display: jsonFileState.individualTimesReconContent.length === 0 ? 'none' : 'flex' }}>
        <div className="col-xs-12 usas-extra-top-margin">
          {jsonFileState.isValidFileType === true && jsonFileState.isZipFile === false ?
            <SecondaryButton type="button" onClick={onDownloadClicked}>Download JSON Files</SecondaryButton> : <Fragment />
          }
          &nbsp;&nbsp;
          {uiState.canUpload === true && jsonFileState.isValidFileType === true && jsonFileState.isZipFile === false ?
            <PrimaryButton type="button" onClick={onSubmitTimesReconResults}>Upload Times Recon Results</PrimaryButton> : <Fragment />
          }
          &nbsp;&nbsp;
          {timesReconState?.isSaved === true &&
            <ActionIntraPageButton type="button" onClick={() => onRunTimesReconReport(meetId)}>Run Times Recon Report</ActionIntraPageButton>}
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={"Processing"}
        displayPopUp={jsonFileState?.reconContent?.length === 0 && jsonFileState?.processingStarted === true}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={timesReconState?.isSaving} />
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
    </Fragment>
  );
}

export default TimesReconUpload;