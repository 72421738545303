import React, { createContext, useState } from 'react';

import RelayAthleteMeetEventData from './RelayAthleteMeetEventData';

export const RelayAthleteMeetEventStateContext = createContext();

const RelayAthleteMeetEventContextProvider = ({ children }) => {
  const stateHook = useState(RelayAthleteMeetEventData.INITIAL_STATE);

  return (
    <RelayAthleteMeetEventStateContext.Provider value={stateHook}>
      {children}
    </RelayAthleteMeetEventStateContext.Provider>
  );
};

export default RelayAthleteMeetEventContextProvider;