import { Fragment } from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ rightSideInfo, onDeleteClicked }) => (
  <Fragment>
    <button
      className={global.IconButton}
      type="button"
      onClick={() => onDeleteClicked(rightSideInfo)}>
      <DeleteIcon />
    </button>
  </Fragment>
);

//TODO tablet view
const SmallGridRow = ({ rightSideInfo, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span></span>&nbsp;
      <SmallGridRowIcons
        rightSideInfo={rightSideInfo}
        onDeleteClicked={onDeleteClicked} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Display Order
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {rightSideInfo?.displayOrder || 0}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Record List
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {rightSideInfo?.recordListName || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Name to Show
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {rightSideInfo?.nameToShow || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Times to Show
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {rightSideInfo?.recordsToShow || 0}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Relay Only?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {rightSideInfo?.isRelayOnly === true ? 'Yes' : 'No'}
        </div>
      </div>
    </div>
  </div>
);

const LaneSheetMeetRightSideNotesSmallGrid = ({ isLoading, gridData, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((rightSideInfo, i) =>
          <SmallGridRow
            key={i}
            rightSideInfo={rightSideInfo}
            onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
          </div>
        )
    }
  </div>
);

export default LaneSheetMeetRightSideNotesSmallGrid;