import { useState } from 'react';

import MeetDistinctEventsData from './MeetDistinctEventsData';

const useMeetDistinctEventsData = () => {
  const [meetDistinctEventsState, setMeetDistinctEventsState] = useState(MeetDistinctEventsData.INITIAL_STATE);
  const getMeetDistinctEvents = (meetId) => MeetDistinctEventsData.getMeetDistinctEvents(meetId, meetDistinctEventsState, setMeetDistinctEventsState);

  return { meetDistinctEventsState, getMeetDistinctEvents };
};

export default useMeetDistinctEventsData;