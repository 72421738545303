import React, {createContext, useState} from 'react';

import MeetTimesData from '../MeetTimesData';

export const MeetTimesAddStateContext = createContext();

const MeetTimesAddContextProvider = ({children}) => {
  const stateHook = useState(MeetTimesData.INITIAL_STATE);

  return (
    <MeetTimesAddStateContext.Provider value={stateHook}>
      {children}
    </MeetTimesAddStateContext.Provider>
  );
};

 export default MeetTimesAddContextProvider;