import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

import searchMeetTeamEntryData from './SearchMeetTeamEntryData';
import getOmeMeetTeamEntryData from './GetOmeMeetTeamEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  omeMeetId: Constants.DEFAULT_ID
};

const OmeMeetTeamEntryData = {
  INITIAL_STATE,
  searchMeetTeamEntryData,
  getOmeMeetTeamEntryData
};

export default OmeMeetTeamEntryData;