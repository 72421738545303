import React, { Fragment } from 'react';

import useOrgUnitAdminCoaches from './UseOrgUnitAdminCoaches';

import OrgUnitAdminCoachesGrid from './OrgUnitAdminCoachesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminCoachesReadOnly = () => {
  const { omeMeetOrgUnitNonAthleteEntryState } = useOrgUnitAdminCoaches();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Coaches</Headings.H3>
        </div>
        <div className="col-xs-12">
          <OrgUnitAdminCoachesGrid
            gridData={omeMeetOrgUnitNonAthleteEntryState.arrayData}
            isLoading={omeMeetOrgUnitNonAthleteEntryState.isArrayLoading}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryState.isArrayLoading} />
    </Fragment>
  );
};

export default OrgUnitAdminCoachesReadOnly;