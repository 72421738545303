import { Fragment } from "react";

import useOrgUnitAdminRelayEntries from './UseOrgUnitAdminRelayEntries';

import OrgUnitAdminRelayEntriesGrid from "./OrgUnitAdminRelayEntriesGrid";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../../common/components/headings/Headings";

import Constants from "../../../../../common/utils/Constants";

const OrgUnitAdminRelayEntriesReadOnly = () => {
    const { omeMeetOrgUnitAthleteEntryRelayRosterEntryState, onEditRelayClicked } = useOrgUnitAdminRelayEntries();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <Headings.H3>Relay Entries</Headings.H3>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <OrgUnitAdminRelayEntriesGrid
                        gridData={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData}
                        isLoading={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading}
                        onEditEventClicked={onEditRelayClicked}
                    />
                </div>
            </div>
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading} />
            <PopUpModal
                widthPct={30}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isSaving} />
        </Fragment>
    )
}
export default OrgUnitAdminRelayEntriesReadOnly;