import Constants from '../../../common/utils/Constants';
import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetOmeFeeTypeData = (state, setState) => {
  if (state.isArrayLoaded !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/OmeFeeType`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ omeFeeTypeId: Constants.DEFAULT_ID, typeName: '--', isAdditionalPurchase: true });
          const additionalPurchaseOnlyArrayData = arrayData.filter(x => x.isAdditionalPurchase === true);
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            dataAsOptionsWithNames: arrayData.map(x => { return { id: x.omeFeeTypeId, name: `${x.typeName}` }; }),
            additionalPurchaseOnlyArrayData: additionalPurchaseOnlyArrayData,
            additionalPurchaseOnlyDataAsOptionsWithNames: additionalPurchaseOnlyArrayData.map(x => { return { id: x.omeFeeTypeId, name: `${x.typeName}` }; }),
            message: ''
          })
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOmeFeeTypeData;