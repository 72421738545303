import React, { createContext, useState } from 'react';

import OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData from './OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData';

export const OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionStateContext = createContext();

const OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionData.INITIAL_STATE);

  return (
    <OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionStateContext.Provider>
  );
};

export default OmeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryCoachesSelectionContextProvider;