import React, { Fragment } from 'react';

import useLaneSheetMeetInfo from './UseLaneSheetMeetInfo';

import LaneSheetMeetInfoForm from '../components/forms/LaneSheetMeetInfoForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LaneSheetMeetInfo = () => {
  const {
    isSaving,
    isLoading,
    laneSheetState,
    onSubmitFormCallback
  } = useLaneSheetMeetInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Info For Lane Sheet</Headings.H3>
        </div>
      </div>
      <LaneSheetMeetInfoForm
        laneSheetObj={laneSheetState.objData || {}}
        onSubmitFormCallback={onSubmitFormCallback} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default LaneSheetMeetInfo;