import React, { Fragment } from 'react';

import useOmeHostLoading from './UseOmeHostLoading';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const OmeHostLoading = ({redirectNavLink}) => {
  const {
    isLoading
  } = useOmeHostLoading(redirectNavLink);

  return (
    <Fragment>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default OmeHostLoading;