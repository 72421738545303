import React from 'react';
import Dropdown from '../dropdowns/Dropdown';
import style from './DatePicker.module.css';
import useDateTimePicker from './UseDateTimePicker';

import global from '../GlobalStyle.module.css';

const DateTimePicker = ({ label, message, name, value, countOfYears, startYearOffset, onChange, error }) => {
  const {
    month, day, year, time, isLoaded,
    months, days, years, times,
    onMonthChanged, onDayChanged, onYearChanged, onTimeChanged
  } = useDateTimePicker(value, countOfYears, startYearOffset, onChange);

  return (
    <div className={global.ComponentFlex}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <div className={style.DateDiv}>
        <div className={style.MonthDiv}>
          <Dropdown
            error={error}
            options={months || []}
            name={`${name}-month`}
            value={month}
            onChange={onMonthChanged}
            isLoading={isLoaded !== true}
            areValuesIntegers={false}
            defaultValue="00" />
        </div>
        <div className={style.DayDiv}>
          <Dropdown
            error={error}
            options={days || []}
            name={`${name}-day`}
            value={day}
            onChange={onDayChanged}
            isLoading={isLoaded !== true}
            areValuesIntegers={false}
            defaultValue="00" />
        </div>
        <div className={style.YearDiv}>
          <Dropdown
            error={error}
            options={years || []}
            name={`${name}-year`}
            value={year}
            onChange={onYearChanged}
            isLoading={isLoaded !== true}
            areValuesIntegers={false}
            defaultValue="0000" />
        </div>
        <div className={style.TimeDiv}>
          <Dropdown
            error={error}
            options={times || []}
            name={`${name}-time`}
            value={time}
            onChange={onTimeChanged}
            isLoading={isLoaded !== true}
            areValuesIntegers={false}
            defaultValue="99:99" />
        </div>
      </div>
      {error && <p className={[global.ErrorMessage, style.ErrorMsg].join(' ')}>{message}</p>}
    </div>
  );
};

export default DateTimePicker;