import React, { Fragment } from 'react';

import useOrgUnitAdminOfficials from './UseOrgUnitAdminOfficials';

import Headings from '../../../../../common/components/headings/Headings';

const OrgUnitAdminOfficialsWrite = () => {
  // eslint-disable-next-line no-empty-pattern
  const { } = useOrgUnitAdminOfficials();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Officials</Headings.H3>
          <div>TODO</div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrgUnitAdminOfficialsWrite;