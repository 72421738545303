import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDateTime } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const MeetTeamEntrySmallGrid = ({ gridData, isLoading, onDeleteTeamEntryClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData && Object.keys(gridData).length > 0
        ?
        (<div className={global.SmallTableRow} key={gridData.omeMeetOrgUnitEntryId}>
          <div className={global.SmallTableRowHead}><span className='hidden-xs'>{gridData.orgUnit?.orgUnitName || <span>&nbsp;</span>}{gridData.orgUnit?.orgUnitName === 'Unattached' ? ' - ' + gridData.orgUnit?.parentOrgUnitName : ''} {gridData.orgUnit?.parentOrgUnitCode ? '(' + gridData.orgUnit?.parentOrgUnitCode + ')' : ''}</span>&nbsp;
            {onDeleteTeamEntryClicked &&
              <button className={global.IconButton}
                type="button"
                onClick={(e) => onDeleteTeamEntryClicked(e, gridData)}>
                <DeleteIcon />
              </button>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Team Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{gridData.orgUnit?.orgUnitName || <span>&nbsp;</span>}{gridData.orgUnit?.orgUnitName === 'Unattached' ? ' - ' + gridData.orgUnit?.parentOrgUnitName : ''} {gridData.orgUnit?.parentOrgUnitCode ? '(' + gridData.orgUnit?.parentOrgUnitCode + ')' : ''}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Team Entry Status</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{gridData.status || 'Unknown'} {gridData.orgUnitSubmissionDatetime ? '- ' + formatDateTime(gridData.orgUnitSubmissionDatetime) + ' ' + gridData.timeZone : ''}</div>
            </div>
          </div>
        </div>
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Team Entry</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetTeamEntrySmallGrid;