import { Fragment } from 'react';

import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const OrgUnitAdminRosterSelectionSmallGrid = ({ gridData, isLoading, onCheckboxChange, onYesNoSwitchChange, allowRelayOnly }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((athlete, i) => (
          <div className={global.SmallTableRow} key={athlete.orgUnitNonAthleteEntryId || i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''}  ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
              {(athlete.canBeDeleted === true || (athlete.isRelayAthlete === false && athlete.hasPaid === false)) ?
                <div className={global.CheckboxMobile}>
                  <Checkbox
                    className={global.IconButton}
                    label=""
                    name="isSelected"
                    onChange={(e) => { onCheckboxChange(e, athlete.personId) }}
                    checked={athlete.isSelected} /></div> :
                <div className='pull-right'>
                  <Checkbox
                    label=""
                    name="isSelected"
                    onChange={(e) => { onCheckboxChange(e, athlete.personId) }}
                    checked={athlete.isSelected}
                    disabled={true} />
                </div>
              }
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''}  ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.competitionGenderTypeName || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Age at Meet</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : <span>&nbsp;</span>}</div>
                {allowRelayOnly === true && athlete.isSelected === true &&
                  <Fragment>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Relay Only Swimmer?</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>
                      {athlete.isIndividualAthlete === true ?
                        <YesNoSwitch
                          label=""
                          name={'isRelayOnlyAthlete' + athlete.personId}
                          onChange={(e) => { onYesNoSwitchChange(e, athlete.personId) }}
                          checked={athlete.isRelayOnlyAthlete}
                          disabled={true} /> :
                        <YesNoSwitch
                          className={global.IconButton}
                          label=""
                          name={'isRelayOnlyAthlete' + athlete.personId}
                          onChange={(e) => { onYesNoSwitchChange(e, athlete.personId) }}
                          checked={athlete.isRelayOnlyAthlete}
                        />}</div>
                  </Fragment>}
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminRosterSelectionSmallGrid;