import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import deleteIndividualAthleteMeetEventData from './DeleteIndividualAthleteMeetEventData';
import deleteRelayMeetEventEntryData from './DeleteRelayMeetEventEntryData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const AthleteMeetEventData = {
  INITIAL_STATE, deleteIndividualAthleteMeetEventData, deleteRelayMeetEventEntryData
}

export default AthleteMeetEventData;