import { useState, useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import getLeftNavigationState from './GetLeftNavigationState';

import useOmeMeetData from '../../state/omeMeet/UseOmeMeetData';
import useOmeMeetInvitationsData from '../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

import useMeetData from '../../../common/state/meet/UseMeetData';
import useOrgUnitData from '../../../common/state/orgUnit/UseOrgUnitData';
import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import { formatDate } from '../../../common/utils/DateFunctions';

const INITIAL_STATE = { items: [], meetName: '', meetHost: '', meetClassification: '', meetDates: '' };

const useLeftNavigation = (viewName) => {
  const navigate = useNavigate();
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const [leftNavState, setLeftNavState] = useState(INITIAL_STATE);
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetInvitationsState } = useOmeMeetInvitationsData();
  const { meetState } = useMeetData();
  const { orgUnitState, getOrgUnits } = useOrgUnitData();
  const { environmentVariableState, ENTRY_CONTACT_STAFF_TYPE_ID, PAYMENT_CONTACT_STAFF_TYPE_ID,
    TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID, INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
    ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID,
    SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();

  const getMeetName = () => {
    if (omeMeetState.selectedMeet?.meetName) {
      return `${omeMeetState.selectedMeet?.meetName}`
    } else {
      return null;
    }
  };

  const getMeetHostName = () => {
    if (omeMeetState.selectedMeet?.meetHost) {
      return `${omeMeetState.selectedMeet?.meetHost}`
    } else {
      return null;
    }
  };

  const getMeetClassification = () => {
    if (omeMeetState.selectedMeet?.meetClassificationName) {
      return `${omeMeetState.selectedMeet?.meetClassificationName}`
    } else {
      return null;
    }
  };

  const getMeetDates = () => {
    if (omeMeetState.selectedMeet?.startDate || omeMeetState.selectedMeet?.endDate) {
      return `${formatDate(omeMeetState.selectedMeet?.startDate)} - ${formatDate(omeMeetState.selectedMeet?.endDate)}`
    } else {
      return null;
    }
  };

  const getOmeMeetStatus = () => {
    if (omeMeetState.objData?.status) {
      return `${omeMeetState.objData?.status}`;
    }
    else {
      return null;
    }
  }

  const onNavigateToPath = (e, path) => {
    if (e?.preventDefault) {
      e.preventDefault();
    };
    setLeftNavModalState({
      ...leftNavModalState,
      modalTitle: 'The information on this page has not been saved',
      displayPopUp: false,
      formStateSaved: true,
      path: ''
    });

    navigate(path);
  };

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === false && orgUnitState.isArrayLoading === false) {
      getOrgUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState]);

  useEffect(() => {
    if (isReadyToRoute === true && environmentVariableState.isLoaded === true && orgUnitState.isArrayLoaded === true) {
      const meetInfoRoutePermission = navRoutes?.OME_HOST_MEET_INFO?.permission;
      const restrictionsRoutePermission = navRoutes?.OME_HOST_RESTRICTIONS?.permission;
      const eligibilityRoutePermission = navRoutes?.OME_HOST_ELIGIBILITY?.permission;
      const items = getLeftNavigationState(viewName, omeMeetState.route,
        omeMeetState.objData, omeMeetInvitationsState.arrayData, orgUnitState.arrayData, meetState.objData,
        ENTRY_CONTACT_STAFF_TYPE_ID, PAYMENT_CONTACT_STAFF_TYPE_ID,
        TEAM_OME_FEE_TYPE_ID, CREDENTIAL_OME_FEE_TYPE_ID,
        INDIVIDUAL_ATHLETE_OME_FEE_TYPE_ID, INDIVIDUAL_INTL_ATHLETE_OME_FEE_TYPE_ID, INTL_STAFF_OME_FEE_TYPE_ID, RELAY_ONLY_SWIMMER_OME_FEE_TYPE_ID,
        ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID, RELAY_EVENT_ENTRY_OME_FEE_TYPE_ID, FINA_ORG_UNIT_ID,
        SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID, restrictionsRoutePermission, eligibilityRoutePermission, meetInfoRoutePermission);
      const meetName = getMeetName();
      const meetHost = getMeetHostName();
      const meetClassification = getMeetClassification();
      const meetDates = getMeetDates();
      const omeMeetStatus = getOmeMeetStatus();
      setLeftNavState({
        ...leftNavState,
        items,
        meetName,
        meetHost,
        meetClassification,
        meetDates,
        omeMeetStatus
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, omeMeetState, omeMeetInvitationsState, orgUnitState, meetState, viewName, isReadyToRoute]);

  return { ...leftNavState, leftNavState, onNavigateToPath };
};

export default useLeftNavigation;
