
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useMeetSessionsData from '../../../../common/state/meetSessions/UseMeetSessionsData';

import Constants from '../../../../common/utils/Constants';

const useMeetSessions = () => {
  const navigate = useNavigate();
  const { meetState, putMeet } = useMeetData();
  const { resetModalState, meetSessionsState } = useMeetSessionsData();

  const onAddSession = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const prevHighestSessionOrderId = meetSessionsState.sortedMeetSessionArray?.length > 0 ? Math.max(...meetSessionsState.sortedMeetSessionArray?.map(o => o.sessionOrderId)) : 0;
    navigate(NavLinks.MEET_SESSIONS_DETAIL, { state: { prevHighestSessionOrderId: prevHighestSessionOrderId } });
  }

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_SESSIONS_DETAIL, { state: { meetSessionId: session.meetSessionId } });
  }

  const onDeleteSession = (e, meetSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const updatedMeetCopy = JSON.parse(JSON.stringify(meetState.objData));
    const selectedIndex = updatedMeetCopy.meetSession.findIndex(x => x.meetSessionId === meetSessionId);
    if (selectedIndex > -1) {
      updatedMeetCopy.meetSession.splice(selectedIndex, 1);
      resetModalState();
      putMeet(meetState.objData.meetId, updatedMeetCopy);
    }
  }

  return {
    meetState,
    isLoading: meetState.isObjLoading,
    isSaving: meetState.isSaving,
    Constants,
    onAddSession,
    onEditSession,
    onDeleteSession
  };
}

export default useMeetSessions;