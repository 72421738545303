import { Fragment } from "react";

import useConfigurationReadOnlyForm from "./UseConfigurationReadOnlyForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import Headings from "../../../../../common/components/headings/Headings";

const ConfigurationReadOnlyForm = ({ onPrimaryButtonClicked, onSecondaryButtonClicked, primaryButtonText, secondaryButtonText,
  selectionsStateObj, children }) => {
  const {
    state,
    getTotalParticipants
  } = useConfigurationReadOnlyForm(selectionsStateObj);

  return (
    <Fragment>
      <div className='row'>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Organization'
            name="meetHost"
            value={state.meetHost} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Meet Name'
            name="meetName"
            value={state.meetName} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Meet Dates'
            name="meetDates"
            value={state.meetDates} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Gender'
            name="competitionGenderTypeName"
            value={state.competitionGenderTypeName} />
        </div>
        <div className="col-xs-12">
          <ReadOnly
            label='Description'
            name="selectionDesc"
            value={state.selectionDesc} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Maximum Number of Participants</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Swimmers'
            name="maxSwimmers"
            value={state.maxSwimmers} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Divers'
            name="maxDivers"
            value={state.maxDivers} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Total Participants'
            name="totalParticipants"
            value={getTotalParticipants(state.maxSwimmers, state.maxDivers)} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Initial Selection Configuration</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Minimum Number of Rows/Places to Seed'
            name="minRowsToSeed"
            value={state.minRowsToSeed} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Minimum Number of Relays to Accept'
            name="minRelayRowsToAccept"
            value={state.minRelayRowsToAccept} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Individual/Relay Ratio</Headings.H5>
          <p>When adding rows based on individual to relay ratio, use the following ratio</p>
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Individual Row(s)'
            name="addRatioIndRows"
            value={state.addRatioIndRows} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Relay Row(s)'
            name="addRatioRelayRows"
            value={state.addRatioRelayRows} />
        </div>
      </div>
      {children}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <ReadOnly
            label='Include entries that are not checked out? (only use this for trial runs)'
            name="includeEntriesNotCheckedOut"
            value={state.includeEntriesNotCheckedOut} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
          <PrimaryButton type="button" onClick={onPrimaryButtonClicked}>{primaryButtonText}</PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfigurationReadOnlyForm;