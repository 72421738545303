import { useState } from 'react';

import MeetResultsDocFilesData from './MeetResultsDocFilesData';

const useMeetMeetResultsDocFilesData = () => {
  const [meetFilesState, setMeetFilesState] = useState(MeetResultsDocFilesData.INITIAL_STATE);

  const postMeetResultsDocFiles = (file) => {
    MeetResultsDocFilesData.postMeetResultsDocFilesData(file, meetFilesState, setMeetFilesState);
  };

  const resetMeetResultsDocFilesState = () => {
    setMeetFilesState(MeetResultsDocFilesData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (meetFilesState.isObjLoaded === true) {
      const fileNameWithDateTime = meetFilesState.objData.uploadUrl.replace(MeetResultsDocFilesData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return meetFilesState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(MeetResultsDocFilesData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    meetFilesState,
    postMeetResultsDocFiles,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    resetMeetResultsDocFilesState,
    BASE_UPLOAD_URL: MeetResultsDocFilesData.BASE_UPLOAD_URL,
    GET_MEET_FILES_PATH: MeetResultsDocFilesData.GET_MEET_FILES_PATH
  };
};

export default useMeetMeetResultsDocFilesData;