/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Min # Qualifying Events</th>
        <th>Max # Qualifying Events</th>
        <th>Allowed # Bonus Events</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (<Fragment>
          {state.isObjLoading === true ?
            <tr>
              <td colSpan="4">Loading...</td>
            </tr> :
            <tr>
              <td colSpan="4">No Bonus Event Rules</td>
            </tr>
          }
        </Fragment>
        ) : state.objData.omeMeetQualification.length > 0 && state.objData.omeMeetQualification[0]?.omeQualificationBonus?.length > 0 &&
          gridState.sortedBonusGridData.length > 0 ?
          gridState.sortedBonusGridData.map((bonus, i) => (
            < tr key={bonus.qualificationBonusId || i} >
              <td>{bonus.minQualifyingEvents >= 0 ? bonus.minQualifyingEvents : ''}</td>
              <td>{bonus.maxQualifyingEvents >= 0 ? bonus.maxQualifyingEvents : ''}</td>
              <td>{bonus.bonusStandardCount >= 0 ? bonus.bonusStandardCount : ''}</td>
              <td>
                {onEdit &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, bonus.omeMeetQualificationId, bonus.qualificationBonusId)}>
                    <EditIcon />
                  </button>}
                {onDelete &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, bonus)}>
                    <DeleteIcon />
                  </button>}
              </td>
            </tr>)
          ) : (
            <tr>
              <td colSpan="4">No Bonus Event Rules</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ state, gridState, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Bonus Event Rules</div>
            }
          </div>
        </div>
      )
      : state.objData.omeMeetQualification.length > 0 && state.objData.omeMeetQualification[0]?.omeQualificationBonus?.length > 0 &&
        gridState.sortedBonusGridData.length > 0 ?
        gridState.sortedBonusGridData.map((bonus, i) => (
          <div className={global.SmallTableRow} key={bonus.qualificationBonusId || i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>Bonus Event Rule</span>&nbsp;
              {onDelete &&
                <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, bonus)}>
                  <DeleteIcon />
                </button>}
              {onEdit &&
                <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, bonus.omeMeetQualificationId, bonus.qualificationBonusId)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-9 col-sm-6', global.SmallTableRowLabels].join(' ')}>Min # Qualifying Events</div>
                <div className={['col-xs-3 col-sm-6', global.SmallTableRowData].join(' ')}>{bonus.minQualifyingEvents >= 0 ? bonus.minQualifyingEvents : <span>&nbsp;</span>}</div>
                <div className={['col-xs-9 col-sm-6', global.SmallTableRowLabels].join(' ')}>Max # Qualifying Events</div>
                <div className={['col-xs-3 col-sm-6', global.SmallTableRowData].join(' ')}>{bonus.maxQualifyingEvents >= 0 ? bonus.maxQualifyingEvents : <span>&nbsp;</span>}</div>
                <div className={['col-xs-9 col-sm-6', global.SmallTableRowLabels].join(' ')}>Allowed # Bonus Events</div>
                <div className={['col-xs-3 col-sm-6', global.SmallTableRowData].join(' ')}>{bonus.bonusStandardCount >= 0 ? bonus.bonusStandardCount : <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )
        )
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Bonus Event Rules</div>
            </div>
          </div>
        )}
  </div >
);

const OmeEligibilityBonusEventsGrid = ({ omeMeetState, gridState, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={omeMeetState} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={omeMeetState} gridState={gridState} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default OmeEligibilityBonusEventsGrid;