import React, { Fragment } from 'react';

import useMyMeetsLandingPage from './UseMyMeetsLandingPage';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const MyMeetsLandingPage = () => {
  const { contextSecurityState } = useMyMeetsLandingPage();

  return (
    <Fragment>
      <hr />
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12 text-center hidden-xs'>
          <Headings.H6>Welcome to your 'My Meets' landing page. Please select a tab to view associated information.</Headings.H6>
        </div>
        <div className='col-xs-12 text-center visible-xs'>
          <Headings.H6>Welcome to your 'My Meets' landing page. Please use the Navigation Quick Links button to select a tab to view associated information.</Headings.H6>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={contextSecurityState.isObjLoading}
      />
    </Fragment>
  );
};

export default MyMeetsLandingPage;