import validate from './LaneSheetAddMeetEventFormValidation';

import useForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  competitionCategoryId: [],
  eventNameAndDistanceId: []
};

const useLaneSheetAddMeetEventForm = (onSubmitFormCallback) => {
  const { formState, errorState, handleSubmit, onFormValueChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit
  };
};

export default useLaneSheetAddMeetEventForm;