import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitAthleteEntryAggregateRelayAthleteData from './GetOrgUnitAthleteEntryAggregateRelayAthleteData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData = {
  INITIAL_STATE, getOrgUnitAthleteEntryAggregateRelayAthleteData
}

export default OmeMeetOrgUnitAthleteEntryAggregateRelayAthleteData;