import { useContext } from 'react';

import { EventMeetEventBonusStateContext } from './EventMeetEventBonusContextProvider';

import EventMeetEventBonusData from './EventMeetEventBonusData';

const useEventMeetEventBonusData = () => {
  const [eventMeetEventBonusState, setEventMeetEventBonusState] = useContext(EventMeetEventBonusStateContext);

  const getEventMeetEventBonus = (meetEventId) => {
    EventMeetEventBonusData.getEventMeetEventBonusData(meetEventId, eventMeetEventBonusState, setEventMeetEventBonusState);
  };

  return {
    eventMeetEventBonusState,
    setEventMeetEventBonusState,
    getEventMeetEventBonus
  };
};

export default useEventMeetEventBonusData;