import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { isValidNumber } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.courseId === Constants.DEFAULT_ID || formState.courseName === '') {
    errors.courseId = 'Course is required';
  }

  if (formState.priority === '') {
    errors.priority = 'Priority is required';
  } else if (!isValidNumber(formState.priority) || ToIntIfInt(formState.priority) > 4 || ToIntIfInt(formState.priority) === 0) {
    errors.priority = 'Priority must be between 1 and 4';
  }

  return errors;
};

const OmeMeetInfoCoursePriorityValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OmeMeetInfoCoursePriorityValidation;