import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const getOrgUnitAthleteEntryPurchaseItems = async (orgUnitAthleteEntryId, state, setState) => {
  const api = initApi(API_NAMES.MEET, state, setState);
  const url = `/OrgUnitAthleteEntryPurchase/orgUnitAthleteEntry/${orgUnitAthleteEntryId}/PurchaseItems`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const OrgUnitAthleteEntryPurchaseItemData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getOrgUnitAthleteEntryPurchaseItems
};

export default OrgUnitAthleteEntryPurchaseItemData;
