import { useEffect } from "react";

import Constants from "../../../../../common/utils/Constants";
import useForm from "../../../../../common/utils/UseForm";

const useOmeEventsHeaderForm = (onFilterGrid) => {
  const { formState, errorState, onValueTextPairChanged
  } = useForm(getInitialFormState, () => { }, () => { });

  useEffect(() => {
    onFilterGrid(formState.eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.eventId]);

  function getInitialFormState() {
    return {
      eventId: Constants.DEFAULT_ID,
      eventName: '',
    };
  };

  return {
    formState,
    errorState,
    onValueTextPairChanged
  };
};

export default useOmeEventsHeaderForm;