import { useEffect } from "react";
import useMeetSessionsData from "../../state/meetSessions/UseMeetSessionsData";
import Constants from "../../utils/Constants";
import { formatDate } from "../../utils/DateFunctions";

const useSessions = (showOptionalFields, showOtsField, requiredFieldsReadOnly, meetSessionArray, meetId, meetStartDate, meetEndDate, deleteButtonFirst, saveNavLink, backNavLink) => {
  const { meetSessionsState, setMeetSessionsState, resetModalState } = useMeetSessionsData();

  const onDeleteClicked = (e, session) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setMeetSessionsState({
      ...meetSessionsState,
      modalState: {
        ...meetSessionsState.modalState,
        displayPopUp: true,
        meetSessionId: session.meetSessionId || Constants.DEFAULT_ID,
        sessionOrderId: session.sessionOrderId || '',
        sessionTypeName: session.sessionType?.typeName || '',
        sessionDate: session.sessionDate || Constants.BLANK_DATE_STRING
      }
    })
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    resetModalState();
  };

  useEffect(() => {
    if (showOptionalFields !== undefined && requiredFieldsReadOnly !== undefined && deleteButtonFirst !== undefined && meetSessionArray) {
      if (meetSessionArray?.length > 0) {
        //Sort grid data to display in ascending order
        const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionArray));
        meetSessionArrayCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
        setMeetSessionsState({
          ...meetSessionsState,
          showOptionalFields,
          showOtsField: showOtsField === true ? true : false,
          requiredFieldsReadOnly,
          meetSessionArray: meetSessionArray,
          sortedMeetSessionArray: meetSessionArrayCopy,
          formState: {
            ...meetSessionsState.formState,
            meetId,
            meetStartDate: meetStartDate ? formatDate(meetStartDate) : Constants.BLANK_DATE_STRING,
            meetEndDate: meetEndDate ? formatDate(meetEndDate) : Constants.BLANK_DATE_STRING
          },
          modalState: {
            ...meetSessionsState.modalState,
            deleteButtonFirst
          },
          saveNavLink,
          backNavLink
        });
      }
      else {
        setMeetSessionsState({
          ...meetSessionsState,
          showOptionalFields,
          showOtsField: showOtsField === true ? true : false,
          requiredFieldsReadOnly,
          meetSessionArray,
          sortedMeetSessionArray: [],
          formState: {
            ...meetSessionsState.formState,
            meetId,
            meetStartDate: meetStartDate ? formatDate(meetStartDate) : Constants.BLANK_DATE_STRING,
            meetEndDate: meetEndDate ? formatDate(meetEndDate) : Constants.BLANK_DATE_STRING
          },
          modalState: {
            ...meetSessionsState.modalState,
            deleteButtonFirst
          },
          saveNavLink,
          backNavLink
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetSessionArray]);

  return {
    meetSessionsState,
    onDeleteClicked,
    onModalCanceled
  };
};

export default useSessions;
