import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getLaneSheetHeatAthletesData = (laneSheetHeatId, state, setState) => {
  if (laneSheetHeatId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetHeatIdForUrl = encodeURIComponent(laneSheetHeatId);
    const url = `/LaneSheetHeat/${laneSheetHeatIdForUrl}/Athlete`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putLaneSheetHeatAthletesData = (laneSheetHeatId, laneSheetHeatAthletesArray, state, setState) => {
  if (laneSheetHeatId > 0 && laneSheetHeatAthletesArray) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetHeatIdForUrl = encodeURIComponent(laneSheetHeatId);
    const url = `/LaneSheetHeat/${laneSheetHeatIdForUrl}/Athlete`;

    return api?.executeArray ? api.executeArray(url, 'PUT', laneSheetHeatAthletesArray) : null;
  }
};

const LaneSheetHeatAthletesData = {
  getLaneSheetHeatAthletesData,
  putLaneSheetHeatAthletesData
};

export default LaneSheetHeatAthletesData;