import React, { Fragment } from 'react';

import useMeetSessionsDetail from './UseMeetAddSessionsDetail';

import useSessionsDetail from '../../../../common/components/meetSessions/UseSessionsDetail';

import SessionsDetail from '../../../../common/components/meetSessions/SessionsDetail';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../common/utils/Constants';
import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const MeetAddSessionsDetail = () => {
  const { meetState } = useMeetSessionsDetail();
  const { onSaveButtonClicked, onBackButtonClicked, meetSessionsState } = useSessionsDetail();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>{meetSessionsState.formState.meetSessionId > 0 ? 'Edit' : 'Add'} Session</Headings.H3>
        </div>
      </div>
      <div className={"row"}>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Name:</b> {meetState?.objData?.meetName || ''}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {meetState?.objData?.startDate ? formatDate(meetState?.objData?.startDate) : ''} - {meetState?.objData?.endDate ? formatDate(meetState?.objData?.endDate) : ''} </p>
        </div>
      </div>
      <SessionsDetail />
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          {meetSessionsState?.errorState?.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{meetSessionsState?.errorState?.error}</p>}
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveButtonClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackButtonClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetState.isSaving} />
    </Fragment>
  );
};

export default MeetAddSessionsDetail;