import { useEffect } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import useOmeMeetData from "../../../state/omeMeet/UseOmeMeetData";
import useOmeMeetOrgUnitEntryData from "../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData";
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from "../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData";
import useMyMeetsFiltersData from "../../../state/myMeetsFilters/UseMyMeetsFiltersData";
import useMyAthleteMeetEntriesFiltersData from '../../../state/myAthleteMeetEntriesFilters/UseMyAthleteMeetEntriesFiltersData';
import useOmeMeetInvitationsData from "../../../state/omeMeetInvitations/UseOmeMeetInvitationsData";

import useSelectOrgUnitData from "../../../../common/state/selectOrgUnit/UseSelectOrgUnitData";
import useSecurityData from "../../../../common/state/security/UseSecurityData";
import useMeetData from "../../../../common/state/meet/UseMeetData";

const TEAM_ENTRY_TAXONOMIES = ['OUEntryDetail'];
const ATHLETE_ENTRY_TAXONOMIES = ['AthleteEntryDetail'];
const SCOPE = 'OnlineMeetEntry';

const useOmeEntryLoading = (teamRedirectNavLink, athleteRedirectNavLink) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState, getMeet } = useMeetData();
  const { omeMeetState, getOmeMeet } = useOmeMeetData();
  const { omeMeetInvitationsState, getOmeMeetInvitations } = useOmeMeetInvitationsData();
  const { omeMeetOrgUnitEntryState, getOmeMeetOrgUnitEntryById } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
  const { setMyMeetsFiltersState, myMeetsFiltersState } = useMyMeetsFiltersData();
  const { setMyAthleteMeetEntriesFiltersState, myAthleteMeetEntriesFiltersState } = useMyAthleteMeetEntriesFiltersData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();

  useEffect(() => {
    if (location.state?.selectOrgUnitState) {
      setSelectOrgUnitState({
        ...selectOrgUnitState,
        orgUnitId: location.state?.selectOrgUnitState.orgUnitId,
        orgUnitName: location.state?.selectOrgUnitState.orgUnitName,
        showBackToSelection: location.state?.selectOrgUnitState.showBackToSelection
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.omeMeetHostFiltersState || location.state?.omeMeetTeamEntryFiltersState) {
      setMyMeetsFiltersState({
        ...myMeetsFiltersState,
        omeMeetHostFiltersState: location.state?.omeMeetHostFiltersState,
        omeMeetTeamEntryFiltersState: location.state?.omeMeetTeamEntryFiltersState
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.omeMeetAthleteEntryFiltersState) {
      setMyAthleteMeetEntriesFiltersState({
        ...myAthleteMeetEntriesFiltersState,
        omeMeetAthleteEntryFiltersState: location.state?.omeMeetAthleteEntryFiltersState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.teamEntry && !location.state?.athleteEntry) {
      if (location.state.teamEntry.omeMeetId > 0 && omeMeetState.isObjLoaded !== true) {
        getOmeMeet(location.state.teamEntry.omeMeetId);
        getOmeMeetInvitations(location.state.teamEntry.omeMeetId);
      }
      if (location.state.teamEntry.omeMeetId > 0 && location.state.teamEntry.meetId > 0 && meetState.isObjLoaded !== true) {
        getMeet(location.state.teamEntry.meetId);
      }
      if (location.state.teamEntry.omeMeetId > 0 && location.state.teamEntry.omeMeetOrgUnitEntryId > 0 && omeMeetOrgUnitEntryState.isObjLoaded !== true) {
        getOmeMeetOrgUnitEntryById(location.state.teamEntry.omeMeetId, location.state.teamEntry.omeMeetOrgUnitEntryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, omeMeetState.isObjLoaded, omeMeetState.selectedMeet, omeMeetOrgUnitEntryState.isObjLoaded]);

  useEffect(() => {
    if (location.state?.athleteEntry && location.state.meetInfo && location.state.teamEntry) {
      if (location.state.meetInfo.omeMeetId > 0 && omeMeetState.isObjLoaded !== true) {
        getOmeMeet(location.state.meetInfo.omeMeetId);
        getOmeMeetInvitations(location.state.meetInfo.omeMeetId);
      }
      if (location.state.meetInfo.omeMeetId > 0 && location.state.teamEntry.omeMeetOrgUnitEntryId > 0 && omeMeetOrgUnitEntryState.isObjLoaded !== true) {
        getOmeMeetOrgUnitEntryById(location.state.meetInfo.omeMeetId, location.state.teamEntry.omeMeetOrgUnitEntryId);
      }
      if (location.state.meetInfo.omeMeetId > 0 && location.state.meetInfo.meetId > 0 && meetState.isObjLoaded !== true) {
        getMeet(location.state.meetInfo.meetId);
      }
      if (location.state.meetInfo.omeMeetId > 0 && location.state.teamEntry.omeMeetOrgUnitEntryId > 0 && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded !== true) {
        getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(location.state.athleteEntry.orgUnitAthleteEntryId, location.state.teamEntry.omeMeetOrgUnitEntryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, omeMeetState.isObjLoaded, omeMeetState.selectedMeet, omeMeetOrgUnitEntryState.isObjLoaded, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded]);


  useEffect(() => {
    if (contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE) {
      if (location.state?.athleteEntry && location.state?.meetInfo) {
        getContextSecurity(location.state.meetInfo?.omeMeetId, ATHLETE_ENTRY_TAXONOMIES, SCOPE);
      } else if (location.state?.teamEntry && !location.state?.athleteEntry) {
        getContextSecurity(location.state.teamEntry?.omeMeetId, TEAM_ENTRY_TAXONOMIES, SCOPE);
      }
    }
    if (contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE) {
      //edit team entry 
      if (location.state?.teamEntry && !location.state?.athleteEntry) {
        if (meetState.isObjLoaded === true && meetState.objData.meetId === location.state.teamEntry.meetId &&
          location.state.teamEntry.omeMeetId > 0 && omeMeetState.objData?.omeMeetId === location.state.teamEntry.omeMeetId && omeMeetInvitationsState.isArrayLoaded === true &&
          omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId === location.state.teamEntry.omeMeetOrgUnitEntryId) {
          const isReadOnly = location.state.teamEntry.isReadOnly ?? true;
          navigate(teamRedirectNavLink, { state: { selectOrgUnitState: selectOrgUnitState, isReadOnly: isReadOnly } });
        }
      }
      //edit athlete entry
      else if (location.state?.athleteEntry && location.state?.meetInfo && location.state?.teamEntry) {
        if (meetState.isObjLoaded === true && meetState.objData.meetId === location.state.meetInfo.meetId &&
          location.state.meetInfo.omeMeetId > 0 && omeMeetState.objData?.omeMeetId === location.state.meetInfo.omeMeetId && omeMeetInvitationsState.isArrayLoaded === true &&
          location.state.teamEntry.omeMeetOrgUnitEntryId > 0 && omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId === location.state.teamEntry.omeMeetOrgUnitEntryId &&
          omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded === true && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.orgUnitAthleteEntryId === location.state.athleteEntry.orgUnitAthleteEntryId) {
          const isReadOnly = location.state.teamEntry.isReadOnly ?? true;
          navigate(athleteRedirectNavLink, {
            state: {
              orgUnitRegistrationStartDate: location.state.teamEntry.orgUnitRegistrationStartDate || '',
              orgUnitRegistrationEndDate: location.state.teamEntry.orgUnitRegistrationEndDate || '',
              orgUnitName: `${location.state.teamEntry.orgUnitName || ''} ${location.state.teamEntry.orgUnitName === 'Unattached' ? location.state.teamEntry.parentOrgUnitName ? '- ' + location.state.teamEntry.parentOrgUnitName : '' : ''} ${location.state.teamEntry.parentOrgUnitCode ? '(' + location.state.teamEntry.parentOrgUnitCode + ')' : ''}`,
              selectOrgUnitState: selectOrgUnitState,
              isReadOnly: isReadOnly
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState, meetState.objData, omeMeetState.objData, omeMeetOrgUnitEntryState.isObjLoaded, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isObjLoaded, omeMeetInvitationsState.isArrayLoaded]);

  return {
    isLoading: true
  };
};

export default useOmeEntryLoading;