import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../utils/NavLinks";

import useSelectionsLoadingModalData from "../../../../state/selections/selectionsLoadingModal/UseSelectionsLoadingModalData";
import useSelectionsData from "../../../../state/selections/UseSelectionsData";

const useSelectionsLoadingModal = () => {
  const navigate = useNavigate();
  const { selectionsState } = useSelectionsData();
  const { isContextError } = useSelectionsLoadingModalData();

  const onReloadContextClicked = () => {
    const selectionMeetId = selectionsState.objData?.selectionMeetId

    if (selectionMeetId > 0) {
      navigate(`${NavLinks.SELECTIONS_BY_ID}/${selectionMeetId}`, { replace: true });
    } else {
      navigate(NavLinks.SELECTIONS_NEW_SELECTION_SEARCH);
    }
  };

  const onBackToSearchClicked = () => {
    navigate(NavLinks.SELECTIONS_NEW_SELECTION_SEARCH);
  };

  return {
    selectionMeetId: selectionsState.objData?.selectionMeetId || 0,
    isContextError,
    onReloadContextClicked,
    onBackToSearchClicked
  };
};

export default useSelectionsLoadingModal;