import React, { Fragment } from 'react';

import useMeetAddStaff from './UseMeetAddStaff';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
// import SearchMemberPopup from "../../../../common/components/searches/searchMember/SearchMemberPopup";
import SearchMemberWithLscPopup from '../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';

import Constants from '../../../../common/utils/Constants';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const MeetAddStaff = () => {
  const {
    meetState,
    isLoading,
    isSaving,
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    handleSubmit,
    onBackClicked
  } = useMeetAddStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Staff</Headings.H3>
        </div>
      </div>
      <div className={"row usas-extra-bottom-margin"}>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Name:</b> {meetState?.objData?.meetName || ''}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {meetState?.objData?.startDate ? formatDate(meetState?.objData?.startDate) : ''} - {meetState?.objData?.endDate ? formatDate(meetState?.objData?.endDate) : ''} </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup
              label="Meet Director Member Id*"
              memberIdName="meetDirectorMemberId"
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup
              label="Meet Referee Member Id*"
              memberIdName="meetRefereeMemberId"
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup
              label="Meet Contact Member Id*"
              memberIdName="meetContactMemberId"
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form >
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default MeetAddStaff;