import React, { Fragment } from 'react';

import useLaneSheetTopHeaderNotes from './UseLaneSheetTopHeaderNotes';

import LaneSheetMeetTopHeaderNotesGrid from '../components/grids/LaneSheetMeetTopHeadersNotesGrid';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LaneSheetTopHeaderNotes = () => {
  const {
    isSaving,
    isLoading,
    gridData,
    deleteModalState,
    onAddTopHeaderInfoClicked,
    onDeleteTopHeaderInfoClicked,
    onDeleteModalDeleteClicked,
    onDeleteModalCancelClicked
  } = useLaneSheetTopHeaderNotes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Top Header Notes For Lane Sheet</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddTopHeaderInfoClicked}>Add New Row</ActionIntraPageButton>
        </div>
      </div>
      <LaneSheetMeetTopHeaderNotesGrid
        isLoading={isLoading}
        gridData={gridData}
        onDeleteClicked={onDeleteTopHeaderInfoClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Lane Sheet Top Header Info?'}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className='col-xs-12'><strong>Record List Name: </strong>{deleteModalState.topHeaderInfo?.recordListName || ''}</p>
            <p className='col-xs-12'><strong>Name to Show: </strong>{deleteModalState.topHeaderInfo?.nameToShow || ''}</p>
            <p className='col-xs-12'><strong>Display Order: </strong>{deleteModalState.topHeaderInfo?.displayOrder || ''}</p>
            <p className='col-xs-12'>Are you sure you want to delete this Lane Sheet Top Header Information?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalDeleteClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LaneSheetTopHeaderNotes;