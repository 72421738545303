import React, { Fragment } from 'react';

import useTeamEntry from './UseTeamEntry';

import TeamEntryGrid from './TeamEntryGrid';
import DeleteOmeModalInfo from '../meetHost/DeleteOmeModalInfo';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import ReportPeriodDropdown from '../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const TeamEntry = () => {
  const {
    formState,
    errorState,
    modalState,
    omeMeetOrgUnitEntryState,
    onFormValueChanged,
    onValueTextPairChanged,
    omeMeetTeamEntryState,
    USAS_ORGANIZATION_ID,
    onAddEntryClicked,
    onEditEntryClicked,
    onDeleteEntryClicked,
    onFilterClicked,
    onClearFilterClicked,
    onModalCanceled,
    displayLoadingPopUp,
    omeMeetTeamEntryStatusState,
    onDeleteTeamEntry,
    onEnterANewMeetButtonClicked,
    onViewEntryClicked
  } = useTeamEntry();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Entry</Headings.H3>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <Input
              label="Meet Name"
              name="meetName"
              value={formState.meetName}
              error={errorState.meetName}
              message={errorState.meetName}
              onChange={(value) => { onFormValueChanged('meetName', value); }}
            />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <Dropdown
              label="Team Entry Status"
              name="teamEntryStatusId"
              options={omeMeetTeamEntryStatusState}
              value={formState.teamEntryStatusId}
              error={errorState.teamEntryStatusId}
              message={errorState.teamEntryStatusId}
              isLoading={false}
              onChange={(value) => { onFormValueChanged('teamEntryStatusId', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <ReportPeriodDropdown
              label="Registration Year"
              name="reportPeriodId"
              value={formState.reportPeriodId}
              error={errorState.reportPeriodId}
              message={errorState.reportPeriodId}
              onChange={(newValue, newValueLabel) =>
                onValueTextPairChanged(newValue, 'reportPeriodId', newValueLabel, 'reportPeriodName')}
              organizationId={USAS_ORGANIZATION_ID}
              defaultOption={true}
              currentAndFutureOptionsOnly={false} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <YesNoSwitch
              label="Future Meet?"
              name="isCurrent"
              checked={formState.isCurrent}
              error={errorState.isCurrent}
              message={errorState.isCurrent}
              onChange={(value) => { onFormValueChanged('isCurrent', value) }} />
          </div>
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </div>
      </form>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        {omeMeetTeamEntryState.arrayData?.length > 15 && omeMeetTeamEntryState.isArrayLoading === false &&
          <div className='visible-md visible-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton onClick={onEnterANewMeetButtonClicked}>Search For a New Meet to Enter</ActionIntraPageButton>
            </div>
          </div>
        }
        {omeMeetTeamEntryState.arrayData?.length > 0 && omeMeetTeamEntryState.isArrayLoading === false &&
          <div className='hidden-md hidden-lg'>
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton onClick={onEnterANewMeetButtonClicked}>Search For a New Meet to Enter</ActionIntraPageButton>
            </div>
          </div>
        }
        <div className='col-xs-12'>
          <TeamEntryGrid
            state={omeMeetTeamEntryState}
            onAdd={onAddEntryClicked}
            onEdit={onEditEntryClicked}
            onDeleteClicked={onDeleteEntryClicked}
            onView={onViewEntryClicked}
          />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12 usas-extra-top-margin'>
          <ActionIntraPageButton onClick={onEnterANewMeetButtonClicked}>Search For a New Meet to Enter</ActionIntraPageButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className='col-xs-12'>
            <DeleteOmeModalInfo modalState={modalState} />
            <p><strong>Team Name:</strong> {modalState?.teamName}</p>
            <p><strong>Team Entry Status:</strong> {modalState?.teamEntryStatus}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteTeamEntry(e, modalState.omeMeetId, modalState.omeMeetOrgUnitEntryId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={displayLoadingPopUp}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitEntryState.isSaving}
      />
    </Fragment>
  );
};

export default TeamEntry;