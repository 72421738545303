import React from 'react';

import useRelayAthleteMeetEventCombobox from './UseRelayAthleteMeetEventCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';

import global from '../../../../common/components/GlobalStyle.module.css';

const RelayAthleteMeetEventCombobox = ({ label, name, valueToMatch, error, message, onChange, meetEventId, omeMeetOrgUnitEntryId }) => {
  const { relayAthleteMeetEventState } = useRelayAthleteMeetEventCombobox(meetEventId, omeMeetOrgUnitEntryId);

  return relayAthleteMeetEventState.message
    ? <span className={global.LoadingMsg}>{relayAthleteMeetEventState.message}</span>
    :
    <Combobox
      label={label}
      name={name}
      isLoading={relayAthleteMeetEventState.isArrayLoading}
      items={relayAthleteMeetEventState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default RelayAthleteMeetEventCombobox; 