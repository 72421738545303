import { useEffect } from 'react';

import validate from './TimeStandardFormValidation';

import useSelectionsTimeStandardData from '../../../../state/selectionsTimeStandard/UseSelectionsTimeStandardData';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  timeStandardId: Constants.DEFAULT_ID,
  timeStandardName: '',
  sortPriority: '',
  isIndividualBaseStandard: false,
  isRelayBaseStandard: false,
  selectionMeetTimeStandard: []
};

const useTimeStandardForm = (onSubmitFormCallback, selectionMeetTimeStandard, editTimeStandardId) => {
  const { selectionsTimeStandardState } = useSelectionsTimeStandardData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (selectionsTimeStandardState.areItemsLoaded === true) {
      if (editTimeStandardId > 0 && Array.isArray(selectionMeetTimeStandard)) {
        const targetTimeStandard = selectionMeetTimeStandard.find(x => x.timeStandardId === editTimeStandardId);

        if (targetTimeStandard) {
          setFormState({
            ...formState,
            timeStandardId: targetTimeStandard.timeStandardId || Constants.DEFAULT_ID,
            timeStandardName: targetTimeStandard.timeStandardName || '',
            sortPriority: targetTimeStandard.sortPriority || '',
            isIndividualBaseStandard: targetTimeStandard.isIndividualBaseStandard === true ? true : false,
            isRelayBaseStandard: targetTimeStandard.isRelayBaseStandard === true ? true : false,
            selectionMeetTimeStandard
          });
        } else {
          const firstTimeStandard = selectionsTimeStandardState.items[0];

          setFormState({
            ...formState,
            selectionMeetTimeStandard,
            timeStandardId: firstTimeStandard?.id || Constants.DEFAULT_ID,
            timeStandardName: firstTimeStandard?.name || ''
          });
        }
      } else if (Array.isArray(selectionMeetTimeStandard)) {
        const firstTimeStandard = selectionsTimeStandardState.items[0];

        setFormState({
          ...formState,
          selectionMeetTimeStandard,
          timeStandardId: firstTimeStandard?.id || Constants.DEFAULT_ID,
          timeStandardName: firstTimeStandard?.name || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMeetTimeStandard, editTimeStandardId, selectionsTimeStandardState.areItemsLoaded]);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useTimeStandardForm;