import React, { Fragment } from 'react';

import useOmeMeetInfo from './UseOmeMeetInfo';

import OmeMeetEntryCourseGrid from './OmeMeetEntryCourseGrid';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import Input from '../../../../../common/components/inputs/Input';
import TimeZoneDropdown from '../../../../../common/components/dropdowns/timeZoneDropdown/TimeZoneDropdown';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import DateTimePicker from '../../../../../common/components/datepickers/DateTimePicker';
import ToolTip from '../../../../../common/components/tooltips/ToolTip';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';
import { formatDate, formatDateTimeTwelveHourClock } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

const OmeMeetInfo = () => {
  const {
    omeMeetState,
    omeMeetDateValidationState,
    dateTimeState,
    orgUnitState,
    gridState,
    formState,
    errorState,
    modalState,
    onModalCanceled,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onSaveAndExitButtonClicked,
    onAddCoursePriorityClicked,
    onEditCoursePriorityClicked,
    onDeleteCoursePriorityClicked,
    onDeleteCoursePriority,
    allowAllEdits,
    allowSomeEdits,
    secondaryRepDeleteModalState,
    onSecondaryRepDeleteModalCanceled,
    handleSave
  } = useOmeMeetInfo();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_INFO}
        onNextButtonClicked={onNextButtonClicked}
        onSaveAndExitButtonClicked={onSaveAndExitButtonClicked}
        errorMessage={errorState.error}>
        {allowAllEdits() === true ?
          <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='row usas-extra-bottom-margin'>
                <div className='col-xs-12 col-sm-6 usas-extra-bottom-margin'>
                  <TimeZoneDropdown
                    label={<Fragment>Meet Time Zone* <span><ToolTip label="Time Zone used for the meet"></ToolTip></span></Fragment>}
                    name="timeZoneValue"
                    value={formState.timeZoneValue}
                    error={errorState.timeZoneValue}
                    message={errorState.timeZoneValue}
                    onChange={(value, newValueLabel, e) => {
                      e && e.target &&
                        onValueTextPairChanged(value, 'timeZoneValue', newValueLabel, 'timeZoneId')
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <DateTimePicker
                    label={<Fragment>Meet Publish Date* <span><ToolTip label="Date and time when the meet packet becomes viewable (if the host provides a Meet Packet URL)"></ToolTip></span></Fragment>}
                    name="publishDate"
                    value={formState.publishDate}
                    error={errorState.publishDate}
                    message={errorState.publishDate}
                    onChange={(value) => { onFormValueChanged('publishDate', value); }}
                    countOfYears={9}
                    startYearOffset={-4} />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <DatePicker
                    label={<Fragment>Athlete Age As Of Date* <span><ToolTip label="Date used to calculate athlete age at the meet"></ToolTip></span></Fragment>}
                    name="athleteAgeAsOfDate"
                    value={formState.athleteAgeAsOfDate}
                    error={errorState.athleteAgeAsOfDate}
                    message={errorState.athleteAgeAsOfDate}
                    onChange={(value) => { onFormValueChanged('athleteAgeAsOfDate', value); }}
                    countOfYears={9}
                    startYearOffset={-4} />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <DateTimePicker
                    label={<Fragment>Meet Entry Start Date* <span><ToolTip label="Date and time when teams can begin to submit their entries"></ToolTip></span></Fragment>}
                    name="meetEntryStartDate"
                    value={formState.meetEntryStartDate}
                    error={errorState.meetEntryStartDate}
                    message={errorState.meetEntryStartDate}
                    onChange={(value) => { onFormValueChanged('meetEntryStartDate', value); }}
                    countOfYears={9}
                    startYearOffset={-4} />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <DateTimePicker
                    label={<Fragment>Meet Entry End Date* <span><ToolTip label="Deadline for teams to submit their entries"></ToolTip></span></Fragment>}
                    name="meetEntryEndDate"
                    value={formState.meetEntryEndDate}
                    error={errorState.meetEntryEndDate}
                    message={errorState.meetEntryEndDate}
                    onChange={(value) => { onFormValueChanged('meetEntryEndDate', value); }}
                    countOfYears={9}
                    startYearOffset={-4} />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <DateTimePicker
                    label={<Fragment>Meet Entry Change Deadline* <span><ToolTip label="Deadline for entered teams to request entry changes"></ToolTip></span></Fragment>}
                    name="meetEntryChangeDeadline"
                    value={formState.meetEntryChangeDeadline}
                    error={errorState.meetEntryChangeDeadline}
                    message={errorState.meetEntryChangeDeadline}
                    onChange={(value) => { onFormValueChanged('meetEntryChangeDeadline', value); }}
                    countOfYears={9}
                    startYearOffset={-4} />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <DatePicker
                    label={<Fragment>Meet Results Publish Date <span><ToolTip label="Date that the meet host will publish meet results"></ToolTip></span></Fragment>}
                    name="resultsPublishDate"
                    value={formState.resultsPublishDate}
                    error={errorState.resultsPublishDate}
                    message={errorState.resultsPublishDate}
                    onChange={(value) => { onFormValueChanged('resultsPublishDate', value); }}
                    countOfYears={9}
                    startYearOffset={-4} />
                </div>
              </div>
              <div className='row usas-extra-top-margin'>
                <div className="usas-extra-top-margin col-xs-12 col-sm-12 col-md-6">
                  <Input
                    label={<Fragment>Meet Packet URL <span><ToolTip label="Link to the meet packet"></ToolTip></span></Fragment>}
                    name="meetPacketUrl"
                    value={formState.meetPacketUrl}
                    error={errorState.meetPacketUrl}
                    message={errorState.meetPacketUrl}
                    onChange={(value) => { onFormValueChanged('meetPacketUrl', value) }} />
                </div>
                <div className="usas-extra-top-margin col-xs-12 col-sm-12 col-md-6">
                  <YesNoSwitch
                    label='Allow Secondary Representation for Athletes?*'
                    name='allowSecondaryOrgUnitRepresentation'
                    checked={formState.allowSecondaryOrgUnitRepresentation}
                    error={errorState.allowSecondaryOrgUnitRepresentation}
                    message={errorState.allowSecondaryOrgUnitRepresentation}
                    onChange={(value) => { onFormValueChanged('allowSecondaryOrgUnitRepresentation', value); }} />
                </div>
              </div>
            </form>
            <div className='row usas-extra-top-margin'>
              <div className='col-xs-12 usas-extra-top-margin'>
                <ActionIntraPageButton type="button" onClick={(e) => onAddCoursePriorityClicked(e)}>Add Course Priority</ActionIntraPageButton>
              </div>
              <div className='col-xs-12'>
                <OmeMeetEntryCourseGrid
                  omeMeetState={omeMeetState}
                  gridState={gridState}
                  onEdit={onEditCoursePriorityClicked}
                  onDelete={onDeleteCoursePriorityClicked}
                />
              </div>
            </div>
          </Fragment> :
          <Fragment>
            {allowSomeEdits() === true ?
              <Fragment>
                <form onSubmit={handleSubmit}>
                  <div className='row usas-extra-bottom-margin'>
                    <div className='col-xs-12 col-sm-6 usas-extra-bottom-margin'>
                      <TimeZoneDropdown
                        label={<Fragment>Meet Time Zone* <span><ToolTip label="Time Zone used for the meet"></ToolTip></span></Fragment>}
                        name="timeZoneValue"
                        value={formState.timeZoneValue}
                        error={errorState.timeZoneValue}
                        message={errorState.timeZoneValue}
                        onChange={(value, newValueLabel, e) => {
                          e && e.target &&
                            onValueTextPairChanged(value, 'timeZoneValue', newValueLabel, 'timeZoneId')
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <DateTimePicker
                        label={<Fragment>Meet Publish Date* <span><ToolTip label="Date and time when the meet packet becomes viewable (if the host provides a Meet Packet URL)"></ToolTip></span></Fragment>}
                        name="publishDate"
                        value={formState.publishDate}
                        error={errorState.publishDate}
                        message={errorState.publishDate}
                        onChange={(value) => { onFormValueChanged('publishDate', value); }}
                        countOfYears={9}
                        startYearOffset={-4} />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <DatePicker
                        label={<Fragment>Athlete Age As Of Date* <span><ToolTip label="Date used to calculate athlete age at the meet"></ToolTip></span></Fragment>}
                        name="athleteAgeAsOfDate"
                        value={formState.athleteAgeAsOfDate}
                        error={errorState.athleteAgeAsOfDate}
                        message={errorState.athleteAgeAsOfDate}
                        onChange={(value) => { onFormValueChanged('athleteAgeAsOfDate', value); }}
                        countOfYears={9}
                        startYearOffset={-4} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <DateTimePicker
                        label={<Fragment>Meet Entry Start Date* <span><ToolTip label="Date and time when teams can begin to submit their entries"></ToolTip></span></Fragment>}
                        name="meetEntryStartDate"
                        value={formState.meetEntryStartDate}
                        error={errorState.meetEntryStartDate}
                        message={errorState.meetEntryStartDate}
                        onChange={(value) => { onFormValueChanged('meetEntryStartDate', value); }}
                        countOfYears={9}
                        startYearOffset={-4} />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <DateTimePicker
                        label={<Fragment>Meet Entry End Date* <span><ToolTip label="Deadline for teams to submit their entries"></ToolTip></span></Fragment>}
                        name="meetEntryEndDate"
                        value={formState.meetEntryEndDate}
                        error={errorState.meetEntryEndDate}
                        message={errorState.meetEntryEndDate}
                        onChange={(value) => { onFormValueChanged('meetEntryEndDate', value); }}
                        countOfYears={9}
                        startYearOffset={-4} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <DateTimePicker
                        label={<Fragment>Meet Entry Change Deadline* <span><ToolTip label="Deadline for entered teams to request entry changes"></ToolTip></span></Fragment>}
                        name="meetEntryChangeDeadline"
                        value={formState.meetEntryChangeDeadline}
                        error={errorState.meetEntryChangeDeadline}
                        message={errorState.meetEntryChangeDeadline}
                        onChange={(value) => { onFormValueChanged('meetEntryChangeDeadline', value); }}
                        countOfYears={9}
                        startYearOffset={-4} />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <DatePicker
                        label={<Fragment>Meet Results Publish Date <span><ToolTip label="Date that the meet host will publish meet results"></ToolTip></span></Fragment>}
                        name="resultsPublishDate"
                        value={formState.resultsPublishDate}
                        error={errorState.resultsPublishDate}
                        message={errorState.resultsPublishDate}
                        onChange={(value) => { onFormValueChanged('resultsPublishDate', value); }}
                        countOfYears={9}
                        startYearOffset={-4} />
                    </div>
                  </div>
                  <div className='row usas-extra-top-margin'>
                    <div className="usas-extra-top-margin col-xs-12 col-sm-12 col-md-6">
                      <Input
                        label={<Fragment>Meet Packet URL <span><ToolTip label="Link to the meet packet"></ToolTip></span></Fragment>}
                        name="meetPacketUrl"
                        value={formState.meetPacketUrl}
                        error={errorState.meetPacketUrl}
                        message={errorState.meetPacketUrl}
                        onChange={(value) => { onFormValueChanged('meetPacketUrl', value) }} />
                    </div>
                    <div className="usas-extra-top-margin col-xs-12 col-sm-12 col-md-6">
                      <YesNoSwitch
                        label='Allow Secondary Representation for Athletes?*'
                        name='allowSecondaryOrgUnitRepresentation'
                        checked={formState.allowSecondaryOrgUnitRepresentation}
                        error={errorState.allowSecondaryOrgUnitRepresentation}
                        message={errorState.allowSecondaryOrgUnitRepresentation}
                        onChange={(value) => { onFormValueChanged('allowSecondaryOrgUnitRepresentation', value); }}
                        disabled={true} />
                    </div>
                  </div>
                </form>
                <div className='row usas-extra-top-margin'>
                  <div className='col-xs-12'>
                    <Headings.H6>Course Priorities</Headings.H6>
                  </div>
                  <div className='col-xs-12'>
                    <OmeMeetEntryCourseGrid
                      omeMeetState={omeMeetState}
                      gridState={gridState}
                    />
                  </div>
                </div>
              </Fragment>
              : <Fragment>
                <form onSubmit={handleSubmit}>
                  <div className='row usas-extra-bottom-margin'>
                    <div className='col-xs-12 col-sm-6 usas-extra-bottom-margin'>
                      <ReadOnly
                        label={<Fragment>Meet Time Zone* <span><ToolTip label="Time Zone used for the meet"></ToolTip></span></Fragment>}
                        name="timeZoneValue"
                        value={formState.timeZoneValue}
                      />
                    </div>
                  </div>
                  <div className='row usas-extra-bottom-margin'>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Meet Publish Date* <span><ToolTip label="Date and time when teams can indicate that they intend to enter the meet, and can open event entry to their members"></ToolTip></span></Fragment>}
                        name="publishDate"
                        value={formatDateTimeTwelveHourClock(omeMeetState.objData.publishDate)}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Athlete Age As Of Date* <span><ToolTip label="Date used to calculate athlete age at the meet"></ToolTip></span></Fragment>}
                        name="athleteAgeAsOfDate"
                        value={formatDate(formState.athleteAgeAsOfDate)}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Meet Entry Start Date* <span><ToolTip label="Date and time when teams can begin to submit their entries"></ToolTip></span></Fragment>}
                        name="meetEntryStartDate"
                        value={formatDateTimeTwelveHourClock(omeMeetState.objData.meetEntryStartDate)}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Meet Entry End Date* <span><ToolTip label="Deadline for teams to submit their entries"></ToolTip></span></Fragment>}
                        name="meetEntryEndDate"
                        value={formatDateTimeTwelveHourClock(omeMeetState.objData.meetEntryEndDate)}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Meet Entry Change Deadline* <span><ToolTip label="Deadline for entered teams to request entry changes"></ToolTip></span></Fragment>}
                        name="meetEntryChangeDeadline"
                        value={formatDateTimeTwelveHourClock(omeMeetState.objData.meetEntryChangeDeadline)}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReadOnly
                        label={<Fragment>Meet Results Publish Date <span><ToolTip label="Date that the meet host will publish meet results"></ToolTip></span></Fragment>}
                        name="resultsPublishDate"
                        value={formState.resultsPublishDate !== Constants.BLANK_DATE_STRING ? formatDate(formState.resultsPublishDate) : ''} />
                    </div>
                    <div className='usas-extra-top-margin col-xs-12 col-sm-12 col-md-6'>
                      <div className='usas-extra-top-margin'>
                        <ReadOnly
                          label={<Fragment>Meet Packet URL <span><ToolTip label="Link to the meet packet"></ToolTip></span></Fragment>}
                          name="meetPacketUrl"
                          value={formState.meetPacketUrl} />
                      </div>
                    </div>
                    <div className="usas-extra-top-margin col-xs-12 col-sm-12 col-md-6">
                      <YesNoSwitch
                        label='Allow Secondary Representation for Athletes?*'
                        name='allowSecondaryOrgUnitRepresentation'
                        checked={formState.allowSecondaryOrgUnitRepresentation}
                        error={errorState.allowSecondaryOrgUnitRepresentation}
                        message={errorState.allowSecondaryOrgUnitRepresentation}
                        onChange={(value) => { onFormValueChanged('allowSecondaryOrgUnitRepresentation', value); }}
                        disabled={true} />
                    </div>
                  </div>
                </form>
                <div className='row usas-extra-top-margin'>
                  <div className='col-xs-12'>
                    <Headings.H6>Course Priorities</Headings.H6>
                  </div>
                  <div className='col-xs-12'>
                    <OmeMeetEntryCourseGrid
                      omeMeetState={omeMeetState}
                      gridState={gridState}
                    />
                  </div>
                </div>
              </Fragment>}
          </Fragment>
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>Course:</b>&nbsp;{modalState.courseCode}</p>
              <p><b>Priority:</b>&nbsp;{modalState.priority}</p>
              {modalState.showAdditionalWarningMsg === true &&
                <p className={global.Multiline}>{modalState.additionalWarningMsg}</p>}
              <p><b>Are you sure you would like to save any meet time zone, date, meet packet URL, and secondary representation changes and make the {modalState.showAdditionalWarningMsg === true ? 'associated deletes' : 'selected delete'}?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => onDeleteCoursePriority(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={secondaryRepDeleteModalState.modalTitle}
          displayPopUp={secondaryRepDeleteModalState.displayPopUp}
          onModalCanceled={onSecondaryRepDeleteModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p>Changing 'Allow Secondary Representation for Athletes?' from 'Yes' to 'No' will delete all Secondary Representation information from any teams that have started their entries.</p>
              <p><b>Are you sure you would like to save any meet time zone, date, meet packet URL, and secondary representation changes and make the associated deletes?</b></p>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onSecondaryRepDeleteModalCanceled}>Cancel</SecondaryButton>&nbsp;
              <PrimaryButton type="button" onClick={(e) => handleSave(e)}>Save &amp; Delete</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={dateTimeState.isObjLoading || omeMeetDateValidationState.isObjLoading || orgUnitState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate >
    </Fragment >
  );
};

export default OmeMeetInfo;