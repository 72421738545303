import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';
import useOmeMeetOrgUnitEntryTimesData from '../../../../state/omeMeetOrgUnitEntryTimes/UseOmeMeetOrgUnitEntryTimesData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  onlyShowAthletesRequiringEventApproval: false
};

const useOrgUnitAdminRosterEntries = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
    = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitEntryTimesState, putOmeMeetOrgUnitEntryTimes, confirmSaveOmeMeetOrgUnitEntryTimes } = useOmeMeetOrgUnitEntryTimesData();
  const { oUAdminEntryContextState } = useOUAdminEntryContextData();
  const { ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID, ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID } = useEnvironmentVariableData();
  const [state, setState] = useState({ tryGet: false });
  const [gridState, setGridState] = useState({ gridData: [], showUpdateButton: false });
  const { handleSubmit, formState, errorState, onFormValueChanged, setIsDirty } = UseForm(INITIAL_FORM_STATE, submitFormCallback, () => { });

  const onEditAthleteClicked = (e, athlete) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES_DETAIL, { state: { athlete } });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES_DETAIL, { state: { athlete } });
    }
  };

  const onUpdateAthleteTimes = (e, athlete) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    putOmeMeetOrgUnitEntryTimes(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, athlete.orgUnitAthleteEntryId);
    setState({ ...state, tryGet: true });
  };

  const onUpdateRosterTimes = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    putOmeMeetOrgUnitEntryTimes(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId);
    setState({ ...state, tryGet: true });
  };

  function submitFormCallback(formState) {
    let filteredGridData = [];
    let showUpdateButton = false;
    const athleteEntryIndividualRosterEntryArrayCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.arrayData));
    if (formState.onlyShowAthletesRequiringEventApproval === true) {
      filteredGridData = athleteEntryIndividualRosterEntryArrayCopy.filter((athlete) => athlete.status === Constants.WORKFLOW_STATUS_EVENT_APPROVAL_REQUIRED)
    }
    else if (formState.onlyShowAthletesRequiringEventApproval === false) {
      filteredGridData = athleteEntryIndividualRosterEntryArrayCopy;
    }

    if (filteredGridData?.find(athlete => athlete?.events?.length > 0)) {
      if (omeMeetState.objData?.omeMeetPrice?.length > 0) {
        const hasCostPerAthleteIndividualEventEntry = omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        const hasCostPerAthleteIndividualBonusEventEntry = omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount !== (null || undefined) ? true : false;
        if (hasCostPerAthleteIndividualEventEntry === true && hasCostPerAthleteIndividualBonusEventEntry === true) {
          //if Individual Event Cost equals Bonus Event Cost, then show the update times button
          if (omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount ===
            omeMeetState.objData?.omeMeetPrice.find(x => x.omeFeeTypeId === ATHLETE_INDIVIDUAL_BONUS_EVENT_ENTRY_OME_FEE_TYPE_ID)?.amount) {
            showUpdateButton = true;
          }
        }
        //if we don't have both Individual and Bonus Event prices for the meet, then show the update times button
        else {
          showUpdateButton = true;
        }
      }
      //if we don't have pricing for the meet, then show the update times button
      else {
        showUpdateButton = true;
      }
    }

    setGridState({ ...gridState, gridData: filteredGridData, showUpdateButton: showUpdateButton });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isSaved === true) {
      confirmSaveOmeMeetOrgUnitEntryTimes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isSaved])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isSaving === false && omeMeetOrgUnitEntryTimesState.isSaving === false) {
      if ((omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded === false || omeMeetOrgUnitAthleteEntryState.isArrayLoaded === false || state.tryGet === true) &&
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false && omeMeetOrgUnitAthleteEntryState.isArrayLoading === false) {
        getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        //Make sure to update roster whenever roster entries changes
        getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitEntryTimesState, state.tryGet])

  useEffect(() => {
    if (omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded === true &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
      (formState.onlyShowAthletesRequiringEventApproval === true || formState.onlyShowAthletesRequiringEventApproval === false)) {
      setIsDirty(true);
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading, formState.onlyShowAthletesRequiringEventApproval])

  return {
    oUAdminEntryContextState,
    omeMeetOrgUnitEntryTimesState,
    state,
    location,
    gridState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryState,
    formState,
    errorState,
    onFormValueChanged,
    onEditAthleteClicked,
    onUpdateAthleteTimes,
    onUpdateRosterTimes
  };
}

export default useOrgUnitAdminRosterEntries;