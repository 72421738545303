const NavLinks = {
  LANE_SHEET_LANDING: '/lanesheet/landing',
  LANE_SHEET_MEET_INFO: '/lanesheet/meetinfo',
  LANE_SHEET_MEET_EVENTS: '/lanesheet/meetevents',
  LANE_SHEET_MEET_EVENTS_ADD_PARTICIPANTS: '/lanesheet/meetevents/addparticipants',
  LANE_SHEET_MEET_EVENTS_ADD_RELAY_TEAMS: '/lanesheet/meetevents/addrelayteams',
  LANE_SHEET_MEET_EVENTS_ADD_EVENTS: '/lanesheet/meetevents/addevents',
  LANE_SHEET_TOP_HEADER_INFO: '/lanesheet/topheaderinfo',
  LANE_SHEET_TOP_HEADER_INFO_DETAIL: '/lanesheet/topheaderinfo/detail',
  LANE_SHEET_RIGHT_SIDE_INFO: '/lanesheet/rightsideinfo',
  LANE_SHEET_RIGHT_SIDE_INFO_DETAIL: '/lanesheet/rightsideinfo/detail',
  LANE_SHEET_LANE_SHEETS: '/lanesheet/lanesheets',
  LANE_SHEET_ADD: '/lanesheet/add',
  LANE_SHEET_LANE_SHEETS_ADD: '/lanesheet/lanesheets/add'
};

export default NavLinks;