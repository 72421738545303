import { Fragment } from 'react';

import { formatPlaceString } from '../utils/MeetUtils';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRelayRow = ({ swimmer }) => (
  <Fragment>
    <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>{`${formatPlaceString(swimmer.legNumber)} Leg`}</div>
    <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{swimmer.firstName} {swimmer.lastName} ({swimmer.age})</div>
  </Fragment>
);

const DetailTableSingleRow = ({ org }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{org?.qualifiedOrgUnitCode || <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Organization
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>
          {org?.qualifiedOrgUnitCode || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const GridRowDetailTableRelay = ({ relayOrganization, relayTeam }) => {
  return (
    <div className={global.SmallTableDetailTable}>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          <span className='hidden-xs'>{relayOrganization || <span>&nbsp;</span>}</span>&nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Organization</div>
            <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{relayOrganization || <span>&nbsp;</span>}</div>
            {Array.isArray(relayTeam) && relayTeam.map((swimmer, i) =>
              <DetailTableRelayRow key={i} swimmer={swimmer} />)}
          </div>
        </div>
      </div>
    </div>);
};

const GridRowDetailTableSingle = ({ individualOrganization }) => {
  return (
    <div className={global.SmallTableDetailTable}>
      {individualOrganization && individualOrganization.map((org, i) =>
        <DetailTableSingleRow key={i} org={org} />)}
    </div>
  );
};

const GridRowContent = ({ time }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Time
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {time.time ? formatTimeForDisplay(time.time) : 'Time'}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Competitor
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time?.competitor}
      </div>
      {
        Array.isArray(time.relayTeam) && time.relayTeam.length > 0 ?
          <Fragment /> :
          <Fragment>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Age
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {time?.age}&nbsp;
            </div>
          </Fragment>
      }
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Event Gender
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time?.eventGender}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Date
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatDate(time?.swimDate)}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Event Course
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatDate(time?.eventCourse)}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Session
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time?.session}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Place
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatPlaceString(time.finishPosition)}
      </div>
    </div>
  </div>
);

const GridRowIcons = ({ id, expandedId, onExpandCollapseClicked, onEdit, time }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={onExpandCollapseClicked}>
      {expandedId === id ? <HideIcon /> : <ShowIcon />}
    </button>
    {onEdit &&
      <button className={global.IconButtonMobileMargin}
        type="button"
        onClick={() => onEdit(time.swimTimeId)}>
        <EditIcon />
      </button>}
  </Fragment>
);

const GridRow = ({ time, onEdit, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{time.time ? formatTimeForDisplay(time.time) : 'Time'}</span>&nbsp;
          <GridRowIcons
            id={time.swimTimeId}
            expandedId={expandedId}
            onExpandCollapseClicked={(e) => onClick(e, time.swimTimeId)}
            onEdit={onEdit}
            time={time} />
        </div>
        <GridRowContent time={time} />
      </div>
      {
        Array.isArray(time.individualOrganization) && time.individualOrganization.length > 0 && expandedId === time.swimTimeId
        && <GridRowDetailTableSingle individualOrganization={time.individualOrganization} />
      }
      {
        Array.isArray(time.relayTeam) && time.relayTeam.length > 0 && expandedId === time.swimTimeId
        && <GridRowDetailTableRelay relayOrganization={time.relayOrganization} relayTeam={time.relayTeam} />
      }
    </Fragment>
  );
};

const MeetTimesSmallGrid = ({ gridData, isLoading, onEdit, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>
            Loading...
          </div>
        </div>
      </div>
    ) : Array.isArray(gridData) && gridData.length > 0
      ? gridData.map((time, i) =>
        <GridRow
          key={i}
          time={time}
          onEdit={onEdit}
          expandedId={expandedId}
          onClick={onClick} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              No Times Results
            </div>
          </div>
        </div>
      )
    }
  </div>
);

export default MeetTimesSmallGrid;