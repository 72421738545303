import React, { Fragment } from 'react';

import useOrgUnitAdminPurchases from './UseOrgUnitAdminPurchases';

import OrgUnitAdminPurchasesGrid from './OrgUnitAdminPurchasesGrid';
import OrgUnitAdminAdditionalPurchasesGrid from './OrgUnitAdminAdditionalPurchasesGrid';
import OrgUnitAdminPreviousPurchasesGrid from './OrgUnitAdminPurchasesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminPurchasesWrite = () => {
  const { omeMeetState, omeMeetOrgUnitEntryState, omeMeetOrgUnitEntryContactState, omeMeetOrgUnitEntryPurchaseState, 
    handleSubmit, errorState, onAdditionalPurchaseValueChanged, onToggle, state,
    submitTeamEntryAllowed, atLeastOneEntryContactProvided, atLeastOneAthleteOnRoster,
     allAthleteEventsApproved, numberOfCoachesAllowed, numberOfAthletesAllowed } = useOrgUnitAdminPurchases();

  return (
    <Fragment>
      <div className={omeMeetOrgUnitEntryState.objData.orgUnit?.orgUnitName === 'Unattached' ? global.HideComponent : global.DisplayComponent}>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Requirements to be able to Make Purchases and Submit Entry</Headings.H3>
          </div>
          <div className='col-xs-12 usas-extra-bottom-margin'>
            <p><li>At least one entry contact must be provided {atLeastOneEntryContactProvided() === true ? <b>(Requirement Completed)</b> : <span style={{ color: 'red' }}>(Requirement Not Completed)</span>}</li>
              <li>At least one athlete must be on the roster {atLeastOneAthleteOnRoster() === true ? <b>(Requirement Completed)</b> : <span style={{ color: 'red' }}>(Requirement Not Completed)</span>}</li>
              {omeMeetState.objData.omeMeetEligibility[0].maxNumberOfAthletesForMeet && <li>The number of athletes on the roster is allowed according to the meet's max number of athletes {omeMeetState.objData.omeMeetEligibility[0].allowRelayOnlySwimmer === true ? (omeMeetState.objData.omeMeetEligibility[0].includeRelayOnlySwimmersInMaxNumber === true ? `(${omeMeetState.objData.omeMeetEligibility[0].maxNumberOfAthletesForMeet}, includes Relay Only Swimmers)` : `(${omeMeetState.objData.omeMeetEligibility[0].maxNumberOfAthletesForMeet}, excludes Relay Only Swimmers)`) : `(${omeMeetState.objData.omeMeetEligibility[0].maxNumberOfAthletesForMeet})`} {numberOfAthletesAllowed() === true ? <b>(Requirement Completed)</b> : <span style={{ color: 'red' }}>(Requirement Not Completed)</span>}</li>}
              <li>All athletes on the roster must have a status of 'Events Approved' {omeMeetState.objData.omeMeetEligibility[0].allowRelayOnlySwimmer === true ? "or 'Relay Only Swimmer'" : omeMeetState.objData.omeMeetEligibility[0].allowRelayOnlySwimmer === false ? "('Relay Only Swimmer' status is not allowed)" : ''} {allAthleteEventsApproved() === true ? <b>(Requirement Completed)</b> : <span style={{ color: 'red' }}>(Requirement Not Completed)</span>}</li>
              <li>The number of coaches is allowed according to the meet's coach pass rules {numberOfCoachesAllowed() === true ? <b>(Requirement Completed)</b> : <span style={{ color: 'red' }}>(Requirement Not Completed)</span>}</li>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className={state.showCurrent === true ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12">
                <Headings.H3>Required Purchases</Headings.H3>
              </div>
              <div className="col-xs-12">
                <OrgUnitAdminPurchasesGrid
                  gridData={omeMeetOrgUnitEntryPurchaseState.objData?.currentPurchase?.omeMeetPurchase}
                  isLoading={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
                />
              </div>
              {omeMeetOrgUnitEntryPurchaseState.objData?.currentPurchase?.additionalPurchase?.length > 0 &&
                <Fragment>
                  <div className="col-xs-12 usas-extra-top-margin">
                    <Headings.H3>Additional Purchases</Headings.H3>
                  </div>
                  <div className="col-xs-12">
                    <OrgUnitAdminAdditionalPurchasesGrid
                      gridData={omeMeetOrgUnitEntryPurchaseState.objData?.currentPurchase?.additionalPurchase}
                      isLoading={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
                      errorState={errorState}
                      onAdditionalPurchaseValueChanged={onAdditionalPurchaseValueChanged}
                    />
                  </div>
                </Fragment>}
            </div>
            <div className={state.showCurrent === false ? global.DisplayComponent : global.HideComponent}>
              {omeMeetOrgUnitEntryPurchaseState.objData?.previousPurchases?.length > 0 &&
                <Fragment>
                  <div className="col-xs-12">
                    <Headings.H3>Previous Purchases Summary</Headings.H3>
                  </div>
                  <div className="col-xs-12">
                    <OrgUnitAdminPreviousPurchasesGrid
                      gridData={omeMeetOrgUnitEntryPurchaseState.objData?.previousPurchases}
                      isLoading={omeMeetOrgUnitEntryPurchaseState.isObjLoading}
                    />
                  </div>
                </Fragment>}
            </div>
            {omeMeetOrgUnitEntryPurchaseState.objData?.previousPurchases?.length > 0 &&
              <div className="col-xs-12 usas-extra-top-margin">
                <button className={global.ToggleButton} type="button" onClick={() => onToggle()}>{state.showCurrent === true ? 'Show Previous Purchases Summary' : 'Show Current Purchases'}</button>
              </div>}
            <div className={state.showCurrent === true && state.showPayButton === true && submitTeamEntryAllowed() === true ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                <PrimaryButton type="submit">{omeMeetOrgUnitEntryState.objData.status === Constants.WORKFLOW_STATUS_SUBMITTED ? 'Pay' : 'Pay & Submit'}</PrimaryButton>
              </div>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={omeMeetOrgUnitEntryContactState.isArrayLoading || omeMeetOrgUnitEntryPurchaseState.isObjLoading || state.tryRedirect === true}
        />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetOrgUnitEntryPurchaseState.isSaving}
        />
      </div>
      <div className={omeMeetOrgUnitEntryState.objData.orgUnit?.orgUnitName === 'Unattached' ? global.DisplayComponent : global.HideComponent}>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Purchases</Headings.H3>
            <p>Unattached teams cannot make purchases. Individual unattached athletes will complete their own purchases through the Athlete Entry process.</p>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default OrgUnitAdminPurchasesWrite;