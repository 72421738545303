import { useState, useEffect } from "react";

import validate from './LaneSheetAddMeetInfoFormValidation';

import useLaneSheetMedalsData from "../../../../state/laneSheetMedals/UseLaneSheetMedalsData";

import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useCourseData from "../../../../../common/state/course/UseCourseData";

import useForm from "../../../../../common/utils/UseForm";
import Constants from "../../../../../common/utils/Constants";

const INITIAL_FORM_STATE = {
  careerStats: [],
  laneSheetTemplateId: Constants.DEFAULT_ID,
  laneSheetTemplateName: '',
  numberOfLanes: 0,
  medalCount1Id: Constants.DEFAULT_ID,
  medalCountName1: '',
  medalCount2Id: Constants.DEFAULT_ID,
  medalCountName2: '',
  recordGroupWithinSheetId: Constants.DEFAULT_ID,
  recordWithinSheetName: '',
  reverseLaneOrder: 'false',
  useAmericanDates: '',
  laneSheetMedalCount1Id: Constants.DEFAULT_ID,
  laneSheetMedalCount2Id: Constants.DEFAULT_ID
};

const INITIAL_LANE_ORDER_STATE = [
  { id: 1, value: 'false', label: "Lane 1 at top" },
  { id: 2, value: 'true', label: "Lane 1 at bottom" },
];

const useLaneSheetMeetInfoForm = (onSubmitFormCallback, laneSheetObj) => {
  const { environmentVariableState } = useEnvironmentVariableData();
  const { courseState, getCourses } = useCourseData();
  const { laneSheetMedalsState } = useLaneSheetMedalsData();
  const { formState, errorState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormData, resetForm
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [laneOrderState] = useState(INITIAL_LANE_ORDER_STATE);
  const [careerStatCourseOptions, setCareerStatCourseOptions] = useState([]);

  useEffect(() => {
    if (laneSheetObj && courseState.isLoaded === true && careerStatCourseOptions.length > 0
      && laneSheetMedalsState.areOptionsLoaded === true) {
      const localMedalCount1Id = laneSheetObj.medalCount?.find(x => x.displayOrder === 1)?.meetClassificationId || Constants.DEFAULT_ID;
      const localMedalCount2Id = laneSheetObj.medalCount?.find(x => x.displayOrder === 2)?.meetClassificationId || Constants.DEFAULT_ID;

      setFormData({
        ...formState,
        careerStats: getCareerStats(laneSheetObj.careerStats, courseState),
        laneSheetMeetId: laneSheetObj.laneSheetMeetId,
        laneSheetTemplateId: laneSheetObj.laneSheetTemplateId,
        laneSheetTemplateName: laneSheetObj.laneSheetTemplateName,
        medalCount1Id: localMedalCount1Id,
        medalCount2Id: localMedalCount2Id,
        medalCountName1: laneSheetMedalsState.options?.find(x => x.id === localMedalCount1Id)?.name || '',
        medalCountName2: laneSheetMedalsState.options?.find(x => x.id === localMedalCount2Id)?.name || '',
        numberOfLanes: laneSheetObj.numberOfLanes,
        recordGroupWithinSheetId: laneSheetObj.recordGroupWithinSheetId,
        recordWithinSheetName: laneSheetObj.recordWithinSheetName,
        reverseLaneOrder: laneSheetObj.startingLaneNumber > 1 ? 'true' : 'false',
        meetId: laneSheetObj.meetId,
        useAmericanDates: laneSheetObj.useAmericanDates,
        laneSheetMedalCount1Id: laneSheetObj.medalCount?.find(x => x.displayOrder === 1)?.laneSheetMedalCountId || Constants.DEFAULT_ID,
        laneSheetMedalCount2Id: laneSheetObj.medalCount?.find(x => x.displayOrder === 2)?.laneSheetMedalCountId || Constants.DEFAULT_ID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetObj, courseState.isLoaded, laneSheetMedalsState.areOptionsLoaded, careerStatCourseOptions]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && courseState.isLoading !== true && courseState.isLoaded !== true) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, courseState]);

  useEffect(() => {
    if (courseState.isLoaded === true) {
      const courseOptions = [];

      for (const course of courseState.data) {
        if (course.courseId > 0) {
          courseOptions.push({ id: course.courseId, name: course.courseCode });
        }
      }

      setCareerStatCourseOptions(courseOptions);
    }
  }, [courseState]);

  function getCareerStats(state, courseState) {
    const newArray = [];

    if (Array.isArray(state) && state.length > 0) {
      for (let i = 0; i < state.length; i++) {
        const findObj = courseState.data?.find(x => x.courseId === state[i].courseId);
        if (findObj && Object.keys(findObj).length > 0) {
          const formatObj = {
            id: findObj.courseId,
            name: findObj.courseCode,
          }

          newArray.push(formatObj);
        }
      }
    }

    return newArray;
  };

  return {
    formState,
    errorState,
    laneOrderState,
    careerStatCourseOptions,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    resetForm
  };
};

export default useLaneSheetMeetInfoForm;