import { useEffect } from 'react';

import useOrgUnitData from '../../../state/orgUnit/UseOrgUnitData';

const useOrgUnitTreeView = (organizationId) => {
  const { orgUnitState, getOrgUnits, getOrgUnitsByOrganizationId } = useOrgUnitData();

  useEffect(() => {
    if (organizationId > 0) {
      getOrgUnitsByOrganizationId(organizationId);
    } else {
      getOrgUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    orgUnitState
  };
};

export default useOrgUnitTreeView;