import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetHostData from './GetMeetHostData';
import searchMeetHostData from './SearchMeetHostData';
import deleteOmeMeetData from './DeleteOmeMeetData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmeMeetHostData = {
  INITIAL_STATE,
  getMeetHostData,
  searchMeetHostData,
  deleteOmeMeetData
};

export default OmeMeetHostData;