import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../meet/NavLinks';
import MyMeetsNavLinks from '../../myMeets/MyMeetsNavLinks';
import EntryNavLinks from '../EntryNavLinks';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useMyMeetsFiltersData from '../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSearchMeetData from '../../../../common/state/searchMeet/UseSearchMeetData';
import useMeetData from '../../../../common/state/meet/UseMeetData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useOUAdminEntryContextData from '../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';
import { useEffect } from 'react';

const useOUAdminEntryContextView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { omeMeetState, clearOmeMeetArrayData } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState, clearOmeMeetOrgUnitEntryObjData, clearOmeMeetOrgUnitEntryArrayData } = useOmeMeetOrgUnitEntryData();
  const { clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { oUAdminEntryContextState, setOUAdminEntryContextState } = useOUAdminEntryContextData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const BACK_TO_LABELS = {
    entryBackToLabel: omeMeetState.route === '' ? 'Back to My Meets' : 'Back to Team Entry Management',
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearOmeMeetOrgUnitEntryObjData();
    clearOmeMeetOrgUnitEntryArrayData();
    clearOmeMeetArrayData();
    clearOmeMeetTeamEntryArrayData();
    clearArrayData();

    if (omeMeetState.route === '') {
      //OME Route
      navigate(MyMeetsNavLinks.MEET_ENTRY_TEAM, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
    }
    else {
      //Meet Route
      setSearchMeetState({ ...searchMeetState, showMeetHeader: true });

      navigate(NavLinks.MEET_ENTRY_TEAM, { state: { goToMeet: true } });
    }
  };

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_LANDING);
    }
    else {
      navigate(NavLinks.OU_ADMIN_LANDING);
    }
  };

  function getBackToLabel() {
    return BACK_TO_LABELS.entryBackToLabel;
  };

  useEffect(() => {
    setOUAdminEntryContextState({ ...oUAdminEntryContextState, isReadOnly: location.state?.isReadOnly ?? true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    oUAdminEntryContextState,
    meetState,
    omeMeetState,
    omeMeetOrgUnitEntryState,
    backToLabel: getBackToLabel(),
    onHomeClicked,
    onBackClicked
  };
};

export default useOUAdminEntryContextView;