
import { Fragment } from 'react';
import useSelectionsExport from './UseSelectionsExport';
import { EXPORT_NAME } from '../components/leftNav/SelectionsLeftNavConstants';
import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import ExportForm from '../components/forms/ExportForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const SelectionsExport = () => {
  const { selectionsState, selectionsEntriesExportState, onExportSubmitFormCallback } = useSelectionsExport();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={EXPORT_NAME} viewTitle={'Export'}>
        <ExportForm
          onSubmitFormCallback={onExportSubmitFormCallback}
          selectionsStateObj={selectionsState.objData}
          submitButtonText={'Run Export'}
          secondaryButtonText={'Cancel'}>
        </ExportForm>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={selectionsEntriesExportState.isObjLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
}

export default SelectionsExport;