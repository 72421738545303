import { Fragment } from "react";

import useTimeStandardForm from "./UseTimeStandardForm";

import SelectionsTimeStandardDropdown from "../../../../components/dropdowns/SelectionsTimeStandardDropdown";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";

const TimeStandardForm = ({ onSubmitFormCallback, onSecondaryButtonClicked, secondaryButtonText,
  selectionMeetTimeStandard, editTimeStandardId, orgUnitId, omeMeetId }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
  } = useTimeStandardForm(onSubmitFormCallback, selectionMeetTimeStandard, editTimeStandardId);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row'>
          <div className="col-xs-12 col-sm-6">
            {editTimeStandardId > 0
              ? (
                <ReadOnly
                  label='Time Standard'
                  name="timeStandardName"
                  value={formState.timeStandardName} />
              ) : (
                <SelectionsTimeStandardDropdown
                  label='Time Standard*'
                  name="timeStandardId"
                  value={formState.timeStandardId}
                  error={errorState.timeStandardId}
                  message={errorState.timeStandardId}
                  orgUnitId={orgUnitId}
                  omeMeetId={omeMeetId}
                  onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'timeStandardId', valueLabel, 'timeStandardName') }} />
              )
            }
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              label='Priority*'
              name="sortPriority"
              value={formState.sortPriority}
              error={errorState.sortPriority}
              message={errorState.sortPriority}
              onChange={(value) => { onFormValueChanged('sortPriority', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is Individual Base Time Standard?'
              name="isIndividualBaseStandard"
              checked={formState.isIndividualBaseStandard}
              error={errorState.isIndividualBaseStandard}
              message={errorState.isIndividualBaseStandard}
              onChange={(value) => { onFormValueChanged('isIndividualBaseStandard', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is Relay Base Time Standard?'
              name="isRelayBaseStandard"
              checked={formState.isRelayBaseStandard}
              error={errorState.isRelayBaseStandard}
              message={errorState.isRelayBaseStandard}
              onChange={(value) => { onFormValueChanged('isRelayBaseStandard', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
            <PrimaryButton type="submit">{editTimeStandardId > 0 ? 'Edit Time Standard' : 'Add Time Standard'}</PrimaryButton>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default TimeStandardForm;