import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const fakeArrayData = [
  {
    unavailableReasonId : 1,
    unavailableReasonName: 'Meet Scratch'
  },
  {
    unavailableReasonId: 2,
    unavailableReasonName: 'Medical Scratch'
  },
  {
    unavailableReasonId: 3,
    unavailableReasonName: 'Other'
  }
];

const getSelectionsUnavailableReasonsData = (state, setState) => {
  //TODO remove fake data
  const api = initApi(API_NAMES.TIMES, state, setState, fakeArrayData);

  const url = `/OmeSelectionsMeet/UnavailableReasons`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const SelectionsUnavailableReasonsData = {
  getSelectionsUnavailableReasonsData
};

export default SelectionsUnavailableReasonsData;