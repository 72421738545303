import { useEffect } from 'react';
import { useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';
import useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryRelayRosterEntry/UseOmeMeetOrgUnitAthleteEntryRelayRosterEntryData';
import useOmeMeetOrgUnitNonAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryData';

const useOrgUnitAdminLanding = () => {
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState, getOmeMeetOrgUnitEntryById } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
    = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitAthleteEntryRelayRosterEntryState, getOrgUnitAthleteEntryRelayRosterEntry }
    = useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData();
    const { omeMeetOrgUnitNonAthleteEntryState, getOrgUnitNonAthleteEntryCoaches} = useOmeMeetOrgUnitNonAthleteEntryData();
  const { oUAdminEntryContextState, setOUAdminEntryContextState } = useOUAdminEntryContextData();

  useEffect(() => {
    setOUAdminEntryContextState({ ...oUAdminEntryContextState, showNav: true });
    //Get updated info after a purchase has been made
    if (location.state && location.state.tryGet === true) {
      getOmeMeetOrgUnitEntryById(omeMeetOrgUnitEntryState.objData.omeMeetId, omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId);
      getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      //Make sure roster entries gets updated any time the roster changes
      getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      //Get updated relay entries too
      getOrgUnitAthleteEntryRelayRosterEntry(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId);
      //Get updated coaches too
      getOrgUnitNonAthleteEntryCoaches(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId) {
      if ((omeMeetOrgUnitAthleteEntryState.isArrayLoaded === false || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded === false)
        && omeMeetOrgUnitAthleteEntryState.isArrayLoading === false && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false) {
        getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        //Make sure roster entries gets updated any time the roster changes
        getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState])

  return {
    oUAdminEntryContextState,
    omeMeetState,
    omeMeetOrgUnitEntryState,
    omeMeetOrgUnitAthleteEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
    omeMeetOrgUnitNonAthleteEntryState
  };
}

export default useOrgUnitAdminLanding;