import validate from './LaneSheetHeatAddFormValidation';

import Constants from "../../../../../common/utils/Constants";
import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  heatNumber: '',
  sessionTypeId: '',
  sessionTypeName: '',
  heatDate: '',
  heatDateId: Constants.DEFAULT_ID,
  eventNameAndDistanceId: []
};

const useLaneSheetHeatAddForm = (onSubmitFormCallback) => {
  const { formState, errorState, updateFormState, onValueTextPairChanged, handleSubmit 
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged
  };
};

export default useLaneSheetHeatAddForm;