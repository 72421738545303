import React, { useState, useEffect } from 'react';
import useMeetData from '../../../../common/state/meet/UseMeetData';

import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';

const INITIAL_VIEW_STATE = {
  reportParameters: { meetId: '' },
  orgUnitId: '',
  routeName: '',
  isLoading: false,
  errorMessage: ''
};

const useRaceStats = () => {
  const { meetState } = useMeetData();
  const [ viewState, setViewState ] = useState(INITIAL_VIEW_STATE);
  const { reportListState, getEmbeddedReportListForRoute } = useEmbeddedReportData();
  
  const onRunRaceStatsDashboard = () => {
    setViewState({
      ...viewState,
      reportParameters: { meetId: meetState.objData.meetId },
      routeName: 'MEET_RACE_STATS',
      isLoading: true,
      reportViewerType: 'Sisense',
      errorMessage: ''
    });
  }

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            
            const reportIdx = stateObj.arrayData.findIndex(x => x.reportViewerType === viewState.reportViewerType);

            if (reportIdx !== -1) {
              setViewState({
                ...viewState,
                isLoading: false,
                showReport: stateObj.arrayData.length > 0, 
                reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
                errorMessage: ''
              });
            } else {
              setViewState({
                ...viewState,
                isLoading: false,
                showReport: false,
                reportInfoId: -1,
                errorMessage: 'No reports found'
              });
            }
            
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1,
              errorMessage: 'No reports found'
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1,
            errorMessage: 'An error occured'
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType]);

  return {
    meetState,
    viewState,
    setViewState,
    reportListState,
    onRunRaceStatsDashboard,
    INITIAL_VIEW_STATE
  }
}

export default useRaceStats;