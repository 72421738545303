import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeRestrictionsTypeAgeValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeRestrictions from './UseOmeRestrictions';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const INITIAL_VIEW_STATE = {
  tryRedirect: false
}

const INITIAL_FORM_STATE = {
  minAge: '',
  maxAge: '',
  restrictionFormState: {}
};

const useOmeRestrictionsTypeAge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formStateHasBeenSaved } = useOmeRestrictions();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { formState, errorState, onFormValueChanged, handleSubmit, isDirty, isSubmitting, setFormData, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      handleSubmit();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (Object.keys(formState.restrictionFormState).length > 0) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS, { state: { restrictionFormState: formState.restrictionFormState } });
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS, { state: { restrictionFormState: formState.restrictionFormState } });
      }
    }
    else {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
  };

  function submitFormCallback() {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));

    //Save any changes to omeMeetEligibility made on main restriction page
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      omeMeetId: updatedOmeMeet.omeMeetId,
      usCitizenOnly: formState.restrictionFormState?.usCitizenOnly ?? null,
      nationalTeamOnly: formState.restrictionFormState?.nationalTeamOnly ?? null,
      allowRepOtherCountry: formState.restrictionFormState?.allowRepOtherCountry ?? null,
      allowUnattachedRoster: formState.restrictionFormState?.allowUnattachedRoster ?? null,
      allowUnattachedAthleteEntry: formState.restrictionFormState?.allowUnattachedAthleteEntry ?? null
    }

    updatedOmeMeet.omeMeetEligibility[0].omeMeetEligibilityAge = [{
      omeMeetEligibilityId: updatedOmeMeet.omeMeetEligibility[0]?.omeMeetEligibilityId,
      minAge: formState.minAge,
      maxAge: formState.maxAge
    }];

    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    setState({ ...state, tryRedirect: true });
  };

  function ageFormStateHasBeenSaved(formState) {
    //compare formState to omeMeetState to see if formState has been saved
    //Edit age restriction
    if (omeMeetState.objData.omeMeetEligibility[0] && omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityAge[0]) {
      const selectedAge = omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityAge[0];
      if (ToIntIfInt(formState?.minAge) !== selectedAge?.minAge
        && (formState?.minAge !== '' || selectedAge?.minAge !== null)) {
        return false;
      }
      if (ToIntIfInt(formState?.maxAge) !== selectedAge?.maxAge
        && (formState?.maxAge !== '' || selectedAge?.maxAge !== null)) {
        return false;
      }
    }
    //Add age restriction
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      if (omeMeetState.objData.omeMeetEligibility[0]) {

        const selectedAge = omeMeetState.objData.omeMeetEligibility[0]?.omeMeetEligibilityAge[0];
        if (selectedAge) {
          //Edit
          setFormData({
            ...formState,
            minAge: selectedAge.minAge >= 0 ? selectedAge.minAge : '',
            maxAge: selectedAge.maxAge >= 0 ? selectedAge.maxAge : '',
            restrictionFormState: location.state && location.state?.restrictionFormState ? location.state?.restrictionFormState : {}
          });
          if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
            setIsDirty(true);
          }
        }
        else {
          //Add
          setFormData({
            ...formState,
            restrictionFormState: location.state && location.state?.restrictionFormState ? location.state?.restrictionFormState : {}
          });
          if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
            setIsDirty(true);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.restrictionFormState);
      const isAgeSaved = ageFormStateHasBeenSaved(formState);
      const isOverallSaved = isSaved === true && isAgeSaved === true ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  return {
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeRestrictionsTypeAge;