import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeEventsDetailValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useTimeStandardAgeGroupData from '../../../../state/timeStandardAgeGroup/UseTimeStandardAgeGroupData';

import useEventData from '../../../../../common/state/event/UseEventData';
import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useEventCompetitionGenderTypeData from '../../../../../common/state/eventCompetitionGender/UseEventCompetitionGenderTypeData';
import useTimeStandardTypeData from '../../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const DUPLICATE_EVENT_NUMBER_ERROR = 'The provided Event Number is associated with an exisiting event. Duplicate Event Numbers are not allowed.'
const DUPLICATE_COMBO_ERROR = 'The provided Event, Competition Category, Age Group, and Session is associated with an exisiting event. Duplicates are not allowed.'

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  isLoaded: false,
  ageGroups: [],
  sessions: [],
  updateTimeCuts: false,
  alternateEventId: Constants.DEFAULT_ID,
  alternateMeetEventId: Constants.DEFAULT_ID
};

const INITIAL_FORM_STATE = {
  showScy: false,
  showScm: false,
  showLcm: false,
  scyPossibleForEvent: true,
  scmPossibleForEvent: true,
  lcmPossibleForEvent: true,
  meetEventId: Constants.DEFAULT_ID,
  eventNumber: '',
  eventId: Constants.DEFAULT_ID,
  eventName: '',
  scyEventId: Constants.DEFAULT_ID,
  scyEventName: '',
  scmEventId: Constants.DEFAULT_ID,
  scmEventName: '',
  lcmEventId: Constants.DEFAULT_ID,
  lcmEventName: '',
  isRelay: '',
  eventCompetitionGenderTypeId: '',
  meetAgeGroupId: Constants.DEFAULT_ID,
  meetSessionId: Constants.DEFAULT_ID,
  hasNoSlowerThanTimeStandards: '',
  hasNoFasterThanTimeStandards: '',
  hasBonusTimeStandards: '',
  hasBonusEvents: '',
  noSlowerThanTimeStandardName: '',
  noSlowerThanTimeStandardSwimTime: '',
  scyQualifyingTime: '',
  scyQualifyingTimeValue: '',
  scmQualifyingTime: '',
  scmQualifyingTimeValue: '',
  lcmQualifyingTime: '',
  lcmQualifyingTimeValue: '',
  noFasterThanTimeStandardName: '',
  noFasterThanTimeStandardSwimTime: '',
  scyLimitTime: '',
  scyLimitTimeValue: '',
  scmLimitTime: '',
  scmLimitTimeValue: '',
  lcmLimitTime: '',
  lcmLimitTimeValue: '',
  bonusTimeStandardName: '',
  bonusTimeStandardSwimTime: '',
  scyBonusTime: '',
  scyBonusTimeValue: '',
  scmBonusTime: '',
  scmBonusTimeValue: '',
  lcmBonusTime: '',
  lcmBonusTimeValue: '',
  eventHasNoFasterThanTimeStandard: true,
  eventHasNoSlowerThanTimeStandard: true,
  eventHasBonusTimeStandard: true,
  offeredAsBonusEvent: false,
  mixedEventCompetitionGenderTypeId: Constants.DEFAULT_ID,
  handleRelayEvents: false
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Save & Delete Confirmation',
  event: '',
  meetEventId: Constants.DEFAULT_ID,
  eventId: Constants.DEFAULT_ID,
  scyEventId: Constants.DEFAULT_ID,
  scmEventId: Constants.DEFAULT_ID,
  lcmEventId: Constants.DEFAULT_ID,
  showAdditionalMsg: false,
  additionalMsg: ''
};

const useOmeEventsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [alternateEventGridState, setAlternateEventGridState] = useState({ gridData: [] });
  const { eventState, setEventState } = useEventData();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { meetState } = useMeetData();
  const { eventCompetitionGenderState } = useEventCompetitionGenderTypeData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { timeStandardAgeGroupState, getTimeStandardAgeGroupEventStandard } = useTimeStandardAgeGroupData();
  const { environmentVariableState, MIXED_EVENT_COMPETITION_GENDER_ID, SCY_COURSE_ID, SCM_COURSE_ID, LCM_COURSE_ID } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formState, errorState, isSubmitting, isDirty, handleSubmit, setErrors, onFormValueChanged, setFormData, setFormState,
    onValueTextPairChanged, setIsDirty } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const tryValidateBeforeRedirect = async (submitButton, alternateMeetEvent) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    //Prevent user from adding a relay event if they set relayEvents to false on the eligibility page
    let relayError = false;
    if (omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents === false) {
      if (formState.isRelay === true) {
        relayError = true;
      }
    }
    if (relayError === true) {
      setErrors({ ...errorState, error: `The selected relay event cannot be added because the 'Meet has Relay Events?' field is set to 'No' on the Eligibility page. In order to add a relay event, please navigate to the Eligibility page and set the 'Meet has Relay Events?' field to 'Yes'.` });
    }
    else if (relayError === false) {
      if (Object.keys(errors).length === 0) {
        setState({ ...state, tryRedirect: true, submitButton: submitButton, alternateEventId: alternateMeetEvent?.eventId || Constants.DEFAULT_ID, alternateMeetEventId: alternateMeetEvent?.meetEventId || Constants.DEFAULT_ID });
      }
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, submitButton: HostRegistrationConstants.BACK, tryRedirect: true });
  };

  const onAddAlternateEventClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.ADD);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.ADD });
    }
  };

  const tryValidateBeforeShowingDeletePopUp = async (alternateMeetEvent) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    //Prevent user from adding a relay event if they set relayEvents to false on the eligibility page
    let relayError = false;
    if (omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents === false) {
      if (formState.isRelay === true) {
        relayError = true;
      }
    }
    if (relayError === true) {
      setErrors({ ...errorState, error: `The selected relay event cannot be added because the 'Meet has Relay Events?' field is set to 'No' on the Eligibility page. In order to add a relay event, please navigate to the Eligibility page and set the 'Meet has Relay Events?' field to 'Yes'.` });
    }
    else if (relayError === false) {
      if (Object.keys(errors).length === 0) {
        const alternateEventObject = eventState.arrayData.find(x => x.eventId === alternateMeetEvent.eventId);
        const alternateScyEventId = alternateEventObject ? alternateEventObject.relatedScyEventId : Constants.DEFAULT_ID;
        const alternateScyEventName = alternateScyEventId > 0 ? eventState.arrayData.find(x => x.eventId === alternateScyEventId)?.eventName : '';
        const alternateScmEventId = alternateEventObject ? alternateEventObject.relatedScmEventId : Constants.DEFAULT_ID;
        const alternateScmEventName = alternateScmEventId > 0 ? eventState.arrayData.find(x => x.eventId === alternateScmEventId)?.eventName : '';
        const alternateLcmEventId = alternateEventObject ? alternateEventObject.relatedLcmEventId : Constants.DEFAULT_ID;
        const alternateLcmEventName = alternateLcmEventId > 0 ? eventState.arrayData.find(x => x.eventId === alternateLcmEventId)?.eventName : '';
        let showAdditionalMsg = false;
        showAdditionalMsg = alternateEventGridState.gridData?.filter(x => x.eventId === alternateScyEventId || x.eventId === alternateScmEventId || x.eventId === alternateLcmEventId)?.length > 1 ? true : false;
        let additionalMsg = '';
        if (showAdditionalMsg === true) {
          const altScyEventExists = alternateEventGridState.gridData?.filter(x => x.eventId === alternateScyEventId)?.length === 1 && alternateScyEventName && alternateScyEventName !== alternateMeetEvent.event?.eventName;
          const altScmEventExists = alternateEventGridState.gridData?.filter(x => x.eventId === alternateScmEventId)?.length === 1 && alternateScmEventName && alternateScmEventName !== alternateMeetEvent.event?.eventName;
          const altLcmEventExists = alternateEventGridState.gridData?.filter(x => x.eventId === alternateLcmEventId)?.length === 1 && alternateLcmEventName && alternateLcmEventName !== alternateMeetEvent.event?.eventName;
          additionalMsg = `${altScyEventExists ? alternateScyEventName : ''}${altScyEventExists && (altScmEventExists || altLcmEventExists) ? ', ' : ''}${altScmEventExists ? alternateScmEventName : ''}${altScmEventExists && altLcmEventExists ? ', ' : ''}${altLcmEventExists ? alternateLcmEventName : ''}`
        }
        setModalState({
          ...modalState,
          displayPopUp: true,
          event: alternateMeetEvent.event?.eventName || '',
          meetEventId: alternateMeetEvent.meetEventId || Constants.DEFAULT_ID,
          eventId: alternateMeetEvent.eventId || Constants.DEFAULT_ID,
          scyEventId: alternateScyEventId,
          scmEventId: alternateScmEventId,
          lcmEventId: alternateLcmEventId,
          showAdditionalMsg: showAdditionalMsg,
          additionalMsg: additionalMsg
        });
      }
    }
  };

  const onEditAlternateEventClicked = (e, alternateMeetEvent) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.EDIT, alternateMeetEvent);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.EDIT, alternateEventId: alternateMeetEvent.eventId, alternateMeetEventId: alternateMeetEvent.meetEventId });
    }
  };

  const onDeleteAlternateEventClicked = (e, alternateMeetEvent) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeShowingDeletePopUp(alternateMeetEvent);
  };

  const onDeleteAlternateEvent = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();

    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    const isSaved = formStateHasBeenSaved(formState);
    if (isSaved === false) {
      updatedOmeMeet = editOmeMeetObj();
    }

    let oldAgeGroupIndex = -1;
    let oldEventIndex = -1;
    for (let i = 0; i < updatedOmeMeet.meetAgeGroup.length; i++) {
      for (let j = 0; j < updatedOmeMeet.meetAgeGroup[i].meetEvent.length; j++) {
        if (updatedOmeMeet.meetAgeGroup[i].meetEvent[j].meetEventId === modalState.meetEventId) {
          oldAgeGroupIndex = i;
          oldEventIndex = j;
        }
      }
    }

    // Remove time cuts for alternate event
    updatedOmeMeet.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualifyingTime =
      updatedOmeMeet.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualifyingTime.filter(x => x.eventId !== modalState.scyEventId && x.eventId !== modalState.scmEventId && x.eventId !== modalState.lcmEventId);
    updatedOmeMeet.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualificationLimit =
      updatedOmeMeet.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualificationLimit.filter(x => x.eventId !== modalState.scyEventId && x.eventId !== modalState.scmEventId && x.eventId !== modalState.lcmEventId);
    updatedOmeMeet.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventBonusTime =
      updatedOmeMeet.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventBonusTime.filter(x => x.eventId !== modalState.scyEventId && x.eventId !== modalState.scmEventId && x.eventId !== modalState.lcmEventId);

    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  function submitFormCallback(formState) {
    //Prevent user from adding a relay event if they set relayEvents to false on the eligibility page
    let relayError = false;
    if (omeMeetState?.objData?.omeMeetEligibility[0]?.relayEvents === false) {
      if (formState.isRelay === true) {
        relayError = true;
      }
    }
    if (relayError === true) {
      setErrors({ ...errorState, error: `The selected relay event cannot be added because the 'Meet has Relay Events?' field is set to 'No' on the Eligibility page. In order to add a relay event, please navigate to the Eligibility page and set the 'Meet has Relay Events?' field to 'Yes'.` });
    }
    else if (relayError === false) {
      const updatedOmeMeetCopy = editOmeMeetObj();
      if (updatedOmeMeetCopy !== null) {
        updatedOmeMeetCopy.meet = undefined;
        putOmeMeet(omeMeetState.objData.omeMeetId, updatedOmeMeetCopy);
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const validateEvent = (omeMeetCopy) => {
    //Check for dupes
    let duplicateEventNumber = false;
    let duplicateEventCompetitionCategoryAgeGroupSession = false;
    for (let i = 0; i < omeMeetCopy.meetAgeGroup.length; i++) {
      for (let j = 0; j < omeMeetCopy.meetAgeGroup[i].meetEvent.length; j++) {
        if (omeMeetCopy.meetAgeGroup[i].meetEvent[j].eventNumber === ToIntIfInt(formState.eventNumber)) {
          if (omeMeetCopy.meetAgeGroup[i].meetEvent[j].meetEventId !== ToIntIfInt(formState.meetEventId)) {
            duplicateEventNumber = true;
          }
        }
        if (omeMeetCopy.meetAgeGroup[i].meetEvent[j].meetEventId !== ToIntIfInt(formState.meetEventId) &&
          omeMeetCopy.meetAgeGroup[i].meetEvent[j].eventId === ToIntIfInt(formState.eventId) &&
          omeMeetCopy.meetAgeGroup[i].meetEvent[j].eventCompetitionGenderTypeId === ToIntIfInt(formState.eventCompetitionGenderTypeId) &&
          omeMeetCopy.meetAgeGroup[i].meetEvent[j].meetAgeGroupId === ToIntIfInt(formState.meetAgeGroupId) &&
          omeMeetCopy.meetAgeGroup[i].meetEvent[j].meetSessionId === ToIntIfInt(formState.meetSessionId)) {
          duplicateEventCompetitionCategoryAgeGroupSession = true;
        }
      }
    }
    if (duplicateEventNumber === true && duplicateEventCompetitionCategoryAgeGroupSession === false) {
      setErrors({ ...errorState, error: DUPLICATE_EVENT_NUMBER_ERROR });
      return false;
    }
    else if (duplicateEventNumber === true && duplicateEventCompetitionCategoryAgeGroupSession === true) {
      setErrors({ ...errorState, error: DUPLICATE_EVENT_NUMBER_ERROR + '\n' + DUPLICATE_COMBO_ERROR });
      return false;
    }
    else if (duplicateEventNumber === false && duplicateEventCompetitionCategoryAgeGroupSession === true) {
      setErrors({ ...errorState, error: DUPLICATE_COMBO_ERROR });
      return false;
    }
    return true;
  }

  const editOmeMeetObj = () => {
    let updatedOmeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    const selectedAgeGroupIndex = updatedOmeMeetCopy.meetAgeGroup.findIndex(x => x.meetAgeGroupId === formState.meetAgeGroupId);
    if (selectedAgeGroupIndex > -1) {
      //Edit
      if (formState.meetEventId > 0) {
        const selectedMeetEventIndex = updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent.findIndex(x => x.meetEventId === formState.meetEventId);
        //Age group is the same
        if (selectedMeetEventIndex > -1) {
          if (validateEvent(updatedOmeMeetCopy) === true) {
            //Remove any previous time cuts for selected event
            updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime =
              updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime.filter(x => x.eventId !== formState.scyEventId && x.eventId !== formState.scmEventId && x.eventId !== formState.lcmEventId);
            updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit =
              updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit.filter(x => x.eventId !== formState.scyEventId && x.eventId !== formState.scmEventId && x.eventId !== formState.lcmEventId);
            updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime =
              updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime.filter(x => x.eventId !== formState.scyEventId && x.eventId !== formState.scmEventId && x.eventId !== formState.lcmEventId);

            //if the event selected changed, remove any previous time cuts for previously selected event so they don't become alt event times
            if (updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].eventId !== formState.eventId) {
              const previouslySelectedEventId = updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].eventId;
              const previouslySelectedEventObject = eventState.arrayData.find(x => x.eventId === previouslySelectedEventId);
              const previouslySelectedScyEventId = previouslySelectedEventObject ? previouslySelectedEventObject.relatedScyEventId : Constants.DEFAULT_ID;
              const previouslySelectedScmEventId = previouslySelectedEventObject ? previouslySelectedEventObject.relatedScmEventId : Constants.DEFAULT_ID;
              const previouslySelectedLcmEventId = previouslySelectedEventObject ? previouslySelectedEventObject.relatedLcmEventId : Constants.DEFAULT_ID;

              updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime =
                updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime.filter(x => x.eventId !== previouslySelectedScyEventId && x.eventId !== previouslySelectedScmEventId && x.eventId !== previouslySelectedLcmEventId);
              updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit =
                updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit.filter(x => x.eventId !== previouslySelectedScyEventId && x.eventId !== previouslySelectedScmEventId && x.eventId !== previouslySelectedLcmEventId);
              updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime =
                updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime.filter(x => x.eventId !== previouslySelectedScyEventId && x.eventId !== previouslySelectedScmEventId && x.eventId !== previouslySelectedLcmEventId);
            }

            updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex] = {
              ...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex],
              eventNumber: formState.eventNumber,
              eventId: formState.eventId,
              eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
              meetAgeGroupId: formState.meetAgeGroupId,
              meetSessionId: formState.meetSessionId,
              event: undefined,
              eventCompetitionGenderType: undefined,
              meetEventQualifyingTime: formState.hasNoSlowerThanTimeStandards === true ?
                (formState.eventHasNoSlowerThanTimeStandard === true ?
                  [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyEventId, qualifyingTime: formState.scyQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmEventId, qualifyingTime: formState.scmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmEventId, qualifyingTime: formState.lcmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                  :
                  [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualifyingTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
                : [],
              meetEventQualificationLimit: formState.hasNoFasterThanTimeStandards === true ?
                (formState.eventHasNoFasterThanTimeStandard === true ?
                  [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyEventId, limitTime: formState.scyLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmEventId, limitTime: formState.scmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmEventId, limitTime: formState.lcmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                  :
                  [
                    ...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventQualificationLimit,
                    ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                    ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                    ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
                : [],
              meetEventBonusTime: formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true ?
                (formState.eventHasBonusTimeStandard === true ?
                  [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyEventId, bonusTime: formState.scyBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmEventId, bonusTime: formState.scmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmEventId, bonusTime: formState.lcmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                  :
                  [...updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventBonusTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scyEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.scmEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ meetEventId: updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent[selectedMeetEventIndex].meetEventId, eventId: formState.lcmEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
                : [],
              offeredAsBonusEvent: formState.offeredAsBonusEvent ?? null
            };

            return updatedOmeMeetCopy;
          }
          else {
            return null;
          }
        }
        else {
          //Age group changed
          let oldAgeGroupIndex = -1;
          let oldEventIndex = -1;
          for (let i = 0; i < updatedOmeMeetCopy.meetAgeGroup.length; i++) {
            for (let j = 0; j < updatedOmeMeetCopy.meetAgeGroup[i].meetEvent.length; j++) {
              if (updatedOmeMeetCopy.meetAgeGroup[i].meetEvent[j].meetEventId === formState.meetEventId) {
                oldAgeGroupIndex = i;
                oldEventIndex = j;
              }
            }
          }

          //remove any previous time cuts for event
          updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualifyingTime =
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualifyingTime.filter(x => x.eventId !== formState.scyEventId && x.eventId !== formState.scmEventId && x.eventId !== formState.lcmEventId);
          updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualificationLimit =
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualificationLimit.filter(x => x.eventId !== formState.scyEventId && x.eventId !== formState.scmEventId && x.eventId !== formState.lcmEventId);
          updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventBonusTime =
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventBonusTime.filter(x => x.eventId !== formState.scyEventId && x.eventId !== formState.scmEventId && x.eventId !== formState.lcmEventId);

          //if the event selected changed, remove any previous time cuts for previously selected event so they don't become alt event times
          const previouslySelectedEventId = updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].eventId;
          const previouslySelectedEventObject = eventState.arrayData.find(x => x.eventId === previouslySelectedEventId);
          const previouslySelectedScyEventId = previouslySelectedEventObject ? previouslySelectedEventObject.relatedScyEventId : Constants.DEFAULT_ID;
          const previouslySelectedScmEventId = previouslySelectedEventObject ? previouslySelectedEventObject.relatedScmEventId : Constants.DEFAULT_ID;
          const previouslySelectedLcmEventId = previouslySelectedEventObject ? previouslySelectedEventObject.relatedLcmEventId : Constants.DEFAULT_ID;

          if (updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].eventId !== formState.eventId) {
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualifyingTime =
              updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualifyingTime.filter(x => x => x.eventId !== previouslySelectedScyEventId && x.eventId !== previouslySelectedScmEventId && x.eventId !== previouslySelectedLcmEventId);
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualificationLimit =
              updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventQualificationLimit.filter(x => x => x.eventId !== previouslySelectedScyEventId && x.eventId !== previouslySelectedScmEventId && x.eventId !== previouslySelectedLcmEventId);
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventBonusTime =
              updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex].meetEventBonusTime.filter(x => x.eventId !== previouslySelectedScyEventId && x.eventId !== previouslySelectedScmEventId && x.eventId !== previouslySelectedLcmEventId);
          }

          //format alternate events we want to copy over to new event
          const meetEventCopy = JSON.parse(JSON.stringify(updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent[oldEventIndex]));
          meetEventCopy.meetEventQualifyingTime = meetEventCopy.meetEventQualifyingTime?.map((x) => {
            return {
              ...x,
              meetEventId: undefined,
              meetAgeGroupId: formState.meetAgeGroupId,
              meetEventQualifyingTimeId: undefined,
              modifiedDatetime: undefined,
              course: undefined,
              event: undefined
            }
          });
          meetEventCopy.meetEventQualificationLimit = meetEventCopy.meetEventQualificationLimit?.map((y) => {
            return {
              ...y,
              meetEventId: undefined,
              meetAgeGroupId: formState.meetAgeGroupId,
              meetEventQualificationLimitId: undefined,
              modifiedDatetime: undefined,
              course: undefined,
              event: undefined
            }
          });
          meetEventCopy.meetEventBonusTime = meetEventCopy.meetEventBonusTime?.map((z) => {
            return {
              ...z,
              meetEventId: undefined,
              meetAgeGroupId: formState.meetAgeGroupId,
              meetEventBonusTimeId: undefined,
              modifiedDatetime: undefined,
              course: undefined,
              event: undefined
            }
          });

          //remove event from previous age group
          if (oldAgeGroupIndex > -1 && oldEventIndex > -1) {
            updatedOmeMeetCopy.meetAgeGroup[oldAgeGroupIndex].meetEvent.splice(oldEventIndex, 1);
          }

          //add event to new age group
          if (validateEvent(updatedOmeMeetCopy) === true) {
            updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex].meetEvent.push({
              eventNumber: formState.eventNumber,
              eventId: formState.eventId,
              eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
              meetAgeGroupId: formState.meetAgeGroupId,
              meetSessionId: formState.meetSessionId,
              meetEventQualifyingTime: formState.hasNoSlowerThanTimeStandards === true ?
                (formState.eventHasNoSlowerThanTimeStandard === true ?
                  [...meetEventCopy.meetEventQualifyingTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, qualifyingTime: formState.scyQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, qualifyingTime: formState.scmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, qualifyingTime: formState.lcmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                  :
                  [...meetEventCopy.meetEventQualifyingTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
                : [],
              meetEventQualificationLimit: formState.hasNoFasterThanTimeStandards === true ?
                (formState.eventHasNoFasterThanTimeStandard === true ?
                  [...meetEventCopy.meetEventQualificationLimit,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, limitTime: formState.scyLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, limitTime: formState.scmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, limitTime: formState.lcmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                  :
                  [...meetEventCopy.meetEventQualificationLimit,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
                : [],
              meetEventBonusTime: formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true ?
                (formState.eventHasBonusTimeStandard === true ?
                  [...meetEventCopy.meetEventBonusTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, bonusTime: formState.scyBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, bonusTime: formState.scmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, bonusTime: formState.lcmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                  :
                  [...meetEventCopy.meetEventBonusTime,
                  ...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                  ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
                : [],
              offeredAsBonusEvent: formState.offeredAsBonusEvent ?? null
            });
            return updatedOmeMeetCopy;
          }
          else {
            return null;
          }
        }
      }

      //Add
      else {
        if (validateEvent(updatedOmeMeetCopy) === true) {
          updatedOmeMeetCopy.meetAgeGroup[selectedAgeGroupIndex]?.meetEvent.push({
            eventNumber: formState.eventNumber,
            eventId: formState.eventId,
            eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
            meetAgeGroupId: formState.meetAgeGroupId,
            meetSessionId: formState.meetSessionId,
            meetEventQualifyingTime: formState.hasNoSlowerThanTimeStandards === true ?
              (formState.eventHasNoSlowerThanTimeStandard === true ?
                [...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, qualifyingTime: formState.scyQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, qualifyingTime: formState.scmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, qualifyingTime: formState.lcmQualifyingTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                :
                [...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, qualifyingTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
              : [],
            meetEventQualificationLimit: formState.hasNoFasterThanTimeStandards === true ?
              (formState.eventHasNoFasterThanTimeStandard === true ?
                [...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, limitTime: formState.scyLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, limitTime: formState.scmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, limitTime: formState.lcmLimitTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                :
                [...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, limitTime: Constants.MIN_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
              : [],
            meetEventBonusTime: formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true ?
              (formState.eventHasBonusTimeStandard === true ?
                [...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, bonusTime: formState.scyBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, bonusTime: formState.scmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, bonusTime: formState.lcmBonusTimeValue, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])]
                :
                [...(formState.showScy === true && formState.scyPossibleForEvent === true ? [{ eventId: formState.scyEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scyEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showScm === true && formState.scmPossibleForEvent === true ? [{ eventId: formState.scmEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.scmEventId)?.courseId, course: undefined, event: undefined }] : []),
                ...(formState.showLcm === true && formState.lcmPossibleForEvent === true ? [{ eventId: formState.lcmEventId, bonusTime: Constants.MAX_SWIM_TIME_STRING, courseId: eventState.arrayData.find(x => x.eventId === formState.lcmEventId)?.courseId, course: undefined, event: undefined }] : [])])
              : [],
            offeredAsBonusEvent: formState.offeredAsBonusEvent ?? null
          });
          return updatedOmeMeetCopy;
        }
        else {
          return null;
        }
      }
    }
    else {
      return null;
    }
  }

  const onChangeEvent = (newValue, newValueLabel) => {
    const eventObject = eventState.arrayData.find(x => x.eventId === newValue);
    const isRelay = eventObject ? eventObject.isRelay : '';
    const scyEventId = eventObject ? eventObject.relatedScyEventId : Constants.DEFAULT_ID;
    const scyEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedScyEventId)?.eventName || '' : '';
    const scmEventId = eventObject ? eventObject.relatedScmEventId : Constants.DEFAULT_ID;
    const scmEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedScmEventId)?.eventName || '' : '';
    const lcmEventId = eventObject ? eventObject.relatedLcmEventId : Constants.DEFAULT_ID;
    const lcmEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedLcmEventId)?.eventName || '' : '';
    let scyPossibleForEvent = true;
    let scmPossibleForEvent = true;
    let lcmPossibleForEvent = true;
    if (!scyEventId) {
      scyPossibleForEvent = false;
    }
    if (!scmEventId) {
      scmPossibleForEvent = false;
    }
    if (!lcmEventId) {
      lcmPossibleForEvent = false;
    }
    let showScy = false;
    let showScm = false;
    let showLcm = false;

    // if (isRelay === true) {
    //   if (eventObject.courseId === SCY_COURSE_ID) {
    //     showScy = true;
    //     showScm = false;
    //     showLcm = false;
    //   }
    //   else if (eventObject.courseId === SCM_COURSE_ID) {
    //     showScy = false;
    //     showScm = true;
    //     showLcm = false;
    //   }
    //   else if (eventObject.courseId === LCM_COURSE_ID) {
    //     showScy = false;
    //     showScm = false;
    //     showLcm = true;
    //   }
    // }
    // else {
      //Figure out which course inputs to display for individual events  
      if (omeMeetState.objData.omeMeetEntryCourse.length > 0) {
        for (let i = 0; i < omeMeetState.objData.omeMeetEntryCourse.length; i++) {
          if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCY_COURSE_ID) {
            showScy = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCM_COURSE_ID) {
            showScm = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === LCM_COURSE_ID) {
            showLcm = true;
          }
        }
      }
    // }
    if (formState.eventId !== newValue
      || formState.eventName !== newValueLabel) {
      setFormState(formState => ({
        ...formState,
        showScy,
        showScm,
        showLcm,
        scyPossibleForEvent,
        scmPossibleForEvent,
        lcmPossibleForEvent,
        eventId: newValue,
        eventName: newValueLabel,
        isRelay: isRelay,
        scyEventId: scyEventId,
        scyEventName: scyEventName,
        scmEventId: scmEventId,
        scmEventName: scmEventName,
        lcmEventId: lcmEventId,
        lcmEventName: lcmEventName
      }));
      setIsDirty(true);
    }
  };

  function formStateHasBeenSaved(formState) {
    //compare event detail formState to omeMeetState to see if formState has been saved
    //Edit event
    if (formState?.meetAgeGroupId !== Constants.DEFAULT_ID && formState?.meetEventId !== Constants.DEFAULT_ID) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState?.meetAgeGroupId);
      const selectedMeetEvent = selectedMeetAgeGroup.meetEvent?.find(x =>
        x.meetEventId === formState?.meetEventId);

      if ((ToIntIfInt(formState?.eventNumber) !== selectedMeetEvent?.eventNumber)
        && (formState?.eventNumber !== '' || selectedMeetEvent?.eventNumber !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.eventId) !== selectedMeetEvent?.eventId)
        && (formState?.eventId !== Constants.DEFAULT_ID || selectedMeetEvent?.eventId !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.eventCompetitionGenderTypeId) !== selectedMeetEvent?.eventCompetitionGenderTypeId)
        && (formState?.eventCompetitionGenderTypeId !== '' || selectedMeetEvent?.eventCompetitionGenderTypeId !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.meetAgeGroupId) !== selectedMeetEvent?.meetAgeGroupId)
        && (formState?.meetAgeGroupId !== Constants.DEFAULT_ID || selectedMeetEvent?.meetAgeGroupId !== null)) {
        return false;
      }
      if ((ToIntIfInt(formState?.meetSessionId) !== selectedMeetEvent?.meetSessionId)
        && (formState?.meetSessionId !== Constants.DEFAULT_ID || selectedMeetEvent?.meetSessionId !== null)) {
        return false;
      }
      if ((formState?.offeredAsBonusEvent !== selectedMeetEvent?.offeredAsBonusEvent)
        && (formState?.offeredAsBonusEvent !== '' || selectedMeetEvent?.offeredAsBonusEvent !== null)) {
        return false;
      }

      if (formState?.eventHasNoSlowerThanTimeStandard !== (selectedMeetEvent.meetEventQualifyingTime?.length > 0 &&
        selectedMeetEvent.meetEventQualifyingTime?.filter(x => (x.eventId === formState.scyEventId || x.eventId === formState.scmEventId || x.eventId === formState.lcmEventId) && formatTimeForDisplay(x.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0) ? true : false) {
        return false;
      }
      if ((formState?.scyQualifyingTimeValue !== selectedMeetEvent?.meetEventQualifyingTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyEventId)?.qualifyingTime)
        && (formState?.scyQualifyingTimeValue !== '' || selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyEventId)?.qualifyingTime !== undefined)) {
        return false;
      }
      if ((formState?.scmQualifyingTimeValue !== selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmEventId)?.qualifyingTime)
        && (formState?.scmQualifyingTimeValue !== '' || selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmEventId)?.qualifyingTime !== undefined)) {
        return false;
      }
      if ((formState?.lcmQualifyingTimeValue !== selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmEventId)?.qualifyingTime)
        && (formState?.lcmQualifyingTimeValue !== '' || selectedMeetEvent.meetEventQualifyingTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmEventId)?.qualifyingTime !== undefined)) {
        return false;
      }

      if (formState?.eventHasNoFasterThanTimeStandard !== (selectedMeetEvent.meetEventQualificationLimit?.length > 0 &&
        selectedMeetEvent.meetEventQualificationLimit?.filter(x => (x.eventId === formState.scyEventId || x.eventId === formState.scmEventId || x.eventId === formState.lcmEventId) && formatTimeForFilterObject(x.limitTime) !== Constants.MIN_SWIM_TIME_STRING).length > 0) ? true : false) {
        return false;
      }
      if ((formState?.scyLimitTimeValue !== selectedMeetEvent?.meetEventQualificationLimit?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyEventId)?.limitTime)
        && (formState?.scyLimitTimeValue !== '' || selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyEventId)?.limitTime !== undefined)) {
        return false;
      }
      if ((formState?.scmLimitTimeValue !== selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmEventId)?.limitTime)
        && (formState?.scmLimitTimeValue !== '' || selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmEventId)?.limitTime !== undefined)) {
        return false;
      }
      if ((formState?.lcmLimitTimeValue !== selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmEventId)?.limitTime)
        && (formState?.lcmLimitTimeValue !== '' || selectedMeetEvent.meetEventQualificationLimit?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmEventId)?.limitTime !== undefined)) {
        return false;
      }

      if (formState?.eventHasBonusTimeStandard !== (selectedMeetEvent.meetEventBonusTime?.length > 0 &&
        selectedMeetEvent.meetEventBonusTime?.filter(x => (x.eventId === formState.scyEventId || x.eventId === formState.scmEventId || x.eventId === formState.lcmEventId) && formatTimeForDisplay(x.bonusTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0) ? true : false) {
        return false;
      }

      if (formState.offeredAsBonusEvent === true) {
        if ((formState?.scyBonusTimeValue !== selectedMeetEvent?.meetEventBonusTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyEventId)?.bonusTime)
          && (formState?.scyBonusTimeValue !== '' || selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === SCY_COURSE_ID && x.eventId === formState.scyEventId)?.bonusTime !== undefined)) {
          return false;
        }
        if ((formState?.scmBonusTimeValue !== selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmEventId)?.bonusTime)
          && (formState?.scmBonusTimeValue !== '' || selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === SCM_COURSE_ID && x.eventId === formState.scmEventId)?.bonusTime !== undefined)) {
          return false;
        }
        if ((formState?.lcmBonusTimeValue !== selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmEventId)?.bonusTime)
          && (formState?.lcmBonusTimeValue !== '' || selectedMeetEvent.meetEventBonusTime?.find(x => x.courseId === LCM_COURSE_ID && x.eventId === formState.lcmEventId)?.bonusTime !== undefined)) {
          return false;
        }
      }
    }
    //Add event
    else {
      if (isDirty === true) {
        return false;
      }
      return true;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && Object.keys(meetState.objData).length > 0) {
      //Sort age group data to display in ascending order in dropdown
      let meetAgeGroupCopy = [];
      if (omeMeetState.objData.meetAgeGroup.length > 0) {
        meetAgeGroupCopy = JSON.parse(JSON.stringify(omeMeetState.objData.meetAgeGroup));
        meetAgeGroupCopy.sort(function (a, b) { return a.minAge - b.minAge });
      }
      const meetAgeGroupCopyOptions = meetAgeGroupCopy.map((ageGroup) => ({ id: ageGroup.meetAgeGroupId, name: `${ageGroup.ageGroupName || ''} (${ageGroup.minAge || ''} - ${ageGroup.maxAge || ''})` }));
      meetAgeGroupCopyOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' });
      //Sort session data to display in ascending order in dropdown
      let meetSessionCopy = [];
      if (meetState.objData.meetSession.length > 0) {
        meetSessionCopy = JSON.parse(JSON.stringify(meetState.objData?.meetSession));
        meetSessionCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
      }
      const meetSessionCopyOptions = meetSessionCopy.map((session) => ({ id: session.meetSessionId, name: `${session.sessionOrderId || ''} (${session.sessionType?.typeName || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})` }));
      meetSessionCopyOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' });
      setState({
        ...state,
        isLoaded: true,
        ageGroups: meetAgeGroupCopyOptions,
        sessions: meetSessionCopyOptions
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, meetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && eventState.isArrayLoaded === true) {
      const allowedCourseIds = omeMeetState.objData?.omeMeetEntryCourse.map(x => { return x.courseId });
      const filteredArrayData = JSON.parse(JSON.stringify(eventState.arrayData)).filter(x => allowedCourseIds.some(courseId => courseId === x.courseId));
      const filteredArrayDataFormatted = filteredArrayData.map(x => { return { id: x.eventId, name: x.eventName }; });
      setEventState({ ...eventState, filteredItems: filteredArrayDataFormatted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, eventState.isArrayLoaded])

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && eventCompetitionGenderState.isArrayLoaded === true &&
      state.isLoaded === true && environmentVariableState.isLoaded === true && eventState.isArrayLoaded === true && eventState.filteredItems) {
      //Figure out which course inputs to display     
      let showScy = false;
      let showScm = false;
      let showLcm = false;
      if (omeMeetState.objData.omeMeetEntryCourse.length > 0) {
        for (let i = 0; i < omeMeetState.objData.omeMeetEntryCourse.length; i++) {
          if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCY_COURSE_ID) {
            showScy = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === SCM_COURSE_ID) {
            showScm = true;
          } else if (omeMeetState.objData.omeMeetEntryCourse[i].courseId === LCM_COURSE_ID) {
            showLcm = true;
          }
        }
      }
      //Edit
      if (location.state && omeMeetState.objData.meetAgeGroup?.length > 0 && location.state?.meetAgeGroupId && location.state?.meetEventId) {
        const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
          x.meetAgeGroupId === location?.state?.meetAgeGroupId);
        if (selectedMeetAgeGroup && Object.keys(selectedMeetAgeGroup).length > 0) {
          const selectedMeetEvent = selectedMeetAgeGroup.meetEvent?.find(x =>
            x.meetEventId === location?.state?.meetEventId);
          if (selectedMeetEvent && Object.keys(selectedMeetEvent).length > 0) {
            const eventObject = eventState.arrayData.find(x => x.eventId === selectedMeetEvent.eventId);
            const isRelay = eventObject ? eventObject.isRelay : '';
            const scyEventId = eventObject ? eventObject.relatedScyEventId : Constants.DEFAULT_ID;
            const scyEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedScyEventId)?.eventName || '' : '';
            const scmEventId = eventObject ? eventObject.relatedScmEventId : Constants.DEFAULT_ID;
            const scmEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedScmEventId)?.eventName || '' : '';
            const lcmEventId = eventObject ? eventObject.relatedLcmEventId : Constants.DEFAULT_ID;
            const lcmEventName = eventObject ? eventState.arrayData.find(x => x.eventId === eventObject.relatedLcmEventId)?.eventName || '' : '';
            let scyPossibleForEvent = true;
            let scmPossibleForEvent = true;
            let lcmPossibleForEvent = true;
            if (!scyEventId) {
              scyPossibleForEvent = false;
            }
            if (!scmEventId) {
              scmPossibleForEvent = false;
            }
            if (!lcmEventId) {
              lcmPossibleForEvent = false;
            }

            // if (isRelay === true) {
            //   if (eventObject.courseId === SCY_COURSE_ID) {
            //     showScy = true;
            //     showScm = false;
            //     showLcm = false;
            //   }
            //   else if (eventObject.courseId === SCM_COURSE_ID) {
            //     showScy = false;
            //     showScm = true;
            //     showLcm = false;
            //   }
            //   else if (eventObject.courseId === LCM_COURSE_ID) {
            //     showScy = false;
            //     showScm = false;
            //     showLcm = true;
            //   }
            // }
            setFormData({
              ...formState,
              showScy: showScy,
              showScm: showScm,
              showLcm: showLcm,
              scyPossibleForEvent: scyPossibleForEvent,
              scmPossibleForEvent: scmPossibleForEvent,
              lcmPossibleForEvent: lcmPossibleForEvent,
              meetEventId: selectedMeetEvent.meetEventId || Constants.DEFAULT_ID,
              eventNumber: selectedMeetEvent.eventNumber || '',
              eventId: selectedMeetEvent.eventId || Constants.DEFAULT_ID,
              eventName: selectedMeetEvent.event?.eventName || '',
              isRelay: isRelay ?? '',
              scyEventId: scyEventId,
              scyEventName: scyEventName,
              scmEventId: scmEventId,
              scmEventName: scmEventName,
              lcmEventId: lcmEventId,
              lcmEventName: lcmEventName,
              eventCompetitionGenderTypeId: selectedMeetEvent.eventCompetitionGenderTypeId || Constants.DEFAULT_ID,
              meetAgeGroupId: selectedMeetEvent.meetAgeGroupId || Constants.DEFAULT_ID,
              meetSessionId: selectedMeetEvent.meetSessionId || Constants.DEFAULT_ID,

              hasNoSlowerThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards ?? '',
              hasNoFasterThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards ?? '',
              hasBonusTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasBonusTimeStandards ?? '',
              hasBonusEvents: omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? '',

              noSlowerThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
              noFasterThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
              bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',

              eventHasNoSlowerThanTimeStandard: selectedMeetEvent.meetEventQualifyingTime?.length > 0 && selectedMeetEvent.meetEventQualifyingTime.filter(x => (x.eventId === scyEventId || x.eventId === scmEventId || x.eventId === lcmEventId) && formatTimeForDisplay(x.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0 ? true : false,
              eventHasNoFasterThanTimeStandard: selectedMeetEvent.meetEventQualificationLimit?.length > 0 && selectedMeetEvent.meetEventQualificationLimit.filter(x => (x.eventId === scyEventId || x.eventId === scmEventId || x.eventId === lcmEventId) && formatTimeForFilterObject(x.limitTime) !== Constants.MIN_SWIM_TIME_STRING).length > 0 ? true : false,
              eventHasBonusTimeStandard: selectedMeetEvent.meetEventBonusTime?.length > 0 && selectedMeetEvent.meetEventBonusTime.filter(x => (x.eventId === scyEventId || x.eventId === scmEventId || x.eventId === lcmEventId) && formatTimeForDisplay(x.bonusTime) !== Constants.MAX_SWIM_TIME_STRING).length > 0 ? true : false,
              offeredAsBonusEvent: selectedMeetEvent.offeredAsBonusEvent ?? '',

              scyQualifyingTime: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.qualifyingTime) : '',
              scyQualifyingTimeValue: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.qualifyingTime : '',

              scyLimitTime: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.limitTime) : '',
              scyLimitTimeValue: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.limitTime : '',

              scyBonusTime: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.bonusTime) : '',
              scyBonusTimeValue: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCY_COURSE_ID && x.eventId === scyEventId)?.bonusTime : '',

              scmQualifyingTime: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.qualifyingTime) : '',
              scmQualifyingTimeValue: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.qualifyingTime : '',

              scmLimitTime: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.limitTime) : '',
              scmLimitTimeValue: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.limitTime : '',

              scmBonusTime: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.bonusTime) : '',
              scmBonusTimeValue: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === SCM_COURSE_ID && x.eventId === scmEventId)?.bonusTime : '',

              lcmQualifyingTime: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.qualifyingTime) : '',
              lcmQualifyingTimeValue: selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.qualifyingTime && formatTimeForDisplay(selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.qualifyingTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualifyingTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.qualifyingTime : '',

              lcmLimitTime: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.limitTime) : '',
              lcmLimitTimeValue: selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.limitTime && formatTimeForFilterObject(selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.limitTime) !== Constants.MIN_SWIM_TIME_STRING ? selectedMeetEvent.meetEventQualificationLimit.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.limitTime : '',

              lcmBonusTime: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.bonusTime) : '',
              lcmBonusTimeValue: selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.bonusTime && formatTimeForDisplay(selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.bonusTime) !== Constants.MAX_SWIM_TIME_STRING ? selectedMeetEvent.meetEventBonusTime.find(x => x.courseId === LCM_COURSE_ID && x.eventId === lcmEventId)?.bonusTime : '',

              mixedEventCompetitionGenderTypeId: MIXED_EVENT_COMPETITION_GENDER_ID
            });

            const alternateNoSlowerThanTimes = selectedMeetEvent.meetEventQualifyingTime.filter(x => x.eventId !== scyEventId && x.eventId !== scmEventId && x.eventId !== lcmEventId)
            const alternateNoFasterThanTimes = selectedMeetEvent.meetEventQualificationLimit.filter(x => x.eventId !== scyEventId && x.eventId !== scmEventId && x.eventId !== lcmEventId)
            const alternateBonusTimes = selectedMeetEvent.meetEventBonusTime.filter(x => x.eventId !== scyEventId && x.eventId !== scmEventId && x.eventId !== lcmEventId)
            const alternateEvents = alternateNoSlowerThanTimes.concat(alternateNoFasterThanTimes, alternateBonusTimes);
            const alternateEventsUnique = alternateEvents.filter((value, index, self) => index === self.findIndex((t) => (t.eventId === value.eventId)));
            setAlternateEventGridState({ ...alternateEventGridState, gridData: alternateEventsUnique });
          }
        }
      }
      //Add
      else {
        setFormData({
          ...formState,
          showScy: showScy,
          showScm: showScm,
          showLcm: showLcm,
          hasNoSlowerThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoSlowerThanTimeStandards ?? '',
          hasNoFasterThanTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasNoFasterThanTimeStandards ?? '',
          hasBonusTimeStandards: omeMeetState.objData.omeMeetQualification[0]?.hasBonusTimeStandards ?? '',
          hasBonusEvents: omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? '',
          mixedEventCompetitionGenderTypeId: MIXED_EVENT_COMPETITION_GENDER_ID,
          eventNumber: location.state?.prevHighestEventNumber >= 0 ? (location.state?.prevHighestEventNumber + 1) : ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, eventCompetitionGenderState, state.isLoaded, environmentVariableState, eventState]);

  useEffect(() => {
    if (formState.meetAgeGroupId > 0) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState.meetAgeGroupId);
      if (Object.keys(selectedMeetAgeGroup).length > 0) {
        setFormState({
          ...formState,
          noSlowerThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          noFasterThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard'
        });
        let timeStandardAgeGroupIds = [];
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          timeStandardAgeGroupIds.push(selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup.timeStandardAgeGroupId);
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          timeStandardAgeGroupIds.push(selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup.timeStandardAgeGroupId);
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          timeStandardAgeGroupIds.push(selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup.timeStandardAgeGroupId);
        }
        if (timeStandardAgeGroupIds.length > 0) {
          let timeStandardAgeGroupIdsString = timeStandardAgeGroupIds.toString();
          getTimeStandardAgeGroupEventStandard(timeStandardAgeGroupIdsString);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.meetAgeGroupId]);

  useEffect(() => {
    if (timeStandardAgeGroupState.isArrayLoading === false && timeStandardAgeGroupState.isArrayLoaded === true &&
      formState.meetAgeGroupId > 0 && formState.eventId > 0 && formState.eventCompetitionGenderTypeId > 0) {
      const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
        x.meetAgeGroupId === formState.meetAgeGroupId);
      if (Object.keys(selectedMeetAgeGroup).length > 0) {
        let scyNoSlowerThanTimeStandardSwimTime = '';
        let scyNoFasterThanTimeStandardSwimTime = '';
        let scyBonusTimeStandardSwimTime = '';
        let scmNoSlowerThanTimeStandardSwimTime = '';
        let scmNoFasterThanTimeStandardSwimTime = '';
        let scmBonusTimeStandardSwimTime = '';
        let lcmNoSlowerThanTimeStandardSwimTime = '';
        let lcmNoFasterThanTimeStandardSwimTime = '';
        let lcmBonusTimeStandardSwimTime = '';
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
            if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
              selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.timeStandardAgeGroupId) {
              for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scyNoSlowerThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scmNoSlowerThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  lcmNoSlowerThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
              }
            }
          }
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
            if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
              selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.timeStandardAgeGroupId) {
              for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scyNoFasterThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scmNoFasterThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  lcmNoFasterThanTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
              }
            }
          }
        }
        if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId &&
          selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
          for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
            if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
              selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId) {
              for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scyBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  scmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
                if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmEventId &&
                  timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                  lcmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                }
              }
            }
          }
        }

        setFormState({
          ...formState,
          noSlowerThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noSlowerThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          noFasterThanTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.noFasterThanTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
          bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',

          scyNoSlowerThanTimeStandardSwimTime: scyNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoSlowerThanTimeStandardSwimTime) : '',
          scyNoFasterThanTimeStandardSwimTime: scyNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoFasterThanTimeStandardSwimTime) : '',
          scyBonusTimeStandardSwimTime: scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '',

          scmNoSlowerThanTimeStandardSwimTime: scmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoSlowerThanTimeStandardSwimTime) : '',
          scmNoFasterThanTimeStandardSwimTime: scmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoFasterThanTimeStandardSwimTime) : '',
          scmBonusTimeStandardSwimTime: scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '',

          lcmNoSlowerThanTimeStandardSwimTime: lcmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoSlowerThanTimeStandardSwimTime) : '',
          lcmNoFasterThanTimeStandardSwimTime: lcmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoFasterThanTimeStandardSwimTime) : '',
          lcmBonusTimeStandardSwimTime: lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '',

          eventHasNoSlowerThanTimeStandard: formState.eventHasNoSlowerThanTimeStandard === '' ? (((formState.meetEventId < 0 && (scyNoSlowerThanTimeStandardSwimTime !== '' || scmNoSlowerThanTimeStandardSwimTime !== '' || lcmNoSlowerThanTimeStandardSwimTime !== '')) || (formState.scyQualifyingTime !== '' || formState.scmQualifyingTime !== '' || formState.lcmQualifyingTime !== '')) ? true : false) : formState.eventHasNoSlowerThanTimeStandard,
          eventHasNoFasterThanTimeStandard: formState.eventHasNoFasterThanTimeStandard === '' ? (((formState.meetEventId < 0 && (scyNoFasterThanTimeStandardSwimTime !== '' || scmNoFasterThanTimeStandardSwimTime !== '' || lcmNoFasterThanTimeStandardSwimTime !== '')) || (formState.scyLimitTime !== '' || formState.scmLimitTime !== '' || formState.lcmLimitTime !== '')) ? true : false) : formState.eventHasNoFasterThanTimeStandard,
          eventHasBonusTimeStandard: formState.eventHasBonusTimeStandard === '' ? (((formState.meetEventId < 0 && (scyBonusTimeStandardSwimTime !== '' || scmBonusTimeStandardSwimTime !== '' || lcmBonusTimeStandardSwimTime !== '')) || (formState.scyBonusTime !== '' || formState.scmBonusTime !== '' || formState.lcmBonusTime !== '')) ? true : false) : formState.eventHasBonusTimeStandard,

          scyQualifyingTime: (formState.scyQualifyingTime === '' || state.updateTimeCuts === true) ? (scyNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoSlowerThanTimeStandardSwimTime) : '') : formState.scyQualifyingTime,
          scyQualifyingTimeValue: (formState.scyQualifyingTimeValue === '' || state.updateTimeCuts === true) ? scyNoSlowerThanTimeStandardSwimTime : formState.scyQualifyingTimeValue,
          scyLimitTime: (formState.scyLimitTime === '' || state.updateTimeCuts === true) ? (scyNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyNoFasterThanTimeStandardSwimTime) : '') : formState.scyLimitTime,
          scyLimitTimeValue: (formState.scyLimitTimeValue === '' || state.updateTimeCuts === true) ? scyNoFasterThanTimeStandardSwimTime : formState.scyLimitTimeValue,
          scyBonusTime: (formState.scyBonusTime === '' || state.updateTimeCuts === true) ? (scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '') : formState.scyBonusTime,
          scyBonusTimeValue: (formState.scyBonusTimeValue === '' || state.updateTimeCuts === true) ? scyBonusTimeStandardSwimTime : formState.scyBonusTimeValue,

          scmQualifyingTime: (formState.scmQualifyingTime === '' || state.updateTimeCuts === true) ? (scmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoSlowerThanTimeStandardSwimTime) : '') : formState.scmQualifyingTime,
          scmQualifyingTimeValue: (formState.scmQualifyingTimeValue === '' || state.updateTimeCuts === true) ? scmNoSlowerThanTimeStandardSwimTime : formState.scmQualifyingTimeValue,
          scmLimitTime: (formState.scmLimitTime === '' || state.updateTimeCuts === true) ? (scmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmNoFasterThanTimeStandardSwimTime) : '') : formState.scmLimitTime,
          scmLimitTimeValue: (formState.scmLimitTimeValue === '' || state.updateTimeCuts === true) ? scmNoFasterThanTimeStandardSwimTime : formState.scmLimitTimeValue,
          scmBonusTime: (formState.scmBonusTime === '' || state.updateTimeCuts === true) ? (scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '') : formState.scmBonusTime,
          scmBonusTimeValue: (formState.scmBonusTimeValue === '' || state.updateTimeCuts === true) ? scmBonusTimeStandardSwimTime : formState.scmBonusTimeValue,

          lcmQualifyingTime: (formState.lcmQualifyingTime === '' || state.updateTimeCuts === true) ? (lcmNoSlowerThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoSlowerThanTimeStandardSwimTime) : '') : formState.lcmQualifyingTime,
          lcmQualifyingTimeValue: (formState.lcmQualifyingTimeValue === '' || state.updateTimeCuts === true) ? lcmNoSlowerThanTimeStandardSwimTime : formState.lcmQualifyingTimeValue,
          lcmLimitTime: (formState.lcmLimitTime === '' || state.updateTimeCuts === true) ? (lcmNoFasterThanTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmNoFasterThanTimeStandardSwimTime) : '') : formState.lcmLimitTime,
          lcmLimitTimeValue: (formState.lcmLimitTimeValue === '' || state.updateTimeCuts === true) ? lcmNoFasterThanTimeStandardSwimTime : formState.lcmLimitTimeValue,
          lcmBonusTime: (formState.lcmBonusTime === '' || state.updateTimeCuts === true) ? (lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '') : formState.lcmBonusTime,
          lcmBonusTimeValue: (formState.lcmBonusTimeValue === '' || state.updateTimeCuts === true) ? lcmBonusTimeStandardSwimTime : formState.lcmBonusTimeValue,

          handleRelayEvents: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardAgeGroupState, formState.meetAgeGroupId, formState.eventId, formState.eventCompetitionGenderTypeId]);

  useEffect(() => {
    //Handle removing bonus time standard info for relay events
    if (formState.handleRelayEvents === true) {
      if (formState.eventId !== Constants.DEFAULT_ID && formState.isRelay === true) {
        setFormState({
          ...formState,
          offeredAsBonusEvent: '',
          eventHasBonusTimeStandard: '',
          scyBonusTime: '',
          scyBonusTimeValue: '',
          scmBonusTime: '',
          scmBonusTimeValue: '',
          lcmBonusTime: '',
          lcmBonusTimeValue: '',
          handleRelayEvents: false
        });

        if (state.updateTimeCuts === true) {
          setState({ ...state, updateTimeCuts: false });
        }
      }
      else if (formState.eventId !== Constants.DEFAULT_ID && formState.isRelay === false) {
        const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
          x.meetAgeGroupId === formState.meetAgeGroupId);
        if (selectedMeetAgeGroup && Object.keys(selectedMeetAgeGroup).length > 0) {
          let scyBonusTimeStandardSwimTime = '';
          let scmBonusTimeStandardSwimTime = '';
          let lcmBonusTimeStandardSwimTime = '';
          if (selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId &&
            selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId > 0) {
            for (let i = 0; i < timeStandardAgeGroupState.arrayData.length; i++) {
              if (timeStandardAgeGroupState.arrayData[i].timeStandardAgeGroupId ===
                selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.timeStandardAgeGroupId) {
                for (let j = 0; j < timeStandardAgeGroupState.arrayData[i].timeStandardCut.length; j++) {
                  if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scyEventId &&
                    timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                    scyBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                  }
                  if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.scmEventId &&
                    timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                    scmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                  }
                  if (timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventId === formState.lcmEventId &&
                    timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId) {
                    lcmBonusTimeStandardSwimTime = timeStandardAgeGroupState.arrayData[i].timeStandardCut[j].swimTime;
                  }
                }
              }
            }
          }
          setFormState({
            ...formState,
            bonusTimeStandardName: selectedMeetAgeGroup?.meetAgeGroupTimeStandard[0]?.bonusTimeStandardAgeGroup?.qualifiedTimeStandardName || 'No Time Standard',
            scyBonusTimeStandardSwimTime: scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '',
            scmBonusTimeStandardSwimTime: scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '',
            lcmBonusTimeStandardSwimTime: lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '',
            eventHasBonusTimeStandard: formState.eventHasBonusTimeStandard === '' ? (((formState.meetEventId < 0 && (scyBonusTimeStandardSwimTime !== '' || scmBonusTimeStandardSwimTime !== '' || lcmBonusTimeStandardSwimTime !== '')) || (formState.scyBonusTime !== '' || formState.scmBonusTime !== '' || formState.lcmBonusTime !== '')) ? true : false) : formState.eventHasBonusTimeStandard,
            scyBonusTime: (formState.scyBonusTime === '' || state.updateTimeCuts === true) ? (scyBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scyBonusTimeStandardSwimTime) : '') : formState.scyBonusTime,
            scyBonusTimeValue: (formState.scyBonusTimeValue === '' || state.updateTimeCuts === true) ? scyBonusTimeStandardSwimTime : formState.scyBonusTimeValue,
            scmBonusTime: (formState.scmBonusTime === '' || state.updateTimeCuts === true) ? (scmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(scmBonusTimeStandardSwimTime) : '') : formState.scmBonusTime,
            scmBonusTimeValue: (formState.scmBonusTimeValue === '' || state.updateTimeCuts === true) ? scmBonusTimeStandardSwimTime : formState.scmBonusTimeValue,
            lcmBonusTime: (formState.lcmBonusTime === '' || state.updateTimeCuts === true) ? (lcmBonusTimeStandardSwimTime !== '' ? formatTimeForDisplay(lcmBonusTimeStandardSwimTime) : '') : formState.lcmBonusTime,
            lcmBonusTimeValue: (formState.lcmBonusTimeValue === '' || state.updateTimeCuts === true) ? lcmBonusTimeStandardSwimTime : formState.lcmBonusTimeValue,
            handleRelayEvents: false
          });

          if (state.updateTimeCuts === true) {
            setState({ ...state, updateTimeCuts: false });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.handleRelayEvents, formState.eventId])

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.ADD) {
        let savedMeetEventId = formState.meetEventId > 0 ? formState.meetEventId : '';
        if (!savedMeetEventId) {
          const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
            x.meetAgeGroupId === formState.meetAgeGroupId);
          if (selectedMeetAgeGroup && Object.keys(selectedMeetAgeGroup).length > 0) {
            const selectedMeetEvent = selectedMeetAgeGroup.meetEvent?.find(x =>
              x.eventNumber === ToIntIfInt(formState.eventNumber));
            savedMeetEventId = selectedMeetEvent?.meetEventId || '';
          }
        }
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS_DETAIL_ALTERNATE, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: savedMeetEventId } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS_DETAIL_ALTERNATE, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: savedMeetEventId } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT) {
        let savedMeetEventId = formState.meetEventId > 0 ? formState.meetEventId : '';
        if (!savedMeetEventId) {
          const selectedMeetAgeGroup = omeMeetState.objData.meetAgeGroup?.find(x =>
            x.meetAgeGroupId === formState.meetAgeGroupId);
          if (selectedMeetAgeGroup && Object.keys(selectedMeetAgeGroup).length > 0) {
            const selectedMeetEvent = selectedMeetAgeGroup.meetEvent?.find(x =>
              x.eventNumber === ToIntIfInt(formState.eventNumber));
            savedMeetEventId = selectedMeetEvent?.meetEventId || '';
          }
        }
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_EVENTS_DETAIL_ALTERNATE, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: savedMeetEventId, alternateMeetEventId: state.alternateMeetEventId, alternateEventId: state.alternateEventId, isEdit: true } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_EVENTS_DETAIL_ALTERNATE, { state: { meetAgeGroupId: formState.meetAgeGroupId, meetEventId: savedMeetEventId, alternateMeetEventId: state.alternateMeetEventId, alternateEventId: state.alternateEventId, isEdit: true } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state.tryRedirect, state.submitButton]);

  return {
    state,
    setState,
    modalState,
    alternateEventGridState,
    omeMeetState,
    eventCompetitionGenderState,
    timeStandardTypeState,
    timeStandardAgeGroupState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onChangeEvent,
    onAddAlternateEventClicked,
    onEditAlternateEventClicked,
    onDeleteAlternateEventClicked,
    onDeleteAlternateEvent,
    onModalCanceled
  };
};

export default useOmeEventsDetail;