import React from 'react';

import useTimePicker from './UseTimePicker';

import Dropdown from '../dropdowns/Dropdown';

import style from './DatePicker.module.css';
import global from '../GlobalStyle.module.css';

const TimePicker = ({ label, message, name, value, onChange, error }) => {
  const {
    hour, minute,
    hours, minutes,
    onHourChanged, onMinuteChanged
  } = useTimePicker(value, onChange);

  return (
    <div className={global.ComponentFlex}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <div className={style.DateDiv}>
        <div className={style.TimeOnlyDiv}>
          <Dropdown
            error={error}
            options={hours || []}
            name={`${name}-hour`}
            value={hour}
            onChange={onHourChanged}
            isLoading={false}
            areValuesIntegers={false}
            defaultValue="99" />
        </div>
        <div className={style.TimeOnlyDiv}>
          <Dropdown
            error={error}
            options={minutes || []}
            name={`${name}-minute`}
            value={minute}
            onChange={onMinuteChanged}
            isLoading={false}
            areValuesIntegers={false}
            defaultValue="99" />
        </div>
      </div>
      {error && <p className={[global.ErrorMessage, style.ErrorMsg].join(' ')}>{message}</p>}
    </div>
  );
};

export default TimePicker;