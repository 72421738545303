import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import SelectionsNewSelectionSearch from "./selectionsNewSelectionSearch/SelectionsNewSelectionSearch";
import SelectionsById from "./selectionsById/SelectionsById";
import SelectionsConfiguration from "./selectionsConfiguration/SelectionsConfiguration";
import SelectionsMeetEvents from "./selectionsMeetEvents/SelectionsMeetEvents";
import SelectionsMeetEntries from "./selectionsMeetEntries/SelectionsMeetEntries";
import SelectionsParticipantSelections from "./selectionsParticipantSelections/SelectionsParticipantSelections";
import SelectionsReports from "./selectionsReports/SelectionsReports";
import SelectionsParticipantSelectionsDetail from "./selectionsParticipantSelections/SelectionsParticipantSelectionsDetail";
import SelectionsExistingSelections from "./selectionsExistingSelections/SelectionsExistingSelections";
import SelectionsExport from "./selectionsExport/SelectionsExport";

import SelectionsOrgLevelContextProvider from "../../state/selectionsOrgLevel/SelectionsOrgLevelContextProvider";
import SelectionsTimeStandardContextProvider from "../../state/selectionsTimeStandard/SelectionsTimeStandardContextProvider";
import SelectionsContextProvider from "../../state/selections/SelectionsContextProvider";
import NewSelectionSearchFiltersContextProvider from "../../state/selections/NewSelectionSearchFiltersContextProvider";
import SelectionsLeftNavContextProvider from "../../state/selectionsLeftNav/SelectionsLeftNavContextProvider";
import SelectionsMeetEventsContextProvider from "../../state/selectionsMeetEvents/SelectionsMeetEventsContextProvider";
import SelectionsParticipantsContextProvider from "../../state/selectionsParticipants/SelectionsParticipantsContextProvider";
import SelectionGridOptionsContextProvider from "../../state/selectionsParticipants/SelectionGridOptionsContextProvider";
import SelectionsActionsContextProvider from "../../state/selectionsActions/SelectionsActionsContextProvider";
import ParticipantGridDetailPopupContextProvider from "../../state/selectionsParticipants/participantGridDetailPopup/ParticipantGridDetailPopupContextProvider";
import SelectionsLoadingModalContextProvider from "../../state/selections/selectionsLoadingModal/SelectionsLoadingModalContextProvider";
import SelectionsUnavailableReasonsContextProvider from "../../state/selectionsUnavailableReasons/SelectionsUnavailableReasonsContextProvider";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import LeftNavModalContextProvider from "../../../common/state/leftNavModal/LeftNavModalContextProvider";

const SelectionsContextRoutesContextProviders = ({ children }) => (
  <SelectionsTimeStandardContextProvider>
    <SelectionsActionsContextProvider>
      <SelectionsUnavailableReasonsContextProvider>
        <LeftNavModalContextProvider>
          <SelectionsLeftNavContextProvider>
            <SelectionsLoadingModalContextProvider>
              <SelectionsMeetEventsContextProvider>
                <SelectionsParticipantsContextProvider>
                  <SelectionGridOptionsContextProvider>
                    <ParticipantGridDetailPopupContextProvider>
                      {children}
                    </ParticipantGridDetailPopupContextProvider>
                  </SelectionGridOptionsContextProvider>
                </SelectionsParticipantsContextProvider>
              </SelectionsMeetEventsContextProvider>
            </SelectionsLoadingModalContextProvider>
          </SelectionsLeftNavContextProvider>
        </LeftNavModalContextProvider>
      </SelectionsUnavailableReasonsContextProvider>
    </SelectionsActionsContextProvider>
  </SelectionsTimeStandardContextProvider>
);

const SelectionsBasicRoutesContextProviders = ({ children }) => (
  <SelectionsOrgLevelContextProvider>
    <NewSelectionSearchFiltersContextProvider>
      <SelectionsContextProvider>
        {children}
      </SelectionsContextProvider>
    </NewSelectionSearchFiltersContextProvider>
  </SelectionsOrgLevelContextProvider>
);

const SelectionsContextRoutes = ({ navRoutes }) => (
  <SelectionsContextRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.SELECTIONS_CONFIGURATION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_CONFIGURATION}
            editElement={<SelectionsConfiguration />} />
        } />
      <Route path={navRoutes.SELECTIONS_MEET_EVENTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_MEET_EVENTS}
            editElement={<SelectionsMeetEvents />} />
        } />
      <Route path={navRoutes.SELECTIONS_MEET_ENTRIES?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_MEET_ENTRIES}
            editElement={<SelectionsMeetEntries />} />
        } />
      <Route path={navRoutes.SELECTIONS_PARTICIPANT_SELECTIONS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_PARTICIPANT_SELECTIONS}
            editElement={<SelectionsParticipantSelections />} />
        } />
      <Route path={navRoutes.SELECTIONS_PARTICIPANT_SELECTIONS_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_PARTICIPANT_SELECTIONS_DETAIL}
            editElement={<SelectionsParticipantSelectionsDetail />} />
        } />
      <Route path={navRoutes.SELECTIONS_REPORTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_REPORTS}
            editElement={<SelectionsReports />} />
        } />
      <Route path={navRoutes.SELECTIONS_EXPORT?.path} 
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_EXPORT}yar
            editElement={<SelectionsExport />} />
        } />
    </Routes>
  </SelectionsContextRoutesContextProviders>
);

const SelectionsBasicRoutes = ({ navRoutes }) => (
  <SelectionsBasicRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.SELECTIONS_NEW_SELECTION_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_NEW_SELECTION_SEARCH}
            editElement={<SelectionsNewSelectionSearch />} />
        } />
      <Route path={navRoutes.SELECTIONS_EXISTING_SELECTIONS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_EXISTING_SELECTIONS}
            editElement={<SelectionsExistingSelections />} />
        } />
      <Route path={navRoutes.SELECTIONS_BY_ID?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SELECTIONS_BY_ID}
            editElement={<SelectionsById />} />
        } />

      <Route path={'/*'} element={<SelectionsContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.SELECTIONS_NEW_SELECTION_SEARCH?.path} replace />} />
    </Routes>
  </SelectionsBasicRoutesContextProviders>
);

const Selections = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <SelectionsBasicRoutes navRoutes={navRoutes} />
  );
};

export default Selections;