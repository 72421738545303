import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatPhoneNumber } from '../../../../../common/utils/validation';

const OrgUnitAdminCoachesSelectionSmallGrid = ({ gridData, isLoading, onCheckboxChange }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData?.length > 0
        ? gridData?.map((coach, i) => (
          <div className={global.SmallTableRow} key={coach.orgUnitNonAthleteEntryId || i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{(coach.firstName || coach.middleName || coach.lastName) ? `${coach.firstName || ''}  ${coach.preferredName !== '' && coach.preferredName !== coach.firstName ? '"' + coach.preferredName + '"' : ''} ${coach.middleName || ''} ${coach.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
              <div className={global.CheckboxMobile}>
                {coach.hasPaid === false ?
                  <Checkbox
                    className={global.IconButton}
                    label=""
                    name="isSelected"
                    onChange={(e) => { onCheckboxChange(e, coach.personId) }}
                    checked={coach.isSelected} /> :
                  <Checkbox
                    className={global.IconButton}
                    label=""
                    name="isSelected"
                    onChange={(e) => { onCheckboxChange(e, coach.personId) }}
                    checked={coach.isSelected}
                    disabled={true} />}
              </div>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{(coach.firstName || coach.middleName || coach.lastName) ? `${coach.firstName || ''}  ${coach.preferredName !== '' && coach.preferredName !== coach.firstName ? '"' + coach.preferredName + '"' : ''} ${coach.middleName || ''} ${coach.lastName || ''}` : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Phone Number</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{coach.phoneNumber ? formatPhoneNumber(coach.phoneNumber) : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{coach.email || <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>In Good Standing?</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{coach.isInGoodStanding === true ? 'Yes' : coach.isInGoodStanding === false ? 'No' : <span>&nbsp;</span>}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Expiration Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{coach.isInGoodStandingExpirationDate ? formatDate(coach.isInGoodStandingExpirationDate) : <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default OrgUnitAdminCoachesSelectionSmallGrid;