import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import EntryNavLinks from '../../EntryNavLinks';
import NavLinks from '../../../meet/NavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryRelayRosterEntry/UseOmeMeetOrgUnitAthleteEntryRelayRosterEntryData';
import useAthleteMeetEventData from '../../../../state/athleteMeetEvent/UseAthleteMeetEventData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useRelayAthleteMeetEventData from '../../../../state/relayAthleteMeetEvent/UseRelayAthleteMeetEventData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../../common/utils/Constants';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

const INITIAL_MODAL_STATE = {
    displayPopUp: false,
    modalTitle: 'Delete Relay Team?',
    relayTeamName: '',
    entryTime: '',
    courseCode: '',
    timeEvent: '',
    isAggregate: '',
    swimTimeEntryOverride: '',
    status: '',
    relayMeetEventEntryId: Constants.DEFAULT_ID
};

const useOrgUnitAdminRelayEntriesTeams = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { omeMeetState } = useOmeMeetData();
    const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
    const { omeMeetOrgUnitAthleteEntryRelayRosterEntryState, getOrgUnitAthleteEntryRelayRosterEntry,
        clearOmeMeetOrgUnitAthleteEntryRelayRosterEntryObjData }
        = useOmeMeetOrgUnitAthleteEntryRelayRosterEntryData();
    const { athleteMeetEventState, deleteRelayMeetEventEntry } = useAthleteMeetEventData();
    const { clearRelayAthleteMeetEventArrayData } = useRelayAthleteMeetEventData();
    const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
    const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
        getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
        = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
    const { oUAdminEntryContextState } = useOUAdminEntryContextData();
    const { MAX_RELAY_TEAM_ENTRIES_PER_EVENT } = useEnvironmentVariableData();
    const [state, setState] = useState({ event: {}, session: '', tryGet: false });
    const [gridState, setGridState] = useState({ gridData: {} });
    const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

    const onAddRelayTeamClicked = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        clearRelayAthleteMeetEventArrayData();
        if (omeMeetState.route === '') {
            //OME Route
            navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES_DETAIL, { state: { event: state.event } });
        }
        else {
            //Meet Route
            navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES_DETAIL, { state: { event: state.event } });
        }
    }

    const onEditRelayTeamClicked = (e, relayTeam) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (omeMeetState.route === '') {
            //OME Route
            navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES_DETAIL, { state: { event: state.event, relayTeam: relayTeam } });
        }
        else {
            //Meet Route
            navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES_DETAIL, { state: { event: state.event, relayTeam: relayTeam } });
        }
    }

    const onDeleteRelayTeamClicked = (e, relayTeam) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        setModalState({
            ...modalState,
            displayPopUp: true,
            relayTeamName: relayTeam.relayTeamName || '',
            entryTime: relayTeam.entryTime ? formatTimeForDisplay(relayTeam.entryTime) : 'NT',
            timeEvent: `${relayTeam.alternateEvent === true ? `ALT: ${relayTeam.qualifyingEventName || ''}` : relayTeam.nonConformingTime === true ? `NC: ${relayTeam.qualifyingEventName || ''}` : `${relayTeam.qualifyingEventName || ''}`}`,
            courseCode: relayTeam.qualifyingEventName ? relayTeam.qualifyingEventName?.substring(relayTeam.qualifyingEventName?.length - 3) : '',
            isAggregate: relayTeam.isAggregate === true ? 'Yes' : 'No',
            swimTimeEntryOverride: relayTeam.swimTimeEntryOverride === true ? 'Yes' : 'No',
            status: relayTeam.status || '',
            relayMeetEventEntryId: relayTeam.relayMeetEventEntryId || Constants.DEFAULT_ID
        });
    }

    const onDeleteRelayTeam = (e, relayMeetEventEntryId) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        deleteRelayMeetEventEntry(relayMeetEventEntryId);
        onModalCanceled();
        setState({ ...state, tryGet: true });
    }

    const onModalCanceled = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        setModalState(INITIAL_MODAL_STATE);
    };

    const onBackClicked = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (omeMeetState.route === '') {
            //OME Route
            navigate(EntryNavLinks.OU_ADMIN_RELAY_ENTRIES);
        }
        else {
            //Meet Route
            navigate(NavLinks.OU_ADMIN_RELAY_ENTRIES);
        }
    }

    useEffect(() => {
        clearOmeMeetOrgUnitAthleteEntryRelayRosterEntryObjData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (location.state && location.state.event &&
            omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoaded === true) {
            const session = location.state.event.meetSessionName || '';

            setState({ ...state, event: location.state.event, session: session });
            const selectedEvent = omeMeetOrgUnitAthleteEntryRelayRosterEntryState.arrayData.find(x =>
                x.meetEventId === location.state.event.meetEventId);

            setGridState({ ...gridState, gridData: selectedEvent || {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState])

    useEffect(() => {
        if (omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId && athleteMeetEventState.isSaving === false &&
            (omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoaded === false || state.tryGet === true)) {
            getOrgUnitAthleteEntryRelayRosterEntry(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId);
            //get Roster to refresh any relay only statuses
            getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
            //get Roster Entries to refresh any relay only statuses
            getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
            if (state.tryGet === true) {
                setState({ ...state, tryGet: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [omeMeetOrgUnitEntryState, athleteMeetEventState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState, state.tryGet])

    return {
        oUAdminEntryContextState,
        state,
        gridState,
        athleteMeetEventState,
        omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
        omeMeetOrgUnitAthleteEntryState,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
        onAddRelayTeamClicked,
        onEditRelayTeamClicked,
        onDeleteRelayTeamClicked,
        onDeleteRelayTeam,
        onBackClicked,
        modalState,
        onModalCanceled,
        omeMeetState,
        MAX_RELAY_TEAM_ENTRIES_PER_EVENT
    };
}

export default useOrgUnitAdminRelayEntriesTeams;