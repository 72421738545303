import { Fragment } from "react";

import SelectionAlternatesGridLarge from "./SelectionAlternatesGridLarge";

import Headings from "../../../../../common/components/headings/Headings";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";

const SelectionAlternatesGrid = ({ gridData, isLoading, showRelaysInAlternatesGrid, onToggleShowRelays, onEditClicked, onSelectClicked }) => (
  <Fragment>
    <div className='row usas-extra-top-margin'>
      <div className="col-xs-12 usas-extra-top-margin">
        <Headings.H5>Alternates</Headings.H5>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-4">
        <YesNoSwitch
          label='Show Relays in Alternates Grid'
          name='showRelaysInAlternatesGrid'
          checked={showRelaysInAlternatesGrid}
          onChange={onToggleShowRelays} />
      </div>
    </div>
    <SelectionAlternatesGridLarge gridData={gridData} isLoading={isLoading} onEditClicked={onEditClicked} onSelectClicked={onSelectClicked} />
  </Fragment >
);

export default SelectionAlternatesGrid;