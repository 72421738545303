import React, { Fragment } from 'react';

import OrgUnitAdminCoachesWrite from './OrgUnitAdminCoachesWrite';
import OrgUnitAdminCoachesReadOnly from './OrgUnitAdminCoachesReadOnly';

import useOrgUnitAdminCoaches from './UseOrgUnitAdminCoaches';

const OrgUnitAdminCoaches = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminCoaches();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminCoachesWrite /> :
        <OrgUnitAdminCoachesReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminCoaches;