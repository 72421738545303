import { TimesHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

const GetTimeStandardTypeByOrgUnitIdData = (orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      orgUnitId,
      isArrayLoading: true,
      isArrayLoaded: false,
      isOptionsLoaded: false,
      options: [],
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/TimeStandardType/OrgUnit/${orgUnitIdForUrl}`;
    TimesHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ timeStandardTypeId: Constants.DEFAULT_ID, typeName: '--' });
          setState({
            ...newState,
            orgUnitId,
            isArrayLoading: false,
            isArrayLoaded: true,
            message: '',
            arrayData,
            options: arrayData.map((timeStandardType) => { return { id: timeStandardType.timeStandardTypeId, name: timeStandardType.typeName } }),
            isOptionsLoaded: true
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          isOptionsLoaded: false,
          message: message
        });
      });
  }
};

export default GetTimeStandardTypeByOrgUnitIdData;