import React, { Fragment } from 'react';

import useTimesRecon from './UseTimesRecon';

import Headings from '../../../../common/components/headings/Headings';
import TimesReconUpload from '../../../../common/components/fileUpload/timesReconUpload/TimesReconUpload';

const TimesRecon = () => {
  const {
    meetId
  } = useTimesRecon();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Times Recon</Headings.H3>
        </div>
      </div>
      {meetId > 0 &&
        <TimesReconUpload meetId={meetId} />
      }
    </Fragment>
  );
};

export default TimesRecon;