import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OrgUnitAdminPurchasesValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitEntryPurchaseData from '../../../../state/omeMeetOrgUnitEntryPurchase/UseOmeMeetOrgUnitEntryPurchaseData';
import useOmeMeetOrgUnitEntryContactData from '../../../../state/omeMeetOrgUnitEntryContact/UseOmeMeetOrgUnitEntryContactData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';
import useOmeMeetOrgUnitNonAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryData';

import useForm from '../../../../../common/utils/UseForm';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
};

const useOrgUnitAdminPurchases = () => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitEntryContactState, getOmeMeetOrgUnitEntryContacts } = useOmeMeetOrgUnitEntryContactData();
  const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
    = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitNonAthleteEntryState, getOrgUnitNonAthleteEntryCoaches } = useOmeMeetOrgUnitNonAthleteEntryData();
  const { omeMeetOrgUnitEntryPurchaseState, setOmeMeetOrgUnitEntryPurchaseState, getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryId, postOmeMeetOrgUnitEntryPurchase } = useOmeMeetOrgUnitEntryPurchaseData();
  const { oUAdminEntryContextState, setOUAdminEntryContextState } = useOUAdminEntryContextData();
  const { errorState, formState, handleSubmit, updateFormState, setFormData, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState({ tryRedirect: false, showCurrent: true, showPayButton: false });

  const onToggle = () => {
    setState({ ...state, showCurrent: !state.showCurrent });
  };

  function submitTeamEntryAllowed() {
    //Must have at least one entry contact
    if (atLeastOneEntryContactProvided() === false) {
      return false;
    }
    //Must have at least one athlete on roster
    if (atLeastOneAthleteOnRoster() === false) {
      return false;
    }
    //All athlete events must be approved
    if (allAthleteEventsApproved() === false) {
      return false;
    }
    //Number of coaches is allowed
    if (numberOfCoachesAllowed() === false) {
      return false;
    }
    //Number of athletes is allowed 
    if (numberOfAthletesAllowed() === false) {
      return false;
    }

    return true;
  };

  function atLeastOneEntryContactProvided() {
    if (omeMeetOrgUnitEntryContactState.arrayData.length > 0) {
      return true;
    }
    return false;
  }

  function atLeastOneAthleteOnRoster() {
    if (omeMeetOrgUnitAthleteEntryState.arrayData.length > 0) {
      return true;
    }
    return false;
  };

  function allAthleteEventsApproved() {
    if (atLeastOneAthleteOnRoster() === false) {
      return false;
    }
    for (let i = 0; i < omeMeetOrgUnitAthleteEntryState.arrayData.length; i++) {
      if (omeMeetState.objData.omeMeetEligibility[0]?.relayEvents === true && omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === true) {
        if (omeMeetOrgUnitAthleteEntryState.arrayData[i].status !== Constants.WORKFLOW_STATUS_EVENTS_APPROVED && omeMeetOrgUnitAthleteEntryState.arrayData[i].status !== Constants.WORKFLOW_STATUS_RELAY_ONLY_SWIMMER) {
          return false;
        }
      }
      else if (omeMeetState.objData.omeMeetEligibility[0]?.relayEvents === false ||
        (omeMeetState.objData.omeMeetEligibility[0]?.relayEvents === true && omeMeetState.objData.omeMeetEligibility[0]?.allowRelayOnlySwimmer === false)) {
        if (omeMeetOrgUnitAthleteEntryState.arrayData[i].status !== Constants.WORKFLOW_STATUS_EVENTS_APPROVED) {
          return false;
        }
      }
    }
    return true;
  };

  function numberOfCoachesAllowed() {
    if (omeMeetState.objData?.omeMeetCoachPass?.length > 0) {
      const includeRelaySwimmerForCoachPasses = omeMeetState?.objData?.includeRelaySwimmerForCoachPasses;
      const countOfAllSwimmers = omeMeetOrgUnitAthleteEntryState.arrayData?.length;
      const countOfSwimmersExcludingRelayOnlySwimmers = omeMeetOrgUnitAthleteEntryState.arrayData?.filter(x => x.isRelayOnlyAthlete === false)?.length;
      const countOfSwimmersToUseForCoachPassRules = includeRelaySwimmerForCoachPasses === true ? countOfAllSwimmers : countOfSwimmersExcludingRelayOnlySwimmers;
      for (let i = 0; i < omeMeetState.objData?.omeMeetCoachPass?.length; i++) {
        //find applicable coach pass rule for number of swimmers
        if (omeMeetState.objData?.omeMeetCoachPass[i].minSwimmers <= countOfSwimmersToUseForCoachPassRules &&
          omeMeetState.objData?.omeMeetCoachPass[i].maxSwimmers >= countOfSwimmersToUseForCoachPassRules) {
          if (omeMeetOrgUnitNonAthleteEntryState.arrayData.length > omeMeetState.objData?.omeMeetCoachPass[i].coachPasses) {
            return false;
          }
        }
      }
    }
    return true;
  };

  function numberOfAthletesAllowed() {
    if (omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet) {
      const includeRelaySwimmerForMaxNumber = omeMeetState.objData?.omeMeetEligibility[0]?.includeRelayOnlySwimmersInMaxNumber ?? false;
      const countOfAllSwimmers = omeMeetOrgUnitAthleteEntryState.arrayData?.length;
      const countOfSwimmersExcludingRelayOnlySwimmers = omeMeetOrgUnitAthleteEntryState.arrayData?.filter(x => x.isRelayOnlyAthlete === false)?.length;
      const countOfSwimmersToUseForMaxNumber = includeRelaySwimmerForMaxNumber === true ? countOfAllSwimmers : countOfSwimmersExcludingRelayOnlySwimmers;

      if (countOfSwimmersToUseForMaxNumber > omeMeetState.objData?.omeMeetEligibility[0]?.maxNumberOfAthletesForMeet) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setOUAdminEntryContextState({ ...oUAdminEntryContextState, showNav: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //Get entry contacts if they aren't already loaded
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId
      && omeMeetOrgUnitEntryContactState.isArrayLoading !== true && omeMeetOrgUnitEntryContactState.isArrayLoaded !== true) {
      getOmeMeetOrgUnitEntryContacts(omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitEntryContactState])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId) {
      if ((omeMeetOrgUnitAthleteEntryState.isArrayLoaded === false || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded === false || omeMeetOrgUnitNonAthleteEntryState.isArrayLoaded === false)
        && omeMeetOrgUnitAthleteEntryState.isArrayLoading === false && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false && omeMeetOrgUnitNonAthleteEntryState.isArrayLoading === false) {
        getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        //Make sure roster entries & coaches gets updated any time the roster changes
        getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId)
        getOrgUnitNonAthleteEntryCoaches(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitNonAthleteEntryState])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetState.isObjLoaded === true) {
      getOmeMeetOrgUnitEntryPurchaseByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetState])

  useEffect(() => {
    if (omeMeetOrgUnitEntryPurchaseState.isObjLoaded === true && Object.keys(omeMeetState.objData).length > 0) {
      if (omeMeetOrgUnitEntryPurchaseState.objData.currentPurchase?.omeMeetPurchase) {
        setFormData({
          ...formState,
        });
        setIsDirty(true);

        const omeMeetPurchaseWithQuantityOtherThanZero = omeMeetOrgUnitEntryPurchaseState.objData.currentPurchase.omeMeetPurchase?.find(x => x.quantity > 0) ? true : false;
        const additionalPurchaseWithQuantityOtherThanZero = omeMeetOrgUnitEntryPurchaseState.objData.currentPurchase.additionalPurchase?.find(x => x.quantity > 0) ? true : false;
        if (omeMeetPurchaseWithQuantityOtherThanZero === true || additionalPurchaseWithQuantityOtherThanZero === true) {
          setState({ ...state, showPayButton: true });
        }
        else {
          setState({ ...state, showPayButton: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryPurchaseState, omeMeetState.objData])

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetOrgUnitEntryPurchaseState.isSaved === true) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.OU_ADMIN_PAYMENTS);
      }
      else {
        //Meet Route
        navigate(NavLinks.OU_ADMIN_PAYMENTS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, omeMeetOrgUnitEntryPurchaseState])

  function submitFormCallback(formState) {
    setOUAdminEntryContextState({ ...oUAdminEntryContextState, showNav: false });
    postOmeMeetOrgUnitEntryPurchase(omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId, createOmeMeetOrgUnitEntryPurchaseObject());
    setState({ ...state, tryRedirect: true });
  };

  function createOmeMeetOrgUnitEntryPurchaseObject() {
    const omeMeetOrgUnitEntryPurchaseStateCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitEntryPurchaseState.objData));

    return {
      ...omeMeetOrgUnitEntryPurchaseStateCopy,
    }
  };

  function onAdditionalPurchaseValueChanged(purchaseItem, value) {
    const omeMeetOrgUnitEntryPurchaseStateCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitEntryPurchaseState.objData));
    const selectedItemIndex = omeMeetOrgUnitEntryPurchaseStateCopy.currentPurchase?.additionalPurchase.findIndex(x => x.omeMeetPriceId === purchaseItem.omeMeetPriceId);
    if (selectedItemIndex > -1) {
      const valueInt = ToIntIfInt(value);
      const valueInteger = valueInt ? parseInt(valueInt).toFixed(0) : 0;
      if (valueInt >= 0 && valueInt <= 1000000) {
        const totalAmount = valueInt * omeMeetOrgUnitEntryPurchaseStateCopy.currentPurchase.additionalPurchase[selectedItemIndex].amount;
        const totalAmountRounded = totalAmount.toFixed(2);
        omeMeetOrgUnitEntryPurchaseStateCopy.currentPurchase.additionalPurchase[selectedItemIndex] = {
          ...omeMeetOrgUnitEntryPurchaseStateCopy.currentPurchase.additionalPurchase[selectedItemIndex],
          quantity: valueInteger,
          totalAmount: totalAmountRounded
        }
      }
    }
    setOmeMeetOrgUnitEntryPurchaseState({
      ...omeMeetOrgUnitEntryPurchaseState,
      objData: omeMeetOrgUnitEntryPurchaseStateCopy
    });

    const omeMeetPurchaseWithQuantityOtherThanZero = omeMeetOrgUnitEntryPurchaseStateCopy.currentPurchase.omeMeetPurchase?.find(x => x.quantity > 0) ? true : false;
    const additionalPurchaseWithQuantityOtherThanZero = omeMeetOrgUnitEntryPurchaseStateCopy.currentPurchase.additionalPurchase?.find(x => x.quantity > 0) ? true : false;
    if (omeMeetPurchaseWithQuantityOtherThanZero === true || additionalPurchaseWithQuantityOtherThanZero === true) {
      setState({ ...state, showPayButton: true });
    }
    else {
      setState({ ...state, showPayButton: false });
    }
  }

  return {
    state,
    omeMeetState,
    omeMeetOrgUnitEntryState,
    omeMeetOrgUnitEntryContactState,
    omeMeetOrgUnitEntryPurchaseState,
    oUAdminEntryContextState,
    errorState,
    formState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onAdditionalPurchaseValueChanged,
    onToggle,
    submitTeamEntryAllowed,
    atLeastOneEntryContactProvided,
    atLeastOneAthleteOnRoster,
    allAthleteEventsApproved,
    numberOfCoachesAllowed,
    numberOfAthletesAllowed
  };
}

export default useOrgUnitAdminPurchases;