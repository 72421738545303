import React, { Fragment } from 'react';

import useAthleteUserCoaches from './UseAthleteUserCoaches';

import AthleteUserCoachesGrid from './AthleteUserCoachesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AthleteUserCoachesWrite = () => {
  const { omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, athleteEntryContextState,
    modalState, onAddCoach, onDeleteCoachClicked, onModalCanceled, onDeleteCoach
  } = useAthleteUserCoaches();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Coach</Headings.H3>
        </div>
        <div className={athleteEntryContextState.isUnattached === true ? global.DisplayComponent : global.HideComponent}>
          {(omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length === 0 ||
            (omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length > 0 && !omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.find(x => x.hasPaid === true))) &&
            <div className="col-xs-12 usas-extra-top-margin">
              <ActionIntraPageButton type="button" onClick={onAddCoach}>Select Coach</ActionIntraPageButton>
            </div>}
          <div className="col-xs-12">
            <AthleteUserCoachesGrid
              gridData={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData}
              isLoading={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading}
              onDeleteCoachClicked={onDeleteCoachClicked}
            />
          </div>
          {(omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length === 0 ||
            (omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length > 0 && !omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.find(x => x.hasPaid === true))) &&
            <Fragment>
              {omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length > 15 && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading === false &&
                <div className='visible-md visible-lg'>
                  <div className="col-xs-12 usas-extra-top-margin">
                    <ActionIntraPageButton className="usas-extra-top-margin" type="button" onClick={onAddCoach}>Select Coach</ActionIntraPageButton>
                  </div>
                </div>}
              {omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData?.length > 0 && omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading === false &&
                <div className='hidden-md hidden-lg'>
                  <div className="col-xs-12 usas-extra-top-margin">
                    <ActionIntraPageButton className="usas-extra-top-margin" type="button" onClick={onAddCoach}>Select Coach</ActionIntraPageButton>
                  </div>
                </div>}
            </Fragment>}
        </div>
        <div className={athleteEntryContextState.isUnattached === true ? global.HideComponent : global.DisplayComponent}>
          <div className="col-xs-12">
            <p>Only Unattached Athletes can add a Coach. Teams will add Coaches through the Team Entry process.</p>
          </div>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Name:</strong> {modalState?.coachName}</p>
            <p><strong>Organization:</strong> {modalState?.organization}</p>
            <p><strong>Is In Good Standing?:</strong> {modalState?.isInGoodStanding}</p>
            <p><strong>Good Standing Expiration Date:</strong> {modalState?.isInGoodStandingExpirationDate}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteCoach(e, modalState.orgUnitAthleteEntryOrgUnitNonAthleteEntryId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isSaving} />
    </Fragment >
  );
};

export default AthleteUserCoachesWrite;