import React, { Fragment } from 'react';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onRadioChanged }) => {
  return (
    <Fragment>
      <tr>
        <td>{person?.firstName || ''}</td>
        <td>{person?.middleName || ''}</td>
        <td>{person?.lastName || ''}</td>
        <td>{person?.memberId || ''}</td>
        <td>{formatDate(person.birthDate) || ''}</td>
        <td>{person?.competitionGenderTypeName || ''} {person?.competitionGenderTypeName === null ? person?.genderIdentityTypeName : ''}</td>
        <td>{person.lscCode || ''} {person?.lscCode !== null ? "/" : ''} {person.clubCode || ''}
        </td>
        <td>
          <input
            type="radio"
            onChange={(e) => { onRadioChanged(e, person) }}
            id={`memberId-${person.memberId}`}
            name="memberId"
            value={person.memberId} />
        </td>
      </tr>
    </Fragment >
  );
};

const MeetUploadErrorTankPotentialMatchesLargeGrid = ({ gridData, onRadioChanged }) => {
  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Member Id</th>
            <th>Date of Birth</th>
            <th>Gender</th>
            <th>Organization</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {gridData?.isArrayLoading
            ? <tr><td colSpan="6">Loading...</td></tr>
            : Array?.isArray(gridData?.arrayData) && gridData?.arrayData?.length > 0
              ? gridData?.arrayData?.map((person, i) =>
                <GridRow key={i}
                  person={person}
                  onRadioChanged={onRadioChanged} />)
              : <tr><td colSpan="9">No Results</td></tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
};

export default MeetUploadErrorTankPotentialMatchesLargeGrid;