import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';
import HostNavLinks from '../../host/HostNavLinks';

import useOmeMeetTeamEntryData from '../../../state/omeMeetTeamEntry/UseOmeMeetTeamEntryData';
import useOmeMeetHostData from '../../../state/omeMeetHost/UseOmeMeetHostData';
import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useMeetCompetitionSummaryData from '../../../state/meetCompetitionSummary/UseMeeetCompetitionSummaryData';
import useOmeMeetDateValidationData from '../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../../common/state/searchMeet/UseSearchMeetData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';

import Constants from '../../../../common/utils/Constants';
import { formatDate, formatDateTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';
import useOmeMeetInvitationsData from '../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete OME Meet?',
  meetHost: '',
  meetName: '',
  meetDates: '',
  entryDates: '',
  timeZone: '',
  status: '',
  omeMeetId: Constants.DEFAULT_ID
};

const useMeetHost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState, meetFiltersState } = useMeetData();
  const { omeMeetState, getOmeMeet, deleteOmeMeet, setOmeMeetState, clearOmeMeetArrayData, clearOmeMeetObjData } = useOmeMeetData();
  const { omeMeetDateValidationState, getOmeMeetDateValidation, clearOmeMeetDateValidationObjData } = useOmeMeetDateValidationData();
  const { omeMeetInvitationsState, getOmeMeetInvitations, clearOmeMeetInvitationsArrayData } = useOmeMeetInvitationsData();
  const { omeMeetHostState, getMeetHost, clearOmeMeetHostObjData } = useOmeMeetHostData();
  const { meetCompetitionSummaryState, getMeetCompetitionSummary, clearMeetCompetitionSummaryObjData } = useMeetCompetitionSummaryData();
  const { clearOmeMeetTeamEntryArrayData } = useOmeMeetTeamEntryData();
  const { personOrgRoleState, clearArrayData } = usePersonOrgRoleData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [state, setState] = useState({ tryRedirect: false, tryGet: false, omeMeet: {}, action: '' });
  const [gridState, setGridState] = useState(getInitialGridState);

  const onEnterOmeHostClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const omeMeet = {
      endDate: meetState.objData.endDate,
      entryClosedDate: null,
      entryOpenDate: null,
      hostOrgUnitId: meetState.objData.hostOrgUnitId || null,
      meetClassificationId: meetState.objData.meetClassificationId || null,
      meetClassificationName: meetState.objData.meetClassification?.typeName || '',
      meetHost: meetState.objData.hostOrgUnit.orgUnitName || '',
      meetId: meetState.objData.meetId || null,
      meetName: meetState.objData.meetName || '',
      omeMeetId: null,
      omeMeetStatus: null,
      startDate: meetState.objData.startDate
    }

    setState({ ...state, tryRedirect: true, omeMeet: omeMeet, action: 'Enter' });
  };

  const onView = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const omeMeet = {
      endDate: meetState.objData.endDate,
      entryClosedDate: omeMeetState.objData.meetEntryEndDate || null,
      entryOpenDate: omeMeetState.objData.meetEntryStartDate || null,
      hostOrgUnitId: meetState.objData.hostOrgUnitId || null,
      meetClassificationId: meetState.objData.meetClassificationId || null,
      meetClassificationName: meetState.objData.meetClassification?.typeName || '',
      meetHost: meetState.objData.hostOrgUnit.orgUnitName || '',
      meetId: meetState.objData.meetId || null,
      meetName: meetState.objData.meetName || '',
      omeMeetId: omeMeetState.objData.omeMeetId,
      omeMeetStatus: omeMeetState.objData.status || null,
      startDate: meetState.objData.startDate
    }

    setState({ ...state, tryRedirect: true, omeMeet: omeMeet, action: 'Edit' });
  };

  const onEdit = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const omeMeet = {
      endDate: meetState.objData.endDate,
      entryClosedDate: omeMeetState.objData.meetEntryEndDate || null,
      entryOpenDate: omeMeetState.objData.meetEntryStartDate || null,
      hostOrgUnitId: meetState.objData.hostOrgUnitId || null,
      meetClassificationId: meetState.objData.meetClassificationId || null,
      meetClassificationName: meetState.objData.meetClassification?.typeName || '',
      meetHost: meetState.objData.hostOrgUnit.orgUnitName || '',
      meetId: meetState.objData.meetId || null,
      meetName: meetState.objData.meetName || '',
      omeMeetId: omeMeetState.objData.omeMeetId,
      omeMeetStatus: omeMeetState.objData.status || null,
      startDate: meetState.objData.startDate
    }

    setState({ ...state, tryRedirect: true, omeMeet: omeMeet, action: 'Edit' });
  };

  const onDeleteOmeMeetClicked = (e, omeMeet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      meetHost: meetState.objData.hostOrgUnit?.orgUnitName || '',
      meetName: meetState.objData.meetName || '',
      meetDates: `${meetState.objData?.startDate ? formatDate(meetState.objData?.startDate) : ''} - ${meetState.objData?.endDate ? formatDate(meetState.objData?.endDate) : ''}`,
      entryDates: `${omeMeet?.meetEntryStartDate ? formatDateTimeTwelveHourClock(omeMeet?.meetEntryStartDate) : ''} ${omeMeet?.meetEntryEndDate ? '-' : ''} ${omeMeet?.meetEntryEndDate ? formatDateTimeTwelveHourClock(omeMeet?.meetEntryEndDate) : ''}`,
      timeZone: omeMeet?.timeZone || '',
      status: omeMeet?.omeMeetStatus || '',
      omeMeetId: omeMeetState.objData.omeMeetId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteOmeMeet = (e, omeMeetId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOmeMeet(omeMeetId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  }

  useEffect(() => {
    if (meetState.isObjLoaded === true && meetCompetitionSummaryState.isObjLoaded === false
      && meetCompetitionSummaryState.isObjLoading === false) {
      getMeetCompetitionSummary(meetState.objData.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, meetCompetitionSummaryState])

  useEffect(() => {
    if (meetState.isObjLoaded === true && omeMeetHostState.isObjLoaded === false
      && omeMeetHostState.isObjLoading === false) {
      getMeetHost(meetState.objData.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, omeMeetHostState])

  useEffect(() => {
    if (location.state && location.state.tryGetOmeMeet === true) {
      clearMeetCompetitionSummaryObjData();
      clearOmeMeetArrayData();
      clearOmeMeetTeamEntryArrayData();
      clearArrayData();
      clearOmeMeetDateValidationObjData();
      clearOmeMeetInvitationsArrayData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state && location.state.tryGetOmeMeet === true && omeMeetState.isArrayLoaded === false &&
      personOrgRoleState.isArrayLoaded === false && omeMeetDateValidationState.isObjLoaded === false && omeMeetInvitationsState.isArrayLoaded === false) {
      if (meetState.objData.meetId) {
        getMeetHost(meetState.objData.meetId);
      }
      if (omeMeetState?.objData?.omeMeetId) {
        getOmeMeet(omeMeetState?.objData?.omeMeetId);
        getOmeMeetDateValidation(omeMeetState?.objData?.omeMeetId);
        getOmeMeetInvitations(omeMeetState?.objData?.omeMeetId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isArrayLoaded, personOrgRoleState.isArrayLoaded, omeMeetDateValidationState.isObjLoaded, omeMeetInvitationsState.isArrayLoaded])

  useEffect(() => {
    if (meetState.isObjLoaded === true && omeMeetState.isObjLoaded === true && omeMeetHostState.isObjLoaded === true) {
      setGridState({
        ...gridState,
        arrayData: [omeMeetHostState.objData],
        isSaving: false,
        isSaved: false,
        isArrayLoading: false,
        isArrayLoaded: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, omeMeetState.isObjLoaded, omeMeetHostState.isObjLoaded]);

  useEffect(() => {
    if (state.tryGet === true && omeMeetState.isSaving === false) {
      setGridState({
        ...gridState,
        arrayData: [],
        isSaving: false,
        isSaved: false,
        isArrayLoading: false,
        isArrayLoaded: true
      });
      setState({ ...state, tryGet: false });
      clearMeetCompetitionSummaryObjData();
      clearOmeMeetObjData();
      clearOmeMeetHostObjData();
      clearOmeMeetDateValidationObjData();
      clearOmeMeetInvitationsArrayData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, omeMeetState.isSaving]);

  useEffect(() => {
    if (state.tryRedirect === true && Object.keys(state.omeMeet).length > 0) {
      const selectedMeet = {
        meetId: meetState.objData.meetId,
        meetName: meetState.objData.meetName,
        meetHost: meetState.objData.hostOrgUnit.orgUnitName,
        hostOrgUnitId: meetState.objData.hostOrgUnit.orgUnitId,
        meetClassificationName: meetState.objData.meetClassification?.typeName,
        startDate: meetState.objData.startDate,
        endDate: meetState.objData.endDate
      }
      if (state.action === 'Edit') {
        getOmeMeetDateValidation(omeMeetState.objData.omeMeetId);
        getOmeMeetInvitations(omeMeetState.objData.omeMeetId);
        setOmeMeetState({ ...omeMeetState, route: 'meet', selectedMeet: selectedMeet });
        setSearchMeetState({ ...searchMeetState, showMeetHeader: false });
        clearMeetCompetitionSummaryObjData();
        navigate(NavLinks.MEET_HOST_INFO, { state: { omeMeet: state.omeMeet } });
      }
      else if (state.action === 'Enter') {
        clearMeetCompetitionSummaryObjData();
        navigate(HostNavLinks.OME_MEET_TEMPLATE_LANDING, { state: { omeMeet: selectedMeet, route: 'meet', meetFiltersState: meetFiltersState } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, state.action, state.omeMeet]);

  return {
    state,
    omeMeetHostState,
    gridState,
    modalState,
    meetState,
    omeMeetState,
    onView,
    onEnterOmeHostClicked,
    onEdit,
    onDeleteOmeMeet,
    onDeleteOmeMeetClicked,
    onModalCanceled
  }
}

export default useMeetHost;

function getInitialGridState() {
  return {
    arrayData: [],
    isSaving: false,
    isSaved: false,
    isArrayLoading: false,
    isArrayLoaded: false
  };
};