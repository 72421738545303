import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getSelectionTimeStandardsData = (orgUnitId, omeMeetId, state, setState) => {
  if (orgUnitId && omeMeetId) {
    let newState = {
      ...state,
      orgUnitId,
      omeMeetId
    };

    const api = initApi(API_NAMES.TIMES, newState, setState);

    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const omeMeetIdForUrl = encodeURIComponent(omeMeetId);
    const url = `/TimeStandard/Ome/OrgUnit/${orgUnitIdForUrl}/${omeMeetIdForUrl}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const SelectionsTimeStandardData = {
  getSelectionTimeStandardsData
};

export default SelectionsTimeStandardData;