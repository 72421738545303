import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getTimeStandardAgeGroupEventStandardData from './GetTimeStandardAgeGroupEventStandardData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TimeStandardAgeGroupData = {
  INITIAL_STATE,
  getTimeStandardAgeGroupEventStandardData
};

export default TimeStandardAgeGroupData;