import React, { Fragment } from 'react';

import useOrgUnitAdminRosterPermissions from './UseOrgUnitAdminRosterPermissions';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import DateTimePicker from '../../../../../common/components/datepickers/DateTimePicker';
import ToolTip from '../../../../../common/components/tooltips/ToolTip';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminRosterPermissionsWrite = () => {
  const { omeMeetOrgUnitEntryState, formState, errorState, onFormValueChanged,
    handleSubmit } = useOrgUnitAdminRosterPermissions();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster Permissions</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            <YesNoSwitch
              label="Allow athletes (and their guardians) to enter themselves into the meet?"
              name="orgUnitAthleteRegistrationAllowed"
              checked={formState.orgUnitAthleteRegistrationAllowed}
              error={errorState.orgUnitAthleteRegistrationAllowed}
              message={errorState.orgUnitAthleteRegistrationAllowed}
              onChange={(value) => { onFormValueChanged('orgUnitAthleteRegistrationAllowed', value) }} />
          </div>
          <div className={formState.orgUnitAthleteRegistrationAllowed === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6">
              <DateTimePicker
                label={<Fragment>Athlete Entry Start Date* <span><ToolTip label="Date and time when the team's athletes (and their guardians) can begin to submit their entry"></ToolTip></span></Fragment>}
                name="orgUnitRegistrationStartDate"
                value={formState.orgUnitRegistrationStartDate}
                error={errorState.orgUnitRegistrationStartDate}
                message={errorState.orgUnitRegistrationStartDate}
                onChange={(value) => { onFormValueChanged('orgUnitRegistrationStartDate', value); }}
                countOfYears={5}
                startYearOffset={0} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <DateTimePicker
                label={<Fragment>Athlete Entry End Date* <span><ToolTip label="Deadline for the team's athletes (and their guardians) to submit their entry"></ToolTip></span></Fragment>}
                name="orgUnitRegistrationEndDate"
                value={formState.orgUnitRegistrationEndDate}
                error={errorState.orgUnitRegistrationEndDate}
                message={errorState.orgUnitRegistrationEndDate}
                onChange={(value) => { onFormValueChanged('orgUnitRegistrationEndDate', value); }}
                countOfYears={5}
                startYearOffset={0} />
            </div>
            <div className="col-xs-12 col-md-6">
              <ReadOnly
                label={<Fragment>Time Zone <span><ToolTip label="Time Zone for the Athlete Entry Start Date and Athlete Entry End Date"></ToolTip></span></Fragment>}
                name="timeZone"
                value={formState.timeZone} />
            </div>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetOrgUnitEntryState.isSaving}
        />
      </form>
    </Fragment>
  );
};

export default OrgUnitAdminRosterPermissionsWrite;