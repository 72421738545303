import Constants from '../../../../../common/utils/Constants';
import { convertTimeToMilliseconds, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { isValidNumber, isValidSwimTime } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  const eventNumber = parseInt(formState.eventNumber);

  const scyQualifyingTimeFormatted = formatTimeForFilterObject(formState.scyQualifyingTime);
  const scyQualifyingTimeInMilliseconds = convertTimeToMilliseconds(scyQualifyingTimeFormatted);
  const scmQualifyingTimeFormatted = formatTimeForFilterObject(formState.scmQualifyingTime);
  const scmQualifyingTimeInMilliseconds = convertTimeToMilliseconds(scmQualifyingTimeFormatted);
  const lcmQualifyingTimeFormatted = formatTimeForFilterObject(formState.lcmQualifyingTime);
  const lcmQualifyingTimeInMilliseconds = convertTimeToMilliseconds(lcmQualifyingTimeFormatted);

  const scyLimitTimeFormatted = formatTimeForFilterObject(formState.scyLimitTime);
  const scyLimitTimeInMilliseconds = convertTimeToMilliseconds(scyLimitTimeFormatted);
  const scmLimitTimeFormatted = formatTimeForFilterObject(formState.scmLimitTime);
  const scmLimitTimeInMilliseconds = convertTimeToMilliseconds(scmLimitTimeFormatted);
  const lcmLimitTimeFormatted = formatTimeForFilterObject(formState.lcmLimitTime);
  const lcmLimitTimeInMilliseconds = convertTimeToMilliseconds(lcmLimitTimeFormatted);

  const scyBonusTimeFormatted = formatTimeForFilterObject(formState.scyBonusTime);
  const scyBonusTimeInMilliseconds = convertTimeToMilliseconds(scyBonusTimeFormatted);
  const scmBonusTimeFormatted = formatTimeForFilterObject(formState.scmBonusTime);
  const scmBonusTimeInMilliseconds = convertTimeToMilliseconds(scmBonusTimeFormatted);
  const lcmBonusTimeFormatted = formatTimeForFilterObject(formState.lcmBonusTime);
  const lcmBonusTimeInMilliseconds = convertTimeToMilliseconds(lcmBonusTimeFormatted);

  let errors = {};

  if (formState.eventNumber === '') {
    errors.eventNumber = 'Event Number is required';
  } else if (!isValidNumber(formState.eventNumber) || formState.eventNumber === '0') {
    errors.eventNumber = 'Event Number must be greater than 0';
  } else if (eventNumber > 10000) {
    errors.eventNumber = 'Event Number cannot be greater than 10000';
  }

  if (formState.eventName === ''
    || formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = 'A valid Event is required';
  }

  if ((formState.eventId !== Constants.DEFAULT_ID && formState.isRelay === false) &&
    formState.eventCompetitionGenderTypeId === formState.mixedEventCompetitionGenderTypeId) {
    errors.eventCompetitionGenderTypeId = "Only Relay Events can be 'Mixed'. Please select 'Male' or 'Female' for Individual Events.";
  }

  if (formState.eventCompetitionGenderTypeId === '') {
    errors.eventCompetitionGenderTypeId = 'A selection is required'
  }

  if (formState.meetAgeGroupId === Constants.DEFAULT_ID) {
    errors.meetAgeGroupId = 'A valid Age Group is required'
  }

  if (formState.meetSessionId === Constants.DEFAULT_ID) {
    errors.meetSessionId = 'A valid Session is required'
  }

  if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard !== true && formState.eventHasNoSlowerThanTimeStandard !== false) {
    errors.eventHasNoSlowerThanTimeStandard = 'A selection is required';
  }

  if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true) {
    if (formState.showScy === true && formState.scyPossibleForEvent === true) {
      if (formState.scyQualifyingTime.trim() === '') {
        errors.scyQualifyingTime = 'SCY No Slower Than Time is required';
      }
      else if (!isValidSwimTime(scyQualifyingTimeFormatted)) {
        errors.scyQualifyingTime = 'SCY No Slower Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true &&
        scyQualifyingTimeInMilliseconds <= scyLimitTimeInMilliseconds) {
        errors.scyQualifyingTime = 'SCY No Slower Than Time must be slower than SCY No Faster Than Time';
      }
      // Bonus time should be slower than or equal to No Slower Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true &&
        scyQualifyingTimeInMilliseconds > scyBonusTimeInMilliseconds) {
        errors.scyQualifyingTime = 'SCY No Slower Than Time must be faster than or equal to SCY Bonus Time';
      }
    }

    if (formState.showScm === true && formState.scmPossibleForEvent === true) {
      if (formState.scmQualifyingTime.trim() === '') {
        errors.scmQualifyingTime = 'SCM No Slower Than Time is required';
      }
      else if (!isValidSwimTime(scmQualifyingTimeFormatted)) {
        errors.scmQualifyingTime = 'SCM No Slower Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true &&
        scmQualifyingTimeInMilliseconds <= scmLimitTimeInMilliseconds) {
        errors.scmQualifyingTime = 'SCM No Slower Than Time must be slower than SCM No Faster Than Time';
      }
      // Bonus time should be slower than or equal to No Slower Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true &&
        scmQualifyingTimeInMilliseconds > scmBonusTimeInMilliseconds) {
        errors.scmQualifyingTime = 'SCM No Slower Than Time must be faster than or equal to SCM Bonus Time';
      }
    }

    if (formState.showLcm === true && formState.lcmPossibleForEvent === true) {
      if (formState.lcmQualifyingTime.trim() === '') {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time is required';
      }
      else if (!isValidSwimTime(lcmQualifyingTimeFormatted)) {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true &&
        lcmQualifyingTimeInMilliseconds <= lcmLimitTimeInMilliseconds) {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time must be slower than LCM No Faster Than Time';
      }
      // Bonus time should be slower than or equal to No Slower Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true &&
        lcmQualifyingTimeInMilliseconds > lcmBonusTimeInMilliseconds) {
        errors.lcmQualifyingTime = 'LCM No Slower Than Time must be faster than or equal to LCM Bonus Time';
      }
    }
  }

  if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard !== true && formState.eventHasNoFasterThanTimeStandard !== false) {
    errors.eventHasNoFasterThanTimeStandard = 'A selection is required';
  }

  if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true) {
    if (formState.showScy === true && formState.scyPossibleForEvent === true) {
      if (formState.scyLimitTime.trim() === '') {
        errors.scyLimitTime = 'SCY No Faster Than Time is required';
      }
      else if (!isValidSwimTime(scyLimitTimeFormatted)) {
        errors.scyLimitTime = 'SCY No Faster Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true &&
        scyLimitTimeInMilliseconds >= scyQualifyingTimeInMilliseconds) {
        errors.scyLimitTime = 'SCY No Faster Than Time must be faster than SCY No Slower Than Time';
      }
      // Bonus time should be slower than both No Slower Than and No Faster Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true &&
        scyLimitTimeInMilliseconds >= scyBonusTimeInMilliseconds) {
        errors.scyLimitTime = 'SCY No Faster Than Time must be faster than SCY Bonus Time';
      }
    }

    if (formState.showScm === true && formState.scmPossibleForEvent === true) {
      if (formState.scmLimitTime.trim() === '') {
        errors.scmLimitTime = 'SCM No Faster Than Time is required';
      }
      else if (!isValidSwimTime(scmLimitTimeFormatted)) {
        errors.scmLimitTime = 'SCM No Faster Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true &&
        scmLimitTimeInMilliseconds >= scmQualifyingTimeInMilliseconds) {
        errors.scmLimitTime = 'SCM No Faster Than Time must be faster than SCM No Slower Than Time';
      }
      // Bonus time should be slower than both No Slower Than and No Faster Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true &&
        scmLimitTimeInMilliseconds >= scmBonusTimeInMilliseconds) {
        errors.scmLimitTime = 'SCM No Faster Than Time must be faster than SCM Bonus Time';
      }
    }

    if (formState.showLcm === true && formState.lcmPossibleForEvent === true) {
      if (formState.lcmLimitTime.trim() === '') {
        errors.lcmLimitTime = 'LCM No Faster Than Time is required';
      }
      else if (!isValidSwimTime(lcmLimitTimeFormatted)) {
        errors.lcmLimitTime = 'LCM No Faster Than Time must be a valid time';
      }
      // No Slower Than should always be slower than No Faster Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true &&
        lcmLimitTimeInMilliseconds >= lcmQualifyingTimeInMilliseconds) {
        errors.lcmLimitTime = 'LCM No Faster Than Time must be faster than LCM No Slower Than Time';
      }
      // Bonus time should be slower than both No Slower Than and No Faster Than
      else if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true &&
        lcmLimitTimeInMilliseconds >= lcmBonusTimeInMilliseconds) {
        errors.lcmLimitTime = 'LCM No Faster Than Time must be faster than LCM Bonus Time';
      }
    }
  }

  if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent !== true && formState.offeredAsBonusEvent !== false && formState.isRelay === false) {
    errors.offeredAsBonusEvent = 'A selection is required';
  }

  if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard !== true && formState.eventHasBonusTimeStandard !== false && formState.isRelay === false) {
    errors.eventHasBonusTimeStandard = 'A selection is required';
  }

  if (formState.hasBonusTimeStandards === true && formState.offeredAsBonusEvent === true && formState.eventHasBonusTimeStandard === true) {
    if (formState.showScy === true && formState.scyPossibleForEvent === true) {
      if (formState.scyBonusTime.trim() === '') {
        errors.scyBonusTime = 'SCY Bonus Time is required';
      }
      else if (!isValidSwimTime(scyBonusTimeFormatted)) {
        errors.scyBonusTime = 'SCY Bonus Time must be a valid time';
      }
      // Bonus time should be slower than No Faster Than and slower than or equal to No Slower Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true &&
        scyBonusTimeInMilliseconds < scyQualifyingTimeInMilliseconds) {
        errors.scyBonusTime = 'SCY Bonus Time must be slower than or equal to SCY No Slower Than Time';
      }
      else if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true &&
        scyBonusTimeInMilliseconds <= scyLimitTimeInMilliseconds) {
        errors.scyBonusTime = 'SCY Bonus Time must be slower than SCY No Faster Than Time';
      }
    }

    if (formState.showScm === true && formState.scmPossibleForEvent === true) {
      if (formState.scmBonusTime.trim() === '') {
        errors.scmBonusTime = 'SCM Bonus Time is required';
      }
      else if (!isValidSwimTime(scmBonusTimeFormatted)) {
        errors.scmBonusTime = 'SCM Bonus Time must be a valid time';
      }
      // Bonus time should be slower than No Faster Than and slower than or equal to No Slower Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true &&
        scmBonusTimeInMilliseconds < scmQualifyingTimeInMilliseconds) {
        errors.scmBonusTime = 'SCM Bonus Time must be slower than or equal to SCM No Slower Than Time';
      }
      else if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true &&
        scmBonusTimeInMilliseconds <= scmLimitTimeInMilliseconds) {
        errors.scmBonusTime = 'SCM Bonus Time must be slower than SCM No Faster Than Time';
      }
    }

    if (formState.showLcm === true && formState.lcmPossibleForEvent === true) {
      if (formState.lcmBonusTime.trim() === '') {
        errors.lcmBonusTime = 'LCM Bonus Time is required';
      }
      else if (!isValidSwimTime(lcmBonusTimeFormatted)) {
        errors.lcmBonusTime = 'LCM Bonus Time must be a valid time';
      }
      // Bonus time should be slower than No Faster Than and slower than or equal to No Slower Than
      else if (formState.hasNoSlowerThanTimeStandards === true && formState.eventHasNoSlowerThanTimeStandard === true &&
        lcmBonusTimeInMilliseconds < lcmQualifyingTimeInMilliseconds) {
        errors.lcmBonusTime = 'LCM Bonus Time must be slower than or equal to LCM No Slower Than Time';
      }
      else if (formState.hasNoFasterThanTimeStandards === true && formState.eventHasNoFasterThanTimeStandard === true &&
        lcmBonusTimeInMilliseconds <= lcmLimitTimeInMilliseconds) {
        errors.lcmBonusTime = 'LCM Bonus Time must be slower than LCM No Faster Than Time';
      }
    }
  }

  return errors;
};


const OmeEventsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default OmeEventsDetailValidation;