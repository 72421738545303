const NavLinks = {
  SELECTIONS_NEW_SELECTION_SEARCH: '/selections/newselectionsearch',
  SELECTIONS_EXISTING_SELECTIONS: '/selections/existingselections',
  SELECTIONS_BY_ID: '/selections/byid',
  SELECTIONS_CONFIGURATION: '/selections/configuration',
  SELECTIONS_MEET_EVENTS: '/selections/meetevents',
  SELECTIONS_MEET_ENTRIES: '/selections/meetentries',
  SELECTIONS_PARTICIPANT_SELECTIONS: '/selections/participantselections',
  SELECTIONS_PARTICIPANT_SELECTIONS_DETAIL: '/selections/participantselections/detail',
  SELECTIONS_REPORTS: '/selections/reports',
  SELECTIONS_EXPORT: '/selections/export'
};

export default NavLinks;