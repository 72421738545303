import { useContext } from 'react';

import { OmeMeetDateValidationStateContext } from './OmeMeetDateValidationContextProvider';

import OmeMeetDateValidationData from './OmeMeetDateValidationData';

const useOmeMeetDateValidationData = () => {
  const [omeMeetDateValidationState, setOmeMeetDateValidationState] = useContext(OmeMeetDateValidationStateContext);

  const getOmeMeetDateValidation = (omeMeetId) => {
    OmeMeetDateValidationData.getOmeMeetDateValidationData(omeMeetId, omeMeetDateValidationState, setOmeMeetDateValidationState);
  };

  const clearOmeMeetDateValidationObjData = () => {
    setOmeMeetDateValidationState({
      ...omeMeetDateValidationState,
      isObjLoaded: false,
      isObjLoading: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      messsage: ''
    })
  }

  return {
    omeMeetDateValidationState,
    setOmeMeetDateValidationState,
    getOmeMeetDateValidation,
    clearOmeMeetDateValidationObjData
  };
};

export default useOmeMeetDateValidationData;