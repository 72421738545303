import React, { Fragment } from 'react';

import useOmeMeetTemplateSearch from './UseOmeMeetTemplateSearch';

import SearchMeet from '../../../common/components/searches/searchMeet/SearchMeet';
import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import global from '../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../common/utils/DateFunctions';
import Constants from '../../../common/utils/Constants';

const OmeMeetTemplateSearch = () => {
  const {
    omeMeetState,
    meetState,
    searchMeetState,
    onEditClicked,
    onCancelClicked
  } = useOmeMeetTemplateSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3
            className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
            excludeBar={true}>Online Meet Entry - Host</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className={"row"}>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Host:</b> {omeMeetState.selectedMeet?.meetHost} </p>
          <p className={global.HeaderText}><b>Meet Name:</b> {omeMeetState.selectedMeet?.meetName}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {formatDate(omeMeetState.selectedMeet?.startDate)} - {formatDate(omeMeetState.selectedMeet?.endDate)} </p>
          <p className={global.HeaderText}><b>Meet Classification:</b> {omeMeetState.selectedMeet?.meetClassificationName || ''}</p>
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <SearchMeet
            pageTitle={"Meet Template Search"}
            searchButtonText={"Search for Meet Template"}
            searchMeetState={searchMeetState}
            onEdit={onEditClicked}
            onCancelClicked={onCancelClicked}
            omeMeetResultsOnly={true}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetState.isObjLoading || meetState.isObjLoading} />
    </Fragment>
  );
};

export default OmeMeetTemplateSearch;