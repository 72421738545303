import { useContext } from 'react';

import { OrgGroupStateContext } from './OrgGroupContextProvider';

import OrgGroupData from './OrgGroupData';

const useOrgGroupData = () => {
  const [orgGroupState, setOrgGroupState] = useContext(OrgGroupStateContext);

  const getOrgGroupsByOrgUnitIdAndGroupTypeId = (orgUnitId, groupTypeId) => {
    OrgGroupData.getOrgGroupDataByOrgUnitIdAndGroupTypeIdData(orgUnitId, groupTypeId, orgGroupState, setOrgGroupState);
  };

  return {
    orgGroupState,
    setOrgGroupState,
    getOrgGroupsByOrgUnitIdAndGroupTypeId
  };
};

export default useOrgGroupData;