import { isValidDate, isValidBirthDate, isValidSwimTime } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';
import { convertTimeToMilliseconds, formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

export const localValidate = (formState) => {
  const errors = {};

  if (formState.relayTeamId < 0) {
    errors.relayTeamId = 'Relay Team Name is required';
  }

  if (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '') {
    errors.eventId = 'Event is required';
  }

  if (formState.isAggregate === false) {
    if (formState.hasTimeStandards === true) {
      const qualifyingStartDate = new Date(formState.qualifyingStartDate);
      const qualifyingEndDate = new Date(formState.qualifyingEndDate);
      const swimDate = new Date(formState.swimDate);

      if (swimDate < qualifyingStartDate || swimDate > qualifyingEndDate) {
        errors.swimDate = `Swim Date must be between the Qualifying Start Date (${formState.qualifyingStartDate}) and the Qualifying End Date (${formState.qualifyingEndDate})`
      }
    }

    if (formState.swimDate === Constants.BLANK_DATE_STRING) {
      errors.swimDate = 'Swim Date is required';
    } else if (!isValidDate(formState.swimDate)) {
      errors.swimDate = 'Swim Date must be a valid date'
    } else if (!isValidBirthDate(formState.swimDate)) {
      errors.swimDate = 'Swim Date cannot be in the future'
    }
  }

  if (formState.isAggregate === false) {
    if (formState.swimTime === '' || formState.swimTime === 'NT') {
      errors.swimTime = 'Swim Time is required';
    } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.swimTimeValue)))) {
      errors.swimTime = 'Swim Time must be a valid time';
    } else if (formState.eventId !== Constants.DEFAULT_ID) {
      if (formState.timeCuts?.length > 0) {
        const index = formState.timeCuts?.findIndex(x => x.qualifyingEventId === formState.eventId);
        if (index > -1) {
          const swimTimeTimeFormatted = formatTimeForFilterObject(formState.swimTime);
          const swimTimeTimeInMilliseconds = convertTimeToMilliseconds(swimTimeTimeFormatted);
          const noFasterThanTimeCutFormatted = formState.timeCuts[index]?.noFasterThanTimeCut ? formatTimeForFilterObject(formatTimeForDisplay(formState.timeCuts[index]?.noFasterThanTimeCut)) : null;
          const noFasterThanTimeCutInMilliseconds = noFasterThanTimeCutFormatted ? convertTimeToMilliseconds(noFasterThanTimeCutFormatted) : null;
          if (noFasterThanTimeCutInMilliseconds) {
            if (swimTimeTimeInMilliseconds < noFasterThanTimeCutInMilliseconds) {
              errors.swimTime = `Swim Time cannot be faster than the ${formState.eventName} No Faster Than Time Standard Cut (${formatTimeForDisplay(formState.timeCuts[index]?.noFasterThanTimeCut)})`;
            }
          }
          const noSlowerThanTimeCutFormatted = formState.timeCuts[index]?.noSlowerThanTimeCut ? formatTimeForFilterObject(formatTimeForDisplay(formState.timeCuts[index]?.noSlowerThanTimeCut)) : null;
          const noSlowerThanTimeCutInMilliseconds = noSlowerThanTimeCutFormatted ? convertTimeToMilliseconds(noSlowerThanTimeCutFormatted) : null;
          if (noSlowerThanTimeCutInMilliseconds) {
            if (swimTimeTimeInMilliseconds > noSlowerThanTimeCutInMilliseconds) {
              errors.swimTime = `Swim Time cannot be slower than the ${formState.eventName} Bonus Time Standard Cut (${formatTimeForDisplay(formState.timeCuts[index]?.noSlowerThanTimeCut)})`;
            }
          }
        }
      }
    }
  }

  if (formState.isAggregate === false) {
    if (formState.meetName.trim() === '') {
      errors.meetName = 'Meet Name is required'
    } else if (formState.meetName.trim().length > 200) {
      errors.meetName = 'Meet Name cannot exceed 200 characters'
    }
  }

  if (formState.isAggregate === true) {
    if (formState.athlete1PersonId === Constants.DEFAULT_ID) {
      errors.athlete1PersonId = 'A valid selection is required';
    }

    if (formState.athlete1PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete1PersonId === formState.athlete2PersonId) {
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete1PersonId === formState.athlete3PersonId) {
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete1PersonId === formState.athlete4PersonId) {
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete1SwimTime === '') {
      errors.athlete1SwimTime = 'Swim Time is required';
    } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.athlete1SwimTimeValue)))) {
      errors.athlete1SwimTime = 'Swim Time must be a valid time';
    }

    if (formState.athlete1EntryTimeMeetName.trim() === '' && formState.athlete1UsingOverrideTime === true) {
      errors.athlete1EntryTimeMeetName = 'Meet Name is required'
    } else if (formState.athlete1EntryTimeMeetName.trim().length > 200 && formState.athlete1UsingOverrideTime === true) {
      errors.athlete1EntryTimeMeetName = 'Meet Name cannot exceed 200 characters'
    }

    if (formState.athlete1UsingOverrideTime === true) {
      if (formState.athlete1EntrySwimDate === Constants.BLANK_DATE_STRING) {
        errors.athlete1EntrySwimDate = 'Swim Date is required';
      } else if (!isValidDate(formState.athlete1EntrySwimDate)) {
        errors.athlete1EntrySwimDate = 'Swim Date must be a valid date'
      } else if (!isValidBirthDate(formState.athlete1EntrySwimDate)) {
        errors.athlete1EntrySwimDate = 'Swim Date cannot be in the future'
      }

      if (formState.hasTimeStandards === true) {
        const qualifyingStartDate = new Date(formState.qualifyingStartDate);
        const qualifyingEndDate = new Date(formState.qualifyingEndDate);
        const athlete1EntrySwimDate = new Date(formState.athlete1EntrySwimDate);

        if (athlete1EntrySwimDate < qualifyingStartDate || athlete1EntrySwimDate > qualifyingEndDate) {
          errors.athlete1EntrySwimDate = `Swim Date must be between the Qualifying Start Date (${formState.qualifyingStartDate}) and the Qualifying End Date (${formState.qualifyingEndDate})`
        }
      }
    }

    if (formState.athlete2PersonId === Constants.DEFAULT_ID) {
      errors.athlete2PersonId = 'A valid selection is required';
    }

    if (formState.athlete2PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete2PersonId === formState.athlete1PersonId) {
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete2PersonId === formState.athlete3PersonId) {
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete2PersonId === formState.athlete4PersonId) {
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete2SwimTime === '') {
      errors.athlete2SwimTime = 'Swim Time is required';
    } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.athlete2SwimTimeValue)))) {
      errors.athlete2SwimTime = 'Swim Time must be a valid time';
    }

    if (formState.athlete2EntryTimeMeetName.trim() === '' && formState.athlete2UsingOverrideTime === true) {
      errors.athlete2EntryTimeMeetName = 'Meet Name is required'
    } else if (formState.athlete2EntryTimeMeetName.trim().length > 200 && formState.athlete2UsingOverrideTime === true) {
      errors.athlete2EntryTimeMeetName = 'Meet Name cannot exceed 200 characters'
    }

    if (formState.athlete2UsingOverrideTime === true) {
      if (formState.athlete2EntrySwimDate === Constants.BLANK_DATE_STRING) {
        errors.athlete2EntrySwimDate = 'Swim Date is required';
      } else if (!isValidDate(formState.athlete2EntrySwimDate)) {
        errors.athlete2EntrySwimDate = 'Swim Date must be a valid date'
      } else if (!isValidBirthDate(formState.athlete2EntrySwimDate)) {
        errors.athlete2EntrySwimDate = 'Swim Date cannot be in the future'
      }

      if (formState.hasTimeStandards === true) {
        const qualifyingStartDate = new Date(formState.qualifyingStartDate);
        const qualifyingEndDate = new Date(formState.qualifyingEndDate);
        const athlete2EntrySwimDate = new Date(formState.athlete2EntrySwimDate);

        if (athlete2EntrySwimDate < qualifyingStartDate || athlete2EntrySwimDate > qualifyingEndDate) {
          errors.athlete2EntrySwimDate = `Swim Date must be between the Qualifying Start Date (${formState.qualifyingStartDate}) and the Qualifying End Date (${formState.qualifyingEndDate})`
        }
      }
    }

    if (formState.athlete3PersonId === Constants.DEFAULT_ID) {
      errors.athlete3PersonId = 'A valid selection is required';
    }

    if (formState.athlete3PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete3PersonId === formState.athlete1PersonId) {
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete3PersonId === formState.athlete2PersonId) {
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete3PersonId === formState.athlete4PersonId) {
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete3SwimTime === '') {
      errors.athlete3SwimTime = 'Swim Time is required';
    } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.athlete3SwimTimeValue)))) {
      errors.athlete3SwimTime = 'Swim Time must be a valid time';
    }

    if (formState.athlete3EntryTimeMeetName.trim() === '' && formState.athlete3UsingOverrideTime === true) {
      errors.athlete3EntryTimeMeetName = 'Meet Name is required'
    } else if (formState.athlete3EntryTimeMeetName.trim().length > 200 && formState.athlete3UsingOverrideTime === true) {
      errors.athlete3EntryTimeMeetName = 'Meet Name cannot exceed 200 characters'
    }

    if (formState.athlete3UsingOverrideTime === true) {
      if (formState.athlete3EntrySwimDate === Constants.BLANK_DATE_STRING) {
        errors.athlete3EntrySwimDate = 'Swim Date is required';
      } else if (!isValidDate(formState.athlete3EntrySwimDate)) {
        errors.athlete3EntrySwimDate = 'Swim Date must be a valid date'
      } else if (!isValidBirthDate(formState.athlete3EntrySwimDate)) {
        errors.athlete3EntrySwimDate = 'Swim Date cannot be in the future'
      }

      if (formState.hasTimeStandards === true) {
        const qualifyingStartDate = new Date(formState.qualifyingStartDate);
        const qualifyingEndDate = new Date(formState.qualifyingEndDate);
        const athlete3EntrySwimDate = new Date(formState.athlete3EntrySwimDate);

        if (athlete3EntrySwimDate < qualifyingStartDate || athlete3EntrySwimDate > qualifyingEndDate) {
          errors.athlete3EntrySwimDate = `Swim Date must be between the Qualifying Start Date (${formState.qualifyingStartDate}) and the Qualifying End Date (${formState.qualifyingEndDate})`
        }
      }
    }

    if (formState.athlete4PersonId === Constants.DEFAULT_ID) {
      errors.athlete4PersonId = 'A valid selection is required';
    }

    if (formState.athlete3PersonId !== Constants.DEFAULT_ID) {
      if (formState.athlete4PersonId === formState.athlete1PersonId) {
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
        errors.athlete1PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete4PersonId === formState.athlete2PersonId) {
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
        errors.athlete2PersonId = 'Duplicate athletes are not allowed';
      }
      if (formState.athlete4PersonId === formState.athlete3PersonId) {
        errors.athlete4PersonId = 'Duplicate athletes are not allowed';
        errors.athlete3PersonId = 'Duplicate athletes are not allowed';
      }
    }

    if (formState.athlete4SwimTime === '') {
      errors.athlete4SwimTime = 'Swim Time is required';
    } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.athlete4SwimTimeValue)))) {
      errors.athlete4SwimTime = 'Swim Time must be a valid time';
    }

    if (formState.athlete4EntryTimeMeetName.trim() === '' && formState.athlete4UsingOverrideTime === true) {
      errors.athlete4EntryTimeMeetName = 'Meet Name is required'
    } else if (formState.athlete4EntryTimeMeetName.trim().length > 200 && formState.athlete4UsingOverrideTime === true) {
      errors.athlete4EntryTimeMeetName = 'Meet Name cannot exceed 200 characters'
    }

    if (formState.athlete4UsingOverrideTime === true) {
      if (formState.athlete4EntrySwimDate === Constants.BLANK_DATE_STRING) {
        errors.athlete4EntrySwimDate = 'Swim Date is required';
      } else if (!isValidDate(formState.athlete4EntrySwimDate)) {
        errors.athlete4EntrySwimDate = 'Swim Date must be a valid date'
      } else if (!isValidBirthDate(formState.athlete4EntrySwimDate)) {
        errors.athlete4EntrySwimDate = 'Swim Date cannot be in the future'
      }

      if (formState.hasTimeStandards === true) {
        const qualifyingStartDate = new Date(formState.qualifyingStartDate);
        const qualifyingEndDate = new Date(formState.qualifyingEndDate);
        const athlete4EntrySwimDate = new Date(formState.athlete4EntrySwimDate);

        if (athlete4EntrySwimDate < qualifyingStartDate || athlete4EntrySwimDate > qualifyingEndDate) {
          errors.athlete4EntrySwimDate = `Swim Date must be between the Qualifying Start Date (${formState.qualifyingStartDate}) and the Qualifying End Date (${formState.qualifyingEndDate})`
        }
      }
    }

  }

  return errors;
};

const OrgUnitAdminRelayEntriesDetailOverrideValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRelayEntriesDetailOverrideValidation;