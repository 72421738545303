import React, { Fragment } from "react";

import useAthleteUserPayments from './UseAthleteUserPayments';

//import Headings from "../../../../../common/components/headings/Headings";
//import Payment from "../../../../../common/components/payment/Payment";
import ShoppingCart from '../../../../../common/components/payments/ShoppingCart';

//import Constants from "../../../../../common/utils/Constants";

const AthleteUserPayments = () => {
  const {
    //omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    //items,
    instructionText,
    redirectToPurchases,
    redirectToEntries,
    orgUnitAthleteEntryId
  } = useAthleteUserPayments();

  console.log(orgUnitAthleteEntryId);

  return (
    // <Fragment>
    //   <div className="row">
    //     <div className="col-xs-12">
    //       <Headings.H3 isBarOnBottom={false}>Payment</Headings.H3>
    //     </div>
    //     {instructionText && (
    //       <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: instructionText }}></div>
    //     )}
    //     <div className="col-xs-12 usas-extra-top-margin">
    //       <Payment
    //         items={items}
    //         successMessage={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData.status !== Constants.WORKFLOW_STATUS_SUBMITTED ? 'Your athlete entry has been submitted.' : ''}
    //         onCompletion={redirectToEntries}
    //         onCancel={redirectToPurchases}
    //       />
    //     </div>
    //   </div>
    // </Fragment>
    <ShoppingCart title="Payment" isBarOnBottom={false} instructionText={instructionText} itemSubsystemId={3} itemContract={11} referenceId={orgUnitAthleteEntryId} onPaymentCompletion={redirectToEntries} onPaymentCancel={redirectToPurchases}></ShoppingCart>
  );
};

export default AthleteUserPayments;
