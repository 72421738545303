import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeRestrictionsValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Restriction?',
  restrictionType: '',
  restriction: '',
  restrictionTypeRoute: ''
};

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  restrictionTypeRoute: ''
};

const INITIAL_FORM_STATE = {
  usCitizenOnly: '',
  nationalTeamOnly: '',
  allowRepOtherCountry: '',
  allowUnattachedAthleteEntry: '',
  allowUnattachedRoster: '',
  status: ''
}

const useOmeRestrictions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { formState, errorState, onFormValueChanged, isSubmitting, handleSubmit, setIsDirty,
    isDirty, setFormData, setErrors } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const RESTRICTION_TYPE_ROUTES = {
    Membership: omeMeetState.route === '' ? HostNavLinks.OME_MEET_RESTRICTIONS_TYPE_MEMBERSHIP : NavLinks.MEET_HOST_RESTRICTIONS_TYPE_MEMBERSHIP,
    CompetitionCategory: omeMeetState.route === '' ? HostNavLinks.OME_MEET_RESTRICTIONS_TYPE_COMPETITION_CATEGORY : NavLinks.MEET_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY,
    Age: omeMeetState.route === '' ? HostNavLinks.OME_MEET_RESTRICTIONS_TYPE_AGE : NavLinks.MEET_HOST_RESTRICTIONS_TYPE_AGE
  };

  const tryValidateBeforeRedirect = async (submitButton, restrictionTypeRoute) => {
    const errors = await validate(formState) || {};
    if (submitButton !== HostRegistrationConstants.ADD && submitButton !== HostRegistrationConstants.EDIT) {
      if (omeMeetState.objData.omeMeetEligibility[0].omeMeetEligibilityAge?.length === 0) {
        errors.general = 'Adding an age restriction is required. Please use the "Add Restriction" button to add an age restriction.';
      }
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true, submitButton: submitButton, restrictionTypeRoute: restrictionTypeRoute ? restrictionTypeRoute : '' });
    }
  };

  const tryValidateBeforeShowingDeletePopUp = async (restrictionTypeRoute, restrictionType, restrictionFormatted) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setModalState({
        ...modalState,
        displayPopUp: true,
        restrictionType: restrictionType,
        restriction: restrictionFormatted,
        restrictionTypeRoute: restrictionTypeRoute
      });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.BACK);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.BACK });
    }
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      handleSubmit();
      setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT });
    }
  };

  const onAddRestrictionClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.ADD);
  };

  const onEditRestrictionClicked = (e, restrictionTypeRoute) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    tryValidateBeforeRedirect(HostRegistrationConstants.EDIT, restrictionTypeRoute);
  };

  const onDeleteRestrictionClicked = (e, restrictionTypeRoute, restrictionType, data) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    let restrictionFormatted = ''
    if (restrictionTypeRoute === RESTRICTION_TYPE_ROUTES.Membership) {
      restrictionFormatted = data.map((membershipRestriction, i) =>
        <span key={i + 'membershipRestriction'}>{membershipRestriction.orgRole?.orgRoleName || ''}{omeMeetState.objData?.omeMeetEligibility[0]?.omeMeetEligibilityOrgRole.length - 1 === i ? '' : ', '}</span>);
    }
    else if (restrictionTypeRoute === RESTRICTION_TYPE_ROUTES.CompetitionCategory) {
      restrictionFormatted = data;
    }
    else if (restrictionTypeRoute === RESTRICTION_TYPE_ROUTES.Age) {
      restrictionFormatted = <span>{data.minAge >= 0 ? data.minAge : ''} - {data.maxAge >= 0 ? data.maxAge : ''}</span>;
    }
    tryValidateBeforeShowingDeletePopUp(restrictionTypeRoute, restrictionType, restrictionFormatted);
  };

  const onDeleteRestriction = (e, restrictionTypeRoute) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();

    let updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    if (isDirty === true) {
      updatedOmeMeet = editOmeMeetCopy(updatedOmeMeet);
    }

    if (restrictionTypeRoute === RESTRICTION_TYPE_ROUTES.Membership) {
      updatedOmeMeet.omeMeetEligibility[0].omeMeetEligibilityOrgRole = [];
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    }
    else if (restrictionTypeRoute === RESTRICTION_TYPE_ROUTES.CompetitionCategory) {
      updatedOmeMeet.omeMeetEligibility[0].omeMeetEligibilityCompetitionGenderType = [];
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    }
    else if (restrictionTypeRoute === RESTRICTION_TYPE_ROUTES.Age) {
      updatedOmeMeet.omeMeetEligibility[0].omeMeetEligibilityAge = [];
      updatedOmeMeet.meet = undefined;
      putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const editOmeMeetCopy = (updatedOmeMeet) => {
    updatedOmeMeet.omeMeetEligibility[0] = {
      ...updatedOmeMeet.omeMeetEligibility[0],
      usCitizenOnly: formState.usCitizenOnly,
      nationalTeamOnly: formState.nationalTeamOnly,
      allowRepOtherCountry: formState.allowRepOtherCountry,
      allowUnattachedRoster: formState.allowUnattachedRoster,
      allowUnattachedAthleteEntry: formState.allowUnattachedAthleteEntry
    }

    return updatedOmeMeet;
  }

  function submitFormCallback(formState) {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));
    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, editOmeMeetCopy(updatedOmeMeet));
    setState({ ...state, tryRedirect: true });
  }

  function formStateHasBeenSaved(restrictionFormState) {
    //compare formState to omeMeetState to see if formState has been saved
    if (restrictionFormState?.usCitizenOnly !== omeMeetState?.objData?.omeMeetEligibility[0]?.usCitizenOnly
      && (restrictionFormState?.usCitizenOnly !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.usCitizenOnly !== null)) {
      return false;
    }
    if (restrictionFormState?.nationalTeamOnly !== omeMeetState?.objData?.omeMeetEligibility[0]?.nationalTeamOnly
      && (restrictionFormState?.nationalTeamOnly !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.nationalTeamOnly !== null)) {
      return false;
    }
    if (restrictionFormState?.allowRepOtherCountry !== omeMeetState?.objData?.omeMeetEligibility[0]?.allowRepOtherCountry
      && (restrictionFormState?.allowRepOtherCountry !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.allowRepOtherCountry !== null)) {
      return false;
    }
    if (restrictionFormState?.allowUnattachedRoster !== omeMeetState?.objData?.omeMeetEligibility[0]?.allowUnattachedRoster
      && (restrictionFormState?.allowUnattachedRoster !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.allowUnattachedRoster !== null)) {
      return false;
    }
    if (restrictionFormState?.allowUnattachedAthleteEntry !== omeMeetState?.objData?.omeMeetEligibility[0]?.allowUnattachedAthleteEntry
      && (restrictionFormState?.allowUnattachedAthleteEntry !== '' || omeMeetState?.objData?.omeMeetEligibility[0]?.allowUnattachedAthleteEntry !== null)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      //Set formState from location.state if 'Back' button was clicked on any restriction detail page
      if (location.state && location.state?.restrictionFormState) {
        setFormData({
          ...formState,
          usCitizenOnly: location.state?.restrictionFormState?.usCitizenOnly ?? '',
          nationalTeamOnly: location.state?.restrictionFormState?.nationalTeamOnly ?? '',
          allowRepOtherCountry: location.state?.restrictionFormState?.allowRepOtherCountry ?? '',
          allowUnattachedRoster: location.state?.restrictionFormState?.allowUnattachedRoster ?? '',
          allowUnattachedAthleteEntry: location.state?.restrictionFormState?.allowUnattachedAthleteEntry ?? '',
          status: omeMeetState.objData.status || ''
        });
        if (formStateHasBeenSaved(location.state?.restrictionFormState) === false) {
          setIsDirty(true);
        }
      }
      else {
        //Set formState from omeMeetState
        setFormData({
          ...formState,
          usCitizenOnly: omeMeetState?.objData?.omeMeetEligibility[0]?.usCitizenOnly ?? '',
          nationalTeamOnly: omeMeetState?.objData?.omeMeetEligibility[0]?.nationalTeamOnly ?? '',
          allowRepOtherCountry: omeMeetState?.objData?.omeMeetEligibility[0]?.allowRepOtherCountry ?? '',
          allowUnattachedRoster: omeMeetState?.objData?.omeMeetEligibility[0]?.allowUnattachedRoster ?? '',
          allowUnattachedAthleteEntry: omeMeetState?.objData?.omeMeetEligibility[0]?.allowUnattachedAthleteEntry ?? '',
          status: omeMeetState.objData.status || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState.objData.omeMeetEligibility[0].omeMeetEligibilityAge?.length === 0) {
          let errors = {};
          errors.general = 'Adding an age restriction is required. Please use the "Add Restriction" button to add an age restriction.';
          setErrors(errors);
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_INVITATIONS);
          }
          else {
            navigate(HostNavLinks.OME_MEET_INVITATIONS);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.objData.omeMeetEligibility[0].omeMeetEligibilityAge?.length === 0) {
          let errors = {};
          errors.general = 'Adding an age restriction is required. Please use the "Add Restriction" button to add an age restriction.';
          setErrors(errors);
        }
        else {
          if (omeMeetState.route !== '') {
            setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
            navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
          }
          else {
            navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.objData.omeMeetEligibility[0].omeMeetEligibilityAge?.length === 0) {
          let errors = {};
          errors.general = 'Adding an age restriction is required. Please use the "Add Restriction" button to add an age restriction.';
          setErrors(errors);
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_ELIGIBILITY);
          }
          else {
            navigate(HostNavLinks.OME_MEET_ELIGIBILITY);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.ADD) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_RESTRICTIONS_TYPE, { state: { restrictionFormState: formState } });
        }
        else {
          navigate(HostNavLinks.OME_MEET_RESTRICTIONS_TYPE, { state: { restrictionFormState: formState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.EDIT && state.restrictionTypeRoute !== '') {
        navigate(state.restrictionTypeRoute, { state: { restrictionFormState: formState } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, state]);

  function allowAllEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryStartDate === true) {
        return true;
      }
      return false;
    }

    return true;
  };

  return {
    RESTRICTION_TYPE_ROUTES,
    omeMeetState,
    modalState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onModalCanceled,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddRestrictionClicked,
    onEditRestrictionClicked,
    onDeleteRestrictionClicked,
    onDeleteRestriction,
    formStateHasBeenSaved,
    allowAllEdits
  };
};

export default useOmeRestrictions;
