import React, { Fragment } from 'react';

import useOmeAlternateEventDetail from './UseOmeAlternateEventDetail';

import Views from '../HostRegistrationViews';
import ViewTemplate from '../../../../components/viewTemplate/ViewTemplate';

import TimeInput from '../../../../../common/components/inputs/TimeInput';
import EventCombobox from '../../../../../common/components/comboboxes/eventCombobox/EventCombobox';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OmeAlternateEventDetail = () => {
  const {
    state,
    setState,
    omeMeetState,
    formState,
    errorState,
    handleSubmit,
    eventCompetitionGenderState,
    timeStandardTypeState,
    timeStandardAgeGroupState,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onBackButtonClicked,
    onChangeEvent
  } = useOmeAlternateEventDetail();

  return (
    <Fragment>
      <ViewTemplate
        viewName={Views.OME_MEET_EVENTS_DETAIL_ALTERNATE}
        onNextButtonClicked={onNextButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        errorMessage={errorState.error}>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-xs-12">
              <p className={global.HeaderText}><b>Selected Event:</b> {formState.selectedEvent?.event?.eventName || ''} ({formState.selectedEvent?.eventCompetitionGenderType?.typeName || ''}, {formState.meetAgeGroupName || ''}), Session {formState.meetSessionName || ''}</p>
            </div>
            <div className={formState.alternateMeetEventId === Constants.DEFAULT_ID ? global.DisplayCompoment : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
                <EventCombobox
                  label={"Alternate Event*"}
                  name={"alternateEventId"}
                  valueToMatch={formState.alternateEventName}
                  error={errorState.alternateEventId}
                  message={errorState.alternateEventId}
                  onChange={(newValue, newValueLabel, e) => { setState({ ...state, updateTimeCuts: true }); onChangeEvent(newValue, newValueLabel, e) }}
                />
              </div>
            </div>
            <div className={formState.alternateMeetEventId !== Constants.DEFAULT_ID ? global.DisplayCompoment : global.HideComponent}>
              <div className="col-xs-12">
                <p className={global.HeaderText}><b>Alternate Event:</b> {formState.alternateEventName}</p>
              </div>
            </div>
            <div className={formState.alternateEventId > 0 &&
              (formState.scyPossibleForAlternateEvent === true || formState.scmPossibleForAlternateEvent === true || formState.lcmPossibleForAlternateEvent === true) ? global.DisplayCompoment : global.HideComponent}>
              <div className={formState.hasNoSlowerThanTimeStandards === true ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12'>
                  <ReadOnly
                    label="No Slower Than Time Standard Name (Time Standard Cut for Selected Alternate Event and Event Competition Category)"
                    name="noSlowerThanTimeStandardName"
                    value={formState.noSlowerThanTimeStandardName + (formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? (` (SCY: ${formState.scyNoSlowerThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? (` (SCM: ${formState.scmNoSlowerThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? (` (LCM: ${formState.lcmNoSlowerThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '')}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Alternate Event uses No Slower Than Time?*'
                    name='alternateEventHasNoSlowerThanTimeStandard'
                    checked={formState.alternateEventHasNoSlowerThanTimeStandard}
                    error={errorState.alternateEventHasNoSlowerThanTimeStandard}
                    message={errorState.alternateEventHasNoSlowerThanTimeStandard}
                    onChange={(value) => { onFormValueChanged('alternateEventHasNoSlowerThanTimeStandard', value); }} />
                </div>
                <div className={formState.alternateEventHasNoSlowerThanTimeStandard === true ? global.DisplayCompoment : global.HideComponent}>
                  <div className={formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCY No Slower Than Time*"
                        name="scyQualifyingTime"
                        value={formState.scyQualifyingTime}
                        error={errorState.scyQualifyingTime}
                        message={errorState.scyQualifyingTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scyQualifyingTime', value, 'scyQualifyingTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCM No Slower Than Time*"
                        name="scmQualifyingTime"
                        value={formState.scmQualifyingTime}
                        error={errorState.scmQualifyingTime}
                        message={errorState.scmQualifyingTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scmQualifyingTime', value, 'scmQualifyingTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="LCM No Slower Than Time*"
                        name="lcmQualifyingTime"
                        value={formState.lcmQualifyingTime}
                        error={errorState.lcmQualifyingTime}
                        message={errorState.lcmQualifyingTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'lcmQualifyingTime', value, 'lcmQualifyingTimeValue'); }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={formState.hasNoFasterThanTimeStandards === true ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12'>
                  <ReadOnly
                    label="No Faster Than Time Standard Name (Time Standard Cut for Selected Alternate Event and Event Competition Category)"
                    name="noFasterThanTimeStandardName"
                    value={formState.noFasterThanTimeStandardName + (formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? (` (SCY: ${formState.scyNoFasterThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? (` (SCM: ${formState.scmNoFasterThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? (` (LCM: ${formState.lcmNoFasterThanTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '')}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Alternate Event uses No Faster Than Time?*'
                    name='alternateEventHasNoFasterThanTimeStandard'
                    checked={formState.alternateEventHasNoFasterThanTimeStandard}
                    error={errorState.alternateEventHasNoFasterThanTimeStandard}
                    message={errorState.alternateEventHasNoFasterThanTimeStandard}
                    onChange={(value) => { onFormValueChanged('alternateEventHasNoFasterThanTimeStandard', value); }} />
                </div>
                <div className={formState.alternateEventHasNoFasterThanTimeStandard === true ? global.DisplayCompoment : global.HideComponent}>
                  <div className={formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCY No Faster Than Time*"
                        name="scyLimitTime"
                        value={formState.scyLimitTime}
                        error={errorState.scyLimitTime}
                        message={errorState.scyLimitTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scyLimitTime', value, 'scyLimitTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCM No Faster Than Time*"
                        name="scmLimitTime"
                        value={formState.scmLimitTime}
                        error={errorState.scmLimitTime}
                        message={errorState.scmLimitTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scmLimitTime', value, 'scmLimitTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="LCM No Faster Than Time*"
                        name="lcmLimitTime"
                        value={formState.lcmLimitTime}
                        error={errorState.lcmLimitTime}
                        message={errorState.lcmLimitTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'lcmLimitTime', value, 'lcmLimitTimeValue'); }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={formState.offeredAsBonusEvent === true && formState.hasBonusTimeStandards === true && formState.isAlternateRelay === false
                ? global.DisplayCompoment : global.HideComponent}>
                <div className='col-xs-12'>
                  <ReadOnly
                    label="Bonus Time Standard Name (Time Standard Cut for Selected Alternate Event and Event Competition Category)"
                    name="bonusTimeStandardName"
                    value={formState.bonusTimeStandardName + (formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? (` (SCY: ${formState.scyBonusTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? (` (SCM: ${formState.scmBonusTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '') + (formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? (` (LCM: ${formState.lcmBonusTimeStandardSwimTime || 'No Associated Time Standard Cut'})`) : '')}
                  />
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <YesNoSwitch
                    label='Alternate Event uses Bonus Time?*'
                    name='alternateEventHasBonusTimeStandard'
                    checked={formState.alternateEventHasBonusTimeStandard}
                    error={errorState.alternateEventHasBonusTimeStandard}
                    message={errorState.alternateEventHasBonusTimeStandard}
                    onChange={(value) => { onFormValueChanged('alternateEventHasBonusTimeStandard', value); }} />
                </div>
                <div className={formState.alternateEventHasBonusTimeStandard === true ? global.DisplayCompoment : global.HideComponent}>
                  <div className={formState.showScy === true && formState.scyPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCY Bonus Time*"
                        name="scyBonusTime"
                        value={formState.scyBonusTime}
                        error={errorState.scyBonusTime}
                        message={errorState.scyBonusTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scyBonusTime', value, 'scyBonusTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showScm === true && formState.scmPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="SCM Bonus Time*"
                        name="scmBonusTime"
                        value={formState.scmBonusTime}
                        error={errorState.scmBonusTime}
                        message={errorState.scmBonusTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'scmBonusTime', value, 'scmBonusTimeValue'); }} />
                    </div>
                  </div>
                  <div className={formState.showLcm === true && formState.lcmPossibleForAlternateEvent === true ? global.DisplayCompoment : global.HideComponent}>
                    <div className='col-xs-12 col-sm-6'>
                      <TimeInput
                        label="LCM Bonus Time*"
                        name="lcmBonusTime"
                        value={formState.lcmBonusTime}
                        error={errorState.lcmBonusTime}
                        message={errorState.lcmBonusTime}
                        onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'lcmBonusTime', value, 'lcmBonusTimeValue'); }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={eventCompetitionGenderState.isArrayLoading === true || timeStandardTypeState.isArrayLoading === true
            || timeStandardAgeGroupState.isArrayLoading === true} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={omeMeetState.isSaving} />
      </ViewTemplate>
    </Fragment >
  );
};

export default OmeAlternateEventDetail;