import React, { Fragment } from 'react';

import useOrgUnitAdminRelayEntriesDetailOverride from './UseOrgUnitAdminRelayEntriesDetailOverride';

import OrgUnitAdminRelayEntryOverrideSelectionGrid from './OrgUnitAdminRelayEntryOverrideSelectionGrid';

import EventMeetEventDropdown from '../../../../components/dropdowns/EventMeetEventDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import TimeInput from '../../../../../common/components/inputs/TimeInput';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import Input from '../../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRelayEntriesDetailOverride = () => {
  const { state, gridState, calculatedTeamEntryTimeState, isLarge, omeMeetState, omeMeetOrgUnitEntryId, relayTeamOptionsState, omeMeetOrgUnitAthleteEntryRelayRosterEntryState,
    onSaveClicked, onBackClicked, onValueTextPairChanged, onFormValueChanged, formState, errorState, MSG, omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState, eventMeetEventState,
    setHandleEventChange } = useOrgUnitAdminRelayEntriesDetailOverride();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Relay Event Entry Override Time</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <p className={global.HeaderText}><b>Relay Event:</b> {state.event?.eventName || ''} ({state.event?.eventCompetitionGenderTypeName || ''}, {state.event?.ageGroupName || ''}), Session {state.event?.meetSessionName || ''}</p>
          {(state.event?.timeCuts && state.event?.timeCuts.length > 0) &&
            <p className={global.HeaderText}><b>Qualifying Date Range:</b> {`${formState.qualifyingStartDate} - ${formState.qualifyingEndDate}`}</p>}
        </div>
      </div>
      <form>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Add Relay Via Aggregate?"
              name="isAggregate"
              checked={formState.isAggregate}
              error={errorState.isAggregate}
              message={errorState.isAggregate}
              onChange={(value) => { onFormValueChanged('isAggregate', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Relay Team Name*"
              name="relayTeamId"
              options={relayTeamOptionsState.options}
              value={formState.relayTeamId}
              error={errorState.relayTeamId}
              message={errorState.relayTeamId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'relayTeamId', newValueLabel, 'relayTeamName');
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <EventMeetEventDropdown
              label="Event*"
              name="eventId"
              value={formState.eventId}
              error={errorState.eventId}
              message={errorState.eventId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  setHandleEventChange(true);
                onValueTextPairChanged(value, 'eventId', newValueLabel, 'eventName')
              }}
              meetEventId={state.event?.meetEventId}
            />
          </div>
          {formState.isAggregate === false &&
            <div className={formState.isAggregate === false ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <TimeInput
                  label="Swim Time*"
                  name="swimTime"
                  value={formState.swimTime}
                  error={errorState.swimTime}
                  message={errorState.swimTime}
                  onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'swimTime', value, 'swimTimeValue'); }} />
              </div>
            </div>}
          {formState.isAggregate === false &&
            <div className={formState.isAggregate === false ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Swim Date*"
                  name="swimDate"
                  value={formState.swimDate}
                  error={errorState.swimDate}
                  message={errorState.swimDate}
                  onChange={(value) => { onFormValueChanged('swimDate', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label={"Meet Name*"}
                  name="meetName"
                  value={formState.meetName}
                  error={errorState.meetName}
                  message={errorState.meetName}
                  onChange={(value) => { onFormValueChanged('meetName', value) }} />
              </div>
            </div>}
        </div>
        {formState.isAggregate === true &&
          <div className={formState.isAggregate === true && formState.eventId !== Constants.DEFAULT_ID ? global.DisplayComponent : global.HideComponent}>
            <div className="row">
              <div className="col-xs-12 usas-extra-top-margin">
                <p className={global.HeaderText}><b>Team Entry Time:</b> {calculatedTeamEntryTimeState.entryTime || 'NT'}  {calculatedTeamEntryTimeState.entryTime !== MSG && calculatedTeamEntryTimeState.entryTime && formState.courseCode ? formState.courseCode : ''}</p>
              </div>
              <div className="col-xs-12">
                <p><b>For their name to appear as a relay leg option, an athlete{omeMeetState?.objData.omeMeetEligibility[0]?.relayTeamFromRosterOnly === true ? ' must be on the roster and' : ''} cannot be associated with an exisiting relay team for the event</b></p>
              </div>
              <div className=" col-xs-12 usas-extra-bottom-margin">
                <OrgUnitAdminRelayEntryOverrideSelectionGrid
                  gridData={gridState.gridData}
                  isLoading={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isArrayLoading}
                  meetEventId={state.event?.meetEventId}
                  omeMeetOrgUnitEntryId={omeMeetOrgUnitEntryId}
                  formState={formState}
                  errorState={errorState}
                  onValueTextPairChanged={onValueTextPairChanged}
                  onFormValueChanged={onFormValueChanged}
                  isLarge={isLarge}
                />
              </div>
            </div>
          </div>}
      </form>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryAggregateRelayAthleteState.isObjLoading || eventMeetEventState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryRelayRosterEntryState.isSaving} />
    </Fragment >
  );
};

export default OrgUnitAdminRelayEntriesDetailOverride;