import React, { Fragment } from 'react';

import useOrgUnitAdminRosterEntriesDetail from './UseOrgUnitAdminRosterEntriesDetail';

import OrgUnitAdminRosterEntriesDetailGrid from './OrgUnitAdminRosterEntriesDetailGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgUnitAdminRosterEntriesDetail = () => {
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, orgUnitAthleteEntryIndividualRosterEntryState,
    state, gridState, athleteMeetEventState, onAddEventClicked, onAddBonusEventClicked, onDeleteEventClicked, onDeleteEvent,
    onApprovePendingEventsClicked, onBackClicked, modalState, onModalCanceled, omeMeetOrgUnitAthleteEntryState, errorState
  } = useOrgUnitAdminRosterEntriesDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Athlete Events</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <Headings.H4 className={global.HeaderText}>Athlete: {(state?.athlete?.firstName || state?.athlete?.middleName || state?.athlete?.lastName) ? `${state?.athlete?.firstName || ''} ${state?.athlete?.preferredName !== '' && state?.athlete?.preferredName !== state?.athlete?.firstName ? '"' + state?.athlete?.preferredName + '"' : ''} ${state?.athlete?.middleName || ''} ${state?.athlete?.lastName || ''}` : ''} ({state?.athlete?.competitionGenderTypeName || ''}, {state?.athlete?.ageAtMeet >= 0 ? state?.athlete?.ageAtMeet : ''})</Headings.H4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onAddEventClicked}>Select Events</ActionIntraPageButton>&nbsp;
          {state.bonusEvents === true && <Fragment><ActionIntraPageButton onClick={onAddBonusEventClicked}>Select Bonus Events</ActionIntraPageButton>&nbsp;</Fragment>}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <OrgUnitAdminRosterEntriesDetailGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading}
            onDeleteEventClicked={onDeleteEventClicked}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          {state?.athlete?.status === Constants.WORKFLOW_STATUS_EVENT_APPROVAL_REQUIRED &&
            <Fragment><PrimaryButton type="button" onClick={onApprovePendingEventsClicked}>Approve Events</PrimaryButton>&nbsp;</Fragment>}
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Athlete:</strong> {(state?.athlete?.firstName || state?.athlete?.middleName || state?.athlete?.lastName) ? `${state?.athlete?.firstName || ''} ${state?.athlete?.preferredName !== '' && state?.athlete?.preferredName !== state?.athlete?.firstName ? '"' + state?.athlete?.preferredName + '"' : ''} ${state?.athlete?.middleName || ''} ${state?.athlete?.lastName || ''}` : ''} ({state?.athlete?.competitionGenderTypeName || ''}, {state?.athlete?.ageAtMeet >= 0 ? state?.athlete?.ageAtMeet : ''})</p>
            <p><strong>Event - Course:</strong> {modalState.eventName}</p>
            <p><strong>Competition Category:</strong> {modalState.eventCompetitionGenderTypeName}</p>
            <p><strong>Age Group:</strong> {modalState.ageGroupName}</p>
            <p><strong>Entry Time:</strong> {modalState.entryTime} {modalState?.qualifyingCourseCode}</p>
            <p><strong>Bonus Entry:</strong> {modalState.qualifiedAsBonus}</p>
            <p><strong>Override Entry:</strong> {modalState.override}</p>
            <p><strong>Status:</strong> {modalState.status}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteEvent(e, modalState.individualAthleteMeetEventId)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitAthleteEntryIndividualRosterEntryState.isSaving || athleteMeetEventState.isSaving} />
    </Fragment>
  );
};

export default OrgUnitAdminRosterEntriesDetail;