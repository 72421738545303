import { useState, useEffect } from 'react';

import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

const useDateRangeDropdown = (startDate, endDate) => {
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      const dateOptions = [];
      const theStartDate = new Date(startDate);
      const theStopDate = new Date(endDate);

      let theCurrentDate = theStartDate;
      let i = 1;
      while (theCurrentDate <= theStopDate) {
        dateOptions.push({ id: i, name: formatDate(theCurrentDate) });

        theCurrentDate.setDate(theCurrentDate.getDate() + 1);
        i += 1;
      }

      dateOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' });

      setOptionsState(dateOptions);
    }
  }, [startDate, endDate]);

  return {
    options: optionsState
  };
};

export default useDateRangeDropdown;