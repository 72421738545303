import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import Input from '../../../../../common/components/inputs/Input';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ laneSheetHeat, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <button
      className={global.IconButton}
      type="button"
      onClick={() => onDeleteClicked(laneSheetHeat)}>
      <DeleteIcon />
    </button>
    <button
      className={global.IconButtonMobileMargin}
      type="button"
      onClick={() => onEditClicked(laneSheetHeat)}>
      <EditIcon />
    </button>
  </Fragment>
);

const SmallGridRow = ({ showArchived, laneSheetHeat, onUpdateDisplayOrder, onUpdateIsSelected, onEditClicked, onDeleteClicked }) => {
  if ((showArchived === true && laneSheetHeat?.isArchived === true) || (showArchived === false && laneSheetHeat?.isArchived === false)) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span></span>&nbsp;
            <SmallGridRowIcons
              laneSheetHeat={laneSheetHeat}
              onUpdateDisplayOrder={onUpdateDisplayOrder}
              onEditClicked={onEditClicked}
              onDeleteClicked={onDeleteClicked} />
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Event Name
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {laneSheetHeat?.eventName || ''}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Competition Category
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {laneSheetHeat?.eventCompetitionGenderTypeName || ''}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Session Type
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {laneSheetHeat?.sessionTypeName || ''}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Heat
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {laneSheetHeat?.heatNumber || ''}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {laneSheetHeat?.heatDate ? formatDate(laneSheetHeat.heatDate) : ''}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Display Order
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                <Input
                  name={"displayOrder" + laneSheetHeat.laneSheetHeatId}
                  type="number"
                  min="0"
                  max="10000"
                  value={laneSheetHeat.displayOrder}
                  onChange={(value) => { onUpdateDisplayOrder(laneSheetHeat, value) }} />
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Archived?
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {laneSheetHeat?.isArchived === true ? 'Yes' : 'No'}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Selected?
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                <Checkbox
                  label=""
                  name={"isSelected" + laneSheetHeat.laneSheetHeatId}
                  onChange={() => { onUpdateIsSelected(laneSheetHeat) }} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  else {
    return (
      <Fragment />
    )
  }
}

const LaneSheetHeatSmallGrid = ({ showArchived, isLoading, gridData, onUpdateDisplayOrder, onUpdateIsSelected, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((laneSheetHeat, i) =>
          <SmallGridRow
            key={i}
            showArchived={showArchived}
            laneSheetHeat={laneSheetHeat}
            onUpdateDisplayOrder={onUpdateDisplayOrder}
            onUpdateIsSelected={onUpdateIsSelected}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
          </div>
        )
    }
  </div>
);

export default LaneSheetHeatSmallGrid;