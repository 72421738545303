import React, { createContext, useState } from 'react';

import OmeMeetData from './OmeMeetData';

export const OmeMeetStateContext = createContext();

const OmeMeetContextProvider = ({ children }) => {
  const stateHook = useState(OmeMeetData.INITIAL_STATE);

  return (
    <OmeMeetStateContext.Provider value={stateHook}>
      {children}
    </OmeMeetStateContext.Provider>
  );
};

export default OmeMeetContextProvider;