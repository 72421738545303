import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import validate from './OrgUnitAdminRosterSecondaryRepValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitNonAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryData';
import useOUAdminEntryContextData from '../../../../state/ouAdminEntryContextView/UseOUAdminEntryContextData';
import useAthleteSecondaryRepresentationData from '../../../../state/athleteSecondaryRepresentation/UseAthleteSecondaryRepresentationData';
import useApprovedClubsForSecondaryRepData from '../../../../state/approvedClubsForSecondaryRep/UseApprovedClubsForSecondaryRepData';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';
import UseForm from '../../../../../common/utils/UseForm';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Athlete from Roster?',
  athleteName: '',
  competitionCategory: '',
  birthDate: '',
  ageAtMeet: '',
  status: '',
  isRelayOnlyAthlete: '',
  orgUnitAthleteEntryId: Constants.DEFAULT_ID
};

const SECONDARY_REP_INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: "Athlete's Secondary Representation",
  athleteName: '',
  competitionCategory: '',
  birthDate: '',
  ageAtMeet: '',
  status: '',
  isRelayOnlyAthlete: '',
  orgUnitAthleteEntryId: Constants.DEFAULT_ID,
  athleteSecondaryRepresentationId: Constants.DEFAULT_ID,
  secondaryOrgUnitId: Constants.DEFAULT_ID,
  secondaryOrgUnitName: ''
};

const INITIAL_FORM_STATE = {
  secondaryOrgUnitId: Constants.DEFAULT_ID,
  secondaryOrgUnitName: '',
  teamOrgUnitId: Constants.DEFAULT_ID
}

const useOrgUnitAdminRoster = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster,
    deleteOrgUnitAthleteEntry } = useOmeMeetOrgUnitAthleteEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
    = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitNonAthleteEntryState, getOrgUnitNonAthleteEntryCoaches } = useOmeMeetOrgUnitNonAthleteEntryData();
  const { athleteSecondaryRepresentationState, postAthleteSecondaryRepresentation, deleteAthleteSecondaryRepresentation } = useAthleteSecondaryRepresentationData();
  const { approvedClubsForSecondaryRepState, getApprovedClubsForSecondaryRep } = useApprovedClubsForSecondaryRepData();
  const { oUAdminEntryContextState } = useOUAdminEntryContextData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [secondaryRepModalState, setSecondaryRepModalState] = useState(SECONDARY_REP_INITIAL_MODAL_STATE);
  const [state, setState] = useState({ tryGet: false });
  const { formState, errorState, setErrors, setFormData, handleSubmit, onValueTextPairChanged } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onAddAthlete = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_SELECTION);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_SELECTION);
    }
  };

  const onDeleteAthleteClicked = (e, athlete) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      athleteName: (athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : '',
      competitionCategory: athlete.competitionGenderTypeName || '',
      birthDate: athlete.birthDate ? formatDate(athlete.birthDate) : '',
      ageAtMeet: athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : '',
      status: athlete.status || '',
      isRelayOnlyAthlete: athlete.isRelayOnlyAthlete === true ? 'Yes' : athlete.isRelayOnlyAthlete === false ? 'No' : '',
      orgUnitAthleteEntryId: athlete.orgUnitAthleteEntryId || Constants.DEFAULT_ID
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteAthlete = (e, orgUnitAthleteEntryId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteOrgUnitAthleteEntry(orgUnitAthleteEntryId);
    onModalCanceled();
    setState({ ...state, tryGet: true });
  };

  const onAddSecondaryRepToAthleteClicked = (e, athlete) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setSecondaryRepModalState({
      ...secondaryRepModalState,
      displayPopUp: true,
      athleteName: (athlete.firstName || athlete.middleName || athlete.lastName) ? `${athlete.firstName || ''} ${athlete.preferredName !== '' && athlete.preferredName !== athlete.firstName ? '"' + athlete.preferredName + '"' : ''} ${athlete.middleName || ''} ${athlete.lastName || ''}` : '',
      competitionCategory: athlete.competitionGenderTypeName || '',
      birthDate: athlete.birthDate ? formatDate(athlete.birthDate) : '',
      ageAtMeet: athlete.ageAtMeet >= 0 && athlete.ageAtMeet !== null ? athlete.ageAtMeet : '',
      status: athlete.status || '',
      isRelayOnlyAthlete: athlete.isRelayOnlyAthlete === true ? 'Yes' : athlete.isRelayOnlyAthlete === false ? 'No' : '',
      orgUnitAthleteEntryId: athlete.orgUnitAthleteEntryId || Constants.DEFAULT_ID,
      athleteSecondaryRepresentationId: athlete.athleteSecondaryRepresentationId || Constants.DEFAULT_ID,
      secondaryOrgUnitId: athlete.secondaryRepresentationOrgUnitId || Constants.DEFAULT_ID,
      secondaryOrgUnitName: athlete.secondaryRepresentationOrgUnitId ? `${athlete.secondaryRepresentationOrgUnitName || ''} (${athlete.secondaryRepresentationParentOrgUnitCode || ''}/${athlete.secondaryRepresentationOrgUnitCode || ''})` : ''
    });
  };

  const onSaveSecondaryOrgUnitRepresentation = (secondaryOrgUnitId, orgUnitAthleteEntryId, athleteSecondaryRepresentationId) => {
    if (secondaryRepModalState.displayPopUp === true) {
      if ((secondaryOrgUnitId !== Constants.DEFAULT_ID) && (secondaryOrgUnitId !== secondaryRepModalState.secondaryOrgUnitId)) {
        postAthleteSecondaryRepresentation({ secondaryOrgUnitId: secondaryOrgUnitId, orgUnitAthleteEntryId: orgUnitAthleteEntryId });
        setState({ ...state, tryGet: true });
        setErrors({});
        setSecondaryRepModalState(SECONDARY_REP_INITIAL_MODAL_STATE);
      } else if (secondaryOrgUnitId === Constants.DEFAULT_ID && athleteSecondaryRepresentationId !== Constants.DEFAULT_ID) {
        deleteAthleteSecondaryRepresentation(athleteSecondaryRepresentationId);
        setState({ ...state, tryGet: true });
        setErrors({});
        setSecondaryRepModalState(SECONDARY_REP_INITIAL_MODAL_STATE);
      } else {
        onSecondaryRepModalCanceled();
      }
    }
  };

  function submitFormCallback() {
    onSaveSecondaryOrgUnitRepresentation(formState.secondaryOrgUnitId, secondaryRepModalState.orgUnitAthleteEntryId, secondaryRepModalState.athleteSecondaryRepresentationId);
  }

  const onSecondaryRepModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setErrors({});
    setState({ ...state, tryGet: false });
    setSecondaryRepModalState(SECONDARY_REP_INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (secondaryRepModalState.displayPopUp === true && approvedClubsForSecondaryRepState.isArrayLoading === false) {
      if (approvedClubsForSecondaryRepState.items === undefined) {
        getApprovedClubsForSecondaryRep();
      }
      else {
        setFormData({ ...formState, secondaryOrgUnitId: secondaryRepModalState.secondaryOrgUnitId || Constants.DEFAULT_ID, secondaryOrgUnitName: secondaryRepModalState.secondaryOrgUnitName || '', teamOrgUnitId: omeMeetOrgUnitEntryState.objData.orgUnitId }, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryRepModalState.displayPopUp, approvedClubsForSecondaryRepState])

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState?.objData?.omeMeetOrgUnitEntryId && omeMeetOrgUnitAthleteEntryState.isSaving === false &&
      athleteSecondaryRepresentationState.isSaving === false) {
      if ((omeMeetOrgUnitAthleteEntryState.isArrayLoaded === false || omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded === false ||
        omeMeetOrgUnitNonAthleteEntryState.isArrayLoaded === false || state.tryGet === true)
        && omeMeetOrgUnitAthleteEntryState.isArrayLoading === false && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
        omeMeetOrgUnitNonAthleteEntryState.isArrayLoading === false) {
        getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        //Make sure roster entries & coaches gets updated any time the roster changes
        getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        getOrgUnitNonAthleteEntryCoaches(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
        if (state.tryGet === true) {
          setState({ ...state, tryGet: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState, omeMeetOrgUnitAthleteEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitNonAthleteEntryState, athleteSecondaryRepresentationState, state.tryGet])

  return {
    oUAdminEntryContextState,
    modalState,
    omeMeetState,
    omeMeetOrgUnitAthleteEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitEntryState,
    onAddAthlete,
    onDeleteAthleteClicked,
    onModalCanceled,
    onDeleteAthlete,
    secondaryRepModalState,
    athleteSecondaryRepresentationState,
    onAddSecondaryRepToAthleteClicked,
    onSaveSecondaryOrgUnitRepresentation,
    onSecondaryRepModalCanceled,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    approvedClubsForSecondaryRepState
  };
}

export default useOrgUnitAdminRoster;