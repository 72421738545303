import useLaneSheetTopHeaderNotesDetailForm from "./UseLaneSheetTopHeaderNotesDetailForm";

import LaneSheetRecordListCombobox from "../../../../components/comboboxes/laneSheetRecordListCombobox/LaneSheetRecordListCombobox";

import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Dropdown from "../../../../../common/components/dropdowns/Dropdown";

const LaneSheetTopHeaderNotesDetailForm = ({ primaryButtonText = 'Save', secondaryButtonText = 'Back',
  topHeaderDataLength, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onValueTextPairChanged,
    displayOrderOptionsState
  } = useLaneSheetTopHeaderNotesDetailForm(onSubmitFormCallback, topHeaderDataLength);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row  usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <LaneSheetRecordListCombobox
            label={"Record List*"}
            name={"recordListId"}
            valueToMatch={formState.listName}
            error={errorState.recordListId}
            message={errorState.recordListId}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'recordListId', newValueLabel, 'listName'); }}
            isTopInfoLaneSheet={true}
            isRightInfoLaneSheet={false} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Display Order*"
            error={errorState.displayOrder}
            message={errorState.displayOrder}
            options={displayOrderOptionsState}
            name="displayOrder"
            onChange={(value) => { onFormValueChanged('displayOrder', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Name to Show*"
            name="nameToShow"
            value={formState.nameToShow}
            error={errorState.nameToShow}
            message={errorState.nameToShow}
            onChange={(value) => { onFormValueChanged('nameToShow', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LaneSheetTopHeaderNotesDetailForm;