import Constants from "../../../../../common/utils/Constants";
import ToIntIfInt from "../../../../../common/utils/ToIntIfInt";

export const localValidate = (formState) => {
  let errors = {};

  // Record Id
  if (formState.recordListId === Constants?.DEFAULT_ID) {
    errors.recordListId = `Please select a Record List`
  }

  // Display Order
  if (formState.displayOrder === '') {
    errors.displayOrder = 'Please enter a Display Order';
  }

  // Name to Show
  if (formState.nameToShow?.trim() === '') {
    errors.nameToShow = 'Please enter a Name to Show';
  } else if (formState.nameToShow?.trim()?.length > 100) {
    errors.nameToShow = 'Name to Show cannot exceed 100 characters';
  }

  // Times to Show
  if (formState.recordsToShow === '') {
    errors.recordsToShow = 'Please enter a number of Times to Show';
  } else if (ToIntIfInt(formState.recordsToShow) > 20 || ToIntIfInt(formState.recordsToShow) < 1) {
    errors.recordsToShow = 'Please enter a number between 1 and 20';
  }

  //Relay Only
  if (formState.isRelayOnly !== true && formState.isRelayOnly !== false) {
    errors.isRelayOnly = 'A selection is required';
  }

  return errors;
};

const LaneSheetRightSideDetailFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LaneSheetRightSideDetailFormValidation;