import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OrgUnitAdminRosterEntriesSelectionValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/orgUnitAthleteEntry/UseOrgUnitAthleteEntryIndividualRosterEntryData';
import useOmeMeetOrgUnitAthleteEntryData from '../../../../state/omeMeetOrgUnitAthleteEntry/UseOmeMeetOrgUnitAthleteEntryData';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useEventData from '../../../../../common/state/event/UseEventData';

import Constants from '../../../../../common/utils/Constants';
import UseForm from '../../../../../common/utils/UseForm';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  eventId: Constants.DEFAULT_ID,
  eventName: '',
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  event: {},
  modalTitle: 'Continue Without Saving?',
};

const useOrgUnitAdminRosterEntriesIndividualSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventState, setEventState } = useEventData();
  const { meetState } = useMeetData();
  const { omeMeetState } = useOmeMeetData()
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId }
    = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { orgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntry,
    putOrgUnitAthleteEntryIndividualRosterEntry } = useOrgUnitAthleteEntryIndividualRosterEntryData();
  const { omeMeetOrgUnitAthleteEntryState, getOrgUnitAthleteEntryRoster } = useOmeMeetOrgUnitAthleteEntryData();
  const [state, setState] = useState({
    athlete: {}, tryGet: false, tryRedirect: false, tryFilter: false, bonusEvents: '', sessions: [],
    override: false, event: {}
  });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const { handleSubmit, formState, errorState, setErrors, setFormState, onFormValueChanged, onValueTextPairChanged }
    = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  }

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    setState({ ...state, tryFilter: true });
    setErrors({});
  };

  const onOpenModalClicked = (e, event) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    //Editing unsaved override time, don't check for unsaved override when showing error msg
    if (event.swimTimeEntryOverride === true && event.individualAthleteMeetEventId === null) {
      if (selectionChangesState.arrayData?.length > 0) {
        setModalState({
          ...modalState,
          event: event,
          displayPopUp: true
        });
      }
      else {
        onAddEditOverrideEntryClicked(e, event);
      }
    }
    else {
      const unsavedOverrideIndex = orgUnitAthleteEntryIndividualRosterEntryState.arrayData?.events?.findIndex((x) => (x.swimTimeEntryOverride === true && x.individualAthleteMeetEventId === null));
      if (selectionChangesState.arrayData?.length > 0 || unsavedOverrideIndex > -1) {
        setModalState({
          ...modalState,
          event: event,
          displayPopUp: true
        });
      }
      else {
        onAddEditOverrideEntryClicked(e, event);
      }
    }
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onAddEditOverrideEntryClicked = (e, event) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE, { state: { athlete: state.athlete, event: event ? event : modalState.event } });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES_INDIVIDUAL_OVERRIDE, { state: { athlete: state.athlete, event: event ? event : modalState.event } });
    }
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    //Check to see if the selections exceed any host event entry limits
    let previousAthleteEventCount = state.athlete.events?.length || 0;
    let updatedAthleteEventsArray = state.athlete.events ? JSON.parse(JSON.stringify(state.athlete.events)) : [];
    let currentAthleteIndividualEventCount = 0;
    let currentAthleteBonusIndividualEventCount = 0;
    let exceededMaxEventsPerSessionLimit = false;
    let exceededMaxEventsPerSessionLimitInfoArray = [];

    if (state.athlete.events) {
      for (let i = 0; i < state.athlete.events?.length; i++) {
        if (state.athlete.events[i].qualifiedAsBonus === false) {
          currentAthleteIndividualEventCount += 1;
        }
        else if (state.athlete.events[i].qualifiedAsBonus === true) {
          currentAthleteBonusIndividualEventCount += 1;
        }
      }
    }

    //figure out event counts by type based on the selections
    let addedAthleteEventCount = 0;
    let removedAthleteEventCount = 0;
    let updatedAthleteEventCount = 0; //Individual and Bonus Event Entry combined Count
    let updatedAthleteNonBonusEventCount = 0;  //Individual Event Entry only Count

    for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
      if (selectionChangesState.arrayData[i].isSelected === true && !selectionChangesState.arrayData[i].individualAthleteMeetEventId) {
        addedAthleteEventCount += 1;
        updatedAthleteEventsArray.push(selectionChangesState.arrayData[i]);
      }
      else if (selectionChangesState.arrayData[i].isSelected === false && selectionChangesState.arrayData[i].individualAthleteMeetEventId) {
        removedAthleteEventCount += 1;
        const index = updatedAthleteEventsArray.findIndex(x => x.meetEventId === selectionChangesState.arrayData[i].meetEventId);
        if (index > -1) {
          updatedAthleteEventsArray.splice(index, 1);
        }
      }
    }
    updatedAthleteEventCount = previousAthleteEventCount + addedAthleteEventCount - removedAthleteEventCount;
    updatedAthleteNonBonusEventCount = currentAthleteIndividualEventCount + addedAthleteEventCount - removedAthleteEventCount;

    //check to see if the selections violate the max events per session limit, if the host set one
    if (state.athlete.maxEventsPerSessionLimit) {
      for (let i = 0; i < state.sessions.length; i++) {
        let eventsPerSessionCount = 0;
        let individualEventsPerSessionCount = 0;
        let bonusEventsPerSessionCount = 0;
        for (let j = 0; j < updatedAthleteEventsArray.length; j++) {
          if (updatedAthleteEventsArray[j].meetSessionId === state.sessions[i].id) {
            eventsPerSessionCount += 1;
            if (updatedAthleteEventsArray[j].qualifiedAsBonus === true) {
              bonusEventsPerSessionCount += 1;
            }
            else {
              individualEventsPerSessionCount += 1;
            }
          }
        }

        if (eventsPerSessionCount > state.athlete.maxEventsPerSessionLimit) {
          exceededMaxEventsPerSessionLimit = true;
          exceededMaxEventsPerSessionLimitInfoArray.push({ meetSessionName: state.sessions[i].name, eventsPerSessionCount: eventsPerSessionCount, individualEventsPerSessionCount: individualEventsPerSessionCount, bonusEventsPerSessionCount: bonusEventsPerSessionCount });
        }
      }
    }

    let bonusRuleViolated = false;
    let bonusRuleViolatedErrorMsg = '';
    //if bonus events are allowed check to see if any bonus rules are violated by the current selections
    if (state.bonusEvents === true) {
      let differenceBetweenBonusEntriesAndAllowedBonusEntries = 0;
      for (let i = 0; i < omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus.length; i++) {
        //find the bonus rule that would applies to the number of individual (non-bonus) selections
        if (updatedAthleteNonBonusEventCount <= omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].maxQualifyingEvents &&
          updatedAthleteNonBonusEventCount >= omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].minQualifyingEvents) {
          if (currentAthleteBonusIndividualEventCount > omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount) {
            bonusRuleViolated = true;
            differenceBetweenBonusEntriesAndAllowedBonusEntries = currentAthleteBonusIndividualEventCount - omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount;
            bonusRuleViolatedErrorMsg = `The current selections would leave this athlete with ${updatedAthleteNonBonusEventCount} individual event ${updatedAthleteNonBonusEventCount === 1 ? 'entry' : 'entries'}. Based on the bonus event rules of the meet, an athlete with ${updatedAthleteNonBonusEventCount} individual event ${updatedAthleteNonBonusEventCount === 1 ? 'entry' : 'entries'} is allowed ${omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount} individual bonus ${omeMeetState.objData.omeMeetQualification[0]?.omeQualificationBonus[i].bonusStandardCount === 1 ? 'event entry' : 'event entries'}. This athlete has ${currentAthleteBonusIndividualEventCount} individual bonus event ${currentAthleteBonusIndividualEventCount === 1 ? 'entry' : 'entries'}, which would not be allowed.\n\nIn order to be able to save the current selections, please click 'Back' and delete ${differenceBetweenBonusEntriesAndAllowedBonusEntries} individual bonus event ${differenceBetweenBonusEntriesAndAllowedBonusEntries === 1 ? 'entry' : 'entries'}.`
          }
        }
      }
    }

    let deleteAfterPayment = false;
        //checking for delete if the team is attached and has submitted, or if the team is unattached
    if (omeMeetOrgUnitEntryState.objData.status !== Constants.WORKFLOW_STATUS_IN_PROGRESS) {
      for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
        if (selectionChangesState.arrayData[i].individualAthleteMeetEventId !== null && selectionChangesState.arrayData[i].isSelected === false &&
          selectionChangesState.arrayData[i].hasPaid === true && selectionChangesState.arrayData[i].canBeDeleted === false) {
          deleteAfterPayment = true;
        }
      }
    }

    //Throw any errors
    if (state.athlete.maxEventsLimit && updatedAthleteEventCount > state.athlete.maxEventsLimit) {
      if (state.bonusEvents === true) {
        setErrors({ ...errorState, general: `The current selections exceed the individual event entry limit set by the meet host. Only ${state.athlete.maxEventsLimit} individual event ${state.athlete.maxEventsLimit === 1 ? 'entry is' : 'entries are'} allowed (including bonus entries). This athlete currently has ${currentAthleteBonusIndividualEventCount} individual bonus event ${currentAthleteBonusIndividualEventCount === 1 ? 'entry' : 'entries'}, and ${updatedAthleteNonBonusEventCount} individual event ${updatedAthleteNonBonusEventCount === 1 ? 'entry is' : 'entries are'} currently selected.` });
      }
      else {
        setErrors({ ...errorState, general: `The current selections exceed the individual event entry limit set by the meet host. Only ${state.athlete.maxEventsLimit} individual event ${state.athlete.maxEventsLimit === 1 ? 'entry is' : 'entries are'} allowed. ${updatedAthleteEventCount} individual event ${updatedAthleteEventCount === 1 ? 'entry is' : 'entries are'} currently selected.` });
      }
    }
    else if (state.athlete.maxEventsPerSessionLimit && exceededMaxEventsPerSessionLimit === true) {
      let sessionCountErrorMsg = '';
      for (let i = 0; i < exceededMaxEventsPerSessionLimitInfoArray.length; i++) {
        if (state.bonusEvents === true) {
          sessionCountErrorMsg += sessionCountErrorMsg !== '' ? `,\n${exceededMaxEventsPerSessionLimitInfoArray[i].meetSessionName}: This athlete has ${exceededMaxEventsPerSessionLimitInfoArray[i].bonusEventsPerSessionCount} individual bonus event ${exceededMaxEventsPerSessionLimitInfoArray[i].bonusEventsPerSessionCount === 1 ? 'entry' : 'entries'} in this session, and ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount} individual event ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount === 1 ? 'entry in this session is' : 'entries in this session are'} currently selected` : `\n${exceededMaxEventsPerSessionLimitInfoArray[i].meetSessionName}. This athlete has ${exceededMaxEventsPerSessionLimitInfoArray[i].bonusEventsPerSessionCount} individual bonus event ${exceededMaxEventsPerSessionLimitInfoArray[i].bonusEventsPerSessionCount === 1 ? 'entry' : 'entries'} in this session, and ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount} individual event ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount === 1 ? 'entry in this session is' : 'entries in this session are'} currently selected`;
        }
        else {
          sessionCountErrorMsg += sessionCountErrorMsg !== '' ? `,\n${exceededMaxEventsPerSessionLimitInfoArray[i].meetSessionName}: ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount} individual event ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount === 1 ? 'entry in this session is' : 'entries in this session are'} currently selected` : `\n${exceededMaxEventsPerSessionLimitInfoArray[i].meetSessionName}. ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount} individual event ${exceededMaxEventsPerSessionLimitInfoArray[i].individualEventsPerSessionCount === 1 ? 'entry in this session is' : 'entries in this session are'} currently selected`;
        }
      }
      if (state.bonusEvents === true) {
        setErrors({ ...errorState, general: `The current selections exceed the individual event entry per session limit set by the meet host. Only ${state.athlete.maxEventsPerSessionLimit} individual event ${state.athlete.maxEventsPerSessionLimit === 1 ? 'entry per session is' : 'entries per session are'} allowed (including bonus entries). Based on the current selections, this athlete has too many individual event entries in the following sessions: ${sessionCountErrorMsg}` });
      }
      else {
        setErrors({ ...errorState, general: `The current selections exceed the individual event entry per session limit set by the meet host. Only ${state.athlete.maxEventsPerSessionLimit} individual event ${state.athlete.maxEventsPerSessionLimit === 1 ? 'entry per session is' : 'entries per session are'} allowed. Based on the current selections, this athlete has too many individual event entries in the following sessions: ${sessionCountErrorMsg}` });
      }
    }
    else if (bonusRuleViolated === true) {
      setErrors({ ...errorState, general: bonusRuleViolatedErrorMsg });
    }
    else if (deleteAfterPayment === true) {
      setErrors({ ...errorState, general: `The current selections removed an individual event entry that has been paid for. Removing an individual event entry after it has been purchased is not allowed.` });
    }
    //Save if there are no errors
    else {
      if (selectionChangesState.arrayData.length > 0) {
        putOrgUnitAthleteEntryIndividualRosterEntry(state.athlete?.orgUnitAthleteEntryId, selectionChangesState.arrayData);
        setState({ ...state, tryGet: true });
      }
      else {
        setState({ ...state, tryGet: false, tryRedirect: true });
      }
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES_DETAIL, { state: { athlete: state.athlete } });
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES_DETAIL, { state: { athlete: state.athlete } });
    }
  }

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasQualifyingTime === true || updatedGridData[i].swimTimeEntryOverride === true) {
        if (updatedGridData[i].hasPaid === false || updatedGridData[i].canBeDeleted === true) {
          updatedGridData[i].isSelected = true;
        }
        const selectedIndividualRosterEntryIndexArrayData = updatedArrayData.findIndex(x => x.meetEventId === updatedGridData[i].meetEventId);
        //Edit
        if (selectedIndividualRosterEntryIndexArrayData > -1) {
          if (updatedArrayData[selectedIndividualRosterEntryIndexArrayData].hasPaid === false ||
            updatedArrayData[selectedIndividualRosterEntryIndexArrayData].canBeDeleted === true) {
            updatedArrayData[selectedIndividualRosterEntryIndexArrayData].isSelected = true;
          }
        }
        else {
          updatedArrayData.push(updatedGridData[i]);
        }
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  }

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasQualifyingTime === true || updatedGridData[i].swimTimeEntryOverride === true) {
        if (updatedGridData[i].hasPaid === false || updatedGridData[i].canBeDeleted === true) {
          updatedGridData[i].isSelected = false;
        }
        const selectedIndividualRosterEntryIndexArrayData = updatedArrayData.findIndex(x => x.meetEventId === updatedGridData[i].meetEventId);
        //Edit
        if (selectedIndividualRosterEntryIndexArrayData > -1) {
          if (updatedArrayData[selectedIndividualRosterEntryIndexArrayData].hasPaid === false ||
            updatedArrayData[selectedIndividualRosterEntryIndexArrayData].canBeDeleted === true) {
            updatedArrayData[selectedIndividualRosterEntryIndexArrayData].isSelected = false;
          }
        }
        else {
          updatedArrayData.push(updatedGridData[i]);
        }
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onCheckboxChange = (e, meetEventId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedIndividualRosterEntryIndexArrayData = updatedArrayData.findIndex(x => x.meetEventId === meetEventId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedIndividualRosterEntryIndexGridData = gridState.gridData?.findIndex(x => x.meetEventId === meetEventId);

    if (selectedIndividualRosterEntryIndexArrayData > -1) {
      updatedArrayData[selectedIndividualRosterEntryIndexArrayData].isSelected = !updatedArrayData[selectedIndividualRosterEntryIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }

    if (selectedIndividualRosterEntryIndexGridData > -1) {
      updatedGridData[selectedIndividualRosterEntryIndexGridData].isSelected = !updatedGridData[selectedIndividualRosterEntryIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedIndividualRosterEntryIndexArrayData === -1) {
      const newIndividualRosterEntry = JSON.parse(JSON.stringify(updatedGridData[selectedIndividualRosterEntryIndexGridData]));
      updatedArrayData.push(newIndividualRosterEntry);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  function submitFormCallback(formState) {
    setState({ ...state, tryFilter: true });
  };

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0 && eventState.isArrayLoaded === true) {
      const allowedCourseIds = omeMeetState.objData?.omeMeetEntryCourse.map(x => { return x.courseId });
      const filteredArrayData = JSON.parse(JSON.stringify(eventState.arrayData)).filter(x => allowedCourseIds.some(courseId => courseId === x.courseId));
      const filteredArrayDataFormatted = filteredArrayData.map(x => { return { id: x.eventId, name: x.eventName }; });
      setEventState({ ...eventState, filteredItems: filteredArrayDataFormatted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData, eventState.isArrayLoaded])

  useEffect(() => {
    if (location.state && location.state.athlete) {
      //Get session data to display session in grid
      let meetSessionCopy = [];
      if (meetState.objData.meetSession.length > 0) {
        meetSessionCopy = JSON.parse(JSON.stringify(meetState.objData?.meetSession));
        meetSessionCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
      }
      const meetSessionCopyOptions = meetSessionCopy.map((session) => ({ id: session.meetSessionId, name: `${session.sessionOrderId || ''} (${session.sessionType?.typeName || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})` }));
      meetSessionCopyOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' });
      setState({ ...state, athlete: location.state.athlete, bonusEvents: omeMeetState.objData.omeMeetEligibility[0]?.bonusEvents ?? '', sessions: meetSessionCopyOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.athlete?.orgUnitAthleteEntryId) {
      if (location.state?.tryGet !== false) {
        getOrgUnitAthleteEntryIndividualRosterEntry(state.athlete?.orgUnitAthleteEntryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.athlete])

  useEffect(() => {
    if (orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded === true &&
      orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false) {
      const athleteMaxNumberMet = orgUnitAthleteEntryIndividualRosterEntryState.arrayData.athleteMaxNumberMet;
      if (athleteMaxNumberMet === true && (orgUnitAthleteEntryIndividualRosterEntryState.arrayData.events === null
        || orgUnitAthleteEntryIndividualRosterEntryState.arrayData.events?.length === 0)) {
        setErrors({ ...errorState, general: "The meet's max number of athletes cap has been reached. Individual event entries are no longer allowed." })
      }
      const arrayDataCopy = orgUnitAthleteEntryIndividualRosterEntryState.arrayData.events ? JSON.parse(JSON.stringify(orgUnitAthleteEntryIndividualRosterEntryState.arrayData.events)) : [];
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.meetEventId === selectionChangesState.arrayData[i].meetEventId &&
            x.qualifiedAsBonus === selectionChangesState.arrayData[i].qualifiedAsBonus);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }
      if (formState.eventId > 0) {
        const filteredArrayDataCopy = arrayDataCopy.filter(x => x.eventId === formState.eventId && x.qualifiedAsBonus === false);
        setGridState({ ...gridState, gridData: filteredArrayDataCopy });
      }
      else {
        const filteredArrayDataCopy = arrayDataCopy.filter(x => x.qualifiedAsBonus === false);
        setGridState({ ...gridState, gridData: filteredArrayDataCopy });
      }
      if (state.tryFilter === true) {
        setState({ ...state, tryFilter: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoaded,
  orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading,
  state.tryFilter])

  useEffect(() => {
    if (state.tryGet === true && orgUnitAthleteEntryIndividualRosterEntryState.isSaving === false) {
      getOrgUnitAthleteEntryIndividualRosterEntryByOmeMeetOrgUnitEntryId(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      getOrgUnitAthleteEntryRoster(omeMeetOrgUnitEntryState?.objData.omeMeetOrgUnitEntryId);
      setState({ ...state, tryGet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, orgUnitAthleteEntryIndividualRosterEntryState])

  useEffect(() => {
    if (state.tryRedirect === true && orgUnitAthleteEntryIndividualRosterEntryState.isSaving === false &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
      omeMeetOrgUnitAthleteEntryState.isArrayLoading === false) {
      const selectedAthlete = omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.arrayData.find(x => x.personId === state.athlete.personId)
      const selectedEvents = selectedAthlete.events;
      const updatedAthlete = { ...state.athlete, events: selectedEvents };
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.OU_ADMIN_ROSTER_ENTRIES_DETAIL, { state: { athlete: updatedAthlete } });
      }
      else {
        //Meet Route
        navigate(NavLinks.OU_ADMIN_ROSTER_ENTRIES_DETAIL, { state: { athlete: updatedAthlete } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, orgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryState])

  return {
    state,
    gridState,
    orgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryState,
    onSelectAll,
    onUnselectAll,
    onAddEditOverrideEntryClicked,
    onSaveClicked,
    onBackClicked,
    onCheckboxChange,
    onFilterClicked,
    onClearFilterClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    formState,
    errorState,
    omeMeetOrgUnitEntryState,
    modalState,
    onOpenModalClicked,
    onModalCanceled
  };
}

export default useOrgUnitAdminRosterEntriesIndividualSelection;