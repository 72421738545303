export const localValidate = (formState) => {
  let errors = {};

  const maxSwimmers = parseInt(formState.maxSwimmers);
  const maxDivers = parseInt(formState.maxDivers);
  const minRowsToSeed = parseInt(formState.minRowsToSeed);
  const minRelayRowsToAccept = parseInt(formState.minRelayRowsToAccept);
  const addRatioIndRows = parseInt(formState.addRatioIndRows);
  const addRatioRelayRows = parseInt(formState.addRatioRelayRows);

  if (formState.selectionDesc.trim() === '') {
    errors.selectionDesc = 'Description is required';
  }

  if (isNaN(maxSwimmers)) {
    errors.maxSwimmers = 'Must be a valid number';
  } else if (!(maxSwimmers > 0)) {
    errors.maxSwimmers = 'Must be greater than zero';
  }

  if (isNaN(maxDivers)) {
    errors.maxDivers = 'Must be a valid number';
  } else if (!(maxDivers > 0)) {
    errors.maxDivers = 'Must be greater than zero';
  }

  if (isNaN(minRowsToSeed)) {
    errors.minRowsToSeed = 'Must must be a valid number';
  } else if (minRowsToSeed < 0) {
    errors.minRowsToSeed = 'Cannot be a negative number';
  }

  if (isNaN(minRelayRowsToAccept)) {
    errors.minRelayRowsToAccept = 'Must must be a valid number';
  } else if (minRelayRowsToAccept < 0) {
    errors.minRelayRowsToAccept = 'Cannot be a negative number';
  }

  if (isNaN(addRatioIndRows)) {
    errors.addRatioIndRows = 'Must must be a valid number';
  } else if (addRatioIndRows < 0) {
    errors.addRatioIndRows = 'Cannot be a negative number';
  }

  if (isNaN(addRatioRelayRows)) {
    errors.addRatioRelayRows = 'Must must be a valid number';
  } else if (addRatioRelayRows < 0) {
    errors.addRatioRelayRows = 'Cannot be a negative number';
  }

  return errors;
};

const ConfigurationFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ConfigurationFormValidation;