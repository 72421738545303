/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ meetEvent, onRowChange }) => (
  <tr>
    <td>{meetEvent.eventNumber || ''}</td>
    <td>{meetEvent.eventName || ''}</td>
    <td>{meetEvent.eventCompetitionGenderTypeName || ''}</td>
    <td>
      <TimeInput
        label=""
        name={'displayBaseTime'}
        value={meetEvent.displayBaseTime}
        onChange={(displayValue, value) => onRowChange(displayValue, value, meetEvent.meetEventId)} />
    </td>
  </tr>
);

const SelectionMeetEventsGridLarge = ({ gridData, isLoading, onRowChange }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event Number</th>
        <th>Event</th>
        <th>Gender</th>
        <th>Base Time</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((meetEvent, i) => <GridRow key={i} meetEvent={meetEvent} onRowChange={onRowChange} />)
          : <tr><td colSpan="4">No Meet Events</td></tr>
      }
    </tbody>
  </table>
);

export default SelectionMeetEventsGridLarge;