import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetInvitationsData from '../../../../state/omeMeetInvitations/UseOmeMeetInvitationsData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import Constants from '../../../../../common/utils/Constants';
import {
  eligibilityIsComplete, invitationsIsComplete, meetInfoIsComplete,
  restrictionsIsComplete, sessionsIsComplete, ageGroupsIsComplete
} from '../../../../components/leftNavigation/LeftNavValidation';

const ERROR = 'At least one Age Group is required';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false,
  error: '',
  sortedGridData: []
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Age Group?',
  meetAgeGroupId: Constants.DEFAULT_ID,
  ageGroupName: '',
  minAge: '',
  maxAge: ''
};

const useOmeAgeGroups = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetInvitationsState } = useOmeMeetInvitationsData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { meetState } = useMeetData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE, tryRedirect: true });
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT, tryRedirect: true });
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, submitButton: HostRegistrationConstants.BACK, tryRedirect: true });
  };

  const onAddAgeGroup = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_AGE_GROUPS_DETAIL);
    }
    else {
      navigate(HostNavLinks.OME_MEET_AGE_GROUPS_DETAIL);
    }
  };

  const onEditAgeGroup = (e, meetAgeGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route !== '') {
      navigate(NavLinks.MEET_HOST_AGE_GROUPS_DETAIL, { state: { meetAgeGroupId } });
    }
    else {
      navigate(HostNavLinks.OME_MEET_AGE_GROUPS_DETAIL, { state: { meetAgeGroupId } });
    }
  };

  const onDeleteAgeGroupClicked = (e, meetAgeGroup) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      displayPopUp: true,
      meetAgeGroupId: meetAgeGroup.meetAgeGroupId || Constants.DEFAULT_ID,
      ageGroupName: meetAgeGroup.ageGroupName || '',
      minAge: meetAgeGroup.minAge || '',
      maxAge: meetAgeGroup.maxAge || ''
    });
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteAgeGroup = (e, meetAgeGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (omeMeetState.objData?.meetAgeGroup?.length === 1) {
      setState({ ...state, error: ERROR });
    }
    let updatedOmeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
    updatedOmeMeetCopy.meet = undefined;
    const selectedIndex = updatedOmeMeetCopy.meetAgeGroup.findIndex(x => x.meetAgeGroupId === meetAgeGroupId);
    if (selectedIndex > -1) {
      updatedOmeMeetCopy.meetAgeGroup.splice(selectedIndex, 1);
      onModalCanceled();
      putOmeMeet(omeMeetState.objData.omeMeetId, updatedOmeMeetCopy);
    }
  }

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      //Sort grid data to display in ascending order
      let meetAgeGroupCopy = [];
      if (omeMeetState.objData.meetAgeGroup.length > 0) {
        meetAgeGroupCopy = JSON.parse(JSON.stringify(omeMeetState.objData.meetAgeGroup));
        meetAgeGroupCopy.sort(function (a, b) { return a.minAge - b.minAge });
      }
      setState({
        ...state,
        sortedGridData: meetAgeGroupCopy
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.objData]);

  useEffect(() => {
    if (isReadyToRoute === true && omeMeetState.isSaving === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        const meetInfoRoutePermission = navRoutes?.OME_HOST_MEET_INFO?.permission;
        const restrictionsRoutePermission = navRoutes?.OME_HOST_RESTRICTIONS?.permission;
        const eligibilityRoutePermission = navRoutes?.OME_HOST_ELIGIBILITY?.permission;
        if (meetInfoIsComplete(omeMeetState.objData, meetState.objData, meetInfoRoutePermission) === false || eligibilityIsComplete(omeMeetState.objData, eligibilityRoutePermission) === false ||
          restrictionsIsComplete(omeMeetState.objData, restrictionsRoutePermission) === false || invitationsIsComplete(omeMeetInvitationsState.arrayData) === false ||
          sessionsIsComplete(meetState.objData) === false || ageGroupsIsComplete(omeMeetState.objData) === false) {
          setState({
            ...state, error: omeMeetState.objData?.meetAgeGroup?.length === 0 ? 'Age Groups must be complete before continuing to Time Standards. ' + ERROR :
              'Meet Info, Eligibility, Restrictions, Invitations, Sessions, and Age Groups must all be complete before continuing to Time Standards.'
          });
        }
        else {
          if (omeMeetState.route !== '') {
            navigate(NavLinks.MEET_HOST_TIME_STANDARDS);
          }
          else {
            navigate(HostNavLinks.OME_MEET_TIME_STANDARDS);
          }
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_SESSIONS);
        }
        else {
          navigate(HostNavLinks.OME_MEET_SESSIONS);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState.isSaving, state, isReadyToRoute]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryStartDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    state,
    modalState,
    omeMeetState,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    onAddAgeGroup,
    onEditAgeGroup,
    onDeleteAgeGroupClicked,
    onDeleteAgeGroup,
    onModalCanceled,
    allowEdits
  };
};

export default useOmeAgeGroups;
