import Constants from "../../../../../common/utils/Constants";
import ToIntIfInt from "../../../../../common/utils/ToIntIfInt";
import { isValidMemberId, isValidName, isValidNumber } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.firstOrPreferredName.trim() !== '' && !isValidName(formState.firstOrPreferredName.trim())) {
    errors.firstOrPreferredName = 'First or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.lastName.trim() !== '' && !isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.minAge.trim() !== '' && formState.maxAge.trim() !== '' &&
    ToIntIfInt(formState.minAge.trim()) > ToIntIfInt(formState.maxAge.trim())) {
    errors.minAge = 'Min Age cannot be greater than Max Age';
    errors.maxAge = 'Max Age cannot be less than Min Age';
  } else if (formState.minAge.trim() !== '' && formState.maxAge.trim() === '') {
    errors.maxAge = 'Max Age is required if Min Age is specified';
  } else if (formState.minAge.trim() === '' && formState.maxAge.trim() !== '') {
    errors.minAge = 'Min Age is required if Max Age is specified';
  }

  if (formState.minAge.trim() !== ''
    && (isValidNumber(ToIntIfInt(formState.minAge.trim())) === false || formState.minAge.trim() === '0')) {
    errors.minAge = 'Min Age must be greater than zero';
  } else if (ToIntIfInt(formState.minAge.trim()) !== ''
    && ToIntIfInt(formState.minAge.trim()) > 99) {
    errors.minAge = 'Min Age cannot be greater than 99';
  }

  if (formState.maxAge.trim() !== ''
    && (isValidNumber(ToIntIfInt(formState.maxAge.trim())) === false || formState.maxAge.trim() === '0')) {
    errors.maxAge = 'Max Age must be greater than zero';
  } else if (ToIntIfInt(formState.maxAge.trim()) !== ''
    && ToIntIfInt(formState.maxAge.trim()) > 99) {
    errors.maxAge = 'Max Age cannot be greater than 99';
  }

  if (formState.orgGroupName.trim() !== '') {
    if (formState.orgGroupId === Constants.DEFAULT_ID || formState.orgGroupId === '') {
      errors.orgGroupId = 'Must be a valid Club Practice Group';
    }
  }

  if (formState.unattachedSwimmers === true) {
    if (formState.memberId.trim() === '') {
      errors.memberId = 'Providing a Member Id is required when searching for an unattached athlete'
    } else if (!isValidMemberId(formState.memberId.trim())) {
      errors.memberId = 'Member Id must be 14 alphanumeric characters';
    }
  }

  if (formState.unattachedSwimmers !== true) {
    if (formState.individualEligibility === true) {
      if (formState.competitionGenderTypeId === Constants.DEFAULT_ID) {
        errors.competitionGenderTypeId = 'Competition Category is required when filtering by Individual Event Eligibility'
      }
      if (formState.minAge === '') {
        errors.minAge = 'Min Age is required when filtering by Individual Event Eligibility'
      }
      if (formState.maxAge === '') {
        errors.maxAge = 'Max Age is required when filtering by Individual Event Eligibility'
      }
    }
  }

  return errors;
};

const OrgUnitAdminRosterSelectionValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRosterSelectionValidation;