import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgUnitsByOmeMeetIdData from './GetOrgUnitsByOmeMeetIdData';

import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  omeMeetId: Constants.DEFAULT_ID,
  treeData: []
};

const OrgUnitOmeMeetData = {
  INITIAL_STATE, getOrgUnitsByOmeMeetIdData
};

export default OrgUnitOmeMeetData;