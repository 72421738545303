import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useMeetSessionsData from '../../../../common/state/meetSessions/UseMeetSessionsData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';

import NavLinks from '../NavLinks';

const TAXONOMIES = ['MeetDetail', 'OUMeetDetail', 'AthleteMeetDetail'];
const SCOPE = 'Meet';

const useMeetAddSessions = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(getInitialState);
  const { meetState, putMeet } = useMeetData();
  const { resetOmeMeetState } = useOmeMeetData();
  const { resetModalState, meetSessionsState } = useMeetSessionsData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { environmentVariableState, SANCTIONED_MEET_CLASSIFICATION_ID } = useEnvironmentVariableData();

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (meetState?.objData.meetSession.length > 0) {
      setState({ ...state, tryRedirectToMeetStaff: true });
    }
    else {
      setState({ ...state, error: 'At least one Session is required' })
    }
  };

  const onAddSession = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const prevHighestSessionOrderId = meetSessionsState.sortedMeetSessionArray?.length > 0 ? Math.max(...meetSessionsState.sortedMeetSessionArray?.map(o => o.sessionOrderId)) : 0;
    navigate(NavLinks.MEET_ADD_SESSIONS_DETAIL, { state: { prevHighestSessionOrderId: prevHighestSessionOrderId } });
  };

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_ADD_SESSIONS_DETAIL, { state: { meetSessionId: session.meetSessionId } });
  };

  const onDeleteSession = (e, meetSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const updatedMeetCopy = JSON.parse(JSON.stringify(meetState.objData));
    const selectedIndex = updatedMeetCopy.meetSession.findIndex(x => x.meetSessionId === meetSessionId);
    if (selectedIndex > -1) {
      updatedMeetCopy.meetSession.splice(selectedIndex, 1);
      resetModalState();
      putMeet(meetState.objData.meetId, updatedMeetCopy);
    }
  };

  useEffect(() => {
    if (meetState.objData.meetId && meetState.objData.meetId > 0 && state.tryRedirectToMeetStaff === true
      && environmentVariableState.isLoaded === true) {
      if (meetState.objData.meetClassification.parentId === SANCTIONED_MEET_CLASSIFICATION_ID) {
        navigate(NavLinks.MEET_ADD_STAFF);
      }
      else {
        getContextSecurity(meetState.objData.meetId, TAXONOMIES, SCOPE);
        setState({ ...state, tryRedirectToMeetInfo: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, state, environmentVariableState]);

  useEffect(() => {
    if (meetState.objData.meetId && meetState.objData.meetId > 0
      && contextSecurityState.isObjLoading === false && state.tryRedirectToMeetInfo === true) {
      resetOmeMeetState();
      navigate(NavLinks.MEET_INFO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, contextSecurityState, state]);

  function getInitialState() {
    return {
      error: '',
      tryRedirectToMeetStaff: false,
      tryRedirectToMeetInfo: false
    };
  };

  return {
    state,
    isLoading: contextSecurityState.isObjLoading,
    isSaving: meetState.isSaving,
    meetState,
    onContinueClicked,
    Constants,
    onAddSession,
    onEditSession,
    onDeleteSession
  };
};

export default useMeetAddSessions;