import React, { Fragment } from 'react';

import MeetAthleteEntryLargeGrid from './MeetAthleteEntryLargeGrid';
import MeetAthleteEntrySmallGrid from './MeetAthleteEntrySmallGrid';

const MeetAthleteEntryGrid = ({ gridData, isLoading, onCreateEntryClicked, onEditEntryClicked, onDeleteEntryClicked }) => {
  return (
    <Fragment>
      <MeetAthleteEntryLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onCreate={onCreateEntryClicked}
        onEdit={onEditEntryClicked}
        onDeleteClicked={onDeleteEntryClicked}
      />
      <MeetAthleteEntrySmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onCreate={onCreateEntryClicked}
        onEdit={onEditEntryClicked}
        onDeleteClicked={onDeleteEntryClicked}
      />
    </Fragment>
  )
};

export default MeetAthleteEntryGrid;