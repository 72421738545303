import React, { Fragment } from 'react';

import useOrgUnitAdminRosterEntries from './UseOrgUnitAdminRosterEntries';

import OrgUnitAdminRosterEntriesGrid from './OrgUnitAdminRosterEntriesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../../common/utils/Constants';

const OrgUnitAdminRosterEntriesWrite = () => {
  const { gridState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitEntryTimesState, formState, errorState, onFormValueChanged,
    onEditAthleteClicked, omeMeetOrgUnitAthleteEntryState, onUpdateAthleteTimes, onUpdateRosterTimes } = useOrgUnitAdminRosterEntries();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Roster Entries</Headings.H3>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-xs-12">
            <YesNoSwitch
              label="Only Show Athletes Requiring Event Approval?"
              name="onlyShowAthletesRequiringEventApproval"
              checked={formState.onlyShowAthletesRequiringEventApproval}
              error={errorState.onlyShowAthletesRequiringEventApproval}
              message={errorState.onlyShowAthletesRequiringEventApproval}
              onChange={(value) => { onFormValueChanged('onlyShowAthletesRequiringEventApproval', value) }} />
          </div>
        </div>
      </form>
      {gridState.gridData.length > 15 && gridState.showUpdateButton === true && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
        omeMeetOrgUnitAthleteEntryState.isArrayLoading === false &&
        <div className='visible-md visible-lg'>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton onClick={(e) => onUpdateRosterTimes(e)}>Update Individual Event Entry Times for Entire Roster</PrimaryButton>
            </div>
          </div>
        </div>}
      {gridState.gridData.length > 0 && gridState.showUpdateButton === true && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
        omeMeetOrgUnitAthleteEntryState.isArrayLoading === false &&
        <div className='hidden-md hidden-lg'>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton onClick={(e) => onUpdateRosterTimes(e)}>Update Individual Event Entry Times for Entire Roster</PrimaryButton>
            </div>
          </div>
        </div>}
      <div className="row">
        <div className="col-xs-12">
          <OrgUnitAdminRosterEntriesGrid
            gridData={gridState.gridData}
            isLoading={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading}
            onEditAthleteClicked={onEditAthleteClicked}
            onUpdateAthleteTimes={gridState.showUpdateButton === true && onUpdateAthleteTimes}
          />
        </div>
      </div>
      {gridState.gridData?.length > 0 && gridState.showUpdateButton === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton onClick={(e) => onUpdateRosterTimes(e)}>Update Individual Event Entry Times for Entire Roster</PrimaryButton>
          </div>
        </div>}
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading || omeMeetOrgUnitAthleteEntryState.isArrayLoading || omeMeetOrgUnitEntryTimesState.isSaving} />
    </Fragment>
  );
};

export default OrgUnitAdminRosterEntriesWrite;