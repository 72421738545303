import { useState, useEffect } from 'react';

import hyTekExport from './lib/HyTekExport';
import omegaExport from './lib/OmegaExport';

import useOmeMeetData from '../../../state/omeMeet/UseOmeMeetData';
import useOmeEntriesExportData from '../../../state/omeEntriesExport/UseOmeEntriesExportData';

import Constants from '../../../../common/utils/Constants';

const useExportEntries = () => {
  const { omeMeetState } = useOmeMeetData();
  const { omeEntriesExportState, getOmeEntriesExport } = useOmeEntriesExportData();
  const [simpleFormState, setSimpleFormState] = useState(getInitialFormState());
  const [simpleErrorState, setSimpleErrorState] = useState({});

  const [uiState, setUiState] = useState({ exportFileCreated: false, fileContent: "" });

  let fileString = "";

  function createOmegaEntriesExportFile() {
    const exportData = omeEntriesExportState.objData === undefined ? [] : omeEntriesExportState.objData;
    fileString = omegaExport.createOmegaOmeExportDoc(exportData);
  };

  function createHyTekEntriesExportFile() {
    const teams = omeEntriesExportState.objData.teams === undefined ? [] : omeEntriesExportState.objData.teams;
    fileString += hyTekExport.createTeams(teams);

    const unattached = omeEntriesExportState.objData.unattachedSwimmers === undefined ? [] : omeEntriesExportState.objData.unattachedSwimmers;
    fileString += hyTekExport.createUnattachedSwimmers(unattached);

    fileString = hyTekExport.buildCreatedByRec(omeEntriesExportState.objData, fileString) + fileString;
  }

  function provideFile() {
    const file = new Blob([uiState.fileContent], { type: 'application/text' });
    const a = document.createElement('a');

    a.href = URL.createObjectURL(file);

    let meetName = omeEntriesExportState.objData.meetName.replace("&", "").replace("'", "").replace("\\", "").replace("/", "").replace(".", "").replace("#", "");
    const meetId = omeMeetState.objData.omeMeetId;

    let fileName = `${meetId}_${meetName}_export.txt`;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
  }

  useEffect(() => {
    if (omeEntriesExportState.isObjLoaded === true) {
      simpleFormState.isInternationalMeet === false ? createHyTekEntriesExportFile() : createOmegaEntriesExportFile();
      setUiState({ ...uiState, exportFileCreated: true, fileContent: fileString });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeEntriesExportState]);

  useEffect(() => {
    if (uiState.exportFileCreated === true && uiState.fileContent.length > 0) {
      provideFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState]);

  const onSimpleFormValueChanged = (component, value) => {
    setSimpleFormState({ ...simpleFormState, [component]: value })
  };

  const onSimpleValueTextPairChanged = (newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    if (simpleFormState[valuePropertyName] !== newValue || simpleFormState[labelPropertyName] !== newValueLabel) {
      setSimpleFormState(simpleFormState => ({
        ...simpleFormState,
        [valuePropertyName]: newValue,
        [labelPropertyName]: newValueLabel
      }));
    }
  };

  const onEntriesExportClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if ((simpleFormState.isInternationalMeet === true && simpleFormState.country !== Constants.DEFAULT_ID) ||
      simpleFormState.isInternationalMeet === false) {
      getOmeEntriesExport(omeMeetState.objData.omeMeetId);
    }
    else {
      setSimpleErrorState({ ...simpleErrorState, country: 'A Country is required' });
    }
  };

  function getInitialFormState() {
    return {
      isInternationalMeet: false,
      country: Constants.DEFAULT_ID,
      countryName: ''
    };
  };

  return {
    omeMeetState,
    simpleFormState,
    simpleErrorState,
    omeEntriesExportState,
    onEntriesExportClicked,
    onSimpleFormValueChanged,
    onSimpleValueTextPairChanged
  }
}

export default useExportEntries;