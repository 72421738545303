import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOrgGroupDataByOrgUnitIdAndGroupTypeIdData from './GetOrgGroupByOrgUnitIdAndGroupTypeIdData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: Constants.DEFAULT_ID,
  groupTypeId: Constants.DEFAULT_ID,
  items: []
};

const OrgGroupData = {
  getOrgGroupDataByOrgUnitIdAndGroupTypeIdData,
  INITIAL_STATE
};

export default OrgGroupData;