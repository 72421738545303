import React, { Fragment } from 'react';

import useOmeEntryLoading from './UseOmeEntryLoading';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const OmeEntryLoading = ({ teamRedirectNavLink, athleteRedirectNavLink }) => {
  const {
    isLoading
  } = useOmeEntryLoading(teamRedirectNavLink, athleteRedirectNavLink);

  return (
    <Fragment>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default OmeEntryLoading;