import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import HostNavLinks from '../host/HostNavLinks';

import useOmeMeetData from '../../state/omeMeet/UseOmeMeetData';
import useMeetData from '../../../common/state/meet/UseMeetData';
import useSearchMeetData from '../../../common/state/searchMeet/UseSearchMeetData';
import useOmeMeetInvitationsData from '../../state/omeMeetInvitations/UseOmeMeetInvitationsData';

const useOmeMeetTemplateSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState, getOmeMeet } = useOmeMeetData();
  const { omeMeetInvitationsState, getOmeMeetInvitations } = useOmeMeetInvitationsData();
  const { meetState, getMeet } = useMeetData();
  const { searchMeetState } = useSearchMeetData();
  const [state, setState] = useState({ tryRedirect: false });

  const onCancelClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(HostNavLinks.OME_MEET_TEMPLATE_LANDING, {
      state: {
        meetId: location.state?.meetId, meetFiltersState: location.state?.meetFiltersState, route: location.state?.route
      }
    });
  };

  const onEditClicked = (e, meet) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    getOmeMeet(meet.omeMeetId);
    getOmeMeetInvitations(meet.omeMeetId);
    getMeet(meet.meetId);
    setState({ ...state, tryRedirect: true });
  }

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetState.isObjLoading === false && meetState.isObjLoading === false && omeMeetInvitationsState.isArrayLoading === false) {
      navigate(HostNavLinks.OME_MEET_TEMPLATE_LANDING, {
        state: {
          meetId: location.state?.meetId, meetFiltersState: location.state?.meetFiltersState, route: location.state?.route
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, omeMeetState.isObjLoading, meetState.isObjLoading, omeMeetInvitationsState.isArrayLoading])

  return {
    omeMeetState,
    meetState,
    searchMeetState,
    onEditClicked,
    onCancelClicked
  };
};

export default useOmeMeetTemplateSearch;