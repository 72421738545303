import React, { createContext, useState } from 'react';

import TimeStandardAgeGroupData from './TimeStandardAgeGroupData';

export const TimeStandardAgeGroupStateContext = createContext();

const TimeStandardAgeGroupContextProvider = ({ children }) => {
  const stateHook = useState(TimeStandardAgeGroupData.INITIAL_STATE);

  return (
    <TimeStandardAgeGroupStateContext.Provider value={stateHook}>
      {children}
    </TimeStandardAgeGroupStateContext.Provider>
  );
};

export default TimeStandardAgeGroupContextProvider;