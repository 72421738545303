import React from 'react';

import useLaneSheetTemplateDropdown from './UseLaneSheetTemplateDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const LaneSheetTemplateDropdown = ({ label, name, value, error, message, onChange, makeGetCall = true }) => {
  const { laneSheetTemplateState } = useLaneSheetTemplateDropdown(makeGetCall);

  return laneSheetTemplateState.message
    ? <span className={global.LoadingMsg}>{laneSheetTemplateState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={laneSheetTemplateState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={laneSheetTemplateState.areOptionsLoaded !== true}
      />
    );
};

export default LaneSheetTemplateDropdown;