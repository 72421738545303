import React, { Fragment } from 'react';

import useSelectionsReports from './UseSelectionsReports';

import SelectionsReporting from './selectionsReporting/SelectionsReporting';

import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import SelectionsLoadingModal from '../components/dialogs/SelectionsLoadingModal';
import { REPORTS_NAME } from '../components/leftNav/SelectionsLeftNavConstants';

const SelectionsReports = () => {
  const {
    isLoading,
  } = useSelectionsReports();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={REPORTS_NAME} viewTitle={''}>
        <SelectionsReporting />
        <SelectionsLoadingModal isLoading={isLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
};

export default SelectionsReports;