import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useMeetStaffData from '../../../../common/state/meet/meetStaff/UseMeetStaffData';

import Constants from '../../../../common/utils/Constants';

const useMeetStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState } = useMeetData();
  const { meetStaffState, getMeetStaff } = useMeetStaffData();
  const [state, setState] = useState({ tryGet: false });

  const onEditStaff = (e, staff) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_STAFF_DETAIL, {
      state: {
        meetStaffTypeId: staff.meetStaffTypeId,
        meetStaffTypeName: staff.meetStaffTypeName,
        memberId: staff?.memberId || '',
        personId: staff?.personId || ''
      }
    });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (meetState.isObjLoaded === true && meetStaffState.isArrayLoading !== true
      && (meetStaffState.isArrayLoaded !== true || state.tryGet === true)) {
      getMeetStaff(meetState.objData?.meetId);
      if (state.tryGet === true) {
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, meetStaffState, state.tryGet])

  return {
    meetStaffState,
    isLoading: meetStaffState.isArrayLoading,
    Constants,
    onEditStaff
  };
}

export default useMeetStaff;