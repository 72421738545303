import { useContext } from 'react';

import { OmeMeetOrgUnitEntryTimesStateContext } from './OmeMeetOrgUnitEntryTimesContextProvider';

import OmeMeetOrgUnitEntryTimesData from './OmeMeetOrgUnitEntryTimesData';

const useOmeMeetOrgUnitEntryTimesData = () => {
  const [omeMeetOrgUnitEntryTimesState, setOmeMeetOrgUnitEntryTimesState] = useContext(OmeMeetOrgUnitEntryTimesStateContext);

  const putOmeMeetOrgUnitEntryTimes = (omeMeetOrgUnitEntryId, orgUnitAthleteEntryId) =>
    OmeMeetOrgUnitEntryTimesData.putOmeMeetOrgUnitEntryTimesData(omeMeetOrgUnitEntryId, orgUnitAthleteEntryId, omeMeetOrgUnitEntryTimesState, setOmeMeetOrgUnitEntryTimesState);

  const clearOmeMeetOrgUnitEntryTimesObjData = () => {
    setOmeMeetOrgUnitEntryTimesState({
      ...omeMeetOrgUnitEntryTimesState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const confirmSaveOmeMeetOrgUnitEntryTimes = () => {
    setOmeMeetOrgUnitEntryTimesState({
      ...omeMeetOrgUnitEntryTimesState,
      isSaved: false
    });
  };

  return {
    omeMeetOrgUnitEntryTimesState,
    putOmeMeetOrgUnitEntryTimes,
    clearOmeMeetOrgUnitEntryTimesObjData,
    confirmSaveOmeMeetOrgUnitEntryTimes
  };
};

export default useOmeMeetOrgUnitEntryTimesData;