import { Fragment } from 'react';

import useLaneSheetAddRelayTeamsForm from './UseLaneSheetAddRelayTeamsForm';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import TreeView from '../../../../../common/components/tree/TreeView';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LaneSheetAddRelayTeamsForm = ({ onSubmitFormCallback, existingRelayTeams }) => {
  const {
    isLoading,
    orgUnitState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit
  } = useLaneSheetAddRelayTeamsForm(onSubmitFormCallback, existingRelayTeams);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <TreeView
              label="Relay Teams*"
              name="orgUnits"
              value={formState.orgUnits}
              options={orgUnitState.treeData}
              selectableLeavesOnly={false}
              singleSelect={false}
              error={errorState.orgUnits}
              message={errorState.orgUnits}
              isLoading={orgUnitState.isArrayLoading}
              onChange={(newValue) => { onFormValueChanged('orgUnits', newValue) }} />
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} type='submit'>Add Teams</button>
          </div>
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LaneSheetAddRelayTeamsForm;