import { Fragment } from "react";
import Headings from "../../../../common/components/headings/Headings";

import useRaceStats from './UseRaceStats';
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import ViewReport from "../../../../common/components/embeddedReports/ViewReport";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";

const RaceStats = () => {
  const { 
    meetState, 
    viewState, 
    setViewState, 
    reportListState, 
    onRunRaceStatsDashboard, 
    INITIAL_VIEW_STATE } = useRaceStats();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Race Stats</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <PrimaryButton type="button" onClick={() => onRunRaceStatsDashboard()}>View Dashboard</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={reportListState?.isArrayLoading} />
      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />
          </div>
        </div>
      }
      {/* {viewState.errorMessage.length > 0 &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p className={global.ErrorMessage}>Error: {viewState.errorMessage}</p></div>
        </div>
      } */}
    </Fragment>);
}

export default RaceStats;