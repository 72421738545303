import React from 'react';

import ViewDashboardIcon from '../../../common/components/icons/ViewDashboardIcon';
import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ athlete, onView }) => (
  <tr>
    <td>{athlete.firstName}</td>
    <td>{athlete.lastName}</td>
    <td style={{float: 'right'}}>
        {onView &&
          <button className={global.IconButtonMargin}
            type="button"
            onClick={() => onView(athlete.personId)}>
            <ViewDashboardIcon />
          </button>
        }
      </td>
  </tr>
);

const AthleteRaceStatsGridLarge = ({ data, isLoading, onView }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((athlete, i) => (
            <GridRow
              key={i}
              athlete={athlete}
              onView={onView} />
          )) : (
            <tr>
              <td colSpan="2">No athletes with Race Stats metrics</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default AthleteRaceStatsGridLarge;