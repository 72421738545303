import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import HostNavLinks from './HostNavLinks';

import OmeMeetContextProvider from '../../state/omeMeet/OmeMeetContextProvider';
import OmeTimeStandardContextProvider from '../../state/omeTimeStandard/OmeTimeStandardContextProvider';
import TimeStandardAgeGroupContextProvider from '../../state/timeStandardAgeGroup/TimeStandardAgeGroupContextProvider';
import OmeFeeTypeContextProvider from '../../state/omeFeeType/OmeFeeTypeContextProvider';
import OmeMeetDateValidationContextProvider from '../../state/omeMeetDateValidation/OmeMeetDateValidationContextProvider';
import OmeMeetInvitationsContextProvider from '../../state/omeMeetInvitations/OmeMeetInvitationsContextProvider';

import MeetContextProvider from '../../../common/state/meet/MeetContextProvider';
import MeetFiltersContextProvider from '../../../common/state/meet/MeetFiltersContextProvider';
import SearchMeetContextProvider from '../../../common/state/searchMeet/SearchMeetContextProvider';
import OrgRoleContextProvider from '../../../common/state/orgRole/OrgRoleContextProvider';
import CompetitonGenderTypeContextProvider from '../../../common/state/competitionGender/CompetitionGenderTypeContextProvider';
import EventCompetitonGenderTypeContextProvider from '../../../common/state/eventCompetitionGender/EventCompetitionGenderTypeContextProvider';
import TimeStandardTypeContextProvider from '../../../common/state/timeStandardType/TimeStandardTypeContextProvider';
import MeetSessionsContextProvider from '../../../common/state/meetSessions/MeetSessionsContextProvider';
import SessionContextProvider from '../../../common/state/time/SessionContextProvider';
import TimeContextProvider from '../../../common/state/time/TimeContextProvider';
import TimeFiltersContextProvider from '../../../common/state/time/TimeFiltersContextProvider';
import TimesOrganizationContextProvider from '../../../common/state/time/TimesOrganizationContextProvider';
import LeftNavModalContextProvider from '../../../common/state/leftNavModal/LeftNavModalContextProvider';
import BasePersonFiltersContextProvider from '../../../common/state/personBase/BasePersonFiltersContextProvider';
import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import PersonGeneralContextProvider from '../../../common/state/personGeneral/PersonGeneralContextProvider';

import OmeHostLoading from './omeHostLoading/OmeHostLoading';
import OmeMeetInfo from './hostRegistration/omeMeetInfo/OmeMeetInfo';
import OmeMeetInfoNonHQ from './hostRegistration/omeMeetInfo/OmeMeetInfoNonHQ';
import OmeMeetInfoCoursePriority from './hostRegistration/omeMeetInfo/OmeMeetInfoCoursePriority';
import OmeEligibility from './hostRegistration/omeEligibility/OmeEligibility';
import OmeEligibilityNonHQ from './hostRegistration/omeEligibility/OmeEligibilityNonHQ';
import OmeEligibilityBonusEvents from './hostRegistration/omeEligibility/OmeEligibilityBonusEvents';
import OmeEligibilityCoachPasses from './hostRegistration/omeEligibility/OmeEligibilityCoachPasses';
import OmeRestrictions from './hostRegistration/omeRestrictions/OmeRestrictions';
import OmeRestrictionsNonHQ from './hostRegistration/omeRestrictions/OmeRestrictionsNonHQ';
import OmeRestrictionsType from './hostRegistration/omeRestrictions/OmeRestrictionsType';
import OmeRestrictionsTypeMembership from './hostRegistration/omeRestrictions/OmeRestrictionsTypeMembership';
import OmeRestrictionsTypeCompetitionCategory from './hostRegistration/omeRestrictions/OmeRestrictionsTypeCompetitionCategory';
import OmeRestrictionsTypeAge from './hostRegistration/omeRestrictions/OmeRestrictionsTypeAge';
import OmeInvitations from './hostRegistration/omeInvitations/OmeInvitations';
import OmeSessions from './hostRegistration/omeSessions/OmeSessions';
import OmeSessionsDetail from './hostRegistration/omeSessions/OmeSessionsDetail';
import OmeAgeGroups from './hostRegistration/omeAgeGroups/OmeAgeGroups';
import OmeAgeGroupsDetail from './hostRegistration/omeAgeGroups/OmeAgeGroupsDetail';
import OmeTimeStandards from './hostRegistration/omeTimeStandards/OmeTimeStandards';
import OmeTimeStandardsDetail from './hostRegistration/omeTimeStandards/OmeTimeStandardsDetail';
import OmeEvents from './hostRegistration/omeEvents/OmeEvents';
import OmeEventsDetail from './hostRegistration/omeEvents/OmeEventsDetail';
import OmeAlternateEventDetail from './hostRegistration/omeEvents/OmeAlternateEventDetail';
import OmePricing from './hostRegistration/omePricing/OmePricing';
import OmePricingAdditionalPurchases from './hostRegistration/omePricing/OmePricingAdditionalPurchases';
import OmeSubmit from './hostRegistration/omeSubmit/OmeSubmit';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const HostRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OME_HOST_LOADING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_LOADING']}
            editElement={<OmeHostLoading redirectNavLink={HostNavLinks.OME_MEET_INFO} />} />
        }
      />
      <Route path={navRoutes['OME_HOST_MEET_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_MEET_INFO']}
            editElement={<OmeMeetInfo />}
            readOnlyElement={<OmeMeetInfoNonHQ />} />
        }
      />
      <Route path={navRoutes['OME_HOST_MEET_INFO_COURSE_PRIORITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_MEET_INFO_COURSE_PRIORITY']}
            editElement={<OmeMeetInfoCoursePriority />} />
        }
      />
      <Route path={navRoutes['OME_HOST_ELIGIBILITY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_ELIGIBILITY']}
            editElement={<OmeEligibility />}
            readOnlyElement={<OmeEligibilityNonHQ />} />
        }
      />
      <Route path={navRoutes['OME_HOST_ELIGIBILITY_BONUS_EVENTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_ELIGIBILITY_BONUS_EVENTS']}
            editElement={<OmeEligibilityBonusEvents />} />
        }
      />
      <Route path={navRoutes['OME_HOST_ELIGIBILITY_COACH_PASSES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_ELIGIBILITY_COACH_PASSES']}
            editElement={<OmeEligibilityCoachPasses />} />
        }
      />
      <Route path={navRoutes['OME_HOST_RESTRICTIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_RESTRICTIONS']}
            editElement={<OmeRestrictions />}
            readOnlyElement={<OmeRestrictionsNonHQ />} />
        }
      />
      <Route path={navRoutes['OME_HOST_RESTRICTIONS_TYPE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_RESTRICTIONS_TYPE']}
            editElement={<OmeRestrictionsType />} />
        }
      />
      <Route path={navRoutes['OME_HOST_RESTRICTIONS_TYPE_MEMBERSHIP']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_RESTRICTIONS_TYPE_MEMBERSHIP']}
            editElement={<OmeRestrictionsTypeMembership />} />
        }
      />
      <Route path={navRoutes['OME_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY']}
            editElement={<OmeRestrictionsTypeCompetitionCategory />} />
        }
      />
      <Route path={navRoutes['OME_HOST_RESTRICTIONS_TYPE_AGE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_RESTRICTIONS_TYPE_AGE']}
            editElement={<OmeRestrictionsTypeAge />} />
        }
      />
      <Route path={navRoutes['OME_HOST_INVITATIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_INVITATIONS']}
            editElement={<OmeInvitations />} />
        }
      />
      <Route path={navRoutes['OME_HOST_SESSIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_SESSIONS']}
            editElement={<OmeSessions />} />
        }
      />
      <Route path={navRoutes['OME_HOST_SESSIONS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_SESSIONS_DETAIL']}
            editElement={<OmeSessionsDetail />} />
        }
      />
      <Route path={navRoutes['OME_HOST_AGE_GROUPS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_AGE_GROUPS']}
            editElement={<OmeAgeGroups />} />
        }
      />
      <Route path={navRoutes['OME_HOST_AGE_GROUPS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_AGE_GROUPS_DETAIL']}
            editElement={<OmeAgeGroupsDetail />} />
        }
      />
      <Route path={navRoutes['OME_HOST_TIME_STANDARDS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_TIME_STANDARDS']}
            editElement={<OmeTimeStandards />} />
        }
      />
      <Route path={navRoutes['OME_HOST_TIME_STANDARDS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_TIME_STANDARDS_DETAIL']}
            editElement={<OmeTimeStandardsDetail />} />
        }
      />
      <Route path={navRoutes['OME_HOST_EVENTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_EVENTS']}
            editElement={<OmeEvents />} />
        }
      />
      <Route path={navRoutes['OME_HOST_EVENTS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_EVENTS_DETAIL']}
            editElement={<OmeEventsDetail />} />
        }
      />
      <Route path={navRoutes['OME_HOST_EVENTS_DETAIL_ALTERNATE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_EVENTS_DETAIL_ALTERNATE']}
            editElement={<OmeAlternateEventDetail />} />
        } />
      <Route path={navRoutes['OME_HOST_PRICING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_PRICING']}
            editElement={<OmePricing />} />
        }
      />
      <Route path={navRoutes['OME_HOST_PRICING_ADDITIONAL_PURCHASES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_PRICING_ADDITIONAL_PURCHASES']}
            editElement={<OmePricingAdditionalPurchases />} />
        }
      />
      <Route path={navRoutes['OME_HOST_SUBMIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OME_HOST_SUBMIT']}
            editElement={<OmeSubmit />} />
        }
      />
      <Route path={'/'} element={<Navigate to={navRoutes['OME_HOST_MEET_TEMPLATE']?.route} replace />} />
    </Routes>
  );
};

const HostContextProviders = ({ children }) => (
  <LeftNavModalContextProvider>
    <MeetContextProvider>
      <MeetFiltersContextProvider>
        <SearchMeetContextProvider>
          <OmeMeetContextProvider>
            <OmeMeetInvitationsContextProvider>
              <TimeStandardTypeContextProvider>
                <OmeTimeStandardContextProvider>
                  <OrgRoleContextProvider>
                    <CompetitonGenderTypeContextProvider>
                      <EventCompetitonGenderTypeContextProvider>
                        <MeetSessionsContextProvider>
                          <TimeContextProvider>
                            <TimeFiltersContextProvider>
                              <TimesOrganizationContextProvider>
                                <SessionContextProvider>
                                  <TimeStandardAgeGroupContextProvider>
                                    <BasePersonContextProvider>
                                      <BasePersonFiltersContextProvider>
                                        <PersonGeneralContextProvider>
                                          <OmeFeeTypeContextProvider>
                                            <OmeMeetDateValidationContextProvider>
                                              {children}
                                            </OmeMeetDateValidationContextProvider>
                                          </OmeFeeTypeContextProvider>
                                        </PersonGeneralContextProvider>
                                      </BasePersonFiltersContextProvider>
                                    </BasePersonContextProvider>
                                  </TimeStandardAgeGroupContextProvider>
                                </SessionContextProvider>
                              </TimesOrganizationContextProvider>
                            </TimeFiltersContextProvider>
                          </TimeContextProvider>
                        </MeetSessionsContextProvider>
                      </EventCompetitonGenderTypeContextProvider>
                    </CompetitonGenderTypeContextProvider>
                  </OrgRoleContextProvider>
                </OmeTimeStandardContextProvider>
              </TimeStandardTypeContextProvider>
            </OmeMeetInvitationsContextProvider>
          </OmeMeetContextProvider>
        </SearchMeetContextProvider>
      </MeetFiltersContextProvider>
    </MeetContextProvider>
  </LeftNavModalContextProvider>
);

const HostContextStateAndRoutes = () => (
  <HostContextProviders>
    <HostRoutes />
  </HostContextProviders>
);

export default HostContextStateAndRoutes;