import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OmeRestrictionsTypeValidation';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';

import useOmeRestrictions from './UseOmeRestrictions';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';

import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';

import useForm from '../../../../../common/utils/UseForm';

const RESTRICTION_TYPES = {
  Membership: "Membership",
  CompetitionCategory: "Competition Category",
  Age: "Age"
}

const INITIAL_VIEW_STATE = {
  restrictionTypeRoute: '',
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  restrictionTypeName: ''
};

const USAS_RESTRICTION_TYPE_OPTIONS = [
  { id: 1, value: "Membership", label: "Membership" },
  { id: 2, value: "Competition Category", label: "Competition Category" },
  { id: 3, value: "Age", label: "Age" },
]

const NON_USAS_RESTRICTION_TYPE_OPTIONS = [
  { id: 1, value: "Competition Category", label: "Competition Category" },
  { id: 2, value: "Age", label: "Age" },
]

const useOmeRestrictionsType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { omeMeetState } = useOmeMeetData();
  const { orgUnitState, getOrgUnitByOrgUnitId } = useOrgUnitData();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { formStateHasBeenSaved } = useOmeRestrictions();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [selectRestrictionTypeState, setSelectRestrictionTypeState] = useState([]);
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { formState, errorState, onFormValueChanged, isDirty, setIsDirty, handleSubmit } =
    useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const RESTRICTION_TYPE_ROUTES = {
    Membership: omeMeetState.route === '' ? HostNavLinks.OME_MEET_RESTRICTIONS_TYPE_MEMBERSHIP : NavLinks.MEET_HOST_RESTRICTIONS_TYPE_MEMBERSHIP,
    CompetitionCategory: omeMeetState.route === '' ? HostNavLinks.OME_MEET_RESTRICTIONS_TYPE_COMPETITION_CATEGORY : NavLinks.MEET_HOST_RESTRICTIONS_TYPE_COMPETITION_CATEGORY,
    Age: omeMeetState.route === '' ? HostNavLinks.OME_MEET_RESTRICTIONS_TYPE_AGE : NavLinks.MEET_HOST_RESTRICTIONS_TYPE_AGE
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    onSelectRestrictionType(e);
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (location.state && location.state?.restrictionFormState) {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS, { state: { restrictionFormState: location.state?.restrictionFormState } });
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS, { state: { restrictionFormState: location.state?.restrictionFormState } });
      }
    }
    else {
      if (omeMeetState.route !== '') {
        navigate(NavLinks.MEET_HOST_RESTRICTIONS);
      }
      else {
        navigate(HostNavLinks.OME_MEET_RESTRICTIONS);
      }
    }
  };

  const onSelectRestrictionType = (e) => {
    if (isDirty === false) {
      setIsDirty(true);
    }
    handleSubmit(e);
  };

  function submitFormCallback() {
    if (state.restrictionTypeRoute)
      if (location.state && location.state?.restrictionFormState) {
        navigate(state.restrictionTypeRoute, { state: { restrictionFormState: location.state?.restrictionFormState } });
      }
      else {
        navigate(state.restrictionTypeRoute);
      }
  }

  useEffect(() => {
    if (omeMeetState.selectedMeet?.hostOrgUnitId !== orgUnitState.objData?.orgUnitId) {
      getOrgUnitByOrgUnitId(omeMeetState.selectedMeet?.hostOrgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState]);

  useEffect(() => {
    if (Object.keys(orgUnitState.objData).length > 0) {
      if (orgUnitState.objData?.organizationId === USAS_ORGANIZATION_ID) {
        setSelectRestrictionTypeState(USAS_RESTRICTION_TYPE_OPTIONS);
      } else {
        setSelectRestrictionTypeState(NON_USAS_RESTRICTION_TYPE_OPTIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(location.state?.restrictionFormState);
      const isOverallSaved = isSaved === true && isDirty === false ? true : false;
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isOverallSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    let restrictionTypeRoute = state.restrictionTypeRoute;
    const restrictionTypeName = formState.restrictionTypeName;
    if (restrictionTypeName === RESTRICTION_TYPES.Membership) {
      restrictionTypeRoute = RESTRICTION_TYPE_ROUTES.Membership
    } else if (restrictionTypeName === RESTRICTION_TYPES.CompetitionCategory) {
      restrictionTypeRoute = RESTRICTION_TYPE_ROUTES.CompetitionCategory
    } else if (restrictionTypeName === RESTRICTION_TYPES.Age) {
      restrictionTypeRoute = RESTRICTION_TYPE_ROUTES.Age
    }
    setState({
      ...state,
      restrictionTypeRoute
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.restrictionTypeName]);

  return {
    orgUnitState,
    selectRestrictionTypeState,
    onSelectRestrictionType,
    formState,
    errorState,
    onFormValueChanged,
    onNextButtonClicked,
    onBackButtonClicked
  };
};

export default useOmeRestrictionsType;
