import React from 'react';
import { Dialog } from '@reach/dialog';

import useSelectionsParticipantSelectionsPopup from './UseSelectionsParticipantSelectionsPopup';

import ParticipantGridDetailPopup from '../components/dialogs/ParticipantGridDetailPopup';
import ParticipantSelectionsReadOnlyForm from '../components/forms/ParticipantSelectionsReadOnlyForm';
import SelectionActions from '../components/actions/SelectionActions';
import SelectionAlternatesGrid from '../components/grids/SelectionAlternatesGrid';
import SelectionParticipantsGrid from '../components/grids/SelectionParticipantsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import '@reach/dialog/styles.css';
import style from './SelectionsParticipantSelectionsPopup.module.css';

const SelectionsParticipantSelectionsPopup = () => {
  const {
    state,
    selectionsState,
    selectionsMeetEventsState,
    selectionsParticipantsState,
    selectionGridOptionsState,
    alternateModalState,
    onToggleShowRelays,
    toggleShowDetailPopupsInGrid,
    onCompleteClicked,
    onCloseClicked,
    onAlternateModalConfirmClicked,
    onAlternateModalCancelClicked,
    onSelectAlternateClicked,
    onParticipantClicked
  } = useSelectionsParticipantSelectionsPopup();

  return (
    <Dialog
      isOpen={selectionGridOptionsState.showSelectionGridPopup === true}
      allowPinchZoom={true}
      aria-label={'Participant Selections'}
      style={{
        width: '99%',
        maxWidth: '99%'
      }}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>Participant Selections</Headings.H2>
        </div>
        <div className="col-xs-12">
          {selectionsParticipantsState.objData?.previousRuleMessage &&
            <p className={style.PreviousRuleNotification}>{selectionsParticipantsState.objData.previousRuleMessage}</p>
          }
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-8">
          <ParticipantSelectionsReadOnlyForm
            selectionsStateObj={selectionsState.objData}
            selectionsParticipantsStateObj={selectionsParticipantsState.objData} />
        </div>
        <div className="col-xs-4">
          <SelectionActions
            errorState={state}
            onCloseClicked={onCloseClicked}
            onCompleteClicked={onCompleteClicked} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectionParticipantsGrid
            selectionsMeetEventsStateArray={selectionsMeetEventsState.arrayData || []}
            selectionParticipants={selectionsParticipantsState.objData?.selectionParticipants || []}
            showDetailPopupsInGrid={selectionGridOptionsState.showDetailPopupsInGrid}
            onToggleShowDetailPopups={toggleShowDetailPopupsInGrid}
            onParticipantClicked={onParticipantClicked} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectionAlternatesGrid
            gridData={selectionsParticipantsState.objData?.topAlternates || []}
            isLoading={false}
            showRelaysInAlternatesGrid={selectionGridOptionsState.showRelaysInAlternatesGrid}
            onToggleShowRelays={onToggleShowRelays}
            onEditClicked={onParticipantClicked}
            onSelectClicked={onSelectAlternateClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={`Select Participant?`}
        displayPopUp={alternateModalState.displayModal}
        onModalCanceled={onAlternateModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label={alternateModalState.relayTeamName ? 'Relay Team Name' : 'Participant Name'}
              name="participantName"
              value={alternateModalState.relayTeamName || `${alternateModalState.firstName} ${alternateModalState.lastName}`} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Team'
              name="orgUnitName"
              value={`${alternateModalState.orgUnitName}`} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onAlternateModalConfirmClicked}>Select Participant</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onAlternateModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      {selectionGridOptionsState.showDetailPopupsInGrid === true &&
        <ParticipantGridDetailPopup />
      }
    </Dialog>
  );
};

export default SelectionsParticipantSelectionsPopup;