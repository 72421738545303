import Constants from "../../../common/utils/Constants";
import {
  CONFIGURATION_NAME, MEET_ENTRIES_NAME, MEET_EVENTS_NAME, PARTICIPANT_SELECTIONS_NAME, REPORTS_NAME, EXPORT_NAME
} from "../../views/selections/components/leftNav/SelectionsLeftNavConstants";
import NavLinks from "../../views/selections/utils/NavLinks";

const INITIAL_OBJ_DATA = {
  meetHost: '',
  meetName: '',
  meetStartDate: Constants.BLANK_DATE_STRING,
  meetEndDate: Constants.BLANK_DATE_STRING,
  competitionGenderTypeName: ''
};

const INITIAL_ARRAY_DATA = [
  {
    name: CONFIGURATION_NAME,
    displayName: 'Configuration',
    path: NavLinks.SELECTIONS_CONFIGURATION,
    isCurrent: true,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: MEET_EVENTS_NAME,
    displayName: 'Meet Events',
    path: NavLinks.SELECTIONS_MEET_EVENTS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [CONFIGURATION_NAME]
  }, {
    name: MEET_ENTRIES_NAME,
    displayName: 'Meet Entries',
    path: NavLinks.SELECTIONS_MEET_ENTRIES,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [CONFIGURATION_NAME, MEET_EVENTS_NAME]
  }, {
    name: PARTICIPANT_SELECTIONS_NAME,
    displayName: 'Participant Selections',
    path: NavLinks.SELECTIONS_PARTICIPANT_SELECTIONS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [CONFIGURATION_NAME, MEET_EVENTS_NAME, MEET_ENTRIES_NAME]
  }, {
    name: REPORTS_NAME,
    displayName: 'Reports',
    path: NavLinks.SELECTIONS_REPORTS,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [CONFIGURATION_NAME, MEET_EVENTS_NAME, MEET_ENTRIES_NAME, PARTICIPANT_SELECTIONS_NAME]
  },
  {
    name: EXPORT_NAME,
    displayName: 'Export',
    path: NavLinks.SELECTIONS_EXPORT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [CONFIGURATION_NAME, MEET_EVENTS_NAME, MEET_ENTRIES_NAME, PARTICIPANT_SELECTIONS_NAME]
  }
];

const SelectionsLeftNavData = {
  INITIAL_OBJ_DATA,
  INITIAL_ARRAY_DATA
};

export default SelectionsLeftNavData;