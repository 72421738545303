const Views = {
  OME_MEET_INFO: 'Meet Info',
  OME_MEET_INFO_NON_HQ: 'Meet Info - Non HQ',
  OME_MEET_INFO_COURSE_PRIORITY: 'Meet Info - Course Priority',
  OME_MEET_ELIGIBILITY: 'Eligibility',
  OME_MEET_ELIGIBILITY_NON_HQ: 'Eligibilty - Non HQ',
  OME_MEET_ELIGIBILITY_BONUS_EVENTS: 'Eligibility - Bonus Event Rule',
  OME_MEET_ELIGIBILITY_COACH_PASSES: 'Eligibility - Coach Pass Rule',
  OME_MEET_RESTRICTIONS: 'Restrictions',
  OME_MEET_RESTRICTIONS_NON_HQ: 'Restrictions - Non HQ',
  OME_MEET_RESTRICTIONS_TYPE: 'Add a Restriction',
  OME_MEET_RESTRICTIONS_TYPE_MEMBERSHIP: 'Membership Restriction',
  OME_MEET_RESTRICTIONS_TYPE_COMPETITION_CATEGORY: 'Competition Category Restriction',
  OME_MEET_RESTRICTIONS_TYPE_AGE: 'Age Restriction',
  OME_MEET_INVITATIONS: 'Invitations',
  OME_MEET_SESSIONS: 'Sessions',
  OME_MEET_SESSIONS_DETAIL: 'Session Info',
  OME_MEET_AGE_GROUPS: 'Age Groups',
  OME_MEET_AGE_GROUPS_DETAIL: 'Age Group Info',
  OME_MEET_TIME_STANDARDS: 'Time Standards',
  OME_MEET_TIME_STANDARDS_DETAIL: 'Age Group Time Standard Info',
  OME_MEET_EVENTS: 'Events',
  OME_MEET_EVENTS_DETAIL: 'Event Info',
  OME_MEET_EVENTS_DETAIL_ALTERNATE: 'Alternate Event Info',
  OME_MEET_PRICING: 'Pricing',
  OME_MEET_PRICING_ADDITIONAL_PURCHASES: 'Pricing - Additional Purchase Item',
  OME_MEET_SUBMIT: 'Submit',
  OME_MEET_SUBMITTED: 'Submitted'
};

const VIEW_DATA = [
  {
    name: Views.OME_MEET_INFO,
    subHeaderText: 'Meet Info',
    instructionText: 'Provide a Meet Time Zone, all required Meet Dates, at least one Course Priority, and determine if Secondary Representation is allowed. Entry Times for any Event Time Standard Cuts will only be considered if they were achieved in a Course with an associated Course Priority.',
    nextButtonText: 'Continue',
    backButtonText: 'Exit',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_INFO_NON_HQ,
    subHeaderText: 'Meet Info',
    instructionText: 'Provide a Meet Time Zone, all required Meet Dates, and at least one Course Priority. Entry Times for any Event Time Standard Cuts will only be considered if they were achieved in a Course with an associated Course Priority.',
    nextButtonText: 'Continue',
    backButtonText: 'Exit',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_INFO_COURSE_PRIORITY,
    subHeaderText: 'Meet Info - Course Priority',
    instructionText: 'Select a Course and provide the associated Priority. A Priority of 1 indicates that any times achieved in the associated Course will be considered first for event entry, and a Priority of 2 indicates that any times achieved in the associated Course will be considered second for event entry, etc.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_ELIGIBILITY,
    subHeaderText: 'Eligibility',
    instructionText: "Provide information to determine Event and Coach Eligibility. At least one Coach Pass Rule is required. At least one Bonus Event Rule is required if the Meet allows Bonus Events.",
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_ELIGIBILITY_NON_HQ,
    subHeaderText: 'Eligibility',
    instructionText: "Provide information to determine Event and Coach Eligibility. At least one Coach Pass Rule is required. At least one Bonus Event Rule is required if the Meet allows Bonus Events.",
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_ELIGIBILITY_BONUS_EVENTS,
    subHeaderText: 'Eligibility - Bonus Event Rule',
    instructionText: 'Provide a Number of Qualifying Events Range and the associated Allowed Number of Bonus Events. If the Max Events Per Individual Per Meet is set on the main Eligibility page, the sum of the Max Number of Qualifying Events and the Allowed Number of Bonus Events cannot exceed the Max Events Per Individual Per Meet.\n\n**If the first provided Bonus Event Rule does not use 0 as the Min Number of Qualifying Events, a Bonus Event Rule allowing 0 bonus events when an athlete has 0 qualifying events will automatically be created upon saving the page.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_ELIGIBILITY_COACH_PASSES,
    subHeaderText: 'Eligibility - Coach Pass Rule',
    instructionText: "Provide a Number of Athletes Range and the associated Allowed Number of Coach Passes. If the 'Include Relay Only Swimmers in Count for Coach Passes?' field is set to 'Yes' on the main Eligibility page, then Relay Only Swimmers will be included in the Number of Athletes Count.\n\n**If the first provided Coach Pass Rule does not use 0 as the Min Number of Athletes, a Coach Pass Rule allowing 0 coach passes when there are 0 athletes will automatically be created upon saving the page.",
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_RESTRICTIONS,
    subHeaderText: 'Restrictions',
    instructionText: 'Provide information to determine Athlete Restrictions. Add Restrictions (Required: Age, Optional: Membership and/or Competition Category) to the table below.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_RESTRICTIONS_NON_HQ,
    subHeaderText: 'Restrictions',
    instructionText: 'Provide information to determine Athlete Restrictions. Add Restrictions (Required: Age, Optional: Membership and/or Competition Category) to the table below.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_RESTRICTIONS_TYPE,
    subHeaderText: 'Add a Restriction',
    instructionText: 'Select a Restriction Type from the options below.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_RESTRICTIONS_TYPE_MEMBERSHIP,
    subHeaderText: 'Membership Restriction',
    instructionText: 'Select all Memberships that are eligible to participate in the meet.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_RESTRICTIONS_TYPE_COMPETITION_CATEGORY,
    subHeaderText: 'Competition Category Restriction',
    instructionText: 'Select which Competition Category is eligible to participate in the meet.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_RESTRICTIONS_TYPE_AGE,
    subHeaderText: 'Age Restriction',
    instructionText: 'Provide an Age Range that is eligible to participate in the meet.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_INVITATIONS,
    subHeaderText: 'Invitations',
    instructionText: 'Select the Organizations/Teams Invited to the Meet. At least one Organization/Team selection is required.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_SESSIONS,
    subHeaderText: 'Sessions',
    instructionText: 'Optional: Provide a Warm Up Time and/or a Start Time for any Meet Sessions.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Exit'
  },
  {
    name: Views.OME_MEET_SESSIONS_DETAIL,
    subHeaderText: 'Session Times',
    instructionText: 'Optional: Provide a Warm Up Time and/or a Start Time for the selected Session. Times are selected using a 24-hour clock.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_AGE_GROUPS,
    subHeaderText: 'Age Groups',
    instructionText: 'Provide Age Groups for the Meet. At least one Age Group is required.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Exit'
  },
  {
    name: Views.OME_MEET_AGE_GROUPS_DETAIL,
    subHeaderText: 'Age Group',
    instructionText: 'Provide an Age Group Name and an Age Range.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_TIME_STANDARDS,
    subHeaderText: 'Time Standards',
    instructionText: 'Indicate whether or not the Meet uses Time Standards. If the Meet uses Time Standards, additional information is required.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_TIME_STANDARDS_DETAIL,
    subHeaderText: 'Age Group Time Standard',
    instructionText: "Select Time Standards for the Age Group. Time Standards cannot be created within the OME Host setup process. To create new or update existing Time Standards, navigate to 'Time Standards' under the 'Competition' portion of the website, or use the button below.\n\nIf the Meet has more than one Time Standard Type, a different Time Standard must be selected for each Time Standard Type. If a Time Standard Type is not applicable, select the 'No Time Standard' option.",
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_EVENTS,
    subHeaderText: 'Events',
    instructionText: 'Provide All Events for Each Session. Each Age Group must have at least one Event. If an Individual Event uses Time Standards Cuts, then that Individual Event must have a Time Standard Cut for each Course Priority.',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Exit'
  },
  {
    name: Views.OME_MEET_EVENTS_DETAIL,
    subHeaderText: 'Event',
    instructionText: 'Provide Event Information.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_EVENTS_DETAIL_ALTERNATE,
    subHeaderText: 'Alternate Event',
    instructionText: 'Provide Alternate Event Information for the Selected Event.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_PRICING,
    subHeaderText: 'Pricing',
    instructionText: 'Provide Pricing Information. Optional: Provide Pricing Information for any Additional Purchase Items (Parking Passes, etc.).',
    nextButtonText: 'Continue',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  },
  {
    name: Views.OME_MEET_PRICING_ADDITIONAL_PURCHASES,
    subHeaderText: 'Pricing - Additional Purchase Item',
    instructionText: 'Provide Additional Purchase Item Pricing Information.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
  },
  {
    name: Views.OME_MEET_SUBMIT,
    subHeaderText: 'Submit',
    instructionText: 'Provide an Entry Contact and a Payment Contact.',
    nextButtonText: 'Submit',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Submit & Exit'
  },
  {
    name: Views.OME_MEET_SUBMITTED,
    subHeaderText: 'OME Meet is Submitted & Created',
    instructionText: 'Optional: Update the Entry Contact and/or the Payment Contact.',
    nextButtonText: 'Save',
    backButtonText: 'Back',
    saveAndExitButtonText: 'Save & Exit'
  }
];

export const getViewData = (viewName) => {
  const view = VIEW_DATA.find(x => x.name === viewName);
  return { ...view };
};

export default Views;