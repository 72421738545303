import React, { Fragment } from 'react';

import useAthleteUserCoaches from './UseAthleteUserCoaches';

import AthleteUserCoachesGrid from './AthleteUserCoachesGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AthleteUserCoachesReadOnly = () => {
  const { omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState, athleteEntryContextState } = useAthleteUserCoaches();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Coach</Headings.H3>
        </div>
        <div className={athleteEntryContextState.isUnattached === true ? global.DisplayComponent : global.HideComponent}>
          <div className="col-xs-12">
            <AthleteUserCoachesGrid
              gridData={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.arrayData}
              isLoading={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading}
            />
          </div>
        </div>
        <div className={athleteEntryContextState.isUnattached === true ? global.HideComponent : global.DisplayComponent}>
          <div className="col-xs-12">
            <p>Only Unattached Athletes can add a Coach. Teams will add Coaches through the Team Entry process.</p>
          </div>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omeMeetOrgUnitNonAthleteEntryOrgUnitAthleteEntryState.isArrayLoading} />
    </Fragment >
  );
};

export default AthleteUserCoachesReadOnly;