import { useEffect, useState } from 'react';

import useLaneSheetMeetEventsData from '../../../state/laneSheetMeetEvents/UseLaneSheetMeetEventsData';

const useLaneSheetEventMultiSelect = (laneSheetMeetId) => {
  const { laneSheetMeetEventsState, getLaneSheetMeetEvents } = useLaneSheetMeetEventsData();
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (laneSheetMeetId > 0) {
      if (laneSheetMeetEventsState.isArrayLoaded !== true) {
        const getLaneSheetMeetEventsPromise = getLaneSheetMeetEvents(laneSheetMeetId);

        if (getLaneSheetMeetEventsPromise !== null) {
          getLaneSheetMeetEventsPromise.then((newState) => {
            const eventOptions = [];

            for (const event of newState.arrayData) {
              if (event.laneSheetEventId > 0) {
                eventOptions.push({ id: event.laneSheetEventId, name: `${event.eventCompetitionGenderTypeName}-${event.eventName}` });
              }
            }

            setOptionsState(eventOptions);
          }).catch((e) => {
            //TODO context error
          });
        }
      } else if (laneSheetMeetEventsState.isArrayLoaded === true) {
        const eventOptions = [];

        for (const event of laneSheetMeetEventsState.arrayData) {
          if (event.laneSheetEventId > 0) {
            eventOptions.push({ id: event.laneSheetEventId, name: `${event.eventCompetitionGenderTypeName}-${event.eventName}` });
          }
        }

        setOptionsState(eventOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetMeetId, laneSheetMeetEventsState.isArrayLoaded]);

  return {
    laneSheetMeetEventsState,
    options: optionsState
  };
};

export default useLaneSheetEventMultiSelect;