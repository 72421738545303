import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getEventMeetEventData from './GetEventMeetEventData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  meetEventId: Constants.DEFAULT_ID,
  items: []
};

const EventMeetEventData = {
  getEventMeetEventData,
  INITIAL_STATE
};

export default EventMeetEventData;