import { Fragment } from 'react';

import useParticipantGridDetailPopup from './UseParticipantGridDetailPopup';

import { formatPercentageForDisplay } from '../../utils/SelectionsUtils';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import style from './ParticipantGridDetailPopup.module.css';

const ParticipantGridDetailPopup = () => {
  const {
    participantGridDetailPopupState,
    onEnterCell,
    onLeaveCell,
    getLeftPx,
    getTopPx,
    getRelayLegName
  } = useParticipantGridDetailPopup();

  if (participantGridDetailPopupState.displayPopup === true) {
    return (
      <span
        onMouseEnter={(e) => { onEnterCell(undefined, participantGridDetailPopupState.participant) }}
        onMouseLeave={onLeaveCell}
        className={style.CellDetailPopup}
        style={{ left: getLeftPx(), top: getTopPx() }}>
        <div className={style.CellDetailPopupHead}>
          <span>{participantGridDetailPopupState.popupTitle || ''}</span>&nbsp;
        </div>
        <div className={style.CellDetailPopupBody}>
          <div className='row'>
            {participantGridDetailPopupState.participant.selectionMeetPersonId === null && (
              <Fragment>
                <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Leg 1:</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{getRelayLegName(participantGridDetailPopupState.participant.leg1FirstName, participantGridDetailPopupState.participant.leg1LastName)  || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Leg 2:</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{getRelayLegName(participantGridDetailPopupState.participant.leg2FirstName, participantGridDetailPopupState.participant.leg2LastName)  || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Leg 3:</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{getRelayLegName(participantGridDetailPopupState.participant.leg3FirstName, participantGridDetailPopupState.participant.leg3LastName)  || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Leg 4:</div>
                <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{getRelayLegName(participantGridDetailPopupState.participant.leg4FirstName, participantGridDetailPopupState.participant.leg4LastName)  || <span>&nbsp;</span>}</div>
              </Fragment>
            )}
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Event:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.eventCode || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Entry Time:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.entryTime ? formatTimeForDisplay(participantGridDetailPopupState.participant.entryTime) : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Qualifying Swim Date:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.qualifyingSwimDate ? formatDate(participantGridDetailPopupState.participant.qualifyingSwimDate) : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Qualifying Swim Meet:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.qualifyingMeet || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Time Standard:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.standardName || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Calc Pct of Base:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.percentOfBaseTime ? formatPercentageForDisplay(participantGridDetailPopupState.participant.percentOfBaseTime) : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyLabels].join(' ')}>Selection Reason:</div>
            <div className={['col-xs-6', style.CellDetailPopupBodyData].join(' ')}>{participantGridDetailPopupState.participant.selectionReason || <span>&nbsp;</span>}</div>
          </div>
        </div>
      </span>
    );
  } else {
    return <Fragment />;
  }
};

export default ParticipantGridDetailPopup;