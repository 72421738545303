import Combobox from '../../../../../common/components/comboboxes/Combobox'
import EditIcon from '../../../../../common/components/icons/EditIcon';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ i, formState, options, onValueTextPairChanged, onEditAthlete }) => (
  <tr style={{ height: "80px" }}>
    <td style={{ width: "5%" }}>{i}</td>
    <td style={{ width: "20%" }}>
      <Combobox
        name={`personId${i}`}
        valueToMatch={formState[`personName${i}`] || ''}
        items={options}
        onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, `personId${i}`, newValueLabel, `personName${i}`); }} />
    </td>
    <td style={{ width: "5%" }}>
      {formState[`personId${i}`] && formState[`personId${i}`] !== Constants.DEFAULT_ID &&
        <button
          type="button"
          className={[global.IconButtonMargin, 'pull-left'].join(' ')}
          onClick={() => onEditAthlete(formState[`personId${i}`])}>
          <EditIcon />
        </button>
      }
    </td>
    <td style={{ width: "60%" }}></td>
  </tr>
);

const GridTBody = ({ numberOfLanes, startingLaneNumber, formState, options, onValueTextPairChanged, onEditAthlete }) => {
  const rows = [];

  if (startingLaneNumber > 1) {
    for (let i = numberOfLanes; i >= 1; i--) {
      rows.push(<LargeGridRow key={i} i={i} formState={formState} options={options} onValueTextPairChanged={onValueTextPairChanged} onEditAthlete={onEditAthlete} />)
    }
  } else {
    for (let i = 1; i <= numberOfLanes; i++) {
      rows.push(<LargeGridRow key={i} i={i} formState={formState} options={options} onValueTextPairChanged={onValueTextPairChanged} onEditAthlete={onEditAthlete} />)
    }
  }

  return <tbody>{rows}</tbody>;
};

const LaneSheetHeatAthleteFormGridLarge = ({ numberOfLanes, startingLaneNumber, formState, options, onValueTextPairChanged, onEditAthlete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Lane #</th>
        <th>Athlete</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <GridTBody numberOfLanes={numberOfLanes} startingLaneNumber={startingLaneNumber} formState={formState} options={options} onValueTextPairChanged={onValueTextPairChanged} onEditAthlete={onEditAthlete} />
  </table>
);

export default LaneSheetHeatAthleteFormGridLarge