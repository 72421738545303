import { useContext } from 'react';

import { EventStateContext } from './EventContextProvider';
import EventData from './EventData';

const useEventData = () => {
  const [eventState, setEventState] = useContext(EventStateContext);

  const getEvents = () => {
    EventData.getEvents(eventState, setEventState);
  };

  return {
    eventState,
    setEventState,
    getEvents
   };
};

export default useEventData;