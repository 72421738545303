import { useContext } from 'react';

import { MyMeetsFiltersStateContext } from './MyMeetsFiltersContextProvider';

const useMyMeetsFiltersData = () => {
  const [myMeetsFiltersState, setMyMeetsFiltersState] = useContext(MyMeetsFiltersStateContext);

  const resetMyMeetsFiltersState = () => {
    setMyMeetsFiltersState({
      omeMeetHostFiltersState: {},
      omeMeetTeamEntryFiltersState: {}
    });
  };

  return {
    myMeetsFiltersState,
    setMyMeetsFiltersState,
    resetMyMeetsFiltersState
  };
};

export default useMyMeetsFiltersData;