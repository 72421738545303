import { useContext } from 'react';
import Constants from '../../../common/utils/Constants';

import { MeetCompetitionSummaryStateContext } from './MeetCompetitionSummaryContextProvider';

import MeetCompetitionSummaryData from './MeetCompetitionSummaryData';

const useMeetCompetitionSummaryData = () => {
  const [meetCompetitionSummaryState, setMeetCompetitionSummaryState] = useContext(MeetCompetitionSummaryStateContext);

  const getMeetCompetitionSummary = (meetId) => {
    MeetCompetitionSummaryData.getMeetCompetitionSummaryData(meetId, meetCompetitionSummaryState, setMeetCompetitionSummaryState);
  };

  const clearMeetCompetitionSummaryObjData = () => {
    setMeetCompetitionSummaryState({
      ...meetCompetitionSummaryState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      meetId: Constants.DEFAULT_ID,
      message: ''
    });
  };

  return {
    meetCompetitionSummaryState,
    setMeetCompetitionSummaryState,
    getMeetCompetitionSummary,
    clearMeetCompetitionSummaryObjData
  };
};

export default useMeetCompetitionSummaryData;