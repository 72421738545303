import React, { Fragment } from 'react';

import OrgUnitAdminRelayEntriesWrite from './OrgUnitAdminRelayEntriesWrite';
import OrgUnitAdminRelayEntriesReadOnly from './OrgUnitAdminRelayEntriesReadOnly';

import useOrgUnitAdminRelayEntries from './UseOrgUnitAdminRelayEntries';

const OrgUnitAdminRelayEntries = () => {
  const { oUAdminEntryContextState } = useOrgUnitAdminRelayEntries();

  return (
    <Fragment>
      {oUAdminEntryContextState.isReadOnly === false ?
        <OrgUnitAdminRelayEntriesWrite /> :
        <OrgUnitAdminRelayEntriesReadOnly />
      }
    </Fragment>
  );
};

export default OrgUnitAdminRelayEntries;