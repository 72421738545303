import { useEffect } from "react";
import { useNavigate, useLocation } from "../../../common/wrappers/ReactRouterDom";

import HostNavLinks from "../host/HostNavLinks";
import EntryNavLinks from '../entry/EntryNavLinks';

import useSecurityData from "../../../common/state/security/UseSecurityData";

const HOST_TAXONOMIES = [''];
const TEAM_ENTRY_TAXONOMIES = ['OUEntryDetail'];
const ATHLETE_ENTRY_TAXONOMIES = ['AthleteEntryDetail'];
const SCOPE = 'OnlineMeetEntry';

const useOmeLoading = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { contextSecurityState, getContextSecurity } = useSecurityData();

  useEffect(() => {
    //Navigating from my meets host
    if (location.state.omeMeet && location.state.selectOrgUnitState !== undefined) {
      //Edit existing OME Meet
      if (location.state.omeMeet?.omeMeetId > 0) {
        if (contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE) {
          getContextSecurity(location.state.omeMeet.omeMeetId, HOST_TAXONOMIES, SCOPE);
        }
        if (contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE) {
          navigate(HostNavLinks.OME_HOST_LOADING, {
            state: {
              omeMeet: location.state.omeMeet, selectOrgUnitState: location.state.selectOrgUnitState,
              omeMeetHostFiltersState: location.state?.omeMeetHostFiltersState, omeMeetTeamEntryFiltersState: location.state?.omeMeetTeamEntryFiltersState
            }
          });
        }
      }
      //Add new OME Meet
      else {
        navigate(HostNavLinks.OME_MEET_TEMPLATE_LANDING, {
          state: {
            omeMeet: location.state.omeMeet, selectOrgUnitState: location.state.selectOrgUnitState,
            omeMeetHostFiltersState: location.state?.omeMeetHostFiltersState, omeMeetTeamEntryFiltersState: location.state?.omeMeetTeamEntryFiltersState
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState]);

  useEffect(() => {
    //Navigating from my meets team entry
    if (location.state.teamEntry && !location.state.athleteEntry && location.state.selectOrgUnitState !== undefined) {
      //Edit existing OME Team Entry
      if (location.state.teamEntry?.omeMeetId > 0 && location.state.teamEntry?.omeMeetOrgUnitEntryId > 0) {
        if (contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE) {
          getContextSecurity(location.state.teamEntry.omeMeetId, TEAM_ENTRY_TAXONOMIES, SCOPE);
        }
        if (contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE) {
          navigate(EntryNavLinks.OME_ENTRY_LOADING, {
            state: {
              teamEntry: location.state.teamEntry, selectOrgUnitState: location.state.selectOrgUnitState,
              omeMeetHostFiltersState: location.state?.omeMeetHostFiltersState, omeMeetTeamEntryFiltersState: location.state?.omeMeetTeamEntryFiltersState
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState]);

  useEffect(() => {
    //Navigating from my meets athlete entry
    if (location.state.athleteEntry && location.state.meetInfo && location.state.teamEntry && location.state.selectOrgUnitState !== undefined) {
      //Edit existing OME Athlete Entry
      if (location.state.meetInfo?.omeMeetId > 0 && location.state.athleteEntry?.orgUnitAthleteEntryId > 0) {
        if (contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE) {
          getContextSecurity(location.state.meetInfo.omeMeetId, ATHLETE_ENTRY_TAXONOMIES, SCOPE);
        }
        if (contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE) {
          navigate(EntryNavLinks.OME_ENTRY_LOADING, { state: { athleteEntry: location.state.athleteEntry, meetInfo: location.state.meetInfo, teamEntry: location.state.teamEntry, selectOrgUnitState: location.state.selectOrgUnitState } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState]);

  useEffect(() => {
    //Navigating from my athlete meet entries
    if (location.state.athleteEntry && location.state.meetInfo && location.state.teamEntry && location.state.selectOrgUnitState === undefined) {
      //Edit existing OME Athlete Entry
      if (location.state.meetInfo?.omeMeetId > 0 && location.state.athleteEntry?.orgUnitAthleteEntryId > 0) {
        if (contextSecurityState.isObjLoading !== true && contextSecurityState.scope !== SCOPE) {
          getContextSecurity(location.state.meetInfo.omeMeetId, ATHLETE_ENTRY_TAXONOMIES, SCOPE);
        }
        if (contextSecurityState.isObjLoaded === true && contextSecurityState.scope === SCOPE) {
          navigate(EntryNavLinks.OME_ENTRY_LOADING, { state: { athleteEntry: location.state.athleteEntry, meetInfo: location.state.meetInfo, teamEntry: location.state.teamEntry, omeMeetAthleteEntryFiltersState: location.state.omeMeetAthleteEntryFiltersState, selectOrgUnitState: location.state.selectOrgUnitState } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState]);

  return {
    isLoading: true
  };
};

export default useOmeLoading;