import { useEffect } from 'react';
import Constants from '../../../common/utils/Constants';

import useLaneSheetMedalsData from '../../state/laneSheetMedals/UseLaneSheetMedalsData';

const useLaneSheetMedalDropdown = () => {
  const { laneSheetMedalsState, setLaneSheetMedalsState, getLaneSheetMedals } = useLaneSheetMedalsData();

  useEffect(() => {
    if (laneSheetMedalsState.isArrayLoading !== true && laneSheetMedalsState.isArrayLoaded !== true) {
      const getLaneSheetMedalsPromise = getLaneSheetMedals();

      if (getLaneSheetMedalsPromise !== null) {
        getLaneSheetMedalsPromise.then((newState) => {
          if (newState !== null) {
            const options = newState.arrayData.map(x => { return { id: x.meetClassificationId, name: x.typeName }; });
            
            options.unshift({ id: Constants.DEFAULT_ID, name: '--' });

            setLaneSheetMedalsState({
              ...newState,
              areOptionsLoaded: true,
              options
            });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetMedalsState]);

  return {
    laneSheetMedalsState
  };
};

export default useLaneSheetMedalDropdown;