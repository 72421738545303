import useExportForm from "./UseExportForm";
import SelectionsTimeStandardDropdown from "../../../../components/dropdowns/SelectionsTimeStandardDropdown";

import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";

const ExportForm = ({ onSubmitFormCallback, onSecondaryButtonClicked, submitButtonText, secondaryButtonText, 
  selectionsStateObj, children }) => {
    const {
      formState,
      errorState,
      onFormValueChanged,
      handleSubmit,
      onValueTextPairChanged
    } = useExportForm(onSubmitFormCallback, selectionsStateObj);

    return (
      <form onSubmit={handleSubmit} noValidate>
        <div className='row'>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='For athletes selected in an individual meet - Include all individual event entries'
              name="includeAllIndEventEntries"
              checked={formState.includeAllIndEventEntries}
              error={errorState.includeAllIndEventEntries}
              message={errorState.includeAllIndEventEntries}
              onChange={(value) => { onFormValueChanged('includeAllIndEventEntries', value) }} />
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='For athletes selected in an relay events only - Include all individual event entries'
              name="relayAthsIncludeAllIndEventEntries"
              checked={formState.relayAthsIncludeAllIndEventEntries}
              error={errorState.relayAthsIncludeAllIndEventEntries}
              message={errorState.relayAthsIncludeAllIndEventEntries}
              onChange={(value) => { onFormValueChanged('relayAthsIncludeAllIndEventEntries', value) }} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <SelectionsTimeStandardDropdown
                  label='Time Standard*'
                  name="timeStandardId"
                  value={formState.timeStandardId}
                  error={errorState.timeStandardId}
                  message={errorState.timeStandardId}
                  orgUnitId={selectionsStateObj.orgUnitId}
                  omeMeetId={selectionsStateObj.omeMeetId}
                  onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'timeStandardId', valueLabel, 'timeStandardName') }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin pull-right">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          </div>
      </div>
      </form>
    );
};

export default ExportForm;
