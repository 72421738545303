import { useContext } from 'react';

import { AthleteEntryContextStateContext } from './AthleteEntryContextContextProvider';

const useAthleteEntryContextData = () => {
  const [athleteEntryContextState, setAthleteEntryContextState] = useContext(AthleteEntryContextStateContext);

  return {
    athleteEntryContextState,
    setAthleteEntryContextState
  };
};

export default useAthleteEntryContextData;