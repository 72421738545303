import { useEffect, useState } from "react";

const INITIAL_STATE = {
  columnData: [],
  rowData: [],
  columnCount: 1
};

const useSelectionParticipantsGrid = (selectionsMeetEventsStateArray, selectionParticipants) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (selectionsMeetEventsStateArray && selectionParticipants) {
      const columnData = selectionsMeetEventsStateArray;
      const rowData = selectionParticipants;

      setState({
        ...state,
        columnData,
        rowData,
        columnCount: columnData.length + 1
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsMeetEventsStateArray, selectionParticipants]);

  return {
    state
  };
};

export default useSelectionParticipantsGrid;